import Immutable, { List, Map } from 'immutable';
import { denormalize } from 'normalizr';

import { groupSchema } from '../schemas/groupSchema';

export default function getCouncils(state: any, format: any) {
  const group = state.get('group', Map());

  const result = group.getIn(['data', 'councilResult'], List());

  const entities = {
    group: state.getIn(['group', 'data', 'byId'], Map()).toJS(),
  };

  let items = denormalize(result.toJS(), [groupSchema], entities);

  if (format === 'suggestions') {
    items = items.map((item: any) => ({
      label: item.displayName,
      value: item.id,
    }));
  }

  return Immutable.fromJS(items);
}
