export const COMMENT_CREATE_REQUEST = 'COMMENT_CREATE_REQUEST';
export const COMMENT_CREATE = 'COMMENT_CREATE';

export const COMMENT_UPDATE_REQUEST = 'COMMENT_UPDATE_REQUEST';
export const COMMENT_UPDATE = 'COMMENT_UPDATE';

export const COMMENT_DELETE_REQUEST = 'COMMENT_DELETE_REQUEST';
export const COMMENT_DELETE = 'COMMENT_DELETE';

export const COMMENT_FETCH_REQUEST = 'COMMENT_FETCH_REQUEST';
export const COMMENT_FETCH = 'COMMENT_FETCH';

export const COMMENT_CLEAR = 'COMMENT_CLEAR';
