import { Map } from 'immutable';
// constants
import {
  INVITE_CREATE,
  INVITE_DELETE,
  INVITE_FETCH_FOR_GROUP,
  INVITE_RESEND,
  INVITE_BULK,
} from 'store/constants/inviteTypes';
// helpers
import httpReducer from './helpers/httpReducer';
import remove from './callbacks/remove';
import addAccountByStatus from './callbacks/addAccountByStatus';
const PENDING = 3;

export default function reduceInvite(state = Map(), action = {}) {
  switch ((action as any).type) {
    case INVITE_FETCH_FOR_GROUP:
    case INVITE_BULK:
      return httpReducer(state, action, {
        entity: 'invite',
      });
    case INVITE_CREATE:
      return addAccountByStatus(state, action, { status: PENDING });
    case INVITE_DELETE:
      return httpReducer(state, action, {
        entity: 'invite',
        callback: remove,
      });
    case INVITE_RESEND:
      return httpReducer(state, action, {
        entity: 'invite',
        updateResult: false,
      });
    default:
      return state;
  }
}
