import { all, call, fork, put, takeEvery, select } from 'redux-saga/effects';

// action creators
import { doneIndicator } from 'store/actions/httpActions';

// api
import client from 'sources/api';

// schemas
import { billingSchema } from 'store/schemas/billingSchema';

// constants
import {
  BILLING_FETCH_STRIPE_CONFIG,
  BILLING_FETCH_STRIPE_CONFIG_REQUEST,
} from 'store/constants/billingTypes';

// helpers
import httpSaga from 'store/sagas/httpSaga';
import getCurrentGroupId from 'store/selectors/getCurrentGroupId';

const WATCH_TYPE = BILLING_FETCH_STRIPE_CONFIG_REQUEST;
const TYPE = BILLING_FETCH_STRIPE_CONFIG;

export function* fetchStripeConfigSaga() {
  const group_id = yield select(getCurrentGroupId);

  // @ts-expect-error ts-migrate(2569) FIXME: Type 'Generator<any, any, unknown>' is not an arra... Remove this comment to see the full error message
  yield* httpSaga(
    TYPE,
    call(client.get, '/billing/stripe', {
      params: { group_id },
    }),
    { schema: billingSchema }
  );

  yield put(doneIndicator(TYPE));
}

export function* watch() {
  yield takeEvery(WATCH_TYPE, fetchStripeConfigSaga);
}

export default function* root() {
  yield all([fork(watch)]);
}
