import { all, put, fork, takeEvery, take, select } from 'redux-saga/effects';

// action creators
import { doneIndicator } from 'store/actions/httpActions';
import { validateSession, refreshSession } from 'store/actions/sessionActions';
import { fetchStripeConfig } from 'store/actions/billingActions';
import { fetchCurrentUser } from 'store/actions/userActions';
import { setCurrentGroup } from 'store/actions/groupActions';

// constants
import { SESSION_VERIFY, SESSION_REFRESH } from 'store/constants/sessionTypes';
import { BILLING_FETCH_STRIPE_CONFIG } from 'store/constants/billingTypes';
import { CURRENT_USER_FETCH } from 'store/constants/userTypes';
import { CURRENT_GROUP_FETCH } from 'store/constants/groupTypes';

// helpers
import { getAccessToken, isTokenValid } from 'helpers/tokenUtils';
import history from 'helpers/history';

// selectors
import getCurrentUserGroups from 'store/selectors/getCurrentUserGroups';
import getIsSystemAdmin from 'store/selectors/getIsSystemAdmin';
import getIsHqAdmin from 'store/selectors/getIsHqAdmin';
import getIsCurrentGroupChapter from 'store/selectors/getIsCurrentGroupChapter';
import getIsCurrentGroupCouncil from 'store/selectors/getIsCurrentGroupCouncil';

const TYPE = SESSION_VERIFY;

/*
 * The session verify saga is responsible for checking if the access token
 * is valid and has not expired. If the access token is valid, the current
 * user is fetched, the session is marked as valid, and the user is logged in.
 * If the access token is not valid, a session refresh is triggered and handled by
 * the session refresh saga.
 */
export function* sessionVerify() {
  // Get the access from local storage
  const accessToken = yield getAccessToken();

  // Determine if access token is valid and hasn't expired
  let accessTokenValid = yield isTokenValid(accessToken);

  if (accessTokenValid) {
    const {
      location: { pathname, search },
    } = history;
    // Get the current group off the search params and from local storage
    const urlParams = new URLSearchParams(search);
    const urlGroupId = urlParams.get('group') || '';

    const localStorageGroupId = localStorage.getItem('group') || '';

    // Use the current group from the search params over the local storage
    let currentGroupId =
      parseInt(urlGroupId) || parseInt(localStorageGroupId) || null;

    if (isNaN(Number(urlGroupId)) && currentGroupId) {
      urlParams.set('group', currentGroupId.toString());
      const newUrlParams = urlParams.toString() || '';
      yield history.replace({
        pathname: history.location.pathname,
        search: newUrlParams,
      });
    }

    // Clears out current group if user refreshes on dashboard
    if (pathname.includes('groups') && !urlGroupId) {
      urlParams.delete('group');
      localStorage.removeItem('group');
      currentGroupId = null;
    }

    // If the access token is valid, fetch the current user
    yield put(fetchCurrentUser({ currentGroupId }));

    // Wait for the current user to be fetched
    yield take(doneIndicator(CURRENT_USER_FETCH).type);

    const currentUserGroups = yield select(state =>
      getCurrentUserGroups(state)
    );

    const isCurrentUserSystemAdmin = yield select(state =>
      getIsSystemAdmin(state)
    );
    const isCurrentUserHqAdmin = yield select(state => getIsHqAdmin(state));

    // We use the current user's groups to set the current group if local and url ids were lost
    if (
      !currentGroupId &&
      currentUserGroups.size === 1 &&
      !isCurrentUserHqAdmin
    ) {
      currentGroupId = currentUserGroups.first();
      yield put(fetchCurrentUser({ currentGroupId, skipImageFetch: true }));
      // Wait for the current user to be fetched
      yield take(doneIndicator(CURRENT_USER_FETCH).type);
    }

    // If the current group is set, fetch it if the user has access
    if (currentGroupId) {
      if (
        currentUserGroups.includes(currentGroupId) ||
        isCurrentUserSystemAdmin ||
        isCurrentUserHqAdmin
      ) {
        yield put(
          setCurrentGroup({
            groupId: currentGroupId,
            skipNavigate: true,
          })
        );

        yield take(doneIndicator(CURRENT_GROUP_FETCH).type);
      } else {
        urlParams.delete('group');
        localStorage.removeItem('group');

        const newUrlParams = urlParams.toString() || '';
        yield history.replace({
          pathname: history.location.pathname,
          search: newUrlParams,
        });
      }
    }

    const isCurrentGroupChapter = yield select(state =>
      getIsCurrentGroupChapter(state)
    );
    const isCurrentGroupCouncil = yield select(state =>
      getIsCurrentGroupCouncil(state)
    );

    // If the current group is chapter or council group, fetch billing data
    if (currentGroupId && (isCurrentGroupChapter || isCurrentGroupCouncil)) {
      yield put(fetchStripeConfig());
      yield take(doneIndicator(BILLING_FETCH_STRIPE_CONFIG).type);
    }

    // Mark the session as valid
    yield put(validateSession());
  } else {
    // If the access token is not valid, try refreshing it
    yield put(refreshSession());

    // Wait for the refresh to finish
    yield take(doneIndicator(SESSION_REFRESH).type);
  }

  // Notify the store that session verification has finished
  yield put(doneIndicator(TYPE));
}

export function* watch() {
  yield takeEvery(TYPE, sessionVerify);
}

export default function* root() {
  yield all([fork(watch)]);
}
