import React, { useState, useEffect } from 'react';
import validate from 'validate.js';
import PropTypes from 'prop-types';
import { List } from 'immutable';
// components
import DazzlingDialog from 'components/DazzlingDialog';
import Button from 'components/Button';
import { Form, Field } from 'react-final-form';
import TextField from 'components/TextField';
import EmailListItem from '../EmailListItem';
// icons
import AddIcon from '@material-ui/icons/AddCircleOutline';
import EmailIcon from '@material-ui/icons/Email';
// material-ui
import Divider from '@material-ui/core/Divider';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
// schemas
const schema = {
  email: {
    presence: true,
    email: { message: 'not valid' },
    length: { maximum: 255 },
  },
};
const EmailsForm = function({ createEmail, emails }: any) {
  const [displayEmailForm, setDisplayEmailForm] = useState(false);
  const [viewEmails, setViewEmails] = useState(false);
  const classes = useStyles();
  useEffect(() => {
    if (emails.size <= 1 && viewEmails) {
      setViewEmails(false);
    }
  }, [emails, viewEmails]);
  const onSubmit = ({ email }: any) => {
    createEmail(email);
  };
  return (
    <Grid item xs={12}>
      <Accordion expanded={viewEmails} elevation={0}>
        <AccordionSummary
          classes={{
            root: classes.expansionPanelSummary,
            focusVisible: classes.expansionPanelSummaryFocused,
          }}
          aria-controls='panel-content'
          id='panel-header'
        >
          <Grid container spacing={1}>
            <Grid item>
              <Button
                id='addEmailButton'
                onClick={() => {
                  setDisplayEmailForm(true);
                }}
                startIcon={<AddIcon />}
              >
                Add Email
              </Button>
            </Grid>

            {emails.size > 1 && (
              <Grid item>
                <Button
                  id='viewEmailsButton'
                  onClick={() => {
                    setViewEmails(!viewEmails);
                  }}
                  startIcon={
                    viewEmails ? <ExpandLessIcon /> : <ExpandMoreIcon />
                  }
                >
                  {viewEmails ? 'Hide' : 'Show'} Emails
                </Button>
              </Grid>
            )}
          </Grid>
        </AccordionSummary>
        <AccordionDetails className={classes.expansionPanelSummary}>
          <Grid container>
            {emails.map((emailObject: any) => {
              return [
                <EmailListItem
                  key={emailObject.get('id')}
                  email={emailObject}
                />,
                <Divider
                  key={`key_${emailObject.get('id')}`}
                  className={(classes as any).divider}
                />,
              ];
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>

      <Form
        onSubmit={onSubmit}
        validate={values => validate(values, schema)}
        render={({ handleSubmit, invalid, form: { reset } }) => (
          <DazzlingDialog
            open={displayEmailForm}
            acceptLabel='Add'
            disabled={invalid}
            formName='addEmailForm'
            onAccept={handleSubmit}
            handleClose={() => {
              setDisplayEmailForm(false);
              reset();
            }}
            headerProps={{
              title: 'Add Email',
              subtitle: 'Add an additional email to your account',
              icon: EmailIcon,
            }}
          >
            <Grid item xs={12} sm={6}>
              <Field
                name='email'
                label='New Email'
                render={fieldProps => <TextField {...fieldProps} />}
                autoFocus
                require
              />
            </Grid>
          </DazzlingDialog>
        )}
      />
    </Grid>
  );
};
EmailsForm.propTypes = {
  createEmail: PropTypes.func.isRequired,
  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'typeof List' is not assignable t... Remove this comment to see the full error message
  emails: PropTypes.instanceOf(List).isRequired,
};
EmailsForm.defaultProps = {
  emails: List(),
};
const useStyles = makeStyles({
  expansionPanelSummary: {
    paddingLeft: 0,
    '&:hover:not(.Mui-disabled)': {
      cursor: 'default',
    },
  },
  expansionPanelSummaryFocused: {
    '&:focus': {
      backgroundColor: 'transparent',
    },
    '&:focus-within': {
      backgroundColor: 'transparent',
    },
  },
  expansionPanelDetail: {
    paddingLeft: 10,
  },
});
export default EmailsForm;
