import React, { useCallback } from 'react';
import { useHistory, useParams } from 'react-router';
import { useLeadDuplicateQuery } from 'api/leadDuplicates';

// components
import DuplicateLeads from './DuplicateLeads';
import { Box, Paper } from '@material-ui/core';

export default function LeadDuplicateView() {
  const { leadDuplicateId: leadDuplicateIdParam } = useParams<{
    leadDuplicateId: string;
  }>();
  const history = useHistory();
  const { data: leads = [], isLoading } = useLeadDuplicateQuery(
    Number(leadDuplicateIdParam)
  );
  const handleDelete = useCallback(() => {
    history.push('/leads');
  }, [history]);
  return (
    <Box
      marginY={3}
      padding={3}
      width='fill-available'
      height='max-content'
      minHeight='calc(100% - 48px)'
      display='flex'
      clone
    >
      <Paper>
        <DuplicateLeads
          leads={leads}
          isLeadsLoading={isLoading}
          onDelete={handleDelete}
        />
      </Paper>
    </Box>
  );
}
