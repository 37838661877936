import { useMutation, useQuery } from 'react-query';
import { client, Resource } from 'api';
import { AxiosError } from 'axios';

type CreateCampusFields = {
  name: string;
};

export const useCampusQuery = (
  search: string = '',
  token: string | null = ''
) => {
  return useQuery<Campus[], AxiosError>([Resource.Campus, search], () =>
    client
      .get(Resource.Campus, {
        params: { search },
        ...(token ? { headers: { Authorization: `Bearer ${token}` } } : {}),
      })
      .then(({ data }) => data.results)
  );
};

export const useCreateCampusMutation = () => {
  return useMutation<Campus, AxiosError<{ msg: string }>, CreateCampusFields>(
    fields =>
      client.post(Resource.Campus, { ...fields }).then(({ data }) => data)
  );
};
