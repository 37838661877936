import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
// action creators
import { doneIndicator } from 'store/actions/httpActions';
// api
import client from 'sources/api';
// constants
import {
  INVITE_FETCH_FOR_GROUP_REQUEST,
  INVITE_FETCH_FOR_GROUP,
} from 'store/constants/inviteTypes';
// helpers
import httpSaga from 'store/sagas/httpSaga';
// schema
import { inviteSchema } from 'store/schemas/inviteSchema';
const WATCH_TYPE = INVITE_FETCH_FOR_GROUP_REQUEST;
const TYPE = INVITE_FETCH_FOR_GROUP;
export function* fetchInvitesForGroup(action: any) {
  const { groupId, page = 1, search } = action.payload;
  const queryParams = {
    group_id: groupId,
    page,
  };
  if (search) {
    (queryParams as any).search = search;
  }
  try {
    // @ts-expect-error ts-migrate(2569) FIXME: Type 'Generator<any, any, unknown>' is not an arra... Remove this comment to see the full error message
    yield* httpSaga(
      TYPE,
      call(client.get, '/invites', {
        params: queryParams,
      }),
      {
        schema: [inviteSchema],
      }
    );
  } finally {
    yield put(doneIndicator(TYPE));
  }
}
export function* watch() {
  yield takeEvery(WATCH_TYPE, fetchInvitesForGroup);
}
export default function* root() {
  yield all([fork(watch)]);
}
