import { Map, List } from 'immutable';
import getGroupFields from 'store/selectors/getGroupFields';
import createDeepEqualSelector from 'helpers/createDeepEqualSelector';
const ATTRIBUTES_TO_FILTER = ['legacy_id', 'image', 'referral_source'];
export default function getAttributes(
  lead = Map(),
  mergeWithGroupFields = true
) {
  return createDeepEqualSelector(
    (state: any) => getGroupFields()(state),
    // @ts-expect-error ts-migrate(2740) FIXME: Type 'Map<unknown, unknown>' is missing the follow... Remove this comment to see the full error message
    (groupFields = Map()) => {
      let attributes = lead.get('attributes', Map());
      if (!attributes) attributes = Map();
      const attributeFields = groupFields.filter(groupField => {
        if (ATTRIBUTES_TO_FILTER.includes((groupField as any).get('label')))
          return false;
        if ((groupField as any).get('sources', List()).includes('FormField'))
          return true;
        if ((groupField as any).get('sources', List()).includes('PNMAttr'))
          return true;
        if ((groupField as any).get('sources', List()).includes('GroupValue'))
          return true;
        return false;
      });
      if (mergeWithGroupFields) {
        let attributeList = List();
        attributeFields.forEach(groupField => {
          attributeList = attributeList.push(
            (groupField as any).set(
              'value',
              // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
              (attributes || Map()).get((groupField as any).get('label'))
            )
          );
        });
        return attributeList.sortBy((attr: any) => attr.get('position'));
      } else {
        return attributes;
      }
    }
  );
}
