import { Styles } from 'assets/types';

const fieldsSettingsStyles: Styles = theme => ({
  cardContent: {
    marginBottom: 55, // prevents save bar from covering last item on mobile
    '& .MuiSelect-selectMenu p.MuiTypography-noWrap, & .MuiSelect-selectMenu .MuiListItemText-root .MuiTypography-body1': {
      lineHeight: 'inherit',
    },
    '& .MuiSelect-selectMenu .MuiListItemText-root': {
      marginTop: 0,
      marginBottom: 0,
    },
    '& .gridSelectBox': { marginTop: 3 },
  },
  noFieldsMessage: {
    [theme.breakpoints.up('sm')]: {
      marginLeft: 90,
    },
  },
  topCard: {
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
  },
});

export default fieldsSettingsStyles;
