import { all, fork, put, takeEvery, take, select } from 'redux-saga/effects';
import Immutable, { Map, List } from 'immutable';

// action creators
import { loading, doneIndicator, success } from 'store/actions/httpActions';

// constants
import {
  TASK_POPULATE_REQUEST,
  TASK_POPULATE,
} from 'store/constants/taskTypes';

// helpers & selectors
import getResponsePayload from 'helpers/getResponsePayload';
import getCurrentGroupId from 'store/selectors/getCurrentGroupId';

// schemas
import { taskSchema } from 'store/schemas/taskSchema';
import { fetchMembersForGroup } from 'store/actions/memberActions';

const WATCH_TYPE = TASK_POPULATE_REQUEST;

export function* populateTaskSaga(action: any) {
  const TYPE = TASK_POPULATE;

  const {
    payload: { taskId },
  } = action;

  const META_ID = `populating-${taskId}`;

  yield put(loading(TYPE, META_ID));

  const task = yield select(state =>
    state.getIn(['task', 'data', 'byId', taskId.toString()], Map())
  );

  const currentGroupId = yield select(getCurrentGroupId);
  const accountIds = task.get('accounts', List()).toJS();

  if (accountIds.length) {
    yield put(
      fetchMembersForGroup({ groupId: currentGroupId, accountIds: accountIds })
    );
    yield take('MEMBER_FETCH_FOR_GROUP_DONE');
  }

  // Send the response data to the store for this type to be caught by a reducer
  const payload = getResponsePayload({ data: {} }, taskSchema);
  yield put(success(TYPE, Immutable.fromJS(payload), META_ID));
  yield put(doneIndicator(TYPE));
}

export function* watch() {
  yield takeEvery(WATCH_TYPE, populateTaskSaga);
}

export default function* root() {
  yield all([fork(watch)]);
}
