const getPricesFromSubscriptions = (
  subscriptionOptions?: StripeProductSubscription[]
): {
  month?: StripeProductSubscription;
  year?: StripeProductSubscription;
} =>
  Array.isArray(subscriptionOptions)
    ? {
        month: subscriptionOptions.find(
          subscriptionOption => subscriptionOption?.interval === 'month'
        ),
        year: subscriptionOptions.find(
          subscriptionOption => subscriptionOption?.interval === 'year'
        ),
      }
    : {};

export default getPricesFromSubscriptions;
