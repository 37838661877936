import { List, Map } from 'immutable';
import React from 'react';

// icons
import AccountBalance from '@material-ui/icons/AccountBalance';
import AccountIcon from '@material-ui/icons/SettingsApplications';
import BillingIcon from '@material-ui/icons/Payment';
import FieldsIcon from '@material-ui/icons/List';
import IntegrationsIcon from '@material-ui/icons/Code';
import LocalOffer from '@material-ui/icons/LocalOffer';
import MembersIcon from '@material-ui/icons/Group';
import MilestonesIcon from '@material-ui/icons/LinearScale';
import PhoneIcon from '@material-ui/icons/PermPhoneMsg';
import StatusIcon from '@material-ui/icons/SwapVert';
import TagsIcon from '@material-ui/icons/LocalOffer';
import ValuesIcon from '@material-ui/icons/Star';
import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';

// routes
import AccountSettings from 'routes/AccountSettings';
import AdminSettings from 'routes/AdminSettings';
import BillingSettings from 'routes/BillingSettings';
import FieldsSettings from 'routes/FieldsSettings';
import GroupSettings from 'routes/GroupSettings';
import IntegrationsSettings from 'routes/IntegrationsSettings';
import LabelsSettings from 'routes/LabelsSettings';
import MemberSettings from 'routes/MemberSettings';
import MilestonesSettings from 'routes/MilestonesSettings';
import PhoneSettings from 'routes/PhoneSettings';
import StatusSettings from 'routes/StatusSettings';
import TagsSettings from 'routes/TagsSettings';
import ValuesSettings from 'routes/ValuesSettings';
import PaymentSettings from 'routes/PaymentSettings';
import CommunitySettings from 'routes/CommunitySettings';

export default function getSettingsTabs({
  isSystemAdmin,
  isCurrentGroupSet,
  features = Map(),
  currentUserRole,
  isCurrentGroupChapter,
  isCurrentGroupOrganization,
  isCurrentGroupCouncil,
  isCurrentGroupSystem,
}: any) {
  let tabs: List<Map<string, any>> = List([
    Map({
      label: 'Account',
      icon: <AccountIcon />,
      route: '/settings/account',
      component: AccountSettings,
    }),
  ]);

  if (isSystemAdmin) {
    tabs = tabs.push(
      Map({
        label: 'Admins',
        icon: <MembersIcon />,
        route: '/settings/admins',
        component: AdminSettings,
      }),
      Map({
        label: 'Integrations',
        icon: <IntegrationsIcon />,
        route: '/settings/integrations',
        component: IntegrationsSettings,
      })
    );
  }

  if (
    isCurrentGroupSet &&
    !isCurrentGroupSystem &&
    (currentUserRole === 1 || isSystemAdmin)
  ) {
    tabs = tabs.push(
      Map({
        label: 'Members',
        icon: <MembersIcon />,
        route: '/settings/members',
        component: MemberSettings,
      })
    );
  }

  if (isCurrentGroupOrganization || isCurrentGroupCouncil) {
    tabs = tabs.push(
      Map({
        label: 'Chapter Labels',
        icon: <LocalOffer />,
        route: '/settings/labels',
        component: LabelsSettings,
      })
    );
  }

  if (isCurrentGroupCouncil) {
    tabs = tabs.push(
      Map({
        label: 'Community',
        icon: <AccountBalanceIcon />,
        route: '/settings/community',
        component: CommunitySettings,
      }),
      Map({
        label: 'Lead Tags',
        icon: <TagsIcon />,
        route: '/settings/tags',
        component: TagsSettings,
      }),
      Map({
        label: 'Fields',
        icon: <FieldsIcon />,
        route: '/settings/fields',
        component: FieldsSettings,
      }),
      Map({
        label: 'Payments',
        icon: <MonetizationOnOutlinedIcon />,
        route: '/settings/payments',
        component: PaymentSettings,
      })
    );
  }

  if (
    (isCurrentGroupChapter || isCurrentGroupCouncil) &&
    isCurrentGroupSet &&
    (currentUserRole === 1 || isSystemAdmin)
  ) {
    tabs = isCurrentGroupCouncil
      ? tabs
      : tabs.concat([
          Map({
            label: 'Organization',
            icon: <AccountBalance />,
            route: '/settings/group',
            component: GroupSettings,
          }),
          Map({
            label: 'Billing',
            icon: <BillingIcon />,
            route: '/settings/billing',
            component: BillingSettings,
          }),
        ]);

    if (
      (isCurrentGroupChapter || isCurrentGroupCouncil) &&
      features.getIn(['phone_numbers', 'enabled'], isSystemAdmin)
    ) {
      tabs = tabs.push(
        Map({
          label: 'Phone',
          icon: <PhoneIcon />,
          route: '/settings/phone',
          component: PhoneSettings,
        })
      );
    }

    if (
      (isCurrentGroupChapter || isCurrentGroupCouncil) &&
      features.getIn(['custom_statuses', 'enabled'], isSystemAdmin)
    ) {
      tabs = tabs.push(
        Map({
          label: 'Status',
          icon: <StatusIcon />,
          route: '/settings/statuses',
          component: StatusSettings,
        })
      );
    }
    if (
      isCurrentGroupChapter &&
      features.getIn(['custom_milestones', 'enabled'], isSystemAdmin)
    ) {
      tabs = tabs.push(
        Map({
          label: 'Milestones',
          icon: <MilestonesIcon />,
          route: '/settings/milestones',
          component: MilestonesSettings,
        })
      );
    }
    if (
      isCurrentGroupChapter &&
      features.getIn(['custom_tags', 'enabled'], isSystemAdmin)
    ) {
      tabs = tabs.push(
        Map({
          label: 'Tags',
          icon: <TagsIcon />,
          route: '/settings/tags',
          component: TagsSettings,
        })
      );
    }
    if (
      isCurrentGroupChapter &&
      features.getIn(['pnm_attrs', 'enabled'], isSystemAdmin)
    ) {
      tabs = tabs.push(
        Map({
          label: 'Fields',
          icon: <FieldsIcon />,
          route: '/settings/fields',
          component: FieldsSettings,
        })
      );
    }
    if (isCurrentGroupChapter && features.getIn(['values', 'enabled'], false)) {
      tabs = tabs.push(
        Map({
          label: 'Values',
          icon: <ValuesIcon />,
          route: '/settings/values',
          component: ValuesSettings,
        })
      );
    }
  }

  return tabs;
}
