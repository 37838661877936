import React from 'react';

// hooks
import { useDateTime } from 'helpers/hooks/useDateTime';

// material-ui
import { makeStyles } from '@material-ui/core/styles';
import {
  Tooltip,
  CardContent,
  Grid,
  Typography,
  Card,
  Hidden,
  Box,
} from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import {
  Today as DateIcon,
  LocationOn as LocationIcon,
  AccountCircle as ContactIcon,
  Info as DescriptionIcon,
} from '@material-ui/icons';

// icons
import EventListIcon from '@material-ui/icons/DateRange';

// components
import Header from 'components/Header';

export default function EventView({
  event,
  loading,
}: {
  event: GroupEvent;
  loading: boolean;
}) {
  const classes = useStyles();

  const { date: startDate, time: startTime } = useDateTime(event?.startDate);
  const { date: endDate, time: endTime } = useDateTime(event?.endDate);
  const hasDateDiff = startDate !== endDate;
  const dateLabel = `${startDate}, ${startTime} to ${
    hasDateDiff ? `${endDate}, ${endTime}` : endTime
  }`;
  const contactLabel = `${event?.pointOfContact?.account?.firstName} ${event?.pointOfContact?.account?.lastName}`;

  return (
    <Card>
      <CardContent className={classes.cardContent}>
        <Grid container alignItems='flex-start'>
          <Grid item>
            <Header
              icon={EventListIcon}
              title={event?.title}
              subtitle='Manage guests, invites, and forms for your event'
              loading={loading}
            />
          </Grid>
        </Grid>
        <Grid container spacing={1} className={classes.eventDetailsContainer}>
          <Hidden xsDown>
            <Grid item>
              <Box width={80}></Box>
            </Grid>
          </Hidden>

          <Grid item xs={12} sm={8} lg={9}>
            <DetailItem
              label={dateLabel}
              icon={<DateIcon className={classes.grayIcon} />}
              tooltipTitle='Event Date'
              loading={loading}
            />
            <DetailItem
              label={event?.location}
              icon={<LocationIcon className={classes.grayIcon} />}
              tooltipTitle='Event Location'
              loading={loading}
            />
            {Boolean(event?.pointOfContact) && (
              <DetailItem
                label={contactLabel}
                icon={<ContactIcon className={classes.grayIcon} />}
                tooltipTitle='Point of contact'
                loading={loading}
              />
            )}
            {Boolean(event?.description) && (
              <DetailItem
                label={event.description || ''}
                icon={<DescriptionIcon className={classes.grayIcon} />}
                tooltipTitle='Description'
                loading={loading}
              />
            )}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

const DetailItem = ({
  label,
  icon,
  tooltipTitle,
  loading,
}: {
  label: string;
  icon: any;
  tooltipTitle: string;
  loading: boolean;
}) => {
  return loading ? (
    <Skeleton variant='text' width={350} />
  ) : (
    <Tooltip title={tooltipTitle} placement='bottom-start' arrow>
      <Grid container spacing={1}>
        <Grid item>{icon}</Grid>
        <Grid item>
          <Typography variant='body2' color='textSecondary'>
            {label}
          </Typography>
        </Grid>
      </Grid>
    </Tooltip>
  );
};

const useStyles = makeStyles(theme => {
  return {
    eventsContainer: {
      height: '100%',
      overflowY: 'scroll',
      overflowX: 'hidden',
    },

    cardContent: {
      paddingTop: 30,
    },

    buttonContainer: {
      textAlign: 'right',
      marginTop: 10,
    },

    button: {
      marginRight: 8,
    },

    grayIcon: {
      color: (theme.palette.text as any).secondary,
    },

    noneFoundContainer: {
      [theme.breakpoints.up('sm')]: {
        marginLeft: 90,
      },
    },

    eventDetailsContainer: {
      marginTop: -24,
    },
  };
});
