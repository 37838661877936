import axios from 'axios';
import store from 'store';

// action creators
import { fetchCurrentUser } from 'store/actions/userActions';
import { destroySession } from 'store/actions/sessionActions';

// constants
import { SESSION_REFRESH } from 'store/constants/sessionTypes';

// helpers
import { getRefreshToken, setAccessToken } from 'helpers/tokenUtils';
import history from 'helpers/history';

const BASE_URL = process.env.REACT_APP_BASE_API_URL;

let isAlreadyFetchingAccessToken = false;
let subscribers: any = [];

// When the refresh is successful, start retrying the requests one by one and empty the queue
function onAccessTokenFetched(accessToken: any) {
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'callback' implicitly has an 'any' type.
  subscribers.forEach(callback => callback(accessToken));
  subscribers = [];
}

// Add a subscriber, a watched promise, onto the queue
function addSubscriber(callback: any) {
  subscribers.push(callback);
}

export default async function handleTokenRefreshAndRetryRequest(err: any) {
  store.dispatch({ type: SESSION_REFRESH + '_START' });

  try {
    const { response: errorResponse } = err;
    const refreshToken = await getRefreshToken();

    // If there is no refresh token, return the original error response
    if (!refreshToken) {
      return Promise.reject(err);
    }

    // Create a new promise that will retry the original failed
    // request after the auth token has been refreshed.
    // This subscribes to that original request being finished
    // so it can be returned back to Axios and the original flow
    // can continue in the original saga.
    const retryOriginalRequest = new Promise(resolve => {
      addSubscriber((accessToken: any) => {
        errorResponse.config.headers.Authorization = 'Bearer ' + accessToken;

        resolve(axios(errorResponse.config));
      });
    });

    if (!isAlreadyFetchingAccessToken) {
      isAlreadyFetchingAccessToken = true;

      const response = await axios<{ access_token: string }>({
        method: 'post',
        url: `${BASE_URL}/auth/refresh`,
        headers: { Authorization: `Bearer ${refreshToken}` },
      });

      if (!response.data) {
        return Promise.reject(err);
      }

      store.dispatch({ type: SESSION_REFRESH + '_SUCCESS' });

      const newAccessToken = response.data.access_token;

      await setAccessToken(newAccessToken);

      // Get the current group off the search params and from local storage
      const urlParams = new URLSearchParams(history.location.search);
      const urlGroupId = urlParams.get('group');

      const localStorageGroupId = localStorage.getItem('group');

      // Use the current group from the search params over the local storage
      const currentGroupId = urlGroupId || localStorageGroupId;

      store.dispatch(fetchCurrentUser({ currentGroupId }));

      store.dispatch({ type: SESSION_REFRESH + '_DONE' });

      isAlreadyFetchingAccessToken = false;

      onAccessTokenFetched(newAccessToken);
    }

    return retryOriginalRequest;
  } catch (err) {
    store.dispatch({ type: SESSION_REFRESH + '_FAILED' });
    store.dispatch(destroySession());
    store.dispatch({ type: SESSION_REFRESH + '_DONE' });

    return Promise.reject(err);
  }
}
