import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

// action creators
import { doneIndicator, success } from 'store/actions/httpActions';

// api
import client from 'sources/api';

// constants
import {
  FORM_FEE_FETCH_REQUEST,
  FORM_FEE_FETCH,
} from 'store/constants/formFeeTypes';

// schemas
import { feeSchema } from 'store/schemas/feeSchema';

// helpers
import getResponsePayload from 'helpers/getResponsePayload';
import Immutable from 'immutable';
import httpSaga from 'store/sagas/httpSaga';

const WATCH_TYPE = FORM_FEE_FETCH_REQUEST;
const TYPE = FORM_FEE_FETCH;

export function* fetchFormFeeSaga(action: any) {
  const {
    payload: { groupId },
  } = action;

  // @ts-expect-error ts-migrate(2569) FIXME: Type 'Generator<any, any, unknown>' is not an arra... Remove this comment to see the full error message
  const response = yield* httpSaga(
    TYPE,
    call(client.get, '/form_fees/all', {
      params: { group_id: groupId },
    }),
    {
      schema: [feeSchema],
    }
  );

  const payload = getResponsePayload(response, [feeSchema]);

  yield put(success(TYPE, Immutable.fromJS(payload)));

  yield put(doneIndicator(TYPE));
}

export function* watch() {
  yield takeEvery(WATCH_TYPE, fetchFormFeeSaga);
}

export default function* root() {
  yield all([fork(watch)]);
}
