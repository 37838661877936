import { Styles } from 'assets/types';

const analyticsCardStyles: Styles = () => ({
  cardContent: {
    paddingLeft: 24,
    paddingRight: 24,
  },
  tableGrid: {
    marginTop: 15,
  },
  dateRangeGrid: {
    maxWidth: 400,
  },
  exportButtonGrid: {
    maxWidth: 112,
  },
  exportButton: {
    textAlign: 'right',
  },
  clearMaxWidth: {
    maxWidth: 'none !important',
  },
  gridFullLine: {
    padding: '0 !important',
  },
});

export default analyticsCardStyles;
