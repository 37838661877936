export const FORM_FEE_FETCH_REQUEST = 'FEE_FETCH_REQUEST';
export const FORM_FEE_FETCH = 'FEE_FETCH';

export const FORM_ONE_FEE_FETCH_REQUEST = 'FORM_ONE_FEE_FETCH_REQUEST';
export const FORM_ONE_FEE_FETCH = 'FORM_ONE_FEE_FETCH';

export const FORM_FEE_CREATE_REQUEST = 'FORM_FEE_CREATE_REQUEST';
export const FORM_FEE_CREATE = 'FORM_FEE_CREATE';

export const FORM_FEE_DELETE_REQUEST = 'FORM_FEE_DELETE_REQUEST';
export const FORM_FEE_DELETE = 'FORM_FEE_DELETE';

export const FORM_FEE_UPDATE_REQUEST = 'FORM_FEE_UPDATE_REQUEST';
export const FORM_FEE_UPDATE = 'FORM_FEE_UPDATE';
