import React, { useMemo, useCallback, useState } from 'react';
import validate from 'validate.js';
import { isEqual } from 'lodash';

// hooks
import { useDispatch, useSelector } from 'react-redux';

// final form
import { Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';

// selectors
import getSliceState from 'store/selectors/getSliceState';
import getCombinedForm from 'store/selectors/getCombinedForm';

// actions
import {
  updateMilestoneAction,
  deleteMilestoneAction,
} from 'store/actions/milestoneActions';
import { destroyForm as destroyFormAction } from 'store/actions/formActions';

// material-ui
import { Grid } from '@material-ui/core';

// components
import MilestoneFields from './MilestoneFields';
import ActionBar from 'components/ActionBar';
import Button from 'components/Button';

const SCHEMA = {
  title: {
    presence: true,
    length: { maximum: 128 },
  },
  description: {
    length: { maximum: 1024 },
  },
};

const FORM = 'manageMilestone';

export default function MilestonesForm() {
  const [submittedIds, setSubmittedIds] = useState([]);

  const {
    actions: { updateMilestone, deleteMilestone, destroyForm },
    state: {
      milestonesForm: { isSubmitting, hasSubmitFailed, hasSubmitSucceeded },
      milestone: { data: initialMilestones },
    },
  } = useRedux(submittedIds);

  const validateForm = useCallback(
    values => ({
      milestones: values.milestones.map((milestone: any) =>
        validate(milestone, SCHEMA)
      ),
    }),
    []
  );

  const handleFormSubmit = useCallback(
    values => {
      for (const milestone of initialMilestones) {
        const valuesMilestone = values.milestones.find(
          ({ id }: any) => id === milestone.id
        );
        if (!valuesMilestone) {
          // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '(ids: never[]) => any[]' is not ... Remove this comment to see the full error message
          setSubmittedIds(ids => [...ids, milestone.id]);
          deleteMilestone({ formName: FORM + milestone.id, id: milestone.id });
        }
        if (valuesMilestone && !isEqual(valuesMilestone, milestone)) {
          // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '(ids: never[]) => any[]' is not ... Remove this comment to see the full error message
          setSubmittedIds(ids => [...ids, valuesMilestone.id]);
          updateMilestone({
            formName: FORM + valuesMilestone.id,
            id: valuesMilestone.id,
            fields: { ...valuesMilestone },
          });
        }
      }
    },
    [initialMilestones, updateMilestone, deleteMilestone]
  );

  const handleClose = useCallback(() => {
    for (const id of submittedIds) destroyForm(FORM + id);
    setSubmittedIds([]);
  }, [submittedIds, destroyForm]);

  return (
    <Form
      onSubmit={handleFormSubmit}
      mutators={{ ...arrayMutators }}
      keepDirtyOnReinitialize={isSubmitting}
      initialValues={{ milestones: initialMilestones }}
      initialValuesEqual={isEqual}
      validate={validateForm}
      render={({ handleSubmit, invalid, pristine, form: { reset } }) => (
        <form onSubmit={handleSubmit}>
          <FieldArray name='milestones' isEqual={isEqual}>
            {({ fields }) => (
              <Grid container justifyContent={'flex-end'} spacing={2}>
                {fields.map((prefix, index) => (
                  <Grid item xs={12} key={prefix}>
                    <MilestoneFields
                      fieldPrefix={prefix}
                      {...fields.value[index]}
                      onDelete={fields.remove}
                    />
                  </Grid>
                ))}
              </Grid>
            )}
          </FieldArray>
          <ActionBar
            open={!pristine || isSubmitting || hasSubmitSucceeded}
            message='Save your changes?'
            onClose={handleClose}
            // @ts-expect-error ts-migrate(2322) FIXME: Type 'number | null' is not assignable to type 'nu... Remove this comment to see the full error message
            autoHideDuration={hasSubmitSucceeded ? 1500 : null}
          >
            <Button disabled={pristine} onClick={reset}>
              Cancel
            </Button>
            <Button
              variant='outlined'
              color='primary'
              type='submit'
              disabled={pristine || invalid || isSubmitting}
              loading={isSubmitting}
              success={hasSubmitSucceeded}
              fail={hasSubmitFailed}
            >
              Save
            </Button>
          </ActionBar>
        </form>
      )}
    />
  );
}

const useRedux = (submittedIds: any) => {
  const dispatch = useDispatch();
  const actions = useMemo(
    () => ({
      updateMilestone: (payload: any) =>
        dispatch(updateMilestoneAction(payload)),
      deleteMilestone: (payload: any) =>
        dispatch(deleteMilestoneAction(payload)),
      destroyForm: (payload: any) => dispatch(destroyFormAction(payload)),
    }),
    [dispatch]
  );
  const state = useSelector(
    state => ({
      milestonesForm: getCombinedForm(submittedIds.map((id: any) => FORM + id))(
        state
      ),
      milestone: getSliceState('milestone')(state),
    }),
    isEqual
  );
  return { actions, state };
};
