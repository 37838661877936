import React, {
  useCallback,
  useRef,
  useEffect,
  useState,
  useMemo,
} from 'react';
import { debounce, isEqual } from 'lodash';

// redux hooks
import { useDispatch, useSelector } from 'react-redux';
import useInfiniteScroll from 'helpers/hooks/useInfiniteScroll';

// action creators
import {
  fetchMembersForGroup,
  clearMembers,
} from 'store/actions/memberActions';

// selectors
import getCurrentGroupId from 'store/selectors/getCurrentGroupId';
import getSliceState from 'store/selectors/getSliceState';
import getNextPageUrlFromState from 'store/selectors/getNextPageUrlFromState';

// MUI
import { TextField, InputAdornment, Box, IconButton } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from 'assets/types';

// icons
import { Person, CloseSharp } from '@material-ui/icons';

type MemberSelectorProps = {
  input: any; // TODO: PropTypes.instanceOf(Object)
  label?: string;
  placeholder?: string;
  isForFiltering?: boolean;
};

export default function MemberSelector({
  input: { name, onChange, value },
  label = 'Member',
  placeholder = 'Search Members',
  isForFiltering = false,
  ...rest
}: MemberSelectorProps) {
  const classes = useStyles();
  const inputRef = useRef();
  const [open, setOpen] = useState(false);
  const {
    clearMembers,
    fetchMembersForGroup,
    currentGroupId,
    loading,
    data,
    hasNextPage,
    nextPage,
  } = useReduxForm();
  const searchMembers = useCallback(
    text => {
      clearMembers();
      fetchMembersForGroup({
        groupId: currentGroupId,
        search: text,
      });
    },
    [clearMembers, fetchMembersForGroup, currentGroupId]
  );
  const [scrolledToBottom, scrollRef] = useInfiniteScroll(loading, 8);

  const urlParams = new URLSearchParams(nextPage);
  const page = urlParams.get('page');

  const fetchNextPage = useCallback(() => {
    fetchMembersForGroup({
      groupId: currentGroupId,
      page: page,
      appendResult: true,
    });
  }, [fetchMembersForGroup, page, currentGroupId]);

  useEffect(() => {
    if (hasNextPage && scrolledToBottom) fetchNextPage();
  }, [hasNextPage, scrolledToBottom, fetchNextPage]);

  const setFocus = useCallback(
    focusState => () =>
      // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
      focusState ? inputRef.current.focus() : inputRef.current.blur(),
    [inputRef]
  );
  const handleInputChange = useCallback(
    (_, input, reason) => reason === 'input' && searchMembers(input),
    [searchMembers]
  );
  const handleOpen = useCallback(() => {
    searchMembers(value ? value.label : '');
    setOpen(true);
  }, [searchMembers, value, setOpen]);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const handleValueChange = useCallback(
    (_, value) => {
      onChange(value);
    },
    [onChange]
  );
  const clear = useCallback(
    event => {
      event.stopPropagation();
      // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
      inputRef.current.blur();
      onChange(null);
    },
    [onChange, inputRef]
  );
  return (
    <Autocomplete
      open={open}
      onOpen={handleOpen}
      onClose={handleClose}
      forcePopupIcon={false}
      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ open: boolean; forcePopupIcon: false; disa... Remove this comment to see the full error message
      disableOpenOnFocus
      blurOnSelect
      getOptionLabel={option => (option && option.label) || ''}
      getOptionSelected={options => options}
      options={
        isForFiltering
          ? [
              { value: 'none', label: 'No Main Contact' },
              ...data.map(mapMemberSuggestion),
            ]
          : data.map(mapMemberSuggestion)
      }
      loading={loading}
      onChange={handleValueChange}
      value={value}
      onInputChange={debounce(handleInputChange, 500)}
      classes={{ paper: classes.paper, listbox: classes.listBox }}
      ListboxProps={{
        role: 'listbox',
        ref: scrollRef,
      }}
      renderInput={params => (
        <TextField
          {...params}
          {...rest}
          onClick={handleOpen}
          onBlur={handleClose}
          inputRef={inputRef}
          name={name}
          label={label}
          placeholder={placeholder}
          variant={'outlined'}
          InputProps={{
            ...params.InputProps,
            // @ts-expect-error ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'string | un... Remove this comment to see the full error message
            className: null,
            startAdornment: (
              // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
              <InputAdornment>
                <Box marginRight={1}>
                  <Person />
                </Box>
              </InputAdornment>
            ),
            endAdornment: value && (
              // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
              <InputAdornment>
                <IconButton
                  id={'clear-member'}
                  onMouseEnter={setFocus(true)}
                  onMouseLeave={setFocus(false)}
                  onClick={clear}
                >
                  <CloseSharp />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      )}
    />
  );
}

const useReduxForm = () => {
  const dispatch = useDispatch();
  const actions = useMemo(
    () => ({
      clearMembers: () => dispatch(clearMembers()),
      fetchMembersForGroup: (payload: any) =>
        dispatch(fetchMembersForGroup(payload)),
    }),
    [dispatch]
  );
  const data = useSelector(
    state => ({
      currentGroupId: getCurrentGroupId(state),
      ...getSliceState('member')(state),
      hasNextPage: Boolean(getNextPageUrlFromState('member')(state)),
      nextPage: getNextPageUrlFromState('member')(state),
    }),
    isEqual
  );
  return { ...actions, ...data };
};
const useStyles = makeStyles(theme => {
  return {
    paper: {
      backgroundColor: (theme as Theme).palette.background.lightPaper,
    },
    listBox: {
      maxHeight: '324px',
    },
  };
});

const mapMemberSuggestion = (member: any) => {
  const { account } = member;
  if (account) {
    return {
      value: account.id,
      label: `${account.firstName} ${account.lastName}`,
    };
  }
};
