export default function getLoadingKey(type: any) {
  switch (type) {
    default:
    case 1:
      return 'standard';
    case 2:
      return 'greekChapter';
    case 3:
      return 'greekOrganization';
    case 4:
      return 'council';
  }
}
