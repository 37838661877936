import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { client, Resource } from 'api';
import getCurrentGroupId from 'store/selectors/getCurrentGroupId';

export function useGroupsQuery() {
  const params = {
    linked_parent_id: useSelector(getCurrentGroupId),
  };
  return useQuery<
    {
      total: number;
    },
    AxiosError,
    {
      total: number;
    }
  >(
    [Resource.Groups, params],
    () => client.get(Resource.Groups, { params }).then(({ data }) => data),
    {
      select: data => {
        return {
          total: data.total,
        };
      },
    }
  );
}
