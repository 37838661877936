import {
  AUTH_INTEGRATION_CREATE_REQUEST,
  AUTH_INTEGRATION_DELETE_REQUEST,
  AUTH_INTEGRATION_UPDATE_REQUEST,
} from 'store/constants/authIntegrationTypes';

export function createIntegration({
  type,
  name,
  label,
  clientId,
  clientSecret,
  scope,
  revokeTokenType,
  jwksUri,
  discoveryUri,
  issuer,
  authorizationEndpoint,
  tokenEndpoint,
  userinfoEndpoint,
  introspectionEndpoint,
  revocationEndpoint,
  signoutEndpoint,
  roleField,
  councilField,
  organizationField,
  firstNameField,
  lastNameField,
  emailField,
  pictureField,
}: AuthIntegration) {
  return {
    type: AUTH_INTEGRATION_CREATE_REQUEST,
    payload: {
      type,
      name,
      label,
      client_id: clientId,
      client_secret: clientSecret,
      scope,
      revoke_token_type: revokeTokenType,
      jwks_uri: jwksUri,
      discovery_uri: discoveryUri,
      issuer,
      authorization_endpoint: authorizationEndpoint,
      token_endpoint: tokenEndpoint,
      userinfo_endpoint: userinfoEndpoint,
      introspection_endpoint: introspectionEndpoint,
      revocation_endpoint: revocationEndpoint,
      signout_endpoint: signoutEndpoint,
      role_field: roleField,
      council_field: councilField,
      organization_field: organizationField,
      first_name_field: firstNameField,
      last_name_field: lastNameField,
      email_field: emailField,
      picture_field: pictureField,
    },
  };
}

export function updateIntegration({
  id,
  type,
  name,
  label,
  clientId,
  clientSecret,
  scope,
  revokeTokenType,
  jwksUri,
  discoveryUri,
  issuer,
  authorizationEndpoint,
  tokenEndpoint,
  userinfoEndpoint,
  introspectionEndpoint,
  revocationEndpoint,
  signoutEndpoint,
  roleField,
  councilField,
  organizationField,
  firstNameField,
  lastNameField,
  emailField,
  pictureField,
}: AuthIntegration) {
  return {
    type: AUTH_INTEGRATION_UPDATE_REQUEST,
    payload: {
      id,
      type,
      name,
      label,
      client_id: clientId,
      client_secret: clientSecret,
      scope,
      revoke_token_type: revokeTokenType,
      jwks_uri: jwksUri,
      discovery_uri: discoveryUri,
      issuer,
      authorization_endpoint: authorizationEndpoint,
      token_endpoint: tokenEndpoint,
      userinfo_endpoint: userinfoEndpoint,
      introspection_endpoint: introspectionEndpoint,
      revocation_endpoint: revocationEndpoint,
      signout_endpoint: signoutEndpoint,
      role_field: roleField,
      council_field: councilField,
      organization_field: organizationField,
      first_name_field: firstNameField,
      last_name_field: lastNameField,
      email_field: emailField,
      picture_field: pictureField,
    },
  };
}

export function deleteIntegration({ id }: { id: number }) {
  return { type: AUTH_INTEGRATION_DELETE_REQUEST, payload: { id } };
}
