import { Map, List } from 'immutable';

/*
 *  This handles removing a single item from a slice when given a
 *  response.
 */
export default function remove(map: any, action: any, options = {}) {
  const { payload } = action;

  let newState = map;
  const entity = payload.get('entity');

  const {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'byAttribute' does not exist on type '{}'... Remove this comment to see the full error message
    byAttribute,
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'result' does not exist on type '{}'.
    result: resultKey = 'result',
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'updateResult' does not exist on type '{}... Remove this comment to see the full error message
    updateResult = true,
  } = options;

  // Handles payloads that don't have the id directly on the state
  // Ex) Using the remove callback on an endpoint that has results
  const nestedItem =
    payload.getIn(['data', 'entities', entity], Map()).first() || Map();

  const id = payload.get('id', '') || nestedItem.get('id', '');

  const oldResult = map.getIn(['data', resultKey], List());
  const itemsById = newState.getIn(['data', 'byId'], Map());

  if (byAttribute) {
    const itemsById = newState.getIn(['data', 'byId'], Map());

    const itemsByAttribute = itemsById.filter(
      (value: any) => value.get(byAttribute)?.toString() !== id
    );
    newState = newState.setIn(['data', 'byId'], itemsByAttribute);
    // Keep item in the results, if the item was found
    let newResult = oldResult.filter((value: any) =>
      itemsByAttribute.has(value.toString())
    );
    newState = newState.setIn(['data', resultKey], newResult);
  } else {
    const oldTotal = newState.getIn(['meta', 'total'], 0);
    const newTotal = oldTotal - 1;

    newState = newState.setIn(['meta', 'total'], newTotal);

    let newResult = List();
    const indexToDelete = oldResult.findIndex((item: any) => {
      return item.toString() === id.toString();
    });

    // Remove item from results, if the item was found
    newResult =
      indexToDelete !== -1 ? oldResult.delete(indexToDelete) : oldResult;

    if (updateResult) {
      // Replace the result if `updateResult` is `true`
      newState = newState.setIn(['data', resultKey], newResult);
    }

    // Merge the new items with the states original `byId` map
    newState = newState.setIn(
      ['data', 'byId'],
      itemsById.delete(id.toString())
    );
  }

  return newState;
}
