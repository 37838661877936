import React, {
  useMemo,
  useState,
  useCallback,
  Dispatch,
  SetStateAction,
} from 'react';
import classNames from 'classnames';
import {
  CardElement,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from '@stripe/react-stripe-js';
import { StripeCardElementOptions } from '@stripe/stripe-js';
// material-ui
import { CircularProgress, useTheme, makeStyles } from '@material-ui/core';
// helpers
import { Theme } from 'assets/theme';

type Props = {
  input: any; // TODO: PropTypes.instanceOf(Object)
  areFieldsSeparately?: boolean;
  borderSchemeColor?: 'grey' | 'default';
  baseTextColor?: string;
  basePlaceholderColor?: string;
};

export default function CardField({
  input,
  areFieldsSeparately,
  borderSchemeColor,
  baseTextColor,
  basePlaceholderColor,
}: Props) {
  const classes = useStyles();
  const theme = useTheme();
  const [isCardNumberReady, setIsCardNumberReady] = useState<boolean>(false);
  const [isCardExpiryReady, setIsCardExpiryReady] = useState<boolean>(false);
  const [isCardCvcReady, setIsCardCvcReady] = useState<boolean>(false);

  const handleCardReady = useCallback(
    (
      setFunction: Dispatch<SetStateAction<boolean>>,
      value: boolean = true
    ) => () => {
      setFunction(value);
    },
    []
  );

  const isCardLoading =
    !isCardNumberReady || !isCardExpiryReady || !isCardCvcReady;

  const cardOptions = useMemo<StripeCardElementOptions>(
    () => ({
      iconStyle: 'solid',
      style: {
        base: {
          iconColor: baseTextColor || theme.palette.common.white,
          color: baseTextColor || theme.palette.common.white,
          fontWeight: 400,
          fontFamily: 'Poppins, sans-serif',
          fontSize: '16px',
          fontSmoothing: 'antialiased',
          ':-webkit-autofill': {
            color: theme.palette.secondary.light,
          },
          '::placeholder': {
            color: basePlaceholderColor || theme.palette.grey['A200'],
          },
        },
        invalid: {
          iconColor: theme.palette.error.main,
          color: theme.palette.error.main,
        },
      },
    }),
    [baseTextColor, basePlaceholderColor, theme]
  );

  if (areFieldsSeparately) {
    return (
      <div className={classNames(classes.cardBox, borderSchemeColor || '')}>
        {isCardLoading ? (
          <div className={classes.loading}>
            <CircularProgress size={40} />
          </div>
        ) : null}
        <CardNumberElement
          className={classes.cardNumberElement}
          options={{
            ...cardOptions,
            showIcon: true,
          }}
          onChange={input.onChange}
          onReady={handleCardReady(setIsCardNumberReady)}
        />
        <div className={classNames(classes.line, borderSchemeColor || '')} />
        <div className={classes.cardInnerBox}>
          <div className={classes.cardInner}>
            <CardExpiryElement
              className={classes.cardExpiryElement}
              options={{
                style: { ...cardOptions.style },
              }}
              onChange={input.onChange}
              onReady={handleCardReady(setIsCardExpiryReady)}
            />
          </div>
          <div
            className={classNames(
              classes.line,
              'verticalLine',
              borderSchemeColor || ''
            )}
          />
          <div className={classes.cardInner}>
            <CardCvcElement
              className={classes.cardExpiryElement}
              options={{
                style: { ...cardOptions.style },
              }}
              onChange={input.onChange}
              onReady={handleCardReady(setIsCardCvcReady)}
            />
          </div>
        </div>
      </div>
    );
  }

  return (
    <CardElement
      className={classNames(classes.cardElement, borderSchemeColor || '')}
      options={cardOptions}
      onChange={input.onChange}
    />
  );
}

const useStyles = makeStyles((theme: Theme) => {
  const inputColor = theme.palette.background.outline;
  const border = `1px solid ${inputColor}`;
  const greyStyle = {
    borderColor: theme.palette.grey[300],
  };
  const separatelyCardElementStyles = {
    width: '100%',
    padding: 16,
  };

  return {
    cardBox: {
      position: 'relative',
      display: 'flex',
      flexWrap: 'wrap',
      width: '100%',
      border,
      borderRadius: 4,
      '&.grey': greyStyle,
    },
    cardInnerBox: {
      display: 'flex',
      flexWrap: 'nowrap',
      width: '100%',
    },
    cardInner: {
      width: '49%',
    },
    cardNumberElement: separatelyCardElementStyles,
    cardExpiryElement: separatelyCardElementStyles,
    line: {
      width: '100%',
      height: 0,
      borderTop: border,
      '&.verticalLine': {
        width: 0,
        height: '100%',
        borderTop: 0,
        borderRight: border,
      },
      '&.grey': greyStyle,
    },
    cardElement: {
      border,
      borderRadius: 4,
      padding: 16,
      '&.grey': greyStyle,
    },
    loading: {
      position: 'absolute',
      display: 'flex',
      width: '100%',
      height: '100%',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: theme.palette.common.white,
    },
  };
});
