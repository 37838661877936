import { List } from 'immutable';
import { PaymentMethodStatus } from 'assets/types';
import { isString } from 'helpers/check';

const getCurrentPaymentStatus = (state: any): PaymentMethodStatus => {
  const paymentMethodStatuses = state.getIn([
    'currentGroup',
    'data',
    'paymentMethodStatus',
  ]);
  if (List.isList(paymentMethodStatuses)) {
    const paymentMethodStatus = paymentMethodStatuses.toJS().pop();
    return isString(paymentMethodStatus)
      ? (paymentMethodStatus as PaymentMethodStatus)
      : 'disabled';
  }
  if (Array.isArray(paymentMethodStatuses)) {
    return paymentMethodStatuses.pop();
  }
  if (isString(paymentMethodStatuses)) {
    return paymentMethodStatuses;
  }
  return 'disabled';
};

export default getCurrentPaymentStatus;
