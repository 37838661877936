import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import Immutable from 'immutable';

// action creators
import { doneIndicator, success } from 'store/actions/httpActions';

// api
import client from 'sources/api';

// constants
import {
  REFERRAL_FORM_DELETE_REQUEST,
  REFERRAL_FORM_DELETE,
} from 'store/constants/referralFormTypes';

// schemas
import { referralFormSchema } from 'store/schemas/referralFormSchema';

// helpers
import httpSaga from 'store/sagas/httpSaga';

const WATCH_TYPE = REFERRAL_FORM_DELETE_REQUEST;
const TYPE = REFERRAL_FORM_DELETE;

export function* deleteReferralForm(action: any) {
  const {
    payload: { formId },
  } = action;

  // @ts-expect-error ts-migrate(2569) FIXME: Type 'Generator<any, any, unknown>' is not an arra... Remove this comment to see the full error message
  yield* httpSaga(TYPE, call(client.delete, `/forms/${formId}`), {
    dispatchSuccess: false,
    metaId: `delete-${formId}`,
    schema: referralFormSchema,
  });

  yield put(
    success(
      TYPE,
      Immutable.fromJS({
        id: formId,
      })
    )
  );

  yield put(doneIndicator(TYPE));
}

export function* watch() {
  yield takeEvery(WATCH_TYPE, deleteReferralForm);
}

export default function* root() {
  yield all([fork(watch)]);
}
