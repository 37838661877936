import React, { useCallback, useContext } from 'react';
import validate from 'helpers/validate';
// hooks
import { useDispatch, useSelector } from 'react-redux';
// material-ui
import {
  Card,
  CardActions,
  CardContent,
  Divider,
  FormHelperText,
  Grid,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { Theme } from 'assets/types';
// icons
import { EditAttributes } from '@material-ui/icons';
// components
import Header from 'components/Header';
import { Form, Field } from 'react-final-form';
import Button from 'components/Button';
import Switch from 'components/Switch';
// action creators
import { updateCurrentUser } from 'store/actions/userActions';
// selectors
import getForm from 'store/selectors/getForm';
import getCurrentUser from 'store/selectors/getCurrentUser';
// helpers
import { SiteVisualDataContext } from 'components/SiteVisualData';

// schemas
const schema = {
  allowEmailNotifications: {
    presence: true,
  },
  allowPushNotifications: {
    presence: true,
  },
};
const FORM = 'updatePreferencesForm';
const PreferencesCard = function() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { preferencesCardFirstLabel, preferencesCardSecondLabel } = useContext(
    SiteVisualDataContext
  );
  const {
    form: { isSubmitting, hasSubmitFailed, hasSubmitSucceeded, formError },
    currentUser,
  } = useSelector(state => ({
    form: getForm(FORM)(state),
    currentUser: getCurrentUser(state),
  }));
  const onSubmit = (values: any) => {
    dispatch(
      updateCurrentUser({
        formName: FORM,
        ...values,
      })
    );
  };
  const getInitialValues = useCallback(() => currentUser.toJS(), [currentUser]);
  return (
    <Grid item sm={12} md={6}>
      <Form
        onSubmit={onSubmit}
        validate={values => validate(values, schema)}
        initialValues={getInitialValues()}
        keepDirtyOnReinitialize={isSubmitting}
        render={({ handleSubmit, invalid, pristine, form: { reset } }) => (
          <Card
            id='preferencesContent'
            component='form'
            onSubmit={handleSubmit}
            className={classes.stretch}
          >
            <Grid
              container
              direction='column'
              className={classes.stretch}
              justifyContent='space-between'
            >
              <Grid item>
                <CardContent>
                  <Header
                    id='header'
                    icon={EditAttributes}
                    title='Preferences'
                  />
                  <Grid container className={classes.outlinedContainer}>
                    <Grid item xs={12}>
                      <Typography color='textSecondary' variant='subtitle2'>
                        Communication
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        name='allowEmailNotifications'
                        label={preferencesCardFirstLabel}
                        type='checkbox'
                        component={Switch}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        name='allowPushNotifications'
                        label={preferencesCardSecondLabel}
                        type='checkbox'
                        component={Switch}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Grid>
              <Grid item>
                <Divider />

                <CardActions>
                  <Button disabled={pristine} onClick={reset}>
                    Cancel
                  </Button>
                  <Button
                    type='submit'
                    color='primary'
                    variant='contained'
                    disabled={pristine || invalid || isSubmitting}
                    loading={isSubmitting}
                    success={hasSubmitSucceeded}
                    fail={hasSubmitFailed}
                  >
                    Save
                  </Button>

                  {formError && (
                    <Grid item xs={12}>
                      <FormHelperText error id='errorMessage'>
                        {formError.get('message')}
                      </FormHelperText>
                    </Grid>
                  )}
                </CardActions>
              </Grid>
            </Grid>
          </Card>
        )}
      />
    </Grid>
  );
};
const useStyles = makeStyles((theme: Theme) => {
  const border = `1px solid ${theme.palette.background.lightPaper}`;
  return {
    outlinedContainer: {
      border,
      borderRadius: 4,
      padding: '32px 16px',
      marginTop: '32px',
    },
    stretch: { height: '100%' },
  };
});
export default PreferencesCard;
