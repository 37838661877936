import React, { useState, useEffect, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Map } from 'immutable';

// material-ui
import {
  withStyles,
  WithStyles,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Skeleton from '@material-ui/lab/Skeleton';
import IconButton from '@material-ui/core/IconButton';

// components
import FileInputField from 'components/FileInputField';
import { Field } from 'react-final-form';

// selectors
import getLoadingFromState from 'store/selectors/getLoadingFromState';

// hooks
import { useReduxForm } from 'store/hooks/useReduxForm';
import { useIsFeatureEnabled } from 'helpers/hooks/featureHooks';

// action creators
import { createAndUploadFile } from 'store/actions/fileActions';

// helpers
import { SiteVisualDataContext } from 'components/SiteVisualData';

//icons
import DeleteIcon from '@material-ui/icons/Delete';

//styles
import formEditorHeaderImageStyle from 'routes/ReferralForm/FormEditorHeaderImage/formEditorHeaderImage.style';

const FORM = 'createAndUploadFileFormHeader';

export interface FormEditorHeaderImageProps
  extends WithStyles<typeof formEditorHeaderImageStyle> {
  formData: Map<string, any>;
  mutators: any;
  isCouncilForm?: boolean | null;
  isOrganizationForm?: boolean | null;
  isUniversalForm?: boolean;
}

const FormEditorHeaderImage = function({
  classes,
  formData,
  isCouncilForm,
  isOrganizationForm,
  isUniversalForm,
  mutators,
}: FormEditorHeaderImageProps) {
  const theme = useTheme();
  const { secondLogo } = useContext(SiteVisualDataContext);
  const dispatch = useDispatch();
  const isMinWidthLg = useMediaQuery(theme.breakpoints.up('lg'));
  const brandedFormsEnabled =
    useIsFeatureEnabled('branded_forms') ||
    !!(isCouncilForm || isOrganizationForm || isUniversalForm);

  const { isSubmitting } = useReduxForm(FORM);

  const { referralFormLoading, fileLoading } = useSelector(state => ({
    fileLoading: getLoadingFromState('file', false, false)(state),
    referralFormLoading: getLoadingFromState('referralForm')(state),
  }));

  const headerPhotoFileData = formData.get('headerPhotoFileId', Map());

  const initialImage = headerPhotoFileData
    ? headerPhotoFileData.get('url')
    : secondLogo;

  // initalize component state
  const [fileId, setFileId] = useState(null);
  const [image, setImage] = useState(initialImage);
  const [isFileConverting, setIsFileConverting] = useState(false);

  const loading =
    referralFormLoading || isSubmitting || fileLoading || isFileConverting;

  useEffect(() => {
    setImage(initialImage);
  }, [initialImage]);

  const dispatchCreateAndUploadFile = (file: any) => {
    dispatch(createAndUploadFile({ file, formName: FORM }));
  };

  const handleFileChange = ({ filePreview, newFileId = fileId }: any) => {
    setImage(filePreview);
    setFileId(newFileId);
  };

  const handleFileConverting = (newIsFileConverting: boolean) => {
    setIsFileConverting(newIsFileConverting);
  };

  const handleDeleteFile = () => {
    setImage(secondLogo);
    setFileId(null);
    mutators.setValue('headerPhotoFileId', null);
  };

  const deleteButton = (isCouncilForm ||
    isOrganizationForm ||
    isUniversalForm) &&
    !!(headerPhotoFileData || fileId) && (
      <IconButton
        className={`${classes.deleteButton}${!isMinWidthLg ? ' beforeLg' : ''}`}
        size='small'
        onClick={handleDeleteFile}
        disabled={loading}
      >
        <DeleteIcon />
      </IconButton>
    );

  return (
    <Grid
      item
      xs={12}
      justifyContent='center'
      className={classes.headerImageContainer}
    >
      {loading ? (
        <Skeleton
          id='headerImageSkeleton'
          variant='rect'
          height={110}
          width={330}
          className={classes.headerImageSkeleton}
        />
      ) : (
        <Grid container spacing={2} alignItems='center'>
          <Grid
            item
            xs={12}
            lg={brandedFormsEnabled ? 6 : 12}
            className={`${classes.alignCenter}${
              brandedFormsEnabled ? ' rightOnLg' : ''
            }`}
          >
            <img
              id='headerImage'
              src={image}
              className={classes.headerImage}
              alt='Form Header'
            />
            {!isMinWidthLg && brandedFormsEnabled && deleteButton}
          </Grid>
          {brandedFormsEnabled && (
            <Grid
              item
              xs={12}
              lg={6}
              id='headerImageFileInput'
              className={`${classes.alignCenter} leftOnLg`}
            >
              <Field
                name='headerPhotoFileId'
                render={props => (
                  <FileInputField
                    // @ts-expect-error ts-migrate(2322) FIXME: Type '{ input: FieldInputProps<any, HTMLElement>; ... Remove this comment to see the full error message
                    fileType='image'
                    formName={FORM}
                    onChange={handleFileChange}
                    onSubmit={dispatchCreateAndUploadFile}
                    buttonLabel='Upload Form Picture'
                    onFileConverting={handleFileConverting}
                    {...props}
                  />
                )}
              />
              {isMinWidthLg && deleteButton}
            </Grid>
          )}
        </Grid>
      )}
    </Grid>
  );
};

export default withStyles(formEditorHeaderImageStyle, { withTheme: true })(
  FormEditorHeaderImage
);
