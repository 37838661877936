import {
  GROUP_VALUE_DELETE_REQUEST,
  GROUP_VALUE_CREATE_REQUEST,
  GROUP_VALUE_FETCH_REQUEST,
} from 'store/constants/groupValueTypes';

export function createGroupValue({
  groupId,
  formName,
  selectedField: { label },
  description,
}: any) {
  return {
    type: GROUP_VALUE_CREATE_REQUEST,
    payload: { groupId, formName, label, description },
  };
}

export function deleteGroupValue({ label, groupId }: any) {
  return {
    type: GROUP_VALUE_DELETE_REQUEST,
    payload: { label, groupId },
  };
}

export function fetchGroupValues({ groupId }: any) {
  return {
    type: GROUP_VALUE_FETCH_REQUEST,
    payload: { groupId },
  };
}
