import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

// components
import Landing from 'layout/Landing';
import { Link as RouterLink, useHistory, useParams } from 'react-router-dom';

// material-ui
import { Link, Grid, Typography, CircularProgress } from '@material-ui/core';

// action creators
import { verifyUserEmail } from 'store/actions/userActions';

// selectors
import stateHasError from 'store/selectors/stateHasError';
import getResponseMessage from 'store/selectors/getResponseMessage';
import getLoadingFromState from 'store/selectors/getLoadingFromState';

const EmailVerification = function() {
  const history = useHistory();
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'token' does not exist on type '{}'.
  const { token } = useParams();
  const dispatch = useDispatch();

  const { error, successMessage, loading } = useSelector(state => ({
    error: stateHasError('user')(state),
    successMessage: getResponseMessage('user')(state),
    loading: getLoadingFromState('user')(state),
  }));
  useEffect(() => {
    if (token) {
      dispatch(verifyUserEmail(token, history));
    }
  }, [token, history, dispatch]);

  return (
    <Landing>
      <Grid item>
        <Typography variant='h4'>Verify Email</Typography>
        {loading && (
          <Grid container spacing={1} justifyContent='center'>
            <Grid item component={Typography} variant='body1' display='inline'>
              We are verifying your email, this could take a moment.
            </Grid>

            <Grid item component={CircularProgress} color='secondary' />
          </Grid>
        )}

        {!loading && !error && (
          <Typography variant='body1'>
            {successMessage} You should be redirected to the login screen in 5
            seconds. If you are not redirected automatically, please{' '}
            <Link
              component={RouterLink}
              to='/login'
              color='textPrimary'
              variant='subtitle2'
              display='inline'
            >
              <strong>click here</strong>
            </Link>
            .
          </Typography>
        )}

        {!loading && error && (
          <Typography variant='body1'>
            We are unable to verify your email, please contact support.
          </Typography>
        )}
      </Grid>
    </Landing>
  );
};

EmailVerification.propTypes = {
  match: PropTypes.instanceOf(Object).isRequired,
  verifyUserEmail: PropTypes.func.isRequired,
  history: PropTypes.instanceOf(Object).isRequired,
  successMessage: PropTypes.string,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.bool.isRequired,
};

EmailVerification.defaultProps = {
  match: { params: {} },
};

export default EmailVerification;
