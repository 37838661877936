import PropTypes from 'prop-types';
import React from 'react';

// components
import FormHelperText from '@material-ui/core/FormHelperText';
import MuiCheckbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const Checkbox = function({
  label,
  id,
  errorClassName,
  input,
  meta: { active, dirty, error, touched },
  ...rest
}: any) {
  const shouldDisplayError = () => {
    return error && !active && (dirty || touched);
  };

  const renderError = () => {
    let element;

    if (shouldDisplayError()) {
      element = (
        <FormHelperText
          id='checkboxErrorMessage'
          className={errorClassName}
          error
        >
          {error[0]}
        </FormHelperText>
      );
    }

    return element;
  };

  const disableParentClick = (event: any) => {
    event.stopPropagation();
  };

  return (
    <FormControl>
      <FormControlLabel
        control={
          <MuiCheckbox
            checked={input.checked}
            onChange={input.onChange(input.checked)}
            onClick={disableParentClick}
            id={id}
            color='primary'
            error={shouldDisplayError()}
            inputProps={{ id, ...input }}
            {...rest}
          />
        }
        label={label}
      />

      {renderError()}
    </FormControl>
  );
};

Checkbox.propTypes = {
  errorClassName: PropTypes.string,
  input: PropTypes.instanceOf(Object).isRequired,
  meta: PropTypes.instanceOf(Object).isRequired,
  id: PropTypes.string,
  label: PropTypes.string,
};

Checkbox.defaultProps = {
  errorClassName: '',
  id: '',
  label: '',
};

export default Checkbox;
