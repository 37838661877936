import { schema } from 'normalizr';
import { roleSchema } from './roleSchema';
import { groupSchema } from './groupSchema';

export const currentGroupSchema = new schema.Entity('currentGroup');

currentGroupSchema.define({
  roles: [roleSchema],
  parents: [groupSchema],
});
