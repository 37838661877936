import Immutable, { Map, List } from 'immutable';
// constants
import {
  CURRENT_USER_ADD,
  CURRENT_USER_UPDATE,
  CURRENT_USER_FETCH,
} from 'store/constants/userTypes';
import { GROUP_CREATE } from 'store/constants/groupTypes';
import {
  EMAIL_CREATE,
  EMAIL_DELETE,
  EMAIL_MAKE_PRIMARY,
} from 'store/constants/emailTypes';
// helpers
import httpReducer from './helpers/httpReducer';

const addEmail = (map: any, { payload = Map() }) => {
  return map.setIn(
    ['data', 'emails'],
    map.getIn(['data', 'emails'], List()).push(payload.get('data'))
  );
};

const deleteEmail = (map: any, { payload = Map() }) => {
  const id = payload.get('id');
  return map.deleteIn([
    'data',
    'emails',
    map
      .getIn(['data', 'emails'])
      .findIndex((item: any) => item.get('id') === id),
  ]);
};

const makeEmailPrimary = (map: any, { payload = Map() }: any) => {
  const id = payload.get('id');
  let newEmails = map.getIn(['data', 'emails']);
  const currentPrimaryIndex = newEmails.findIndex((item: any) =>
    item.get('primary')
  );
  const currentPrimary = newEmails.get(currentPrimaryIndex);
  const newPrimaryIndex = newEmails.findIndex(
    (item: any) => item.get('id') === id
  );
  const newPrimary = newEmails.get(newPrimaryIndex);
  // Set `primary` to `false` on the old primary email
  newEmails = newEmails.set(
    currentPrimaryIndex,
    currentPrimary.set('primary', false)
  );
  // Set `primary` to `true` on the new primary email
  newEmails = newEmails.set(newPrimaryIndex, newPrimary.set('primary', true));
  return map.setIn(['data', 'emails'], newEmails);
};

const updateGroupIds = (map: any, { payload = Map() }: any) => {
  const newGroupId = payload
    .getIn(['data', 'entities', 'group'], Map())
    .first(Map())
    .get('id');
  return map.setIn(['data', 'groupIds'], List([newGroupId]));
};

export default function reduceCurrentUser(state = Map(), action = {}) {
  switch ((action as any).type) {
    case CURRENT_USER_ADD:
      return state.set('data', Immutable.fromJS((action as any).payload));
    case CURRENT_USER_UPDATE:
      return httpReducer(state, action, {
        mergeItems: true,
        updateResult: false,
      });
    case CURRENT_USER_FETCH:
      return httpReducer(state, action, {
        updateResult: false,
        mergeItems: false,
      });
    case EMAIL_CREATE:
      return httpReducer(state, action, {
        callback: addEmail,
      });
    case EMAIL_DELETE:
      return httpReducer(state, action, { callback: deleteEmail });
    case EMAIL_MAKE_PRIMARY:
      return httpReducer(state, action, { callback: makeEmailPrimary });
    case GROUP_CREATE:
      return updateGroupIds(state, action);
    default:
      return state;
  }
}
