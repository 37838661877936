import React from 'react';
import { Map } from 'immutable';
import pluralize from 'pluralize';
// hooks
import {
  useFeatureLimitRemaining,
  useFeatureHasLimit,
} from 'helpers/hooks/featureHooks';
import { useSelector } from 'react-redux';
// material-ui
import { Grid, Card, CardContent, Box, Hidden } from '@material-ui/core';
// icons
import { LinearScale } from '@material-ui/icons';
// components
import Header from 'components/Header';
import MilestonesForm from './MilestonesForm';
export default function MilestonesSettings() {
  const milestoneSize = useSelector(
    state => (state as any).getIn(['milestone', 'data', 'byId'], Map()).size
  );
  const remainingMilestones = useFeatureLimitRemaining(
    'custom_milestones',
    milestoneSize
  );
  const milestonesHaveLimit = useFeatureHasLimit('custom_milestones');
  let highlightedSubtitle = '';
  if (milestonesHaveLimit) {
    if (remainingMilestones > 0) {
      highlightedSubtitle = pluralize(
        `You may create ${remainingMilestones} more milestones`,
        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'number | true' is not assignable... Remove this comment to see the full error message
        remainingMilestones
      );
    } else {
      highlightedSubtitle =
        'You are out of custom milestones. Delete milestones or upgrade for more.';
    }
  }
  const headerProps = {
    id: 'elevio_milestones_overview',
    icon: LinearScale,
    title: 'Manage Milestones',
    subtitle:
      'Create milestone tags to track steps in your recruitment process for leads',
    highlightedSubtitle,
  };
  return (
    <Grid item xs>
      <Card
        id='tabContent'
        style={{
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
        }}
      >
        <CardContent>
          <Grid container>
            <Grid item xs>
              <Header {...headerProps} />
            </Grid>

            <Hidden xsDown>
              <Box width={75} />
            </Hidden>

            <Grid item xs={12}>
              <MilestonesForm />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
}
