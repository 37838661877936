import { connect } from 'react-redux';

// components
import EmailsForm from './EmailsForm';

// action creators
import { createEmail } from 'store/actions/emailActions';

const mapDispatch = { createEmail };

export default connect(null, mapDispatch)(EmailsForm);
