import { all, call, fork, put, takeEvery, take } from 'redux-saga/effects';
import Immutable from 'immutable';
import moment from 'moment-timezone';

// action creators
import { doneIndicator, success } from 'store/actions/httpActions';
import { createEventGuestsMessagesAction } from 'store/actions/apiTaskActions';
import { setFormSubmitSucceeded } from 'store/actions/formActions';

// api
import client from 'sources/api';

// constants
import {
  EVENT_GUEST_CREATE_REQUEST,
  EVENT_GUEST_CREATE,
} from 'store/constants/eventGuestTypes';

// schemas
import { eventGuestSchema } from 'store/schemas/eventGuestSchema';

// helpers
import httpSaga from 'store/sagas/httpSaga';
import getResponsePayload from 'helpers/getResponsePayload';

const WATCH_TYPE = EVENT_GUEST_CREATE_REQUEST;
const TYPE = EVENT_GUEST_CREATE;

export function* createEventGuestsSaga(action: any) {
  const {
    payload: {
      formName,
      groupId,
      eventId,
      leadIds,
      accountIds,
      leadFilters,
      memberFilters,
      invited,
      rsvp,
      eventTimeSlotId: event_time_slot_id,
      attended,
      emailParams: { subject, emailBody } = { subject: null, emailBody: null },
      textMessageParams: { textMessageBody } = { textMessageBody: null },
    },
  } = action;

  // Create guest records for event
  // @ts-expect-error ts-migrate(2569) FIXME: Type 'Generator<any, any, unknown>' is not an arra... Remove this comment to see the full error message
  const response = yield* httpSaga(
    TYPE,
    call(client.post, `/event_guests?group_id=${groupId}`, {
      event_id: eventId,
      pnm_ids: leadIds,
      account_ids: accountIds,
      invited,
      rsvp,
      event_time_slot_id,
      attended,
      timezone_name: moment.tz.guess(),
      ...leadFilters,
      ...memberFilters,
    }),
    {
      dispatchSuccess: false,
      dispatchFormSuccess: false,
      schema: eventGuestSchema,
      formName,
    }
  );

  if (emailBody) {
    // Send email invitations
    yield put(
      createEventGuestsMessagesAction({
        formName,
        groupId,
        eventId,
        leadIds,
        accountIds,
        leadFilters,
        memberFilters,
        body: emailBody,
        subject,
        threadType: 'email',
      })
    );
    yield take('API_TASK_CREATE_EVENT_GUEST_MESSAGE_DONE');
  }

  if (textMessageBody) {
    // Send text message invitations
    yield put(
      createEventGuestsMessagesAction({
        formName,
        groupId,
        eventId,
        leadIds,
        accountIds,
        leadFilters,
        memberFilters,
        body: textMessageBody,
        subject,
        threadType: 'text',
      })
    );
    yield take('API_TASK_CREATE_EVENT_GUEST_MESSAGE_DONE');
  }

  // Manually set the payload and wrap up
  let payload;
  if (response) {
    payload = getResponsePayload(response, [eventGuestSchema]);
  }
  yield put(success(TYPE, Immutable.fromJS(payload)));
  yield put(setFormSubmitSucceeded(formName, 'Invitations sent successfully'));

  yield put(doneIndicator(TYPE));
}

export function* watch() {
  yield takeEvery(WATCH_TYPE, createEventGuestsSaga);
}

export default function* root() {
  yield all([fork(watch)]);
}
