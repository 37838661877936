import Immutable, { List, Map } from 'immutable';
import { denormalize } from 'normalizr';

import { taskSchema } from '../schemas/taskSchema';
import createDeepEqualSelector from 'helpers/createDeepEqualSelector';

const getResult = (state: any) =>
  state.getIn(['task', 'data', 'result'], List());
const getTaskSlice = (state: any) =>
  state.getIn(['task', 'data', 'byId'], Map());
const getMemberSlice = (state: any) =>
  state.getIn(['member', 'data', 'byId'], Map());
const getLeadSlice = (state: any) =>
  state.getIn(['lead', 'data', 'byId'], Map());

export default createDeepEqualSelector(
  [getResult, getTaskSlice, getMemberSlice, getLeadSlice],
  (result: any, taskSlice: any, memberSlice: any, leadSlice: any) => {
    const entities = {
      task: taskSlice.toJS(),
      member: memberSlice.toJS(),
      lead: leadSlice.toJS(),
    };

    let items = denormalize(result.toJS(), [taskSchema], entities);

    return Immutable.fromJS(items).sortBy((item: any) => item.get('dueOn'));
  }
);
