import { Map } from 'immutable';
// constants
import {
  FORM_FIELD_CREATE,
  FORM_FIELD_DELETE,
  FORM_FIELD_FETCH,
  FORM_FIELD_CLEAR,
  FORM_FIELD_UPDATE_MANY,
} from 'store/constants/formFieldTypes';
// helpers
import httpReducer from 'store/reducers/helpers/httpReducer';
import removeFirstFieldByAttribute from 'store/reducers/callbacks/removeFirstFieldByAttribute';

export default function reduceFormField(state = Map(), action = {}) {
  switch ((action as any).type) {
    case FORM_FIELD_DELETE:
      return httpReducer(state, action, {
        byAttribute: 'label',
        callback: removeFirstFieldByAttribute,
      });
    case FORM_FIELD_CREATE:
      return httpReducer(state, action, {
        entity: 'formField',
        updateResult: true,
        mergeItems: true,
        disableMergeDeep: true,
        appendResult: true,
      });
    case FORM_FIELD_UPDATE_MANY:
      return httpReducer(state, action, {
        entity: 'formField',
        updateResult: true,
        mergeItems: true,
        disableMergeDeep: true,
        appendResult: true,
      });
    case FORM_FIELD_FETCH:
      return httpReducer(state, action, {
        entity: 'formField',
        updateResult: true,
        mergeItems: true,
        disableMergeDeep: true,
        appendResult: true,
      });
    case FORM_FIELD_CLEAR:
      return Map();
    default:
      return state;
  }
}
