import { UPDATE_LEAD_SHARING_REQUEST } from 'store/constants/communitySettingsTypes';

export function updateLeadSharing({
  formName,
  pushLead,
  groupId,
}: {
  formName: string;
  pushLead: string;
  groupId?: number;
}) {
  return {
    type: UPDATE_LEAD_SHARING_REQUEST,
    payload: {
      formName,
      pushLead,
      groupId,
    },
  };
}
