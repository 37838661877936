import { all, call, fork, put, takeEvery, select } from 'redux-saga/effects';
import { success, successIndicator } from 'store/actions/httpActions';
import Immutable from 'immutable';

// action creators
import { doneIndicator } from 'store/actions/httpActions';

// api
import client from 'sources/api';

// schemas

// constants
import {
  GROUP_FEATURE_USAGE_FETCH,
  GROUP_FEATURE_USAGE_FETCH_REQUEST,
} from 'store/constants/groupFeatureUsageTypes';
import {
  PHONE_NUMBER_CREATE_DONE,
  PHONE_NUMBER_DELETE_DONE,
} from 'store/constants/phoneNumberTypes';

// helpers
import httpSaga from 'store/sagas/httpSaga';
import getCurrentGroupId from 'store/selectors/getCurrentGroupId';

const WATCH_TYPE = [
  GROUP_FEATURE_USAGE_FETCH_REQUEST,
  PHONE_NUMBER_CREATE_DONE,
  PHONE_NUMBER_DELETE_DONE,
];
const TYPE = GROUP_FEATURE_USAGE_FETCH;

export function* fetchGroupFeatureUsageSaga() {
  const group_id = yield select(getCurrentGroupId);

  // @ts-expect-error ts-migrate(2569) FIXME: Type 'Generator<any, any, unknown>' is not an arra... Remove this comment to see the full error message
  const response = yield* httpSaga(
    TYPE,
    call(client.get, '/group_features_usage', {
      params: { group_id },
    }),
    { dispatchSuccess: false }
  );

  const groupFeatureUsage = response?.data;

  yield put(successIndicator(TYPE));
  yield put(success(TYPE, Immutable.fromJS(groupFeatureUsage)));

  yield put(doneIndicator(TYPE));
}

export function* watch() {
  yield takeEvery(WATCH_TYPE, fetchGroupFeatureUsageSaga);
}

export default function* root() {
  yield all([fork(watch)]);
}
