import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import PropTypes from 'prop-types';
import { Map } from 'immutable';

// material-ui
import Grid from '@material-ui/core/Grid';

// action creators
import { fetchInvitesForGroup } from 'store/actions/inviteActions';

// components
import ListCard from 'components/ListCard';
import PendingInviteItem from './PendingInviteItem';

// icons
import PeopleIcon from '@material-ui/icons/PeopleOutlined';

// selectors
import getInvites from 'store/selectors/getInvites';
import getLoadingFromState from 'store/selectors/getLoadingFromState';
import getNextPageUrlFromState from 'store/selectors/getNextPageUrlFromState';
import getTotal from 'store/selectors/getTotal';

const PendingInvitesCard = function({ currentGroup }: any) {
  const dispatch = useDispatch();
  const [searchValue, setSearchValue] = useState('');
  const [timerId, setTimerId] = useState(null);
  const currentGroupId = currentGroup.get('id');

  useEffect(() => {
    if (currentGroupId) {
      dispatch(fetchInvitesForGroup({ groupId: currentGroupId }));
    }
  }, [currentGroupId, dispatch]);

  const { invites, loading, hasNextPage, totalInvites } = useSelector(
    state => ({
      invites: getInvites(state),
      loading: getLoadingFromState('invite', false, false)(state),
      hasNextPage: Boolean(getNextPageUrlFromState('invite')(state)),
      totalInvites: getTotal('invite')(state),
    }),
    shallowEqual
  );

  const renderItems = () => {
    return invites.map((invite: any) => <PendingInviteItem invite={invite} />);
  };

  const fetchItems = (page: any) => {
    dispatch(
      fetchInvitesForGroup({
        groupId: currentGroupId,
        page,
        search: searchValue,
      })
    );
  };

  const handleSearchChange = ({ target: { value } }: any) => {
    if (currentGroupId && (value || searchValue)) {
      if (timerId) {
        // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
        clearTimeout(timerId);
      }

      setTimerId(
        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'number' is not assignable to par... Remove this comment to see the full error message
        setTimeout(
          () =>
            dispatch(
              fetchInvitesForGroup({
                groupId: currentGroupId,
                page: 1,
                search: value,
              })
            ),
          1000
        )
      );
    }

    setSearchValue(value);
  };

  return (
    <Grid item sm={12} md={6}>
      <ListCard
        title='Invited Members'
        subtitle='Manage invited members of your team'
        searchLabel='Search Invites'
        searchPlaceholder='Enter email'
        headerIcon={PeopleIcon}
        items={renderItems()}
        loading={loading}
        onSearchChange={handleSearchChange}
        paginationProps={{
          disableNextPage: !hasNextPage,
          onPageChange: fetchItems,
          total: totalInvites,
          items: invites,
          loading,
        }}
      />
    </Grid>
  );
};

PendingInvitesCard.propTypes = {
  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'typeof Map' is not assignable to... Remove this comment to see the full error message
  currentGroup: PropTypes.instanceOf(Map).isRequired,
};

export default PendingInvitesCard;
