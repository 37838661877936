import React, { useState } from 'react';
import PropTypes from 'prop-types';

// components
import LandingButton from 'layout/Landing/components/LandingButton';

// material-ui
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';

// icons
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

interface SelectChapterFormActionsProps {
  createDisabled: boolean;
  createRequest: (args: { groupId: number }) => void;
  destroySession: () => void;
  joinDisabled: boolean;
  selectGroupFormSubmitting: boolean;
  selectGroupFormSubmitFailed: boolean;
  selectGroupFormSubmitSucceeded: boolean;
  selectedGroupId: number;
  numberOfFormPage: number;
  onNumberOfFormPageChange: (number: number) => number;
  isNamesListSite: boolean;
  onCreateGroup: () => void;
}

const SelectChapterFormActions = ({
  createDisabled,
  createRequest,
  destroySession,
  joinDisabled,
  selectGroupFormSubmitting,
  selectGroupFormSubmitFailed,
  selectGroupFormSubmitSucceeded,
  selectedGroupId,
  numberOfFormPage,
  onNumberOfFormPageChange,
  isNamesListSite,
  onCreateGroup,
}: SelectChapterFormActionsProps) => {
  const classes = useStyles();

  const [activeButton, setActiveButton] = useState('');

  const handleCreate = () => {
    if (isNamesListSite && numberOfFormPage === 1) {
      onNumberOfFormPageChange(2);
    } else {
      onCreateGroup();
    }
    setActiveButton('selectGroupCreateButton');
  };

  const handleJoin = () => {
    setActiveButton('selectGroupJoinButton');

    createRequest({
      groupId: selectedGroupId,
    });
  };

  return (
    <Grid item>
      <Grid container spacing={1} className={classes.buttonsContainer}>
        <Grid item xs={6} sm={5} lg={6}>
          <LandingButton
            id='selectGroupJoinButton'
            type='submit'
            disabled={joinDisabled}
            onClick={handleJoin}
            loading={
              activeButton === 'selectGroupJoinButton' &&
              selectGroupFormSubmitting
            }
            fail={
              activeButton === 'selectGroupJoinButton' &&
              selectGroupFormSubmitFailed
            }
            success={
              activeButton === 'selectGroupJoinButton' &&
              selectGroupFormSubmitSucceeded
            }
          >
            Request To Join
          </LandingButton>
        </Grid>

        <Grid item xs={6} sm={5} lg={6}>
          <LandingButton
            id='selectGroupCreateButton'
            type='submit'
            disabled={createDisabled}
            onClick={handleCreate}
            loading={
              activeButton === 'selectGroupCreateButton' &&
              selectGroupFormSubmitting
            }
            fail={
              activeButton === 'selectGroupCreateButton' &&
              selectGroupFormSubmitFailed
            }
            success={
              activeButton === 'selectGroupCreateButton' &&
              selectGroupFormSubmitSucceeded
            }
          >
            {isNamesListSite && numberOfFormPage === 1
              ? 'Next Step'
              : 'Create Group'}
          </LandingButton>
        </Grid>
      </Grid>

      {isNamesListSite && !joinDisabled && createDisabled && (
        <Grid container>
          <Grid item className={classes.joinMessage}>
            <div className={classes.joinMessageInfoIcon}>
              <InfoOutlinedIcon />
            </div>
            Looks like your group already has an account. By clicking “Request
            to Join” you will be sending a request to be added. Note, this
            request will go to the current account admin. If you have questions,
            please contact us at
            <span className={classes.space} />
            <a href='mailto: support@growmygroup.com'>
              support@growmygroup.com
            </a>
          </Grid>
        </Grid>
      )}

      <Grid container className={classes.bottomContainer}>
        <Grid item>
          {/* @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call. */}
          <Link
            id='logoutLink'
            className={classes.bold}
            variant='subtitle2'
            component='button'
            color='textPrimary'
            to='/login'
            onClick={() => {
              destroySession();
            }}
          >
            Log Out
          </Link>
        </Grid>
      </Grid>
    </Grid>
  );
};

SelectChapterFormActions.propTypes = {
  createDisabled: PropTypes.bool.isRequired,
  createRequest: PropTypes.func.isRequired,
  destroySession: PropTypes.func.isRequired,
  joinDisabled: PropTypes.bool.isRequired,
  selectGroupFormSubmitFailed: PropTypes.bool.isRequired,
  selectGroupFormSubmitSucceeded: PropTypes.bool.isRequired,
  selectGroupFormSubmitting: PropTypes.bool.isRequired,
  selectedGroupId: PropTypes.number,
  numberOfFormPage: PropTypes.number.isRequired,
  onNumberOfFormPageChange: PropTypes.func.isRequired,
  isNamesListSite: PropTypes.bool,
  onCreateGroup: PropTypes.func.isRequired,
};

SelectChapterFormActions.defaultProps = {
  selectedGroupId: null,
  isNamesListSite: false,
};

const useStyles = makeStyles(theme => ({
  buttonsContainer: {
    [theme.breakpoints.up('md')]: {
      justifyContent: 'flex-start',
    },

    [theme.breakpoints.down('md')]: {
      justifyContent: 'center',
    },
  },
  bottomContainer: {
    marginTop: 50,

    [theme.breakpoints.up('md')]: {
      justifyContent: 'flex-start',
    },

    [theme.breakpoints.down('md')]: {
      justifyContent: 'center',
    },
  },
  joinMessage: {
    position: 'relative',
    marginTop: 40,
    marginLeft: 12,
    '& a': {
      color: 'inherit',
      textDecoration: 'none',
      fontWeight: 600,
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  },
  joinMessageInfoIcon: {
    position: 'absolute',
    top: 2,
    left: -18,
    fontSize: '1rem',
    '& svg': {
      fontSize: '1em',
    },
  },
  space: {
    display: 'inline-block',
    width: '0.3em',
  },
  bold: {
    fontWeight: 'bold',
  },
}));

export default SelectChapterFormActions;
