import { Styles, Theme } from 'assets/types';

const chapterListStyles: Styles = theme => ({
  groupsListContainer: {
    height: '100%',
    overflow: 'hidden',
    margin: 0,
  },
  tabsContainer: {
    backgroundColor: (theme as Theme).palette.background.darkPaper,
    padding: 8,
    paddingBottom: 0,
  },
});

export default chapterListStyles;
