import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

// action creators
import { doneIndicator } from 'store/actions/httpActions';

// api
import client from 'sources/api';

// constants
import { REQUEST_CREATE_REQUEST } from 'store/constants/requestTypes';

// lib
import httpSaga from 'store/sagas/httpSaga';
import getBaseType from '../helpers/getBaseType';
import history from 'helpers/history';

// schema
import { requestSchema } from 'store/schemas/requestSchema';

const WATCH_TYPE = REQUEST_CREATE_REQUEST;

export function* createRequestSaga(action: any) {
  const { groupId } = action.payload;

  const FORM = 'selectGroupForm';
  const TYPE = getBaseType(action.type);

  try {
    // @ts-expect-error ts-migrate(2569) FIXME: Type 'Generator<any, any, unknown>' is not an arra... Remove this comment to see the full error message
    yield* httpSaga(
      TYPE,
      call(client.post, '/requests', { group_id: groupId }),
      {
        schema: requestSchema,
        formName: FORM,
      }
    );
  } finally {
    yield put(doneIndicator(TYPE));
    yield history.push('/requests/pending');
  }
}

export function* watch() {
  yield takeEvery(WATCH_TYPE, createRequestSaga);
}

export default function* root() {
  yield all([fork(watch)]);
}
