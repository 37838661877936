import React, { useCallback, useContext } from 'react';
import { Route, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

// components
import ChapterSearchBar from 'layout/ApplicationLayout/components/ChapterSearchBar';
import EventBackButton from 'layout/ApplicationLayout/components/EventBackButton';
import GroupSearchBar from 'layout/ApplicationLayout/components/GroupSearchBar';
import LeadBackButton from 'layout/ApplicationLayout/components/LeadBackButton';
import LeadSearchBar from 'layout/ApplicationLayout/components/LeadSearchBar';
import LeadSearch from './LeadSearchBar/LeadSearch';
import NewButton from 'components/NewButton';
import NotificationsButton from 'layout/ApplicationLayout/components/NotificationsButton';
import ProfileAvatar from 'layout/ApplicationLayout/components/ProfileAvatar';

// material-ui
import { makeStyles } from '@material-ui/core/styles';
import { Hidden, Grid, Button } from '@material-ui/core';

// selectors
import getIsCurrentGroupChapter from 'store/selectors/getIsCurrentGroupChapter';
import getCurrentSubscription from 'store/selectors/getCurrentSubscription';

// helpers
import { SiteVisualDataContext } from 'components/SiteVisualData';

// styles
import { Theme } from 'assets/types';

interface AppHeaderProps {
  isCurrentGroupOrganization: boolean;
  isHqAdmin: boolean;
  isSystemAdmin: boolean;
  pathname?: string;
}

const AppHeader = ({
  isCurrentGroupOrganization,
  isHqAdmin,
  isSystemAdmin,
  pathname = '',
}: AppHeaderProps) => {
  const classes = useStyles();
  const { appHeaderLogo, appHeaderLogoAlt } = useContext(SiteVisualDataContext);
  const { isCurrentGroupChapter, currentSubscription } = useSelector(state => ({
    isCurrentGroupChapter: getIsCurrentGroupChapter(state),
    currentSubscription: getCurrentSubscription(state),
  }));
  const shouldRenderShadow =
    !pathname?.includes('leads') && !pathname?.includes('groups');
  const hideButton =
    (!isSystemAdmin && pathname.includes('/groups')) ||
    (isHqAdmin &&
      isCurrentGroupOrganization &&
      !pathname.includes('labels') &&
      !pathname.includes('forms'));

  const history = useHistory();

  const handleUpgradePlan = useCallback(() => {
    history.push(`/settings/billing`, { upgradePlanClicked: true });
  }, [history]);

  const showUpgradePlanButton =
    isCurrentGroupChapter &&
    currentSubscription &&
    currentSubscription.getIn(['plan', 'name']).includes('Basic');

  return (
    <Grid
      component='header'
      container
      alignItems='center'
      className={classNames(
        classes.appHeader,
        shouldRenderShadow && classes.shadow
      )}
      wrap='nowrap'
      id='appHeader'
    >
      <Grid item xs={9}>
        <Grid container alignItems='center'>
          <Hidden smDown>
            <Grid item>
              <img
                className={classes.logo}
                src={appHeaderLogo}
                alt={appHeaderLogoAlt}
              />
            </Grid>
          </Hidden>

          {!hideButton && (
            <Hidden smDown>
              <Grid item>
                <NewButton />
              </Grid>
            </Hidden>
          )}

          <Hidden xsDown>
            <Grid item>
              <Route path={`/leads/:leadId`} component={LeadBackButton} />
            </Grid>
          </Hidden>
          <Hidden xsDown>
            <Grid item>
              <Route path={`/events/:eventId`} component={EventBackButton} />
            </Grid>
          </Hidden>

          <Hidden smDown>
            <Route
              render={({ location }) =>
                location.pathname.includes('/leads') ||
                location.pathname.includes('/groups') ||
                !isCurrentGroupChapter ? null : (
                  <Grid item xs>
                    <LeadSearch />
                  </Grid>
                )
              }
            ></Route>
          </Hidden>

          <Hidden smDown>
            <Route exact path='/leads'>
              <Grid item xs>
                <LeadSearchBar />
              </Grid>
            </Route>
          </Hidden>
          <Hidden smDown>
            <Route path={`/groups`} render={props => <GroupSearchBar />} />
          </Hidden>
          <Hidden smDown>
            <Route path='/chapters' render={props => <ChapterSearchBar />} />
          </Hidden>

          <Hidden mdUp>
            <img
              className={classes.logo}
              src={appHeaderLogo}
              alt={appHeaderLogoAlt}
            />
          </Hidden>
        </Grid>
      </Grid>
      <Grid item xs={3}>
        <Grid
          container
          wrap='nowrap'
          justifyContent='flex-end'
          alignItems='center'
          spacing={1}
        >
          {showUpgradePlanButton && (
            <Grid item>
              <Button
                variant='contained'
                fullWidth
                size='medium'
                color='primary'
                onClick={handleUpgradePlan}
              >
                Upgrade Plan
              </Button>
            </Grid>
          )}
          <Grid item>
            <NotificationsButton />
          </Grid>
          <Grid item>
            <ProfileAvatar />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles(theme => ({
  appHeader: {
    flex: '0 0 auto',
    boxSizing: 'border-box',
    [theme.breakpoints.up('md')]: {
      height: 75,
      paddingRight: 24,
      paddingLeft: 0,
      zIndex: 100,
      borderBottom: `1px solid ${(theme as Theme).palette.background.paper}`,
      backgroundColor: (theme as Theme).palette.background.darkPaper,
    },
    [theme.breakpoints.only('sm')]: {
      marginTop: -10,
    },
    [theme.breakpoints.only('xs')]: {
      marginTop: -4,
    },
  },
  logo: {
    width: 128,

    [theme.breakpoints.down('sm')]: {
      position: 'fixed',
      left: '50%',
      transform: 'translateX(-50%)',
    },
    [theme.breakpoints.up('md')]: {
      marginTop: -4,
      marginLeft: 12,
      marginRight: 35,
    },
  },
  shadow: {
    [theme.breakpoints.up('md')]: {
      boxShadow: '0px 0px 10px 5px rgb(0 0 0 / 12%)',
    },
  },
}));

export default AppHeader;
