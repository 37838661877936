import React from 'react';
import PropTypes from 'prop-types';

// components
import VerticalSplit from 'layout/VerticalSplit';
import LandingFormContainer from './components/LandingFormContainer';
import LandingRightSide from './components/LandingRightSide';
import ErrorBoundary from 'layout/ErrorBoundary';

const Landing = function({ children }: any) {
  return (
    <VerticalSplit
      leftSide={
        <LandingFormContainer>
          {/* @ts-ignore */}
          <ErrorBoundary variant='dark'>{children}</ErrorBoundary>
        </LandingFormContainer>
      }
      rightSide={<LandingRightSide />}
    />
  );
};

Landing.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Landing;
