import React, { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useUpdateLeadMutation } from 'api/leads';

// mui components
import { Box } from '@material-ui/core';

// components
import MemberSelector from 'components/MemberSelector';

type Props = {
  initialValue: { value?: number | null; label?: string };
  leadId?: number;
};

export default function MainContactSelector({
  initialValue,
  leadId: propLeadId,
}: Props) {
  const { leadId = propLeadId } = useParams<{ leadId: string }>();
  const { mutate: updateLead } = useUpdateLeadMutation();

  const [mainContactValue, setMainContactValue] = useState(initialValue);

  const handleUpdateMainContact = useCallback(
    (newValue = {}) => {
      const { value = null, label = '' } = newValue || {};
      setMainContactValue({ value, label });
      updateLead({ id: Number(leadId), mainContactId: value });
    },
    [updateLead, leadId]
  );

  return (
    <Box padding={1}>
      <MemberSelector
        input={{
          value: mainContactValue,
          onChange: handleUpdateMainContact,
        }}
      />
    </Box>
  );
}
