import getCurrentGroupId from './getCurrentGroupId';
import { Map } from 'immutable';

export default function getPhoneAddOn(state: any) {
  //get the AvailableAddOnProducts object
  const availableAddOnProducts = state.getIn([
    'billing',
    'data',
    'byId',
    getCurrentGroupId(state)?.toString(),
    'availableAddOnProducts',
  ]);

  //returns Map object
  const phoneLineAddOn = availableAddOnProducts?.find(
    (addOnProduct: any) =>
      addOnProduct.get('productName')?.toString() === 'Phone Line Add-On',
    {},
    Map()
  );

  //convert from Map to JSON object
  const result = Object.fromEntries(phoneLineAddOn);

  return result;
}
