import React, { useCallback, useMemo, useEffect } from 'react';
import validate from 'validate.js';
import { isEqual } from 'lodash';

// hooks
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useCreateBgTaskMutation } from 'api/tasks';

// components
import DazzlingDialog from 'components/DazzlingDialog';
import TextField from 'components/TextField';
import TagsSelector from 'components/ChipsSelector';
import { Field, Form } from 'react-final-form';

// material-ui
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

// icons
import { NoteAdd } from '@material-ui/icons';

// actions
import { createNoteAction } from 'store/actions/noteActions';
import { fetchLeadTagsAction } from 'store/actions/leadTagActions';

// selectors
import getSliceState from 'store/selectors/getSliceState';
import getSliceEntityById from 'store/selectors/getSliceEntityById';
import useFetchFilters from 'helpers/hooks/useFetchFilters';

// constants
import { TaskNames, CreateNotesParams } from 'api/tasks';

const SCHEMA = {
  body: {
    length: { maximum: 2048 },
  },
};

const FORM = 'createNoteForm';

interface CreateNoteTagModalProps {
  allSelected?: boolean;
  callSid?: string | null;
  leadIds?: number[];
  onClose: (...args: any[]) => any;
  open: boolean;
  disabled?: boolean;
  shouldFetchTags?: boolean;
}

export default function CreateNoteTagModal({
  allSelected = false,
  callSid,
  leadIds = [],
  onClose,
  open,
  disabled,
  shouldFetchTags,
}: CreateNoteTagModalProps) {
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'leadId' does not exist on type '{}'.
  const { leadId: leadProfileId } = useParams();
  const filters = useFetchFilters();
  const singleLeadId = leadIds.length === 1 ? leadIds[0] : leadProfileId;

  const {
    actions: { createNote, fetchLeadTags },
    state: { tags = [], milestones = [], leadTags = {} },
  } = useRedux(singleLeadId);

  const {
    isLoading: isCreatingNotes,
    isSuccess: isCreatingNotesSucceed,
    isError: isCreatingNotesFailed,
    reset: resetCreateNotesMutation,
    mutate: createNotes,
  } = useCreateBgTaskMutation<CreateNotesParams>(TaskNames.BulkCreateNotes);

  const isBulk = allSelected || leadIds.length > 1;

  useEffect(() => {
    if (shouldFetchTags && singleLeadId && open) {
      fetchLeadTags(singleLeadId);
    }
  }, [fetchLeadTags, open, shouldFetchTags, singleLeadId]);

  const tagOptions = useMemo(
    () =>
      tags.map(({ id, title, color }: any = {}) => ({
        value: id,
        label: title,
        color: '#' + color,
        checked: leadTags[id],
        type: 'label',
      })),
    [tags, leadTags]
  );
  const milestoneOptions = useMemo(
    () =>
      milestones.map(({ id, title }: any = {}) => ({
        value: id,
        label: title,
        ring: true,
        count: leadTags[id]?.count || 0,
        type: 'event',
      })),
    [milestones, leadTags]
  );

  const subTitleArg = useMemo(() => {
    if (allSelected) return 'all leads';
    else if (leadIds.length > 1) return `${leadIds.length} leads`;
    else return 'this lead';
  }, [leadIds, allSelected]);

  const handleExit = useCallback(() => {
    if (singleLeadId && singleLeadId !== 'all') {
      fetchLeadTags(singleLeadId);
    }
  }, [singleLeadId, fetchLeadTags]);

  const handleReject = useCallback(() => {
    if (singleLeadId && singleLeadId !== 'all' && callSid) {
      createNote({
        formName: 'CallNote',
        fields: {
          leadId: singleLeadId,
          callSid,
        },
      });
    }
  }, [singleLeadId, callSid, createNote]);

  const handleFormSubmit = useCallback(
    ({ body, milestones = {}, tags = {} }) => {
      if (isBulk) {
        const params: CreateNotesParams = {
          pnmIds: allSelected ? 'all' : leadIds,
          noteData: { body: body, tagIds: [...milestones, ...tags] },
          ...filters,
        };
        if (allSelected) params.excludedPnmIds = leadIds;
        createNotes(params);
      } else {
        createNote({
          formName: FORM,
          fields: {
            body,
            leadId: singleLeadId,
            tagIds: [...milestones, ...tags],
          },
        });
      }
    },
    [
      createNotes,
      createNote,
      filters,
      singleLeadId,
      isBulk,
      allSelected,
      leadIds,
    ]
  );

  const validateFormFields = useCallback(
    fields => validate(fields, SCHEMA),
    []
  );

  return (
    <Form
      onSubmit={handleFormSubmit}
      initialValues={{ tags: [], milestones: [] }}
      initialValuesEqual={isEqual}
      validate={validateFormFields}
      render={({ handleSubmit, invalid, pristine, form: { reset } }) => (
        <DazzlingDialog
          disabled={invalid || pristine || disabled}
          isLoading={isCreatingNotes}
          isSuccess={isCreatingNotesSucceed}
          isError={isCreatingNotesFailed}
          formName={!isBulk ? FORM : undefined}
          handleClose={onClose}
          open={open}
          onAccept={handleSubmit}
          headerProps={{
            icon: NoteAdd,
            title: 'Write Note & Tag',
            highlightedSubtitle: `Record your relationship and interactions with ${subTitleArg} through notes, milestones, and tags`,
          }}
          acceptLabel='Post'
          submitOnEnter
          onExit={handleExit}
          onExited={() => {
            !disabled && reset();
            resetCreateNotesMutation();
          }}
          onReject={handleReject}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {isBulk && (
                <Typography paragraph>
                  This could take a while -- you will receive a notification
                  when completed.
                </Typography>
              )}
            </Grid>
            <Grid item xs={12}>
              <Field
                name='body'
                label='Note'
                multiline
                rows='4'
                placeholder='Type something'
                maxLength={SCHEMA.body.length.maximum}
                component={TextField}
                fullWidth
                margin='normal'
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>

            <Grid item xs={12} id='actionsFilter'>
              <Tooltip
                arrow
                placement='top'
                title='Milestones help you log significant steps a lead has taken in
                the recruitment process'
              >
                <div>
                  <Field
                    name='milestones'
                    label='Select Milestones'
                    // @ts-expect-error ts-migrate(2322) FIXME: Type 'typeof ChipsSelector' is not assignable to t... Remove this comment to see the full error message
                    component={TagsSelector}
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    options={milestoneOptions}
                    isEqual={(a, b) => isEqual(new Set(a), new Set(b))}
                    chipsColor={null}
                  />
                </div>
              </Tooltip>
            </Grid>
            <Grid item xs={12} id='actionsFilter'>
              <Tooltip
                arrow
                title='Tags give you simple ways to categorize and group your leads'
              >
                <div>
                  <Field
                    name='tags'
                    label='Select Tags'
                    // @ts-expect-error ts-migrate(2322) FIXME: Type 'typeof ChipsSelector' is not assignable to t... Remove this comment to see the full error message
                    component={TagsSelector}
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    options={tagOptions}
                    isEqual={(a, b) => isEqual(new Set(a), new Set(b))}
                  />
                </div>
              </Tooltip>
            </Grid>
          </Grid>
        </DazzlingDialog>
      )}
    />
  );
}

const useRedux = (leadId: any) => {
  const dispatch = useDispatch();
  const actions = useMemo(
    () => ({
      createNote: (payload: any) => dispatch(createNoteAction(payload)),
      fetchLeadTags: (id: number) => dispatch(fetchLeadTagsAction({ id })),
    }),
    [dispatch]
  );
  const state = useSelector(
    state => ({
      tags: getSliceState('tag')(state).data,
      milestones: getSliceState('milestone')(state).data,
      leadTags: getSliceEntityById('leadTag', leadId, [])(state),
    }),
    isEqual
  );
  return { actions, state };
};
