import { all } from 'redux-saga/effects';

// sagas
import fetchMilestonesSaga from './fetchMilestonesSaga';
import createMilestoneSaga from './createMilestoneSaga';
import deleteMilestoneSaga from './deleteMilestoneSaga';
import updateMilestoneSaga from './updateMilestoneSaga';

const milestoneSaga = function* rootSaga() {
  yield all([
    fetchMilestonesSaga(),
    createMilestoneSaga(),
    deleteMilestoneSaga(),
    updateMilestoneSaga(),
  ]);
};

export default milestoneSaga;
