import Immutable, { Map } from 'immutable';
// constants
import { STORE_CLEAR } from 'store/constants/storeTypes';

export default function reduceRoot(reducers: any) {
  return (state: any = Map(), action = {}) => {
    let newState = state;
    if ((action as any).type === STORE_CLEAR) {
      // Clear the application state and re-initialize it using the new
      // state coming from the action payload.
      // If no new state was given, the entire app state will be cleared.
      const {
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'payload' does not exist on type '{}'.
        payload: { newState: newStateFromPayload } = { newState: {} },
      } = action;
      newState = Immutable.fromJS(newStateFromPayload);
    }
    return reducers(newState, action);
  };
}
