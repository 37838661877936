import React, { useEffect, useMemo, useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

// mui components
import {
  TextField,
  Chip,
  CircularProgress,
  Typography,
  Grid,
  Box,
} from '@material-ui/core';
import Autocomplete, {
  createFilterOptions,
} from '@material-ui/lab/Autocomplete';

// styles
import {
  makeStyles,
  createTheme,
  ThemeProvider,
} from '@material-ui/core/styles';

// icons
import { Search, AddCircle } from '@material-ui/icons';

// actions
import {
  fetchReferralSourcesAction,
  createReferralSourceAction,
} from 'store/actions/referralSourceActions';

// selectors
import getSliceState from 'store/selectors/getSliceState';

const tagsTheme = createTheme({
  palette: {
    primary: {
      main: '#fff',
    },
  },
});
const filter = createFilterOptions();

export default function ReferralSourceSelector({
  input,
  placeholder = 'Filter',
  label = 'Referral Sources',
  readOnly,
  paddingTopClass,
  ...restProps
}: {
  input: {
    value: any[];
    onChange: (...args: any[]) => any;
  };
  placeholder?: string;
  label?: string;
  readOnly?: boolean;
  multiple?: boolean;
  paddingTopClass?: 'paddingTop6';
}) {
  const classes = useStyles();
  const {
    actions: { fetchReferralSources, createReferralSource },
    state: { referralSource },
  } = useRedux();

  useEffect(() => {
    fetchReferralSources();
  }, [fetchReferralSources]);

  const [newReferralSource, setNewReferralSource] = useState();
  const referralSourceValue = useMemo(
    () =>
      (restProps as any).multiple
        ? referralSource.data.filter(({ id }: any) => input.value.includes(id))
        : referralSource.data.find(
            (referralSource: any) =>
              input.value?.toString() === referralSource.id?.toString()
          ),
    [input.value, referralSource.data, restProps]
  );
  useEffect(() => {
    const createdReferralSource = referralSource.data.find(
      ({ displayName }: any) =>
        // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
        displayName === (newReferralSource && newReferralSource.displayName)
    );
    if (createdReferralSource) {
      setNewReferralSource(undefined);
      input.onChange([...input.value, createdReferralSource.id]);
    }
  }, [referralSource.data, input, newReferralSource]);
  const handleChange = useCallback(
    (_, referralSources) => {
      const newReferralSource = Array.isArray(referralSources)
        ? referralSources.find(({ id } = {}) => !id)
        : !referralSources?.id
        ? referralSources
        : false;
      if (newReferralSource && !readOnly) {
        createReferralSource(newReferralSource);
        setNewReferralSource(newReferralSource);
      } else {
        input.onChange(
          Array.isArray(referralSources)
            ? referralSources.map(({ id }) => id)
            : referralSources?.id || referralSources
        );
      }
    },
    [createReferralSource, input, readOnly]
  );
  const renderReferralSourceInput = useCallback(
    params => {
      return (
        <TextField
          {...params}
          multiline={false}
          variant='outlined'
          label={label}
          placeholder={placeholder}
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {referralSource.loading ? (
                  <CircularProgress size={20} />
                ) : (
                  <Search />
                )}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      );
    },
    [label, placeholder, referralSource.loading]
  );
  return (
    <Autocomplete
      limitTags={1}
      disableCloseOnSelect={!readOnly}
      onChange={handleChange}
      options={referralSource.data}
      renderTags={renderReferralSourceTags}
      forcePopupIcon={false}
      // @ts-expect-error ts-migrate(2322) FIXME: Type '(options: any, params: any) => unknown[]' is... Remove this comment to see the full error message
      filterOptions={filterReferralSourceOptions}
      getOptionLabel={getOptionDisplayName}
      renderOption={option => renderReferralSourceOption(option, readOnly)}
      renderInput={renderReferralSourceInput}
      classes={classes}
      className={paddingTopClass || ''}
      value={referralSourceValue}
      {...restProps}
    />
  );
}
const useStyles = makeStyles(theme => ({
  paper: {
    backgroundColor: (theme.palette.background as any).lightPaper,
  },
  root: {
    '&.paddingTop6': {
      paddingTop: 6,
    },
  },
}));
const useRedux = () => {
  const dispatch = useDispatch();
  const actions = useMemo(
    () => ({
      fetchReferralSources: () => dispatch(fetchReferralSourcesAction()),
      createReferralSource: (payload: any) =>
        dispatch(createReferralSourceAction(payload)),
    }),
    [dispatch]
  );
  const combinedSelector = useCallback(
    state => ({ referralSource: getSliceState('referralSource')(state) }),
    []
  );
  const state = useSelector(combinedSelector);
  return { actions, state };
};
const getOptionDisplayName = (option = {}) =>
  (option as any)?.displayName || '';
// There are referral sources with 'null' displayName which is causing the problem
// because Autocomplete's getOptionsLabel argument gets 'null'. This is just a walk around for that issue.
// TODO: Need to make sure that referral sources display names are not null and unique.
const renderReferralSourceTags = (values: any, getTagProps: any) => (
  <Box width='fill-available'>
    <ThemeProvider theme={tagsTheme}>
      {values.map((option: any, index: any) => (
        <Chip
          key={option}
          label={option.displayName}
          {...getTagProps({ index })}
        />
      ))}
    </ThemeProvider>
  </Box>
);
const renderReferralSourceOption = (option: any, readOnly: any) =>
  option.id || readOnly ? (
    option.displayName
  ) : (
    <Grid container spacing={1} alignItems='center'>
      <AddCircle />
      <Grid item>
        <Typography>{`Add "${option.displayName}"`}</Typography>
      </Grid>
    </Grid>
  );
const filterReferralSourceOptions = (options: any, params: any) => {
  const filtered = filter(options, params);
  if (!filtered.length && params.inputValue !== '') {
    filtered.push({ displayName: params.inputValue });
  }
  return filtered;
};
