import React, { useCallback, useState, useEffect } from 'react';

// material-ui
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

// hooks
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';

// selectors
import getLoadingFromState from 'store/selectors/getLoadingFromState';

// components
import Button from 'components/Button';
import ManageSubscription from 'components/ManageSubscription';

// helpers
import { ManageSubscriptionStatus } from 'components/ManageSubscription/helpers/types';

interface ManageSubscriptionButtonsProps {
  isBillingError: boolean;
  isEmptyCurrentSubscription: boolean;
  isSubscriptionBlocked?: boolean;
  hasDefaultPaymentMethod: boolean;
}

const ManageSubscriptionButtons = ({
  isBillingError,
  isEmptyCurrentSubscription,
  isSubscriptionBlocked,
  hasDefaultPaymentMethod,
}: ManageSubscriptionButtonsProps) => {
  const classes = useStyles();
  const [currentModalStatus, setCurrentModalStatus] = useState<
    ManageSubscriptionStatus
  >(ManageSubscriptionStatus.Closed);
  const [willSubscriptionBeChanged, setWillSubscriptionBeChanged] = useState<
    boolean
  >(false);
  const [isItNewSubscription, setIsItNewSubscription] = useState<boolean>(
    false
  );
  const billingLoading = useSelector(
    getLoadingFromState('billing', false, false)
  );

  const handleClickChangePlan = useCallback(() => {
    if (isBillingError) {
      setCurrentModalStatus(ManageSubscriptionStatus.BillingError);
      return;
    }
    if (!hasDefaultPaymentMethod) {
      setCurrentModalStatus(ManageSubscriptionStatus.EnterPaymentMethod);
      return;
    }
    setWillSubscriptionBeChanged(true);
    setIsItNewSubscription(false);
    setCurrentModalStatus(ManageSubscriptionStatus.SelectYourPlan);
  }, [
    isBillingError,
    hasDefaultPaymentMethod,
    setWillSubscriptionBeChanged,
    setCurrentModalStatus,
    setIsItNewSubscription,
  ]);

  const { state: upgradePlanClicked = false } = useLocation<{
    upgradePlanClicked?: boolean;
  }>();

  useEffect(() => {
    if (upgradePlanClicked) handleClickChangePlan();
  }, [upgradePlanClicked, handleClickChangePlan]);

  const handleClickCreateNewSubscription = useCallback(() => {
    if (isBillingError) {
      setCurrentModalStatus(ManageSubscriptionStatus.BillingError);
      return;
    }
    setWillSubscriptionBeChanged(true);
    setIsItNewSubscription(true);
    setCurrentModalStatus(ManageSubscriptionStatus.SelectYourPlan);
  }, [
    isBillingError,
    setWillSubscriptionBeChanged,
    setCurrentModalStatus,
    setIsItNewSubscription,
  ]);

  const handleClickChangePaymentMethod = useCallback(() => {
    if (isBillingError) {
      setCurrentModalStatus(ManageSubscriptionStatus.BillingError);
      return;
    }
    setWillSubscriptionBeChanged(false);
    setIsItNewSubscription(false);
    setCurrentModalStatus(ManageSubscriptionStatus.EnterPaymentMethod);
  }, [
    isBillingError,
    setWillSubscriptionBeChanged,
    setCurrentModalStatus,
    setIsItNewSubscription,
  ]);

  return (
    <>
      <Grid item xs={12} className={classes.buttons}>
        <Grid
          container
          spacing={2}
          alignItems='center'
          justifyContent='flex-end'
        >
          {isEmptyCurrentSubscription && !isBillingError ? (
            <Grid item xs={12} sm='auto'>
              <Button
                variant='contained'
                color='primary'
                className={classes.manageButton}
                disabled={billingLoading}
                onClick={handleClickCreateNewSubscription}
              >
                Create new subscription
              </Button>
            </Grid>
          ) : (
            <>
              <Grid item xs={12} sm='auto'>
                <Button
                  variant='outlined'
                  className={classes.manageButton}
                  disabled={billingLoading}
                  onClick={handleClickChangePaymentMethod}
                >
                  Update Billing Info
                </Button>
              </Grid>
              <Grid item xs={12} sm='auto'>
                <Button
                  variant='contained'
                  color='primary'
                  className={classes.manageButton}
                  disabled={billingLoading}
                  onClick={handleClickChangePlan}
                >
                  Change Plan
                </Button>
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
      <ManageSubscription
        currentModalStatus={currentModalStatus}
        onCurrentModalStatusChange={setCurrentModalStatus}
        isSubscriptionBlocked={
          isSubscriptionBlocked && (!isItNewSubscription || isBillingError)
        }
        willSubscriptionBeChanged={willSubscriptionBeChanged}
        isItNewSubscription={isItNewSubscription}
      />
    </>
  );
};

const useStyles = makeStyles(theme => ({
  buttons: {
    marginTop: 10,
  },
  manageButton: {
    minWidth: 155,
    [theme.breakpoints.only('xs')]: {
      width: '100%',
    },
  },
}));

export default ManageSubscriptionButtons;
