import React, {
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Map, Set } from 'immutable';
import classNames from 'classnames';
import { isEqual } from 'lodash';
import validate from 'validate.js';
import { Field, Form } from 'react-final-form';
// hooks
import { useDispatch, useSelector } from 'react-redux';
import {
  useUpdateSubscriptionPlanMutation,
  useCreateNewSubscriptionMutation,
} from 'api/billing';
// material-ui
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MuiCheckbox from '@material-ui/core/Checkbox';
import { makeStyles } from '@material-ui/core/styles';
// icons
import CheckIcon from '@material-ui/icons/Check';
// components
import DazzlingDialog from 'components/DazzlingDialog';
import Button from 'components/Button';
import StripeField from 'components/ManageSubscription/components/StripeField';
// selectors
import getCurrentGroupId from 'store/selectors/getCurrentGroupId';
import getCustomer from 'store/selectors/getCustomer';
import getCurrentSubscription from 'store/selectors/getCurrentSubscription';
import getCurrentUserGroups from 'store/selectors/getCurrentUserGroups';
import {
  hasSubmitFailed,
  isSubmitting,
  hasSubmitSucceeded,
  getError,
} from 'store/selectors/formSelectors';
import { SiteVisualDataContext } from 'components/SiteVisualData';
// actions
import { fetchStripeConfig } from 'store/actions/billingActions';
import { fetchCurrentGroup } from 'store/actions/groupActions';
import { createGroup } from 'store/actions/groupActions';
// helpers
import {
  GroupFormValues,
  SubscriptionInfo,
  StripeConfirmPaymentIntent,
} from 'components/ManageSubscription/helpers/interfaces';
import {
  ManageSubscriptionStatus,
  StripePaymentStatus,
} from 'components/ManageSubscription/helpers/types';
import getPricesFromSubscriptions from 'components/ManageSubscription/helpers/getPricesFromSubscriptions';
import {
  numberToCurrency,
  toNumber,
  toString,
  toStringWithoutNullUndefined,
} from 'helpers/transform';
import isItFreeSubscription from '../helpers/isItFreeSubscription';

const FORM = 'confirmOrderForm';

const SCHEMA = {
  firstAgreement: {
    presence: { message: '^This field is required.' },
    type: 'boolean',
    inclusion: {
      within: [true],
    },
  },
  secondAgreement: {
    presence: { message: '^This field is required.' },
    type: 'boolean',
    inclusion: {
      within: [true],
    },
  },
  thirdAgreement: {
    presence: { message: '^This field is required.' },
    type: 'boolean',
    inclusion: {
      within: [true],
    },
  },
};

interface ConfirmOrderModalProps {
  open: boolean;
  orderComplete: boolean;
  isItNewSubscription: boolean;
  subscriptionInfo: SubscriptionInfo;
  onCurrentModalStatusChange: Dispatch<
    SetStateAction<ManageSubscriptionStatus>
  >;
  currentSubscriptionPlanInterval?: string;
  currentSubscriptionPlanProductId?: string;
  currentSubscriptionPlanId?: string;
  willSubscriptionBeChanged?: boolean;
  shouldGroupBeCreated?: boolean;
  stripeProducts?: StripeProduct[];
  groupFormValues?: GroupFormValues;
  onSuccessCreatingSubscription?: (groupId?: number) => void;
  onClose?: (status?: ManageSubscriptionStatus) => void;
}

const ConfirmOrderModal = ({
  open,
  orderComplete,
  isItNewSubscription,
  willSubscriptionBeChanged,
  shouldGroupBeCreated,
  currentSubscriptionPlanInterval,
  currentSubscriptionPlanProductId,
  currentSubscriptionPlanId,
  stripeProducts,
  subscriptionInfo,
  onCurrentModalStatusChange,
  onSuccessCreatingSubscription,
  groupFormValues,
  onClose = () => {},
}: ConfirmOrderModalProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { firstLogoAlt } = useContext(SiteVisualDataContext);

  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [wasGroupCreated, setWasGroupCreated] = useState<boolean>(false);
  const [stripePaymentStatus, setStripePaymentStatus] = useState<
    StripePaymentStatus
  >(StripePaymentStatus.Init);
  const {
    isLoading: isSubscriptionPlanUpdating,
    isSuccess: isSubscriptionPlanUpdated,
    isError: isSubscriptionPlanUpdatingFailed,
    mutate: updateSubscriptionPlanMethod,
    error: subscriptionPlanError,
    data: subscriptionPlanData,
  } = useUpdateSubscriptionPlanMutation();

  const {
    isLoading: isNewSubscriptionCreating,
    isSuccess: isNewSubscriptionCreated,
    isError: isNewSubscriptionCreateFailed,
    mutate: createNewSubscription,
    error: createNewSubscriptionError,
    data: createNewSubscriptionData,
  } = useCreateNewSubscriptionMutation();

  const {
    currentGroupId,
    currentSubscription,
    customer,
    currentUserGroups,
    newGroupFormSubmitting,
    newGroupFormSubmitFailed,
    newGroupFormSubmitSucceeded,
    newGroupFormError,
  } = useSelector(state => ({
    currentGroupId: getCurrentGroupId(state),
    currentSubscription: getCurrentSubscription(state),
    customer: getCustomer(state),
    currentUserGroups: getCurrentUserGroups(state),
    newGroupFormSubmitting: isSubmitting('newGroupForm')(state),
    newGroupFormSubmitFailed: hasSubmitFailed('newGroupForm')(state),
    newGroupFormError: getError('newGroupForm')(state),
    newGroupFormSubmitSucceeded: hasSubmitSucceeded('newGroupForm')(state),
  }));

  const isSuccess = isItNewSubscription
    ? isNewSubscriptionCreated &&
      (!createNewSubscriptionData?.clientSecret ||
        stripePaymentStatus === StripePaymentStatus.ConfirmPISSuccess)
    : isSubscriptionPlanUpdated &&
      !errorMessage &&
      (!subscriptionPlanData?.clientSecret ||
        stripePaymentStatus === StripePaymentStatus.ConfirmPISSuccess);

  const isLoading =
    isSubscriptionPlanUpdating ||
    [
      StripePaymentStatus.SendRequestToConfirmPIS,
      StripePaymentStatus.WaitingConfirmPIS,
    ].includes(stripePaymentStatus) ||
    (isItNewSubscription &&
      (newGroupFormSubmitting || isNewSubscriptionCreating));

  const isError =
    isSubscriptionPlanUpdatingFailed ||
    !!errorMessage ||
    stripePaymentStatus === StripePaymentStatus.ConfirmPISError ||
    (isItNewSubscription &&
      (newGroupFormSubmitFailed || isNewSubscriptionCreateFailed));

  const { currentSubscriptionId, currentSubscriptionName = '' } = useMemo(
    (): {
      currentSubscriptionId?: string;
      currentSubscriptionName?: string;
    } =>
      !currentSubscription || !Map.isMap(currentSubscription)
        ? {}
        : {
            currentSubscriptionId: toString(currentSubscription.get('id')),
            currentSubscriptionName: toString(
              currentSubscription.getIn(['plan', 'name'])
            ),
          },
    [currentSubscription]
  );

  const lastAddedGroupId = useMemo((): number | undefined => {
    const maxGroupId =
      Set.isSet(currentUserGroups) && !currentUserGroups.isEmpty()
        ? currentUserGroups.max()
        : undefined;

    return maxGroupId ? toNumber(maxGroupId) : undefined;
  }, [currentUserGroups]);

  const { currentCardBrand, currentCardLast4 } = useMemo((): {
    currentCardBrand?: string;
    currentCardLast4?: string;
  } => {
    const currentCardBrand = customer?.getIn(['paymentCard', 'brand']);
    const currentCardLast4 = customer?.getIn(['paymentCard', 'last4']);

    return !customer || !Map.isMap(customer)
      ? {}
      : {
          ...(currentCardBrand
            ? { currentCardBrand: toString(currentCardBrand) }
            : {}),
          ...(currentCardLast4
            ? { currentCardLast4: toString(currentCardLast4) }
            : {}),
        };
  }, [customer]);

  const initialValues = {
    firstAgreement: false,
    secondAgreement: false,
    thirdAgreement: false,
  };

  const {
    cardBrand,
    cardLast4,
    newSubscriptionPlanInterval,
    newSubscriptionName,
    newSubscriptionPriceText,
    newSubscriptionPriceMonth,
    newSubscriptionPriceYear,
    newSubscriptionPriceId,
    newPaymentMethodId,
    newStripeEmail,
    paymentMethodSetup,
  } = useMemo(() => {
    const {
      newSubscription,
      paymentMethodCardBrand,
      paymentMethodCardLast4,
      paymentMethodId,
      emailAddress,
      newSubscriptionPlanInterval = 'year',
    } = subscriptionInfo || {};
    const {
      name,
      priceText,
      paymentMethodSetup,
      subscriptions: subscriptionOptions,
    } = newSubscription || {};
    const {
      month: monthSubscriptionOptions,
      year: yearSubscriptionOptions,
    } = getPricesFromSubscriptions(subscriptionOptions);
    const yearPriceId =
      newSubscriptionPlanInterval === 'year' && yearSubscriptionOptions?.priceId
        ? yearSubscriptionOptions.priceId
        : null;
    const monthPriceId =
      newSubscriptionPlanInterval === 'month' &&
      monthSubscriptionOptions?.priceId
        ? monthSubscriptionOptions.priceId
        : null;

    return {
      cardBrand: paymentMethodCardBrand || '',
      cardLast4: paymentMethodCardLast4 || '',
      newSubscriptionPlanInterval: toStringWithoutNullUndefined(
        newSubscriptionPlanInterval || ''
      ),
      newSubscriptionName: toStringWithoutNullUndefined(name || ''),
      newSubscriptionPriceText: toStringWithoutNullUndefined(priceText || ''),
      newSubscriptionPriceMonth: toNumber(
        monthSubscriptionOptions?.amount || 0
      ),
      newSubscriptionPriceYear: toNumber(yearSubscriptionOptions?.amount || 0),
      newSubscriptionPriceId: yearPriceId || monthPriceId,
      newPaymentMethodId: paymentMethodId,
      newStripeEmail: emailAddress,
      paymentMethodSetup,
    };
  }, [subscriptionInfo]);

  const stripeConfirmPaymentIntent = useMemo((): StripeConfirmPaymentIntent | null => {
    if (
      isSubscriptionPlanUpdated &&
      subscriptionPlanData?.clientSecret &&
      subscriptionPlanData?.paymentMethod
    ) {
      return {
        clientSecret: subscriptionPlanData.clientSecret,
        paymentMethod: subscriptionPlanData.paymentMethod,
      };
    }
    if (
      isNewSubscriptionCreated &&
      createNewSubscriptionData?.clientSecret &&
      newPaymentMethodId
    ) {
      return {
        clientSecret: createNewSubscriptionData.clientSecret,
        paymentMethod: newPaymentMethodId,
      };
    }

    return null;
  }, [
    isSubscriptionPlanUpdated,
    subscriptionPlanData,
    isNewSubscriptionCreated,
    createNewSubscriptionData,
    newPaymentMethodId,
  ]);

  const formMessage = useMemo((): string => {
    if (isSuccess) {
      return 'Subscription plan was updated successfully';
    }

    if (isSubscriptionPlanUpdatingFailed) {
      return (
        subscriptionPlanError?.response?.data?.msg ||
        "Subscription plan wasn't updated"
      );
    }

    if (isNewSubscriptionCreateFailed) {
      return (
        createNewSubscriptionError?.response?.data?.msg ||
        "Can't subscribe. There is some issue in your subscription."
      );
    }

    if (stripePaymentStatus === StripePaymentStatus.ConfirmPISError) {
      return "Subscription plan wasn't paid. There is some issue with your card.";
    }

    if (errorMessage) {
      return errorMessage;
    }

    return isError
      ? 'There is some issue with your subscription. Please try again.'
      : '';
  }, [
    errorMessage,
    isError,
    isSuccess,
    isSubscriptionPlanUpdatingFailed,
    isNewSubscriptionCreateFailed,
    stripePaymentStatus,
    subscriptionPlanError,
    createNewSubscriptionError,
  ]);

  const totalPriceText = useMemo((): ReactNode => {
    if (!isItNewSubscription && !willSubscriptionBeChanged) {
      if (!stripeProducts || !currentSubscriptionPlanId) {
        return '';
      }
      const stripeProduct = stripeProducts.find(
        stripeProduct => stripeProduct?.id === currentSubscriptionPlanProductId
      );
      const currentSubscriptionPlan = stripeProduct
        ? stripeProduct?.subscriptions.find(
            subscription => subscription?.priceId === currentSubscriptionPlanId
          )
        : null;

      return currentSubscriptionPlan?.amount
        ? `$${toString(
            numberToCurrency(toNumber(currentSubscriptionPlan.amount))
          )}`
        : '';
    }

    if (!!newSubscriptionPriceText) {
      return newSubscriptionPriceText;
    }

    if (newSubscriptionPlanInterval === 'year') {
      const youSaved =
        !!newSubscriptionPriceMonth && !!newSubscriptionPriceYear
          ? newSubscriptionPriceMonth * 12 - newSubscriptionPriceYear
          : 0;
      return youSaved > 0 ? (
        <>
          ${toString(numberToCurrency(newSubscriptionPriceYear))}
          <span className={classNames(classes.space, 'largeWidth')} />
          <span className={classes.textLite}>
            You saved
            <span className={classes.space} />${youSaved}
          </span>
        </>
      ) : (
        `$${toString(numberToCurrency(newSubscriptionPriceYear))}`
      );
    }

    return !!newSubscriptionPriceMonth
      ? `$${toString(numberToCurrency(newSubscriptionPriceMonth))}`
      : '';
  }, [
    newSubscriptionPriceText,
    newSubscriptionPriceMonth,
    newSubscriptionPriceYear,
    newSubscriptionPlanInterval,
    isItNewSubscription,
    willSubscriptionBeChanged,
    currentSubscriptionPlanProductId,
    currentSubscriptionPlanId,
    stripeProducts,
    classes.space,
    classes.textLite,
  ]);

  const paymentMethodText = useMemo(() => {
    const shouldNewCardBeShown =
      isItNewSubscription || !willSubscriptionBeChanged;
    const brand = shouldNewCardBeShown ? cardBrand : currentCardBrand;
    const last4 = shouldNewCardBeShown ? cardLast4 : currentCardLast4;

    return !!brand || !!last4 ? (
      <>
        {!!brand && (
          <>
            {brand}
            <span className={classes.space} />
            -
            <span className={classes.space} />
          </>
        )}
        {!!last4 ? last4 : ''}
      </>
    ) : null;
  }, [
    cardBrand,
    currentCardBrand,
    cardLast4,
    currentCardLast4,
    isItNewSubscription,
    willSubscriptionBeChanged,
    classes.space,
  ]);

  const getSubscriptionNameYearlyOrMonthly = useCallback(
    (typeOfSubscription?: string) => {
      switch (typeOfSubscription) {
        case 'year':
          return 'Yearly';
        case 'month':
          return 'Monthly';
        default:
          return '';
      }
    },
    []
  );

  const dialogContent = useMemo(() => {
    const shouldNewSubscriptionBeShown =
      isItNewSubscription || willSubscriptionBeChanged;
    const terms = getSubscriptionNameYearlyOrMonthly(
      shouldNewSubscriptionBeShown
        ? newSubscriptionPlanInterval
        : currentSubscriptionPlanInterval
    );

    return (
      <Grid className={classes.container} container spacing={2}>
        <Grid item xs={12}>
          <Grid
            className={classes.informationContainer}
            container
            alignItems='center'
            spacing={1}
          >
            <Grid item xs={6} sm={5} md={2}>
              Subscription:
            </Grid>
            <Grid item xs={6} sm={7} md={10}>
              {shouldNewSubscriptionBeShown
                ? newSubscriptionName
                : currentSubscriptionName}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid
            className={classes.informationContainer}
            container
            alignItems='center'
            spacing={1}
          >
            <Grid item xs={6} sm={5} md={2}>
              Terms:
            </Grid>
            <Grid item xs={6} sm={7} md={10}>
              {!!terms && (
                <>
                  {terms}
                  <span className={classes.space} />
                  Subscription
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
        {!!totalPriceText && (
          <Grid item xs={12}>
            <Grid
              className={classes.informationContainer}
              container
              alignItems='center'
              spacing={1}
            >
              <Grid item xs={6} sm={5} md={2}>
                Total:
              </Grid>
              <Grid item xs={6} sm={7} md={10}>
                {totalPriceText}
              </Grid>
            </Grid>
          </Grid>
        )}
        {paymentMethodText && (
          <Grid item xs={12}>
            <Grid
              className={classes.informationContainer}
              container
              alignItems='center'
              spacing={1}
            >
              <Grid item xs={6} sm={5} md={2}>
                Payment Method:
              </Grid>
              <Grid item xs={6} sm={7} md={10}>
                {paymentMethodText}
              </Grid>
            </Grid>
          </Grid>
        )}
        {!orderComplete ? (
          <Grid item xs={12}>
            <Grid className={classes.agreementContainer} container spacing={1}>
              <Grid item xs={12}>
                <Field name='firstAgreement' type='checkbox'>
                  {(props: any) => (
                    <FormControlLabel
                      className={classes.agreementLabel}
                      control={
                        <MuiCheckbox
                          {...props.input}
                          className={classes.agreementCheckbox}
                          color='primary'
                          required
                        />
                      }
                      label={
                        <>
                          By selecting and confirming, I am opting for my
                          account to auto-renew. Meaning it will automatically
                          renew, charging the account on file at the end of the
                          current contact.
                          <span className={classes.space} />
                          <a href='#review-full-terms'>Review full terms</a>
                        </>
                      }
                    />
                  )}
                </Field>
              </Grid>
              <Grid item xs={12}>
                <Field name='secondAgreement' type='checkbox'>
                  {(props: any) => (
                    <FormControlLabel
                      className={classes.agreementLabel}
                      control={
                        <MuiCheckbox
                          {...props.input}
                          className={classes.agreementCheckbox}
                          color='primary'
                          required
                        />
                      }
                      label={
                        <>
                          By selecting and confirming, you agree to{' '}
                          {firstLogoAlt}'s{' '}
                          <a
                            href='https://www.phiredup.com/privacy-policy'
                            rel='noreferrer'
                            target='_blank'
                          >
                            Privacy Policy
                          </a>
                          {' & '}
                          <a
                            href='https://www.phiredup.com/eula'
                            rel='noreferrer'
                            target='_blank'
                          >
                            End User License Agreement
                          </a>
                        </>
                      }
                    />
                  )}
                </Field>
              </Grid>
              <Grid item xs={12}>
                <Field name='thirdAgreement' type='checkbox'>
                  {(props: any) => (
                    <FormControlLabel
                      className={classes.agreementLabel}
                      control={
                        <MuiCheckbox
                          {...props.input}
                          className={classes.agreementCheckbox}
                          color='primary'
                          required
                        />
                      }
                      label={
                        <>
                          By selecting and confirming, you agree to Stripes
                          <span className={classes.space} />
                          <a
                            href='https://stripe.com/legal/consumer'
                            rel='noreferrer'
                            target='_blank'
                          >
                            Privacy Policy
                          </a>
                        </>
                      }
                    />
                  )}
                </Field>
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <Grid item xs={12}>
            <Grid className={classes.agreementContainer} container spacing={1}>
              <Grid className={classes.successMessage} item xs={12}>
                A receipt was sent to your email address. If you did not receive
                it, you can always access it from the billing page.
              </Grid>
            </Grid>
          </Grid>
        )}
        {(isSubscriptionPlanUpdated || isNewSubscriptionCreated) &&
          stripeConfirmPaymentIntent && (
            <Grid item xs={12}>
              <StripeField
                stripePaymentStatus={stripePaymentStatus}
                onStripePaymentStatusChange={setStripePaymentStatus}
                callSubmitGlobalForm={() => {}}
                stripeConfirmPaymentIntent={stripeConfirmPaymentIntent}
                onlyConfirmPaymentIntent
              />
            </Grid>
          )}
      </Grid>
    );
  }, [
    stripePaymentStatus,
    paymentMethodText,
    newSubscriptionPlanInterval,
    newSubscriptionName,
    isSubscriptionPlanUpdated,
    isNewSubscriptionCreated,
    stripeConfirmPaymentIntent,
    isItNewSubscription,
    willSubscriptionBeChanged,
    currentSubscriptionName,
    currentSubscriptionPlanInterval,
    totalPriceText,
    getSubscriptionNameYearlyOrMonthly,
    orderComplete,
    firstLogoAlt,
    classes.container,
    classes.informationContainer,
    classes.agreementContainer,
    classes.agreementLabel,
    classes.agreementCheckbox,
    classes.space,
    classes.successMessage,
  ]);

  const validateForm = useCallback(values => validate(values, SCHEMA), []);

  const handleSubmitForm = useCallback(() => {
    if (
      willSubscriptionBeChanged &&
      !isItNewSubscription &&
      !!newSubscriptionPriceId &&
      !!currentSubscriptionId
    ) {
      setErrorMessage(null);
      updateSubscriptionPlanMethod({
        stripePriceId: newSubscriptionPriceId,
        subscriptionId: currentSubscriptionId,
      });
      return;
    }

    if (isItNewSubscription) {
      setErrorMessage(null);
      if (wasGroupCreated || !shouldGroupBeCreated) {
        const groupId =
          shouldGroupBeCreated && lastAddedGroupId
            ? lastAddedGroupId
            : !shouldGroupBeCreated && currentGroupId
            ? currentGroupId
            : null;
        if (
          groupId &&
          newSubscriptionPriceId &&
          (newPaymentMethodId || !paymentMethodSetup)
        ) {
          createNewSubscription({
            groupId,
            stripePriceId: newSubscriptionPriceId,
            ...(paymentMethodSetup && newPaymentMethodId
              ? { paymentMethodId: newPaymentMethodId }
              : {}),
            ...(newStripeEmail ? { stripeEmail: newStripeEmail } : {}),
          });
        } else {
          setErrorMessage(
            'There are some issues with your request. Please update this page and try again or log out and sign in again.'
          );
        }
        return;
      }
      if (groupFormValues) {
        const { organization, council } = groupFormValues;
        dispatch(
          createGroup({
            formName: 'newGroupForm',
            name: `${organization.label} at ${council.label}`,
            displayName: `${organization.label}`,
            type: 'GREEK_CHAPTER',
            parents: [council.value, organization.value],
            redirectToGroup: false,
          })
        );
        return;
      }
    }

    setErrorMessage(
      'There are some issues with your request. Please update this page and try again.'
    );
  }, [
    isItNewSubscription,
    willSubscriptionBeChanged,
    shouldGroupBeCreated,
    currentGroupId,
    currentSubscriptionId,
    newSubscriptionPriceId,
    newPaymentMethodId,
    newStripeEmail,
    updateSubscriptionPlanMethod,
    setErrorMessage,
    dispatch,
    groupFormValues,
    wasGroupCreated,
    lastAddedGroupId,
    createNewSubscription,
    paymentMethodSetup,
  ]);

  const getNewFeatures = useCallback(() => {
    if (currentGroupId) {
      dispatch(fetchCurrentGroup({ id: currentGroupId }));
    }
  }, [dispatch, currentGroupId]);

  const onSuccessForm = useCallback(() => {
    dispatch(fetchStripeConfig());
  }, [dispatch]);

  const handleClose = useCallback(() => {
    if (isSuccess && !orderComplete) {
      onCurrentModalStatusChange(ManageSubscriptionStatus.OrderComplete);
      return;
    }

    onCurrentModalStatusChange(newCurrentModalStatus => {
      if (isItNewSubscription && !orderComplete) {
        return newCurrentModalStatus;
      }

      onClose();

      if (orderComplete) {
        getNewFeatures();
        return ManageSubscriptionStatus.OrderCompleteClose;
      }

      return ManageSubscriptionStatus.Closed;
    });
  }, [
    onCurrentModalStatusChange,
    getNewFeatures,
    isSuccess,
    orderComplete,
    onClose,
    isItNewSubscription,
  ]);

  const handleClickBack = useCallback(() => {
    if (isItNewSubscription && !orderComplete) {
      onCurrentModalStatusChange(
        isItFreeSubscription(subscriptionInfo.newSubscription)
          ? ManageSubscriptionStatus.SelectYourPlan
          : ManageSubscriptionStatus.EnterPaymentMethod
      );
    }
  }, [
    onCurrentModalStatusChange,
    isItNewSubscription,
    orderComplete,
    subscriptionInfo,
  ]);

  useEffect(() => {
    if (isSubscriptionPlanUpdated) {
      if (
        subscriptionPlanData?.clientSecret &&
        subscriptionPlanData?.paymentMethod
      ) {
        setStripePaymentStatus(StripePaymentStatus.SendRequestToConfirmPIS);
      }
      onSuccessForm();
    }
  }, [isSubscriptionPlanUpdated]); // eslint-disable-line

  useEffect(() => {
    if (isItNewSubscription && isNewSubscriptionCreated) {
      if (createNewSubscriptionData?.clientSecret) {
        setStripePaymentStatus(StripePaymentStatus.SendRequestToConfirmPIS);
      } else {
        if (onSuccessCreatingSubscription) {
          onSuccessCreatingSubscription(lastAddedGroupId);
        }
        onSuccessForm();
      }
    }
  }, [isNewSubscriptionCreated]); // eslint-disable-line

  useEffect(() => {
    if (
      isItNewSubscription &&
      isNewSubscriptionCreated &&
      stripePaymentStatus === StripePaymentStatus.ConfirmPISSuccess &&
      createNewSubscriptionData?.clientSecret &&
      onSuccessCreatingSubscription
    ) {
      onSuccessCreatingSubscription(lastAddedGroupId);
    }
  }, [stripePaymentStatus]); // eslint-disable-line

  useEffect(() => {
    if (
      [
        StripePaymentStatus.ConfirmPISSuccess,
        StripePaymentStatus.ConfirmPISError,
      ].includes(stripePaymentStatus)
    ) {
      onSuccessForm();
    }
  }, [stripePaymentStatus, onSuccessForm]);

  useEffect(() => {
    if (isItNewSubscription && newGroupFormSubmitSucceeded) {
      setWasGroupCreated(true);
      if (
        newSubscriptionPriceId &&
        (newPaymentMethodId || !paymentMethodSetup) &&
        lastAddedGroupId
      ) {
        createNewSubscription({
          groupId: lastAddedGroupId,
          stripePriceId: newSubscriptionPriceId,
          ...(newPaymentMethodId && paymentMethodSetup
            ? { paymentMethodId: newPaymentMethodId }
            : {}),
          ...(newStripeEmail ? { stripeEmail: newStripeEmail } : {}),
        });
      }
    }
  }, [newGroupFormSubmitSucceeded]); // eslint-disable-line

  useEffect(() => {
    if (isItNewSubscription && newGroupFormSubmitFailed) {
      const errorMessage = Map.isMap(newGroupFormError)
        ? newGroupFormError.get('message')
        : '';
      setErrorMessage(
        errorMessage
          ? toString(errorMessage)
          : "Can't subscribe. There is some issue in your subscription."
      );
    }
  }, [newGroupFormSubmitFailed]); // eslint-disable-line

  return (
    <Form
      id={FORM}
      validate={validateForm}
      onSubmit={handleSubmitForm}
      initialValues={initialValues}
      initialValuesEqual={isEqual}
      render={({ handleSubmit, invalid, form: { restart } }) => (
        <DazzlingDialog
          acceptLabel='Place Order'
          rejectLabel={
            isItNewSubscription && !orderComplete ? 'Back' : 'Cancel'
          }
          disabled={invalid}
          handleClose={handleClose}
          headerProps={{
            icon: CheckIcon,
            title: orderComplete ? 'Order Complete!' : 'Confirm Order',
            alignItems: 'center',
          }}
          open={open}
          children={dialogContent}
          alertProps={{
            message: formMessage,
            color: isSuccess ? 'success' : 'error',
          }}
          isLoading={isLoading}
          isError={isError}
          isSuccess={isSuccess}
          hideSnackbar={orderComplete || isLoading}
          resetForm={restart}
          onReject={handleClickBack}
          {...(orderComplete
            ? {
                actions: [
                  <Button
                    color='primary'
                    variant='contained'
                    onClick={handleClose}
                  >
                    Close
                  </Button>,
                ],
              }
            : {
                onAccept: handleSubmit,
              })}
          isBackgroundWhite
          isButtonOnTheRightCorner
          isFullWidth
        />
      )}
    />
  );
};

const useStyles = makeStyles(theme => ({
  container: {
    marginBottom: 10,
  },
  informationContainer: {
    fontSize: '1.05rem',
    lineHeight: '1.6em',
    letterSpacing: '0.5px',
    fontWeight: 600,
    color: theme.palette.common.black,
  },
  space: {
    display: 'inline-block',
    fontSize: 'inherit',
    width: '0.3em',
    '&.largeWidth': {
      width: '2em',
      [theme.breakpoints.down('sm')]: {
        width: '0.3em',
      },
    },
  },
  textLite: {
    display: 'inline-block',
    fontWeight: 300,
    fontStyle: 'italic',
    fontSize: 'inherit',
    letterSpacing: 'inherit',
  },
  agreementContainer: {
    marginTop: 30,
  },
  agreementLabel: {
    fontWeight: 500,
    fontSize: '0.87rem',
    alignItems: 'flex-start',
    '& .MuiTypography-root': {
      fontSize: 'inherit',
      fontWeight: 500,
      paddingTop: 11,
      paddingLeft: 4,
      color: theme.palette.background.paper,
      '& a': {
        color: theme.palette.background.paper,
        fontWeight: 700,
        textDecoration: 'none',
        '&:hover': {
          textDecoration: 'underline',
        },
      },
    },
  },
  agreementCheckbox: {
    color: theme.palette.primary.main,
  },
  successMessage: {
    fontSize: '0.85rem',
    lineHeight: '1.5em',
    fontWeight: 'normal',
  },
}));

export default ConfirmOrderModal;
