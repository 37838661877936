import {
  TAG_FETCH_REQUEST,
  TAG_CREATE_REQUEST,
  TAG_UPDATE_REQUEST,
  TAG_DELETE_REQUEST,
  TAG_CLEAR,
} from 'store/constants/tagTypes';

export function fetchTagsAction({ groupId }: any) {
  return {
    type: TAG_FETCH_REQUEST,
    payload: { groupId },
  };
}

export function createTagAction({ formName, fields }: any) {
  return {
    type: TAG_CREATE_REQUEST,
    payload: { formName, fields },
  };
}

export function updateTagAction({ formName, id, fields }: any) {
  return {
    type: TAG_UPDATE_REQUEST,
    payload: { formName, id, fields },
  };
}

export function deleteTagAction({ formName, id }: any) {
  return {
    type: TAG_DELETE_REQUEST,
    payload: { formName, id },
  };
}

export function clearTagsAction() {
  return {
    type: TAG_CLEAR,
  };
}
