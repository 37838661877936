import { Map } from 'immutable';
// constants
import {
  BILLING_FETCH_STRIPE_CONFIG,
  BILLING_CREATE_PORTAL,
} from 'store/constants/billingTypes';
// helpers
import httpReducer from './helpers/httpReducer';

export default function reduceBilling(state = Map(), action = {}) {
  switch ((action as any).type) {
    case BILLING_FETCH_STRIPE_CONFIG:
      return httpReducer(state, action, {
        mergeItems: false,
        entity: 'billing',
      });
    case BILLING_CREATE_PORTAL:
      return httpReducer(state, action);
    default:
      return state;
  }
}
