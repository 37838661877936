import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

// action creators
import { doneIndicator } from 'store/actions/httpActions';
import { createFormField as createAction } from 'store/actions/formFieldActions';

// api
import client from 'sources/api';

// constants
import {
  FORM_FIELD_CREATE_REQUEST,
  FORM_FIELD_CREATE,
} from 'store/constants/formFieldTypes';

// schemas
import { formFieldSchema } from 'store/schemas/formFieldSchema';

// helpers
import httpSaga from 'store/sagas/httpSaga';

const WATCH_TYPE = FORM_FIELD_CREATE_REQUEST;
const TYPE = FORM_FIELD_CREATE;

export function* createFormField(action: ReturnType<typeof createAction>) {
  const {
    payload: { formName, groupId: group_id, formId: form_id, ...values },
  } = action;

  const {
    label,
    description,
    required,
    fieldType: field_type,
    options,
    isPublished: is_published,
    type,
  } = values;

  const META_ID = `create-${values.label}`;

  const endpoint = `/form_fields?group_id=${group_id}&form_id=${form_id}`;

  // @ts-expect-error ts-migrate(2569) FIXME: Type 'Generator<any, any, unknown>' is not an arra... Remove this comment to see the full error message
  yield* httpSaga(
    TYPE,
    call(client.post, endpoint, {
      label,
      description,
      required,
      ...(field_type ? { field_type } : {}),
      ...(options ? { options } : {}),
      ...(is_published ? { is_published } : {}),
      type,
    }),
    {
      schema: formFieldSchema,
      metaId: META_ID,
      formName,
    }
  );

  yield put(doneIndicator(TYPE));
}

export function* watch() {
  yield takeEvery(WATCH_TYPE, createFormField);
}

export default function* root() {
  yield all([fork(watch)]);
}
