import React from 'react';
import { isEqual } from 'lodash';

// redux hooks
import { useSelector } from 'react-redux';

// components
import ToggleChip from 'components/ToggleChip';
import Comments from 'components/Comments';

// MUI
import { useTheme } from '@material-ui/core/styles';
import { Grid, Box, Typography, useMediaQuery } from '@material-ui/core';

// selectors
import getSliceEntityById from 'store/selectors/getSliceEntityById';
import getSliceState from 'store/selectors/getSliceState';

const NoteCreatedDetails = ({
  id,
  activityId,
  totalComments,
}: {
  id: number;
  activityId: number;
  totalComments: number;
}) => {
  const theme = useTheme();
  const isExtraSmall = useMediaQuery(theme.breakpoints.only('xs'));

  const { milestones, tags, note = {} } = useSelector(
    state => ({
      milestones: getSliceState('milestone')(state).data,
      tags: getSliceState('tag')(state).data,
      note: getSliceEntityById('note', id)(state),
    }),
    isEqual
  );

  const {
    body = '',
    tagIds = [],
  }: {
    body?: string;
    tagIds?: any[];
  } = note;

  const noteMilestones = milestones.filter(({ id }: any) =>
    tagIds.includes(id)
  );
  const noteTags = tags.filter(({ id }: any) => tagIds.includes(id));

  return (
    <Grid container direction='column' spacing={2}>
      <Grid item>
        <Box marginLeft={!isExtraSmall && 8}>
          <Grid container direction='column' spacing={2}>
            {note?.id ? (
              <>
                {body && (
                  <Grid item>
                    <Grid container spacing={3}>
                      <Grid item>
                        <Box
                          width={4}
                          height='100%'
                          borderRadius={4}
                          bgcolor='background.default'
                        />
                      </Grid>
                      <Grid item xs>
                        <Typography
                          variant='body2'
                          style={{ wordBreak: 'break-word' }}
                        >
                          {body}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
                {(noteMilestones.length > 0 || noteTags.length > 0) && (
                  <Grid item>
                    <Grid container spacing={2}>
                      {noteMilestones.map(({ id, title }: any) => (
                        <Grid key={id} item>
                          <ToggleChip
                            // @ts-expect-error ts-migrate(2322) FIXME: Type '{ children: any; ring: true; selected: true;... Remove this comment to see the full error message
                            ring
                            selected
                            disabled
                            tooltipDisabled
                            hideSelectIcon
                          >
                            {title}
                          </ToggleChip>
                        </Grid>
                      ))}
                      {noteTags.map(({ id, title, color }: any) => (
                        <Grid key={id} item>
                          <ToggleChip
                            // @ts-expect-error ts-migrate(2322) FIXME: Type '{ children: any; color: string; selected: tr... Remove this comment to see the full error message
                            color={'#' + color}
                            selected
                            disabled
                            tooltipDisabled
                            hideSelectIcon
                          >
                            {title}
                          </ToggleChip>
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                )}
              </>
            ) : (
              <Grid item>
                <Grid container spacing={3}>
                  <Grid item>
                    <Box
                      width={4}
                      height='100%'
                      borderRadius={4}
                      bgcolor='background.default'
                    />
                  </Grid>
                  <Grid item xs>
                    <Typography variant='body2'>
                      This note has been deleted.
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Box>
      </Grid>
      {note?.id && (
        <Grid item>
          <Comments activityId={activityId} total={totalComments} />
        </Grid>
      )}
    </Grid>
  );
};

export default NoteCreatedDetails;
