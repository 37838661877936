import Immutable, { List, Map } from 'immutable';
import { denormalize } from 'normalizr';

import { groupFieldSchema } from '../schemas/groupFieldSchema';

// selectors
import getGroupValues from 'store/selectors/getGroupValues';
import createDeepEqualSelector from 'helpers/createDeepEqualSelector';
import getSliceItems from './getSliceItems';
import getSliceResult from './getSliceResult';

export default function getGroupFields(
  sources?: any,
  format?: any,
  resultKey = 'result'
) {
  return createDeepEqualSelector(
    getSliceResult('groupField', resultKey),
    getGroupValues,
    getSliceItems('groupField'),
    (result: any, groupValues: any, groupFieldSlice: any) => {
      const entities = {
        groupField: groupFieldSlice.toJS(),
      };

      let items = denormalize(result.toJS(), [groupFieldSchema], entities);

      items = Immutable.fromJS(items);
      let filteredItems = List();

      if (sources) {
        // filters by an array of strings containing one of these 3 sources: ['PNMAttr', 'FormField', 'GroupValue']
        sources.forEach((source: any) => {
          let newItems = items.filter((item: any) =>
            item.get('sources', List()).includes(source)
          );

          // Adds description property to items
          if (source === 'GroupValue') {
            newItems = newItems.map((item = Map()) => {
              const description = groupValues
                .find(
                  (groupValue: any) =>
                    groupValue.get('label') === item.get('label'),
                  {},
                  Map()
                )
                .get('description', '');

              return item.set('description', description);
            });
          }

          // Check for duplicate fields
          newItems.forEach((newItem: any) => {
            const alreadyExists = filteredItems.find(
              (filteredItem: any) =>
                filteredItem.get('id') === newItem.get('id')
            );
            if (!Boolean(alreadyExists)) {
              filteredItems = filteredItems.push(newItem);
            }
          });
        });
      } else {
        // gets all group fields
        filteredItems = items;
      }

      let sortedItems = filteredItems.sortBy((item: any = Map()) =>
        item.get('position')
      );

      if (format === 'suggestions') {
        sortedItems = sortedItems.map((item: any = Map()) => ({
          label: item.get('label'),
          value: item.get('id'),
        }));
      }

      return sortedItems;
    }
  );
}
