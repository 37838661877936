import React, { useContext } from 'react';

// material-ui
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';

// helpers
import { SiteVisualDataContext } from 'components/SiteVisualData';

type Props = {
  classes: any; // TODO: PropTypes.instanceOf(Object)
};

const LoadingScreen = ({ classes }: Props) => {
  const { secondLogo, secondLogoAlt } = useContext(SiteVisualDataContext);

  return (
    <Grid
      container
      direction='column'
      alignItems='center'
      justifyContent='center'
    >
      <img className={classes.logo} src={secondLogo} alt={secondLogoAlt} />

      <CircularProgress size={30} />
    </Grid>
  );
};

const styles = {
  logo: {
    width: '50%',
  },
};

export default withStyles(styles)(LoadingScreen);
