import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

// action creators
import { doneIndicator, success } from 'store/actions/httpActions';

// api
import client from 'sources/api';

// constants
import { REQUEST_DELETE_REQUEST } from 'store/constants/requestTypes';

// lib
import httpSaga from 'store/sagas/httpSaga';
import getBaseType from '../helpers/getBaseType';

import Immutable from 'immutable';

// schema
import { requestSchema } from 'store/schemas/requestSchema';

const WATCH_TYPE = REQUEST_DELETE_REQUEST;

export function* deleteRequest(action: any) {
  const { formName, id } = action.payload;
  const FORM = formName;
  const TYPE = getBaseType(action.type);

  try {
    // @ts-expect-error ts-migrate(2569) FIXME: Type 'Generator<any, any, unknown>' is not an arra... Remove this comment to see the full error message
    yield* httpSaga(TYPE, call(client.delete, `/requests/${id}`), {
      schema: requestSchema,
      formName: FORM,
      dispatchSuccess: false,
      metaId: `delete-${id}`,
    });

    yield put(
      success(
        TYPE,
        Immutable.fromJS({
          id,
        })
      )
    );
  } finally {
    yield put(doneIndicator(TYPE));
  }
}

export function* watch() {
  yield takeEvery(WATCH_TYPE, deleteRequest);
}

export default function* root() {
  yield all([fork(watch)]);
}
