import React, { useContext, useMemo } from 'react';
import { isEqual } from 'lodash';
import { useSelector } from 'react-redux';

// material-ui
import {
  Grid,
  Box,
  Card,
  CardContent,
  Typography,
  Theme,
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { NewReleases } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';

// components
import SuperAvatar from 'components/SuperAvatar';

// selectors
import getSliceState from 'store/selectors/getSliceState';

// helpers
import { SiteVisualDataContext } from 'components/SiteVisualData';

type DeletedLeadCardProps = {
  canViewDeletedLead: boolean;
};

export default function DeletedLeadCard({
  canViewDeletedLead,
}: DeletedLeadCardProps) {
  const { loading, data } = useSelector(getSliceState('activity'), isEqual);
  const classes = useStyles();

  const [avatar, header, info] = useComponents(
    loading,
    data,
    canViewDeletedLead,
    classes
  );

  if (loading && data.length === 0 && canViewDeletedLead) return null;

  return (
    <Card>
      <CardContent>
        {canViewDeletedLead ? (
          <Grid container wrap='nowrap' spacing={2}>
            <Grid item>{avatar}</Grid>
            <Grid item xs>
              {header}
              {info}
            </Grid>
          </Grid>
        ) : (
          <Grid container spacing={2}>
            <Grid item>
              <DeletedLead canViewDeletedLead={false} />
            </Grid>
          </Grid>
        )}
      </CardContent>
    </Card>
  );
}

const useComponents = (
  loading: any,
  data: any,
  canViewDeletedLead: any,
  classes: any
) =>
  useMemo(() => {
    if (loading)
      return [
        <Skeleton variant='circle' width={175} height={175} />,
        <Skeleton height={40} width={180} />,
        <>
          <Skeleton height={30} width={250} />
          <Skeleton height={30} width={250} />
        </>,
      ];
    else {
      let leadFullName = '';
      if (data.length > 0) {
        const { leadLabel = '' } = data[data.length - 1];
        leadFullName = leadLabel;
      }
      return [
        <SuperAvatar fullName={leadFullName} size='huge' background='dark' />,

        <Box clone height={42}>
          <Grid item xs={12} zeroMinWidth>
            <Typography className={classes.leadProfileName} noWrap>
              {leadFullName}
            </Typography>
          </Grid>
        </Box>,
        <DeletedLead canViewDeletedLead={canViewDeletedLead} />,
      ];
    }
  }, [loading, data, canViewDeletedLead, classes]);

const useStyles = makeStyles((theme: Theme) => ({
  leadProfileName: {
    fontSize: 20,
    [theme.breakpoints.only('xs')]: {
      fontSize: 18,
      textAlign: 'center',
    },
    fontWeight: 500,
  },
}));

type DeletedLeadProps = {
  canViewDeletedLead: boolean;
};

const DeletedLead = ({ canViewDeletedLead }: DeletedLeadProps) => {
  const { deletedLeadCardMessage } = useContext(SiteVisualDataContext);
  return (
    <Box
      border={2}
      borderRadius={'borderRadius'}
      borderColor={'background.lightPaper'}
      padding={2}
      width={'50%'}
    >
      <Grid container alignItems={'center'} spacing={2}>
        <Grid item>
          <NewReleases color={'primary'} />
        </Grid>
        <Grid item>
          <Typography variant={'subtitle1'} color={'primary'} gutterBottom>
            <i>
              <b>Deleted</b>
            </i>
          </Typography>
        </Grid>
      </Grid>
      <Typography variant={'body2'} color={'textPrimary'}>
        {deletedLeadCardMessage}
      </Typography>

      {canViewDeletedLead && (
        <Typography variant={'body2'} color={'textSecondary'}>
          You may still view their activity feed, but all other data can no
          longer be accessed.
        </Typography>
      )}
    </Box>
  );
};
