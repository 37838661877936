import {
  all,
  call,
  fork,
  put,
  takeEvery,
  select,
  take,
} from 'redux-saga/effects';
import Immutable, { Map } from 'immutable';
import moment from 'moment';

// action creators
import {
  doneIndicator,
  success,
  error,
  errorIndicator,
} from 'store/actions/httpActions';
import { fetchTask } from 'store/actions/apiTaskActions';
import { createFileExport } from 'store/actions/fileExportActions';

import {
  setFormSubmitFailed,
  setFormSubmitSucceeded,
} from 'store/actions/formActions';

// api
import client from 'sources/api';

// schemas
import { apiTaskSchema } from 'store/schemas/apiTaskSchema';

// constants
import {
  API_TASK_CREATE_EXPORT_REQUEST,
  API_TASK_CREATE_EXPORT,
} from 'store/constants/apiTaskTypes';

// helpers
import httpSaga from 'store/sagas/httpSaga';
import getSliceEntityById from 'store/selectors/getSliceEntityById';
import delayTwoSeconds from 'helpers/delayTwoSeconds';
import humps from 'humps';

const WATCH_TYPE = API_TASK_CREATE_EXPORT_REQUEST;
const TYPE = API_TASK_CREATE_EXPORT;

export function* createExportSaga(action: any) {
  const {
    payload: { groupId, exportType: export_type, formName, ...exportParams },
  } = action;

  const formattedExportParams = {};

  // converts params to snake case for API usage
  for (let property in exportParams) {
    const key = humps.decamelize(property);

    // Format date properties for API usage
    if (key.includes('Date')) {
      property = moment(property)?.toISOString();
    }
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    formattedExportParams[key] = exportParams[property];
  }

  // Make a request to start an API task to generate the report
  // @ts-expect-error ts-migrate(2569) FIXME: Type 'Generator<any, any, unknown>' is not an arra... Remove this comment to see the full error message
  const taskPostResponse = yield* httpSaga(
    TYPE,
    call(client.post, `/tasks?group_id=${groupId}`, {
      name: 'generate_export',
      params: {
        export_type,
        ...formattedExportParams,
      },
    }),
    {
      schema: apiTaskSchema,
      formName,
      dispatchSuccesss: false,
      dispatchFormSuccess: false,
    }
  );

  // If there is no task response, dispatch done indicator and abort
  if (!taskPostResponse) {
    yield put(doneIndicator(TYPE));
    return;
  }

  // @ts-expect-error ts-migrate(2525) FIXME: Initializer provides no value for this binding ele... Remove this comment to see the full error message
  const { data: { taskId } = {} } = taskPostResponse || {};

  let resolvedTask;

  // Use the worker/task ID to hit an endpoint periodically to check on the status
  while (!resolvedTask) {
    yield delayTwoSeconds();

    yield put(fetchTask({ taskId, groupId }));
    yield take('API_TASK_FETCH_DONE');

    const task = yield select(state =>
      getSliceEntityById('apiTask', taskId.toString())(state)
    );

    if (task?.status && task?.status !== 'PROCESSING') {
      resolvedTask = task;
    }
  }
  if (resolvedTask?.status === 'FAILURE') {
    // Notify the store that this type had an error
    const taskData = yield select(state =>
      getSliceEntityById('apiTask', taskId.toString())(state)
    );

    // @ts-expect-error ts-migrate(2525) FIXME: Initializer provides no value for this binding ele... Remove this comment to see the full error message
    const { result: { excMessage = '', errors } = {} } = taskData;

    const message = {
      data: {
        msg: {
          general: excMessage,
          list: errors ? Immutable.fromJS(errors) : [],
        },
      },
    };
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 2.
    yield put(errorIndicator(TYPE, message));

    // Dispatch an error payload for this type to be caught by the reducer
    yield put(setFormSubmitFailed(formName, message));
    yield put(error(TYPE, message));
  } else {
    yield put(success(TYPE, Map()));
    yield put(
      createFileExport({
        exportData: resolvedTask?.result,
        type: export_type,
      })
    );
    yield put(setFormSubmitSucceeded(formName, 'Exported successfully'));
  }

  yield put(doneIndicator(TYPE));
}

export function* watch() {
  yield takeEvery(WATCH_TYPE, createExportSaga);
}

export default function* root() {
  yield all([fork(watch)]);
}
