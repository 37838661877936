import React from 'react';
import { Field } from 'react-final-form';

// components
import TextField from 'components/TextField';
import CampusDropdown from 'components/CampusDropdown';
import ChapterDropdownSearch from 'components/ChapterDropdownSearch';

//helpers
import {
  FORM_FIELD_CAMPUS,
  FORM_FIELD_REFER_TO_CHAPTER,
} from 'routes/ReferralForm/FormView/helpers/fieldsWithSpecialTypes';
import { isEmpty } from 'helpers/check';
import { toLowerCase } from 'helpers/transform';

export interface FieldWithTypeSelectProps {
  name: string;
  label: string;
  options: { label: string; value: string | number }[];
  groupId: number;
  required?: boolean;
  description?: string | null;
  isUniversalForm?: boolean;
  isOrganizationForm?: boolean;
  isCouncilAdmin?: boolean;
  value?: boolean | null | (string | number)[] | string;
  isImmutable?: boolean;
  token?: string | null;
}

const FieldWithTypeSelect = ({
  name,
  required,
  label,
  groupId,
  description,
  options,
  isUniversalForm,
  isOrganizationForm,
  isCouncilAdmin,
  isImmutable,
  token,
}: FieldWithTypeSelectProps) => {
  const getTextField = (props: any) => {
    if (
      isUniversalForm &&
      isImmutable &&
      toLowerCase(label) === FORM_FIELD_CAMPUS
    ) {
      return <CampusDropdown token={token} {...props} />;
    }

    if (
      isOrganizationForm &&
      isImmutable &&
      toLowerCase(label) === FORM_FIELD_REFER_TO_CHAPTER
    ) {
      return (
        <ChapterDropdownSearch
          labelFormat='suggestionsByName'
          currentGroupId={groupId}
          isCouncilAdmin={isCouncilAdmin}
          token={token}
          {...props}
        />
      );
    }

    return (
      <TextField
        {...props}
        {...(!isEmpty(description) ? { helperText: description } : {})}
        options={options}
        select
      />
    );
  };

  return (
    <Field name={name} required={required} label={label}>
      {getTextField}
    </Field>
  );
};

export default FieldWithTypeSelect;
