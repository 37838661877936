import { Map } from 'immutable';
// constants
import {
  LEAD_REFERRAL_SOURCE_FETCH,
  LEAD_REFERRAL_SOURCE_CREATE,
  LEAD_REFERRAL_SOURCE_DELETE,
} from 'store/constants/leadReferralSourceTypes';
// helpers
import httpReducer from './helpers/httpReducer';
// callbacks
import removeAttributeById from './callbacks/removeAttributeById';

export default function reduceLeadReferralSource(state = Map(), action = {}) {
  switch ((action as any).type) {
    case LEAD_REFERRAL_SOURCE_CREATE:
      return httpReducer(state, action, { updateResult: false });
    case LEAD_REFERRAL_SOURCE_FETCH:
      return httpReducer(state, action);
    case LEAD_REFERRAL_SOURCE_DELETE:
      return httpReducer(state, action, { callback: removeAttributeById });
    default:
      return state;
  }
}
