import { Styles } from 'assets/types';

const leadListFilterStyles: Styles = () => ({
  selectedFiltersHeader: {
    marginTop: 20,
  },
  addFilterButton: {
    marginTop: 24,
  },
  selector: { marginBottom: 24 },
});

export default leadListFilterStyles;
