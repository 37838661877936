import React, { useCallback, useMemo, useRef } from 'react';
import { Moment } from 'moment';
import { FieldRenderProps } from 'react-final-form';
import moment from 'moment';
import MomentUtils from '@date-io/moment';

// material-ui
import { InputAdornment, IconButton } from '@material-ui/core';
import {
  MuiPickersUtilsProvider,
  DatePicker,
  KeyboardDatePicker,
  TimePicker,
  KeyboardTimePicker,
} from '@material-ui/pickers';

// icons
import { Event, CloseSharp, AccessTime } from '@material-ui/icons';

export default function DateSelector({
  input: { name, onChange, value },
  withStartAdornment = true,
  label = 'Date',
  pickerType = 'date',
  pickerFormat = '',
  keyboard = false,
  fullWidth = false,
  required = false,
  placeholder = 'All time',
  ...rest
}: FieldRenderProps<Moment | string | null, HTMLElement>) {
  const inputRef = useRef();
  const setFocus = useCallback(
    focusState => () =>
      // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
      focusState ? inputRef.current.focus() : inputRef.current.blur(),
    [inputRef]
  );
  const clear = useCallback(
    event => {
      event.stopPropagation();
      onChange(null);
    },
    [onChange]
  );
  const Picker = useMemo<React.ElementType>(() => {
    switch (pickerType) {
      case 'date':
        return keyboard ? KeyboardDatePicker : DatePicker;
      case 'time':
        return keyboard ? KeyboardTimePicker : TimePicker;
      default:
        return DatePicker;
    }
  }, [pickerType, keyboard]);
  return (
    <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
      <Picker
        {...rest}
        name={name}
        PopoverProps={
          !keyboard && {
            anchorEl: inputRef.current,
            disableRestoreFocus: true,
            disableEnforceFocus: true,
            disableAutoFocus: true,
          }
        }
        autoOk
        format={pickerFormat || (pickerType === 'date' && 'll')}
        disableToolbar
        fullWidth={fullWidth}
        variant='inline'
        inputVariant='outlined'
        label={label}
        value={value || null}
        onChange={onChange}
        placeholder={placeholder}
        clearable
        InputAdornmentProps={
          !keyboard && {
            endAdornment: value && (
              <InputAdornment position='end'>
                <IconButton
                  id={'clear-date'}
                  onClick={clear}
                  onMouseEnter={setFocus(true)}
                  onMouseLeave={setFocus(false)}
                >
                  <CloseSharp />
                </IconButton>
              </InputAdornment>
            ),
          }
        }
        keyboardIcon={<AccessTime />}
        InputProps={
          !keyboard && {
            inputRef: inputRef,
            startAdornment: withStartAdornment && (
              <InputAdornment position='start'>
                {pickerType === 'time' ? <AccessTime /> : <Event />}
              </InputAdornment>
            ),
            endAdornment: value && (
              <InputAdornment position='end'>
                <IconButton
                  id={'clear-date'}
                  onClick={clear}
                  onMouseEnter={setFocus(true)}
                  onMouseLeave={setFocus(false)}
                >
                  <CloseSharp />
                </IconButton>
              </InputAdornment>
            ),
          }
        }
        style={{ minWidth: 220 }}
      />
    </MuiPickersUtilsProvider>
  );
}
