import { useRef, useEffect, useState, useCallback } from 'react';
const useScrollTop = (topOffset = 0) => {
  const [isTop, setIsTop] = useState(false);
  const scrollRef = useRef(null);
  const onScroll = useCallback(() => {
    if (scrollRef.current) {
      // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
      setIsTop(scrollRef.current.scrollTop === topOffset);
    }
  }, [topOffset]);
  useEffect(() => {
    const current = scrollRef.current;
    if (current) {
      (current as any).addEventListener('scroll', onScroll);
      return () => {
        if (scrollRef && current) {
          // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
          current.removeEventListener('scroll', onScroll);
        }
      };
    }
  }, [onScroll]);
  return [isTop, scrollRef];
};
export default useScrollTop;
