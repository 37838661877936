import { useSelector } from 'react-redux';
import { isEqual } from 'lodash';

// selectors
import getFeatures from 'store/selectors/getFeatures';

// Returns boolean of whether or not feature has a limit
export function useFeatureHasLimit(featureName: any) {
  const { features } = useSelector(
    state => ({ features: getFeatures(state) }),
    isEqual
  );
  return Boolean(features.getIn([featureName, 'limit'], false)) || false;
}

// Returns the feature's numeric limit, or false if one does not exist
export function useFeatureLimit(featureName: any) {
  const { features } = useSelector(
    state => ({ features: getFeatures(state) }),
    isEqual
  );
  const limit = features.getIn([featureName, 'limit'], false);
  return limit === null ? false : limit;
}

// Returns the feature's numeric remaining limit, or true if a limit does not exist
export function useFeatureLimitRemaining(featureName: any, numberUsed: any) {
  const { features } = useSelector(
    state => ({ features: getFeatures(state) }),
    isEqual
  );
  const limit = features.getIn([featureName, 'limit'], false);

  const remaining = limit ? (limit as any) - numberUsed : true;
  return remaining;
}

export function useIsFeatureEnabled(featureName: string): boolean {
  const { features } = useSelector(
    state => ({ features: getFeatures(state) }),
    isEqual
  );
  return features.getIn([featureName, 'enabled'], false) as boolean;
}
