import {
  EMAIL_RESEND_VERIFICATION_REQUEST,
  EMAIL_MAKE_PRIMARY_REQUEST,
  EMAIL_DELETE_REQUEST,
  EMAIL_CREATE_REQUEST,
  EMAIL_FETCH_REQUEST,
} from 'store/constants/emailTypes';

export function resendVerificationEmail(id: any) {
  return {
    type: EMAIL_RESEND_VERIFICATION_REQUEST,
    payload: { id },
  };
}

export function makeEmailPrimary(id: any) {
  return {
    type: EMAIL_MAKE_PRIMARY_REQUEST,
    payload: { id },
  };
}

export function deleteEmail(id: any) {
  return {
    type: EMAIL_DELETE_REQUEST,
    payload: { id },
  };
}

export function createEmail(email: any) {
  return {
    type: EMAIL_CREATE_REQUEST,
    payload: { email },
  };
}

export function fetchEmails(accountIds: any) {
  return {
    type: EMAIL_FETCH_REQUEST,
    payload: { accountIds },
  };
}
