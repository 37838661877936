import { all } from 'redux-saga/effects';

// sagas
import fetchActivitiesSaga from './fetchActivitiesSaga';
import fetchActivitySaga from './fetchActivitySaga';

const sessionSaga = function* rootSaga() {
  yield all([fetchActivitiesSaga(), fetchActivitySaga()]);
};

export default sessionSaga;
