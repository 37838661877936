import { PaymentMethodStatus } from 'assets/types';

export const isPaymentMethodEnabled = (
  paymentMethodStatus?: PaymentMethodStatus
): boolean => !!(paymentMethodStatus && paymentMethodStatus === 'enabled');

export const isPaymentMethodDisabled = (
  paymentMethodStatus?: PaymentMethodStatus
): boolean => !paymentMethodStatus || paymentMethodStatus === 'disabled';

export const isPaymentMethodWaiting = (
  paymentMethodStatus?: PaymentMethodStatus
): boolean => !!(paymentMethodStatus && paymentMethodStatus === 'waiting');

export const isPaymentMethodFailed = (
  paymentMethodStatus?: PaymentMethodStatus
): boolean => !!(paymentMethodStatus && paymentMethodStatus === 'failed');
