import React, { ChangeEvent } from 'react';

// material-ui
import { Checkbox, Grid, Typography, Tooltip } from '@material-ui/core';

// components
import { Field } from 'react-final-form';
import TextField from 'components/TextField';

type Props = {
  fieldPrefix: string;
  member: Lead;
  isSelected: boolean;
  handleSetSelectedIds: (
    {
      target: { checked },
    }: ChangeEvent<HTMLInputElement>,
    id: number
  ) => void;
  reportingPlatformName: string | undefined;
};

const NewMemberItem = function({
  fieldPrefix,
  member,
  isSelected,
  handleSetSelectedIds,
  reportingPlatformName,
}: Props) {
  const newMemberIsValid =
    member.emailAddress &&
    member.emailAddress.slice(-4) !== '.edu' &&
    (reportingPlatformName === 'IMIS' || member.phoneNumber);

  return (
    <Grid container alignItems='center' spacing={2}>
      <Grid item xs={1}>
        <Tooltip
          title={
            newMemberIsValid
              ? 'Select as new member'
              : `Enter valid email${
                  reportingPlatformName !== 'IMIS' ? ' and phone number ' : ' '
                }to select`
          }
        >
          <div>
            <Checkbox
              id={`checkbox_${member.id}`}
              color='primary'
              disabled={!newMemberIsValid}
              checked={isSelected}
              onChange={e => handleSetSelectedIds(e, member.id)}
            />
          </div>
        </Tooltip>
      </Grid>
      <Grid item xs={3}>
        <Typography>{member.fullName}</Typography>
      </Grid>
      <Grid item xs={4}>
        <Field
          required
          name={`${fieldPrefix}.emailAddress`}
          component={TextField}
          label='Email Address'
        />
      </Grid>
      {reportingPlatformName !== 'IMIS' && (
        <Grid item xs={4}>
          <Field
            required
            name={`${fieldPrefix}.phoneNumber`}
            render={props => (
              <TextField label='Phone Number' type='tel' {...props} />
            )}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default NewMemberItem;
