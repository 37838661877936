import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import moment from 'moment-timezone';

// action creators
import { doneIndicator } from 'store/actions/httpActions';

// api
import client from 'sources/api';

// constants
import {
  EVENT_GUEST_UPDATE_REQUEST,
  EVENT_GUEST_UPDATE,
} from 'store/constants/eventGuestTypes';

// schemas
import { eventGuestSchema } from 'store/schemas/eventGuestSchema';

// helpers
import httpSaga from 'store/sagas/httpSaga';

const WATCH_TYPE = EVENT_GUEST_UPDATE_REQUEST;
const TYPE = EVENT_GUEST_UPDATE;

export function* updateEventGuestSaga(action: any) {
  const {
    payload: {
      id,
      groupId,
      data: { invited, rsvp, attended, eventTimeSlotId: event_time_slot_id },
    },
  } = action;

  // @ts-expect-error ts-migrate(2569) FIXME: Type 'Generator<any, any, unknown>' is not an arra... Remove this comment to see the full error message
  yield* httpSaga(
    TYPE,
    call(client.patch, `/event_guests/${id}?group_id=${groupId}`, {
      invited,
      rsvp,
      attended,
      event_time_slot_id,
      timezone_name: moment.tz.guess(),
    }),
    {
      metaId: `update-${id}`,
      schema: eventGuestSchema,
    }
  );

  yield put(doneIndicator(TYPE));
}

export function* watch() {
  yield takeEvery(WATCH_TYPE, updateEventGuestSaga);
}

export default function* root() {
  yield all([fork(watch)]);
}
