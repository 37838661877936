import { all } from 'redux-saga/effects';

// sagas
import fetchLabelsSaga from 'store/sagas/label/fetchLabelsSaga';
import createLabelSaga from 'store/sagas/label/createLabelSaga';
import updateLabelSaga from 'store/sagas/label/updateLabelSaga';
import deleteLabelSaga from 'store/sagas/label/deleteLabelSaga';

const labelSaga = function* rootSaga() {
  yield all([
    fetchLabelsSaga(),
    createLabelSaga(),
    updateLabelSaga(),
    deleteLabelSaga(),
  ]);
};

export default labelSaga;
