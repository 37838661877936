import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

// action creators
import { doneIndicator } from 'store/actions/httpActions';

// api
import client from 'sources/api';

// constants
import {
  SUGGESTED_FIELD_FETCH_REQUEST,
  SUGGESTED_FIELD_FETCH,
} from 'store/constants/suggestedFieldTypes';

// schemas
import { suggestedFieldSchema } from 'store/schemas/suggestedFieldSchema';

// helpers
import httpSaga from 'store/sagas/httpSaga';

const WATCH_TYPE = SUGGESTED_FIELD_FETCH_REQUEST;
const TYPE = SUGGESTED_FIELD_FETCH;

export function* fetchSuggestedFieldsSaga(action: any) {
  const {
    payload: { search },
  } = action;

  // @ts-expect-error ts-migrate(2569) FIXME: Type 'Generator<any, any, unknown>' is not an arra... Remove this comment to see the full error message
  yield* httpSaga(
    TYPE,
    call(client.get, '/suggested_fields', {
      params: {
        search,
      },
    }),
    {
      schema: [suggestedFieldSchema],
    }
  );

  yield put(doneIndicator(TYPE));
}

export function* watch() {
  yield takeEvery(WATCH_TYPE, fetchSuggestedFieldsSaga);
}

export default function* root() {
  yield all([fork(watch)]);
}
