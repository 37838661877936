import React, { memo, useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import validate from 'validate.js';
import PropTypes from 'prop-types';
// material-ui
import { Grid } from '@material-ui/core';
// icons
import { LinearScale } from '@material-ui/icons';
//components
import DazzlingDialog from 'components/DazzlingDialog';
import TextField from 'components/TextField';
import { Form, Field } from 'react-final-form';
// actions
import { createMilestoneAction } from 'store/actions/milestoneActions';
const FORM = 'createMilestone';
const SCHEMA = {
  title: {
    presence: true,
    length: { maximum: 128 },
  },
};
const DIALOG_DATA = {
  icon: LinearScale,
  title: 'New Milestone',
  subtitle: 'Create a milestone',
};

type Props = {
  open: boolean;
  onClose: () => void;
};

const CreateMilestoneModal = memo(({ open, onClose }: Props) => {
  const {
    actions: { createMilestone },
  } = useRedux();
  const validateMilestoneFields = useCallback(
    values => validate(values, SCHEMA),
    []
  );
  const handleFormSubmit = useCallback(
    values => {
      createMilestone({
        formName: FORM,
        fields: { ...values },
      });
    },
    [createMilestone]
  );
  return (
    <Form
      validate={validateMilestoneFields}
      onSubmit={handleFormSubmit}
      render={({ handleSubmit, invalid, form: { reset } }) => (
        <DazzlingDialog
          id='create-milestone-dialog'
          acceptLabel='Create'
          formName={FORM}
          handleClose={onClose}
          headerProps={DIALOG_DATA}
          onAccept={handleSubmit}
          disabled={invalid}
          open={Boolean(open)}
          onExited={reset}
        >
          <Grid
            container
            spacing={2}
            alignItems='center'
            justifyContent='space-between'
          >
            <Grid item xs={5}>
              <Field
                fullwidth
                name='title'
                label='Title'
                variant='outlined'
                component={TextField}
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                name='description'
                label='Description'
                variant='outlined'
                component={TextField}
              />
            </Grid>
          </Grid>
        </DazzlingDialog>
      )}
    />
  );
});
(CreateMilestoneModal as any).propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
const useRedux = () => {
  const dispatch = useDispatch();
  const actions = useMemo(
    () => ({
      createMilestone: (payload: any) =>
        dispatch(createMilestoneAction(payload)),
    }),
    [dispatch]
  );
  return { actions };
};
export default CreateMilestoneModal;
