import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

// action creators
import { doneIndicator } from 'store/actions/httpActions';

// api
import client from 'sources/api';

// constants
import { EMAIL_CREATE_REQUEST, EMAIL_CREATE } from 'store/constants/emailTypes';

// lib
import httpSaga from 'store/sagas/httpSaga';

// helpers
import isNamesListSite from 'helpers/isNamesListSite';

const WATCH_TYPE = EMAIL_CREATE_REQUEST;
const TYPE = EMAIL_CREATE;
const FORM = 'addEmailForm';

export function* createEmailSaga(action: any) {
  const {
    payload: { email },
  } = action;

  // @ts-expect-error ts-migrate(2569) FIXME: Type 'Generator<any, any, unknown>' is not an arra... Remove this comment to see the full error message
  yield* httpSaga(
    TYPE,
    call(client.post, '/emails', { email, is_nameslist: isNamesListSite() }),
    {
      formName: FORM,
    }
  );

  yield put(doneIndicator(TYPE));
}

export function* watch() {
  yield takeEvery(WATCH_TYPE, createEmailSaga);
}

export default function* root() {
  yield all([fork(watch)]);
}
