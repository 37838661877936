import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

// action creators
import { doneIndicator } from 'store/actions/httpActions';

// api
import client from 'sources/api';

// constants
import {
  INVITE_CREATE_REQUEST,
  INVITE_CREATE,
} from 'store/constants/inviteTypes';

// schemas
import { inviteSchema } from 'store/schemas/inviteSchema';

// helpers
import httpSaga from 'store/sagas/httpSaga';

const WATCH_TYPE = INVITE_CREATE_REQUEST;
const TYPE = INVITE_CREATE;

export function* createInvite(action: any) {
  const {
    payload: { groupId, email, role, childGroupId },
  } = action;

  // @ts-expect-error ts-migrate(2569) FIXME: Type 'Generator<any, any, unknown>' is not an arra... Remove this comment to see the full error message
  yield* httpSaga(
    TYPE,
    call(client.post, '/invites', {
      group_id: groupId,
      role_id: role,
      child_group_id: childGroupId,
      email,
    }),
    {
      schema: inviteSchema,
      metaId: `create-${email}`,
      formName: 'singleInviteForm',
    }
  );

  yield put(doneIndicator(TYPE));
}

export function* watch() {
  yield takeEvery(WATCH_TYPE, createInvite);
}

export default function* root() {
  yield all([fork(watch)]);
}
