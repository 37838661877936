import { Styles } from 'assets/types';

const manuallyPushLeadsModalStyles: Styles = () => ({
  container: {
    fontSize: '0.9rem',
    '&.color-white': {
      color: '#ffffff',
      borderColor: '#ffffff',
    },
    '&.color-red': {
      color: '#f04e45',
      borderColor: '#f04e45',
    },
    '&.border': {
      borderWidth: 1,
      borderRadius: 4,
      borderStyle: 'solid',
    },
  },
  icon: {
    maxWidth: 40,
  },
});

export default manuallyPushLeadsModalStyles;
