import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { isEqual } from 'lodash';
// hooks
import { useDispatch, useSelector } from 'react-redux';
import { useDateTime } from 'helpers/hooks/useDateTime';
// material-ui
import {
  Box,
  Grid,
  Typography,
  Checkbox,
  Fade,
  CircularProgress,
  Tooltip,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
// icons
import { Today, Delete } from '@material-ui/icons';
// components
import IconButton from 'components/IconButton';
// action creators
import {
  completeTask,
  uncompleteTask,
  deleteTask,
  clearTaskById,
} from 'store/actions/taskActions';
// selectors
import getLoadingFromState from 'store/selectors/getLoadingFromState';
import getStatusFromState from 'store/selectors/getStatusFromState';
import getPermission from 'store/selectors/getPermission';
const TaskItemHeader = function({
  id,
  dueOn,
  isCompleted,
  title,
  hover,
  isAssignedToYou,
}: any) {
  const { date, dayOfWeek, time } = useDateTime(dueOn);
  const classes = useStyles();
  const dispatch = useDispatch();
  const [isChecked, setIsChecked] = useState(isCompleted);
  const {
    taskCompletedStatus,
    taskUncompletedStatus,
    taskCompleting,
    taskUncompleting,
    taskDeleting,
    canDeleteTask,
  } = useSelector(
    state => ({
      taskCompletedStatus: getStatusFromState('task', `complete-${id}`)(state),
      taskUncompletedStatus: getStatusFromState(
        'task',
        `uncomplete-${id}`
      )(state),
      taskCompleting: getLoadingFromState(
        'task',
        `complete-${id}`,
        false
      )(state),
      taskUncompleting: getLoadingFromState(
        'task',
        `uncomplete-${id}`,
        false
      )(state),
      taskDeleting: getLoadingFromState('task', `delete-${id}`, false)(state),
      canDeleteTask: getPermission('todo.deleteOne')(state),
    }),
    isEqual
  );

  const dispatchDeleteTask = useCallback(() => {
    dispatch(deleteTask({ taskId: id }));
  }, [dispatch, id]);
  const dispatchChangeTask = useCallback(
    // @ts-expect-error ts-migrate(2525) FIXME: Initializer provides no value for this binding ele... Remove this comment to see the full error message
    ({ target: { checked } = {} }) => {
      if (checked) {
        dispatch(completeTask({ taskId: id }));
      } else {
        dispatch(uncompleteTask({ taskId: id }));
      }
      setIsChecked(checked);
    },
    [dispatch, id]
  );

  useEffect(() => {
    if (
      !isAssignedToYou &&
      (taskCompletedStatus === 'success' || taskUncompletedStatus === 'success')
    ) {
      dispatch(clearTaskById(id));
    }
  }, [
    dispatch,
    id,
    isAssignedToYou,
    taskCompletedStatus,
    taskUncompletedStatus,
  ]);
  return (
    <Grid item xs={12} className={classes.header}>
      <Grid container spacing={3} alignItems='center' justifyContent='center'>
        <Grid
          item
          xs={2}
          sm={1}
          lg={2}
          className={
            taskCompleting || taskUncompleting ? classes.circularProgress : ''
          }
        >
          {taskCompleting || taskUncompleting ? (
            <CircularProgress id='circularProgress' size={24} />
          ) : (
            <Tooltip title={isChecked ? 'Mark Incomplete' : 'Mark Complete'}>
              <Checkbox
                id='checkbox'
                color='primary'
                onChange={dispatchChangeTask}
                checked={isChecked}
              />
            </Tooltip>
          )}
        </Grid>

        <Grid item xs>
          <Typography variant='body1' className={classes.bold}>
            {title}
          </Typography>

          <Grid container>
            <Box fontSize={'medium'} display={'flex'} alignSelf={'center'}>
              <Today fontSize='inherit' color={'disabled'} />
            </Box>
            &nbsp;
            <Typography
              variant={'caption'}
              component={'span'}
              color={'textSecondary'}
            >
              Due on {dayOfWeek}, {date} {time && `at ${time}`}
            </Typography>
          </Grid>
        </Grid>

        {canDeleteTask && (
          <Grid item xs={2} sm={1} lg={2}>
            <Fade in={hover}>
              <IconButton
                id='deleteButton'
                onClick={dispatchDeleteTask}
                loading={taskDeleting}
              >
                <Delete />
              </IconButton>
            </Fade>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};
TaskItemHeader.propTypes = {
  accounts: PropTypes.instanceOf(Array).isRequired,
  description: PropTypes.string,
  dueOn: PropTypes.string.isRequired,
  hover: PropTypes.bool.isRequired,
  id: PropTypes.number.isRequired,
  isCompleted: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
};
TaskItemHeader.defaultProps = {
  accounts: [],
  description: '',
  leads: [],
  pnms: [],
};
const useStyles = makeStyles(theme => {
  const border = `1px solid ${(theme.palette.background as any).lightPaper}`;
  return {
    header: {
      padding: 10,
      borderBottom: border,
    },
    bold: {
      fontWeight: 800,
    },
    circularProgress: {
      textAlign: 'center',
    },
  };
});
export default TaskItemHeader;
