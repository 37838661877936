import React, { useState, useEffect, memo } from 'react';
import { List, Map } from 'immutable';
import { isEqual } from 'lodash';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useLeadsQueries } from 'api/leads';
// material-ui
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
// icons
import { ExpandMore } from '@material-ui/icons';
// components
import TaskItemSection from './TaskItemSection';
// action creators
import { populateTask } from 'store/actions/taskActions';

const TaskItemDetails = function({ description, accounts, id }: any) {
  const [expanded, setExpanded] = useState(false);
  const classes = useStyles();
  const dispatch = useDispatch();
  const memberAccounts = accounts.map(({ account }: any = {}) => account) || [];

  const { task } = useSelector(
    state => ({
      task: (state as any).getIn(
        ['task', 'data', 'byId', id.toString()],
        Map()
      ),
    }),
    isEqual
  );
  const leadIds = task.get('pnms', List()).toJS();
  const leads = useLeadsQueries(leadIds);
  const leadsData = leads.map(({ data }) => data);

  useEffect(() => {
    if (expanded) {
      dispatch(
        populateTask({
          taskId: id,
        })
      );
    }
  }, [dispatch, expanded, id]);
  return (
    <Grid
      item
      xs={12}
      className={(classes as any).details}
      classes={{ root: classes.expansionPanel }}
    >
      <Accordion
        elevation={0}
        expanded={expanded}
        onChange={() => setExpanded(!expanded)}
        className={classes.expansionPanel}
      >
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Grid item xs={12} zeroMinWidth>
            <Typography variant='body2' id='summary'>
              Details
            </Typography>
          </Grid>
        </AccordionSummary>

        <AccordionDetails classes={{ root: classes.expansionDetails }}>
          <Grid container id='details'>
            {description && (
              <Grid item xs={12} className={classes.description}>
                <Typography variant='subtitle2' color='textSecondary'>
                  Description
                </Typography>
                <Typography variant='body2'>{description}</Typography>
              </Grid>
            )}

            <Grid item xs={12} className={classes.sections}>
              <Grid container>
                <TaskItemSection
                  label='Members'
                  items={memberAccounts}
                  taskId={id}
                  divider
                />
                <TaskItemSection
                  label='Leads'
                  navigateTo={`/leads/`}
                  items={leadsData}
                  taskId={id}
                />
              </Grid>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
};
TaskItemDetails.propTypes = {
  description: PropTypes.string,
  id: PropTypes.number.isRequired,
  accounts: PropTypes.instanceOf(Array).isRequired,
  pnms: PropTypes.instanceOf(Array).isRequired,
  leads: PropTypes.instanceOf(Array).isRequired,
};
TaskItemDetails.defaultProps = {
  description: '',
  accounts: [],
  leads: [],
  pnms: [],
};
const useStyles = makeStyles(theme => {
  const border = `1px solid ${(theme.palette.background as any).lightPaper}`;
  return {
    description: {
      paddingLeft: 24,
      paddingBottom: 16,
    },
    summary: {
      width: '100%',
      paddingLeft: 16,
    },
    sections: {
      borderTop: border,
    },
    expansionPanel: {
      background: 'none',
    },
    expansionDetails: {
      padding: 0,
    },
  };
});
export default memo(TaskItemDetails);
