import React, { useCallback, memo, useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

// api
import { useUpdateLeadMutation } from 'api/leads';
import { useCreateBgTaskMutation } from 'api/tasks';

// material-ui
import { Box, Typography } from '@material-ui/core';
import Ballot from '@material-ui/icons/Ballot';

// components
import DazzlingDialog from 'components/DazzlingDialog';
import ReferralSourceSelector from './ReferralSourceSelector';

// selectors
import getSliceEntityById from 'store/selectors/getSliceEntityById';

// actions
import { fetchLeadReferralSourcesAction } from 'store/actions/leadReferralSourceActions';

// constants
import { TaskNames, CreateReferralSourcesParams } from 'api/tasks';

type AssignReferralSourceModalProps = {
  open: boolean;
  onClose: (...args: any[]) => any;
  allSelected?: boolean;
  leadIds?: number[];
  filters?: LeadFetchFilters;
};

const AssignReferralSourceModal = ({
  open,
  onClose,
  allSelected = false,
  leadIds = [],
  filters = {},
}: AssignReferralSourceModalProps) => {
  const dispatch = useDispatch();
  const { leadId } = useParams<{ leadId: string }>();

  const {
    isLoading: isLeadUpdating,
    isSuccess: isLeadUpdated,
    isError: isLeadUpdatingFailed,
    mutate: updateLead,
    reset: resetUpdateLeadMutation,
  } = useUpdateLeadMutation();
  const {
    isLoading: isCreatingReferralSources,
    isSuccess: isCreatingReferralSourcesSucceed,
    isError: isCreatingReferralSourcesFailed,
    reset: resetCreateReferralSourcesMutation,
    mutate: createReferralSources,
  } = useCreateBgTaskMutation<CreateReferralSourcesParams>(
    TaskNames.BulkCreateReferralSources
  );

  const [referralSourceIds, setReferralSourceIds] = useState<number[]>([]);
  const singleLeadId =
    Number(leadId) || (!allSelected && leadIds.length === 1 && leadIds[0]);

  useEffect(() => {
    if (singleLeadId) {
      dispatch(fetchLeadReferralSourcesAction({ leadId: singleLeadId }));
    }
  }, [dispatch, singleLeadId]);

  const leadReferralSources = useSelector(
    getSliceEntityById('leadReferralSource', singleLeadId)
  );

  const handleReferralSourceIdsChange = useCallback(
    newReferralSourceIds => {
      setReferralSourceIds(newReferralSourceIds);
    },
    [setReferralSourceIds]
  );

  const handleSubmit = useCallback(() => {
    if (singleLeadId) {
      const leadReferralSourcesArray = Object.keys(leadReferralSources).map(
        Number
      );
      const newReferralSources = referralSourceIds.filter(
        referralSourceId => !leadReferralSourcesArray.includes(referralSourceId)
      );
      updateLead({
        id: singleLeadId,
        addedReferralSourceIds: newReferralSources,
      });
    } else {
      const params: CreateReferralSourcesParams = allSelected
        ? {
            pnmIds: 'all',
            excludedPnmIds: leadIds,
            referralSourceIds,
            ...filters,
          }
        : { pnmIds: leadIds, referralSourceIds };
      createReferralSources(params);
    }
  }, [
    updateLead,
    singleLeadId,
    allSelected,
    leadIds,
    referralSourceIds,
    leadReferralSources,
    filters,
    createReferralSources,
  ]);

  const handleExit = useCallback(() => {
    setReferralSourceIds([]);
  }, []);

  return (
    <DazzlingDialog
      id='assignReferralSourcesModal'
      disabled={!referralSourceIds.length}
      handleClose={onClose}
      open={open}
      onAccept={handleSubmit}
      headerProps={{
        icon: Ballot,
        title: 'Assign Referral Sources',
        subtitle: `Choose Referral Sources for the lead${
          leadIds.length > 1 ? 's' : ''
        }`,
        highlightedSubtitle: `${
          allSelected
            ? `All ${Object.keys(filters).length ? 'filtered' : ''}`
            : leadIds.length
        } lead${allSelected || leadIds.length > 1 ? 's' : ''} selected ${
          allSelected && leadIds.length
            ? `${leadIds.length} lead${leadIds.length > 1 ? 's' : ''} excluded`
            : ''
        }`,
      }}
      acceptLabel='Submit'
      submitOnEnter
      onExit={handleExit}
      onExited={() => {
        resetUpdateLeadMutation();
        resetCreateReferralSourcesMutation();
      }}
      isLoading={isLeadUpdating || isCreatingReferralSources}
      isSuccess={isLeadUpdated || isCreatingReferralSourcesSucceed}
      isError={isLeadUpdatingFailed || isCreatingReferralSourcesFailed}
    >
      <Box width='70%' marginTop={1} marginBottom={3}>
        {!singleLeadId && (
          <Typography paragraph>
            This could take a while -- you will receive a notification when
            completed.
          </Typography>
        )}
        <ReferralSourceSelector
          input={{
            value: referralSourceIds,
            onChange: handleReferralSourceIdsChange,
          }}
          multiple
          placeholder='Referral Sources'
          paddingTopClass='paddingTop6'
        />
      </Box>
    </DazzlingDialog>
  );
};

export default memo(AssignReferralSourceModal);
