import React, { useCallback, useEffect, useMemo } from 'react';
import validate from 'validate.js';
import { isEqual } from 'lodash';
import { Form, Field } from 'react-final-form';

// material-ui
import { Grid, FormHelperText } from '@material-ui/core';

// components
import PhoneAutoSuggest from 'components/PhoneAutoSuggest';
import Button from 'components/Button';

// selectors
import getForm from 'store/selectors/getForm';
import getCurrentGroupId from 'store/selectors/getCurrentGroupId';

// actions
import { createPhoneNumber as createPhoneNumberAction } from 'store/actions/phoneNumberActions';

// hooks
import { useDispatch, useSelector } from 'react-redux';

const CREATE_FORM = 'createPhoneNumberForm';

const SCHEMA = {
  number: {
    presence: true,
  },
};

export default function CreatePhoneNumberForm() {
  const dispatch = useDispatch();
  const { createPhoneNumber } = useMemo(
    () => ({
      createPhoneNumber: (payload: any) =>
        dispatch(createPhoneNumberAction(payload)),
    }),
    [dispatch]
  );
  const {
    form: { hasSubmitSucceeded, isSubmitting, formError, hasSubmitFailed },
  } = useSelector(
    state => ({
      form: getForm(CREATE_FORM)(state),
      currentGroupId: getCurrentGroupId(state),
    }),
    isEqual
  );

  let resetForm: any;

  useEffect(() => {
    if (hasSubmitSucceeded) {
      resetForm();
    }
  }, [hasSubmitSucceeded]); // eslint-disable-line

  const handleCreatePhoneNumber = ({ number: { value } }: any) => {
    createPhoneNumber({ number: value, formName: CREATE_FORM });
  };

  const validateFields = useCallback(values => validate(values, SCHEMA), []);

  return (
    <Form
      onSubmit={handleCreatePhoneNumber}
      validate={validateFields}
      render={({ handleSubmit, invalid, values, form: { reset } }) => {
        resetForm = reset;

        return (
          <Grid item xs={12} component='form' onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs>
                <Grid container spacing={2} alignItems='center'>
                  <Grid item xs={9} sm={6}>
                    <Field name='number'>
                      {props => (
                        <PhoneAutoSuggest
                          values={values}
                          // @ts-expect-error ts-migrate(2322) FIXME: Type '{ input: FieldInputProps<any, HTMLElement>; ... Remove this comment to see the full error message
                          shouldReset={hasSubmitSucceeded}
                          {...props}
                        />
                      )}
                    </Field>
                  </Grid>

                  <Grid item xs={3}>
                    <Button
                      color='primary'
                      variant='contained'
                      type='submit'
                      loading={isSubmitting}
                      disabled={invalid || isSubmitting}
                      success={hasSubmitSucceeded}
                      fail={hasSubmitFailed}
                    >
                      Add Number To Group
                    </Button>
                  </Grid>

                  {formError && (
                    <Grid item xs={12}>
                      <FormHelperText error id='errorMessage'>
                        {formError.get('message')}
                      </FormHelperText>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        );
      }}
    />
  );
}
