import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

// material-ui
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

// icons
import DeleteIcon from '@material-ui/icons/Delete';

//components
import DazzlingDialog from 'components/DazzlingDialog';

// action creators
import { deleteEvent } from 'store/actions/eventActions';
import getCurrentGroupId from 'store/selectors/getCurrentGroupId';

const DeleteFormModal = ({
  open,
  onClose,
  eventId,
}: {
  onClose: (...args: any[]) => any;
  open: boolean;
  eventId: number;
}) => {
  const dispatch = useDispatch();

  const currentGroupId = useSelector(getCurrentGroupId);
  const dispatchDeleteForm = useCallback(() => {
    dispatch(deleteEvent({ eventId, groupId: currentGroupId }));
  }, [currentGroupId, dispatch, eventId]);

  return (
    <DazzlingDialog
      id='deleteEventForm'
      alertProps={{ message: 'Event Deleted Successfully' }}
      acceptLabel='Delete'
      slice='event'
      metaId={`delete-${eventId}`}
      handleClose={onClose}
      headerProps={dialogData}
      height='short'
      onAccept={() => dispatchDeleteForm()}
      submitOnEnter={false}
      open={open}
    >
      <Grid container spacing={2} direction='column'>
        <Grid item xs={12} sm={6}>
          <Typography variant='body2'>
            Guests, RSVPs, sign up forms, and all other data will be lost for
            this event.
          </Typography>
        </Grid>
      </Grid>
    </DazzlingDialog>
  );
};

const dialogData = {
  icon: DeleteIcon,
  title: 'Delete Referral Form',
  subtitle: 'Are you sure you want to remove this event?',
};

export default DeleteFormModal;
