import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { useDispatch, useSelector } from 'react-redux';

// action creators
import { deleteInvite, resendInvite } from 'store/actions/inviteActions';

// selectors
import getCurrentGroupId from 'store/selectors/getCurrentGroupId';

// material-ui
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core/styles';

// icons
import SendIcon from '@material-ui/icons/Send';
import CopyIcon from '@material-ui/icons/FileCopy';
import DeleteIcon from '@material-ui/icons/Delete';

// components
import Button from 'components/Button';
import IconButton from 'components/IconButton';
import getLoadingFromState from 'store/selectors/getLoadingFromState';

const PendingInviteItem = function({ invite }: any) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [copied, setCopied] = useState(false);
  const deleteMetaId = `delete-${invite.get('id')}`;
  const resendMetaId = `resend-${invite.get('email')}`;

  const {
    deleteInviteLoading,
    currentGroupId,
    resendInviteLoading,
  } = useSelector(state => ({
    deleteInviteLoading: getLoadingFromState(
      'invite',
      deleteMetaId,
      false
    )(state),
    resendInviteLoading: getLoadingFromState(
      'invite',
      resendMetaId,
      false
    )(state),
    currentGroupId: getCurrentGroupId(state),
  }));

  const url = window.location.origin;
  const inviteLink = `${url}/signup?emailAddress=${invite.get('email')}`;

  const inviteLinkRef = useRef(null);

  const handleDelete = () => {
    dispatch(deleteInvite({ id: invite.get('id') }));
  };

  const handleResendInvite = () => {
    dispatch(
      resendInvite({ email: invite.get('email'), groupId: currentGroupId })
    );
  };

  useEffect(() => {
    setTimeout(() => setCopied(false), 1500);
  }, [copied]);

  const handleCopyLink = () => {
    // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
    inviteLinkRef.current.select();
    document.execCommand('copy');
    setCopied(true);
  };

  return (
    <Grid container>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography className={classes.semibold} color='textSecondary'>
            {invite.get('email')}
          </Typography>
        </Grid>

        <Grid item xs={4}>
          <Button
            startIcon={<SendIcon />}
            id='resendButton'
            onClick={handleResendInvite}
            loading={resendInviteLoading}
          >
            Resend Invite
          </Button>
        </Grid>

        <Grid item xs={6}>
          <input
            className={classes.inviteLinkInput}
            type='text'
            ref={inviteLinkRef}
            value={inviteLink}
          />

          <Tooltip arrow open={copied} title='Copied to clipboard'>
            <Button startIcon={<CopyIcon />} onClick={handleCopyLink}>
              Copy Invite Link
            </Button>
          </Tooltip>
        </Grid>

        <Grid item xs={2} className={classes.iconContainer}>
          <IconButton
            onClick={handleDelete}
            loading={deleteInviteLoading}
            id='deleteButton'
          >
            <DeleteIcon className={classes.grayIcon} />
          </IconButton>
        </Grid>
      </Grid>
    </Grid>
  );
};

PendingInviteItem.propTypes = {
  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'typeof Map' is not assignable to... Remove this comment to see the full error message
  invite: PropTypes.instanceOf(Map),
};

PendingInviteItem.defaultProps = {
  invite: Map(),
};

const useStyles = makeStyles({
  semibold: {
    fontWeight: 600,
  },
  grayIcon: {
    opacity: 0.5,
  },
  iconContainer: {
    textAlign: 'right',
  },
  inviteLinkInput: {
    zIndex: -10,
    opacity: 0,
    position: 'absolute',
  },
});

export default PendingInviteItem;
