import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { List } from 'immutable';
import classNames from 'classnames';
import { usePrevious } from 'helpers/hooks/usePrevious';
// MUI
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Grid, Typography, Button } from '@material-ui/core';
const TabController = function({
  containerColor,
  currentTab,
  elevation,
  onAnyTabClick,
  size,
  tabAnimation,
  tabHoverColor,
  tabs,
}: any) {
  const classes = useStyles();
  const theme = useTheme();
  const previousTab = usePrevious(currentTab);
  const currentTabIndex = tabs.findIndex(
    (tab: any) => tab.get('key') === currentTab
  );
  const [previousTabIndex, setPreviousTabIndex] = useState(currentTabIndex);
  useEffect(() => {
    // Prevents animation from being skipped when re-rendering
    if (previousTab && previousTab !== currentTab) {
      const index = tabs.findIndex(
        (tab: any) => tab.get('key') === previousTab
      );
      setPreviousTabIndex(index);
    }
  }, [previousTab, currentTab]); // eslint-disable-line
  const getAnimationClass = useCallback(
    index => {
      if (index === previousTabIndex || previousTabIndex < 0) {
        // Prevents animation on initial render
        return {};
      }
      if (tabAnimation === 'fade') {
        return classes.fadeTab;
      } else if (tabAnimation === 'slide') {
        if (previousTabIndex > index) {
          const difference = previousTabIndex - index;
          if (difference === 1) {
            return classes.slideInRightOneTab;
          } else {
            return classes.slideInRightMultiTab;
          }
        } else if (previousTabIndex < index) {
          const difference = index - previousTabIndex;
          if (difference === 1) {
            return classes.slideInLeftOneTab;
          } else {
            return classes.slideInLeftMultiTab;
          }
        }
      }
    },
    [previousTabIndex] // eslint-disable-line
  );
  const handleTabClick = useCallback(
    tab => {
      tab.get('onClick', () => {});
      onAnyTabClick(tab.get('key'));
    },
    [onAnyTabClick]
  );
  return (
    <Grid
      container
      className={classNames(
        classes.tabsContainer,
        size === 'compact'
          ? classes.compactContainer
          : classes.defaultContainer,
        elevation > 0 && classes.elevation
      )}
      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      style={{ background: theme.palette.background[containerColor] }}
      alignItems='center'
      wrap='nowrap'
      spacing={2}
    >
      {tabs.map((tab: any, index: any) => (
        <Grid
          item
          xs
          id={tab.get('key')}
          key={tab.get('key')}
          onClick={() => handleTabClick(tab)}
        >
          <Grid
            container
            justifyContent='flex-start'
            className={classNames(classes.tab)}
            id='tab'
          >
            {tab.get('key') === currentTab ? (
              <Button
                className={classNames(
                  classes.selected,
                  size === 'compact'
                    ? classes.compactSelected
                    : classes.defaultSelected,
                  getAnimationClass(index)
                )}
              >
                <Typography className={classes.tabLabel} variant='body2' noWrap>
                  {tab.get('label')}
                </Typography>
              </Button>
            ) : (
              <Button
                className={classNames(
                  classes.unselected,
                  size === 'compact'
                    ? classes.compactUnselected
                    : classes.defaultUnselected,
                  // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                  classes[`${tabHoverColor}HoverBackground`]
                )}
              >
                <Typography
                  color='textSecondary'
                  variant='body2'
                  className={classes.tabLabel}
                  noWrap
                >
                  {tab.get('label')}
                </Typography>
              </Button>
            )}
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
};
TabController.propTypes = {
  containerColor: PropTypes.oneOf([
    'default',
    'lightPaper',
    'paper',
    'darkPaper',
  ]),
  currentTab: PropTypes.string.isRequired,
  elevation: PropTypes.number,
  onAnyTabClick: PropTypes.func,
  size: PropTypes.string,
  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'typeof List' is not assignable t... Remove this comment to see the full error message
  tabs: PropTypes.instanceOf(List).isRequired,
  tabAnimation: PropTypes.oneOf(['slide', 'fade']),
  tabHoverColor: PropTypes.oneOf([
    'default',
    'lightPaper',
    'paper',
    'darkPaper',
  ]),
};
TabController.defaultProps = {
  containerColor: 'paper',
  elevation: 0,
  onAnyTabClick: () => {},
  size: 'default',
  tabAnimation: 'fade',
  tabHoverColor: 'darkPaper',
  tabs: List(),
};
const useStyles = makeStyles(theme => ({
  tabsContainer: {
    borderRadius: 24,
    overflowX: 'scroll',
    width: 'auto',
  },
  defaultContainer: {
    height: 57,
    marginTop: 10,
    marginBottom: 10,
    marginLeft: 0,
    marginRight: 0,
  },
  compactContainer: {
    height: 40,
    margin: 5,
  },
  tab: {
    cursor: 'pointer',
  },
  tabLabel: {
    textAlign: 'center',
    fontWeight: 'bold',
  },
  elevation: {
    boxShadow:
      '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
  },
  selected: {
    width: '100%',
    borderRadius: 1000,
    backgroundColor: theme.palette.secondary.main, // eslint-disable-line
    background: `linear-gradient(312deg, ${theme.palette.secondary.main} 0%, ${theme.palette.primary.main} 75%)`, // eslint-disable-line
  },
  defaultSelected: {
    padding: 10,
  },
  compactSelected: {
    padding: 5,
  },
  unselected: {
    width: '100%',
    '&:hover': {
      padding: 10,
      borderRadius: 1000,
      transition: 'all 0.25s ease',
    },
  },
  defaultUnselected: {
    '&:hover': {
      padding: 10,
    },
  },
  compactUnselected: {
    '&:hover': {
      padding: 5,
    },
  },
  fadeTab: {
    animationName: '$fade',
    animationDuration: '0.25s',
    animationFillMode: 'both',
  },
  slideInLeftOneTab: {
    animationName: '$slideInLeftOneWidth',
    animationDuration: '0.25s',
    animationFillMode: 'both',
  },
  slideInRightOneTab: {
    animationName: '$slideInRightOneWidth',
    animationDuration: '0.25s',
    animationFillMode: 'both',
  },
  slideInLeftMultiTab: {
    animationName: '$slideInLeftMultiWidth',
    animationDuration: '0.35s',
    animationFillMode: 'both',
  },
  slideInRightMultiTab: {
    animationName: '$slideInRightMultiWidth',
    animationDuration: '0.35s',
    animationFillMode: 'both',
  },
  defaultHoverBackground: {
    '&:hover': {
      background: theme.palette.background.default,
    },
  },
  lightPaperHoverBackground: {
    '&:hover': {
      background: (theme.palette.background as any).lightPaper,
    },
  },
  paperHoverBackground: {
    '&:hover': {
      background: theme.palette.background.paper,
    },
  },
  darkPaperHoverBackground: {
    '&:hover': {
      background: (theme.palette.background as any).darkPaper,
    },
  },
  '@keyframes fade': {
    '0%': {
      opacity: 0,
    },
    '100%': {
      opacity: 1,
    },
  },
  '@keyframes slideInLeftOneWidth': {
    '0%': {
      transform: 'translateX(-100%)',
      visibility: 'visible',
    },
    '100%': {
      transform: 'translateX(0)',
    },
  },
  '@keyframes slideInRightOneWidth': {
    '0%': {
      transform: 'translateX(100%)',
      visibility: 'visible',
    },
    '100%': {
      transform: 'translateX(0)',
    },
  },
  '@keyframes slideInLeftMultiWidth': {
    '0%': {
      transform: 'translateX(-200%)',
      visibility: 'visible',
    },
    '100%': {
      transform: 'translateX(0)',
    },
  },
  '@keyframes slideInRightMultiWidth': {
    '0%': {
      transform: 'translateX(200%)',
      visibility: 'visible',
    },
    '100%': {
      transform: 'translateX(0)',
    },
  },
}));
export default TabController;
