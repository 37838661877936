import { Styles } from 'assets/types';

const leadAttributeWithFieldTypeStyles: Styles = () => ({
  gridDialogBox: {},

  gridDialogItem: {},

  textInput: {
    fontWeight: 600,
    fontSize: '0.875rem',
  },
});

export default leadAttributeWithFieldTypeStyles;
