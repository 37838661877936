import React, { useCallback, useEffect, useMemo } from 'react';
import { isEqual } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';

// MUI components
import {
  Grid,
  Card,
  CardContent,
  Hidden,
  WithStyles,
  withStyles,
} from '@material-ui/core';

// components
import Header from 'components/Header';
import Table from 'components/Table';
import ExportButton from 'components/ExportButton';
import ReportRefreshButton from 'components/ReportRefreshButton';

// action
import { createReferralSourceFormDataReport } from 'store/actions/apiTaskActions';

// selectors
import getCurrentGroupId from 'store/selectors/getCurrentGroupId';
import getForm from 'store/selectors/getForm';
import getSliceEntityById from 'store/selectors/getSliceEntityById';

// icons
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';

// helpers
import { isArray, isEmpty } from 'helpers/check';
import { getComparator } from 'components/Table/helpers/sortFunctions';

// styles
import referralSourceFormDataCardStyles from 'routes/ReferralSourceAnalytics/ReferralSourceFormDataCard/referralSourceFormDataCard.style';

const REPORT_FORM = 'referralFormDataReportForm';
const ROWS_PER_PAGE = 10;
const INITIAL_ORDER = 'asc';
const INITIAL_ORDER_BY = 'group';

export interface ReferralSourceFormDataCardContent {
  formSubmissions: number;
  formsCreated: number;
  group: string | null;
  joiners: number;
  totalLeads: number;
  lastSubmission: string | null;
  lastSubmissionSort?: number;
}

export interface ReferralSourceFormDataCardProps
  extends WithStyles<typeof referralSourceFormDataCardStyles> {}

const ReferralSourceFormDataCard = ({
  classes,
}: ReferralSourceFormDataCardProps) => {
  const dispatch = useDispatch();

  const {
    currentGroupId,
    report: {
      data: {
        content: unformattedContent = [],
        meta: { columns = [] } = {},
      } = {},
      id: reportId,
    },
    form: { isSubmitting },
  } = useSelector(
    state => ({
      currentGroupId: getCurrentGroupId(state),
      form: getForm(REPORT_FORM)(state),
      report: getSliceEntityById('report', 'ReportTypes.FORM_DATA', {})(state),
    }),
    isEqual
  );

  const dispatchCreateReport = useCallback(
    ({ forceRefresh }: { forceRefresh: boolean }) => {
      dispatch(
        createReferralSourceFormDataReport({
          groupId: currentGroupId,
          formName: REPORT_FORM,
          forceRefresh,
        })
      );
    },
    [dispatch, currentGroupId]
  );

  useEffect(() => {
    dispatchCreateReport({ forceRefresh: false });
  }, [dispatchCreateReport]);

  const content = useMemo<ReferralSourceFormDataCardContent[]>(() => {
    if (isEmpty(unformattedContent) || !isArray(unformattedContent)) {
      return [];
    }

    return unformattedContent.map(
      (itemContent: ReferralSourceFormDataCardContent) => {
        const { lastSubmission } = itemContent || {};
        return {
          ...itemContent,
          lastSubmission: lastSubmission
            ? moment
                .utc(lastSubmission)
                .local()
                .format('MMM DD, YYYY')
            : '',
          lastSubmissionSort: lastSubmission
            ? moment(lastSubmission).unix()
            : 0,
        };
      }
    );
  }, [unformattedContent]);

  const sortingComparator = (order: boolean | string, orderBy: string) => {
    const newOrderBy =
      orderBy === 'lastSubmission' ? 'lastSubmissionSort' : orderBy;

    return getComparator(order, newOrderBy);
  };

  return (
    <Card>
      <CardContent className={classes.cardContent}>
        <Grid container>
          <Grid item xs>
            <Header
              title='Form Data'
              subtitle='See which linked groups have forms created and the impact'
              icon={AssignmentIndIcon}
              compact
            />
          </Grid>

          <Hidden xsDown>
            <Grid item xs={2}>
              <Grid container justifyContent='flex-end'>
                <Grid item>
                  <ReportRefreshButton
                    loading={isSubmitting}
                    onRefresh={dispatchCreateReport}
                  />
                </Grid>
                <ExportButton params={{ reportId }} disabled={isSubmitting} />
              </Grid>
            </Grid>
          </Hidden>

          <Grid item xs={12}>
            <Table
              columns={columns}
              data={content}
              variant='outlined'
              numberOfSkeletonRows={isSubmitting ? 8 : 0}
              initialOrder={INITIAL_ORDER}
              initialOrderBy={INITIAL_ORDER_BY}
              TablePaginationProps={{
                count: content.length,
                rowsPerPage: ROWS_PER_PAGE,
                rowsPerPageOptions: [ROWS_PER_PAGE],
              }}
              sortingComparator={sortingComparator}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default withStyles(referralSourceFormDataCardStyles, {
  withTheme: true,
})(ReferralSourceFormDataCard);
