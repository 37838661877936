import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Map, List } from 'immutable';
import { useSelector } from 'react-redux';
// material-ui
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import Box from '@material-ui/core/Box';
import Chip from '@material-ui/core/Chip';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import TextInput from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
// icons
import DeleteIcon from '@material-ui/icons/Delete';
import DragIcon from '@material-ui/icons/DragIndicator';
// components
import { Field } from 'react-final-form';
import Switch from 'components/Switch';
import TextField from 'components/TextField';
import IconButton from 'components/IconButton';
import Select from 'components/Select';
import Button from 'components/Button';
import getFieldTypeOptions from 'helpers/getFieldTypeOptions';
// selectors
import getLoadingFromState from 'store/selectors/getLoadingFromState';
import getStatusFromState from 'store/selectors/getStatusFromState';
//helpers
import { isEmpty } from 'helpers/check';

const FormEditorFieldItem = function({
  field,
  index,
  items,
  label,
  labelToDelete,
  onDelete,
  mutators,
  resetFieldsValue,
  fieldRemove,
  isCouncilForm,
  isOrganizationForm,
}: {
  field: any;
  index: any;
  items: any;
  label: any;
  labelToDelete: any;
  onDelete: any;
  mutators: any;
  resetFieldsValue?: () => void;
  fieldRemove?: (index: any) => void;
  isCouncilForm?: boolean | null;
  isOrganizationForm?: boolean | null;
}) {
  const classes = useStyles();
  const fieldData = items.get(index, Map());
  const isCustomType = fieldData.get('type') === 'custom';
  const { deleteLoading, deleteStatus } = useSelector(state => ({
    deleteLoading:
      labelToDelete === fieldData.get('label')
        ? getLoadingFromState('formField', `delete-${labelToDelete}`)(state)
        : false,
    deleteStatus:
      labelToDelete === fieldData.get('label')
        ? getStatusFromState('formField', `delete-${labelToDelete}`)(state)
        : false,
  }));

  const options = fieldData.get('options', List()).toJS();

  const [optionName, setOptionName] = useState('');

  const isImmutable = fieldData.get('isImmutable');

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOptionName(e.target.value);
  };

  const addOption = (optionName: string) => {
    if (
      optionName &&
      (isEmpty(options) || !options.find((item: string) => item === optionName))
    ) {
      const newOptionList = [...options, optionName];
      mutators.setValue(`${field}.options`, newOptionList);
    }
  };

  const removeOption = (key: string) => {
    const newOptionList = [
      ...options.filter((option: string) => option !== key),
    ];
    mutators.setValue(`${field}.options`, newOptionList);
  };

  const handleSubmit = (e: Event) => {
    e.preventDefault();
    addOption(optionName);
    setOptionName('');
  };

  useEffect(() => {
    if (deleteStatus === 'success' && fieldRemove) {
      fieldRemove(index);
      if (resetFieldsValue) {
        resetFieldsValue();
      }
    }
  }, [deleteStatus]); // eslint-disable-line

  const renderFieldLabel = () => {
    return (
      <Grid container>
        <Grid item xs={12}>
          <Typography
            variant='body2'
            color='textSecondary'
            className={classes.italic}
          >
            {label}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography variant='body1' className={classes.bold}>
            {fieldData.get('label')}
          </Typography>
        </Grid>
      </Grid>
    );
  };
  return (
    <Grid item xs={12} id='formEditorFieldItem'>
      <Card className={classes.card}>
        <Grid container spacing={3} alignItems='center' alignContent='center'>
          <Grid item xs={1} className={classes.dragIconContainer}>
            <Tooltip title={`Click & drag to reorder`}>
              <DragIcon />
            </Tooltip>
          </Grid>

          <Grid item xs={4} sm={3}>
            {renderFieldLabel()}
          </Grid>

          <Grid item xs={5} sm={6}>
            <Field
              name={`${field}.required`}
              type='checkbox'
              label='Required'
              component={Switch}
              disabled={isImmutable}
            />
          </Grid>

          {onDelete && (
            <Grid item xs={2} id='deleteIcon'>
              <Grid
                container
                direction='column'
                justifyContent='center'
                alignItems='center'
              >
                <Grid item xs={12}>
                  <IconButton
                    onClick={() => onDelete(fieldData)}
                    loading={deleteLoading}
                    disabled={isImmutable}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
          )}

          <Grid item xs={12}>
            <Field
              name={`${field}.description`}
              label='Description'
              component={TextField}
              disabled={isImmutable}
            />
          </Grid>

          <Grid item xs={12}>
            <Field
              name={`${field}.fieldType`}
              label='Field Type'
              variant='outlined'
              options={getFieldTypeOptions()}
              component={Select}
              disabled={isImmutable || !isCustomType}
            />
          </Grid>
          {fieldData.get('fieldType') === 'select' ||
          fieldData.get('fieldType') === 'multiselect' ? (
            <Grid container spacing={1} item xs={12}>
              <Grid item xs={3}>
                <Field name={`${field}.option`} disabled={isImmutable}>
                  {props => (
                    <TextInput
                      label='Option Name'
                      variant='outlined'
                      value={optionName}
                      name={props.input.name}
                      onChange={handleChange}
                      {...props}
                    />
                  )}
                </Field>
              </Grid>
              <Grid item xs={4}>
                <Button
                  style={{ paddingTop: 14, paddingBottom: 14 }}
                  variant='outlined'
                  size='large'
                  startIcon={<AddCircleIcon />}
                  iconButtonIcon={<AddCircleIcon />}
                  onClick={handleSubmit}
                  disabled={isImmutable}
                >
                  Add Option
                </Button>
              </Grid>
              <Grid container>
                {options.map((item: string) => (
                  <Box key={item} marginLeft={1} marginTop={2} marginRight={1}>
                    <Grid item xs={2} sm={4} md={4}>
                      <Chip
                        label={item}
                        variant='outlined'
                        onDelete={() => removeOption(item)}
                        disabled={isImmutable}
                      />
                    </Grid>
                  </Box>
                ))}
              </Grid>
            </Grid>
          ) : null}

          {(isOrganizationForm || isCouncilForm) && (
            <Grid item xs={5} sm={6}>
              <Field
                name={`${field}.isPublished`}
                type='checkbox'
                label='Share with Linked Group'
                component={Switch}
                disabled={isImmutable}
              />
            </Grid>
          )}
        </Grid>
      </Card>
    </Grid>
  );
};
FormEditorFieldItem.propTypes = {
  field: PropTypes.string,
  label: PropTypes.string.isRequired,
  index: PropTypes.number,
  labelToDelete: PropTypes.string,
  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'typeof List' is not assignable t... Remove this comment to see the full error message
  items: PropTypes.instanceOf(List).isRequired,
  onDelete: PropTypes.func,
};
FormEditorFieldItem.defaultProps = {
  field: '',
  index: 0,
  onDelete: null,
  labelToDelete: null,
};
const useStyles = makeStyles(theme => ({
  card: {
    backgroundColor: (theme.palette.background as any).lightPaper,
    padding: 16,
  },
  dragIconContainer: {
    textAlign: 'center',
  },
  italic: {
    fontStyle: 'italic',
  },
  bold: {
    fontWeight: 600,
  },
  requiredIcon: {
    color: theme.palette.success.main,
  },
}));
export default FormEditorFieldItem;
