import Immutable, { List, Map } from 'immutable';
import { denormalize } from 'normalizr';

import { userSchema } from '../schemas/userSchema';

export default function getUsers(state: any) {
  const request = state.get('user', Map());
  const result = request.getIn(['data', 'result'], List());

  const entities = {
    user: state.getIn(['user', 'data', 'byId'], Map()).toJS(),
    email: state.getIn(['email', 'data', 'byId'], Map()).toJS(),
  };

  let items = denormalize(result.toJS(), [userSchema], entities);

  return Immutable.fromJS(items);
}
