import React, { useMemo, useCallback, ReactNode } from 'react';
// material-ui
import { makeStyles } from '@material-ui/core/styles';
import { Skeleton } from '@material-ui/lab';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
// helpers
import { Theme } from 'assets/types';

export interface BillingOverviewItemProps {
  billingLoading: boolean;
  title: string;
  description: string;
  icon?: ReactNode;
}

const BillingOverviewItem = ({
  billingLoading,
  title,
  description,
  icon,
}: BillingOverviewItemProps) => {
  const classes = useStyles();
  const titleContent = useMemo<ReactNode>(
    () => (
      <Typography color='textSecondary' variant='subtitle2'>
        {title}
      </Typography>
    ),
    [title]
  );

  const getTitle = useCallback<(titleContent: ReactNode) => ReactNode>(
    titleContent =>
      icon ? (
        <Grid container spacing={1} alignItems='center'>
          <Grid item xs>
            {titleContent}
          </Grid>
          <Grid className={classes.iconBox} item xs='auto'>
            {icon}
          </Grid>
        </Grid>
      ) : (
        <Grid container spacing={1} alignItems='center'>
          <Grid item xs={12}>
            {titleContent}
          </Grid>
        </Grid>
      ),
    [classes.iconBox, icon]
  );

  return (
    <Grid item xs={12} sm={6}>
      {billingLoading ? (
        <Skeleton variant='rect' height={79} />
      ) : (
        <div className={classes.outlinedContainer}>
          {getTitle(titleContent)}
          <Typography variant='body1'>{description}</Typography>
        </div>
      )}
    </Grid>
  );
};

const useStyles = makeStyles(theme => ({
  outlinedContainer: {
    border: `1px solid ${(theme as Theme).palette.background.lightPaper}`,
    borderRadius: 4,
    padding: 16,
    overflow: 'hidden',
  },
  iconBox: {
    '& .MuiSvgIcon-root': {
      fontSize: '1rem',
    },
  },
}));

export default BillingOverviewItem;
