import PropTypes from 'prop-types';
import React, { useState, useEffect, useRef, useCallback } from 'react';
import heic2any from 'heic2any';
import { Map, List } from 'immutable';
import Compressor from 'compressorjs';

// MUI Components
import FormHelperText from '@material-ui/core/FormHelperText';
import green from '@material-ui/core/colors/green';
// hooks
import { useSelector } from 'react-redux';
import { useReduxForm } from 'store/hooks/useReduxForm';
// selectors
import getFiles from 'store/selectors/getFiles';
import getStatusFromState from 'store/selectors/getStatusFromState';
// local components
import Button from 'components/Button';
const FileInput = function({
  buttonLabel,
  reSelectLabel,
  containerClassName,
  disabled,
  fileType,
  formName,
  onChange,
  onSubmit,
  onFileConverting,
}: any) {
  const {
    hasSubmitFailed,
    hasSubmitSucceeded,
    isSubmitting,
    formError,
  } = useReduxForm(formName);

  // component state and refs
  const [file, setFile] = useState(null); // file object
  const [compressedImage, setCompressedImage] = useState<any>(null); // compressed image file object

  const [uploading, setUploading] = useState(false);
  const inputRef = useRef(null);

  // grab state data
  const { files = List(), fileStatus } = useSelector(state => ({
    files: getFiles(state) as any,
    fileStatus: getStatusFromState('file')(state),
  }));
  const { id: fileId, url } = files.last(Map()).toJS();

  useEffect(() => {
    if (hasSubmitSucceeded && url) {
      onChange({
        filePreview: url,
        newFileId: fileId,
        fileName: (file as any)?.name,
      });
    }
  }, [fileId, fileStatus, onChange, hasSubmitSucceeded, url]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (hasSubmitSucceeded) {
      setFile(null);
      // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
      inputRef.current.value = ''; // clears file input
    }
  }, [hasSubmitSucceeded]);

  useEffect(() => {
    if (uploading && !isSubmitting) {
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '"done"' is not assignable to par... Remove this comment to see the full error message
      setUploading('done');
    } else if (uploading && hasSubmitSucceeded && !isSubmitting) {
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '"done"' is not assignable to par... Remove this comment to see the full error message
      setUploading('done');
    }
  }, [isSubmitting, hasSubmitSucceeded, uploading]);

  const getFileAcceptType = () => {
    let acceptedFile;
    switch (fileType) {
      case 'image':
        acceptedFile = 'image/*,.heic';
        break;
      case 'video':
        acceptedFile = 'video/*';
        break;
      case 'audio':
        acceptedFile = 'audio/*';
        break;
      case 'file':
        acceptedFile = '.csv';
        break;
      case 'media':
        acceptedFile = 'media_type';
        break;
      default:
        acceptedFile = 'file_extension';
        break;
    }
    return acceptedFile;
  };

  const handleFileUpload = useCallback(
    (resultFile: any) => {
      const reader = new FileReader();
      reader.readAsDataURL(resultFile);
      reader.onload = e => {
        // Update the image in the component state and callback
        const { result: fileDataUrl } = reader;
        if (resultFile?.name !== (file as any)?.name) {
          setFile(resultFile);
          onChange({
            filePreview: fileDataUrl,
            newFileId: fileId,
            fileName: resultFile?.name,
          });
          onSubmit(resultFile);
          setUploading(true);
        }
      };
    },
    [file, fileId, onChange, onSubmit]
  );

  const compressImage = (file: any) => {
    if (!file) return;

    new Compressor(file, {
      quality: 0.8, // the quality of the image, max is 1,
      width: 600, // the width of the output image
      height: 600, // the height of the output image

      // The compression process is asynchronous,
      // which means you have to access the `result` in the `success` hook function.
      success: result => {
        // returns as a Blob
        // We set the resized image in the component state because of the async compressing
        setCompressedImage(result);
      },
    });
  };

  // Handles uploading resized image files once they're done compressing
  useEffect(() => {
    if (compressedImage) {
      handleFileUpload(compressedImage);
      setCompressedImage(null);
      onFileConverting(false);
    }
  }, [compressedImage, handleFileUpload, onFileConverting]);

  const handleChange = (event: any) => {
    event.preventDefault();
    const selectedFile = event.target.files[0];

    if (selectedFile) {
      const fileType = selectedFile?.type || '';

      if (fileType.includes('image')) {
        if (fileType === 'image/heic') {
          onFileConverting(true);

          // Converts HEIC file type to PNG
          heic2any({
            blob: selectedFile,
            toType: 'image/png',
          }).then(function(resultBlob) {
            // @ts-expect-error ts-migrate(2322) FIXME: Type 'Blob | Blob[]' is not assignable to type 'Bl... Remove this comment to see the full error message
            const resultFile = new File([resultBlob], selectedFile.name, {
              type: 'image/png',
            });
            // Resizes and compresses image
            compressImage(resultFile);
          });
        } else {
          // Resizes and compresses image
          compressImage(selectedFile);
        }
      } else {
        // Handles non-image files
        handleFileUpload(selectedFile);
      }
    }
  };

  const renderStatusMessage = () => {
    let element;
    if (hasSubmitFailed) {
      element = (
        <FormHelperText id='errorMessage' style={{ textAlign: 'left' }} error>
          Error: {formError.get('message')}
        </FormHelperText>
      );
    } else if (hasSubmitSucceeded) {
      // We set uploading to 'done' so that this success message only appears on upload.
      element = (
        <FormHelperText
          id='successMessage'
          style={{ textAlign: 'left', color: green.A700 }}
        >
          Uploaded successfully
        </FormHelperText>
      );
    }
    return element;
  };

  const handleInputUpload = () => {
    (inputRef as any).current.click();
  };

  const acceptedFile = getFileAcceptType();
  return (
    <div>
      <div className={containerClassName}>
        <Button
          onClick={handleInputUpload}
          variant='contained'
          color='primary'
          success={hasSubmitSucceeded}
          fail={hasSubmitFailed}
          loading={isSubmitting && uploading}
          disabled={isSubmitting || disabled}
          style={{ marginBottom: 20 }}
        >
          {file ? reSelectLabel || 'Reselect file' : buttonLabel}
        </Button>
        <input
          type='file'
          ref={inputRef}
          id='pickerInput'
          accept={acceptedFile}
          style={{ display: 'none' }}
          onChange={e => handleChange(e)}
          onBlur={e => handleChange(e)}
        />
      </div>

      {!disabled && renderStatusMessage()}
    </div>
  );
};

FileInput.propTypes = {
  buttonLabel: PropTypes.string,
  containerClassName: PropTypes.string,
  disabled: PropTypes.bool,
  fileType: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
  onFileConverting: PropTypes.func,
  formName: PropTypes.string,
};
FileInput.defaultProps = {
  buttonLabel: 'Choose file',
  formName: 'createAndUploadFileForm',
  containerClassName: '',
  fileType: 'file_extension',
  onSubmit: () => {},
  onFileConverting: () => {},
};
export default FileInput;
