import React from 'react';
import { Route, Switch, Redirect, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { roles } from 'helpers/getRoles';
import { isEqual } from 'lodash';

// material-ui
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';

// helpers
import getSettingsTabs from './helpers/getSettingsTabs';

// selectors
import getIsSystemAdmin from 'store/selectors/getIsSystemAdmin';
import getCurrentGroup from 'store/selectors/getCurrentGroup';
import getFeatures from 'store/selectors/getFeatures';
import getIsCurrentGroupChapter from 'store/selectors/getIsCurrentGroupChapter';
import getIsCurrentGroupOrganization from 'store/selectors/getIsCurrentGroupOrganization';
import getIsCurrentGroupCouncil from 'store/selectors/getIsCurrentGroupCouncil';
import getIsCurrentGroupSystem from 'store/selectors/getIsCurrentGroupSystem';

const SettingsLayout = function() {
  const history = useHistory();
  const {
    location: { pathname = '', search },
  } = history;
  const classes = useStyles();
  const formattedPathname = pathname.replace('*', 'account');
  const {
    isSystemAdmin,
    currentGroup,
    features,
    currentUserRole,
    isCurrentGroupChapter,
    isCurrentGroupOrganization,
    isCurrentGroupCouncil,
    isCurrentGroupSystem,
  } = useSelector(
    state => ({
      isSystemAdmin: getIsSystemAdmin(state),
      currentGroup: getCurrentGroup(state) as any,
      isCurrentGroupChapter: getIsCurrentGroupChapter(state),
      isCurrentGroupOrganization: getIsCurrentGroupOrganization(state),
      isCurrentGroupCouncil: getIsCurrentGroupCouncil(state),
      isCurrentGroupSystem: getIsCurrentGroupSystem(state),
      features: getFeatures(state),
      currentUserRole: (state as any).getIn(
        ['currentUser', 'data', 'roleId'],
        roles.member
      ),
    }),
    isEqual
  );
  const handleChangeTab = (_: any, value: any) => {
    history.push({ pathname: value, search });
  };
  const tabs = getSettingsTabs({
    isSystemAdmin,
    isCurrentGroupSet: currentGroup.size !== 0,
    features,
    currentUserRole,
    isCurrentGroupChapter,
    isCurrentGroupOrganization,
    isCurrentGroupCouncil,
    isCurrentGroupSystem,
  });
  const renderTabs = () => {
    return tabs.map((tab: any) => {
      let route = tab.get('route');
      if (route.includes('settings/members')) {
        let lastPart = pathname.split('/').pop();
        // adds any extra path parts to the tab's value, so that tab still appears active
        // ex) single and bulk
        if (!route.includes(lastPart)) {
          route = `${route}/${lastPart}`;
        }
      }
      return (
        <Tab
          id='tab'
          key={route}
          label={tab.get('label')}
          icon={tab.get('icon')}
          value={route}
        />
      );
    });
  };
  return (
    <Box position='relative'>
      <Box position='absolute' width='100%'>
        <Grid
          container
          className={classes.settingsContainer}
          id='settingsContainer'
        >
          <Tabs
            className={classes.tabBar}
            id='tabBar'
            value={formattedPathname}
            onChange={handleChangeTab}
            variant='scrollable'
            scrollButtons='auto'
            indicatorColor='primary'
            textColor='primary'
            aria-label='settings tabs'
          >
            {renderTabs()}
          </Tabs>

          <Grid container id='tabsContentContainer' spacing={2}>
            <Switch>
              {tabs.map((tab: any) => (
                <Route
                  key={tab.get('route')}
                  path={tab.get('route')}
                  component={tab.get('component')}
                />
              ))}

              <Redirect
                to={{
                  pathname: tabs.getIn([0, 'route']) as any,
                  search,
                }}
              />
            </Switch>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};
const useStyles = makeStyles(theme => ({
  settingsContainer: {
    paddingTop: 24,
    paddingBottom: 24,
  },
  tabBar: {
    width: '100%',
    borderTopRightRadius: 8,
    borderTopLeftRadius: 8,
    backgroundColor: theme.palette.background.paper,
    borderBottom: `2px solid ${(theme.palette.background as any).darkPaper}`,
    zIndex: 100,
    [theme.breakpoints.down('xs')]: {
      backgroundColor: theme.palette.background.paper,
    },
  },
}));
export default SettingsLayout;
