import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';

// material-ui
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

// icons
import DeleteIcon from '@material-ui/icons/Delete';

//components
import DazzlingDialog from 'components/DazzlingDialog';

// action creators
import { deleteReferralForm } from 'store/actions/referralFormActions';

type Props = {
  onClose: (...args: any[]) => any;
  open: boolean;
  formId: number;
};

const DeleteFormModal = ({ open, onClose, formId }: Props) => {
  const dispatch = useDispatch();
  const dispatchDeleteForm = useCallback(
    values => {
      dispatch(deleteReferralForm({ formId }));
    },
    [dispatch, formId]
  );

  const dialogContent = (values: any) => {
    return (
      <Grid container spacing={2} direction='column'>
        <Grid item xs={12} sm={6}>
          <Typography variant='body2'>
            Fields, responses, settings, and all other data will be lost for
            this form.
          </Typography>
        </Grid>
      </Grid>
    );
  };

  return (
    <DazzlingDialog
      id='deleteFormForm'
      alertProps={{ message: 'Form Deleted Successfully' }}
      acceptLabel='Delete'
      slice='referralForm'
      metaId={`delete-${formId}`}
      handleClose={onClose}
      headerProps={dialogData}
      height='short'
      // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 0.
      onAccept={() => dispatchDeleteForm()}
      submitOnEnter={false}
      open={open}
    >
      {/* @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 0. */}
      {dialogContent()}
    </DazzlingDialog>
  );
};

const dialogData = {
  icon: DeleteIcon,
  title: 'Delete Referral Form',
  subtitle: 'Are you sure you want to remove this form?',
};

export default DeleteFormModal;
