import { all } from 'redux-saga/effects';

// sagas
import createRequestSaga from './createRequestSaga';
import fetchRequestsForGroupSaga from './fetchRequestsForGroupSaga';
import fetchRequestsForUserSaga from './fetchRequestsForUserSaga';
import updateRequestSaga from './updateRequestSaga';
import deleteRequestSaga from './deleteRequestSaga';

const sessionSaga = function* rootSaga() {
  yield all([
    createRequestSaga(),
    fetchRequestsForGroupSaga(),
    fetchRequestsForUserSaga(),
    updateRequestSaga(),
    deleteRequestSaga(),
  ]);
};

export default sessionSaga;
