import React, { useEffect, useCallback, useState } from 'react';
// mui components
import {
  TextField,
  Chip,
  CircularProgress,
  Typography,
  Grid,
  Box,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
// styles
import {
  makeStyles,
  createTheme,
  ThemeProvider,
} from '@material-ui/core/styles';
// icons
import { Search, AddCircle } from '@material-ui/icons';
const tagsTheme = createTheme({
  palette: {
    primary: {
      main: '#fff',
    },
  },
});
type MultiAutocompleteProps = {
  id?: string;
  classes?: {};
  input: {
    value: any[];
    onChange: (...args: any[]) => any;
  };
  placeholder?: string;
  label?: string;
  readOnly?: boolean;
  options: any; // TODO: PropTypes.instanceOf(Array)
  loading?: boolean;
  onFetch?: (...args: any[]) => void;
  onChange?: (...args: any[]) => void;
  onOpen?: () => void;
  onClose?: () => void;
  open?: boolean;
  ListboxProps?: object;
};

export default function MultiAutocomplete({
  input,
  placeholder = 'Search',
  label = 'Select Items',
  readOnly,
  options,
  loading,
  onFetch = () => {},
  onChange = () => {},
  onClose,
  onOpen,
  open,
  ListboxProps,
  ...restProps
}: MultiAutocompleteProps) {
  const classes = useStyles();
  useEffect(() => {
    onFetch();
  }, [onFetch]);
  const [newOption, setNewOption] = useState<{ label: string } | null>();
  const [inputValue, setInputValue] = useState('');
  const [timerId, setTimerId] = useState<NodeJS.Timeout>();
  useEffect(() => {
    const createdOption = options.find(
      ({ label }: any) => label === (newOption && newOption.label)
    );
    if (createdOption) {
      setNewOption(null);
      input.onChange([...input.value, createdOption.id]);
    }
  }, [input, newOption, options]);
  // Handles fetching filtered options
  const handleInputChange = useCallback(
    (_, value, reason) => {
      if (value !== null && reason === 'input') {
        setInputValue(value);
        if (value.length > 1) {
          if (timerId) {
            clearTimeout(timerId);
          }
          setTimerId(setTimeout(onFetch, 400, value));
        } else {
          onFetch();
        }
      } else if (reason === 'clear') {
        setInputValue('');
        onFetch();
      }
    },
    [onFetch, timerId]
  );
  // Handles when autocomplete options are selected or deselected
  const handleChange = useCallback(
    (_, options) => {
      const newOption = Array.isArray(options)
        ? options.find(({ id } = {}) => !id)
        : !options?.id
        ? options
        : false;
      setInputValue('');
      if (newOption && !readOnly) {
        onChange(newOption);
        setNewOption(newOption);
      } else {
        input.onChange(
          Array.isArray(options) ? options.map(option => option) : options
        );
      }
    },
    [input, onChange, readOnly]
  );
  const renderInput = useCallback(
    params => {
      return (
        <TextField
          {...params}
          multiline={false}
          variant='outlined'
          label={label}
          placeholder={placeholder}
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress size={20} /> : <Search />}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      );
    },
    [label, loading, placeholder]
  );
  return (
    <Autocomplete
      open={open}
      classes={classes}
      disableCloseOnSelect={!readOnly}
      forcePopupIcon={false}
      getOptionLabel={getOptionDisplayName}
      inputValue={inputValue}
      limitTags={1}
      onClose={onClose}
      onOpen={onOpen}
      ListboxProps={ListboxProps}
      multiple
      clearText='Clear All Selected'
      onChange={handleChange}
      onInputChange={handleInputChange}
      options={options}
      renderInput={renderInput}
      renderOption={option => renderOption(option, readOnly)}
      renderTags={renderTags}
      value={input?.value || []}
      {...restProps}
    />
  );
}
const useStyles = makeStyles(theme => ({
  paper: {
    backgroundColor: (theme.palette.background as any).lightPaper,
  },
}));
const getOptionDisplayName = (option = {}) => (option as any)?.label;
const renderTags = (values: any, getTagProps: any) => (
  <Box width='fill-available'>
    <ThemeProvider theme={tagsTheme}>
      {values.map((option: any, index: any) => (
        <Chip key={option} label={option.label} {...getTagProps({ index })} />
      ))}
    </ThemeProvider>
  </Box>
);
const renderOption = (option: any, readOnly: any) =>
  option.id || readOnly ? (
    option.label
  ) : (
    <Grid container spacing={1} alignItems='center'>
      <AddCircle />
      <Grid item>
        <Typography>{`Add "${option.label}"`}</Typography>
      </Grid>
    </Grid>
  );
