import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { isEqual } from 'lodash';
import validate from 'validate.js';
import { Field, Form } from 'react-final-form';

// material-ui
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';

// icons
import CheckIcon from '@material-ui/icons/Check';

// components
import DazzlingDialog from 'components/DazzlingDialog';
import SubscriptionPlanItem from 'components/ManageSubscription/components/SubscriptionPlanItem';
import EnterpriseSubscriptionPlanModal from 'components/ManageSubscription/components/EnterpriseSubscriptionPlanModal';

// helpers
import {
  SubscriptionInfo,
  PlanMessageInfo,
} from 'components/ManageSubscription/helpers/interfaces';
import { ManageSubscriptionStatus } from 'components/ManageSubscription/helpers/types';
import getPricesFromSubscriptions from 'components/ManageSubscription/helpers/getPricesFromSubscriptions';
import getNextStep from 'components/ManageSubscription/helpers/getNextStep';
import getNextAcceptLabel from 'components/ManageSubscription/helpers/getNextAcceptLabel';
import isItFreeSubscription from 'components/ManageSubscription/helpers/isItFreeSubscription';
import { toStringWithoutNullUndefined } from 'helpers/transform';
import { isEmpty, isArray } from 'helpers/check';
import isNamesListSite from 'helpers/isNamesListSite';

const FORM = 'selectYourPlanForm';

interface SelectYourPlanModalProps {
  open: boolean;
  isItNewSubscription: boolean;
  isSubscriptionBlocked: boolean;
  currentModalStatus: ManageSubscriptionStatus;
  stripeProductLoading: boolean;
  onCurrentModalStatusChange: Dispatch<
    SetStateAction<ManageSubscriptionStatus>
  >;
  onSubscriptionInfoChange: Dispatch<SetStateAction<SubscriptionInfo>>;
  newSubscriptionProductId?: string;
  currentSubscriptionPlanProductId?: string;
  currentSubscriptionPlanId?: string;
  stripeProducts?: StripeProduct[];
  onClose?: () => void;
}

const SelectYourPlanModal = ({
  open,
  isItNewSubscription,
  isSubscriptionBlocked,
  currentModalStatus,
  stripeProducts,
  stripeProductLoading,
  newSubscriptionProductId,
  currentSubscriptionPlanProductId,
  currentSubscriptionPlanId,
  onCurrentModalStatusChange,
  onSubscriptionInfoChange,
  onClose = () => {},
}: SelectYourPlanModalProps) => {
  const classes = useStyles();
  const [
    planMessageInfo,
    setPlanMessageInfo,
  ] = useState<PlanMessageInfo | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const visibleStripeProductIds = useMemo<string[]>(() => {
    if (!stripeProducts) {
      return [];
    }

    return (isSubscriptionBlocked
      ? stripeProducts.filter(isItFreeSubscription)
      : stripeProducts
    ).map(({ id }) => id);
  }, [isSubscriptionBlocked, stripeProducts]);

  const getSubscriptionById = useCallback(
    (subscriptionId?: string): StripeProduct | undefined =>
      subscriptionId && stripeProducts
        ? stripeProducts?.find(
            stripeProduct => stripeProduct?.id === subscriptionId
          )
        : undefined,
    [stripeProducts]
  );

  const currentSubscription = getSubscriptionById(
    currentSubscriptionPlanProductId
  );
  const isFreeSubscription = isItFreeSubscription(currentSubscription);

  const initialValues = {
    subscriptionId: isFreeSubscription
      ? newSubscriptionProductId
      : currentSubscriptionPlanProductId || newSubscriptionProductId || null,
  };

  const schema = useMemo(
    () => ({
      subscriptionId: {
        presence: { message: '^Please, choose your plan.' },
        inclusion: {
          within: visibleStripeProductIds,
          message: '^Please, choose one of these plans.',
        },
      },
    }),
    [visibleStripeProductIds]
  );

  const getAcceptLabel = useCallback(
    values => {
      const subscription = getSubscriptionById(values?.subscriptionId);

      return getNextAcceptLabel(
        getNextStep(
          currentModalStatus,
          subscription,
          isItNewSubscription,
          stripeProducts,
          currentSubscriptionPlanProductId
        )
      );
    },
    [
      currentModalStatus,
      isItNewSubscription,
      stripeProducts,
      currentSubscriptionPlanProductId,
      getSubscriptionById,
    ]
  );

  const handleLabelClick = useCallback(
    (title: string, salesEmail: string) => () => {
      setPlanMessageInfo({
        title,
        salesEmail,
      });
    },
    [setPlanMessageInfo]
  );

  const dialogContent = useMemo(
    () => (
      <Grid container spacing={1} justifyContent='center'>
        {stripeProductLoading && (
          <div className={classes.loading}>
            <CircularProgress size={40} />
          </div>
        )}
        {stripeProducts &&
          stripeProducts
            .sort(
              (firstStripeProduct, secondStripeProduct) =>
                (firstStripeProduct?.sortPosition || 0) -
                (secondStripeProduct?.sortPosition || 0)
            )
            .map(
              ({
                id,
                name,
                subTitle,
                features,
                priceText,
                salesEmail,
                subscriptions,
              }: StripeProduct) => {
                const prices = getPricesFromSubscriptions(subscriptions);
                const priceMonth = toStringWithoutNullUndefined(
                  prices?.month?.amount
                );
                const priceYear = toStringWithoutNullUndefined(
                  prices?.year?.amount
                );

                return (
                  <Grid key={id} item xs={12} md={isNamesListSite() ? 6 : 4}>
                    <Field
                      name='subscriptionId'
                      type='radio'
                      value={id}
                      component={SubscriptionPlanItem}
                      title={name}
                      subTitle={subTitle}
                      features={isArray(features) ? features : null}
                      disabled={
                        !visibleStripeProductIds.includes(id) ||
                        (id === currentSubscription?.id && isFreeSubscription)
                      }
                      onLabelClick={
                        salesEmail &&
                        isEmpty(subscriptions) &&
                        handleLabelClick(`${name} plan`, salesEmail)
                      }
                      price={
                        priceText ||
                        (priceMonth || priceYear ? (
                          <>
                            {priceMonth ? `$${priceMonth}/Month` : ''}
                            {priceMonth && priceYear && (
                              <>
                                <br />
                                oR
                                <br />
                              </>
                            )}
                            {priceYear ? `$${priceYear}/year` : ''}
                          </>
                        ) : null)
                      }
                    />
                  </Grid>
                );
              }
            )}
      </Grid>
    ),
    [
      isFreeSubscription,
      currentSubscription,
      stripeProducts,
      stripeProductLoading,
      visibleStripeProductIds,
      handleLabelClick,
      classes.loading,
    ]
  );

  const validateForm = useCallback(
    values => {
      setErrorMessage(null);
      return validate(values, schema);
    },
    [schema, setErrorMessage]
  );

  const handleComeBack = useCallback(
    (reset: () => void) => () => {
      reset();
      setPlanMessageInfo(null);
    },
    [setPlanMessageInfo]
  );

  const handleSubmitForm = useCallback(
    values => {
      const subscription = getSubscriptionById(values?.subscriptionId);
      if (subscription) {
        setErrorMessage(null);
        const nextStep = getNextStep(
          currentModalStatus,
          subscription,
          isItNewSubscription,
          stripeProducts,
          currentSubscriptionPlanProductId
        );
        const [newSubscriptionPlanInterval, newSubscriptionPlanPriceId] =
          nextStep !== ManageSubscriptionStatus.SelectYourPlanYearOrMonth &&
          subscription?.subscriptions?.length === 1
            ? [
                subscription.subscriptions?.[0]?.interval,
                subscription.subscriptions?.[0]?.priceId,
              ]
            : [];
        if (
          !!currentSubscriptionPlanId &&
          !!newSubscriptionPlanPriceId &&
          newSubscriptionPlanPriceId === currentSubscriptionPlanId
        ) {
          setErrorMessage('This is your current subscription plan.');
          return;
        }
        onSubscriptionInfoChange(data => ({
          ...data,
          newSubscription: { ...subscription },
          newSubscriptionPlanInterval,
        }));
        onCurrentModalStatusChange(nextStep);
        return;
      }
      setErrorMessage(
        'There is some issue with your subscription. Please, update this page and try again.'
      );
    },
    [
      currentModalStatus,
      isItNewSubscription,
      stripeProducts,
      currentSubscriptionPlanProductId,
      currentSubscriptionPlanId,
      onSubscriptionInfoChange,
      onCurrentModalStatusChange,
      setErrorMessage,
      getSubscriptionById,
    ]
  );

  const handleClose = useCallback(() => {
    onCurrentModalStatusChange(ManageSubscriptionStatus.Closed);
    onClose();
  }, [onCurrentModalStatusChange, onClose]);

  return (
    <Form
      id={FORM}
      validate={validateForm}
      onSubmit={handleSubmitForm}
      initialValues={initialValues}
      initialValuesEqual={isEqual}
      render={({ handleSubmit, invalid, values, form: { restart } }) => (
        <DazzlingDialog
          acceptLabel={getAcceptLabel(values)}
          disabled={invalid}
          formName={FORM}
          handleClose={handleClose}
          headerProps={{
            icon: CheckIcon,
            title: 'Select Your Plan',
            alignItems: 'center',
          }}
          id={`${FORM}Dialog`}
          onAccept={handleSubmit}
          open={open}
          resetForm={restart}
          isError={!!errorMessage}
          alertProps={{
            message: errorMessage || '',
          }}
          isBackgroundWhite
          isFullWidth
          isButtonOnTheRightCorner
        >
          <>
            {dialogContent}
            {planMessageInfo && (
              <EnterpriseSubscriptionPlanModal
                title={planMessageInfo.title}
                salesEmail={planMessageInfo.salesEmail}
                onClose={handleComeBack(restart)}
                open
              />
            )}
          </>
        </DazzlingDialog>
      )}
    />
  );
};

const useStyles = makeStyles(() => ({
  loading: {
    width: '100%',
    textAlign: 'center',
  },
}));

export default SelectYourPlanModal;
