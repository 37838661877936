import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

// action creators
import { doneIndicator, error } from 'store/actions/httpActions';

// api
import client from 'sources/api';

// constants
import { GROUP_UPDATE_REQUEST } from 'store/constants/groupTypes';

// lib
import httpSaga from 'store/sagas/httpSaga';
import getBaseType from '../helpers/getBaseType';

// schema
import { groupSchema } from 'store/schemas/groupSchema';

const WATCH_TYPES = [GROUP_UPDATE_REQUEST];

export function* updateGroup(action: any) {
  const {
    formName,
    id,
    displayName: display_name,
    voteMethodId: vote_method_id,
    voteVisibility: vote_visibility,
    currentActiveMembers: current_active_members,
    type,
    ...values
  } = action.payload;
  const FORM = formName;
  const TYPE = getBaseType(action.type);

  try {
    // @ts-expect-error ts-migrate(2569) FIXME: Type 'Generator<any, any, unknown>' is not an arra... Remove this comment to see the full error message
    yield* httpSaga(
      TYPE,
      call(client.patch, `/groups/${id}`, {
        display_name,
        vote_method_id,
        vote_visibility,
        current_active_members,
        type,
        ...values,
      }),
      { schema: groupSchema, formName: FORM }
    );
  } catch (err) {
    yield put(error(TYPE, err));
  } finally {
    yield put(doneIndicator(TYPE));
  }
}

export function* watch() {
  yield takeEvery(WATCH_TYPES, updateGroup);
}

export default function* root() {
  yield all([fork(watch)]);
}
