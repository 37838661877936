import React, { useEffect, useCallback } from 'react';
// hooks
import { useCreateCampusMutation } from 'api/campus';

// components
import DazzlingDialog from 'components/DazzlingDialog';
import TextField from 'components/TextField';
import { Field, Form } from 'react-final-form';

// helpers
import validate from 'helpers/validate';

// icons
import Campus from '@material-ui/icons/LocationOn';

// schemas
const schema = {
  name: {
    presence: true,
    length: {
      maximum: 255,
    },
  },
};

type CreateCampusModalProps = {
  open: boolean;
  onClose: (...args: any[]) => any;
};
export default function CreateCampusModal({
  open,
  onClose,
}: CreateCampusModalProps) {
  const {
    mutate: createCampus,
    error,
    isError,
    isSuccess,
    isLoading,
    reset: resetCreateCampus,
  } = useCreateCampusMutation();

  const onSubmit = useCallback(
    fields => {
      createCampus(fields);
    },
    [createCampus]
  );

  useEffect(() => {
    if (isSuccess) {
      resetCreateCampus();
      // Delays close so that check indicator on button can appear
      setTimeout(onClose, 750);
    }
  }, [onClose, isSuccess, resetCreateCampus]);

  const handleValidate = useCallback(fields => validate(fields, schema), []);
  return (
    <Form
      id='createCampusForm'
      onSubmit={onSubmit}
      validate={handleValidate}
      render={({ handleSubmit, invalid, form: { restart } }) => {
        return (
          <DazzlingDialog
            id='createCampusModal'
            disabled={invalid || isLoading}
            alertProps={{ message: 'Campus Created Successfully' }}
            handleClose={() => {
              resetCreateCampus();
              restart();
              onClose();
            }}
            open={open}
            isLoading={isLoading}
            isSuccess={isSuccess}
            isError={isError}
            failureMessage={error?.response?.data?.msg}
            onAccept={handleSubmit}
            headerProps={{
              icon: Campus,
              title: 'Create New Campus',
              subtitle: 'Enter a name to create a new Campus',
            }}
            resetForm={restart}
          >
            <Field
              id='nameField'
              name='name'
              component={TextField}
              label='Display Name'
              required
            />
          </DazzlingDialog>
        );
      }}
    />
  );
}
