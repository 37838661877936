import { Styles } from 'assets/types';

const chapterDropdownStyles: Styles = () => ({
  selectBox: {
    '&.analyticsList': {
      '& .MuiSelect-root > .MuiListItemText-root': {
        marginTop: 0,
        marginBottom: 0,
        '& > .MuiTypography-root': {
          lineHeight: '1em',
        },
      },
    },
  },
});

export default chapterDropdownStyles;
