import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import Immutable from 'immutable';

// action creators
import { doneIndicator, success } from 'store/actions/httpActions';

// api
import { getClient } from 'sources/api';

// constants
import {
  LEAD_ATTRIBUTE_DELETE_REQUEST,
  LEAD_ATTRIBUTE_DELETE,
} from 'store/constants/leadAttributeTypes';

// schemas
import { leadAttributeSchema } from 'store/schemas/leadAttributeSchema';

// helpers
import httpSaga from 'store/sagas/httpSaga';
import getResponsePayload from 'helpers/getResponsePayload';

const WATCH_TYPE = LEAD_ATTRIBUTE_DELETE_REQUEST;
const TYPE = LEAD_ATTRIBUTE_DELETE;
const client = getClient({ camelizeResponse: false });

export function* deleteLeadAttribute(action: any) {
  const {
    payload: { leadId, groupId, label },
  } = action;
  const META_ID = `delete-${label}`;

  // @ts-expect-error ts-migrate(2569) FIXME: Type 'Generator<any, any, unknown>' is not an arra... Remove this comment to see the full error message
  yield* httpSaga(
    TYPE,
    call(client.delete, `/pnm_attrs/?pnm_id=${leadId}&group_id=${groupId}`, {
      data: { label },
    }),
    {
      dispatchSuccess: false,
      schema: leadAttributeSchema,
      metaId: META_ID,
    }
  );

  const response = { data: { label, id: leadId } };
  const payload = getResponsePayload(response, leadAttributeSchema);

  yield put(success(TYPE, Immutable.fromJS(payload), META_ID));

  yield put(doneIndicator(TYPE));
}

export function* watch() {
  yield takeEvery(WATCH_TYPE, deleteLeadAttribute);
}

export default function* root() {
  yield all([fork(watch)]);
}
