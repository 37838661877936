import { all, call, fork, takeEvery, put } from 'redux-saga/effects';

// action creators
import { doneIndicator } from 'store/actions/httpActions';
import { setFormSubmitSucceeded } from 'store/actions/formActions';
import { updateFormFields as updateAction } from 'store/actions/formFieldActions';

// api
import client from 'sources/api';

// constants
import {
  FORM_FIELD_UPDATE_MANY_REQUEST,
  FORM_FIELD_UPDATE_MANY,
} from 'store/constants/formFieldTypes';

// lib
import httpSaga from 'store/sagas/httpSaga';

// schemas
import { formFieldSchema } from 'store/schemas/formFieldSchema';

const WATCH_TYPE = FORM_FIELD_UPDATE_MANY_REQUEST;
const TYPE = FORM_FIELD_UPDATE_MANY;

export function* updateFormFields(action: ReturnType<typeof updateAction>) {
  const {
    formId: form_id,
    groupId: group_id,
    formName,
    formFields,
  } = action.payload;
  const FORM = formName;

  yield all(
    formFields.map((formField, index: any) => {
      formField.position = index + 1; // we want to use `index + 1` as the position

      const {
        label,
        description,
        position,
        required,
        fieldType,
        isPublished,
        options,
      } = formField || {};

      return httpSaga(
        TYPE,
        call(
          client.put,
          `/form_fields/?group_id=${group_id}&form_id=${form_id}`,
          {
            label,
            description,
            position,
            required,
            field_type: fieldType || 'text',
            is_published: !!isPublished,
            options:
              options && Array.isArray(options)
                ? options.filter(
                    (item: string, index: number) =>
                      index === options.indexOf(item)
                  )
                : options,
          }
        ),
        {
          formName: FORM,
          dispatchFormSuccess: false,
          shouldDestroyForm: false,
          schema: formFieldSchema,
        }
      );
    })
  );

  // Manually setting form submit success necessary for forms that loop through API calls:
  //  Otherwise `submitting` may be set to false while calls are still occuring
  yield put(setFormSubmitSucceeded(FORM, ''));

  yield put(doneIndicator(TYPE));
}

export function* watch() {
  yield takeEvery(WATCH_TYPE, updateFormFields);
}

export default function* root() {
  yield all([fork(watch)]);
}
