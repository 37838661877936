import React, { useCallback } from 'react';

// MUI components
import RefreshIcon from '@material-ui/icons/Refresh';

// components
import IconButton from 'components/IconButton';

type Props = {
  loading?: boolean;
  disabled?: boolean;
  onRefresh?: (...args: any) => void;
};

export default function ReportRefreshButton({
  loading,
  onRefresh,
  disabled = false,
  ...restOfProps
}: Props) {
  const handleRefresh = useCallback(() => {
    onRefresh && onRefresh({ forceRefresh: true });
  }, [onRefresh]);

  return (
    <IconButton
      loading={loading}
      onClick={handleRefresh}
      disabled={disabled}
      {...restOfProps}
    >
      <RefreshIcon />
    </IconButton>
  );
}
