import React from 'react';

// components
import { Field } from 'react-final-form';
import Switch from 'components/Switch';
import TextField from 'components/TextField';
import WizzyWigField from 'components/WizzyWigField';
import Button from 'components/Button';
import OutlinedCard from 'components/OutlinedCard';

// material-ui
import { Grid } from '@material-ui/core';
import { AddCircle } from '@material-ui/icons';

export default function EmailForm({
  emailSelected,
  mutators,
  textToInsert,
}: {
  emailSelected: boolean;
  mutators: any;
  textToInsert?: string;
}) {
  const getInsertOptions = () => {
    return [
      {
        label: 'Lead First Name',
        value: 'first_name',
      },
      {
        label: 'Lead Full Name',
        value: 'full_name',
      },
    ];
  };

  return (
    <OutlinedCard borderRadiusVariant={8}>
      <Grid container justifyContent='flex-start' spacing={2}>
        <Grid item xs={12}>
          <Field
            name='emailSelected'
            label='Send an invite via email'
            type='checkbox'
            component={Switch}
          />
        </Grid>
        {emailSelected && (
          <>
            <Grid item xs={12}>
              <Field
                name='subject'
                component={TextField}
                label='Subject'
                margin='none'
                required
              />
            </Grid>
            <Grid item xs={8} sm={4}>
              <Field
                name='emailTextToInsert'
                component={TextField}
                label='Text To Insert'
                margin='none'
                helperText='Inserts at beginning of email'
                options={getInsertOptions()}
                select
              />
            </Grid>
            <Grid item xs={4} sm={8} style={{ marginTop: 10 }}>
              <Button
                id='insertButton'
                startIcon={<AddCircle />}
                onClick={() => mutators.insertEmailTemplateText(textToInsert)}
              >
                Click To Insert
              </Button>
            </Grid>

            <Grid item xs={12}>
              <Field name='emailBody' component={WizzyWigField} />
            </Grid>
          </>
        )}
      </Grid>
    </OutlinedCard>
  );
}
