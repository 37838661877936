import React, { useState, useMemo, useCallback } from 'react';

// MUI
import { List, ListItem, ListSubheader } from '@material-ui/core';

// components
import Table from 'components/Table';

// hooks
import { useHistory } from 'react-router-dom';
import { useLeadsQueries } from 'api/leads';

const ROWS_PER_PAGE = 5;

const COLUMNS = [
  {
    label: 'Name',
    key: 'fullName',
    disableSort: true,
  },
];

const LeadsDetails = ({
  leadIds = [],
  title,
}: {
  leadIds: number[];
  title: string;
}) => {
  const history = useHistory();
  const [leadIdsOnPage, setLeadIdsOnPage] = useState<number[]>([]);

  const results = useLeadsQueries(leadIdsOnPage);
  const leads = useMemo(
    () =>
      results
        .filter(result => result.isSuccess)
        .map(result => result.data as Lead),
    [results]
  );
  const leadsLoading = useMemo(
    () => results.map(result => result.isLoading).some(Boolean),
    [results]
  );

  const handlePageChange = useCallback(
    (_, page: number) =>
      setLeadIdsOnPage(
        leadIds.slice(
          page * ROWS_PER_PAGE,
          page * ROWS_PER_PAGE + ROWS_PER_PAGE
        )
      ),
    [leadIds]
  );

  const navigate = useCallback(
    (rowData = {}) => {
      const pathname = `/leads/${rowData.id}`;
      history.push({ pathname });
    },
    [history]
  );
  return (
    <List>
      <ListSubheader>{title}</ListSubheader>
      <ListItem>
        <Table
          columns={COLUMNS}
          data={leadsLoading ? [] : leads}
          variant='outlined'
          onPageChange={handlePageChange}
          numberOfSkeletonRows={leadsLoading ? ROWS_PER_PAGE : 0}
          SkeletonProps={{
            height: 28,
          }}
          TablePaginationProps={{
            paginationType: 'api',
            count: leadIds.length,
            rowsPerPage: ROWS_PER_PAGE,
            rowsPerPageOptions: [ROWS_PER_PAGE],
          }}
          TableRowProps={{
            onRowClick: navigate,
          }}
        />
      </ListItem>
    </List>
  );
};

export default LeadsDetails;
