import { all, call, fork, put, takeEvery, select } from 'redux-saga/effects';

// action creators
import { doneIndicator } from 'store/actions/httpActions';

// api
import client from 'sources/api';

// constants
import {
  COMMENT_CREATE_REQUEST,
  COMMENT_CREATE,
} from 'store/constants/commentTypes';

// selectors
import getCurrentGroupId from 'store/selectors/getCurrentGroupId';

// schemas
import { commentSchema } from 'store/schemas/commentSchema';

// helpers
import httpSaga from 'store/sagas/httpSaga';

const WATCH_TYPE = COMMENT_CREATE_REQUEST;
const TYPE = COMMENT_CREATE;

export function* createComment(action: any) {
  const {
    payload: {
      fields: { activityId, body },
      metaId,
    },
  } = action;

  const currentGroupId = yield select(getCurrentGroupId);

  // @ts-expect-error ts-migrate(2569) FIXME: Type 'Generator<any, any, unknown>' is not an arra... Remove this comment to see the full error message
  yield* httpSaga(
    TYPE,
    call(client.post, `/comments?group_id=${currentGroupId}`, {
      activity_id: activityId,
      body,
    }),
    { schema: commentSchema, metaId }
  );

  yield put(doneIndicator(TYPE));
}

export function* watch() {
  yield takeEvery(WATCH_TYPE, createComment);
}

export default function* root() {
  yield all([fork(watch)]);
}
