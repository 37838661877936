// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'jwt-... Remove this comment to see the full error message
import jwtDecode from 'jwt-decode';
import humps from 'humps';
import moment from 'moment';

export function getAccessToken() {
  return localStorage.getItem('accessToken');
}

export function setAccessToken(token: any) {
  return localStorage.setItem('accessToken', token);
}

export function removeAccessToken() {
  return localStorage.removeItem('accessToken');
}

export function getRefreshToken() {
  return localStorage.getItem('refreshToken');
}

export function setRefreshToken(token: any) {
  return localStorage.setItem('refreshToken', token);
}

export function removeRefreshToken() {
  return localStorage.removeItem('refreshToken');
}

export function decodeToken(token: any) {
  let decodedToken;

  try {
    decodedToken = humps.camelizeKeys(jwtDecode(token));
    return decodedToken;
  } catch {
    return false;
  }
}

export function isTokenValid(token: any) {
  const tokenPayload = decodeToken(token);

  if (!tokenPayload) {
    return false;
  }

  // @ts-expect-error ts-migrate(2339) FIXME: Property 'exp' does not exist on type 'object[]'.
  const { exp } = tokenPayload;

  if (!exp) {
    return false;
  }

  return moment().unix() < exp;
}
