import { all, call, fork, put, takeEvery, select } from 'redux-saga/effects';

// action creators
import { doneIndicator } from 'store/actions/httpActions';

// api
import client from 'sources/api';

// constants
import {
  CURRENT_GROUP_UPDATE_REQUEST,
  CURRENT_GROUP_UPDATE,
} from 'store/constants/groupTypes';

// lib
import httpSaga from 'store/sagas/httpSaga';

// schema
import { currentGroupSchema } from 'store/schemas/currentGroupSchema';

// selectors
import getCurrentGroupId from 'store/selectors/getCurrentGroupId';

const WATCH_TYPE = CURRENT_GROUP_UPDATE_REQUEST;
const TYPE = CURRENT_GROUP_UPDATE;

export function* updateCurrentGroup(action: any) {
  const {
    formName,
    fields: {
      displayName: display_name,
      currentActiveMembers: current_active_members,
      voteMethodId: vote_method_id,
      voteVisibility: vote_visibility,
      ...restFields
    },
  } = action.payload;

  const currentGroupId = yield select(getCurrentGroupId);

  // @ts-expect-error ts-migrate(2569) FIXME: Type 'Generator<any, any, unknown>' is not an arra... Remove this comment to see the full error message
  yield* httpSaga(
    TYPE,
    call(client.put, `/groups/${currentGroupId}`, {
      display_name,
      current_active_members,
      vote_method_id,
      vote_visibility,
      ...restFields,
    }),
    { schema: currentGroupSchema, formName }
  );

  yield put(doneIndicator(TYPE));
}

export function* watch() {
  yield takeEvery(WATCH_TYPE, updateCurrentGroup);
}

export default function* root() {
  yield all([fork(watch)]);
}
