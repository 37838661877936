import React, { useState, useMemo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';
import { roles } from 'helpers/getRoles';
// material-ui
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  ButtonGroup,
  Card,
  Grid,
  Tooltip,
  Typography,
  useMediaQuery,
  WithStyles,
  useTheme,
  withStyles,
} from '@material-ui/core';
// icons
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Visibility from '@material-ui/icons/Visibility';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Share from '@material-ui/icons/Share';
import Edit from '@material-ui/icons/Edit';
import GetApp from '@material-ui/icons/GetApp';
// components
import MuiButton from 'components/Button';
import IconButton from 'components/IconButton';
import FormOptionsMenu from 'routes/Forms/FormOptionsMenu';
import ActivityFeed from 'components/ActivityFeed';
import EmptyMessage from 'components/EmptyMessage';
import ShareFormModal from 'routes/Forms/ShareFormModal';
import ExportResponsesModal from 'routes/Forms/ExportResponsesModal';
// action creators
import { clearActivities } from 'store/actions/activityActions';
// selectors
import getCurrentGroupId from 'store/selectors/getCurrentGroupId';
import getPermission from 'store/selectors/getPermission';
import getIsCurrentGroupSystem from 'store/selectors/getIsCurrentGroupSystem';
import getIsSystemAdmin from 'store/selectors/getIsSystemAdmin';
//styles
import formItemStyles from 'routes/Forms/FormItem/formItem.style';

const Button = withStyles({
  root: {
    '&.Mui-disabled': {
      pointerEvents: 'auto',
    },
  },
})(MuiButton);

export interface FormItemProps extends WithStyles<typeof formItemStyles> {
  id: number;
  title: string;
  setExpandedId: (...args: any[]) => any;
  expandedId?: number;
  eventId?: any;
  isCouncilForm?: boolean;
  isOrganizationForm?: boolean;
  isPublished: boolean;
}

const FormItem = function({
  classes,
  id,
  title,
  expandedId,
  setExpandedId,
  eventId,
  isCouncilForm,
  isOrganizationForm,
  isPublished,
}: FormItemProps) {
  const history = useHistory();
  const dispatch = useDispatch();
  const theme = useTheme();
  const isExtraSmall = useMediaQuery(theme.breakpoints.only('xs'));
  const responsesExpanded = id === expandedId;
  const [optionsAnchorEl, setOptionsAnchorEl] = useState(null);
  const [shareModalOpen, setShareModalOpen] = useState(false);
  const [exportModalOpen, setExportModalOpen] = useState(false);
  const handleOpenShareModal = useCallback(() => setShareModalOpen(true), []);
  const handleCloseShareModal = useCallback(() => setShareModalOpen(false), []);
  const openExportModal = useCallback(() => setExportModalOpen(true), []);
  const closeExportModal = useCallback(() => setExportModalOpen(false), []);
  const {
    currentGroupId,
    canAccessFormOptions,
    canUpdateForm,
    isCurrentGroupSystem,
    isSystemAdmin,
  } = useSelector(state => ({
    currentGroupId: getCurrentGroupId(state),
    isCurrentGroupSystem: getIsCurrentGroupSystem(state),
    isSystemAdmin: getIsSystemAdmin(state),
    canUpdateForm: getPermission('form.updateOne')(state),
    canAccessFormOptions: [roles.admin, roles.leader].includes(
      (state as any).getIn(['currentUser', 'data', 'roleId'], roles.member)
    ),
  }));

  const isUniversalForm = useMemo<boolean>(
    () => isCurrentGroupSystem && isSystemAdmin,
    [isCurrentGroupSystem, isSystemAdmin]
  );

  const initialResponsesFilters = useMemo(
    () => ({
      actions: ['form.submitted'],
      createdOnDate: null,
      member: null,
      targetId: id,
      targetType: 'Form',
    }),
    [id]
  );
  const source = useMemo<{
    name: 'group' | 'pnm';
    id: number;
  }>(
    () => ({
      name: 'group',
      id: currentGroupId,
    }),
    [currentGroupId]
  );
  const navigateToViewForm = useCallback(() => {
    const path = `/forms/${id}/view`;
    history.push(path);
  }, [history, id]);
  const navigateToEditForm = useCallback(() => {
    const path = `/forms/${id}/edit`;
    history.push(path);
  }, [history, id]);
  const handleResponsesExpanded = () => {
    const newExpandedId = !responsesExpanded ? id : null;
    setExpandedId(newExpandedId);
    dispatch(clearActivities());
  };

  return (
    <Grid item xs={12} sm={10} md={8} lg={7}>
      <Card variant='outlined'>
        <Grid container className={classes.headerContainer}>
          <Grid item xs={12} sm={6} lg={7}>
            <Typography variant='body2' color='textSecondary'>
              Name
            </Typography>
            <Typography variant='body1' className={classes.bold}>
              {title}
            </Typography>
          </Grid>

          <Grid item xs={12} sm={6} lg={5}>
            <Grid
              container
              alignItems='center'
              className={classes.actionsContainer}
            >
              <Grid item xs>
                <ButtonGroup>
                  <Button
                    id='viewButton'
                    endIcon={<Visibility />}
                    onClick={navigateToViewForm}
                  >
                    View
                  </Button>

                  {canUpdateForm && (
                    <Button
                      id='editButton'
                      endIcon={<Edit />}
                      onClick={navigateToEditForm}
                    >
                      Edit
                    </Button>
                  )}

                  {!isExtraSmall && isPublished && (
                    <Tooltip title='Choose how to share your published form.'>
                      <Button
                        id='shareButton'
                        endIcon={<Share />}
                        onClick={handleOpenShareModal}
                      >
                        Share
                      </Button>
                    </Tooltip>
                  )}
                </ButtonGroup>
              </Grid>

              {(canAccessFormOptions || isUniversalForm) && (
                <Grid item xs>
                  <IconButton
                    id='optionsButton'
                    onClick={(e: any) => setOptionsAnchorEl(e.currentTarget)}
                  >
                    <MoreVertIcon />
                  </IconButton>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>

        <Grid container className={classes.responsesContainer}>
          <Grid item xs={12}>
            <Accordion
              elevation={0}
              expanded={responsesExpanded}
              onChange={handleResponsesExpanded}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Grid container>
                  <Grid item xs={12}>
                    <Typography
                      className={classNames(
                        classes.responsesLabel,
                        classes.bold
                      )}
                    >
                      Responses
                    </Typography>
                  </Grid>
                </Grid>
              </AccordionSummary>

              <AccordionDetails className={classes.expansionDetails}>
                {responsesExpanded && (
                  <div>
                    <IconButton
                      id='exporButton'
                      onClick={openExportModal}
                      className={classes.exportIcon}
                    >
                      <GetApp />
                    </IconButton>
                    <ActivityFeed
                      source={source}
                      paginateOnScroll={false}
                      displayHeader={false}
                      emptyContent={
                        <EmptyMessage
                          title='None found.'
                          description={`Return here to track form responses as they're submitted.`}
                        />
                      }
                      filterOptions={{ showActions: false, showUsers: false }}
                      initialFilters={initialResponsesFilters}
                      isPnmLabelLinkDisabled={isUniversalForm}
                    />
                  </div>
                )}
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
      </Card>

      {(canAccessFormOptions || isUniversalForm) && (
        <FormOptionsMenu
          formId={id}
          eventId={eventId}
          anchorEl={optionsAnchorEl}
          onClose={() => setOptionsAnchorEl(null)}
          isCouncilForm={isCouncilForm}
          isOrganizationForm={isOrganizationForm}
        />
      )}

      <ShareFormModal
        formId={id}
        open={shareModalOpen}
        onClose={handleCloseShareModal}
        isCouncilForm={isCouncilForm}
        isOrganizationForm={isOrganizationForm}
      />

      <ExportResponsesModal
        formId={id}
        open={exportModalOpen}
        onClose={closeExportModal}
      />
    </Grid>
  );
};

export default withStyles(formItemStyles, { withTheme: true })(FormItem);
