import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import Immutable from 'immutable';

// action creators
import { doneIndicator, success } from 'store/actions/httpActions';

// api
import client from 'sources/api';

// constants
import {
  GROUP_TAG_CREATE_REQUEST,
  GROUP_TAG_CREATE,
} from 'store/constants/groupTagTypes';

// schemas
import { groupTagSchema } from 'store/schemas/groupTagSchema';

// helpers
import httpSaga from 'store/sagas/httpSaga';
import getResponsePayload from 'helpers/getResponsePayload';

const WATCH_TYPE = GROUP_TAG_CREATE_REQUEST;
const TYPE = GROUP_TAG_CREATE;

export function* createGroupTagsSaga(action: any) {
  const {
    payload: { groupId, parentId, tagIds, formName },
  } = action;

  const META_ID = groupId;

  // @ts-expect-error ts-migrate(2569) FIXME: Type 'Generator<any, any, unknown>' is not an arra... Remove this comment to see the full error message
  yield* httpSaga(
    TYPE,
    call(client.post, `/group_tags?group_id=${groupId}`, {
      parent_id: parentId,
      tag_ids: tagIds,
    }),
    {
      dispatchSuccess: false,
      schema: groupTagSchema,
      metaId: META_ID,
      formName,
    }
  );

  const response = {
    data: {
      results: tagIds.map((tagId: any) => ({
        id: groupId,
        groupId,
        parentId,
        tagId,
      })),
    },
  };

  const payload = getResponsePayload(response, groupTagSchema);
  // @ts-expect-error ts-migrate(2538) FIXME: Type 'undefined' cannot be used as an index type.
  payload.data.entities.groupTag = payload.data.entities.groupTag[undefined];
  // @ts-expect-error ts-migrate(2538) FIXME: Type 'undefined' cannot be used as an index type.
  delete payload.data.entities.groupTag[undefined];
  payload.data.result = tagIds;

  yield put(success(TYPE, Immutable.fromJS(payload), META_ID));
  yield put(doneIndicator(TYPE));
}

export function* watch() {
  yield takeEvery(WATCH_TYPE, createGroupTagsSaga);
}

export default function* root() {
  yield all([fork(watch)]);
}
