import {
  isSubmitting,
  hasSubmitSucceeded,
  hasSubmitFailed,
  getError,
  getResponseMessage,
} from 'store/selectors/formSelectors';

export default function getCombinedForm(formNames: any) {
  return (state: any) => ({
    isSubmitting: formNames
      .map((formName: any) => isSubmitting(formName)(state))
      .some(Boolean),

    hasSubmitSucceeded:
      formNames.length > 0 &&
      formNames
        .map((formName: any) => hasSubmitSucceeded(formName)(state))
        .every(Boolean),

    hasSubmitFailed: formNames
      .map((formName: any) => hasSubmitFailed(formName)(state))
      .some(Boolean),

    formErrors: formNames.reduce(
      (acc: any, formName: any) => ({
        ...acc,
        [formName]: getError(formName)(state),
      }),
      {}
    ),

    successMessages: formNames.reduce(
      (acc: any, formName: any) => ({
        ...acc,
        [formName]: getResponseMessage(formName)(state),
      }),
      {}
    ),
  });
}
