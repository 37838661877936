import { all } from 'redux-saga/effects';

// sagas
import fetchGroupFieldsSaga from './fetchGroupFieldsSaga';
import updateGroupFieldsSaga from './updateGroupFieldsSaga';
import deleteGroupField from './deleteGroupFieldsSaga';

const groupFieldSaga = function* rootSaga() {
  yield all([
    fetchGroupFieldsSaga(),
    updateGroupFieldsSaga(),
    deleteGroupField(),
  ]);
};

export default groupFieldSaga;
