import {
  SESSION_CREATE_REQUEST,
  SESSION_DESTROY_REQUEST,
  SESSION_REFRESH_REQUEST,
  SESSION_VALIDATE,
  SESSION_INVALIDATE,
  SESSION_VERIFY,
} from 'store/constants/sessionTypes';

export function createSession({ email, password }: any) {
  return {
    type: SESSION_CREATE_REQUEST,
    payload: { email, password },
  };
}

export function destroySession() {
  return {
    type: SESSION_DESTROY_REQUEST,
  };
}

export function refreshSession() {
  return {
    type: SESSION_REFRESH_REQUEST,
  };
}

export function validateSession() {
  return {
    type: SESSION_VALIDATE,
  };
}

export function invalidateSession() {
  return {
    type: SESSION_INVALIDATE,
  };
}

export function verifySession() {
  return {
    type: SESSION_VERIFY,
  };
}
