import { Map } from 'immutable';
// constants
import {
  PHONE_NUMBER_CREATE,
  PHONE_NUMBER_FETCH_FOR_GROUP,
  PHONE_NUMBER_UPDATE,
  PHONE_NUMBER_DELETE,
  PHONE_NUMBER_TOGGLE_CALL_FORWARDING,
} from 'store/constants/phoneNumberTypes';
// helpers
import httpReducer from './helpers/httpReducer';
import remove from './callbacks/remove';

export default function reducePhoneNumber(state = Map(), action = {}) {
  switch ((action as any).type) {
    case PHONE_NUMBER_CREATE:
    case PHONE_NUMBER_FETCH_FOR_GROUP:
      return httpReducer(state, action, { appendResult: true });
    case PHONE_NUMBER_UPDATE:
    case PHONE_NUMBER_TOGGLE_CALL_FORWARDING:
      return httpReducer(state, action, { updateResult: false });
    case PHONE_NUMBER_DELETE:
      return httpReducer(state, action, { callback: remove });
    default:
      return state;
  }
}
