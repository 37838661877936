import React, { useState, useCallback } from 'react';
// hooks
import { useDispatch } from 'react-redux';
// material-ui
import { Grid, Tooltip, WithStyles, withStyles } from '@material-ui/core';
// components
import IconButton from 'components/IconButton';
import AssignLabelsForm from 'routes/ChapterList/ChapterListActions/AssignLabelsForm';
// icons
import Delete from '@material-ui/icons/Delete';
import Add from '@material-ui/icons/Add';
// action creators
import { deleteGroupTagAction } from 'store/actions/groupTagActions';
//Styles
import labelsCellStyles from 'routes/ChapterList/LabelsCell/labelsCell.style';

export interface LabelsCellProps extends WithStyles<typeof labelsCellStyles> {
  tags?: any; // TODO: PropTypes.instanceOf(Array)
  groupId: number;
}

const LabelsCell = ({ classes, tags, groupId }: LabelsCellProps) => {
  const dispatch = useDispatch();

  const [modalOpen, setModalOpen] = useState(false);

  const handleLabelClick = (groupId: any, tagId: any) => {
    dispatch(deleteGroupTagAction({ groupId, tagId }));
  };

  const handleOpenModal = useCallback((e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    setModalOpen(true);
  }, []);
  const handleCloseModal = useCallback(() => setModalOpen(false), []);

  return (
    <Grid
      container
      alignItems='center'
      onClick={(event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
      }}
      className={classes.gridBox}
    >
      {tags.map(({ id, title, color }: any = {}) => {
        return (
          <Grid
            item
            xs
            key={id}
            onClick={(e: React.MouseEvent<HTMLElement>) => {
              e.preventDefault();
              handleLabelClick(groupId, id);
            }}
            className={classes.gridIn}
          >
            <Tooltip title={title}>
              <div
                style={{
                  backgroundColor: `#${color}`,
                }}
                className={classes.labelContainer}
              >
                <Delete className={classes.deleteIcon} />
              </div>
            </Tooltip>
          </Grid>
        );
      })}

      <Grid item xs className={classes.gridIn}>
        <Tooltip title='Assign Label'>
          <IconButton onClick={handleOpenModal} className={classes.addButton}>
            <Add />
          </IconButton>
        </Tooltip>

        {modalOpen && (
          <AssignLabelsForm
            selectedGroupIds={[groupId]}
            open={modalOpen}
            onClose={handleCloseModal}
            data={[
              {
                id: groupId,
                labelsClear: tags,
              },
            ]}
          />
        )}
      </Grid>
    </Grid>
  );
};

LabelsCell.defaultProps = {
  tags: [],
};

export default withStyles(labelsCellStyles, { withTheme: true })(LabelsCell);
