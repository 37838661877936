import React, { forwardRef } from 'react';
// material-ui
import {
  IconButton as MuiIconButton,
  IconButtonProps as MuiIconButtonProps,
} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';

interface Props extends MuiIconButtonProps {
  loading?: boolean;
  children?: React.ReactNode;
  disabled?: boolean;
}

const IconButton = forwardRef<any, Props>(function(
  { loading, children, disabled, ...restOfProps }: any,
  ref
) {
  return (
    <MuiIconButton ref={ref} disabled={loading || disabled} {...restOfProps}>
      {loading ? <CircularProgress disableShrink size={16} /> : children}
    </MuiIconButton>
  );
});
export default IconButton;
