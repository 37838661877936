import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

// action creators
import { doneIndicator } from 'store/actions/httpActions';

// api
import client from 'sources/api';

// constants
import {
  VOTE_METHOD_FETCH_REQUEST,
  VOTE_METHOD_FETCH,
} from 'store/constants/voteMethodTypes';

// schemas
import { voteMethodSchema } from 'store/schemas/voteMethodSchema';

// helpers
import httpSaga from 'store/sagas/httpSaga';

const WATCH_TYPE = VOTE_METHOD_FETCH_REQUEST;
const TYPE = VOTE_METHOD_FETCH;

export function* fetchVoteMethods(action: any) {
  const {
    payload: { groupId },
  } = action;

  // @ts-expect-error ts-migrate(2569) FIXME: Type 'Generator<any, any, unknown>' is not an arra... Remove this comment to see the full error message
  yield* httpSaga(
    TYPE,
    call(client.get, '/vote_methods', {
      params: { group_id: groupId },
    }),
    {
      schema: [voteMethodSchema],
    }
  );

  yield put(doneIndicator(TYPE));
}

export function* watch() {
  yield takeEvery(WATCH_TYPE, fetchVoteMethods);
}

export default function* root() {
  yield all([fork(watch)]);
}
