import {
  BILLING_FETCH_STRIPE_CONFIG_REQUEST,
  BILLING_CREATE_PORTAL_REQUEST,
} from 'store/constants/billingTypes';

export function fetchStripeConfig() {
  return {
    type: BILLING_FETCH_STRIPE_CONFIG_REQUEST,
  };
}

export function createBillingPortal({ returnUrl }: any) {
  return {
    type: BILLING_CREATE_PORTAL_REQUEST,
    payload: { returnUrl },
  };
}
