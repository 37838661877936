import { all, call, fork, put, takeEvery, select } from 'redux-saga/effects';
import Immutable from 'immutable';

// action creators
import { doneIndicator, success } from 'store/actions/httpActions';

// api
import client from 'sources/api';

// schemas
import { billingSchema } from 'store/schemas/billingSchema';

// constants
import {
  BILLING_CREATE_PORTAL,
  BILLING_CREATE_PORTAL_REQUEST,
} from 'store/constants/billingTypes';

// helpers
import httpSaga from 'store/sagas/httpSaga';
import getCurrentGroupId from 'store/selectors/getCurrentGroupId';
import getResponsePayload from 'helpers/getResponsePayload';
import isNamesListSite from 'helpers/isNamesListSite';

const WATCH_TYPE = BILLING_CREATE_PORTAL_REQUEST;
const TYPE = BILLING_CREATE_PORTAL;
const META_ID = 'portal';

export function* createBillingPortalSaga(action: any) {
  // @ts-expect-error ts-migrate(2525) FIXME: Initializer provides no value for this binding ele... Remove this comment to see the full error message
  const { payload: { returnUrl: return_url } = {} } = action;
  const currentGroupId = yield select(getCurrentGroupId);

  // @ts-expect-error ts-migrate(2569) FIXME: Type 'Generator<any, any, unknown>' is not an arra... Remove this comment to see the full error message
  const response = yield* httpSaga(
    TYPE,
    call(
      client.post,
      `/billing/self_service_session/?group_id=${currentGroupId}`,
      {
        return_url,
        is_nameslist: isNamesListSite(),
      }
    ),
    { schema: billingSchema, dispatchSuccess: false, metaId: META_ID }
  );

  if (response) {
    const portalData = response.data;

    response.data = {};

    Object.assign(response.data, {
      customer: { metadata: { groupId: currentGroupId } },
    });
    Object.assign(response.data, {
      portal: portalData,
    });

    const payload = getResponsePayload(response, billingSchema);

    yield put(success(TYPE, Immutable.fromJS(payload), META_ID));
  }

  yield put(doneIndicator(TYPE));
}

export function* watch() {
  yield takeEvery(WATCH_TYPE, createBillingPortalSaga);
}

export default function* root() {
  yield all([fork(watch)]);
}
