import { all } from 'redux-saga/effects';

// sagas
import addDashboardLinkSaga from './addDashboardLinkSaga';
import clearCurrentGroupSaga from './clearCurrentGroupSaga';
import createGroupSaga from './createGroupSaga';
import deleteGroupSaga from './deleteGroupSaga';
import fetchCurrentGroupSaga from './fetchCurrentGroupSaga';
import fetchGroupByIdSaga from './fetchGroupByIdSaga';
import fetchGroupsSaga from './fetchGroupsSaga';
import removeDashboardLinkSaga from './removeDashboardLinkSaga';
import setCurrentGroupSaga from './setCurrentGroupSaga';
import updateCurrentGroupSaga from './updateCurrentGroupSaga';
import updateGroupSaga from './updateGroupSaga';
import groupUnlinkSaga from './groupUnlinkSaga';

const sessionSaga = function* rootSaga() {
  yield all([
    addDashboardLinkSaga(),
    clearCurrentGroupSaga(),
    createGroupSaga(),
    deleteGroupSaga(),
    fetchCurrentGroupSaga(),
    fetchGroupByIdSaga(),
    fetchGroupsSaga(),
    removeDashboardLinkSaga(),
    setCurrentGroupSaga(),
    updateCurrentGroupSaga(),
    updateGroupSaga(),
    groupUnlinkSaga(),
  ]);
};

export default sessionSaga;
