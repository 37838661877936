import React from 'react';

// MUI components
import { Grid } from '@material-ui/core';
import TagUsageCard from './TagUsageCard';
import TagStatusCard from './TagStatusCard';

export interface TagsAnalyticsProps {
  isCouncilTagsAnalytics?: boolean;
}

const TagsAnalytics = function({ isCouncilTagsAnalytics }: TagsAnalyticsProps) {
  const lgGrid = isCouncilTagsAnalytics ? 6 : 12;

  return (
    <Grid container spacing={2} alignContent='flex-start'>
      <Grid item xs={12} lg={lgGrid}>
        <TagUsageCard isCouncilTagsAnalytics={isCouncilTagsAnalytics} />
      </Grid>
      <Grid item xs={12} lg={lgGrid}>
        <TagStatusCard />
      </Grid>
    </Grid>
  );
};

export default TagsAnalytics;
