import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

// action creators
import { doneIndicator } from 'store/actions/httpActions';

// api
import client from 'sources/api';

// constants
import { EVENT_UPDATE_REQUEST, EVENT_UPDATE } from 'store/constants/eventTypes';

// schemas
import { eventSchema } from 'store/schemas/eventSchema';

// helpers
import httpSaga from 'store/sagas/httpSaga';

const WATCH_TYPE = EVENT_UPDATE_REQUEST;
const TYPE = EVENT_UPDATE;

export function* updateEventSaga(action: any) {
  const {
    payload: { formName, eventId, groupId: group_id, ...values },
  } = action;

  const {
    title,
    eventDate: { startDate: start_date, endDate: end_date },
    location,
    pointOfContact: { value: point_of_contact = null } = {},
    description = '',
    inviteAllMembers: invite_all_members,
    linkToParents: link_to_parents,
    attendingStatusId: attending_status_id,
    attendedStatusId: attended_status_id,
    attendingTagIds: attending_tag_ids,
    attendedTagIds: attended_tag_ids,
  } = values;

  // @ts-expect-error ts-migrate(2569) FIXME: Type 'Generator<any, any, unknown>' is not an arra... Remove this comment to see the full error message
  yield* httpSaga(
    TYPE,
    call(client.patch, `/events/${eventId}?group_id=${group_id}`, {
      title,
      start_date,
      end_date,
      location,
      point_of_contact,
      description,
      invite_all_members,
      link_to_parents,
      attending_status_id,
      attended_status_id,
      attending_tag_ids,
      attended_tag_ids,
    }),
    {
      metaId: `update-${eventId}`,
      schema: eventSchema,
      formName,
    }
  );

  yield put(doneIndicator(TYPE));
}

export function* watch() {
  yield takeEvery(WATCH_TYPE, updateEventSaga);
}

export default function* root() {
  yield all([fork(watch)]);
}
