import React, { useState, useCallback } from 'react';

// router hooks
import { useParams } from 'react-router-dom';

// redux hooks
import { useSelector } from 'react-redux';

// icons
import { ThumbsUpDown } from '@material-ui/icons';

// components
import Button from 'components/Button';
import EndorseModal from 'components/EndorseModal';

// selectors
import getLoadingFromState from 'store/selectors/getLoadingFromState';

export default function EndorseButton() {
  const { leadId } = useParams<{ leadId: string }>();
  const [opened, setOpened] = useState(false);
  const toggleOpened = useCallback(() => setOpened(opened => !opened), []);

  const loading = useSelector(
    getLoadingFromState('lead', `fetchById-${leadId}`, false)
  );
  const buttonProps = {
    onClick: toggleOpened,
    disabled: loading,
  };
  return (
    <>
      <Button
        startIcon={<ThumbsUpDown />}
        style={{ marginTop: 18 }}
        fullWidth
        variant='outlined'
        {...buttonProps}
      >
        Endorse
      </Button>
      <EndorseModal opened={opened} onClose={toggleOpened} />
    </>
  );
}
