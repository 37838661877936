import React, { useCallback, useEffect, useState } from 'react';
import { useDeleteLeadsDuplicateMutation } from 'api/leadDuplicates';

// components
import LeadCard from './LeadListView/LeadListCardView/LeadCard';
import EmptyMessage from 'components/EmptyMessage';
import Header from 'components/Header';
import DazzlingDialog from 'components/DazzlingDialog';
import {
  Grid,
  Box,
  Typography,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core';
import { Group, CallMerge } from '@material-ui/icons';
import Button from 'components/Button';

type DuplicateLeadsProps = {
  leads: Lead[];
  isLeadsLoading: boolean;
  onDelete: () => void;
};

export default function DuplicateLeads({
  leads,
  isLeadsLoading,
  onDelete,
}: DuplicateLeadsProps) {
  const [opened, setOpened] = useState(false);
  const leadDuplicateId = leads[0]?.pnmDuplicateId;
  const [selectedLeadIds, setSelectedLeadIds] = useState<number[]>([]);
  const handleLeadSelect = useCallback(
    lead =>
      setSelectedLeadIds(selectedLeadIds => {
        if (selectedLeadIds.includes(lead.id)) {
          setPrimaryLeadId(primaryLeadId =>
            primaryLeadId === lead.id ? null : primaryLeadId
          );
          return selectedLeadIds.filter(
            selectedLeadId => selectedLeadId !== lead.id
          );
        } else {
          return [...selectedLeadIds, lead.id];
        }
      }),
    []
  );
  const [primaryLeadId, setPrimaryLeadId] = useState<number | null>(null);
  const handleLeadStarred = useCallback(
    (lead: Lead) =>
      setPrimaryLeadId(primaryLeadId =>
        primaryLeadId === lead.id ? null : lead.id
      ),
    []
  );
  const {
    isLoading: isDeletingDuplicate,
    mutate: deleteLeadsDuplicate,
    isSuccess,
    isError,
    reset,
  } = useDeleteLeadsDuplicateMutation();
  const isLoading = isLeadsLoading || isDeletingDuplicate;
  const handleAllSelectedChange = useCallback(
    () =>
      setSelectedLeadIds(selectedLeadIds =>
        selectedLeadIds.length < leads.length ? leads.map(lead => lead.id) : []
      ),
    [leads]
  );
  const handleDialogClose = useCallback(() => setOpened(false), []);
  const handleDialogOpen = useCallback(() => setOpened(true), []);
  const handleMergeDuplicateLeads = useCallback(() => {
    if (leadDuplicateId && selectedLeadIds.length && primaryLeadId)
      deleteLeadsDuplicate({
        id: leadDuplicateId,
        leadIds: selectedLeadIds,
        primaryLeadId: primaryLeadId,
      });
  }, [leadDuplicateId, deleteLeadsDuplicate, selectedLeadIds, primaryLeadId]);
  const handleIgnoreDuplicateLeads = useCallback(() => {
    if (leadDuplicateId && selectedLeadIds.length)
      deleteLeadsDuplicate({
        id: leadDuplicateId,
        leadIds:
          selectedLeadIds.length === leads.length - 1
            ? leads.map(lead => lead.id)
            : selectedLeadIds,
      });
  }, [leadDuplicateId, selectedLeadIds, deleteLeadsDuplicate, leads]);
  useEffect(() => {
    if (isSuccess) {
      reset();
      setSelectedLeadIds([]);
      if (primaryLeadId || selectedLeadIds.length >= leads.length - 1)
        onDelete();
    }
  }, [
    isSuccess,
    reset,
    onDelete,
    primaryLeadId,
    selectedLeadIds.length,
    leads.length,
  ]);
  return (
    <>
      <Box clone height='100%'>
        <Grid container direction='column' spacing={1} wrap='nowrap'>
          <Grid item>
            <Header
              title='Manage Duplicate Leads'
              subtitle='Merge leads based on similar email address, phone number, and name'
              icon={Group}
            />
          </Grid>
          <Box marginLeft='91px' clone>
            <Grid item xs>
              <Grid container direction='column' spacing={3}>
                <Grid item>
                  <Grid container spacing={2} justifyContent='space-between'>
                    <Grid item>
                      <Typography variant='body2'>
                        Select leads and then star the lead profile that you
                        want to keep and merge the duplicates into. Do this by
                        clicking on the star icon.
                      </Typography>
                      <Typography variant='body2' color='secondary'>
                        Merging will delete duplicate leads, but their data will
                        be preserved on the activity feed
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Box clone minWidth={480}>
                        <Grid container spacing={2}>
                          <Grid item xs={4}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  color='primary'
                                  checked={selectedLeadIds.length > 0}
                                  onChange={handleAllSelectedChange}
                                  indeterminate={
                                    selectedLeadIds.length > 0 &&
                                    selectedLeadIds.length < leads.length
                                  }
                                />
                              }
                              label='All Selected'
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <Button
                              variant='outlined'
                              fullWidth
                              onClick={handleIgnoreDuplicateLeads}
                              disabled={!selectedLeadIds.length}
                            >
                              Ignore
                            </Button>
                          </Grid>
                          <Grid item xs={4}>
                            <Button
                              startIcon={<CallMerge />}
                              variant='contained'
                              color='primary'
                              onClick={handleDialogOpen}
                              disabled={
                                selectedLeadIds.length < 2 || !primaryLeadId
                              }
                              fullWidth
                            >
                              Merge
                            </Button>
                          </Grid>
                        </Grid>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container spacing={2}>
                    {isLoading ? (
                      Array(2)
                        .fill(null)
                        .map((_, i) => (
                          <Grid item key={i} xs={12} lg={6} zeroMinWidth>
                            <LeadCard
                              lead={{} as Lead}
                              isSelected={false}
                              skeleton
                              withBorder
                              onSelect={() => {}}
                            />
                          </Grid>
                        ))
                    ) : leads.length === 0 ? (
                      <EmptyMessage
                        title={'No lead duplicates found'}
                        descriptionColor='secondary'
                      />
                    ) : (
                      leads.map(lead => (
                        <Grid item key={lead.id} xs={12} lg={6} zeroMinWidth>
                          <LeadCard
                            lead={lead}
                            isSelected={selectedLeadIds.includes(lead.id)}
                            starDisabled={selectedLeadIds.length < 2}
                            isStarred={lead.id === primaryLeadId}
                            withBorder
                            onSelect={handleLeadSelect}
                            onStar={handleLeadStarred}
                          />
                        </Grid>
                      ))
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Box>
      <DazzlingDialog
        id='mergeDuplicateLeads'
        metaId='mergeDuplicateLeads'
        isLoading={isDeletingDuplicate}
        isError={isError}
        isSuccess={isSuccess}
        successMessage='Duplicate Leads Merged Successfully'
        handleClose={handleDialogClose}
        open={opened}
        slice='lead'
        acceptLabel='Merge'
        onAccept={handleMergeDuplicateLeads}
        headerProps={{
          icon: CallMerge,
          title: `Merge duplicate leads`,
          highlightedSubtitle: 'Duplicate leads will be removed!',
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid
              container
              justifyContent='center'
              alignItems='center'
              spacing={2}
            >
              <Grid item xs={12}>
                <Typography paragraph>
                  {`Are you sure that you want to merge ${leads.length -
                    1} duplicate leads into the selected lead?`}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DazzlingDialog>
    </>
  );
}
