import { all } from 'redux-saga/effects';

// sagas
import fetchReferralSourceSaga from './fetchReferralSourceSaga';
import fetchReferralSourcesSaga from './fetchReferralSourcesSaga';
import createReferralSourceSaga from './createReferralSourceSaga';

const referralSourceSaga = function* rootSaga() {
  yield all([
    fetchReferralSourceSaga(),
    fetchReferralSourcesSaga(),
    createReferralSourceSaga(),
  ]);
};

export default referralSourceSaga;
