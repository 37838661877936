import React, { useEffect } from 'react';

// hooks
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

// material-ui
import { Grid } from '@material-ui/core';

// components
import EventHeaderCard from './EventHeaderCard';
import EventTabs from './EventTabs';

// selectors
import getLoadingFromState from 'store/selectors/getLoadingFromState';
import getCurrentGroupId from 'store/selectors/getCurrentGroupId';
import getEventById from 'store/selectors/getEventById';

// action creators
import { fetchEventById } from 'store/actions/eventActions';

export default function EventView() {
  const dispatch = useDispatch();
  const params: { eventId?: string } = useParams();
  const { eventId = '' } = params;

  const {
    eventsLoading,
    groupId,
    event,
  }: {
    event: GroupEvent;
    groupId: number;
    eventsLoading: boolean;
  } = useSelector(state => ({
    eventsLoading: getLoadingFromState('event', false, false)(state),
    event: getEventById(eventId)(state),
    groupId: getCurrentGroupId(state),
  }));

  useEffect(() => {
    dispatch(fetchEventById({ eventId, groupId }));
  }, [dispatch, groupId, eventId]);

  return (
    <Grid
      container
      justifyContent='center'
      style={{ paddingTop: 24, paddingBottom: 24 }}
    >
      <Grid item xs={12}>
        <EventHeaderCard event={event} loading={eventsLoading} />
      </Grid>
      <Grid item xs={12}>
        <EventTabs eventId={event?.id} />
      </Grid>
    </Grid>
  );
}
