import {
  MILESTONE_FETCH_REQUEST,
  MILESTONE_CREATE_REQUEST,
  MILESTONE_DELETE_REQUEST,
  MILESTONE_UPDATE_REQUEST,
} from 'store/constants/milestoneTypes';

export function fetchMilestonesAction({ groupId }: any) {
  return {
    type: MILESTONE_FETCH_REQUEST,
    payload: { groupId },
  };
}

export function createMilestoneAction({ formName, fields }: any) {
  return {
    type: MILESTONE_CREATE_REQUEST,
    payload: { formName, fields },
  };
}

export function deleteMilestoneAction({ formName, id }: any) {
  return {
    type: MILESTONE_DELETE_REQUEST,
    payload: { formName, id },
  };
}

export function updateMilestoneAction({ formName, id, fields }: any) {
  return {
    type: MILESTONE_UPDATE_REQUEST,
    payload: { formName, id, fields },
  };
}
