import { all } from 'redux-saga/effects';

// sagas
import createAndUploadFileSaga from './createAndUploadFileSaga';
import createFileAndInviteSaga from './createFileAndInviteSaga';
import createFileAndImportLeadsSaga from './createFileAndImportLeadsSaga';
import fetchFilesSaga from './fetchFilesSaga';

const fileSaga = function* rootSaga() {
  yield all([
    createAndUploadFileSaga(),
    fetchFilesSaga(),
    createFileAndInviteSaga(),
    createFileAndImportLeadsSaga(),
  ]);
};

export default fileSaga;
