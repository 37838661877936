import React from 'react';
import { Field, Form } from 'react-final-form';
import validate from 'validate.js';
import { isEqual } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

// selectors
import getChapters from 'store/selectors/getChapters';
import getLoadingFromState from 'store/selectors/getLoadingFromState';
import getIsSystemAdmin from 'store/selectors/getIsSystemAdmin';

// action creators
import { updateGroup } from 'store/actions/groupActions';

// MUI
import Grid from '@material-ui/core/Grid';

// components
import TextField from 'components/TextField';
import DazzlingDialog from 'components/DazzlingDialog';
import GroupsAutoSuggest from 'components/GroupsAutoSuggest';

// icons
import CouncilIcon from '@material-ui/icons/School';
import OrgIcon from '@material-ui/icons/LocationCity';
import ChapterIcon from '@material-ui/icons/AccountBalance';
import getGroupType from 'helpers/getGroupType';

type Props = {
  groupType: string;
  handleClose: (...args: any[]) => any;
  initialValues: any; // TODO: PropTypes.instanceOf(Object)
  open: boolean;
};

const DeleteChapterForm = ({
  groupType,
  handleClose,
  initialValues,
  open,
}: Props) => {
  const dispatch = useDispatch();
  const {
    id,
    name,
    displayName,
    currentActiveMembers,
    voteMethodId,
    voteVisibility,
  } = initialValues || {};
  const { chapters = [], groupLoading, isSystemAdmin } = useSelector(
    state => ({
      chapters: getChapters(state).toJS() as any,
      groupLoading: getLoadingFromState('group')(state),
      isSystemAdmin: getIsSystemAdmin(state),
    }),
    isEqual
  );

  const onSubmit = ({
    // @ts-expect-error ts-migrate(7031) FIXME: Binding element 'newDisplayName' implicitly has an... Remove this comment to see the full error message
    displayName: newDisplayName,
    // @ts-expect-error ts-migrate(7031) FIXME: Binding element 'newName' implicitly has an... Remove this comment to see the full error message
    name: newName,
  }) => {
    return dispatch(
      updateGroup({
        formName: 'updateGroupForm',
        id,
        name: newName,
        displayName: newDisplayName,
        currentActiveMembers,
        type: groupType,
        voteMethodId,
        voteVisibility,
      })
    );
  };

  const renderAutoSuggests = (values: any) => {
    return [
      <Grid item xs={12} key='council' id='autoSuggest'>
        <Field id='council' name='council' required>
          {props => (
            <GroupsAutoSuggest
              groupType='COUNCIL'
              values={values}
              suggestionsCardType='expand'
              {...props}
            />
          )}
        </Field>
      </Grid>,
      <Grid item xs={12} key='org' id='autoSuggest'>
        <Field id='organization' name='organization' required>
          {props => (
            <GroupsAutoSuggest
              groupType='GREEK_ORGANIZATION'
              values={values}
              suggestionsCardType='expand'
              {...props}
            />
          )}
        </Field>
      </Grid>,
    ];
  };

  const getDialogContent = (values: any) => {
    return (
      <Grid container>
        <Grid item xs={12} id='textField'>
          <Field
            name='displayName'
            component={TextField}
            label='Display Name'
            required
          />
          <Field
            name='name'
            component={TextField}
            label='Designation'
            required
          />
        </Grid>

        {groupType === 'GREEK_CHAPTER' &&
          !isSystemAdmin &&
          renderAutoSuggests(values)}
      </Grid>
    );
  };

  const getHeaderProps = () => {
    switch (groupType) {
      case 'GREEK_CHAPTER':
        return {
          icon: ChapterIcon,
          title: 'Update Chapter',
          subtitle: 'Edit chapter display name and designation',
        };
      case 'COUNCIL':
        return {
          icon: CouncilIcon,
          title: 'Update Council',
          subtitle: 'Update council display name and designation',
        };
      case 'GREEK_ORGANIZATION':
        return {
          icon: OrgIcon,
          title: 'Update Organization',
          subtitle: 'Update organization display name and designation',
        };
      default:
        return {
          title: 'Update',
        };
    }
  };

  const getInitialValues = () => {
    // groupType:
    // OTHER = 1
    // GREEK_CHAPTER = 2
    // GREEK_ORGANIZATION = 3
    // COUNCIL = 4
    const newValues = {
      voteMethodId,
      voteVisibility,
      currentActiveMembers,
    };

    switch (groupType) {
      //A greek chapter has a greek organization and a council as parents
      case 'GREEK_CHAPTER':
        const chapterParents =
          (chapters.find((chapter = {}) => (chapter as any).id === id) || {})
            .parents || [];
        const parentCouncil =
          chapterParents.find(
            (parent = {}) => (parent as any).type === getGroupType('COUNCIL')
          ) || {};
        const parentOrganization =
          chapterParents.find(
            (parent = {}) =>
              (parent as any).type === getGroupType('GREEK_ORGANIZATION')
          ) || {};

        return {
          ...newValues,
          name,
          displayName,
          council: {
            label: parentCouncil.displayName,
            value: parentCouncil.id,
          },
          organization: {
            label: parentOrganization.displayName,
            value: parentOrganization.id,
          },
        };
      //A council has no parents
      case 'COUNCIL':
        return { ...newValues, name, displayName };
      //A greek organization has no parents
      case 'GREEK_ORGANIZATION':
        return { ...newValues, name, displayName };
      default:
        return {};
    }
  };

  const schema = {
    displayName: {
      presence: true,
      length: {
        maximum: 1024,
      },
    },
    name: {
      presence: true,
      length: {
        maximum: 1024,
      },
    },
  };

  if (groupType === 'GREEK_CHAPTER') {
    (schema as any).organization = { presence: true };
    (schema as any).council = { presence: true };
  }
  const headerProps = getHeaderProps();
  const newInitialValues = getInitialValues();

  return (
    <Form
      id='updateForm'
      onSubmit={onSubmit}
      initialValues={newInitialValues}
      initialValuesEqual={isEqual}
      validate={values => validate(values, schema)}
      render={({
        handleSubmit,
        invalid,
        values,
        pristine,
        form: { reset },
      }) => (
        <DazzlingDialog
          id='updateDialog'
          acceptLabel='Update'
          children={getDialogContent(values)}
          disabled={invalid || pristine || groupLoading}
          formName='updateGroupForm'
          handleClose={handleClose}
          headerProps={headerProps}
          onReject={reset}
          onAccept={handleSubmit}
          open={open}
        />
      )}
    />
  );
};

export default DeleteChapterForm;
