import React, { useState, memo, useEffect, useCallback } from 'react';
import { debounce } from 'lodash';
// material-ui
import { CircularProgress, TextField, makeStyles } from '@material-ui/core';
import { Autocomplete as MuiAutocomplete } from '@material-ui/lab';
import { Search } from '@material-ui/icons';
type OwnProps = {
  options: any; // TODO: PropTypes.instanceOf(Array)
  inputValue?: string;
  label?: string;
  value?: any; // TODO: PropTypes.instanceOf(Object)
  onChange: (...args: any[]) => any;
  onInputChange: (...args: any[]) => any;
  loading?: boolean;
};
// @ts-expect-error ts-migrate(2456) FIXME: Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof Autocomplete.defaultProps;
// @ts-expect-error ts-migrate(7022) FIXME: 'Autocomplete' implicitly has type 'any' because i... Remove this comment to see the full error message
const Autocomplete = ({
  value,
  onInputChange,
  onChange,
  label,
  loading,
  options,
  ...rest
}: Props) => {
  const [inputValue, setInputValue] = useState(value.label);
  const classes = useStyles();
  useEffect(() => {
    setInputValue(value.label);
  }, [value.label, options]);
  const handleInputChange = (event: any, value: any, reason: any) => {
    setInputValue(value);
    onInputChange(event, value, reason);
  };
  const renderInput = useCallback(
    params => {
      return (
        <TextField
          {...params}
          multiline={false}
          variant='outlined'
          label={label}
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            ...params.InputProps,
            startAdornment: <Search />,
            endAdornment: (
              <React.Fragment>
                {loading && <CircularProgress size={20} />}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      );
    },
    [label, loading]
  );
  return (
    <MuiAutocomplete
      {...rest}
      classes={{ paper: classes.paper }}
      onInputChange={debounce(handleInputChange, 500)}
      onChange={onChange}
      options={options}
      getOptionLabel={option => (option as any).label || ''}
      getOptionSelected={(option, selected) => {
        const optionValue = (option as any)?.value?.toString();
        const selectedValue = (selected as any)?.value?.toString();
        return optionValue === selectedValue;
      }}
      inputValue={inputValue}
      value={value}
      renderInput={renderInput}
    />
  );
};
Autocomplete.defaultProps = {
  inputValue: '',
  value: {},
  label: 'Search',
};
const useStyles = makeStyles(theme => ({
  paper: {
    backgroundColor: (theme.palette.background as any).lightPaper,
  },
}));
export default memo(Autocomplete);
