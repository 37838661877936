import { all } from 'redux-saga/effects';

// sagas
import activitySagas from './activity';
import apiTaskSagas from './apiTask';
import billingSagas from './billing';
import commentSagas from './comment';
import emailSagas from './email';
import eventSagas from './event';
import eventGuestSagas from './eventGuest';
import fileSagas from './file';
import forgotPasswordSagas from './forgotPassword';
import formFieldSagas from './formField';
import formFeeSagas from 'store/sagas/formFee';
import groupFeatureUsageSagas from './groupFeatureUsage';
import groupFieldSagas from './groupField';
import groupSagas from './group';
import groupTagSagas from './groupTag';
import groupValueSagas from './groupValue';
import inviteSagas from './invite';
import memberSagas from './member';
import messageSagas from './message';
import milestoneSagas from './milestone';
import noteSagas from './note';
import phoneNumber from './phoneNumber';
import leadAttributeSagas from './leadAttribute';
import leadReferralSourceSagas from './leadReferralSource';
import leadSagas from './lead';
import leadTagSagas from './leadTag';
import referralFormSagas from './referralForm';
import referralSourceSagas from './referralSource';
import requestSagas from './request';
import sessionSagas from './session';
import statusSagas from './status';
import suggestedFieldSagas from './suggestedField';
import tagSagas from './tag';
import labelSagas from 'store/sagas/label';
import taskSagas from './task';
import threadSagas from './thread';
import userSagas from './user';
import voteSagas from './vote';
import voteMethodSagas from './voteMethod';
import updateLeadSharingSaga from 'store/sagas/communitySettings/updateLeadSharingSaga';
import paymentSettingsSaga from 'store/sagas/paymentSettings';
import oidcSagas from './oidc';
import authIntegrationSaga from './authIntegration';

export default function* sagas() {
  yield all([
    activitySagas(),
    apiTaskSagas(),
    billingSagas(),
    commentSagas(),
    emailSagas(),
    eventSagas(),
    eventGuestSagas(),
    fileSagas(),
    forgotPasswordSagas(),
    formFieldSagas(),
    formFeeSagas(),
    groupFeatureUsageSagas(),
    groupFieldSagas(),
    groupSagas(),
    groupTagSagas(),
    groupValueSagas(),
    inviteSagas(),
    memberSagas(),
    messageSagas(),
    milestoneSagas(),
    noteSagas(),
    phoneNumber(),
    leadAttributeSagas(),
    leadReferralSourceSagas(),
    leadSagas(),
    leadTagSagas(),
    referralFormSagas(),
    referralSourceSagas(),
    requestSagas(),
    sessionSagas(),
    oidcSagas(),
    statusSagas(),
    suggestedFieldSagas(),
    tagSagas(),
    labelSagas(),
    taskSagas(),
    threadSagas(),
    userSagas(),
    voteSagas(),
    voteMethodSagas(),
    updateLeadSharingSaga(),
    paymentSettingsSaga(),
    authIntegrationSaga(),
  ]);
}
