import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// actions
import {
  createIntegration,
  deleteIntegration,
  updateIntegration,
} from 'store/actions/authIntegrationActions';

// api
import { useAuthIntegrationsFullQuery } from 'api/integrations';

// components
import Header from 'components/Header';
import TextField from 'components/TextField';
import { EditIntegrationDialog } from './EditIntegrationDialog';
import { DeleteIntegrationDialog } from './DeleteIntegrationDialog';

// material-ui
import {
  Box,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  AccordionActions,
  makeStyles,
  Typography,
  Divider,
  Button,
  CircularProgress,
} from '@material-ui/core';
import grey from '@material-ui/core/colors/grey';

// icons
import LockIcon from '@material-ui/icons/Lock';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/AddCircleOutline';

// selectors
import getIsSystemAdmin from 'store/selectors/getIsSystemAdmin';

export default function AuthIntegrations() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const {
    data: integrationsData,
    isLoading: integrationsLoading,
  } = useAuthIntegrationsFullQuery();

  const { isSystemAdmin } = useSelector(state => ({
    isSystemAdmin: getIsSystemAdmin(state),
  }));

  const [displayForm, setDisplayForm] = useState(false);
  const [displayDelete, setDisplayDelete] = useState(false);
  const [
    integrationToDelete,
    setIntegrationToDelete,
  ] = useState<AuthIntegration | null>(null);
  const [
    integrationToEdit,
    setIntegrationToEdit,
  ] = useState<AuthIntegration | null>(null);

  if (!isSystemAdmin) {
    return null;
  }

  const onSubmitForm = (data: AuthIntegration) => {
    if (data.id) {
      dispatch(updateIntegration(data));
    } else {
      dispatch(createIntegration(data));
    }
    setDisplayForm(false);
  };
  const onSubmitDelete = (data: AuthIntegration) => {
    dispatch(deleteIntegration(data));
  };

  return (
    <>
      <Box>
        <Grid container>
          <Grid item xs={12}>
            <Header
              icon={LockIcon}
              title='SSO Integrations'
              subtitle='Manage SSO integrations for this system'
            />
          </Grid>
          <Grid item>
            <Box width={91} />
          </Grid>
          <Grid item xs={7}>
            {(integrationsData ?? []).length > 0 &&
              (integrationsData ?? []).map(integration => (
                <Accordion
                  elevation={0}
                  className={classes.accordion}
                  key={integration.id}
                >
                  <AccordionSummary
                    className={classes.accordionSummary}
                    expandIcon={<ExpandMoreIcon />}
                  >
                    {integration.label}
                  </AccordionSummary>
                  <AccordionDetails className={classes.accordionDetails}>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <TextField
                          label='Type'
                          value={integration.type}
                          disabled
                        />
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <TextField
                          label='Name'
                          value={integration.name}
                          disabled
                        />
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <TextField
                          label='Label'
                          value={integration.label}
                          disabled
                        />
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <TextField
                          label='Client ID'
                          value={integration.clientId}
                          disabled
                        />
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <TextField
                          label='Client Secret'
                          value={integration.clientSecret}
                          disabled
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <TextField
                          label='Scope'
                          value={integration.scope}
                          disabled
                        />
                      </Grid>

                      {!integration.discoveryUri && (
                        <Grid item xs={12}>
                          <TextField
                            label='JWKS URI'
                            value={integration.jwksUri}
                            disabled
                          />
                        </Grid>
                      )}

                      {!integration.discoveryUri && (
                        <Grid item xs={12}>
                          <TextField
                            label='Discovery URI'
                            value={integration.discoveryUri}
                            disabled
                          />
                        </Grid>
                      )}

                      {!integration.discoveryUri && (
                        <Grid item xs={12} sm={6}>
                          <TextField
                            label='Issuer'
                            value={integration.issuer}
                            disabled
                          />
                        </Grid>
                      )}

                      {!integration.discoveryUri && (
                        <Grid item xs={12} sm={6}>
                          <TextField
                            label='Authorization Endpoint'
                            value={integration.authorizationEndpoint}
                            disabled
                          />
                        </Grid>
                      )}

                      {!integration.discoveryUri && (
                        <Grid item xs={12} sm={6}>
                          <TextField
                            label='Token Endpoint'
                            value={integration.tokenEndpoint}
                            disabled
                          />
                        </Grid>
                      )}

                      {!integration.discoveryUri && (
                        <Grid item xs={12} sm={6}>
                          <TextField
                            label='Introspection Endpoint'
                            value={integration.introspectionEndpoint}
                            disabled
                          />
                        </Grid>
                      )}

                      {!integration.discoveryUri && (
                        <Grid item xs={12} sm={6}>
                          <TextField
                            label='Signout Endpoint'
                            value={integration.signoutEndpoint}
                            disabled
                          />
                        </Grid>
                      )}

                      {!integration.discoveryUri && (
                        <Grid item xs={12} sm={6}>
                          <TextField
                            label='Revocation Endpoint'
                            value={integration.revocationEndpoint}
                            disabled
                          />
                        </Grid>
                      )}

                      <Grid item xs={12}>
                        <Typography variant='h6'>
                          UserInfo Field Customizations
                        </Typography>
                      </Grid>

                      <Grid item xs={12}>
                        <TextField
                          label='Role Field'
                          value={integration.roleField}
                          disabled
                        />
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <TextField
                          label='Council Field'
                          value={integration.councilField}
                          disabled
                        />
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <TextField
                          label='Organization Field'
                          value={integration.organizationField}
                          disabled
                        />
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <TextField
                          label='First Name Field'
                          value={integration.firstNameField}
                          disabled
                        />
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <TextField
                          label='Last Name Field'
                          value={integration.lastNameField}
                          disabled
                        />
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <TextField
                          label='Email Field'
                          value={integration.emailField}
                          disabled
                        />
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <TextField
                          label='Picture Field'
                          value={integration.pictureField}
                          disabled
                        />
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                  <Divider />
                  <AccordionActions>
                    <Button
                      size='small'
                      endIcon={<EditIcon />}
                      variant='outlined'
                      onClick={() => {
                        setDisplayForm(true);
                        setIntegrationToEdit(integration);
                      }}
                    >
                      Edit
                    </Button>
                    <Button
                      size='small'
                      color='primary'
                      variant='contained'
                      disableElevation
                      onClick={() => {
                        setDisplayDelete(true);
                        setIntegrationToDelete(integration);
                      }}
                    >
                      Delete
                    </Button>
                  </AccordionActions>
                </Accordion>
              ))}

            {!(integrationsData ?? []).length && (
              <Grid
                container
                direction='column'
                alignItems='center'
                justifyContent='center'
                className={classes.error}
              >
                <Typography variant='body1'>
                  There are no SSO integrations in the System yet.
                </Typography>
              </Grid>
            )}

            {integrationsLoading && (
              <Grid
                container
                direction='column'
                alignItems='center'
                justifyContent='center'
                className={classes.spinner}
              >
                <CircularProgress color='secondary' size={30} />
              </Grid>
            )}

            <Button
              onClick={() => {
                setDisplayForm(true);
              }}
              startIcon={<AddIcon />}
            >
              Add SSO Integration
            </Button>
          </Grid>
        </Grid>
      </Box>

      <EditIntegrationDialog
        open={displayForm}
        onSubmit={onSubmitForm}
        onClose={() => {
          setDisplayForm(false);
          setIntegrationToEdit(null);
        }}
        integration={integrationToEdit}
      />

      <DeleteIntegrationDialog
        open={displayDelete}
        onDelete={onSubmitDelete}
        onClose={() => {
          setDisplayDelete(false);
          setIntegrationToDelete(null);
        }}
        integration={integrationToDelete}
      />
    </>
  );
}

const useStyles = makeStyles({
  spinner: {
    padding: '0.4em 0',
  },
  error: {
    padding: '1.5em 0',
    color: grey['400'],
    fontStyle: 'italic',
  },
  accordion: {
    marginBottom: '0.6em',
    border: `1px solid ${grey['700']}`,
    borderRadius: 6,
  },
  accordionSummary: {},
  accordionDetails: {
    borderTop: `1px solid ${grey['700']}`,
  },
  formFieldSection: {
    marginTop: '0.7em',
  },
});
