import Immutable, { List, Map } from 'immutable';
import { denormalize } from 'normalizr';
import { memberSchema } from '../schemas/memberSchema';
export default function getMembers(format?: any) {
  return (state: any) => {
    const members = state.get('member', Map());
    const result = members.getIn(['data', 'result'], List());
    const entities = {
      member: state.getIn(['member', 'data', 'byId'], Map()).toJS(),
    };
    let items = denormalize(result.toJS(), [memberSchema], entities);
    if (format === 'suggestions') {
      items = items.map((item = {}) => ({
        label: `${(item as any).account.firstName} ${
          (item as any).account.lastName
        }`,
        value: (item as any).account.id,
      }));
    }
    return Immutable.fromJS(items);
  };
}
