import { all, fork, takeEvery, put, take, select } from 'redux-saga/effects';
// action creators
import { doneIndicator } from 'store/actions/httpActions';
import { fetchCurrentGroup } from 'store/actions/groupActions';
import { fetchStatusesAction } from 'store/actions/statusActions';
import { fetchMilestonesAction } from 'store/actions/milestoneActions';
import { fetchTagsAction } from 'store/actions/tagActions';
import { fetchVoteMethodsAction } from 'store/actions/voteMethodActions';

// constants
import { CURRENT_GROUP_SET } from 'store/constants/groupTypes';

// helpers
import history from 'helpers/history';
import { isEmpty } from 'helpers/check';

// selectors
import getIsCurrentGroupChapter from 'store/selectors/getIsCurrentGroupChapter';
import getIsCurrentGroupCouncil from 'store/selectors/getIsCurrentGroupCouncil';
import getCurrentGroupId from 'store/selectors/getCurrentGroupId';

/*
 * Handle setting the current group in local storage and
 * fetching the current group from the API be dispatching `fetchCurrentGroup`
 */
export function* setCurrentGroup(action: any) {
  const { groupId, getGroupByType, skipNavigate } = action.payload;

  if (!groupId) {
    if (!isEmpty(getGroupByType)) {
      try {
        yield put(fetchCurrentGroup({ type: getGroupByType }));
        yield take('CURRENT_GROUP_FETCH_DONE');
        const currentGroupId = yield select(getCurrentGroupId);
        if (currentGroupId) {
          yield localStorage.setItem('group', currentGroupId);
        }
      } finally {
        yield put(doneIndicator(CURRENT_GROUP_SET));
      }
    }

    return;
  }

  try {
    const currentGroupId = yield select(getCurrentGroupId);
    localStorage.setItem(
      'navigatedFromGroupId',
      currentGroupId ? currentGroupId : ''
    );
    yield localStorage.setItem('group', groupId);
    const {
      location: { search },
    } = history;

    yield put(fetchCurrentGroup({ id: groupId }));
    // we wait to make sure that the current group has been fetched so that the navigation below uses the new current group
    yield take('CURRENT_GROUP_FETCH_DONE');

    const isCurrentGroupChapter = yield select(getIsCurrentGroupChapter);
    const isCurrentGroupCouncil = yield select(getIsCurrentGroupCouncil);

    if (isCurrentGroupChapter) {
      yield put(fetchStatusesAction({ groupId }));
      yield put(fetchMilestonesAction({ groupId }));
      yield put(fetchTagsAction({ groupId }));
      yield put(fetchVoteMethodsAction({ groupId }));
    }

    if (isCurrentGroupCouncil) {
      yield put(fetchStatusesAction({ groupId }));
      yield put(fetchMilestonesAction({ groupId }));
    }

    if (!skipNavigate) {
      const newSearch = new URLSearchParams(search);
      newSearch.delete('search');
      newSearch.set('group', groupId);

      let pathname = '/groups/chapters';
      if (isCurrentGroupChapter) {
        pathname = '/home';
      }
      if (isCurrentGroupCouncil) {
        pathname = '/chapters';
      }
      yield history.push({
        pathname: pathname,
        search: newSearch.toString(),
      });
    }
  } finally {
    yield put(doneIndicator(CURRENT_GROUP_SET));
  }
}

export function* watchCurrentGroupSet() {
  yield takeEvery(CURRENT_GROUP_SET, setCurrentGroup);
}

export default function* root() {
  yield all([fork(watchCurrentGroupSet)]);
}
