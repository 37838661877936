import { Styles } from 'assets/types';
import formViewStyles from 'routes/ReferralForm/FormView/formView.style';
import basicFieldsStyles from 'routes/ReferralForm/BasicFields/basicFields.style';

const referrerFieldsStyles: Styles = theme => {
  const { formContent } = formViewStyles(theme);
  const { leftField, rightField } = basicFieldsStyles(theme);
  return {
    formContent,
    leftField,
    rightField,
  };
};

export default referrerFieldsStyles;
