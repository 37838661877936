import React, { useCallback, useMemo } from 'react';
import validate from 'validate.js';

// hooks
import { useSelector, useDispatch } from 'react-redux';

// material-ui
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';

// icons
import FormIcon from '@material-ui/icons/Ballot';

//components
import DazzlingDialog from 'components/DazzlingDialog';
import TextField from 'components/TextField';
import { Form, Field } from 'react-final-form';
import Select from 'components/Select';

// action creators
import { createReferralForm } from 'store/actions/referralFormActions';

// selectors
import getCurrentGroupId from 'store/selectors/getCurrentGroupId';
import getIsCurrentGroupCouncil from 'store/selectors/getIsCurrentGroupCouncil';
import getIsSystemAdmin from 'store/selectors/getIsSystemAdmin';
import getIsCurrentGroupSystem from 'store/selectors/getIsCurrentGroupSystem';

// helpers
import getFormTypeOptions from 'helpers/getFormTypeOptions';

const FORM = 'createReferralForm';

type Props = {
  onClose: (...args: any[]) => any;
  open: boolean;
};

const CreateFormModal = ({ open, onClose }: Props) => {
  const dispatch = useDispatch();

  const {
    groupId,
    isCurrentGroupCouncil,
    isCurrentGroupSystem,
    isSystemAdmin,
  } = useSelector(state => ({
    groupId: getCurrentGroupId(state),
    isCurrentGroupCouncil: getIsCurrentGroupCouncil(state),
    isCurrentGroupSystem: getIsCurrentGroupSystem(state),
    isSystemAdmin: getIsSystemAdmin(state),
  }));

  const isUniversalForm = useMemo(() => isCurrentGroupSystem && isSystemAdmin, [
    isCurrentGroupSystem,
    isSystemAdmin,
  ]);

  const dispatchCreateForm = useCallback(
    values => {
      dispatch(
        createReferralForm({
          groupId,
          formName: FORM,
          ...(values || {}),
          ...(isUniversalForm ? { isUniversal: true } : {}),
        })
      );
    },
    [dispatch, groupId, isUniversalForm]
  );

  const dialogContent = useCallback(
    values => (
      <Grid container spacing={2} direction='column'>
        <Grid item xs={12} sm={8}>
          <Field
            name='title'
            label='Title'
            variant='outlined'
            component={TextField}
            margin='none'
            required
          />
        </Grid>
        <Grid item xs={12} sm={8}>
          <Field
            name='description'
            label='Description'
            variant='outlined'
            component={TextField}
            margin='none'
            multiline
          />
        </Grid>
        <Grid item xs={12} sm={8}>
          <Box marginBottom={1}>
            <Field
              name='type'
              label='Form Type'
              variant='outlined'
              component={Select}
              options={getFormTypeOptions(!!isCurrentGroupCouncil)}
              required
            />
          </Box>
        </Grid>
      </Grid>
    ),
    [isCurrentGroupCouncil]
  );

  const validateForm = useCallback(values => {
    return validate(values, schema);
  }, []);

  return (
    <Form
      id='createReferralForm'
      validate={validateForm}
      onSubmit={dispatchCreateForm}
      render={({ handleSubmit, invalid, values, form: { restart } }) => (
        <DazzlingDialog
          acceptLabel='Create'
          alertProps={{ message: 'Form Created Successfully' }}
          disabled={invalid}
          formName={FORM}
          handleClose={onClose}
          headerProps={{
            icon: FormIcon,
            title: isUniversalForm ? 'New Universal Form' : 'New Form',
            subtitle: 'Provide the title and type of your form to get started',
          }}
          id='createReferralForm'
          onAccept={handleSubmit}
          open={open}
          resetForm={restart}
        >
          {dialogContent(values)}
        </DazzlingDialog>
      )}
    />
  );
};

// schemas
const schema = {
  title: {
    presence: true,
  },
  description: {
    length: {
      maximum: 2048,
    },
  },
  type: {
    presence: true,
  },
};

export default CreateFormModal;
