import React, { useState, useCallback, useEffect, useMemo } from 'react';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { DEFAULT_LEADS_ORDER_BY, useLeadsInfiniteQuery } from 'api/leads';
import useDebounce from 'helpers/hooks/useDebounce';

// material-ui
import {
  Fade,
  Card,
  Grid,
  InputAdornment,
  CircularProgress,
  withStyles,
  WithStyles,
  Button,
  Tooltip,
  Snackbar,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';

// components
import TextField from 'components/TextField';
import IconButton from 'components/IconButton';
import LeadListFilter from 'components/LeadListFilter';

// icons
import Search from '@material-ui/icons/Search';
import Clear from '@material-ui/icons/Clear';

// selectors
import getCurrentGroupId from 'store/selectors/getCurrentGroupId';
import getCurrentUser from 'store/selectors/getCurrentUser';

//styles
import leadSearchBarStyles from 'layout/ApplicationLayout/components/LeadSearchBar/leadSearchBar.style';

export interface LeadSearchBarProps
  extends WithStyles<typeof leadSearchBarStyles> {}

const LeadSearchBar = ({ classes }: LeadSearchBarProps) => {
  const history = useHistory();
  const urlParams = useMemo(() => {
    return new URLSearchParams(history.location.search);
  }, [history.location.search]);
  const searchParam = urlParams.get('search') || '';

  const { isLoading: leadsLoading } = useLeadsInfiniteQuery();

  const currentGroupId = useSelector(getCurrentGroupId);
  const currentUser = useSelector(getCurrentUser);

  // State initializations
  const [showSaveButton, setShowSaveButton] = useState(false);
  const [showSaveSnackbar, setShowSaveSnackbar] = useState(false);
  const [searchValue, setSearchValue] = useState(searchParam);
  const [focused, setFocused] = useState(false);
  const debouncedSearchValue = useDebounce(searchValue, 600);

  const handleClear = useCallback(() => setSearchValue(''), []);

  const handleSearchChange = useCallback(
    ({ target: { value } }) => setSearchValue(value),
    []
  );

  const handleFiltersSave = useCallback(() => {
    const savedQueryString = localStorage.getItem('leadsListQueries');
    const savedQueryList = savedQueryString
      ? JSON.parse(savedQueryString)
      : null;
    if (savedQueryList) {
      const existingQuery = savedQueryList.find(
        ({ groupId, accountId }: { groupId: number; accountId: number }) =>
          groupId.toString() === currentGroupId.toString() &&
          accountId.toString() === currentUser.toJS().id.toString()
      );
      if (existingQuery) {
        existingQuery.queryParams = urlParams.toString();
      } else {
        savedQueryList.push({
          groupId: currentGroupId,
          accountId: currentUser.toJS().id,
          queryParams: urlParams.toString(),
        });
      }
      localStorage.setItem('leadsListQueries', JSON.stringify(savedQueryList));
    } else {
      localStorage.setItem(
        'leadsListQueries',
        JSON.stringify([
          {
            groupId: currentGroupId,
            accountId: currentUser.toJS().id,
            queryParams: urlParams.toString(),
          },
        ])
      );
    }
    setShowSaveSnackbar(true);
    setTimeout(() => {
      setShowSaveButton(false);
      setShowSaveSnackbar(false);
    }, 3000);
  }, [urlParams, currentUser, currentGroupId]);

  const getExistingSavedQuery = useCallback(() => {
    const savedQueryString = localStorage.getItem('leadsListQueries');
    const savedQueryList = savedQueryString
      ? JSON.parse(savedQueryString)
      : null;
    if (savedQueryList) {
      return savedQueryList.find(
        ({ groupId, accountId }: { groupId: number; accountId: number }) =>
          groupId.toString() === currentGroupId.toString() &&
          accountId.toString() === currentUser.toJS().id.toString()
      );
    }
  }, [currentGroupId, currentUser]);

  useEffect(() => {
    const newSearch = new URLSearchParams(history.location.search);
    if (debouncedSearchValue) {
      newSearch.set('search', debouncedSearchValue);
      newSearch.delete('orderBy');
    } else {
      newSearch.delete('search');
    }
    history.replace({
      pathname: history.location.pathname,
      search: newSearch.toString(),
    });
  }, [history, debouncedSearchValue]);

  useEffect(() => {
    const existingQuery = getExistingSavedQuery();
    const newSearch = new URLSearchParams(history.location.search);
    const newLeads = newSearch.get('newLeads');
    const uncontacted = newSearch.get('uncontacted');
    if (existingQuery && !newLeads && !uncontacted) {
      history.replace({
        pathname: history.location.pathname,
        search: existingQuery.queryParams,
      });
    } else if (newLeads) {
      newSearch.delete('newLeads');
      history.replace({ search: newSearch.toString() });
    } else if (uncontacted) {
      history.replace({ search: newSearch.toString() });
    }
  }, [history, getExistingSavedQuery]);

  useEffect(() => {
    const existingQuery = getExistingSavedQuery();
    if (urlParams.toString().includes('group')) urlParams.delete('group');
    if (existingQuery) {
      if (
        existingQuery.queryParams === urlParams.toString() ||
        urlParams.toString().includes('search')
      ) {
        setShowSaveButton(false);
      } else setShowSaveButton(true);
    } else if (
      !urlParams.toString() ||
      urlParams.toString().includes('search') ||
      urlParams.toString() === 'orderBy=' + DEFAULT_LEADS_ORDER_BY
    ) {
      setShowSaveButton(false);
    } else {
      setShowSaveButton(true);
    }
  }, [urlParams, getExistingSavedQuery]);

  return (
    <Grid container spacing={2} alignItems='center'>
      <Grid item xl={6} lg={7} md={8} sm={10}>
        <Card
          className={classNames(classes.searchBar, focused && classes.focused)}
        >
          <Grid container spacing={1} alignItems='center'>
            <Grid item xs={7} xl={8} className={classes.textField}>
              <TextField
                placeholder='Search Leads'
                value={searchValue}
                onChange={handleSearchChange}
                onFocus={() => setFocused(true)}
                onBlur={() => setFocused(false)}
                variant='standard'
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <Search />
                    </InputAdornment>
                  ),
                  disableUnderline: true,
                }}
              />
            </Grid>

            {leadsLoading ? (
              <Grid item xs={2} className={classes.barIcon}>
                <Fade in={leadsLoading}>
                  <CircularProgress size={20} />
                </Fade>
              </Grid>
            ) : (
              <Grid item xs={2} className={classes.barIcon}>
                <Fade in={Boolean(searchValue)}>
                  <IconButton id='clearButton' onClick={handleClear}>
                    <Clear />
                  </IconButton>
                </Fade>
              </Grid>
            )}

            <Grid item xs={3} xl={2} className={classes.filterIcon}>
              <LeadListFilter />
            </Grid>
          </Grid>
        </Card>
      </Grid>
      {showSaveButton && (
        <Grid item xs>
          <Tooltip
            id='leadListFiltersSave'
            title='Save applied filters as default'
          >
            <Button
              id='saveButton'
              color='primary'
              variant='contained'
              onClick={handleFiltersSave}
            >
              Save Filters
            </Button>
          </Tooltip>
          <Snackbar
            className={classes.filterSaveSnackbar}
            open={showSaveSnackbar}
            autoHideDuration={3000}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <Alert variant='filled'>
              Your filters have been set as default for this browser
            </Alert>
          </Snackbar>
        </Grid>
      )}
    </Grid>
  );
};

export default withStyles(leadSearchBarStyles, { withTheme: true })(
  LeadSearchBar
);
