import React, { useEffect } from 'react';
import { List, Map } from 'immutable';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

// material-ui
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

// components
import ListCard from 'components/ListCard';

// icons
import PeopleIcon from '@material-ui/icons/People';

// action creators
import { fetchSystemAdmins } from 'store/actions/userActions';

// selectors
import getUsers from 'store/selectors/getUsers';
import getIsSystemAdmin from 'store/selectors/getIsSystemAdmin';
import getLoadingFromState from 'store/selectors/getLoadingFromState';

const AdminSettings = function() {
  const classes = useStyles();

  const { isSystemAdmin, users, usersLoading, emailsLoading } = useSelector(
    state => ({
      isSystemAdmin: getIsSystemAdmin(state),
      users: getUsers(state) as any,
      usersLoading: getLoadingFromState('user')(state),
      emailsLoading: getLoadingFromState('email')(state),
    }),
    shallowEqual
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (isSystemAdmin) {
      dispatch(fetchSystemAdmins({ page: 1 }));
    }
  }, [dispatch, isSystemAdmin]);

  let items = List();

  users.forEach((user: any) => {
    const userEmails = user.get('emailAddresses', List());
    const primaryEmail = userEmails.find(
      (email = Map()) => email.get('primary'),
      {}, // context
      Map() // not set value
    );

    items = items.push(
      <Grid container direction='column'>
        <Typography className={classes.bold}>
          {`${user.get('firstName')} ${user.get('lastName')}`}
        </Typography>
        <Typography className={classes.semibold} color='textSecondary'>
          {primaryEmail.get('email', '')}
        </Typography>
      </Grid>
    );
  });

  const handlePageChange = (page: any) => {
    dispatch(fetchSystemAdmins({ page }));
  };

  return [
    <Grid item xs={12}>
      <ListCard
        // @ts-expect-error ts-migrate(2322) FIXME: Type '{ className: string; headerIcon: Overridable... Remove this comment to see the full error message
        className={classes.topCard}
        headerIcon={PeopleIcon}
        items={items}
        loading={(usersLoading && users.size === 0) || emailsLoading}
        paginationProps={{
          // TO-DO: Add pagination to users
          disableNextPage: false,
          onPageChange: handlePageChange,
          loading: usersLoading || emailsLoading,
          items: users,
        }}
        searchLabel='Search System Admins'
        searchPlaceholder='Enter name or email'
        subtitle='Manage system admins'
        title='System Admins'
      />
    </Grid>,
  ];
};

const useStyles = makeStyles({
  bold: {
    fontWeight: 800,
  },
  semibold: {
    fontWeight: 600,
  },
  topCard: {
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
  },
});

export default AdminSettings;
