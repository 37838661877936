import {
  all,
  call,
  fork,
  put,
  takeEvery,
  take,
  select,
} from 'redux-saga/effects';

import { Map } from 'immutable';

// action creators
import {
  doneIndicator,
  success,
  error,
  errorIndicator,
} from 'store/actions/httpActions';
import { importLeads } from 'store/actions/leadActions';
import {
  setFormSubmitSucceeded,
  destroyForm,
  setFormSubmitFailed,
} from 'store/actions/formActions';
import axios from 'axios';

// api
import client from 'sources/api';

// constants
import {
  FILE_CREATE_AND_IMPORT_LEADS_REQUEST,
  FILE_CREATE_AND_IMPORT_LEADS,
  FILE_UPLOAD,
} from 'store/constants/fileTypes';

// schemas
import { fileSchema } from 'store/schemas/fileSchema';

// lib
import httpSaga from 'store/sagas/httpSaga';

// selectors
import getErrorMessage from 'store/selectors/getErrorMessage';
import getStatusFromState from 'store/selectors/getStatusFromState';

const WATCH_TYPE = FILE_CREATE_AND_IMPORT_LEADS_REQUEST;

export function* createFileAndImportLeadsSaga(action: any) {
  const {
    payload: { file = {}, formName, groupId, shouldSkipDuplicates },
  } = action;
  const { type, name } = file;

  // 1) POST /files to create file entry in db, returning file id
  // @ts-expect-error ts-migrate(2569) FIXME: Type 'Generator<any, any, unknown>' is not an arra... Remove this comment to see the full error message
  const createResponse = yield* httpSaga(
    FILE_CREATE_AND_IMPORT_LEADS,
    call(client.post, '/files', {
      file_name: name,
      content_type: type,
    }),
    {
      formName,
      dispatchFormSuccess: false,
      dispatchSuccess: false,
      schema: fileSchema,
    }
  );

  if (createResponse) {
    // @ts-expect-error ts-migrate(2525) FIXME: Initializer provides no value for this binding ele... Remove this comment to see the full error message
    const { data: { id, uploadUrl } = {} } = createResponse;

    // 2) Upload file to presigned s3 URL
    const options = {
      headers: {
        'Content-Type': type,
      },
    };

    // @ts-expect-error ts-migrate(2569) FIXME: Type 'Generator<any, any, unknown>' is not an arra... Remove this comment to see the full error message
    yield* httpSaga(FILE_UPLOAD, call(axios.put, uploadUrl, file, options), {
      dispatchSuccess: false,
    });

    // @ts-expect-error ts-migrate(2554) FIXME: Expected 3 arguments, but got 1.
    yield put(success(FILE_UPLOAD));
    // Wait for the file upload to complete
    yield put(doneIndicator(FILE_UPLOAD));

    // 2) Post file ID to tasks endpoint to be processed and initiate the worker/task to bulk lead members
    yield put(importLeads({ fileId: id, groupId, shouldSkipDuplicates }));
    // Wait for the leads to be sent
    yield take('LEAD_IMPORT_DONE');

    const importStatus = yield select(state =>
      getStatusFromState('lead')(state)
    );

    if (importStatus === 'error') {
      const leadErrorMessage = yield select(state =>
        getErrorMessage('lead')(state)
      );

      const message = Map({
        data: Map({ errors: leadErrorMessage }),
      });

      yield put(setFormSubmitFailed(formName, message.toJS()));

      // Notify the store that this type had an error
      // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 2.
      yield put(errorIndicator(FILE_CREATE_AND_IMPORT_LEADS, message));

      // Dispatch an error payload for this type to be caught by the reducer
      yield put(error(FILE_CREATE_AND_IMPORT_LEADS, message));
    } else {
      yield put(setFormSubmitSucceeded(formName, 'Imported successfully'));
      // @ts-expect-error ts-migrate(2554) FIXME: Expected 3 arguments, but got 1.
      yield put(success(FILE_CREATE_AND_IMPORT_LEADS));
      yield put(destroyForm(formName));
    }
  }

  yield put(doneIndicator(FILE_CREATE_AND_IMPORT_LEADS));
}

export function* watch() {
  yield takeEvery(WATCH_TYPE, createFileAndImportLeadsSaga);
}

export default function* root() {
  yield all([fork(watch)]);
}
