import React from 'react';
import { List, Map } from 'immutable';
import { roles } from 'helpers/getRoles';

// route components
import Calls from 'routes/Calls';
import Home from 'routes/Home';
import GroupList from 'routes/GroupList';
import ChapterList from 'routes/ChapterList';
import SettingsLayout from 'layout/SettingsLayout';
import LeadList from 'routes/LeadList';
import Forms from 'routes/Forms';
import LeadImport from 'routes/LeadImport';
import Messages from 'routes/Messages';
import Analytics from 'routes/Analytics';
import ReviewMembers from 'routes/ReviewMembers';
import EventList from 'routes/EventList';
import EventView from 'routes/EventView';

// icons
import CallsIcon from '@material-ui/icons/Phone';
import HomeIcon from '@material-ui/icons/Home';
import ListIcon from '@material-ui/icons/RecentActors';
import SettingsIcon from '@material-ui/icons/SettingsApplications';
import MessagesIcon from '@material-ui/icons/QuestionAnswer';
import FormsIcon from '@material-ui/icons/Ballot';
import EventsIcon from '@material-ui/icons/DateRange';
import AnalyticsIcon from '@material-ui/icons/InsertChart';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';

const getPages = ({
  isSystemAdmin,
  isCouncilAdmin,
  isHqAdmin,
  currentGroupId,
  groupIds = List(),
  features = Map(),
  permissions = Map(),
  roleId,
  isCurrentGroupCouncil,
  isCurrentGroupChapter,
  isCurrentGroupOrganization,
  isCurrentGroupSystem,
}: any) => {
  let pages = List();

  if (currentGroupId && isCurrentGroupChapter) {
    pages = pages.push(
      Map({
        label: 'Home',
        path: '/home',
        component: Home,
        icon: HomeIcon,
        sidebar: true,
        mobileMenu: false,
      })
    );
  }
  if (
    currentGroupId &&
    !isCurrentGroupSystem &&
    (roleId === roles.admin || isSystemAdmin)
  ) {
    pages = pages.push(
      Map({
        path: '/review-members',
        component: ReviewMembers,
        sidebar: false,
      })
    );
  }

  if (isSystemAdmin || isHqAdmin || groupIds.size > 1) {
    pages = pages.push(
      Map({
        label: 'Home',
        path: '/groups',
        component: GroupList,
        icon: HomeIcon,
        sidebar:
          !currentGroupId || isCurrentGroupSystem || isCurrentGroupOrganization,
        mobileMenu: false,
      })
    );
  }

  if (
    isCurrentGroupCouncil &&
    ((isSystemAdmin && currentGroupId) || isCouncilAdmin)
  ) {
    pages = pages.push(
      Map({
        label: 'Chapter List',
        path: '/chapters',
        component: ChapterList,
        icon: AccountBalanceIcon,
        sidebar: true,
        mobileMenu: false,
      })
    );
  }

  if (
    currentGroupId &&
    (isCurrentGroupChapter || isCurrentGroupCouncil) &&
    features.getIn(['pnms', 'enabled'], false)
  ) {
    pages = pages.concat(
      List([
        Map({
          label: 'Lead List',
          path: '/leads',
          component: isCurrentGroupCouncil
            ? (props: any) => <LeadList {...props} isCouncilLeadPage />
            : LeadList,
          icon: ListIcon,
          sidebar: true,
          mobileMenu: false,
        }),
      ])
    );
  }

  if (
    currentGroupId &&
    (isCurrentGroupChapter || isCurrentGroupCouncil) &&
    (permissions.get('task.bulkImportPnms') ||
      isSystemAdmin ||
      isHqAdmin ||
      isCouncilAdmin)
  ) {
    pages = pages.concat(
      List([
        Map({
          path: '/import/leads',
          component: LeadImport,
          sidebar: false,
          mobileMenu: false,
        }),
      ])
    );
  }

  if (
    ((features.getIn(['texts', 'enabled'], false) &&
      permissions.get('message.createMany')) ||
      (features.getIn(['texts', 'enabled'], false) &&
        (isSystemAdmin || isHqAdmin))) &&
    !isCurrentGroupSystem &&
    !isCurrentGroupOrganization &&
    roleId !== roles.leader
  ) {
    pages = pages.push(
      Map({
        id: 'messages',
        label: 'Messages',
        path: '/messages',
        component: Messages,
        icon: MessagesIcon,
        sidebar: true,
        mobileMenu: false,
      })
    );
  }

  if (
    features.getIn(['calls', 'enabled'], false) &&
    (roleId === roles.admin || isSystemAdmin) &&
    !isCurrentGroupCouncil &&
    !isCurrentGroupSystem &&
    !isCurrentGroupOrganization
  ) {
    pages = pages.push(
      Map({
        id: 'calls',
        label: 'Calls',
        path: '/calls',
        component: Calls,
        icon: CallsIcon,
        sidebar: true,
        mobileMenu: false,
      })
    );
  }

  if (
    features.getIn(['events', 'enabled'], false) &&
    !isCurrentGroupSystem &&
    !isCurrentGroupOrganization &&
    roleId !== roles.member
  ) {
    pages = pages.push(
      Map({
        exact: true,
        path: '/events/:eventId',
        component: EventView,
        icon: EventsIcon,
        sidebar: false,
        mobileMenu: false,
      })
    );
    pages = pages.push(
      Map({
        exact: true,
        label: 'Events',
        path: '/events',
        component: EventList,
        icon: EventsIcon,
        sidebar: true,
        mobileMenu: true,
      })
    );
  }

  if (
    currentGroupId &&
    (isCurrentGroupChapter ||
      isCurrentGroupCouncil ||
      isCurrentGroupOrganization ||
      isCurrentGroupSystem) &&
    features.getIn(['forms', 'enabled'], false) &&
    (permissions.get('form.getMany') ||
      isSystemAdmin ||
      isHqAdmin ||
      isCouncilAdmin)
  ) {
    pages = pages.push(
      Map({
        label: 'Forms',
        path: '/forms',
        component: () => (
          <Forms
            isCouncilForm={!!isCurrentGroupCouncil}
            isOrganizationForm={!!isCurrentGroupOrganization}
            isUniversalForm={isCurrentGroupSystem && isSystemAdmin}
          />
        ),
        icon: FormsIcon,
        sidebar: true,
        mobileMenu: true,
      })
    );
  }

  if (
    currentGroupId &&
    !isCurrentGroupSystem &&
    features.getIn(['analytics', 'enabled'], false)
  ) {
    pages = pages.push(
      Map({
        label: 'Analytics',
        path: '/analytics/*',
        component: Analytics,
        icon: AnalyticsIcon,
        sidebar: true,
        mobileMenu: true,
      })
    );
  }

  pages = pages.concat(
    List([
      Map({
        label: 'Settings',
        path: '/settings/*',
        component: SettingsLayout,
        icon: SettingsIcon,
        sidebar: true,
        mobileMenu: true,
      }),
    ])
  );

  return pages;
};

export default getPages;
