import React from 'react';

// components
import Landing from 'layout/Landing';
import SignupForm from './SignupForm';
import SignupFormHeader from './SignupFormHeader';

const Signup = function() {
  return (
    <Landing>
      <SignupFormHeader />
      <SignupForm />
    </Landing>
  );
};

export default Signup;
