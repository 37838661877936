import { useMemo } from 'react';
import { useLocation } from 'react-router';
import getLeadFilterParams from 'helpers/getLeadFilterParams';

export default function useFetchFilters(): LeadFetchFilters {
  const { search } = useLocation();
  return useMemo(() => {
    const fetchFilters: LeadFetchFilters = {};
    const urlParams = new URLSearchParams(search);
    urlParams.forEach((value, key) => {
      switch (key) {
        case 'createdOnStart':
          fetchFilters.createdOnStart = value;
          break;
        case 'createdOnEnd':
          fetchFilters.createdOnEnd = value;
          break;
        case 'updatedOnStart':
          fetchFilters.updatedOnStart = value;
          break;
        case 'updatedOnEnd':
          fetchFilters.updatedOnEnd = value;
          break;
        case 'lastContactedOnStart':
          fetchFilters.lastContactedOnStart = value;
          break;
        case 'lastContactedOnEnd':
          fetchFilters.lastContactedOnEnd = value;
          break;
        case 'mainContactId':
          fetchFilters.mainContactId = value;
          break;
        case 'referralSourceId':
          fetchFilters.referralSourceId = value;
          break;
        case 'statuses':
          fetchFilters.statusIds = value
            ?.split(',')
            ?.map(urlStatus => parseInt(urlStatus, 10));
          break;
        case 'tags':
          fetchFilters.tagIds = value
            ?.split(',')
            ?.map(urlStatus => parseInt(urlStatus, 10));
          break;
        case 'tagId':
          fetchFilters.tagIds = getLeadFilterParams(
            fetchFilters?.tagIds,
            value
          );
          break;
        case 'milestoneId':
          fetchFilters.tagIds = getLeadFilterParams(
            fetchFilters?.tagIds,
            value
          );
          break;
        case 'excludedTagId':
          fetchFilters.excludedTagIds = getLeadFilterParams(
            fetchFilters?.excludedTagIds,
            value
          );
          break;
        case 'excludedMilestoneId':
          fetchFilters.excludedTagIds = getLeadFilterParams(
            fetchFilters?.excludedTagIds,
            value
          );
          break;
        case 'statusId':
          fetchFilters.statusIds = getLeadFilterParams(
            fetchFilters?.statusIds,
            value
          );
          break;
        default:
          break;
      }
    });
    return fetchFilters;
  }, [search]);
}
