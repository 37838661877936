import { Map, List } from 'immutable';

export default function addAccountByStatus(
  state: any,
  action: any,
  { status }: any = {}
) {
  const { payload = Map() } = action;
  let newState = state;
  const entity = payload.get('entity', '');

  const nestedItem =
    payload.getIn(['data', 'entities', entity], Map()).first() || Map();
  const id = nestedItem.getIn(['account', 'id'], '');

  if (nestedItem.get('status') === status) {
    newState = newState.setIn(['data', 'byId', id.toString()], nestedItem);

    const newResult = newState.getIn(['data', 'result'], List()).push(id);
    newState = newState.setIn(['data', 'result'], newResult);

    const oldTotal = newState.getIn(['meta', 'total'], 0);
    newState = newState.setIn(['meta', 'total'], oldTotal + 1);
  }

  return newState;
}
