import React, { useEffect, useState, useRef } from 'react';

// material-ui
import { Grid, Tooltip, withStyles, WithStyles } from '@material-ui/core';

//components
import DazzlingDialog from 'components/DazzlingDialog';
import Button from 'components/Button';

// icons
import CopyIcon from '@material-ui/icons/FileCopy';
import ShareIcon from '@material-ui/icons/Share';
import CodeIcon from '@material-ui/icons/Code';

// action creators
import { createReferralFormToken } from 'store/actions/referralFormActions';

// selectors
import getLoadingFromState from 'store/selectors/getLoadingFromState';
import getStatusFromState from 'store/selectors/getStatusFromState';
import getCurrentGroupId from 'store/selectors/getCurrentGroupId';
import getSliceEntityById from 'store/selectors/getSliceEntityById';

// hooks
import { useDispatch, useSelector } from 'react-redux';

//styles
import shareFormModalStyles from 'routes/Forms/ShareFormModal/shareFormModal.style';

export interface ShareFormModalProps
  extends WithStyles<typeof shareFormModalStyles> {
  onClose: (...args: any[]) => any;
  open: boolean;
  formId: number;
  isCouncilForm?: boolean;
  isOrganizationForm?: boolean;
}

const ShareFormModal = ({
  classes,
  open,
  onClose,
  formId,
}: ShareFormModalProps) => {
  const dispatch = useDispatch();
  const [linkCopied, setLinkCopied] = useState<boolean>(false);
  const [embedCopied, setEmbedCopied] = useState<boolean>(false);

  const {
    tokenLoading,
    tokenStatus,
    form,
    groupId,
    referralFormLoading,
  } = useSelector(state => ({
    tokenLoading: getLoadingFromState('referralForm', 'token', false)(state),
    referralFormLoading: getLoadingFromState(
      'referralForm',
      false,
      false
    )(state),
    tokenStatus: getStatusFromState('referralForm', 'token')(state),
    form: getSliceEntityById('referralForm', formId)(state),
    groupId: getCurrentGroupId(state),
  }));

  const formLinkRef = useRef(null);
  const formEmbedRef = useRef(null);

  const token = form.token;
  const url = window.location.origin;
  const formLink = `${url}/forms/${formId}/view/?token=${token}`;
  const formEmbed = `<iframe src='${formLink}' height='600' width='95%' style='border: 2px solid #dfdfdf; border-radius: 4px; display: block; margin: auto;'></iframe>`;

  useEffect(() => {
    if (open) {
      dispatch(createReferralFormToken({ formId, groupId }));
    }
  }, [dispatch, formId, groupId, open]);

  useEffect(() => {
    setTimeout(() => setLinkCopied(false), 1500);
  }, [linkCopied]);

  useEffect(() => {
    setTimeout(() => setEmbedCopied(false), 1500);
  }, [embedCopied]);

  const handleCopyLink = () => {
    navigator.clipboard.writeText(formLink);
    setLinkCopied(true);
  };

  const handleCopyEmbed = () => {
    navigator.clipboard.writeText(formEmbed);
    setEmbedCopied(true);
  };

  const dialogContent = () => {
    return (
      <Grid container spacing={2} direction='column'>
        <Grid item xs={12} className={classes.optionsCardContent}>
          <input
            className={classes.formLinkInput}
            type='text'
            ref={formLinkRef}
            value={formLink}
          />
          <input
            className={classes.formLinkInput}
            type='text'
            ref={formEmbedRef}
            value={formEmbed}
          />
        </Grid>
        <Grid
          item
          xs={12}
          className={classes.optionsCardContent}
          id='copyFormLink'
        >
          <Tooltip arrow open={linkCopied} title='Copied to clipboard'>
            <Button
              variant='outlined'
              startIcon={<CopyIcon />}
              loading={tokenLoading}
              onClick={handleCopyLink}
              disabled={tokenLoading || tokenStatus === 'error'}
            >
              Copy Form Link
            </Button>
          </Tooltip>
        </Grid>

        <Grid
          item
          xs={12}
          className={classes.optionsCardContent}
          id='copyEmbedCode'
        >
          <Tooltip arrow open={embedCopied} title='Copied to clipboard'>
            <Button
              variant='outlined'
              startIcon={<CodeIcon />}
              loading={tokenLoading}
              onClick={handleCopyEmbed}
              disabled={tokenLoading || tokenStatus === 'error'}
            >
              Copy Embed Code
            </Button>
          </Tooltip>
        </Grid>
      </Grid>
    );
  };

  return (
    <DazzlingDialog
      id='shareFormDialog'
      actions={[]}
      slice='referralForm'
      metaId={`update-${formId}`}
      handleClose={onClose}
      headerProps={dialogData}
      height='auto'
      open={open}
      disabled={referralFormLoading}
    >
      {dialogContent()}
    </DazzlingDialog>
  );
};

const dialogData = {
  icon: ShareIcon,
  title: 'Form Sharing Options',
  subtitle:
    'Copy the link or embed code to allow anyone to complete your form. Turn form sharing on or off in the Edit form window. Event forms are shared by default.',
};

export default withStyles(shareFormModalStyles)(ShareFormModal);
