import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import Immutable, { List, Map } from 'immutable';

// MUI
import { makeStyles } from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';

// components
import AutoSuggest from 'components/AutoSuggest';

// icons
import CouncilIcon from '@material-ui/icons/School';
import OrgIcon from '@material-ui/icons/LocationCity';
import ChapterIcon from '@material-ui/icons/AccountBalance';

const GroupsAutoSuggest = function({
  councils,
  chapters,
  fetchGroups,
  chapterLoading,
  organizationLoading,
  councilLoading,
  groupType,
  organizations,
  values,
  fetchParams,
  ...rest
}: any) {
  const classes = useStyles();

  const getAutoSuggestProps = (): Map<string, any> => {
    switch (groupType) {
      default:
      case 'STANDARD':
        return Map({});
      case 'GREEK_CHAPTER':
        return Map({
          key: 'chapter',
          label: 'Chapter',
          placeholder: 'Search Chapters',
          loading: chapterLoading,
          suggestions: chapters.toJS(),
          icon: <ChapterIcon />,
        });
      case 'COUNCIL':
        return Map({
          key: 'council',
          label: 'Council',
          placeholder: 'Search Councils',
          loading: councilLoading,
          suggestions: councils.toJS(),
          icon: <CouncilIcon />,
        });
      case 'GREEK_ORGANIZATION':
        return Map({
          key: 'organization',
          label: 'Organization',
          placeholder: 'Search Organizations',
          loading: organizationLoading,
          suggestions: organizations.toJS(),
          icon: <OrgIcon />,
        });
    }
  };

  const handleSearchChange = useCallback(
    (value: string) => {
      fetchGroups({ type: groupType, search: value, ...fetchParams });
    },
    [fetchGroups, groupType, fetchParams]
  );

  const autoSuggestProps = getAutoSuggestProps();

  return (
    <AutoSuggest
      label={autoSuggestProps.get('label')}
      placeholder={autoSuggestProps.get('placeholder')}
      formValues={Immutable.fromJS(values)}
      loading={autoSuggestProps.get('loading')}
      onSearchChange={handleSearchChange}
      defaultSuggestion={
        values[autoSuggestProps.get('key')]
          ? values[autoSuggestProps.get('key')]
          : {}
      }
      suggestions={autoSuggestProps.get('suggestions')}
      InputProps={{
        startAdornment: (
          <InputAdornment position='start' className={classes.adornment}>
            {autoSuggestProps.get('icon')}
          </InputAdornment>
        ),
      }}
      {...rest}
      required
    />
  );
};

GroupsAutoSuggest.propTypes = {
  councilLoading: PropTypes.bool.isRequired,
  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'typeof List' is not assignable t... Remove this comment to see the full error message
  councils: PropTypes.instanceOf(List).isRequired,
  chapterLoading: PropTypes.bool.isRequired,
  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'typeof List' is not assignable t... Remove this comment to see the full error message
  chapters: PropTypes.instanceOf(List).isRequired,
  fetchGroups: PropTypes.func.isRequired,
  fetchParams: PropTypes.instanceOf(Object),
  groupType: PropTypes.string.isRequired,
  organizationLoading: PropTypes.bool.isRequired,
  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'typeof List' is not assignable t... Remove this comment to see the full error message
  organizations: PropTypes.instanceOf(List).isRequired,
  values: PropTypes.instanceOf(Object).isRequired,
};

GroupsAutoSuggest.defaultProps = {
  fetchParams: {},
};

const useStyles = makeStyles(theme => ({
  adornment: {
    marginRight: 14,
  },
}));

export default GroupsAutoSuggest;
