import React from 'react';
import { useHistory } from 'react-router';
import { Field } from 'react-final-form';

// components
import Select from 'components/Select';
import { OptionsProps } from 'components/ChapterDropdown/ChapterDropdown';

// material-ui
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/styles';

// helpers
import { Theme } from 'assets/theme';

interface Props {
  subtext?: string;
  label?: string;
}

const GenderSelectionField = ({
  subtext = 'Please select one that applies. This will help us match you with the best organizations.',
  label = 'What type of organizations are you interested in?',
}: Props) => {
  const classes = useStyles();
  const { location: { pathname } = {} } = useHistory();
  const isEditing = pathname?.includes('edit');
  const options: OptionsProps[] = [
    {
      label: 'Men - Single-Gender Male identifying',
      value: 'Men',
    },
    {
      label: 'Women - Single-Gender Female identifying',
      value: 'Women',
    },
    {
      label:
        'CoEd/All Genders - Organizations open to all genders and identities',
      value: 'All',
    },
  ];

  return (
    <Grid item xs={12} className={classes.selectorContainer}>
      <Field
        disabled={isEditing}
        name='orgGender'
        variant='outlined'
        margin='normal'
        component={Select}
        label={label}
        options={options}
        helperText={subtext}
      />
    </Grid>
  );
};

const useStyles = makeStyles((theme: Theme) => {
  return {
    selectorContainer: {
      paddingTop: 8,
      paddingLeft: 50,
      paddingRight: 50,
      [theme.breakpoints.only('xs')]: {
        paddingLeft: 20,
        paddingRight: 20,
      },
    },
  };
});

export default GenderSelectionField;
