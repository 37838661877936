import React from 'react';
// material-ui
import { withStyles, WithStyles } from '@material-ui/core/styles';
import SvgIcon from '@material-ui/core/SvgIcon';
//styles
import formSuccessPageStyles from 'routes/ReferralForm/FormView/components/FormSuccessPage/formSuccessPage.style';
import { ReactComponent as SuccessIcon } from 'assets/images/success-icon.svg';

export interface FormSuccessPageProps
  extends WithStyles<typeof formSuccessPageStyles> {
  isOrganizationForm?: boolean | null;
  isUniversalForm?: boolean;
}

const FormSuccessPage = ({
  classes,
  isOrganizationForm,
  isUniversalForm,
}: FormSuccessPageProps) => (
  <div className={classes.root}>
    <div className={classes.left}>
      <SvgIcon
        className={classes.leftSvgIcon}
        component={SuccessIcon}
        viewBox='0 0 50 48'
      />
    </div>
    <div className={classes.right}>
      <div className={classes.title}>Success! Your form was submitted!</div>
      {isOrganizationForm || isUniversalForm ? (
        <div className={classes.text}>
          Your form was successfully submitted.
        </div>
      ) : (
        <div className={classes.text}>
          Your form was successfully submitted. If your submission included a
          payment, you should receive a receipt in your email (the email address
          you used when inputting your payment). If you do not, make sure to
          check your spam folder.
        </div>
      )}
    </div>
  </div>
);

export default withStyles(formSuccessPageStyles, { withTheme: true })(
  FormSuccessPage
);
