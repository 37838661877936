import { all, call, fork, put, takeEvery, select } from 'redux-saga/effects';
import Immutable from 'immutable';

// action creators
import { doneIndicator, success } from 'store/actions/httpActions';

// selectors
import getCurrentGroupId from 'store/selectors/getCurrentGroupId';

// api
import client from 'sources/api';

// constants
import {
  LEAD_REFERRAL_SOURCE_FETCH_REQUEST,
  LEAD_REFERRAL_SOURCE_FETCH,
} from 'store/constants/leadReferralSourceTypes';

// schemas
import { leadReferralSourceSchema } from 'store/schemas/leadReferralSourceSchema';
import getResponsePayload from 'helpers/getResponsePayload';

// helpers
import httpSaga from 'store/sagas/httpSaga';

const WATCH_TYPE = LEAD_REFERRAL_SOURCE_FETCH_REQUEST;
const TYPE = LEAD_REFERRAL_SOURCE_FETCH;

export function* fetchLeadReferralSources(action: any) {
  const {
    payload: { leadId },
  } = action;

  const currentGroupId = yield select(getCurrentGroupId);

  // @ts-expect-error ts-migrate(2569) FIXME: Type 'Generator<any, any, unknown>' is not an arra... Remove this comment to see the full error message
  const response = yield* httpSaga(
    TYPE,
    call(
      client.get,
      `/pnm_referral_sources?group_id=${currentGroupId}&pnm_id=${leadId}`
    ),
    {
      dispatchSuccess: false,
      schema: leadReferralSourceSchema,
      metaId: leadId,
    }
  );

  const payload = getResponsePayload(response, leadReferralSourceSchema);
  payload.data.entities.leadReferralSource[leadId] =
    // @ts-expect-error ts-migrate(2538) FIXME: Type 'undefined' cannot be used as an index type.
    payload.data.entities.leadReferralSource[undefined];
  // @ts-expect-error ts-migrate(2538) FIXME: Type 'undefined' cannot be used as an index type.
  delete payload.data.entities.leadReferralSource[undefined];
  payload.data.result = [leadId];

  yield put(success(TYPE, Immutable.fromJS(payload), leadId));
  yield put(doneIndicator(TYPE));
}

export function* watch() {
  yield takeEvery(WATCH_TYPE, fetchLeadReferralSources);
}

export default function* root() {
  yield all([fork(watch)]);
}
