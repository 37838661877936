export const FILE_CREATE_REQUEST = 'FILE_CREATE_REQUEST';
export const FILE_CREATE = 'FILE_CREATE';

export const FILE_CREATE_AND_INVITE_REQUEST = 'FILE_CREATE_AND_INVITE_REQUEST';
export const FILE_CREATE_AND_INVITE = 'FILE_CREATE_AND_INVITE';

export const FILE_CREATE_AND_IMPORT_LEADS_REQUEST =
  'FILE_CREATE_AND_IMPORT_LEADS_REQUEST';
export const FILE_CREATE_AND_IMPORT_LEADS = 'FILE_CREATE_AND_IMPORT_LEADS';

export const FILE_CREATE_AND_UPLOAD_REQUEST = 'FILE_CREATE_AND_UPLOAD_REQUEST';
export const FILE_CREATE_AND_UPLOAD = 'FILE_CREATE_AND_UPLOAD';

export const FILE_FETCH_REQUEST = 'FILE_FETCH_REQUEST';
export const FILE_FETCH = 'FILE_FETCH';

export const FILE_UPLOAD_REQUEST = 'FILE_UPLOAD_REQUEST';
export const FILE_UPLOAD = 'FILE_UPLOAD';

export const FILE_CLEAR = 'FILE_CLEAR';
