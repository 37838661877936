import React, { useEffect, useMemo, useState } from 'react';
import Immutable, { List } from 'immutable';
import { useSelector, useDispatch } from 'react-redux';
import { isEqual } from 'lodash';
import useChapterFetchParams from 'helpers/hooks/useChapterFetchParams';

// material-ui
import Grid from '@material-ui/core/Grid';

// components
import AutoSuggest from 'components/AutoSuggest';

// action creators
import { fetchGroups } from 'store/actions/groupActions';

// selectors
import getLoadingFromState from 'store/selectors/getLoadingFromState';
import getChapters from 'store/selectors/getChapters';

//helpers
import { isEmpty } from 'helpers/check';
import getInitialParams from 'routes/ChapterList/helpers/getInitialParams';

export interface ChapterDropdownSearchProps {
  currentGroupId: number;
  isCouncilAdmin?: boolean;
  token?: string | null;
  multiSelect?: boolean;
  values?: any;
  input?: {
    name?: string;
    value?: string | null;
  };
  labelFormat?: 'suggestionsByName' | 'suggestions';
}

const ChapterDropdownSearch = ({
  currentGroupId,
  token,
  multiSelect,
  input,
  isCouncilAdmin,
  values,
  labelFormat,
  ...restOfProps
}: ChapterDropdownSearchProps) => {
  const dispatch = useDispatch();

  const [lastSearch, setLastSearch] = useState<string | null | undefined>(
    'init'
  );

  const { name } = input || {};

  const { chapters, chapterLoading } = useSelector(
    state => ({
      chapters: getChapters(state, labelFormat || 'suggestions'),
      chapterLoading: getLoadingFromState(
        'group',
        'greekChapter',
        false
      )(state),
    }),
    isEqual
  );

  const params =
    useChapterFetchParams(
      getInitialParams({
        isCouncilAdmin: !!isCouncilAdmin,
        currentGroupId,
      }) || {}
    ) || {};

  const getNewChapters = (search?: string | null) => {
    if (lastSearch !== search && (!isEmpty(lastSearch) || !isEmpty(search))) {
      dispatch(
        fetchGroups({
          ...params,
          appendResult: false,
          mergeItems: false,
          fetchParents: false,
          token,
          ...(!isEmpty(search) ? { search } : {}),
        })
      );
      setLastSearch(!isEmpty(search) ? search : '');
    }
  };

  useEffect(() => {
    if (!chapterLoading) {
      getNewChapters();
    }
  }, []); // eslint-disable-line

  const suggestions = useMemo<{ label?: string; value?: string }[]>(
    () =>
      List.isList(chapters)
        ? (chapters.toJS() as { label?: string; value?: string }[])
        : [],
    [chapters]
  );
  const loading = chapterLoading;

  const onSearchChange = (search?: string | null) => {
    if (search || search === '') {
      getNewChapters(search);
    }
  };

  return (
    <Grid>
      <AutoSuggest
        // @ts-ignore
        suggestionsCardType='expand'
        autoSuggestType='expand'
        label={name || ''}
        loading={loading}
        onSearchChange={onSearchChange}
        suggestions={suggestions}
        variant='outlined'
        input={input}
        multiSelect={multiSelect}
        callSearchChangeWhenNull
        {...(values ? { formValues: Immutable.fromJS(values) } : {})}
        {...restOfProps}
      />
    </Grid>
  );
};

export default ChapterDropdownSearch;
