import React, { useContext } from 'react';

// components
import LandingSocialMediaLinks from '../LandingSocialMediaLinks';
import { SiteVisualDataContext } from 'components/SiteVisualData';

// material-ui
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';

// helpers
import { Theme } from 'assets/theme/theme';
import isNamesListSiteFunction from 'helpers/isNamesListSite';

/*
 * Some of this render logic could be pulled into a reusable `HorizontalLinkNavigation` layout component.
 * Right now this would be over engineering, but if we ever find ourselves needing to reuse this,
 * we should do that.
 */
const LandingNavigation = function() {
  const classes = useStyles();
  const { about, demo, pricing, supportEmail } = useContext(
    SiteVisualDataContext
  );
  const isNamesListSite = isNamesListSiteFunction();

  const linkProps = {
    rel: 'noopener',
    target: '_blank',
    variant: 'h6',
    className: classes.landingNavigation,
  };

  return (
    <Grid container direction='row' justifyContent='space-evenly' spacing={3}>
      <Grid item>
        {/* @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call. */}
        <Link href={about} {...linkProps}>
          About
        </Link>
      </Grid>

      <Grid item>
        {/* @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call. */}
        <Link href={demo} {...linkProps}>
          Demo
        </Link>
      </Grid>

      {!isNamesListSite && (
        <Grid item>
          {/* @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call. */}
          <Link href={pricing} {...linkProps}>
            Pricing
          </Link>
        </Grid>
      )}

      <Grid item>
        {/* @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call. */}
        <Link href={`mailto: ${supportEmail}`} {...linkProps}>
          Contact
        </Link>
      </Grid>

      <Grid item>
        <LandingSocialMediaLinks />
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles<Theme>(theme => ({
  landingNavigation: {
    color: theme.palette.link?.landingNavigation || 'inherit',
  },
}));

export default LandingNavigation;
