import React from 'react';
// material-ui
import { withStyles, WithStyles } from '@material-ui/core/styles';
//styles
import formPageStyles from 'routes/ReferralForm/FormView/components/FormPage/formPage.style';

export interface FormPageProps extends WithStyles<typeof formPageStyles> {
  isVisible: boolean;
  children?: React.ReactNode;
}

const FormPage = ({ classes, isVisible, children }: FormPageProps) =>
  isVisible ? (
    <>{children}</>
  ) : (
    <div className={classes.hidden}>{children}</div>
  );

export default withStyles(formPageStyles)(FormPage);
