import { List, Map } from 'immutable';

export default function removeLabels(state: any, action: any) {
  const { payload = Map() } = action;

  const groupId = payload.get('groupId');
  const oldData = state.get('data', Map());
  const oldLabels = oldData.getIn(
    ['byId', groupId?.toString(), 'labels'],
    List()
  );

  const indexToDelete = oldLabels.findIndex(
    (labelId: any) => labelId === payload.get('tagId')
  );
  const newLabels = oldLabels.splice(indexToDelete, 1);

  let newData = oldData.setIn(
    ['byId', groupId?.toString(), 'labels'],
    newLabels
  );

  return state.set('data', oldData.merge(newData));
}
