import React, { useEffect, useState, useRef, useCallback } from 'react';
import Immutable, { Map } from 'immutable';
import { roles } from 'helpers/getRoles';
// hooks
import { useDispatch, useSelector } from 'react-redux';
// material-ui
import { Grid, Typography, Card } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
// icons
import { DoneAll } from '@material-ui/icons';
// components
import TabController from 'components/TabController';
import TaskList from 'components/TaskList';
// action creators
import { fetchTasks, clearTasks } from 'store/actions/taskActions';
import CreateTaskButton from 'components/CreateTaskButton';
import getPermission from 'store/selectors/getPermission';
import getIsSystemAdmin from 'store/selectors/getIsSystemAdmin';

const TasksTab = function() {
  const containerRef = useRef();
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    currentUserId,
    canCreateTask,
    currentUserRole,
    nextTasksUrl,
    isSystemAdmin,
  } = useSelector(state => ({
    isSystemAdmin: getIsSystemAdmin(state),
    currentUserId: (state as any).getIn(['currentUser', 'data', 'id']),
    nextTasksUrl: (state as any).getIn(['task', 'meta', 'next']),
    canCreateTask: getPermission('todo.createOne')(state),
    currentUserRole: (state as any).getIn(
      ['currentUser', 'data', 'roleId'],
      roles.member
    ),
  }));
  const isLeaderOrAdmin =
    [roles.admin, roles.leader].includes(currentUserRole) || isSystemAdmin;
  const initialTab = 'incomplete';
  const [currentTab, setCurrentTab] = useState(initialTab);
  const [fetchTaskParams, setFetchTaskParams] = useState({
    accountId: currentUserId,
  });
  const fetchNextPage = useCallback(() => {
    if (nextTasksUrl) {
      const page = new URLSearchParams(nextTasksUrl).get('page');
      dispatch(fetchTasks({ ...fetchTaskParams, page }));
    }
  }, [dispatch, fetchTaskParams, nextTasksUrl]);
  useEffect(() => {
    handleChangeTab(initialTab);
  }, []); // eslint-disable-line
  const handleChangeTab = (newTab: any) => {
    setCurrentTab(newTab);
    let params;
    switch (newTab) {
      default:
      case 'incomplete':
        params = {
          // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ accountId: any; } | { complete... Remove this comment to see the full error message
          completed: 'false',
        };
        break;
      case 'complete':
        params = {
          completed: 'true',
        };
        break;
      case 'assignedToYou':
        params = {
          accountId: currentUserId,
        };
        break;
    }
    if (currentUserRole === roles.member) {
      params.accountId = currentUserId;
    }
    setFetchTaskParams(params);
    dispatch(clearTasks());
    dispatch(fetchTasks(params));
  };
  let tabs: any = Immutable.fromJS([
    {
      key: 'incomplete',
      label: 'Incomplete',
    },
    {
      key: 'complete',
      label: 'Complete',
    },
  ]);
  if (isLeaderOrAdmin) {
    tabs = tabs.unshift(
      Map({
        key: 'assignedToYou',
        label: 'For You',
      })
    );
  }
  return (
    <Grid container className={classes.tasksTab} justifyContent='center'>
      <Grid item xs={12} sm={10} lg={11}>
        <Card ref={containerRef}>
          <Grid
            container
            spacing={4}
            alignItems='center'
            className={classes.header}
          >
            <Grid item xs={10}>
              <Grid container alignItems='center' spacing={2}>
                <Grid item xs={3} sm={2} lg={3}>
                  <DoneAll fontSize='large' color='primary' />
                </Grid>

                <Grid item xs>
                  <Typography
                    variant='h5'
                    className={classes.bold}
                    id='elevio_task_overview'
                  >
                    Tasks
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            {canCreateTask && (
              <Grid item xs={2}>
                <CreateTaskButton />
              </Grid>
            )}
          </Grid>

          <Grid
            container
            className={classes.tabBarContainer}
            justifyContent='center'
          >
            <Grid item xs={12} sm={8} md={6} lg={10} xl={6}>
              <TabController
                // @ts-expect-error ts-migrate(2322) FIXME: Type '{ id: string; tabs: any; size: string; curre... Remove this comment to see the full error message
                id='tabController'
                tabs={tabs}
                size='compact'
                currentTab={currentTab}
                onAnyTabClick={handleChangeTab}
                containerColor='darkPaper'
                tabHoverColor='default'
              />
            </Grid>
          </Grid>

          <Grid container spacing={2} className={classes.taskListContainer}>
            <Grid item xs={12}>
              <TaskList
                isAssignedToYou={currentTab === 'assignedToYou'}
                hasNextPage={Boolean(nextTasksUrl)}
                onFetchNextPage={fetchNextPage}
              />
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </Grid>
  );
};
const useStyles = makeStyles(theme => {
  const border = `1px solid ${(theme.palette.background as any).lightPaper}`;
  return {
    tasksTab: {
      marginBottom: 24,
      [theme.breakpoints.only('xs')]: {
        marginBottom: 45,
        height: '100%',
      },
    },
    header: {
      borderBottom: border,
      marginBottom: 7,
      paddingLeft: 20,
      paddingRight: 20,
      paddingTop: 15,
    },
    bold: {
      fontWeight: 800,
    },
    tabBarContainer: {
      borderBottom: border,
      paddingBottom: 7,
    },
    taskListContainer: {
      paddingTop: 8,
      paddingLeft: 20,
      paddingRight: 20,
      [theme.breakpoints.down('xs')]: {
        paddingBottom: 65,
      },
    },
  };
});
export default TasksTab;
