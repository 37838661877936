import { List, Map } from 'immutable';
import { denormalize } from 'normalizr';

import { eventSchema } from '../schemas/eventSchema';

export default function getEventById(eventId: string | number) {
  return (state: any): GroupEvent => {
    const event = state.get('event', Map());

    const result = event
      .getIn(['data', 'result'], List())
      .filter((r: number) => r?.toString() === eventId?.toString());

    const entities = {
      event: state.getIn(['event', 'data', 'byId'], Map()).toJS(),
      member: state.getIn(['member', 'data', 'byId'], Map()).toJS(),
    };

    let items: GroupEvent[] = denormalize(
      result.toJS(),
      [eventSchema],
      entities
    );

    return items[0];
  };
}
