import { Map } from 'immutable';
// constants
import {
  GROUP_VALUE_CREATE,
  GROUP_VALUE_DELETE,
  GROUP_VALUE_FETCH,
} from 'store/constants/groupValueTypes';
// helpers
import httpReducer from './helpers/httpReducer';
import remove from './callbacks/remove';

export default function reduceGroupValue(state = Map(), action = {}) {
  switch ((action as any).type) {
    case GROUP_VALUE_DELETE:
      return httpReducer(state, action, {
        entity: 'groupValue',
        callback: remove,
      });
    case GROUP_VALUE_CREATE:
      return httpReducer(state, action, {
        entity: 'groupValue',
      });
    case GROUP_VALUE_FETCH:
      return httpReducer(state, action, {
        entity: 'groupValue',
      });
    default:
      return state;
  }
}
