import { Styles } from 'assets/types';

const chapterStatusCardStyle: Styles = theme => ({
  cardContainer: {
    height: '100%',
  },
  cardContent: {
    paddingLeft: 24,
    paddingRight: 24,
  },
  dateItem: {
    marginRight: 3,
  },
  toItem: {
    marginRight: 12,
    marginLeft: 8,
  },

  root: {
    backgroundColor: theme.palette.background.paper,
  },

  tableCell: {
    paddingTop: 10,
    paddingBottom: 10,
  },

  headerTableCell: {
    color: theme.palette.text.secondary,
  },

  noneFoundContainer: {
    border: `2px solid ${theme.palette.background.paper}`,
    borderRadius: 4,
    padding: 20,
  },
  textInputContainer: {
    [theme.breakpoints.down('lg')]: {
      order: 1,
    },
  },
  dateRangeContainer: {
    [theme.breakpoints.down('lg')]: {
      order: 1,
    },
  },
  tableContainer: {
    [theme.breakpoints.down('lg')]: {
      marginTop: 16,
    },
  },
});

export default chapterStatusCardStyle;
