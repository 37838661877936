import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

// action creators
import { doneIndicator, error } from 'store/actions/httpActions';

// api
import client from 'sources/api';

// constants
import {
  PAYMENT_DELETE_REQUEST,
  PAYMENT_DELETE,
} from 'store/constants/paymentSettingsTypes';

// helpers
import httpSaga from 'store/sagas/httpSaga';

const WATCH_TYPE = PAYMENT_DELETE_REQUEST;
const TYPE = PAYMENT_DELETE;

export function* deletePaymentSettingsSaga(action: any) {
  const {
    payload: { groupId, doAfterSuccessSubmit = () => {} },
  } = action || {};

  try {
    // @ts-expect-error ts-migrate(2569) FIXME: Type 'Generator<any, any, unknown>' is not an arra... Remove this comment to see the full error message
    yield* httpSaga(
      TYPE,
      call(client.delete, '/payment_setup', {
        params: { group_id: groupId },
      }),
      {
        throwError: true,
        dispatchSuccess: false,
      }
    );
    doAfterSuccessSubmit();
  } catch (e) {
    yield put(error(TYPE, e));
  }

  yield put(doneIndicator(TYPE));
}

export function* watch() {
  yield takeEvery(WATCH_TYPE, deletePaymentSettingsSaga);
}

export default function* root() {
  yield all([fork(watch)]);
}
