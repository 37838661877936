import {
  all,
  call,
  fork,
  put,
  takeEvery,
  select,
  take,
} from 'redux-saga/effects';
import { Map } from 'immutable';

// action creators
import {
  doneIndicator,
  success,
  error,
  errorIndicator,
} from 'store/actions/httpActions';
import { fetchTask } from 'store/actions/apiTaskActions';

// api
import client from 'sources/api';

// schemas
import { apiTaskSchema } from 'store/schemas/apiTaskSchema';

// constants
import {
  API_TASK_CREATE_EVENT_GUEST_MESSAGE_REQUEST,
  API_TASK_CREATE_EVENT_GUEST_MESSAGE,
} from 'store/constants/apiTaskTypes';

// helpers
import httpSaga from 'store/sagas/httpSaga';
import getSliceEntityById from 'store/selectors/getSliceEntityById';
import delayTwoSeconds from 'helpers/delayTwoSeconds';
import { EventGuestFilters } from 'api/eventGuests';

const WATCH_TYPE = API_TASK_CREATE_EVENT_GUEST_MESSAGE_REQUEST;
const TYPE = API_TASK_CREATE_EVENT_GUEST_MESSAGE;

export function* createEventGuestsMessagesSaga(action: {
  type: string;
  payload: {
    formName: string;
    groupId: number;
    eventId: number;
    leadIds?: number[] | 'all';
    accountIds?: number[] | 'all';
    leadFilters?: EventGuestFilters;
    memberFilters?: EventGuestFilters;
    body: string;
    subject: string;
    threadType: 'text' | 'email';
  };
}) {
  const {
    payload: {
      formName,
      groupId,
      eventId,
      leadIds,
      accountIds,
      leadFilters,
      memberFilters,
      body,
      subject,
      threadType,
    },
  } = action;

  // @ts-expect-error ts-migrate(2569) FIXME: Type 'Generator<any, any, unknown>' is not an arra... Remove this comment to see the full error message
  const taskPostResponse = yield* httpSaga(
    TYPE,
    call(client.post, `/tasks?group_id=${groupId}`, {
      name: 'create_and_send_event_guest_messages',
      params: {
        event_id: eventId,
        pnm_ids: leadIds,
        account_ids: accountIds,
        ...leadFilters,
        ...memberFilters,
        body,
        subject,
        thread_type: threadType,
      },
    }),
    {
      schema: apiTaskSchema,
      formName,
      dispatchSuccess: false,
      dispatchFormSuccess: false,
    }
  );

  // If there is no task response, dispatch done indicator and abort
  if (!taskPostResponse) {
    yield put(doneIndicator(TYPE));
    return;
  }

  // @ts-expect-error ts-migrate(2525) FIXME: Initializer provides no value for this binding ele... Remove this comment to see the full error message
  const { data: { taskId } = {} } = taskPostResponse || {};

  let resolvedTask;

  // Use the worker/task ID to hit an endpoint periodically to check on the status
  while (!resolvedTask) {
    yield delayTwoSeconds();

    yield put(fetchTask({ taskId, groupId }));
    yield take('API_TASK_FETCH_DONE');

    const task = yield select(state =>
      getSliceEntityById('apiTask', taskId.toString())(state)
    );

    if (task?.status && task?.status !== 'PROCESSING') {
      resolvedTask = task;
    }
  }
  if (resolvedTask?.status === 'FAILURE') {
    // Notify the store that this type had an error
    const taskData = yield select(state =>
      getSliceEntityById('apiTask', taskId.toString())(state)
    );

    const { result: { excMessage = '', errors = [] } = {} } = taskData;

    const message = {
      data: {
        msg: excMessage,
        errors,
      },
    };

    // Dispatch an error payload for this type to be caught by the reducer
    yield put(errorIndicator(TYPE));
    yield put(error(TYPE, message));
  } else {
    yield put(success(TYPE, Map()));
  }

  yield put(doneIndicator(TYPE));
}

export function* watch() {
  yield takeEvery(WATCH_TYPE, createEventGuestsMessagesSaga);
}

export default function* root() {
  yield all([fork(watch)]);
}
