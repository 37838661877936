import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { Map } from 'immutable';

// action creators
import { doneIndicator, success } from 'store/actions/httpActions';

// api
import client from 'sources/api';

// constants
import {
  GROUP_TAG_DELETE_REQUEST,
  GROUP_TAG_DELETE,
} from 'store/constants/groupTagTypes';

// schemas
import { groupTagSchema } from 'store/schemas/groupTagSchema';

// helpers
import httpSaga from 'store/sagas/httpSaga';

const WATCH_TYPE = GROUP_TAG_DELETE_REQUEST;
const TYPE = GROUP_TAG_DELETE;

export function* deleteGroupTagSaga(action: any) {
  const {
    payload: { groupId, tagId, formName },
  } = action;

  const META_ID = groupId;

  // @ts-expect-error ts-migrate(2569) FIXME: Type 'Generator<any, any, unknown>' is not an arra... Remove this comment to see the full error message
  yield* httpSaga(
    TYPE,
    call(client.delete, `/group_tags?group_id=${groupId}&tag_id=${tagId}`),
    {
      dispatchSuccess: false,
      schema: groupTagSchema,
      metaId: META_ID,
      formName,
    }
  );

  yield put(success(TYPE, Map({ id: tagId, tagId: tagId, groupId }), META_ID));
  yield put(doneIndicator(TYPE));
}

export function* watch() {
  yield takeEvery(WATCH_TYPE, deleteGroupTagSaga);
}

export default function* root() {
  yield all([fork(watch)]);
}
