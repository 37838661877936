export enum ManageSubscriptionStatus {
  Closed,
  BillingError,
  SelectYourPlan,
  SelectYourPlanYearOrMonth,
  DowngradePlan,
  EnterPaymentMethod,
  ConfirmOrder,
  OrderComplete,
  OrderCompleteClose,
}

export enum StripePaymentStatus {
  Init,
  SendRequestToGetPm,
  WaitingPm,
  PmSuccess,
  PmError,
  PMWasUsed,
  SendRequestToConfirmPIS,
  WaitingConfirmPIS,
  ConfirmPISSuccess,
  ConfirmPISError,
}
