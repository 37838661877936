import React, { useState, useCallback } from 'react';
import pluralize from 'pluralize';
import { Map } from 'immutable';
// hooks
import { useSelector } from 'react-redux';
import {
  useFeatureLimitRemaining,
  useFeatureHasLimit,
} from 'helpers/hooks/featureHooks';
// selectors
import getLoadingFromState from 'store/selectors/getLoadingFromState';
// material-ui
import { Grid, Card, CardContent, Box, Hidden } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
// icons
import { AddCircle, SwapVert } from '@material-ui/icons';
// components
import Header from 'components/Header';
import CreateStatusModal from './CreateStatusModal';
import StatusesForm from './StatusesForm';
import Button from 'components/Button';
export default function FieldsSettings() {
  const classes = useStyles();
  const [createModalOpened, setCreateModalOpened] = useState(false);
  const toggleCreateModal = useCallback(
    () => setCreateModalOpened(opened => !opened),
    [setCreateModalOpened]
  );
  const { buttonDisabled, statusSize } = useSelector(state => ({
    statusSize: (state as any).getIn(['status', 'data', 'byId'], Map()).size,
    buttonDisabled: getLoadingFromState('status'),
  }));
  const remainingStatuses = useFeatureLimitRemaining(
    'custom_statuses',
    statusSize
  );
  const statusesHaveLimit = useFeatureHasLimit('custom_statuses');
  let highlightedSubtitle = '';
  if (statusesHaveLimit) {
    if (remainingStatuses > 0) {
      highlightedSubtitle = pluralize(
        `You may create ${remainingStatuses} more statuses`,
        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'number | true' is not assignable... Remove this comment to see the full error message
        remainingStatuses
      );
    } else {
      highlightedSubtitle =
        'You are out of custom statuses. Delete statuses or upgrade for more.';
    }
  }
  const headerProps = {
    id: 'header',
    icon: SwapVert,
    title: 'Manage Lead Statuses',
    subtitle: 'Fine tune your recruitment process and how you track leads',
    highlightedSubtitle,
  };
  return (
    <Grid item xs>
      <Card id='tabContent' className={classes.topCard}>
        <CardContent>
          <Grid container>
            <Grid item xs>
              <Header {...headerProps} />
            </Grid>

            <Hidden smUp>
              <Grid item>
                <Button
                  id='create-status'
                  color='primary'
                  fabIcon={<AddCircle />}
                  variant='contained'
                  disabled={buttonDisabled}
                  startIcon={<AddCircle />}
                  onClick={toggleCreateModal}
                >
                  New Status
                </Button>
                <CreateStatusModal
                  open={createModalOpened}
                  onClose={toggleCreateModal}
                />
              </Grid>
            </Hidden>

            <Hidden xsDown>
              <Box width={75} />
            </Hidden>

            <Grid item xs={12}>
              <StatusesForm />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
}
const useStyles = makeStyles(theme => ({
  topCard: {
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
  },
}));
