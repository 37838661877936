import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useState,
  useMemo,
  useEffect,
} from 'react';
import { List, Map } from 'immutable';
import { isEqual } from 'lodash';
import validate from 'validate.js';
import { Field, Form } from 'react-final-form';
import { SetupIntent } from '@stripe/stripe-js';
// hooks
import { useDispatch, useSelector } from 'react-redux';
import {
  useUpdateSubscriptionPaymentMethodMutation,
  useUpdateSubscriptionDefaultPaymentMethodMutation,
} from 'api/billing';
// material-ui
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
// icons
import CheckIcon from '@material-ui/icons/Check';
//components
import DazzlingDialog from 'components/DazzlingDialog';
import TextField from 'components/TextField';
import StripeField from 'components/ManageSubscription/components/StripeField';
// selectors
import getCurrentUser from 'store/selectors/getCurrentUser';
import getCurrentGroupId from 'store/selectors/getCurrentGroupId';
import getCustomer from 'store/selectors/getCustomer';
// actions
import { fetchStripeConfig } from 'store/actions/billingActions';
// helpers
import { toString } from 'helpers/transform';
import { SubscriptionInfo } from 'components/ManageSubscription/helpers/interfaces';
import {
  ManageSubscriptionStatus,
  StripePaymentStatus,
} from 'components/ManageSubscription/helpers/types';

const FORM = 'enterPaymentMethodForm';

const SCHEMA = {
  stripeEmailAddress: {
    presence: true,
    email: { message: 'not valid' },
    length: { maximum: 255 },
  },
  cardNumberComplete: {
    presence: true,
    type: 'boolean',
    inclusion: {
      within: [true],
    },
  },
  cardExpiryComplete: {
    presence: true,
    type: 'boolean',
    inclusion: {
      within: [true],
    },
  },
  cardCvcComplete: {
    presence: true,
    type: 'boolean',
    inclusion: {
      within: [true],
    },
  },
};

interface PaymentMethodModalProps {
  open: boolean;
  isItNewSubscription: boolean;
  subscriptionInfo: SubscriptionInfo;
  onCurrentModalStatusChange: Dispatch<
    SetStateAction<ManageSubscriptionStatus>
  >;
  onSubscriptionInfoChange: Dispatch<SetStateAction<SubscriptionInfo>>;
  onClose?: (status?: ManageSubscriptionStatus) => void;
}

const PaymentMethodModal = ({
  open,
  isItNewSubscription,
  subscriptionInfo,
  onCurrentModalStatusChange,
  onSubscriptionInfoChange,
  onClose = () => {},
}: PaymentMethodModalProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [stripePaymentStatus, setStripePaymentStatus] = useState<
    StripePaymentStatus
  >(StripePaymentStatus.Init);
  const {
    isLoading: isPaymentMethodUpdating,
    isSuccess: isPaymentMethodUpdated,
    isError: isPaymentMethodUpdatingFailed,
    mutate: updateSubscriptionPaymentMethod,
    error: subscriptionPaymentMethodError,
    data: subscriptionPaymentMethodData,
  } = useUpdateSubscriptionPaymentMethodMutation();

  const {
    isLoading: isDefaultPaymentMethodUpdating,
    isSuccess: isDefaultPaymentMethodUpdated,
    isError: isDefaultPaymentMethodUpdatingFailed,
    mutate: updateSubscriptionDefaultPaymentMethod,
    error: subscriptionDefaultPaymentMethodError,
  } = useUpdateSubscriptionDefaultPaymentMethodMutation();

  const { currentGroupId, currentUser, customer } = useSelector(
    state => ({
      currentGroupId: getCurrentGroupId(state),
      currentUser: getCurrentUser(state),
      customer: getCustomer(state),
    }),
    isEqual
  );

  const primaryEmail = useMemo(() => {
    const stripeEmail = Map.isMap(customer) ? customer.get('email') : null;
    if (stripeEmail) {
      return stripeEmail;
    }

    const email = currentUser
      .get('emails', List())
      .find((email: any) => email.get('primary'), null, Map())
      .get('email');

    return email ? toString(email) : '';
  }, [currentUser, customer]);

  const initialValues = {
    stripeEmailAddress: subscriptionInfo?.emailAddress || primaryEmail || '',
    cardNumberComplete: false,
    cardExpiryComplete: false,
    cardCvcComplete: false,
    paymentMethodId: '',
  };

  const isLoading =
    isPaymentMethodUpdating ||
    isDefaultPaymentMethodUpdating ||
    [
      StripePaymentStatus.SendRequestToConfirmPIS,
      StripePaymentStatus.WaitingConfirmPIS,
    ].includes(stripePaymentStatus);

  const isSuccess =
    isPaymentMethodUpdated &&
    !errorMessage &&
    (!subscriptionPaymentMethodData?.clientSecret ||
      (stripePaymentStatus === StripePaymentStatus.ConfirmPISSuccess &&
        (!isDefaultPaymentMethodUpdating || isDefaultPaymentMethodUpdated)));

  const isError =
    isPaymentMethodUpdatingFailed ||
    isDefaultPaymentMethodUpdatingFailed ||
    !!errorMessage ||
    stripePaymentStatus === StripePaymentStatus.ConfirmPISError;

  const formMessage = useMemo((): string => {
    if (isSuccess) {
      return 'Payment Method was updated successfully';
    }

    if (
      isPaymentMethodUpdatingFailed ||
      stripePaymentStatus === StripePaymentStatus.ConfirmPISError
    ) {
      return (
        subscriptionPaymentMethodError?.response?.data?.msg ||
        "Payment Method wasn't updated"
      );
    }

    if (isDefaultPaymentMethodUpdatingFailed) {
      return (
        subscriptionDefaultPaymentMethodError?.response?.data?.msg ||
        "Payment Method wasn't setup as default. Please, try again!"
      );
    }

    return errorMessage || '';
  }, [
    errorMessage,
    isPaymentMethodUpdatingFailed,
    isDefaultPaymentMethodUpdatingFailed,
    subscriptionPaymentMethodError,
    subscriptionDefaultPaymentMethodError,
    isSuccess,
    stripePaymentStatus,
  ]);

  const onSetupIntentSuccess = useCallback(
    (email?: string) => (setupIntent?: SetupIntent) => {
      const { id: setupIntentId } = setupIntent || {};
      if (setupIntentId) {
        updateSubscriptionDefaultPaymentMethod({ setupIntentId, email });
      }
    },
    [updateSubscriptionDefaultPaymentMethod]
  );

  const dialogContent = useCallback(
    (values, mutators, handleSubmit) => {
      const stripeConfirmPaymentIntent =
        isPaymentMethodUpdated &&
        subscriptionPaymentMethodData?.clientSecret &&
        values?.paymentMethodId
          ? {
              clientSecret: subscriptionPaymentMethodData.clientSecret,
              paymentMethod: values.paymentMethodId,
            }
          : undefined;

      return (
        <div className={classes.dialogContentBox}>
          <Grid className={classes.container} container spacing={1}>
            <Grid item xs={12} className={classes.textWithLineContainer}>
              <div className={classes.textWithLine}>
                <div className={classes.textInLine}>Or pay with card</div>
              </div>
            </Grid>
            <Grid item xs={12}>
              <Field
                name='stripeEmailAddress'
                component={TextField}
                label='Email'
                required
              />
            </Grid>
            <Grid item xs={12}>
              <StripeField
                mutators={mutators}
                callSubmitGlobalForm={handleSubmit}
                stripePaymentStatus={stripePaymentStatus}
                onStripePaymentStatusChange={setStripePaymentStatus}
                stripeConfirmPaymentIntent={stripeConfirmPaymentIntent}
                onSetupIntentSuccess={onSetupIntentSuccess(
                  values?.stripeEmailAddress
                )}
              />
            </Grid>
          </Grid>
        </div>
      );
    },
    [
      stripePaymentStatus,
      setStripePaymentStatus,
      isPaymentMethodUpdated,
      subscriptionPaymentMethodData,
      onSetupIntentSuccess,
      classes.dialogContentBox,
      classes.textWithLineContainer,
      classes.textWithLine,
      classes.textInLine,
      classes.container,
    ]
  );

  const validateForm = useCallback(values => validate(values, SCHEMA), []);

  const onSuccessForm = useCallback(() => {
    dispatch(fetchStripeConfig());
  }, [dispatch]);

  const handleSubmitForm = useCallback(
    values => {
      if (
        !values?.paymentMethodId ||
        [
          StripePaymentStatus.PMWasUsed,
          StripePaymentStatus.SendRequestToGetPm,
          StripePaymentStatus.ConfirmPISSuccess,
          StripePaymentStatus.ConfirmPISError,
          StripePaymentStatus.WaitingConfirmPIS,
        ].includes(stripePaymentStatus)
      ) {
        setStripePaymentStatus(StripePaymentStatus.SendRequestToGetPm);
        return;
      }

      if (!isItNewSubscription) {
        if (values?.paymentMethodId && currentGroupId) {
          setErrorMessage(null);
          setStripePaymentStatus(StripePaymentStatus.PMWasUsed);
          updateSubscriptionPaymentMethod({
            paymentMethodId: values.paymentMethodId,
            groupId: currentGroupId,
            email: values?.stripeEmailAddress,
          });
          return;
        }
        setErrorMessage(
          'There are some issues with your request. Please update this page and try again.'
        );
        return;
      }

      onSubscriptionInfoChange(data => ({
        ...data,
        paymentMethodId: values.paymentMethodId,
        paymentMethodCardBrand: values?.paymentMethodCardBrand || '',
        paymentMethodCardLast4: values?.paymentMethodCardLast4 || '',
        emailAddress: values?.stripeEmailAddress || '',
      }));
      onCurrentModalStatusChange(ManageSubscriptionStatus.ConfirmOrder);
    },
    [
      currentGroupId,
      isItNewSubscription,
      stripePaymentStatus,
      onCurrentModalStatusChange,
      setErrorMessage,
      setStripePaymentStatus,
      onSubscriptionInfoChange,
      updateSubscriptionPaymentMethod,
    ]
  );

  const handleClose = useCallback(() => {
    onCurrentModalStatusChange(newCurrentModalStatus => {
      if (
        isItNewSubscription &&
        newCurrentModalStatus === ManageSubscriptionStatus.SelectYourPlan
      ) {
        onClose(newCurrentModalStatus);
        return newCurrentModalStatus;
      }
      onClose();

      return ManageSubscriptionStatus.Closed;
    });
  }, [onCurrentModalStatusChange, onClose, isItNewSubscription]);

  const handleClickBack = useCallback(() => {
    if (isItNewSubscription) {
      onCurrentModalStatusChange(ManageSubscriptionStatus.SelectYourPlan);
    }
  }, [onCurrentModalStatusChange, isItNewSubscription]);

  useEffect(() => {
    if (isPaymentMethodUpdated) {
      if (
        subscriptionPaymentMethodData?.clientSecret &&
        ![
          StripePaymentStatus.SendRequestToConfirmPIS,
          StripePaymentStatus.ConfirmPISSuccess,
          StripePaymentStatus.ConfirmPISError,
        ].includes(stripePaymentStatus)
      ) {
        setStripePaymentStatus(StripePaymentStatus.SendRequestToConfirmPIS);
      }
      onSuccessForm();
    }
  }, [isPaymentMethodUpdated]); // eslint-disable-line

  useEffect(() => {
    if (
      [
        StripePaymentStatus.ConfirmPISSuccess,
        StripePaymentStatus.ConfirmPISError,
      ].includes(stripePaymentStatus) ||
      isDefaultPaymentMethodUpdated
    ) {
      onSuccessForm();
    }
  }, [stripePaymentStatus, onSuccessForm, isDefaultPaymentMethodUpdated]);

  return (
    <Form
      id={FORM}
      validate={validateForm}
      onSubmit={handleSubmitForm}
      initialValues={initialValues}
      initialValuesEqual={isEqual}
      mutators={{
        setValue: ([field, value], state, { changeValue }) => {
          changeValue(state, field, () => value);
        },
      }}
      render={({
        handleSubmit,
        invalid,
        values,
        form: { restart, mutators },
      }) => (
        <DazzlingDialog
          acceptLabel={isItNewSubscription ? 'Review Order' : 'Update'}
          disabled={invalid}
          handleClose={handleClose}
          headerProps={{
            icon: CheckIcon,
            title: 'Enter Payment Method',
            alignItems: 'center',
          }}
          onAccept={handleSubmit}
          open={open}
          children={dialogContent(values, mutators, handleSubmit)}
          alertProps={{
            message: formMessage,
            color: isSuccess ? 'success' : 'error',
          }}
          isLoading={isLoading}
          isError={isError}
          isSuccess={isSuccess}
          hideSnackbar={isLoading}
          resetForm={restart}
          onReject={handleClickBack}
          rejectLabel={isItNewSubscription ? 'Back' : 'Cancel'}
          isBackgroundWhite
          isButtonOnTheRightCorner
          isFullWidth
        />
      )}
    />
  );
};

const useStyles = makeStyles(theme => ({
  dialogContentBox: {
    margin: '0px auto',
    width: '100%',
    maxWidth: 531,
  },
  container: {
    marginBottom: 10,
  },
  textWithLineContainer: {
    marginBottom: 10,
  },
  textWithLine: {
    position: 'relative',
    width: '100%',
    padding: '0px',
    textAlign: 'center',
    '&::before': {
      position: 'absolute',
      display: 'block',
      top: '50%',
      left: 0,
      width: '100%',
      borderTop: `1px solid ${theme.palette.grey[100]}`,
      content: '" "',
      zIndex: 1,
    },
  },
  textInLine: {
    position: 'relative',
    display: 'inline-block',
    boxSizing: 'border-box',
    maxWidth: '75%',
    textAlign: 'center',
    padding: '0px 15px',
    color: theme.palette.grey[500],
    fontSize: '0.9rem',
    fontWeight: 400,
    backgroundColor: theme.palette.common.white,
    zIndex: 2,
  },
}));

export default PaymentMethodModal;
