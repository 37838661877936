import { Map } from 'immutable';
// constants
import {
  STATUS_FETCH,
  STATUS_CREATE,
  STATUS_DELETE,
  STATUS_UPDATE,
  STATUS_CLEAR,
} from 'store/constants/statusTypes';
// helpers
import httpReducer from './helpers/httpReducer';
// callbacks
import removeMany from './callbacks/removeMany';

export default function reduceStatus(state = Map(), action = {}) {
  switch ((action as any).type) {
    case STATUS_FETCH:
      return httpReducer(state, action, { appendResult: true });
    case STATUS_CREATE:
      return httpReducer(state, action, { appendResult: true });
    case STATUS_DELETE:
      return httpReducer(state, action, { callback: removeMany });
    case STATUS_UPDATE:
      return httpReducer(state, action);
    case STATUS_CLEAR:
      return Map();
    default:
      return state;
  }
}
