import React from 'react';
import PropTypes from 'prop-types';
import validate from 'helpers/validate';

// components
import { Form, Field } from 'react-final-form';
import LandingTextField from 'layout/Landing/components/LandingTextField';

// material-ui
import {
  FormHelperText,
  Grid,
  InputAdornment,
  withStyles,
} from '@material-ui/core';
import EmailIcon from '@material-ui/icons/Email';
import LandingButton from 'layout/Landing/components/LandingButton';
import LockIcon from '@material-ui/icons/Lock';

// schemas
const sendEmailSchema = {
  email: {
    presence: true,
    email: { message: 'not valid' },
    length: { maximum: 255 },
  },
};

const passwordResetSchema = {
  password: {
    presence: true,
    containsUppercase: true,
    containsLowercase: true,
    containsNumber: true,
    length: {
      minimum: 8,
      maximum: 255,
    },
  },

  confirmPassword: {
    presence: true,
    equality: 'password',
    length: { maximum: 255 },
  },
};

const ForgotPasswordForm = function({
  classes,
  stage,
  createForgotPassword,
  updateForgotPassword,
  token,
  forgotPasswordFormSubmitting,
  successMessage,
  errorMessage,
}: any) {
  let formFields: any = [];
  let schema: any;

  if (stage === 'sendEmail') {
    schema = sendEmailSchema;
    formFields = [
      <Field
        key='email'
        name='email'
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'ComponentType<Pick<InferProps<any>, string |... Remove this comment to see the full error message
        component={LandingTextField}
        label='Email Address'
        InputProps={{
          startAdornment: (
            <InputAdornment position='start'>
              <EmailIcon />
            </InputAdornment>
          ),
        }}
        autoFocus
        required
      />,
    ];
  } else {
    schema = passwordResetSchema;
    formFields = [
      <Field
        key='password'
        name='password'
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'ComponentType<Pick<InferProps<any>, string |... Remove this comment to see the full error message
        component={LandingTextField}
        label='New Password'
        InputProps={{
          startAdornment: (
            <InputAdornment position='start'>
              <LockIcon />
            </InputAdornment>
          ),
        }}
        type='password'
        autoFocus
        required
      />,
      <Field
        key='confirmPassword'
        name='confirmPassword'
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'ComponentType<Pick<InferProps<any>, string |... Remove this comment to see the full error message
        component={LandingTextField}
        label='Confirm New Password'
        InputProps={{
          startAdornment: (
            <InputAdornment position='start'>
              <LockIcon />
            </InputAdornment>
          ),
        }}
        type='password'
        required
      />,
    ];
  }

  const onSubmit = (values: any) => {
    if (stage === 'sendEmail') {
      createForgotPassword(values);
    } else {
      updateForgotPassword(values.password, token);
    }
  };

  return (
    <Form
      onSubmit={onSubmit}
      validate={values => validate(values, schema)}
      render={({ handleSubmit, invalid }) => (
        <form onSubmit={handleSubmit} className={classes.formContainer}>
          <Grid container direction='column' spacing={6}>
            <Grid item className={classes.form}>
              {formFields}
              {(successMessage || errorMessage) &&
                !forgotPasswordFormSubmitting && (
                  <FormHelperText error={Boolean(errorMessage)}>
                    {errorMessage || successMessage}
                  </FormHelperText>
                )}
            </Grid>

            <Grid item>
              <Grid item>
                <LandingButton
                  id='sendLinkButton'
                  type='submit'
                  disabled={invalid || forgotPasswordFormSubmitting}
                  loading={forgotPasswordFormSubmitting}
                >
                  {stage === 'sendEmail' && 'Send Link'}
                  {stage === 'passwordReset' && 'Reset Password'}
                </LandingButton>
              </Grid>
            </Grid>
          </Grid>
        </form>
      )}
    />
  );
};

ForgotPasswordForm.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  createForgotPassword: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  forgotPasswordFormSubmitting: PropTypes.bool.isRequired,
  stage: PropTypes.oneOf(['passwordReset', 'sendEmail']).isRequired,
  successMessage: PropTypes.string,
  token: PropTypes.string,
  updateForgotPassword: PropTypes.func.isRequired,
};

const styles = (theme: any) => {
  return {
    formContainer: {
      [theme.breakpoints.up('sm')]: {
        padding: 24,
      },
    },
    form: {
      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },
      [theme.breakpoints.up('sm')]: {
        width: '350px',
      },
      [theme.breakpoints.up('lg')]: {
        width: '450px',
      },
    },
  };
};

export default withStyles(styles)(ForgotPasswordForm);
