import React, { useContext } from 'react';

// components
import { SiteVisualDataContext } from 'components/SiteVisualData';

// images
import FacebookLogo from 'assets/images/FacebookLogo';
import InstagramLogo from 'assets/images/InstagramLogo';
import TwitterLogo from 'assets/images/TwitterLogo';

// material-ui
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import SvgIcon from '@material-ui/core/SvgIcon';

// helpers
import { Theme } from 'assets/theme/theme';
import isNamesListSiteFunction from 'helpers/isNamesListSite';

const SocialMediaLinks = function() {
  const classes = useStyles();
  const isNamesListSite = isNamesListSiteFunction();
  const { facebook, instagram, twitter } = useContext(SiteVisualDataContext);

  const linkProps = {
    target: '_blank',
    underline: 'none',
    variant: 'h6',
    className: classes.socialMediaLinks,
  };

  return (
    <Grid container spacing={3}>
      <Grid item>
        {/* @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call. */}
        <Link aria-label='Facebook link' href={facebook} {...linkProps}>
          <SvgIcon className={classes.socialMediaLinks}>
            <FacebookLogo />
          </SvgIcon>
        </Link>
      </Grid>
      <Grid item>
        {/* @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call. */}
        <Link aria-label='Instagram link' href={instagram} {...linkProps}>
          <SvgIcon className={classes.socialMediaLinks}>
            <InstagramLogo />
          </SvgIcon>
        </Link>
      </Grid>

      {!isNamesListSite && (
        <Grid item>
          {/* @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call. */}
          <Link aria-label='Twitter  link' href={twitter} {...linkProps}>
            <SvgIcon className={classes.socialMediaLinks}>
              <TwitterLogo />
            </SvgIcon>
          </Link>
        </Grid>
      )}
    </Grid>
  );
};

const useStyles = makeStyles<Theme>(theme => ({
  socialMediaLinks: {
    color: theme.palette.link?.landingNavigation || 'inherit',
  },
}));

export default SocialMediaLinks;
