import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

// action creators
import { doneIndicator } from 'store/actions/httpActions';

// api
import client from 'sources/api';

// constants
import {
  STATUS_CREATE_REQUEST,
  STATUS_CREATE,
} from 'store/constants/statusTypes';

// schemas
import { statusSchema } from 'store/schemas/statusSchema';

// helpers
import httpSaga from 'store/sagas/httpSaga';

const WATCH_TYPE = STATUS_CREATE_REQUEST;
const TYPE = STATUS_CREATE;

export function* createStatus(action: any) {
  const {
    payload: {
      formName,
      groupId,
      defaultStatusId,
      values: { title, abbreviation, description, isDefault: is_default },
    },
  } = action;

  if (is_default) {
    // @ts-expect-error ts-migrate(2569) FIXME: Type 'Generator<any, any, unknown>' is not an arra... Remove this comment to see the full error message
    yield* httpSaga(
      TYPE,
      call(client.put, `/statuses/${defaultStatusId}?group_id=${groupId}`, {
        is_default: false,
      }),
      {
        schema: statusSchema,
        formName,
        dispatchFormSuccess: false,
        shouldDestroyForm: false,
      }
    );
  }

  // @ts-expect-error ts-migrate(2569) FIXME: Type 'Generator<any, any, unknown>' is not an arra... Remove this comment to see the full error message
  yield* httpSaga(
    TYPE,
    call(client.post, `/statuses?group_id=${groupId}`, {
      title,
      abbreviation,
      description,
      is_default,
    }),
    {
      schema: statusSchema,
      formName,
    }
  );

  yield put(doneIndicator(TYPE));
}

export function* watch() {
  yield takeEvery(WATCH_TYPE, createStatus);
}

export default function* root() {
  yield all([fork(watch)]);
}
