import React, {
  useCallback,
  useMemo,
  Dispatch,
  SetStateAction,
  useState,
} from 'react';
import { isEqual } from 'lodash';
import validate from 'validate.js';
import { Field, Form } from 'react-final-form';
// material-ui
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
// icons
import CheckIcon from '@material-ui/icons/Check';
//components
import DazzlingDialog from 'components/DazzlingDialog';
import RadioFieldAsButton from 'components/RadioFieldAsButton';
// helpers
import { SubscriptionInfo } from 'components/ManageSubscription/helpers/interfaces';
import { ManageSubscriptionStatus } from 'components/ManageSubscription/helpers/types';
import getNextStep from 'components/ManageSubscription/helpers/getNextStep';
import getNextAcceptLabel from 'components/ManageSubscription/helpers/getNextAcceptLabel';

const FORM = 'selectYourPlanMonthYearForm';

interface SelectYourPlanYearMonthModalProps {
  open: boolean;
  currentModalStatus: ManageSubscriptionStatus;
  isItNewSubscription: boolean;
  newSubscription?: null | StripeProduct;
  onCurrentModalStatusChange: Dispatch<
    SetStateAction<ManageSubscriptionStatus>
  >;
  onSubscriptionInfoChange: Dispatch<SetStateAction<SubscriptionInfo>>;
  currentSubscriptionPlanProductId?: string;
  currentSubscriptionPlanId?: string;
  currentSubscriptionPlanInterval?: string;
  stripeProducts?: StripeProduct[];
  onClose?: () => void;
}

const SCHEMA = {
  subscriptionMonthYear: {
    presence: { message: '^This field is required' },
  },
};

const SelectYourPlanYearMonthModal = ({
  open,
  currentModalStatus,
  isItNewSubscription,
  newSubscription,
  stripeProducts,
  currentSubscriptionPlanProductId,
  currentSubscriptionPlanId,
  currentSubscriptionPlanInterval,
  onCurrentModalStatusChange,
  onSubscriptionInfoChange,
  onClose = () => {},
}: SelectYourPlanYearMonthModalProps) => {
  const classes = useStyles();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const initialValues = {
    subscriptionMonthYear: currentSubscriptionPlanInterval || null,
  };

  const acceptLabel = useMemo(
    () =>
      getNextAcceptLabel(
        getNextStep(
          currentModalStatus,
          newSubscription,
          isItNewSubscription,
          stripeProducts,
          currentSubscriptionPlanProductId
        )
      ),
    [
      currentModalStatus,
      isItNewSubscription,
      stripeProducts,
      currentSubscriptionPlanProductId,
      newSubscription,
    ]
  );

  const dialogContent = useMemo(
    () => (
      <Grid className={classes.container} container spacing={1}>
        <Grid item xs={12}>
          <div className={classes.title}>Monthly or Yearly?</div>
          <div className={classes.subTitle}>
            Select how you want billed; monthly or yearly.
          </div>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs='auto'>
              <Field
                name='subscriptionMonthYear'
                type='radio'
                value='year'
                label='Yearly'
                component={RadioFieldAsButton}
              />
            </Grid>
            <Grid item xs='auto'>
              <Field
                name='subscriptionMonthYear'
                type='radio'
                value='month'
                label='Monthly'
                component={RadioFieldAsButton}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    ),
    [classes.container, classes.title, classes.subTitle]
  );

  const validateForm = useCallback(
    values => {
      setErrorMessage(null);
      return validate(values, SCHEMA);
    },
    [setErrorMessage]
  );

  const handleSubmitForm = useCallback(
    values => {
      setErrorMessage(null);
      const newSubscriptionPlanPriceId =
        newSubscription?.subscriptions && values?.subscriptionMonthYear
          ? newSubscription.subscriptions.find(
              subscription =>
                subscription?.interval === values.subscriptionMonthYear
            )?.priceId
          : null;
      if (!newSubscriptionPlanPriceId) {
        setErrorMessage(
          'There is some issue with your subscription. Please, update this page and try again.'
        );
        return;
      }
      if (newSubscriptionPlanPriceId === currentSubscriptionPlanId) {
        setErrorMessage('This is your current subscription plan.');
        return;
      }
      onSubscriptionInfoChange(data => ({
        ...data,
        newSubscriptionPlanInterval: values?.subscriptionMonthYear,
      }));

      onCurrentModalStatusChange(
        getNextStep(
          currentModalStatus,
          newSubscription,
          isItNewSubscription,
          stripeProducts,
          currentSubscriptionPlanProductId
        )
      );
    },
    [
      stripeProducts,
      currentModalStatus,
      newSubscription,
      isItNewSubscription,
      onSubscriptionInfoChange,
      onCurrentModalStatusChange,
      currentSubscriptionPlanProductId,
      currentSubscriptionPlanId,
      setErrorMessage,
    ]
  );

  const handleClose = useCallback(() => {
    onCurrentModalStatusChange(ManageSubscriptionStatus.Closed);
    onClose();
  }, [onCurrentModalStatusChange, onClose]);

  return (
    <Form
      id={FORM}
      validate={validateForm}
      onSubmit={handleSubmitForm}
      initialValues={initialValues}
      initialValuesEqual={isEqual}
      render={({ handleSubmit, invalid, form: { restart } }) => (
        <DazzlingDialog
          acceptLabel={acceptLabel}
          disabled={invalid}
          formName={FORM}
          handleClose={handleClose}
          headerProps={{
            icon: CheckIcon,
            title: 'Finalize your plan',
            alignItems: 'center',
          }}
          onAccept={handleSubmit}
          open={open}
          children={dialogContent}
          resetForm={restart}
          isError={!!errorMessage}
          alertProps={{
            message: errorMessage || '',
          }}
          isBackgroundWhite
          isButtonOnTheRightCorner
        />
      )}
    />
  );
};

const useStyles = makeStyles(theme => ({
  container: {
    marginBottom: 10,
  },
  title: {
    fontSize: '1.3rem',
    fontWeight: 600,
    lineHeight: '1.5rem',
  },
  subTitle: {
    fontSize: '1rem',
    fontWeight: 500,
    lineHeight: '1.2rem',
    color: theme.palette.background.paper,
    marginTop: 15,
    marginBottom: 15,
  },
}));

export default SelectYourPlanYearMonthModal;
