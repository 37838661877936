import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import validate from 'validate.js';
import { Map } from 'immutable';

// hooks
import { useDispatch, useSelector } from 'react-redux';

// material-ui
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

// components
import { Form, Field } from 'react-final-form';
import FileInputField from 'components/FileInputField';
import TextField from 'components/TextField';
import ChapterSelectionField from 'components/ChapterSelectionField';

// action creators
import { createFileAndInvite } from 'store/actions/fileActions';

// selectors
import getIsHqAdmin from 'store/selectors/getIsHqAdmin';
import getIsCouncilAdmin from 'store/selectors/getIsCouncilAdmin';
import getIsSystemAdmin from 'store/selectors/getIsSystemAdmin';
import getIsCurrentGroupOrganization from 'store/selectors/getIsCurrentGroupOrganization';
import getIsCurrentGroupCouncil from 'store/selectors/getIsCurrentGroupCouncil';

// schemas
const schema = {
  role: {
    presence: true,
  },
};

const FORM = 'bulkInviteForm';

const CHAPTER_SUB_TEXT = `Select group to add admins to, else they will be added as HQ admins.`;

const BulkInviteForm = function({ currentGroup, roleOptions }: any) {
  const dispatch = useDispatch();
  const classes = useStyles();

  const {
    isCouncilAdmin,
    isCurrentGroupCouncil,
    isCurrentGroupOrganization,
    isHqAdmin,
    isSystemAdmin,
  } = useSelector(state => ({
    isCouncilAdmin: getIsCouncilAdmin(state),
    isCurrentGroupCouncil: getIsCurrentGroupCouncil(state),
    isCurrentGroupOrganization: getIsCurrentGroupOrganization(state),
    isHqAdmin: getIsHqAdmin(state),
    isSystemAdmin: getIsSystemAdmin(state),
  }));

  const dispatchCreateFileAndInvite = (file: any, values: any) => {
    dispatch(
      createFileAndInvite({
        file,
        formName: FORM,
        roleId: values.role,
        groupId: currentGroup.get('id'),
        childGroupId: values.childIds,
      })
    );
  };

  const getInitialValues = useCallback(() => {
    if (
      (isCurrentGroupOrganization && (isHqAdmin || isSystemAdmin)) ||
      (isCurrentGroupCouncil && (isCouncilAdmin || isSystemAdmin))
    ) {
      return {
        role: 1,
      };
    }
    return {};
  }, [
    isCouncilAdmin,
    isCurrentGroupCouncil,
    isCurrentGroupOrganization,
    isHqAdmin,
    isSystemAdmin,
  ]);

  return (
    <div>
      <Form
        onSubmit={() => {}}
        validate={values => validate(values, schema)}
        initialValues={getInitialValues()}
        render={({ values, handleSubmit, invalid }) => (
          <Grid
            container
            component='form'
            onSubmit={handleSubmit}
            spacing={4}
            alignItems='flex-start'
            justifyContent='flex-start'
          >
            <Grid item xs={12} style={{ marginBottom: -20 }}>
              <Typography color='secondary' variant='body2'>
                Create a spreadsheet with only an email column. Your first row
                will be omitted.
              </Typography>
              <Typography color='textSecondary' variant='subtitle2'>
                Accepted file formats: .csv
              </Typography>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Field
                id='roleSelect'
                name='role'
                label='Role'
                disabled={
                  (isCurrentGroupOrganization &&
                    (isHqAdmin || isSystemAdmin)) ||
                  (isCurrentGroupCouncil && (isCouncilAdmin || isSystemAdmin))
                }
                render={fieldProps => <TextField {...fieldProps} />}
                options={roleOptions}
                helperText='This role will be assigned to all invited members'
                select
              />
            </Grid>

            {(isSystemAdmin || isHqAdmin) && isCurrentGroupOrganization && (
              <Grid item xs={12} sm={3}>
                <ChapterSelectionField
                  groupId={currentGroup.get('id')}
                  multiple={false}
                  subtext={CHAPTER_SUB_TEXT}
                  label='HQ Group'
                  suggestions='suggestionsByName'
                />
              </Grid>
            )}

            <Grid item xs className={classes.importButton}>
              <Field
                name='file'
                render={props => (
                  <FileInputField
                    // @ts-expect-error ts-migrate(2322) FIXME: Type '{ input: FieldInputProps<any, HTMLElement>; ... Remove this comment to see the full error message
                    fileType='file'
                    disabled={invalid}
                    formName={FORM}
                    buttonLabel='Import Members File'
                    reSelectLabel='Reselect Members File'
                    onChange={() => {}}
                    onSubmit={(file: any) =>
                      dispatchCreateFileAndInvite(file, values)
                    }
                    {...props}
                  />
                )}
              />
            </Grid>
          </Grid>
        )}
      />
    </div>
  );
};

const useStyles = makeStyles({
  importButton: {
    marginTop: 25,
  },
});

BulkInviteForm.propTypes = {
  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'typeof Map' is not assignable to... Remove this comment to see the full error message
  currentGroup: PropTypes.instanceOf(Map).isRequired,
  roleOptions: PropTypes.instanceOf(Array).isRequired,
};

export default BulkInviteForm;
