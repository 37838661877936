import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

// MUI components
import { Grid } from '@material-ui/core';

// components
import RecruitmentActivityOverviewCard from 'routes/Analytics/RecruitmentActivityOverviewCard';
import RecruitmentActivityTagsCard from 'routes/Analytics/RecruitmentActivityTagsCard';
import RecruitmentActivityMilestonesCard from 'routes/Analytics/RecruitmentActivityMilestonesCard';
import NewMemberList from 'routes/NewMemberAnalytics/NewMemberList';
import RecruitmentStory from 'routes/NewMemberAnalytics/RecruitmentStory';
import NewMemberCount from 'routes/NewMemberAnalytics/NewMemberCount';
import CouncilNewMemberList from 'routes/NewMemberAnalytics/CouncilNewMemberList';

// action creators
import { clearReports } from 'store/actions/reportActions';

const TOP_STATUS = [1];

interface NewMemberAnalyticsProps {
  isCouncilMemberAnalytics?: boolean;
  isOrganizationMemberAnalytics?: boolean;
}

const NewMemberAnalytics = ({
  isCouncilMemberAnalytics,
  isOrganizationMemberAnalytics,
}: NewMemberAnalyticsProps) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearReports());
  }, [dispatch]);

  const getBoxes = () => {
    if (isCouncilMemberAnalytics) {
      return (
        <>
          <Grid item xs={12}>
            <RecruitmentStory />
          </Grid>
          <Grid item xs={12}>
            <NewMemberList />
          </Grid>
          <Grid item xs={12}>
            <CouncilNewMemberList isFetchingGroupsTurnedOff />
          </Grid>
        </>
      );
    }

    if (isOrganizationMemberAnalytics) {
      return (
        <>
          <Grid item xs={12}>
            <RecruitmentStory isOrganizationMemberAnalytics />
          </Grid>
          <Grid item xs={12} sm={6}>
            <RecruitmentActivityTagsCard
              reportFormName='newMemberTagsReportForm'
              exportFormName='newMemberTagsExportForm'
              isOrganizationMemberAnalytics
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <RecruitmentActivityMilestonesCard
              reportFormName='newMemberMilestonesReportForm'
              exportFormName='newMemberMilestonesExportForm'
              isOrganizationMemberAnalytics
            />
          </Grid>
          <Grid item xs={12}>
            <NewMemberList isOrganizationMemberAnalytics />
          </Grid>
          <Grid item xs={12}>
            <NewMemberCount />
          </Grid>
        </>
      );
    }

    return (
      <>
        <Grid item xs={12}>
          <RecruitmentActivityOverviewCard
            statusPositions={TOP_STATUS}
            reportFormName='newMemberActivityReportForm'
            exportFormName='newMemberActivityExportForm'
            statLabel='Joined'
            HeaderProps={{
              title: 'New Member Activity Overview',
              subtitle: `Analyze the leads that have achieved your group's top status and see what it took for them to join`,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <RecruitmentActivityTagsCard
            reportFormName='newMemberTagsReportForm'
            exportFormName='newMemberTagsExportForm'
            statusPositions={TOP_STATUS}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <RecruitmentActivityMilestonesCard
            reportFormName='newMemberMilestonesReportForm'
            exportFormName='newMemberMilestonesExportForm'
            statusPositions={TOP_STATUS}
          />
        </Grid>
      </>
    );
  };

  return (
    <Grid container spacing={2} alignContent='flex-start'>
      {getBoxes()}
    </Grid>
  );
};

export default NewMemberAnalytics;
