import Immutable, { List, Map } from 'immutable';
import { denormalize } from 'normalizr';

import { requestSchema } from '../schemas/requestSchema';

export default function getRequests(state: any) {
  const request = state.get('request', Map());
  const result = request.getIn(['data', 'result'], List());

  const entities = {
    request: state.getIn(['request', 'data', 'byId'], Map()).toJS(),
    group: state.getIn(['group', 'data', 'byId'], Map()).toJS(),
  };

  let items = denormalize(result.toJS(), [requestSchema], entities);

  return Immutable.fromJS(items);
}
