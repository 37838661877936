import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Map } from 'immutable';
// material-ui
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
// icons
import LogOutIcon from '@material-ui/icons/MeetingRoom';
// components
import MobileMenuItem from './MobileMenuItem';
// action creators
import { destroySession } from 'store/actions/sessionActions';
// selectors
import getCurrentGroupId from 'store/selectors/getCurrentGroupId';
import getCurrentUserGroups from 'store/selectors/getCurrentUserGroups';
import getFeatures from 'store/selectors/getFeatures';
import getIsSystemAdmin from 'store/selectors/getIsSystemAdmin';
// helpers
import getPages from 'layout/ApplicationLayout/helpers/getPages';
const MobileMenu = function() {
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    location: { search },
  } = history;
  const handleNavigate = (path: any) => {
    return () => {
      history.push({ pathname: path, search });
    };
  };
  const {
    currentGroupId,
    groupIds,
    features,
    isSystemAdmin,
    permissions,
    roleId,
  } = useSelector(state => ({
    currentGroupId: getCurrentGroupId(state),
    groupIds: getCurrentUserGroups(state),
    features: getFeatures(state),
    isSystemAdmin: getIsSystemAdmin(state),
    permissions: (state as any).getIn(
      ['currentUser', 'data', 'permissions'],
      Map()
    ),
    roleId: (state as any).getIn(['currentUser', 'data', 'roleId']),
  }));
  const pages = getPages({
    isSystemAdmin,
    currentGroupId,
    groupIds,
    features,
    permissions,
    roleId,
  });
  const mobileMenuPages = pages.filter((page: any) => page.get('mobileMenu'));
  return (
    <div className={classes.container}>
      <List className={classes.title}>
        <Typography variant='h6'>Menu</Typography>
      </List>

      <Divider />

      <List>
        {mobileMenuPages.map((page: any) => {
          const Icon = page.get('icon');
          return (
            // @ts-expect-error ts-migrate(2741) FIXME: Property 'selected' is missing in type '{ label: a... Remove this comment to see the full error message
            <MobileMenuItem
              label={page.get('label')}
              icon={<Icon color='action' fontSize='default' />}
              onClick={handleNavigate(page.get('path'))}
            />
          );
        })}
      </List>

      <Divider />

      <List>
        {/* @ts-expect-error ts-migrate(2741) FIXME: Property 'selected' is missing in type '{ label: s... Remove this comment to see the full error message */}
        <MobileMenuItem
          label='Log Out'
          icon={<LogOutIcon />}
          onClick={() => dispatch(destroySession())}
        />
      </List>
    </div>
  );
};
MobileMenu.propTypes = {
  history: PropTypes.instanceOf(Object).isRequired,
  destroySession: PropTypes.func.isRequired,
  isSystemAdmin: PropTypes.bool.isRequired,
  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'typeof Map' is not assignable to... Remove this comment to see the full error message
  currentGroup: PropTypes.instanceOf(Map),
};
const useStyles = makeStyles(theme => ({
  container: {
    backgroundColor: (theme.palette.background as any).darkPaper,
  },
  title: {
    paddingLeft: 16,
  },
}));
export default MobileMenu;
