import {
  PHONE_NUMBER_CREATE_REQUEST,
  PHONE_NUMBER_DELETE_REQUEST,
  PHONE_NUMBER_FETCH_FOR_GROUP_REQUEST,
  PHONE_NUMBER_GET_AVAILABLE_TWILIO_REQUEST,
  PHONE_NUMBER_TOGGLE_CALL_FORWARDING_REQUEST,
  PHONE_NUMBER_UPDATE_REQUEST,
} from 'store/constants/phoneNumberTypes';

export function createPhoneNumber({ number, accountId, formName }: any) {
  return {
    type: PHONE_NUMBER_CREATE_REQUEST,
    payload: { number, accountId, formName },
  };
}

export function updatePhoneNumber({ phoneNumberId, accountId }: any) {
  return {
    type: PHONE_NUMBER_UPDATE_REQUEST,
    payload: { phoneNumberId, accountId },
  };
}

export function deletePhoneNumber({ phoneNumberId }: any) {
  return {
    type: PHONE_NUMBER_DELETE_REQUEST,
    payload: { phoneNumberId },
  };
}

export function toggleCallForwarding({ phoneNumberId }: any) {
  return {
    type: PHONE_NUMBER_TOGGLE_CALL_FORWARDING_REQUEST,
    payload: { phoneNumberId },
  };
}

export function fetchPhoneNumbersForGroup() {
  return {
    type: PHONE_NUMBER_FETCH_FOR_GROUP_REQUEST,
  };
}

export function getAvailableFromTwilio({ areaCode }: any) {
  return {
    type: PHONE_NUMBER_GET_AVAILABLE_TWILIO_REQUEST,
    payload: { areaCode },
  };
}
