import React from 'react';
import PropTypes from 'prop-types';

// MUI components
import { makeStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

const MobileMenuItem = function({ label, icon, onClick, selected }: any) {
  const classes = useStyles();

  return (
    <ListItem button onClick={onClick}>
      <ListItemIcon className={selected ? classes.selected : ''}>
        {icon}
      </ListItemIcon>
      <ListItemText
        className={selected ? classes.selected : ''}
        primary={label}
      />
    </ListItem>
  );
};

MobileMenuItem.propTypes = {
  onClick: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  selected: PropTypes.bool.isRequired,
  icon: PropTypes.node.isRequired,
};

const useStyles = makeStyles(theme => ({
  selected: {
    color: theme.palette.primary.main,
  },
}));

export default MobileMenuItem;
