import React from 'react';

// material-ui
import Grid from '@material-ui/core/Grid';
import { WithStyles, withStyles } from '@material-ui/core/styles';

// icons
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';

//styles
import warningStyles from 'components/Warning/warning.style';

export interface WarningProps extends WithStyles<typeof warningStyles> {
  message: string | React.ReactNode;
  icon?: React.ReactNode;
  border?: boolean;
  color?: 'red' | 'white';
}

const Warning = ({
  classes,
  icon,
  message,
  border,
  color = 'white',
}: WarningProps) => {
  return (
    <Grid
      className={`${classes.container} color-${color} ${
        border ? 'border' : ''
      }`}
      container
      spacing={2}
      alignItems='center'
    >
      <Grid className={classes.icon} item xs>
        {icon || <ReportProblemOutlinedIcon />}
      </Grid>
      <Grid item xs>
        {message}
      </Grid>
    </Grid>
  );
};

export default withStyles(warningStyles)(Warning);
