import { Styles } from 'assets/types';
import formViewStyles from 'routes/ReferralForm/FormView/formView.style';

const agreeToTermsFieldStyles: Styles = theme => {
  const { formContent } = formViewStyles(theme);
  return {
    formContent,
    subtitleNodeInModal: {
      fontWeight: 500,
      fontSize: '14px',
      lineHeight: '22px',
      color: 'rgba(255, 255, 255, 0.5);',
      paddingBottom: 10,
    },
    agreementTextInModal: {
      fontWeight: 500,
      fontSize: '16px',
      lineHeight: '24px',
      color: '#FFFFFF',
      '& a': {
        color: '#F28D26',
        textDecoration: 'underline',
        '&:hover': {
          textDecoration: 'none',
        },
      },
    },
    buttonViewAgreement: {
      fontSize: 'inherit',
      fontWeight: 700,
      fontStyle: 'inherit',
      color: '#F28D26',
      lineHeight: 'inherit',
      display: 'inline',
      background: 'none !important',
      width: 'auto',
      height: 'auto',
      minWidth: 0,
      minHeight: 0,
      padding: 0,
      margin: '0px 0px 0px 15px',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  };
};

export default agreeToTermsFieldStyles;
