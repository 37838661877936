import { all } from 'redux-saga/effects';

// sagas
import fetchTagsSaga from './fetchTagsSaga';
import createTagSaga from './createTagSaga';
import updateTagSaga from './updateTagSaga';
import deleteTagSaga from './deleteTagSaga';

const tagSaga = function* rootSaga() {
  yield all([
    fetchTagsSaga(),
    createTagSaga(),
    updateTagSaga(),
    deleteTagSaga(),
  ]);
};

export default tagSaga;
