import React, { useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

// MUI components
import { makeStyles } from '@material-ui/core/styles';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';

// components
import NewButton from 'components/NewButton';

// icons
import MenuIcon from '@material-ui/icons/Menu';
import HomeIcon from '@material-ui/icons/Home';
import FormsIcon from '@material-ui/icons/Ballot';
import ListIcon from '@material-ui/icons/RecentActors';
import MessagesIcon from '@material-ui/icons/QuestionAnswer';
import AccountIcon from '@material-ui/icons/SettingsApplications';

// selectors
import getCurrentGroupId from 'store/selectors/getCurrentGroupId';
import getCurrentUserGroups from 'store/selectors/getCurrentUserGroups';
import getIsSystemAdmin from 'store/selectors/getIsSystemAdmin';
import getPermission from 'store/selectors/getPermission';
import getFeatures from 'store/selectors/getFeatures';

const MobileNavigation = function() {
  const history = useHistory();
  const classes = useStyles();
  const {
    location: { pathname, search },
  } = history;

  const getInitialPage = useCallback(() => {
    if (pathname.includes('home') || pathname.includes('groups')) {
      return 'home';
    } else if (pathname.includes('settings/account')) {
      return 'account';
    } else if (pathname.includes('leads')) {
      return 'leads';
    } else if (pathname.includes('messages')) {
      return 'messages';
    }
  }, [pathname]);

  const [page, setPage] = useState(getInitialPage());
  const {
    currentGroupId,
    groupIds,
    isSystemAdmin,
    features,
    canCreateMessages,
  } = useSelector(state => ({
    currentGroupId: getCurrentGroupId(state),
    groupIds: getCurrentUserGroups(state),
    isSystemAdmin: getIsSystemAdmin(state),
    features: getFeatures(state),
    canCreateMessages: getPermission('message.createMany')(state),
  }));

  const navigate = (page: any) => {
    history.push({ pathname: page, search });
  };

  return (
    <BottomNavigation
      value={page}
      onChange={(event, newPage) => {
        setPage(newPage);
      }}
      className={classes.bottomNavigation}
    >
      <BottomNavigationAction
        id='homeItem'
        value='home'
        label={'Home'}
        showLabel
        icon={<HomeIcon />}
        onClick={() =>
          navigate(
            (groupIds.size || isSystemAdmin) && !currentGroupId
              ? '/groups/chapters'
              : '/home'
          )
        }
      />

      {(groupIds.size || isSystemAdmin) && !currentGroupId ? (
        <BottomNavigationAction
          id='accountItem'
          value='account'
          label='Account'
          showLabel
          icon={<AccountIcon />}
          onClick={() => navigate('/settings/account')}
        />
      ) : (
        <BottomNavigationAction
          id='leadsItem'
          value='leads'
          label='Lead List'
          showLabel
          icon={<ListIcon />}
          onClick={() => navigate('/leads')}
        />
      )}

      <NewButton variant='fab' />

      {features.getIn(['texts', 'enabled'], false) && canCreateMessages ? (
        <BottomNavigationAction
          id='messagesItem'
          value='messages'
          label='Messages'
          showLabel
          icon={<MessagesIcon />}
          onClick={() => navigate('/messages')}
        />
      ) : (
        <BottomNavigationAction
          id='formsItem'
          value='forms'
          label='Forms'
          showLabel
          icon={<FormsIcon />}
          onClick={() => navigate('/forms')}
        />
      )}

      <BottomNavigationAction
        id='menuItem'
        value='menu'
        label='Menu'
        showLabel
        icon={<MenuIcon />}
        onClick={() => navigate('/mobile-menu')}
      />
    </BottomNavigation>
  );
};

const useStyles = makeStyles(theme => ({
  bottomNavigation: {
    position: 'sticky',
    backgroundColor: (theme as any).palette.background.darkPaper,
    bottom: 0,
    zIndex: 1,
    height: 65,
    width: '100vw',
    borderTop: `1px solid ${(theme as any).palette.background.lightPaper}`,
    boxShadow: '-1px -2px 15px -3px rgba(0,0,0,0.25)',
  },

  items: {
    cursor: 'pointer',
    marginTop: 24,
  },

  actionButtonItem: {
    marginLeft: 6,
    marginRight: 6,
  },
}));

export default MobileNavigation;
