type Column = {
  label: string;
  key: string;
  disableSort?: boolean;
};

const getTableColumns = (isSystemAdmin: boolean, orderingDisabled: boolean) => {
  const columns: Column[] = [
    // {
    //   label: 'Id',
    //   key: 'id',
    // },
    {
      label: 'Designation',
      key: 'name',
    },
    {
      label: 'Organization',
      key: 'organizationName',
    },
    ...(isSystemAdmin
      ? [
          {
            label: 'Campus',
            key: 'campus',
          },
        ]
      : []),
    {
      label: 'Council',
      key: 'council',
      disableSort: true,
    },
    {
      label: 'Latest Activity',
      key: 'latestActivityOn',
    },
    {
      label: 'Leads',
      key: 'leadsCount',
    },
    {
      label: 'New members',
      key: 'joinersCount',
    },
    {
      label: 'Members',
      key: 'membersCount',
    },
    {
      label: 'Chapter Labels',
      key: 'labels',
      disableSort: true,
    },
    // {
    //   label: 'Primary admin',
    //   key: 'undefined',
    // },
    // {
    //   label: 'Linking icon/button',
    //   key: 'undefined',
    // },
    // {
    //   label: 'Subscription',
    //   key: 'undefined',
    // },
  ];

  if (isSystemAdmin) {
    columns.unshift({ label: 'ID', key: 'id' });
  }
  if (orderingDisabled)
    columns.forEach(column => {
      column.disableSort = true;
    });

  return columns;
};

export default getTableColumns;
