export default function getNextPageFromState(slice: string, metaId?: string) {
  const key = metaId ? `${metaId}-next` : 'next';

  return (state: any) => {
    const url = (state.getIn([slice, 'meta', key]) || '').replace('?', '&');
    const pageParam = new URLSearchParams(url).get('page');

    return pageParam ? parseInt(pageParam) : null;
  };
}
