import { Styles } from 'assets/types';
import analyticsCardStyles from 'assets/stylesheets/defaultStyles/analyticsCard.style';

const chapterUserDataStyles: Styles = theme => {
  const newAnalyticsCardStyle = analyticsCardStyles(theme) || {};
  return {
    ...newAnalyticsCardStyle,
  };
};

export default chapterUserDataStyles;
