import React, { useCallback, useContext, useState } from 'react';
import validate from 'validate.js';
import { isEqual } from 'lodash';

// hooks
import { useSelector, useDispatch } from 'react-redux';

// material-ui
import { Grid, Typography } from '@material-ui/core';

// icons
import EventIcon from '@material-ui/icons/DateRange';

//components
import DazzlingDialog from 'components/DazzlingDialog';
import TextField from 'components/TextField';
import { Form, Field } from 'react-final-form';
import DateRange from 'components/DateRange';
import Switch from 'components/Switch';
import MemberSelector from 'components/MemberSelector';
import OutlinedCard from 'components/OutlinedCard';
import StatusSelector from 'components/StatusSelector';
import TagSelector from './TagSelector';

// action creators
import { createEvent } from 'store/actions/eventActions';

// selectors
import getCurrentGroupId from 'store/selectors/getCurrentGroupId';

// helpers
import { SiteVisualDataContext } from 'components/SiteVisualData';

const FORM = 'createEvent';

export default function CreateEventModal({
  open,
  onClose,
}: {
  onClose: (...args: any[]) => any;
  open: boolean;
}) {
  const { createEventModalLabel } = useContext(SiteVisualDataContext);
  const groupId = useSelector(getCurrentGroupId);

  const [timeValidationError, setTimeValidationError] = useState<string | null>(
    null
  );

  const dispatch = useDispatch();
  const dispatchCreateEvent = useCallback(
    values => {
      dispatch(createEvent({ groupId, formName: FORM, ...values }));
    },
    [dispatch, groupId]
  );

  const handleDateChange = useCallback(({ startDate, endDate, input }) => {
    input.onChange({ startDate, endDate });
  }, []);

  const validateForm = useCallback((values = {}) => {
    const { eventDate: { startDate = false, endDate = false } = {} } = values;

    const newValidationErrors = validate(values, schema) || {};

    // The DatePicker component's minDateTime and maxDateTime props are broken!
    //  This manually implements validations for the time portion of a date
    if (startDate && endDate && startDate.diff(endDate) > 0) {
      newValidationErrors['eventDate.startDate'] = [
        'Start time cannot be after end time',
      ];
      newValidationErrors['eventDate.endDate'] = [
        'End time cannot be before start time',
      ];

      setTimeValidationError(newValidationErrors['eventDate.endDate']);
    }

    return newValidationErrors;
  }, []);

  return (
    <Form
      id='createEvent'
      validate={validateForm}
      onSubmit={dispatchCreateEvent}
      initialValues={{ attendingTagIds: [], attendedTagIds: [] }}
      keepDirtyOnReinitialize
      render={({ handleSubmit, invalid, values, form: { restart } }) => (
        <DazzlingDialog
          acceptLabel='Create'
          alertProps={{ message: 'Event Created Successfully' }}
          disabled={invalid}
          formName={FORM}
          handleClose={onClose}
          headerProps={dialogData}
          height='auto'
          id='createEvent'
          onAccept={handleSubmit}
          open={open}
          resetForm={restart}
        >
          <Grid container spacing={1} direction='row'>
            <Grid item xs={12} sm={8}>
              <Field
                name='title'
                label='Title'
                variant='outlined'
                component={TextField}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                name='eventDate'
                // @ts-expect-error ts-migrate(2322) FIXME: Type 'typeof DateRange' is not assignable to type ... Remove this comment to see the full error message
                component={DateRange}
                pickerType='datetime'
                formName={FORM}
                disableFuture={false}
                endLabel='End Date & Time'
                startLabel='Start Date & Time'
                onDateChange={handleDateChange}
                justifyContent='flex-start'
                variant='input'
                required
              />

              {timeValidationError && (
                <div style={{ marginTop: 8 }}>
                  <Typography variant='caption' color='error'>
                    {timeValidationError}
                  </Typography>
                </div>
              )}
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                name='location'
                label='Event Location'
                variant='outlined'
                component={TextField}
                multiline
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                allowNull
                isEqual={isEqual}
                name='pointOfContact'
                label='Point of Contact'
                component={MemberSelector}
                margin='normal'
                required
              />
            </Grid>
            <Grid item xs={12} sm={8}>
              <Field
                name='description'
                label='Description'
                variant='outlined'
                component={TextField}
                multiline
              />
            </Grid>

            <Grid item xs={12}>
              <OutlinedCard>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant='body2'>Auto Statuses</Typography>
                    <Typography color='textSecondary' variant='caption'>
                      Advance your leads' statuses when they RSVP or attend this
                      event
                    </Typography>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Field
                      id='attendingStatusId'
                      name='attendingStatusId'
                      component={StatusSelector}
                      label="RSVP'd as attending"
                      aria-label="RSVP'd as attending"
                      margin='none'
                      clearable
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Field
                      id='attendedStatusId'
                      name='attendedStatusId'
                      component={StatusSelector}
                      label='Attended Event'
                      aria-label='Attended Event'
                      margin='none'
                      clearable
                    />
                  </Grid>
                </Grid>
              </OutlinedCard>
            </Grid>

            <Grid item xs={12}>
              <OutlinedCard>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant='body2'>
                      Auto Milestones & Tags
                    </Typography>
                    <Typography color='textSecondary' variant='caption'>
                      Add milestones and tags to your leads when they RSVP or
                      attend this event
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Field
                      id='attendingTagIds'
                      name='attendingTagIds'
                      label="RSVP'd as attending"
                      aria-label="RSVP'd as attending"
                      margin='none'
                      render={({ input, ...rest }) => (
                        <TagSelector {...input} {...rest} />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Field
                      id='attendedTagIds'
                      name='attendedTagIds'
                      label='Attended Event'
                      aria-label='Attended Event'
                      margin='none'
                      render={({ input, ...rest }) => (
                        <TagSelector {...input} {...rest} />
                      )}
                    />
                  </Grid>
                </Grid>
              </OutlinedCard>
            </Grid>

            <Grid item xs={12}>
              <OutlinedCard>
                <Grid container spacing={0}>
                  <Grid item>
                    <Typography variant='body2'>Additional Options</Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <Field
                      name='inviteAllMembers'
                      label={createEventModalLabel}
                      type='checkbox'
                      component={Switch}
                    />
                  </Grid>
                </Grid>
              </OutlinedCard>
            </Grid>
          </Grid>
        </DazzlingDialog>
      )}
    />
  );
}

const dialogData = {
  icon: EventIcon,
  title: 'New Event',
  subtitle:
    'Provide the title, time, and location of your event to get started',
};

// schemas
const schema = {
  title: {
    presence: true,
  },
  'eventDate.startDate': {
    presence: true,
  },
  'eventDate.endDate': {
    presence: true,
  },
  location: {
    presence: true,
  },
  pointOfContact: {
    presence: true,
  },
  description: {
    length: {
      maximum: 2048,
    },
  },
};
