import React from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { useDispatch, useSelector } from 'react-redux';

// components
import AutoSuggest from 'components/AutoSuggest';

// selectors
import getSliceState from 'store/selectors/getSliceState';

// actions
import { getAvailableFromTwilio as getAvailableFromTwilioAction } from 'store/actions/phoneNumberActions';

const PhoneAutoSuggest = function({ values, ...rest }: any) {
  const dispatch = useDispatch();

  const { twilioNumberState = { data: [] } } = useSelector(state => ({
    twilioNumberState: getSliceState('twilioNumber', null, false)(state),
  }));

  const getAvailableFromTwilio = (value: any) => {
    if (value) {
      dispatch(getAvailableFromTwilioAction({ areaCode: value }));
    }
  };

  // @ts-expect-error ts-migrate(2349) FIXME: This expression is not callable.
  const suggestions = twilioNumberState.data.map((number: any) => ({
    label: number.friendlyName,
    value: number.phoneNumber,
  }));

  return (
    <AutoSuggest
      id='phoneAutoSuggest'
      label='Search Phone Numbers'
      placeholder='Enter Area Code'
      formValues={Immutable.fromJS(values)}
      onSearchChange={getAvailableFromTwilio}
      suggestions={suggestions}
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'loading' does not exist on type '{ loadi... Remove this comment to see the full error message
      loading={twilioNumberState.loading}
      htmlInputProps={{ maxlength: 3 }}
      {...rest}
      required
    />
  );
};

PhoneAutoSuggest.propTypes = {
  values: PropTypes.instanceOf(Object).isRequired,
};

export default PhoneAutoSuggest;
