import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import PersonIcon from '@material-ui/icons/Person';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import PhoneIcon from '@material-ui/icons/Phone';
import SmsIcon from '@material-ui/icons/Sms';
import EmailIcon from '@material-ui/icons/Email';
import PeopleOutline from '@material-ui/icons/PeopleOutline';
import DateRange from '@material-ui/icons/DateRange';

export interface TableColumns {
  key: string;
  label: string | React.ReactNode;
  isTooltipAllowed?: boolean;
  disableSort?: boolean;
}

const getTableColumns = (): TableColumns[] => [
  {
    key: 'leadFullName',
    label: (
      <Tooltip title='Chapter Name'>
        <PersonIcon />
      </Tooltip>
    ),
    isTooltipAllowed: true,
  },
  {
    key: 'notesCreated',
    label: (
      <Tooltip title='Notes Created'>
        <NoteAddIcon />
      </Tooltip>
    ),
    isTooltipAllowed: true,
  },
  {
    key: 'callsReceived',
    label: (
      <Tooltip title='Calls Received'>
        <PhoneIcon />
      </Tooltip>
    ),
    isTooltipAllowed: true,
  },
  {
    key: 'textsReceived',
    label: (
      <Tooltip title='Texts Received'>
        <SmsIcon />
      </Tooltip>
    ),
    isTooltipAllowed: true,
  },
  {
    key: 'emailsReceived',
    label: (
      <Tooltip title='Emails Received'>
        <EmailIcon />
      </Tooltip>
    ),
    isTooltipAllowed: true,
  },
  {
    key: 'eventsAttended',
    label: (
      <Tooltip title='Events Attended - determined by "Attended Meeting or Activity" milestone'>
        <DateRange />
      </Tooltip>
    ),
    isTooltipAllowed: true,
  },
  {
    key: 'membersInvolved',
    label: (
      <Tooltip title='Members Involved'>
        <PeopleOutline />
      </Tooltip>
    ),
    isTooltipAllowed: true,
  },
];

export default getTableColumns;
