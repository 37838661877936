import { Map } from 'immutable';
// constants
import {
  LABEL_FETCH,
  LABEL_CREATE,
  LABEL_UPDATE,
  LABEL_DELETE,
  LABEL_CLEAR,
} from 'store/constants/labelTypes';
import update from 'store/reducers/callbacks/update';
import remove from 'store/reducers/callbacks/remove';
// helpers
import httpReducer from 'store/reducers/helpers/httpReducer';

const reduceLabel = (
  state: any = Map(),
  action: { type: string; payload: Map<any, any> } = {
    payload: Map(),
    type: '',
  }
) => {
  const { type } = action || {};
  switch (type) {
    case LABEL_FETCH:
      return httpReducer(state, action, { appendResult: true });
    case LABEL_CREATE:
      return httpReducer(state, action, { appendResult: true });
    case LABEL_UPDATE:
      return httpReducer(state, action, { callback: update });
    case LABEL_DELETE:
      return httpReducer(state, action, { callback: remove });
    case LABEL_CLEAR:
      return Map();
    default:
      return state;
  }
};

export default reduceLabel;
