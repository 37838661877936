import { all } from 'redux-saga/effects';

// sagas
import createCommentSaga from './createCommentSaga';
import deleteCommentSaga from './deleteCommentSaga';
import fetchCommentsSaga from './fetchCommentsSaga';
import updateCommentSaga from './updateCommentSaga';

const commentSaga = function* rootSaga() {
  yield all([
    createCommentSaga(),
    deleteCommentSaga(),
    fetchCommentsSaga(),
    updateCommentSaga(),
  ]);
};

export default commentSaga;
