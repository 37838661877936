import { all, call, fork, takeEvery, put } from 'redux-saga/effects';
import Immutable from 'immutable';

// action creators
import { doneIndicator, success } from 'store/actions/httpActions';

// api
import client from 'sources/api';

// constants
import {
  FORM_FIELD_DELETE_REQUEST,
  FORM_FIELD_DELETE,
} from 'store/constants/formFieldTypes';

// helpers
import httpSaga from 'store/sagas/httpSaga';

// schemas
import { formFieldSchema } from 'store/schemas/formFieldSchema';

const WATCH_TYPE = FORM_FIELD_DELETE_REQUEST;
const TYPE = FORM_FIELD_DELETE;

export function* deleteFormField(action: any) {
  const { label, groupId, formId } = action.payload;

  const META_ID = `delete-${label}`;

  const endpoint = `/form_fields?group_id=${groupId}&form_id=${formId}`;

  // @ts-expect-error ts-migrate(2569) FIXME: Type 'Generator<any, any, unknown>' is not an arra... Remove this comment to see the full error message
  yield* httpSaga(
    TYPE,
    call(client.delete, endpoint, {
      // delete needs a data object in order to use a request body
      data: {
        label,
      },
    }),
    {
      dispatchSuccess: false,
      schema: formFieldSchema,
      metaId: META_ID,
    }
  );

  // Send the response data to the store for this type to be caught by a reducer
  yield put(
    success(
      TYPE,
      Immutable.fromJS({
        label,
        id: label,
      }),
      META_ID
    )
  );

  yield put(doneIndicator(TYPE));
}

export function* watch() {
  yield takeEvery(WATCH_TYPE, deleteFormField);
}

export default function* root() {
  yield all([fork(watch)]);
}
