import React, { useCallback, useState } from 'react';
import { isEqual } from 'lodash';
import { useSelector } from 'react-redux';

// MUI components
import {
  Grid,
  Card,
  CardContent,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Chip,
  Checkbox,
  ListItemText,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { SwapVert } from '@material-ui/icons';
import Header from 'components/Header';

// selectors
import getCurrentGroupId from 'store/selectors/getCurrentGroupId';
import getSliceState from 'store/selectors/getSliceState';

type Props = {
  handleStatusPositionsChange: (...args: any[]) => any;
};

export default function StatusesSelectorCard({
  handleStatusPositionsChange,
}: Props) {
  const classes = useStyles();
  const [statusIds, setStatusIds] = useState([]);
  const { currentGroupId, status: { data: statuses = [] } = {} } = useSelector(
    state => ({
      currentGroupId: getCurrentGroupId(state),
      status: getSliceState('status')(state),
    }),
    isEqual
  );
  const fetchReports = useCallback(() => {
    if (statusIds.length) {
      const statusPositions = statuses
        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'any' is not assignable to parame... Remove this comment to see the full error message
        .filter((status: any) => statusIds.includes(status.id))
        .map(({ position }: any) => position);
      handleStatusPositionsChange(statusPositions);
      return;
    }
    handleStatusPositionsChange([]);
  }, [currentGroupId, statusIds.length]); // eslint-disable-line
  const statusOptions = statuses
    .sort((a: any, b: any) => a.position - b.position)
    .map(({ id, abbreviation, title }: any = {}) => ({
      label: `${abbreviation} - ${title}`,
      value: id,
    }));
  const handleChange = (event: any) => {
    setStatusIds(event.target.value);
  };
  return (
    <Card>
      <CardContent className={classes.cardContent}>
        <Grid container>
          <Grid item xs={12}>
            <Header
              title='Select Statuses'
              subtitle='Analyze the joining process story for your leads by status'
              icon={SwapVert}
              compact
            />
          </Grid>

          <Grid item xs={12}>
            <FormControl variant='outlined' className={classes.formControl}>
              <InputLabel className={classes.inputLabel}>Statuses</InputLabel>
              <Select
                variant='outlined'
                multiple
                value={statusIds}
                onChange={handleChange}
                onClose={fetchReports}
                renderValue={selected => (
                  <Grid container spacing={1}>
                    {(selected as any).map((value: any) => (
                      <Grid item xs>
                        <Chip
                          key={value}
                          label={
                            (
                              statusOptions.find(
                                // @ts-expect-error ts-migrate(2739) FIXME: Type '{}' is missing the following properties from... Remove this comment to see the full error message
                                (status = {}) => value === (status as any).value
                              ) || {}
                            ).label
                          }
                          className={classes.chip}
                        />
                      </Grid>
                    ))}
                  </Grid>
                )}
                MenuProps={{}}
              >
                {statusOptions.map(({ value, label }: any) => (
                  <MenuItem key={value} value={value}>
                    {/* @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'any' is not assignable to parame... Remove this comment to see the full error message */}
                    <Checkbox checked={statusIds.includes(value)} />
                    <ListItemText primary={label} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
const useStyles = makeStyles(theme => ({
  cardContent: {
    paddingLeft: 24,
    paddingRight: 24,
  },
  formControl: {
    minWidth: 250,
    marginBottom: 24,
  },
  chip: {
    backgroundColor: (theme.palette.background as any).lightPaper,
  },
  inputLabel: {
    backgroundColor: theme.palette.background.paper,
  },
}));
