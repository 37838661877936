import React, { useCallback, useMemo } from 'react';
import { isEqual } from 'lodash';

// hooks
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

// components
import DazzlingDialog from 'components/DazzlingDialog';
import TagsSelector from 'components/ChipsSelector';
import { Field, Form } from 'react-final-form';

// material-ui
import {
  withStyles,
  WithStyles,
  Typography,
  Tooltip,
  Grid,
} from '@material-ui/core';

// icons
import LocalOffer from '@material-ui/icons/LocalOffer';

// actions
import { createGroupTagsAction } from 'store/actions/groupTagActions';

// selectors
import getCurrentGroupId from 'store/selectors/getCurrentGroupId';
import getLabels from 'store/selectors/getLabels';

//styles
import assignLabelsFormStyles from 'routes/ChapterList/ChapterListActions/AssignLabelsForm/assignLabelsForm.style';

const FORM = 'assignLabels';

export interface AssignLabelsFormProps
  extends WithStyles<typeof assignLabelsFormStyles> {
  selectedGroupIds: number[];
  onClose: (...args: any[]) => any;
  open: boolean;
  data?: { id: number; labelsClear: ({ id: number } | undefined)[] }[];
}

const AssignLabelsForm = ({
  classes,
  data,
  onClose,
  open,
  selectedGroupIds,
}: AssignLabelsFormProps) => {
  const {
    actions: { createGroupTags },
    state: { tags = [], currentGroupId },
  } = useRedux();

  const history = useHistory();

  const navigateToSettings = () => {
    const {
      location: { search },
    } = history;
    const urlGroup = new URLSearchParams(search).get('group');
    const newSearch = new URLSearchParams();
    if (urlGroup) {
      newSearch.set('group', urlGroup);
    }

    history.push({
      pathname: '/settings/labels',
      search: newSearch.toString(),
    });
  };

  const getCurrentLabels = (
    data?: { id: number; labelsClear: ({ id: number } | undefined)[] }[]
  ) => {
    if (selectedGroupIds.length === 1) {
      const selectedId = selectedGroupIds[0];
      const { labelsClear } =
        data?.find((item: { id: number }) => item?.id === selectedId) || {};

      return labelsClear
        ? labelsClear.map((item?: { id: number }) => item?.id)
        : false;
    }

    return false;
  };

  const tagOptions = () => {
    const currentLabels = getCurrentLabels(data);
    return tags.map(({ id, title, color }: any = {}) => ({
      value: id,
      label: title,
      color: '#' + color,
      type: 'label',
      ...(currentLabels && currentLabels.indexOf(id) !== -1
        ? {
            selected: true,
            disabled: true,
          }
        : {}),
    }));
  };

  const handleFormSubmit = useCallback(
    ({ tagIds = {} }) => {
      selectedGroupIds.forEach((selectedGroupId: number) => {
        createGroupTags({
          formName: FORM,
          parentId: currentGroupId,
          groupId: selectedGroupId,
          tagIds: tagIds,
        });
      });
    },
    // eslint-disable-next-line
    [createGroupTags, currentGroupId, selectedGroupIds]
  );

  return (
    <Form
      onSubmit={handleFormSubmit}
      initialValues={{ tags: [] }}
      initialValuesEqual={isEqual}
      render={({ handleSubmit, invalid, pristine, form: { reset } }) => (
        <DazzlingDialog
          disabled={invalid || pristine}
          formName={FORM}
          handleClose={onClose}
          open={open}
          onAccept={handleSubmit}
          headerProps={{
            icon: LocalOffer,
            title: 'Assign Labels',
            highlightedSubtitle: 'Categorize your groups with label tags',
          }}
          acceptLabel='Submit'
          submitOnEnter
          onExited={() => reset()}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Tooltip
                arrow
                title='Tags give you simple ways to categorize and group your chapters'
              >
                <div>
                  <Field
                    name='tagIds'
                    label='Select Labels'
                    // @ts-expect-error ts-migrate(2322) FIXME: Type 'typeof ChipsSelector' is not assignable to t... Remove this comment to see the full error message
                    component={TagsSelector}
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    options={tagOptions()}
                    isEqual={(a, b) => isEqual(new Set(a), new Set(b))}
                  />
                </div>
              </Tooltip>
            </Grid>

            <Grid item xs={12}>
              <Typography variant='body2'>
                Want more label tags? Manage them from your{' '}
                <span className={classes.link} onClick={navigateToSettings}>
                  settings.
                </span>
              </Typography>
            </Grid>
          </Grid>
        </DazzlingDialog>
      )}
    />
  );
};

const useRedux = () => {
  const dispatch = useDispatch();
  const actions = useMemo(
    () => ({
      createGroupTags: (payload: any) =>
        dispatch(createGroupTagsAction(payload)),
    }),
    [dispatch]
  );
  const state = useSelector(
    state => ({
      tags: getLabels(state),
      currentGroupId: getCurrentGroupId(state),
    }),
    isEqual
  );
  return { actions, state };
};

export default withStyles(assignLabelsFormStyles, { withTheme: true })(
  AssignLabelsForm
);
