import React, { useState } from 'react';
import { Form, Field } from 'react-final-form';
import { useSelector, shallowEqual } from 'react-redux';
import { useCreateCheckoutSessionMutation } from 'api/billing';
import { useStripe } from '@stripe/react-stripe-js';

// MUI
import { Grid, Typography } from '@material-ui/core';
import { Phone } from '@material-ui/icons';

// components
import DazzlingDialog from 'components/DazzlingDialog';
import TextField from 'components/TextField';

//selectors
import getPhoneAddOn from 'store/selectors/getPhoneAddOn';

interface AddOnModalPhoneLinesProps {
  opened: boolean;
  handleClose: () => void;
}

export default function AddOnModalPhoneLines({
  opened,
  handleClose,
}: AddOnModalPhoneLinesProps) {
  const stripe = useStripe();
  const {
    data,
    mutate: createCheckoutSession,
    isError,
    isLoading,
    isSuccess,
    reset: resetMutation,
  } = useCreateCheckoutSessionMutation('subscription');

  const { phoneLines } = useSelector(
    state => ({
      phoneLines: getPhoneAddOn(state),
    }),
    shallowEqual
  );

  const [priceId] = useState<string>(phoneLines?.priceId);
  const [unitPrice] = useState<number>(phoneLines?.priceAmount / 100);

  return (
    <Form
      onSubmit={value => {
        const stripeLineItems = [];
        if (priceId)
          stripeLineItems.push({
            price: priceId,
            quantity: value.numberOfPhoneLines,
          });
        createCheckoutSession(stripeLineItems);
      }}
      render={({ handleSubmit, pristine, values, form: { reset } }) => (
        <DazzlingDialog
          isError={isError}
          isLoading={isLoading}
          isSuccess={isSuccess}
          acceptLabel={'Continue'}
          open={opened}
          headerProps={{
            icon: Phone,
            title: 'Purchase Additional Phone Lines',
            subtitle: 'Select your option and proceed to secure checkout',
          }}
          onAccept={handleSubmit}
          disabled={pristine}
          handleClose={handleClose}
          submitOnEnter
          onExited={() => {
            console.log(`onExited... isSuccess:  ${isSuccess}`);
            if (isSuccess)
              stripe?.redirectToCheckout({ sessionId: data.checkoutSessionId });
            resetMutation();
            reset();
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={3} md={3}>
              <Typography variant='subtitle2' color='textSecondary'>
                Phone Line | $15/year
              </Typography>
            </Grid>
            <Grid item xs={7}>
              <Field
                name='numberOfPhoneLines'
                component={TextField}
                select
                variant='outlined'
                label='Quantity'
                style={{ marginTop: '0px', width: '120px' }}
                options={
                  phoneLines
                    ? [
                        {
                          value: 1,
                          label: `1`,
                        },
                        {
                          value: 2,
                          label: `2`,
                        },
                        {
                          value: 3,
                          label: `3`,
                        },
                        {
                          value: 4,
                          label: `4`,
                        },
                        {
                          value: 5,
                          label: `5`,
                        },
                      ]
                    : []
                }
              />
            </Grid>
            <Grid item xs={3} container justify='space-between'>
              <Grid item>
                <Typography variant='subtitle2' color='textSecondary'>
                  Total:
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant='subtitle2' color='textSecondary'>
                  $
                  {values.numberOfPhoneLines > 0
                    ? values.numberOfPhoneLines * unitPrice
                    : 0}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              item
              container
              direction='column'
              alignContent='flex-start'
              spacing={1}
            ></Grid>
          </Grid>
        </DazzlingDialog>
      )}
    />
  );
}
