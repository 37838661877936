import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import Immutable, { List } from 'immutable';
// material-ui
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Skeleton from '@material-ui/lab/Skeleton';
import Typography from '@material-ui/core/Typography';
// icons
import AddCircleIcon from '@material-ui/icons/AddCircle';
// components
import Button from 'components/Button';
import DraggableList from 'components/DraggableList';
import { FieldArray } from 'react-final-form-arrays';
import FormEditorFieldItem from './FormEditorFieldItem';
import CreateFormFieldModal from './CreateFormFieldModal';
// selectors
import getLoadingFromState from 'store/selectors/getLoadingFromState';
import getCurrentGroupId from 'store/selectors/getCurrentGroupId';
// action creators
import { deleteFormField } from 'store/actions/formFieldActions';
// hooks
import { useReduxForm } from 'store/hooks/useReduxForm';
//helpers
import { isObject } from 'helpers/check';

const FORM = 'editReferralForm';

const FormEditorFields = function({
  formFields,
  values,
  mutators,
  initialValues,
  isCouncilForm,
  isOrganizationForm,
}: {
  formFields: any;
  values: any;
  mutators: any;
  initialValues: any;
  isCouncilForm?: boolean | null;
  isOrganizationForm?: boolean | null;
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const params = useParams();
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'formId' does not exist on type '{}'.
  const { formId } = params;
  const [createOpen, setCreateOpen] = useState(false);
  const [labelToDelete, setLabelToDelete] = useState(null);
  const metaId = `delete-${labelToDelete}`;
  const { isSubmitting } = useReduxForm(FORM);
  const { deleteFormFieldLoading, formFieldLoading, groupId } = useSelector(
    state => ({
      formFieldLoading: getLoadingFromState('formField')(state),
      deleteFormFieldLoading: getLoadingFromState('formField', metaId)(state),
      groupId: getCurrentGroupId(state),
    })
  );
  const loading = formFieldLoading && !deleteFormFieldLoading && !isSubmitting;

  const resetFieldsValue = () => {
    if (isObject(initialValues)) {
      const { fields } = initialValues;
      mutators.setValue('fields', fields);
    }
  };

  const handleDeleteFieldItem = (formField: any) => {
    const label = formField.get('label');
    setLabelToDelete(label);
    dispatch(deleteFormField({ groupId, formId, label }));
  };

  const handleCloseCreateFormFieldModal = () => {
    setCreateOpen(false);
  };

  const renderSkeleton = () => {
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 1-3 arguments, but got 0.
    return new Array(2).fill().map((skeleton, index) => (
      <Grid item xs={12} key={`skeleton_${index}`}>
        <Skeleton id='formFieldSkeleton' variant='rect' height={56} />
      </Grid>
    ));
  };
  return (
    <Grid item xs={12} className={classes.formFieldContainer}>
      <Grid
        container
        spacing={3}
        alignItems='center'
        justifyContent='space-around'
      >
        <Grid item xs={10} sm={8} className={classes.header}>
          <Typography>Form Fields</Typography>
          <Typography color='textSecondary' variant='subtitle2'>
            Select, describe, and require fields for individuals to fill out
          </Typography>
        </Grid>

        <Grid item xs={2} sm={4} className={classes.addFieldButton}>
          <Button
            variant='outlined'
            disabled={loading}
            startIcon={<AddCircleIcon />}
            iconButtonIcon={<AddCircleIcon />}
            onClick={() => setCreateOpen(true)}
          >
            Add Field
          </Button>
        </Grid>

        {loading ? (
          renderSkeleton()
        ) : (
          <FieldArray name='fields'>
            {({ fields }) => (
              <DraggableList
                // @ts-expect-error ts-migrate(2322) FIXME: Type '{ id: string; initialItems: any; key: string... Remove this comment to see the full error message
                id='draggableList'
                initialItems={formFields}
                key='list'
                fields={fields}
                itemComponent={
                  <FormEditorFieldItem
                    items={Immutable.fromJS(values.fields)}
                    onDelete={handleDeleteFieldItem}
                    labelToDelete={labelToDelete}
                    label='Field'
                    resetFieldsValue={resetFieldsValue}
                    mutators={mutators}
                    fieldRemove={fields?.remove}
                    isCouncilForm={isCouncilForm}
                    isOrganizationForm={isOrganizationForm}
                  />
                }
              />
            )}
          </FieldArray>
        )}
      </Grid>

      <CreateFormFieldModal
        open={createOpen}
        onClose={handleCloseCreateFormFieldModal}
        resetFieldsValue={resetFieldsValue}
        isCouncilForm={isCouncilForm}
        isOrganizationForm={isOrganizationForm}
      />
    </Grid>
  );
};
FormEditorFields.propTypes = {
  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'typeof List' is not assignable t... Remove this comment to see the full error message
  formFields: PropTypes.instanceOf(List).isRequired,
  values: PropTypes.instanceOf(Object).isRequired,
};
const useStyles = makeStyles(theme => {
  const border = `2px solid ${(theme.palette.background as any).darkPaper}`;
  return {
    formFieldContainer: {
      borderTop: border,
      marginTop: 10,
      marginBottom: 10,
      paddingTop: 15,
      paddingLeft: 50,
      paddingRight: 50,
      paddingBottom: 15,
      [theme.breakpoints.only('xs')]: {
        paddingLeft: 20,
        paddingRight: 20,
      },
    },
    header: {
      paddingBottom: 15,
    },
    addFieldButton: {
      textAlign: 'right',
    },
  };
});
export default FormEditorFields;
