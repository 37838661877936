import { all, call, fork, put, takeEvery, select } from 'redux-saga/effects';

// action creators
import { doneIndicator } from 'store/actions/httpActions';

// selectors
import getCurrentGroupId from 'store/selectors/getCurrentGroupId';

// api
import client from 'sources/api';

// constants
import {
  LEAD_REFERRAL_SOURCE_CREATE_REQUEST,
  LEAD_REFERRAL_SOURCE_CREATE,
} from 'store/constants/leadReferralSourceTypes';

// schemas
import { leadReferralSourceSchema } from 'store/schemas/leadReferralSourceSchema';

// helpers
import httpSaga from 'store/sagas/httpSaga';

const WATCH_TYPE = LEAD_REFERRAL_SOURCE_CREATE_REQUEST;
const TYPE = LEAD_REFERRAL_SOURCE_CREATE;

export function* createLeadReferralSource(action: any) {
  const {
    payload: { leadId, referralSourceId },
  } = action;
  const currentGroupId = yield select(getCurrentGroupId);

  // @ts-expect-error ts-migrate(2569) FIXME: Type 'Generator<any, any, unknown>' is not an arra... Remove this comment to see the full error message
  yield* httpSaga(
    TYPE,
    call(client.post, `/pnm_referral_sources`, {
      pnm_id: leadId,
      group_id: currentGroupId,
      referral_source_id: referralSourceId,
    }),
    {
      schema: leadReferralSourceSchema,
      metaId: referralSourceId,
    }
  );

  yield put(doneIndicator(TYPE));
}

export function* watch() {
  yield takeEvery(WATCH_TYPE, createLeadReferralSource);
}

export default function* root() {
  yield all([fork(watch)]);
}
