import React from 'react';

// hooks
import { useDispatch } from 'react-redux';

// material-ui
import { Grid, Tooltip } from '@material-ui/core';

// components
import IconButton from 'components/IconButton';

// icons
import Link from '@material-ui/icons/Link';
import LinkOff from '@material-ui/icons/LinkOff';

// action creators
import {
  addDashboardLink,
  removeDashboardLink,
} from 'store/actions/groupActions';

type Props = {
  parentId: number;
  childId: number;
  parentName?: string;
  isLinked: boolean;
};

export default function ParentCell({
  parentId,
  childId,
  parentName,
  isLinked,
}: Props) {
  const dispatch = useDispatch();

  const handleLinkClick = (
    event: React.MouseEvent<HTMLElement>,
    parentId: number,
    childId: number,
    isLinked: boolean
  ) => {
    event.stopPropagation();
    event.preventDefault();

    if (isLinked) {
      dispatch(
        removeDashboardLink({
          parentId,
          childId,
        })
      );
    } else {
      dispatch(addDashboardLink({ parentId, childId }));
    }
  };

  return (
    <Grid container spacing={1} alignItems='center'>
      <Grid item xs>
        {parentName}
      </Grid>
      {parentId && (
        <Grid item xs={3}>
          <Tooltip
            title={
              isLinked
                ? `Click to unlink from ${parentName} dashboard`
                : `Click to link to ${parentName} dashboard`
            }
          >
            <IconButton
              onClick={(e: any) =>
                handleLinkClick(e, parentId, childId, isLinked)
              }
            >
              {isLinked ? <Link /> : <LinkOff />}
            </IconButton>
          </Tooltip>
        </Grid>
      )}
    </Grid>
  );
}
