import React, { useState, useCallback } from 'react';

// redux hooks
import { useSelector } from 'react-redux';

// components
import Button from 'components/Button';
import IconButton from 'components/IconButton';
import CreateNoteTagModal from 'components/CreateNoteTagModal';

// material-ui
import { NoteAdd, AddCircle } from '@material-ui/icons';

// selectors
import getLoadingFromState from 'store/selectors/getLoadingFromState';

type Props = {
  withIcon?: boolean;
  leadId: number;
};

export default function WriteNoteButton({ withIcon, leadId }: Props) {
  const [modalOpened, setModalOpened] = useState(false);
  const loading = useSelector(
    getLoadingFromState('lead', `fetchById-${leadId}`, false)
  );
  const handleOpenModal = useCallback(() => setModalOpened(true), []);
  const handleCloseModal = useCallback(() => {
    setModalOpened(false);
  }, []);

  const handleClick = useCallback(() => {
    handleOpenModal();
  }, [handleOpenModal]);

  const buttonProps = {
    onClick: handleClick,
    disabled: loading || modalOpened,
  };
  return (
    <>
      {withIcon ? (
        <IconButton {...buttonProps}>
          <AddCircle />
        </IconButton>
      ) : (
        <Button
          id='note'
          style={{ marginTop: 18 }}
          fullWidth
          variant='outlined'
          startIcon={<NoteAdd />}
          {...buttonProps}
        >
          Add Note
        </Button>
      )}
      <CreateNoteTagModal
        open={modalOpened}
        leadIds={[leadId]}
        onClose={handleCloseModal}
      />
    </>
  );
}
