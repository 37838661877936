import React, { useState, useEffect, useMemo } from 'react';

// hooks
import { useSelector, useDispatch } from 'react-redux';
import { usePrevious } from 'helpers/hooks/usePrevious';
import { useHistory } from 'react-router-dom';
import { toInteger } from 'lodash';

// MUI components
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  Fab,
  CircularProgress,
  useMediaQuery,
  InputAdornment,
} from '@material-ui/core';
import { Send } from '@material-ui/icons';

// components
import Toast from 'components/Toast';
import TextField from 'components/TextField';
import DazzlingDialog from 'components/DazzlingDialog';

// action creators
import { createMessageAction } from 'store/actions/messageActions';

// selectors
import getCurrentGroupId from 'store/selectors/getCurrentGroupId';
import getLoadingFromState from 'store/selectors/getLoadingFromState';
import getStatusFromState from 'store/selectors/getStatusFromState';
import getSliceState from 'store/selectors/getSliceState';

type MessageInputProps = {
  recipients: (string | number | null)[];
  excludedRecipients: number[];
  thread: any;
  templateText: string;
  filters: LeadFetchFilters;
  totalLeads: number;
  contentType: string;
};

const MessageInput = ({
  recipients,
  excludedRecipients,
  thread,
  templateText,
  filters,
  totalLeads,
  contentType,
}: MessageInputProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const theme = useTheme();
  const history = useHistory();
  const {
    location: { pathname, search },
  } = history;
  const isExtraSmall = useMediaQuery(theme.breakpoints.only('xs'));
  const [inputValue, setInputValue] = useState('');
  const [sendAllModal, setSendAllModal] = useState(false);
  const disabled =
    !inputValue.length || (!thread.get('id') && !recipients.length);
  let recipientIds = recipients;
  const allSelected = useMemo(() => {
    if (thread.get('id')) return false;
    return recipients && recipients.includes('all');
  }, [recipients, thread]);
  if (thread.get('id')) {
    const leadId = thread.getIn(['recipients', 0, 'pnmId']);
    recipientIds = [typeof leadId === 'string' ? toInteger(leadId) : leadId];
  }

  const {
    currentGroupId,
    messageCreating,
    messageStatus,
    threads,
  } = useSelector(state => ({
    currentGroupId: getCurrentGroupId(state),
    messageCreating: getLoadingFromState('message', 'create', false)(state),
    threads: getSliceState('thread')(state),
    messageStatus: getStatusFromState('message', 'create')(state),
  }));

  const previousMessageCreating = usePrevious(messageCreating);
  useEffect(() => {
    if (templateText) {
      const insertString =
        templateText === 'full_name'
          ? '{{ first_name }} {{ last_name }}'
          : `{{ ${templateText} }}`;
      setInputValue(`${insertString}${inputValue ? ` ${inputValue}` : ''}`);
    }
  }, [templateText]); // eslint-disable-line

  useEffect(() => {
    if (previousMessageCreating && !messageCreating) {
      if (recipients.length && !allSelected) {
        // Redirect to the thread of the first recipient from the newly created message
        const threadToNavigateTo = (threads.data as Thread[]).find(
          (t: Thread) =>
            t.recipients.length > 0 && t.recipients[0].pnmId === recipients[0]
        );
        const newThreadId = threadToNavigateTo?.id;
        if (newThreadId) {
          const newSearch = new URLSearchParams(search);
          newSearch.set('thread', newThreadId.toString());
          history.push({ pathname, search: newSearch.toString() });
        }
      }
      setInputValue('');
    }
  }, [
    allSelected,
    history,
    messageCreating,
    messageStatus,
    pathname,
    previousMessageCreating,
    recipients,
    recipients.length,
    search,
    threads,
  ]);
  const filtered = Object.keys(filters).length ? 'filtered' : '';
  const sendAllModalTitle = `Are you sure you want to text ALL ${filtered} leads?`;

  const handleCreateMessage = () => {
    const params: any = {
      threadType: 'text',
      body: inputValue,
      leadIds: recipientIds,
    };
    if (currentGroupId) (params as any).groupId = currentGroupId;
    if (params.leadIds.includes('all')) {
      params.allSelected = true;
      params.leadIds = excludedRecipients;
      params.filters = filters;
      setSendAllModal(false);
    }
    if (thread.get('id')) (params as any).threadId = thread.get('id');
    else if (recipients.length) (params as any).isNewMessage = true;
    if (inputValue.length && recipientIds.length) {
      dispatch(createMessageAction(params));
    }
  };

  return recipientIds.includes(null) ? null : (
    <>
      <TextField
        id='messageTextField'
        placeholder='Write a message'
        multiline
        fullWidth
        margin='none'
        variant='standard'
        maxLength={1600}
        rows={isExtraSmall ? 1 : 4}
        className={classes.padding}
        input={{
          value: inputValue,
          onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
            setInputValue(e.target.value),
        }}
        InputProps={{
          disableUnderline: true,
          className: classes.messageTextField,
          endAdornment: (
            <InputAdornment position='end'>
              <Fab
                id='messageFab'
                className={
                  disabled ? classes.disabledButton : classes.sendButton
                }
                size={isExtraSmall ? 'small' : 'medium'}
                onClick={
                  contentType !== 'thread' &&
                  ((allSelected && !excludedRecipients.length) ||
                    recipients.length === totalLeads)
                    ? () => setSendAllModal(true)
                    : handleCreateMessage
                }
                disabled={disabled || messageCreating}
              >
                {messageCreating ? (
                  <CircularProgress
                    id='messageCreating'
                    size={24}
                    className={classes.loadingIndicator}
                  />
                ) : (
                  <Send id='sendIcon' className={classes.sendIcon} />
                )}
              </Fab>
            </InputAdornment>
          ),
        }}
      />

      <Toast
        id={'messageToast'}
        slice='message'
        metaId='create'
        snackbarProps={{ autoHideDuration: 4000 }}
        successMessage='Sent to text message queue successfully!'
        failureMessage={
          allSelected || recipientIds.length > 1
            ? 'Some messages failed to send'
            : 'Message failed to send'
        }
      />
      <DazzlingDialog
        id='sendAllModal'
        handleClose={() => setSendAllModal(false)}
        open={sendAllModal}
        acceptLabel='Send All'
        onAccept={handleCreateMessage}
        headerProps={{
          icon: Send,
          title: sendAllModalTitle,
        }}
      />
    </>
  );
};

const useStyles = makeStyles(theme => {
  return {
    messageTextField: {
      paddingTop: 15,
      paddingBottom: 10,
      paddingLeft: 20,
      paddingRight: 10,
      borderRadius: 8,
      backgroundColor: (theme.palette.background as any).lightPaper,
      transition: 'all 0.25s ease',
      overflow: 'auto',
      '&:focus': {
        transition: 'all 0.25s ease',
        backgroundColor: (theme.palette.background as any).highlightedPaper,
      },
    },
    padding: {
      padding: '12px 16px',
    },
    sendButton: {
      background: `radial-gradient(circle, ${theme.palette.secondary.main} 0%, ${theme.palette.primary.main} 100%)`,
    },
    disabledButton: {
      backgroundColor: '#dfdfdf',
    },
    sendIcon: {
      color: theme.palette.common.white,
    },
    loadingIndicator: {
      color: theme.palette.common.white,
    },
  };
});

export default MessageInput;
