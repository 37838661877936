import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import AccountBalance from '@material-ui/icons/AccountBalance';
import NoteAdd from '@material-ui/icons/NoteAdd';
import SwapVertIcon from '@material-ui/icons/SwapVert';
import PhoneIcon from '@material-ui/icons/Phone';
import SmsIcon from '@material-ui/icons/Sms';
import EmailIcon from '@material-ui/icons/Email';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import AccessTimeIcon from '@material-ui/icons/AccessTime';

const getTableColumns = (isOrganization: boolean) => [
  {
    key: isOrganization ? 'groupCouncil' : 'groupOrganization',
    label: (
      <Tooltip title={isOrganization ? 'Council' : 'Organization'}>
        <AccountBalance />
      </Tooltip>
    ),
    isTooltipAllowed: true,
  },
  {
    key: 'notesCreated',
    label: (
      <Tooltip title='Notes Created'>
        <NoteAdd />
      </Tooltip>
    ),
    isTooltipAllowed: true,
  },
  {
    key: 'pnmStatusesUpdated',
    label: (
      <Tooltip title='Lead Status Updates'>
        <SwapVertIcon />
      </Tooltip>
    ),
    isTooltipAllowed: true,
  },
  {
    key: 'callsCreated',
    label: (
      <Tooltip title='Calls Made'>
        <PhoneIcon />
      </Tooltip>
    ),
    isTooltipAllowed: true,
  },
  {
    key: 'textsSent',
    label: (
      <Tooltip title='Texts Sent'>
        <SmsIcon />
      </Tooltip>
    ),
    isTooltipAllowed: true,
  },
  {
    key: 'emailsSent',
    label: (
      <Tooltip title='Emails Sent'>
        <EmailIcon />
      </Tooltip>
    ),
    isTooltipAllowed: true,
  },
  {
    key: 'pnmsCreated',
    label: (
      <Tooltip title='Leads Created'>
        <GroupAddIcon />
      </Tooltip>
    ),
    isTooltipAllowed: true,
  },
  {
    key: 'latestActivityDate',
    label: (
      <Tooltip title='Latest Activity Date'>
        <AccessTimeIcon />
      </Tooltip>
    ),
    isTooltipAllowed: true,
  },
];

export default getTableColumns;
