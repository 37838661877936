import getCurrentParentOrganization from './getCurrentParentOrganization';

export default function getCurrentParentOrganizationName(state: any) {
  const parent = getCurrentParentOrganization(state);
  if (parent && parent != null && parent?.displayName) {
    return parent.displayName;
  } else {
    return '';
  }
}
