import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { List } from 'immutable';

// material-ui
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import Skeleton from '@material-ui/lab/Skeleton';
import Typography from '@material-ui/core/Typography';

// components
import Header from 'components/Header';
import TextField from 'components/TextField';
import SimplePagination from 'components/SimplePagination';
import ListCardItem from './ListCardItem';
import MegaMenu from 'components/MegaMenu';
import IconButton from 'components/IconButton';

// icons
import SearchIcon from '@material-ui/icons/Search';
import OptionsIcon from '@material-ui/icons/MoreVert';

const ListCard = function({
  headerIcon,
  highlightedSubtitle,
  items,
  loading,
  menuSections,
  onSearchChange,
  paginationProps,
  searchLabel,
  searchPlaceholder,
  subtitle,
  title,
  ...restOfProps
}: any) {
  const classes = useStyles();
  const [optionsAnchorEl, setOptionsAnchorEl] = useState(null);
  const shouldRenderOptionsIcon = menuSections.size > 0;

  const renderItems = () => {
    if (items.size) {
      return (
        <Grid container>
          {items.map((item: any, index: any) => {
            return <ListCardItem key={index}>{item}</ListCardItem>;
          })}
        </Grid>
      );
    } else {
      return (
        <Grid container id='noneFoundContainer'>
          <Grid item xs={12}>
            <Typography variant='body1'>None found</Typography>
          </Grid>
        </Grid>
      );
    }
  };

  return (
    <Card {...restOfProps}>
      <CardContent>
        <Grid container>
          <Grid item xs={shouldRenderOptionsIcon ? 11 : 12}>
            <Header
              icon={headerIcon}
              title={title}
              subtitle={subtitle}
              highlightedSubtitle={highlightedSubtitle}
            />
          </Grid>

          {shouldRenderOptionsIcon && (
            <Grid item xs={1} id='optionsIconButton'>
              <IconButton
                onClick={(e: any) => setOptionsAnchorEl(e.currentTarget)}
              >
                <OptionsIcon />
              </IconButton>
            </Grid>
          )}
        </Grid>

        <Grid
          container
          className={classes.searchContainer}
          alignItems='center'
          justifyContent='space-between'
        >
          <Grid item xs={8} sm={6}>
            <TextField
              id='listCardSearchInput'
              label={searchLabel}
              placeholder={searchPlaceholder}
              onChange={onSearchChange}
              loading={loading}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          <Grid item id='pagination'>
            <SimplePagination {...paginationProps} />
          </Grid>
        </Grid>

        {!items.size && loading ? (
          <Grid container spacing={2} id='bodySkeleton'>
            <Grid item xs={12}>
              <Skeleton variant='rect' height={50} />
            </Grid>
            <Grid item xs={12}>
              <Skeleton variant='rect' height={50} />
            </Grid>
            <Grid item xs={12}>
              <Skeleton variant='rect' height={50} />
            </Grid>
          </Grid>
        ) : (
          renderItems()
        )}
      </CardContent>

      <MegaMenu
        // @ts-expect-error ts-migrate(2322) FIXME: Type '{ id: string; anchorEl: null; handleClose: (... Remove this comment to see the full error message
        id='megaMenu'
        anchorEl={optionsAnchorEl}
        handleClose={() => setOptionsAnchorEl(null)}
        menuSections={menuSections}
      />
    </Card>
  );
};

ListCard.propTypes = {
  headerIcon: PropTypes.instanceOf(Object),
  highlightedSubtitle: PropTypes.string,
  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'typeof List' is not assignable t... Remove this comment to see the full error message
  items: PropTypes.instanceOf(List).isRequired,
  loading: PropTypes.bool,
  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'typeof List' is not assignable t... Remove this comment to see the full error message
  menuSections: PropTypes.instanceOf(List),
  onSearchChange: PropTypes.func.isRequired,
  paginationProps: PropTypes.instanceOf(Object),
  searchLabel: PropTypes.string,
  searchPlaceholder: PropTypes.string,
  subtitle: PropTypes.string,
  title: PropTypes.string.isRequired,
};

ListCard.defaultProps = {
  menuSections: List(),
  highlightedSubtitle: '',
};

const useStyles = makeStyles({
  searchContainer: {
    marginBottom: 20,
  },
});

export default ListCard;
