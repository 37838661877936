import React, { useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { isEqual } from 'lodash';
import arrayMutators from 'final-form-arrays';
import validate from 'helpers/validate';
import PropTypes from 'prop-types';

// hooks
import { useNewMemberLeadsQuery, useUpdateLeadMutation } from 'api/leads';

// helpers
import formatDisplayedPhoneNumber from 'helpers/formatDisplayedPhoneNumber';

// material-ui
import {
  Grid,
  CardContent,
  CardActions,
  FormHelperText,
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';

// icons
import GroupAdd from '@material-ui/icons/GroupAdd';

// components
import { Form } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import Header from 'components/Header';
import Button from 'components/Button';
import EmptyMessage from 'components/EmptyMessage';
import NewMemberItem from './NewMemberItem';

// selectors
import getReportingPlatformName from 'store/selectors/getReportingPlatformName';

const UPDATE_PNMS_SCHEMA = {
  emailAddress: {
    length: { maximum: 128 },
  },
  phoneNumber: {
    validPhoneNumber: true,
  },
};

const UpdateLeadsForm = function({
  updateLeadsFormDirty,
  setUpdateLeadsFormDirty,
  setUpdateLeadsFormInvalid,
  updateLeadsFormInvalid,
  selectedIds,
  setSelectedIds,
}: any) {
  const classes = useStyles();
  const {
    isLoading: isLeadUpdating,
    isSuccess: isLeadUpdated,
    isError: isLeadUpdatingFailed,
    mutate: updateLead,
    error,
  } = useUpdateLeadMutation();
  const {
    data,
    isLoading: leadsLoading,
    hasNextPage,
    fetchNextPage,
  } = useNewMemberLeadsQuery({
    page: 1,
  });

  const newMemberLeads: Lead[] = useMemo(
    () => (data?.pages ? data?.pages.flat() : []),
    [data]
  );

  const reportingPlatformName = useSelector(getReportingPlatformName);

  const validateForm = useCallback(
    values => ({
      newMembers: values.newMembers.map((status: any) =>
        validate(status, UPDATE_PNMS_SCHEMA)
      ),
    }),
    []
  );

  const dispatchUpdateLeads = useCallback(
    (values, dirtyFields) => {
      const leadIds: any = [];
      const leadFields: any = [];

      // Filter down the form values to get only the leads that need to be updated
      //  We're calling the update lead saga in a loop, so efficiency is necessary here
      newMemberLeads.forEach((newMember, index) => {
        const currentEmailAddressField = `newMembers[${index}].emailAddress`;
        const currentPhoneNumberField = `newMembers[${index}].phoneNumber`;
        if (
          dirtyFields[currentEmailAddressField] ||
          dirtyFields[currentPhoneNumberField]
        ) {
          leadIds.push(newMember.id);
          leadFields.push(values.newMembers[index]);
        }
      });
      leadIds.forEach((id: number, index: number) => {
        updateLead({ id, ...leadFields[index] });
      });
    },
    [newMemberLeads, updateLead]
  );

  const getInitialValues = () => {
    const newMemberValues = newMemberLeads.map(newMember => ({
      emailAddress: newMember.emailAddress,
      phoneNumber: formatDisplayedPhoneNumber(newMember.phoneNumber),
    }));

    return {
      newMembers: newMemberValues,
    };
  };

  const handleSetSelectedIds = ({ target: { checked } }: any, id: any) => {
    if (checked) {
      setSelectedIds(selectedIds.concat([id]));
    } else {
      setSelectedIds(
        selectedIds.filter((selectedId: any) => selectedId !== id)
      );
    }
  };
  useEffect(() => {
    if (hasNextPage) fetchNextPage();
  }, [hasNextPage, fetchNextPage]);

  const ReviewMembersSkeleton = () => {
    return (
      <Grid container spacing={2} alignItems='center'>
        {Array(3)
          // @ts-expect-error ts-migrate(2554) FIXME: Expected 1-3 arguments, but got 0.
          .fill()
          .map((skeleton, index) => (
            <Grid item xs={12} key={index}>
              <Grid container spacing={2} alignItems='center'>
                <Grid item xs={1}>
                  <Skeleton variant='rect' className={classes.skeleton} />
                </Grid>
                <Grid item xs={3}>
                  <Skeleton variant='rect' className={classes.skeleton} />
                </Grid>
                <Grid item xs={4}>
                  <Skeleton variant='rect' className={classes.skeleton} />
                </Grid>
                <Grid item xs={4}>
                  <Skeleton variant='rect' className={classes.skeleton} />
                </Grid>
              </Grid>
            </Grid>
          ))}
      </Grid>
    );
  };

  return (
    <Form
      id='updateLeadsForm'
      onSubmit={() => {}}
      mutators={{ ...arrayMutators }}
      initialValues={getInitialValues()}
      initialValuesEqual={isEqual}
      validate={validateForm}
      render={({
        values,
        invalid,
        pristine,
        dirty,
        dirtyFields,
        form: { reset },
      }) => {
        if (dirty !== updateLeadsFormDirty) {
          setUpdateLeadsFormDirty(dirty);
        }
        if (invalid !== updateLeadsFormInvalid) {
          setUpdateLeadsFormInvalid(invalid);
        }

        return (
          <form>
            <CardContent className={classes.cardContent}>
              <Grid container spacing={4} alignItems='flex-start'>
                <Grid item xs>
                  <Header
                    id='header'
                    icon={GroupAdd}
                    title='Review New Members'
                    subtitle={`Select the members that you wish to report to ${reportingPlatformName} and verify their information. Emails MUST NOT use a '.edu' domain for reporting.`}
                  />
                </Grid>
              </Grid>

              {leadsLoading ? (
                <ReviewMembersSkeleton />
              ) : newMemberLeads.length > 0 ? (
                <FieldArray name='newMembers' isEqual={isEqual}>
                  {({ fields }) => (
                    <Grid container spacing={2}>
                      {fields.map((prefix, index) => (
                        <Grid item xs={12} key={prefix}>
                          <NewMemberItem
                            fieldPrefix={prefix}
                            isSelected={selectedIds.includes(
                              newMemberLeads[index]?.id
                            )}
                            handleSetSelectedIds={handleSetSelectedIds}
                            member={newMemberLeads[index]}
                            reportingPlatformName={reportingPlatformName}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  )}
                </FieldArray>
              ) : (
                <EmptyMessage
                  title='No new, unreported members found'
                  description='leads will appear here once their status reaches the highest position, and they have yet to be reported.'
                />
              )}
            </CardContent>

            <CardActions className={classes.cardActions}>
              <Grid
                container
                direction='column'
                justifyContent='center'
                alignItems='center'
              >
                <Grid item xs={12}>
                  <Button id='cancelButton' onClick={reset} disabled={pristine}>
                    Cancel
                  </Button>

                  <Button
                    id='updateLeadsButton'
                    color='primary'
                    variant='outlined'
                    onClick={() => dispatchUpdateLeads(values, dirtyFields)}
                    disabled={pristine || invalid || isLeadUpdating}
                    loading={isLeadUpdating}
                    success={isLeadUpdated}
                    fail={isLeadUpdatingFailed}
                  >
                    Save Changes
                  </Button>
                </Grid>

                {error && (
                  <Grid item xs={12}>
                    <FormHelperText error id='errorMessage'>
                      {error?.response?.data}
                    </FormHelperText>
                  </Grid>
                )}
              </Grid>
            </CardActions>
          </form>
        );
      }}
    />
  );
};

UpdateLeadsForm.propTypes = {
  updateLeadsFormDirty: PropTypes.bool.isRequired,
  setUpdateLeadsFormDirty: PropTypes.func.isRequired,
  setUpdateLeadsFormInvalid: PropTypes.func.isRequired,
  updateLeadsFormInvalid: PropTypes.bool.isRequired,
  selectedIds: PropTypes.instanceOf(Array).isRequired,
  setSelectedIds: PropTypes.func.isRequired,
};

const useStyles = makeStyles(theme => {
  return {
    cardContent: {
      paddingTop: 30,
      paddingLeft: 24,
    },
    skeleton: {
      height: 56,
      borderRadius: 4,
    },
    cardActions: { margin: 16 },
  };
});

export default UpdateLeadsForm;
