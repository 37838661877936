import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
// action creators
import { doneIndicator } from 'store/actions/httpActions';
// api
import client from 'sources/api';
// constants
import {
  FORM_FIELD_FETCH_REQUEST,
  FORM_FIELD_FETCH,
} from 'store/constants/formFieldTypes';
// schemas
import { formFieldSchema } from 'store/schemas/formFieldSchema';
// helpers
import httpSaga from 'store/sagas/httpSaga';
const WATCH_TYPE = FORM_FIELD_FETCH_REQUEST;
const TYPE = FORM_FIELD_FETCH;
export function* fetchFormFields(action: any) {
  const {
    payload: { token, formId: form_id, groupId: group_id, orderBy: order_by },
  } = action;
  const options = {};
  if (token) {
    (options as any).headers = {
      Authorization: `Bearer ${token}`,
    };
  }
  // @ts-expect-error ts-migrate(2569) FIXME: Type 'Generator<any, any, unknown>' is not an arra... Remove this comment to see the full error message
  yield* httpSaga(
    TYPE,
    call(client.get, '/form_fields', {
      params: { group_id, form_id, order_by, token },
      ...options,
    }),
    {
      schema: [formFieldSchema],
    }
  );
  yield put(doneIndicator(TYPE));
}
export function* watch() {
  yield takeEvery(WATCH_TYPE, fetchFormFields);
}
export default function* root() {
  yield all([fork(watch)]);
}
