import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams, useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import {
  useEventTimeSlotsQuery,
  useUpdateEventTimeSlotsMutation,
} from 'api/eventTimeSlots';
import { isEqual } from 'lodash';
import moment from 'moment';

// final form
import { Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';

// material-ui
import { Grid, Typography, Snackbar } from '@material-ui/core';
import { AddCircle, Edit, OpenInNew } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';

// components
import OutlinedCard from 'components/OutlinedCard';
import Button from 'components/Button';
import ActionBar from 'components/ActionBar';
import EventDateTimeSlots from './EventDateTimeSlots';
import CreateEventDateModal from './CreateEventDateTimeSlotsModal';
import getSliceEntityById from 'store/selectors/getSliceEntityById';
import { Alert } from '@material-ui/lab';

//helpers
import { toInt10 } from 'helpers/transform';

export interface FormEventTimeSlot {
  id: number;
  startTime: string;
  endTime: string;
  description?: string;
  guestsLimit?: number;
}

export default function FormSettings() {
  const [opened, setOpened] = useState(false);
  const [formStatus, setFormStatus] = useState<
    null | 'startUpdating' | 'shouldBeRefetched'
  >(null);
  const classes = useStyles();
  const { eventId } = useParams<{ eventId: string }>();
  const history = useHistory();
  const event = useSelector(getSliceEntityById('event', eventId));
  const {
    data: eventTimeSlots = [],
    isLoading: isLoadingEventTimeSlots,
    isFetching: isFetchingEventTimeSlots,
    refetch: refetchEventTimeSlots,
  } = useEventTimeSlotsQuery(Number(eventId));
  const {
    isLoading,
    isSuccess,
    isError,
    error,
    mutate: updateEventTimeSlots,
    reset: resetUpdateMutation,
  } = useUpdateEventTimeSlotsMutation();
  const eventDatesData = useMemo(() => {
    const newEventDatesData: { [key: string]: FormEventTimeSlot[] } = {};
    const sortedEventTimeSLots = eventTimeSlots.sort((left, right) =>
      left.startDate.diff(right.startDate)
    );
    for (const timeSlot of sortedEventTimeSLots) {
      const { id, startDate, endDate, description, guestsLimit } = timeSlot;
      const timeSlotDate = startDate.format('MMM DD, YYYY');
      const formEventTimeSlot = {
        id,
        startTime: startDate.format(),
        endTime: startDate
          .clone()
          .set({
            hour: moment(endDate).get('hour'),
            minute: moment(endDate).get('minute'),
          })
          .format(),
        guestsLimit: guestsLimit ? toInt10(guestsLimit) : guestsLimit,
        description,
      };
      if (!newEventDatesData[timeSlotDate])
        newEventDatesData[timeSlotDate] = [formEventTimeSlot];
      else newEventDatesData[timeSlotDate].push(formEventTimeSlot);
    }
    for (const key in newEventDatesData)
      newEventDatesData[key] = newEventDatesData[key].sort(
        (left, right) => left.id - right.id
      );

    return newEventDatesData;
  }, [eventTimeSlots]);

  const handleFormSubmit = useCallback(
    (values: { [key: string]: FormEventTimeSlot[] }) => {
      const updatedTimeSlots: {
        eventTimeSlotId: number;
        startDate: string;
        endDate: string;
        description?: string;
        guestsLimit?: number;
      }[] = [];
      for (const eventDate in values) {
        if (!isEqual(values[eventDate], eventDatesData[eventDate])) {
          const updatedDateTimeSlots = values[eventDate]
            .filter(
              ets => !eventDatesData[eventDate].find(item => isEqual(item, ets))
            )
            .map(
              ({ id, startTime, endTime, description = '', guestsLimit }) => ({
                eventTimeSlotId: id,
                startDate: moment(startTime)
                  .utc()
                  .toISOString(),
                endDate: moment(startTime)
                  .clone()
                  .add(Number(moment(startTime) > moment(endTime)), 'days')
                  .set({
                    hour: moment(endTime).get('hour'),
                    minute: moment(endTime).get('minute'),
                  })
                  .utc()
                  .toISOString(),
                description,
                guestsLimit,
              })
            );
          updatedTimeSlots.push(...updatedDateTimeSlots);
        }
      }
      setFormStatus('startUpdating');
      updateEventTimeSlots(updatedTimeSlots);
    },
    [eventDatesData, updateEventTimeSlots]
  );

  const updateDataOnPage = () => {
    setFormStatus('shouldBeRefetched');
  };

  useEffect(() => {
    if (isSuccess) {
      setFormStatus(newFormStatus =>
        newFormStatus === 'startUpdating' ? 'shouldBeRefetched' : newFormStatus
      );
    }
  }, [isSuccess]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (
      !isLoadingEventTimeSlots &&
      !isFetchingEventTimeSlots &&
      formStatus === 'shouldBeRefetched'
    ) {
      refetchEventTimeSlots();
      setFormStatus(null);
    }
  }, [formStatus, isLoadingEventTimeSlots, isFetchingEventTimeSlots]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Form
        onSubmit={handleFormSubmit}
        mutators={{ ...arrayMutators }}
        keepDirtyOnReinitialize={isLoading}
        initialValues={eventDatesData}
        initialValuesEqual={isEqual}
        render={({ handleSubmit, values, pristine, form: { reset } }) => (
          <Grid
            component='form'
            onSubmit={handleSubmit}
            container
            justifyContent='flex-start'
            spacing={2}
          >
            <Grid item xs={9}>
              <OutlinedCard>
                <Grid container alignItems='center' spacing={2}>
                  <Grid item xs={8}>
                    <Typography>Dates</Typography>
                  </Grid>
                  <Grid item xs={4} className={classes.buttonContainer}>
                    <Button
                      startIcon={<AddCircle />}
                      onClick={() => setOpened(true)}
                    >
                      New Date
                    </Button>
                  </Grid>
                  {Object.keys(values).map(eventDate => (
                    <Grid item xs={12} key={eventDate}>
                      <FieldArray<FormEventTimeSlot>
                        name={eventDate}
                        isEqual={isEqual}
                      >
                        {fieldArrayRenderProps => (
                          <EventDateTimeSlots
                            fieldArrayRenderProps={fieldArrayRenderProps}
                            eventDate={eventDate}
                            isOnLastDateTimeSlot={Boolean(
                              Object.keys(values).length === 1
                            )}
                            updateDataOnPage={updateDataOnPage}
                          />
                        )}
                      </FieldArray>
                    </Grid>
                  ))}
                </Grid>
              </OutlinedCard>
            </Grid>
            <Grid item xs={3}>
              <Grid container spacing={1}>
                <Grid item xs={7}>
                  <Button
                    variant='contained'
                    fullWidth
                    color='primary'
                    endIcon={<Edit />}
                    onClick={() => history.push(`/forms/${event.formId}/edit`)}
                  >
                    Edit Form
                  </Button>
                </Grid>

                <Grid item xs={7}>
                  <Button
                    fullWidth
                    variant='outlined'
                    endIcon={<OpenInNew />}
                    onClick={() => history.push(`/forms/${event.formId}/view`)}
                  >
                    View Form
                  </Button>
                </Grid>
              </Grid>
            </Grid>

            <ActionBar
              open={!pristine || isLoading || isSuccess}
              message='Save your changes?'
              onClose={() => {
                resetUpdateMutation();
              }}
              // @ts-expect-error ts-migrate(2322) FIXME: Type 'number | null' is not assignable to type 'nu... Remove this comment to see the full error message
              autoHideDuration={isSuccess ? 1500 : null}
            >
              <Button disabled={pristine} onClick={reset}>
                Cancel
              </Button>
              <Button
                variant='outlined'
                color='primary'
                type='submit'
                disabled={pristine || isLoading}
                loading={isLoading}
                success={isSuccess}
                fail={isError}
              >
                Save
              </Button>
            </ActionBar>
            <Snackbar
              open={Boolean(error?.response?.data.msg)}
              autoHideDuration={3000}
              onClose={() => resetUpdateMutation()}
              className={classes.snackbarBottom}
            >
              <Alert severity='error'>{error?.response?.data.msg}</Alert>
            </Snackbar>
          </Grid>
        )}
      />
      <CreateEventDateModal
        opened={opened}
        onClose={() => setOpened(false)}
        usedDates={Object.keys(eventDatesData)}
        updateDataOnPage={updateDataOnPage}
      />
    </>
  );
}

const useStyles = makeStyles(theme => {
  return {
    accordionContainer: {
      borderTop: `1px solid ${(theme.palette.background as any).outline}`,
    },
    buttonContainer: { textAlign: 'right' },
    snackbarBottom: { bottom: 48 },
  };
});
