import {
  VOTE_CREATE_REQUEST,
  VOTE_DELETE_REQUEST,
  VOTE_FETCH_REQUEST,
  VOTE_UPDATE_REQUEST,
} from 'store/constants/voteTypes';

export function createVoteAction({ leadId, fields, formName }: any) {
  return {
    type: VOTE_CREATE_REQUEST,
    payload: { leadId, fields, formName },
  };
}

export function deleteVotesAction({
  allSelected,
  leadIds,
  filters,
  metaId,
}: any) {
  return {
    type: VOTE_DELETE_REQUEST,
    payload: { allSelected, filters, leadIds, metaId },
  };
}

export function fetchVotesAction({ leadId, page }: any) {
  return {
    type: VOTE_FETCH_REQUEST,
    payload: { leadId, page },
  };
}

export function updateVoteAction({ id, leadId, fields, formName }: any) {
  return {
    type: VOTE_UPDATE_REQUEST,
    payload: { id, leadId, fields, formName },
  };
}
