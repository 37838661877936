import { Styles } from 'assets/types';

const phoneLineCommunicationStyles: Styles = () => ({
  cardContent: {
    paddingLeft: 24,
    paddingRight: 24,
  },
  exportButton: {
    textAlign: 'right',
  },
});

export default phoneLineCommunicationStyles;
