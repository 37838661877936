import React from 'react';
import PropTypes from 'prop-types';

// MUI components
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

import SuperAvatar from 'components/SuperAvatar';

const StatItem = function({
  title,
  subtitle,
  icon,
  gutterBottom,
  loading,
}: any) {
  const classes = useStyles();

  return (
    <Grid item xs={12} className={gutterBottom && classes.statItem}>
      <Grid container spacing={2} alignItems='center'>
        <Grid item>
          <SuperAvatar background='gradient'>{icon}</SuperAvatar>
        </Grid>
        <Grid item>
          {loading ? (
            <Skeleton height={25} width={70} />
          ) : (
            <Typography variant='subtitle1' className={classes.semibold}>
              {title}
            </Typography>
          )}
          {loading ? (
            <Skeleton height={25} width={100} />
          ) : (
            <Typography variant='caption' color='textSecondary'>
              {subtitle}
            </Typography>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

StatItem.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  icon: PropTypes.instanceOf(Object),
  loading: PropTypes.bool,
  gutterBottom: PropTypes.bool,
};

const useStyles = makeStyles(() => ({
  statItem: {
    marginBottom: 20,
  },
  semibold: {
    fontWeight: 600,
  },
}));

export default StatItem;
