import React, { useEffect, useState } from 'react';
import validate from 'validate.js';
import { Field, Form } from 'react-final-form';

import { useSelector, useDispatch } from 'react-redux';

// material-ui
import { Box, Grid } from '@material-ui/core';
import { Sms, AddCircle, Save } from '@material-ui/icons';

// components
import Button from 'components/Button';
import DazzlingDialog from 'components/DazzlingDialog';
import TextField from 'components/TextField';

// selectors
import getCurrentGroupId from 'store/selectors/getCurrentGroupId';

// actions
import { createMessageAction } from 'store/actions/messageActions';

const FORM_NAME = 'sendTemplatedMessage';

export default function TemplatedMessageButton({ leadId }: { leadId: number }) {
  const [open, setOpen] = useState(false);
  const toggleDialog = () => setOpen(open => !open);
  return (
    <>
      <Box clone marginTop={'18px'}>
        <Button
          fullWidth
          variant='outlined'
          startIcon={<Sms />}
          onClick={toggleDialog}
        >
          Send Text
        </Button>
      </Box>
      <TemplatedMessageDialog
        open={open}
        onClose={toggleDialog}
        leadId={leadId}
      />
    </>
  );
}

const SCHEMA = {
  message: {
    length: { maximum: 1024 },
    presence: true,
  },
};

function TemplatedMessageDialog({
  open,
  onClose,
  leadId,
}: {
  open: boolean;
  leadId: number;
  onClose: () => void;
}) {
  const localTemplate = localStorage.getItem('messageTemplate') || '';
  const [template, setTemplate] = useState(localTemplate);
  useEffect(() => localStorage.setItem('messageTemplate', template), [
    template,
  ]);
  const dispatch = useDispatch();
  const currentGroupId = useSelector(getCurrentGroupId);
  const handleFormSubmit = (fields: { message: string }) => {
    dispatch(
      createMessageAction({
        body: fields.message,
        leadIds: [leadId],
        groupId: currentGroupId,
        formName: FORM_NAME,
      })
    );
  };
  const validateFormFields = (fields: { message: string }) =>
    validate(fields, SCHEMA);
  return (
    <Form
      onSubmit={handleFormSubmit}
      initialValues={{ message: template }}
      validate={validateFormFields}
      render={({
        handleSubmit,
        invalid,
        values: { message },
        form: { reset, change },
      }) => (
        <DazzlingDialog
          open={open}
          onAccept={handleSubmit}
          handleClose={() => {
            onClose();
            reset();
          }}
          headerProps={{
            title: 'Send Text Message',
            subtitle: 'Manage your template and send a message',
            icon: Sms,
          }}
          acceptLabel='Send'
          disabled={invalid}
          formName={FORM_NAME}
        >
          <Grid container spacing={2}>
            <Grid item xs>
              <Button
                variant='outlined'
                startIcon={<AddCircle />}
                onClick={() => change('message', message + '{{ first_name }}')}
              >
                Insert First Name
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Field
                name='message'
                label='Message'
                multiline
                rows='4'
                placeholder='Type something'
                component={TextField}
                fullWidth
                margin='normal'
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs>
              <Button
                variant='outlined'
                disabled={template === message}
                startIcon={<Save />}
                onClick={() => setTemplate(message)}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </DazzlingDialog>
      )}
    />
  );
}
