import {
  GROUP_FETCH_REQUEST,
  GROUP_FETCH_BY_ID_REQUEST,
  GROUP_CREATE_REQUEST,
  GROUP_UPDATE_REQUEST,
  GROUP_DELETE_REQUEST,
  GROUP_ADD_DASHBOARD_LINK_REQUEST,
  GROUP_REMOVE_DASHBOARD_LINK_REQUEST,
  CURRENT_GROUP_SET,
  CURRENT_GROUP_CLEAR,
  CURRENT_GROUP_FETCH_REQUEST,
  CURRENT_GROUP_UPDATE_REQUEST,
  GROUP_CLEAR,
  GROUP_UNLINK_REQUEST,
} from 'store/constants/groupTypes';

// helpers
import getGroupType from 'helpers/getGroupType';

export function fetchGroups({
  appendResult,
  fetchParents = false,
  ids,
  labelIds,
  latestActivityEnd,
  latestActivityStart,
  mergeItems,
  orderBy,
  page,
  parentId,
  parentIds = [],
  search = null,
  type,
  useOrderWhenIds = false,
  token,
}: any) {
  return {
    type: GROUP_FETCH_REQUEST,
    payload: {
      appendResult,
      fetchParents,
      ids,
      labelIds,
      latestActivityEnd,
      latestActivityStart,
      mergeItems,
      orderBy,
      page,
      parentId,
      parentIds,
      search,
      type: getGroupType(type),
      useOrderWhenIds,
      token,
    },
  };
}

export function fetchGroupById({ id, fetchParents = false }: any) {
  return {
    type: GROUP_FETCH_BY_ID_REQUEST,
    payload: {
      id,
      fetchParents,
    },
  };
}

export function createGroup({
  formName,
  name,
  campus,
  gender,
  focus,
  displayName,
  type,
  parents,
  redirectToGroup,
}: any) {
  return {
    type: GROUP_CREATE_REQUEST,
    payload: {
      formName,
      name,
      campus,
      gender,
      focus,
      displayName,
      type: getGroupType(type),
      parents,
      redirectToGroup,
    },
  };
}

export function updateGroup({
  formName,
  id,
  name,
  displayName,
  currentActiveMembers,
  type,
  voteMethodId,
  voteVisibility,
  parents,
}: any) {
  return {
    type: GROUP_UPDATE_REQUEST,
    payload: {
      formName,
      id,
      name,
      displayName,
      currentActiveMembers,
      type: getGroupType(type),
      voteMethodId,
      voteVisibility,
      parents,
    },
  };
}

export function deleteGroup({ id, formName, type }: any) {
  return {
    type: GROUP_DELETE_REQUEST,
    payload: { id, formName, type: getGroupType(type) },
  };
}

export function addDashboardLink({ parentId, childId }: any) {
  return {
    type: GROUP_ADD_DASHBOARD_LINK_REQUEST,
    payload: {
      parentId,
      childId,
    },
  };
}

export function removeDashboardLink({ parentId, childId }: any) {
  return {
    type: GROUP_REMOVE_DASHBOARD_LINK_REQUEST,
    payload: {
      parentId,
      childId,
    },
  };
}

export function setCurrentGroup({
  groupId,
  getGroupByType,
  skipNavigate = false,
}: any) {
  return {
    type: CURRENT_GROUP_SET,
    payload: { groupId, getGroupByType, skipNavigate },
  };
}

export function clearCurrentGroup() {
  return {
    type: CURRENT_GROUP_CLEAR,
  };
}

export function fetchCurrentGroup({ id, type }: any) {
  return {
    type: CURRENT_GROUP_FETCH_REQUEST,
    payload: {
      id,
      type,
    },
  };
}

export function updateCurrentGroup({ formName, fields }: any) {
  return {
    type: CURRENT_GROUP_UPDATE_REQUEST,
    payload: {
      formName,
      fields,
    },
  };
}

export function clearGroups() {
  return {
    type: GROUP_CLEAR,
  };
}

export function groupUnlink({ parentId, childId, type, formName }: any) {
  return {
    type: GROUP_UNLINK_REQUEST,
    payload: {
      parentId,
      childId,
      type: getGroupType(type),
      formName,
    },
  };
}
