import {
  FILE_CREATE_AND_UPLOAD_REQUEST,
  FILE_CREATE_AND_INVITE_REQUEST,
  FILE_CREATE_AND_IMPORT_LEADS_REQUEST,
  FILE_FETCH_REQUEST,
  FILE_CLEAR,
} from 'store/constants/fileTypes';

export function createAndUploadFile({ file, formName, token }: any) {
  return {
    type: FILE_CREATE_AND_UPLOAD_REQUEST,
    payload: { file, formName, token },
  };
}

export function createFileAndInvite({
  file,
  formName,
  roleId,
  groupId,
  childGroupId,
}: any) {
  return {
    type: FILE_CREATE_AND_INVITE_REQUEST,
    payload: { file, formName, roleId, groupId, childGroupId },
  };
}
export function createFileAndImportLeads({
  file,
  formName,
  groupId,
  shouldSkipDuplicates,
}: any) {
  return {
    type: FILE_CREATE_AND_IMPORT_LEADS_REQUEST,
    payload: { file, formName, groupId, shouldSkipDuplicates },
  };
}

export function fetchFiles(fileIds: any, token: any) {
  return {
    type: FILE_FETCH_REQUEST,
    payload: { fileIds, token },
  };
}

export function clearFiles() {
  return {
    type: FILE_CLEAR,
  };
}
