import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { doneIndicator, success } from 'store/actions/httpActions';

// api
import client from 'sources/api';

// constants
import {
  FORM_FEE_DELETE,
  FORM_FEE_DELETE_REQUEST,
} from 'store/constants/formFeeTypes';

// schemas
import { feeSchema } from 'store/schemas/feeSchema';

// helpers
import httpSaga from 'store/sagas/httpSaga';
import { Map } from 'immutable';

const WATCH_TYPE = FORM_FEE_DELETE_REQUEST;
const TYPE = FORM_FEE_DELETE;

export function* deleteFormFeeSaga(action: any) {
  const {
    payload: { formName, groupId, formId, feeId },
  } = action;

  // @ts-ignore
  yield* httpSaga(
    TYPE,
    call(client.delete, '/form_fees', {
      params: {
        group_id: groupId,
        form_id: formId,
      },
    }),
    {
      formName,
      schema: feeSchema,
      dispatchSuccess: false,
    }
  );

  yield put(success(TYPE, Map({ id: feeId })));
  yield put(doneIndicator(TYPE));
}

export function* watch() {
  yield takeEvery(WATCH_TYPE, deleteFormFeeSaga);
}

export default function* root() {
  yield all([fork(watch)]);
}
