import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { usePrevious } from 'helpers/hooks/usePrevious';
import { Skeleton } from '@material-ui/lab';
import { Box, Button, Grid, Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { ArrowUpward } from '@material-ui/icons';
import Comment from './Comment';
import CreateCommentInput from './CreateCommentInput';
// actions
import { fetchCommentsAction } from 'store/actions/commentActions';
// selectors
import getSliceState from 'store/selectors/getSliceState';
import getLoadingFromState from 'store/selectors/getLoadingFromState';
import getStatusFromState from 'store/selectors/getStatusFromState';
type CommentsProps = {
  activityId: number;
  total: number;
};
export default function Comments({ activityId, total }: CommentsProps) {
  const [page, setPage] = useState(1);
  const dispatch = useDispatch();
  const comments = useSelector(getSliceState('comment'));
  const metaId = `fetchComments-${activityId}`;
  const loading = useSelector(getLoadingFromState('comment', metaId, false));
  const status = useSelector(getStatusFromState('comment'));
  const [currentTotal, setCurrentTotal] = useState(total);
  const [somethingWentWrong, setSomethingWentWrong] = useState(false);
  const prevLoading = usePrevious(loading);
  const feedItemComments = (comments as any).data.filter(
    (comment: any) => comment.activityId === activityId
  );
  const buttonText = constructButtonText(
    currentTotal - feedItemComments.length
  );
  const fetchComments = () =>
    dispatch(fetchCommentsAction({ activityId, page, metaId }));
  const handleCreate = useCallback(() => setCurrentTotal(total => total + 1), [
    setCurrentTotal,
  ]);
  const handleDelete = useCallback(() => setCurrentTotal(total => total - 1), [
    setCurrentTotal,
  ]);
  const handleClose = () => setSomethingWentWrong(false);
  useEffect(() => {
    if (prevLoading && !loading) setPage((comments as any).page);
  }, [prevLoading, loading, comments]);
  useEffect(() => {
    if (prevLoading && !loading) setPage((comments as any).page);
  }, [prevLoading, loading, comments]);
  useEffect(() => {
    if (status === 'error') setSomethingWentWrong(true);
  }, [status]);
  return (
    <>
      <Grid container direction='column' spacing={2}>
        {feedItemComments.length > 0 && (
          <Grid item>
            <Grid container alignItems='center' spacing={1}>
              {total - feedItemComments.length > 0 && (
                <Grid item>
                  <Button
                    size='small'
                    endIcon={<ArrowUpward />}
                    onClick={fetchComments}
                  >
                    {buttonText}
                  </Button>
                </Grid>
              )}
              <Grid item xs>
                <Box height='1px' bgcolor='background.lightPaper' />
              </Grid>
            </Grid>
          </Grid>
        )}
        <Grid item>
          {loading && (
            // @ts-expect-error ts-migrate(2786) FIXME: 'CommentsSkeleton' cannot be used as a JSX compone... Remove this comment to see the full error message
            <CommentsSkeleton
              numSkeletons={currentTotal - feedItemComments.length}
            />
          )}
          {feedItemComments.map((commentProps: any) => (
            <Box
              marginTop={feedItemComments.length === 1 && !loading ? 0.5 : 2.5}
              key={commentProps.id}
            >
              <Comment {...commentProps} onDelete={handleDelete} />
            </Box>
          ))}
        </Grid>
        <Grid item>
          <CreateCommentInput activityId={activityId} onCreate={handleCreate} />
        </Grid>
      </Grid>
      <Snackbar
        open={somethingWentWrong}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity='error'>
          Something went wrong, please try again...
        </Alert>
      </Snackbar>
    </>
  );
}
const CommentsSkeleton = ({ numSkeletons }: any) =>
  Array.from({ length: numSkeletons > 5 ? 5 : numSkeletons }, (_, i) => (
    <Grid
      container
      key={i}
      spacing={2}
      alignItems='center'
      justifyContent={i % 2 !== 0 ? 'flex-end' : 'flex-start'}
    >
      {[
        <Grid item key='avatar'>
          <Skeleton variant='circle' width={40} height={40} />
        </Grid>,
        <Grid item xs={4} key='comment'>
          <Skeleton height={40} />
        </Grid>,
      ][i % 2 !== 0 ? 'reverse' : 'slice']()}
    </Grid>
  ));
const constructButtonText = (previousCommentsCount: any) => {
  const parts = ['View'];
  if (previousCommentsCount > 1 && previousCommentsCount < 10)
    parts.push(previousCommentsCount);
  parts.push('previous');
  if (previousCommentsCount > 1) parts.push('comments');
  else parts.push('comment');
  return parts.join(' ');
};
