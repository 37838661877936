import { all } from 'redux-saga/effects';

// sagas
import createCommunicationOverviewReportSaga from './createCommunicationOverviewReportSaga';
import createEventGuestsMessagesSaga from './createEventGuestsMessagesSaga';
import createExportSaga from './createExportSaga';
import createGroupActivityReportSaga from './createGroupActivityReportSaga';
import createGroupLeaderboardReportSaga from './createGroupLeaderboardReportSaga';
import createGroupStatusReportSaga from './createGroupStatusReportSaga';
import createLeadCommunicationReportSaga from './createLeadCommunicationReportSaga';
import createLeadsInfoReportSaga from './createLeadsInfoReportSaga';
import createLeadsMainContactReportSaga from './createLeadsMainContactReportSaga';
import createLeadsOverviewReportSaga from './createLeadsOverviewReportSaga';
import createMemberCommunicationReportSaga from './createMemberCommunicationReportSaga';
import createMemberLeaderboardReportSaga from './createMemberLeaderboardReportSaga';
import createMilestoneStatusReportSaga from './createMilestoneStatusReportSaga';
import createMilestoneUsageReportSaga from './createMilestoneUsageReportSaga';
import createRecruitmentActivityMilestonesReportSaga from './createRecruitmentActivityMilestonesReportSaga';
import createRecruitmentActivityReportSaga from './createRecruitmentActivityReportSaga';
import createRecruitmentActivityTagsReportSaga from './createRecruitmentActivityTagsReportSaga';
import createTagStatusReportSaga from './createTagStatusReportSaga';
import createTagUsageReportSaga from './createTagUsageReportSaga';
import fetchApiTaskSaga from './fetchApiTaskSaga';
import reportNewMembersSaga from './reportNewMembersSaga';
import createTaskReportSaga from 'store/sagas/apiTask/createTaskReportSaga';
import createGroupStatusByGroupIdReportSaga from 'store/sagas/apiTask/createGroupStatusByGroupIdReportSaga';

const apiTaskSaga = function* rootSaga() {
  yield all([
    createCommunicationOverviewReportSaga(),
    createEventGuestsMessagesSaga(),
    createExportSaga(),
    createGroupActivityReportSaga(),
    createGroupLeaderboardReportSaga(),
    createGroupStatusReportSaga(),
    createLeadCommunicationReportSaga(),
    createLeadsInfoReportSaga(),
    createLeadsMainContactReportSaga(),
    createLeadsOverviewReportSaga(),
    createMemberCommunicationReportSaga(),
    createMemberLeaderboardReportSaga(),
    createMilestoneStatusReportSaga(),
    createMilestoneUsageReportSaga(),
    createRecruitmentActivityMilestonesReportSaga(),
    createRecruitmentActivityReportSaga(),
    createRecruitmentActivityTagsReportSaga(),
    createTagStatusReportSaga(),
    createTagUsageReportSaga(),
    fetchApiTaskSaga(),
    reportNewMembersSaga(),
    createTaskReportSaga(),
    createGroupStatusByGroupIdReportSaga(),
  ]);
};

export default apiTaskSaga;
