import ForgotPasswordForm from './ForgotPasswordForm';
import { connect } from 'react-redux';

// action creators
import {
  createForgotPassword,
  updateForgotPassword,
} from 'store/actions/forgotPasswordActions';

// selectors
import { hasSubmitFailed, isSubmitting } from 'store/selectors/formSelectors';
import getErrorMessage from 'store/selectors/getErrorMessage';
import getResponseMessage from 'store/selectors/getResponseMessage';

const mapDispatch = { createForgotPassword, updateForgotPassword };

const mapState = (state: any) => {
  return {
    successMessage: getResponseMessage('forgotPassword')(state),
    errorMessage: getErrorMessage('forgotPassword')(state),
    forgotPasswordFormSubmitting: isSubmitting('forgotPasswordForm')(state),
    forgotPasswordFormSubmitFailed: hasSubmitFailed('forgotPasswordForm')(
      state
    ),
  };
};

export default connect(mapState, mapDispatch)(ForgotPasswordForm);
