import React, { useCallback, useState, memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { isEqual } from 'lodash';
// material-ui
import {
  CircularProgress,
  Fade,
  Grid,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/styles';
// components
import IconButton from 'components/IconButton';
const CardActionItem = function({
  TextFieldProps,
  actions,
  bold,
  displayActions,
  editable,
  emptyLabel,
  hover,
  label,
  loading,
  onBlur,
  onChange,
  onClick,
  startElement,
  tooltipTitle,
  typographyVariant,
  variant,
}: any) {
  const classes = useStyles();
  const theme = useTheme();
  const isExtraSmall = useMediaQuery((theme as any).breakpoints.only('xs'));
  const [editing, setEditing] = useState(false);
  const [inputValue, setInputValue] = useState(label);

  const handleClick = useCallback(
    e => {
      if (editable && !editing) {
        setInputValue(label);
        setEditing(true);
      }
      onClick(e);
    },
    [editable, editing, label, onClick]
  );

  const handleChange = useCallback(
    ({ target: { value } }) => {
      let newValue = value;
      if (onChange) {
        newValue = onChange(value);
      }
      setInputValue(newValue);
    },
    [onChange]
  );

  const handleBlur = useCallback(() => {
    setEditing(false);
    onBlur(inputValue);
  }, [onBlur, inputValue]);

  const handleKeyDown = useCallback(
    event => {
      if (event.key === 'Escape' && editing) {
        setEditing(false);
        setInputValue(TextFieldProps.defaultValue);
      }
    },
    [TextFieldProps.defaultValue, editing]
  );

  const handleKeypress = useCallback(
    event => {
      if (event.key === 'Enter' && editing) {
        handleBlur();
      }
    },
    [editing, handleBlur]
  );
  return (
    <Tooltip
      title={tooltipTitle}
      disableHoverListener={!tooltipTitle}
      disableTouchListener={!tooltipTitle}
      disableFocusListener={!tooltipTitle}
      arrow
    >
      <Grid container alignItems='center'>
        <Grid
          item
          xs={12}
          id='container'
          onClick={handleClick}
          className={classNames(
            classes.itemContainer,
            variant === 'outlined' && classes.outlined,
            variant === 'contained' && classes.contained,
            hover && classes.hover,
            hover && !editable ? classes.pointerCursor : classes.textCursor,
            !startElement && classes.itemLeftPadding
          )}
        >
          <Grid
            container
            alignItems='center'
            justifyContent='center'
            spacing={1}
          >
            {editing ? (
              <Grid
                item
                xs={10}
                onKeyPress={handleKeypress}
                onKeyDown={handleKeyDown}
              >
                <TextField
                  id='textField'
                  className={classes.textFieldContainer}
                  InputProps={{ classes: { root: classes.textInput } }}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={inputValue}
                  {...TextFieldProps}
                />
              </Grid>
            ) : (
              <>
                {startElement && (
                  <Grid item xs={2} md={3} lg={2} id='startElement'>
                    <div className={classes.startElement}>{startElement}</div>
                  </Grid>
                )}
                <Grid item xs zeroMinWidth>
                  <Typography
                    noWrap
                    color={!label ? 'textSecondary' : 'initial'}
                    variant={typographyVariant}
                    className={bold ? classes.bold : ''}
                  >
                    {label ? label : emptyLabel}
                  </Typography>
                </Grid>

                {loading && (
                  <Grid item xs={3} sm={2} id='circularProgress'>
                    <CircularProgress size={20} disableShrink />
                  </Grid>
                )}
                {!loading && !actions.length && <Grid item xs />}

                {!loading &&
                  actions.map((action: any) => (
                    <Grid
                      item
                      xs={2}
                      md={1}
                      className={classes.iconContainer}
                      key={action.id}
                      ref={action.ref}
                    >
                      <Fade in={displayActions || isExtraSmall}>
                        <IconButton
                          id={action.id}
                          onClick={action.onClick}
                          className={classes.iconButton}
                        >
                          <Tooltip
                            arrow
                            title={action.tooltipTitle}
                            disableHoverListener={!action.tooltipTitle}
                            disableTouchListener={!action.tooltipTitle}
                            disableFocusListener={!action.tooltipTitle}
                          >
                            {action.icon}
                          </Tooltip>
                        </IconButton>
                      </Fade>
                    </Grid>
                  ))}
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Tooltip>
  );
};
CardActionItem.propTypes = {
  TextFieldProps: PropTypes.instanceOf(Object),
  actions: PropTypes.instanceOf(Array),
  bold: PropTypes.bool,
  displayActions: PropTypes.bool,
  editable: PropTypes.bool,
  emptyLabel: PropTypes.string,
  hover: PropTypes.bool,
  label: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  startElement: PropTypes.instanceOf(Object),
  tooltipTitle: PropTypes.string,
  typographyVariant: PropTypes.string,
  variant: PropTypes.oneOf(['outlined', 'contained']),
};
CardActionItem.defaultProps = {
  emptyLabel: 'None found',
  actions: [],
  tooltipTitle: '',
  onBlur: () => {},
  onClick: () => {},
  TextFieldProps: {
    id: 'textField',
    fullWidth: true,
    autoFocus: true,
  },
  typographyVariant: 'body2',
};
const useStyles = makeStyles(theme => {
  const itemSize = 42;
  const iconSize = 35;
  return {
    itemContainer: {
      display: 'flex',
      minHeight: itemSize,
      paddingRight: 16,
      minWidth: 0,
      transition: 'all ease 0.3s',
    },
    outlined: {
      border: `1px solid ${(theme as any).palette.background.outline}`,
      borderRadius: 4,
      marginBottom: 10,
    },
    contained: {
      borderTop: `1px solid ${(theme as any).palette.background.outline}`,
    },
    itemLeftPadding: {
      paddingLeft: 16,
    },
    compactContainer: {
      [(theme as any).breakpoints.only('xs')]: {
        marginRight: 0,
      },
      [(theme as any).breakpoints.only('sm')]: {
        marginRight: '30%',
      },
      [(theme as any).breakpoints.only('md')]: {
        marginRight: 0,
      },
      [(theme as any).breakpoints.only('lg')]: {
        marginRight: '10%',
      },
      [(theme as any).breakpoints.only('xl')]: {
        marginRight: '3%',
      },
    },
    iconContainer: {
      textAlign: 'right',
    },
    iconButton: {
      height: iconSize,
      width: iconSize,
      minHeight: iconSize,
      minWidth: iconSize,
    },
    hover: {
      '&:hover': {
        backgroundColor: (theme as any).palette.background.lightPaper,
        transition: 'all ease 0.3s',
      },
    },
    pointerCursor: {
      cursor: 'pointer',
    },
    textCursor: {
      cursor: 'text',
    },
    textFieldContainer: {
      marginTop: 8,
      marginBottom: 8,
    },
    textInput: {
      fontSize: '0.875rem',
    },
    bold: {
      fontWeight: 'bold',
    },
    startElement: {
      alignItems: 'center',
      borderRight: `1px solid ${(theme as any).palette.background.outline}`,
      display: 'flex',
      minHeight: itemSize,
      minWidth: itemSize,
      justifyContent: 'center',
    },
  };
});
export default memo(CardActionItem, isEqual);
