import { all } from 'redux-saga/effects';

// sagas
import createEventSaga from './createEventGuestsSaga';
import updateEventGuestSaga from './updateEventGuestSaga';

const eventGuestSaga = function* rootSaga() {
  yield all([createEventSaga(), updateEventGuestSaga()]);
};

export default eventGuestSaga;
