import { Map } from 'immutable';
// constants
import {
  FORGOT_PASSWORD_CREATE,
  FORGOT_PASSWORD_UPDATE,
} from 'store/constants/forgotPasswordTypes';
// helpers
import httpReducer from './helpers/httpReducer';

export default function reduceForgotPassword(state = Map(), action = {}) {
  switch ((action as any).type) {
    case FORGOT_PASSWORD_CREATE:
      return httpReducer(state, action);
    case FORGOT_PASSWORD_UPDATE:
      return httpReducer(state, action);
    default:
      return state;
  }
}
