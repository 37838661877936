import React from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { roles } from 'helpers/getRoles';
// material-ui
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
// components
import IconButton from 'components/IconButton';
// icons
import RejectIcon from '@material-ui/icons/HighlightOff';
import AcceptIcon from '@material-ui/icons/Check';
// selectors
import getCurrentGroupId from 'store/selectors/getCurrentGroupId';
import getLoadingFromState from 'store/selectors/getLoadingFromState';
// action creators
import { updateRequest, deleteRequest } from 'store/actions/requestActions';
const ACCEPTED = 1;
const RequestItem = function({ request, disableAccept }: any) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const updateMetaId = `update-${request.get('id')}`;
  const deleteMetaId = `delete-${request.get('id')}`;
  const { requestUpdating, requestDeleting, currentGroupId } = useSelector(
    state => ({
      requestUpdating: getLoadingFromState(
        'request',
        updateMetaId,
        false
      )(state),
      requestDeleting: getLoadingFromState(
        'request',
        deleteMetaId,
        false
      )(state),
      currentGroupId: getCurrentGroupId(state),
    })
  );
  const handleReject = () => {
    dispatch(deleteRequest({ id: request.get('id') }));
  };
  const handleAccept = () => {
    dispatch(
      updateRequest({
        id: request.get('id'),
        status: ACCEPTED,
        roleId: roles.member,
        groupId: currentGroupId,
      })
    );
  };
  return (
    <Grid item xs={12}>
      <Grid container>
        <Grid item xs={8} sm={4}>
          <Typography variant='subtitle1' className={classes.semibold}>
            {`${request.getIn(['account', 'firstName'])} ${request.getIn([
              'account',
              'lastName',
            ])}`}
          </Typography>

          <Typography
            variant='subtitle2'
            className={classNames(classes.subtitle, classes.semibold)}
          >
            {request.get('email')}
          </Typography>
        </Grid>

        <Grid item xs={4} sm={8}>
          <Grid container>
            <Grid item xs={6} sm={1}>
              <IconButton
                onClick={handleAccept}
                id='acceptButton'
                disabled={disableAccept}
                loading={requestUpdating}
              >
                <AcceptIcon />
              </IconButton>
            </Grid>

            <Grid item xs={6} sm={11}>
              <IconButton
                onClick={handleReject}
                id='rejectButton'
                loading={requestDeleting}
              >
                <RejectIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
RequestItem.propTypes = {
  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'typeof Map' is not assignable to... Remove this comment to see the full error message
  request: PropTypes.instanceOf(Map).isRequired,
  disableAccept: PropTypes.bool,
};
RequestItem.defaultProps = {
  request: Map(),
};
const useStyles = makeStyles(theme => ({
  itemContainer: {
    paddingLeft: 95,
  },
  subtitle: {
    color: (theme.palette.text as any).light,
  },
  bold: {
    fontWeight: 800,
  },
  semibold: {
    fontWeight: 600,
  },
}));
export default RequestItem;
