import { Map } from 'immutable';
// constants
import {
  GROUP_TAG_FETCH,
  GROUP_TAG_CLEAR,
  GROUP_TAG_CREATE,
  GROUP_TAG_DELETE,
} from 'store/constants/groupTagTypes';
// helpers
import httpReducer from './helpers/httpReducer';
import remove from './callbacks/remove';

export default function reduceGroupTag(state = Map(), action = {}) {
  switch ((action as any).type) {
    case GROUP_TAG_FETCH:
      return httpReducer(state, action, { appendResult: true });
    case GROUP_TAG_CREATE:
      return httpReducer(state, action);
    case GROUP_TAG_DELETE:
      return httpReducer(state, action, { callback: remove });
    case GROUP_TAG_CLEAR:
      return Map();
    default:
      return state;
  }
}
