import { List, Map } from 'immutable';

export default function getPrimaryEmail(state: any) {
  const emails = state.getIn(['currentUser', 'data', 'emails'], List());

  return emails
    .find(
      (email: any) => email.get('primary'),
      {}, // context
      Map() // not set value
    )
    .get('email');
}
