import React from 'react';
// material-ui
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
// icons
import MailOutlineIcon from '@material-ui/icons/MailOutline';
// components
import DazzlingDialog from 'components/DazzlingDialog';
import Button from 'components/Button';

interface EnterpriseSubscriptionPlanModalProps {
  open: boolean;
  title: string;
  salesEmail: string;
  onClose: () => void;
}

const EnterpriseSubscriptionPlanModal = ({
  open,
  title,
  salesEmail,
  onClose,
}: EnterpriseSubscriptionPlanModalProps) => {
  const classes = useStyles();

  return (
    <DazzlingDialog
      handleClose={onClose}
      headerProps={{
        icon: MailOutlineIcon,
        title,
        alignItems: 'center',
      }}
      open={open}
      actions={[
        <Button color='primary' variant='contained' onClick={onClose}>
          Back
        </Button>,
      ]}
      hideSnackbar
      isBackgroundWhite
      isButtonOnTheRightCorner
    >
      <Grid className={classes.container} container spacing={2}>
        <Grid item xs={12}>
          If you are interested in Enterprise plan pricing, please contact us at
          the email below:
          <br />
          <a href={`mailto:${salesEmail}`}>{salesEmail}</a>
        </Grid>
      </Grid>
    </DazzlingDialog>
  );
};

const useStyles = makeStyles(() => ({
  container: {
    '& a': {
      color: 'inherit',
      fontWeight: 600,
    },
  },
}));

export default EnterpriseSubscriptionPlanModal;
