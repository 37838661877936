import React, { useEffect } from 'react';
import { isEqual } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';

// MUI components
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { GetApp } from '@material-ui/icons';

// components
import IconButton from 'components/IconButton';
import Toast from 'components/Toast';

// action creators
import { createExportAction } from 'store/actions/apiTaskActions';
import { clearFileExportById } from 'store/actions/fileExportActions';

// selectors
import getCurrentGroupId from 'store/selectors/getCurrentGroupId';
import getForm from 'store/selectors/getForm';
import getSliceEntityById from 'store/selectors/getSliceEntityById';

// helpers
import download from 'helpers/download';

type Props = {
  exportType?: string;
  params: { [key: string]: any };
  disabled?: boolean;
};

export default function ExportButton({
  exportType = 'reports',
  params,
  disabled = false,
}: Props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const currentGroupId = useSelector(getCurrentGroupId);
  const exportFormName = [
    exportType,
    currentGroupId,
    JSON.stringify(params),
  ].join('_');
  const exportForm = useSelector(getForm(exportFormName), isEqual);
  const { downloadUrl } = useSelector(
    getSliceEntityById('fileExport', exportType, {})
  );

  useEffect(() => {
    if (downloadUrl) {
      download(downloadUrl);
      dispatch(clearFileExportById({ exportId: exportType }));
    }
  }, [dispatch, downloadUrl, exportType]);

  const handleExport = () => {
    dispatch(
      createExportAction({
        groupId: currentGroupId,
        exportType,
        formName: exportFormName,
        ...params,
      })
    );
  };

  return (
    <Grid item className={classes.exportButton}>
      <IconButton
        onClick={handleExport}
        loading={exportForm.isSubmitting}
        disabled={disabled}
      >
        <GetApp />
      </IconButton>

      <Toast
        alertProps={{
          message: exportForm.hasSubmitSucceeded
            ? 'Exported Successfully'
            : 'Export Failed',
        }}
        hideErrorDialog={true}
        formName={exportFormName}
        shouldDestroyFormOnClose
      />
    </Grid>
  );
}

const useStyles = makeStyles(() => ({
  exportButton: {
    textAlign: 'right',
  },
}));
