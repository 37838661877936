import React, { useMemo, useCallback, useState, useEffect } from 'react';
import validate from 'validate.js';
import { useDispatch, useSelector } from 'react-redux';
import { isEqual } from 'lodash';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
// final form
import { Form, FormSpy } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';
import createDecorator from 'final-form-calculate';
// selectors
import getLeadStatuses from 'store/selectors/getLeadStatuses';
import getForm from 'store/selectors/getForm';
import getCurrentGroupId from 'store/selectors/getCurrentGroupId';
import getIsCurrentGroupCouncil from 'store/selectors/getIsCurrentGroupCouncil';

// actions
import {
  updateStatusesAction,
  deleteStatusesAction,
  fetchStatusesAction,
} from 'store/actions/statusActions';
import { setFormSubmitSucceeded as setFormSubmitSucceededAction } from 'store/actions/formActions';
import { destroyForm as destroyFormAction } from 'store/actions/formActions';
// material-ui
import { Grid } from '@material-ui/core';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
// components
import Status from './Status';
import ActionBar from 'components/ActionBar';
import Button from 'components/Button';
const UPDATE_FORM = 'updateStatuses';
const DELETE_FORM = 'deleteStatuses';
const SCHEMA = {
  abbreviation: {
    presence: true,
    length: { maximum: 4 },
  },
  title: {
    presence: true,
    length: { maximum: 128 },
  },
  description: {
    length: { maximum: 1024 },
  },
};
const CALCULATOR = createDecorator({
  field: /statuses\[\d+\]\.isDefault/,
  // @ts-expect-error ts-migrate(2322) FIXME: Type '(value: any, name: string, allValues: Object... Remove this comment to see the full error message
  updates: (value, name, allValues, previousValues) => {
    const valueIndex = Number(name.match(/\d+/));
    if (!value || !(previousValues as any).statuses) return allValues;
    return {
      statuses: (allValues as any).statuses.map((status: any, index: any) => ({
        ...status,
        isDefault: valueIndex === index,
      })),
    };
  },
});

export default function StatusesForm() {
  const [animation, setAnimation] = useState(true);
  const {
    actions: { updateStatuses, deleteStatuses, destroyForm, fetchStatuses },
    state: {
      updateForm,
      deleteForm,
      statuses,
      currentGroupId,
      isCurrentGroupCouncil,
    },
  } = useRedux();
  const initialStatuses = useMemo(
    () =>
      statuses
        .map((status, index: any) => ({
          ...status,
          position: status.position !== null ? status.position : index,
        }))
        .sort((a: any, b: any) => a.position - b.position),
    [statuses]
  );
  const validateForm = useCallback(
    values => ({
      statuses: values.statuses.map((status: Status) =>
        validate(status, SCHEMA)
      ),
    }),
    []
  );
  const handleFormChange = useCallback(() => setAnimation(true), []);
  const handleFormSubmit = useCallback(
    values => {
      const changedStatuses = values.statuses
        .map((status: any, index: any) => ({
          ...status,
          position: index + 1,
        }))
        .filter(
          (valuesStatus: any, index: any) =>
            !isEqual(valuesStatus, initialStatuses[index])
        );
      updateStatuses({ formName: UPDATE_FORM, statuses: changedStatuses });
      const deletedStatuses = initialStatuses.filter(
        (status: any) =>
          !values.statuses.find(({ id }: any) => id === status.id)
      );
      deleteStatuses({
        formName: DELETE_FORM,
        statuses: deletedStatuses,
      });
    },
    [initialStatuses, updateStatuses, deleteStatuses]
  );
  const handleDelete = useCallback(
    fields => (index: any) => {
      fields.remove(index);
      setAnimation(false);
    },
    []
  );
  const handleClose = useCallback(
    reset => (_: any, reason: any) => {
      if (reason === 'timeout') {
        destroyForm(UPDATE_FORM);
        destroyForm(DELETE_FORM);
        reset();
      }
    },
    [destroyForm]
  );
  const handleCancel = useCallback(
    reset => () => {
      setAnimation(false);
      reset();
    },
    []
  );
  const handleDragEnd = useCallback(
    fields => (result: any) => {
      if (!result.destination) {
        return;
      }
      fields.move(result.source.index, result.destination.index);
      setAnimation(false);
    },
    [setAnimation]
  );
  const handleTheme = useCallback(
    theme =>
      animation
        ? theme
        : createTheme({
            ...theme,
            transitions: {
              create: () => 'none',
            },
          }),
    [animation]
  );

  useEffect(() => {
    if (isCurrentGroupCouncil) {
      fetchStatuses(currentGroupId);
    }
  }, [isCurrentGroupCouncil, fetchStatuses, currentGroupId]);

  return (
    <>
      <Form
        onSubmit={handleFormSubmit}
        mutators={{ ...arrayMutators }}
        keepDirtyOnReinitialize={
          updateForm.isSubmitting || deleteForm.isSubmitting
        }
        decorators={[CALCULATOR]}
        initialValues={{ statuses: initialStatuses }}
        initialValuesEqual={isEqual}
        validate={validateForm}
        render={({ handleSubmit, invalid, pristine, form: { reset } }) => (
          <>
            <FormSpy
              // @ts-expect-error ts-migrate(2322) FIXME: Type '{ statuses: true; }' is not assignable to ty... Remove this comment to see the full error message
              subscription={{ values: { statuses: true } }}
              onChange={handleFormChange}
            />
            <FieldArray name='statuses' isEqual={isEqual}>
              {({ fields }) => (
                <DragDropContext onDragEnd={handleDragEnd(fields)}>
                  <Droppable droppableId='statuses'>
                    {provided => (
                      <Grid
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                        container
                        justifyContent={'flex-end'}
                        spacing={2}
                        component='form'
                        onSubmit={handleSubmit}
                      >
                        {fields.map((prefix, index) => (
                          <Draggable
                            key={prefix}
                            draggableId={prefix}
                            index={index}
                          >
                            {provided => (
                              <Grid
                                item
                                xs={12}
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <ThemeProvider theme={handleTheme}>
                                  <Status
                                    fieldPrefix={prefix}
                                    isDefault={fields.value[index].isDefault}
                                    onDelete={handleDelete(fields)}
                                  />
                                </ThemeProvider>
                              </Grid>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                        <ActionBar
                          open={!pristine}
                          message='Save your changes?'
                          onClose={handleClose(reset)}
                          // @ts-expect-error ts-migrate(2322) FIXME: Type 'number | null' is not assignable to type 'nu... Remove this comment to see the full error message
                          autoHideDuration={
                            updateForm.hasSubmitSucceeded &&
                            deleteForm.hasSubmitSucceeded
                              ? 1500
                              : null
                          }
                        >
                          <Button
                            disabled={pristine}
                            onClick={handleCancel(reset)}
                          >
                            Cancel
                          </Button>
                          <Button
                            variant='outlined'
                            color='primary'
                            type='submit'
                            disabled={
                              pristine ||
                              invalid ||
                              updateForm.isSubmitting ||
                              deleteForm.isSubmitting
                            }
                            loading={
                              updateForm.isSubmitting || deleteForm.isSubmitting
                            }
                            success={
                              updateForm.hasSubmitSucceeded &&
                              deleteForm.hasSubmitSucceeded
                            }
                            fail={
                              updateForm.hasSubmitFailed ||
                              deleteForm.hasSubmitFailed
                            }
                          >
                            Save
                          </Button>
                        </ActionBar>
                      </Grid>
                    )}
                  </Droppable>
                </DragDropContext>
              )}
            </FieldArray>
          </>
        )}
      />
    </>
  );
}
const useRedux = () => {
  const dispatch = useDispatch();
  const actions = useMemo(
    () => ({
      updateStatuses: (payload: any) => dispatch(updateStatusesAction(payload)),
      deleteStatuses: (payload: any) => dispatch(deleteStatusesAction(payload)),
      destroyForm: (payload: any) => dispatch(destroyFormAction(payload)),
      setFormSubmitSucceeded: (payload: any) =>
        dispatch(setFormSubmitSucceededAction(payload)),
      fetchStatuses: (groupId: number) => {
        dispatch(fetchStatusesAction({ groupId }));
      },
    }),
    [dispatch]
  );
  const state = useSelector(
    state => ({
      updateForm: getForm(UPDATE_FORM)(state),
      deleteForm: getForm(DELETE_FORM)(state),
      statuses: getLeadStatuses(state),
      currentGroupId: getCurrentGroupId(state),
      isCurrentGroupCouncil: getIsCurrentGroupCouncil(state),
    }),
    isEqual
  );
  return { actions, state };
};
