import React from 'react';
import { isEqual } from 'lodash';

// redux
import { useSelector } from 'react-redux';

// MUI components
import { Grid, Box } from '@material-ui/core';

// components
import LeadDetails from 'components/LeadDetails';
import LeadAttributes from './LeadAttributes';
import LeadTags from './LeadTags';

// selectors
import getPermission from 'store/selectors/getPermission';

export default function LeadInfo({
  leadId,
  shouldFetchData,
  withoutDetails,
}: {
  leadId: number;
  shouldFetchData?: boolean;
  withoutDetails?: boolean;
}) {
  const { canGetLeadAttributes, canGetLeadTags } = useSelector(
    state => ({
      canGetLeadAttributes: getPermission('pnmAttr.getMany')(state),
      canGetLeadTags: getPermission('pnmTag.getMany')(state),
    }),
    isEqual
  );

  return (
    <Box overflow='hidden'>
      <Grid container spacing={2} alignItems={'flex-start'}>
        {!withoutDetails && (
          <Grid item xs>
            <LeadDetails leadId={leadId} />
          </Grid>
        )}
        <Grid container item spacing={2} alignItems={'flex-start'}>
          {canGetLeadAttributes && (
            <Grid item xs={12} sm={6}>
              <LeadAttributes
                leadId={leadId}
                shouldFetchData={shouldFetchData}
              />
            </Grid>
          )}
          {canGetLeadTags && (
            <Grid item xs={12} sm={6}>
              <LeadTags leadId={leadId} />
            </Grid>
          )}
        </Grid>
      </Grid>
    </Box>
  );
}

LeadInfo.defaultProps = {
  shouldFetchData: true,
};
