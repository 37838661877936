import React, { useCallback } from 'react';
import { Form } from 'react-final-form';
import { useDispatch } from 'react-redux';
import validate from 'validate.js';

import DazzlingDialog from 'components/DazzlingDialog';

import { createFormFeeAction } from 'store/actions/formFeeActions';

import FormFeesItem from 'routes/PaymentSettings/FormFeesCard/FormFeesItem/FormFeesItem';
import { SCHEMA } from 'routes/PaymentSettings/helpers/validationSchema';

import MonetizationOnOutlined from '@material-ui/icons/MonetizationOnOutlined';

const FORM_NAME = 'addFormFeesForm';

export interface AddFormFeesModalProps {
  isModalOpen: boolean;
  setIsModalOpen: (value: boolean) => void;
  groupId: number;
  formFees?: FormFee[];
}

export default function AddFormFeesModal({
  isModalOpen,
  setIsModalOpen,
  groupId,
  formFees,
}: AddFormFeesModalProps) {
  const dispatch = useDispatch();

  const onSubmit = useCallback(
    values => {
      dispatch(
        createFormFeeAction({ formName: FORM_NAME, groupId, ...values })
      );
    },
    [dispatch, groupId]
  );

  const handleClose = useCallback(
    reset => () => {
      setIsModalOpen(false);
      reset();
    },
    [setIsModalOpen]
  );

  return (
    <Form
      id='addFormFees'
      onSubmit={onSubmit}
      validate={values => validate(values, SCHEMA)}
      render={({ handleSubmit, invalid, form: { reset } }) => (
        <DazzlingDialog
          open={isModalOpen}
          acceptLabel='Add'
          disabled={invalid}
          formName={FORM_NAME}
          onAccept={handleSubmit}
          handleClose={handleClose(reset)}
          headerProps={{
            title: 'Add Form Fee',
            subtitle: 'New Form Fee',
            icon: MonetizationOnOutlined,
          }}
        >
          <FormFeesItem formFees={formFees} />
        </DazzlingDialog>
      )}
    />
  );
}
