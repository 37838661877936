import React, { useCallback, useEffect, useMemo } from 'react';
import { isEqual } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';

// MUI components
import {
  Grid,
  Card,
  CardContent,
  Hidden,
  WithStyles,
  withStyles,
} from '@material-ui/core';

// components
import Header from 'components/Header';
import Table from 'components/Table';
import ExportButton from 'components/ExportButton';
import ReportRefreshButton from 'components/ReportRefreshButton';

// action
import { createChapterUserDataReport } from 'store/actions/apiTaskActions';

// selectors
import getCurrentGroupId from 'store/selectors/getCurrentGroupId';
import getForm from 'store/selectors/getForm';
import getSliceEntityById from 'store/selectors/getSliceEntityById';

// icons
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';

// helpers
import { isArray, isEmpty } from 'helpers/check';
import { getComparator } from 'components/Table/helpers/sortFunctions';

// styles
import chapterUserDataStyles from 'routes/ChapterAnalytics/ChapterUserData/chapterUserData.style';

const REPORT_FORM = 'chapterUserDataReportForm';
const ROWS_PER_PAGE = 10;
const INITIAL_ORDER = 'asc';
const INITIAL_ORDER_BY = 'groupName';

export interface ChapterUserDataContent {
  adminCount: number;
  groupId: number;
  groupName: string;
  latestActivity?: string | null;
  lastLogin?: string | null;
  latestActivitySort?: number;
  outstandingInvites: number;
  totalUsers: number;
}

export interface ChapterUserDataProps
  extends WithStyles<typeof chapterUserDataStyles> {}

const ChapterUserData = ({ classes }: ChapterUserDataProps) => {
  const dispatch = useDispatch();

  const {
    currentGroupId,
    report: {
      data: {
        content: unformattedContent = [],
        meta: { columns = [] } = {},
      } = {},
      id: reportId,
    },
    form: { isSubmitting },
  } = useSelector(
    state => ({
      currentGroupId: getCurrentGroupId(state),
      form: getForm(REPORT_FORM)(state),
      report: getSliceEntityById('report', 'ReportTypes.USER_DATA', {})(state),
    }),
    isEqual
  );

  const dispatchCreateReport = useCallback(
    ({ forceRefresh }: { forceRefresh: boolean }) => {
      dispatch(
        createChapterUserDataReport({
          groupId: currentGroupId,
          formName: REPORT_FORM,
          forceRefresh,
        })
      );
    },
    [dispatch, currentGroupId]
  );

  useEffect(() => {
    dispatchCreateReport({ forceRefresh: false });
  }, [dispatchCreateReport]);

  const content = useMemo<ChapterUserDataContent[]>(() => {
    if (isEmpty(unformattedContent) || !isArray(unformattedContent)) {
      return [];
    }

    return unformattedContent.map((itemContent: ChapterUserDataContent) => {
      const { latestActivity } = itemContent || {};
      const latestActivityMoment =
        latestActivity && moment(latestActivity).isValid()
          ? moment(latestActivity)
          : false;
      return {
        ...itemContent,
        lastLogin: latestActivityMoment
          ? latestActivityMoment.local().format('MMM DD, YYYY')
          : '',
        latestActivitySort: latestActivityMoment
          ? latestActivityMoment.unix()
          : 0,
      };
    });
  }, [unformattedContent]);

  const getOrderBy = (orderBy: string): string => {
    if (orderBy === 'latestActivity' || orderBy === 'lastLogin') {
      return 'latestActivitySort';
    }

    return orderBy;
  };

  const sortingComparator = (order: boolean | string, orderBy: string) =>
    getComparator(order, getOrderBy(orderBy));

  return (
    <Card>
      <CardContent className={classes.cardContent}>
        <Grid container>
          <Grid item xs>
            <Header
              title='User Data'
              subtitle='See some data about each linked groups users, usage, and access'
              icon={PeopleAltIcon}
              compact
            />
          </Grid>

          <Hidden xsDown>
            <Grid item xs={2}>
              <Grid container justifyContent='flex-end'>
                <Grid item>
                  <ReportRefreshButton
                    loading={isSubmitting}
                    onRefresh={dispatchCreateReport}
                  />
                </Grid>
                <ExportButton params={{ reportId }} disabled={isSubmitting} />
              </Grid>
            </Grid>
          </Hidden>

          <Grid item xs={12}>
            <Table
              columns={columns}
              data={content}
              variant='outlined'
              numberOfSkeletonRows={isSubmitting ? 8 : 0}
              initialOrder={INITIAL_ORDER}
              initialOrderBy={INITIAL_ORDER_BY}
              TablePaginationProps={{
                count: content.length,
                rowsPerPage: ROWS_PER_PAGE,
                rowsPerPageOptions: [ROWS_PER_PAGE],
              }}
              sortingComparator={sortingComparator}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default withStyles(chapterUserDataStyles, {
  withTheme: true,
})(ChapterUserData);
