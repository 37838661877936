import { all, call, fork, put, takeEvery, select } from 'redux-saga/effects';

// action creators
import { doneIndicator } from 'store/actions/httpActions';

// selector
import getCurrentGroupId from 'store/selectors/getCurrentGroupId';

// api
import client from 'sources/api';

// constants
import {
  MILESTONE_CREATE_REQUEST,
  MILESTONE_CREATE,
} from 'store/constants/milestoneTypes';

// schemas
import { milestoneSchema } from 'store/schemas/milestoneSchema';

// helpers
import httpSaga from 'store/sagas/httpSaga';

const WATCH_TYPE = MILESTONE_CREATE_REQUEST;
const TYPE = MILESTONE_CREATE;

export function* createMilestone(action: any) {
  const {
    payload: {
      formName,
      fields: { title, description },
    },
  } = action;
  const currentGroupId = yield select(getCurrentGroupId);
  // @ts-expect-error ts-migrate(2569) FIXME: Type 'Generator<any, any, unknown>' is not an arra... Remove this comment to see the full error message
  yield* httpSaga(
    TYPE,
    call(client.post, `/tags?group_id=${currentGroupId}`, {
      title,
      description,
      type: 'event',
    }),
    {
      schema: milestoneSchema,
      formName,
    }
  );

  yield put(doneIndicator(TYPE));
}

export function* watch() {
  yield takeEvery(WATCH_TYPE, createMilestone);
}

export default function* root() {
  yield all([fork(watch)]);
}
