import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import Immutable from 'immutable';

// action creators
import { doneIndicator, success } from 'store/actions/httpActions';

// api
import client from 'sources/api';

// constants
import {
  REFERRAL_FORM_CREATE_TOKEN_REQUEST,
  REFERRAL_FORM_CREATE_TOKEN,
} from 'store/constants/referralFormTypes';

// helpers
import httpSaga from 'store/sagas/httpSaga';
import getResponsePayload from 'helpers/getResponsePayload';

// schemas
import { referralFormSchema } from 'store/schemas/referralFormSchema';

const WATCH_TYPE = REFERRAL_FORM_CREATE_TOKEN_REQUEST;
const TYPE = REFERRAL_FORM_CREATE_TOKEN;
const META_ID = 'token';

export function* createReferralFormTokenSaga(action: any) {
  const {
    payload: { formId, groupId: group_id },
  } = action;

  // @ts-expect-error ts-migrate(2569) FIXME: Type 'Generator<any, any, unknown>' is not an arra... Remove this comment to see the full error message
  const response = yield* httpSaga(
    TYPE,
    call(client.post, `/forms/${formId}/token`, {
      group_id,
    }),
    {
      dispatchSuccess: false,
      schema: referralFormSchema,
      metaId: META_ID,
    }
  );

  if (response) {
    response.data.id = formId;
  }

  const payload = getResponsePayload(response, referralFormSchema);

  // Send the response data to the store for this type to be caught by a reducer
  yield put(success(TYPE, Immutable.fromJS(payload), META_ID));

  yield put(doneIndicator(TYPE));
}

export function* watch() {
  yield takeEvery(WATCH_TYPE, createReferralFormTokenSaga);
}

export default function* root() {
  yield all([fork(watch)]);
}
