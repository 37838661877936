import React, { Component, ReactNode, ErrorInfo } from 'react';
import Bugsnag from '@bugsnag/js';
import ErrorFallback from 'layout/ErrorFallback';

interface State {
  hasError: boolean;
  errorPathname: null | string;
}

interface Props {
  children: ReactNode;
  history: any;
  variant?: 'dark' | 'light' | null;
}

export default class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
    errorPathname: null,
  };

  componentWillReceiveProps = (nextProps: any) => {
    const {
      history: { location: { pathname: nextPathname = '' } = {} } = {},
    } = nextProps;

    // Clears the errors out if user navigates to new page
    if (this.state.hasError && this.state.errorPathname !== nextPathname) {
      this.setState({ hasError: false, errorPathname: null });
    }
  };

  componentDidCatch(error: any, errorInfo: ErrorInfo) {
    const { history: { location: { pathname = '' } = {} } = {} } = this.props;
    // Sets state with a flag and the path where the error occurred
    this.setState({ hasError: true, errorPathname: pathname });

    Bugsnag.notify(error);
  }

  render() {
    if (this.state.hasError) {
      // Error component
      return <ErrorFallback variant={this.props.variant || 'light'} />;
    }
    // Normally, just render children
    return this.props.children;
  }
}
