import { Styles } from 'assets/types';

const alertMessageStyle: Styles = () => ({
  paper: {
    '&.fullWidth': {
      width: '100%',
    },
  },
  title: {
    fontWeight: 700,
    fontSize: '1.125rem',
    lineHeight: 1.5,
    '&.s-14': {
      fontSize: '14px',
      fontWeight: 800,
    },
  },
  message: {
    fontWeight: 400,
  },
});

export default alertMessageStyle;
