export const GROUP_FETCH_REQUEST = 'GROUP_FETCH_REQUEST';
export const GROUP_FETCH = 'GROUP_FETCH';

export const GROUP_FETCH_BY_ID_REQUEST = 'GROUP_FETCH_BY_ID_REQUEST';
export const GROUP_FETCH_BY_ID = 'GROUP_FETCH_BY_ID';

export const GROUP_CREATE_REQUEST = 'GROUP_CREATE_REQUEST';
export const GROUP_CREATE = 'GROUP_CREATE';

export const GROUP_UPDATE_REQUEST = 'GROUP_UPDATE_REQUEST';
export const GROUP_UPDATE = 'GROUP_UPDATE';

export const GROUP_DELETE_REQUEST = 'GROUP_DELETE_REQUEST';
export const GROUP_DELETE = 'GROUP_DELETE';

export const GROUP_ADD_DASHBOARD_LINK_REQUEST =
  'GROUP_ADD_DASHBOARD_LINK_REQUEST';
export const GROUP_ADD_DASHBOARD_LINK = 'GROUP_ADD_DASHBOARD_LINK';

export const GROUP_REMOVE_DASHBOARD_LINK_REQUEST =
  'GROUP_REMOVE_DASHBOARD_LINK_REQUEST';
export const GROUP_REMOVE_DASHBOARD_LINK = 'GROUP_REMOVE_DASHBOARD_LINK';

export const CURRENT_GROUP_SET = 'CURRENT_GROUP_SET';

export const CURRENT_GROUP_CLEAR = 'CURRENT_GROUP_CLEAR';

export const CURRENT_GROUP_UPDATE_REQUEST = 'CURRENT_GROUP_UPDATE_REQUEST';
export const CURRENT_GROUP_UPDATE = 'CURRENT_GROUP_UPDATE';

export const CURRENT_GROUP_FETCH_REQUEST = 'CURRENT_GROUP_FETCH_REQUEST';
export const CURRENT_GROUP_FETCH = 'CURRENT_GROUP_FETCH';

export const GROUP_CLEAR = 'GROUP_CLEAR';

export const GROUP_UNLINK_REQUEST = 'GROUP_UNLINK_REQUEST';
export const GROUP_UNLINK = 'GROUP_UNLINK';
