import React from 'react';

// MUI components
import { Grid } from '@material-ui/core';

// components
import GroupOverviewCard from 'routes/GroupAnalytics/components/GroupOverviewCard/GroupOverviewCard';
import GroupActivityCard from 'routes/GroupAnalytics/components/GroupActivityCard/GroupActivityCard';
import StatusCard from 'routes/GroupAnalytics/components/StatusCard/StatusCard';
import ChapterStatusCard from 'routes/GroupAnalytics/components/ChapterStatusCard/ChapterStatusCard';
import { shallowEqual, useSelector } from 'react-redux';

import getIsCurrentGroupCouncil from 'store/selectors/getIsCurrentGroupCouncil';
import getIsCurrentGroupOrganization from 'store/selectors/getIsCurrentGroupOrganization';

const GroupAnalytics = function() {
  const { isCurrentGroupCouncil, isCurrentGroupOrganization } = useSelector(
    state => ({
      isCurrentGroupCouncil: getIsCurrentGroupCouncil(state),
      isCurrentGroupOrganization: getIsCurrentGroupOrganization(state),
    }),
    shallowEqual
  );

  return (
    <Grid container spacing={2} alignContent='flex-start'>
      <Grid container item spacing={2}>
        <Grid item xs={12} sm={5}>
          <GroupOverviewCard />
        </Grid>
        <Grid item xs={12} sm={7}>
          <GroupActivityCard />
        </Grid>
      </Grid>
      <Grid container item spacing={2}>
        <Grid
          item
          xs={12}
          lg={isCurrentGroupCouncil || isCurrentGroupOrganization ? 6 : 12}
        >
          <StatusCard />
        </Grid>
        {(isCurrentGroupCouncil || isCurrentGroupOrganization) && (
          <Grid item xs={12} lg={6}>
            <ChapterStatusCard />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default GroupAnalytics;
