import {
  LEAD_IMPORT_REQUEST,
  LEAD_MANUAL_PUSH_REQUEST,
} from 'store/constants/leadTypes';

export function importLeads({ groupId, fileId, shouldSkipDuplicates }: any) {
  return {
    type: LEAD_IMPORT_REQUEST,
    payload: { groupId, fileId, shouldSkipDuplicates },
  };
}

export interface ManuallyPushLeadsParams extends LeadFetchFilters {
  pnmIds: number[] | 'all';
  groupId: number;
  leadsUpdate?: () => void;
  name?: string;
  formName?: string;
  excludedPnmIds?: number[];
}

export function updateManuallyPushLeads({
  pnmIds,
  groupId,
  leadsUpdate,
  name,
  formName,
  ...filters
}: ManuallyPushLeadsParams) {
  return {
    type: LEAD_MANUAL_PUSH_REQUEST,
    payload: { pnmIds, leadsUpdate, name, formName, filters, groupId },
  };
}
