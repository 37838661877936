import { Map } from 'immutable';
// constants
import {
  PAYMENT_SETUP_FETCH,
  PAYMENT_SETUP_GET_LINK_FETCH,
  PAYMENT_SETUP_UPDATE,
  PAYMENT_DELETE,
  PAYMENT_CLEAR,
} from 'store/constants/paymentSettingsTypes';

// helpers
import httpReducer from 'store/reducers/helpers/httpReducer';

interface ActionPaymentSettings {
  type: string;
  payload: Map<string, any>;
  meta: Map<string, any>;
  error: boolean;
}

export default function reducePaymentSettings(
  state = Map(),
  action: ActionPaymentSettings
) {
  const { type } = action;
  switch (type) {
    case PAYMENT_SETUP_FETCH:
    case PAYMENT_DELETE:
    case PAYMENT_SETUP_UPDATE:
      return httpReducer(state, action, {
        entity: 'paymentSettings',
        updateItemsById: false,
        updateResult: false,
      });

    case PAYMENT_SETUP_GET_LINK_FETCH:
      return httpReducer(state, action, {
        entity: 'paymentSettings',
        updateItemsById: false,
        updateResult: false,
      });

    case PAYMENT_CLEAR:
      return Map();

    default:
      return state;
  }
}
