import React, { useMemo } from 'react';

// material-ui
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

// icons
import WarningIcon from '@material-ui/icons/Warning';

// components
import Header from 'components/Header';

interface AccountLockedProps {
  isBillingError: boolean;
  isEmptyCurrentSubscription: boolean;
  hasDefaultPaymentMethod: boolean;
}

const AccountLocked = ({
  isBillingError,
  isEmptyCurrentSubscription,
  hasDefaultPaymentMethod,
}: AccountLockedProps) => {
  const classes = useStyles();

  const message = useMemo((): JSX.Element => {
    if (isBillingError) {
      return (
        <>
          There are some issues in your group. Please, update this page and
          check again. If you have these issues again, please contact the
          administrator.
        </>
      );
    }
    if (!hasDefaultPaymentMethod) {
      return (
        <>
          No payment method on file, add one to upgrade your account to a paid
          version.
        </>
      );
    }
    if (isEmptyCurrentSubscription) {
      return (
        <>
          You don't have a valid subscription now. Please, create a new
          subscription.
        </>
      );
    }

    return (
      <>
        Your account is locked because you do not have a valid form of payment
        on file. To renew your account, click “Update Billing Info.” If you do
        not wish to continue with the paid version, click “Update Plan” and
        downgrade your account to “Basic.”
      </>
    );
  }, [isBillingError, isEmptyCurrentSubscription, hasDefaultPaymentMethod]);

  return (
    <div className={classes.container}>
      <Grid container>
        <Grid item xs={12}>
          <Header
            icon={(props: any) => (
              <WarningIcon className={classes.warningIcon} {...(props || {})} />
            )}
            title='Account Locked'
            alignItems='center'
            isBackgroundWhite
          />
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <div className={classes.content}>
          <Grid className={classes.text} item xs={12}>
            {message}
          </Grid>
        </div>
      </Grid>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  container: {
    width: '100%',
    backgroundColor: theme.palette.common.white,
    padding: 25,
    borderRadius: 8,
  },
  content: {
    paddingLeft: 100,
    paddingBottom: 20,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
    },
  },
  text: {
    fontSize: '0.95rem',
    fontWeight: 500,
    lineHeight: '1.5em',
    color: theme.palette.background.paper,
  },
  warningIcon: {
    color: theme.palette.error.main,
  },
}));

export default AccountLocked;
