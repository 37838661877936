import React, { useCallback } from 'react';

// hooks
import { useDeleteLeadTagMutation } from 'api/leadTags';

// material-ui
import { Grid, Tooltip, WithStyles, withStyles } from '@material-ui/core';

// components
import IconButton from 'components/IconButton';

// icons
import Delete from '@material-ui/icons/Delete';
import Add from '@material-ui/icons/Add';

// helpers
import { LeadsQuery } from 'routes/LeadList/helpers/interfaces';

// styles
import tagsCellStyles from 'routes/LeadList/components/TagsCell/tagsCell.style';

export interface TagsCellProps extends WithStyles<typeof tagsCellStyles> {
  currentTags: number[];
  currentLeadId: number;
  leadsQuery: LeadsQuery;
  tags?: Tag[];
  onOpenTagModal: (leadId: number) => void;
}

const TagsCell = ({
  classes,
  currentTags,
  currentLeadId,
  leadsQuery,
  tags = [],
  onOpenTagModal,
}: TagsCellProps) => {
  const { queryKey } = leadsQuery;
  const { isLoading, mutate: deleteLeadTag } = useDeleteLeadTagMutation();

  const handleLabelClick = useCallback(
    (tagId: number) => {
      if (isLoading) {
        return;
      }
      deleteLeadTag({
        tagId,
        pnmId: currentLeadId,
        leadsQueryKey: queryKey,
      });
    },
    [currentLeadId, queryKey, deleteLeadTag, isLoading]
  );

  const handleOpenModal = useCallback(() => onOpenTagModal(currentLeadId), [
    onOpenTagModal,
    currentLeadId,
  ]);

  return (
    <Grid
      container
      alignItems='center'
      onClick={event => {
        event.stopPropagation();
        event.preventDefault();
      }}
      className={classes.gridBox}
    >
      {currentTags.map(itemId => {
        const { id, title, color } =
          tags.find(item => item.id === itemId) || {};
        return (
          id && (
            <Grid
              item
              xs
              key={id}
              onClick={() => handleLabelClick(id)}
              className={classes.gridIn}
            >
              <Tooltip title={title || ''}>
                <div
                  style={{
                    backgroundColor: `#${color}`,
                  }}
                  className={`${classes.labelContainer} ${
                    isLoading ? 'disabled' : ''
                  }`}
                >
                  <Delete className={classes.deleteIcon} />
                </div>
              </Tooltip>
            </Grid>
          )
        );
      })}

      <Grid item xs className={classes.gridIn}>
        <Tooltip title='Assign Tag'>
          <IconButton onClick={handleOpenModal} className={classes.addButton}>
            <Add />
          </IconButton>
        </Tooltip>
      </Grid>
    </Grid>
  );
};

export default withStyles(tagsCellStyles, { withTheme: true })(TagsCell);
