import { useSelector } from 'react-redux';
import { List } from 'immutable';

// selectors
import getIsSystemAdmin from 'store/selectors/getIsSystemAdmin';
import getIsHqAdmin from 'store/selectors/getIsHqAdmin';
import getIsCouncilAdmin from 'store/selectors/getIsCouncilAdmin';
import getCurrentUserGroups from 'store/selectors/getCurrentUserGroups';
import getCurrentGroupId from 'store/selectors/getCurrentGroupId';
import getIsCurrentGroupOrganization from 'store/selectors/getIsCurrentGroupOrganization';

export default function useGroupFetchParams(initialParams: any) {
  const fetchParams = initialParams;

  const {
    currentGroupId,
    isSystemAdmin,
    isHqAdmin,
    isCouncilAdmin,
    currentUserGroupIds = List(),
    isCurrentGroupOrganization,
  } = useSelector(state => ({
    currentGroupId: getCurrentGroupId(state),
    currentUserGroupIds: getCurrentUserGroups(state),
    isSystemAdmin: getIsSystemAdmin(state),
    isCouncilAdmin: getIsCouncilAdmin(state),
    isHqAdmin: getIsHqAdmin(state),
    isCurrentGroupOrganization: getIsCurrentGroupOrganization(state),
  }));
  if (!isSystemAdmin && !isHqAdmin) {
    fetchParams.ids = currentUserGroupIds.toJS();
  } else if (
    (isHqAdmin || isSystemAdmin || isCouncilAdmin) &&
    currentGroupId &&
    isCurrentGroupOrganization
  ) {
    fetchParams.parentId = currentGroupId;
  } else if (!isSystemAdmin && (isHqAdmin || isCouncilAdmin)) {
    return null; // prevents HQ admin from fetching all data
  } else {
    return fetchParams;
  }

  return fetchParams;
}
