import React, { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'react-final-form';

import { isEqual } from 'lodash';

// MUI
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

// components
import DazzlingDialog from 'components/DazzlingDialog';

// action
import { groupUnlink } from 'store/actions/groupActions';
import getCurrentGroupId from 'store/selectors/getCurrentGroupId';

// helpers
import { SiteVisualDataContext } from 'components/SiteVisualData';

// icons
import LinkOff from '@material-ui/icons/LinkOff';

type UnlinkChapterModalProps = {
  groupType: string;
  selectedChapter: {
    id?: number;
    organizationName?: string;
    councilName?: string;
  };
  open: boolean;
  onClose: (...args: any[]) => any;
};

export default function UnlinkChapterModal({
  groupType,
  selectedChapter,
  open,
  onClose,
}: UnlinkChapterModalProps) {
  const { unlinkChapterModalAdditionalSubtitle } = useContext(
    SiteVisualDataContext
  );
  const { currentGroupId } = useSelector(
    state => ({
      currentGroupId: getCurrentGroupId(state),
    }),
    isEqual
  );
  const dispatch = useDispatch();
  const { id, organizationName = '', councilName = '' } = selectedChapter || {};

  const onSubmit = () => {
    dispatch(
      groupUnlink({
        parentId: currentGroupId,
        childId: id,
        type: groupType,
        formName: 'unlinkChapterFormName',
      })
    );
  };

  const getHeaderProps = () => {
    return {
      icon: LinkOff,
      title: `Unlink Chapter from ${councilName}`,
      subtitle:
        'By unlinking this group from your dashboard, you will no longer be able to share leads, access data, or pull reports about this group.',
      additionalSubtitle: unlinkChapterModalAdditionalSubtitle,
    };
  };

  const getDialogContent = () => {
    return (
      <Grid container>
        <Grid item xs={12} id='warningLabel'>
          <Typography paragraph>
            Are you sure you want to unlink {organizationName}? If so, click
            “Unlink.”
          </Typography>
        </Grid>
      </Grid>
    );
  };

  return (
    <Form
      id='unlinkChapterForm'
      onSubmit={onSubmit}
      render={({ handleSubmit }) => (
        <DazzlingDialog
          id='unlinkChapterDialog'
          acceptLabel='Unlink'
          children={getDialogContent()}
          formName='unlinkChapterFormName'
          handleClose={onClose}
          headerProps={getHeaderProps()}
          onAccept={handleSubmit}
          open={open}
        />
      )}
    />
  );
}
