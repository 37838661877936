import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import arrayMutators from 'final-form-arrays';
import validate from 'validate.js';
import { isEqual } from 'lodash';
import ActionBar from 'components/ActionBar';
import Button from 'components/Button';
import { updateFormFeeAction } from 'store/actions/formFeeActions';
import { destroyForm } from 'store/actions/formActions';
import getForm from 'store/selectors/getForm';
import FormFeesItem from 'routes/PaymentSettings/FormFeesCard/FormFeesItem/FormFeesItem';
import DeleteFormFeesModal from 'routes/PaymentSettings/FormFeesCard/DeleteFormFeesModal/DeleteFormFeesModal';
import { SCHEMA } from 'routes/PaymentSettings/helpers/validationSchema';
import { Grid, Typography } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';

export interface FormFeesFormProps {
  groupId: number;
  formFees?: FormFee[];
}

const UPDATE_FORM = 'updateFormFees';

const FormFeesForm = ({ groupId, formFees }: FormFeesFormProps) => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<number | false>(
    false
  );

  const dispatch = useDispatch();

  const { updateForm } = useSelector(state => ({
    updateForm: getForm(UPDATE_FORM)(state),
  }));

  const handleClose = useCallback(
    reset => (_: any, reason: string) => {
      if (reason === 'timeout') {
        destroyForm(UPDATE_FORM);
        reset();
      }
    },
    []
  );

  const handleCancel = useCallback(
    reset => () => {
      reset();
    },
    []
  );

  const initialFormFees = useMemo(
    () =>
      formFees
        ? formFees.map((item: FormFee) => ({
            ...item,
          }))
        : [],
    [formFees]
  );

  const validateForm = useCallback(
    values => ({
      formFees: values.formFees.map((value: FormFee) =>
        validate(value, SCHEMA)
      ),
    }),
    []
  );

  const onSubmit = useCallback(
    values => {
      const changedFormFees = values.formFees.filter(
        (value: FormFee, index: number) =>
          !isEqual(value, initialFormFees[index])
      );
      dispatch(
        updateFormFeeAction({
          formName: UPDATE_FORM,
          groupId,
          formFees: changedFormFees,
        })
      );
    },
    [dispatch, initialFormFees, groupId]
  );

  return (
    <Grid item xs={12}>
      <Form
        id='formFeesForm'
        onSubmit={onSubmit}
        mutators={{ ...arrayMutators }}
        keepDirtyOnReinitialize={updateForm.isSubmitting}
        initialValues={{ formFees: initialFormFees }}
        validate={validateForm}
        render={({ handleSubmit, invalid, pristine, form: { reset } }) => (
          <form onSubmit={handleSubmit}>
            <FieldArray name='formFees'>
              {({ fields }) => {
                const { value: fieldsValue } = fields || {};
                return fields.length ? (
                  fields.map((prefix: string, index: number) => {
                    const { id } = fieldsValue?.[index] || {};
                    const handleSetIsDeleteModalOpen = () => {
                      setIsDeleteModalOpen(id);
                    };

                    return (
                      <Grid container spacing={2} key={prefix}>
                        <Grid item xs={12} sm={10}>
                          <FormFeesItem
                            prefix={prefix}
                            values={fieldsValue?.[index]}
                            formFees={formFees}
                            isFormIdDisabled
                          />
                        </Grid>
                        <Grid
                          container
                          item
                          xs={12}
                          sm={2}
                          justifyContent='center'
                          alignItems='center'
                        >
                          {!!id && (
                            <>
                              <IconButton onClick={handleSetIsDeleteModalOpen}>
                                <DeleteIcon />
                              </IconButton>
                              {!!(
                                isDeleteModalOpen && id === isDeleteModalOpen
                              ) && (
                                <DeleteFormFeesModal
                                  groupId={groupId}
                                  isModalOpen={!!isDeleteModalOpen}
                                  setIsModalOpen={setIsDeleteModalOpen}
                                  element={fieldsValue?.[index]}
                                />
                              )}
                            </>
                          )}
                        </Grid>
                      </Grid>
                    );
                  })
                ) : (
                  <Grid item>
                    <Typography variant='body1'>
                      No one fee hasn't added yet.
                    </Typography>
                  </Grid>
                );
              }}
            </FieldArray>
            <ActionBar
              open={!pristine}
              message='Save your changes?'
              onClose={handleClose(reset)}
              // @ts-expect-error ts-migrate(2322) FIXME: Type 'number | null' is not assignable to type 'nu... Remove this comment to see the full error message
              autoHideDuration={updateForm.hasSubmitSucceeded ? 1500 : null}
            >
              <Button disabled={pristine} onClick={handleCancel(reset)}>
                Cancel
              </Button>
              <Button
                variant='outlined'
                color='primary'
                type='submit'
                disabled={pristine || invalid || updateForm.isSubmitting}
                loading={updateForm.isSubmitting}
                success={updateForm.hasSubmitSucceeded}
                fail={updateForm.hasSubmitFailed}
              >
                Save
              </Button>
            </ActionBar>
          </form>
        )}
      />
    </Grid>
  );
};

export default FormFeesForm;
