import {
  EXPORT_FILE_CREATE,
  EXPORT_FILE_CLEAR_BY_ID,
} from 'store/constants/fileExportTypes';

export function createFileExport({ exportData, type }: any) {
  return {
    type: EXPORT_FILE_CREATE,
    payload: {
      ...exportData,
      type,
    },
  };
}

export function clearFileExportById({ exportId }: any) {
  return {
    type: EXPORT_FILE_CLEAR_BY_ID,
    payload: {
      type: exportId,
    },
  };
}
