import {
  all,
  cancel,
  cancelled,
  delay,
  fork,
  put,
  select,
  take,
  takeEvery,
} from 'redux-saga/effects';

// action creators
import { doneIndicator } from 'store/actions/httpActions';
import { fetchMessagesAction } from 'store/actions/messageActions';

// selector
import getCurrentGroupId from 'store/selectors/getCurrentGroupId';

// constants
import {
  MESSAGE_START_LISTEN,
  MESSAGE_START_LISTEN_REQUEST,
  MESSAGE_STOP_LISTEN_REQUEST,
} from 'store/constants/messageTypes';

const WATCH_TYPE = MESSAGE_START_LISTEN_REQUEST;
const TYPE = MESSAGE_START_LISTEN;

export function* bgSync(action: any) {
  const currentGroupId = yield select(getCurrentGroupId);
  const {
    payload: { threadId },
  } = action;

  try {
    while (true) {
      yield put(fetchMessagesAction({ groupId: currentGroupId, threadId }));
      yield take('MESSAGE_FETCH_DONE');
      yield delay(5000);
    }
  } finally {
    if (yield cancelled()) {
      // We don't need anything implemented here now,
      //  this is just here in case we do later on and need to recall the syntax
    }
  }
}

export function* listenForMessagesSaga(action: any) {
  // starts the task in the background
  const bgSyncTask = yield fork(bgSync, action);

  // wait for the stop action
  yield take(MESSAGE_STOP_LISTEN_REQUEST);
  // user clicked stop. cancel the background task
  // this will cause the forked bgSync task to jump into its finally block
  yield cancel(bgSyncTask);

  yield put(doneIndicator(TYPE));
}

export function* watch() {
  yield takeEvery(WATCH_TYPE, listenForMessagesSaga);
}

export default function* root() {
  yield all([fork(watch)]);
}
