import { all, call, fork, takeEvery } from 'redux-saga/effects';

// api
import client from 'sources/api';

// constants
import {
  MEMBER_UPDATE_REQUEST,
  MEMBER_UPDATE,
} from 'store/constants/memberTypes';

// lib
import httpSaga from 'store/sagas/httpSaga';

// schemas
import { memberSchema } from 'store/schemas/memberSchema';

const WATCH_TYPE = MEMBER_UPDATE_REQUEST;
const TYPE = MEMBER_UPDATE;

export function* updateMember(action: any) {
  const { memberId, roleId: role_id } = action.payload;

  // @ts-expect-error ts-migrate(2569) FIXME: Type 'Generator<any, any, unknown>' is not an arra... Remove this comment to see the full error message
  yield* httpSaga(
    TYPE,
    call(client.patch, `/members/${memberId}`, {
      role_id,
    }),
    {
      schema: memberSchema,
      metaId: `update-${memberId}`,
    }
  );
}

export function* watch() {
  yield takeEvery(WATCH_TYPE, updateMember);
}

export default function* root() {
  yield all([fork(watch)]);
}
