import React from 'react';

// material-ui
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

// components
import Button from 'components/Button';

export default function StepperButtons({
  activeStep,
  incrementStep,
  decrementStep,
  isNextDisabled,
  NextButtonProps,
}: {
  activeStep: number;
  incrementStep: () => void;
  decrementStep: () => void;
  isNextDisabled: boolean;
  NextButtonProps?: any;
}) {
  const classes = useStyles();

  return (
    <Grid item xs={12}>
      <Grid container justifyContent='flex-end'>
        <Grid item xs={12} className={classes.stepperButtons}>
          <Button
            id='backButton'
            disabled={activeStep === stepsMap['selectLeads']}
            onClick={decrementStep}
          >
            Back
          </Button>

          <Button
            id='nextButton'
            variant='contained'
            color='primary'
            type={
              activeStep === stepsMap['sendInvitations'] ? 'submit' : 'button'
            }
            onClick={incrementStep}
            disabled={isNextDisabled}
            {...NextButtonProps}
          >
            {activeStep === stepsMap['sendInvitations'] ? 'Send' : 'Next'}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}

const stepsMap = {
  selectLeads: 0,
  selectMembers: 1,
  confirmGuests: 2,
  sendInvitations: 3,
};

const useStyles = makeStyles(theme => {
  return {
    stepperButtons: {
      textAlign: 'right',
      paddingRight: 32,
    },
    cardContent: {
      paddingTop: 30,
    },
  };
});
