import React, { useCallback, useEffect } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import validate from 'validate.js';
import { useParams, useHistory } from 'react-router-dom';
import arrayMutators from 'final-form-arrays';
import { isEqual } from 'lodash';
import { useEventTimeSlotsQuery } from 'api/eventTimeSlots';

// material-ui
import { AddCircle } from '@material-ui/icons';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import FormHelperText from '@material-ui/core/FormHelperText';
import Skeleton from '@material-ui/lab/Skeleton';
import Typography from '@material-ui/core/Typography';

// components
import Button from 'components/Button';
import WizzyWigField from 'components/WizzyWigField';
import WizzyWigFieldWithoutClipboard from 'components/WizzyWigFieldWithoutClipboard';
import TextField from 'components/TextField';
import Select from 'components/Select';
import { Form, Field } from 'react-final-form';
import FormEditorFields from 'routes/ReferralForm/FormEditorFields';
import FormEditorOptions from 'routes/ReferralForm/FormEditorOptions';
import ActionBar from 'components/ActionBar';
import ReferrerFields from 'routes/ReferralForm/ReferrerFields';
import BasicFields from 'routes/ReferralForm/BasicFields';
import FormEditorHeaderImage from 'routes/ReferralForm/FormEditorHeaderImage';
import Switch from 'components/Switch';
import Toast from 'components/Toast';
import ChapterSelectionField from 'components/ChapterSelectionField';
import GenderSelectionField from 'components/GenderSelectionField';

// selectors
import getLoadingFromState from 'store/selectors/getLoadingFromState';
import getCurrentGroupId from 'store/selectors/getCurrentGroupId';

// helpers
import getFormTypeOptions from 'helpers/getFormTypeOptions';
import isFieldTypeWithOptions from 'routes/FieldsSettings/helpers/isFieldTypeWithOptions';
import { isEmpty, isObject } from 'helpers/check';

// hooks
import { useReduxForm } from 'store/hooks/useReduxForm';

// action creators
import {
  createReferralFormToken,
  updateReferralForm,
} from 'store/actions/referralFormActions';
import { updateFormFields } from 'store/actions/formFieldActions';
import { destroyForm } from 'store/actions/formActions';

// styles
import formEditorStyles from 'routes/ReferralForm/FormEditor/formEditor.style';

export interface FormEditorProps extends WithStyles<typeof formEditorStyles> {
  formData: any;
  formFields: any;
  hasActivePaymentMethod: boolean;
  isCouncilForm?: boolean;
  isOrganizationForm?: boolean;
  isUniversalForm?: boolean;
}

const FORM = 'editReferralForm';
const INDEX_TO_INSERT = 0;

const CHAPTER_SUB_TEXT = `Please select the chapters you are interested in and want your
  information shared with. If you do not select any groups, your
  information will be shared with all chapters.`;

// schemas
const schema = {
  title: {
    presence: true,
  },
  type: {
    presence: true,
  },
};

const FormEditor = function({
  classes,
  formData,
  formFields,
  isCouncilForm,
  isOrganizationForm,
  isUniversalForm,
  hasActivePaymentMethod,
}: FormEditorProps) {
  const history = useHistory();
  const dispatch = useDispatch();
  const params = useParams();

  // @ts-expect-error ts-migrate(2339) FIXME: Property 'formId' does not exist on type '{}'.
  const { formId } = params;

  const confirmationEmailContent = formData.get('confirmationEmailContent', '');
  const customAgreement1Content = formData.get('customAgreement1Content', '');
  const customAgreement2Content = formData.get('customAgreement2Content', '');
  const isPublished = formData.get('isPublished', false);
  const token = formData.get('token', '');

  const {
    hasSubmitFailed,
    hasSubmitSucceeded,
    isSubmitting,
    formError,
  } = useReduxForm(FORM);

  const {
    referralFormLoading,
    groupId,
    tokenLoading,
    currentGroupId,
  } = useSelector(
    state => ({
      currentGroupId: getCurrentGroupId(state),
      referralFormLoading: getLoadingFromState('referralForm')(state),
      tokenLoading: getLoadingFromState('referralForm', 'token')(state),
      groupId: getCurrentGroupId(state),
    }),
    shallowEqual
  );

  const { data: eventTimeSlots = [] } = useEventTimeSlotsQuery(
    Number(formData.get('eventId'))
  );

  const formBelongsToCurrentGroup = currentGroupId === formData.get('groupId');

  useEffect(() => {
    if (!formBelongsToCurrentGroup && formData.get('id')) {
      // navigate to view, don't let them edit this form!
      history.push(`/forms/${formData.get('id')}/view`);
    }
  }, [formBelongsToCurrentGroup, formData, history]);

  useEffect(() => {
    // Create referral form token for sharing
    if (isPublished && !token) {
      dispatch(createReferralFormToken({ formId, groupId }));
    }
  }, [dispatch, isPublished, token, formId, groupId]);

  const loading = referralFormLoading && !isSubmitting;
  const dispatchUpdateReferralForm = useCallback(
    values => {
      dispatch(
        updateReferralForm({
          ...values,
          groupId,
          formId,
          formName: FORM,
        })
      );
    },
    [dispatch, formId, groupId]
  );

  const dispatchUpdateFormFields = useCallback(
    values => {
      const formFields = Array.isArray(values.fields)
        ? values.fields.map((value: FormField) => {
            const { fieldType, options, label } = value || {};
            return {
              ...(value || {}),
              ...(!isEmpty(options) && !isFieldTypeWithOptions(fieldType)
                ? { options: [] }
                : {}),
              label: label.trim(),
            };
          })
        : values.fields;

      dispatch(
        updateFormFields({
          groupId,
          formId,
          formName: FORM,
          formFields,
        })
      );
    },
    [dispatch, formId, groupId]
  );

  const getInitialValues = useCallback(
    () => ({
      ...formData.toJS(),
      headerPhotoFileId: formData.getIn(['headerPhotoFileId', 'id']),
      fields: formFields.toJS(),
      updatedOn: '',
    }),
    [formData, formFields]
  );

  const handleSave = (values: any) => {
    const emailContentWithoutHtml = (values.confirmationEmailContent || '')
      .replace('<p>', '')
      .replace('</p>', '')
      .replace('<br>', '');
    const hasEmailContent = Boolean(emailContentWithoutHtml);
    dispatchUpdateFormFields(values);
    dispatchUpdateReferralForm({
      formName: FORM,
      isCouncilForm,
      isOrganizationForm,
      isUniversalForm,
      noFormFields: !formFields.size,
      ...values,
      enableConfirmationEmail: hasEmailContent
        ? values.enableConfirmationEmail
        : false,
    });
  };

  const handleActionBarClose = (event: any, reason: any, reset: any) => {
    // sets values back to pristine
    if (reason === 'timeout') {
      reset();
      dispatch(destroyForm(FORM));
    }
  };

  const getInsertOptions = () => {
    const insertOptions = [
      {
        label: 'Lead First Name',
        value: 'first_name',
      },
      {
        label: 'Lead Full Name',
        value: 'full_name',
      },
    ];
    if (formData.get('eventId') && eventTimeSlots.length > 1) {
      insertOptions.push({
        label: 'Selected Time Slot',
        value: 'selected_time_slot_str',
      });
    }
    return insertOptions;
  };

  return (
    <Grid
      item
      xs={12}
      sm={10}
      md={7}
      lg={7}
      xl={6}
      className={classes.formCard}
    >
      <Form
        validate={values => validate(values, schema)}
        onSubmit={values => handleSave(values)}
        initialValues={getInitialValues()}
        initialValuesEqual={isEqual}
        mutators={{
          ...arrayMutators,
          setValue: ([field, value], state, { changeValue }) => {
            changeValue(state, field, () => value);
          },
          insertEmailTemplateText: insertTemplateText(
            'confirmationEmailContent'
          ),
        }}
        keepDirtyOnReinitialize={
          isSubmitting || referralFormLoading || tokenLoading
        }
        render={({
          handleSubmit,
          invalid,
          values = {
            enableImageUpload: false,
            emailRequired: false,
            phoneNumberRequired: false,
            chapterSelectionEnabled: false,
            genderSelectionEnabled: false,
          },
          pristine,
          initialValues,
          form: { reset, mutators },
        }) => {
          const resetAllValues = () => {
            if (isObject(initialValues)) {
              for (const key in initialValues) {
                mutators.setValue(key, initialValues[key]);
              }
            }
            return true;
          };
          const handleResetForm = () => {
            reset(initialValues);
            resetAllValues();
          };

          return (
            <>
              <Card key='card'>
                <Grid container justifyContent='center'>
                  <FormEditorHeaderImage
                    formData={formData}
                    isCouncilForm={isCouncilForm}
                    isOrganizationForm={isOrganizationForm}
                    isUniversalForm={isUniversalForm}
                    mutators={mutators}
                  />

                  <Grid item xs={12} className={classes.header}>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={6}>
                        {loading ? (
                          <Skeleton
                            id='titleSkeleton'
                            variant='text'
                            height={38}
                          />
                        ) : (
                          <Field
                            className={classes.titleField}
                            name='title'
                            variant='standard'
                            component={TextField}
                            label='Title'
                          />
                        )}
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        {loading ? (
                          <Skeleton
                            id='titleSkeleton'
                            variant='text'
                            height={38}
                          />
                        ) : (
                          <Field
                            name='type'
                            component={Select}
                            label='Form Type'
                            disabled={formData.get('eventId')}
                            options={
                              formData.get('eventId')
                                ? [{ value: 'event', label: 'Event' }]
                                : getFormTypeOptions(!!isCouncilForm)
                            }
                          />
                        )}
                      </Grid>

                      <Grid item xs={12}>
                        {loading ? (
                          <Skeleton
                            id='descriptionSkeleton'
                            variant='text'
                            height={38}
                          />
                        ) : (
                          <Field
                            className={classes.titleField}
                            name='description'
                            variant='standard'
                            component={TextField}
                            label='Description'
                          />
                        )}
                      </Grid>
                    </Grid>
                  </Grid>

                  <BasicFields
                    enableImageUpload={values.enableImageUpload}
                    emailRequired={values.emailRequired}
                    phoneNumberRequired={values.phoneNumberRequired}
                  />

                  {values.chapterSelectionEnabled && (
                    <ChapterSelectionField
                      className={classes.editorContent}
                      groupId={groupId}
                      subtext={CHAPTER_SUB_TEXT}
                      multiple
                    />
                  )}

                  {values.genderSelectionEnabled && <GenderSelectionField />}

                  {values.requestReferrersInfo && (
                    <ReferrerFields
                      disabled={true}
                      referringSomeoneChecked={false}
                      onCheckboxChange={() => {}}
                    />
                  )}

                  <FormEditorFields
                    formFields={formFields}
                    initialValues={initialValues}
                    values={values}
                    mutators={mutators}
                    isCouncilForm={isCouncilForm}
                    isOrganizationForm={isOrganizationForm}
                  />

                  <Grid container className={classes.editorContent} id='editor'>
                    <Grid
                      item
                      xs={8}
                      sm={10}
                      className={classes.formContentHeader}
                    >
                      <Typography>Confirmation Email</Typography>
                      <Typography color='textSecondary' variant='subtitle2'>
                        Customize the content of emails that are sent after a
                        form is filled out
                      </Typography>
                    </Grid>

                    <Grid container justifyContent='flex-start' spacing={2}>
                      <Grid item xs={8} sm={4}>
                        <Field
                          name='emailTextToInsert'
                          component={TextField}
                          label='Text To Insert'
                          margin='none'
                          helperText='Inserts at beginning of email'
                          options={getInsertOptions()}
                          select
                        />
                      </Grid>
                      <Grid item xs={4} sm={8} style={{ marginTop: 10 }}>
                        <Button
                          id='insertButton'
                          startIcon={<AddCircle />}
                          onClick={() =>
                            mutators.insertEmailTemplateText(
                              values?.emailTextToInsert
                            )
                          }
                        >
                          Click To Insert
                        </Button>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        name='confirmationEmailContent'
                        defaultValue={confirmationEmailContent}
                        component={WizzyWigField}
                      />
                    </Grid>
                  </Grid>

                  {(isCouncilForm || isOrganizationForm || isUniversalForm) && (
                    <>
                      {values?.enableCustomAgreement1 && (
                        <Grid container className={classes.editorContent}>
                          <Grid
                            item
                            xs={8}
                            sm={10}
                            className={classes.formContentHeader}
                          >
                            <Typography>Custom Agreement</Typography>
                            <Typography
                              color='textSecondary'
                              variant='subtitle2'
                            >
                              Users filling out the form will have the option to
                              agree to the terms and conditions. If you want to
                              make it required to submit the form, make sure you
                              set it as “Required.”
                            </Typography>
                          </Grid>

                          <Grid
                            item
                            xs={12}
                            className={classes.customAgreementRequired}
                          >
                            <Field
                              name='customAgreement1Required'
                              label='Required'
                              type='checkbox'
                              component={Switch}
                            />
                          </Grid>

                          <Grid item xs={12}>
                            <Field
                              name='customAgreement1Content'
                              defaultValue={customAgreement1Content}
                              component={WizzyWigFieldWithoutClipboard}
                            />
                          </Grid>
                        </Grid>
                      )}

                      {values?.enableCustomAgreement2 && (
                        <Grid container className={classes.editorContent}>
                          <Grid
                            item
                            xs={8}
                            sm={10}
                            className={classes.formContentHeader}
                          >
                            <Typography>Custom Agreement 2</Typography>
                            <Typography
                              color='textSecondary'
                              variant='subtitle2'
                            >
                              Users filling out the form will have the option to
                              agree to the terms and conditions. If you want to
                              make it required to submit the form, make sure you
                              set it as “Required.”
                            </Typography>
                          </Grid>

                          <Grid
                            item
                            xs={12}
                            className={classes.customAgreementRequired}
                          >
                            <Field
                              name='customAgreement2Required'
                              label='Required'
                              type='checkbox'
                              component={Switch}
                            />
                          </Grid>

                          <Grid item xs={12}>
                            <Field
                              name='customAgreement2Content'
                              defaultValue={customAgreement2Content}
                              component={WizzyWigFieldWithoutClipboard}
                            />
                          </Grid>
                        </Grid>
                      )}
                    </>
                  )}

                  <Grid item xs={12} className={classes.footerContainer}>
                    {formError && (
                      <FormHelperText
                        error
                        id='errorMessage'
                        className={classes.errorMessage}
                      >
                        {formError.get('message')}
                      </FormHelperText>
                    )}
                  </Grid>

                  <ActionBar
                    open={!pristine || isSubmitting || hasSubmitSucceeded}
                    message='Save your changes?'
                    id='actionBar'
                    onClose={(event: any, reason: any) =>
                      handleActionBarClose(event, reason, handleResetForm)
                    }
                    // @ts-expect-error ts-migrate(2322) FIXME: Type 'number | null' is not assignable to type 'nu... Remove this comment to see the full error message
                    autoHideDuration={hasSubmitSucceeded ? 1500 : null}
                  >
                    <Button
                      disabled={pristine || isSubmitting}
                      onClick={handleResetForm}
                    >
                      Cancel
                    </Button>

                    <Button
                      variant='outlined'
                      color='primary'
                      type='submit'
                      onClick={handleSubmit}
                      disabled={pristine || invalid || isSubmitting}
                      loading={isSubmitting}
                      success={hasSubmitSucceeded}
                      fail={hasSubmitFailed}
                    >
                      Save
                    </Button>
                  </ActionBar>
                  <Toast
                    snackbarProps={{
                      autoHideDuration: 2000,
                    }}
                    successMessage='Submitted Successfully'
                    failureMessage='Failed To Submit. Try Again!'
                    formName={FORM}
                    shouldDestroyFormOnClose
                    handleResetForm={handleResetForm}
                    hideSuccessMessage
                  />
                </Grid>
              </Card>

              <FormEditorOptions
                key='options'
                isPublished={values.isPublished}
                emailContent={values.confirmationEmailContent}
                formId={formId}
                eventId={formData.get('eventId')}
                token={formData.get('token')}
                isCouncilForm={isCouncilForm}
                isOrganizationForm={isOrganizationForm}
                isUniversalForm={isUniversalForm}
                hasActivePaymentMethod={hasActivePaymentMethod}
                valuesCollectPayment={values?.collectPayment}
                chapterSelectionEnabled={values.chapterSelectionEnabled}
                genderSelectionEnabled={values.genderSelectionEnabled}
              />
            </>
          );
        }}
      />
    </Grid>
  );
};

const insertTemplateText = (fieldName: string) => (
  textToInsert: string,
  state: any,
  { changeValue }: any
) => {
  const insertString =
    textToInsert[0] === 'full_name'
      ? '{{ first_name }} {{ last_name }}'
      : `{{ ${textToInsert} }}`;

  changeValue(state, fieldName, (value: any) => {
    if (!value) return insertString;

    return `${value.slice(0, INDEX_TO_INSERT)}${insertString}${value.slice(
      INDEX_TO_INSERT
    )}`;
  });
};

export default withStyles(formEditorStyles, { withTheme: true })(FormEditor);
