import React, { useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { isEqual } from 'lodash';
import { useHistory } from 'react-router-dom';

// MUI
import { makeStyles } from '@material-ui/core/styles';

// components
import Table from 'components/Table';

// actions
import { fetchMembersForGroup } from 'store/actions/memberActions';
import getCurrentGroupId from 'store/selectors/getCurrentGroupId';
import getLoadingFromState from 'store/selectors/getLoadingFromState';
import getSliceState from 'store/selectors/getSliceState';
import { useLeadsQueries } from 'api/leads';

const rowsPerPage = 5;

const MessageDetails = ({
  contactAttribute,
  recipientType,
  recipients,
}: {
  contactAttribute: 'phoneNumber' | 'emailAddress';
  recipientType: 'member' | 'lead';
  recipients: ActivityMessageRecipient[];
}) => {
  const history = useHistory();
  const {
    location: { search },
  } = history;
  const dispatch = useDispatch();
  const classes = useStyles();
  const [recipientsOnPage, setRecipientsOnPage] = useState<any[]>([]);
  const [leadRecipientsOnPage, setLeadRecipientsOnPage] = useState<number[]>(
    []
  );
  const results = useLeadsQueries(leadRecipientsOnPage);
  const leads = useMemo(
    () =>
      results
        .filter(result => result.isSuccess)
        .map(result => result.data as Lead),
    [results]
  );
  const leadsLoading = useMemo(
    () => results.map(result => result.isLoading).some(Boolean),
    [results]
  );

  const {
    currentGroupId,
    memberLoading,
    member: { data: members = [] },
  } = useSelector(
    state => ({
      currentGroupId: getCurrentGroupId(state),
      memberLoading: getLoadingFromState('member', null, false)(state),
      member: getSliceState('member')(state),
    }),
    isEqual
  );

  const recipientNames: any[] = useMemo(() => {
    const names: any = {};

    if (recipientType === 'lead') {
      recipientsOnPage.forEach(recipient => {
        const leadRecipient = leads.find(
          lead => lead.id?.toString() === recipient.id.toString()
        );

        names[recipient.id] = leadRecipient?.fullName;
      });
    } else {
      recipientsOnPage.forEach(recipient => {
        const memberRecipient =
          members.find((member: any) => {
            const { account } = member;
            return account.id?.toString() === recipient.id.toString();
          }) || {};

        const { account } = memberRecipient;
        names[recipient.id] = `${account?.firstName} ${account?.lastName}`;
      });
    }

    return names;
  }, [recipientsOnPage, leads, members, recipientType]);

  const handlePageChange = (tableRows: any[]) => {
    const idsToFetch = tableRows.map((tableRow: any) => tableRow.id);
    setRecipientsOnPage(tableRows);

    if (recipientType === 'lead' && !leadsLoading) {
      setLeadRecipientsOnPage(idsToFetch);
    } else if (recipientType === 'member' && !memberLoading) {
      dispatch(
        fetchMembersForGroup({
          accountIds: idsToFetch,
          groupId: currentGroupId,
        })
      );
    }
  };

  const navigateToRecipient = ({ id }: { id: number }) => {
    if (recipientType === 'lead') {
      history.push({ pathname: `/leads/${id}`, search });
    }
  };

  const columns = [
    {
      label: 'Recipient',
      key: 'recipientName',
      disableSort: true,
    },
    {
      label: 'Sent To',
      key: 'sentTo',
      disableSort: true,
    },
  ];

  const content = recipients.map((recipient: ActivityMessageRecipient) => {
    return {
      id: recipient.id,
      recipientName: recipientNames[recipient.id],
      sentTo: recipient[contactAttribute],
    };
  });

  const loading = recipientType === 'member' ? memberLoading : leadsLoading;
  const maxSkeletons = recipients.length > 5 ? 5 : recipients.length;

  return (
    <div className={classes.container}>
      <Table
        columns={columns}
        data={loading ? [] : content}
        variant='outlined'
        onPageChange={handlePageChange}
        numberOfSkeletonRows={loading ? maxSkeletons : 0}
        SkeletonProps={{
          height: 28,
        }}
        TableRowProps={{
          onRowClick: navigateToRecipient,
        }}
        TablePaginationProps={{
          count: content.length,
          rowsPerPage,
          rowsPerPageOptions: [rowsPerPage],
        }}
      />
    </div>
  );
};

const useStyles = makeStyles(theme => {
  return {
    container: {
      margin: 8,
      marginRight: 12,
      marginBottom: 4,
    },
  };
});

export default MessageDetails;
