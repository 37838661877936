import React from 'react';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';
import { SvgIconTypeMap } from '@material-ui/core/SvgIcon';
// components
import DazzlingDialog from 'components/DazzlingDialog';

// icons
import DeleteIcon from '@material-ui/icons/Delete';

export interface DeleteModalProps {
  open: boolean;
  onClose: () => void;
  onSubmit: () => void;
  isSuccess?: boolean;
  isError?: boolean;
  isLoading?: boolean;
  alertProps?: {
    message: string;
  };
  title?: string;
  highlightedSubtitle?: string;
  icon?: OverridableComponent<SvgIconTypeMap>;
  slice?: string;
  acceptLabel?: string;
  rejectLabel?: string;
}

const DeleteModal = ({
  open,
  onClose,
  onSubmit,
  isSuccess,
  isError,
  isLoading,
  alertProps,
  title = 'Are you sure?',
  icon = DeleteIcon,
  highlightedSubtitle,
  slice,
  acceptLabel = 'Yes',
  rejectLabel = 'No',
}: DeleteModalProps) => {
  return (
    <DazzlingDialog
      id='deleteModal'
      isSuccess={isSuccess}
      isError={isError}
      isLoading={isLoading}
      alertProps={alertProps}
      handleClose={onClose}
      open={open}
      slice={slice}
      acceptLabel={acceptLabel}
      rejectLabel={rejectLabel}
      onAccept={onSubmit}
      headerProps={{
        icon,
        title,
        ...(highlightedSubtitle
          ? { highlightedSubtitle }
          : { alignItems: 'center' }),
      }}
    />
  );
};

export default DeleteModal;
