import Immutable, { Map } from 'immutable';
// constants
import {
  EXPORT_FILE_CREATE,
  EXPORT_FILE_CLEAR_BY_ID,
} from 'store/constants/fileExportTypes';

export default function reduceFileExport(state = Map(), action = {}) {
  const payload = Immutable.fromJS((action as any).payload);
  switch ((action as any).type) {
    case EXPORT_FILE_CREATE:
      return state.mergeIn(['data', 'byId', payload.get('type')], payload);
    case EXPORT_FILE_CLEAR_BY_ID:
      return state.deleteIn(['data', 'byId', payload.get('type')]);
    default:
      return state;
  }
}
