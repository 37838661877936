import { Styles } from 'assets/types';
import analyticsCardStyles from 'assets/stylesheets/defaultStyles/analyticsCard.style';

const labelAnalyticsStyle: Styles = theme => {
  const newAnalyticsCardStyle = analyticsCardStyles(theme) || {};
  return {
    ...newAnalyticsCardStyle,

    dateItem: {
      marginRight: 3,
    },
    toItem: {
      marginRight: 12,
      marginLeft: 8,
    },

    root: {
      backgroundColor: theme.palette.background.paper,
    },

    tableCell: {
      paddingTop: 10,
      paddingBottom: 10,
    },

    headerTableCell: {
      color: theme.palette.text.secondary,
    },

    noneFoundContainer: {
      border: `2px solid ${theme.palette.background.paper}`,
      borderRadius: 4,
      padding: 20,
    },
    percent: {
      display: 'inline-block',
      fontSize: '1rem',
    },
  };
};

export default labelAnalyticsStyle;
