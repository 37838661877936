import React from 'react';

// material-ui
import { alpha } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import AutoSuggest from 'components/AutoSuggest';

const LandingAutoSuggest = function({
  InputLabelProps,
  InputProps,
  ...restOfProps
}: any) {
  const classes = useStyle();

  return (
    <AutoSuggest
      InputProps={{
        classes: {
          root: classes.textField,
          notchedOutline: classes.notchedOutline,
          focused: classes.focused,
        },
        ...InputProps,
      }}
      InputLabelProps={{
        classes: {
          root: classes.inputLabel,
          focused: classes.focused,
        },
        ...InputLabelProps,
      }}
      circularProgressProps={{
        classes: {
          colorPrimary: classes.circularProgress,
        },
      }}
      {...restOfProps}
    />
  );
};

LandingAutoSuggest.propTypes = AutoSuggest.propTypes;

const useStyle = makeStyles(theme => ({
  focused: {},
  notchedOutline: {},
  textField: {
    '& $notchedOutline': {
      borderColor: theme.palette.common.white,
    },
    '&$focused $notchedOutline': {
      borderColor: theme.palette.common.white,
      borderWidth: '1px',
    },
  },

  inputLabel: {
    '&$focused': {
      color: alpha(theme.palette.common.white, 0.7),
    },
  },
  circularProgress: {
    color: theme.palette.common.white,
  },
}));

export default LandingAutoSuggest;
