import moment from 'moment';

export default function formatEndDate(date: any) {
  let formattedDate = date;

  if (!moment.isMoment(date)) {
    formattedDate = moment(date);
  }

  return formattedDate
    .clone()
    .endOf('day')
    .utc()
    .toISOString();
}
