const getTableColumns = (
  status: string,
  isCouncilLeadPage: boolean,
  searchParam: string
) => [
  {
    key: 'avatar',
    padding: 'default',
  },
  {
    key: 'firstName',
    label: 'First Name',
    padding: 'checkbox',
    disableSort: !!searchParam,
  },
  {
    key: 'lastName',
    label: 'Last Name',
    padding: 'checkbox',
    disableSort: !!searchParam,
  },
  {
    key: status,
    label: 'Status',
    disableSort: !!searchParam,
  },
  {
    key: 'phoneNumber',
    label: 'Phone',
    disableSort: !!searchParam,
  },
  {
    key: 'emailAddress',
    label: 'Email',
    disableSort: !!searchParam,
  },
  ...(isCouncilLeadPage
    ? [
        {
          key: 'tagsLabel',
          label: 'Lead Tags',
          disableSort: true,
        },
        {
          key: 'createdOn',
          label: 'Date Created',
          disableSort: !!searchParam,
        },
      ]
    : [
        {
          key: 'mainContactFullName',
          label: 'Main Contact',
          disableSort: !!searchParam,
        },
        {
          key: 'lastContactedOn',
          label: 'Last Contacted',
        },
      ]),
];

export default getTableColumns;
