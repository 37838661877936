import React, { useEffect, useMemo, useCallback, memo } from 'react';
import { Map, List } from 'immutable';
import { isEqual } from 'lodash';
import classNames from 'classnames';

// MUI
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

// icons
import CloseIcon from '@material-ui/icons/Close';
// components
import Header, { HeaderProps } from 'components/Header';
import Button from 'components/Button';
import Toast from 'components/Toast';
import ErrorList from 'components/ErrorList';
// hooks
import { useSelector, useDispatch } from 'react-redux';
import { usePrevious } from 'helpers/hooks/usePrevious';
// action creators
import { destroyForm } from 'store/actions/formActions';
// selectors
import getErrorMessage from 'store/selectors/getErrorMessage';
import getLoadingFromState from 'store/selectors/getLoadingFromState';
import getStatusFromState from 'store/selectors/getStatusFromState';
import getForm from 'store/selectors/getForm';

interface DazzlingDialogProps extends DialogProps {
  isLoading?: boolean;
  isSuccess?: boolean;
  isError?: boolean;
  loading?: boolean;
  acceptLabel?: string;
  actions?: JSX.Element[];
  alertProps?: object;
  children?: JSX.Element | JSX.Element[];
  disabled?: boolean;
  displayActions?: boolean;
  errorListTitle?: string;
  hideSnackbar?: boolean;
  formName?: string;
  headerProps: HeaderProps;
  height?: 'auto' | 'short' | 'medium' | 'tall' | 'max';
  id?: string;
  metaId?: null | string | number;
  open: boolean;
  rejectLabel?: string;
  slice?: string;
  snackbarProps?: object;
  submitOnEnter?: boolean;
  successMessage?: string;
  failureMessage?: string;
  isBackgroundWhite?: boolean;
  isFullWidth?: boolean;
  isButtonOnTheRightCorner?: boolean;
  handleClose: () => void;
  onAccept?: ((event: React.FormEvent<HTMLFormElement>) => void) | undefined;
  onReject?: () => void;
  resetForm?: () => void;
}

const DazzlingDialog = function({
  isLoading = false,
  isSuccess = false,
  isError = false,
  acceptLabel = 'Submit',
  actions,
  alertProps = {},
  children,
  disabled = false,
  displayActions = true,
  errorListTitle,
  hideSnackbar = false,
  formName = '',
  isBackgroundWhite,
  isFullWidth,
  isButtonOnTheRightCorner,
  handleClose,
  headerProps,
  height = 'auto',
  id = 'dialog',
  metaId = null,
  onAccept = () => {},
  onReject = () => {},
  rejectLabel = 'Cancel',
  resetForm = () => {},
  slice = '',
  snackbarProps = {},
  // submitting on enter stopped working for some reason
  submitOnEnter = true,
  successMessage = 'Submitted Successfully',
  failureMessage = 'Action failed',
  ...dialogProps
}: DazzlingDialogProps) {
  const { stateError, stateLoading, status, formData = {} } = useSelector(
    state => ({
      stateError: getErrorMessage(slice)(state),
      stateLoading: getLoadingFromState(slice, metaId, isLoading)(state),
      status: getStatusFromState(slice, metaId)(state),
      formData: getForm(formName)(state),
    }),
    isEqual
  );
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'formError' does not exist on type '{}'.
  const { formError = Map() } = formData;
  const dispatch = useDispatch();
  const classes = useStyles();
  const theme = useTheme();
  const isExtraSmall = useMediaQuery(theme.breakpoints.only('xs'));
  const formErrorMessage = formError ? formError.get('message') : '';
  const errorMessage = formName ? formErrorMessage : stateError;
  const errorList = formName
    ? formError.getIn(['validationErrors', 'errors'], List())
    : stateError || List();
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'isSubmitting' does not exist on type '{}... Remove this comment to see the full error message
  const loading = formName ? formData.isSubmitting : stateLoading;
  const previousLoading = usePrevious(loading);
  const hasSubmitFailed = formName
    ? // @ts-expect-error ts-migrate(2339) FIXME: Property 'hasSubmitFailed' does not exist on type ... Remove this comment to see the full error message
      formData.hasSubmitFailed
    : status === 'error' || isError;

  // Handle success states
  const hasSubmitSucceeded = formName
    ? // @ts-expect-error ts-migrate(2339) FIXME: Property 'hasSubmitSucceeded' does not exist on ty... Remove this comment to see the full error message
      formData.hasSubmitSucceeded
    : (previousLoading && !loading && status === 'success') || isSuccess;

  const previousHasSubmitSucceeded = usePrevious(Boolean(hasSubmitSucceeded));
  const previousOpen = usePrevious(dialogProps.open);

  // closes the dialog
  useEffect(() => {
    if (!previousHasSubmitSucceeded && hasSubmitSucceeded && !actions) {
      setTimeout(handleClose, 1000);
    }
  }, [actions, handleClose, hasSubmitSucceeded, previousHasSubmitSucceeded]);

  // clears the values of the form, resetting values
  useEffect(() => {
    if (!previousHasSubmitSucceeded && hasSubmitSucceeded && formName) {
      resetForm();
    }
  }, [
    hasSubmitSucceeded,
    formName,
    resetForm,
    previousHasSubmitSucceeded,
    dispatch,
  ]);

  // destroys the form, resetting the form status metadata (submitted, submitting, etc)
  useEffect(() => {
    if (previousOpen && !dialogProps.open && formName) {
      dispatch(destroyForm(formName));
    }
  }, [dialogProps.open, dispatch, formName, previousOpen]);

  const acceptButtonProps = useMemo(
    () => ({
      id: 'acceptButton',
      disabled,
      color: 'primary',
      variant: 'contained',
      loading,
      success: hasSubmitSucceeded,
      fail: hasSubmitFailed,
    }),
    [disabled, hasSubmitFailed, hasSubmitSucceeded, loading]
  );
  // determines whether or not to treat dialog as a form
  if (formName) {
    (acceptButtonProps as any).type = 'submit';
  } else {
    (acceptButtonProps as any).onClick = onAccept;
  }
  const handleCloseButtonClick = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    handleClose();
  };
  const handleAccept = (event: any) => {
    event.preventDefault();
    onAccept(event);
  };
  const onRejectClick = () => {
    onReject();
    handleClose();
  };
  const getDialogContentHeight = useCallback(() => {
    switch (height) {
      case 'auto':
      default:
        return classes.heightAuto;
      case 'short':
        return classes.heightShort;
      case 'medium':
        return classes.heightMedium;
      case 'tall':
        return classes.heightTall;
      case 'max':
        return classes.heightMax;
    }
  }, [
    classes.heightAuto,
    classes.heightMax,
    classes.heightMedium,
    classes.heightShort,
    classes.heightTall,
    height,
  ]);
  return (
    <>
      <Dialog
        fullWidth={true}
        id={id}
        scroll='body'
        fullScreen={isExtraSmall ? true : false}
        maxWidth={'md'}
        onClose={handleCloseButtonClick}
        aria-labelledby='form-dialog-title'
        classes={{
          paper: classNames(
            classes.dialogContainer,
            isBackgroundWhite ? classes.dialogContainerWhite : null
          ),
        }}
        /* disableEnforceFocus: this prop is a workaround until v5 of MUI when we can use the new date pickers
         issue: https://github.com/mui-org/material-ui-pickers/issues/1852
        */
        disableEnforceFocus
        {...dialogProps}
      >
        <form onSubmit={handleAccept} autoComplete='off'>
          <DialogTitle classes={{ root: classes.dialogTitle }}>
            <Grid container>
              <Grid item xs={11}>
                <Header
                  isBackgroundWhite={isBackgroundWhite}
                  {...headerProps}
                />
              </Grid>

              <Grid item xs={1}>
                <Grid container justifyContent='flex-end'>
                  <Grid item>
                    <Tooltip title='Close'>
                      <IconButton
                        onClick={handleCloseButtonClick}
                        aria-label='Close'
                      >
                        <CloseIcon className='closeIcon' />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </DialogTitle>

          <DialogContent
            className={classNames(
              classes.dialogContent,
              getDialogContentHeight(),
              isBackgroundWhite ? classes.dialogContentWhite : null,
              {
                fullWidth: !!isFullWidth,
              }
            )}
          >
            {children}

            {hasSubmitFailed && errorList.size > 0 && (
              <ErrorList errors={errorList.toJS()} title={errorListTitle} />
            )}
          </DialogContent>

          {displayActions && (
            <DialogActions
              id='dialogActions'
              className={classNames(classes.dialogActions, {
                fullWidth: !!isFullWidth,
              })}
            >
              <Grid
                container
                justifyContent={
                  isButtonOnTheRightCorner ? 'flex-end' : 'flex-start'
                }
                spacing={1}
              >
                {actions ? (
                  actions.map((action: any, index: any) => (
                    <Grid item key={`action-${index}`}>
                      {action}
                    </Grid>
                  ))
                ) : (
                  <>
                    <Grid item>
                      <Button
                        id='rejectButton'
                        onClick={onRejectClick}
                        disabled={loading}
                      >
                        {rejectLabel}
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button {...acceptButtonProps}>{acceptLabel}</Button>
                    </Grid>
                  </>
                )}

                {hasSubmitFailed && (
                  <Grid item className={classes.errorText} id='errorText'>
                    <Typography color='error' variant='body2'>
                      {errorMessage ? errorMessage : failureMessage}
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </DialogActions>
          )}
        </form>
      </Dialog>

      <Toast
        alertProps={alertProps}
        hideErrorDialog={true}
        hideSnackbar={hideSnackbar}
        formName={formName}
        id='dialogToast'
        metaId={metaId}
        slice={slice}
        isSuccess={isSuccess}
        isError={isError}
        snackbarProps={snackbarProps}
        successMessage={successMessage}
        failureMessage={failureMessage}
      />
    </>
  );
};

const useStyles = makeStyles(theme => ({
  dialogContainer: {
    paddingTop: 15,
    height: 'auto',
  },
  dialogContainerWhite: {
    position: 'relative',
    backgroundColor: theme.palette.common.white,
    '&::before, &::after': {
      position: 'absolute',
      bottom: 0,
      left: 0,
      width: '100%',
      height: 8,
      background: theme.palette.background.default,
      display: 'block',
      content: '" "',
    },
    '& .closeIcon': {
      color: theme.palette.grey[500],
    },
  },
  header: {
    paddingRight: 24,
    paddingLeft: 24,
  },
  dialogTitle: {
    paddingBottom: 0,
  },
  dialogContent: {
    marginBottom: 20,
    paddingLeft: 120,
    paddingRight: 120,
    '&.fullWidth': {
      paddingLeft: 20,
      paddingRight: 20,
    },
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 20,
      paddingRight: 20,
      marginBottom: 0,
    },
  },
  dialogContentWhite: {
    color: theme.palette.common.black,
    '& .MuiFormLabel-root, & .MuiInputBase-root': {
      color: theme.palette.common.black,
      '&.Mui-error': {
        color: theme.palette.error.main,
      },
    },
    '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.grey[300],
    },
    '& .MuiFormLabel-root.Mui-error': {
      color: theme.palette.error.main,
    },
    '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.error.main,
    },
    '& .MuiFormLabel-asterisk.Mui-error': {
      color: theme.palette.error.main,
    },
    '& p.Mui-error': {
      color: theme.palette.error.main,
    },
  },
  dialogActions: {
    backgroundColor: theme.palette.background.default,
    padding: 24,
    '&.fullWidth': {
      paddingLeft: 24,
      [theme.breakpoints.down('xs')]: {
        padding: 10,
      },
    },
    [theme.breakpoints.down('xs')]: {
      padding: 10,
    },
  },
  errorText: {
    marginLeft: 10,
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
  },
  heightAuto: {
    height: 'auto',
  },
  heightShort: {
    height: 275,
  },
  heightMedium: {
    height: 450,
  },
  heightTall: {
    height: 550,
  },
  heightMax: {
    height: 'calc(100vh - 300px)',
  },
}));
export default memo(DazzlingDialog, isEqual);
