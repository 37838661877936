import {
  LEAD_ATTRIBUTE_CREATE_REQUEST,
  LEAD_ATTRIBUTE_DELETE_REQUEST,
  LEAD_ATTRIBUTE_UPDATE_OR_CREATE_REQUEST,
} from 'store/constants/leadAttributeTypes';

export function createLeadAttribute({
  leadId,
  groupId,
  formName,
  label,
  value,
}: any) {
  return {
    type: LEAD_ATTRIBUTE_CREATE_REQUEST,
    payload: { leadId, groupId, formName, label, value },
  };
}

export function updateOrCreateLeadAttribute({
  leadId,
  groupId,
  formName,
  label,
  value,
  isNewAttribute,
}: any) {
  return {
    type: LEAD_ATTRIBUTE_UPDATE_OR_CREATE_REQUEST,
    payload: { leadId, groupId, formName, label, value, isNewAttribute },
  };
}

export function deleteLeadAttribute({ leadId, groupId, label }: any) {
  return {
    type: LEAD_ATTRIBUTE_DELETE_REQUEST,
    payload: { leadId, groupId, label },
  };
}
