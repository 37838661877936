import React, { useMemo, useState } from 'react';
import { Field } from 'react-final-form';

// material-ui
import SvgIcon from '@material-ui/core/SvgIcon';
import Grid from '@material-ui/core/Grid';
import { withStyles, WithStyles } from '@material-ui/core/styles';

// components
import TextField from 'components/TextField';
import DazzlingDialog from 'components/DazzlingDialog';
import MultiSelectFormControl from 'routes/ReferralForm/FormView/components/MultiSelectFormControl';
import MultiSelectOrganizationFocuses from 'routes/ReferralForm/FormView/components/MultiSelectOrganizationFocuses';

// icons
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { ReactComponent as BookCheckIcon } from 'assets/images/book-check.svg';

// helpers
import {
  FORM_FIELD_TYPE_OF_ORGANIZATION,
  FORM_FIELD_ORGANIZATION_FOCUSES,
} from 'routes/ReferralForm/FormView/helpers/fieldsWithSpecialTypes';
import { isEmpty } from 'helpers/check';
import {
  arrayToStringWithoutEmpty,
  toLowerCase,
  toString,
} from 'helpers/transform';

// styles
import fieldWithTypeMultiSelectStyles from 'routes/ReferralForm/FormView/components/FieldWithTypeMultiSelect/fieldWithTypeMultiSelect.style';

export interface OptionType {
  label: string;
  value: string | number;
}

export interface FieldWithTypeMultiSelectProps
  extends WithStyles<typeof fieldWithTypeMultiSelectStyles> {
  name: string;
  label: string;
  options: OptionType[];
  mutators: any;
  value?: boolean | null | (string | number)[] | string;
  required?: boolean;
  description?: string | null;
  multiSelectModalButtons?: React.ReactNode | null;
  isUniversalForm?: boolean;
  open?: boolean;
  setOpen?: (open: boolean) => void;
}

const FieldWithTypeMultiSelect = ({
  classes,
  name,
  required,
  label,
  value,
  description,
  options,
  multiSelectModalButtons,
  open,
  setOpen = () => {},
  isUniversalForm,
  mutators,
}: FieldWithTypeMultiSelectProps) => {
  const [multiSelectModalOpen, setMultiSelectModalOpen] = useState<boolean>(
    false
  );

  const isCustomFieldTypeOfOrganizationForUniversalForm = useMemo<boolean>(
    () =>
      !!(
        isUniversalForm &&
        toLowerCase(label) === FORM_FIELD_TYPE_OF_ORGANIZATION
      ),
    [isUniversalForm, label]
  );

  const isCustomFieldOrganizationFocusesForUniversalForm = useMemo<boolean>(
    () =>
      !!(
        isUniversalForm &&
        toLowerCase(label) === FORM_FIELD_ORGANIZATION_FOCUSES
      ),
    [isUniversalForm, label]
  );

  const newValue = Array.isArray(value) ? [...value] : [];

  const mdOptions = options.length > 4 ? 4 : 12;

  const multiSelectTextValue = arrayToStringWithoutEmpty(
    newValue.map(
      (item: string | number) =>
        (
          options.find(
            ({ value }: { value: string | number }) =>
              toString(value) === toString(item)
          ) || {}
        )?.label
    )
  );

  const formControlLabelClass = useMemo<string | undefined>(
    () =>
      isCustomFieldTypeOfOrganizationForUniversalForm
        ? classes.controlLabelDesc
        : undefined,
    [isCustomFieldTypeOfOrganizationForUniversalForm, classes]
  );

  const multiSelectOnClick = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    e.preventDefault();
    setMultiSelectModalOpen(true);
    setOpen(true);
  };

  const handleCloseMultiSelectModal = () => {
    setMultiSelectModalOpen(false);
    setOpen(false);
  };

  const handleChangeCheckBox = (itemVal: string | number) => () => {
    const newValueToSet = !newValue.find(
      (item: string | number) => item === itemVal
    )
      ? [...newValue, itemVal]
      : [...newValue.filter((item: string | number) => item !== itemVal)];
    mutators.setValue(name, newValueToSet.length > 0 ? newValueToSet : null);
  };

  const getGridDialogContent = () => {
    if (isCustomFieldOrganizationFocusesForUniversalForm) {
      return (
        <MultiSelectOrganizationFocuses
          className={classes.gridDialogItem}
          noteClasses={classes.checkboxDescription}
          dazzlingDialogTextClasses={classes.dazzlingDialogTextClasses}
          formControlLabelClasses={formControlLabelClass}
          headerBoxClasses={classes.headerBoxClasses}
          options={options}
          values={newValue}
          isCustomFieldTypeOfOrganizationForUniversalForm={
            isCustomFieldTypeOfOrganizationForUniversalForm
          }
          handleChangeCheckBox={handleChangeCheckBox}
        />
      );
    }

    return options.map(({ label, value }: OptionType) => (
      <Grid item xs={12} md={mdOptions} className={classes.gridDialogItem}>
        <MultiSelectFormControl
          label={label}
          value={value}
          values={newValue}
          className={formControlLabelClass}
          handleChangeCheckBox={handleChangeCheckBox}
          isCustomFieldTypeOfOrganizationForUniversalForm={
            isCustomFieldTypeOfOrganizationForUniversalForm
          }
          noteClasses={classes.checkboxDescription}
        />
      </Grid>
    ));
  };

  return (
    <>
      <Field name={name} required={required} label={label}>
        {(props: any) => (
          <TextField
            {...props}
            {...(!isEmpty(description) && !isUniversalForm
              ? { helperText: description }
              : {})}
            input={{
              value: multiSelectTextValue,
              onClick: multiSelectOnClick,
              onChange: multiSelectOnClick,
            }}
            InputProps={{
              endAdornment: <ArrowDropDownIcon />,
            }}
          />
        )}
      </Field>
      <DazzlingDialog
        open={isEmpty(open) ? multiSelectModalOpen : !!open}
        headerProps={{
          icon: (props: any) => (
            <SvgIcon
              {...props}
              className='svgIcon'
              component={BookCheckIcon}
              viewBox='0 0 46 52'
            />
          ),
          title: label,
          subtitleNode: description,
        }}
        handleClose={handleCloseMultiSelectModal}
        displayActions={false}
      >
        <Grid container className={classes.gridDialogBox}>
          {getGridDialogContent()}
        </Grid>
        <>
          {!!multiSelectModalButtons && (
            <Grid container className={classes.gridDialogButtons}>
              <Grid item xs={12}>
                {multiSelectModalButtons}
              </Grid>
            </Grid>
          )}
        </>
      </DazzlingDialog>
    </>
  );
};

export default withStyles(fieldWithTypeMultiSelectStyles, { withTheme: true })(
  FieldWithTypeMultiSelect
);
