import React, { useEffect } from 'react';
import moment from 'moment';

// hooks
import { useSelector, useDispatch } from 'react-redux';

// material-ui
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Skeleton from '@material-ui/lab/Skeleton';
import Typography from '@material-ui/core/Typography';

// icons
import EventListIcon from '@material-ui/icons/DateRange';

// components
import Header from 'components/Header';
import EventItem from './EventItem';
// import CreateEventModal from './CreateEventModal';

// selectors
import getLoadingFromState from 'store/selectors/getLoadingFromState';
import getCurrentGroupId from 'store/selectors/getCurrentGroupId';
import getEvents from 'store/selectors/getEvents';

// action creators
import { fetchEventsForGroup, clearEvents } from 'store/actions/eventActions';

export default function EventList() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { eventsLoading, events = [], groupId } = useSelector(state => ({
    eventsLoading: getLoadingFromState('event', false, false)(state),
    events: getEvents(state),
    groupId: getCurrentGroupId(state),
  }));

  let eventsRemainingMessage = '';

  useEffect(() => {
    dispatch(clearEvents());
    dispatch(fetchEventsForGroup({ groupId }));
    return () => {
      dispatch(clearEvents());
    };
  }, [dispatch, groupId]);

  const renderEventItems = () => {
    let previousDate: any = null;
    const eventItems: any = [];

    events.forEach((event: GroupEvent, index) => {
      const currentDate = moment(event.startDate).format('YYYYMMDD');

      eventItems.push(
        <EventItem
          key={event.id}
          event={event}
          showDate={previousDate !== currentDate}
          isLastChild={events.length - 1 === index}
          displayTopLine={index !== 0}
        />
      );

      previousDate = currentDate;
    });

    return eventItems;
  };

  return (
    <Grid container className={classes.eventsContainer} justifyContent='center'>
      <Grid item xs={12}>
        <Card>
          <CardContent className={classes.cardContent}>
            <Grid container spacing={4} alignItems='flex-start'>
              <Grid item>
                <Header
                  icon={EventListIcon}
                  title='Events'
                  subtitle='Select an event to manage guests and invites'
                  highlightedSubtitle={eventsRemainingMessage}
                />
              </Grid>
            </Grid>

            {eventsLoading && events.length === 0 && (
              <Grid
                container
                id='skeletonContainer'
                spacing={3}
                justifyContent='flex-start'
                alignContent='center'
                className={classes.listItemContainer}
              >
                {Array(3)
                  .fill(1)
                  .map(() => (
                    <Grid item xs={12} sm={10} lg={8} xl={7}>
                      <Skeleton variant='rect' height={148} />
                    </Grid>
                  ))}
              </Grid>
            )}

            {!eventsLoading && events.length === 0 && (
              <Grid
                container
                id='noneFoundContainer'
                className={classes.listItemContainer}
              >
                <Grid item xs={12} sm={10} lg={8} xl={7}>
                  <Typography variant='body1'>No events found</Typography>
                </Grid>
                <Grid item xs={12} sm={10} lg={8} xl={7}>
                  <Typography variant='body2' color='textSecondary'>
                    Create your first event by clicking the button in the top
                    left of your screen.
                  </Typography>
                </Grid>
              </Grid>
            )}

            {events.length > 0 && (
              <Grid
                container
                spacing={3}
                className={classes.paddedListItemContainer}
              >
                {renderEventItems()}
              </Grid>
            )}
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles(theme => {
  return {
    eventsContainer: {
      height: '100%',
      overflowY: 'scroll',
      overflowX: 'hidden',
      paddingTop: 24,
      paddingBottom: 24,
    },

    cardContent: {
      paddingTop: 30,
      marginBottom: 55, // prevents save bar from covering last item on mobile
    },

    buttonContainer: {
      textAlign: 'right',
      marginTop: 10,
    },

    button: {
      marginRight: 8,
    },

    listItemContainer: {
      [theme.breakpoints.up('sm')]: {
        marginLeft: 90,
      },
    },
    paddedListItemContainer: {
      [theme.breakpoints.up('sm')]: {
        marginLeft: 78,
      },
    },
  };
});
