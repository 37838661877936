import React, { useContext } from 'react';

// material-ui
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

// helpers
import { SiteVisualDataContext } from 'components/SiteVisualData';
import { Theme } from 'assets/types';

const LandingFormContainer = function({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) {
  const { firstLogo, firstLogoAlt } = useContext(SiteVisualDataContext);
  const classes = useStyles();

  return (
    <Grid container className={classes.screenContainer}>
      <img className={classes.logo} src={firstLogo} alt={firstLogoAlt} />
      <Grid
        container
        className={classes.formContainer}
        spacing={6}
        direction='column'
        alignItems='flex-start'
        justifyContent='center'
      >
        {children}
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles<Theme>(theme => ({
  screenContainer: {
    backgroundColor: theme.palette.background.landing,
    height: '100%',
    width: '100%',
    padding: '100px 60px 60px',
  },

  formContainer: {
    [theme.breakpoints.down('md')]: {
      alignItems: 'center',
    },
  },

  logo: {
    height: '77px',
    left: '20px',
    position: 'absolute',
    top: '20px',
  },
}));

export default LandingFormContainer;
