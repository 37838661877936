import React, { memo } from 'react';
import { isEqual } from 'lodash';
import { useHistory } from 'react-router-dom';

// material-ui
import { Card, Grid, Hidden, Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

// components
import SuperAvatar from 'components/SuperAvatar';
import EmailItem from 'components/EmailItem';
import PhoneItem from 'components/PhoneItem';
import MixedLink from 'components/MixedLink';

// styles
import { Theme } from 'assets/types';

type LeadCardProps = {
  lead: Lead;
};

const LeadCard = function({ lead }: LeadCardProps) {
  const classes = useStyles();
  const history = useHistory<{ fromPathname: string }>();

  return (
    <Card className={classes.card} onClick={e => e.stopPropagation()}>
      <MixedLink
        to={{
          pathname: `/leads/${lead.id}`,
          state: {
            ...history.location.state,
            fromPathname: history.location.pathname,
          },
        }}
        color='inherit'
      >
        <Grid
          container
          wrap='nowrap'
          spacing={3}
          className={classes.cardContent}
        >
          <Grid item>
            <SuperAvatar
              fullName={lead.fullName}
              variant='roundedSquare'
              raised={Boolean(lead.profilePhotoFileUrl)}
              imageUrl={lead.profilePhotoFileUrl}
              size='2xlarge'
              background='dark'
            />
          </Grid>

          <Grid item xs={10}>
            <Grid
              container
              className={classes.leadHeaderContainer}
              spacing={1}
              alignItems='flex-start'
              wrap='nowrap'
            >
              <Grid item xs={12}>
                <Typography className={classes.leadListName} noWrap>
                  {lead.fullName}
                </Typography>
              </Grid>
            </Grid>
            <Hidden xsDown>
              <Box>
                <CardInfoGroup
                  {...{
                    classes,
                    lead,
                  }}
                  key={lead.id}
                />
              </Box>
            </Hidden>
          </Grid>
        </Grid>
      </MixedLink>
    </Card>
  );
};

const useStyles = makeStyles((theme: Theme) => {
  return {
    card: {
      transition: 'all 0.3s ease',
      width: '100%',
    },
    outlinedContainer: {
      border: `1px solid ${theme.palette.background.outline}`,
    },
    leadHeaderContainer: {
      height: 50,
    },
    leadListName: {
      fontSize: 20,
      fontWeight: 500,
    },
    cardContent: {
      paddingTop: 16,
      paddingLeft: 16,
      paddingRight: 16,
      paddingBottom: 8,
    },
  };
});

const CardInfoGroup = ({ classes, lead }: any) => (
  <div className={classes.outlinedContainer} style={{ pointerEvents: 'none' }}>
    <EmailItem lead={lead} displayActions={false} />
    <PhoneItem lead={lead} displayActions={false} />
  </div>
);

export default memo(LeadCard, isEqual);
