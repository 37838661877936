import React, { useEffect } from 'react';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { isEqual } from 'lodash';

// action creators
import { fetchSuggestedFields } from 'store/actions/suggestedFieldActions';
import { fetchGroupFields } from 'store/actions/groupFieldActions';

// selectors
import getSuggestedFields from 'store/selectors/getSuggestedFields';
import getGroupFields from 'store/selectors/getGroupFields';
import getCurrentGroupId from 'store/selectors/getCurrentGroupId';

// material-ui
import Grid from '@material-ui/core/Grid';

// components
import AutoSuggest from 'components/AutoSuggest';
import getLoadingFromState from 'store/selectors/getLoadingFromState';

const FieldSelector = function({ values, ...restOfProps }: any) {
  const dispatch = useDispatch();
  const location = useLocation();
  const { pathname = '' } = location;
  const onValueSettingsPage = pathname.includes('settings/values');

  // filters out existing values as options if we're creating a new value
  const sources = onValueSettingsPage ? ['PNMAttr', 'FormField'] : [];

  // `suggestedFields` are the TechniPhi "global" fields that every chapter sees by default
  const {
    groupFields,
    groupFieldsLoading,
    groupId,
    suggestedFields,
    suggestedFieldsLoading,
  } = useSelector(
    state => ({
      groupFields: getGroupFields(
        sources,
        'suggestions',
        'searchResult'
      )(state),
      groupFieldsLoading: getLoadingFromState(
        'groupField',
        false,
        false
      )(state),
      groupId: getCurrentGroupId(state),
      suggestedFields: getSuggestedFields('suggestions')(state) as any,
      suggestedFieldsLoading: getLoadingFromState(
        'suggestedField',
        false,
        false
      )(state),
    }),
    isEqual
  );

  const initializeData = () => {
    dispatch(fetchSuggestedFields({}));
    dispatch(fetchGroupFields({ groupId, resultKey: 'searchResult' }));
  };

  useEffect(() => {
    if (!groupFieldsLoading || !suggestedFieldsLoading) {
      initializeData();
    }
  }, []); // eslint-disable-line

  // Suggestions are the items that we're passing to the AutoSuggest search component
  const suggestions = suggestedFields.toJS().concat(groupFields.toJS());
  const loading = suggestedFieldsLoading || groupFieldsLoading;

  const onSearchChange = (value: any) => {
    if (value !== null) {
      if (value.length > 2) {
        dispatch(
          fetchGroupFields({
            groupId,
            search: value,
            resultKey: 'searchResult',
          })
        );
        dispatch(fetchSuggestedFields({ search: value }));
      } else {
        // Handles case where user clears value of input and needs to refetch suggestions
        initializeData();
      }
    }
  };

  return (
    <Grid>
      <AutoSuggest
        autoSuggestType='fields'
        displayAllSuggestionsOnFocus
        formValues={Immutable.fromJS(values)}
        id='autoSuggestField'
        label='Field Name'
        loading={loading}
        onSearchChange={onSearchChange}
        required
        suggestions={suggestions}
        variant='outlined'
        circularProgressProps={{ disableShrink: true }}
        {...restOfProps}
      />
    </Grid>
  );
};

FieldSelector.propTypes = {
  values: PropTypes.instanceOf(Object).isRequired,
};

export default FieldSelector;
