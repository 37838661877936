import { Styles, Theme } from 'assets/types';

const leadSearchBarStyles: Styles = theme => ({
  searchBar: {
    marginLeft: 20,
    marginRight: 20,
    paddingLeft: 15,
    borderRadius: 8,
    paddingRight: 24,
    backgroundColor: (theme as Theme).palette.background.lightPaper,
    transition: 'all 0.25s ease',
  },
  focused: {
    transition: 'all 0.25s ease',
    backgroundColor: (theme as Theme).palette.background.lightPaper,
  },
  textField: {
    marginTop: -9,
  },
  barIcon: {
    textAlign: 'right',
  },
  filterIcon: {
    textAlign: 'center',
  },
  loadingIndicator: {
    height: 24,
  },
  filterSaveSnackbar: {
    marginTop: 60,
  },
});

export default leadSearchBarStyles;
