import httpSaga from 'store/sagas/httpSaga';
import client from 'sources/api';
import {
  AUTH_INTEGRATION_DELETE,
  AUTH_INTEGRATION_DELETE_REQUEST,
} from 'store/constants/authIntegrationTypes';
import { doneIndicator, success } from 'store/actions/httpActions';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import Immutable from 'immutable';

const WATCH_TYPE = AUTH_INTEGRATION_DELETE_REQUEST;
const TYPE = AUTH_INTEGRATION_DELETE;

export function* deleteAuthIntegration({ payload }: any) {
  const { id } = payload;

  // @ts-expect-error ts-migrate(2569) FIXME: Type 'Generator<any, any, unknown>' is not an arra... Remove this comment to see the full error message
  yield* httpSaga(TYPE, call(client.delete, `/auth_integrations/${id}`), {
    metaId: id,
  });

  yield put(success(TYPE, Immutable.fromJS({ id })));

  yield put(doneIndicator(TYPE));
}

export function* watch() {
  yield takeEvery(WATCH_TYPE, deleteAuthIntegration);
}

export default function* root() {
  yield all([fork(watch)]);
}
