import { Map } from 'immutable';
// constants
import {
  REFERRAL_FORM_CREATE,
  REFERRAL_FORM_DELETE,
  REFERRAL_FORM_UPDATE,
  REFERRAL_FORM_FETCH_FOR_GROUP,
  REFERRAL_FORM_FETCH_BY_ID,
  REFERRAL_FORM_SEND_PREVIEW_EMAIL,
  REFERRAL_FORM_CREATE_TOKEN,
  REFERRAL_FORM_SUBMIT,
} from 'store/constants/referralFormTypes';
// helpers
import httpReducer from './helpers/httpReducer';
import remove from './callbacks/remove';
import merge from './callbacks/merge';

export default function reduceReferralForm(state = Map(), action = {}) {
  switch ((action as any).type) {
    case REFERRAL_FORM_DELETE:
      return httpReducer(state, action, {
        entity: 'referralForm',
        callback: remove,
      });
    case REFERRAL_FORM_CREATE:
      return httpReducer(state, action, {
        entity: 'referralForm',
      });
    case REFERRAL_FORM_UPDATE:
      return httpReducer(state, action, {
        entity: 'referralForm',
        updateResult: true,
        mergeItems: true,
        disableMergeDeep: true,
        appendResult: true,
      });
    case REFERRAL_FORM_FETCH_FOR_GROUP:
      return httpReducer(state, action, {
        entity: 'referralForm',
        disableMergeDeep: true,
        appendResult: true,
      });
    case REFERRAL_FORM_FETCH_BY_ID:
      return httpReducer(state, action, {
        entity: 'referralForm',
        appendResult: true,
        mergeItems: false,
      });
    case REFERRAL_FORM_SEND_PREVIEW_EMAIL:
      return httpReducer(state, action, {
        updateResult: false,
        updateItemsById: false,
      });
    case REFERRAL_FORM_CREATE_TOKEN:
      return httpReducer(state, action, {
        callback: merge,
        updateResult: false,
      });
    case REFERRAL_FORM_SUBMIT:
      return httpReducer(state, action, {
        entity: 'referralForm',
      });
    default:
      return state;
  }
}
