import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

// MUI components
import { Grid } from '@material-ui/core';

// components
import RecruitmentActivityOverviewCard from 'routes/Analytics/RecruitmentActivityOverviewCard';
import RecruitmentActivityMilestonesCard from 'routes/Analytics/RecruitmentActivityMilestonesCard';
import RecruitmentActivityTagsCard from 'routes/Analytics/RecruitmentActivityTagsCard';
import StatusesSelectorCard from './StatusesSelectorCard';

// action creators
import { clearReports } from 'store/actions/reportActions';

const RecruitmentActivityAnalytics = function() {
  const dispatch = useDispatch();
  const [selectedStatusPositions, setSelectedStatusPositions] = useState([]);

  useEffect(() => {
    dispatch(clearReports());
  }, [dispatch]);

  const handleStatusPositionsChange = (newStatusPositions: any) => {
    dispatch(clearReports());
    setSelectedStatusPositions(newStatusPositions);
  };

  return (
    <Grid container spacing={2} alignContent='flex-start'>
      <Grid item xs={12}>
        <StatusesSelectorCard
          handleStatusPositionsChange={handleStatusPositionsChange}
        />
      </Grid>
      <Grid item xs={12}>
        <RecruitmentActivityOverviewCard
          statusPositions={selectedStatusPositions || []}
          reportFormName='recruitmentActivityReportForm'
          exportFormName='recruitmentActivityExportForm'
          statLabel='Filtered Leads'
          HeaderProps={{
            title: 'Status Story Overview',
            subtitle:
              'Select statuses to analyze the joining process story for your leads',
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <RecruitmentActivityTagsCard
          reportFormName='recruitmentActivityTagsReportForm'
          exportFormName='recruitmentActivityTagsExportForm'
          statusPositions={selectedStatusPositions || []}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <RecruitmentActivityMilestonesCard
          reportFormName='recruitmentActivityMilestonesReportForm'
          exportFormName='recruitmentActivityMilestonesExportForm'
          statusPositions={selectedStatusPositions || []}
        />
      </Grid>
    </Grid>
  );
};

export default RecruitmentActivityAnalytics;
