import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';

// components
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import { Switch as MuiSwitch } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

const Switch = function({
  containerClassName,
  errorClassName,
  id,
  input,
  label,
  meta: { active, dirty, error, touched },
  subText,
  keepChecked,
  ...rest
}: any) {
  const classes = useStyles();

  const shouldDisplayError = () => error && !active && (dirty || touched);

  const renderError = () => {
    let element;

    if (shouldDisplayError()) {
      element = (
        <FormHelperText
          id='switchErrorMessage'
          className={errorClassName}
          error
        >
          {error[0]}
        </FormHelperText>
      );
    }

    return element;
  };

  return (
    <FormControl
      className={classNames(containerClassName, classes.formControl)}
    >
      <FormControlLabel
        control={
          <MuiSwitch
            checked={input.checked}
            color='primary'
            error={shouldDisplayError()}
            inputProps={{
              id,
              ...input,
              onChange:
                input.checked && keepChecked ? () => {} : input.onChange,
            }}
            {...rest}
          />
        }
        label={label}
      />

      <Typography color='textSecondary' id='subText'>
        {subText}
      </Typography>

      {renderError()}
    </FormControl>
  );
};

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 140,
  },
}));

Switch.propTypes = {
  containerClassName: PropTypes.string,
  errorClassName: PropTypes.string,
  id: PropTypes.string,
  input: PropTypes.instanceOf(Object).isRequired,
  label: PropTypes.string,
  meta: PropTypes.instanceOf(Object).isRequired,
  subText: PropTypes.string,
  keepChecked: PropTypes.bool,
};

Switch.defaultProps = {
  containerClassName: '',
  errorClassName: '',
  id: '',
  label: '',
  subText: '',
  keepChecked: false,
};

export default Switch;
