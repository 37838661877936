import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';

// components
import DazzlingDialog from 'components/DazzlingDialog';
import { Form } from 'react-final-form';
import Warning from 'components/Warning';

// material-ui
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { WithStyles, withStyles } from '@material-ui/core/styles';

// actions
import {
  ManuallyPushLeadsParams,
  updateManuallyPushLeads,
} from 'store/actions/leadActions';

// helpers
import { firstLetterToUppercase } from 'helpers/transform';

// icons
import ShareIcon from '@material-ui/icons/Share';

// styles
import manuallyPushLeadsModalStyles from 'routes/LeadList/LeadListView/LeadListActions/ManuallyPushLeadsModal/manuallyPushLeadsModal.style';

const FORM_NAME = 'manuallyPushLeadsModal';

export interface ManuallyPushLeadsModalProps
  extends WithStyles<typeof manuallyPushLeadsModalStyles> {
  open: boolean;
  ids: number[];
  leads: Lead[];
  allSelected: boolean;
  groupId: number;
  onClose: () => void;
  refetchLeads: () => void;
  filters: LeadFetchFilters;
}

const ManuallyPushLeadsModal = ({
  classes,
  open,
  onClose,
  ids,
  allSelected,
  groupId,
  leads,
  refetchLeads,
  filters,
}: ManuallyPushLeadsModalProps) => {
  const dispatch = useDispatch();

  const getFirstSharedLead = (listOfLeads: Lead[]) =>
    listOfLeads.find(({ isShared }) => !!isShared);

  const oneLeadSelected = !allSelected && ids.length === 1;
  const leadCountText = allSelected ? 'all' : ids.length;
  const leadLabel = oneLeadSelected ? 'lead' : 'leads';

  const isThereSharedLead = useMemo<boolean>(() => {
    const sharedLead = getFirstSharedLead(
      allSelected ? leads : leads.filter(({ id }) => ids.indexOf(id) !== -1)
    );

    return !!sharedLead;
  }, [leads, allSelected, ids]);

  const leadsUpdate = () => {
    refetchLeads();
  };

  const onSubmit = () => {
    const params: ManuallyPushLeadsParams = {
      pnmIds: allSelected ? 'all' : ids,
      leadsUpdate,
      formName: FORM_NAME,
      groupId,
      ...(allSelected ? { excludedPnmIds: ids, ...filters } : {}),
    };

    dispatch(updateManuallyPushLeads(params));
  };

  return (
    <Form id={FORM_NAME} onSubmit={onSubmit}>
      {({ handleSubmit, form: { restart } }) => (
        <DazzlingDialog
          id='manuallyPushLeads'
          alertProps={{
            message: `${firstLetterToUppercase(leadLabel)} ${
              oneLeadSelected ? 'is' : 'are'
            } pushing in background, you will receive a notification when completed`,
          }}
          handleClose={onClose}
          open={open}
          formName={FORM_NAME}
          onAccept={handleSubmit}
          headerProps={{
            icon: ShareIcon,
            title: `Manually Push ${firstLetterToUppercase(leadLabel)}`,
            alignItems: 'center',
          }}
          resetForm={restart}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid
                container
                justifyContent='center'
                alignItems='center'
                spacing={2}
              >
                {isThereSharedLead && (
                  <Grid item xs={12}>
                    <Warning
                      color='red'
                      message='You have selected leads that were already shared, it will create a duplicate lead for the Chapters. Are you sure you want to do that?'
                      border
                    />
                  </Grid>
                )}
                <Grid item xs={12}>
                  <Typography paragraph>
                    Are you sure you want to share the
                    <span className={classes.spacing} />
                    {leadCountText}
                    <span className={classes.spacing} />
                    of selected
                    <span className={classes.spacing} />
                    {leadLabel}
                    <span className={classes.spacing} />
                    with your linked chapters?
                    <br />
                    You will not be able to undo this action and the selected
                    leads will be pushed to all linked chapters upon
                    confirmation.
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DazzlingDialog>
      )}
    </Form>
  );
};

export default withStyles(manuallyPushLeadsModalStyles)(ManuallyPushLeadsModal);
