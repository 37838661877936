import React, { useState, useEffect } from 'react';
import moment from 'moment-timezone';

// redux hooks
import { useSelector } from 'react-redux';

// api
import client from 'sources/api';

// components
import { Grid, Typography, Box } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

// selectors
import getCurrentGroupId from 'store/selectors/getCurrentGroupId';

interface Stats {
  avgTime: number;
  totalCount: number;
  totalTime: number;
}

interface CallsStatsProps {
  statsUpToDate: boolean;
  onStatsUpdated: () => void;
}

export default function CallsStats({
  statsUpToDate,
  onStatsUpdated,
}: CallsStatsProps) {
  const [stats, setStats] = useState<Stats>({
    avgTime: 0,
    totalCount: 0,
    totalTime: 0,
  });
  const [loading, setLoading] = useState(false);
  const currentGroupId = useSelector(getCurrentGroupId);
  useEffect(() => {
    if (!statsUpToDate) {
      setLoading(true);
      client
        .get<Stats>('/calls/current_day_stats', {
          params: {
            group_id: currentGroupId.toString(),
            timezone_name: moment.tz.guess(),
          },
        })
        .then(({ data }) => {
          setStats(data);
          setLoading(false);
          onStatsUpdated();
        });
    }
  }, [statsUpToDate, currentGroupId, onStatsUpdated]);
  return (
    <Grid container direction='column'>
      <Box paddingY='20px' paddingX={2} clone>
        <Grid item>
          <Grid container justifyContent='center' direction='column'>
            <Grid item>
              <Typography variant='h6'>Calls Statistics</Typography>
            </Grid>
            <Grid item>
              <Box fontSize='0.8rem' clone>
                <Typography variant='subtitle2' color='textSecondary'>
                  Past 24 hours
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Grid item>
        <Box height='2px' width='100%' bgcolor='background.darkPaper' />
      </Grid>
      <Box paddingY={1} paddingX={2} clone>
        <Grid item>
          <Grid container justifyContent='center' direction='column'>
            <Grid item>
              <Typography variant='h6'>
                {loading ? <Skeleton /> : stats.totalCount}
              </Typography>
            </Grid>
            <Grid item>
              <Box fontSize='0.8rem' clone>
                <Typography variant='subtitle2' color='textSecondary'>
                  Total calls
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Box paddingY={1} paddingX={2} clone>
        <Grid item>
          <Grid container justifyContent='center' direction='column'>
            <Grid item>
              <Typography variant='h6'>
                {loading ? (
                  <Skeleton />
                ) : (
                  moment()
                    .startOf('day')
                    .seconds(stats.totalTime || 0)
                    .format('HH:mm:ss')
                )}
              </Typography>
            </Grid>
            <Grid item>
              <Box fontSize='0.8rem' clone>
                <Typography variant='subtitle2' color='textSecondary'>
                  Total call duration
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Box paddingY={1} paddingX={2} clone>
        <Grid item>
          <Grid container justifyContent='center' direction='column'>
            <Grid item>
              <Typography variant='h6'>
                {loading ? (
                  <Skeleton />
                ) : (
                  moment()
                    .startOf('day')
                    .seconds(stats.avgTime || 0)
                    .format('HH:mm:ss')
                )}
              </Typography>
            </Grid>
            <Grid item>
              <Box fontSize='0.8rem' clone>
                <Typography variant='subtitle2' color='textSecondary'>
                  Average call duration
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );
}
