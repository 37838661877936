import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

// action creators
import { doneIndicator } from 'store/actions/httpActions';

// api
import client from 'sources/api';

// constants
import {
  STATUS_FETCH_REQUEST,
  STATUS_FETCH,
} from 'store/constants/statusTypes';

// schemas
import { statusSchema } from 'store/schemas/statusSchema';

// helpers
import httpSaga from 'store/sagas/httpSaga';

const WATCH_TYPE = STATUS_FETCH_REQUEST;
const TYPE = STATUS_FETCH;

export function* fetchStatuses(action: any) {
  const {
    payload: { groupId },
  } = action;

  // @ts-expect-error ts-migrate(2569) FIXME: Type 'Generator<any, any, unknown>' is not an arra... Remove this comment to see the full error message
  yield* httpSaga(
    TYPE,
    call(client.get, '/statuses', {
      params: { group_id: groupId },
    }),
    {
      schema: [statusSchema],
    }
  );

  yield put(doneIndicator(TYPE));
}

export function* watch() {
  yield takeEvery(WATCH_TYPE, fetchStatuses);
}

export default function* root() {
  yield all([fork(watch)]);
}
