import { Styles } from 'assets/types';

const filesListStyles: Styles = () => ({
  fileTitle: {
    fontWeight: 500,
    color: 'rgba(255,255,255, 0.5)',
    fontSize: '0.875rem',
    marginTop: 10,
  },
});

export default filesListStyles;
