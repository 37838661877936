import React, { useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { isEqual } from 'lodash';
import { Moment } from 'moment';

// MUI components
import {
  Grid,
  Card,
  CardContent,
  WithStyles,
  withStyles,
} from '@material-ui/core';
import { SwapVert } from '@material-ui/icons';

// components
import Table from 'components/Table';
import AnalyticsActionBar from 'components/AnalyticsActionBar';

// action creators
import {
  createGroupStatusReportByChapter,
  createParentGroupsStatusReportAll,
} from 'store/actions/apiTaskActions';

// selectors
import getCurrentGroupId from 'store/selectors/getCurrentGroupId';
import getForm from 'store/selectors/getForm';
import getSliceEntityById from 'store/selectors/getSliceEntityById';
import getIsCurrentGroupCouncil from 'store/selectors/getIsCurrentGroupCouncil';
import getIsCurrentGroupOrganization from 'store/selectors/getIsCurrentGroupOrganization';
import getIsCouncilAdmin from 'store/selectors/getIsCouncilAdmin';

// styles
import chapterStatusCardStyle from 'routes/GroupAnalytics/components/ChapterStatusCard/chapterStatusCard.style';

const REPORT_FORM = 'groupByChapterStatusReportForm';

export interface ContentType {
  change: number | null;
  currentCount: number | null;
  statusId: number | null;
  percentOfList: string | null;
  statusAbbreviation: string | null;
  statusTitle: string | null;
}

export interface ColumnType {
  key: string;
  label: string;
}

export interface StatusCardProps
  extends WithStyles<typeof chapterStatusCardStyle> {}

const ChapterStatusCard = ({ classes }: StatusCardProps) => {
  const dispatch = useDispatch();
  const initialOrder = 'desc';
  const initialOrderBy = 'status';

  const {
    currentGroupId,
    report: {
      data: {
        content: unformattedContent = [],
        meta: { columns: unformattedColumns = [] } = {},
      } = [],
      id: reportId,
    } = {},
    form: { isSubmitting },
    isCurrentGroupCouncil,
    isCurrentGroupOrganization,
    isCouncilAdmin,
  } = useSelector(
    state => ({
      currentGroupId: getCurrentGroupId(state),
      form: getForm(REPORT_FORM)(state),
      report: getSliceEntityById(
        'report',
        'ReportTypes.GROUP_STATUS_BY_CHAPTER',
        {}
      )(state),
      isCurrentGroupCouncil: getIsCurrentGroupCouncil(state),
      isCurrentGroupOrganization: getIsCurrentGroupOrganization(state),
      isCouncilAdmin: getIsCouncilAdmin(state),
    }),
    isEqual
  );

  const columns = (unformattedColumns || []).map(
    ({ key, label }: ColumnType) => ({
      key,
      label,
      disableSort: true,
    })
  );

  const columnsForCouncilOrOrganizationAdmin = (unformattedColumns || [])
    .filter(({ key }: ColumnType) => key !== 'statusTitle')
    .map(({ key, label }: ColumnType) => ({
      key,
      label,
      disableSort: true,
    }));

  const content = useMemo(() => {
    if (!unformattedContent) return [];

    if (isSubmitting || !unformattedContent.length) return [];

    return unformattedContent.map(({ statusId, ...rest }: ContentType) => ({
      key: statusId,
      ...(rest || {}),
    }));
  }, [isSubmitting, unformattedContent]);

  const createReport = useCallback(
    ({
      startDate,
      endDate,
      forceRefresh,
      groupId = currentGroupId,
    }: {
      startDate: Moment;
      endDate: Moment;
      forceRefresh: boolean;
      groupId?: number;
    }) => {
      const args = {
        groupId,
        formName: REPORT_FORM,
        startDate,
        endDate,
        forceRefresh,
      };
      dispatch(
        groupId === currentGroupId
          ? createParentGroupsStatusReportAll(args)
          : createGroupStatusReportByChapter(args)
      );
    },
    [dispatch, currentGroupId]
  );

  const titleText = useMemo<string>(() => {
    if (isCurrentGroupCouncil || isCurrentGroupOrganization) {
      return 'Chapter Status Report';
    }

    return 'Status Report';
  }, [isCurrentGroupCouncil, isCurrentGroupOrganization]);

  const subTitleText = useMemo<string>(() => {
    if (isCurrentGroupCouncil || isCurrentGroupOrganization) {
      return '';
    }

    return 'View status trends and metrics for the past 90 days';
  }, [isCurrentGroupCouncil, isCurrentGroupOrganization]);

  return (
    <Card className={classes.cardContainer}>
      <CardContent className={classes.cardContent}>
        <AnalyticsActionBar
          onCreateReport={createReport}
          isCouncilAdmin={isCouncilAdmin}
          title={titleText}
          subtitle={subTitleText}
          icon={SwapVert}
          reportId={reportId}
          loading={isSubmitting}
          currentGroupId={currentGroupId}
        />
        <Grid item xs={12} className={classes.tableContainer}>
          <Table
            columns={
              isCurrentGroupCouncil || isCurrentGroupOrganization
                ? columnsForCouncilOrOrganizationAdmin
                : columns
            }
            data={content}
            variant='outlined'
            numberOfSkeletonRows={isSubmitting ? 8 : 0}
            fetchSortedData={() => {}}
            initialOrder={initialOrder}
            initialOrderBy={initialOrderBy}
            TableRowProps={{
              classes: {
                root: classes.root,
              },
            }}
            TableCellProps={{
              className: classes.tableCell,
            }}
            HeaderTableCellProps={{
              className: classes.headerTableCell,
            }}
            TablePaginationProps={{ count: null }}
          />
        </Grid>
      </CardContent>
    </Card>
  );
};

export default withStyles(chapterStatusCardStyle, { withTheme: true })(
  ChapterStatusCard
);
