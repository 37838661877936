import React, { useCallback } from 'react';
import moment from 'moment';
import MomentUtils from '@date-io/moment';

// MUI components
import { makeStyles } from '@material-ui/core/styles';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardDateTimePicker,
  KeyboardTimePicker,
} from '@material-ui/pickers';

export default function DateButton({
  id,
  value,
  onChange,
  type = 'date',
  label = '',
  DatePickerProps = {},
}: {
  id: string;
  value: any; // TODO: PropTypes.instanceOf(Object)
  onChange: (...args: any[]) => any;
  label?: string;
  type?: 'date' | 'datetime' | 'time';
  ButtonProps?: any; // TODO: PropTypes.instanceOf(Object)
  DatePickerProps?: any; // TODO: PropTypes.instanceOf(Object)
  variant?: 'default' | 'input';
}) {
  const classes = useStyles();

  const getContainerStyle = useCallback(() => {
    switch (type) {
      case 'time':
        return classes.smallContainer;
      case 'date':
        return classes.mediumContainer;
      case 'datetime':
        return classes.largeContainer;
    }
  }, [
    classes.largeContainer,
    classes.mediumContainer,
    classes.smallContainer,
    type,
  ]);

  const renderPicker = useCallback(() => {
    const pickerProps = {
      onChange,
      value: value || null,
      label,
      fullWidth: true,
      autoOk: true,
      variant: 'inline',
      inputVariant: 'outlined',
      ...DatePickerProps,
      InputProps: {
        classes: { root: classes.text },
      },
    };

    switch (type) {
      case 'time':
        pickerProps.invalidDateMessage = 'Select a valid time';
        pickerProps.minutesStep = 5;
        return <KeyboardTimePicker format='LT' {...pickerProps} />;
      case 'date':
        return (
          <KeyboardDatePicker
            format='MM/DD/YYYY'
            placeholder='mm/dd/yyyy'
            {...pickerProps}
          />
        );
      case 'datetime':
        return (
          <KeyboardDateTimePicker
            format='MM/DD/YYYY hh:mm A'
            {...pickerProps}
          />
        );
    }
  }, [DatePickerProps, classes.text, label, onChange, type, value]);

  return (
    <div className={getContainerStyle()}>
      <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
        {renderPicker()}
      </MuiPickersUtilsProvider>
    </div>
  );
}

const useStyles = makeStyles(theme => {
  return {
    smallContainer: {
      minWidth: 135,
    },
    mediumContainer: {
      minWidth: 160,
    },
    largeContainer: { minWidth: 215 },

    text: {
      fontSize: 14,
    },
  };
});
