import React from 'react';
import Grid from '@material-ui/core/Grid';
import LabelAveragesAnalyticsCard from 'routes/LabelAnalytics/LabelAveragesAnalyticsCard';
import LabelTotalsAnalyticsCard from 'routes/LabelAnalytics/LabelTotalsAnalyticsCard';

const LabelAnalytics = () => {
  return (
    <Grid container spacing={2} alignContent='flex-start'>
      <Grid item xs={12}>
        <LabelAveragesAnalyticsCard />
      </Grid>
      <Grid item xs={12}>
        <LabelTotalsAnalyticsCard />
      </Grid>
    </Grid>
  );
};

export default LabelAnalytics;
