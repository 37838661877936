import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import validate from 'validate.js';

// material-ui
import Grid from '@material-ui/core/Grid';

// icons
import { ListAlt } from '@material-ui/icons';

//components
import DazzlingDialog from 'components/DazzlingDialog';
import FieldSelector from 'components/FieldSelector';
import TextField from 'components/TextField';
import { Form, Field } from 'react-final-form';

// action creators
import { createLeadAttribute } from 'store/actions/leadAttributeActions';

// selectors
import getCurrentGroupId from 'store/selectors/getCurrentGroupId';
import { useLeadAttrsQuery } from 'api/leadAttrs';

const FORM = 'createLeadAttributeForm';

type Props = {
  onClose: (...args: any[]) => any;
  open: boolean;
  leadId: number;
};

const CreateLeadAttributeModal = ({ open, onClose, leadId }: Props) => {
  const { groupId } = useSelector(state => ({
    groupId: getCurrentGroupId(state),
  }));

  const dispatch = useDispatch();
  const dispatchCreateLeadAttribute = useCallback(
    values => {
      const { label } = values.label;
      const value = values.value;

      dispatch(
        createLeadAttribute({ groupId, leadId, formName: FORM, label, value })
      );
    },
    [dispatch, groupId, leadId]
  );
  const { refetch } = useLeadAttrsQuery(leadId);

  const dialogContent = (values: any) => {
    return (
      <Grid container spacing={2} direction='column'>
        <Grid item xs={12} sm={6}>
          <Field name='label' values={values} component={FieldSelector} />
        </Grid>

        <Grid item xs={12} sm={6}>
          <Field
            name='value'
            label='Field Value'
            variant='outlined'
            required
            component={TextField}
          />
        </Grid>
      </Grid>
    );
  };

  return (
    <Form
      id='createLeadAttributeForm'
      validate={values => validate(values, schema)}
      onSubmit={values => dispatchCreateLeadAttribute(values)}
      render={({
        handleSubmit,
        invalid,
        values,
        form: { reset, getRegisteredFields, resetFieldState },
      }) => (
        <DazzlingDialog
          acceptLabel='Create'
          disabled={invalid}
          formName={FORM}
          // @ts-expect-error ts-migrate(2322) FIXME: Type '{ children: Element; acceptLabel: string; di... Remove this comment to see the full error message
          getRegisteredFields={getRegisteredFields}
          handleClose={onClose}
          headerProps={dialogData}
          height='short'
          id='createLeadAttribute'
          onAccept={handleSubmit}
          open={open}
          resetFieldState={resetFieldState}
          resetForm={reset}
          onExit={() => {
            refetch();
          }}
          submitOnEnter={false}
        >
          {dialogContent(values)}
        </DazzlingDialog>
      )}
    />
  );
};

const dialogData = {
  icon: ListAlt,
  title: 'New Info Field',
  subtitle: `Create a field to track info about your leads`,
};

// schemas
const schema = {
  label: {
    presence: true,
  },
  value: {
    presence: true,
  },
};

export default CreateLeadAttributeModal;
