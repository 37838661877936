import { Styles } from 'assets/types';

const formsStyles: Styles = theme => ({
  formsContainer: {
    height: '100%',
    overflowY: 'auto',
    overflowX: 'hidden',
    padding: 24,
  },

  cardContent: {
    paddingTop: 30,
    marginBottom: 55, // prevents save bar from covering last item on mobile
  },

  buttonContainer: {
    textAlign: 'right',
    marginTop: 10,
  },

  button: {
    marginRight: 8,
  },

  listItemContainer: {
    [theme.breakpoints.up('sm')]: {
      marginLeft: 90,
    },
  },
  paddedListItemContainer: {
    [theme.breakpoints.up('sm')]: {
      marginLeft: 78,
    },
  },
});

export default formsStyles;
