import Immutable from 'immutable';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

// action creators
import { doneIndicator, success } from 'store/actions/httpActions';

// api
import client from 'sources/api';

// constants
import {
  STATUS_DELETE_REQUEST,
  STATUS_DELETE,
} from 'store/constants/statusTypes';

// schemas
import { statusSchema } from 'store/schemas/statusSchema';

// helpers
import httpSaga from 'store/sagas/httpSaga';

import { setFormSubmitSucceeded } from 'store/actions/formActions';

const WATCH_TYPE = STATUS_DELETE_REQUEST;
const TYPE = STATUS_DELETE;

export function* deleteStatuses(action: any) {
  const {
    payload: { formName, statuses },
  } = action;
  yield all(
    statuses.map((statuses: any) => {
      const { groupId, id } = statuses;

      return httpSaga(
        TYPE,
        call(client.delete, `/statuses/${id}?group_id=${groupId}`),
        {
          formName,
          dispatchSuccess: false,
          dispatchFormSuccess: false,
          shouldDestroyForm: false,
          schema: statusSchema,
        }
      );
    })
  );
  yield put(setFormSubmitSucceeded(formName));
  yield put(success(TYPE, Immutable.fromJS(statuses)));
  yield put(doneIndicator(TYPE));
}

export function* watch() {
  yield takeEvery(WATCH_TYPE, deleteStatuses);
}

export default function* root() {
  yield all([fork(watch)]);
}
