import React, { useContext } from 'react';
import PropTypes from 'prop-types';

// components
import LandingButton from 'layout/Landing/components/LandingButton';

// material-ui
import { Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';

// helpers
import { SiteVisualDataContext } from 'components/SiteVisualData';

const SignupFormActions = function({
  hasSubmitFailed,
  hasSubmitSucceeded,
  isSubmitting,
  invalid,
}: any) {
  const { signupFormActionsText } = useContext(SiteVisualDataContext);
  const classes = useStyles();

  return (
    <Grid item className={classes.signupActions}>
      <Grid
        container
        spacing={2}
        justifyContent='space-between'
        className={classes.container}
      >
        <Grid item xs={6} sm={4}>
          <LandingButton
            id='signupButton'
            type='submit'
            disabled={hasSubmitFailed || isSubmitting || invalid}
            loading={isSubmitting}
            success={hasSubmitSucceeded}
            fail={hasSubmitFailed}
          >
            Sign Up
          </LandingButton>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Typography variant='subtitle2' display='inline'>
            {signupFormActionsText + ' '}
            <Link
              className={classes.boldLink}
              href='https://www.phiredup.com/privacy-policy'
              rel='noopener'
              target='_blank'
              color='textPrimary'
            >
              Privacy Policy
            </Link>
            {' and '}
            <Link
              className={classes.boldLink}
              href='https://www.phiredup.com/eula'
              rel='noopener'
              target='_blank'
              color='textPrimary'
            >
              End User License Agreement
            </Link>
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

SignupFormActions.propTypes = {
  hasSubmitFailed: PropTypes.bool.isRequired,
  hasSubmitSucceeded: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
};

const useStyles = makeStyles(theme => ({
  signupActions: {
    maxWidth: '500px',
  },

  container: {
    [theme.breakpoints.only('xs')]: {
      textAlign: 'center',
      justifyContent: 'center',
    },
  },

  boldLink: {
    fontWeight: 'bold',

    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

export default SignupFormActions;
