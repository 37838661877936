import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
// components
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import Tooltip from '@material-ui/core/Tooltip';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import Chip from '@material-ui/core/Chip';
import Switch from '@material-ui/core/Switch';
const Select = function({
  containerClassName,
  menuItemClassName,
  errorClassName,
  input,
  meta: { active, dirty, error, touched },
  options,
  id,
  disabled,
  label,
  multiple,
  subText,
  toggleLabel,
  ...rest
}: any) {
  const classes = useStyles();
  const shouldDisplayError = () => {
    return error && !active && (dirty || touched);
  };
  const renderError = () => {
    let element;
    if (shouldDisplayError()) {
      element = (
        <FormHelperText
          id='selectErrorMessage'
          className={errorClassName}
          error
        >
          {error[0]}
        </FormHelperText>
      );
    } else {
      element = <FormHelperText>{subText}</FormHelperText>;
    }
    return element;
  };

  let value: any;
  let excluded: boolean | undefined;

  if (toggleLabel) {
    [excluded, value] = input.value;
  } else {
    value = input.value;
  }

  if (multiple) {
    value = Array.isArray(input.value) ? value : [];
  }

  const renderOptions = () => {
    const elements: any = [];

    toggleLabel &&
      elements.push(
        <MenuItem id='toggleItem' className={menuItemClassName}>
          <Switch checked={excluded} color='primary' />
          <ListItemText>{toggleLabel}</ListItemText>
        </MenuItem>
      );

    options.forEach((option: any) => {
      elements.push(
        <MenuItem
          id='optionItem'
          className={menuItemClassName}
          key={option.value}
          value={option.value}
        >
          {option.description ? (
            <Tooltip id='optionTooltip' title={option.description}>
              <Grid container>
                <Typography noWrap id='optionLabel'>
                  {option.label}
                </Typography>
              </Grid>
            </Tooltip>
          ) : (
            <>
              {multiple && <Checkbox checked={value.includes(option.value)} />}
              <ListItemText primary={option.label} />
            </>
          )}
        </MenuItem>
      );
    });
    return elements;
  };
  const SelectProps = {};
  if (multiple) {
    (SelectProps as any).multiple = true;
    (SelectProps as any).renderValue = (selected: any) => (
      <Grid container spacing={1}>
        {selected.map((selectedValue: any) => (
          <Grid item xs>
            <Chip
              key={selectedValue}
              label={
                (
                  options.find(
                    (option = {}) => selectedValue === (option as any).value
                  ) || {}
                ).label
              }
              className={classes.chip}
            />
          </Grid>
        ))}
      </Grid>
    );
  }

  const handleChange = (event: any) => {
    if (toggleLabel) {
      input.onChange([
        event.target.value.includes(undefined) ? !excluded : excluded,
        event.target.value.filter(Boolean),
      ]);
    } else {
      input.onChange(event.target.value);
    }
  };
  return (
    <FormControl
      className={classNames(containerClassName, classes.formControl)}
    >
      <TextField
        id={id}
        select
        onChange={handleChange}
        value={value}
        label={label}
        disabled={disabled}
        SelectProps={SelectProps}
        {...rest}
      >
        {renderOptions()}
      </TextField>

      {renderError()}
    </FormControl>
  );
};
const useStyles = makeStyles(theme => ({
  formControl: {
    width: '100%',
  },
  chip: {
    backgroundColor: (theme.palette.background as any).lightPaper,
  },
}));
Select.propTypes = {
  containerClassName: PropTypes.string,
  menuItemClassName: PropTypes.string,
  errorClassName: PropTypes.string,
  input: PropTypes.instanceOf(Object).isRequired,
  meta: PropTypes.instanceOf(Object).isRequired,
  options: PropTypes.instanceOf(Array),
  id: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  multiple: PropTypes.bool,
  subText: PropTypes.string,
  toggleLabel: PropTypes.string,
};
Select.defaultProps = {
  containerClassName: '',
  menuItemClassName: '',
  errorClassName: '',
  options: [],
  id: '',
  disabled: false,
  label: '',
  subText: '',
  toggleLabel: '',
};
export default Select;
