import { useMutation, useQuery, useQueryClient } from 'react-query';
import { AxiosError, AxiosRequestConfig } from 'axios';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { useParams, useLocation } from 'react-router';
import { client, Resource } from 'api';
import getCurrentGroupId from 'store/selectors/getCurrentGroupId';

export const useEventTimeSlotsQuery = (
  eventId: number,
  groupId?: number,
  isAvailable?: boolean
) => {
  const reduxGroupId = useSelector(getCurrentGroupId);
  const params = {
    groupId: groupId || reduxGroupId,
    eventId,
    isAvailable,
  };
  const { search } = useLocation();

  const urlParams = new URLSearchParams(search);
  const token = urlParams.get('token');
  const config: AxiosRequestConfig = { params };
  if (token) {
    config.headers = { Authorization: `Bearer ${token}` };
  }
  return useQuery<EventTimeSlot[], Error>(
    [Resource.EventTimeSlots, params],
    () =>
      client.get(Resource.EventTimeSlots, config).then(({ data }) =>
        data.map((ets: EventTimeSlot) => ({
          ...ets,
          startDate: moment.utc(ets.startDate).local(),
          endDate: moment.utc(ets.endDate).local(),
        }))
      ),
    {
      enabled: Boolean(params.groupId && params.eventId),
    }
  );
};

interface NewEventTimeSlot {
  eventTimeSlotId?: number;
  startDate?: string;
  endDate?: string;
  description?: string;
  guestsLimit?: number;
}

export const useCreateEventTimeSlotsMutation = () => {
  const queryClient = useQueryClient();
  const { eventId } = useParams<{ eventId: string }>();
  const params = {
    groupId: useSelector(getCurrentGroupId),
    eventId: Number(eventId),
  };
  return useMutation<EventTimeSlot[], Error, NewEventTimeSlot[]>(
    (newTimeSlots: NewEventTimeSlot[]) =>
      client
        .post(Resource.EventTimeSlots, { items: newTimeSlots }, { params })
        .then(({ data }) => data),
    {
      onSuccess: newData => {
        const queryParams = { ...params, available: false };
        const previousData =
          queryClient.getQueryData<EventTimeSlot[]>([
            Resource.EventTimeSlots,
            queryParams,
          ]) || [];
        const modifiedNewData = newData.map((ets: EventTimeSlot) => ({
          ...ets,
          startDate: moment.utc(ets.startDate).local(),
          endDate: moment.utc(ets.endDate).local(),
        }));
        queryClient.setQueryData(
          [Resource.EventTimeSlots, queryParams],
          [...previousData, ...modifiedNewData]
        );
      },
    }
  );
};

export const useUpdateEventTimeSlotsMutation = () => {
  const queryClient = useQueryClient();
  const { eventId } = useParams<{ eventId: string }>();
  const params = {
    groupId: useSelector(getCurrentGroupId),
    eventId: Number(eventId),
  };
  return useMutation<
    EventTimeSlot[],
    AxiosError<{ msg: string }>,
    NewEventTimeSlot[]
  >(
    (newTimeSlots: NewEventTimeSlot[]) =>
      client
        .patch(Resource.EventTimeSlots, { items: newTimeSlots }, { params })
        .then(({ data }) => data),
    {
      onSuccess: newData => {
        const queryParams = { ...params, available: false };
        const previousData =
          queryClient.getQueryData<EventTimeSlot[]>([
            Resource.EventTimeSlots,
            queryParams,
          ]) || [];
        const modifiedNewData = newData.map((ets: EventTimeSlot) => ({
          ...ets,
          startDate: moment.utc(ets.startDate).local(),
          endDate: moment.utc(ets.endDate).local(),
        }));
        queryClient.setQueryData(
          [Resource.EventTimeSlots, queryParams],
          previousData.map(
            previousTimeSlot =>
              modifiedNewData.find(
                newTimeSlot => previousTimeSlot.id === newTimeSlot.id
              ) || previousTimeSlot
          )
        );
      },
    }
  );
};

export const useDeleteEventTimeSlotMutation = () => {
  const queryClient = useQueryClient();
  const { eventId } = useParams<{ eventId: string }>();
  const params = {
    groupId: useSelector(getCurrentGroupId),
    eventId: Number(eventId),
  };
  return useMutation<{}, Error, number>(
    (eventTimeSlotId: number) =>
      client
        .delete(Resource.EventTimeSlots + '/' + eventTimeSlotId, { params })
        .then(({ data }) => data),
    {
      onSuccess: (_, eventTimeSlotId) => {
        const queryParams = { ...params, available: false };
        const previousData =
          queryClient.getQueryData<EventTimeSlot[]>([
            Resource.EventTimeSlots,
            queryParams,
          ]) || [];
        queryClient.setQueryData(
          [Resource.EventTimeSlots, queryParams],
          previousData.filter(ets => ets.id !== eventTimeSlotId)
        );
      },
    }
  );
};

export const useDeleteEventTimeSlotsMutation = () => {
  const queryClient = useQueryClient();
  const { eventId } = useParams<{ eventId: string }>();
  const params = {
    groupId: useSelector(getCurrentGroupId),
    eventId: Number(eventId),
  };
  return useMutation<{}, Error, number[]>(
    (eventTimeSlotIds: number[]) =>
      client
        .delete(Resource.EventTimeSlots, {
          params: { ...params, id: eventTimeSlotIds },
        })
        .then(({ data }) => data),
    {
      onSuccess: (_, eventTimeSlotIds) => {
        const queryParams = { ...params, available: false };
        const previousData =
          queryClient.getQueryData<EventTimeSlot[]>([
            Resource.EventTimeSlots,
            queryParams,
          ]) || [];
        queryClient.setQueryData(
          [Resource.EventTimeSlots, queryParams],
          previousData.filter(ets => !eventTimeSlotIds.includes(ets.id))
        );
      },
    }
  );
};
