import { all } from 'redux-saga/effects';

// sagas
import createInviteSaga from './createInviteSaga';
import deleteInviteSaga from './deleteInviteSaga';
import fetchInvitesForGroupSaga from './fetchInvitesForGroupSaga';
import resendInviteSaga from './resendInviteSaga';
import bulkInviteSaga from './bulkInviteSaga';

const inviteSaga = function* rootSaga() {
  yield all([
    createInviteSaga(),
    deleteInviteSaga(),
    fetchInvitesForGroupSaga(),
    resendInviteSaga(),
    bulkInviteSaga(),
  ]);
};

export default inviteSaga;
