import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';

// hooks
import { useDispatch, useSelector } from 'react-redux';

// material-ui
import {
  Button,
  Card,
  CardContent,
  Checkbox,
  Grid,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

// icons
import GroupAdd from '@material-ui/icons/GroupAdd';
import GetApp from '@material-ui/icons/GetApp';

// components
import { Form, Field } from 'react-final-form';
import FileInputField from 'components/FileInputField';
import Header from 'components/Header';
import Toast from 'components/Toast';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'assets/spreadsheets/lead-impor... Remove this comment to see the full error message
import LeadImportTemplate from 'assets/spreadsheets/lead-import-template.csv';

// action creators
import { createFileAndImportLeads } from 'store/actions/fileActions';

// selectors
import getCurrentGroupId from 'store/selectors/getCurrentGroupId';
import getForm from 'store/selectors/getForm';

// helpers
import isNamesListSiteFunction from 'helpers/isNamesListSite';

const FORM = 'leadImportForm';

export default function LeadImport() {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const [shouldSkipDuplicates, setShouldSkipDuplicates] = useState<boolean>(
    false
  );
  const { location: { search } = {} } = history;
  const isNamesListSite = isNamesListSiteFunction();
  const subtitle = `Start with our template and add more columns for any info that your ${
    isNamesListSite ? 'group' : 'chapter'
  } needs`;

  const {
    currentGroupId,
    form: { hasSubmitSucceeded },
  } = useSelector(state => ({
    currentGroupId: getCurrentGroupId(state),
    form: getForm(FORM)(state),
  }));

  useEffect(() => {
    if (hasSubmitSucceeded) {
      setTimeout(() => history.push({ pathname: '/leads', search }), 2000);
    }
  }, [hasSubmitSucceeded, history, search]);

  const toggleShouldSkipDuplicates = useCallback(() => {
    setShouldSkipDuplicates(!shouldSkipDuplicates);
  }, [shouldSkipDuplicates]);

  const dispatchCreateFileAndImportLeads = (file: any, values: any) => {
    dispatch(
      createFileAndImportLeads({
        file,
        formName: FORM,
        groupId: currentGroupId,
        shouldSkipDuplicates,
      })
    );
  };

  return (
    <Grid
      container
      className={classes.leadImportContainer}
      justifyContent='center'
    >
      <Grid item xs={12}>
        <Card>
          <CardContent className={classes.cardContent}>
            <Grid container spacing={4} alignItems='flex-start'>
              <Grid item xs>
                <Header
                  id='header'
                  icon={GroupAdd}
                  title='Import leads'
                  subtitle={subtitle}
                  highlightedSubtitle='First and last name are required.'
                />
              </Grid>
            </Grid>
            <Form
              onSubmit={() => {}}
              render={({ values, handleSubmit, invalid, form: { reset } }) => {
                return (
                  <Grid
                    container
                    component='form'
                    onSubmit={handleSubmit}
                    spacing={4}
                    alignItems='center'
                    className={classes.formContainer}
                  >
                    <Grid item xs={12}>
                      <Button
                        startIcon={<GetApp />}
                        variant='outlined'
                        href={LeadImportTemplate}
                      >
                        Download Template Spreadsheet
                      </Button>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      md={10}
                      lg={8}
                      xl={7}
                      className={classes.fileContainer}
                    >
                      <Grid container spacing={2} alignItems='center'>
                        <Grid item xs={12}>
                          <Typography
                            color='secondary'
                            variant='body2'
                            gutterBottom
                          >
                            Your first row will be omitted. <br />
                            If you enable 'Skip duplicates', it will
                            automatically skip all rows that would create
                            duplicate leads.
                          </Typography>
                        </Grid>

                        <Grid item xs={12}>
                          <Field
                            name='file'
                            render={props => (
                              <FileInputField
                                // @ts-expect-error ts-migrate(2322) FIXME: Type '{ input: FieldInputProps<any, HTMLElement>; ... Remove this comment to see the full error message
                                fileType='file'
                                disabled={invalid}
                                formName={FORM}
                                buttonLabel='Import Leads File'
                                reSelectLabel='Reselect Leads File'
                                onChange={() => {}}
                                onSubmit={(file: any) =>
                                  dispatchCreateFileAndImportLeads(file, values)
                                }
                                {...props}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={8}>
                          <Grid
                            container
                            alignItems='center'
                            className={classes.checkBoxContainer}
                          >
                            <Grid item>
                              <Tooltip title='Enable to automatically skip creating duplicate leads while importing'>
                                <Checkbox
                                  id='checkBox'
                                  color='primary'
                                  onClick={e => e.stopPropagation()}
                                  onChange={toggleShouldSkipDuplicates}
                                />
                              </Tooltip>
                            </Grid>
                            <Grid item>
                              <Typography
                                variant='subtitle2'
                                color='textSecondary'
                              >
                                Skip duplicates
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant='subtitle2' color='textSecondary'>
                          Accepted file formats: .csv
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                );
              }}
            />
          </CardContent>
        </Card>
      </Grid>

      <Toast
        id={'messageToast'}
        formName={FORM}
        snackbarProps={{ autoHideDuration: 6000 }}
        successMessage='Imported Successfully'
        failureMessage='Import Failed'
        forceErrorDialog
      />
    </Grid>
  );
}

const useStyles = makeStyles(theme => {
  const border = `1px solid ${(theme.palette.background as any).lightPaper}`;
  return {
    leadImportContainer: {
      height: '100%',
      overflowY: 'scroll',
      overflowX: 'hidden',
      paddingTop: 24,
    },
    cardContent: {
      paddingTop: 30,
      paddingLeft: 24,
      marginBottom: 55,
    },
    formContainer: {
      [theme.breakpoints.up('md')]: {
        marginLeft: 90,
      },
    },
    checkBoxContainer: {
      marginTop: -28,
      marginBottom: 20,
    },
    fileContainer: {
      border,
      borderRadius: 8,
      margin: 15,
    },
    noneFoundContainer: {
      [theme.breakpoints.up('sm')]: {
        marginLeft: 90,
      },
    },
  };
});
