import {
  GROUP_FIELD_FETCH_REQUEST,
  GROUP_FIELD_UPDATE_REQUEST,
  GROUP_FIELD_DELETE_REQUEST,
} from 'store/constants/groupFieldTypes';

export function fetchGroupFields({
  groupId,
  orderBy,
  search,
  resultKey,
  page,
  metaId,
}: any) {
  return {
    type: GROUP_FIELD_FETCH_REQUEST,
    payload: { groupId, orderBy, search, resultKey, page, metaId },
  };
}

export function updateGroupFields({ formName, groupFields }: any) {
  return {
    type: GROUP_FIELD_UPDATE_REQUEST,
    payload: { formName, groupFields },
  };
}

export function deleteGroupField({
  formName,
  id,
  groupId,
}: {
  formName: string;
  id: number;
  groupId: number;
}) {
  return {
    type: GROUP_FIELD_DELETE_REQUEST,
    payload: { formName, id, groupId },
  };
}
