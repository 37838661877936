import React from 'react';

// redux hooks
import { useDispatch } from 'react-redux';

// actions
import { DeleteOutline } from '@material-ui/icons';

// components
import DazzlingDialog from 'components/DazzlingDialog';
import { Grid, Typography } from '@material-ui/core';

export default function DeleteActivityModal({
  deleteAction,
  idToDelete,
  onClose,
  open,
  slice,
  title,
}: {
  deleteAction: (...args: any[]) => any;
  idToDelete: number;
  onClose: (...args: any[]) => any;
  open: boolean;
  slice?: string;
  title: string;
}) {
  const dispatch = useDispatch();
  const onSubmit = () => {
    dispatch(deleteAction({ id: idToDelete }));
  };

  return (
    <DazzlingDialog
      id='deleteActivityModal'
      alertProps={{
        message: 'Activity Deleted Successfully',
      }}
      handleClose={onClose}
      open={open}
      slice={slice}
      metaId={`delete-${idToDelete?.toString()}`}
      acceptLabel='Delete'
      onAccept={onSubmit}
      headerProps={{
        icon: DeleteOutline,
        title: `Delete ${title} Activity`,
        highlightedSubtitle: `All data for this ${title?.toLowerCase()} will be lost.`,
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid
            container
            justifyContent='center'
            alignItems='center'
            spacing={2}
          >
            <Grid item xs={12}>
              <Typography variant='h6'>
                {'Are you sure that you want to delete this activity?'}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </DazzlingDialog>
  );
}
