import { call, put, select } from 'redux-saga/effects';
import Immutable, { Map } from 'immutable';

import { success } from 'store/actions/httpActions';
import { GROUP_FETCH } from 'store/constants/groupTypes';
import client from 'sources/api';

import getGroups from 'store/selectors/getGroups';
import { groupSchema } from 'store/schemas/groupSchema';

import getResponsePayload from 'helpers/getResponsePayload';
import isNamesListSite from 'helpers/isNamesListSite';
import { toString } from 'helpers/transform';

export default function* populateParents(allParentIds: any) {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  const groups = yield select(getGroups);
  const groupIds = groups.map((group = Map()) => group.get('id'));

  // Get unique ids and those not in the current org app state
  const parentIds: number[] = [];
  allParentIds.forEach((id: number) => {
    if (
      !groupIds.includes(id) // ignores groups already in state
    ) {
      parentIds.push(id);
    }
  });
  for (let i = 0; i < parentIds.length; i += 10) {
    // Format the params by appending multiple values for the same key
    //  In this case `?id=1&id=2&id=3` so that we can get multiple items
    let parentParams = new URLSearchParams();
    parentParams.set('is_nameslist', toString(isNamesListSite()));
    // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
    parentIds.slice(i, i + 10).forEach(id => parentParams.append('id', id));

    // Yield to the request promise
    const parentsResponse = yield call(client.get, `/groups`, {
      params: parentParams,
    });

    // format and normalize the response
    const parentsPayload = getResponsePayload(parentsResponse, [groupSchema]);

    // Send the response data to the store for this type to be caught by a reducer
    yield put(
      success(
        GROUP_FETCH,
        Immutable.fromJS({
          responseOptions: {
            resultKey: 'result',
          },
          ...parentsPayload,
        })
      )
    );
  }
}
