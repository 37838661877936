import { toNumber } from 'helpers/transform';

const isItFreeSubscription = (subscription?: null | StripeProduct): boolean => {
  if (!subscription) {
    return false;
  }

  return !!subscription?.subscriptions?.find(
    ({ amount }) => toNumber(amount) === 0
  );
};

export default isItFreeSubscription;
