import Immutable from 'immutable';
import {
  TASK_FETCH_REQUEST,
  TASK_CREATE_REQUEST,
  TASK_COMPLETE_REQUEST,
  TASK_UNCOMPLETE_REQUEST,
  TASK_DELETE_REQUEST,
  TASK_POPULATE_REQUEST,
  TASK_CLEAR,
  TASK_CLEAR_BY_ID,
} from 'store/constants/taskTypes';

export function fetchTasks({
  completed,
  leadId,
  page = 1,
  accountId,
  dueOnFrom,
  dueOnTo,
}: any) {
  return {
    type: TASK_FETCH_REQUEST,
    payload: {
      completed,
      leadId,
      page,
      accountId,
      dueOnFrom,
      dueOnTo,
    },
  };
}

export function createTask({ formName, ...values }: any) {
  return {
    type: TASK_CREATE_REQUEST,
    payload: {
      formName,
      ...values,
    },
  };
}

export function completeTask({ taskId }: any) {
  return {
    type: TASK_COMPLETE_REQUEST,
    payload: {
      taskId,
    },
  };
}

export function uncompleteTask({ taskId }: any) {
  return {
    type: TASK_UNCOMPLETE_REQUEST,
    payload: {
      taskId,
    },
  };
}

export function deleteTask({ taskId }: any) {
  return {
    type: TASK_DELETE_REQUEST,
    payload: { taskId },
  };
}

export function populateTask({ taskId }: any) {
  return {
    type: TASK_POPULATE_REQUEST,
    payload: { taskId },
  };
}

export function clearTasks() {
  return {
    type: TASK_CLEAR,
  };
}

export function clearTaskById(id: number) {
  return {
    type: TASK_CLEAR_BY_ID,
    payload: Immutable.Map({ id }),
  };
}
