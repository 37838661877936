import React from 'react';
import { isEqual } from 'lodash';
import { useSelector } from 'react-redux';

//material-ui
import Grid from '@material-ui/core/Grid';

//components
import LeadSharingForm from 'routes/CommunitySettings/components/LeadSharingForm';

//selectors
import getPushLead from 'store/selectors/getPushLead';
import getCurrentGroupId from 'store/selectors/getCurrentGroupId';

const CommunitySettings = () => {
  const { groupId, pushLead } = useSelector(
    state => ({
      groupId: getCurrentGroupId(state),
      pushLead: getPushLead(state),
    }),
    isEqual
  );

  return (
    <Grid item xs={12}>
      <LeadSharingForm groupId={groupId} pushLead={pushLead} isFirstCard />
    </Grid>
  );
};

export default CommunitySettings;
