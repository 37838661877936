import React, { useCallback, useMemo } from 'react';
import validate from 'validate.js';
import { isEqual } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Field } from 'react-final-form';

// material-ui
import {
  Grid,
  Box,
  Card,
  CardContent,
  CardActions,
  Divider,
  Hidden,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { AccountBalance, ThumbsUpDown } from '@material-ui/icons';

// components
import Header from 'components/Header';
import TextField from 'components/TextField';
import Button from 'components/Button';

// selectors
import getCurrentGroup from 'store/selectors/getCurrentGroup';
import getForm from 'store/selectors/getForm';
import getSliceState from 'store/selectors/getSliceState';
import getIsHqAdmin from 'store/selectors/getIsHqAdmin';
import getIsSystemAdmin from 'store/selectors/getIsSystemAdmin';
import getCurrentParentCouncilName from 'store/selectors/getCurrentParentCouncilName';
import getCurrentParentOrganizationName from 'store/selectors/getCurrentParentOrganizationName';

// actions
import { updateCurrentGroup as updateCurrentGroupAction } from 'store/actions/groupActions';

// hooks
import { useIsFeatureEnabled } from 'helpers/hooks/featureHooks';

const FORM = 'updateChapter';
const VOTE_FORM = 'updateVoteSettings';
const SCHEMA = {
  displayName: {
    presence: true,
    length: { maximum: 1024 },
  },
  name: {
    length: { maximum: 1024 },
  },
};

export default function GroupSettings() {
  const classes = useStyles();
  const {
    actions: { updateCurrentGroup },
    state: {
      currentGroup: {
        name = '',
        displayName = '',
        currentActiveMembers = null,
        type = null,
        voteMethodId = null,
        voteVisibility = null,
        isVoted = false,
      } = {},
      form: { isSubmitting, hasSubmitFailed, hasSubmitSucceeded },
      voteForm,
      voteMethod,
      title,
      subtitle,
      isSystemAdmin,
    },
  } = useRedux();

  const isVoteMethodUpdateEnabled = useIsFeatureEnabled('custom_vote_method');

  const submitForm = useCallback(
    ({
      displayName,
      name,
      currentActiveMembers,
      voteMethodId,
      voteVisibility,
      voteForm,
    }) => {
      const fields: {
        name: string;
        currentActiveMembers: string;
        type: string;
        voteMethodId: string;
        voteVisibility: 'all' | 'admin';
        displayName?: string;
      } = {
        name,
        currentActiveMembers,
        type,
        voteMethodId,
        voteVisibility,
      };
      if (isSystemAdmin) {
        fields.displayName = displayName;
      }
      updateCurrentGroup({
        formName: voteForm ? VOTE_FORM : FORM,
        fields,
      });
    },
    [type, isSystemAdmin, updateCurrentGroup]
  );

  const validateFields = useCallback(values => validate(values, SCHEMA), []);

  const parseInteger = useCallback(
    value => (value ? Number(value.replace(/[^0-9]/g, '')) : undefined),
    []
  );

  const initialValues = useMemo(() => {
    const values = {
      displayName,
      currentActiveMembers,
      voteMethodId,
      voteVisibility,
      name,
    };
    return values;
  }, [currentActiveMembers, displayName, voteMethodId, voteVisibility, name]);

  return (
    <Grid item xs>
      <Grid container direction='column' spacing={4}>
        <Grid item xs>
          <Form
            onSubmit={submitForm}
            validate={validateFields}
            initialValues={initialValues}
            render={({ handleSubmit, invalid, pristine, form: { reset } }) => (
              <Card
                component='form'
                onSubmit={handleSubmit}
                className={classes.topCard}
              >
                <CardContent>
                  <Grid container>
                    <Grid item xs={12}>
                      <Header
                        icon={AccountBalance}
                        title={title}
                        subtitle={subtitle}
                      />
                    </Grid>
                    <Grid item xs>
                      <Grid container spacing={2}>
                        <Hidden xsDown>
                          <Grid item>
                            <Box width={75} />
                          </Grid>
                        </Hidden>

                        <Grid item xs>
                          <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                              <Field
                                name='displayName'
                                component={TextField}
                                label='Display Name'
                                disabled={!isSystemAdmin}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <Field
                                name='name'
                                component={TextField}
                                label='Designation'
                              />
                            </Grid>

                            <Grid item xs={12} sm={3}>
                              <Field
                                name='currentActiveMembers'
                                component={TextField}
                                label='Number Of Members'
                                parse={parseInteger}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
                <Divider />
                <CardActions className={classes.cardActions}>
                  <Grid
                    container
                    justifyContent='flex-end'
                    alignItems='center'
                    spacing={1}
                  >
                    <Grid item>
                      <Button
                        disabled={pristine || isSubmitting}
                        onClick={reset}
                      >
                        Cancel
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        type='submit'
                        color='primary'
                        variant='contained'
                        disabled={pristine || invalid || isSubmitting}
                        loading={isSubmitting}
                        success={hasSubmitSucceeded}
                        fail={hasSubmitFailed}
                      >
                        Save
                      </Button>
                    </Grid>
                    <Grid item xs={1} />
                  </Grid>
                </CardActions>
              </Card>
            )}
          />
        </Grid>
        <Grid item xs>
          <Form
            onSubmit={submitForm}
            initialValues={{ ...initialValues, voteForm: true }}
            render={({ handleSubmit, invalid, pristine, form: { reset } }) => (
              <Card component='form' onSubmit={handleSubmit}>
                <CardContent>
                  <Grid container>
                    <Grid item xs={12}>
                      <Header
                        icon={ThumbsUpDown}
                        title={'Culture Fit Endorsements'}
                        subtitle={
                          'Manage the endorsement type and numeric scale for all leads'
                        }
                      />
                    </Grid>
                    <Grid item xs>
                      <Grid container spacing={2}>
                        <Hidden xsDown>
                          <Grid item>
                            <Box width={75} />
                          </Grid>
                        </Hidden>

                        <Grid item xs>
                          <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                              <Field
                                disabled={!isVoteMethodUpdateEnabled || isVoted}
                                name='voteMethodId'
                                component={TextField}
                                options={voteMethod.data.map((vm: any) => ({
                                  label: vm.title,
                                  value: vm.id,
                                }))}
                                select
                                label='Type'
                                helperText={
                                  !isVoteMethodUpdateEnabled
                                    ? 'Upgrade your plan to use custom vote methods'
                                    : isVoted
                                    ? 'Clear existing votes to update vote method'
                                    : null
                                }
                                error={isVoted}
                              />
                            </Grid>
                            <Grid item xs={6} sm={3}>
                              <Field
                                name='voteVisibility'
                                component={TextField}
                                options={[
                                  { label: 'Admins Only', value: 'admin' },
                                  { label: 'All Users', value: 'all' },
                                ]}
                                select
                                label='Visible To'
                                helperText={
                                  'Determines what members can see others endorsements'
                                }
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
                <Divider />
                <CardActions className={classes.cardActions}>
                  <Grid
                    container
                    justifyContent='flex-end'
                    alignItems='center'
                    spacing={1}
                  >
                    <Grid item>
                      <Button
                        disabled={pristine || voteForm.isSubmitting}
                        onClick={reset}
                      >
                        Cancel
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        type='submit'
                        color='primary'
                        variant='contained'
                        disabled={pristine || invalid || voteForm.isSubmitting}
                        loading={voteForm.isSubmitting}
                        success={voteForm.hasSubmitSucceeded}
                        fail={voteForm.hasSubmitFailed}
                      >
                        Save
                      </Button>
                    </Grid>
                    <Grid item xs={1} />
                  </Grid>
                </CardActions>
              </Card>
            )}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
const useStyles = makeStyles({
  topCard: {
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    width: '100%',
  },
  cardActions: { margin: 16 },
});
const useRedux = () => {
  const dispatch = useDispatch();
  const actions = useMemo(
    () => ({
      updateCurrentGroup: (payload: any) =>
        dispatch(updateCurrentGroupAction(payload)),
    }),
    [dispatch]
  );
  const state = useSelector(
    state => ({
      currentGroup: getCurrentGroup(state).toJS() as any,
      form: getForm(FORM)(state),
      voteForm: getForm(VOTE_FORM)(state),
      voteMethod: getSliceState('voteMethod')(state),
      isSystemAdmin: getIsSystemAdmin(state),
      isHqAdmin: getIsHqAdmin(state),
      title: getCurrentParentOrganizationName(state),
      subtitle: getCurrentParentCouncilName(state),
    }),
    isEqual
  ) as any;
  return { actions, state };
};
