import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { Form, Field } from 'react-final-form';
import { List, Map } from 'immutable';
// material-ui
import {
  Card,
  CardActions,
  CardContent,
  Divider,
  FormHelperText,
  Grid,
  WithStyles,
  withStyles,
  CircularProgress,
  Box,
} from '@material-ui/core';
import { AccountBalance, NewReleases } from '@material-ui/icons';
//components
import AgreeToFeeDisclaimer from 'routes/PaymentSettings/PaymentSetupCard/AgreeToFeeDisclaimer/AgreeToFeeDisclaimer';
import Header from 'components/Header';
import AlertMessage from 'components/AlertMessage/AlertMessage';
import TextField from 'components/TextField';
import Button from 'components/Button';
import Toast from 'components/Toast';
import DeleteModal from 'components/DeleteModal';
//selectors
import getForm from 'store/selectors/getForm';
import getPaymentStripeLink from 'store/selectors/getPaymentStripeLink';
import getMembers from 'store/selectors/getMembers';
import getTotal from 'store/selectors/getTotal';
import getLoadingFromState from 'store/selectors/getLoadingFromState';
//actions
import {
  fetchPaymentStripeLinkAction,
  updatePaymentSettingsAction,
  deletePaymentAction,
  clearPaymentAction,
} from 'store/actions/paymentSettingsActions';
import { fetchMembersForGroup } from 'store/actions/memberActions';
import { fetchCurrentGroup } from 'store/actions/groupActions';
//helpers
import validate from 'helpers/validate';
import { isEmpty } from 'helpers/check';
import {
  isPaymentMethodWaiting,
  isPaymentMethodFailed,
} from 'helpers/checkPaymentMethod';
import { arrayToStringWithoutEmpty } from 'helpers/transform';
import { PaymentMethodStatus } from 'assets/types';
import { SiteVisualDataContext } from 'components/SiteVisualData';
//styles
import paymentSetupCardStyle from 'routes/PaymentSettings/PaymentSetupCard/paymentSetupCard.style';

const FORM = 'paymentSetupForm';

export interface PaymentSetupCardProps
  extends WithStyles<typeof paymentSetupCardStyle> {
  groupId?: number;
  accountNumber?: string | null;
  contactPersonId?: number | null;
  isPaymentActive?: boolean;
  areYouGoingToGoToLink: boolean;
  setAreYouGoingToGoToLink: (value: boolean) => void;
  setIsFailedAfterSetup: (value: boolean) => void;
  paymentStatus: PaymentMethodStatus;
  isAccountNumberLoading?: boolean;
  isFailedAfterSetup: boolean;
  waitingCount: number;
}

const PaymentSetupCard = ({
  classes,
  groupId,
  accountNumber,
  contactPersonId,
  isPaymentActive,
  areYouGoingToGoToLink,
  setAreYouGoingToGoToLink,
  setIsFailedAfterSetup,
  paymentStatus,
  isAccountNumberLoading,
  isFailedAfterSetup,
  waitingCount,
}: PaymentSetupCardProps) => {
  const { paymentSetupCardAlertMessage } = useContext(SiteVisualDataContext);
  const dispatch = useDispatch();
  const history = useHistory();
  const { location: { pathname, search } = {} } = history;
  const urlParams = new URLSearchParams(search);

  const stripeSuccess: string | null | undefined = urlParams.get('success');
  const isPaymentFailed = isPaymentMethodFailed(paymentStatus);
  const isPaymentFailedAfterSetup = !!(
    isPaymentFailed &&
    isFailedAfterSetup &&
    waitingCount < 7
  );

  const validateSchema = isPaymentActive
    ? {
        accountType: {
          presence: true,
        },
        accountNumber: {
          presence: true,
        },
        contactPerson: {
          presence: true,
        },
      }
    : {};

  const {
    form: { hasSubmitFailed, hasSubmitSucceeded, isSubmitting, formError },
    stripePaymentLink,
    members,
    totalMembers,
    isMembersLoading,
  } = useSelector(state => ({
    form: getForm(FORM)(state),
    stripePaymentLink: getPaymentStripeLink(state),
    members: getMembers()(state) as List<
      Map<keyof Member, Member[keyof Member]>
    >,
    totalMembers: getTotal('member')(state),
    isMembersLoading: getLoadingFromState('member')(state),
  }));

  const [maxTotalMembersSize, setMaxTotalMembersSize] = useState<number>(
    totalMembers || 50
  );
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState<boolean>(
    false
  );

  const initialValues = useMemo(
    () => ({
      accountType: 'stripeAccount',
      accountNumber,
      contactPerson: contactPersonId,
    }),
    [accountNumber, contactPersonId]
  );

  const allContactPersons = useMemo(() => {
    if (!members || !List.isList(members)) {
      return [];
    }

    return (
      members
        .map((member: Map<keyof Member, Member[keyof Member]>) => {
          const email = member.get('email');
          const account = (member.get('account') || Map()) as Map<
            keyof Account,
            Account[keyof Account]
          >;
          const firstName = account.get('firstName');
          const lastName = account.get('lastName');
          const id = account.get('id');

          return {
            label: arrayToStringWithoutEmpty([firstName, lastName, email], ' '),
            value: id,
          };
        })
        ?.toJS() || []
    );
  }, [members]);

  const doAfterDeleting = () => {
    if (groupId) {
      dispatch(clearPaymentAction());
      dispatch(fetchCurrentGroup({ id: groupId }));
    }
    setIsDeleteModalVisible(false);
  };

  const dispatchPaymentSetup = useCallback(
    values => {
      if (groupId) {
        dispatch(
          updatePaymentSettingsAction({
            groupId,
            formName: FORM,
            formData: values,
          })
        );
      }
    },
    [dispatch, groupId]
  );

  const handleClickCreateStripe = () => {
    if (groupId) {
      const refreshUrl = new URL(window.location.href);
      const returnUrl = new URL(window.location.href);
      returnUrl.searchParams.set('success', 'true');
      returnUrl.searchParams.delete('return');
      refreshUrl.searchParams.set('return', 'true');
      refreshUrl.searchParams.delete('success');
      dispatch(
        fetchPaymentStripeLinkAction({
          groupId,
          refreshUrl: refreshUrl.href,
          returnUrl: returnUrl.href,
        })
      );
      setAreYouGoingToGoToLink(true);
    }
  };

  const handleClickDeleteStripe = () => {
    setIsDeleteModalVisible(true);
  };

  const handleSubmitDeleteStripe = () => {
    if (isPaymentActive && groupId) {
      dispatch(
        deletePaymentAction({ groupId, doAfterSuccessSubmit: doAfterDeleting })
      );
    }
  };

  const handleCloseDeleteStripeModal = () => {
    setIsDeleteModalVisible(false);
  };

  useEffect(() => {
    if (areYouGoingToGoToLink && !isEmpty(stripePaymentLink)) {
      if (groupId && isPaymentMethodFailed(paymentStatus)) {
        dispatch(
          updatePaymentSettingsAction({
            groupId,
            formName: 'updateStatus',
            formData: { paymentStatus: 'disabled' },
          })
        );
      }
      setTimeout(() => {
        window.location.href = stripePaymentLink;
      }, 5);
      setAreYouGoingToGoToLink(false);
    }
    // eslint-disable-next-line
  }, [areYouGoingToGoToLink, setAreYouGoingToGoToLink, stripePaymentLink]);

  useEffect(() => {
    if (groupId && accountNumber && isPaymentActive) {
      dispatch(fetchMembersForGroup({ groupId, perPage: maxTotalMembersSize }));
    }
  }, [dispatch, groupId, accountNumber, isPaymentActive, maxTotalMembersSize]);

  useEffect(() => {
    if (
      !isMembersLoading &&
      totalMembers &&
      totalMembers > maxTotalMembersSize
    ) {
      setMaxTotalMembersSize(totalMembers);
    }
  }, [totalMembers, maxTotalMembersSize, isMembersLoading]);

  useEffect(() => {
    if (
      stripeSuccess &&
      groupId &&
      !isAccountNumberLoading &&
      !isPaymentActive &&
      !isPaymentMethodWaiting(paymentStatus)
    ) {
      dispatch(
        updatePaymentSettingsAction({
          groupId,
          formName: 'updateStatus',
          formData: { paymentStatus: 'waiting' },
        })
      );
      setIsFailedAfterSetup(true);
      const newSearch = new URLSearchParams(search);
      newSearch.delete('success');
      history.push({ pathname, search: newSearch.toString() });
    }
    // eslint-disable-next-line
  }, [isPaymentActive, paymentStatus, stripeSuccess, isAccountNumberLoading]);

  return (
    <Grid item xs={12}>
      <Form
        onSubmit={dispatchPaymentSetup}
        validate={values => validate(values, validateSchema)}
        initialValues={initialValues}
      >
        {({ handleSubmit, invalid, pristine, form: { reset } }) => (
          <Card
            id='paymentSetupCard'
            component='form'
            className={classes.topCard}
            onSubmit={handleSubmit}
          >
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Header
                    icon={AccountBalance}
                    title='Payment Setup'
                    alignItems='center'
                  />
                </Grid>
                <Grid item xs={12}>
                  <Grid
                    container
                    spacing={2}
                    direction='column-reverse'
                    className={classes.container}
                    justifyContent='space-between'
                  >
                    {!!(
                      isPaymentMethodWaiting(paymentStatus) ||
                      isPaymentFailedAfterSetup
                    ) && (
                      <Grid item xs={12}>
                        <div className={classes.waitingMessage}>
                          Please wait. Your Stripe account is being connected
                          now!
                          <Box m={3}>
                            <CircularProgress />
                          </Box>
                        </div>
                      </Grid>
                    )}
                    {isPaymentFailed && !isPaymentFailedAfterSetup && (
                      <Grid item xs={12}>
                        <div className={classes.errorMessage}>
                          There is some issue with your Stripe account! Please
                          update it!
                        </div>
                      </Grid>
                    )}
                    <Grid
                      item
                      xs={12}
                      {...(isPaymentActive ? { sm: 6, md: 5 } : {})}
                    >
                      {!isPaymentActive ? (
                        !isPaymentMethodWaiting(paymentStatus) &&
                        !isPaymentFailedAfterSetup && (
                          <Button
                            className={classes.createButton}
                            color='primary'
                            variant='contained'
                            disabled={invalid || isSubmitting || !groupId}
                            loading={isSubmitting}
                            onClick={handleClickCreateStripe}
                          >
                            {isPaymentFailed
                              ? 'Update Stripe account'
                              : 'Create or add Stripe account'}
                          </Button>
                        )
                      ) : (
                        <Grid container direction='column'>
                          <Grid item xs={12}>
                            <Field
                              name='accountType'
                              label='Account Type'
                              component={TextField}
                              disabled
                              options={[
                                {
                                  label: 'Stripe Account',
                                  value: 'stripeAccount',
                                },
                              ]}
                              select
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <Field
                              name='accountNumber'
                              label='Account Number'
                              component={TextField}
                              disabled
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <Field
                              name='contactPerson'
                              label='Contact Person'
                              component={TextField}
                              options={allContactPersons}
                              select
                            />
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                    <Grid
                      container
                      item
                      justifyContent='center'
                      alignItems='center'
                      xs={12}
                      {...(isPaymentActive
                        ? { sm: 6, md: 5 }
                        : { className: classes.createButton })}
                    >
                      <AlertMessage
                        icon={<NewReleases color='secondary' />}
                        title='Fee Disclaimer'
                        message={paymentSetupCardAlertMessage}
                        button={<AgreeToFeeDisclaimer />}
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
            {!!isPaymentActive && (
              <>
                <Divider />
                <CardActions>
                  <Grid container spacing={2} justifyContent='space-between'>
                    <Grid item xs='auto'>
                      <Grid container>
                        <Grid item xs='auto'>
                          <Button disabled={pristine} onClick={reset}>
                            Cancel
                          </Button>
                        </Grid>
                        <Grid item xs='auto'>
                          <Button
                            type='submit'
                            color='primary'
                            variant='contained'
                            disabled={pristine || invalid || isSubmitting}
                            loading={isSubmitting}
                            success={hasSubmitSucceeded}
                            fail={hasSubmitFailed}
                          >
                            Save
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs='auto'>
                      <Grid container spacing={2}>
                        <Grid item xs='auto'>
                          <Button
                            className={classes.deleteButton}
                            color='secondary'
                            variant='contained'
                            disabled={isSubmitting || !groupId}
                            loading={isSubmitting}
                            onClick={handleClickDeleteStripe}
                          >
                            Delete Stripe Account
                          </Button>
                        </Grid>
                        <Grid item xs='auto'>
                          <Button
                            className={classes.updateButton}
                            color='primary'
                            variant='contained'
                            disabled={isSubmitting || !groupId}
                            loading={isSubmitting}
                            onClick={handleClickCreateStripe}
                          >
                            Update Stripe Account
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                    {!!formError && !isEmpty(formError?.get('message')) && (
                      <Grid item xs={12}>
                        <FormHelperText error id='errorMessage'>
                          {formError.get('message')}
                        </FormHelperText>
                      </Grid>
                    )}
                  </Grid>
                </CardActions>
              </>
            )}
            <Toast slice='paymentSettings' hideSuccessMessage />
          </Card>
        )}
      </Form>
      {isDeleteModalVisible && (
        <DeleteModal
          open={isDeleteModalVisible}
          onClose={handleCloseDeleteStripeModal}
          onSubmit={handleSubmitDeleteStripe}
          isLoading={isAccountNumberLoading}
        />
      )}
    </Grid>
  );
};

export default withStyles(paymentSetupCardStyle)(PaymentSetupCard);
