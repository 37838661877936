export const GROUP_TAG_FETCH_REQUEST = 'GROUP_TAG_FETCH_REQUEST';
export const GROUP_TAG_FETCH = 'GROUP_TAG_FETCH';

export const GROUP_TAG_CREATE_REQUEST = 'GROUP_TAG_CREATE_REQUEST';
export const GROUP_TAG_CREATE = 'GROUP_TAG_CREATE';

export const GROUP_TAG_DELETE_REQUEST = 'GROUP_TAG_DELETE_REQUEST';
export const GROUP_TAG_DELETE = 'GROUP_TAG_DELETE';

export const GROUP_TAG_CLEAR = 'GROUP_TAG_CLEAR';
