import { Styles } from 'assets/types';
import formViewStyles from 'routes/ReferralForm/FormView/formView.style';

const basicFieldsStyles: Styles = theme => {
  const { formContent } = formViewStyles(theme);
  return {
    basicFieldsContent: {
      ...formContent,
      paddingTop: 15,
    },

    leftField: {
      paddingRight: 12,
      paddingTop: 4,
      paddingBottom: 4,

      [theme.breakpoints.only('xs')]: {
        paddingRight: 0,
      },
    },
    rightField: {
      paddingLeft: 12,
      paddingTop: 4,
      paddingBottom: 4,

      [theme.breakpoints.only('xs')]: {
        paddingLeft: 0,
      },
    },
    avatar: {
      marginTop: 10,
    },
  };
};

export default basicFieldsStyles;
