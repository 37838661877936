import Home from './Home';
import { connect } from 'react-redux';

const mapState = (state: any) => {
  return {
    isHqAdmin: false,
    isSystemAdmin: true,
  };
};

export default connect(mapState)(Home);
