import React, { useEffect, useMemo, useState } from 'react';
import { xor } from 'lodash';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useLeadsQueries } from 'api/leads';

import { Link as RouterLink } from 'react-router-dom';
import SuperAvatar from 'components/SuperAvatar';
import LeadStatus from 'components/LeadStatus';
import LeadInfo from 'components/LeadInfo';
import ActivityFeed from 'components/ActivityFeed';
import CallPanel from 'routes/Calls/CallPanel';
import DazzlingDialog from 'components/DazzlingDialog';
import {
  Grid,
  Typography,
  Box,
  Paper,
  Tab as MuiTab,
  Tabs,
  Card,
  CardHeader,
  Link as MaterialLink,
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { Warning } from '@material-ui/icons';
import CallQueue from './CallQueue';
import CallStats from './CallsStats';

// selectors
import getCurrentGroupId from 'store/selectors/getCurrentGroupId';
import { useIsFeatureEnabled } from 'helpers/hooks/featureHooks';

// helpers
import formatDisplayedPhoneNumber from 'helpers/formatDisplayedPhoneNumber';

const INITIAL_FILTERS = {
  actions: ['note.created'],
};

interface CallQueueProps {
  groupId: number;
  leadIds: number[];
}

export default function Calls() {
  const history = useHistory<{
    leadsWithoutPhoneNumber?: Lead[];
    newCallQueueLeadIds?: number[];
  }>();
  const rollingCallsEnabled = useIsFeatureEnabled('rolling_calls');
  const { leadsWithoutPhoneNumber = [], newCallQueueLeadIds = [] } =
    history.location.state || {};

  const [value, setValue] = useState(0);
  const [statsUpToDate, setStatsUpToDate] = useState(false);
  const currentGroupId = useSelector(getCurrentGroupId);
  const localCallQueueLeadIds = useLocalCallQueueLeadIds(currentGroupId);

  const [callQueueLeadIds, setCallQueueLeadIds] = useState(
    localCallQueueLeadIds
  );
  const queueUpToDate = !newCallQueueLeadIds.some(
    leadId => !callQueueLeadIds.includes(leadId)
  );

  const results = useLeadsQueries(callQueueLeadIds);
  const callQueueLeads = useMemo(
    () =>
      results
        .filter(result => result.isSuccess)
        .map(result => result.data as Lead),
    [results]
  );
  const loading = useMemo(
    () => results.map(result => result.isLoading).some(Boolean),
    [results]
  );
  const unavailableLeadIds = useMemo(
    () =>
      loading
        ? []
        : callQueueLeadIds.filter(
            leadId => !callQueueLeads.find(lead => lead.id === leadId)
          ),
    [callQueueLeads, callQueueLeadIds, loading]
  );

  const currentLead = callQueueLeads[0];
  const currentLeadId = currentLead?.id;
  const activitySource = useMemo<{ name: 'pnm'; id: number }>(
    () => ({
      name: 'pnm',
      id: currentLeadId,
    }),
    [currentLeadId]
  );

  const displayedPhoneNumber = formatDisplayedPhoneNumber(
    currentLead?.phoneNumber || ''
  );

  useEffect(() => {
    if (unavailableLeadIds.length)
      setCallQueueLeadIds(callQueueLeadIds =>
        callQueueLeadIds.filter(leadId => !unavailableLeadIds.includes(leadId))
      );
  }, [unavailableLeadIds]);

  useEffect(() => {
    if (!queueUpToDate) {
      setCallQueueLeadIds(callQueueLeadIds => {
        const newValue = rollingCallsEnabled
          ? xor(newCallQueueLeadIds, callQueueLeadIds)
          : newCallQueueLeadIds.slice(0, 1);
        history.push({
          state: { newCallQueueLeadIds: [], leadsWithoutPhoneNumber },
        });
        return newValue;
      });
    }
  }, [
    queueUpToDate,
    newCallQueueLeadIds,
    leadsWithoutPhoneNumber,
    history,
    rollingCallsEnabled,
  ]);

  useEffect(() => {
    const callQueueString = localStorage.getItem('callQueue');
    const callQueue: CallQueueProps[] = callQueueString
      ? JSON.parse(callQueueString)
      : [];
    const currentQueueIndex = callQueue.findIndex(
      queue => queue.groupId === currentGroupId
    );
    if (currentQueueIndex !== -1)
      callQueue[currentQueueIndex].leadIds = callQueueLeadIds;
    else callQueue.push({ groupId: currentGroupId, leadIds: callQueueLeadIds });
    localStorage.setItem('callQueue', JSON.stringify(callQueue));
  }, [currentGroupId, callQueueLeadIds]);

  const handleNextCall = () => setCallQueueLeadIds(callQueueLeadIds.slice(1));
  const handleCallEnd = () => setStatsUpToDate(false);

  const handleCallQueueChange = (changedLeads: Lead[]) =>
    setCallQueueLeadIds(changedLeads.map(lead => lead.id));

  const handleStatsUpdated = () => setStatsUpToDate(true);
  const handleChange = (_: any, newValue: any) => setValue(newValue);

  const handleDialogClose = () => {
    history.push({ state: {} });
  };

  return (
    <>
      <Box height='95%' overflow='auto' clone marginTop={3} marginBottom={3}>
        <Paper>
          <Box height='100%' clone>
            <Grid container>
              <Grid item xs={3}>
                <CallQueue
                  leads={callQueueLeads}
                  onChange={handleCallQueueChange}
                  loading={loading}
                />
              </Grid>
              <Grid item>
                <Box height='100%' width='2px' bgcolor='background.darkPaper' />
              </Grid>
              <Grid item xs zeroMinWidth>
                {currentLead ? (
                  <>
                    <Box padding={2}>
                      <Grid
                        spacing={2}
                        container
                        alignItems='center'
                        justifyContent='center'
                      >
                        <Grid item>
                          <SuperAvatar
                            fullName={currentLead.fullName}
                            imageUrl={currentLead.profilePhotoFileUrl}
                            size={'large'}
                          />
                        </Grid>
                        <Grid item>
                          <Typography>
                            {loading ? (
                              <Skeleton width={120} height={36} />
                            ) : (
                              currentLead.fullName
                            )}
                          </Typography>
                        </Grid>
                        <Grid item xs>
                          {loading ? (
                            <Skeleton height={56} />
                          ) : (
                            <Box
                              border={1}
                              marginX='auto'
                              borderRadius={4}
                              borderColor='background.outline'
                            >
                              <LeadStatus lead={currentLead} variant='label' />
                            </Box>
                          )}
                        </Grid>
                      </Grid>
                    </Box>
                    <Box
                      height='2px'
                      width='100%'
                      bgcolor='background.darkPaper'
                    />
                    <Tabs
                      value={value}
                      onChange={handleChange}
                      variant='fullWidth'
                      indicatorColor='primary'
                      textColor='primary'
                    >
                      <Tab label='Call' />
                      <Tab label='Info' />
                      <Tab label='Notes' />
                    </Tabs>
                    <Box display={value === 0 ? 'block' : 'none'}>
                      <CallPanel
                        {...currentLead}
                        phoneNumber={displayedPhoneNumber}
                        leadsLoading={loading}
                        onNextCall={handleNextCall}
                        onCallEnd={handleCallEnd}
                        lastCall={callQueueLeadIds.length === 1}
                      />
                    </Box>
                    <Box display={value === 1 ? 'block' : 'none'} padding={2}>
                      <LeadInfo leadId={currentLead.id} />
                    </Box>
                    <Box display={value === 2 ? 'block' : 'none'}>
                      <ActivityFeed
                        source={activitySource}
                        initialFilters={INITIAL_FILTERS}
                        displayHeader={false}
                        displayTimeline={false}
                        withoutFiltering
                      />
                    </Box>
                  </>
                ) : (
                  <Box padding={4}>
                    <Grid
                      container
                      spacing={2}
                      alignItems='center'
                      justifyContent='center'
                      direction='column'
                    >
                      <Grid item>
                        <Typography variant='h6'>
                          There are no leads in the call queue
                        </Typography>
                      </Grid>
                      <Grid>
                        <Typography color='textSecondary' variant='body2'>
                          Add leads into the queue and start making calls!
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                )}
              </Grid>
              <Grid item>
                <Box height='100%' width='2px' bgcolor='background.darkPaper' />
              </Grid>
              <Grid item xs={3}>
                <CallStats
                  statsUpToDate={statsUpToDate}
                  onStatsUpdated={handleStatsUpdated}
                />
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </Box>
      <DazzlingDialog
        open={leadsWithoutPhoneNumber.length > 0}
        handleClose={handleDialogClose}
        headerProps={{
          icon: Warning,
          title: `Leads Without Phone Number`,
          highlightedSubtitle: `Following leads were not added to the queue because they did not have a phone number`,
        }}
        actions={[]}
      >
        <Grid container>
          {leadsWithoutPhoneNumber.map(lead => (
            <Grid item xs={4}>
              <MaterialLink
                underline={'none'}
                component={RouterLink}
                to={`/leads/${lead.id}`}
                color={'primary'}
              >
                <Box clone bgcolor='background.lightPaper'>
                  <Card>
                    <CardHeader
                      avatar={
                        <SuperAvatar
                          fullName={lead.fullName}
                          imageUrl={lead.profilePhotoFileUrl}
                          size={'large'}
                          loading={loading}
                        />
                      }
                      title={lead.fullName}
                    />
                  </Card>
                </Box>
              </MaterialLink>
            </Grid>
          ))}
        </Grid>
      </DazzlingDialog>
    </>
  );
}
const Tab = (props: any) => (
  <Box
    border={2}
    borderTop={0}
    borderRight={0}
    borderLeft={0}
    height='58px'
    borderColor='background.darkPaper'
    clone
  >
    <MuiTab {...props} />
  </Box>
);

const useLocalCallQueueLeadIds = (currentGroupId: number) => {
  const callQueueString = localStorage.getItem('callQueue');
  const callQueueLeadIds = useMemo<number[]>(() => {
    if (callQueueString) {
      const currentGroupQueue = JSON.parse(callQueueString).find(
        (queue: CallQueueProps) => queue.groupId === currentGroupId
      );
      if (currentGroupQueue) return currentGroupQueue.leadIds;
    }
    return [];
  }, [callQueueString, currentGroupId]);
  return callQueueLeadIds;
};
