import React, { useCallback, useEffect, useMemo } from 'react';
import { isEqual } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { Map } from 'immutable';

// MUI components
import {
  Grid,
  Card,
  CardContent,
  Hidden,
  WithStyles,
  withStyles,
  useMediaQuery,
} from '@material-ui/core';

// icons
import BookIcon from '@material-ui/icons/Book';
import PieChart from '@material-ui/icons/PieChart';
import Group from '@material-ui/icons/Group';

// components
import Header from 'components/Header';
import Table from 'components/Table';
import ExportButton from 'components/ExportButton';
import StatItem from 'components/StatItem';
import ReportRefreshButton from 'components/ReportRefreshButton';

// action creators
import { createNonJoinersStoryDataReport } from 'store/actions/apiTaskActions';

// selectors
import getCurrentGroupId from 'store/selectors/getCurrentGroupId';
import getForm from 'store/selectors/getForm';
import getSliceEntityById from 'store/selectors/getSliceEntityById';

// helpers
import getTableColumns from 'routes/NonJoinerAnalytics/NonJoinerStoryCard/helpers/getTableColumns';
import { isArray, isEmpty } from 'helpers/check';

// styles
import nonJoinerStoryCardStyles from 'routes/NonJoinerAnalytics/NonJoinerStoryCard/nonJoinerStoryCard.style';

const REPORT_FORM = 'nonJoinersStoryDataReportForm';

export interface NonJoinersStoryProps
  extends WithStyles<typeof nonJoinerStoryCardStyles> {
  statusPositions: number[];
}

const NonJoinerStoryCard = ({
  classes,
  statusPositions,
}: NonJoinersStoryProps) => {
  const dispatch = useDispatch();
  const isMaxWidth500 = useMediaQuery('(max-width:500px)');

  const {
    currentGroupId,
    report: { data: { content = [] } = {}, id: reportId },
    form: { isSubmitting },
  } = useSelector(
    state => ({
      currentGroupId: getCurrentGroupId(state),
      form: getForm(REPORT_FORM)(state),
      report: getSliceEntityById(
        'report',
        'ReportTypes.RECRUITMENT_STORY',
        Map()
      )(state),
    }),
    isEqual
  );

  const dispatchCreateReport = useCallback(
    ({ forceRefresh }: { forceRefresh: boolean }) => {
      if (!isEmpty(statusPositions)) {
        dispatch(
          createNonJoinersStoryDataReport({
            groupId: currentGroupId,
            statusPositions,
            forceRefresh,
          })
        );
      }
    },
    [dispatch, currentGroupId, statusPositions]
  );

  useEffect(() => {
    dispatchCreateReport({ forceRefresh: false });
  }, [dispatchCreateReport]);

  const tableColumns = useMemo(getTableColumns, []);

  const [formattedContent, averagesData] = useMemo(() => {
    if (isEmpty(content)) {
      return [[], {}];
    }
    const newContent =
      content?.map((item: {}) => ({
        ...(item || {}),
      })) || [];
    const newAveragesData = newContent.pop();

    return [newContent, { ...(newAveragesData || {}) }];
  }, [content]);

  const [
    totalLeadsCount,
    filteredLeadsCount,
    filteredLeadsPercentage,
  ] = useMemo(() => {
    if (!content || isEmpty(content) || !isArray(content)) {
      return [0, 0, 0];
    }

    const {
      filteredChapterCount = 0,
      filteredChapterPercentage = 0,
      totalChapterCount = 0,
    } = content[0] || {};

    return [
      totalChapterCount,
      filteredChapterCount,
      filteredChapterPercentage.toString(10).slice(0, 5),
    ];
  }, [content]);

  return (
    <Card>
      <CardContent className={classes.cardContent}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm>
            <Header
              title='Organization Non-Joiner Story'
              subtitle='Discover what prevented your chapters from securing members by analyzing their Non-Joiner Story '
              icon={BookIcon}
              compact
            />
          </Grid>

          <Hidden xsDown>
            <Grid className={classes.exportButtonGrid} xs={3} item>
              <Grid container justifyContent='flex-end'>
                <Grid item>
                  <ReportRefreshButton
                    loading={isSubmitting}
                    onRefresh={dispatchCreateReport}
                  />
                </Grid>
                <ExportButton params={{ reportId }} disabled={isSubmitting} />
              </Grid>
            </Grid>
            <Grid className={classes.gridFullLine} item xs={12} />
          </Hidden>

          <Grid item xs={isMaxWidth500 ? 12 : 6} sm={5} lg={3} xl={2}>
            <StatItem
              title={
                totalLeadsCount
                  ? `${filteredLeadsCount} / ${totalLeadsCount}`
                  : 'No leads found.'
              }
              subtitle='Non-Joined / Total Leads'
              icon={<Group />}
              loading={isSubmitting}
              gutterBottom
            />
          </Grid>
          <Grid item xs={isMaxWidth500 ? 12 : 6} sm={5} lg={3} xl={2}>
            <StatItem
              title={`${filteredLeadsPercentage}%`}
              subtitle='Percent Non-Joined'
              icon={<PieChart />}
              loading={isSubmitting}
              gutterBottom
            />
          </Grid>

          <Grid item xs={12} className={classes.tableGrid}>
            <Table
              columns={tableColumns}
              data={formattedContent}
              variant='outlined'
              numberOfSkeletonRows={isSubmitting ? 8 : 0}
              TablePaginationProps={{
                count: formattedContent.length,
                rowsPerPage: 10,
                rowsPerPageOptions: [10],
              }}
              TableFooterRowProps={{
                data: averagesData,
              }}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default withStyles(nonJoinerStoryCardStyles, { withTheme: true })(
  NonJoinerStoryCard
);
