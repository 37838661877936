import Immutable from 'immutable';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

// action creators
import { doneIndicator, success } from 'store/actions/httpActions';

// api
import client from 'sources/api';

// constants
import {
  EMAIL_MAKE_PRIMARY_REQUEST,
  EMAIL_MAKE_PRIMARY,
} from 'store/constants/emailTypes';

// lib
import httpSaga from 'store/sagas/httpSaga';

const WATCH_TYPE = EMAIL_MAKE_PRIMARY_REQUEST;
const TYPE = EMAIL_MAKE_PRIMARY;

export function* makeEmailPrimarySaga(action: any) {
  const {
    payload: { id },
  } = action;

  const FORM = `makeEmailPrimaryForm${id}`;

  try {
    // @ts-expect-error ts-migrate(2569) FIXME: Type 'Generator<any, any, unknown>' is not an arra... Remove this comment to see the full error message
    yield* httpSaga(TYPE, call(client.post, `/emails/${id}/mark_primary`), {
      formName: FORM,
      dispatchSuccess: false,
    });

    yield put(
      success(
        TYPE,
        Immutable.fromJS({
          id,
        })
      )
    );
  } finally {
    yield put(doneIndicator(TYPE));
  }
}

export function* watch() {
  yield takeEvery(WATCH_TYPE, makeEmailPrimarySaga);
}

export default function* root() {
  yield all([fork(watch)]);
}
