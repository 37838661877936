import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import Immutable, { Map, List } from 'immutable';

// hooks
import { useHistory } from 'react-router-dom';

// material-ui
import { Card, CardContent, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

// icons
import PersonAddIcon from '@material-ui/icons/PersonAdd';

// components
import Header from 'components/Header';
import TabController from 'components/TabController';
import SingleInviteForm from './SingleInviteForm';
import BulkInviteForm from './BulkInviteForm';

// helpers
import { SiteVisualDataContext } from 'components/SiteVisualData';

const InviteMembersCard = function({ currentGroup }: any) {
  const { inviteMembersCardSubtitle } = useContext(SiteVisualDataContext);
  const classes = useStyles();
  const history = useHistory();
  const { location: { pathname, search = '' } = {} } = history;
  // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
  const lastPart = pathname.split('/').pop();
  const inviteModes = ['single', 'bulk'];
  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
  const initialInviteMode = inviteModes.includes(lastPart)
    ? lastPart
    : 'single'; // allows routing directly to specific invite mode

  const [inviteMode, setInviteMode] = useState(initialInviteMode);

  const handleTabClick = (tab: any) => {
    setInviteMode(tab);

    history.push({
      pathname: `/settings/members/${tab}`,
      search,
    });
  };

  const roleOptions = currentGroup
    .get('roles', List())
    .map((role: any) => {
      return {
        value: role.get('id'),
        label: role.get('displayName'),
      };
    })
    .toJS();

  const tabs = Immutable.fromJS([
    {
      key: 'single',
      label: 'Single',
    },
    {
      key: 'bulk',
      label: 'Bulk',
    },
  ]);

  return (
    <Grid item xs={12}>
      <Card className={classes.topCard}>
        <CardContent>
          <Grid container spacing={4} alignItems='flex-start'>
            <Grid item xs={12} sm={7} md={9} xl={10}>
              <Header
                icon={PersonAddIcon}
                title='Invite Members'
                subtitle={inviteMembersCardSubtitle}
              />
            </Grid>

            <Grid item xs={12} sm={5} md={3} xl={2}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Typography variant='subtitle2' color='textSecondary'>
                    Invite Mode
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <TabController
                    tabs={tabs}
                    // @ts-expect-error ts-migrate(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
                    currentTab={inviteMode}
                    onAnyTabClick={handleTabClick}
                    containerColor='darkPaper'
                    tabHoverColor='default'
                    size='compact'
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              {inviteMode === 'single' ? (
                <SingleInviteForm
                  currentGroup={currentGroup}
                  roleOptions={roleOptions}
                />
              ) : (
                <BulkInviteForm
                  currentGroup={currentGroup}
                  roleOptions={roleOptions}
                />
              )}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
};

InviteMembersCard.propTypes = {
  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'typeof Map' is not assignable to... Remove this comment to see the full error message
  currentGroup: PropTypes.instanceOf(Map).isRequired,
};

const useStyles = makeStyles(theme => ({
  topCard: {
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
  },
}));

export default InviteMembersCard;
