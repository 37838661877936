import { Map } from 'immutable';
// constants
import {
  MESSAGE_FETCH,
  MESSAGE_CREATE,
  MESSAGE_CLEAR,
  MESSAGE_START_LISTEN,
  MESSAGE_STOP_LISTEN,
} from 'store/constants/messageTypes';
// helpers
import httpReducer from './helpers/httpReducer';

export default function reduceMessage(state = Map(), action = {}) {
  switch ((action as any).type) {
    case MESSAGE_FETCH:
      return httpReducer(state, action, { appendResult: true });
    case MESSAGE_CREATE:
      return httpReducer(state, action, {
        updateResult: false,
        updateItemsById: false,
      });
    case MESSAGE_START_LISTEN:
    case MESSAGE_STOP_LISTEN:
      return httpReducer(state, action);
    case MESSAGE_CLEAR:
      return Map();
    default:
      return state;
  }
}
