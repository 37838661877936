import { all, call, fork, put, takeEvery, select } from 'redux-saga/effects';
// action creators
import { doneIndicator } from 'store/actions/httpActions';
// selector
import getCurrentGroupId from 'store/selectors/getCurrentGroupId';
// api
import client from 'sources/api';
// constants
import {
  PHONE_NUMBER_CREATE_REQUEST,
  PHONE_NUMBER_CREATE,
} from 'store/constants/phoneNumberTypes';
// schemas
import { phoneNumberSchema } from 'store/schemas/phoneNumberSchema';
// helpers
import httpSaga from 'store/sagas/httpSaga';
const WATCH_TYPE = PHONE_NUMBER_CREATE_REQUEST;
const TYPE = PHONE_NUMBER_CREATE;
export function* createPhoneNumberSaga(action: any) {
  const {
    payload: { number, accountId, formName },
  } = action;
  const currentGroupId = yield select(getCurrentGroupId);
  const postBody = {
    number,
  };
  if (accountId) {
    (postBody as any).account_id = accountId;
  }
  // @ts-expect-error ts-migrate(2569) FIXME: Type 'Generator<any, any, unknown>' is not an arra... Remove this comment to see the full error message
  yield* httpSaga(
    TYPE,
    call(client.post, `/phone_numbers?group_id=${currentGroupId}`, postBody),
    {
      schema: phoneNumberSchema,
      formName,
    }
  );
  yield put(doneIndicator(TYPE));
}
export function* watch() {
  yield takeEvery(WATCH_TYPE, createPhoneNumberSaga);
}
export default function* root() {
  yield all([fork(watch)]);
}
