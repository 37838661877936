import React, { useCallback, useMemo } from 'react';
import { isEqual } from 'lodash';

// hooks
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

// components
import DazzlingDialog from 'components/DazzlingDialog';
import TagsSelector from 'components/ChipsSelector';
import { Field, Form } from 'react-final-form';

// material-ui
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core/styles';

// icons
import { LocalOffer } from '@material-ui/icons';

// actions
import { createGroupTagsAction } from 'store/actions/groupTagActions';

// selectors
import getSliceState from 'store/selectors/getSliceState';
import getCurrentGroupId from 'store/selectors/getCurrentGroupId';
import { Typography } from '@material-ui/core';

const FORM = 'assignLabels';

type AssignLabelsFormProps = {
  selectedGroupId: number;
  onClose: (...args: any[]) => any;
  open: boolean;
};

export default function AssignLabelsForm({
  onClose,
  open,
  selectedGroupId,
}: AssignLabelsFormProps) {
  const {
    actions: { createGroupTags },
    state: { tags = [], currentGroupId },
  } = useRedux();

  const history = useHistory();
  const classes = useStyles();

  const navigateToSettings = () => {
    const {
      location: { search },
    } = history;
    const urlGroup = new URLSearchParams(search).get('group');
    const newSearch = new URLSearchParams();
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string | null' is not assignable... Remove this comment to see the full error message
    newSearch.set('group', urlGroup);

    history.push({
      pathname: '/settings/labels',
      search: newSearch.toString(),
    });
  };

  const tagOptions = useMemo(
    () =>
      tags.map(({ id, title, color }: any = {}) => ({
        value: id,
        label: title,
        color: '#' + color,
      })),
    [tags]
  );

  const handleFormSubmit = useCallback(
    ({ tagIds = {} }) => {
      createGroupTags({
        formName: FORM,
        parentId: currentGroupId,
        groupId: selectedGroupId,
        tagIds: tagIds,
      });
    },
    [createGroupTags, currentGroupId, selectedGroupId]
  );

  return (
    <Form
      onSubmit={handleFormSubmit}
      initialValues={{ tags: [] }}
      initialValuesEqual={isEqual}
      render={({ handleSubmit, invalid, pristine, form: { reset } }) => (
        <DazzlingDialog
          disabled={invalid || pristine}
          formName={FORM}
          handleClose={onClose}
          open={open}
          onAccept={handleSubmit}
          headerProps={{
            icon: LocalOffer,
            title: 'Assign Labels',
            highlightedSubtitle: 'Categorize your groups with label tags',
          }}
          acceptLabel='Submit'
          submitOnEnter
          onExited={() => reset()}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Tooltip
                arrow
                title='Tags give you simple ways to categorize and group your chapters'
              >
                <div>
                  <Field
                    name='tagIds'
                    label='Select Labels'
                    // @ts-expect-error ts-migrate(2322) FIXME: Type 'typeof ChipsSelector' is not assignable to t... Remove this comment to see the full error message
                    component={TagsSelector}
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    options={tagOptions}
                    isEqual={(a, b) => isEqual(new Set(a), new Set(b))}
                  />
                </div>
              </Tooltip>
            </Grid>

            <Grid item xs={12}>
              <Typography variant='body2'>
                Want more label tags? Manage them from your{' '}
                <span className={classes.link} onClick={navigateToSettings}>
                  settings.
                </span>
              </Typography>
            </Grid>
          </Grid>
        </DazzlingDialog>
      )}
    />
  );
}

const useRedux = () => {
  const dispatch = useDispatch();
  const actions = useMemo(
    () => ({
      createGroupTags: (payload: any) =>
        dispatch(createGroupTagsAction(payload)),
    }),
    [dispatch]
  );
  const state = useSelector(
    state => ({
      tags: getSliceState('tag')(state).data,
      currentGroupId: getCurrentGroupId(state),
    }),
    isEqual
  );
  return { actions, state };
};

const useStyles = makeStyles(theme => {
  return {
    link: {
      color: theme.palette.secondary.main,
      cursor: 'pointer',
      fontWeight: 'bold',
    },
  };
});
