import React from 'react';
import PropTypes from 'prop-types';
// MUI components
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Tooltip, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
const ActivityOverview = function({ columns, data, loading }: any) {
  const classes = useStyles();
  return (
    <Grid container className={classes.container}>
      <Grid item xs={12} className={classes.header}>
        <Grid container spacing={1}>
          {columns.map(({ label, icon }: any) => (
            <Grid item xs key={label}>
              <Tooltip arrow title={label}>
                <div className={classes.headerIcon}>{icon}</div>
              </Tooltip>
            </Grid>
          ))}
        </Grid>
      </Grid>

      <Grid item xs={12} className={classes.content}>
        <Grid container spacing={1}>
          {loading ? (
            // @ts-expect-error ts-migrate(2786) FIXME: 'SkeletonItems' cannot be used as a JSX component.
            <SkeletonItems numSkeletons={columns.length} />
          ) : (
            <StatItems columns={columns} data={data} />
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};
ActivityOverview.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
  columns: PropTypes.instanceOf(Array).isRequired,
  loading: PropTypes.bool,
};
ActivityOverview.defaultProps = {
  data: {},
};
const useStyles = makeStyles(theme => {
  const border = `1px solid ${(theme.palette.background as any).outline}`;
  const itemSize = 42;
  return {
    container: {
      marginTop: 8,
      border,
      borderRadius: 4,
      [theme.breakpoints.only('lg')]: {
        width: '85%',
      },
      [theme.breakpoints.only('xl')]: {
        width: '55%',
      },
    },
    header: {
      borderBottom: border,
      padding: 16,
      textAlign: 'center',
    },
    headerIcon: {
      width: itemSize,
      color: theme.palette.text.secondary,
    },
    content: {
      padding: 16,
    },
    numberContainer: {
      border: `1px solid ${theme.palette.primary.main}`,
      borderRadius: 100,
      padding: 4,
      width: itemSize,
      height: itemSize,
    },
    numberText: {
      textAlign: 'center',
    },
    emptyText: {
      padding: 16,
    },
  };
});
const StatItems = ({ columns, data = {} }: any) => {
  const classes = useStyles();
  if (!data)
    return (
      <Typography
        color='error'
        variant='subtitle2'
        className={classes.emptyText}
      >
        No data found.
      </Typography>
    );
  return columns.map(({ label, key }: any) => (
    <Grid item xs key={key}>
      <Tooltip arrow title={label}>
        <div className={classes.numberContainer}>
          <Typography variant='h6' className={classes.numberText}>
            {data[key] || 0}
          </Typography>
        </div>
      </Tooltip>
    </Grid>
  ));
};
type SkeletonItemsProps = {
  numSkeletons: number;
};
const SkeletonItems = ({ numSkeletons }: SkeletonItemsProps) => {
  return Array(numSkeletons)
    .fill(1)
    .map((_, index) => (
      <Grid item xs key={index}>
        <Skeleton variant='circle' height={42} width={42} />
      </Grid>
    ));
};
export default ActivityOverview;
