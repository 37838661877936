import { Map } from 'immutable';
// constants
import {
  TAG_FETCH,
  TAG_CREATE,
  TAG_UPDATE,
  TAG_DELETE,
  TAG_CLEAR,
} from 'store/constants/tagTypes';
import update from './callbacks/update';
import remove from './callbacks/remove';
// helpers
import httpReducer from './helpers/httpReducer';

export default function reduceTag(state = Map(), action = {}) {
  switch ((action as any).type) {
    case TAG_FETCH:
      return httpReducer(state, action, { appendResult: true });
    case TAG_CREATE:
      return httpReducer(state, action, { appendResult: true });
    case TAG_UPDATE:
      return httpReducer(state, action, { callback: update });
    case TAG_DELETE:
      return httpReducer(state, action, { callback: remove });
    case TAG_CLEAR:
      return Map();
    default:
      return state;
  }
}
