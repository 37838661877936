import React from 'react';
import {
  AccountBox,
  CompareArrows,
  DateRangeOutlined,
  Delete,
  Email,
  List,
  NoteAdd,
  People,
  Person,
  PersonAdd,
  Phone,
  PhoneDisabled,
  Sms,
} from '@material-ui/icons';

const getTableColumnIcons = ({
  percentClassName,
}: {
  percentClassName: string;
}) => ({
  label: 'Label',
  leads: <People />,
  joiners: <Person />,
  pnmsCreated: <PersonAdd />,
  pnmsDeleted: <Delete />,
  formCreated: <List />,
  formSubmitted: <AccountBox />,
  notesCreated: <NoteAdd />,
  pnmStatusesUpdated: <CompareArrows />,
  callsReceived: <Phone />,
  textsReceived: <Sms />,
  emailsReceived: <Email />,
  leadWithNoContact: <PhoneDisabled />,
  eventsAttended: <DateRangeOutlined />,
  membersInvolved: <People />,
  percentToJoinRate: <span className={percentClassName}>%</span>,
});

export default getTableColumnIcons;
