import React from 'react';

// components
import AccountCard from './AccountCard';
import UpdatePasswordCard from './components/UpdatePasswordCard';
import PreferencesCard from './PreferencesCard';
import DeleteAccountCard from './DeleteAccountCard';

const AccountSettings = function() {
  return (
    <>
      <AccountCard />
      <PreferencesCard />
      <UpdatePasswordCard />
      <DeleteAccountCard />
    </>
  );
};

export default AccountSettings;
