import {
  REFERRAL_SOURCE_FETCH_ONE_REQUEST,
  REFERRAL_SOURCE_FETCH_MANY_REQUEST,
  REFERRAL_SOURCE_CREATE_REQUEST,
} from 'store/constants/referralSourceTypes';

export function createReferralSourceAction({ displayName }: any) {
  return {
    type: REFERRAL_SOURCE_CREATE_REQUEST,
    payload: { displayName },
  };
}

export function fetchReferralSourceAction({ id }: any) {
  return {
    type: REFERRAL_SOURCE_FETCH_ONE_REQUEST,
    payload: { id },
  };
}

export function fetchReferralSourcesAction() {
  return {
    type: REFERRAL_SOURCE_FETCH_MANY_REQUEST,
  };
}
