import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Form } from 'react-final-form';
import DazzlingDialog from 'components/DazzlingDialog';
import { deleteFormFeeAction } from 'store/actions/formFeeActions';
import DeleteIcon from '@material-ui/icons/Delete';

const FORM_NAME = 'deleteFormFeesForm';

export interface DeleteFormFeesModalProps {
  isModalOpen: boolean;
  groupId: number;
  setIsModalOpen: (value: number | false) => void;
  element?: FormFee | null;
}

const DeleteFormFeesModal = ({
  groupId,
  isModalOpen,
  setIsModalOpen,
  element,
}: DeleteFormFeesModalProps) => {
  const dispatch = useDispatch();

  const handleClose = useCallback(() => {
    setIsModalOpen(false);
  }, [setIsModalOpen]);

  const onSubmit = useCallback(() => {
    const { formId, id } = element || {};
    if (formId && id) {
      dispatch(
        deleteFormFeeAction({
          formName: FORM_NAME,
          groupId,
          formId: formId,
          feeId: id,
        })
      );
    }
  }, [dispatch, groupId, element]);

  return (
    <Form
      id='deleteFormFees'
      onSubmit={onSubmit}
      render={({ handleSubmit }) => (
        <DazzlingDialog
          formName={FORM_NAME}
          open={isModalOpen}
          onAccept={handleSubmit}
          handleClose={handleClose}
          acceptLabel={'Delete'}
          headerProps={{
            title: 'Delete Form Fee',
            highlightedSubtitle:
              'Are you sure you want to remove this Form Fee?',
            icon: DeleteIcon,
          }}
        />
      )}
    />
  );
};

export default DeleteFormFeesModal;
