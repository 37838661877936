import React from 'react';
import PropTypes from 'prop-types';
// material-ui
import MuiFab from '@material-ui/core/Fab';
import CircularProgress from '@material-ui/core/CircularProgress';
const Fab = function({ loading, children, disabled, ...restOfProps }: any) {
  return (
    <MuiFab disabled={loading || disabled} {...restOfProps}>
      {loading ? <CircularProgress size={16} /> : children}
    </MuiFab>
  );
};
Fab.propTypes = {
  ...(MuiFab as any).propTypes,
  loading: PropTypes.bool,
};
Fab.defaultProps = {
  ...(MuiFab as any).defaultProps,
  loading: false,
};
export default Fab;
