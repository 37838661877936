import { Styles } from 'assets/types';

const paymentSetupCardStyle: Styles = theme => ({
  topCard: {
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
  },
  container: {
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
    },
  },
  gridWithCreateButton: {},
  errorMessage: {
    fontSize: '0.9rem',
    color: '#D6903C',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  waitingMessage: {
    fontSize: '0.9rem',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  createButton: {
    width: '100%',
    textTransform: 'uppercase',
  },
  updateButton: {
    minWidth: 210,
  },
  deleteButton: {
    minWidth: 210,
  },
});

export default paymentSetupCardStyle;
