import {
  LEAD_REFERRAL_SOURCE_FETCH_REQUEST,
  LEAD_REFERRAL_SOURCE_CREATE_REQUEST,
  LEAD_REFERRAL_SOURCE_DELETE_REQUEST,
} from 'store/constants/leadReferralSourceTypes';

export function fetchLeadReferralSourcesAction({ leadId }: any) {
  return {
    type: LEAD_REFERRAL_SOURCE_FETCH_REQUEST,
    payload: { leadId },
  };
}

export function createLeadReferralSourceAction({
  leadId,
  referralSourceId,
}: any) {
  return {
    type: LEAD_REFERRAL_SOURCE_CREATE_REQUEST,
    payload: { leadId, referralSourceId },
  };
}

export function deleteLeadReferralSourceAction({
  leadId,
  referralSourceId,
}: any) {
  return {
    type: LEAD_REFERRAL_SOURCE_DELETE_REQUEST,
    payload: { leadId, referralSourceId },
  };
}
