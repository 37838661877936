import { all, call, fork, put, take, takeEvery } from 'redux-saga/effects';
import Immutable from 'immutable';

// action creators
import { doneIndicator, success } from 'store/actions/httpActions';
import { fetchGroupFields } from 'store/actions/groupFieldActions';

// api
import { getClient } from 'sources/api';

// constants
import {
  LEAD_ATTRIBUTE_CREATE_REQUEST,
  LEAD_ATTRIBUTE_CREATE,
} from 'store/constants/leadAttributeTypes';
import { GROUP_FIELD_FETCH } from 'store/constants/groupFieldTypes';

// schemas
import { leadAttributeSchema } from 'store/schemas/leadAttributeSchema';

// helpers
import httpSaga from 'store/sagas/httpSaga';
import getResponsePayload from 'helpers/getResponsePayload';

const WATCH_TYPE = LEAD_ATTRIBUTE_CREATE_REQUEST;
const TYPE = LEAD_ATTRIBUTE_CREATE;
const client = getClient({ camelizeResponse: false });

export function* createLeadAttribute(action: any) {
  const {
    payload: { leadId, groupId, label, value, formName },
  } = action;
  const META_ID = `create-${leadId}`;

  // @ts-expect-error ts-migrate(2569) FIXME: Type 'Generator<any, any, unknown>' is not an arra... Remove this comment to see the full error message
  const response = yield* httpSaga(
    TYPE,
    call(client.post, `/pnm_attrs/?pnm_id=${leadId}&group_id=${groupId}`, {
      label,
      value,
    }),
    {
      dispatchSuccess: false,
      schema: leadAttributeSchema,
      formName,
      metaId: META_ID,
    }
  );

  let payload;
  if (response && response.data) {
    yield put(fetchGroupFields({ groupId, orderBy: 'position' }));
    yield take(GROUP_FIELD_FETCH + '_DONE');

    response.data.id = leadId;

    payload = getResponsePayload(response, leadAttributeSchema);
  }

  yield put(success(TYPE, Immutable.fromJS(payload), META_ID));

  yield put(doneIndicator(TYPE));
}

export function* watch() {
  yield takeEvery(WATCH_TYPE, createLeadAttribute);
}

export default function* root() {
  yield all([fork(watch)]);
}
