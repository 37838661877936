import Immutable, { Map, List } from 'immutable';
import _ from 'lodash';

/*
 *  This handles removing or adding ids to the dashboard linked chapters array for a group
 */
export default function updateDashboardLinkedGroups(map: any, action: any) {
  const { payload = Map(), type } = action;

  let newState = map;

  const childGroupId = payload.getIn(['childGroup', 'id']);
  let oldDashboardLinkedParents = payload
    .getIn(['childGroup', 'dashboardLinkedParents'], List())
    .toJS();

  let itemsById = newState.getIn(['data', 'byId'], Map());

  let newDashboardLinkedParents = _.uniq(oldDashboardLinkedParents);

  if (type.includes('REMOVE')) {
    const indexToDelete = newDashboardLinkedParents.findIndex(
      parentId => parentId === payload.get('parentId')
    );
    newDashboardLinkedParents.splice(indexToDelete, 1);
  } else {
    newDashboardLinkedParents.push(payload.get('parentId'));
  }

  itemsById = itemsById.setIn(
    [childGroupId?.toString(), 'dashboardLinkedParents'],
    Immutable.fromJS(newDashboardLinkedParents)
  );

  // Merge the new items with the states original `byId` map
  newState = newState.setIn(['data', 'byId'], itemsById);

  return newState;
}
