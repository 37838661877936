import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

// MUI components
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Typography from '@material-ui/core/Typography';

const MegaMenuItem = function({
  color,
  handleClose,
  icon,
  itemId,
  label,
  onClick,
  subText,
  display,
  autoFocus,
}: any) {
  const classes = useStyles();
  const ref = useRef<null | HTMLLIElement>(null);

  const onClose = () => {
    onClick();
    handleClose();
  };

  useEffect(() => {
    if (autoFocus) {
      ref.current?.focus();
    }
  }, [autoFocus]);

  if (!display) return null;

  return (
    <MenuItem ref={ref} onClick={() => onClose()} key={label} id={itemId}>
      {icon && (
        <ListItemIcon classes={{ root: classes.listItemIcon }}>
          {React.cloneElement(icon, { color })}
        </ListItemIcon>
      )}

      {subText ? (
        <Grid container>
          <Grid item xs={12}>
            <Typography variant='body2' color={color}>
              {label}
            </Typography>
            <Typography variant='caption' color='textSecondary'>
              {subText}
            </Typography>
          </Grid>
        </Grid>
      ) : (
        <Typography color={color}>{label}</Typography>
      )}
    </MenuItem>
  );
};

MegaMenuItem.propTypes = {
  handleClose: PropTypes.func.isRequired,
  itemId: PropTypes.string,
  display: PropTypes.bool,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  subText: PropTypes.string,
  icon: PropTypes.instanceOf(Object),
  color: PropTypes.oneOf([
    'action',
    'disabled',
    'error',
    'inherit',
    'primary',
    'secondary',
  ]),
};

MegaMenuItem.defaultProps = {
  icon: null,
  itemId: '0',
  color: 'inherit',
  display: true,
};

const useStyles = makeStyles(theme => ({
  listItemIcon: {
    minWidth: 40,
  },
}));

export default MegaMenuItem;
