import React, { useCallback, memo } from 'react';
import { isEqual } from 'lodash';
import { Form, Field } from 'react-final-form';
import { useParams } from 'react-router';
import { useUpdateLeadMutation, useUpdateLeadsMutation } from 'api/leads';

// material-ui
import { Box } from '@material-ui/core';
import { ContactMail } from '@material-ui/icons';

// components
import DazzlingDialog from 'components/DazzlingDialog';
import MemberSelector from 'components/MemberSelector';

type AssignMainContactModalProps = {
  open: boolean;
  onClose: (...args: any[]) => any;
  allSelected?: boolean;
  leadIds?: number[];
};

const AssignMainContactModal = ({
  open,
  onClose,
  allSelected = false,
  leadIds = [],
}: AssignMainContactModalProps) => {
  const { leadId } = useParams<{ leadId: string }>();
  const {
    isLoading: isLeadUpdating,
    isSuccess: isLeadUpdated,
    isError: isLeadUpdatingFailed,
    mutate: updateLead,
    reset: resetUpdateLeadMutation,
  } = useUpdateLeadMutation();
  const {
    isLoading: isLeadsUpdating,
    isSuccess: isLeadsUpdated,
    isError: isLeadsUpdatingFailed,
    mutate: updateLeads,
    reset: resetUpdateLeadsMutation,
  } = useUpdateLeadsMutation();
  const singleLeadId =
    Number(leadId) || (!allSelected && leadIds.length === 1 && leadIds[0]);

  const handleFormSubmit = useCallback(
    ({ mainContact }) => {
      if (singleLeadId)
        updateLead({ id: singleLeadId, mainContactId: mainContact.value });
      else {
        updateLeads({
          allSelected,
          ids: leadIds,
          mainContactId: mainContact.value,
        });
      }
    },
    [updateLead, updateLeads, leadIds, singleLeadId, allSelected]
  );

  return (
    <Form
      initialValues={{ mainContact: null }}
      onSubmit={handleFormSubmit}
      render={({ handleSubmit, pristine, form: { reset } }) => (
        <DazzlingDialog
          alertProps={{ message: 'Main Contact Assigned Successfully' }}
          disabled={pristine}
          handleClose={onClose}
          open={open}
          onAccept={handleSubmit}
          headerProps={{
            icon: ContactMail,
            title: 'Assign Main Contact',
            subtitle: `Choose a new main contact for the lead${
              leadIds.length > 1 ? 's' : ''
            }`,
            highlightedSubtitle: `${leadIds.length || 'All'} lead${
              !leadIds.length || leadIds.length > 1 ? 's' : ''
            } selected`,
          }}
          acceptLabel='Submit'
          submitOnEnter
          onExited={() => {
            reset();
            if (singleLeadId) resetUpdateLeadMutation();
            else resetUpdateLeadsMutation();
          }}
          isLoading={isLeadUpdating || isLeadsUpdating}
          isSuccess={isLeadUpdated || isLeadsUpdated}
          isError={isLeadUpdatingFailed || isLeadsUpdatingFailed}
        >
          <Box width='70%' marginTop={1} marginBottom={3}>
            <Field
              allowNull
              isEqual={isEqual}
              name='mainContact'
              component={MemberSelector}
              fullWidth
              margin='normal'
            />
          </Box>
        </DazzlingDialog>
      )}
    />
  );
};

export default memo(AssignMainContactModal);
