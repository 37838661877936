import React, { memo } from 'react';

import { ZoomIn } from '@material-ui/icons';

// components
import DazzlingDialog from 'components/DazzlingDialog';
import LeadInfo from 'components/LeadInfo';
import SuperAvatar from 'components/SuperAvatar';

type LeadQuickViewModalProps = {
  lead: Lead;
  open: boolean;
  onClose: () => void;
};

const LeadQuickViewModal = function({
  lead,
  open,
  onClose,
}: LeadQuickViewModalProps) {
  const headerData = {
    icon: ZoomIn,
    title: lead.fullName,
    subtitle: 'View and update key information',
  };
  return (
    <DazzlingDialog
      handleClose={onClose}
      headerProps={headerData}
      open={open}
      height='auto'
      actions={[]}
    >
      <SuperAvatar
        style={{ marginBottom: 20 }}
        fullName={lead.fullName}
        imageUrl={lead.profilePhotoFileUrl}
        size='huge'
        variant='roundedSquare'
        background='dark'
      />

      <LeadInfo leadId={lead.id || 0} shouldFetchData={open} />
    </DazzlingDialog>
  );
};

export default memo(LeadQuickViewModal);
