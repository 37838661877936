import { all, call, fork, takeEvery, put } from 'redux-saga/effects';

// action creators
import { doneIndicator } from 'store/actions/httpActions';

// api
import client from 'sources/api';

// constants
import {
  MEMBER_DELETE_ALL_FOR_GROUP_REQUEST,
  MEMBER_DELETE_ALL_FOR_GROUP,
} from 'store/constants/memberTypes';

// lib
import httpSaga from 'store/sagas/httpSaga';

// schemas
import { memberSchema } from 'store/schemas/memberSchema';

const WATCH_TYPE = MEMBER_DELETE_ALL_FOR_GROUP_REQUEST;
const TYPE = MEMBER_DELETE_ALL_FOR_GROUP;
const FORM = 'deleteAllMembersForm';

export function* deleteAllMembersForGroup(action: any) {
  const { groupId: group_id } = action.payload;

  // @ts-expect-error ts-migrate(2569) FIXME: Type 'Generator<any, any, unknown>' is not an arra... Remove this comment to see the full error message
  yield* httpSaga(
    TYPE,
    call(client.delete, '/members', {
      params: {
        group_id,
        id: 'all',
      },
    }),
    {
      schema: [memberSchema],
      formName: FORM,
    }
  );

  yield put(doneIndicator(TYPE));
}

export function* watch() {
  yield takeEvery(WATCH_TYPE, deleteAllMembersForGroup);
}

export default function* root() {
  yield all([fork(watch)]);
}
