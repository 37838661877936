import Immutable, { List, Map } from 'immutable';
import { denormalize } from 'normalizr';

import { referralFormSchema } from '../schemas/referralFormSchema';

export default function getReferralForms(state: any) {
  const referralForm = state.get('referralForm', Map());

  const result = referralForm.getIn(['data', 'result'], List());

  const entities = {
    referralForm: state.getIn(['referralForm', 'data', 'byId'], Map()).toJS(),
    file: state.getIn(['file', 'data', 'byId'], Map()).toJS(),
  };

  let items = denormalize(result.toJS(), [referralFormSchema], entities);

  items = Immutable.fromJS(items);
  items = items.sortBy((item: any) => item?.get('title'));

  return items;
}
