import { all, call, fork, put, takeEvery, select } from 'redux-saga/effects';

// action creators
import { doneIndicator } from 'store/actions/httpActions';

// api
import client from 'sources/api';

// constants
import {
  REFERRAL_SOURCE_CREATE_REQUEST,
  REFERRAL_SOURCE_CREATE,
} from 'store/constants/referralSourceTypes';

// schemas
import { referralSourceSchema } from 'store/schemas/referralSourceSchema';

// helpers
import httpSaga from 'store/sagas/httpSaga';

// selectors
import getCurrentGroupId from 'store/selectors/getCurrentGroupId';

const WATCH_TYPE = REFERRAL_SOURCE_CREATE_REQUEST;
const TYPE = REFERRAL_SOURCE_CREATE;

export function* createReferralSourceSaga(action: any) {
  const {
    payload: { displayName },
  } = action;

  const currentGroupId = yield select(getCurrentGroupId);

  // @ts-expect-error ts-migrate(2569) FIXME: Type 'Generator<any, any, unknown>' is not an arra... Remove this comment to see the full error message
  yield* httpSaga(
    TYPE,
    call(client.post, `/referral_sources`, {
      display_name: displayName,
      group_id: currentGroupId,
    }),
    {
      schema: referralSourceSchema,
    }
  );

  yield put(doneIndicator(TYPE));
}

export function* watch() {
  yield takeEvery(WATCH_TYPE, createReferralSourceSaga);
}

export default function* root() {
  yield all([fork(watch)]);
}
