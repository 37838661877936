import { Styles } from 'assets/types';
import analyticsCardStyles from 'assets/stylesheets/defaultStyles/analyticsCard.style';

const chapterLeaderboardStyles: Styles = theme => {
  const newAnalyticsCardStyle = analyticsCardStyles(theme) || {};
  return {
    ...newAnalyticsCardStyle,
    points: {
      color: '#D6903C',
    },
  };
};

export default chapterLeaderboardStyles;
