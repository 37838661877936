import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import Immutable from 'immutable';

// action creators
import { doneIndicator, success } from 'store/actions/httpActions';

// api
import client from 'sources/api';

// constants
import { GROUP_UNLINK_REQUEST } from 'store/constants/groupTypes';

// lib
import httpSaga from 'store/sagas/httpSaga';
import getBaseType from 'store/sagas/helpers/getBaseType';
import getResultKey from 'store/sagas/group/helpers/getResultKey';

// schema
import { groupSchema } from 'store/schemas/groupSchema';

const WATCH_TYPE = GROUP_UNLINK_REQUEST;

export function* groupUnlink(action: any) {
  const {
    parentId: parent_id,
    childId: child_id,
    type,
    formName,
  } = action.payload;

  const FORM = formName;
  const TYPE = getBaseType(action.type);

  try {
    // @ts-expect-error ts-migrate(2569) FIXME: Type 'Generator<any, any, unknown>' is not an arra... Remove this comment to see the full error message
    yield* httpSaga(
      TYPE,
      call(client.post, `/groups/${parent_id}/remove_dashboard_link`, {
        parent_id,
        child_id,
      }),
      { schema: groupSchema, formName: FORM, dispatchSuccess: false }
    );

    yield put(
      success(
        TYPE,
        Immutable.fromJS({
          id: child_id,
          responseOptions: {
            resultKey: getResultKey(type),
          },
        })
      )
    );
  } finally {
    yield put(doneIndicator(TYPE));
  }
}

export function* watch() {
  yield takeEvery(WATCH_TYPE, groupUnlink);
}

export default function* root() {
  yield all([fork(watch)]);
}
