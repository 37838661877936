import React, { useCallback } from 'react';

// react router
import { useHistory } from 'react-router-dom';

// material-ui
import ArrowBack from '@material-ui/icons/ArrowBack';
import Button from '@material-ui/core/Button';
import { makeStyles, useTheme } from '@material-ui/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

export default function LeadBackButton() {
  const history = useHistory<{ fromPathname?: string }>();

  const handleClick = useCallback(() => {
    const {
      location: { state = {} },
    } = history;
    if (state.fromPathname?.includes('/leads')) history.goBack();
    else history.push({ pathname: '/leads', state });
  }, [history]);

  // Theme initializations
  const classes = useStyles();
  const theme = useTheme();
  const isSmallAndDown = useMediaQuery((theme as any).breakpoints.down('sm'));

  return (
    <Button
      size={isSmallAndDown ? 'medium' : 'large'}
      className={classes.container}
      startIcon={<ArrowBack />}
      onClick={handleClick}
    >
      Back To List
    </Button>
  );
}

const useStyles = makeStyles(theme => {
  return {
    container: {
      marginRight: 10,
      marginLeft: 10,
      [(theme as any).breakpoints.down('sm')]: {
        margin: 0,
      },
    },
  };
});
