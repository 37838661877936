import React, { useContext } from 'react';

// components
import { Link as RouterLink } from 'react-router-dom';

// material-ui
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import Hidden from '@material-ui/core/Hidden';

// helpers
import { SiteVisualDataContext } from 'components/SiteVisualData';
import isNamesListSite from 'helpers/isNamesListSite';

const LoginFormHeader = function() {
  const { loginFormHeaderTitle } = useContext(SiteVisualDataContext);
  const classes = useStyles();

  return (
    <Grid item>
      <Typography variant='h4' display='inline'>
        {loginFormHeaderTitle}
      </Typography>

      <Hidden smDown>
        {!isNamesListSite() ? (
          <>
            <Typography variant='h4' display='inline'>
              {' '}
              to{' '}
            </Typography>
            <Typography
              className={classes.darkAccentColor}
              variant='h4'
              display='inline'
            >
              ChapterBuilder
            </Typography>
          </>
        ) : (
          <span className={classes.emptyBox} />
        )}
      </Hidden>

      <Grid container justifyContent='space-between'>
        <Hidden smDown>
          <Grid item>
            <Typography variant='body1'>Hop back in.</Typography>
          </Grid>
        </Hidden>

        <Grid item>
          <Typography variant='body1' display='inline'>
            New Here?{' '}
          </Typography>

          <Typography className={classes.bold} variant='body1' display='inline'>
            <Link component={RouterLink} to='/signup' color='textPrimary'>
              Create An Account
            </Link>
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles(theme => ({
  emptyBox: {
    display: 'inline-block',
    [theme.breakpoints.up('sm')]: {
      width: 245,
    },
  },
  darkAccentColor: {
    color: theme.palette.background.paper,
  },
  bold: {
    fontWeight: 'bold',
  },
}));

export default LoginFormHeader;
