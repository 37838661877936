import isItFreeSubscription from 'components/ManageSubscription/helpers/isItFreeSubscription';

const isSubscriptionDowngraded = (
  currentSubscriptionPlanProductId?: string,
  newSubscription?: null | StripeProduct,
  stripeProducts?: StripeProduct[]
): boolean => {
  if (
    !currentSubscriptionPlanProductId ||
    !newSubscription ||
    !newSubscription?.sortPosition ||
    !stripeProducts ||
    stripeProducts.length === 0
  ) {
    return false;
  }
  const currentSubscription = stripeProducts.find(
    ({ id }: StripeProduct) => id === currentSubscriptionPlanProductId
  );
  const { sortPosition: currentSubscriptionSortPosition = 0 } =
    currentSubscription || {};

  return !!(
    isItFreeSubscription(newSubscription) &&
    newSubscription.sortPosition < currentSubscriptionSortPosition
  );
};

export default isSubscriptionDowngraded;
