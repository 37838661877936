import { Map } from 'immutable';
// constants
import {
  NOTE_FETCH,
  NOTE_UPDATE,
  NOTE_CLEAR,
  NOTE_DELETE,
} from 'store/constants/noteTypes';
// helpers
import httpReducer from './helpers/httpReducer';
import remove from './callbacks/remove';
import update from './callbacks/update';

export default function reduceNote(state = Map(), action = {}) {
  switch ((action as any).type) {
    case NOTE_FETCH:
      return httpReducer(state, action, { appendResult: true });
    case NOTE_UPDATE:
      return httpReducer(state, action, { callback: update });
    case NOTE_DELETE:
      return httpReducer(state, action, { callback: remove });
    case NOTE_CLEAR:
      return remove(state, action);
    default:
      return state;
  }
}
