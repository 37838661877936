import { List } from 'immutable';
import getCurrentGroupId from './getCurrentGroupId';

export default function getAvailableAddOnProdIds(state: any) {
  const currentGroupId = getCurrentGroupId(state) || '';
  const availableAddOnProds = state.getIn(
    [
      'billing',
      'data',
      'byId',
      currentGroupId.toString(),
      'availableAddOnProducts',
    ],
    List()
  );

  return availableAddOnProds.map((addOn: any) => addOn.get('productId'));
}
