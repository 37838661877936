import React, { useMemo } from 'react';
import moment from 'moment';
import classNames from 'classnames';
// MUI components
import {
  Box,
  Grid,
  Typography,
  CircularProgress,
  withStyles,
  WithStyles,
} from '@material-ui/core';
// MUI icons
import MonetizationOn from '@material-ui/icons/MonetizationOn';
// components
import EmptyMessage from 'components/EmptyMessage';
import PaymentItem from 'routes/LeadList/components/PaymentItem';
// action creators
import { useLeadPaymentQuery } from 'api/billing';
// helpers
import { toInt10 } from 'helpers/transform';
// styles
import paymentsTabStyles from 'routes/LeadList/components/PaymentsTab/paymentsTab.style';

export interface PaymentsTabProps extends WithStyles<typeof paymentsTabStyles> {
  leadId: number;
}

const PaymentsTab = ({ classes, leadId }: PaymentsTabProps) => {
  const { data: { paymentData } = {}, isLoading } = useLeadPaymentQuery(leadId);

  const getPaymentData = () => {
    let lastDate: number = 0;

    return (item: LeadPayments) => {
      const { id, createdOn } = item || {};
      const currentDate: number = createdOn
        ? toInt10(moment(createdOn).format('YYYYMMDD'))
        : 0;
      const newLastDate = lastDate;
      lastDate = currentDate;

      return (
        <PaymentItem
          key={id}
          paymentData={item}
          currentDate={currentDate}
          lastDate={newLastDate}
        />
      );
    };
  };

  const sortedPaymentData = useMemo<LeadPayments[]>(() => {
    if (!paymentData || !Array.isArray(paymentData)) {
      return [];
    }

    if (paymentData.length === 1) {
      return paymentData;
    }

    return paymentData.sort((left: LeadPayments, right: LeadPayments) => {
      if (!left?.createdOn || !right?.createdOn) {
        return 0;
      }
      return (
        toInt10(moment(right.createdOn).unix()) -
        toInt10(moment(left.createdOn).unix())
      );
    });
  }, [paymentData]);

  const emptyMessage = useMemo(
    () =>
      isLoading ? (
        <Box className={classes.circularProgress} m={3}>
          <CircularProgress />
        </Box>
      ) : (
        <Grid container>
          <Grid item xs={6} className={classes.emptyMessage}>
            <EmptyMessage title='No activity found' variant='outlined' />
          </Grid>
        </Grid>
      ),
    [isLoading] // eslint-disable-line
  );

  return (
    <Box className={classes.root} id={'activity'}>
      <Grid
        container
        spacing={2}
        alignItems={'center'}
        className={classes.header}
      >
        <Grid item key='timelineIcon' id='timelineIcon'>
          <Box
            className={classNames(classes.timeLineIcon, classes.marginTimeLine)}
          >
            <MonetizationOn />
          </Box>
        </Grid>

        <Grid item xs>
          <Typography variant={'h6'}>Payments</Typography>
        </Grid>
      </Grid>
      {sortedPaymentData.length > 0
        ? sortedPaymentData.map(getPaymentData())
        : emptyMessage}
    </Box>
  );
};

export default withStyles(paymentsTabStyles, { withTheme: true })(PaymentsTab);
