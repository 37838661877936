import React, { createContext } from 'react';

// helpers
import isNamesListSite from 'helpers/isNamesListSite';
import getCB from 'helpers/siteVisualData/getCB';
import getNL from 'helpers/siteVisualData/getNL';

const SITE_VISUAL_DATA = isNamesListSite() ? getNL() : getCB();

export const SiteVisualDataContext = createContext<SiteVisualData>(
  SITE_VISUAL_DATA
);

const SiteVisualDataComponent = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) => (
  <SiteVisualDataContext.Provider value={SITE_VISUAL_DATA}>
    {children}
  </SiteVisualDataContext.Provider>
);

export default SiteVisualDataComponent;
