import {
  PAYMENT_SETUP_FETCH_REQUEST,
  PAYMENT_SETUP_GET_LINK_FETCH_REQUEST,
  PAYMENT_SETUP_UPDATE_REQUEST,
  PAYMENT_DELETE_REQUEST,
  PAYMENT_CLEAR,
} from 'store/constants/paymentSettingsTypes';
import { PaymentMethodStatus } from 'assets/types';

export function fetchPaymentSettingsAction({
  groupId,
  token,
}: {
  groupId: number;
  token?: string | null;
}) {
  return {
    type: PAYMENT_SETUP_FETCH_REQUEST,
    payload: { groupId, token },
  };
}

export function fetchPaymentStripeLinkAction({
  groupId,
  refreshUrl,
  returnUrl,
}: {
  groupId: number;
  refreshUrl: string;
  returnUrl: string;
}) {
  return {
    type: PAYMENT_SETUP_GET_LINK_FETCH_REQUEST,
    payload: { groupId, refreshUrl, returnUrl },
  };
}

export function updatePaymentSettingsAction({
  groupId,
  formName,
  formData,
}: {
  groupId: number;
  formName: string;
  formData: {
    contactPerson?: number;
    paymentStatus?: PaymentMethodStatus;
  };
}) {
  return {
    type: PAYMENT_SETUP_UPDATE_REQUEST,
    payload: { groupId, formName, formData },
  };
}

export function deletePaymentAction({
  groupId,
  doAfterSuccessSubmit,
}: {
  groupId: number;
  doAfterSuccessSubmit?: () => void;
}) {
  return {
    type: PAYMENT_DELETE_REQUEST,
    payload: { groupId, doAfterSuccessSubmit },
  };
}

export function clearPaymentAction() {
  return {
    type: PAYMENT_CLEAR,
  };
}
