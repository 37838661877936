import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';

// components
import DazzlingDialog from 'components/DazzlingDialog';
import { Grid, Typography } from '@material-ui/core';
import { DeleteOutline } from '@material-ui/icons';

// actions
import { deleteTagAction } from 'store/actions/tagActions';

// constant
import { FORM } from './TagsForm';

type Props = {
  open: boolean;
  onClose: () => void;
  tagId: number | null;
};

export default function DeleteTagModal({ open, onClose, tagId }: Props) {
  const dispatch = useDispatch();
  const deleteHandler = useCallback(() => {
    dispatch(deleteTagAction({ formName: FORM + tagId, id: tagId }));
  }, [dispatch, tagId]);

  return (
    <DazzlingDialog
      id='deleteTagModal'
      successMessage='Tag Deleted Successfully'
      failureMessage='Failed to Delete Tag'
      handleClose={onClose}
      open={open}
      formName={FORM + tagId}
      acceptLabel='Delete'
      onAccept={deleteHandler}
      headerProps={{
        icon: DeleteOutline,
        title: `Are you sure you want to delete this tag?`,
        highlightedSubtitle:
          'By deleting this tag it will no longer be available to use when writing a note. It will also be removed from all notes and leads that it was applied.',
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid
            container
            justifyContent='center'
            alignItems='center'
            spacing={2}
          >
            <Grid item xs={12}>
              <Typography variant='h6'>
                This action cannot be undone.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </DazzlingDialog>
  );
}
