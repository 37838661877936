// images
import firstLogo from 'assets/images/phiredUpLogoWhite.png';
import secondLogo from 'assets/images/chapterBuilderLogoWhite.png';
import imageContainerBackgroundImage from 'assets/images/landingBackground.jpg';
import formHeaderDefaultLogo from 'assets/images/chapterBuilderLogoWhite.png';

// ChapterBuilder
const getCB = (): SiteVisualData => ({
  pageTitle: 'ChapterBuilder',
  firstLogo,
  firstLogoAlt: 'Phired Up',
  formHeaderDefaultLogo,
  secondLogo,
  secondLogoAlt: 'ChapterBuilder',
  landingRightSideText: 'Technology to grow fraternities and sororities',
  imageContainerBackgroundImage,
  supportEmail: 'chapterbuildersupport@phiredup.com',
  about: 'https://www.phiredup.com/chapterbuilder',
  demo: 'https://www.phiredup.com/councildirector#Request-A-Demo-CD',
  pricing: 'https://www.phiredup.com/chapterbuilder#Pricing',
  facebook: 'https://www.facebook.com/PhiredUp/',
  instagram: 'https://www.instagram.com/phired.up',
  twitter: 'https://x.com/phiredup',
  deletePhoneNumberModalFirstMessage:
    'Are you sure you want to delete this phone number for your ChapterBuilder account?',
  preferencesCardFirstLabel: 'Receive Emails From ChapterBuilder',
  preferencesCardSecondLabel:
    'Receive Push Notifications From ChapterBuilder on Android and iOS',
  errorDialogSubtitle:
    'Report any unresolvable errors to chapterbuildersupport@phiredup.com',
  deleteAccountCardHighlightedSubtitle:
    'Are you sure you want to delete your personal ChapterBuilder account? Note this will not delete your organization/chapter only your personal account. By selecting the delete button you will be permanently removing your account. You will no longer receive notifications and will no longer have access.',
  deleteAccountCardSubtitle:
    'Do you want to permanently delete your ChapterBuilder account?',
  callPanelMessage:
    "We couldn't find a ChapterBuilder phone number for your account.",
  createEventModalLabel: 'Invite all of your ChapterBuilder members to event',
  textMessageFormMessage:
    "We couldn't find a ChapterBuilder phone number for your account.",
  deletedLeadCardMessage:
    'This lead has been removed from your ChapterBuilder account.',
  deleteLeadModalSubtitle: 'Remove lead from ChapterBuilder',
  leadMessageTabMessage:
    "We couldn't find a ChapterBuilder phone number for your account.",
  inviteMembersCardSubtitle:
    'Email fellow chapter members an invitation to join your ChapterBuilder team',
  messagesContentMessage:
    "We couldn't find a ChapterBuilder phone number for your account.",
  paymentSetupCardAlertMessage:
    'By creating and collecting fees through ChapterBuilder, you acknowledge that both Stripe and Phired Up will be collecting a percentage of your fees. To see full terms and conditions, please click on the link below.',
  unlinkChapterModalAdditionalSubtitle:
    'If you want to re-link this group, you will need to contact chapterbuildersupport@phiredup.com.',
  selectChapterFormError: 'Failed to create or join chapter',
  selectChapterFormCouncilLabel: 'Council',
  selectChapterFormCouncilPlaceholder: 'Search Councils',
  selectChapterFormOrganizationLabel: 'Organization',
  selectChapterFormOrganizationPlaceholder: 'Search Organizations',
  selectChapterHeaderTitle: 'Select your chapter',
  selectChapterHeaderSubTitle: 'Join an existing chapter, or start a new one',
  selectChapterFormSchemaCouncilMessage: "^Council can't be blank",
  selectChapterFormSchemaOrganizationMessage: "^Organization can't be blank",
  loginFormHeaderTitle: 'Welcome Back',
  loginFormMessage:
    'The ChapterBuilder System is experiencing issues. We are working to resolve this as quickly as we can. Please wait a few minutes and attempt to login again.',
  signupFormHeaderTitle: 'Welcome To',
  signupFormActionsText: "By signing up, you agree to Phired Up's",
  appHeaderLogo: secondLogo,
  appHeaderLogoAlt: 'ChapterBuilder',
});

export default getCB;
