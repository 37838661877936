import { Map } from 'immutable';
// constants
import {
  USER_REGISTER,
  USER_VERIFY_EMAIL,
  SYSTEM_ADMIN_FETCH,
  USER_CLEAR,
  USER_FETCH_BY_IDS,
} from 'store/constants/userTypes';
// helpers
import httpReducer from './helpers/httpReducer';

export default function reduceUser(state = Map(), action = {}) {
  switch ((action as any).type) {
    case USER_REGISTER:
      return httpReducer(state, action);
    case SYSTEM_ADMIN_FETCH:
      return httpReducer(state, action);
    case USER_VERIFY_EMAIL:
      return httpReducer(state, action);
    case USER_FETCH_BY_IDS:
      return httpReducer(state, action, { appendResult: true });
    case USER_CLEAR:
      return Map();
    default:
      return state;
  }
}
