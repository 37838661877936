export interface InitialParamsProps {
  isCouncilAdmin: boolean;
  currentGroupId: number;
  type?: 'GREEK_ORGANIZATION' | 'COUNCIL' | 'GREEK_CHAPTER';
  initialOrder?: ('asc' | 'desc')[];
  initialOrderBy?: string;
  search?: string;
}

export interface FiltersInitialParams {
  latestActivityStart?: string;
  latestActivityEnd?: string;
  labelIds?: (string | number)[];
}

export interface InitialParams {
  type: string;
  orderBy?: string;
  search?: string;
  filters?: FiltersInitialParams;
  fetchParents?: boolean;
  initialOrder?: 'asc' | 'desc';
  initialOrderBy?: string;
  labelsString?: string;
  parentId?: number | string;
}

const getInitialParams = ({
  type = 'GREEK_CHAPTER',
  isCouncilAdmin,
  currentGroupId,
  search,
  initialOrder = ['desc', 'asc'],
  initialOrderBy = 'name',
}: InitialParamsProps): InitialParams => {
  const getOptions = () => {
    if (search) {
      const urlParams = new URLSearchParams(search);
      return {
        orderByParam: urlParams.get('orderBy'),
        searchParam: urlParams.get('search'),
        groupParam:
          urlParams.get('group') || isCouncilAdmin ? currentGroupId : null,
        latestActivityStart: urlParams.get('latestActivityStart'),
        latestActivityEnd: urlParams.get('latestActivityEnd'),
        labelsString: urlParams.get('labels'),
      };
    }
    return {
      groupParam: currentGroupId,
    };
  };

  const {
    orderByParam,
    searchParam,
    groupParam,
    latestActivityStart,
    latestActivityEnd,
    labelsString,
  } = getOptions() || {};

  const labels = labelsString
    ? labelsString?.split(',')?.map(urlLabel => parseInt(urlLabel, 10))
    : null;
  const order =
    orderByParam && orderByParam?.charAt(0) === '-'
      ? initialOrder[0]
      : initialOrder[1];
  const orderBy = orderByParam
    ? orderByParam.charAt(0) === '-'
      ? orderByParam.substr(1)
      : orderByParam
    : initialOrderBy;

  return {
    type,
    orderBy: orderByParam || orderBy,
    filters: {
      ...(latestActivityStart ? { latestActivityStart } : {}),
      ...(latestActivityEnd ? { latestActivityEnd } : {}),
      ...(labels ? { labelIds: labels } : {}),
    },
    fetchParents: type === 'GREEK_CHAPTER',
    initialOrder: order,
    initialOrderBy: orderBy,
    ...(labelsString ? { labelsString } : {}),
    ...(searchParam ? { search: searchParam } : {}),
    ...(groupParam ? { parentId: groupParam } : {}),
  };
};

export default getInitialParams;
