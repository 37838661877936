import { useContext } from 'react';

// api
import {
  useAuthIntegrationsQuery,
  useAuthSessionIdQuery,
} from 'api/integrations';

// context
import { SiteVisualDataContext } from 'components/SiteVisualData';

// material-ui
import {
  Divider,
  Grid,
  makeStyles,
  Typography,
  Button,
  CircularProgress,
  Link,
} from '@material-ui/core';

// helpers
import { setIntegrationId, setStateToken } from 'helpers/oidcUtils';

const LoginSSO = () => {
  const classes = useStyles();
  const { firstLogoAlt } = useContext(SiteVisualDataContext);
  const { data = [], isLoading } = useAuthIntegrationsQuery();

  const {
    data: sessionId,
    isLoading: sessionIdLoading,
  } = useAuthSessionIdQuery();

  if (isLoading || sessionIdLoading) {
    return (
      <Grid
        container
        direction='column'
        alignItems='center'
        justifyContent='center'
        style={{ paddingTop: '0.4em', paddingBottom: '0.4em' }}
      >
        <CircularProgress color='secondary' size={30} />
      </Grid>
    );
  }

  return data.length > 0 ? (
    <Grid container direction='row' className={classes.grid}>
      <Grid container alignItems='center'>
        <Grid item xs={5}>
          <Divider />
        </Grid>

        <Grid item xs={2}>
          <Typography variant='h6' align='center'>
            or
          </Typography>
        </Grid>

        <Grid item xs={5}>
          <Divider />
        </Grid>
      </Grid>

      <Grid
        container
        xs={12}
        className={classes.ssoButtons}
        direction='column'
        spacing={1}
      >
        {data.map(integration => (
          <Grid key={integration.id} item>
            <Button
              className={classes.ssoSignIn}
              onClick={() => {
                if (integration.authorizationEndpoint) {
                  const url = new URL(integration.authorizationEndpoint);
                  url.searchParams.append('client_id', integration.clientId);
                  url.searchParams.append('response_type', 'code');
                  url.searchParams.append('scope', integration.scope);
                  url.searchParams.append('prompt', 'login'); // TODO: This forces the
                  // browser to always prompt for login. Since we are doing the IDP sign out
                  // behind the scenes on the API side instead of from the browser,
                  // there is some kind of state not being removed. So unless you close
                  // your browser it will automatically sign in the last person without
                  // prompting login credentials unless we do this.

                  const redirectUrl = new URL(
                    '/oidc/redirect',
                    window.location.origin
                  );
                  url.searchParams.append('redirect_uri', redirectUrl.href);
                  // create base64 encoded token to identify the request
                  if (sessionId) {
                    setStateToken(sessionId);
                    url.searchParams.append('state', sessionId);
                  }

                  setIntegrationId(integration.id.toString());
                  window.location.replace(url.href);
                }
              }}
            >
              {integration.name === 'google' && (
                <img
                  className={classes.googleLogo}
                  src='/google_logo.svg'
                  alt={integration.label}
                />
              )}
              {integration.name === 'imis' && (
                <img
                  className={classes.imisLogo}
                  src='/delta_zeta_icon.png'
                  alt={integration.label}
                />
              )}
              Continue with {integration.label}
            </Button>
          </Grid>
        ))}
      </Grid>

      <Typography
        variant='subtitle2'
        display='inline'
        className={classes.ssoText}
      >
        By signing up with SSO, you agree to {firstLogoAlt}'s{' '}
        <Link
          className={classes.boldLink}
          href='https://www.phiredup.com/privacy-policy'
          rel='noopener'
          target='_blank'
          color='textPrimary'
        >
          Privacy Policy
        </Link>
        {' and '}
        <Link
          className={classes.boldLink}
          href='https://www.phiredup.com/eula'
          rel='noopener'
          target='_blank'
          color='textPrimary'
        >
          End User License Agreement
        </Link>
      </Typography>
    </Grid>
  ) : null;
};

const useStyles = makeStyles(theme => ({
  grid: {
    [theme.breakpoints.up('sm')]: {
      padding: 24,
    },
  },
  ssoButtons: {
    paddingTop: 40,
  },
  ssoSignIn: {
    width: 300,
    color: 'black',
    background: 'white',
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.75)',
    },
  },
  ssoText: {
    maxWidth: 400,
    paddingTop: 8,
  },
  boldLink: {
    fontWeight: 'bold',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  googleLogo: {
    marginRight: '0.5em',
    height: 18,
    width: 18,
  },
  imisLogo: {
    marginRight: '0.5em',
    height: 16,
    width: 'auto',
  },
}));

export default LoginSSO;
