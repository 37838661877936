import {
  LABEL_FETCH_REQUEST,
  LABEL_CREATE_REQUEST,
  LABEL_UPDATE_REQUEST,
  LABEL_DELETE_REQUEST,
  LABEL_CLEAR,
} from 'store/constants/labelTypes';

export function fetchLabelsAction({ groupId }: { groupId: number }) {
  return {
    type: LABEL_FETCH_REQUEST,
    payload: { groupId },
  };
}

export function createLabelAction({
  formName,
  fields,
}: {
  formName: string;
  fields: { title: string; description: string; color: string };
}) {
  return {
    type: LABEL_CREATE_REQUEST,
    payload: { formName, fields },
  };
}

export function updateLabelAction({
  formName,
  id,
  fields,
}: {
  formName: string;
  id: number;
  fields: { title: string; description: string; color: string };
}) {
  return {
    type: LABEL_UPDATE_REQUEST,
    payload: { formName, id, fields },
  };
}

export function deleteLabelAction({
  formName,
  id,
}: {
  formName: string;
  id: number;
}) {
  return {
    type: LABEL_DELETE_REQUEST,
    payload: { formName, id },
  };
}

export function clearLabelsAction() {
  return {
    type: LABEL_CLEAR,
  };
}
