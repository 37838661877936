import React, { useEffect, useCallback } from 'react';
import { isEqual } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import humps from 'humps';

// MUI components
import {
  Grid,
  Card,
  CardContent,
  Typography,
  List,
  ListItem,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  RecentActors,
  Group,
  SwapVert,
  DateRange,
  AccessTime,
  PhoneDisabled,
} from '@material-ui/icons';

// components
import Header from 'components/Header';
import StatItem from 'components/StatItem';
import Accordion from 'components/Accordion';
import ReportRefreshButton from 'components/ReportRefreshButton';

// action creators
import { createLeadsOverviewReport } from 'store/actions/apiTaskActions';

// selectors
import getCurrentGroupId from 'store/selectors/getCurrentGroupId';
import getForm from 'store/selectors/getForm';
import getSliceEntityById from 'store/selectors/getSliceEntityById';

const FORM = 'leadsOverviewReportForm';

const icons = {
  totalLeads: <Group />,
  averageTimeOnList: <DateRange />,
  statusChangeFrequency: <SwapVert />,
  contactFrequency: <AccessTime />,
  leadsNeverContacted: <PhoneDisabled />,
};

// We use these skeleton columns so that the icons are displayed while loading
const skeletonColumns = [
  { key: 'totalLeads', label: '' },
  { key: 'averageTimeOnList', label: '' },
  { key: 'statusChangeFrequency', label: '' },
  { key: 'contactFrequency', label: '' },
  { key: 'leadsNeverContacted', label: '' },
];

export default function LeadsOverviewCard() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const {
    currentGroupId,
    report: {
      data: { content = [], meta: { columns = skeletonColumns } = {} } = {},
    } = {},
    form: { isSubmitting },
  } = useSelector(
    state => ({
      currentGroupId: getCurrentGroupId(state),
      form: getForm(FORM)(state),
      report: getSliceEntityById(
        'report',
        'ReportTypes.LEADS_OVERVIEW',
        {}
      )(state),
    }),
    isEqual
  );

  const dispatchCreateReport = useCallback(
    ({ forceRefresh }: { forceRefresh: boolean }) => {
      dispatch(
        createLeadsOverviewReport({ groupId: currentGroupId, forceRefresh })
      );
    },
    [dispatch, currentGroupId]
  );

  useEffect(() => {
    dispatchCreateReport({ forceRefresh: false });
  }, [dispatchCreateReport]);

  return (
    <Card>
      <CardContent className={classes.cardContent}>
        <Grid container>
          <Grid item xs={12} sm>
            <Grid container justifyContent='space-between'>
              <Grid item xs={11}>
                <Header title='Leads Overview' icon={RecentActors} compact />
              </Grid>
              <Grid item xs={1}>
                <ReportRefreshButton
                  loading={isSubmitting}
                  onRefresh={dispatchCreateReport}
                />
              </Grid>
            </Grid>
          </Grid>

          {columns.map(({ key, label }: any, index: any) => (
            <Grid item xs={12}>
              <StatItem
                title={
                  Array.isArray(content[humps.camelize(key)])
                    ? content[humps.camelize(key)].length
                    : content[humps.camelize(key)]
                }
                subtitle={label}
                // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                icon={icons[humps.camelize(key)]}
                loading={isSubmitting}
                gutterBottom={index < columns.length}
              />
              {index === columns.length - 1 && (
                <Accordion
                  label='View leads never contacted'
                  details={<LeadsList leads={content[humps.camelize(key)]} />}
                />
              )}
            </Grid>
          ))}
        </Grid>
      </CardContent>
    </Card>
  );
}

LeadsList.defaultProps = {
  leads: [],
};

type OwnLeadsListProps = {
  leads?: any; // TODO: PropTypes.instanceOf(Array)
};

type LeadsListProps = OwnLeadsListProps & typeof LeadsList.defaultProps;

function LeadsList({ leads }: LeadsListProps) {
  const history = useHistory();
  const navigate = useCallback(
    leadId => {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'search' does not exist on type 'History<... Remove this comment to see the full error message
      const { search } = history;
      history.push({ pathname: `/leads/${leadId}`, search });
    },
    [history]
  );

  return (
    <List>
      {leads.map(({ leadId, leadFullName }: any) => (
        <ListItem key={leadId} onClick={() => navigate(leadId)} button>
          <Typography variant='subtitle2'>{leadFullName}</Typography>
        </ListItem>
      ))}
    </List>
  );
}

const useStyles = makeStyles(theme => ({
  cardContent: {
    paddingLeft: 24,
    paddingRight: 24,
  },
}));
