import { List, Map } from 'immutable';
import { denormalize } from 'normalizr';
import { feeSchema } from 'store/schemas/feeSchema';

export default function getFormFees(state: any): FormFee[] {
  const formFee = state.get('formFee', Map());
  const result = formFee.getIn(['data', 'result'], List());

  const entities = {
    formFee: state.getIn(['formFee', 'data', 'byId'], Map()).toJS(),
  };

  return denormalize(result.toJS(), [feeSchema], entities);
}
