import {
  EVENT_CREATE_REQUEST,
  EVENT_UPDATE_REQUEST,
  EVENT_FETCH_FOR_GROUP_REQUEST,
  EVENT_FETCH_BY_ID_REQUEST,
  EVENT_DELETE_REQUEST,
  CLEAR_EVENT,
} from 'store/constants/eventTypes';

export function createEvent({ groupId, formName, ...values }: any) {
  return {
    type: EVENT_CREATE_REQUEST,
    payload: { groupId, formName, ...values },
  };
}

export function deleteEvent({ eventId, groupId }: any) {
  return {
    type: EVENT_DELETE_REQUEST,
    payload: { eventId, groupId },
  };
}

export function updateEvent({ groupId, eventId, formName, ...values }: any) {
  return {
    type: EVENT_UPDATE_REQUEST,
    payload: { groupId, eventId, formName, ...values },
  };
}

export function fetchEventsForGroup({ groupId }: any) {
  return {
    type: EVENT_FETCH_FOR_GROUP_REQUEST,
    payload: { groupId },
  };
}

export function fetchEventById({ groupId, eventId }: any) {
  return {
    type: EVENT_FETCH_BY_ID_REQUEST,
    payload: { groupId, eventId },
  };
}

export function clearEvents() {
  return {
    type: CLEAR_EVENT,
  };
}
