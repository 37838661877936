import React, { useCallback } from 'react';

// material-ui
import { Grid, Paper, Box, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { DragIndicator, Delete } from '@material-ui/icons';

// components
import IconButton from 'components/IconButton';
import Switch from 'components/Switch';
import TextField from 'components/TextField';
import { Field } from 'react-final-form';

type Props = {
  fieldPrefix?: string;
  isDefault?: boolean;
  onDelete?: (...args: any[]) => any;
};

export default function Status({ isDefault, fieldPrefix, onDelete }: Props) {
  const theme = useTheme();
  const isExtraSmall = useMediaQuery(theme.breakpoints.only('xs'));

  const handleClick = useCallback(
    // @ts-expect-error ts-migrate(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
    () => onDelete(Number(fieldPrefix.match(/\d+/))),
    [onDelete, fieldPrefix]
  );

  return (
    <Box clone bgcolor='background.lightPaper' paddingX={3} paddingY={1}>
      <Paper>
        <Grid
          container
          alignItems='center'
          spacing={isExtraSmall ? 1 : 3}
          wrap={isExtraSmall ? 'wrap' : 'nowrap'}
        >
          <Grid item xs={2} sm='auto'>
            <DragIndicator />
          </Grid>
          <Grid item xs={10} sm={2}>
            <Field
              name={`${fieldPrefix}.abbreviation`}
              label='Abbreviation'
              variant='outlined'
              component={TextField}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Field
              fullWidth
              name={`${fieldPrefix}.title`}
              label='Title'
              variant='outlined'
              component={TextField}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Field
              fullWidth
              name={`${fieldPrefix}.description`}
              label='Description'
              variant='outlined'
              component={TextField}
            />
          </Grid>
          <Grid item xs={8} sm={2}>
            <Field
              name={`${fieldPrefix}.isDefault`}
              label='Default'
              type='checkbox'
              disableRipple
              keepChecked={isDefault}
              component={Switch}
            />
          </Grid>

          <Grid item xs={4} sm={1}>
            <Box clone display='block' marginLeft='auto'>
              <IconButton disabled={isDefault} onClick={handleClick}>
                <Delete />
              </IconButton>
            </Box>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
}
