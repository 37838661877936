import { all } from 'redux-saga/effects';

// sagas
import createReferralFormSaga from './createReferralFormSaga';
import createReferralFormTokenSaga from './createReferralFormTokenSaga';
import deleteReferralFormSaga from './deleteReferralFormSaga';
import fetchReferralFormsForGroupSaga from './fetchReferralFormsForGroupSaga';
import sendPreviewEmailSaga from './sendPreviewEmailSaga';
import submitReferralFormSaga from './submitReferralFormSaga';
import updateReferralFormSaga from './updateReferralFormSaga';
import fetchReferralFormByIdSaga from './fetchReferralFormByIdSaga';

const sessionSaga = function* rootSaga() {
  yield all([
    createReferralFormSaga(),
    createReferralFormTokenSaga(),
    deleteReferralFormSaga(),
    fetchReferralFormsForGroupSaga(),
    sendPreviewEmailSaga(),
    submitReferralFormSaga(),
    updateReferralFormSaga(),
    fetchReferralFormByIdSaga(),
  ]);
};

export default sessionSaga;
