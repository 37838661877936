import React from 'react';
import PropTypes from 'prop-types';

// material-ui
import Grid from '@material-ui/core/Grid';

const VerticalSplit = function({ leftSide, rightSide }: any) {
  return (
    <Grid container>
      <Grid item sm={12} md={6}>
        {leftSide}
      </Grid>

      <Grid item sm={12} md={6}>
        {rightSide}
      </Grid>
    </Grid>
  );
};

VerticalSplit.propTypes = {
  leftSide: PropTypes.element.isRequired,
  rightSide: PropTypes.element.isRequired,
};

export default VerticalSplit;
