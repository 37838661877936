import React, { useMemo, useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { List, Map } from 'immutable';

// hooks
import { useSelector } from 'react-redux';
import { useIsFeatureEnabled } from 'helpers/hooks/featureHooks';

// MUI components
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Grid, useMediaQuery } from '@material-ui/core';
import Alarm from '@material-ui/icons/Alarm';

// components
import ActivityFeed from 'components/ActivityFeed';
import Banner from 'components/Banner';
import LeaderboardTab from './LeaderboardTab';
import TabController from 'components/TabController';
import TasksTab from './TasksTab';

// selectors
import getCurrentGroupId from 'store/selectors/getCurrentGroupId';

// helpers
import { DEFAULT_LEADS_ORDER_BY } from 'api/leads';

const Home = function() {
  const history = useHistory();
  const { groupId, uncontactedLeadCount } = useSelector((state: any) => ({
    groupId: getCurrentGroupId(state),
    uncontactedLeadCount: state.getIn(
      ['currentGroup', 'data', 'uncontactedLeadCount'],
      0
    ),
  }));
  const source = useMemo<{ name: 'group' | 'pnm'; id: number }>(
    () => ({ name: 'group', id: groupId }),
    [groupId]
  );

  const navigateToLeadList = useCallback(() => {
    history.push({
      pathname: 'leads',
      search: `orderBy=${DEFAULT_LEADS_ORDER_BY}&uncontacted=true`,
    });
  }, [history]);

  const theme = useTheme();
  const classes = useStyles();
  const isMediumAndDown = useMediaQuery(theme.breakpoints.down('md'), {
    noSsr: true,
  });
  const activityEnabled = useIsFeatureEnabled('activity');
  const tasksEnabled = useIsFeatureEnabled('tasks');
  const goalsEnabled = useIsFeatureEnabled('goals');
  const leaderboardEnabled = useIsFeatureEnabled('leaderboard');

  const displaySideTabController =
    tasksEnabled && (goalsEnabled || leaderboardEnabled);
  const displayMainTabController =
    isMediumAndDown &&
    (tasksEnabled || goalsEnabled || leaderboardEnabled || activityEnabled);

  const initialTab = isMediumAndDown ? 'activity' : 'tasks';
  const [currentTab, setCurrentTab] = useState(initialTab);

  useEffect(() => {
    if (isMediumAndDown) {
      setCurrentTab('activity');
    }
  }, [isMediumAndDown]);

  if (!groupId) return null;

  let tabs = List();

  if (tasksEnabled) {
    tabs = tabs.push(
      Map({
        key: 'tasks',
        label: 'Tasks',
      })
    );
  }

  if (goalsEnabled) {
    tabs = tabs.push(
      Map({
        key: 'goals',
        label: 'Goals',
      })
    );
  }

  if (leaderboardEnabled) {
    tabs = tabs.push(
      Map({
        key: 'leaderboard',
        label: 'Leaderboard',
      })
    );
  }

  if (isMediumAndDown && activityEnabled) {
    tabs = tabs.unshift(
      Map({
        key: 'activity',
        label: 'Activity Feed',
      })
    );
  }

  const renderActivityFeed = () =>
    activityEnabled && (
      <Grid item xs className={classes.fullHeight}>
        <Grid container className={classes.fullHeight}>
          {uncontactedLeadCount > 0 && (
            <Grid
              item
              xs={12}
              className={classes.uncontactedLeadBanner}
              key='uncontactedBanner'
            >
              <Banner
                buttonLabel='View now'
                header={`${uncontactedLeadCount} Uncontacted Leads`}
                subtitle='You can’t recruit who you don’t know! Contact them today.'
                icon={<Alarm />}
                onButtonClick={navigateToLeadList}
              />
            </Grid>
          )}
          <Grid item xs key='feed' className={classes.fullHeight}>
            <ActivityFeed {...{ source }} />
          </Grid>
        </Grid>
      </Grid>
    );

  const renderTabContent = () => {
    switch (currentTab) {
      case 'activity':
      default:
        return renderActivityFeed();
      case 'tasks':
        return <TasksTab key='tasks' />;
      case 'goals':
      case 'leaderboard':
        // @ts-expect-error ts-migrate(2322) FIXME: Type '{ rowsPerPage: number; }' is not assignable ... Remove this comment to see the full error message
        return <LeaderboardTab rowsPerPage={5} />;
    }
  };

  return (
    <Grid
      container
      justifyContent='center'
      // Allows activity feed to infinite scroll, without messing up layout on other tabs
      className={
        (displayMainTabController && currentTab === 'activity') ||
        displaySideTabController
          ? classes.homeContainer
          : undefined
      }
    >
      {displayMainTabController
        ? [
            <Grid item xs={12} sm={10} lg={7} xl={6} key='controller'>
              <TabController
                // @ts-expect-error ts-migrate(2322) FIXME: Type '{ id: string; tabs: List<any>; currentTab: s... Remove this comment to see the full error message
                id='mainTabController'
                tabs={tabs}
                currentTab={currentTab}
                onAnyTabClick={setCurrentTab}
              />
            </Grid>,
            renderTabContent(),
          ]
        : [
            renderActivityFeed(),

            <Grid item lg={5} xl={6} key='sideLayout'>
              <Grid container spacing={1}>
                {displaySideTabController && (
                  <Grid item xs={12}>
                    <Grid
                      container
                      justifyContent='center'
                      className={classes.sideLayoutContainer}
                    >
                      <Grid item xs={12} sm={10} lg={11}>
                        <TabController
                          // @ts-expect-error ts-migrate(2322) FIXME: Type '{ id: string; tabs: List<any>; currentTab: s... Remove this comment to see the full error message
                          id='sideTabController'
                          tabs={tabs}
                          currentTab={currentTab}
                          onAnyTabClick={setCurrentTab}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                )}

                <Grid
                  item
                  xs={12}
                  className={
                    !displaySideTabController
                      ? classes.singleTabContainer
                      : undefined
                  }
                >
                  {renderTabContent()}
                </Grid>
              </Grid>
            </Grid>,
          ]}
    </Grid>
  );
};

const useStyles = makeStyles(theme => ({
  homeContainer: {
    height: '100%',
    overflow: 'hidden',

    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  sideLayoutContainer: {
    paddingTop: 8,
  },
  singleTabContainer: {
    marginTop: 24,
  },
  uncontactedLeadBanner: {
    marginTop: 24,
    marginLeft: 84,
  },
  fullHeight: {
    height: '100%',
  },
}));

export default Home;
