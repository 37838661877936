import React, { useState, useCallback } from 'react';
import { Map, List } from 'immutable';
import { useSelector } from 'react-redux';

// mui components
import { useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';

// icons
import ExportIcon from '@material-ui/icons/GetApp';
import EmailIcon from '@material-ui/icons/Email';
import DeleteIcon from '@material-ui/icons/Delete';
import ShareIcon from '@material-ui/icons/Share';

// components
import MegaMenu from 'components/MegaMenu';
import DeleteFormModal from './DeleteFormModal';
import PreviewEmailModal from './PreviewEmailModal';
import ShareFormModal from './ShareFormModal';
import ExportResponsesModal from './ExportResponsesModal';

// selectors
import getPermission from 'store/selectors/getPermission';

const FormOptionsMenu = function({
  anchorEl,
  onClose,
  formId,
  eventId,
  isCouncilForm,
  isOrganizationForm,
}: any) {
  const theme = useTheme();
  const isExtraSmall = useMediaQuery(theme.breakpoints.only('xs'));

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [previewModalOpen, setPreviewModalOpen] = useState(false);
  const [shareModalOpen, setShareModalOpen] = useState(false);
  const [exportModalOpen, setExportModalOpen] = useState(false);

  const openDeleteModal = useCallback(() => setDeleteModalOpen(true), []);
  const closeDeleteModal = useCallback(() => setDeleteModalOpen(false), []);

  const openPreviewModal = useCallback(() => setPreviewModalOpen(true), []);
  const closePreviewModal = useCallback(() => setPreviewModalOpen(false), []);

  const openShareModal = useCallback(() => setShareModalOpen(true), []);
  const closeShareModal = useCallback(() => setShareModalOpen(false), []);

  const openExportModal = useCallback(() => setExportModalOpen(true), []);
  const closeExportModal = useCallback(() => setExportModalOpen(false), []);

  const { canShareForm, canDeleteForm } = useSelector(state => ({
    canUpdateForm: getPermission('form.updateOne')(state),
    canShareForm: getPermission('form.createToken')(state),
    canDeleteForm: getPermission('form.deleteOne')(state),
  }));

  const getMenuSections = () => {
    // This list is composed into immutable elements manually
    //  so that the icon components don't get converted into objects
    let menuSections = List([
      Map({
        sectionHeader: 'Form Options',
        id: 'formOptions',
        items: List([
          Map({
            id: 'export',
            label: 'Export Responses',
            icon: <ExportIcon />,
            onClick: openExportModal,
          }),
          Map({
            id: 'share',
            label: 'Form Sharing',
            icon: <ShareIcon />,
            display: canShareForm && isExtraSmall && !Boolean(eventId),
            onClick: openShareModal,
          }),
          Map({
            id: 'email',
            label: 'Send Preview Email',
            icon: <EmailIcon />,
            onClick: openPreviewModal,
          }),
        ]),
      }),
      Map({
        id: 'formDelete',
        display: canDeleteForm,
        items: List([
          Map({
            id: 'delete',
            label: 'Delete Form',
            icon: <DeleteIcon />,
            onClick: openDeleteModal,
          }),
        ]),
      }),
    ]);

    return menuSections;
  };

  return (
    <div>
      <MegaMenu
        // @ts-expect-error ts-migrate(2322) FIXME: Type '{ id: string; anchorEl: any; handleClose: an... Remove this comment to see the full error message
        id='formOptionsMenu'
        anchorEl={anchorEl}
        handleClose={onClose}
        menuSections={getMenuSections()}
      />
      <DeleteFormModal
        formId={formId}
        open={deleteModalOpen}
        onClose={closeDeleteModal}
      />
      <PreviewEmailModal
        formId={formId}
        open={previewModalOpen}
        onClose={closePreviewModal}
      />
      <ShareFormModal
        formId={formId}
        open={shareModalOpen}
        onClose={closeShareModal}
        isCouncilForm={!!isCouncilForm}
        isOrganizationForm={!!isOrganizationForm}
      />
      <ExportResponsesModal
        formId={formId}
        open={exportModalOpen}
        onClose={closeExportModal}
      />
    </div>
  );
};

export default FormOptionsMenu;
