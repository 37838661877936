import { useCallback, useMemo } from 'react';
import { isEqual } from 'lodash';
import { useParams } from 'react-router';

// components
import MemberSelector from 'components/MemberSelector';
import DateSelector from 'components/DateSelector';
import ActionsSelector from 'components/ChipsSelector';
import DazzlingDialog from 'components/DazzlingDialog';
import { Field, Form } from 'react-final-form';

// material-ui
import { Grid } from '@material-ui/core';

// icons
import { Timeline } from '@material-ui/icons';

export default function ActivityFilterDialog({
  eventsEnabled,
  filters,
  onClose,
  onFilter,
  open,
  reportingPlatformEnabled,
  showActions = true,
  showDate = true,
  showUsers = true,
}: {
  eventsEnabled: boolean;
  filters: any;
  onClose: (...args: any[]) => any;
  onFilter: (...args: any[]) => any;
  open: boolean;
  reportingPlatformEnabled?: boolean;
  showActions?: boolean;
  showDate?: boolean;
  showUsers?: boolean;
}) {
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'leadId' does not exist on type '{}'.
  const { leadId: onLeadProfile } = useParams();
  const defaultActionOptions = useMemo(() => {
    let defaultActionOptions = [
      { value: 'pnm.created', label: 'Added Leads' },
      { value: 'pnm.updated', label: 'Lead Info Updates' },
      { value: 'pnm_status.updated', label: 'Status Changes' },
      { value: 'pnm.deleted', label: 'Removed Leads' },
      { value: 'form.submitted', label: 'Leads Referred By Form' },
      { value: 'pnms.deleted', label: 'Bulk Removed Leads' },
      { value: 'note.created', label: 'Notes' },
      { value: 'pnms.imported', label: 'Imported Leads' },
    ];

    if (reportingPlatformEnabled) {
      defaultActionOptions = defaultActionOptions.concat([
        { value: 'pnm.reported', label: 'Reported Individual Lead' },
        { value: 'pnms.reported', label: 'Reported Multiple Leads' },
      ]);
    }

    if (eventsEnabled) {
      defaultActionOptions = defaultActionOptions.concat([
        { value: 'event_guests.created', label: 'Guests Invited To Event' },
      ]);
    }
    return defaultActionOptions;
  }, [reportingPlatformEnabled, eventsEnabled]);

  const [emailMessageAction, textMessageAction] = onLeadProfile
    ? ['email_message.sent', 'text_message.sent']
    : ['email_message.created', 'text_messages.created'];

  const actionOptions = useMemo(
    () => [
      ...defaultActionOptions,
      {
        value: emailMessageAction,
        label: 'Emails',
      },
      {
        value: textMessageAction,
        label: 'Text messages',
      },
    ],
    [emailMessageAction, textMessageAction, defaultActionOptions]
  );
  const handleFormSubmit = useCallback(
    fields => {
      const { actions } = fields;
      // Mutates actions, since we add or remove two actions on filtering with a single `Text messages` chip.
      if (actions) {
        if (actions.includes(textMessageAction))
          actions.push('text_message.received');
        else actions.splice(actions.indexOf('text_message.received'), 1);
      }
      onFilter(fields);
      onClose();
    },
    [onFilter, onClose, textMessageAction]
  );
  return (
    <Form
      onSubmit={handleFormSubmit}
      initialValues={{ ...filters }}
      initialValuesEqual={isEqual}
      render={({ handleSubmit, pristine }) => (
        <DazzlingDialog
          disabled={pristine}
          handleClose={onClose}
          open={open}
          onAccept={handleSubmit}
          headerProps={{
            icon: Timeline,
            title: 'Filter Feed',
            highlightedSubtitle: 'Select filters and refine your activity feed',
          }}
          acceptLabel='Filter'
        >
          <Grid container spacing={2}>
            {showUsers && (
              <Grid item xs={7} id='usersFilter'>
                <Field
                  name={'member'}
                  component={MemberSelector}
                  fullWidth
                  allowNull
                  isEqual={isEqual}
                />
              </Grid>
            )}

            {showDate && (
              <Grid item xs={7}>
                <Field
                  name={'createdOnDate'}
                  component={DateSelector}
                  fullWidth
                  disableFuture
                  allowNull
                />
              </Grid>
            )}

            {showActions && (
              <Grid item xs={12} id='actionsFilter'>
                <Field
                  name={'actions'}
                  // @ts-expect-error ts-migrate(2322) FIXME: Type 'typeof ChipsSelector' is not assignable to t... Remove this comment to see the full error message
                  component={ActionsSelector}
                  fullWidth
                  label='Include'
                  options={actionOptions}
                  atLeastOne
                  isEqual={(a, b) => isEqual(new Set(a), new Set(b))}
                />
              </Grid>
            )}
          </Grid>
        </DazzlingDialog>
      )}
    />
  );
}
