import moment from 'moment';
import { useMemo } from 'react';

export function useDateTime(datetimeString: any) {
  const datetime = useMemo(
    () => datetimeString && moment.utc(datetimeString).local(),
    [datetimeString]
  );
  let date, time, dayOfWeek;
  if (datetime) {
    date = datetime.format('MMM D');
    time = datetime.format('LT');
    dayOfWeek = datetime.format('ddd');
  }
  return { date, time, dayOfWeek };
}
