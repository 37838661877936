export const SESSION_CREATE = 'SESSION_CREATE';
export const SESSION_CREATE_REQUEST = 'SESSION_CREATE_REQUEST';

export const SESSION_DESTROY = 'SESSION_DESTROY';
export const SESSION_DESTROY_REQUEST = 'SESSION_DESTROY_REQUEST';

export const SESSION_REFRESH_REQUEST = 'SESSION_REFRESH_REQUEST';
export const SESSION_REFRESH = 'SESSION_REFRESH';

export const SESSION_VALIDATE = 'SESSION_VALIDATE';
export const SESSION_INVALIDATE = 'SESSION_INVALIDATE';

export const SESSION_VERIFY = 'SESSION_VERIFY';
export const SESSION_VERIFY_DONE = 'SESSION_VERIFY_DONE';
