import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import Immutable from 'immutable';

// action creators
import { loading, doneIndicator, success } from 'store/actions/httpActions';

// api
import client from 'sources/api';

// constants
import {
  SYSTEM_ADMIN_FETCH_REQUEST,
  SYSTEM_ADMIN_FETCH,
} from 'store/constants/userTypes';
import { EMAIL_FETCH } from 'store/constants/emailTypes';

// schemas
import { userSchema } from 'store/schemas/userSchema';
import { emailSchema } from 'store/schemas/emailSchema';

// helpers
import httpSaga from 'store/sagas/httpSaga';
import getResponsePayload from 'helpers/getResponsePayload';

const WATCH_TYPE = SYSTEM_ADMIN_FETCH_REQUEST;

export function* fetchSystemAdmins(action: any) {
  const TYPE = SYSTEM_ADMIN_FETCH;

  const {
    payload: { page = 1 },
  } = action;

  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  yield put(loading(TYPE));

  try {
    // @ts-expect-error ts-migrate(2569) FIXME: Type 'Generator<any, any, unknown>' is not an arra... Remove this comment to see the full error message
    const response = yield* httpSaga(
      TYPE,
      call(client.get, '/accounts', {
        params: { page, is_system_admin: true },
      }),
      {
        schema: [userSchema],
        dispatchSuccess: false,
      }
    );

    // Fetch emails for admins
    const {
      data: { results = [] },
    } = response;

    const accountIds = yield all(results.map((result: any) => result.id));

    yield all(
      accountIds.map((accountId: any) =>
        httpSaga(
          EMAIL_FETCH,
          call(client.get, '/emails', {
            params: { account_id: accountId },
          }),
          {
            schema: [emailSchema],
          }
        )
      )
    );

    // We wait to set success until after emails are done fetching
    const payload = getResponsePayload(response, [userSchema]);

    // Send the response data to the store for this type to be caught by a reducer
    yield put(
      success(
        TYPE,
        Immutable.fromJS({
          ...payload,
        })
      )
    );
  } finally {
    yield put(doneIndicator(TYPE));
  }
}

export function* watch() {
  yield takeEvery(WATCH_TYPE, fetchSystemAdmins);
}

export default function* root() {
  yield all([fork(watch)]);
}
