import * as serviceWorker from './serviceWorker';
import React from 'react';
import ReactDOM from 'react-dom';
import App from 'App';
import { Router } from 'react-router-dom';
import history from 'helpers/history';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import Elevio from 'elevio/lib/react';

// redux / saga
import { Provider } from 'react-redux';
import store from 'store';

// material-ui
import theme from 'assets/theme';
import { MuiThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

// components
import SiteVisualData from 'components/SiteVisualData';

// helpers
import isNamesListSite from 'helpers/isNamesListSite';

const queryClient = new QueryClient();

ReactDOM.render(
  <>
    <Elevio accountId={isNamesListSite() ? '6463fb2f38d01' : '5ef4adab5b609'} />
    <Router history={history}>
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen={false} />
        <Provider store={store}>
          <MuiThemeProvider theme={theme}>
            <SiteVisualData>
              <CssBaseline />
              <App />
            </SiteVisualData>
          </MuiThemeProvider>
        </Provider>
      </QueryClientProvider>
    </Router>
  </>,

  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA

serviceWorker.unregister();
