import { Map } from 'immutable';
// constants
import { SUGGESTED_FIELD_FETCH } from 'store/constants/suggestedFieldTypes';
// helpers
import httpReducer from './helpers/httpReducer';

export default function reduceSuggestedField(state = Map(), action = {}) {
  switch ((action as any).type) {
    case SUGGESTED_FIELD_FETCH:
      return httpReducer(state, action);
    default:
      return state;
  }
}
