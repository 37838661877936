import Immutable, { Map } from 'immutable';
// constants
import {
  FORM_DESTROY,
  FORM_SET_SUBMIT_FAILED,
  FORM_SET_SUBMIT_SUCCEEDED,
  FORM_START_SUBMIT,
  FORM_STOP_SUBMIT,
} from 'store/constants/formTypes';
const constructErrorPayload = function(action: any) {
  // @ts-expect-error ts-migrate(2525) FIXME: Initializer provides no value for this binding ele... Remove this comment to see the full error message
  const { payload: { error: { data = {}, status } = {} } = {} } = action;
  const { msg } = data;
  let errorPayload;
  const errorStatuses = [500, 405, 404];
  if (errorStatuses.includes(status) && !msg) {
    errorPayload = { message: 'Something went wrong, please try again' };
  } else if (msg) {
    errorPayload = { message: msg };
  } else if (status === 422) {
    errorPayload = {
      message: 'Successful with warnings',
      validationErrors: data,
    };
  } else {
    errorPayload = {
      message: 'Please fix validation errors and try again',
      validationErrors: data,
    };
  }
  return Immutable.fromJS(errorPayload);
};

export default function reduceForm(state = Map(), action = {}) {
  const { formName } = (action as any).payload || {};
  switch ((action as any).type) {
    case FORM_DESTROY:
      return state.delete(formName);
    case FORM_START_SUBMIT:
      return state.withMutations(map => {
        map.setIn([formName, 'submitting'], true);
        map.deleteIn([formName, 'submitFailed']);
        map.deleteIn([formName, 'submitSucceeded']);
        map.deleteIn([formName, 'error']);
        return map;
      });
    case FORM_STOP_SUBMIT:
      return state.setIn([formName, 'submitting'], false);
    case FORM_SET_SUBMIT_FAILED:
      const errorPayload = constructErrorPayload(action);
      return state.withMutations(map => {
        map.setIn([formName, 'submitting'], false);
        map.setIn([formName, 'submitFailed'], true);
        map.setIn([formName, 'submitSucceeded'], false);
        map.setIn([formName, 'error'], errorPayload);
        return map;
      });
    case FORM_SET_SUBMIT_SUCCEEDED:
      return state.withMutations(map => {
        map.setIn([formName, 'submitting'], false);
        map.setIn([formName, 'submitFailed'], false);
        map.setIn([formName, 'submitSucceeded'], true);
        map.setIn([formName, 'successMessage'], (action as any).payload.msg);
        map.deleteIn([formName, 'error']);
        return map;
      });
    default:
      return state;
  }
}
