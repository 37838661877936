import isNamesListSite from 'helpers/isNamesListSite';

export default function getFormTypeOptions(isCouncilForm?: boolean) {
  if (isCouncilForm) {
    return [
      {
        value: 'registration',
        label: 'Registration',
      },
      {
        value: 'interest',
        label: 'Interest',
      },
    ];
  }
  if (!isNamesListSite()) {
    return [
      {
        value: 'fair',
        label: 'Organization Fair',
      },
      {
        value: 'social_media',
        label: 'Social Media Referrals',
      },
      {
        value: 'tabling',
        label: 'Tabling',
      },
      {
        value: 'website',
        label: 'Website Referrals',
      },
      {
        value: 'other_group',
        label: 'Referrals From Other Organizations',
      },
      {
        value: 'info_session',
        label: 'Interest / Info Session',
      },
      {
        value: 'alumni',
        label: 'Alumni / Alumnae',
      },
      {
        value: 'marketing',
        label: 'Marketing Campaign',
      },
      {
        value: 'other',
        label: 'Other',
      },
    ];
  }

  return [
    {
      value: 'community_event',
      label: 'Community Event',
    },
    {
      value: 'info_session',
      label: 'Interest / Info Session',
    },
    {
      value: 'marketing',
      label: 'Marketing Campaign',
    },
    {
      value: 'referrals_from_members',
      label: 'Referrals From Members',
    },
    {
      value: 'other_group',
      label: 'Referrals From Other Organizations',
    },
    {
      value: 'social_media',
      label: 'Social Media',
    },
    {
      value: 'tabling',
      label: 'Tabling',
    },
    {
      value: 'website',
      label: 'Website',
    },
    {
      value: 'other',
      label: 'Other',
    },
  ];
}
