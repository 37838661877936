import Immutable, { List, Map } from 'immutable';
import { denormalize } from 'normalizr';

// schemas
import { groupSchema } from '../schemas/groupSchema';

export type GroupFormatType =
  | 'suggestions'
  | 'suggestionsByName'
  | 'suggestionsByOrgName';

export default function getChapters(state: any, format?: GroupFormatType) {
  const group = state.get('group', Map());

  const result = group.getIn(['data', 'greekChapterResult'], List());

  const entities = {
    group: state.getIn(['group', 'data', 'byId'], Map()).toJS(),
    tag: state.getIn(['tag', 'data', 'byId'], Map()).toJS(),
  };

  let items = denormalize(result.toJS(), [groupSchema], entities);

  // formats data for the suggestions of the AutoSuggest search component
  if (format === 'suggestions') {
    items = items.map((item: Group) => ({
      label: item.displayName,
      value: item.id,
    }));
  }

  if (format === 'suggestionsByName') {
    items = items.map((item: Group) => ({
      label: item.name || item.displayName,
      value: item.id,
    }));
  }

  if (format === 'suggestionsByOrgName') {
    items = items.map((item: Group) => ({
      label: item.organizationName,
      value: item.id,
    }));
  }

  return Immutable.fromJS(
    items.filter(
      (item: Group | { label: string; value: number }) => item !== undefined
    )
  );
}
