import React, { useEffect, useState, useCallback } from 'react';
import { Field, Form } from 'react-final-form';
import Immutable from 'immutable';
import validate from 'validate.js';
import { isEqual } from 'lodash';
// hooks
import { useSelector, useDispatch } from 'react-redux';
// selectors
import getLoadingFromState from 'store/selectors/getLoadingFromState';
import getChapters from 'store/selectors/getChapters';
// action creators
import { fetchGroups, createGroup } from 'store/actions/groupActions';
// MUI
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
// components
import TextField from 'components/TextField';
import GroupsAutoSuggest from 'components/GroupsAutoSuggest';
import DazzlingDialog from 'components/DazzlingDialog';
import CampusDropdown from 'components/CampusDropdown';
// icons
import CouncilIcon from '@material-ui/icons/School';
import OrgIcon from '@material-ui/icons/LocationCity';
import ChapterIcon from '@material-ui/icons/AccountBalance';
// helpers
import { getOrganizationGenderAsArray } from 'helpers/getOrganizationGender';
import { getOrganizationFocusAsArray } from 'helpers/getOrganizationFocus';

type Props = {
  groupType?: string | null;
  onClose: (...args: any[]) => any;
  open: boolean;
};

export default function CreateGroupForm({ groupType, onClose, open }: Props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [chapterExists, setChapterExists] = useState(false);
  const [selectedOrganizationId, setSelectedOrg] = useState(null);
  const [selectedCouncilId, setSelectedCouncil] = useState(null);
  const { chapters, groupLoading } = useSelector(
    state => ({
      chapters: getChapters(state),
      groupLoading: getLoadingFromState('group', false, false)(state),
    }),
    isEqual
  );
  useEffect(() => {
    setChapterExists(false);
  }, [groupLoading]);
  useEffect(() => {
    // Fetch chapter using the ids of the parent group suggestions, to see if chapter already exists
    if (selectedCouncilId && selectedOrganizationId) {
      dispatch(
        fetchGroups({
          parentIds: [selectedCouncilId, selectedOrganizationId],
          type: 'GREEK_CHAPTER',
          fetchParents: false,
        })
      );
    }
  }, [dispatch, selectedCouncilId, selectedOrganizationId]);
  const schema = {
    displayName: {
      presence: true,
      length: {
        maximum: 255,
      },
    },
  };
  if (groupType === 'GREEK_CHAPTER') {
    (schema as any).organization = { presence: true };
    (schema as any).council = { presence: true };
  }
  const handleValidate = (values = {}) => {
    const validationErrors = validate(values, schema);
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'council' does not exist on type '{}'.
    const { council = {}, organization = {} } = values;
    const councilId = council.value;
    const organizationId = organization.value;
    const parentIds = [councilId, organizationId];
    if (!groupLoading && groupType === 'GREEK_CHAPTER' && open) {
      if ((values as any).council && (values as any).organization) {
        // sets selected items so that we know whether or not to re-fetch chapters
        setSelectedOrg(organizationId);
        setSelectedCouncil(councilId);
      }
      const chapterParentsMatchSelected = Boolean(
        chapters.find((chapter: any) => {
          // determines if selected council and organization are the combination for an existing chapter
          const firstParent = chapter.getIn(['parents', 0, 'id']);
          const lastParent = chapter.getIn(['parents', 1, 'id']);
          return (
            parentIds.includes(firstParent) && parentIds.includes(lastParent)
          );
        })
      );
      // Makes create button disable when a chapter already exists after groups fetched
      if (
        chapterParentsMatchSelected &&
        selectedCouncilId &&
        selectedOrganizationId
      ) {
        setChapterExists(true);
      }
    }
    return validationErrors;
  };
  const onSubmit = (values: any) => {
    const immutableValues = Immutable.fromJS(values);
    const parents =
      groupType === 'GREEK_CHAPTER'
        ? [
            immutableValues.getIn(['council', 'value']),
            immutableValues.getIn(['organization', 'value']),
          ]
        : [];
    switch (groupType) {
      case 'GREEK_CHAPTER':
        dispatch(
          createGroup({
            formName: 'createGroupForm',
            name: `${immutableValues.getIn([
              'organization',
              'label',
            ])} at ${immutableValues.getIn(['council', 'label'])}`,
            displayName: immutableValues.get('displayName'),
            type: groupType,
            parents,
          })
        );
        break;
      case 'GREEK_ORGANIZATION':
        dispatch(
          createGroup({
            formName: 'createGroupForm',
            name: immutableValues.get('displayName'),
            displayName: immutableValues.get('displayName'),
            gender: immutableValues.get('gender'),
            focus: immutableValues.get('focus'),
            type: groupType,
            parents,
          })
        );
        break;
      case 'COUNCIL':
        dispatch(
          createGroup({
            formName: 'createGroupForm',
            name: immutableValues.get('displayName'),
            campus: immutableValues.getIn(['campus', 'label']),
            displayName: immutableValues.get('displayName'),
            type: groupType,
            parents,
          })
        );
        break;
      default:
        break;
    }
  };
  const renderAutoSuggests = (values: any) => {
    return [
      <Grid item xs={12} key='council' id='autoSuggest'>
        <Field id='council' name='council' required>
          {props => (
            <GroupsAutoSuggest
              groupType='COUNCIL'
              values={values}
              suggestionsCardType='expand'
              {...props}
            />
          )}
        </Field>
      </Grid>,
      <Grid item xs={12} key='organization' id='autoSuggest'>
        <Field id='organization' name='organization' required>
          {props => (
            <GroupsAutoSuggest
              groupType='GREEK_ORGANIZATION'
              values={values}
              suggestionsCardType='expand'
              {...props}
            />
          )}
        </Field>
      </Grid>,
    ];
  };

  const getDialogContent = useCallback(
    values => {
      const genderList = getOrganizationGenderAsArray().map(value => ({
        value,
        label: value,
      }));

      const focusList = getOrganizationFocusAsArray().map(value => ({
        value,
        label: value,
      }));

      return (
        <Grid container>
          <Grid item xs={12}>
            <Field
              id='textField'
              name='displayName'
              component={TextField}
              label='Display Name'
              required
            />
          </Grid>

          {groupType === 'COUNCIL' && (
            <Grid item xs={12}>
              <Field name='campus' label='Campus Name' required>
                {props => (
                  <CampusDropdown hasAddButton values={values} {...props} />
                )}
              </Field>
            </Grid>
          )}

          {groupType === 'GREEK_CHAPTER' && renderAutoSuggests(values)}

          {groupType === 'GREEK_ORGANIZATION' && (
            <>
              <Grid item xs={12}>
                <Field
                  name='gender'
                  component={TextField}
                  label='Gender'
                  options={genderList}
                  select
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  name='focus'
                  component={TextField}
                  label='Focus'
                  options={focusList}
                  select
                  required
                />
              </Grid>
            </>
          )}

          {chapterExists && (
            <Grid item xs={12} id='errorMessage'>
              <Typography
                color='error'
                variant='body2'
                className={classes.errorMessage}
              >
                Chapter already exists
              </Typography>
            </Grid>
          )}
        </Grid>
      );
    },
    [chapterExists, classes.errorMessage, groupType]
  );
  const getHeaderProps = () => {
    switch (groupType) {
      case 'GREEK_CHAPTER':
        return {
          icon: ChapterIcon,
          title: 'Create New Chapter',
          subtitle:
            'Enter chapter name, then select the council and organization the chapter belongs to',
        };
      case 'COUNCIL':
        return {
          icon: CouncilIcon,
          title: 'Add Council',
          subtitle: 'Create a new council dashboard',
        };
      case 'GREEK_ORGANIZATION':
        return {
          icon: OrgIcon,
          title: 'Add Organization',
          subtitle: 'Create a new organization dashboard',
        };
      default:
        return {
          title: 'Add',
        };
    }
  };
  const headerProps = getHeaderProps();
  const groupDeselected = !selectedOrganizationId || !selectedCouncilId;
  const disableForGreekChapters =
    (groupType === 'GREEK_CHAPTER' && groupDeselected) ||
    (groupType === 'GREEK_CHAPTER' && chapterExists);
  return (
    <Form
      id='createReferralForm'
      onSubmit={onSubmit}
      validate={handleValidate}
      render={({ handleSubmit, invalid, values, form: { restart } }) => (
        <DazzlingDialog
          acceptLabel='Create'
          children={getDialogContent(values)}
          disabled={invalid || groupLoading || disableForGreekChapters}
          formName='createGroupForm'
          handleClose={onClose}
          headerProps={headerProps}
          id='createDialog'
          onAccept={handleSubmit}
          onReject={restart}
          open={open}
          resetForm={restart}
        />
      )}
    />
  );
}
const useStyles = makeStyles(() => ({
  errorMessage: {
    marginBottom: 10,
  },
}));
