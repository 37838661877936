import { ManageSubscriptionStatus } from 'components/ManageSubscription/helpers/types';
import getPricesFromSubscriptions from 'components/ManageSubscription/helpers/getPricesFromSubscriptions';
import isSubscriptionDowngraded from 'components/ManageSubscription/helpers/isSubscriptionDowngraded';
import isItFreeSubscription from 'components/ManageSubscription/helpers/isItFreeSubscription';

const getNextStep = (
  currentModalStatus: ManageSubscriptionStatus,
  subscription?: null | StripeProduct,
  isItNewSubscription?: boolean,
  stripeProducts?: StripeProduct[],
  currentSubscriptionPlanProductId?: string
): ManageSubscriptionStatus => {
  const { month = false, year = false } =
    subscription && subscription?.subscriptions
      ? getPricesFromSubscriptions(subscription.subscriptions)
      : {};
  if (
    month &&
    year &&
    ![
      ManageSubscriptionStatus.SelectYourPlanYearOrMonth,
      ManageSubscriptionStatus.DowngradePlan,
      ManageSubscriptionStatus.ConfirmOrder,
      ManageSubscriptionStatus.EnterPaymentMethod,
    ].includes(currentModalStatus) &&
    !isItFreeSubscription(subscription)
  ) {
    return ManageSubscriptionStatus.SelectYourPlanYearOrMonth;
  }

  if (
    ![
      ManageSubscriptionStatus.DowngradePlan,
      ManageSubscriptionStatus.ConfirmOrder,
      ManageSubscriptionStatus.EnterPaymentMethod,
    ].includes(currentModalStatus) &&
    isSubscriptionDowngraded(
      currentSubscriptionPlanProductId,
      subscription,
      stripeProducts
    )
  ) {
    return ManageSubscriptionStatus.DowngradePlan;
  }

  if (
    currentModalStatus !== ManageSubscriptionStatus.ConfirmOrder &&
    (!isItNewSubscription ||
      (!subscription?.paymentMethodSetup &&
        currentModalStatus !== ManageSubscriptionStatus.EnterPaymentMethod)) &&
    subscription
  ) {
    return ManageSubscriptionStatus.ConfirmOrder;
  }

  if (
    currentModalStatus !== ManageSubscriptionStatus.EnterPaymentMethod &&
    subscription &&
    subscription?.paymentMethodSetup
  ) {
    return ManageSubscriptionStatus.EnterPaymentMethod;
  }

  return ManageSubscriptionStatus.Closed;
};

export default getNextStep;
