import { Styles } from 'assets/types';

const groupOverviewCardStyle: Styles = theme => ({
  container: {
    height: '100%',
  },
  cardContent: {
    paddingLeft: 24,
    paddingRight: 24,
  },
});

export default groupOverviewCardStyle;
