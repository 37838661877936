import { all } from 'redux-saga/effects';

// sagas
import fetchGroupFeatureUsageSaga from './fetchGroupFeatureUsageSaga';

const groupFeatureUsageSagas = function* rootSaga() {
  yield all([fetchGroupFeatureUsageSaga()]);
};

export default groupFeatureUsageSagas;
