import React from 'react';

// MUI components
import { Typography } from '@material-ui/core';

export default function DateIndicator({
  date,
  showDate,
}: {
  date?: string;
  showDate?: boolean;
}) {
  if (showDate) {
    return (
      <Typography variant={'body2'} component={'span'} color={'textSecondary'}>
        {date}
      </Typography>
    );
  } else {
    return null;
  }
}
