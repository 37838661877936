import React, { useEffect } from 'react';
import { useDeleteEventTimeSlotMutation } from 'api/eventTimeSlots';
import { Field } from 'react-final-form';

// material-ui
import {
  Grid,
  IconButton,
  CircularProgress,
  Tooltip,
  Box,
} from '@material-ui/core';
import { Delete } from '@material-ui/icons';

// components
// import IconButton from 'components/IconButton';
import TextField from 'components/TextField';
import DateSelector from 'components/DateSelector';

interface TimeSlotProps {
  eventTimeSlotId: number;
  fieldPrefix: string;
  isOnLastTimeSlot: boolean;
  updateDataOnPage?: () => void;
}

export default function TimeSlot({
  eventTimeSlotId,
  fieldPrefix,
  isOnLastTimeSlot,
  updateDataOnPage = () => {},
}: TimeSlotProps) {
  const {
    mutate: deleteEventTimeSlot,
    isLoading: deletingTimeSlot,
    isSuccess,
  } = useDeleteEventTimeSlotMutation();

  useEffect(() => {
    if (isSuccess) {
      updateDataOnPage();
    }
  }, [isSuccess]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Grid container alignItems='center' spacing={2}>
      <Grid item xs={5}>
        <Grid container spacing={2}>
          <Grid item xs>
            <Field
              name={`${fieldPrefix}.startTime`}
              parse={value => value && value.format()}
              component={DateSelector}
              pickerFormat='hh:mm A'
              keyboard
              withStartAdornment={false}
              label='Start Time'
              pickerType='time'
            />
          </Grid>
          <Grid item xs>
            <Tooltip title='Selecting end time before start time will mean that the time slot ends on the next day'>
              <Box>
                <Field
                  name={`${fieldPrefix}.endTime`}
                  component={DateSelector}
                  keyboard
                  pickerFormat='hh:mm A'
                  parse={value => value && value.format()}
                  withStartAdornment={false}
                  label='End Time'
                  pickerType='time'
                />
              </Box>
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={7}>
        <Grid container alignItems='center' spacing={2}>
          <Grid item xs>
            <Field
              component={TextField}
              margin='none'
              name={`${fieldPrefix}.description`}
              label='Description'
              fullWidth
            />
          </Grid>
          <Grid item xs={4}>
            <Field
              component={TextField}
              parse={Number}
              margin='none'
              name={`${fieldPrefix}.guestsLimit`}
              label='Guests Limit'
              type='number'
              fullWidth
            />
          </Grid>
          <Grid item>
            <Tooltip
              title={
                isOnLastTimeSlot
                  ? "You can't delete the last time slot"
                  : 'Delete the time slot'
              }
            >
              <Box>
                <IconButton
                  disabled={isOnLastTimeSlot}
                  aria-label='delete'
                  onClick={() => {
                    deleteEventTimeSlot(eventTimeSlotId);
                  }}
                >
                  {deletingTimeSlot ? (
                    <CircularProgress size={24} />
                  ) : (
                    <Delete />
                  )}
                </IconButton>
              </Box>
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
