import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { doneIndicator } from 'store/actions/httpActions';

// api
import client from 'sources/api';

// constants
import {
  FORM_FEE_CREATE,
  FORM_FEE_CREATE_REQUEST,
} from 'store/constants/formFeeTypes';

// schemas
import { feeSchema } from 'store/schemas/feeSchema';

// helpers
import httpSaga from 'store/sagas/httpSaga';

const WATCH_TYPE = FORM_FEE_CREATE_REQUEST;
const TYPE = FORM_FEE_CREATE;

export function* createFormFeeSaga(action: any) {
  const {
    payload: { formName, groupId, formId, title, amount },
  } = action;

  // @ts-ignore
  yield* httpSaga(
    TYPE,
    call(client.post, `/form_fees?group_id=${groupId}&form_id=${formId}`, {
      form_fee_name: title,
      fee_amount: amount,
    }),
    {
      schema: feeSchema,
      formName,
    }
  );

  yield put(doneIndicator(TYPE));
}

export function* watch() {
  yield takeEvery(WATCH_TYPE, createFormFeeSaga);
}

export default function* root() {
  yield all([fork(watch)]);
}
