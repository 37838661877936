import React from 'react';
// material-ui
import Skeleton from '@material-ui/lab/Skeleton';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/styles';
SkeletonRow.defaultProps = {
  SkeletonProps: { height: 26 },
};
type OwnProps = {
  SkeletonProps?: any; // TODO: PropTypes.instanceOf(Object)
  variant?: string;
};
type Props = OwnProps & typeof SkeletonRow.defaultProps;
export default function SkeletonRow({ variant, SkeletonProps }: Props) {
  const classes = useStyles();
  return (
    <Grid
      item
      xs={12}
      // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
      className={variant === 'outlined' ? classes.outlinedSkeleton : null}
    >
      <Skeleton
        variant='rect'
        className={
          variant === 'outlined' ? classes.skeletonRow : classes.skeletonCard
        }
        {...SkeletonProps}
      />
    </Grid>
  );
}
const useStyles = makeStyles(theme => {
  return {
    skeletonRow: {
      borderRadius: 4,
      margin: 8,
    },
    skeletonCard: {
      borderRadius: 4,
    },
    outlinedSkeleton: {
      borderBottom: `1px solid ${(theme as any).palette.background.lightPaper}`,
    },
  };
});
