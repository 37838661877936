import { all, call, fork, put, takeEvery, select } from 'redux-saga/effects';

// action creators
import { doneIndicator } from 'store/actions/httpActions';

// api
import client from 'sources/api';

// constants
import {
  REFERRAL_SOURCE_FETCH_MANY_REQUEST,
  REFERRAL_SOURCE_FETCH_MANY,
} from 'store/constants/referralSourceTypes';

// schemas
import { referralSourceSchema } from 'store/schemas/referralSourceSchema';

// helpers
import httpSaga from 'store/sagas/httpSaga';

// selectors
import getCurrentGroupId from 'store/selectors/getCurrentGroupId';

const WATCH_TYPE = REFERRAL_SOURCE_FETCH_MANY_REQUEST;
const TYPE = REFERRAL_SOURCE_FETCH_MANY;

export function* fetchReferralSourcesSaga() {
  const currentGroupId = yield select(getCurrentGroupId);

  // @ts-expect-error ts-migrate(2569) FIXME: Type 'Generator<any, any, unknown>' is not an arra... Remove this comment to see the full error message
  yield* httpSaga(
    TYPE,
    call(client.get, `/referral_sources?group_id=${currentGroupId}`),
    {
      schema: [referralSourceSchema],
    }
  );

  yield put(doneIndicator(TYPE));
}

export function* watch() {
  yield takeEvery(WATCH_TYPE, fetchReferralSourcesSaga);
}

export default function* root() {
  yield all([fork(watch)]);
}
