import Immutable from 'immutable';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

// action creators
import { doneIndicator, success } from 'store/actions/httpActions';

// api
import client from 'sources/api';

// constants
import { EMAIL_DELETE_REQUEST, EMAIL_DELETE } from 'store/constants/emailTypes';

// lib
import httpSaga from 'store/sagas/httpSaga';

const WATCH_TYPE = EMAIL_DELETE_REQUEST;
const TYPE = EMAIL_DELETE;

export function* deleteEmailSaga(action: any) {
  const {
    payload: { id },
  } = action;

  const FORM = `deleteEmailForm${id}`;

  try {
    // @ts-expect-error ts-migrate(2569) FIXME: Type 'Generator<any, any, unknown>' is not an arra... Remove this comment to see the full error message
    yield* httpSaga(TYPE, call(client.delete, `/emails/${id}`), {
      formName: FORM,
      dispatchSuccess: false,
    });

    yield put(
      success(
        TYPE,
        Immutable.fromJS({
          id,
        })
      )
    );
  } finally {
    yield put(doneIndicator(TYPE));
  }
}

export function* watch() {
  yield takeEvery(WATCH_TYPE, deleteEmailSaga);
}

export default function* root() {
  yield all([fork(watch)]);
}
