import { all, call, fork, put, takeEvery, select } from 'redux-saga/effects';

// action creators
import { fetchMembersForGroup } from 'store/actions/memberActions';
import { doneIndicator } from 'store/actions/httpActions';

// api
import client from 'sources/api';

// constants
import {
  COMMENT_FETCH_REQUEST,
  COMMENT_FETCH,
} from 'store/constants/commentTypes';

// schemas
import { commentSchema } from 'store/schemas/commentSchema';

// helpers
import httpSaga from 'store/sagas/httpSaga';

// selectors
import getCurrentGroupId from 'store/selectors/getCurrentGroupId';
import getCurrentUser from 'store/selectors/getCurrentUser';
import getSliceResult from 'store/selectors/getSliceResult';

const WATCH_TYPE = COMMENT_FETCH_REQUEST;
const TYPE = COMMENT_FETCH;

export function* fetchComments(action: any) {
  const {
    payload: { activityId, page, metaId },
  } = action;

  const currentGroupId = yield select(getCurrentGroupId);

  // @ts-expect-error ts-migrate(2569) FIXME: Type 'Generator<any, any, unknown>' is not an arra... Remove this comment to see the full error message
  const response = yield* httpSaga(
    TYPE,
    call(client.get, '/comments', {
      params: { group_id: currentGroupId, activity_id: activityId, page },
    }),
    {
      schema: [commentSchema],
      metaId,
    }
  );

  if (response) {
    const currentUser = yield select(getCurrentUser);
    const accountIds = response.data.results.map(
      (comment: any) => comment.accountId
    );
    const membersAccountIdList = yield select(getSliceResult('member'));
    const availableAccountIds = [
      currentUser.get('id'),
      ...membersAccountIdList.toJS(),
    ];
    const currentGroupId = yield select(getCurrentGroupId);
    const accountIdsToFetch = accountIds.filter(
      (id: any) => !availableAccountIds.includes(id)
    );

    if (accountIdsToFetch.length)
      yield put(
        fetchMembersForGroup({
          groupId: currentGroupId,
          accountIds: accountIdsToFetch,
        })
      );
  }

  yield put(doneIndicator(TYPE));
}

export function* watch() {
  yield takeEvery(WATCH_TYPE, fetchComments);
}

export default function* root() {
  yield all([fork(watch)]);
}
