import React from 'react';
import PropTypes from 'prop-types';
// material-ui
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';
const TableToolbar = function({ title }: any) {
  const classes = useStyles();
  return (
    <Toolbar className={classes.root}>
      {title && (
        <Typography className={classes.title} variant='h6' id='tableTitle'>
          {title}
        </Typography>
      )}
    </Toolbar>
  );
};
TableToolbar.propTypes = {
  title: PropTypes.string,
};
const useStyles = makeStyles(theme => {
  return {
    root: {
      paddingLeft: (theme as any).spacing(2),
      paddingRight: (theme as any).spacing(1),
    },
    title: {
      flex: '1 1 100%',
    },
  };
});
export default TableToolbar;
