import Immutable, { Map } from 'immutable';
import { denormalize } from 'normalizr';

import { threadSchema } from '../schemas/threadSchema';
import createDeepEqualSelector from 'helpers/createDeepEqualSelector';
import getSliceItems from './getSliceItems';

export default function getThreadById(id: any) {
  return createDeepEqualSelector(
    [getSliceItems('thread'), getSliceItems('file')],
    (threads: any, files: any) => {
      const entities = {
        thread: threads.toJS(),
        file: files.toJS(),
      };

      let items = denormalize([id], [threadSchema], entities);

      return Immutable.fromJS(items).first() || Map();
    }
  );
}
