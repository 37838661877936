import React, { useMemo, useEffect, useCallback } from 'react';
import { isEqual } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';

// MUI components
import { Grid, Card, CardContent, Hidden } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ContactPhone } from '@material-ui/icons';

// components
import Header from 'components/Header';
import Table from 'components/Table';
import ExportButton from 'components/ExportButton';
import ReportRefreshButton from 'components/ReportRefreshButton';

// action creators
import { createLeadsMainContactReport } from 'store/actions/apiTaskActions';

// selectors
import getCurrentGroupId from 'store/selectors/getCurrentGroupId';
import getForm from 'store/selectors/getForm';
import getSliceEntityById from 'store/selectors/getSliceEntityById';

const REPORT_FORM = 'leadsMainContactReportForm';
const rowsPerPage = 10;

export default function LeadsMainContactCard() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const initialOrder = 'asc';
  const initialOrderBy = 'leadFullName';
  const {
    currentGroupId,
    report: {
      data: { content = [], meta: { columns = [] } = {} } = {},
      id: reportId,
    },
    form: { isSubmitting },
  } = useSelector(
    state => ({
      currentGroupId: getCurrentGroupId(state),
      form: getForm(REPORT_FORM)(state),
      report: getSliceEntityById(
        'report',
        'ReportTypes.LEADS_MAIN_CONTACT',
        {}
      )(state),
    }),
    isEqual
  );

  const dispatchCreateReport = useCallback(
    ({ forceRefresh }: { forceRefresh: boolean }) => {
      dispatch(
        createLeadsMainContactReport({ groupId: currentGroupId, forceRefresh })
      );
    },
    [dispatch, currentGroupId]
  );

  useEffect(() => {
    dispatchCreateReport({ forceRefresh: false });
  }, [dispatchCreateReport]);

  const rows = useMemo(() => {
    if (!content) return [];
    if (isSubmitting || !content.length) return [];
    return content.map(({ leadId, ...rest }: any) => ({
      key: leadId,
      ...rest,
    }));
  }, [isSubmitting, content]);
  return (
    <Card>
      <CardContent className={classes.cardContent}>
        <Grid container>
          <Grid item xs>
            <Header title='Main Contacts' icon={ContactPhone} compact />
          </Grid>

          <Hidden xsDown>
            <Grid item xs={4} md={2}>
              <Grid container justifyContent='flex-end'>
                <Grid item>
                  <ReportRefreshButton
                    loading={isSubmitting}
                    onRefresh={dispatchCreateReport}
                  />
                </Grid>
                <ExportButton params={{ reportId }} disabled={isSubmitting} />
              </Grid>
            </Grid>
          </Hidden>

          <Grid item xs={12}>
            <Table
              columns={columns}
              data={rows}
              variant='outlined'
              numberOfSkeletonRows={isSubmitting ? 8 : 0}
              initialOrder={initialOrder}
              initialOrderBy={initialOrderBy}
              TableRowProps={{
                classes: {
                  root: (classes as any).root,
                },
              }}
              TableCellProps={{
                className: (classes as any).tableCell,
              }}
              HeaderTableCellProps={{
                className: (classes as any).headerTableCell,
              }}
              TablePaginationProps={{
                count: content.length,
                rowsPerPage,
                rowsPerPageOptions: [rowsPerPage],
              }}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
const useStyles = makeStyles(theme => ({
  cardContent: {
    paddingLeft: 24,
    paddingRight: 24,
  },
  exportButton: {
    textAlign: 'right',
  },
}));
