import { Map } from 'immutable';
import { all, call, fork, put, takeEvery, select } from 'redux-saga/effects';

// action creators
import { doneIndicator, success } from 'store/actions/httpActions';

// api
import client from 'sources/api';

// selectors
import getCurrentGroupId from 'store/selectors/getCurrentGroupId';

// constants
import { TAG_DELETE_REQUEST, TAG_DELETE } from 'store/constants/tagTypes';

// schemas
import { tagSchema } from 'store/schemas/tagSchema';

// helpers
import httpSaga from 'store/sagas/httpSaga';

const WATCH_TYPE = TAG_DELETE_REQUEST;
const TYPE = TAG_DELETE;

export function* deleteTag(action: any) {
  const {
    payload: { formName, id },
  } = action;

  const currentGroupId = yield select(getCurrentGroupId);

  // @ts-expect-error ts-migrate(2569) FIXME: Type 'Generator<any, any, unknown>' is not an arra... Remove this comment to see the full error message
  yield* httpSaga(
    TYPE,
    call(client.delete, `/tags/${id}?group_id=${currentGroupId}`),
    {
      formName,
      shouldDestroyForm: false,
      dispatchSuccess: false,
      schema: tagSchema,
    }
  );

  yield put(success(TYPE, Map({ id })));
  yield put(doneIndicator(TYPE));
}

export function* watch() {
  yield takeEvery(WATCH_TYPE, deleteTag);
}

export default function* root() {
  yield all([fork(watch)]);
}
