import React, { useState, useEffect } from 'react';

// material-ui
import { withStyles, WithStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

// hooks
import { useSelector, useDispatch } from 'react-redux';
import { useReduxForm } from 'store/hooks/useReduxForm';
import { useHistory } from 'react-router-dom';

// components
import TextField from 'components/TextField';
import FileInputField from 'components/FileInputField';
import SuperAvatar from 'components/SuperAvatar';
import { Field } from 'react-final-form';

// selectors
import getLoadingFromState from 'store/selectors/getLoadingFromState';

// action creators
import { createAndUploadFile } from 'store/actions/fileActions';

// styles
import basicFieldsStyles from 'routes/ReferralForm/BasicFields/basicFields.style';

export interface BasicFieldsProps extends WithStyles<typeof basicFieldsStyles> {
  enableImageUpload?: boolean;
  emailRequired?: boolean;
  phoneNumberRequired?: boolean;
}

const IMAGE_FORM = 'createAndUploadFileForm';

const BasicFields = ({
  classes,
  enableImageUpload = false,
  emailRequired = false,
  phoneNumberRequired = false,
}: BasicFieldsProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { location: { pathname, search } = {} } = history;
  const urlParams = new URLSearchParams(search);
  const token = urlParams.get('token');

  // initalize component state
  const [imageId, setImageId] = useState<number | null>(null);
  const [isFileConverting, setIsFileConverting] = useState(false);
  // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
  const editing = pathname.includes('edit');
  const formName = editing ? 'editReferralForm' : 'viewReferralForm';
  const { hasSubmitSucceeded } = useReduxForm(formName);

  useEffect(() => {
    if (!editing && hasSubmitSucceeded) {
      setImageId(null);
    }
  }, [hasSubmitSucceeded]); // eslint-disable-line

  const { fileLoading } = useSelector(state => ({
    fileLoading: getLoadingFromState('file')(state),
  }));

  const dispatchCreateAndUploadFile = (file: any) => {
    dispatch(createAndUploadFile({ file, formName: IMAGE_FORM, token }));
  };

  const handleFileChange = ({ filePreview, newFileId }: any) => {
    setImageId(newFileId);
  };

  const handleFileConverting = (newIsFileConverting: boolean) => {
    setIsFileConverting(newIsFileConverting);
  };

  const handleClickImageUploadGrid = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  return (
    <Grid item xs={12} className={classes.basicFieldsContent}>
      <Grid container>
        {editing && (
          <Grid item xs={12} id='basicFieldsHeader'>
            <Typography>Basic Fields</Typography>
            <Typography color='textSecondary' variant='subtitle2'>
              These fields serve as a preview and are view-only in edit mode
            </Typography>
          </Grid>
        )}

        <Grid item xs={12} sm={6} className={classes.leftField}>
          <Field
            name='firstName'
            disabled={editing}
            component={TextField}
            label='First Name'
            required
          />
        </Grid>

        <Grid item xs={12} sm={6} className={classes.rightField}>
          <Field
            name='lastName'
            disabled={editing}
            component={TextField}
            label='Last Name'
            required
          />
        </Grid>

        <Grid item xs={12} sm={6} className={classes.leftField}>
          <Field
            name='emailAddress'
            disabled={editing}
            component={TextField}
            label='Email'
            required={emailRequired}
          />
        </Grid>

        <Grid item xs={12} sm={6} className={classes.rightField}>
          <Field
            name='phoneNumber'
            render={props => (
              <TextField
                disabled={editing}
                label='Phone Number'
                type='tel'
                required={phoneNumberRequired}
                {...props}
              />
            )}
          />
        </Grid>

        {enableImageUpload && (
          <Grid item xs={12} id='imageUploader'>
            <Grid
              container
              alignItems='center'
              spacing={4}
              {...(editing
                ? {
                    onClick: handleClickImageUploadGrid,
                  }
                : {})}
            >
              <Grid
                item
                xs={6}
                sm={4}
                md={3}
                lg={3}
                xl={2}
                className={classes.avatar}
              >
                <SuperAvatar
                  size='2xlarge'
                  background='dark'
                  loading={
                    (fileLoading && Boolean(imageId)) || isFileConverting
                  }
                  imageId={editing ? null : imageId}
                  token={token}
                />
              </Grid>
              <Grid item xs={6} sm={8} md={9} lg={9} xl={10}>
                <Field
                  name='profilePhotoFileId'
                  render={props => (
                    <FileInputField
                      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ input: FieldInputProps<any, HTMLElement>; ... Remove this comment to see the full error message
                      disabled={editing}
                      fileType='image'
                      onChange={handleFileChange}
                      onSubmit={dispatchCreateAndUploadFile}
                      formName={IMAGE_FORM}
                      onFileConverting={handleFileConverting}
                      buttonLabel='Upload Picture'
                      {...props}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default withStyles(basicFieldsStyles, { withTheme: true })(BasicFields);
