import { all } from 'redux-saga/effects';

// sagas
import createForgotPasswordSaga from './createForgotPasswordSaga';
import updateForgotPasswordSaga from './updateForgotPasswordSaga';

const sessionSaga = function* rootSaga() {
  yield all([createForgotPasswordSaga(), updateForgotPasswordSaga()]);
};

export default sessionSaga;
