export default function getGroupType(groupName: any) {
  switch (groupName) {
    default:
    case 'STANDARD':
      return 1;
    case 'GREEK_CHAPTER':
      return 2;
    case 'GREEK_ORGANIZATION':
      return 3;
    case 'COUNCIL':
      return 4;
  }
}
