import Immutable from 'immutable';
import { denormalize } from 'normalizr';

import { fileSchema } from '../schemas/fileSchema';
import createDeepEqualSelector from 'helpers/createDeepEqualSelector';
import getSliceItems from './getSliceItems';
import getSliceResult from './getSliceResult';

export default createDeepEqualSelector(
  [getSliceResult('file'), getSliceItems('file')],
  (result: any, fileSlice: any) => {
    const entities = {
      file: fileSlice.toJS(),
    };

    const items = denormalize(result.toJS(), [fileSchema], entities);

    return Immutable.fromJS(items);
  }
);
