import { Map } from 'immutable';
// constants
import {
  ACTIVITY_FETCH,
  ACTIVITY_FETCH_BY_ID,
  ACTIVITY_CLEAR,
} from 'store/constants/activityTypes';

// helpers
import httpReducer from './helpers/httpReducer';

export default function reduceActivities(state = Map(), action = {}) {
  switch ((action as any).type) {
    case ACTIVITY_FETCH:
      return httpReducer(state, action, { appendResult: true });
    case ACTIVITY_FETCH_BY_ID:
      return httpReducer(state, action, { appendResult: true });
    case ACTIVITY_CLEAR:
      return Map();
    default:
      return state;
  }
}
