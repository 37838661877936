import { all } from 'redux-saga/effects';

// sagas
import fetchLeadReferralSources from './fetchLeadReferralSourcesSaga';
import createLeadReferralSource from './createLeadReferralSourceSaga';
import deleteLeadReferralSource from './deleteLeadReferralSourceSaga';

const leadReferralSourceSaga = function* rootSaga() {
  yield all([
    fetchLeadReferralSources(),
    createLeadReferralSource(),
    deleteLeadReferralSource(),
  ]);
};

export default leadReferralSourceSaga;
