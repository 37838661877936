import { Styles } from 'assets/types';

const fileItemStyles: Styles = () => ({
  fileBox: {
    maxWidth: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  fileName: {
    maxWidth: '95%',
    overflow: 'hidden',
  },
  deleteFileButton: {
    marginLeft: 10,
    '& .MuiSvgIcon-root': {
      fontSize: '1.3rem',
    },
  },
});

export default fileItemStyles;
