import React from 'react';
import PropTypes from 'prop-types';
import validate from 'helpers/validate';
import { Map } from 'immutable';

// material-ui

// material-ui
import {
  Card,
  CardActions,
  CardContent,
  Divider,
  FormHelperText,
  Grid,
} from '@material-ui/core';

// icons
import LockIcon from '@material-ui/icons/Lock';

// components
import Header from 'components/Header';
import { Form, Field } from 'react-final-form';
import TextField from 'components/TextField';
import Button from 'components/Button';

// schemas
const schema = {
  currentPassword: {
    presence: true,
    length: {
      maximum: 255,
    },
  },

  newPassword: {
    presence: true,
    containsUppercase: true,
    containsLowercase: true,
    containsNumber: true,
    length: {
      minimum: 8,
      maximum: 255,
    },
  },

  confirmNewPassword: {
    presence: true,
    equality: 'newPassword',
    length: { maximum: 255 },
  },
};

const UpdatePasswordCard = function({
  updatePasswordFormSubmitting,
  updatePasswordFormSubmitSucceeded,
  updatePasswordFormSubmitFailed,
  updatePasswordFormError,
  updateCurrentUserPassword,
}: any) {
  const onSubmit = (values: any) => {
    updateCurrentUserPassword(values);
  };

  return (
    <Grid item sm={12} md={6}>
      <Form
        onSubmit={onSubmit}
        validate={values => validate(values, schema)}
        render={({ handleSubmit, invalid, pristine, form: { reset } }) => (
          <Card component='form' onSubmit={handleSubmit}>
            <CardContent>
              <Header
                id='header'
                icon={LockIcon}
                title='Change Password'
                subtitle='Update your password'
              />

              <Grid container>
                <Grid item xs={12}>
                  <Grid container>
                    <Grid item xs={12}>
                      <Field
                        name='currentPassword'
                        render={props => <TextField {...props} />}
                        label='Current Password'
                        type='password'
                        required
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <Field
                        name='newPassword'
                        render={props => <TextField {...props} />}
                        label='New Password'
                        type='password'
                        required
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <Field
                        name='confirmNewPassword'
                        render={props => <TextField {...props} />}
                        label='Confirm New Password'
                        type='password'
                        required
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>

            <Divider />

            <CardActions>
              <Button disabled={pristine} onClick={reset}>
                Cancel
              </Button>
              <Button
                type='submit'
                color='primary'
                variant='contained'
                disabled={pristine || invalid || updatePasswordFormSubmitting}
                loading={updatePasswordFormSubmitting}
                success={updatePasswordFormSubmitSucceeded}
                fail={updatePasswordFormSubmitFailed}
              >
                Save
              </Button>

              {updatePasswordFormError && (
                <Grid item xs={12}>
                  <FormHelperText error id='errorMessage'>
                    {updatePasswordFormError.get('message')}
                  </FormHelperText>
                </Grid>
              )}
            </CardActions>
          </Card>
        )}
      />
    </Grid>
  );
};

UpdatePasswordCard.propTypes = {
  updateCurrentUserPassword: PropTypes.func.isRequired,
  updatePasswordFormSubmitting: PropTypes.bool.isRequired,
  updatePasswordFormSubmitFailed: PropTypes.bool.isRequired,
  updatePasswordFormSubmitSucceeded: PropTypes.bool.isRequired,
  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'typeof Map' is not assignable to... Remove this comment to see the full error message
  updatePasswordFormError: PropTypes.instanceOf(Map),
};

export default UpdatePasswordCard;
