export const LEAD_ATTRIBUTE_DELETE_REQUEST = 'LEAD_ATTRIBUTE_DELETE_REQUEST';
export const LEAD_ATTRIBUTE_DELETE = 'LEAD_ATTRIBUTE_DELETE';

export const LEAD_ATTRIBUTE_CREATE_REQUEST = 'LEAD_ATTRIBUTE_CREATE_REQUEST';
export const LEAD_ATTRIBUTE_CREATE = 'LEAD_ATTRIBUTE_CREATE';

export const LEAD_ATTRIBUTE_UPDATE_OR_CREATE_REQUEST =
  'LEAD_ATTRIBUTE_UPDATE_OR_CREATE_REQUEST';
export const LEAD_ATTRIBUTE_UPDATE_OR_CREATE =
  'LEAD_ATTRIBUTE_UPDATE_OR_CREATE';
