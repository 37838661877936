import React, { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

// actions
import { login } from 'store/actions/oidcActions';

// helpers
import { getIntegrationId, getStateToken } from 'helpers/oidcUtils';

// material-ui
import { Button, Grid, makeStyles, Typography } from '@material-ui/core';

const OpenIDConnect = function() {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { search } = useLocation();
  const params = useMemo(() => new URLSearchParams(search), [search]);

  let error: string | undefined | null;
  if (params.has('error')) {
    error = params.get('error');
  }

  if (params.has('state')) {
    const localState = getStateToken();
    if (localState) {
      if (localState !== params.get('state')) {
        error =
          "The verification token from the Auth Server didn't match the local one";
      }
    } else {
      error = 'The local verification token is missing';
    }
  }

  const code = params.get('code');

  useEffect(() => {
    if (!code) {
      return;
    }

    const integrationId = getIntegrationId();
    if (!integrationId) {
      return;
    }

    const redirectUrl = new URL('/oidc/redirect', window.location.origin);
    dispatch(
      login({
        code,
        id: Number(integrationId),
        redirectUri: redirectUrl.href,
      })
    );
  }, [dispatch, params, code]);

  if (error) {
    return (
      <Grid
        container
        alignItems='center'
        justifyContent='center'
        className={classes.container}
      >
        <Grid item>
          <Typography variant='h4'>
            We've encountered an error &#128563;
          </Typography>
          <Typography variant='body1'>
            <q>{error}</q>
          </Typography>
          <Typography variant='h6' className={classes.loginText}>
            Please try to login again
          </Typography>

          <Button
            color='primary'
            variant='outlined'
            className={classes.loginButton}
            onClick={() => {
              history.push('/login');
            }}
          >
            Back to Login
          </Button>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid
      container
      xs={12}
      direction='column'
      alignItems='center'
      justifyContent='center'
    >
      <Grid item direction='column'>
        <Typography variant='h4'>Redirecting...</Typography>
        <Typography variant='body1'>
          Please be patient while we redirect you...
        </Typography>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles(theme => ({
  container: {
    padding: '0 1.2em',
  },
  loginText: {
    marginTop: '1.8em',
  },
  loginButton: {
    marginTop: '0.9em',
  },
}));

export default OpenIDConnect;
