import React, { useCallback } from 'react';
import { Moment } from 'moment';
import { isEqual } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';

// MUI components
import {
  Grid,
  Card,
  CardContent,
  WithStyles,
  withStyles,
} from '@material-ui/core';

// components
import Table from 'components/Table';
import AnalyticsActionBar from 'components/AnalyticsActionBar';
import TagsAnalyticCell from 'routes/NewMemberAnalytics/components/TagsAnalyticCell';

// actions
import { createTaskCouncilNewMembersListReport } from 'store/actions/apiTaskActions';

// selectors
import getCurrentGroupId from 'store/selectors/getCurrentGroupId';
import getForm from 'store/selectors/getForm';
import getSliceEntityById from 'store/selectors/getSliceEntityById';
import getIsCouncilAdmin from 'store/selectors/getIsCouncilAdmin';

// helpers
import { arrayToStringWithoutEmpty } from 'helpers/transform';
import {
  ContentType,
  ColumnType,
} from 'routes/NewMemberAnalytics/NewMemberList/NewMemberList';

// icons
import BookIcon from '@material-ui/icons/Book';

// styles
import councilNewMemberListStyles from 'routes/NewMemberAnalytics/CouncilNewMemberList/councilNewMemberList.style';

const REPORT_FORM = 'councilNewMemberListReportForm';
const ROWS_PER_PAGE = 10;

export interface CouncilNewMemberListProps
  extends WithStyles<typeof councilNewMemberListStyles> {
  isFetchingGroupsTurnedOff?: boolean;
}

const DISABLE_SORT = ['tags'];

const CouncilNewMemberList = ({
  classes,
  isFetchingGroupsTurnedOff,
}: CouncilNewMemberListProps) => {
  const dispatch = useDispatch();

  const {
    currentGroupId,
    isCouncilAdmin,
    report: {
      data: { content = [], meta: { columns = [] } = {} } = {},
      id: reportId,
    },
    form: { isSubmitting },
  } = useSelector(
    state => ({
      currentGroupId: getCurrentGroupId(state),
      isCouncilAdmin: getIsCouncilAdmin(state),
      form: getForm(REPORT_FORM)(state),
      report: getSliceEntityById(
        'report',
        'ReportTypes.NEW_MEMBERS_COUNCIL_LIST',
        {}
      )(state),
    }),
    isEqual
  );

  const newContent = content.map((item: ContentType) => {
    const { tags, referralSource } = item || {};
    return {
      ...(item || {}),
      tags: tags ? <TagsAnalyticCell currentTags={tags} /> : '',
      referralSource: referralSource
        ? arrayToStringWithoutEmpty(referralSource)
        : '',
    };
  });

  const newColumns = columns.map(({ key, label }: ColumnType) => ({
    key,
    label,
    disableSort: DISABLE_SORT.indexOf(key) !== -1,
  }));

  const dispatchCreateReport = useCallback(
    ({
      startDate,
      endDate,
      forceRefresh,
      groupId,
    }: {
      startDate: Moment;
      endDate: Moment;
      forceRefresh: boolean;
      groupId?: number;
    }) => {
      dispatch(
        createTaskCouncilNewMembersListReport({
          groupId: currentGroupId,
          formName: REPORT_FORM,
          startDate,
          endDate,
          ...(groupId && currentGroupId !== groupId
            ? { childId: groupId }
            : {}),
          forceRefresh,
        })
      );
    },
    [dispatch, currentGroupId]
  );

  return (
    <Card>
      <CardContent className={classes.cardContent}>
        <Grid container spacing={2}>
          <AnalyticsActionBar
            onCreateReport={dispatchCreateReport}
            isCouncilAdmin={isCouncilAdmin}
            currentGroupId={currentGroupId}
            reportId={reportId}
            newMembersReport
            title='Council New Member List'
            subtitle='Pull a list of all new members. This comes from the Council Lead List.'
            icon={BookIcon}
            loading={isSubmitting}
            isFetchingGroupsTurnedOff={isFetchingGroupsTurnedOff}
          />

          <Grid item xs={12} className={classes.tableGrid}>
            <Table
              columns={newColumns}
              data={newContent}
              variant='outlined'
              numberOfSkeletonRows={isSubmitting ? 8 : 0}
              initialOrder='asc'
              initialOrderBy='lead'
              TablePaginationProps={{
                count: newContent.length,
                rowsPerPage: ROWS_PER_PAGE,
                rowsPerPageOptions: [ROWS_PER_PAGE],
              }}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default withStyles(councilNewMemberListStyles, { withTheme: true })(
  CouncilNewMemberList
);
