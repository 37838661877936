import {
  all,
  call,
  fork,
  put,
  takeEvery,
  select,
  take,
} from 'redux-saga/effects';
import { List, Map } from 'immutable';

// action creators
import {
  doneIndicator,
  success,
  error,
  errorIndicator,
} from 'store/actions/httpActions';
import { fetchTask } from 'store/actions/apiTaskActions';
import {
  setFormSubmitFailed,
  setFormSubmitSucceeded,
} from 'store/actions/formActions';

// api
import client from 'sources/api';

// schemas
import { apiTaskSchema } from 'store/schemas/apiTaskSchema';

// constants
import {
  API_TASK_REPORT_NEW_MEMBERS,
  API_TASK_REPORT_NEW_MEMBERS_REQUEST,
} from 'store/constants/apiTaskTypes';

// sagas
import httpSaga from 'store/sagas/httpSaga';

// selectors
import getCurrentGroupId from 'store/selectors/getCurrentGroupId';
import getTaskStatusById from 'store/selectors/getTaskStatusById';

// helpers
import delayTwoSeconds from 'helpers/delayTwoSeconds';

const WATCH_TYPE = API_TASK_REPORT_NEW_MEMBERS_REQUEST;
const TYPE = API_TASK_REPORT_NEW_MEMBERS;

export function* reportNewMembersSaga(action: {
  type: string;
  payload: {
    leadIds: number[];
    joinDate: string;
    formName: string;
    isCob?: boolean;
  };
}) {
  const {
    payload: {
      leadIds: lead_ids,
      joinDate: join_date,
      formName,
      isCob: is_cob,
    },
  } = action;

  const currentGroupId = yield select(state => getCurrentGroupId(state));

  // @ts-expect-error ts-migrate(2569) FIXME: Type 'Generator<any, any, unknown>' is not an arra... Remove this comment to see the full error message
  const taskPostResponse = yield* httpSaga(
    TYPE,
    call(client.post, `/tasks?group_id=${currentGroupId}`, {
      name: 'report_new_members',
      params: {
        pnm_ids: lead_ids,
        join_date,
        ...(typeof is_cob === 'boolean' && { is_cob }),
      },
    }),
    {
      schema: apiTaskSchema,
      formName,
      dispatchSuccesss: false,
      dispatchFormSuccess: false,
    }
  );

  if (taskPostResponse) {
    // @ts-expect-error ts-migrate(2525) FIXME: Initializer provides no value for this binding ele... Remove this comment to see the full error message
    const { data: { taskId } = {} } = taskPostResponse || {};

    // Use the worker/task ID to hit an endpoint periodically to check on the status
    let taskStatus = 'PROCESSING';

    while (taskStatus === 'PROCESSING') {
      yield delayTwoSeconds();

      yield put(fetchTask({ taskId, groupId: currentGroupId }));
      yield take('API_TASK_FETCH_DONE');

      const newTaskStatus = yield select(state =>
        getTaskStatusById(taskId)(state)
      );

      if (newTaskStatus) {
        taskStatus = newTaskStatus;
      }
    }

    if (taskStatus === 'FAILURE') {
      // Notify the store that this type had an error
      const taskData = yield select(state =>
        state.getIn(['apiTask', 'data', 'byId', taskId.toString()], Map())
      );

      const generalErrorMessage = taskData.getIn(['result', 'excMessage'], '');

      const errorsList = taskData.getIn(['result', 'errors'], List());

      const message = {
        data: { msg: { general: generalErrorMessage, list: errorsList } },
      };
      // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 2.
      yield put(errorIndicator(TYPE, message));

      // Dispatch an error payload for this type to be caught by the reducer
      yield put(setFormSubmitFailed(formName, message));
      yield put(error(TYPE, message));
    } else {
      const successMessage = yield select(state =>
        state.getIn(
          ['apiTask', 'data', 'byId', taskId.toString(), 'result'],
          ''
        )
      );
      yield put(setFormSubmitSucceeded(formName, successMessage));
      yield put(success(TYPE, Map()));
    }
  }

  yield put(doneIndicator(TYPE));
}

export function* watch() {
  yield takeEvery(WATCH_TYPE, reportNewMembersSaga);
}

export default function* root() {
  yield all([fork(watch)]);
}
