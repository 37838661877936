import { isEmpty, isString, isNumber, isArray } from 'helpers/check';

export const toFloat = (value: any) =>
  isEmpty(value)
    ? 0
    : isString(value)
    ? parseFloat(value ? value.replace(/,/, '.').replace(/[^\d-.]/g, '') : '0')
    : isNumber(value)
    ? parseFloat(value)
    : 0;

export const toInt10 = (value: any) =>
  isEmpty(value) ? 0 : isNumber(parseInt(value, 10)) ? parseInt(value, 10) : 0;

export const toNumber = (value: any): number =>
  isEmpty(value) ? 0 : isNumber(Number(value)) ? Number(value) : 0;

export const toBool = (value: any) => (isEmpty(value) ? false : !!value);

export const toString = (value: any): string =>
  isEmpty(value) ? '' : value.toString();

export const toArray = (value: any): any[] =>
  isEmpty(value) ? [] : isArray(value) ? value : [value];

export const toObject = (value: any) =>
  isEmpty(value) ? {} : isString(value) ? JSON.parse(value) : value;

export const toLowerCase = (value: any): string =>
  isEmpty(value) ? '' : value.toLowerCase();

export const toUpperCase = (value: any): string =>
  isEmpty(value) ? '' : toString(value).toUpperCase();

export const arrayToStringWithoutEmpty = (
  list: any,
  separator: string = ', '
) =>
  toArray(list)
    .filter((item: any) => !isEmpty(item))
    .join(separator);

export const firstLetterToUppercase = (value?: string): string =>
  value ? `${value[0].toUpperCase()}${value.slice(1)}` : '';

export const numberToCurrency = (value: number) => toFloat(value).toFixed(2);

export const toStringWithoutNullUndefined = (value: any): string =>
  toString(value).replace(/^(null)|(undefined)$/, '');
