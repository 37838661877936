import { Map } from 'immutable';
// constants
import {
  TASK_FETCH,
  TASK_CREATE,
  TASK_COMPLETE,
  TASK_DELETE,
  TASK_POPULATE,
  TASK_CLEAR,
  TASK_UNCOMPLETE,
  TASK_CLEAR_BY_ID,
} from 'store/constants/taskTypes';
import remove from './callbacks/remove';
// helpers
import httpReducer from './helpers/httpReducer';

export default function reduceTask(state = Map(), action = {}) {
  switch ((action as any).type) {
    case TASK_FETCH:
      return httpReducer(state, action, {
        appendResult: true,
      });
    case TASK_CREATE:
      return httpReducer(state, action, { appendResult: true });
    case TASK_COMPLETE:
    case TASK_UNCOMPLETE:
      return httpReducer(state, action, { updateResult: false });
    case TASK_DELETE:
      return httpReducer(state, action, { callback: remove });
    case TASK_POPULATE:
      return httpReducer(state, action, {
        updateItemsById: false,
        updateResult: false,
        updateLoading: true,
      });
    case TASK_CLEAR:
      return Map();
    case TASK_CLEAR_BY_ID:
      return httpReducer(state, action, { callback: remove });
    default:
      return state;
  }
}
