import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { isEqual } from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import classNames from 'classnames';
import queryString from 'query-string';

// hooks
import useInfiniteScroll from 'helpers/hooks/useInfiniteScroll';
import { useIsFeatureEnabled } from 'helpers/hooks/featureHooks';

// helpers
import formatStartDate from 'helpers/formatStartDate';
import formatEndDate from 'helpers/formatEndDate';

// redux
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

// router
import { useHistory } from 'react-router';

// action creators
import {
  fetchActivity,
  fetchActivities,
  clearActivities,
} from 'store/actions/activityActions';

// selectors
import getSliceState from 'store/selectors/getSliceState';
import getReportingPlatformName from 'store/selectors/getReportingPlatformName';
import { hasSubmitSucceeded } from 'store/selectors/formSelectors';

// MUI components
import { Box, Grid, Typography, IconButton } from '@material-ui/core';

// MUI icons
import {
  Timeline,
  FilterList,
  Clear,
  ArrowDownward,
  Ballot,
} from '@material-ui/icons';

// components
import FeedItem from './FeedItem';
import ActivityFilterDialog from './ActivityFilterDialog';
import SimplePagination from 'components/SimplePagination';
import EmptyMessage from 'components/EmptyMessage';
import Button from 'components/Button';

const INITIAL_FILTERS = {
  member: null,
  createdOnDate: null,
  actions: [
    'email_message.created',
    'event_guests.created',
    'form.submitted',
    'note.created',
    'pnm_status.updated',
    'pnm.created',
    'pnm.deleted',
    'pnm.updated',
    'pnms.deleted',
    'pnms.distributed.fail',
    'pnms.distributed.success',
    'pnms.imported',
    'pnms.reported',
    'pnms.duplicates_merged',
    'pnms.duplicates_skipped',
    'text_message.received',
    'text_messages.created',
  ],
};

export default function ActivityFeed({
  displayHeader = true,
  displayTimeline = true,
  emptyContent = renderEmptyMessage(),
  filterOptions = {},
  initialFilters = INITIAL_FILTERS,
  paginateOnScroll = true,
  reload,
  source,
  title = 'Activity Feed',
  withoutFiltering,
  isPnmLabelLinkDisabled,
}: {
  displayHeader?: boolean;
  displayTimeline?: boolean;
  emptyContent?: any; // TODO: PropTypes.instanceOf(Object)
  filterOptions?: any; // TODO: PropTypes.instanceOf(Object)
  initialFilters?: any; // TODO: PropTypes.instanceOf(Object)
  paginateOnScroll?: boolean;
  reload?: boolean;
  source?: {
    name: 'group' | 'pnm';
    id: number;
  };
  title?: string;
  withoutFiltering?: boolean;
  isPnmLabelLinkDisabled?: boolean;
}) {
  // react router init
  const history = useHistory();
  const searchObj = useMemo(() => queryString.parse(history.location.search), [
    history.location.search,
  ]);

  // redux state and action init
  const {
    loading,
    nextActivityUrl,
    reportingPlatformName,
    page,
    data,
    clearActivities,
    fetchActivities,
    fetchActivity,
    currentUserId,
  } = useReduxForm();

  // permissions
  const eventsEnabled = useIsFeatureEnabled('events') || true;

  // component state init
  const [scrolledToBottom, scrollRef] = useInfiniteScroll(loading, 2);
  const [filterDialogOpened, setActivityFilterDialogOpened] = useState(false);
  const [filters, setFilters] = useState(initialFilters);
  const filtered = !isEqual(filters, initialFilters);

  const classes = useStyles();
  const nextPage = new URLSearchParams(nextActivityUrl).get('page');

  const toggleActivityFilterDialog = useCallback(
    () => setActivityFilterDialogOpened(isOpened => !isOpened),
    [setActivityFilterDialogOpened]
  );

  const handleFilter = useCallback(
    values => {
      setFilters(values);
    },
    [setFilters]
  );

  const handlePageChange = (newPage: any) => {
    // Handles manual page changes through pagination controls
    if (!paginateOnScroll) {
      clearActivities();
    }

    fetchActivities({ source, ...mapFetchArgs(filters), page: newPage });
  };

  const resetFilters = useCallback(() => {
    setFilters(initialFilters);
  }, [initialFilters]);

  useEffect(() => {
    // Handles setting new filters from the result of a change in the parent component
    if (reload) {
      setFilters({ ...filters, ...initialFilters });
    }
  }, [initialFilters, reload]); // eslint-disable-line

  // Checks each form to see if any have succeeded
  //  We're using a separate useSelector because
  //  the value was never changing while in the useReduxForm hook
  const { reloadOnSubmitSucceeded } = useSelector(
    state => ({
      reloadOnSubmitSucceeded: [
        'updateLeadForm',
        'createEmailForm',
        'createNoteForm',
        'createLeadForm',
      ].some(formName => hasSubmitSucceeded(formName)(state)),
    }),
    shallowEqual
  );

  useEffect(() => {
    if (reloadOnSubmitSucceeded) {
      // Handles fetching list when new feed item is created for certain forms
      clearActivities();

      fetchActivities({
        source,
        ...mapFetchArgs(filters),
      });
    }
  }, [reloadOnSubmitSucceeded]); // eslint-disable-line

  useEffect(() => {
    if (scrolledToBottom && page && paginateOnScroll) {
      fetchActivities({ source, ...mapFetchArgs(filters), page });
    }
  }, [
    clearActivities,
    scrolledToBottom,
    fetchActivities,
    filters,
    page,
    source,
    paginateOnScroll,
    searchObj,
  ]);

  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '() => () => { type: string; }' i... Remove this comment to see the full error message
  useEffect(() => {
    if (searchObj.activityId) {
      // fetch initial, single activity item
      fetchActivity({ id: searchObj.activityId });
    } else {
      // fetch initial activities
      fetchActivities({ source, ...mapFetchArgs(filters) });
    }
    return () => clearActivities();
  }, [
    fetchActivities,
    clearActivities,
    filters,
    searchObj.activityId,
    fetchActivity,
    source,
  ]);

  const clearActivityIdSearch = useCallback(() => {
    if (searchObj.activityId) {
      delete searchObj.activityId;
      const modifiedSearch = queryString.stringify(searchObj);
      history.push({ search: modifiedSearch });
    }
  }, [history, searchObj]);

  return (
    <Box
      className={classNames(
        classes.root,
        history.location.pathname.includes('home') && classes.paddedFeed
      )}
      id='activity'
    >
      <Grid
        container
        spacing={2}
        alignItems={'center'}
        className={classes.header}
      >
        {displayHeader && [
          <Grid item key='timelineIcon' id='timelineIcon'>
            <Box
              className={classNames(
                classes.timeLineIcon,
                displayTimeline && classes.marginTimeLine
              )}
            >
              <Timeline />
            </Box>
          </Grid>,
          <Grid item xs key='title' id='title'>
            <Typography variant={'h6'}>{title}</Typography>
          </Grid>,
        ]}

        {!displayHeader && <Grid id='headerFill' item xs />}

        {filtered && (
          <Grid item>
            <Button size='small' endIcon={<Clear />} onClick={resetFilters}>
              Clear Filters
            </Button>
          </Grid>
        )}
        {!withoutFiltering && (
          <Grid item>
            {searchObj.activityId ? (
              <Button
                size='small'
                endIcon={<Ballot />}
                onClick={clearActivityIdSearch}
              >
                Show All
              </Button>
            ) : (
              <>
                <IconButton
                  color={filtered ? 'primary' : 'default'}
                  disabled={!loading && !data.length}
                  onClick={toggleActivityFilterDialog}
                >
                  <FilterList />
                </IconButton>
                <ActivityFilterDialog
                  filters={filters}
                  onFilter={handleFilter}
                  open={filterDialogOpened}
                  onClose={toggleActivityFilterDialog}
                  reportingPlatformEnabled={Boolean(reportingPlatformName)}
                  eventsEnabled={eventsEnabled}
                  {...filterOptions}
                />
              </>
            )}
          </Grid>
        )}

        {!paginateOnScroll && (
          <Grid item id='paginationControls'>
            <SimplePagination
              disableNextPage={!page}
              onPageChange={handlePageChange}
            />
          </Grid>
        )}
      </Grid>

      <Grid
        container
        ref={scrollRef as React.RefObject<HTMLDivElement>}
        className={classes.activityFeedContainer}
        spacing={1}
        alignContent='flex-start'
      >
        {renderFeedItems(data, {
          displayHeader,
          displayTimeline,
          loading,
          emptyContent,
          currentUserId,
          reportingPlatformName,
          isPnmLabelLinkDisabled,
        })}

        {loading &&
          (searchObj.activityId ? (
            <Grid item xs={12}>
              <FeedItem
                skeleton
                skeletonType='expanded'
                isPnmLabelLinkDisabled={isPnmLabelLinkDisabled}
                isLastChild
              />
            </Grid>
          ) : (
            Array.from({ length: 4 }, (_, i) => (
              <Grid item xs={12}>
                <FeedItem
                  skeleton
                  key={i + 1}
                  displayTimeline={displayTimeline}
                  displayTopLine={displayHeader || (!displayHeader && i > 0)}
                  isLastChild={i === 3}
                  reportingPlatformName={reportingPlatformName}
                  isPnmLabelLinkDisabled={isPnmLabelLinkDisabled}
                />
              </Grid>
            ))
          ))}

        {paginateOnScroll && nextPage && (
          <Grid item xs={12} className={classes.loadMoreButton}>
            <Button
              variant='outlined'
              onClick={() => handlePageChange(nextPage)}
              loading={loading}
              startIcon={<ArrowDownward />}
            >
              Load More
            </Button>
          </Grid>
        )}
      </Grid>
    </Box>
  );
}
const renderEmptyMessage = () => {
  return (
    <Grid container>
      <Grid item xs={6} style={{ paddingLeft: 32, margin: 15 }}>
        <EmptyMessage
          title='No activity found'
          description='Return here to track your recruitment story as it unfolds.'
          descriptionColor='secondary'
          variant='outlined'
        />
      </Grid>
    </Grid>
  );
};

const renderFeedItems = (data: any, options: any) => {
  const feedItems = [];
  const {
    displayHeader,
    displayTimeline,
    loading,
    emptyContent,
    currentUserId,
    isPnmLabelLinkDisabled,
  } = options;
  let prevDate = null;
  let itemIndex = 0;

  for (const activity of data) {
    const currentDate = moment(activity.createdOn).format('YYYYMMDD');
    const currentUserIsAuthor = activity.actorId === currentUserId;
    feedItems.push(
      <Grid item xs={12}>
        <FeedItem
          {...activity}
          displayTimeline={displayTimeline}
          key={activity.id}
          displayTopLine={displayHeader || (!displayHeader && itemIndex > 0)}
          currentUserIsAuthor={currentUserIsAuthor}
          showDate={currentDate !== prevDate}
          isLastChild={itemIndex === data.length - 1}
          reportingPlatformName={options.reportingPlatformName}
          isPnmLabelLinkDisabled={isPnmLabelLinkDisabled}
        />
      </Grid>
    );
    prevDate = currentDate;
    itemIndex += 1;
  }

  if (!feedItems.length && !loading) {
    return emptyContent;
  }
  return feedItems;
};

const useReduxForm = () => {
  const dispatch = useDispatch();
  const actions = useMemo(
    () => ({
      clearActivities: () => dispatch(clearActivities()),
      fetchActivities: (payload: any) => dispatch(fetchActivities(payload)),
      fetchActivity: (payload: any) => dispatch(fetchActivity(payload)),
    }),
    [dispatch]
  );

  const { activity, ...rest } = useSelector(
    state => ({
      activity: getSliceState('activity')(state),
      reportingPlatformName: getReportingPlatformName(state),
      nextActivityUrl: (state as any).getIn(['activity', 'meta', 'next']),
      currentUserId: (state as any).getIn(['currentUser', 'data', 'id']),
    }),
    isEqual
  );
  return { ...actions, ...activity, ...rest };
};

const mapFetchArgs = ({
  member,
  createdOnDate,
  createdOnFrom,
  createdOnTo,
  actions,
  targetId,
  targetType,
  orderBy,
}: any) => {
  const args = { actions };
  if (createdOnDate) {
    (args as any).createdOnFrom = formatStartDate(createdOnDate);
    (args as any).createdOnTo = formatEndDate(createdOnDate);
  }
  if (member?.value) (args as any).actorId = member.value;
  if (targetId) (args as any).targetId = targetId;
  if (targetType) (args as any).targetType = targetType;
  if (orderBy) (args as any).orderBy = orderBy;
  if (createdOnFrom) (args as any).createdOnFrom = createdOnFrom;
  if (createdOnTo) (args as any).createdOnTo = createdOnTo;
  return args;
};

const useStyles = makeStyles(theme => ({
  root: {
    padding: 8,
    overflow: 'auto',
    height: '100%',
  },
  paddedFeed: {
    paddingTop: 21,
  },
  header: {
    marginTop: 0,
  },
  timeLineIcon: {
    position: 'relative',
    display: 'flex',
    padding: 6,
    borderRadius: '50%',
    borderStyle: 'solid',
    borderWidth: 10,
    borderColor: 'rgba(240, 79, 69, .3)',
    backgroundColor: theme.palette.primary.main,
    backgroundClip: 'padding-box',
    zIndex: 1,
  },
  marginTimeLine: {
    marginLeft: 74,
    [theme.breakpoints.down('xs')]: {
      marginLeft: 0,
    },
  },
  loadMoreButton: {
    marginTop: 20,
    textAlign: 'center',
  },
  emptyMessage: {
    paddingLeft: 52,
  },
  activityFeedContainer: {
    height: 'calc(100% - 72px)',
    overflowX: 'hidden',
    overflowY: 'auto',
  },
}));
