import Immutable from 'immutable';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { doneIndicator, success } from 'store/actions/httpActions';

// api
import client from 'sources/api';

// constants
import {
  PAYMENT_SETUP_UPDATE_REQUEST,
  PAYMENT_SETUP_UPDATE,
} from 'store/constants/paymentSettingsTypes';

// schemas
import { paymentSetupSchema } from 'store/schemas/paymentSetupSchema';

// helpers
import httpSaga from 'store/sagas/httpSaga';
import getResponsePayload from 'helpers/getResponsePayload';

const WATCH_TYPE = PAYMENT_SETUP_UPDATE_REQUEST;
const TYPE = PAYMENT_SETUP_UPDATE;

export function* updatePaymentSettingsSaga(action: any) {
  const {
    payload: { formName, groupId, formData },
  } = action;

  const { paymentStatus, contactPerson } = formData || {};

  // @ts-expect-error ts-migrate(2569) FIXME: Type 'Generator<any, any, unknown>' is not an arra... Remove this comment to see the full error message
  const response = yield* httpSaga(
    TYPE,
    call(client.patch, `/payment_setup?group_id=${groupId}`, {
      ...(contactPerson ? { contact_person_id: contactPerson } : {}),
      ...(paymentStatus ? { status: paymentStatus } : {}),
    }),
    {
      formName,
    }
  );

  const payload = getResponsePayload(response, [paymentSetupSchema]);

  yield put(success(TYPE, Immutable.fromJS(payload)));

  yield put(doneIndicator(TYPE));
}

export function* watch() {
  yield takeEvery(WATCH_TYPE, updatePaymentSettingsSaga);
}

export default function* root() {
  yield all([fork(watch)]);
}
