import { Styles } from 'assets/types';

const paymentsTabStyles: Styles = theme => ({
  root: {
    padding: 8,
    overflow: 'hidden',
  },
  paddedFeed: {
    paddingTop: 21,
  },
  header: {
    marginTop: 0,
  },
  timeLineIcon: {
    position: 'relative',
    display: 'flex',
    padding: 6,
    borderRadius: '50%',
    borderStyle: 'solid',
    borderWidth: 10,
    borderColor: 'rgba(240, 79, 69, .3)',
    backgroundColor: theme.palette.primary.main,
    backgroundClip: 'padding-box',
    zIndex: 1,
  },
  marginTimeLine: {
    marginLeft: 74,
    [theme.breakpoints.down('xs')]: {
      marginLeft: 0,
    },
  },
  loadMoreButton: {
    marginTop: 20,
    textAlign: 'center',
  },
  emptyMessage: {
    paddingLeft: 32,
    margin: 15,
  },
  circularProgress: {
    textAlign: 'center',
  },
});

export default paymentsTabStyles;
