import { Styles, Theme } from 'assets/types';

const formEditorOptionsStyles: Styles = theme => {
  const border = `2px solid ${(theme as Theme).palette.background.darkPaper}`;
  return {
    optionsCard: {
      position: 'fixed',
      right: 20,
      top: 38,
      paddingTop: 15,
      paddingBottom: 10,
      [theme.breakpoints.only('xl')]: {
        width: '27%',
      },
      [theme.breakpoints.only('lg')]: {
        width: '28%',
      },
      [theme.breakpoints.only('md')]: {
        width: '27%',
      },
    },
    optionsCardContent: {
      paddingLeft: 20,
      paddingRight: 20,
      marginBottom: 15,
    },
    linkCopyButton: {
      marginBottom: 15,
    },
    sectionDivider: {
      borderTop: border,
      paddingBottom: 15,
    },
    formLinkInput: {
      zIndex: -10,
      opacity: 0,
      position: 'absolute',
    },
    warningPaymentText: {
      [theme.breakpoints.up('md')]: {
        paddingRight: 20,
      },
    },
  };
};

export default formEditorOptionsStyles;
