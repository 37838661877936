import { Map } from 'immutable';
// constants
import {
  GROUP_FETCH,
  GROUP_CREATE,
  GROUP_UPDATE,
  GROUP_DELETE,
  GROUP_CLEAR,
  GROUP_UNLINK,
} from 'store/constants/groupTypes';
import {
  GROUP_TAG_CREATE,
  GROUP_TAG_DELETE,
} from 'store/constants/groupTagTypes';
import {
  GROUP_ADD_DASHBOARD_LINK,
  GROUP_REMOVE_DASHBOARD_LINK,
} from 'store/constants/groupTypes';
// helpers
import httpReducer from './helpers/httpReducer';
import remove from './callbacks/remove';
import addLabels from './callbacks/addLabels';
import removeLabels from './callbacks/removeLabels';
import updateDashboardLinkedGroups from './callbacks/updateDashboardLinkedGroups';
import update from './callbacks/update';

export default function reduceGroup(state = Map(), action = {}) {
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'payload' does not exist on type '{}'.
  const { payload = Map() } = action;
  switch ((action as any).type) {
    case GROUP_FETCH:
      return httpReducer(state, action, {
        entity: 'group',
        appendResult: payload.get('appendResult', false),
        result: Map.isMap(payload)
          ? payload.getIn(['responseOptions', 'resultKey'], 'result')
          : 'result',
        total: Map.isMap(payload)
          ? payload.getIn(['responseOptions', 'totalKey'], 'total')
          : 'total',
        paginationKey: Map.isMap(payload)
          ? payload.getIn(['responseOptions', 'paginationKey'], '')
          : '',
        // labels will duplicate on search/filter if true
        mergeItems: payload.get('mergeItems', true),
      });
    case GROUP_CREATE:
      return httpReducer(state, action, {
        entity: 'group',
        result: payload.getIn(['responseOptions', 'resultKey'], 'result'),
      });
    case GROUP_UPDATE:
      return httpReducer(state, action, {
        entity: 'group',
        result: Map.isMap(payload)
          ? payload.getIn(['responseOptions', 'resultKey'], 'result')
          : 'result',
        callback: update,
      });
    case GROUP_DELETE:
    case GROUP_UNLINK:
      return httpReducer(state, action, {
        entity: 'group',
        result: payload.getIn(['responseOptions', 'resultKey'], 'result'),
        callback: remove,
      });
    case GROUP_TAG_CREATE:
      return addLabels(state, action);
    case GROUP_TAG_DELETE:
      return removeLabels(state, action);
    case GROUP_ADD_DASHBOARD_LINK:
    case GROUP_REMOVE_DASHBOARD_LINK:
      return httpReducer(state, action, {
        callback: updateDashboardLinkedGroups,
      });
    case GROUP_CLEAR:
      return Map();
    default:
      return state;
  }
}
