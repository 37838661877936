import React from 'react';

// material-ui
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

// components
import LeaderboardTable from 'components/LeaderboardTable';

const LeaderboardTab = function() {
  const classes = useStyles();

  return (
    <Grid container className={classes.leaderboardTab} justifyContent='center'>
      <Grid item xs={12} sm={10} lg={11}>
        <LeaderboardTable rowsPerPage={5} />
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles(theme => {
  return {
    leaderboardTab: {
      marginBottom: 24,

      [theme.breakpoints.only('xs')]: {
        marginBottom: 45,
        height: '100%',
      },
    },
  };
});

export default LeaderboardTab;
