import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import moment from 'moment';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';
import formatStartDate from 'helpers/formatStartDate';
import formatEndDate from 'helpers/formatEndDate';
import validate from 'validate.js';
// selectors
import getCurrentGroupId from 'store/selectors/getCurrentGroupId';
import getLoadingFromState from 'store/selectors/getLoadingFromState';
import getLabels from 'store/selectors/getLabels';
// material-ui
import {
  Fade,
  Card,
  Grid,
  InputAdornment,
  CircularProgress,
  withStyles,
  WithStyles,
} from '@material-ui/core';
// components
import TextField from 'components/TextField';
import IconButton from 'components/IconButton';
import FilterDialog from 'components/FilterDialog';
import { Field } from 'react-final-form';
import DateRange from 'components/DateRange';
import Select from 'components/Select';
// icons
import Search from '@material-ui/icons/Search';
import Clear from '@material-ui/icons/Clear';
//helpers
import { isEmpty } from 'helpers/check';
//Styles
import chapterSearchBarStyles from 'layout/ApplicationLayout/components/ChapterSearchBar/chapterSearchBar.style';

const FORM = 'groupFiltersForm';

export interface ChapterSearchBarProps
  extends WithStyles<typeof chapterSearchBarStyles> {}

const ChapterSearchBar = ({ classes }: ChapterSearchBarProps) => {
  const history = useHistory();
  const { location: { pathname, search } = {} } = history;
  const urlParams = new URLSearchParams(search);
  const searchParam = urlParams.get('search') || '';
  const latestActivityStart = urlParams.get('latestActivityStart');
  const latestActivityEnd = urlParams.get('latestActivityEnd');
  const urlLabels = urlParams
    .get('labels')
    ?.split(',')
    ?.map(urlLabel => parseInt(urlLabel, 10));
  const { currentGroupId, groupLoading, labels = [] } = useSelector(
    state => ({
      currentGroupId: getCurrentGroupId(state),
      labels: getLabels(state),
      groupLoading: getLoadingFromState('group')(state),
    }),
    shallowEqual
  );

  // component state initialization
  const [timerId, setTimerId] = useState(null);
  const [searchValue, setSearchValue] = useState();
  const [focused, setFocused] = useState(false);

  // useEffect flows
  useEffect(() => {
    // handles setting initial search value if it's in the URL but not state
    if (!searchValue && searchParam) {
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
      setSearchValue(searchParam);
    }
  }, [searchParam, searchValue]);
  useEffect(() => {
    // handles clearing search if the url params changes
    if (searchValue && !searchParam) {
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '""' is not assignable to paramet... Remove this comment to see the full error message
      setSearchValue('');
    }
  }, [searchParam]); // eslint-disable-line
  useEffect(() => {
    if (searchValue) {
      handleClearSearchParam(); // clears search when different group tabs are clicked
    }
  }, [pathname]); // eslint-disable-line
  useEffect(() => {
    if (searchValue) {
      updateSearchParam(searchValue);
    }
  }, [pathname]); // eslint-disable-line

  const validateFields = (values: any) => {
    return validate(values, getSchema(values));
  };

  const updateSearchParam = useCallback(
    newSearchValue => {
      const newSearch = new URLSearchParams(search);
      newSearch.set('search', newSearchValue);
      history.replace({ pathname, search: newSearch.toString() });
    },
    [history, pathname, search]
  );

  const handleClearSearchParam = useCallback(() => {
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '""' is not assignable to paramet... Remove this comment to see the full error message
    setSearchValue('');
    const newSearch = new URLSearchParams(search);
    newSearch.delete('search');
    history.replace({ pathname, search: newSearch.toString() });
  }, [history, pathname, search]);

  const handleSearchChange = useCallback(
    ({ target: { value } }) => {
      setSearchValue(value);
      if (timerId) {
        // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
        clearTimeout(timerId);
      }
      if (value.length > 1) {
        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'number' is not assignable to par... Remove this comment to see the full error message
        setTimerId(setTimeout(updateSearchParam, 600, value));
      }
      if (!value.length && searchValue) {
        handleClearSearchParam();
      }
    },
    [handleClearSearchParam, searchValue, timerId, updateSearchParam]
  );

  const handleDateChange = useCallback(({ startDate, endDate, input }) => {
    input.onChange({ startDate, endDate });
  }, []);

  const handleSetFilters = ({
    labels,
    // @ts-expect-error ts-migrate(2525) FIXME: Initializer provides no value for this binding ele... Remove this comment to see the full error message
    latestActivity: { startDate, endDate } = {},
  }: any) => {
    const newSearch = new URLSearchParams(search);
    if (labels && !isEmpty(labels)) {
      newSearch.set('labels', labels);
    } else {
      newSearch.delete('labels');
    }
    if (startDate && endDate && !isEmpty(startDate) && !isEmpty(endDate)) {
      newSearch.set('latestActivityStart', formatStartDate(startDate));
      newSearch.set('latestActivityEnd', formatEndDate(endDate));
    } else {
      newSearch.delete('latestActivityStart');
      newSearch.delete('latestActivityEnd');
    }
    history.push({ pathname, search: newSearch.toString() });
  };

  const labelSuggestions = labels.map(({ title, id }: any = {}) => ({
    label: title,
    value: id,
  }));

  const filterItems = [];
  if (currentGroupId) {
    filterItems.push({
      label: 'Chapter Label',
      field: (
        <Grid item xs className={classes.labelSelector}>
          <Field
            name='labels'
            render={props => (
              <Select
                label='Select Labels'
                // @ts-expect-error ts-migrate(2322) FIXME: Type '{ input: FieldInputProps<any, HTMLElement>; ... Remove this comment to see the full error message
                variant='outlined'
                options={labelSuggestions}
                multiple
                {...props}
              />
            )}
          />
        </Grid>
      ),
      selected: urlLabels ? Boolean(urlLabels) : undefined,
    });
  }
  filterItems.push({
    label: 'Latest Activity',
    field: (
      <Grid item xs>
        <Field
          name='latestActivity'
          // @ts-expect-error ts-migrate(2322) FIXME: Type 'typeof DateRange' is not assignable to type ... Remove this comment to see the full error message
          component={DateRange}
          formName={FORM}
          onDateChange={handleDateChange}
          justifyContent='flex-start'
          initialStartDate={
            latestActivityStart ? moment(latestActivityStart) : ''
          }
          initialEndDate={latestActivityEnd ? moment(latestActivityEnd) : ''}
          required
        />
      </Grid>
    ),
    selected: Boolean(latestActivityStart),
  });
  return (
    <Card className={classNames(classes.searchBar, focused && classes.focused)}>
      <Grid container spacing={1} alignItems='center'>
        <Grid item xs={9} sm={8} className={classes.textField}>
          <TextField
            placeholder='Search Chapters'
            value={searchValue}
            onChange={handleSearchChange}
            onFocus={() => setFocused(true)}
            onBlur={() => setFocused(false)}
            variant='standard'
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <Search />
                </InputAdornment>
              ),
              disableUnderline: true,
            }}
          />
        </Grid>

        {groupLoading && searchValue ? (
          <Grid item xs className={classes.barIcon}>
            <Fade in={groupLoading}>
              <CircularProgress size={20} />
            </Fade>
          </Grid>
        ) : (
          <Grid item xs className={classes.barIcon}>
            <Fade in={Boolean(searchValue)}>
              <IconButton id='clearButton' onClick={handleClearSearchParam}>
                <Clear />
              </IconButton>
            </Fade>
          </Grid>
        )}

        {/* @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'. */}
        {pathname.includes('chapters') && (
          <Grid item xs={1} sm={2} className={classes.barIcon}>
            <FilterDialog
              filterItems={filterItems}
              onFilterChange={handleSetFilters}
              validateFields={validateFields}
              initialFilters={{
                latestActivity: {
                  startDate: latestActivityStart,
                  endDate: latestActivityEnd,
                },
                labels: urlLabels,
              }}
              defaultExpanded={false}
              closeFormAfterReset
              turnOnHardResetForm
            />
          </Grid>
        )}
      </Grid>
    </Card>
  );
};

const getSchema = (values = {}) => {
  const schema = {};
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'latestActivity' does not exist on type '... Remove this comment to see the full error message
  const { latestActivity: { startDate, endDate } = {} } = values;
  if (startDate && !endDate) {
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    schema['latestActivity.endDate'] = {
      presence: { message: 'Select an end date' },
    };
  } else if (!startDate && endDate) {
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    schema['latestActivity.startDate'] = {
      presence: { message: 'Select a start date' },
    };
  }
  return schema;
};

export default withStyles(chapterSearchBarStyles, { withTheme: true })(
  ChapterSearchBar
);
