import { all, call, fork, takeEvery, put } from 'redux-saga/effects';

// action creators
import { doneIndicator } from 'store/actions/httpActions';

// api
import client from 'sources/api';

// constants
import {
  GROUP_FIELD_UPDATE_REQUEST,
  GROUP_FIELD_UPDATE,
} from 'store/constants/groupFieldTypes';

// lib
import httpSaga from 'store/sagas/httpSaga';

// schemas
import { groupFieldSchema } from 'store/schemas/groupFieldSchema';

import { setFormSubmitSucceeded } from 'store/actions/formActions';

const WATCH_TYPE = GROUP_FIELD_UPDATE_REQUEST;
const TYPE = GROUP_FIELD_UPDATE;

export function* updateGroupFields(action: any) {
  const FORM = action.payload.formName;
  const { groupFields } = action.payload;

  yield all(
    groupFields.map((groupField: any, index: any) => {
      const {
        groupId,
        fieldType,
        isPublished,
        description,
        label,
        sources,
        visibility,
        type,
        options,
      } = groupField;
      const position = index + 1; // we want to use `index + 1` as the position
      const newOptions =
        options && Array.isArray(options)
          ? options.filter(
              (item?: string | null, index?: number) =>
                index === options.indexOf(item)
            )
          : options;

      return httpSaga(
        TYPE,
        call(client.put, `/group_fields/${groupField.id}`, {
          group_id: groupId,
          field_type: fieldType,
          is_published: isPublished,
          description: type === 'custom' ? description || '' : description,
          label,
          sources,
          visibility,
          type,
          position,
          options: newOptions,
        }),
        {
          formName: FORM,
          dispatchFormSuccess: false,
          shouldDestroyForm: false,
          schema: groupFieldSchema,
        }
      );
    })
  );

  // Manually setting form submit success necessary for forms that loop through API calls:
  //  Otherwise `submitting` may be set to false while calls are still occuring
  yield put(setFormSubmitSucceeded(FORM, ''));

  yield put(doneIndicator(TYPE));
}

export function* watch() {
  yield takeEvery(WATCH_TYPE, updateGroupFields);
}

export default function* root() {
  yield all([fork(watch)]);
}
