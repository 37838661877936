import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

// action creators
import { doneIndicator } from 'store/actions/httpActions';

// api
import client from 'sources/api';

// constants
import {
  INVITE_DELETE_REQUEST,
  INVITE_DELETE,
} from 'store/constants/inviteTypes';

// schemas
import { inviteSchema } from 'store/schemas/inviteSchema';

// helpers
import httpSaga from 'store/sagas/httpSaga';

const WATCH_TYPE = INVITE_DELETE_REQUEST;
const TYPE = INVITE_DELETE;

export function* deleteInvite(action: any) {
  const {
    payload: { id },
  } = action;

  // @ts-expect-error ts-migrate(2569) FIXME: Type 'Generator<any, any, unknown>' is not an arra... Remove this comment to see the full error message
  yield* httpSaga(TYPE, call(client.delete, `/invites/${id}`), {
    schema: inviteSchema,
    metaId: `delete-${id}`,
  });

  yield put(doneIndicator(TYPE));
}

export function* watch() {
  yield takeEvery(WATCH_TYPE, deleteInvite);
}

export default function* root() {
  yield all([fork(watch)]);
}
