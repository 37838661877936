import React from 'react';

// MUI components
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

// components
import Leader from './Leader';

Podium.defaultProps = {
  data: [],
};

type OwnProps = {
  data: any; // TODO: PropTypes.instanceOf(Array)
};

type Props = OwnProps & typeof Podium.defaultProps;

export default function Podium({ data }: Props) {
  const classes = useStyles();
  return (
    <Grid container className={classes.container}>
      <Leader leaders={data} index={1} />
      <Leader leaders={data} index={0} />
      <Leader leaders={data} index={2} />
    </Grid>
  );
}

const useStyles = makeStyles(() => ({
  container: {
    marginBottom: 16,
  },
}));
