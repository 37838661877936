import { Styles } from 'assets/types';

const shareFormModalStyles: Styles = () => ({
  optionsCardContent: {
    paddingLeft: 20,
  },
  formLinkInput: {
    zIndex: -10,
    opacity: 0,
    position: 'absolute',
  },
});

export default shareFormModalStyles;
