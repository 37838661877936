export const REQUEST_FETCH_FOR_GROUP_REQUEST =
  'REQUEST_FETCH_FOR_GROUP_REQUEST';
export const REQUEST_FETCH_FOR_GROUP = 'REQUEST_FETCH_FOR_GROUP';

export const REQUEST_FETCH_FOR_USER_REQUEST = 'REQUEST_FETCH_FOR_USER_REQUEST';
export const REQUEST_FETCH_FOR_USER = 'REQUEST_FETCH_FOR_USER';

export const REQUEST_CREATE_REQUEST = 'REQUEST_CREATE_REQUEST';
export const REQUEST_CREATE = 'REQUEST_CREATE';

export const REQUEST_UPDATE_REQUEST = 'REQUEST_UPDATE_REQUEST';
export const REQUEST_UPDATE = 'REQUEST_UPDATE';

export const REQUEST_DELETE_REQUEST = 'REQUEST_DELETE_REQUEST';
export const REQUEST_DELETE = 'REQUEST_DELETE';
