import React, { useState, useCallback } from 'react';
import { isEqual } from 'lodash';

// material-ui
import { makeStyles } from '@material-ui/core/styles';
import { Badge, Grid, Hidden } from '@material-ui/core';

// icons
import { FilterList } from '@material-ui/icons';

// components
import Button from 'components/Button';
import Accordion from 'components/Accordion';
import IconButton from 'components/IconButton';
import DazzlingDialog from 'components/DazzlingDialog';
import { Form } from 'react-final-form';

export default function FilterDialog({
  defaultExpanded = true,
  filterItems,
  onFilterChange = () => {},
  initialFilters = {},
  validateFields,
  closeFormAfterReset,
  turnOnHardResetForm,
}: {
  defaultExpanded?: boolean;
  filterItems: { label: string; field: JSX.Element; selected?: boolean }[];
  onFilterChange: (...args: any[]) => any;
  initialFilters?: any; // TODO: PropTypes.instanceOf(Object)
  validateFields: (...args: any[]) => any;
  closeFormAfterReset?: boolean;
  turnOnHardResetForm?: boolean;
}) {
  const classes = useStyles();
  const selectedFilters = filterItems.filter(item => Boolean(item.selected));

  // set up the component state
  const [filterModalOpen, setFilterModalOpen] = useState(false);

  const openFilterModal = useCallback(() => {
    setFilterModalOpen(true);
  }, []);

  // gets new groups given filters
  const handleFilterList = useCallback(
    (values, shouldCloseModal = true) => {
      if (shouldCloseModal) setFilterModalOpen(false);
      onFilterChange(values);
    },
    [onFilterChange]
  );

  const handleReset = useCallback(
    (values, reset, shouldCloseModal = true) => {
      handleFilterList(values, shouldCloseModal);
      if (turnOnHardResetForm) {
        reset();
      }
    },
    [handleFilterList, turnOnHardResetForm]
  );

  // custom dialog actions
  const getFormActions = ({ invalid, reset }: any) => [
    <Button
      id='rejectButton'
      onClick={() => {
        setFilterModalOpen(false);
      }}
    >
      Cancel
    </Button>,
    <Button
      id='resetButton'
      color='primary'
      variant='outlined'
      onClick={() => handleReset({}, reset, !!closeFormAfterReset)}
    >
      Reset
    </Button>,
    <Button
      id='submitButton'
      type='submit'
      color='primary'
      variant='contained'
      disabled={invalid}
    >
      Apply Filters
    </Button>,
  ];

  return (
    <div>
      <Hidden smUp>
        <IconButton id='filterButton' onClick={openFilterModal}>
          <Badge badgeContent={selectedFilters.length} color='primary'>
            <FilterList />
          </Badge>
        </IconButton>
      </Hidden>

      <Hidden xsDown>
        <Button
          id='filterButton'
          onClick={openFilterModal}
          endIcon={
            <Badge badgeContent={selectedFilters.length} color='primary'>
              <FilterList />
            </Badge>
          }
        >
          Filter
        </Button>
      </Hidden>

      <Form
        onSubmit={values => handleFilterList(values)}
        initialValues={initialFilters}
        initialValuesEqual={isEqual}
        validate={validateFields}
        render={({
          handleSubmit,
          invalid,
          values,
          form: { submit, restart, reset },
        }) => {
          return (
            <DazzlingDialog
              acceptLabel='Apply Filters'
              headerProps={{
                icon: FilterList,
                title: 'Filter List',
                highlightedSubtitle: 'Select filters and refine your list',
              }}
              height='auto'
              disabled={invalid}
              handleClose={() => {
                restart();
                setFilterModalOpen(false);
              }}
              open={filterModalOpen}
              onAccept={handleSubmit}
              actions={getFormActions({ invalid, reset })}
              resetForm={restart}
            >
              <Grid container alignItems='center'>
                <Grid item xs className={classes.outlinedContainer}>
                  {filterItems.map(({ label, field, selected }, index) => (
                    <Grid
                      key={label}
                      container
                      className={
                        index !== filterItems.length - 1
                          ? classes.filterItemContainer
                          : ''
                      }
                      alignItems='center'
                      justifyContent='flex-start'
                    >
                      <Grid item xs={12}>
                        <Accordion
                          label={label}
                          details={field}
                          defaultExpanded={defaultExpanded || selected}
                          highlighted={selected}
                        />
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </DazzlingDialog>
          );
        }}
      />
    </div>
  );
}

const useStyles = makeStyles(theme => {
  const border = `1px solid rgba(255,255,255,0.25)`;

  return {
    outlinedContainer: {
      marginBottom: 20,
      border,
      borderRadius: 4,
    },
    filterItemContainer: {
      borderBottom: border,
    },
    selectedFiltersHeader: {
      marginTop: 20,
    },
    addFilterButton: {
      marginTop: 24,
    },
  };
});
