import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

// action creators
import { doneIndicator } from 'store/actions/httpActions';
import { setFormSubmitSucceeded } from 'store/actions/formActions';

// api
import client from 'sources/api';

// constants
import {
  REFERRAL_FORM_UPDATE_REQUEST,
  REFERRAL_FORM_UPDATE,
} from 'store/constants/referralFormTypes';

// schemas
import { referralFormSchema } from 'store/schemas/referralFormSchema';

// helpers
import httpSaga from 'store/sagas/httpSaga';

const WATCH_TYPE = REFERRAL_FORM_UPDATE_REQUEST;
const TYPE = REFERRAL_FORM_UPDATE;

export function* updateReferralForm(action: any) {
  const {
    payload: {
      formName,
      isCouncilForm,
      isOrganizationForm,
      isUniversalForm,
      formId,
      groupId: group_id,
      noFormFields,
      ...values
    },
  } = action;

  const {
    title,
    type,
    description,
    enableImageUpload: enable_image_upload,
    enableConfirmationEmail: enable_confirmation_email,
    requestReferrersInfo: request_referrers_info,
    confirmationEmailContent: confirmation_email_content,
    headerPhotoFileId: header_photo_file_id,
    isPublished: is_published,
    enableCustomAgreement1: enable_custom_agreement_1,
    customAgreement1Required: custom_agreement_1_required,
    customAgreement1Content: custom_agreement_1_content,
    enableCustomAgreement2: enable_custom_agreement_2,
    customAgreement2Required: custom_agreement_2_required,
    customAgreement2Content: custom_agreement_2_content,
    collectPayment: collect_payment,
    emailRequired: email_required,
    phoneNumberRequired: phone_number_required,
    chapterSelectionEnabled: chapter_selection_enabled,
    genderSelectionEnabled: gender_selection_enabled,
    childIds: child_ids,
  } = values || {};

  // @ts-expect-error ts-migrate(2569) FIXME: Type 'Generator<any, any, unknown>' is not an arra... Remove this comment to see the full error message
  yield* httpSaga(
    TYPE,
    call(client.put, `/forms/${formId}`, {
      title,
      type,
      description,
      group_id,
      enable_image_upload,
      enable_confirmation_email,
      request_referrers_info,
      confirmation_email_content,
      header_photo_file_id,
      is_published,
      email_required,
      phone_number_required,
      ...(isCouncilForm || isOrganizationForm || isUniversalForm
        ? {
            enable_custom_agreement_1,
            custom_agreement_1_required,
            custom_agreement_1_content,
            chapter_selection_enabled,
            gender_selection_enabled,
            enable_custom_agreement_2,
            custom_agreement_2_required,
            custom_agreement_2_content,
            collect_payment,
            ...(typeof child_ids !== 'undefined'
              ? { child_ids: child_ids || [] }
              : {}),
          }
        : {}),
    }),
    {
      metaId: `update-${formId}`,
      dispatchFormSuccess: false,
      shouldDestroyForm: false,
      singleResponseAsArray: true,
      schema: [referralFormSchema],
      formName,
    }
  );

  if (noFormFields) {
    yield put(setFormSubmitSucceeded(formName, ''));
  }

  yield put(doneIndicator(TYPE));
}

export function* watch() {
  yield takeEvery(WATCH_TYPE, updateReferralForm);
}

export default function* root() {
  yield all([fork(watch)]);
}
