import {
  FORM_FIELD_DELETE_REQUEST,
  FORM_FIELD_CREATE_REQUEST,
  FORM_FIELD_FETCH_REQUEST,
  FORM_FIELD_CLEAR,
  FORM_FIELD_UPDATE_MANY_REQUEST,
} from 'store/constants/formFieldTypes';

export function createFormField({
  groupId,
  formName,
  ...values
}: { groupId: number; formName: string } & Omit<
  FormField,
  'id' | 'isImmutable'
>) {
  return {
    type: FORM_FIELD_CREATE_REQUEST,
    payload: { groupId, formName, ...values },
  };
}

export function deleteFormField({ groupId, formId, label }: any) {
  return {
    type: FORM_FIELD_DELETE_REQUEST,
    payload: { groupId, formId, label },
  };
}

export function updateFormFields({
  groupId,
  formId,
  formName,
  formFields,
}: {
  groupId: number;
  formId: number;
  formName: string;
  formFields: FormField[];
}) {
  return {
    type: FORM_FIELD_UPDATE_MANY_REQUEST,
    payload: {
      groupId,
      formId,
      formName,
      formFields,
    },
  };
}

export function fetchFormFields({
  formId,
  groupId,
  orderBy = null,
  token,
}: any) {
  return {
    type: FORM_FIELD_FETCH_REQUEST,
    payload: { formId, groupId, orderBy, token },
  };
}

export function clearFormFields() {
  return {
    type: FORM_FIELD_CLEAR,
  };
}
