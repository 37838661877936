import { all } from 'redux-saga/effects';

// sagas
import deleteLeadAttributeSaga from './deleteLeadAttributeSaga';
import createLeadAttributeSaga from './createLeadAttributeSaga';
import updateOrCreateLeadAttributeSaga from './updateOrCreateLeadAttributeSaga';

const leadAttributeSaga = function* rootSaga() {
  yield all([
    deleteLeadAttributeSaga(),
    createLeadAttributeSaga(),
    updateOrCreateLeadAttributeSaga(),
  ]);
};

export default leadAttributeSaga;
