import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import Immutable from 'immutable';

// action creators
import { doneIndicator, success } from 'store/actions/httpActions';

// api
import client from 'sources/api';

// constants
import {
  THREAD_FETCH_REQUEST,
  THREAD_FETCH,
} from 'store/constants/threadTypes';

// schemas
import { threadSchema } from 'store/schemas/threadSchema';

// helpers
import httpSaga from 'store/sagas/httpSaga';
import getResponsePayload from 'helpers/getResponsePayload';

const WATCH_TYPE = THREAD_FETCH_REQUEST;
const TYPE = THREAD_FETCH;
const LEAD_ID = 'pnmId';

export function* fetchThreadsSaga(action: any) {
  const {
    payload: {
      groupId: group_id,
      page,
      search,
      pnmRecipientId: pnm_recipient_id,
      metaId = 'fetch',
    },
  } = action;
  const params = {
    group_id,
    page,
    pnm_recipient_id,
    type: 'text',
  };
  if (search) (params as any).search = search;
  // @ts-expect-error ts-migrate(2569) FIXME: Type 'Generator<any, any, unknown>' is not an arra... Remove this comment to see the full error message
  const response = yield* httpSaga(
    TYPE,
    call(client.get, '/threads', {
      params,
    }),
    {
      dispatchSuccess: false,
      metaId,
      schema: [threadSchema],
    }
  );
  let payload;
  if (response) {
    let recipientIds: any = [];
    // Get all of the recipient ids from the threads
    yield all(
      response.data.results.map((result: any) => {
        const threadRecipientIds = result.recipients.map(
          (recipient: any) => recipient[LEAD_ID]
        );
        recipientIds = recipientIds.concat(threadRecipientIds);
        return true;
      })
    );

    // @ts-expect-error ts-migrate(2525) FIXME: Initializer provides no value for this binding ele... Remove this comment to see the full error message
    const { data: { hasNext, hasPrevious, next, previous } = {} } = response;
    payload = {
      hasNext,
      hasPrevious,
      next,
      previous,
      ...getResponsePayload(response, [threadSchema]),
    };
  }
  yield put(success(TYPE, Immutable.fromJS(payload), metaId));
  yield put(doneIndicator(TYPE));
}
export function* watch() {
  yield takeEvery(WATCH_TYPE, fetchThreadsSaga);
}
export default function* root() {
  yield all([fork(watch)]);
}
