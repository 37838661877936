import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

// MUI components
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Badge } from '@material-ui/core';

// components
import SuperAvatar from 'components/SuperAvatar';

type LeaderProps = {
  leaders: any; // TODO: PropTypes.instanceOf(Array)
  index: number;
};

export default function Leader({ leaders, index }: LeaderProps) {
  const classes = useStyles();
  const {
    accountId,
    points,
    accountName = '',
    accountProfilePhotoFileId: imageId,
  } = leaders[index] || {};

  const history = useHistory();
  const {
    location: { pathname, search },
  } = history;

  const setOverviewMember = useCallback(() => {
    if (pathname.includes('analytics')) {
      const newSearch = new URLSearchParams(search);
      newSearch.set('account', accountId);
      history.replace({ pathname, search: newSearch?.toString() });
    }
  }, [history, accountId, pathname, search]);

  return (
    <Grid
      item
      xs={index === 0 ? 4 : true}
      sm={index === 0 ? 2 : true}
      className={getLeaderStyle(index, classes)}
    >
      <Grid container direction='column' alignItems='center'>
        <Grid item xs>
          <Badge
            badgeContent={index + 1}
            classes={{ badge: getBadgeStyle(index, classes) }}
          >
            <SuperAvatar
              fullName={accountName}
              background='gradient'
              src={imageId}
              size={index === 0 ? 'large' : 'medium'}
              onClick={setOverviewMember}
            />
          </Badge>
        </Grid>

        <Grid item xs className={classes.nameContainer}>
          <Typography variant='body2'>{accountName}</Typography>
        </Grid>

        <Grid item xs>
          <Typography variant='caption' color='secondary'>
            {points} points
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}

const getBadgeStyle = (index: any, classes: any) => {
  switch (index) {
    case 0:
      return classes.gold;
    case 1:
      return classes.silver;
    case 2:
      return classes.bronze;
    default:
      return {};
  }
};
const getLeaderStyle = (index: any, classes: any) => {
  switch (index) {
    case 1:
      return classes.leftLeader;
    case 2:
      return classes.rightLeader;
    default:
      return '';
  }
};

const useStyles = makeStyles(theme => ({
  leftLeader: {
    alignSelf: 'flex-end',
  },
  rightLeader: {
    alignSelf: 'flex-end',
  },
  nameContainer: {
    marginTop: 4,
    textAlign: 'center',
  },
  gold: {
    backgroundColor: '#F0BD1F',
  },
  silver: {
    backgroundColor: '#C3C6D4',
  },
  bronze: {
    backgroundColor: '#A08B55',
  },
}));
