import React, { useMemo, useState, useCallback, memo, useEffect } from 'react';
import { isEqual } from 'lodash';
import { roles } from 'helpers/getRoles';

// material-ui
import {
  Box,
  Divider,
  FormControlLabel,
  Grid,
  Switch,
  Typography,
  Hidden,
  Tooltip,
} from '@material-ui/core';
import { Delete } from '@material-ui/icons';

// components
import IconButton from 'components/IconButton';
import Autocomplete from 'components/Autocomplete';

// selectors
import getLoadingFromState from 'store/selectors/getLoadingFromState';
import getMembers from 'store/selectors/getMembers';
import getCurrentGroupId from 'store/selectors/getCurrentGroupId';

// actions
import {
  updatePhoneNumber as updatePhoneNumberAction,
  toggleCallForwarding as toggleCallForwardingAction,
} from 'store/actions/phoneNumberActions';
import { fetchMembersForGroup as fetchMembersForGroupAction } from 'store/actions/memberActions';

// hooks
import { useDispatch, useSelector } from 'react-redux';

// helpers
import formatDisplayedPhoneNumber from 'helpers/formatDisplayedPhoneNumber';

type Props = {
  id: number;
  twilioNumber: string;
  account: number;
  allowCallForwarding: boolean;
  onDelete: (id: number) => void;
};

const PhoneNumberItem = ({
  allowCallForwarding: initialAllowCallForwarding,
  twilioNumber,
  id,
  onDelete,
  account,
}: Props) => {
  const dispatch = useDispatch();
  const [currentAccount, setCurrentAccount] = useState();
  const [allowCallForwarding, setAllowCallForwarding] = useState(
    initialAllowCallForwarding
  );
  const [disableForwarding, setDisableForwarding] = useState(false);

  const callForwardingLabel = allowCallForwarding
    ? 'Call Forwarding Enabled'
    : 'Call Forwarding Disabled';

  const displayedPhoneNumber = formatDisplayedPhoneNumber(twilioNumber);

  const {
    fetchMembersForGroup,
    updatePhoneNumber,
    toggleCallForwarding,
  } = useMemo(
    () => ({
      fetchMembersForGroup: (payload: any) =>
        dispatch(fetchMembersForGroupAction(payload)),
      updatePhoneNumber: (payload: any) =>
        dispatch(updatePhoneNumberAction(payload)),
      toggleCallForwarding: (payload: any) =>
        dispatch(toggleCallForwardingAction(payload)),
    }),
    [dispatch]
  );

  const { currentGroupId, deleteLoading, memberLoading, members } = useSelector(
    state => ({
      updateLoading: getLoadingFromState(
        'phoneNumber',
        `update-${id}`,
        false
      )(state),
      toggleLoading: getLoadingFromState(
        'phoneNumber',
        `toggle-${id}`,
        false
      )(state),
      deleteLoading: getLoadingFromState(
        'phoneNumber',
        `delete-${id}`,
        false
      )(state),
      memberLoading: getLoadingFromState('member', `fetch-${id}`, false)(state),
      members: getMembers()(state).toJS() as any,
      currentGroupId: getCurrentGroupId(state),
    }),
    isEqual
  );

  useEffect(() => {
    if (!(currentAccount as any)?.value && members.length && id) {
      const initialMember =
        members.find(
          (member = {}) =>
            (member as any).account?.id?.toString() === account?.toString(),
          {},
          {}
        ) || {};
      setCurrentAccount({
        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ label: string; value: any; }' ... Remove this comment to see the full error message
        label: `${initialMember?.account?.firstName ||
          'Unassigned'} ${initialMember?.account?.lastName || ''}`,
        value: initialMember?.account?.id,
      });
      setDisableForwarding(!initialMember?.account?.phoneNumber);
    }
  }, [members.length]); // eslint-disable-line

  // Fetches the currently assigned account if it isn't in the first 10 leads paginated
  useEffect(() => {
    if ((currentAccount as any)?.value && account && members.length) {
      const accountIds = members.map((member = {}) =>
        (member as any).account?.id?.toString()
      );
      const isAccountInSuggestions = accountIds.includes(account?.toString());
      if (!isAccountInSuggestions) {
        fetchMembersForGroup({
          groupId: currentGroupId,
          accountIds: [account],
          metaId: `fetch-${id}`,
          appendResult: true,
        });
      }
    }
  }, [account, currentAccount, currentGroupId]); // eslint-disable-line

  const adminSuggestions = useMemo(() => {
    const adminMembers = members.filter(
      (member = {}) => (member as any).roleId === roles.admin
    );
    adminMembers.unshift({
      account: { id: null, firstName: 'Unassign', lastName: 'Number' },
    });
    return adminMembers.map((item = {}) => ({
      label: `${(item as any).account.firstName} ${
        (item as any).account.lastName
      }`,
      value: (item as any).account.id,
      phoneNumber: (item as any).account.phoneNumber,
    }));
  }, [members]);

  const handleToggleCallForwarding = (event: any) => {
    toggleCallForwarding({ phoneNumberId: id });
    setAllowCallForwarding(event.target.checked);
  };

  const onMemberSearchChange = useCallback(
    (event, value, reason) => {
      if (value && value.length > 1 && reason === 'input') {
        fetchMembersForGroup({
          groupId: currentGroupId,
          search: value,
          metaId: `fetch-${id}`,
        });
      } else if (reason === 'clear') {
        fetchMembersForGroup({
          groupId: currentGroupId,
          metaId: `fetch-${id}`,
        });
      }
    },
    [currentGroupId, fetchMembersForGroup, id]
  );

  useEffect(() => {
    if (disableForwarding) {
      setAllowCallForwarding(false);
    }
  }, [disableForwarding]);

  const handleAssignAccount = useCallback(
    (event, option) => {
      if (option) {
        setDisableForwarding(!option.phoneNumber);
        setCurrentAccount(option);
        updatePhoneNumber({
          phoneNumberId: id,
          accountId: option.value,
        });
      }
    },
    [id, updatePhoneNumber]
  );

  const handleDelete = useCallback(() => {
    onDelete(id);
  }, [onDelete, id]);

  return (
    <Grid item xs={12} lg={8}>
      <Box p={2} border={1} borderRadius={8} borderColor='text.secondary'>
        <Hidden smUp>
          <Divider style={{ marginTop: 10, marginBottom: 10 }} />
        </Hidden>

        <Grid container spacing={3} alignItems='center'>
          <Grid item xs={5} md={3}>
            <Typography style={{ fontWeight: 600 }}>
              {displayedPhoneNumber}
            </Typography>
          </Grid>

          <Grid item xs={6} md={4}>
            <Autocomplete
              onInputChange={onMemberSearchChange}
              onChange={handleAssignAccount}
              options={adminSuggestions}
              label='Assigned To'
              value={currentAccount}
              loading={memberLoading}
            />
          </Grid>

          <Grid item xs={8} md={4}>
            <Tooltip
              title={
                disableForwarding
                  ? 'Call Forwarding is Disabled: Member is missing phone number'
                  : ''
              }
              placement='top'
              arrow
            >
              <FormControlLabel
                disabled={disableForwarding}
                control={
                  <Switch
                    checked={allowCallForwarding}
                    color='primary'
                    type='checkbox'
                    onChange={handleToggleCallForwarding}
                  />
                }
                label={callForwardingLabel}
              />
            </Tooltip>
          </Grid>

          <Grid item xs={2} md={1}>
            <Tooltip id='deleteButtonTooltip' title='Delete Phone Number'>
              <IconButton
                id='delete'
                onClick={handleDelete}
                loading={deleteLoading}
              >
                <Delete />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );
};

export default memo(PhoneNumberItem);
