import { Map } from 'immutable';
// constants
import { LEAD_IMPORT } from 'store/constants/leadTypes';
// helpers
import httpReducer from './helpers/httpReducer';

export default function reduceLead(state = Map(), action = {}) {
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'payload' does not exist on type '{}'.
  const { payload: { appendResult } = {} } = action;
  switch ((action as any).type) {
    case LEAD_IMPORT:
      return httpReducer(state, action, {
        result: 'fetchResult',
        appendResult: appendResult,
      });
    default:
      return state;
  }
}
