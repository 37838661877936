import React, { Dispatch, SetStateAction, useCallback, useMemo } from 'react';
import { isEqual } from 'lodash';
import validate from 'validate.js';
import { Form } from 'react-final-form';
// material-ui
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
// icons
import WarningIcon from '@material-ui/icons/Warning';
//components
import DazzlingDialog from 'components/DazzlingDialog';
// helpers
import { ManageSubscriptionStatus } from 'components/ManageSubscription/helpers/types';
import { SubscriptionInfo } from 'components/ManageSubscription/helpers/interfaces';

const FORM = 'downgradeForm';

interface DowngradeModalProps {
  open: boolean;
  isItNewSubscription: boolean;
  onCurrentModalStatusChange: Dispatch<
    SetStateAction<ManageSubscriptionStatus>
  >;
  onSubscriptionInfoChange: Dispatch<SetStateAction<SubscriptionInfo>>;
  onClose?: () => void;
}

const SCHEMA = {
  confirmDowngrade: false,
};

const DowngradeModal = ({
  open,
  isItNewSubscription,
  onCurrentModalStatusChange,
  onSubscriptionInfoChange,
  onClose = () => {},
}: DowngradeModalProps) => {
  const classes = useStyles();
  const initialValues = {
    confirmDowngrade: false,
  };

  const dialogContent = useMemo(
    () => (
      <Grid className={classes.container} container spacing={1}>
        <Grid className={classes.text} item xs={12}>
          By downgrading to the Basic (FREE) version of NamesList. You will no
          longer be charge for using the service. However, you will lose access
          to premium features including your phone numbers, ability to text or
          call. All messages associated with your numbers will also no longer be
          accessible.
        </Grid>
        <Grid className={classes.text} item xs={12}>
          If you want to keep leveraging those features, click “Cancel.” or
          upgrade to a paid version of NamesList.
        </Grid>
      </Grid>
    ),
    [classes.container, classes.text]
  );

  const validateForm = useCallback(values => validate(values, SCHEMA), []);

  const handleSubmitForm = useCallback(() => {
    onSubscriptionInfoChange(data => ({ ...data, confirmDowngrade: true }));
    onCurrentModalStatusChange(
      isItNewSubscription
        ? ManageSubscriptionStatus.EnterPaymentMethod
        : ManageSubscriptionStatus.ConfirmOrder
    );
  }, [
    isItNewSubscription,
    onSubscriptionInfoChange,
    onCurrentModalStatusChange,
  ]);

  const handleClose = useCallback(() => {
    onCurrentModalStatusChange(ManageSubscriptionStatus.Closed);
    onClose();
  }, [onCurrentModalStatusChange, onClose]);

  return (
    <Form
      id={FORM}
      validate={validateForm}
      onSubmit={handleSubmitForm}
      initialValues={initialValues}
      initialValuesEqual={isEqual}
      render={({ handleSubmit, form: { restart } }) => (
        <DazzlingDialog
          acceptLabel='Confirm'
          formName={FORM}
          handleClose={handleClose}
          headerProps={{
            icon: (props: any) => (
              <WarningIcon className={classes.warningIcon} {...(props || {})} />
            ),
            title: 'Are you sure you want to downgrade?',
            alignItems: 'center',
          }}
          id={`${FORM}Dialog`}
          onAccept={handleSubmit}
          open={open}
          children={dialogContent}
          resetForm={restart}
          isBackgroundWhite
          isButtonOnTheRightCorner
        />
      )}
    />
  );
};

const useStyles = makeStyles(theme => ({
  container: {
    marginBottom: 10,
  },
  text: {
    fontSize: '0.95rem',
    fontWeight: 500,
    lineHeight: '1.5em',
    color: theme.palette.background.paper,
  },
  warningIcon: {
    color: theme.palette.error.main,
  },
}));

export default DowngradeModal;
