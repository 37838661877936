import { all, call, fork, takeEvery, put, select } from 'redux-saga/effects';

// action creators
import { doneIndicator } from 'store/actions/httpActions';

// api
import client from 'sources/api';

// selectors
import getCurrentGroupId from 'store/selectors/getCurrentGroupId';

// constants
import { TAG_UPDATE_REQUEST, TAG_UPDATE } from 'store/constants/tagTypes';

// lib
import httpSaga from 'store/sagas/httpSaga';

// schemas
import { tagSchema } from 'store/schemas/tagSchema';

const WATCH_TYPE = TAG_UPDATE_REQUEST;
const TYPE = TAG_UPDATE;

export function* updateTag(action: any) {
  const {
    payload: {
      formName,
      id,
      fields: { title, color, description },
    },
  } = action;

  const currentGroupId = yield select(getCurrentGroupId);

  // @ts-expect-error ts-migrate(2569) FIXME: Type 'Generator<any, any, unknown>' is not an arra... Remove this comment to see the full error message
  yield* httpSaga(
    TYPE,
    call(client.put, `/tags/${id}?group_id=${currentGroupId}`, {
      title,
      color,
      description,
      visibility: [],
      type: 'label',
    }),
    {
      formName,
      shouldDestroyForm: false,
      schema: tagSchema,
    }
  );

  yield put(doneIndicator(TYPE));
}

export function* watch() {
  yield takeEvery(WATCH_TYPE, updateTag);
}

export default function* root() {
  yield all([fork(watch)]);
}
