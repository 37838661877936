import { Map } from 'immutable';
// constants
import { EMAIL_FETCH } from 'store/constants/emailTypes';
// helpers
import httpReducer from './helpers/httpReducer';

export default function reduceEmail(state = Map(), action = {}) {
  switch ((action as any).type) {
    case EMAIL_FETCH:
      return httpReducer(state, action, {
        entity: 'email',
      });
    default:
      return state;
  }
}
