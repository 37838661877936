import { all, call, fork, put, takeEvery, select } from 'redux-saga/effects';

// action creators
import { doneIndicator } from 'store/actions/httpActions';

// selector
import getCurrentGroupId from 'store/selectors/getCurrentGroupId';

// api
import client from 'sources/api';

// constants
import { NOTE_FETCH_REQUEST, NOTE_FETCH } from 'store/constants/noteTypes';

// schemas
import { noteSchema } from 'store/schemas/noteSchema';

// helpers
import httpSaga from 'store/sagas/httpSaga';

const WATCH_TYPE = NOTE_FETCH_REQUEST;
const TYPE = NOTE_FETCH;

export function* fetchNoteSaga(action: any) {
  const {
    payload: { id },
  } = action;
  const currentGroupId = yield select(getCurrentGroupId);
  // @ts-expect-error ts-migrate(2569) FIXME: Type 'Generator<any, any, unknown>' is not an arra... Remove this comment to see the full error message
  yield* httpSaga(
    TYPE,
    call(client.get, `/notes/${id}?group_id=${currentGroupId}`),
    {
      schema: noteSchema,
      metaId: id,
    }
  );
  yield put(doneIndicator(TYPE));
}

export function* watch() {
  yield takeEvery(WATCH_TYPE, fetchNoteSaga);
}

export default function* root() {
  yield all([fork(watch)]);
}
