import React from 'react';

//helpers
import { toLowerCase } from 'helpers/transform';

export interface MultiSelectLabelProps {
  label: string;
  noteClasses: string;
  isCustomFieldTypeOfOrganizationForUniversalForm?: boolean;
}

const MultiSelectLabel = ({
  noteClasses,
  label,
  isCustomFieldTypeOfOrganizationForUniversalForm,
}: MultiSelectLabelProps) => {
  if (isCustomFieldTypeOfOrganizationForUniversalForm) {
    const labelsForOrganization: { [key: string]: React.ReactNode } = {
      men: (
        <>
          Men
          <div className={noteClasses}>Single-Gender Male identifying</div>
        </>
      ),
      women: (
        <>
          Women
          <div className={noteClasses}>
            Single-Gender Female identifying
          </div>{' '}
        </>
      ),
      all: (
        <>
          CoEd/All Genders
          <div className={noteClasses}>
            Organizations open to all genders and identities
          </div>
        </>
      ),
    };

    return <>{labelsForOrganization?.[toLowerCase(label)] || label}</>;
  }

  return <>{label}</>;
};

export default MultiSelectLabel;
