import { Map } from 'immutable';
// constants
import {
  MEMBER_FETCH_FOR_GROUP,
  MEMBER_CLEAR,
  MEMBER_UPDATE,
  MEMBER_DELETE,
  MEMBER_DELETE_ALL_FOR_GROUP,
} from 'store/constants/memberTypes';
import { INVITE_CREATE } from 'store/constants/inviteTypes';
// helpers
import httpReducer from './helpers/httpReducer';
import removeByAccountId from './callbacks/removeByAccountId';
import addAccountByStatus from './callbacks/addAccountByStatus';
const ACCEPTED = 1;
export default function reduceMember(state = Map(), action = {}) {
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'payload' does not exist on type '{}'.
  const { payload = Map() } = action;
  switch ((action as any).type) {
    case MEMBER_FETCH_FOR_GROUP:
      return httpReducer(state, action, {
        appendResult: payload.get('appendResult', false),
      });
    case MEMBER_UPDATE:
      return httpReducer(state, action, { updateResult: false });
    case MEMBER_DELETE:
      return httpReducer(state, action, { callback: removeByAccountId });
    case MEMBER_CLEAR:
      return Map();
    case MEMBER_DELETE_ALL_FOR_GROUP:
      return Map();
    case INVITE_CREATE:
      return addAccountByStatus(state, action, { status: ACCEPTED });
    default:
      return state;
  }
}
