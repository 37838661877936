import React from 'react';
import PropTypes from 'prop-types';

// material-ui
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

const ListCardItem = function({ children }: any) {
  const classes = useStyles();

  return (
    <Grid container className={classes.listItem} alignItems='center'>
      {children}
    </Grid>
  );
};

ListCardItem.propTypes = {
  children: PropTypes.node.isRequired,
};

const useStyles = makeStyles({
  listItem: {
    borderTop: '1px solid #1E2E39',
    padding: '10px 0',
    minHeight: 60,
  },
});

export default ListCardItem;
