import React, { useMemo, useState, useCallback } from 'react';
import { isEqual } from 'lodash';
import { roles } from 'helpers/getRoles';

// hooks
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useLeadQuery } from 'api/leads';

// material-ui
import {
  Typography,
  Box,
  Grid,
  Card,
  CardContent,
  Hidden,
  useMediaQuery,
} from '@material-ui/core';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import { Skeleton } from '@material-ui/lab';
import { NewReleases } from '@material-ui/icons';

// components
import Banner from 'components/Banner';
import SuperAvatar from 'components/SuperAvatar';
import WriteNoteButton from 'components/WriteNoteButton';
import LeadStatus from 'components/LeadStatus';
import LeadDetails from 'components/LeadDetails';
import PhoneItem from 'components/PhoneItem';
import EmailItem from 'components/EmailItem';
import EndorseButton from 'components/EndorseButton';
import LeadActionsButton from './LeadActionsButton';
import DeletedLeadCard from './DeletedLeadCard';
import LeadTabs from './LeadTabs';

// selectors
import getLoadingFromState from 'store/selectors/getLoadingFromState';
import getPermission from 'store/selectors/getPermission';
import getIsSystemAdmin from 'store/selectors/getIsSystemAdmin';

interface LeadProfileViewProps {
  isCouncilLeadPage?: boolean | null;
}

export default function LeadProfileView({
  isCouncilLeadPage,
}: LeadProfileViewProps) {
  const classes = useStyles();
  const history = useHistory();
  const [statusLoading, setStatusLoading] = useState(false);
  const toggleStatusLoading = useCallback(
    loading => setStatusLoading(loading),
    [setStatusLoading]
  );
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'leadId' does not exist on type '{}'.
  const { leadId } = useParams();
  const { data: lead, isLoading } = useLeadQuery(Number(leadId));
  const {
    currentUserLoading,
    canUserViewDeletedLead,
    isSystemAdmin,
    canGetLeadAttributes,
    canGetLeadTags,
  } = useSelector(
    state => ({
      canCreateNote: getPermission('note.createMany')(state),
      canGetLeadAttributes: getPermission('pnmAttr.getMany')(state),
      canGetLeadTags: getPermission('pnmTag.getMany')(state),
      canUserViewDeletedLead: [roles.admin, roles.leader].includes(
        (state as any).getIn(['currentUser', 'data', 'roleId'], roles.member)
      ),
      currentUserLoading: getLoadingFromState('currentUser')(state),
      fileLoading: getLoadingFromState('file', false, false)(state),
      isSystemAdmin: getIsSystemAdmin(state),
    }),
    isEqual
  );

  const theme = useTheme();
  const canViewDeletedLead = canUserViewDeletedLead || isSystemAdmin;
  const isExtraSmall = useMediaQuery(theme.breakpoints.only('xs'));
  const isMediumAndDown = useMediaQuery(theme.breakpoints.down('md'));
  const isDeleted = !isLoading && !lead;
  const [avatar] = useComponents(
    classes,
    isLoading,
    lead,
    statusLoading,
    toggleStatusLoading,
    isExtraSmall
  );

  const infoAlignment = useMemo(() => {
    if (isExtraSmall) {
      return 'center';
    } else if (isMediumAndDown) {
      return 'initial';
    } else {
      return 'initial';
    }
  }, [isExtraSmall, isMediumAndDown]);

  const navigateToDuplicatePage = useCallback(() => {
    history.push({
      pathname: `/leads/duplicates/${lead?.pnmDuplicateId}`,
    });
  }, [history, lead]);

  return (
    <Grid
      container
      spacing={2}
      justifyContent='center'
      className={classes.leadProfileContainer}
    >
      {lead?.pnmDuplicateId && (
        <Grid item xs={12}>
          <Banner
            buttonLabel='Manage Duplicates'
            header='Let’s clean those up!'
            subtitle={`We’ve found duplicate leads for ${lead.fullName}.`}
            icon={<NewReleases />}
            onButtonClick={navigateToDuplicatePage}
          />
        </Grid>
      )}
      <Grid item xs={12}>
        {isDeleted ? (
          <DeletedLeadCard canViewDeletedLead={canViewDeletedLead} />
        ) : (
          <Card className={classes.card}>
            <CardContent classes={{ root: classes.root }}>
              <Hidden smUp>
                <Grid
                  container
                  justifyContent='center'
                  className={classes.mobileAvatar}
                >
                  <Grid item>{avatar}</Grid>
                </Grid>
              </Hidden>

              <Grid container wrap='nowrap' spacing={3}>
                <Hidden xsDown>
                  <Grid item>
                    <Grid container direction='column'>
                      <Grid item>{avatar}</Grid>
                      <Grid item>
                        <WriteNoteButton leadId={lead?.id || 0} />
                      </Grid>
                      {!isCouncilLeadPage && (
                        <Grid item>
                          <EndorseButton />
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </Hidden>

                <Grid
                  container
                  item
                  direction='column'
                  justifyContent='space-between'
                  // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
                  alignItems={infoAlignment}
                  className={classes.container}
                >
                  <Grid item xs zeroMinWidth>
                    <Grid container>
                      <Box clone height={42}>
                        <Grid item xs={12} zeroMinWidth>
                          <Typography
                            className={classes.leadProfileName}
                            noWrap
                          >
                            {lead?.fullName}
                          </Typography>
                        </Grid>
                      </Box>
                      {lead && (
                        <Grid
                          item
                          xs={12}
                          md={10}
                          lg={8}
                          xl={7}
                          className={classes.outlinedContainer}
                        >
                          <LeadStatus
                            lead={lead}
                            variant='label'
                            size={isExtraSmall ? 'small' : 'large'}
                            onLoadingChange={() => {}}
                          />
                          <EmailItem
                            lead={lead}
                            isCouncilLeadPage={isCouncilLeadPage}
                          />
                          <PhoneItem
                            isCouncilLeadPage={!!isCouncilLeadPage}
                            lead={lead}
                          />
                        </Grid>
                      )}
                    </Grid>

                    <LeadDetails leadId={lead?.id} />
                  </Grid>
                </Grid>

                <Hidden xsDown>
                  <LeadActionsButton
                    isCouncilLeadPage={isCouncilLeadPage}
                    loading={isLoading}
                    lead={lead}
                  />
                </Hidden>
              </Grid>

              <Hidden smUp>
                <Grid container justifyContent='center'>
                  <Grid item>
                    <LeadActionsButton
                      isCouncilLeadPage={isCouncilLeadPage}
                      loading={isLoading}
                      lead={lead}
                    />
                  </Grid>
                </Grid>
              </Hidden>
            </CardContent>
          </Card>
        )}
      </Grid>
      {(!isDeleted || (isDeleted && canViewDeletedLead)) && (
        <Grid item xs={12}>
          <LeadTabs
            leadId={parseInt(leadId)}
            hideInfo={
              isDeleted ||
              (!isLoading &&
                !currentUserLoading &&
                !canGetLeadAttributes &&
                !canGetLeadTags)
            }
            hideTasks={isDeleted}
            isCouncilLeadPage={isCouncilLeadPage}
          />
        </Grid>
      )}
    </Grid>
  );
}

const useComponents = (
  classes: any,
  leadLoading: any,
  lead: any,
  statusLoading: any,
  toggleStatusLoading: any,
  isExtraSmall: any
) =>
  useMemo(
    () =>
      !statusLoading && leadLoading
        ? [
            <Skeleton
              className={classes.avatarSkeleton}
              variant='rect'
              width={isExtraSmall ? 125 : 205}
              height={isExtraSmall ? 125 : 205}
            />,
            <Skeleton height={44} width={180} />,
            <Skeleton
              height={128}
              width={250}
              variant='rect'
              style={{ marginBottom: 10, borderRadius: 4 }}
            />,
          ]
        : [
            <SuperAvatar
              fullName={lead?.fullName}
              imageUrl={lead?.profilePhotoFileUrl}
              size={isExtraSmall ? '2xlarge' : 'huge'}
              variant='roundedSquare'
              background='dark'
            />,
          ],
    [statusLoading, leadLoading, classes.avatarSkeleton, isExtraSmall, lead]
  );

const useStyles = makeStyles(theme => ({
  leadProfileName: {
    fontSize: 20,
    [theme.breakpoints.only('xs')]: {
      fontSize: 18,
      textAlign: 'center',
    },
    fontWeight: 500,
  },
  leadProfileContainer: {
    paddingTop: 24,
    paddingBottom: 24,
    [theme.breakpoints.only('sm')]: {
      padding: 8,
    },
    [theme.breakpoints.only('xs')]: {
      padding: 4,
    },
  },
  root: {
    [theme.breakpoints.only('xs')]: {
      '&:last-child': {
        paddingBottom: 0,
      },
    },
  },
  container: {
    [theme.breakpoints.only('md')]: {
      marginRight: '5%',
    },
    [theme.breakpoints.only('lg')]: {
      marginRight: '5%',
    },
    [theme.breakpoints.only('xl')]: {
      marginRight: '10%',
    },
  },
  avatarSkeleton: {
    borderRadius: 8,
  },
  mobileAvatar: {
    marginBottom: 15,
  },
  outlinedContainer: {
    border: `1px solid ${(theme.palette.background as any).outline}`,
    borderRadius: 4,
    marginBottom: 15,
  },
  card: {
    [theme.breakpoints.down('sm')]: {
      marginTop: 10,
      marginLeft: 10,
      marginRight: 10,
    },
  },
}));
