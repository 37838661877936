import queryString from 'query-string';
import Immutable from 'immutable';
import { all, call, fork, put, takeEvery, select } from 'redux-saga/effects';

// action creators
import { doneIndicator, success } from 'store/actions/httpActions';

// api
import client from 'sources/api';

// selectors
import getCurrentGroupId from 'store/selectors/getCurrentGroupId';

// constants
import { VOTE_DELETE_REQUEST, VOTE_DELETE } from 'store/constants/voteTypes';

// schemas
import { voteSchema } from 'store/schemas/voteSchema';

// helpers
import httpSaga from 'store/sagas/httpSaga';
import { fetchCurrentGroup } from 'store/actions/groupActions';

const WATCH_TYPE = VOTE_DELETE_REQUEST;
const TYPE = VOTE_DELETE;

const FILTERS_MAP = {
  isReported: 'is_reported',
  statusIds: 'status_id',
  tagIds: 'tag_id',
  createdOnStart: 'created_on_start',
  createdOnEnd: 'created_on_end',
  updatedOnStart: 'updated_on_start',
  updatedOnEnd: 'updated_on_end',
  lastContactedOnStart: 'last_contacted_on_start',
  lastContactedOnEnd: 'last_contacted_on_end',
  mainContactId: 'main_contact_id',
  referralSourceId: 'referral_source_id',
};

export function* deleteVotes(action: any) {
  const {
    payload: { allSelected, leadIds, filters, metaId },
  } = action;

  const leadFilters = {};
  if (allSelected) {
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    leadFilters.excluded_lead_id = leadIds;
  }
  for (const filter in filters) {
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    leadFilters[FILTERS_MAP[filter]] = filters[filter];
  }

  const currentGroupId = yield select(getCurrentGroupId);

  // @ts-expect-error ts-migrate(2569) FIXME: Type 'Generator<any, any, unknown>' is not an arra... Remove this comment to see the full error message
  yield* httpSaga(
    TYPE,
    call(
      client.delete,
      `/votes?` +
        queryString.stringify({
          group_id: currentGroupId,
          lead_id: allSelected ? 'all' : leadIds,
          ...leadFilters,
        })
    ),
    {
      dispatchSuccess: false,
      schema: [voteSchema],
      metaId,
    }
  );

  yield put(
    success(
      TYPE,
      Immutable.fromJS(
        leadIds.map((id: any) => ({
          id,
        }))
      ),
      metaId
    )
  );
  yield put(doneIndicator(TYPE));
  yield put(fetchCurrentGroup({ id: currentGroupId }));
}

export function* watch() {
  yield takeEvery(WATCH_TYPE, deleteVotes);
}

export default function* root() {
  yield all([fork(watch)]);
}
