import React from 'react';

// material-ui
import Grid from '@material-ui/core/Grid';
import { WithStyles, withStyles } from '@material-ui/core/styles';

// components
import FileItem from 'components/CreateEmailModal/components/FileItem';

//helpers
import { FileFields } from 'components/CreateEmailModal/helpers/interfaces';

//styles
import filesListStyles from 'components/CreateEmailModal/components/FilesList/filesList.style';

export interface FilesListProps extends WithStyles<typeof filesListStyles> {
  loading: boolean;
  removeFile: (id: number) => void;
  files?: FileFields[];
}

const FilesList = ({ classes, loading, removeFile, files }: FilesListProps) => (
  <>
    <Grid item xs={12} className={classes.fileTitle}>
      Attach documents or images:
    </Grid>
    {files &&
      files.map(file => (
        <Grid key={file.newFileId} item xs={12}>
          <FileItem loading={loading} file={file} removeFile={removeFile} />
        </Grid>
      ))}
  </>
);

export default withStyles(filesListStyles)(FilesList);
