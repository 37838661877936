import GroupsAutoSuggest from './GroupsAutoSuggest';
import { connect } from 'react-redux';

// action creators
import { fetchGroups } from 'store/actions/groupActions';

// selectors
import getLoadingFromState from 'store/selectors/getLoadingFromState';
import getOrganizations from 'store/selectors/getOrganizations';
import getCouncils from 'store/selectors/getCouncils';
import getChapters from 'store/selectors/getChapters';

const mapState = (state: any, props: any) => {
  return {
    councils: getCouncils(state, 'suggestions'),
    chapters: getChapters(state, 'suggestions'),
    chapterLoading: getLoadingFromState('group', 'greekChapter', false)(state),
    organizationLoading: getLoadingFromState(
      'group',
      'greekOrganization',
      false
    )(state),
    councilLoading: getLoadingFromState('group', 'council', false)(state),
    organizations: getOrganizations(state, 'suggestions'),
  };
};

const mapDispatch = {
  fetchGroups,
};

export default connect(mapState, mapDispatch)(GroupsAutoSuggest);
