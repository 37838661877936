/*
 *  This handles updateing an item from a slice when given a
 *  response.
 */
export default function update(map: any, action: any, options = {}) {
  const { payload } = action;
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'entity' does not exist on type '{}'.
  const { entity, result: resultKey = 'result' } = options;

  let newState = map;

  const newEntity = entity || payload.get('entity');
  const id = payload.getIn(['data', resultKey]);
  if (id) {
    const updatedItem = payload.getIn([
      'data',
      'entities',
      newEntity,
      id.toString(),
    ]);
    newState = newState.setIn(['data', 'byId', id.toString()], updatedItem);
  }
  return newState;
}
