import Immutable from 'immutable';
import { denormalize } from 'normalizr';

import { suggestedFieldSchema } from '../schemas/suggestedFieldSchema';
import createDeepEqualSelector from 'helpers/createDeepEqualSelector';
import getSliceItems from './getSliceItems';
import getSliceResult from './getSliceResult';

export default function getSuggestedFields(format?: any) {
  return createDeepEqualSelector(
    [getSliceResult('suggestedField'), getSliceItems('suggestedField')],
    (result: any, suggestedFields: any) => {
      const entities = {
        suggestedField: suggestedFields.toJS(),
      };

      let items = denormalize(result.toJS(), [suggestedFieldSchema], entities);

      items.forEach((item: any) => {
        item.sources = ['SuggestedField'];
      });

      if (format === 'suggestions') {
        items = items.map((item: any) => ({
          label: item.label,
          value: item.id,
        }));
      }

      return Immutable.fromJS(items);
    }
  );
}
