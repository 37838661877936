import React, { useContext, useMemo } from 'react';

// helpers
import getQuotes from 'components/BlockQuote/helpers/getQuotes';
import getRandomNumberInRange from 'helpers/getRandomNumberInRange';

// components
import BlockQuote from 'components/BlockQuote';

// material-ui
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

// helpers
import { SiteVisualDataContext } from 'components/SiteVisualData';

export default function ErrorFallback({
  variant = 'light',
}: {
  variant?: 'dark' | 'light' | null;
}) {
  const classes = useStyles();
  const { supportEmail } = useContext(SiteVisualDataContext);

  const quotes = useMemo(() => getQuotes(), []);
  const quote = useMemo(
    () => quotes[getRandomNumberInRange(0, quotes.length)],
    [quotes]
  );

  return (
    <Grid container spacing={3} className={classes.container}>
      <Grid item xs={12}>
        <Typography variant='h6'>Something went wrong.</Typography>

        <Typography variant='body2' color='textSecondary' gutterBottom>
          If refreshing the page or logging out and back in doesn't resolve the
          issue, contact us at{' '}
          <a
            href={supportEmail}
            className={
              variant === 'light' ? classes.lightLink : classes.darkLink
            }
          >
            {supportEmail}
          </a>
        </Typography>
      </Grid>

      <Grid
        item
        xs={12}
        sm={10}
        md={8}
        xl={6}
        className={classes.quoteContainer}
      >
        <BlockQuote
          id='blockQuote'
          content={quote.content}
          author={quote.author}
          subtitle={quote.subtitle}
        />
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles(theme => {
  return {
    container: {
      margin: 0,
      width: 'fill-available',
      paddingTop: 24,
      [(theme as any).breakpoints.down('sm')]: {
        padding: 32,
      },
    },
    quoteContainer: {
      marginLeft: 8,
    },
    lightLink: {
      color: (theme as any).palette.primary.main,
    },
    darkLink: {
      color: (theme as any).palette.background.default,
    },
  };
});
