import React, { useMemo, useCallback, useContext } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useDeleteLeadMutation, useLeadQuery } from 'api/leads';

// material-ui
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

// icons
import DeleteOutlined from '@material-ui/icons/DeleteOutlined';

//components
import DazzlingDialog from 'components/DazzlingDialog';
import { Form } from 'react-final-form';

// helpers
import { SiteVisualDataContext } from 'components/SiteVisualData';

type DeleteLeadModalProps = {
  onClose: (...args: any[]) => any;
  open: boolean;
};

const DeleteLeadModal = ({ open, onClose }: DeleteLeadModalProps) => {
  const {
    isLoading,
    isSuccess,
    isError,
    mutate: deleteLead,
  } = useDeleteLeadMutation();
  const { deleteLeadModalSubtitle } = useContext(SiteVisualDataContext);
  const history = useHistory();
  const { leadId } = useParams<{ leadId: string }>();
  const { data: lead } = useLeadQuery(Number(leadId));
  const handleDelete = useCallback(() => {
    deleteLead(Number(leadId));
  }, [leadId, deleteLead]);

  const dialogData = {
    icon: DeleteOutlined,
    title: 'Delete Lead',
    subtitle: deleteLeadModalSubtitle,
  };

  const DialogContent = useMemo(
    () => (
      <Grid container>
        <Grid item xs={12} id='warningLabel'>
          <Typography variant='subtitle2' color='secondary'>
            Are you sure you want to delete the lead
            {lead?.fullName && ` of ${lead?.fullName}`}?
          </Typography>
        </Grid>
      </Grid>
    ),
    [lead]
  );

  const handleExited = useCallback(() => {
    if (isSuccess) history.push({ pathname: '/leads' });
  }, [history, isSuccess]);

  return (
    <Form
      id='deleteForm'
      onSubmit={handleDelete}
      render={({ handleSubmit }) => (
        <DazzlingDialog
          id='deleteLead'
          alertProps={{ message: 'Lead Deleted Successfully' }}
          acceptLabel='Delete'
          children={DialogContent}
          handleClose={onClose}
          headerProps={dialogData}
          onAccept={handleSubmit}
          onExited={handleExited}
          open={open}
          isLoading={isLoading}
          isSuccess={isSuccess}
          isError={isError}
        />
      )}
    />
  );
};

export default DeleteLeadModal;
