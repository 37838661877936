import React, { useState, useCallback } from 'react';
import classNames from 'classnames';

// hooks
import { useSelector, shallowEqual } from 'react-redux';

// material-ui
import { Grid, Typography, ButtonBase } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

// icons
import { NoteAdd, AccountBox, ZoomIn } from '@material-ui/icons';

// components
import LeadQuickViewModal from 'components/LeadQuickViewModal';
import CreateNoteTagModal from 'components/CreateNoteTagModal';
import MixedLink from './MixedLink';

// selectors
import getPermission from 'store/selectors/getPermission';

// types
import { Location } from 'history';

type CardButtonGroupProps = {
  loading?: boolean;
  lead: Lead;
  navigateToProfileParams: Location;
};

export default function CardButtonGroup({
  loading = false,
  lead,
  navigateToProfileParams,
}: CardButtonGroupProps) {
  const classes = useStyles();
  const [quickViewModalOpen, setQuickViewModalOpen] = useState(false);
  const [createNoteModalOpen, setCreateNoteModalOpen] = useState(false);
  const { canCreateNote } = useSelector(
    state => ({
      canCreateNote: getPermission('note.createMany')(state),
    }),
    shallowEqual
  );

  // Modal handlers
  const closeCreateNoteModal = useCallback(() => {
    setCreateNoteModalOpen(false);
  }, []);
  const openCreateNoteModal = useCallback(() => {
    setCreateNoteModalOpen(true);
  }, []);

  const closeQuickViewModal = useCallback(() => {
    setQuickViewModalOpen(false);
  }, []);
  const openQuickViewModal = useCallback(() => {
    setQuickViewModalOpen(true);
  }, []);

  return (
    <Grid
      container
      id='buttonGroup'
      alignItems='center'
      className={classes.buttonGroup}
    >
      <ButtonBase
        id='profile'
        component={Grid}
        item
        xs
        className={classes.button}
        disabled={loading}
      >
        <MixedLink
          id='profileLink'
          to={navigateToProfileParams}
          className={classes.link}
        >
          <AccountBox />
          <Typography className={classes.buttonText} variant='subtitle2'>
            Profile
          </Typography>
        </MixedLink>
      </ButtonBase>

      {canCreateNote && (
        <ButtonBase
          id='note'
          component={Grid}
          item
          xs
          className={classNames(classes.button, classes.middleButton)}
          onClick={openCreateNoteModal}
          disabled={loading}
        >
          <NoteAdd />
          <Typography className={classes.buttonText} variant='subtitle2'>
            Add Note
          </Typography>
        </ButtonBase>
      )}

      <ButtonBase
        id='quickView'
        component={Grid}
        item
        xs
        className={classes.button}
        onClick={openQuickViewModal}
        disabled={loading}
      >
        <ZoomIn />
        <Typography className={classes.buttonText} variant='subtitle2'>
          Quick View
        </Typography>
      </ButtonBase>

      <CreateNoteTagModal
        open={createNoteModalOpen}
        leadIds={[lead.id]}
        onClose={closeCreateNoteModal}
        shouldFetchTags
      />
      <LeadQuickViewModal
        lead={lead}
        open={quickViewModalOpen}
        onClose={closeQuickViewModal}
      />
    </Grid>
  );
}

const useStyles = makeStyles(theme => {
  const border = `1px solid rgba(255,255,255,0.23)`;
  return {
    buttonGroup: {
      borderTop: border,
      height: 48,
      marginTop: 20,
    },
    button: {
      height: 'inherit',
      color: 'rgba(255,255,255,0.5)',
      transition: 'all ease 0.3s',
      '&:hover': {
        backgroundColor: (theme.palette.background as any).lightPaper,
        color: theme.palette.common.white,
        transition: 'all ease 0.3s',
      },
    },
    link: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: 'rgba(255,255,255,0.5)',
      width: '100%',
      height: '100%',
    },
    middleButton: {
      borderLeft: border,
      borderRight: border,
    },
    buttonText: {
      color: 'inherit',
      marginLeft: 16,
    },
  };
});
