import React, { useState, useEffect, useCallback } from 'react';
import moment from 'moment';
import { isEqual } from 'lodash';
import { roles } from 'helpers/getRoles';

// hooks
import { useParams } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { useLeadQuery } from 'api/leads';

// styles
import { makeStyles } from '@material-ui/core/styles';

// icons
import { ThumbsUpDown, ThumbDown, ThumbUp, Event } from '@material-ui/icons';

// components
import {
  Table,
  TableBody,
  TableFooter,
  TableRow,
  TableCell,
  TablePagination,
  Typography,
  Grid,
  Box,
  IconButton,
  Tooltip,
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import EmptyMessage from 'components/EmptyMessage';

// selectors
import getCurrentGroup from 'store/selectors/getCurrentGroup';
import getCurrentUser from 'store/selectors/getCurrentUser';
import getSliceState from 'store/selectors/getSliceState';
import getSliceEntityById from 'store/selectors/getSliceEntityById';

// actions
import { fetchVotesAction } from 'store/actions/voteActions';

const ROWS_PER_PAGE = 10;

export default function CultureFitEndorsements() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { leadId } = useParams<{ leadId: string }>();
  const [currentPage, setCurrentPage] = useState(0);
  const currentGroup = useSelector(getCurrentGroup);
  const currentUser = useSelector(getCurrentUser);
  const isAllVotesVisible =
    (currentUser as any).get('roleId') === roles.admin ||
    (currentGroup as any).get('voteVisibility') === 'all';
  const currentVoteMethod = useSelector(
    getSliceEntityById('voteMethod', (currentGroup as any).get('voteMethodId'))
  );
  const { data: lead, isLoading: leadIsLoading } = useLeadQuery(Number(leadId));
  const vote = useSelector(getSliceState('vote', null, false), isEqual);
  const leadVotes = vote.data.filter(
    (vote: any) => vote.leadId.toString() === leadId
  );
  const handleChangePage = useCallback(
    (_, newPage) => setCurrentPage(newPage),
    []
  );
  useEffect(() => {
    if (
      isAllVotesVisible &&
      leadVotes.length < (currentPage + 1) * ROWS_PER_PAGE
    ) {
      dispatch(fetchVotesAction({ leadId, page: currentPage + 1 }));
    }
  }, [isAllVotesVisible, dispatch, leadId, currentPage, leadVotes.length]);
  return (
    <Grid container className={classes.container}>
      <Grid item xs={12}>
        <Grid container alignItems='center'>
          <Grid item xs>
            <Typography className={classes.header}>
              Culture Fit Endorsements
            </Typography>
          </Grid>
          {isAllVotesVisible && leadVotes.length !== 0 && (
            <Grid item>
              {vote.loading || leadIsLoading ? (
                <Box marginRight={2}>
                  <Skeleton height={40} width={100} />
                </Box>
              ) : (currentVoteMethod as any).scoreAlgorithm === 'sum' ? (
                <Grid container justifyContent='center' alignItems='center'>
                  <Grid item>
                    <Grid container alignItems='center'>
                      <Grid item>
                        <IconButton disabled size='small'>
                          <ThumbUp />
                        </IconButton>
                      </Grid>
                      <Grid item>
                        <Grid
                          container
                          justifyContent='center'
                          alignItems='center'
                          className={classes.badge}
                        >
                          {(vote.total + (lead as any).voteScore) / 2}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid container alignItems='center'>
                      <Grid item>
                        <IconButton disabled size='small'>
                          <ThumbDown />
                        </IconButton>
                      </Grid>
                      <Grid item>
                        <Grid
                          container
                          justifyContent='center'
                          alignItems='center'
                          className={classes.badge}
                        >
                          {(vote.total - (lead as any).voteScore) / 2}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              ) : (
                <Grid container alignItems='center'>
                  <Grid item>
                    <IconButton disabled size='small'>
                      <ThumbsUpDown />
                    </IconButton>
                  </Grid>
                  <Grid item>
                    <Tooltip title='Average Endorsement'>
                      <Typography
                        variant='h6'
                        display='block'
                        className={classes.meanScore}
                      >
                        {(lead as any).voteScore}
                      </Typography>
                    </Tooltip>
                  </Grid>
                </Grid>
              )}
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Table className={(classes as any).table}>
          <TableBody>
            {vote.loading ? (
              Array.from({ length: 5 }, (_, i) => (
                <TableRow key={i}>
                  <TableCell className={classes.tableCell}>
                    <Skeleton height={40} width='100%' />
                  </TableCell>
                </TableRow>
              ))
            ) : leadVotes.length === 0 ? (
              <TableRow>
                <TableCell className={classes.tableCell}>
                  <EmptyMessage
                    title={'No endorsements found'}
                    description={'Evaluate culture fit of the lead'}
                    descriptionColor='secondary'
                  />
                </TableCell>
              </TableRow>
            ) : (
              leadVotes
                // @ts-expect-error ts-migrate(2362) FIXME: The left-hand side of an arithmetic operation must... Remove this comment to see the full error message
                .sort((a: any, b: any) => moment(a) - moment(b))
                .slice(
                  currentPage * ROWS_PER_PAGE,
                  currentPage * ROWS_PER_PAGE + ROWS_PER_PAGE
                )
                .map((vote: any) => (
                  <TableRow key={vote.id}>
                    <TableCell
                      size='small'
                      align='center'
                      className={classes.tableCell}
                    >
                      {(currentVoteMethod as any).scoreAlgorithm === 'sum' ? (
                        vote.value === 1 ? (
                          <ThumbUp />
                        ) : (
                          <ThumbDown />
                        )
                      ) : (
                        <Typography variant='h4'>{vote.value}</Typography>
                      )}
                    </TableCell>
                    <TableCell colSpan={3} className={classes.tableCell}>
                      <Grid container direction='column' spacing={1}>
                        {vote.comment && (
                          <Grid item>
                            <Typography variant='body2'>
                              {vote.comment}
                            </Typography>
                          </Grid>
                        )}
                        <Grid item>
                          <Grid container spacing={1} alignItems='center'>
                            <Box fontSize={16} clone>
                              <Event fontSize='inherit' />
                            </Box>
                            <Grid item>
                              <Typography
                                variant='caption'
                                color='textSecondary'
                              >
                                {`${moment
                                  .utc(vote.updatedOn)
                                  .local()
                                  .calendar()} - ${vote.accountFullName}`}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </TableCell>
                  </TableRow>
                ))
            )}
          </TableBody>
          {vote.total > 10 && (
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[10]}
                  colSpan={4}
                  count={vote.total}
                  rowsPerPage={10}
                  page={currentPage}
                  onPageChange={handleChangePage}
                />
              </TableRow>
            </TableFooter>
          )}
        </Table>
      </Grid>
    </Grid>
  );
}
const useStyles = makeStyles(theme => ({
  meanScore: {
    marginRight: 16,
    marginLeft: 8,
  },
  badge: {
    width: 20,
    height: 20,
    backgroundColor: theme.palette.primary.main,
    borderRadius: '50%',
    marginRight: 16,
  },
  header: { padding: 10 },
  container: {
    borderRadius: 8,
    border: `1px solid ${(theme.palette.background as any).lightPaper}`,
  },
  tableCell: {
    borderTop: `1px solid ${(theme.palette.background as any).lightPaper}`,
    borderBottom: `none`,
  },
}));
