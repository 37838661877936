import moment from 'moment';

export function getFormattedDateTime(
  dateTimeString?: string,
  momentFormat?: string
) {
  const datetime = dateTimeString && moment.utc(dateTimeString).local();
  if (datetime && momentFormat) {
    return datetime.format(momentFormat);
  }
  return dateTimeString;
}
