import { List, Map } from 'immutable';
import { denormalize } from 'normalizr';
import { labelSchema } from 'store/schemas/labelSchema';

const getLabels = (state: any): Tag[] => {
  const labels = state.get('label', Map());
  const result = labels.getIn(['data', 'result'], List());

  const entities = {
    label: state.getIn(['label', 'data', 'byId'], Map()).toJS(),
  };

  return denormalize(result.toJS(), [labelSchema], entities);
};

export default getLabels;
