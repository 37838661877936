import React from 'react';
import { Map } from 'immutable';
import { roles } from 'helpers/getRoles';

// components
import GroupAnalytics from 'routes/GroupAnalytics';
import CommunicationAnalytics from 'routes/CommunicationAnalytics';
import LeaderboardAnalytics from 'routes/LeaderboardAnalytics';
import LeadsAnalytics from 'routes/LeadsAnalytics';
import ReferralSourceAnalytics from 'routes/ReferralSourceAnalytics';
import TagsAnalytics from 'routes/TagsAnalytics';
import MilestonesAnalytics from 'routes/MilestonesAnalytics';
import NewMemberAnalytics from 'routes/NewMemberAnalytics';
import NonJoinerAnalytics from 'routes/NonJoinerAnalytics';
import RecruitmentActivityAnalytics from 'routes/RecruitmentActivityAnalytics';
import ChapterAnalytics from 'routes/ChapterAnalytics';
import LabelAnalytics from 'routes/LabelAnalytics';
import PaymentAnalytics from 'routes/PaymentAnalytics';

// icons
import {
  AccountBalance,
  Ballot,
  NotInterested,
  Group,
  ContactPhone,
  InsertChart,
  LinearScale,
  LocalOffer,
  RecentActors,
  Book,
  BubbleChart,
  MonetizationOnOutlined,
} from '@material-ui/icons';

export const getAnalyticsTabs = ({
  isSystemAdmin,
  isHqAdmin,
  isCurrentGroupCouncil,
  isCouncilAdmin,
  features = Map(),
  roleId,
  isCurrentGroupOrganization,
}: any) => {
  const userIsAdmin =
    roleId === roles.admin ||
    roleId === roles.coach ||
    isSystemAdmin ||
    isHqAdmin;
  const tagsMilestonesEnabled =
    features.getIn(['report_tags_milestones', 'enabled'], false) && userIsAdmin;

  const isShowForCouncilAdmin =
    isCurrentGroupCouncil && (isCouncilAdmin || isSystemAdmin);
  const isShowForHqAdmin =
    isCurrentGroupOrganization && (isHqAdmin || isSystemAdmin);

  return [
    {
      id: 'group',
      label: 'Overview',
      route: '/analytics/group',
      component: GroupAnalytics,
      icon: isShowForCouncilAdmin ? <InsertChart /> : <AccountBalance />,
      enabled: true,
    },
    {
      id: 'chaptersForCouncilAdmin',
      label: 'Chapters',
      route: '/analytics/chapters',
      component: () => (
        <ChapterAnalytics
          isOrganizationChapterAnalytics={isCurrentGroupOrganization}
        />
      ),
      icon: <AccountBalance />,
      enabled: isShowForCouncilAdmin || isShowForHqAdmin,
    },
    {
      id: 'leaderboard',
      label: 'Leaderboard',
      route: '/analytics/leaderboard',
      component: LeaderboardAnalytics,
      icon: <InsertChart />,
      enabled: !isCurrentGroupCouncil && !isCurrentGroupOrganization,
    },
    {
      id: 'leads',
      label: 'Leads',
      route: '/analytics/leads',
      component: LeadsAnalytics,
      icon: <RecentActors />,
      enabled:
        features.getIn(['report_leads', 'enabled'], false) &&
        userIsAdmin &&
        !isCurrentGroupCouncil &&
        !isCurrentGroupOrganization,
    },
    {
      id: 'communication',
      label: 'Communication',
      route: '/analytics/communication',
      component: () => (
        <CommunicationAnalytics
          isOrganizationCommunicationAnalytics={isCurrentGroupOrganization}
        />
      ),
      icon: <ContactPhone />,
      enabled:
        (features.getIn(['report_communication', 'enabled'], false) &&
          userIsAdmin &&
          !isCurrentGroupCouncil) ||
        isShowForHqAdmin,
    },
    {
      id: 'new-members',
      label: 'New Members',
      route: '/analytics/new-members',
      component: () => (
        <NewMemberAnalytics
          isCouncilMemberAnalytics={isCurrentGroupCouncil}
          isOrganizationMemberAnalytics={isCurrentGroupOrganization}
        />
      ),
      icon: <Group />,
      enabled:
        (features.getIn(['report_joiners', 'enabled'], false) && userIsAdmin) ||
        (isCurrentGroupCouncil && isCouncilAdmin) ||
        isShowForHqAdmin,
    },
    {
      id: 'non-joiners',
      label: 'Non-Joiners',
      route: '/analytics/non-joiners',
      component: () => (
        <NonJoinerAnalytics
          isOrganizationNonJoinerAnalytics={isCurrentGroupOrganization}
        />
      ),
      icon: <NotInterested />,
      enabled:
        (features.getIn(['report_non_joiners', 'enabled'], false) &&
          userIsAdmin &&
          !isCurrentGroupCouncil) ||
        isShowForHqAdmin,
    },
    {
      id: 'status-story',
      label: 'Status Story',
      route: '/analytics/status-story',
      component: RecruitmentActivityAnalytics,
      icon: <Book />,
      enabled:
        features.getIn(['report_joiners', 'enabled'], false) &&
        userIsAdmin &&
        !isCurrentGroupCouncil &&
        !isCurrentGroupOrganization,
    },
    {
      id: 'referralSource',
      label: 'Referral Source',
      route: '/analytics/referral-source',
      component: () => (
        <ReferralSourceAnalytics
          isCouncilReferralSourceAnalytics={isCurrentGroupCouncil}
          isOrganizationReferralSourceAnalytics={isCurrentGroupOrganization}
        />
      ),
      icon: <Ballot />,
      enabled:
        (features.getIn(['report_referrals', 'enabled'], false) &&
          userIsAdmin) ||
        (isCurrentGroupCouncil && isCouncilAdmin) ||
        isShowForHqAdmin,
    },
    {
      id: 'labels',
      label: 'Chapter Labels',
      route: '/analytics/labels',
      component: LabelAnalytics,
      icon: <LocalOffer />,
      enabled:
        (features.getIn(['report_labels', 'enabled'], false) && userIsAdmin) ||
        (isCurrentGroupCouncil && isCouncilAdmin) ||
        isShowForHqAdmin,
    },
    {
      id: 'tags',
      label: 'Lead Tags',
      route: '/analytics/tags',
      component: () => (
        <TagsAnalytics
          isCouncilTagsAnalytics={isCurrentGroupCouncil && isCouncilAdmin}
        />
      ),
      icon:
        isShowForCouncilAdmin || isHqAdmin ? <BubbleChart /> : <LocalOffer />,
      enabled:
        tagsMilestonesEnabled || (isCurrentGroupCouncil && isCouncilAdmin),
    },
    {
      id: 'milestones',
      label: 'Milestones',
      route: '/analytics/milestones',
      component: MilestonesAnalytics,
      icon: <LinearScale />,
      enabled: tagsMilestonesEnabled && !isCurrentGroupCouncil,
    },
    {
      id: 'paymentsForCouncilAdmin',
      label: 'Payments',
      route: '/analytics/payments',
      component: PaymentAnalytics,
      icon: <MonetizationOnOutlined />,
      enabled: isShowForCouncilAdmin,
    },
  ];
};
