import { Map } from 'immutable';
// constants
import { VOTE_METHOD_FETCH } from 'store/constants/voteMethodTypes';
// helpers
import httpReducer from './helpers/httpReducer';

export default function reduceVoteMethod(state = Map(), action = {}) {
  switch ((action as any).type) {
    case VOTE_METHOD_FETCH:
      return httpReducer(state, action, { appendResult: true });
    default:
      return state;
  }
}
