import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { isEqual } from 'lodash';
import { Moment } from 'moment';
import pluralize from 'pluralize';

// MUI components
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Card, CardContent, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import {
  ContactPhone,
  Phone,
  Sms,
  Email,
  Schedule,
  Timelapse,
} from '@material-ui/icons';

// components
import StatItem from 'components/StatItem';
import AnalyticsActionBar from 'components/AnalyticsActionBar';

// action creators
import { createCommunicationOverviewReport } from 'store/actions/apiTaskActions';

// selectors
import getCurrentGroupId from 'store/selectors/getCurrentGroupId';
import getForm from 'store/selectors/getForm';
import getSliceEntityById from 'store/selectors/getSliceEntityById';
import getIsHqAdmin from 'store/selectors/getIsHqAdmin';

const REPORT_FORM = 'communicationOverviewReportForm';

const CommunicationOverviewCard = ({
  isOrganizationCommunicationAnalytics,
}: {
  isOrganizationCommunicationAnalytics?: boolean;
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const {
    currentGroupId,
    isHqAdmin,
    report: {
      data: { content = [], meta: { columns = [] } = {} } = {},
      id: reportId,
    },
    form: { isSubmitting },
  } = useSelector(
    state => ({
      currentGroupId: getCurrentGroupId(state),
      isHqAdmin: getIsHqAdmin(state),
      form: getForm(REPORT_FORM)(state),
      report: getSliceEntityById(
        'report',
        'ReportTypes.COMMUNICATION_OVERVIEW',
        {}
      )(state),
    }),
    isEqual
  );

  const dispatchCreateReport = useCallback(
    ({
      startDate,
      endDate,
      forceRefresh,
    }: {
      startDate?: Moment;
      endDate?: Moment;
      forceRefresh?: boolean;
    }) => {
      dispatch(
        createCommunicationOverviewReport({
          groupId: currentGroupId,
          formName: REPORT_FORM,
          startDate,
          endDate,
          forceRefresh,
        })
      );
    },
    [currentGroupId, dispatch]
  );

  return (
    <Card className={classes.container}>
      <CardContent className={classes.cardContent}>
        <Grid container spacing={1}>
          <AnalyticsActionBar
            onCreateReport={dispatchCreateReport}
            title='Communication Overview'
            subtitle={
              isOrganizationCommunicationAnalytics
                ? 'Filter and export text, call, and email data for your overall organization'
                : ''
            }
            icon={ContactPhone}
            isHqAdmin={isHqAdmin}
            reportId={reportId}
            loading={isSubmitting}
            isHqReport
          />

          <Grid item xs={12}>
            <Grid container spacing={4}>
              <StatGroup
                title='Overall'
                columns={columns.slice(0, 3)}
                content={content[0]}
                isSubmitting={isSubmitting}
              />
              <StatGroup
                title='Averages'
                columns={columns.slice(3, 6)}
                content={content[0]}
                isSubmitting={isSubmitting}
              />
              <StatGroup
                title='Calls'
                columns={columns.slice(6)}
                content={content[0]}
                isSubmitting={isSubmitting}
                titleSuffix='second'
              />
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

type OwnProps = {
  title?: string;
  titleSuffix?: string;
  isSubmitting: boolean;
  columns?: any; // TODO: PropTypes.instanceOf(Array)
  content?: any; // TODO: PropTypes.instanceOf(Object)
};

// @ts-expect-error ts-migrate(2456) FIXME: Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof StatGroup.defaultProps;

// @ts-expect-error ts-migrate(7022) FIXME: 'StatGroup' implicitly has type 'any' because it d... Remove this comment to see the full error message
const StatGroup = ({
  title,
  columns,
  content,
  isSubmitting,
  titleSuffix,
}: Props) => {
  const classes = useStyles();
  const skeletons = [1, 2, 3];
  return (
    <Grid item xs={12} sm={6} lg={4} xl={3}>
      <div className={classes.outlined}>
        {title && (
          <Typography className={classes.title} variant='subtitle2'>
            {title}
          </Typography>
        )}

        {isSubmitting && skeletons.map(s => <Skeleton height={60} />)}

        {!isSubmitting &&
          columns.map(({ key, label }: any) => (
            <StatItem
              key={key}
              title={`${content[key]} ${pluralize(titleSuffix, content[key])}`}
              subtitle={label}
              // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
              icon={icons[key]}
            />
          ))}
      </div>
    </Grid>
  );
};

StatGroup.defaultProps = { titleSuffix: '', columns: [], content: {} };

const icons = {
  totalCalls: <Phone />,
  totalTexts: <Sms />,
  totalEmails: <Email />,
  averageCalls: <Phone />,
  averageTexts: <Sms />,
  averageEmails: <Email />,
  totalCallDuration: <Schedule />,
  averageCallDuration: <Timelapse />,
};

const useStyles = makeStyles(theme => ({
  container: {
    minHeight: 270,
    [theme.breakpoints.down('md')]: {
      minHeight: 325,
    },
  },
  cardContent: {
    paddingLeft: 24,
    paddingRight: 24,
    marginBottom: 10,
  },
  outlined: {
    border: `1px solid ${(theme.palette.background as any).outline}`,
    borderRadius: 4,
    paddingLeft: 24,
    paddingRight: 24,
    paddingTop: 16,
    paddingBottom: 16,
  },
  title: {
    marginBottom: 12,
  },
}));

export default CommunicationOverviewCard;
