import moment from 'moment-timezone';

export const getTimeSlotLabel = ({
  startDate,
  endDate,
  description,
}: EventTimeSlot) => {
  const timezoneName = moment.tz.guess();

  const startDateTimeString = moment.utc(startDate).local();
  const startDateLabel = startDateTimeString
    .tz(timezoneName)
    .format('dddd, MMM Do LT');

  const endDateTimeString = moment.utc(endDate).local();
  const sameDate =
    startDateTimeString.format('MMM D') === endDateTimeString.format('MMM D');

  const endDateLabel = sameDate
    ? endDateTimeString.tz(timezoneName).format('LT')
    : endDateTimeString.tz(timezoneName).format('dddd, MMM Do LT ');

  let tz = endDateTimeString.tz(timezoneName).format('z');
  let tz_name = '';
  if (!/^[A-Za-z]*$/.test(tz)) {
    tz_name = 'GMT' + tz;
  } else {
    tz_name = tz;
  }

  let timeSlotLabel = `${startDateLabel} to ${endDateLabel} ${tz_name}`;
  timeSlotLabel = description
    ? `${timeSlotLabel} - (${description})`
    : timeSlotLabel;
  return timeSlotLabel;
};
