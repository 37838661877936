import React, { useCallback, useMemo, memo, useEffect } from 'react';
import { isEqual } from 'lodash';
import { Map } from 'immutable';
import moment from 'moment';

// hooks and helpers
import { useSelector, useDispatch } from 'react-redux';
import download from 'helpers/download';

// components
import DazzlingDialog from 'components/DazzlingDialog';
import DateRange from 'components/DateRange';
import { Field, Form } from 'react-final-form';

// material-ui
import Grid from '@material-ui/core/Grid';

// icons
import GetApp from '@material-ui/icons/GetApp';

// action creators
import { createExportAction } from 'store/actions/apiTaskActions';
import { clearFileExportById as clearFileExportByIdAction } from 'store/actions/fileExportActions';

// selectors
import getCurrentGroupId from 'store/selectors/getCurrentGroupId';
import getIsCouncilAdmin from 'store/selectors/getIsCouncilAdmin';
import getSliceEntityById from 'store/selectors/getSliceEntityById';
import getForm from 'store/selectors/getForm';

const FORM = 'exportResponsesForm';
const EXPORT_TYPE = 'form_responses';

type ExportResponsesModalProps = {
  open: boolean;
  onClose: (...args: any[]) => any;
  formId: number;
};

const ExportResponsesModal = ({
  open,
  onClose,
  formId,
}: ExportResponsesModalProps) => {
  const {
    actions: { createExport, clearFileExportById },
    data: {
      currentGroupId,
      isCouncilAdmin,
      fileExport: { downloadUrl } = {},
      form: { hasSubmitFailed } = {},
    },
  } = useRedux();

  useEffect(() => {
    if (downloadUrl) {
      download(downloadUrl);
      clearFileExportById({ exportId: EXPORT_TYPE });
    }
  }, [downloadUrl, clearFileExportById]);

  const onSubmit = useCallback(
    ({ dateRange }) => {
      createExport({
        groupId: currentGroupId,
        exportType: EXPORT_TYPE,
        formId,
        formName: FORM,
        ...dateRange,
      });
    },
    [createExport, currentGroupId, formId]
  );

  const handleDateChange = useCallback(({ startDate, endDate, input }) => {
    input.onChange({ startDate, endDate });
  }, []);

  return (
    <Form
      id='exportResponsesForm'
      onSubmit={onSubmit}
      render={({ handleSubmit, invalid, form: { restart } }) => {
        return (
          <DazzlingDialog
            id='exportResponsesModal'
            disabled={invalid}
            alertProps={{
              message: hasSubmitFailed
                ? 'Export Failed'
                : 'Exported Successfully',
            }}
            formName={FORM}
            handleClose={() => {
              restart();
              onClose();
            }}
            open={open}
            onAccept={handleSubmit}
            headerProps={{
              icon: GetApp,
              title: 'Export',
              subtitle: 'Select date range and download form responses',
            }}
            resetForm={restart}
          >
            <Grid container spacing={2} id='dialogContent'>
              <Grid item>
                <Field
                  name='dateRange'
                  // @ts-expect-error ts-migrate(2322) FIXME: Type 'typeof DateRange' is not assignable to type ... Remove this comment to see the full error message
                  component={DateRange}
                  formName={FORM}
                  onDateChange={handleDateChange}
                  initialStartDate={moment().subtract(7, 'days')}
                  initialEndDate={moment()}
                  maxDays={isCouncilAdmin ? 366 : 90}
                  required
                />
              </Grid>
            </Grid>
          </DazzlingDialog>
        );
      }}
    />
  );
};

const useRedux = () => {
  const dispatch = useDispatch();
  const actions = useMemo(
    () => ({
      createExport: (payload: any) => dispatch(createExportAction(payload)),
      clearFileExportById: (payload: { exportId: string }) =>
        dispatch(clearFileExportByIdAction(payload)),
    }),
    [dispatch]
  );
  const data = useSelector(
    state => ({
      currentGroupId: getCurrentGroupId(state),
      isCouncilAdmin: getIsCouncilAdmin(state),
      fileExport: getSliceEntityById('fileExport', EXPORT_TYPE, Map())(state),
      form: getForm(FORM)(state),
    }),
    isEqual
  );
  return { actions, data };
};

export default memo(ExportResponsesModal);
