import { Map } from 'immutable';
import getCurrentGroupId from './getCurrentGroupId';
import { toInt10 } from 'helpers/transform';

export default function getLeadStatuses(state: any): Status[] {
  const currentGroupId = getCurrentGroupId(state);
  const statuses = state
    .getIn(['status', 'data', 'byId'], Map())
    .toList()
    .toJS();

  return statuses
    .map((status: any) => ({
      ...status,
      groupId: toInt10(status?.groupId || 0),
    }))
    .filter(({ groupId }: any = {}) => groupId === currentGroupId)
    .sort(
      (first: any, second: any) =>
        toInt10(first?.position || 0) - toInt10(second?.position || 0)
    );
}
