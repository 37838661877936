import { Styles, Theme } from 'assets/types';
import analyticsCardStyles from 'assets/stylesheets/defaultStyles/analyticsCard.style';

const recruitmentActivityOverviewCardStyles: Styles = theme => {
  const newAnalyticsCardStyle = analyticsCardStyles(theme) || {};
  return {
    ...newAnalyticsCardStyle,
    formControl: {
      minWidth: 250,
      marginBottom: 24,
    },
    chip: {
      backgroundColor: (theme as Theme).palette.background.lightPaper,
    },
    inputLabel: {
      backgroundColor: theme.palette.background.paper,
    },
  };
};

export default recruitmentActivityOverviewCardStyles;
