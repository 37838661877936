import React, { useEffect, useMemo } from 'react';
import { List } from 'immutable';
import { useSelector, useDispatch } from 'react-redux';
import useChapterFetchParams from 'helpers/hooks/useChapterFetchParams';
import { isEqual } from 'lodash';

// components
import { WithStyles, withStyles } from '@material-ui/core';
import Select from 'components/Select';

// action
import { fetchGroups } from 'store/actions/groupActions';

// selectors
import getLoadingFromState from 'store/selectors/getLoadingFromState';
import getChapters, { GroupFormatType } from 'store/selectors/getChapters';
import getNextPageFromState from 'store/selectors/getNextPageFromState';

// helpers
import getInitialParams from 'routes/ChapterList/helpers/getInitialParams';
import { isEmpty } from 'helpers/check';

// Styles
import chapterDropdownStyles from 'components/ChapterDropdown/chapterDropdown.style';

export interface OptionsProps {
  label?: string;
  value?: string | number;
}

export interface ChapterDropdownProps
  extends WithStyles<typeof chapterDropdownStyles> {
  currentGroupId: number;
  isCouncilAdmin?: boolean;
  multiSelect?: boolean;
  meta: any;
  disabled?: boolean;
  moreOptionsFirst?: OptionsProps[];
  moreOptionsLast?: OptionsProps[];
  variant?: 'outlined' | 'standard' | 'filled';
  label?: string;
  isFetchingGroupsTurnedOff?: boolean;
  selectStyle?: 'default' | 'analyticsList';
  input: {
    name?: string;
    value?: string | number | null;
    onChange?: any;
  };
  labelFormat?: GroupFormatType;
}

const ChapterDropdown = ({
  classes,
  currentGroupId,
  isCouncilAdmin,
  multiSelect,
  moreOptionsFirst,
  moreOptionsLast,
  disabled,
  input,
  isFetchingGroupsTurnedOff,
  selectStyle = 'default',
  labelFormat = 'suggestions',
  ...restOfProps
}: ChapterDropdownProps) => {
  const dispatch = useDispatch();

  const params =
    useChapterFetchParams(
      getInitialParams({
        isCouncilAdmin: !!isCouncilAdmin,
        currentGroupId,
      }) || {}
    ) || {};

  const { chapters, chapterLoading, groupNextPage } = useSelector(
    state => ({
      chapters: getChapters(state, labelFormat),
      chapterLoading: getLoadingFromState(
        'group',
        'greekChapter',
        false
      )(state),
      groupNextPage: getNextPageFromState('group', 'greekChapter')(state),
    }),
    isEqual
  );

  const getNewCampuses = (newParams?: {
    page?: number | string;
    appendResult?: boolean;
  }) => {
    dispatch(
      fetchGroups({
        ...params,
        ...(!isEmpty(newParams) ? newParams : {}),
        fetchParents: false,
      })
    );
  };

  useEffect(() => {
    if (!chapterLoading && !isFetchingGroupsTurnedOff) {
      getNewCampuses();
    }
  }, []); // eslint-disable-line

  useEffect(() => {
    if (!chapterLoading && !!groupNextPage && !isFetchingGroupsTurnedOff) {
      getNewCampuses({ page: groupNextPage, appendResult: true });
    }
  }, [chapterLoading, groupNextPage]); // eslint-disable-line

  const options = useMemo<OptionsProps[]>(() => {
    const newChapters = List.isList(chapters)
      ? (chapters.toJS() as OptionsProps[])
      : [];
    return [
      ...(moreOptionsFirst || []),
      ...newChapters,
      ...(moreOptionsLast || []),
    ];
  }, [chapters, moreOptionsFirst, moreOptionsLast]);

  return (
    <div className={`${classes.selectBox} ${selectStyle}`}>
      <Select
        input={input}
        options={options}
        disabled={disabled}
        multiple={multiSelect}
        {...restOfProps}
      />
    </div>
  );
};

export default withStyles(chapterDropdownStyles, { withTheme: true })(
  ChapterDropdown
);
