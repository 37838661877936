import { all, call, fork, put, takeEvery, select } from 'redux-saga/effects';

// action creators
import { doneIndicator } from 'store/actions/httpActions';

// selector
import getCurrentGroupId from 'store/selectors/getCurrentGroupId';

// api
import client from 'sources/api';

// constants
import {
  PHONE_NUMBER_GET_AVAILABLE_TWILIO_REQUEST,
  PHONE_NUMBER_GET_AVAILABLE_TWILIO,
} from 'store/constants/phoneNumberTypes';

// schemas
import { twilioNumberSchema } from 'store/schemas/twilioNumberSchema';

// helpers
import httpSaga from 'store/sagas/httpSaga';

const WATCH_TYPE = PHONE_NUMBER_GET_AVAILABLE_TWILIO_REQUEST;
const TYPE = PHONE_NUMBER_GET_AVAILABLE_TWILIO;

export function* getAvailableFromTwilioSaga(action: any) {
  const {
    payload: { areaCode: area_code },
  } = action;

  const currentGroupId = yield select(getCurrentGroupId);

  // @ts-expect-error ts-migrate(2569) FIXME: Type 'Generator<any, any, unknown>' is not an arra... Remove this comment to see the full error message
  yield* httpSaga(
    TYPE,
    call(client.get, '/phone_numbers/available_from_twilio', {
      params: {
        group_id: currentGroupId,
        area_code,
      },
    }),
    {
      schema: [twilioNumberSchema],
    }
  );
  yield put(doneIndicator(TYPE));
}

export function* watch() {
  yield takeEvery(WATCH_TYPE, getAvailableFromTwilioSaga);
}

export default function* root() {
  yield all([fork(watch)]);
}
