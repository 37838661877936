import getIsSystemAdmin from './getIsSystemAdmin';
import createDeepEqualSelector from 'helpers/createDeepEqualSelector';

const getPermissionValue = (state: any, permissionKey: any) =>
  state.getIn(['currentUser', 'data', 'permissions', permissionKey], false);

export default function getPermission(permissionKey: any) {
  return createDeepEqualSelector(
    [
      getIsSystemAdmin,
      (state: any) => getPermissionValue(state, permissionKey),
    ],
    (isSystemAdmin: any, hasPermission: any) => {
      return isSystemAdmin || hasPermission;
    }
  );
}
