import { Styles, Theme } from 'assets/types';

const formViewStyles: Styles = theme => {
  const border = `2px solid ${(theme as Theme).palette.background.darkPaper}`;

  return {
    title: {
      borderTop: border,
      borderBottom: border,
      padding: 15,
      paddingLeft: 35,
    },
    headerImage: {
      maxHeight: 100,
      [theme.breakpoints.only('xs')]: {
        maxHeight: 80,
      },
    },
    headerImageContainer: {
      padding: 24,
      textAlign: 'center',
      [theme.breakpoints.only('xs')]: {
        padding: 12,
      },
    },
    headerImageSkeleton: {
      margin: 'auto',
    },
    formCard: {
      zIndex: 1,
      marginTop: 38,
      marginBottom: 38,
      [theme.breakpoints.only('xs')]: {
        margin: 10,
      },
    },
    formContent: {
      paddingTop: 24,
      paddingLeft: 50,
      paddingRight: 50,
      [theme.breakpoints.only('xs')]: {
        paddingLeft: 20,
        paddingRight: 20,
      },
    },
    formFieldsContent: {
      paddingTop: 8,
      paddingLeft: 50,
      paddingRight: 50,
      [theme.breakpoints.only('xs')]: {
        paddingLeft: 20,
        paddingRight: 20,
      },
    },
    errorMessage: {
      marginBottom: 15,
      textAlign: 'center',
    },
    footerContainer: {
      textAlign: 'center',
      margin: '20px 0px',
      paddingLeft: 50,
      paddingRight: 50,
      [theme.breakpoints.only('xs')]: {
        paddingLeft: 20,
        paddingRight: 20,
      },
    },
    notViewableCard: {
      height: 250,
      padding: 24,
      paddingTop: 50,
    },
    gridBack: {
      textAlign: 'left',
    },
    buttonBack: {
      fontSize: '16px',
      fontWeight: 500,
      fontStyle: 'inherit',
      color: '#FFFFFF',
      lineHeight: 'inherit',
      display: 'inline',
      background: 'none !important',
      width: 'auto',
      height: 'auto',
      minWidth: 0,
      minHeight: 0,
      padding: 0,
      margin: '0px 0px 0px 0px !important',
      boxShadow: 'none !important',
      '&:hover': {
        textDecoration: 'underline',
      },
      '& .arrowBack': {
        verticalAlign: 'middle',
        display: 'inline-block',
        marginRight: '5px',
        fontSize: '20px',
      },
    },
  };
};

export default formViewStyles;
