import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

// MUI components
import { Box, Grid, Typography, Link as MaterialLink } from '@material-ui/core';

// MUI icons
import { Today } from '@material-ui/icons';

type DateIndicatorProps = {
  date?: string;
  showDate?: boolean;
};

export const DateIndicator = ({ date, showDate }: DateIndicatorProps) => (
  <>
    {showDate && (
      <Typography variant={'body2'} component={'span'} color={'textSecondary'}>
        {date}
      </Typography>
    )}
  </>
);

type ActionIconProps = {
  icon?: React.ReactElement;
};

export const ActionIcon = ({ icon }: ActionIconProps) => (
  <Box
    bgcolor='background.default'
    borderRadius='50%'
    padding={1}
    display={'flex'}
  >
    {icon}
  </Box>
);

type OwnMainHeaderProps = {
  actorLabel?: string;
  targetLabel?: string;
  body?: string;
  ending?: string;
  leadId?: number;
  leadLabel?: string;
  passive?: boolean;
  suffix?: string;
  withActorPrefix?: boolean;
  isChanged?: boolean;
  wrap?: string;
  isLeadLabelLinkDisabled?: boolean;
};

// @ts-expect-error ts-migrate(2456) FIXME: Type alias 'MainHeaderProps' circularly references... Remove this comment to see the full error message
type MainHeaderProps = OwnMainHeaderProps & typeof MainHeader.defaultProps;

// @ts-expect-error ts-migrate(7022) FIXME: 'MainHeader' implicitly has type 'any' because it ... Remove this comment to see the full error message
export const MainHeader = ({
  actorLabel,
  targetLabel = 'Event Name',
  body,
  leadLabel,
  leadId,
  passive,
  suffix,
  isChanged,
  wrap,
  withActorPrefix,
  ending,
  isLeadLabelLinkDisabled,
}: MainHeaderProps) => {
  const renderActorLabelForPassive = () => {
    // Adds an additional "by `actorLabel`" to end of activity feed label
    //  This is useful for showing who referred the lead for a form
    if (passive && actorLabel) {
      return (
        <>
          {withActorPrefix && (
            <>
              &nbsp;<em>by</em>&nbsp;
            </>
          )}
          {actorLabel}
        </>
      );
    }
  };
  return (
    <Grid container wrap={wrap}>
      {[
        <React.Fragment key='actor'>
          <Grid item zeroMinWidth>
            <Typography variant={'subtitle2'} noWrap>
              <strong>{passive ? targetLabel : actorLabel}</strong>
              {renderActorLabelForPassive()}
            </Typography>
          </Grid>
          {suffix &&
            !(passive ? targetLabel : actorLabel)
              ?.toLowerCase() // Syntax: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Optional_chaining
              .includes(suffix) && (
              <Grid item>
                <Typography variant={'subtitle2'} noWrap>
                  <em>{suffix}</em>
                </Typography>
              </Grid>
            )}
        </React.Fragment>,
        <Grid item key='body'>
          <Typography variant={'subtitle2'} noWrap>
            &nbsp;
            <em>{body}</em>
            &nbsp;
          </Typography>
        </Grid>,
        leadLabel ? (
          <React.Fragment key='lead'>
            <Grid item>
              <Typography variant={'subtitle2'} noWrap>
                <em>{passive ? 'Lead' : 'lead'}</em>
                &nbsp;
              </Typography>
            </Grid>
            <Grid item zeroMinWidth>
              <Typography variant={'subtitle2'} color={'primary'} noWrap>
                <strong>
                  {isLeadLabelLinkDisabled ? (
                    leadLabel
                  ) : (
                    <MaterialLink
                      underline={'none'}
                      component={RouterLink}
                      to={`/leads/${leadId}`}
                      color={'primary'}
                    >
                      {leadLabel}
                    </MaterialLink>
                  )}
                </strong>
              </Typography>
            </Grid>
          </React.Fragment>
        ) : (
          <Grid item zeroMinWidth key='targetLabel'>
            <Typography variant={'subtitle2'} color={'secondary'} noWrap>
              <strong>{targetLabel}</strong>
            </Typography>
          </Grid>
        ),
        isChanged && (
          <React.Fragment key='target'>
            <Grid item>
              <Typography variant={'subtitle2'} noWrap>
                &nbsp;
                <em>to</em>
                &nbsp;
              </Typography>
            </Grid>
            <Grid item zeroMinWidth>
              <Typography variant={'subtitle2'} noWrap>
                <strong>{targetLabel}</strong>
              </Typography>
            </Grid>
          </React.Fragment>
        ),
        ending && (
          <Grid item>
            <Typography variant={'subtitle2'} noWrap>
              <em>&nbsp;{ending}</em>
            </Typography>
          </Grid>
        ),
      ][passive ? 'reverse' : 'slice']()}
    </Grid>
  );
};

MainHeader.defaultProps = {
  wrap: 'nowrap',
  withActorPrefix: true,
};

type TimeSubHeaderProps = {
  time?: string;
};

export const TimeSubHeader = ({ time }: TimeSubHeaderProps) => (
  <Grid container>
    <Box fontSize={'medium'} display={'flex'} alignSelf={'center'}>
      <Today fontSize='inherit' color={'disabled'} />
    </Box>
    &nbsp;
    <Typography variant={'caption'} component={'span'} color={'textSecondary'}>
      {time}
    </Typography>
  </Grid>
);
