import React, { useCallback, useState, useMemo, memo } from 'react';
import { useHistory } from 'react-router-dom';
import { isEqual } from 'lodash';
import { parsePhoneNumberFromString, AsYouType } from 'libphonenumber-js';
import { useSelector, shallowEqual } from 'react-redux';
import { useIsFeatureEnabled } from 'helpers/hooks/featureHooks';
import { useUpdateLeadMutation } from 'api/leads';

// helpers
import formatDisplayedPhoneNumber from 'helpers/formatDisplayedPhoneNumber';

// material-ui
import { Box } from '@material-ui/core';
import { Sms, Edit, Phone } from '@material-ui/icons';

// components
import CardActionItem from 'components/CardActionItem';
import Toast from 'components/Toast';

// selectors
import getPermission from 'store/selectors/getPermission';

type PhoneItemProps = {
  lead: Lead;
  displayActions?: boolean;
  isCouncilLeadPage?: boolean;
};

const PhoneItem = function({
  lead,
  displayActions = true,
  isCouncilLeadPage,
}: PhoneItemProps) {
  const {
    isLoading: phoneUpdating,
    mutate: updateLead,
  } = useUpdateLeadMutation();
  const history = useHistory();
  const {
    location: { search, state },
  } = history;

  const { canCreateCall, canCreateMessages } = useSelector(
    state => ({
      canCreateCall: getPermission('call.createOne')(state),
      canCreateMessages: getPermission('message.createMany')(state),
    }),
    shallowEqual
  );

  const textsEnabled = useIsFeatureEnabled('texts');
  const callsEnabled = useIsFeatureEnabled('calls');

  const navigateToMessages = useCallback(() => {
    history.push({
      pathname: '/messages',
      search,
      state: {
        recipients: [{ value: lead.id, label: lead.fullName }],
        // @ts-expect-error ts-migrate(2698) FIXME: Spread types may only be created from object types... Remove this comment to see the full error message
        ...state,
      },
    });
  }, [history, lead, search, state]);

  const navigateToCalls = useCallback(() => {
    history.push({
      pathname: '/calls',
      state: {
        newCallQueueLeadIds: [lead.id],
        // @ts-expect-error ts-migrate(2698) FIXME: Spread types may only be created from object types... Remove this comment to see the full error message
        ...state,
      },
    });
  }, [history, lead, state]);

  const noPhoneNumber = !Boolean(lead.phoneNumber);
  const [isNumberValid, setIsNumberValid] = useState(true);

  const actions = useMemo(() => {
    if (noPhoneNumber || !textsEnabled || !canCreateMessages) {
      return [
        {
          id: 'updateSms',
          icon: <Edit />,
          tooltipTitle: 'Update Phone Number',
          onClick: () => {},
        },
      ];
    }

    const contactActions = [];

    if (textsEnabled && canCreateMessages) {
      contactActions.push({
        id: 'sms',
        icon: <Sms />,
        tooltipTitle: 'Send Text Message',
        onClick: navigateToMessages,
      });
    }

    if (callsEnabled && canCreateCall && !isCouncilLeadPage) {
      contactActions.push({
        id: 'call',
        icon: <Phone />,
        tooltipTitle: 'Make Call',
        onClick: navigateToCalls,
      });
    }

    return contactActions;
  }, [
    noPhoneNumber,
    textsEnabled,
    canCreateMessages,
    callsEnabled,
    canCreateCall,
    navigateToMessages,
    navigateToCalls,
    isCouncilLeadPage,
  ]);

  const handleUpdate = useCallback(
    phoneNumber => {
      const dirty = phoneNumber !== lead.phoneNumber;

      if (isNumberValid && dirty) {
        if (phoneNumber === '') {
          phoneNumber = null;
        }
        updateLead({
          id: lead.id,
          phoneNumber,
        });
      }
    },
    [updateLead, isNumberValid, lead]
  );

  const formatPhoneNumber = useCallback((value = '') => {
    const number = parsePhoneNumberFromString(value?.toString(), 'US');
    let formattedNumber = value?.toString();

    /* The formatter has issues with open and closed parentheses
      and backspacing through them.

      We strip the parentheses out so that the formatter can still work.
      More: https://github.com/catamphetamine/libphonenumber-js/issues/225
    */
    if (formattedNumber.includes('(') && !formattedNumber.includes(')')) {
      formattedNumber = formattedNumber.replace('(', '');
    } else {
      formattedNumber = new AsYouType('US').input(formattedNumber);
    }

    //If number is undefined this allows us to delete the number;
    if ((number && number.isValid()) || number === undefined) {
      setIsNumberValid(true);
    } else {
      setIsNumberValid(false);
    }

    return formattedNumber;
  }, []);

  let displayedPhoneNumber = formatDisplayedPhoneNumber(lead.phoneNumber);

  return (
    <Box>
      <CardActionItem
        label={displayedPhoneNumber}
        displayActions={displayActions}
        emptyLabel='No phone number'
        variant='contained'
        loading={phoneUpdating}
        actions={actions}
        // @ts-expect-error ts-migrate(2322) FIXME: Type '{ label: any; displayActions: any; emptyLabe... Remove this comment to see the full error message
        button={noPhoneNumber}
        hover
        editable
        onChange={formatPhoneNumber}
        onBlur={handleUpdate}
        TextFieldProps={{
          id: 'phoneField',
          type: 'tel',
          label: isNumberValid ? 'Phone Number' : 'Phone Number Invalid',
          error: isNumberValid,
          fullWidth: true,
          autoFocus: true,
        }}
      />

      <Toast
        successMessage='Updated successfully'
        failureMessage='Update failed'
        slice='lead'
      />
    </Box>
  );
};

export default memo(PhoneItem, isEqual);
