import React from 'react';
import { Map, List } from 'immutable';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { isEqual } from 'lodash';

// material-ui
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core/styles';

// selectors
import getSuggestedFields from 'store/selectors/getSuggestedFields';
import getGroupFields from 'store/selectors/getGroupFields';

// icons
import ValueIcon from '@material-ui/icons/Star';
import FormIcon from '@material-ui/icons/Ballot';
import LeadIcon from '@material-ui/icons/AccountBox';
import GlobalIcon from '@material-ui/icons/Public';

const AutoSuggestFieldItem = function({ label, value, selected }: any) {
  const classes = useStyles();

  let { suggestedFields, groupFields } = useSelector(
    state => ({
      suggestedFields: getSuggestedFields()(state),
      groupFields: getGroupFields()(state),
    }),
    isEqual
  );

  const fields = suggestedFields.concat(groupFields);

  const renderIcons = (value: any) => {
    const fieldItem = fields.find(
      (field: any) => field.get('id') === value,
      {}, // context param
      Map() // not set value param
    ) as any;

    let element: any = [];
    const sources = fieldItem.get('sources', List());

    if (sources.has('GroupValue')) {
      element.includes(
        <Grid key='GroupValue' item>
          <Tooltip title='Group Value'>
            <ValueIcon />
          </Tooltip>
        </Grid>
      );
    }
    if (sources.includes('SuggestedField')) {
      element.push(
        <Grid key='SuggestedField' item>
          <Tooltip title='Global suggested field'>
            <GlobalIcon />
          </Tooltip>
        </Grid>
      );
    }
    if (sources.includes('PNMAttr')) {
      element.push(
        <Grid key='PNMAttr' item>
          <Tooltip title='Used on lead profile'>
            <LeadIcon />
          </Tooltip>
        </Grid>
      );
    }
    if (sources.includes('FormField')) {
      element.push(
        <Grid key='FormField' item>
          <Tooltip title='Used on referral form'>
            <FormIcon />
          </Tooltip>
        </Grid>
      );
    }

    return element;
  };

  return (
    <MenuItem selected={selected} component='div'>
      <ListItemText
        primary={label}
        classes={{
          primary: classes.fieldText,
        }}
      />
      <ListItemSecondaryAction>
        <Grid container spacing={1}>
          {renderIcons(value)}
        </Grid>
      </ListItemSecondaryAction>
    </MenuItem>
  );
};

const useStyles = makeStyles(theme => {
  return {
    fieldText: {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
  };
});

AutoSuggestFieldItem.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  selected: PropTypes.bool.isRequired,
};

export default AutoSuggestFieldItem;
