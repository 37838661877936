import { schema } from 'normalizr';
import { tagSchema } from 'store/schemas/tagSchema';
import { labelSchema } from 'store/schemas/labelSchema';

export const groupSchema = new schema.Entity('group');

groupSchema.define({
  parents: [groupSchema],
  dashboardLinkedParents: [groupSchema],
  labels: [tagSchema],
});

export const groupCouncilSchema = new schema.Entity('group');

groupCouncilSchema.define({
  parents: [groupCouncilSchema],
  dashboardLinkedParents: [groupCouncilSchema],
  labels: [labelSchema],
});
