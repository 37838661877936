import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { isEqual } from 'lodash';
import moment, { Moment } from 'moment';

// hooks
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { usePrevious } from 'helpers/hooks/usePrevious';

// helpers
import formatStartDate from 'helpers/formatStartDate';
import formatEndDate from 'helpers/formatEndDate';

// MUI components
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Card, CardContent, Typography } from '@material-ui/core';
import {
  Email,
  GroupAdd,
  NoteAdd,
  Call,
  Sms,
  SwapVert,
} from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';

// components
import ActivityOverview from 'components/ActivityOverview';
import AnalyticsActionBar from 'components/AnalyticsActionBar';
import ActivityFeed from 'components/ActivityFeed';

// action creators
import { createMemberLeaderboardReport } from 'store/actions/apiTaskActions';

// selectors
import getCurrentGroupId from 'store/selectors/getCurrentGroupId';
import getForm from 'store/selectors/getForm';
import getSliceEntityById from 'store/selectors/getSliceEntityById';
import getSliceState from 'store/selectors/getSliceState';
import getCurrentUser from 'store/selectors/getCurrentUser';

const FORM = 'memberLeaderboardReportForm';
const INITIAL_START_DATE = moment().subtract(7, 'days');
const INITIAL_END_DATE = moment();

const MemberOverview = function() {
  const classes = useStyles();
  const history = useHistory();

  const {
    location: { pathname, search },
  } = history;
  const dispatch = useDispatch();
  const [createdOnFrom, setCreatedOnFrom] = useState(
    formatStartDate(INITIAL_START_DATE)
  );
  const [createdOnTo, setCreatedOnTo] = useState(
    formatEndDate(INITIAL_END_DATE)
  );

  const {
    currentGroupId,
    currentUserId,
    report: {
      data: { content: contentArray = [], meta: { columns = [] } = {} } = {},
    } = {},
    form: { isSubmitting },
  } = useSelector(
    state => ({
      currentGroupId: getCurrentGroupId(state),
      currentUserId: getCurrentUser(state).get('id'),
      form: getForm(FORM)(state),
      member: getSliceState('member')(state),
      report: getSliceEntityById(
        'report',
        'ReportTypes.MEMBER_LEADERBOARD',
        {}
      )(state),
    }),
    isEqual
  );

  const content = contentArray[0];
  const urlParams = new URLSearchParams(search);
  const actorId = urlParams.get('account');
  const previousActorId = usePrevious(actorId);
  const previousCreatedOnFrom = usePrevious(createdOnFrom);
  const previousCreatedOnTo = usePrevious(createdOnTo);

  const actorIdChanged = actorId && previousActorId !== actorId;
  const createdOnFromChanged =
    previousCreatedOnFrom && previousCreatedOnFrom !== createdOnFrom;
  const createdOnToChanged =
    previousCreatedOnTo && previousCreatedOnTo !== createdOnTo;

  const source = useMemo<{
    name: 'group' | 'pnm';
    id: number;
  }>(() => ({ name: 'group', id: currentGroupId }), [currentGroupId]);

  const dispatchCreateReport = useCallback(
    ({
      startDate,
      endDate,
      forceRefresh,
    }: {
      startDate: Moment;
      endDate: Moment;
      forceRefresh: boolean;
    }) => {
      if (actorId) {
        dispatch(
          createMemberLeaderboardReport({
            actorId,
            groupId: currentGroupId,
            startDate,
            endDate,
            forceRefresh,
          })
        );
        setCreatedOnFrom(formatStartDate(startDate));
        setCreatedOnTo(formatEndDate(endDate));
      }
    },
    [actorId, currentGroupId, dispatch]
  );

  // Fetch report when member changes
  useEffect(() => {
    if (actorId) {
      dispatchCreateReport({
        startDate: INITIAL_START_DATE,
        endDate: INITIAL_END_DATE,
        forceRefresh: false,
      });
    } else {
      // Set the current user as the selected member
      const newSearch = new URLSearchParams(search);
      newSearch.set('account', currentUserId);
      history.replace({ pathname, search: newSearch?.toString() });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    actorId,
    currentGroupId,
    currentUserId,
    history,
    pathname,
    search,
    source,
  ]);

  const filters = useMemo(
    () => ({
      createdOnFrom,
      createdOnTo,
      member: { value: actorId },
      actions: [
        'text_messages.created',
        'text_message.received',
        'email_message.created',
        'pnm.created',
        'pnm.updated',
        'pnm_status.updated',
        'pnm.deleted',
        'pnms.imported',
        'pnms.deleted',
        'form.submitted',
        'note.created',
      ],
    }),
    [actorId, createdOnFrom, createdOnTo]
  );

  const formattedColumns = icons.map((icon, index) => ({
    icon,
    ...columns[index],
  }));

  return (
    <Card>
      <CardContent className={classes.cardContent}>
        <Grid container>
          <Grid container justifyContent='space-between'>
            <Grid item xs={12} md={4}>
              <Typography variant='subtitle2' color='textSecondary'>
                Member Overview
              </Typography>

              {isSubmitting ? (
                <>
                  <Skeleton height={50} width={200} />
                  <Skeleton height={30} width={100} />
                </>
              ) : (
                <>
                  <Typography variant='h6'>{content?.accountName}</Typography>
                  <Typography variant='subtitle2' color='secondary'>
                    {content?.points} points
                  </Typography>
                </>
              )}
            </Grid>

            <Grid item xs={12} md>
              <Grid container>
                <Grid item md lg={11} className={classes.dateRangeContainer}>
                  <AnalyticsActionBar
                    onCreateReport={dispatchCreateReport}
                    loading={isSubmitting}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} className={classes.overviewContainer}>
            <ActivityOverview
              data={content}
              loading={isSubmitting}
              columns={formattedColumns}
            />
          </Grid>

          <Grid item xs={12}>
            <ActivityFeed
              displayTimeline={false}
              paginateOnScroll={false}
              initialFilters={filters}
              filterOptions={{ showDate: false, showUsers: false }}
              reload={
                actorIdChanged || createdOnFromChanged || createdOnToChanged
              }
              {...{ source }}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

const icons = [
  <NoteAdd />,
  <SwapVert />,
  <Sms />,
  <Email />,
  <Call />,
  <GroupAdd />,
];

const useStyles = makeStyles(theme => ({
  cardContent: {
    paddingLeft: 24,
    paddingRight: 24,
  },
  overviewContainer: {
    marginTop: 10,
  },
  dateRangeContainer: {
    [theme.breakpoints.only('xs')]: {
      paddingRight: '20%',
      marginTop: 15,
    },
  },
}));

export default MemberOverview;
