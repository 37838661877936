import React, { useEffect, useMemo, useCallback, useState } from 'react';
import { isEqual } from 'lodash';
import moment from 'moment';
import { Map } from 'immutable';
import { useSelector, useDispatch } from 'react-redux';
import { useLeadQuery } from 'api/leads';
import { roles } from 'helpers/getRoles';
import { useParams } from 'react-router-dom';

// material-ui
import { Box, Grid, Typography, Tooltip, Popover } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';
// selectors
import getSliceEntityById from 'store/selectors/getSliceEntityById';
import getSliceState from 'store/selectors/getSliceState';
import getLoadingFromState from 'store/selectors/getLoadingFromState';
import getIsSystemAdmin from 'store/selectors/getIsSystemAdmin';

// actions
import {
  fetchLeadReferralSourcesAction,
  createLeadReferralSourceAction,
  deleteLeadReferralSourceAction,
} from 'store/actions/leadReferralSourceActions';
import { fetchReferralSourceAction } from 'store/actions/referralSourceActions';

// components
import ReferralSourceSelector from 'components/ReferralSourceSelector';
import MainContactSelector from 'components/MainContactSelector';

type LeadDetailsProps = {
  leadId?: number;
  compact?: boolean;
};

export default function LeadDetails({ leadId, compact }: LeadDetailsProps) {
  const classes = useStyles();
  const { leadId: leadIdParam } = useParams<{ leadId: string }>();
  const { data: lead } = useLeadQuery(Number(leadId || leadIdParam));
  const isSystemAdmin = useSelector(getIsSystemAdmin);

  const {
    state: {
      loading,
      referralSources,
      leadReferralSources,
      canAssignMainContact,
    },
    actions: {
      fetchLeadReferralSources,
      createLeadReferralSource,
      deleteLeadReferralSource,
      fetchReferralSource,
    },
  } = useRedux(leadId);
  const [leadReferralSourceIds, setLeadReferralSourceIds] = useState<number[]>(
    []
  );
  const handleReferralSourceIdsChange = useCallback(
    newLeadReferralSourceIds => {
      setLeadReferralSourceIds(newLeadReferralSourceIds);
    },
    [setLeadReferralSourceIds]
  );
  const leadReferralSourceNames = useMemo(
    () =>
      referralSources
        .filter(({ id }: any) => leadReferralSources?.hasOwnProperty(id))
        .map(({ id, displayName }: any) => {
          let referralInfo = displayName;
          const { personFirstName, personLastName } = leadReferralSources[id];
          if (personFirstName && personLastName)
            referralInfo += ` via ${personFirstName} ${personLastName}`;
          return referralInfo;
        }),
    [referralSources, leadReferralSources]
  );
  const uniqueLeadReferralSourceNames = (leadReferralSourceNames || []).filter(
    (el: string, index: number) => leadReferralSourceNames.indexOf(el) === index
  );
  const [mainContactAnchorEl, setMainContactAnchorEl] = useState(null);
  const [referralSourceAnchorEl, setReferralSourceAnchorEl] = useState(null);
  const toggleMainContactAnchorEl = useCallback(
    event => {
      if (isSystemAdmin || canAssignMainContact) {
        const currentElement = event.currentTarget;
        setMainContactAnchorEl(prevAnchorEl =>
          prevAnchorEl !== null ? null : currentElement
        );
        if (referralSourceAnchorEl) setReferralSourceAnchorEl(null);
      }
    },
    [canAssignMainContact, referralSourceAnchorEl, isSystemAdmin]
  );
  const toggleReferralSourceAnchorEl = useCallback(
    event => {
      const currentElement = event.currentTarget;
      // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 0.
      if (mainContactAnchorEl) toggleReferralSourceAnchorEl();
      setReferralSourceAnchorEl(prevAnchorEl => {
        if (prevAnchorEl !== null) {
          const prevLeadReferralSourceIds = Object.keys(
            leadReferralSources || {}
          ).map(Number);
          for (const referralSourceId of leadReferralSourceIds)
            if (!prevLeadReferralSourceIds.includes(referralSourceId))
              createLeadReferralSource({ leadId, referralSourceId });
          for (const referralSourceId of prevLeadReferralSourceIds)
            if (!leadReferralSourceIds.includes(referralSourceId))
              deleteLeadReferralSource({ leadId, referralSourceId });
          return null;
        }
        return currentElement;
      });
    },
    [
      mainContactAnchorEl,
      leadReferralSources,
      leadReferralSourceIds,
      createLeadReferralSource,
      leadId,
      deleteLeadReferralSource,
    ]
  );
  useEffect(() => {
    if (leadId) {
      fetchLeadReferralSources({ leadId });
    }
  }, [leadId, fetchLeadReferralSources]);
  useEffect(() => {
    if (!Map.isMap(leadReferralSources)) {
      for (const id in leadReferralSources) {
        fetchReferralSource({ id });
      }
      setLeadReferralSourceIds(Object.keys(leadReferralSources).map(Number));
    }
  }, [leadReferralSources, fetchReferralSource]);
  return (
    <Grid container>
      <Grid
        item
        xs={12}
        md={leadIdParam ? 10 : 12}
        lg={leadIdParam ? 8 : 12}
        xl={leadIdParam ? 7 : 12}
        style={{ marginBottom: 12 }}
      >
        <Box
          border={1}
          borderColor='background.outline'
          borderRadius={4}
          minWidth={280}
        >
          <Grid container>
            <Grid
              item
              xs={6}
              className={
                isSystemAdmin || canAssignMainContact
                  ? classes.editableSection
                  : classes.uneditableSection
              }
            >
              <Box
                paddingX={2}
                paddingY={compact ? '1px' : 2}
                onClick={toggleMainContactAnchorEl}
              >
                {loading ? (
                  <>
                    <Skeleton variant='text' />
                    <Skeleton variant='text' />
                  </>
                ) : (
                  <>
                    <Typography variant='caption' color='textSecondary'>
                      Main Contact
                    </Typography>
                    <Typography variant='body2' noWrap>
                      {lead?.mainContactFullName || 'Not assigned'}
                    </Typography>
                  </>
                )}
              </Box>
              <Popover
                open={Boolean(mainContactAnchorEl)}
                anchorEl={mainContactAnchorEl}
                onClose={toggleMainContactAnchorEl}
                classes={classes}
              >
                <Box
                  width={(mainContactAnchorEl as any)?.offsetWidth}
                  minWidth={275}
                >
                  <MainContactSelector
                    leadId={leadId}
                    initialValue={{
                      value: lead?.mainContactId,
                      label: lead?.mainContactFullName,
                    }}
                  />
                </Box>
              </Popover>
            </Grid>
            <Grid item xs={6} className={classes.editableSection}>
              <Box
                paddingX={2}
                paddingY={compact ? '1px' : 2}
                onClick={toggleReferralSourceAnchorEl}
                borderLeft={1}
                borderColor='background.outline'
              >
                {loading ? (
                  <>
                    <Skeleton variant='text' />
                    <Skeleton variant='text' />
                  </>
                ) : (
                  <>
                    <Typography variant='caption' color='textSecondary' noWrap>
                      Referral Source
                    </Typography>
                    <Typography variant='body2' noWrap>
                      {uniqueLeadReferralSourceNames.length
                        ? uniqueLeadReferralSourceNames[0]
                        : 'Not referred'}
                      {uniqueLeadReferralSourceNames.length > 1 && (
                        <Tooltip
                          title={uniqueLeadReferralSourceNames
                            .slice(1)
                            .join(', ')}
                        >
                          <span>{` and ${uniqueLeadReferralSourceNames.length -
                            1} more`}</span>
                        </Tooltip>
                      )}
                    </Typography>
                  </>
                )}
              </Box>
              <Popover
                open={Boolean(referralSourceAnchorEl)}
                anchorEl={referralSourceAnchorEl}
                onClose={toggleReferralSourceAnchorEl}
                classes={classes}
              >
                <Box
                  width={(referralSourceAnchorEl as any)?.offsetWidth}
                  minWidth={220}
                >
                  <ReferralSourceSelector
                    input={{
                      value: leadReferralSourceIds,
                      onChange: handleReferralSourceIdsChange,
                    }}
                    multiple
                    placeholder='Referral Sources'
                    label={undefined}
                    paddingTopClass='paddingTop6'
                  />
                </Box>
              </Popover>
            </Grid>
          </Grid>
        </Box>
      </Grid>

      <Grid
        item
        xs={12}
        md={leadIdParam ? 10 : 12}
        lg={leadIdParam ? 8 : 12}
        xl={leadIdParam ? 7 : 12}
      >
        <Box
          border={1}
          borderColor='background.outline'
          borderRadius={4}
          minWidth={280}
        >
          <Grid container>
            <Grid item xs={6}>
              <Box paddingX={2} paddingY={compact ? '1px' : 2}>
                {loading ? (
                  <>
                    <Skeleton variant='text' />
                    <Skeleton variant='text' />
                  </>
                ) : (
                  <>
                    <Typography variant='caption' color='textSecondary'>
                      Last Contacted
                    </Typography>
                    <Typography variant='body2' noWrap>
                      {lead && lead.lastContactedOn
                        ? moment
                            .utc(lead.lastContactedOn)
                            .local()
                            .format('MMM DD, YYYY')
                        : 'Not contacted'}
                    </Typography>
                  </>
                )}
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box
                paddingX={2}
                paddingY={compact ? '1px' : 2}
                borderLeft={1}
                borderColor='background.outline'
              >
                {loading ? (
                  <>
                    <Skeleton variant='text' />
                    <Skeleton variant='text' />
                  </>
                ) : (
                  <>
                    <Typography variant='caption' color='textSecondary'>
                      Date Added
                    </Typography>
                    <Typography variant='body2' noWrap>
                      {moment
                        .utc(lead?.createdOn)
                        .local()
                        .format('MMM DD, YYYY')}
                    </Typography>
                  </>
                )}
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
}
const useRedux = (leadId: any) => {
  const dispatch = useDispatch();
  const actions = useMemo(
    () => ({
      fetchLeadReferralSources: (payload: any) =>
        dispatch(fetchLeadReferralSourcesAction(payload)),
      createLeadReferralSource: (payload: any) =>
        dispatch(createLeadReferralSourceAction(payload)),
      deleteLeadReferralSource: (payload: any) =>
        dispatch(deleteLeadReferralSourceAction(payload)),
      fetchReferralSource: (payload: any) =>
        dispatch(fetchReferralSourceAction(payload)),
    }),
    [dispatch]
  );
  const getState = useCallback(
    state => {
      const leadReferralSources = getSliceEntityById(
        'leadReferralSource',
        leadId,
        {}
      )(state);
      return {
        loading:
          getLoadingFromState('leadReferralSource', leadId)(state) ||
          (leadReferralSources &&
            Object.keys(leadReferralSources)
              .map(rsId => getLoadingFromState('referralSource', rsId)(state))
              .some(Boolean)),
        leadReferralSources,
        referralSources: getSliceState('referralSource')(state).data,
        canAssignMainContact: ![roles.coach, roles.member].includes(
          (state as any).getIn(['currentUser', 'data', 'roleId'], roles.member)
        ),
      };
    },
    [leadId]
  );
  const state = useSelector(getState, isEqual);
  return { actions, state };
};
const useStyles = makeStyles(theme => ({
  paper: {
    borderRadius: 0,
  },
  editableSection: {
    transition: 'all ease 0.3s',
    cursor: 'pointer',
    '&:hover': {
      transition: 'all ease 0.3s',
      backgroundColor: (theme.palette.background as any).lightPaper,
    },
  },
  leftBorder: {
    leftBorder: '1px',
  },
  uneditableSection: {},
}));
