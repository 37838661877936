import { Map } from 'immutable';
// constants
import {
  MILESTONE_FETCH,
  MILESTONE_CREATE,
  MILESTONE_DELETE,
  MILESTONE_UPDATE,
} from 'store/constants/milestoneTypes';
// helpers
import httpReducer from './helpers/httpReducer';
// callbacks
import remove from './callbacks/remove';
import update from './callbacks/update';

export default function reduceMilestone(state = Map(), action = {}) {
  switch ((action as any).type) {
    case MILESTONE_FETCH:
      return httpReducer(state, action, { appendResult: true });
    case MILESTONE_CREATE:
      return httpReducer(state, action, { appendResult: true });
    case MILESTONE_DELETE:
      return httpReducer(state, action, { callback: remove });
    case MILESTONE_UPDATE:
      return httpReducer(state, action, { callback: update });
    default:
      return state;
  }
}
