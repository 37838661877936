import React, { useEffect } from 'react';

import { useCreateEventTimeSlotsMutation } from 'api/eventTimeSlots';
import validate, { isInteger } from 'validate.js';
import moment, { Moment } from 'moment';
import { Form, Field } from 'react-final-form';

// material-ui
import Grid from '@material-ui/core/Grid';

// icons
import FormIcon from '@material-ui/icons/Ballot';

//components
import DazzlingDialog from 'components/DazzlingDialog';
import TextField from 'components/TextField';
import DateSelector from 'components/DateSelector';
import { FormEventTimeSlot } from './FormSettings';

//helpers
import { toInt10 } from 'helpers/transform';

// schemas
const DATE_SCHEMA = {
  date: {
    presence: true,
  },
};

const FULL_SCHEMA = {
  ...DATE_SCHEMA,
  startTime: {
    presence: true,
  },
  endTime: {
    presence: true,
  },
  description: {
    length: {
      maximum: 128,
    },
  },
  guestsLimit: { numericality: true },
};

interface Props {
  onClose: () => void;
  opened: boolean;
  date?: Moment | null;
  isCopy?: boolean;
  timeSlots?: FormEventTimeSlot[];
  usedDates?: string[];
  updateDataOnPage?: () => void;
}

interface EventTimeSlotForm {
  date: Moment;
  startTime: Moment;
  endTime: Moment;
  description?: string;
  guestsLimit?: number;
}

export default function CreateEventDateModal({
  opened,
  onClose,
  date,
  isCopy = false,
  timeSlots = [],
  usedDates = [],
  updateDataOnPage = () => {},
}: Props) {
  const {
    mutate: createEventTimeSlots,
    isLoading,
    isError,
    isSuccess,
    reset: resetMutation,
  } = useCreateEventTimeSlotsMutation();
  const validateForm = (values: EventTimeSlotForm) =>
    validate(values, isCopy ? DATE_SCHEMA : FULL_SCHEMA);
  const submitForm = ({
    date: newDate,
    startTime,
    endTime,
    description,
    guestsLimit,
  }: EventTimeSlotForm) => {
    let newTimeSlots = [];
    if (isCopy) {
      newTimeSlots = timeSlots.map(
        ({ startTime, endTime, description, guestsLimit }) => ({
          startDate: newDate
            .clone()
            .set({
              hour: moment(startTime).get('hour'),
              minute: moment(startTime).get('minute'),
            })
            .utc()
            .toISOString(),
          endDate: newDate
            .clone()
            .add(Number(moment(startTime) > moment(endTime)), 'days')
            .set({
              hour: moment(endTime).get('hour'),
              minute: moment(endTime).get('minute'),
            })
            .utc()
            .toISOString(),
          description,
          guestsLimit: guestsLimit ? toInt10(guestsLimit) : guestsLimit,
        })
      );
    } else {
      for (const item of [newDate, startTime, endTime]) item.set('second', 0);
      newTimeSlots.push({
        startDate: newDate
          .clone()
          .set({
            hour: startTime.get('hour'),
            minute: startTime.get('minute'),
          })
          .utc()
          .toISOString(),
        endDate: newDate
          .clone()
          .add(Number(startTime > endTime), 'days')
          .set({
            hour: endTime.get('hour'),
            minute: endTime.get('minute'),
          })
          .utc()
          .toISOString(),
        description,
        guestsLimit: guestsLimit ? toInt10(guestsLimit) : guestsLimit,
      });
    }
    createEventTimeSlots(newTimeSlots);
  };

  useEffect(() => {
    if (isSuccess) {
      updateDataOnPage();
    }
  }, [isSuccess]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Form
      initialValues={{ date }}
      validate={validateForm}
      onSubmit={submitForm}
      render={({ handleSubmit, invalid, form: { restart } }) => (
        <DazzlingDialog
          {...{ isLoading, isError, isSuccess }}
          acceptLabel={isCopy ? 'Copy' : 'Create'}
          alertProps={{ message: 'Event Date Time Slot Created Successfully' }}
          disabled={invalid}
          handleClose={onClose}
          headerProps={{
            icon: FormIcon,
            title: isCopy
              ? 'Copy Event Date Time Slots'
              : 'New Event Date Time Slot',
            subtitle: isCopy
              ? 'Provide date to copy event time slots'
              : 'Provide date, start and end times of a new event time slot',
          }}
          // height='short'
          id='createReferralForm'
          onAccept={handleSubmit}
          onExited={() => {
            restart();
            resetMutation();
          }}
          open={opened}
          resetForm={restart}
        >
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Field
                name='date'
                component={DateSelector}
                disabled={date}
                pickerFormat='MM/DD/YYYY'
                shouldDisableDate={(currentDate: Moment) =>
                  usedDates.includes(currentDate.format('MMM DD, YYYY'))
                }
                withStartAdornment={false}
                fullWidth
                required
                allowNull
                keyboard
              />
            </Grid>
            {!isCopy && (
              <>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Field
                        name='startTime'
                        label='Start Time'
                        variant='outlined'
                        pickerFormat='hh:mm A'
                        component={DateSelector}
                        keyboard
                        pickerType='time'
                        withStartAdornment={false}
                        fullWidth
                        required
                        allowNull
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Field
                        name='endTime'
                        label='End Time'
                        variant='outlined'
                        component={DateSelector}
                        keyboard
                        pickerType='time'
                        pickerFormat='hh:mm A'
                        withStartAdornment={false}
                        fullWidth
                        required
                        allowNull
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Field
                        name='description'
                        label='Description'
                        variant='outlined'
                        component={TextField}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Field
                        name='guestsLimit'
                        label='Guests Limit'
                        variant='outlined'
                        type='number'
                        parse={parseInteger}
                        component={TextField}
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </>
            )}
          </Grid>
        </DazzlingDialog>
      )}
    />
  );
}

const parseInteger = (value?: string) => {
  const number = Number(value);
  if (isInteger(number) && number > 0) {
    return number;
  }
  return null;
};
