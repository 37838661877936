import { all, fork, takeEvery, put } from 'redux-saga/effects';

// constants
import { CURRENT_GROUP_CLEAR } from '../../constants/groupTypes';

// action creators
import { doneIndicator } from 'store/actions/httpActions';

// helpers
import history from 'helpers/history';

export function* clearCurrentGroup(action: any) {
  const TYPE = CURRENT_GROUP_CLEAR;

  try {
    yield localStorage.removeItem('group');

    // Remove the group query param from the URL!
    //  Replace the URL with a string that does not have the group param
    const urlParams = new URLSearchParams(history.location.search);
    yield urlParams.delete('group');
    const newUrlParams = urlParams.toString() || '';

    yield history.replace({
      pathname: history.location.pathname,
      search: newUrlParams,
    });
  } finally {
    yield put(doneIndicator(TYPE));
  }
}

export function* watchCurrentGroupClear() {
  yield takeEvery(CURRENT_GROUP_CLEAR, clearCurrentGroup);
}

export default function* root() {
  yield all([fork(watchCurrentGroupClear)]);
}
