export const roles = {
  admin: 1,
  coach: 2,
  leader: 3,
  member: 4,
};

export const roleNames = {
  1: 'Admin',
  2: 'Coach',
  3: 'Team Leader',
  4: 'Member',
};
