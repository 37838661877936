import { useQuery, useMutation, useQueryClient } from 'react-query';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { client, Resource } from 'api';
import getCurrentGroupId from 'store/selectors/getCurrentGroupId';
import { fetchCurrentGroup } from 'store/actions/groupActions';
import queryString from 'query-string';

type LeadDuplicatesResult = {
  next: null | string;
  previous: null | string;
  results: Lead[];
  total: number;
};

export const useLeadDuplicatesQuery = (index: number) => {
  const params = { groupId: useSelector(getCurrentGroupId), index };
  return useQuery<LeadDuplicatesResult, Error>(
    [Resource.LeadDuplicates, index],
    () =>
      client.get(Resource.LeadDuplicates, { params }).then(({ data }) => data),
    { enabled: Boolean(index) }
  );
};

export const useLeadDuplicateQuery = (leadDuplicateId: number) => {
  const params = { groupId: useSelector(getCurrentGroupId) };
  return useQuery<Lead[], Error>(
    [Resource.LeadDuplicates],
    () =>
      client
        .get(Resource.LeadDuplicates + '\\' + leadDuplicateId, { params })
        .then(({ data }) => data),
    { enabled: Boolean(leadDuplicateId) }
  );
};

type DeleteLeadData = { id: number; leadIds: number[]; primaryLeadId?: number };

export const useDeleteLeadsDuplicateMutation = () => {
  const { search } = useLocation();
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const groupId = useSelector(getCurrentGroupId);
  return useMutation(
    ({ id, leadIds, primaryLeadId }: DeleteLeadData) =>
      client.delete(`${Resource.LeadDuplicates}/${id}`, {
        params: { groupId, pnmId: leadIds, primaryPnmId: primaryLeadId },
      }),
    {
      onSuccess: (_, { leadIds }) => {
        const searchObject = queryString.parse(search);
        const index = Number(searchObject.index);
        let previousLeads: Lead[] | undefined;
        if (index) {
          const previousResults = queryClient.getQueryData<
            LeadDuplicatesResult
          >([Resource.LeadDuplicates, index]);
          previousLeads = previousResults?.results;
          if (previousLeads?.length !== leadIds.length) {
            queryClient.setQueryData([Resource.LeadDuplicates, index], {
              ...previousResults,
              results: previousLeads?.filter(
                lead => !leadIds.includes(lead.id)
              ),
            });
          }
        } else {
          previousLeads = queryClient.getQueryData<Lead[]>([
            Resource.LeadDuplicates,
          ]);
          if (previousLeads?.length !== leadIds.length) {
            queryClient.setQueryData(
              [Resource.LeadDuplicates],
              previousLeads?.filter(lead => !leadIds.includes(lead.id))
            );
          }
        }
        if (previousLeads?.length === leadIds.length) {
          dispatch(fetchCurrentGroup({ id: groupId }));
        }
      },
    }
  );
};
