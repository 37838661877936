import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { isEqual } from 'lodash';

// material-ui
import { Grid, Card, CardContent, Box, Hidden } from '@material-ui/core';

// icons
import LocalOffer from '@material-ui/icons/LocalOffer';

// components
import Header from 'components/Header';
import LabelsForm from './LabelsForm';

// action creators
import { fetchTagsAction } from 'store/actions/tagActions';
import { fetchLabelsAction } from 'store/actions/labelActions';

// selectors
import getCurrentGroupId from 'store/selectors/getCurrentGroupId';
import getIsCurrentGroupCouncil from 'store/selectors/getIsCurrentGroupCouncil';

export default function LabelsSettings() {
  let highlightedSubtitle = '';
  const dispatch = useDispatch();

  const { currentGroupId, isCurrentGroupCouncil } =
    useSelector(
      state => ({
        currentGroupId: getCurrentGroupId(state),
        isCurrentGroupCouncil: getIsCurrentGroupCouncil(state),
      }),
      isEqual
    ) || {};

  useEffect(() => {
    if (isCurrentGroupCouncil) {
      dispatch(fetchLabelsAction({ groupId: currentGroupId }));
      return;
    }
    dispatch(fetchTagsAction({ groupId: currentGroupId }));
  }, [currentGroupId, isCurrentGroupCouncil, dispatch]);

  const headerProps = {
    icon: LocalOffer,
    title: 'Manage Chapter Labels',
    subtitle:
      'Customize the name and color of the labels available for categorizing your groups',
    highlightedSubtitle,
  };

  return (
    <Grid item xs>
      <Card
        id='tabContent'
        style={{
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
        }}
      >
        <CardContent>
          <Grid container>
            <Grid item xs>
              <Header {...headerProps} />
            </Grid>

            <Hidden xsDown>
              <Box width={75} />
            </Hidden>

            <Grid item xs={12}>
              <LabelsForm isCurrentGroupCouncil={isCurrentGroupCouncil} />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
}
