import { all } from 'redux-saga/effects';

// sagas
import fetchTasksSaga from './fetchTasksSaga';
import createTaskSaga from './createTaskSaga';
import completeTaskSaga from './completeTaskSaga';
import uncompleteTaskSaga from './uncompleteTaskSaga';
import deleteTaskSaga from './deleteTaskSaga';
import populateTaskSaga from './populateTaskSaga';

const taskSaga = function* rootSaga() {
  yield all([
    fetchTasksSaga(),
    createTaskSaga(),
    completeTaskSaga(),
    uncompleteTaskSaga(),
    deleteTaskSaga(),
    populateTaskSaga(),
  ]);
};

export default taskSaga;
