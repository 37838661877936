export const FORM_FIELD_CREATE_REQUEST = 'FORM_FIELD_CREATE_REQUEST';
export const FORM_FIELD_CREATE = 'FORM_FIELD_CREATE';

export const FORM_FIELD_DELETE_REQUEST = 'FORM_FIELD_DELETE_REQUEST';
export const FORM_FIELD_DELETE = 'FORM_FIELD_DELETE';

export const FORM_FIELD_UPDATE_MANY_REQUEST = 'FORM_FIELD_UPDATE_MANY_REQUEST';
export const FORM_FIELD_UPDATE_MANY = 'FORM_FIELD_UPDATE_MANY';

export const FORM_FIELD_FETCH_REQUEST = 'FORM_FIELD_FETCH_REQUEST';
export const FORM_FIELD_FETCH = 'FORM_FIELD_FETCH';

export const FORM_FIELD_CLEAR = 'FORM_FIELD_CLEAR';
