import React, { useMemo, useCallback, useState } from 'react';
import validate from 'validate.js';
import { useDispatch, useSelector } from 'react-redux';
import { isEqual } from 'lodash';

// final form
import { Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';

// selectors
import getSliceState from 'store/selectors/getSliceState';
import getCombinedForm from 'store/selectors/getCombinedForm';
import getLabels from 'store/selectors/getLabels';

// actions
import { updateTagAction, deleteTagAction } from 'store/actions/tagActions';
import {
  updateLabelAction,
  deleteLabelAction,
} from 'store/actions/labelActions';
import { destroyForm as destroyFormAction } from 'store/actions/formActions';

// material-ui
import { Grid } from '@material-ui/core';

// components
import LabelFields from './LabelFields';
import ActionBar from 'components/ActionBar';
import Button from 'components/Button';

const SCHEMA = {
  title: {
    presence: true,
    length: { maximum: 128 },
  },
  description: {
    length: { maximum: 1024 },
  },
};

const FORM = 'manageLabel';

export interface LabelsFormProps {
  isCurrentGroupCouncil?: boolean;
}

const LabelsForm = ({ isCurrentGroupCouncil }: LabelsFormProps) => {
  const [submittedIds, setSubmittedIds] = useState([]);
  const {
    actions: {
      updateLabel,
      deleteLabel,
      updateLabelGroup,
      deleteLabelGroup,
      destroyForm,
    },
    state: {
      labelsForm: { isSubmitting, hasSubmitFailed, hasSubmitSucceeded },
      label: { data: labels },
      labelGroup,
    },
  } = useRedux(submittedIds);

  const initialLabels = useMemo(
    () =>
      ((isCurrentGroupCouncil ? labelGroup : labels) || []).map(
        (label: Tag) => ({
          ...label,
          color: '#' + label.color,
        })
      ),
    [labels, labelGroup, isCurrentGroupCouncil]
  );

  const updateTag = isCurrentGroupCouncil ? updateLabelGroup : updateLabel;

  const deleteTag = isCurrentGroupCouncil ? deleteLabelGroup : deleteLabel;

  const validateForm = useCallback(
    values => ({
      labels: values.labels.map((status: any) => validate(status, SCHEMA)),
    }),
    []
  );

  const handleFormSubmit = useCallback(
    values => {
      for (const label of initialLabels) {
        const valuesLabel = values.labels.find(
          ({ id }: any) => id === label.id
        );
        if (!valuesLabel) {
          // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '(ids: never[]) => any[]' is not ... Remove this comment to see the full error message
          setSubmittedIds(ids => [...ids, label.id]);
          deleteTag({ formName: FORM + label.id, id: label.id });
        }
        if (valuesLabel && !isEqual(valuesLabel, label)) {
          // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '(ids: never[]) => any[]' is not ... Remove this comment to see the full error message
          setSubmittedIds(ids => [...ids, valuesLabel.id]);
          updateTag({
            formName: FORM + valuesLabel.id,
            id: valuesLabel.id,
            fields: { ...valuesLabel, color: valuesLabel.color.slice(1) },
          });
        }
      }
    },
    [initialLabels, updateTag, deleteTag]
  );
  const handleClose = useCallback(() => {
    for (const id of submittedIds) destroyForm(FORM + id);
    setSubmittedIds([]);
  }, [submittedIds, destroyForm]);
  return (
    <Form
      onSubmit={handleFormSubmit}
      mutators={{ ...arrayMutators }}
      keepDirtyOnReinitialize={isSubmitting}
      initialValues={{ labels: initialLabels }}
      initialValuesEqual={isEqual}
      validate={validateForm}
      render={({ handleSubmit, invalid, pristine, form: { reset } }) => (
        <form onSubmit={handleSubmit}>
          <FieldArray name='labels' isEqual={isEqual}>
            {({ fields }) => (
              <Grid container justifyContent={'flex-end'} spacing={2}>
                {fields.map((prefix, index) => (
                  <Grid item xs={12} key={prefix}>
                    <LabelFields
                      fieldPrefix={prefix}
                      {...fields.value[index]}
                      onDelete={fields.remove}
                    />
                  </Grid>
                ))}
              </Grid>
            )}
          </FieldArray>
          <ActionBar
            open={!pristine || isSubmitting || hasSubmitSucceeded}
            message='Save your changes?'
            onClose={handleClose}
            // @ts-expect-error ts-migrate(2322) FIXME: Type 'number | null' is not assignable to type 'nu... Remove this comment to see the full error message
            autoHideDuration={hasSubmitSucceeded ? 1500 : null}
          >
            <Button disabled={pristine} onClick={reset}>
              Cancel
            </Button>
            <Button
              variant='outlined'
              color='primary'
              type='submit'
              disabled={pristine || invalid || isSubmitting}
              loading={isSubmitting}
              success={hasSubmitSucceeded}
              fail={hasSubmitFailed}
            >
              Save
            </Button>
          </ActionBar>
        </form>
      )}
    />
  );
};

const useRedux = (submittedIds: any) => {
  const dispatch = useDispatch();
  const actions = useMemo(
    () => ({
      updateLabel: (payload: any) => dispatch(updateTagAction(payload)),
      deleteLabel: (payload: any) => dispatch(deleteTagAction(payload)),
      updateLabelGroup: (payload: any) => dispatch(updateLabelAction(payload)),
      deleteLabelGroup: (payload: any) => dispatch(deleteLabelAction(payload)),
      destroyForm: (payload: any) => dispatch(destroyFormAction(payload)),
    }),
    [dispatch]
  );
  const state = useSelector(
    state => ({
      labelsForm: getCombinedForm(submittedIds.map((id: any) => FORM + id))(
        state
      ),
      label: getSliceState('tag')(state),
      labelGroup: getLabels(state),
    }),
    isEqual
  );
  return { actions, state };
};

export default LabelsForm;
