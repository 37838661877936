import React from 'react';

// material-ui
import { withStyles, WithStyles } from '@material-ui/core/styles';
import MuiCheckbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';

import Typography from '@material-ui/core/Typography';

// components
import TextField from 'components/TextField';
import { Field } from 'react-final-form';

//styles
import referrerFieldsStyles from 'routes/ReferralForm/ReferrerFields/referrerFields.style';

export interface ReferrerFieldsProps
  extends WithStyles<typeof referrerFieldsStyles> {
  onCheckboxChange: (...args: any[]) => any;
  referringSomeoneChecked: boolean;
  disabled?: boolean;
}

const ReferrerFields = ({
  classes,
  referringSomeoneChecked,
  onCheckboxChange,
  disabled,
}: ReferrerFieldsProps) => (
  <Grid item xs={12} className={classes.formContent} id='referrerFields'>
    <Grid container>
      <Grid item xs={12}>
        <FormControlLabel
          control={
            <MuiCheckbox
              id='referringSomeoneCheckbox'
              checked={referringSomeoneChecked}
              color='primary'
              disabled={disabled}
              onChange={onCheckboxChange}
            />
          }
          label='I am completing this form for someone else'
        />
      </Grid>

      {referringSomeoneChecked && [
        <Grid item xs={12} key='title'>
          <Typography>Referrer Info</Typography>
        </Grid>,

        <Grid item xs={12} sm={6} key='first' className={classes.leftField}>
          <Field
            name='referrerFirstName'
            component={TextField}
            label='First Name'
            required
          />
        </Grid>,

        <Grid item xs={12} sm={6} key='last' className={classes.rightField}>
          <Field
            name='referrerLastName'
            component={TextField}
            label='Last Name'
            required
          />
        </Grid>,

        <Grid item xs={12} key='email'>
          <Field
            name='referrerEmailAddress'
            component={TextField}
            label='Email'
            required
          />
        </Grid>,
      ]}
    </Grid>
  </Grid>
);

export default withStyles(referrerFieldsStyles, { withTheme: true })(
  ReferrerFields
);
