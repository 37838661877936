import React, { useEffect, useState } from 'react';

// hooks
import { useSelector } from 'react-redux';

// material-ui
import { Grid, Card, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

// components
import UpdateLeadsForm from './UpdateLeadsForm';
import ReportMembersForm from './ReportMembersForm';

// selectors
import getForm from 'store/selectors/getForm';

const REPORT_FORM = 'reportForm';

const ReviewMembers = function() {
  const classes = useStyles();
  const [selectedIds, setSelectedIds] = useState([]);
  const [updateLeadsFormDirty, setUpdateLeadsFormDirty] = useState(false);
  const [updateLeadsFormInvalid, setUpdateLeadsFormInvalid] = useState(false);

  const {
    reportMembersForm: { hasSubmitSucceeded },
  } = useSelector(state => ({
    reportMembersForm: getForm(REPORT_FORM)(state),
  }));

  useEffect(() => {
    if (hasSubmitSucceeded) {
      setSelectedIds([]);
    }
  }, [hasSubmitSucceeded]);

  return (
    <Grid container className={classes.formsContainer} justifyContent='center'>
      <Grid item xs={12}>
        <Card>
          <UpdateLeadsForm
            {...{
              updateLeadsFormDirty,
              setUpdateLeadsFormDirty,
              setUpdateLeadsFormInvalid,
              updateLeadsFormInvalid,
              selectedIds,
              setSelectedIds,
            }}
          />
          <Divider />
          <ReportMembersForm
            {...{
              updateLeadsFormDirty,
              updateLeadsFormInvalid,
              selectedIds,
            }}
          />
        </Card>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles(theme => {
  return {
    formsContainer: {
      height: '100%',
      overflowY: 'scroll',
      overflowX: 'hidden',
      paddingTop: 24,
      paddingBottom: 24,
    },
  };
});

export default ReviewMembers;
