import { all } from 'redux-saga/effects';

// sagas
import fetchThreadsSaga from './fetchThreadsSaga';
import readThreadSaga from './readThreadSaga';
import listenForThreadsSaga from './listenForThreadsSaga';

const threadSaga = function* rootSaga() {
  yield all([fetchThreadsSaga(), readThreadSaga(), listenForThreadsSaga()]);
};

export default threadSaga;
