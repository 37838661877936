import React from 'react';
import classNames from 'classnames';

// MUI
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Grid, { GridItemsAlignment } from '@material-ui/core/Grid';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Typography from '@material-ui/core/Typography';
import Skeleton from '@material-ui/lab/Skeleton';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';
import { SvgIconTypeMap } from '@material-ui/core';

export interface HeaderProps {
  compact?: boolean;
  highlightedSubtitle?: string;
  icon?: OverridableComponent<SvgIconTypeMap>;
  subtitle?: string;
  additionalSubtitle?: string;
  subtitleNode?: React.ReactNode | null;
  id?: string;
  title: string;
  loading?: boolean;
  alignItems?: GridItemsAlignment;
  isBackgroundWhite?: boolean;
}

const Header = function({
  icon,
  id,
  title,
  subtitle,
  additionalSubtitle,
  subtitleNode,
  highlightedSubtitle,
  compact,
  loading,
  alignItems,
  isBackgroundWhite,
}: HeaderProps) {
  const classes = useStyles();
  const theme = useTheme();
  const isExtraSmall = useMediaQuery(theme.breakpoints.only('xs'));
  const MaterialIcon = icon;

  return (
    <Grid
      container
      spacing={2}
      className={classes.headerContainer}
      wrap='nowrap'
      id='headerContainer'
      {...(alignItems ? { alignItems } : {})}
    >
      {icon && (
        <Grid item xs={2} sm='auto' id='icon'>
          <Grid
            container
            alignItems='center'
            justifyContent='center'
            className={
              compact ? classes.compactIconContainer : classes.iconContainer
            }
          >
            {MaterialIcon && (
              <MaterialIcon
                fontSize={isExtraSmall || compact ? 'default' : 'large'}
              />
            )}
          </Grid>
        </Grid>
      )}

      <Grid item xs={10} sm={9} id='headerTitleContainer'>
        <Grid
          container
          direction='row'
          spacing={1}
          className={classNames(
            classes.titleContainer,
            isBackgroundWhite ? classes.titleContainerWhite : null
          )}
        >
          <Grid item xs={12}>
            {loading ? (
              <Skeleton height={40} width={350} />
            ) : (
              <Typography
                variant={isExtraSmall || compact ? 'h6' : 'h5'}
                className={classes.bold}
              >
                {title}
              </Typography>
            )}
          </Grid>

          {subtitleNode && (
            <Grid item xs={12}>
              {loading ? (
                <Skeleton variant='text' width={350} />
              ) : (
                <Typography
                  id={id}
                  variant={compact ? 'subtitle2' : 'body2'}
                  color='textSecondary'
                  className={classNames(
                    compact ? classes.compactText : classes.semibold
                  )}
                  component='div'
                >
                  {subtitleNode}
                </Typography>
              )}
            </Grid>
          )}

          {subtitle && (
            <Grid item xs={12} id='subtitle'>
              {loading ? (
                <Skeleton variant='text' width={350} />
              ) : (
                <Typography
                  id={id}
                  variant={compact ? 'subtitle2' : 'body2'}
                  color='textSecondary'
                  className={classNames(
                    compact ? classes.compactText : classes.semibold
                  )}
                >
                  {subtitle}
                </Typography>
              )}
            </Grid>
          )}

          {additionalSubtitle && (
            <Grid
              item
              xs={12}
              id='additionalSubtitle'
              className={classes.additionalSubtitle}
            >
              {loading ? (
                <Skeleton variant='text' width={350} />
              ) : (
                <Typography
                  id={id}
                  variant={compact ? 'subtitle2' : 'body2'}
                  color='textSecondary'
                  className={classNames(
                    compact ? classes.compactText : classes.semibold
                  )}
                >
                  {additionalSubtitle}
                </Typography>
              )}
            </Grid>
          )}

          {highlightedSubtitle && (
            <Grid item id='highlightedSubtitle'>
              {loading ? (
                <Skeleton variant='text' width={350} />
              ) : (
                <Typography
                  variant={compact ? 'subtitle2' : 'body2'}
                  className={classNames(
                    compact ? classes.compactText : classes.semibold
                  )}
                  color='secondary'
                >
                  {highlightedSubtitle}
                </Typography>
              )}
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles(theme => ({
  headerContainer: {
    marginBottom: 15,
  },
  titleContainer: {
    [theme.breakpoints.only('xs')]: {
      paddingLeft: 8,
    },
  },

  titleContainerWhite: {
    color: theme.palette.common.black,
    '& .MuiTypography-colorTextSecondary': {
      color: theme.palette.common.black,
    },
  },

  iconContainer: {
    borderRadius: 100,
    height: 75,
    width: 75,
    backgroundColor: theme.palette.background.default,
    textAlign: 'center',

    [theme.breakpoints.only('xs')]: {
      height: 50,
      width: 50,
    },
  },
  compactIconContainer: {
    borderRadius: 100,
    height: 44,
    width: 44,
    backgroundColor: theme.palette.background.default,
    textAlign: 'center',
  },
  bold: {
    fontWeight: 800,
  },
  semibold: {
    fontWeight: 600,
  },
  compactText: {
    marginTop: -10,
    fontSize: 13,
  },
  additionalSubtitle: {
    marginTop: 15,
  },
}));

export default Header;
