import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import Immutable from 'immutable';

// action creators
import {
  doneIndicator,
  error,
  errorIndicator,
  loading,
  success,
  successIndicator,
} from 'store/actions/httpActions';

// api
import client from 'sources/api';

// constants
import {
  CURRENT_GROUP_FETCH_REQUEST,
  CURRENT_GROUP_FETCH,
} from 'store/constants/groupTypes';

// helpers
import getResponsePayload from 'helpers/getResponsePayload';
import populateParents from './helpers/populateParents';
import { isEmpty } from 'helpers/check';
import { toString } from 'helpers/transform';
import isNamesListSite from 'helpers/isNamesListSite';

// schema
import { currentGroupSchema } from 'store/schemas/currentGroupSchema';

const WATCH_TYPE = CURRENT_GROUP_FETCH_REQUEST;
const TYPE = CURRENT_GROUP_FETCH;

/*
 *  Handle fetching a group by ID using the `CURRENT_GROUP_FETCH` type
 */
// @ts-expect-error ts-migrate(7010) FIXME: 'fetchCurrentGroup', which lacks return-type annot... Remove this comment to see the full error message
export function* fetchCurrentGroup(action: any) {
  const {
    payload: { id, type },
  } = action;

  if (isEmpty(id) && isEmpty(type)) {
    return;
  }

  // Notify the store that this type is loading
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  yield put(loading(TYPE));

  try {
    // Yield to the request promises
    const [groupResponse, roleResponse] = yield all([
      call(client.get, '/groups', {
        params: {
          ...(id ? { id } : {}),
          ...(!isEmpty(type) ? { type } : {}),
          is_nameslist: toString(isNamesListSite()),
        },
      }),
      ...(id ? [call(client.get, '/roles', { params: { group_id: id } })] : []),
    ]);

    // Populates parents for groups
    // Get ids off each item of the result

    let allParentIds: any = [];
    const { data: { results = [] } = {} } = groupResponse;
    results.forEach((item: any) => {
      allParentIds = allParentIds.concat(item.parents);
    });

    yield populateParents(allParentIds);

    // Set group.roles to the roles results
    groupResponse.data.results[0].roles = roleResponse?.data?.results || [];

    // format and normalize the response
    const payload = getResponsePayload(groupResponse, [currentGroupSchema]);

    // Notify the store that the HTTP call was successful for this type
    yield put(successIndicator(TYPE));

    // Send the response data to the store for this type to be caught by a reducer
    yield put(
      success(
        TYPE,
        Immutable.fromJS({
          ...payload,
        })
      )
    );
  } catch (err) {
    // Notify the store that this type had an error
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 2.
    yield put(errorIndicator(TYPE, err));

    // Dispatch an error payload for this type to be caught by the reducer
    yield put(error(TYPE, err));
  } finally {
    yield put(doneIndicator(TYPE));
  }
}

export function* watch() {
  yield takeEvery(WATCH_TYPE, fetchCurrentGroup);
}

export default function* root() {
  yield all([fork(watch)]);
}
