import { Map } from 'immutable';
// constants
import {
  FORM_FEE_FETCH,
  FORM_ONE_FEE_FETCH,
  FORM_FEE_CREATE,
  FORM_FEE_DELETE,
  FORM_FEE_UPDATE,
} from 'store/constants/formFeeTypes';

// helpers
import httpReducer from 'store/reducers/helpers/httpReducer';
import remove from 'store/reducers/callbacks/remove';
import update from 'store/reducers/callbacks/update';

export default function reduceFormFee(state = Map(), action = {}) {
  switch ((action as any).type) {
    case FORM_FEE_FETCH:
      return httpReducer(state, action, {
        entity: 'formFee',
      });
    case FORM_ONE_FEE_FETCH:
      return httpReducer(state, action, {
        entity: 'formFee',
        appendResult: true,
      });
    case FORM_FEE_CREATE:
      return httpReducer(state, action, {
        appendResult: true,
        entity: 'formFee',
      });
    case FORM_FEE_DELETE:
      return httpReducer(state, action, {
        entity: 'formFee',
        callback: remove,
      });
    case FORM_FEE_UPDATE:
      return httpReducer(state, action, {
        entity: 'formFee',
        callback: update,
      });
    default:
      return state;
  }
}
