import Immutable, { List, Map } from 'immutable';

/*
 *  Set `slice.data` to the first result object that was returned
 *  on the request. This bypasses the `data.byId` and `data.result` stuff
 *  and instead simply sets `slice.data` to a single resource object.
 *
 * This is helpful in keeping slices of the state clean when all we need
 * to do is keep track of a single object, like in the currentUser or currentGroup
 * slices.
 */
export default function mapSingleEntityToData() {
  return (map: any, action = {}) => {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'payload' does not exist on type '{}'.
    const { payload = Map() } = action;
    const entity = payload.get('entity', '');

    const result = payload.getIn(['data', 'result'], '');

    const id = List.isList(result) ? result.first() : result;

    const oldData = map.get('data', Map());
    const newData = Immutable.fromJS(
      payload.getIn(['data', 'entities', entity.toString(), id.toString()])
    );

    map.set('data', oldData.merge(newData));

    return map;
  };
}
