import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import Table from '@material-ui/core/Table';
import Grid from '@material-ui/core/Grid';
import TableBody from '@material-ui/core/TableBody';
import TableCellMui, { TableCellProps } from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import { Star } from '@material-ui/icons';

type MergedLeadsTableProps = {
  leads: Lead[];
  primaryLead: Lead;
};

export default function MergedLeadsTable({
  leads,
  primaryLead,
}: MergedLeadsTableProps) {
  const classes = useStyles();
  const history = useHistory();
  const handleClick = useCallback(
    () => history.push(`/leads/${primaryLead?.id}`),
    [history, primaryLead]
  );
  return (
    <TableContainer component={Paper} className={classes.outlinedTable}>
      <Table aria-label='simple table'>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Email Address</TableCell>
            <TableCell>Phone Number</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {leads.map(lead => (
            <TableRow key={lead.id}>
              <TableCell>
                <Grid container wrap='nowrap'>
                  <Grid item>{lead.fullName}</Grid>
                  <Grid item>
                    {lead.id === primaryLead.id && (
                      <>
                        &nbsp;
                        <Star fontSize='small' color='secondary' />
                      </>
                    )}
                  </Grid>
                </Grid>
              </TableCell>
              <TableCell>{lead.emailAddress}</TableCell>
              <TableCell>{lead.phoneNumber}</TableCell>
            </TableRow>
          ))}
        </TableBody>
        <TableHead onClick={handleClick} className={classes.footer}>
          <TableRow hover>
            <TableCell>{primaryLead?.fullName}</TableCell>
            <TableCell>{primaryLead?.emailAddress}</TableCell>
            <TableCell>{primaryLead?.phoneNumber}</TableCell>
          </TableRow>
        </TableHead>
      </Table>
    </TableContainer>
  );
}

const TableCell = (props: TableCellProps) => {
  const classes = useStyles();
  return <TableCellMui {...props} className={classes.borderTop} />;
};

const useStyles = makeStyles(theme => {
  const border = `1px solid ${
    ((theme as any).palette.background as any).lightPaper
  }`;

  return {
    outlinedTable: {
      borderRadius: 8,
      border,
      borderTop: border,
    },
    borderTop: {
      borderBottom: border,
    },
    footer: {
      borderBottom: 'none',
      cursor: 'pointer',
      background: ((theme as any).palette.background as any).hoverPaper,
      color: 'inherit',
    },
  };
});
