import React, { useMemo, useState, useCallback, useEffect } from 'react';
import moment from 'moment';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useLocation } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { isEqual, uniq } from 'lodash';
import classNames from 'classnames';

// helpers
import { roles } from 'helpers/getRoles';
import { getFormattedDateTime } from 'helpers/getFormattedDateTime';

// MUI components
import {
  Box,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Fade,
  Hidden,
  useMediaQuery,
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

// MUI icons
import {
  Delete,
  Edit,
  Email,
  ExpandMore,
  Message,
  PersonAdd,
  Phone,
  Sms,
  SwapVert,
  GroupAdd,
  Event,
  Group,
} from '@material-ui/icons';

// components
import * as skeletons from './skeletons';
import * as components from './components';
import DeleteActivityModal from './DeleteActivityModal';
import UpdateNoteTagModal from 'components/UpdateNoteTagModal';
import IconButton from 'components/IconButton';
import NoteCreatedDetails from './NoteCreatedDetails';
import MessageDetails from './MessageDetails';
import LeadsDetails from './LeadsDetails';
import MergedLeadsTable from './MergedLeadsTable';
import SkippedLeadsTable from './SkippedLeadsTable';
import EventGuestsCreatedDetails from './EventGuestsCreatedDetails';

// actions
import { deleteNoteAction, fetchNoteAction } from 'store/actions/noteActions';

// selectors
import getPermission from 'store/selectors/getPermission';
import getLoadingFromState from 'store/selectors/getLoadingFromState';
import getSliceEntityById from 'store/selectors/getSliceEntityById';

export default function FeedItem({
  id,
  action,
  actorLabel,
  createdOn,
  currentUserIsAuthor,
  displayTimeline = true,
  displayTopLine = true,
  isLastChild,
  pnmId: leadId,
  pnmLabel: leadLabel,
  isPnmLabelLinkDisabled,
  reportingPlatformName,
  showDate,
  skeleton,
  skeletonType = 'compact',
  targetIds,
  targetLabel,
  sanitizedData,
  totalComments,
}: {
  action?: string;
  actorLabel?: string;
  createdOn?: string;
  currentUserIsAuthor?: boolean;
  displayTimeline?: boolean;
  displayTopLine?: boolean;
  id?: number;
  isLastChild?: boolean;
  leadId?: number;
  leadLabel?: string;
  pnmId?: number;
  pnmLabel?: string;
  reportingPlatformName?: string;
  sanitizedData?: any;
  showDate?: boolean;
  skeleton?: boolean;
  skeletonType?: 'compact' | 'expanded';
  targetIds?: any; // TODO: PropTypes.instanceOf(Array)
  targetLabel?: string;
  totalComments?: number;
  isPnmLabelLinkDisabled?: boolean;
}) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const isExtraSmall = useMediaQuery(theme.breakpoints.only('xs'));
  const enableTextWrap = true;

  // Component state initialization
  const [focused, setFocused] = useState(false);
  const [updateModalOpen, setUpdateModalOpen] = useState(false);

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [expanded, setExpanded] = useState(
    action === 'note.created' || skeletonType === 'expanded'
  );

  // Component state functions
  const enableFocus = useCallback(() => setFocused(true), []);
  const disableFocus = useCallback(() => setFocused(false), []);
  const openDeleteModal = useCallback(() => setDeleteModalOpen(true), []);
  const closeDeleteModal = useCallback(() => setDeleteModalOpen(false), []);
  const openUpdateModal = useCallback(() => setUpdateModalOpen(true), []);
  const closeUpdateModal = useCallback(() => setUpdateModalOpen(false), []);

  const date = getFormattedDateTime(createdOn, 'MMM D, YYYY');
  const time = getFormattedDateTime(createdOn, 'LT');
  const { pathname } = useLocation();
  const permissions = useSelector(
    state => ({
      canDeleteNote: getPermission('note.deleteOne')(state),
      canUpdateNote: getPermission('note.updateOne')(state),
      canDeleteOwnNoteOnly: [roles.member, roles.coach].includes(
        (state as any).getIn(['currentUser', 'data', 'roleId'], roles.member)
      ),
      canUpdateOwnNoteOnly: true,
    }),
    isEqual
  );

  const isNotNestedCard = pathname.includes('home') || pathname === '/';
  const { DateIndicator, ActionIcon, MainHeader, TimeSubHeader } = skeleton
    ? skeletons
    : components;
  const classes = useStyles();

  const {
    icon,
    details,
    actionTitle,
    deleteAction,
    displayDeleteButton,
    displayUpdateButton,
    slice,
    updateActivityModal,
    populateAction,
    ...headerProps
  } = useAction(
    action,
    {
      id,
      totalComments,
      targetIds,
      sanitizedData,
    },
    {
      currentUserIsAuthor,
      reportingPlatformName,
      updateModalOpen,
      closeUpdateModal,
      ...permissions,
    }
  );

  const { populatedData, activityPopulating } = useSelector(
    state => ({
      populatedData: getSliceEntityById(slice, sanitizedData?.id)(state),
      activityPopulating: getLoadingFromState(
        slice,
        sanitizedData?.id,
        false
      )(state),
    }),
    isEqual
  );

  useEffect(() => {
    // Populate any additional data that the activity feed item requires
    if (populateAction && sanitizedData?.id && !populatedData?.id) {
      dispatch(populateAction({ id: sanitizedData?.id }));
    }
  }, [populateAction, dispatch, sanitizedData, populatedData]);

  const handleChange = useCallback(
    () => setExpanded(expanded => Boolean(details) && !expanded),
    [details]
  );

  const handleEditClick = useCallback(
    e => {
      e.stopPropagation();
      openUpdateModal();
    },
    [openUpdateModal]
  );
  const handleDeleteClick = useCallback(
    e => {
      e.stopPropagation();
      openDeleteModal();
    },
    [openDeleteModal]
  );

  return (
    <Grid container spacing={2}>
      {displayTimeline && (
        <Hidden xsDown>
          <Grid item>
            <Box position='relative' width={128} height='100%'>
              <Box position='absolute' top={28}>
                <DateIndicator {...{ date, showDate }} />
              </Box>
              <Box className={classes.dot} />
              <>
                {displayTopLine && (
                  <Box id='topLine' className={classes.topLine} />
                )}
                {!isLastChild && (
                  <Box id='bottomLine' className={classes.bottomLine} />
                )}
              </>
            </Box>
          </Grid>
        </Hidden>
      )}

      <Grid
        item
        xs
        zeroMinWidth
        onMouseEnter={enableFocus}
        onMouseLeave={disableFocus}
      >
        <Box
          clone
          bgcolor={
            isNotNestedCard ? 'background.paper' : 'background.darkPaper'
          }
        >
          <Accordion
            style={{ borderRadius: 8 }}
            expanded={expanded}
            onChange={handleChange}
            TransitionProps={{ mountOnEnter: true }}
          >
            <AccordionSummary
              classes={{
                // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
                root: !details && classes.summaryRoot,
                disabled: classes.disabled,
              }}
            >
              <Grid
                container
                spacing={3}
                alignItems={'center'}
                wrap={!enableTextWrap ? 'nowrap' : 'wrap'}
              >
                <Hidden xsDown>
                  <Grid item>
                    <ActionIcon icon={icon} />
                  </Grid>
                </Hidden>

                <Grid item zeroMinWidth xs>
                  <Grid
                    container
                    direction='column'
                    spacing={1}
                    wrap={!enableTextWrap ? 'nowrap' : 'wrap'}
                  >
                    <Grid item zeroMinWidth>
                      <Grid
                        container
                        wrap={'nowrap'}
                        justifyContent='space-between'
                        alignItems='center'
                      >
                        <Grid item>
                          <MainHeader
                            {...{
                              actorLabel,
                              targetLabel,
                              leadId: leadId || targetIds,
                              leadLabel,
                              isLeadLabelLinkDisabled: isPnmLabelLinkDisabled,
                              wrap: enableTextWrap ? 'wrap' : 'nowrap',
                            }}
                            {...headerProps}
                          />
                        </Grid>

                        <Grid item>
                          {displayUpdateButton && populatedData?.id && (
                            <Fade in={focused}>
                              <IconButton onClick={handleEditClick}>
                                <Edit fontSize='small' />
                              </IconButton>
                            </Fade>
                          )}
                          {displayDeleteButton && populatedData?.id && (
                            <Fade in={focused}>
                              <IconButton
                                onClick={handleDeleteClick}
                                className={classes.deleteButton}
                              >
                                <Delete fontSize='small' />
                              </IconButton>
                            </Fade>
                          )}

                          {details && (
                            <IconButton
                              disableRipple
                              className={classes.iconButton}
                            >
                              <ExpandMore
                                className={classNames(
                                  classes.expandIcon,
                                  expanded
                                    ? classes.expanded
                                    : classes.notExpanded
                                )}
                              />
                            </IconButton>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Grid
                        container
                        justifyContent='space-between'
                        wrap='nowrap'
                      >
                        <TimeSubHeader
                          {...{
                            time:
                              isExtraSmall || !displayTimeline
                                ? `${date} at ${time}`
                                : time,
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </AccordionSummary>

            {skeleton || activityPopulating ? (
              <Box margin={2}>
                <Grid container direction='column' spacing={2}>
                  {Array.from({ length: 4 }, (_, i) => (
                    <Grid item key={i}>
                      <Skeleton variant='rect' height={32} />
                    </Grid>
                  ))}
                </Grid>
              </Box>
            ) : (
              details && <AccordionDetails>{details}</AccordionDetails>
            )}
          </Accordion>
        </Box>
      </Grid>

      {updateActivityModal}

      <DeleteActivityModal
        title={actionTitle}
        deleteAction={deleteAction}
        open={deleteModalOpen}
        idToDelete={sanitizedData?.id}
        onClose={closeDeleteModal}
        slice={slice}
      />
    </Grid>
  );
}

const useAction = (
  action: any,
  { id, totalComments, targetIds, sanitizedData }: any,
  options: any
) =>
  useMemo(
    () =>
      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      ({
        'text_message.received': {
          icon: <Sms />,
          body: 'received a message from',
          details: <MessageDetails {...sanitizedData} type='sms' />,
        },
        'text_messages.created': {
          icon: <Sms />,
          body: 'sent a message to:',
          details: <MessageDetails {...sanitizedData} type='sms' />,
        },
        'text_message.sent': {
          icon: <Sms />,
          body: 'sent a message to:',
          details: <MessageDetails {...sanitizedData} type='sms' />,
        },
        'email_message.created': {
          icon: <Email />,
          body: 'sent an email to',
          details: <MessageDetails {...sanitizedData} type='email' />,
        },
        'email_message.sent': {
          icon: <Email />,
          body: 'sent an email to',
          details: <MessageDetails {...sanitizedData} type='email' />,
        },
        'pnm.created': { icon: <PersonAdd />, body: 'created' },
        'pnm.updated': { icon: <Edit />, body: 'updated' },
        'pnm_status.updated': {
          icon: <SwapVert />,
          body: 'changed the status for',
          isChanged: true,
        },
        'pnm.deleted': { icon: <Delete />, body: 'deleted' },
        'pnms.imported': { icon: <PersonAdd />, body: 'imported leads' },
        'pnms.deleted': {
          icon: <Delete />,
          body: `deleted ${uniq(sanitizedData?.ids)?.length} leads`,
        },
        'pnms.duplicates_merged': {
          icon: <Group />,
          body: `merged ${uniq(sanitizedData?.items)?.length} leads into`,
          details: (
            <MergedLeadsTable
              leads={sanitizedData?.items}
              primaryLead={sanitizedData?.primaryPnm}
            />
          ),
        },
        'pnms.duplicates_skipped': {
          icon: <Group />,
          body: `imported leads, ${
            uniq(sanitizedData?.items)?.length
          } potential duplicates identified and not imported`,
          details: <SkippedLeadsTable leadsData={sanitizedData?.items} />,
        },
        'form.submitted': {
          icon: <PersonAdd />,
          body: 'was referred through the form',
          passive: true,
        },
        'note.created': {
          icon: sanitizedData?.callDuration !== null ? <Phone /> : <Message />,
          body:
            sanitizedData?.callDuration !== null
              ? 'called'
              : 'wrote a note for',
          ending: sanitizedData?.callDuration
            ? 'lasting ' +
              moment.duration(sanitizedData?.callDuration, 'seconds').humanize()
            : null,
          details: (
            <NoteCreatedDetails
              {...sanitizedData}
              activityId={id}
              totalComments={totalComments}
            />
          ),
          slice: 'note',
          actionTitle: 'Note',
          deleteAction: deleteNoteAction,
          populateAction: fetchNoteAction,
          // Enforces deleting any note and deleting only your own notes
          displayDeleteButton:
            (!options.canDeleteOwnNoteOnly && options.canDeleteNote) ||
            (options.canDeleteOwnNoteOnly &&
              options.currentUserIsAuthor &&
              options.canDeleteNote),
          displayUpdateButton: options.canUpdateNote,
          updateActivityModal: (
            <UpdateNoteTagModal
              open={options.updateModalOpen}
              noteId={sanitizedData?.id}
              leadId={sanitizedData?.pnmId}
              onClose={options.closeUpdateModal}
              shouldFetchTags
            />
          ),
        },
        // Using uniq function because API has assigned duplicate IDs in the `data.ids` array for some
        'pnms.reported': {
          icon: <GroupAdd />,
          body: `reported ${uniq(sanitizedData?.ids)?.length || 0} new
                ${
                  uniq(sanitizedData?.ids)?.length > 1
                    ? ' members '
                    : ' member '
                }
                to ${options.reportingPlatformName}`,
          details: (
            <LeadsDetails
              title='New Members'
              leadIds={uniq(sanitizedData?.ids)}
            />
          ),
        },
        'pnm.reported': {
          icon: <PersonAdd />,
          body: 'reported',
          ending: `as a new member to ${options.reportingPlatformName}`,
        },
        'event_guests.created': {
          icon: <Event />,
          body: `invited ${sanitizedData?.length} ${
            sanitizedData?.length > 1 ? 'guests' : 'guest'
          } to`,
          details: <EventGuestsCreatedDetails guests={sanitizedData} />,
        },
        'event_guest.invited': {
          icon: <Event />,
          body: 'was invited to',
          passive: true,
        },
        'pnms.distributed.fail': {
          icon: <PersonAdd />,
          body: 'failed to distribute leads to',
          details:
            sanitizedData &&
            `Error: ${sanitizedData?.error ||
              sanitizedData?.errors?.[0]?.error}`,
        },
        'pnms.distributed.success': {
          icon: <PersonAdd />,
          body: 'successfully distributed leads to',
          details: <LeadsDetails title='New Leads' leadIds={targetIds} />,
        },
      }[action] || {}),
    [
      action,
      options.canDeleteNote,
      options.canDeleteOwnNoteOnly,
      options.canUpdateNote,
      options.closeUpdateModal,
      options.currentUserIsAuthor,
      options.reportingPlatformName,
      options.updateModalOpen,
      id,
      sanitizedData,
      totalComments,
      targetIds,
    ]
  );

const useStyles = makeStyles(theme => ({
  summaryRoot: {
    '&:hover:not($disabled)': {
      cursor: 'default',
    },
  },
  disabled: {},
  deleteButton: {
    marginRight: 8,
  },
  iconButton: {
    margin: -16,
    padding: 16,
    '&:hover': {
      backgroundColor: 'transparent',
    },
    [theme.breakpoints.down('xs')]: {
      position: 'absolute',
      top: 10,
      right: 15,
    },
  },
  expandIcon: {
    transition: theme.transitions.create(['transform'], {
      duration: theme.transitions.duration.short,
    }),
  },
  expanded: {
    transform: 'rotate(-180deg)',
  },
  notExpanded: {
    transform: 'rotate(0)',
  },
  topLine: {
    position: 'absolute',
    right: 24,
    top: -36,
    width: 4,
    height: 64,
    backgroundColor: (theme.palette.background as any).lightPaper,
  },
  bottomLine: {
    position: 'absolute',
    right: 24,
    top: 40,
    width: 4,
    height: '100%',
    backgroundColor: (theme.palette.background as any).lightPaper,
  },
  dot: {
    top: 28,
    right: 18,
    position: 'absolute',
    padding: 9,
    borderRadius: '50%',
    backgroundColor: (theme.palette.background as any).lightPaper,
    [theme.breakpoints.down('xs')]: {
      zIndex: 0,
    },
  },
}));
