import React, { useCallback, useEffect } from 'react';
import { isEqual } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { Map } from 'immutable';

// MUI components
import {
  Grid,
  Card,
  CardContent,
  Hidden,
  WithStyles,
  withStyles,
} from '@material-ui/core';
import { ContactPhone } from '@material-ui/icons';

// components
import Header from 'components/Header';
import Table from 'components/Table';
import ExportButton from 'components/ExportButton';
import ReportRefreshButton from 'components/ReportRefreshButton';

// action creators
import { createPhoneLineDataReport } from 'store/actions/apiTaskActions';

// selectors
import getCurrentGroupId from 'store/selectors/getCurrentGroupId';
import getForm from 'store/selectors/getForm';
import getSliceEntityById from 'store/selectors/getSliceEntityById';
import phoneLineCommunicationStyles from 'routes/CommunicationAnalytics/PhoneLineCommunication.style';

const REPORT_FORM = 'phoneLineDataReportForm';

export interface PhoneLineProps
  extends WithStyles<typeof phoneLineCommunicationStyles> {}

const PhoneLineCommunicationCard = ({ classes }: PhoneLineProps) => {
  const dispatch = useDispatch();

  const {
    currentGroupId,
    report: {
      data: { content = [], meta: { columns = [] } = {} } = {},
      id: reportId,
    },
    form: { isSubmitting },
  } = useSelector(
    state => ({
      currentGroupId: getCurrentGroupId(state),
      form: getForm(REPORT_FORM)(state),
      report: getSliceEntityById(
        'report',
        'ReportTypes.PHONE_LINE_DATA',
        Map()
      )(state),
    }),
    isEqual
  );

  const dispatchCreateReport = useCallback(
    ({ forceRefresh }: { forceRefresh: boolean }) => {
      dispatch(
        createPhoneLineDataReport({
          groupId: currentGroupId,
          formName: REPORT_FORM,
          forceRefresh,
        })
      );
    },
    [dispatch, currentGroupId]
  );

  useEffect(() => {
    dispatchCreateReport({ forceRefresh: false });
  }, [dispatchCreateReport]);

  return (
    <Card>
      <CardContent className={classes.cardContent}>
        <Grid container>
          <Grid item xs>
            <Header
              title='Phone Line Data'
              subtitle='See which linked groups have phone lines setup and the impact'
              icon={ContactPhone}
              compact
            />
          </Grid>

          <Hidden xsDown>
            <Grid item xs={2}>
              <Grid container justifyContent='flex-end'>
                <Grid item>
                  <ReportRefreshButton
                    loading={isSubmitting}
                    onRefresh={dispatchCreateReport}
                  />
                </Grid>
                <ExportButton params={{ reportId }} disabled={isSubmitting} />
              </Grid>
            </Grid>
          </Hidden>

          <Grid item xs={12}>
            <Table
              columns={columns}
              data={content}
              variant='outlined'
              numberOfSkeletonRows={isSubmitting ? 8 : 0}
              TablePaginationProps={{
                count: content.length,
                rowsPerPage: 10,
                rowsPerPageOptions: [10],
              }}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default withStyles(phoneLineCommunicationStyles, { withTheme: true })(
  PhoneLineCommunicationCard
);
