import React from 'react';
import WizzyWigField from 'components/WizzyWigField';

const WizzyWigFieldWithoutClipboard = (props: any) => (
  <WizzyWigField
    clipboard={{
      matchVisual: false,
    }}
    {...props}
  />
);

export default WizzyWigFieldWithoutClipboard;
