import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { List } from 'immutable';
import { isEqual } from 'lodash';
// MUI
import { makeStyles } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
// components
import MegaMenuList from './MegaMenuList';

const MegaMenu = function({
  anchorEl,
  anchorOrigin,
  anchorPosition,
  anchorReference,
  handleClose,
  handlePagination,
  itemProps,
  menuSections,
  paperId,
  transformOrigin,
  PaperProps,
}: any) {
  const classes = useStyles();
  return (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={anchorOrigin}
      anchorPosition={anchorPosition}
      anchorReference={anchorReference}
      classes={{ paper: classes.popover }}
      onClose={handleClose}
      open={Boolean(anchorEl)}
      PaperProps={{
        id: paperId,
        style: { marginTop: 5 },
        ...PaperProps,
      }}
      transformOrigin={transformOrigin}
    >
      <MegaMenuList
        handleClose={handleClose}
        handlePagination={handlePagination}
        menuSections={menuSections}
        itemProps={itemProps}
      />
    </Menu>
  );
};
MegaMenu.propTypes = {
  anchorEl: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.bool]),
  anchorOrigin: PropTypes.instanceOf(Object),
  anchorPosition: PropTypes.instanceOf(Object),
  anchorReference: PropTypes.oneOf(['anchorPosition', 'anchorEl', 'none']),
  handleClose: PropTypes.func,
  handlePagination: PropTypes.func,
  itemProps: PropTypes.instanceOf(Object),
  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'typeof List' is not assignable t... Remove this comment to see the full error message
  menuSections: PropTypes.instanceOf(List).isRequired,
  paperId: PropTypes.string,
  transformOrigin: PropTypes.instanceOf(Object),
  PaperProps: PropTypes.instanceOf(Object),
};
MegaMenu.defaultProps = {
  anchorEl: null,
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left',
  },
  anchorPosition: { top: 10, left: 10 },
  anchorReference: 'anchorEl',
  handleClose: () => {},
  handlePagination: () => {},
  PaperProps: {},
};
const useStyles = makeStyles(theme => ({
  popover: {
    backgroundColor: (theme.palette.background as any).lightPaper,
    paddingTop: 10,
    paddingBottom: 10,
  },
}));
export default memo(MegaMenu, isEqual);
