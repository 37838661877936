import React, { useState, useCallback } from 'react';
import { Map, List } from 'immutable';
import validate from 'helpers/validate';
import { isEqual } from 'lodash';

// hooks
import { useSelector, useDispatch } from 'react-redux';
import { useIsFeatureEnabled } from 'helpers/hooks/featureHooks';

// material-ui
import {
  Card,
  CardActions,
  CardContent,
  Divider,
  FormHelperText,
  Grid,
  Hidden,
  makeStyles,
} from '@material-ui/core';

import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

// icons
import AccountIcon from '@material-ui/icons/SettingsApplications';

// action creator
import { updateCurrentUser } from 'store/actions/userActions';
import { createAndUploadFile } from 'store/actions/fileActions';

// selectors
import getLoadingFromState from 'store/selectors/getLoadingFromState';
import getCurrentUser from 'store/selectors/getCurrentUser';
import getForm from 'store/selectors/getForm';

// components
import Header from 'components/Header';
import { Form, Field } from 'react-final-form';
import TextField from 'components/TextField';
import Button from 'components/Button';
import SuperAvatar from 'components/SuperAvatar';
import EmailsForm from './components/EmailsForm';
import FileInputField from 'components/FileInputField';

// helpers
import formatDisplayedPhoneNumber from 'helpers/formatDisplayedPhoneNumber';

// schemas
const schema = {
  firstName: {
    presence: true,
  },

  lastName: {
    presence: true,
  },

  email: {
    presence: true,
  },

  phoneNumber: {
    validPhoneNumber: true,
  },
};

const ACCOUNT_FORM = 'accountForm';
const PROFILE_IMAGE_FORM = 'profileImageForm';

const AccountCard = function() {
  const {
    form: { hasSubmitFailed, hasSubmitSucceeded, isSubmitting, formError },
    currentUser,
    fileLoading,
  } = useSelector(state => ({
    fileLoading: getLoadingFromState('file', false, false)(state),
    currentUser: getCurrentUser(state),
    form: getForm(ACCOUNT_FORM)(state),
  }));

  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const phoneNumbersEnabled = useIsFeatureEnabled('phone_numbers');

  const isExtraSmall = useMediaQuery(theme.breakpoints.only('xs'));
  const primaryEmail = currentUser
    .get('emails', List())
    .find((e: any) => e.get('primary'), null, Map())
    .get('email');

  const displayedPhoneNumber = formatDisplayedPhoneNumber(
    currentUser.get('phoneNumber', '')
  );

  /* -- File Fetch Flow -- */
  const profilePhotoFileId = currentUser.get('profilePhotoFileId');
  const [imageId, setImageId] = useState(profilePhotoFileId);
  const [isFileConverting, setIsFileConverting] = useState(false);

  const dispatchUpdateCurrentUser = useCallback(
    values => {
      dispatch(updateCurrentUser({ formName: ACCOUNT_FORM, ...values }));
    },
    [dispatch]
  );

  const dispatchCreateAndUploadFile = (file: any) => {
    dispatch(createAndUploadFile({ file, formName: PROFILE_IMAGE_FORM }));
  };

  const handleFileChange = ({ _, newFileId = imageId }: any) => {
    setImageId(newFileId);
  };

  /* -- End File Fetch Flow -- */

  const handleFileConverting = (newIsFileConverting: boolean) => {
    setIsFileConverting(newIsFileConverting);
  };

  const handleClickCancel = (reset: () => void) => () => {
    setImageId(profilePhotoFileId);
    reset();
  };

  const renderAvatar = () => {
    return (
      <SuperAvatar
        size='huge'
        background='dark'
        fullName={currentUser.toJS().fullName}
        imageId={imageId}
        loading={fileLoading || isFileConverting}
      />
    );
  };

  const renderFileInput = () => {
    return (
      <Field
        name='profilePhotoFileId'
        render={props => (
          <FileInputField
            // @ts-expect-error ts-migrate(2322) FIXME: Type '{ input: FieldInputProps<any, HTMLElement>; ... Remove this comment to see the full error message
            fileType='image'
            formName={PROFILE_IMAGE_FORM}
            onChange={handleFileChange}
            onSubmit={dispatchCreateAndUploadFile}
            onFileConverting={handleFileConverting}
            buttonLabel='Choose Profile Picture'
            {...props}
          />
        )}
      />
    );
  };

  return (
    <Grid item xs={12}>
      <Form
        onSubmit={dispatchUpdateCurrentUser}
        validate={values => validate(values, schema)}
        initialValuesEqual={isEqual}
        initialValues={{
          ...currentUser.toJS(),
          profilePhotoFileId,
          email: primaryEmail,
          phoneNumber: displayedPhoneNumber,
        }}
        render={({
          handleSubmit,
          invalid,
          values,
          pristine,
          form: { reset },
        }) => (
          <Card
            id='tabContent'
            className={classes.topCard}
            component='form'
            onSubmit={handleSubmit}
          >
            <CardContent>
              <Header
                id='header'
                icon={AccountIcon}
                title='Account'
                subtitle='Update your profile'
              />
              <Grid container spacing={2}>
                <Grid item xs={12} sm={7}>
                  <Grid
                    id='accountFields'
                    container
                    spacing={isExtraSmall ? 0 : 2}
                  >
                    <Grid item xs={12} sm={6}>
                      <Field
                        name='firstName'
                        component={TextField}
                        label='First Name'
                        required
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <Field
                        name='lastName'
                        component={TextField}
                        label='Last Name'
                        required
                      />
                    </Grid>

                    {phoneNumbersEnabled && (
                      <Grid item xs={12} sm={6}>
                        <Field
                          name='phoneNumber'
                          render={props => (
                            <TextField
                              label='Personal Phone Number'
                              type='tel'
                              {...props}
                            />
                          )}
                        />
                      </Grid>
                    )}

                    <Grid item xs={12} sm={6}>
                      <Field
                        name='email'
                        component={TextField}
                        label='Primary Email'
                        disabled
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Hidden smDown>
                  <Grid item xs={5} id='avatar'>
                    <Grid container justifyContent='center' spacing={2}>
                      <Grid item xs={7}>
                        {renderAvatar()}
                      </Grid>

                      <Grid item xs={7}>
                        {renderFileInput()}
                      </Grid>
                    </Grid>
                  </Grid>
                </Hidden>

                <EmailsForm emails={currentUser.get('emails', List())} />

                <Hidden smUp>
                  <Grid item xs={12}>
                    <Grid
                      container
                      justifyContent='center'
                      alignItems='center'
                      spacing={2}
                    >
                      <Grid item xs={6}>
                        {renderAvatar()}
                      </Grid>
                      <Grid item xs={6}>
                        {renderFileInput()}
                      </Grid>
                    </Grid>
                  </Grid>
                </Hidden>
              </Grid>
            </CardContent>

            <Divider />

            <CardActions>
              <Button disabled={pristine} onClick={handleClickCancel(reset)}>
                Cancel
              </Button>

              <Button
                type='submit'
                color='primary'
                variant='contained'
                disabled={pristine || invalid || isSubmitting}
                loading={isSubmitting}
                success={hasSubmitSucceeded}
                fail={hasSubmitFailed}
              >
                Save
              </Button>

              {formError && (
                <Grid item xs={12}>
                  <FormHelperText error id='errorMessage'>
                    {formError.get('message')}
                  </FormHelperText>
                </Grid>
              )}
            </CardActions>
          </Card>
        )}
      />
    </Grid>
  );
};

const useStyles = makeStyles(theme => ({
  topCard: {
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
  },
}));

export default AccountCard;
