import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

// action creators
import { doneIndicator } from 'store/actions/httpActions';

// api
import client from 'sources/api';

// constants
import { LABEL_FETCH_REQUEST, LABEL_FETCH } from 'store/constants/labelTypes';

// schemas
import { labelSchema } from 'store/schemas/labelSchema';

// helpers
import httpSaga from 'store/sagas/httpSaga';

const WATCH_TYPE = LABEL_FETCH_REQUEST;
const TYPE = LABEL_FETCH;

export function* fetchLabelsSaga(action: any) {
  const {
    payload: { groupId },
  } = action || {};

  // @ts-expect-error ts-migrate(2569) FIXME: Type 'Generator<any, any, unknown>' is not an arra... Remove this comment to see the full error message
  yield* httpSaga(
    TYPE,
    call(client.get, '/tags', {
      params: { group_id: groupId, type: 'group_label' },
    }),
    {
      schema: [labelSchema],
    }
  );

  yield put(doneIndicator(TYPE));
}

export function* watch() {
  yield takeEvery(WATCH_TYPE, fetchLabelsSaga);
}

export default function* root() {
  yield all([fork(watch)]);
}
