import { all, call, fork, put, takeEvery, select } from 'redux-saga/effects';
import Immutable from 'immutable';

// action creators
import { doneIndicator, success } from 'store/actions/httpActions';

// api
import client from 'sources/api';

// constants
import { TASK_DELETE_REQUEST, TASK_DELETE } from 'store/constants/taskTypes';

// selectors
import getCurrentGroupId from 'store/selectors/getCurrentGroupId';

// schemas
import { taskSchema } from 'store/schemas/taskSchema';

// helpers
import httpSaga from 'store/sagas/httpSaga';

const WATCH_TYPE = TASK_DELETE_REQUEST;
const TYPE = TASK_DELETE;

export function* deleteTaskSaga(action: any) {
  const {
    payload: { taskId },
  } = action;

  const currentGroupId = yield select(getCurrentGroupId);

  // @ts-expect-error ts-migrate(2569) FIXME: Type 'Generator<any, any, unknown>' is not an arra... Remove this comment to see the full error message
  yield* httpSaga(
    TYPE,
    call(client.delete, `/todos/${taskId}?group_id=${currentGroupId}`),
    {
      metaId: `delete-${taskId}`,
      schema: taskSchema,
      dispatchSuccess: false,
    }
  );

  yield put(
    success(
      TYPE,
      Immutable.fromJS({
        id: taskId,
      })
    )
  );

  yield put(doneIndicator(TYPE));
}

export function* watch() {
  yield takeEvery(WATCH_TYPE, deleteTaskSaga);
}

export default function* root() {
  yield all([fork(watch)]);
}
