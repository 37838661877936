import React, { useMemo, useEffect } from 'react';
import { isEqual } from 'lodash';

// hooks
import { useDispatch, useSelector } from 'react-redux';

// MUI components
import { Box, Grid, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

// components
import EmptyMessage from 'components/EmptyMessage';
import ToggleChip from 'components/ToggleChip';
import WriteNoteButton from 'components/WriteNoteButton';

// actions
import {
  fetchLeadTagsAction,
  clearLeadTagsAction,
} from 'store/actions/leadTagActions';

// selectors
import getSliceEntityById from 'store/selectors/getSliceEntityById';
import getLoadingFromState from 'store/selectors/getLoadingFromState';
import getSliceState from 'store/selectors/getSliceState';
import getPermission from 'store/selectors/getPermission';

type LeadTagsProps = {
  leadId: number;
};

export default function LeadTags({ leadId }: LeadTagsProps) {
  const {
    state: { loading, allLeadTags, milestones, tags, canCreateNote },
    actions: { fetchLeadTags, clearLeadTags },
  } = useRedux(leadId);
  const [leadMilestones, leadTags] = [milestones, tags].map(tags =>
    tags
      .filter(({ id }: any) => allLeadTags?.hasOwnProperty(id))
      .map((tag: any) => ({
        ...tag,
        count: allLeadTags[tag.id].count,
      }))
  );
  const milestonesTags = [
    { title: 'Milestones', tags: leadMilestones },
    { title: 'Tags', tags: leadTags },
  ];

  useEffect(() => {
    if (leadId) {
      // @ts-expect-error ts-migrate(2554) FIXME: Expected 0 arguments, but got 1.
      fetchLeadTags({ id: leadId });
      return () => {
        // @ts-expect-error ts-migrate(2554) FIXME: Expected 0 arguments, but got 1.
        clearLeadTags({ id: leadId });
      };
    }
  }, [fetchLeadTags, clearLeadTags, leadId]);
  return (
    <Grid container direction='column' spacing={2} style={{ paddingBottom: 8 }}>
      {milestonesTags.map(({ title, tags }) => (
        <Grid item xs key={title}>
          <Box border={1} borderColor='rgba(255,255,255,0.25)' borderRadius={4}>
            <Box marginX={2} marginY={1}>
              <Grid
                container
                justifyContent='space-between'
                alignItems='center'
              >
                <Grid item>
                  <Typography
                    style={{
                      fontWeight: 600,
                      wordWrap: 'break-word',
                    }}
                    variant='subtitle2'
                  >
                    {title}
                  </Typography>
                </Grid>
                {canCreateNote && (
                  <Grid item>
                    <WriteNoteButton withIcon leadId={leadId} />
                  </Grid>
                )}
              </Grid>
            </Box>
            <Box
              component='hr'
              height='1px'
              width='100%'
              border='none'
              bgcolor='rgba(255,255,255,0.25)'
            />
            {!loading && tags.length === 0 ? (
              <EmptyMessage
                title={`No ${title.toLowerCase()} found`}
                description={`Set ${title.toLowerCase()} by creating notes`}
                descriptionColor='secondary'
              />
            ) : (
              <Box marginX={2} marginBottom={1} marginTop={2}>
                <Grid container spacing={2}>
                  {loading
                    ? Array.from({ length: 3 }, (_, i) => (
                        <Grid item key={i} xs={12}>
                          <Skeleton variant='rect' height={30} />
                        </Grid>
                      ))
                    : tags.map(({ id, title, count, color, type }: any) => (
                        <Grid item key={id} zeroMinWidth>
                          <ToggleChip
                            ring={type === 'event'}
                            color={type === 'label' && '#' + color}
                            selected
                            hideSelectIcon
                            disabled
                            // @ts-expect-error ts-migrate(2322) FIXME: Type 'number | false' is not assignable to type 'n... Remove this comment to see the full error message
                            count={type === 'event' && count - 1}
                            disableBadge={type === 'label'}
                          >
                            {title}
                          </ToggleChip>
                        </Grid>
                      ))}
                </Grid>
              </Box>
            )}
          </Box>
        </Grid>
      ))}
    </Grid>
  );
}

const useRedux = (id: any) => {
  const dispatch = useDispatch();
  const actions = useMemo(
    () => ({
      fetchLeadTags: () => dispatch(fetchLeadTagsAction({ id })),
      clearLeadTags: () => dispatch(clearLeadTagsAction({ id })),
    }),
    [dispatch, id]
  );
  const state = useSelector(
    state => ({
      allLeadTags: getSliceEntityById('leadTag', id)(state),
      loading: getLoadingFromState('leadTag', id, false)(state),
      milestones: getSliceState('milestone')(state).data,
      tags: getSliceState('tag')(state).data,
      canCreateNote: getPermission('note.createMany')(state),
    }),
    isEqual
  );
  return { state, actions };
};
