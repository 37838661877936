import React from 'react';
// MUI components
import { Box, Grid, withStyles, WithStyles, Hidden } from '@material-ui/core';
// MUI icons
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import TodayIcon from '@material-ui/icons/Today';
// components
import { DateIndicator } from 'components/ActivityFeed/FeedItem/components';
// helpers
import { numberToCurrency } from 'helpers/transform';
import { getFormattedDateTime } from 'helpers/getFormattedDateTime';
// styles
import paymentItemStyles from 'routes/LeadList/components/PaymentItem/paymentItem.style';

export interface PaymentItemProps extends WithStyles<typeof paymentItemStyles> {
  paymentData: LeadPayments;
  currentDate: number;
  lastDate: number;
}

const PaymentItem = ({
  classes,
  paymentData,
  currentDate,
  lastDate,
}: PaymentItemProps) => {
  const { createdOn, amount, formFeeTitle, paymentIntentId, paymentMethod } =
    paymentData || {};
  const date = createdOn
    ? getFormattedDateTime(createdOn, 'MMM D, YYYY')
    : null;
  const time = createdOn ? getFormattedDateTime(createdOn, 'LT') : '';

  return (
    <Grid container spacing={2}>
      <Hidden xsDown>
        <Grid item>
          <Box position='relative' width={128} height='100%'>
            <Box position='absolute' top={28}>
              {currentDate !== lastDate && (
                <DateIndicator date={date || ''} showDate={!!date} />
              )}
            </Box>
            <Box className={classes.dot} />
            <Box id='topLine' className={classes.topLine} />
            <Box id='bottomLine' className={classes.bottomLine} />
          </Box>
        </Grid>
      </Hidden>
      <Grid item xs zeroMinWidth className={classes.payBox}>
        <Box>
          <div className={classes.formTitle}>
            {formFeeTitle || 'Undefined form'}
          </div>
          <div className={classes.amountBox}>
            <div className={classes.status}>
              <CheckCircleOutlineIcon />
            </div>
            <div className={classes.amount}>
              {amount ? `$${numberToCurrency(Math.round(amount / 100))}` : ''}
            </div>
          </div>
          <div className={classes.textBox}>
            <span className='leftText'>Transaction ID:</span>
            {paymentIntentId || ''}
          </div>
          <div className={classes.textBox}>
            <span className='leftText'>Method:</span>
            {paymentMethod || ''}
          </div>
          <div className={classes.timeBox}>
            <span className='leftIcon'>
              <TodayIcon />
            </span>
            <Hidden smUp>
              {date}
              <span className={classes.nbsp} />
            </Hidden>
            {time}
          </div>
        </Box>
      </Grid>
    </Grid>
  );
};

export default withStyles(paymentItemStyles, { withTheme: true })(PaymentItem);
