import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
// action creators
import { doneIndicator } from 'store/actions/httpActions';
// api
import client from 'sources/api';
// constants
import {
  MESSAGE_FETCH_REQUEST,
  MESSAGE_FETCH,
} from 'store/constants/messageTypes';
// schemas
import { messageSchema } from 'store/schemas/messageSchema';
// helpers
import httpSaga from 'store/sagas/httpSaga';
const WATCH_TYPE = MESSAGE_FETCH_REQUEST;
const TYPE = MESSAGE_FETCH;
export function* fetchMessagesForGroupSaga(action: any) {
  const {
    payload: { groupId: group_id, threadId: thread_id, since },
  } = action;
  const params = {
    group_id,
    thread_id,
  };
  if (since) {
    (params as any).since = since;
  }
  // @ts-expect-error ts-migrate(2569) FIXME: Type 'Generator<any, any, unknown>' is not an arra... Remove this comment to see the full error message
  yield* httpSaga(
    TYPE,
    call(client.get, '/messages', {
      params,
    }),
    {
      schema: [messageSchema],
    }
  );
  yield put(doneIndicator(TYPE));
}
export function* watch() {
  yield takeEvery(WATCH_TYPE, fetchMessagesForGroupSaga);
}
export default function* root() {
  yield all([fork(watch)]);
}
