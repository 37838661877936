export const THREAD_FETCH_REQUEST = 'THREAD_FETCH_REQUEST';
export const THREAD_FETCH = 'THREAD_FETCH';

export const THREAD_CLEAR = 'THREAD_CLEAR';

export const THREAD_READ_REQUEST = 'THREAD_READ_REQUEST';
export const THREAD_READ = 'THREAD_READ';

export const THREAD_START_LISTEN_REQUEST = 'THREAD_START_LISTEN_REQUEST';
export const THREAD_START_LISTEN = 'THREAD_START_LISTEN';

export const THREAD_STOP_LISTEN_REQUEST = 'THREAD_STOP_LISTEN_REQUEST';
export const THREAD_STOP_LISTEN = 'THREAD_STOP_LISTEN';
