import { Styles, Theme } from 'assets/types';

const leadListActionsStyles: Styles = theme => ({
  toolbarContainer: {
    borderBottom: `1px solid ${(theme as Theme).palette.background.paper}`,
    backgroundColor: (theme as Theme).palette.background.darkPaper,
    zIndex: 100,
    marginTop: 0,
    paddingLeft: 16,
    paddingRight: 24,
    padding: 12,
    boxShadow: '0px 0px 10px 5px rgb(0 0 0 / 12%)',

    [theme.breakpoints.down('sm')]: {
      position: 'fixed',
    },
  },
  leftActionsContainer: {
    paddingLeft: 4,
  },
  iconButtonMargin: {
    marginRight: 15,
    marginLeft: 15,
  },
  banner: {
    backgroundColor: theme.palette.primary.main,
    marginTop: -16,
    marginBottom: 16,
  },
  bannerButton: {
    textAlign: 'right',
  },
  spacing: {
    display: 'inline-block',
    width: '1rem',
  },
});

export default leadListActionsStyles;
