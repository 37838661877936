import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

// action creators
import { doneIndicator } from 'store/actions/httpActions';

// api
import client from 'sources/api';

// constants
import {
  REFERRAL_FORM_CREATE_REQUEST,
  REFERRAL_FORM_CREATE,
} from 'store/constants/referralFormTypes';

// schemas
import { referralFormSchema } from 'store/schemas/referralFormSchema';

// helpers
import httpSaga from 'store/sagas/httpSaga';
import { isEmpty } from 'helpers/check';

const WATCH_TYPE = REFERRAL_FORM_CREATE_REQUEST;
const TYPE = REFERRAL_FORM_CREATE;

export function* createReferralForm(action: any) {
  const {
    payload: { formName, groupId: group_id, isUniversal, childIds, ...values },
  } = action;

  // @ts-expect-error ts-migrate(2569) FIXME: Type 'Generator<any, any, unknown>' is not an arra... Remove this comment to see the full error message
  yield* httpSaga(
    TYPE,
    call(client.post, '/forms', {
      group_id,
      ...values,
      ...(!isEmpty(isUniversal) ? { is_universal: isUniversal } : {}),
      ...(typeof childIds !== 'undefined' ? { child_ids: childIds || [] } : {}),
    }),
    {
      schema: referralFormSchema,
      formName,
    }
  );

  yield put(doneIndicator(TYPE));
}

export function* watch() {
  yield takeEvery(WATCH_TYPE, createReferralForm);
}

export default function* root() {
  yield all([fork(watch)]);
}
