import React, { useMemo, useState } from 'react';

// material-ui
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';

// components
import DazzlingDialog from 'components/DazzlingDialog';
import MultiSelectFormControl from 'routes/ReferralForm/FormView/components/MultiSelectFormControl';

//icons
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

//helpers
import { OptionType } from 'routes/ReferralForm/FormView/components/FieldWithTypeMultiSelect/FieldWithTypeMultiSelect';
import { isOrganizationFocusCulturallyBased } from 'helpers/getOrganizationFocus';
import { toString } from 'helpers/transform';

export interface MultiSelectOrganizationFocusesProps {
  options: OptionType[];
  noteClasses: string;
  dazzlingDialogTextClasses: string;
  headerBoxClasses: string;
  handleChangeCheckBox: (itemVal: string | number) => () => void;
  values: (string | number | boolean | null)[];
  formControlLabelClasses?: string;
  className?: string;
  isCustomFieldTypeOfOrganizationForUniversalForm?: boolean;
}

const MultiSelectOrganizationFocuses = ({
  noteClasses,
  dazzlingDialogTextClasses,
  formControlLabelClasses,
  headerBoxClasses,
  options,
  values,
  handleChangeCheckBox,
  isCustomFieldTypeOfOrganizationForUniversalForm,
  className,
}: MultiSelectOrganizationFocusesProps) => {
  const [openHelpModal, setOpenHelpModal] = useState<boolean>(false);

  const getMultiSelectFormControl = ({ label, value }: OptionType) => (
    <Grid item xs={12} className={className}>
      <MultiSelectFormControl
        label={label}
        value={value}
        values={values}
        className={formControlLabelClasses}
        handleChangeCheckBox={handleChangeCheckBox}
        isCustomFieldTypeOfOrganizationForUniversalForm={
          isCustomFieldTypeOfOrganizationForUniversalForm
        }
        noteClasses={noteClasses}
      />
    </Grid>
  );

  const [optionsCulturallyBased, optionsOther] = useMemo<OptionType[][]>(() => {
    const sortByLabel = (
      { label: firstLabel }: OptionType,
      { label: secondLabel }: OptionType
    ) => (firstLabel < secondLabel ? -1 : firstLabel > secondLabel ? 1 : 0);
    const optionsCulturallyBased: OptionType[] = [];
    const optionsOther: OptionType[] = [];
    options.forEach(option => {
      const { value } = option || {};
      if (isOrganizationFocusCulturallyBased(undefined, toString(value))) {
        optionsCulturallyBased.push(option);
      } else {
        optionsOther.push(option);
      }
    });

    return [
      optionsCulturallyBased.sort(sortByLabel),
      optionsOther.sort(sortByLabel),
    ];
  }, [options]);

  const handleOpenCloseHelpCulturallyBased = () => {
    setOpenHelpModal(!openHelpModal);
  };

  return (
    <>
      <Grid item xs={12} md={7}>
        <div className={`${headerBoxClasses} firstHeader`}>
          Culturally Based
          <IconButton onClick={handleOpenCloseHelpCulturallyBased}>
            <InfoOutlinedIcon />
          </IconButton>
        </div>
        <Grid container>
          {optionsCulturallyBased.map(getMultiSelectFormControl)}
        </Grid>
      </Grid>
      <Grid item xs={12} md={5}>
        <div className={headerBoxClasses}>Special Interest</div>
        <Grid container>{optionsOther.map(getMultiSelectFormControl)}</Grid>
      </Grid>

      <DazzlingDialog
        open={openHelpModal}
        headerProps={{
          icon: InfoOutlinedIcon,
          title: 'About Culturally-Based Organizations',
          alignItems: 'center',
        }}
        handleClose={handleOpenCloseHelpCulturallyBased}
        displayActions={false}
      >
        <p className={dazzlingDialogTextClasses}>
          Although these organizations or historically culturally based, they
          are not culturally exclusive. Membership is open to all who meet the
          criteria of said organization.
        </p>
      </DazzlingDialog>
    </>
  );
};

export default MultiSelectOrganizationFocuses;
