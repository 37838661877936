import React, { useState, useCallback, useEffect } from 'react';
import { useHistory } from 'react-router';
import { Map, List } from 'immutable';
import { memoize, isEqual } from 'lodash';
import pluralize from 'pluralize';
import getGroupTypeName from 'helpers/getGroupTypeName';
// hooks
import { useSelector } from 'react-redux';
// material-ui
import {
  Grid,
  Checkbox as MuiCheckbox,
  Tooltip,
  Fade,
  Typography,
  Hidden,
  WithStyles,
  withStyles,
  Box,
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
// icons
import {
  Edit,
  Delete,
  ArrowDropDown,
  LocalOffer,
  GetApp,
  LinkOff,
} from '@material-ui/icons';
// components
import Button from 'components/Button';
import MegaMenu from 'components/MegaMenu';
import UpdateChapterForm from 'routes/ChapterList/ChapterListActions/UpdateChapterForm';
import DeleteChapterForm from 'routes/ChapterList/ChapterListActions/DeleteChapterForm';
import AssignLabelsForm from 'routes/ChapterList/ChapterListActions/AssignLabelsForm';
import ExportChaptersModal from 'routes/ChapterList/ChapterListActions/ExportChaptersModal';
import UnlinkChapterModal from 'routes/ChapterList/ChapterListActions/UnlinkChapterModal';
// selectors
import getPermission from 'store/selectors/getPermission';
import getLoadingFromState from 'store/selectors/getLoadingFromState';
import getIsSystemAdmin from 'store/selectors/getIsSystemAdmin';
import getIsHqAdmin from 'store/selectors/getIsHqAdmin';
import getIsCouncilAdmin from 'store/selectors/getIsCouncilAdmin';
//helpers
import { isEmpty } from 'helpers/check';
import { FiltersInitialParams } from 'routes/ChapterList/helpers/getInitialParams';
//Styles
import chapterListActionsStyles from 'routes/ChapterList/ChapterListActions/chapterListActions.style';

const BULK_ACTIONS = [
  'updateGroup',
  'deleteGroup',
  'exportChapters',
  'unlinkChapter',
];

export interface ChapterListProps
  extends WithStyles<typeof chapterListActionsStyles> {
  allSelected: boolean;
  resetSelected: (...args: any[]) => any;
  data: any; // TODO: PropTypes.instanceOf(Array)
  selectedGroups: number[];
  filters?: FiltersInitialParams;
  search?: string;
  onSelectAll: () => void;
  onSelectAllFetched: () => void;
  chapterTotal: number;
}

const ChapterListActions = ({
  classes,
  allSelected,
  data,
  resetSelected,
  selectedGroups,
  filters,
  search,
  onSelectAll,
  onSelectAllFetched,
  chapterTotal,
}: ChapterListProps) => {
  const history = useHistory();
  const { location: { pathname } = {} } = history;
  const [actionsAnchorEl, setActionsAnchorEl] = useState(null);
  const [modalKey, setModalKey] = useState(null);
  const groupType = getGroupTypeName(pathname);

  const {
    canUpdateGroup,
    canDeleteGroup,
    numTotalGroups,
    groupsLoading,
    isSystemAdmin,
    isHqAdmin,
    isCouncilAdmin,
  } = useSelector(
    state => ({
      canUpdateGroup: getPermission('group.updateOne')(state),
      canDeleteGroup: getPermission('group.deleteOne')(state),
      numTotalGroups: (state as any).getIn(
        ['group', 'meta', 'total'],
        data.length
      ),
      groupsLoading: getLoadingFromState('group', false, false)(state),
      isSystemAdmin: getIsSystemAdmin(state),
      isHqAdmin: getIsHqAdmin(state),
      isCouncilAdmin: getIsCouncilAdmin(state),
    }),
    isEqual
  );

  const handleModalKeyChange = useCallback(
    newKey =>
      memoize(() =>
        setModalKey(oldKey => {
          // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
          if (newKey === null && BULK_ACTIONS.includes(oldKey)) resetSelected();
          return newKey;
        })
      ),
    [resetSelected]
  );

  const selectedChaptersCount = allSelected
    ? chapterTotal - selectedGroups.length
    : selectedGroups.length;

  const areGroupsSelected = selectedGroups.length > 0 || allSelected;
  const groupsLabel = selectedGroups.length === 1 ? 'chapter' : 'chapters';
  const headerCount = allSelected ? 'all' : `${selectedGroups.length} selected`;

  const groupActionsMenu = List([
    Map({
      display: areGroupsSelected,
      sectionHeader: `Actions for ${headerCount} ${
        selectedGroups.length === 1 ? 'group' : 'groups'
      }`,
      id: 'selectedActions',
      items: List([
        Map({
          id: 'updateGroup',
          display:
            canUpdateGroup &&
            (isHqAdmin || isSystemAdmin) &&
            selectedGroups.length === 1,
          label: 'Edit Group',
          icon: <Edit />,
          onClick: handleModalKeyChange('updateGroup'),
        }),
        Map({
          id: 'assignLabels',
          display:
            (isHqAdmin || isSystemAdmin || isCouncilAdmin) &&
            !isEmpty(selectedGroups),
          label: 'Assign Labels',
          icon: <LocalOffer />,
          onClick: handleModalKeyChange('assignLabels'),
        }),
        Map({
          id: 'exportChapters',
          display:
            (isCouncilAdmin || isSystemAdmin) &&
            (!allSelected || (isEmpty(filters) && isEmpty(search))),
          label: 'Export',
          icon: <GetApp />,
          onClick: handleModalKeyChange('exportChapters'),
        }),
        Map({
          id: 'unlinkChapter',
          display: selectedGroups.length === 1,
          label: 'Unlink Chapter',
          icon: <LinkOff />,
          onClick: handleModalKeyChange('unlinkChapter'),
        }),
      ]),
    }),
    Map({
      display: canDeleteGroup && isSystemAdmin && selectedGroups.length === 1,
      sectionHeader: `Delete Actions`,
      id: 'deleteActions',
      items: List([
        Map({
          id: 'deleteGroup',
          label: 'Delete Group',
          icon: <Delete />,
          color: 'primary',
          onClick: handleModalKeyChange('deleteGroup'),
        }),
      ]),
    }),
  ]);

  const menuSectionsToDisplay = groupActionsMenu.filter(item =>
    item.get('display')
  );

  const firstSelectedItem = data.find(
    (item: { id: number }) => item.id === selectedGroups[0]
  );

  const onCheckedToggle = useCallback(() => {
    if (selectedChaptersCount > 0) {
      resetSelected();
    } else {
      onSelectAllFetched();
    }
  }, [onSelectAllFetched, resetSelected, selectedChaptersCount]);

  useEffect(() => {
    if (allSelected || !isEmpty(selectedGroups)) {
      resetSelected();
    }
  }, [chapterTotal]); // eslint-disable-line

  return (
    <Grid
      container
      spacing={3}
      alignItems='center'
      className={classes.toolbarContainer}
    >
      <Grid item xs={6}>
        <Grid container justifyContent='flex-start' alignItems='center'>
          <Hidden xsDown>
            <Tooltip
              id='headerCheckboxTooltip'
              title={
                !isEmpty(search)
                  ? 'Select all disabled when searching'
                  : areGroupsSelected
                  ? 'Deselect All'
                  : 'Select All'
              }
            >
              <MuiCheckbox
                checked={areGroupsSelected}
                color='primary'
                className={(classes as any).selectAllCheckbox}
                indeterminate={areGroupsSelected}
                onChange={onCheckedToggle}
                disabled={!isEmpty(search)}
              />
            </Tooltip>
          </Hidden>

          <Hidden xsDown>
            <Grid item>
              <Grid container spacing={2} alignItems='center' wrap='nowrap'>
                <Grid item>
                  {groupsLoading ? (
                    <Skeleton width={160} height={40} />
                  ) : (
                    data.length > 0 && (
                      <Typography color='textSecondary' variant='subtitle2'>
                        Displaying {numTotalGroups}{' '}
                        {pluralize('chapter', numTotalGroups)}
                      </Typography>
                    )
                  )}
                </Grid>
                {data.length > 0 &&
                  selectedGroups.length === data.length &&
                  data.length !== chapterTotal &&
                  !allSelected && (
                    <Grid item>
                      <Tooltip
                        title={
                          !isEmpty(search)
                            ? 'Select all disabled when searching'
                            : ''
                        }
                      >
                        <Box>
                          <Button
                            onClick={onSelectAll}
                            color={'primary'}
                            variant={'contained'}
                            disabled={!!search}
                          >
                            Select all chapters
                          </Button>
                        </Box>
                      </Tooltip>
                    </Grid>
                  )}
              </Grid>
            </Grid>
          </Hidden>
        </Grid>
      </Grid>

      <Grid item xs={6} id='groupListActions'>
        <Grid container justifyContent='flex-end' alignItems='center'>
          {areGroupsSelected && (
            <Hidden xsDown>
              <Fade in={areGroupsSelected}>
                <Typography
                  variant='body2'
                  id='selectedLabel'
                  className={classes.selectedLabelMargin}
                >
                  {allSelected
                    ? 'All chapters selected'
                    : `${selectedGroups.length} ${groupsLabel} selected`}
                </Typography>
              </Fade>
            </Hidden>
          )}

          <Button
            disabled={!menuSectionsToDisplay.size}
            variant={areGroupsSelected ? 'contained' : 'outlined'}
            color={areGroupsSelected ? 'primary' : 'default'}
            onClick={(e: any) => {
              setActionsAnchorEl(e.currentTarget);
            }}
          >
            Actions
            <ArrowDropDown />
          </Button>
        </Grid>
      </Grid>

      <MegaMenu
        // @ts-expect-error ts-migrate(2322) FIXME: Type '{ id: string; anchorEl: null; handleClose: (... Remove this comment to see the full error message
        id='groupActionsMenu'
        anchorEl={actionsAnchorEl}
        handleClose={() => {
          setActionsAnchorEl(null);
        }}
        menuSections={groupActionsMenu}
      />

      <UpdateChapterForm
        // @ts-expect-error ts-migrate(2322) FIXME: Type '{ id: string; open: boolean; groupType: stri... Remove this comment to see the full error message
        id='updateChapterForm'
        open={modalKey === 'updateGroup'}
        groupType={groupType}
        initialValues={firstSelectedItem}
        handleClose={handleModalKeyChange(null)}
      />

      <DeleteChapterForm
        // @ts-expect-error ts-migrate(2322) FIXME: Type '{ id: string; open: boolean; groupType: stri... Remove this comment to see the full error message
        id='deleteChapterForm'
        open={modalKey === 'deleteGroup'}
        groupType={groupType}
        initialValues={firstSelectedItem}
        handleClose={handleModalKeyChange(null)}
      />

      <AssignLabelsForm
        selectedGroupIds={selectedGroups}
        open={modalKey === 'assignLabels'}
        onClose={handleModalKeyChange(null)}
        data={data}
      />

      <ExportChaptersModal
        open={modalKey === 'exportChapters'}
        onClose={handleModalKeyChange(null)}
        chaptersIds={selectedGroups}
        allSelected={allSelected}
      />

      <UnlinkChapterModal
        groupType={groupType}
        selectedChapter={firstSelectedItem}
        open={modalKey === 'unlinkChapter'}
        onClose={handleModalKeyChange(null)}
      />
    </Grid>
  );
};

export default withStyles(chapterListActionsStyles, { withTheme: true })(
  ChapterListActions
);
