import React, { useContext } from 'react';

// components
import LandingNavigation from '../LandingNavigation';

// material-ui
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

// helpers
import { SiteVisualDataContext } from 'components/SiteVisualData';
import { Theme } from 'assets/types';

const LandingRightSide = function() {
  const {
    secondLogo,
    secondLogoAlt,
    landingRightSideText,
    imageContainerBackgroundImage,
  } = useContext(SiteVisualDataContext);
  const classes = useStyles({ imageContainerBackgroundImage });

  return (
    <Grid
      container
      className={classes.imageContainer}
      justifyContent='space-between'
    >
      <LandingNavigation />

      <Grid item>
        <img className={classes.logo} src={secondLogo} alt={secondLogoAlt} />
        <Typography className={classes.headline} variant='h5'>
          {landingRightSideText}
        </Typography>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles<Theme, { imageContainerBackgroundImage: string }>(
  theme => ({
    imageContainer: {
      backgroundImage: props => `url(${props.imageContainerBackgroundImage})`,
      backgroundPosition: 'right top',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      height: '100%',
      padding: '15px 30px',
    },

    logo: {
      width: '100%',
    },

    headline: {
      fontStyle: 'italic',
      fontWeight: 300,
      textAlign: 'center',
    },
  })
);

export default LandingRightSide;
