import { Styles } from 'assets/types';

const formEditorHeaderImageStyles: Styles = theme => ({
  headerImage: {
    maxHeight: 100,
    textAlign: 'right',
  },

  headerImageContainer: {
    padding: 24,
  },

  alignRight: {
    textAlign: 'right',
  },

  alignCenter: {
    textAlign: 'center',
    [theme.breakpoints.up('lg')]: {
      '&.rightOnLg': {
        textAlign: 'right',
      },
      '&.leftOnLg': {
        textAlign: 'left',
      },
    },
  },

  headerImageSkeleton: {
    margin: 'auto',
  },

  deleteButton: {
    '&.beforeLg': {
      display: 'inline-block',
      marginLeft: 5,
      marginRight: 5,
      verticalAlign: 'baseline',
    },
  },
});

export default formEditorHeaderImageStyles;
