import { useSelector } from 'react-redux';
import { is } from 'immutable';

// selectors
import {
  isSubmitting,
  hasSubmitSucceeded,
  hasSubmitFailed,
  getError,
  getResponseMessage,
} from 'store/selectors/formSelectors';

export function useReduxForm(formName: any) {
  return useSelector(
    state => ({
      isSubmitting: isSubmitting(formName)(state),
      hasSubmitSucceeded: hasSubmitSucceeded(formName)(state),
      hasSubmitFailed: hasSubmitFailed(formName)(state),
      formError: getError(formName)(state),
      successMessage: getResponseMessage(formName)(state),
    }),
    is
  );
}
