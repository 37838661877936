import {
  all,
  cancel,
  cancelled,
  delay,
  fork,
  put,
  select,
  take,
  takeEvery,
} from 'redux-saga/effects';

// action creators
import { doneIndicator } from 'store/actions/httpActions';
import { fetchThreadsAction } from 'store/actions/threadActions';

// selector
import getCurrentGroupId from 'store/selectors/getCurrentGroupId';

// constants
import {
  THREAD_START_LISTEN,
  THREAD_START_LISTEN_REQUEST,
  THREAD_STOP_LISTEN_REQUEST,
} from 'store/constants/threadTypes';

const WATCH_TYPE = THREAD_START_LISTEN_REQUEST;
const TYPE = THREAD_START_LISTEN;

export function* bgSync(search: null | string) {
  const currentGroupId = yield select(getCurrentGroupId);

  try {
    while (true) {
      yield put(
        fetchThreadsAction({
          groupId: currentGroupId,
          search,
          metaId: 'listen',
        })
      );
      yield take('THREAD_FETCH_DONE');
      yield delay(5000);
    }
  } finally {
    if (yield cancelled()) {
      // We don't need anything implemented here now,
      //  this is just here in case we do later on and need to recall the syntax
    }
  }
}

export function* listenForThreadsSaga(action: {
  type: string;
  payload: { search: null | string };
}) {
  // starts the task in the background
  const bgSyncTask = yield fork(bgSync, action.payload.search);

  // wait for the stop action
  yield take(THREAD_STOP_LISTEN_REQUEST);
  // user clicked stop. cancel the background task
  // this will cause the forked bgSync task to jump into its finally block
  yield cancel(bgSyncTask);

  yield put(doneIndicator(TYPE));
}

export function* watch() {
  yield takeEvery(WATCH_TYPE, listenForThreadsSaga);
}

export default function* root() {
  yield all([fork(watch)]);
}
