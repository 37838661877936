import React, { useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import validate from 'validate.js';
import { useParams } from 'react-router-dom';

// material-ui
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import TextInput from '@material-ui/core/TextField';
import Chip from '@material-ui/core/Chip';

// icons
import FormIcon from '@material-ui/icons/Ballot';
import AddCircleIcon from '@material-ui/icons/AddCircle';

//components
import DazzlingDialog from 'components/DazzlingDialog';
import FieldSelector from 'components/FieldSelector';
import TextField from 'components/TextField';
import Switch from 'components/Switch';
import { Form, Field } from 'react-final-form';
import Button from 'components/Button';
import Select from 'components/Select';
import getFieldTypeOptions from 'helpers/getFieldTypeOptions';

// action creators
import { createFormField } from 'store/actions/formFieldActions';
import getCurrentGroupId from 'store/selectors/getCurrentGroupId';

//helpers
import { isEmpty } from 'helpers/check';

const FORM = 'createFormFieldForm';

type Props = {
  onClose: (...args: any[]) => any;
  open: boolean;
  resetFieldsValue?: () => void;
  isCouncilForm?: boolean | null;
  isOrganizationForm?: boolean | null;
};

const CreateFormFieldModal = ({
  open,
  onClose,
  resetFieldsValue,
  isCouncilForm,
  isOrganizationForm,
}: Props) => {
  const { groupId } = useSelector(state => ({
    groupId: getCurrentGroupId(state),
  }));

  const params = useParams();
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'formId' does not exist on type '{}'.
  const { formId } = params;

  const [optionName, setOptionName] = useState('');
  const [optionList, setOptionList] = useState<any[]>([]);

  const handleChange = (e: any) => {
    setOptionName(e.target.value);
  };

  const addOption = (optionName: any) => {
    if (
      optionName &&
      (isEmpty(optionList) ||
        !optionList.find(
          (item: { option: string }) => item.option === optionName
        ))
    ) {
      const newOption = {
        id: Math.floor(Math.random() * 10000),
        option: optionName,
      };
      setOptionList([...optionList, newOption]);
    }
  };

  const removeOption = (id: number) => {
    setOptionList([...optionList.filter(option => option.id !== id)]);
  };

  const handleSubmit = (e: Event) => {
    e.preventDefault();
    addOption(optionName);
    setOptionName('');
  };

  const dispatch = useDispatch();
  const dispatchCreateFormField = useCallback(
    values => {
      const { selectedField = {} } = values || {};
      const newValues = {
        ...(values || {}),
        label: selectedField?.label.trim(),
        options: (Array.isArray(optionList) ? optionList : []).map(
          item => item?.option
        ),
        type: values?.type || 'custom',
      };
      if (newValues?.selectedField) {
        delete newValues.selectedField;
      }
      dispatch(
        createFormField({ groupId, formId, formName: FORM, ...newValues })
      );
    },
    [dispatch, formId, groupId, optionList]
  );

  const dialogContent = (values: any) => {
    return (
      <Grid container spacing={2} direction='column'>
        <Grid item xs={12} sm={6}>
          <Field
            name='selectedField'
            values={values}
            component={FieldSelector}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <Field
            name='description'
            label='Description'
            variant='outlined'
            helperText='This will appear below the field on your form'
            component={TextField}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <Field
            name='fieldType'
            label='Field Type'
            variant='outlined'
            options={getFieldTypeOptions()}
            component={Select}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          {values.fieldType === 'select' ||
          values.fieldType === 'multiselect' ? (
            <Grid container spacing={4} alignItems='center'>
              <Grid item xs={8} sm={6}>
                <TextInput
                  name='options'
                  label='Option Name'
                  value={optionName}
                  onChange={handleChange}
                  variant='outlined'
                />
              </Grid>
              <Grid item xs={8} sm={6}>
                <Button
                  style={{ paddingTop: 14, paddingBottom: 14 }}
                  variant='outlined'
                  size='large'
                  startIcon={<AddCircleIcon />}
                  iconButtonIcon={<AddCircleIcon />}
                  onClick={handleSubmit}
                >
                  Add Option
                </Button>
              </Grid>
              <Grid container>
                {optionList.map((item: { id: number; option: string }) => (
                  <Box
                    key={item?.id}
                    marginLeft={2}
                    marginTop={1}
                    marginBottom={2}
                    marginRight={1}
                  >
                    <Grid item xs={2} sm={4} md={4}>
                      <Chip
                        label={item.option}
                        variant='outlined'
                        onDelete={() => removeOption(item.id)}
                      />
                    </Grid>
                  </Box>
                ))}
              </Grid>
            </Grid>
          ) : null}
        </Grid>

        <Grid item xs={12} sm={6}>
          <Field
            name='required'
            label='Required'
            type='checkbox'
            component={Switch}
          />
        </Grid>
        {!!(isCouncilForm || isOrganizationForm) && (
          <Grid item xs={12} sm={6}>
            <Field
              name='isPublished'
              label='Share with Linked Group'
              type='checkbox'
              component={Switch}
            />
          </Grid>
        )}
      </Grid>
    );
  };

  const handleResetForm = (reset: () => void) => () => {
    reset();
    if (resetFieldsValue) {
      resetFieldsValue();
    }
  };

  const handleClose = () => {
    onClose();
    setOptionList([]);
  };

  return (
    <Form
      id='createFormFieldForm'
      validate={values => validate(values, schema)}
      onSubmit={dispatchCreateFormField}
      render={({ handleSubmit, invalid, values, form: { reset } }) => (
        <DazzlingDialog
          id='createFormField'
          acceptLabel='Create'
          formName={FORM}
          handleClose={handleClose}
          headerProps={dialogData}
          height='auto'
          onAccept={handleSubmit}
          disabled={invalid}
          submitOnEnter={false}
          resetForm={handleResetForm(reset)}
          open={open}
        >
          {dialogContent(values)}
        </DazzlingDialog>
      )}
    />
  );
};

const dialogData = {
  icon: FormIcon,
  title: 'New Form Field',
  subtitle: `Create a field to gather information from leads`,
};

// schemas
const schema = {
  selectedField: {
    presence: true,
  },
};

export default CreateFormFieldModal;
