import { useMutation, useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { client, Resource } from 'api';
import getCurrentGroupId from 'store/selectors/getCurrentGroupId';
import { AxiosError } from 'axios';

export const useCreateCheckoutSessionMutation = (mode: any) => {
  const params = { groupId: useSelector(getCurrentGroupId), mode };
  return useMutation((stripeLineItems: { price: string; quantity: number }[]) =>
    client
      .post(
        Resource.CreateCheckoutSession,
        {
          stripeLineItems,
          returnUrl: `${window.location.origin}/settings/phone?group=${params.groupId}`,
          mode,
        },
        { params }
      )
      .then(({ data }) => data)
  );
};

export function useLeadPaymentQuery(leadId: number) {
  const groupId = useSelector(getCurrentGroupId);
  return useQuery<
    {
      results: LeadPayments[];
      next: string | null;
      previous: string | null;
      total: number;
    },
    AxiosError,
    {
      paymentData: LeadPayments[];
      paymentTotal: number;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
    }
  >(
    [Resource.BillingPayments, groupId, leadId],
    () =>
      client
        .get(Resource.BillingPayments, { params: { groupId, pnm_id: leadId } })
        .then(({ data }) => data),
    {
      keepPreviousData: true,
      select: data => {
        return {
          paymentData: data.results,
          hasNextPage: data.next !== null,
          hasPreviousPage: data.previous !== null,
          paymentTotal: data.total,
        };
      },
    }
  );
}

export function useStripeApiKeyQuery() {
  return useQuery<StripeApiKey, AxiosError, StripeApiKey>(
    [Resource.BillingStripeApiKey],
    () => client.get(Resource.BillingStripeApiKey).then(({ data }) => data),
    { retry: 1, refetchOnWindowFocus: false }
  );
}

interface UpdateSubscriptionPlanResponse {
  clientSecret?: string | null;
  paymentMethod?: string | null;
  msg?: string;
}

export const useUpdateSubscriptionPlanMutation = () => {
  return useMutation<
    UpdateSubscriptionPlanResponse,
    AxiosError<{ msg: string }>,
    { stripePriceId: string; subscriptionId: string }
  >(({ stripePriceId, subscriptionId }) =>
    client
      .patch(Resource.BillingSubscriptionPlan, {
        stripePriceId,
        subscriptionId,
      })
      .then(({ data }) => data)
  );
};

interface UpdateSubscriptionPaymentMethodResponse {
  msg?: string;
  clientSecret?: string | null;
}

export const useUpdateSubscriptionPaymentMethodMutation = () => {
  return useMutation<
    UpdateSubscriptionPaymentMethodResponse,
    AxiosError<{ msg: string }>,
    { groupId: number; paymentMethodId: string; email?: string }
  >(({ groupId, paymentMethodId, email }) =>
    client
      .patch(Resource.BillingSubscriptionPaymentMethod, {
        groupId,
        paymentMethodId,
        ...(email ? { stripeEmail: email } : {}),
      })
      .then(({ data }) => data)
  );
};

interface UpdateSubscriptionDefaultPaymentMethodResponse {
  msg?: string;
}

export const useUpdateSubscriptionDefaultPaymentMethodMutation = () => {
  return useMutation<
    UpdateSubscriptionDefaultPaymentMethodResponse,
    AxiosError<{ msg: string }>,
    { setupIntentId: string; email?: string }
  >(({ setupIntentId, email }) =>
    client
      .patch(Resource.BillingSubscriptionDefaultPaymentMethod, {
        setupIntentId,
        ...(email ? { stripeEmail: email } : {}),
      })
      .then(({ data }) => data)
  );
};

export const useCreateNewSubscriptionMutation = () => {
  return useMutation<
    UpdateSubscriptionPaymentMethodResponse,
    AxiosError<{ msg: string }>,
    {
      groupId: number;
      stripePriceId: string;
      paymentMethodId?: string;
      stripeEmail?: string;
    }
  >(({ groupId, stripePriceId, paymentMethodId, stripeEmail }) =>
    client
      .post(Resource.BillingSubscriptionPlan, {
        groupId,
        stripePriceId,
        paymentMethodId,
        stripeEmail,
      })
      .then(({ data }) => data)
  );
};
