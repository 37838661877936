import { List, Map } from 'immutable';
import createDeepEqualSelector from 'helpers/createDeepEqualSelector';

const getFeatureData = (state: any) =>
  state.getIn(['currentGroup', 'data', 'features'], List()) || List();

export default createDeepEqualSelector(getFeatureData, (features: any) => {
  let formattedFeatures = Map();

  features.forEach((feature: any) => {
    formattedFeatures = formattedFeatures.set(feature.get('name'), feature);
  });

  return formattedFeatures;
});
