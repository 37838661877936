import React from 'react';

// MUI components
import { Grid } from '@material-ui/core';
import LeaderboardTable from 'components/LeaderboardTable';
import MemberOverview from './MemberOverview';

const LeaderboardAnalytics = function() {
  return (
    <Grid container spacing={2} alignContent='flex-start'>
      <Grid item xs={12} md={7}>
        <LeaderboardTable />
      </Grid>
      <Grid item xs={12} md={5}>
        <MemberOverview />
      </Grid>
    </Grid>
  );
};

export default LeaderboardAnalytics;
