import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

// action creators
import { doneIndicator } from 'store/actions/httpActions';

// api
import client from 'sources/api';

// constants
import {
  MEMBER_FETCH_FOR_GROUP_REQUEST,
  MEMBER_FETCH_FOR_GROUP,
} from 'store/constants/memberTypes';

// schemas
import { memberSchema } from 'store/schemas/memberSchema';

// helpers
import httpSaga from 'store/sagas/httpSaga';

const WATCH_TYPE = MEMBER_FETCH_FOR_GROUP_REQUEST;
const TYPE = MEMBER_FETCH_FOR_GROUP;

export function* fetchMembersForGroup(action: any) {
  const {
    payload: {
      accountIds = [],
      appendResult = false,
      groupId,
      metaId,
      page = 1,
      perPage,
      search,
    },
  } = action;

  const queryParams = new URLSearchParams();
  queryParams.set('group_id', groupId);
  queryParams.set('page', page);
  if (perPage) {
    queryParams.set('per_page', perPage);
  }
  if (search) {
    queryParams.set('search', search);
  }

  accountIds.forEach((accountId: any) =>
    queryParams.append('account_id', accountId)
  );

  try {
    // @ts-expect-error ts-migrate(2569) FIXME: Type 'Generator<any, any, unknown>' is not an arra... Remove this comment to see the full error message
    yield* httpSaga(
      TYPE,
      call(client.get, '/members', {
        params: queryParams,
      }),
      {
        schema: [memberSchema],
        metaId,
        reducerOptions: {
          appendResult,
        },
      }
    );
  } finally {
    yield put(doneIndicator(TYPE));
  }
}

export function* watch() {
  yield takeEvery(WATCH_TYPE, fetchMembersForGroup);
}

export default function* root() {
  yield all([fork(watch)]);
}
