import { Styles } from 'assets/types';

const assignLabelsFormStyles: Styles = theme => ({
  link: {
    color: theme.palette.secondary.main,
    cursor: 'pointer',
    fontWeight: 'bold',
  },
});

export default assignLabelsFormStyles;
