import React, { useCallback, useMemo, memo, useEffect } from 'react';
import { isEqual } from 'lodash';
import pluralize from 'pluralize';
import { Map } from 'immutable';
import humps from 'humps';

// hooks and helpers
import { useLocation } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import download from 'helpers/download';
import queryString from 'query-string';
import { DEFAULT_LEADS_ORDER_BY } from 'api/leads';

// components
import DazzlingDialog from 'components/DazzlingDialog';
import MultiAutocomplete from 'components/MultiAutocomplete';
import Checkbox from 'components/Checkbox';
import { Field, Form } from 'react-final-form';

// material-ui
import Grid from '@material-ui/core/Grid';

// icons
import GetApp from '@material-ui/icons/GetApp';

// action creators
import { createExportAction } from 'store/actions/apiTaskActions';
import { fetchGroupFields as fetchGroupFieldsAction } from 'store/actions/groupFieldActions';
import { clearFileExportById as clearFileExportByIdAction } from 'store/actions/fileExportActions';
//selectors
import getSliceState from 'store/selectors/getSliceState';
import getSliceEntityById from 'store/selectors/getSliceEntityById';
import getForm from 'store/selectors/getForm';
import getCurrentGroup from 'store/selectors/getCurrentGroup';
//helpers
import getExportDataOptions from 'routes/LeadList/helpers/getExportDataOptions';

const FORM = 'exportLeadsForm';
const EXPORT_TYPE = 'leads';
const MULTIPLE_FILTER_KEYS = [
  'statusId',
  'tagId',
  'milestoneId',
  'excludedTagId',
  'excludedMilestoneId',
];

type OwnExportLeadsModalProps = {
  open: boolean;
  onClose: (...args: any[]) => any;
  leadIds?: any; // TODO: PropTypes.instanceOf(Array)
  allSelected?: boolean;
  isCouncilLeadPage?: boolean | null;
};

// @ts-expect-error ts-migrate(2456) FIXME: Type alias 'ExportLeadsModalProps' circularly refe... Remove this comment to see the full error message
type ExportLeadsModalProps = OwnExportLeadsModalProps &
  typeof ExportLeadsModal.defaultProps;

// @ts-expect-error ts-migrate(7022) FIXME: 'ExportLeadsModal' implicitly has type 'any' becau... Remove this comment to see the full error message
const ExportLeadsModal = ({
  open,
  onClose,
  allSelected,
  leadIds,
  isCouncilLeadPage,
}: ExportLeadsModalProps) => {
  const {
    actions: { createExport, fetchGroupFields, clearFileExportById },
    data: {
      currentGroup,
      voteMethod,
      groupField: { data, loading } = {},
      fileExport: { downloadUrl } = {},
      form: { hasSubmitFailed } = {},
    },
  } = useRedux();

  useEffect(() => {
    if (downloadUrl) {
      download(downloadUrl);
      clearFileExportById({ exportId: 'leads' });
    }
  }, [clearFileExportById, downloadUrl]);

  const handleFetchGroupFields = useCallback(
    value => {
      fetchGroupFields({ groupId: currentGroup.get('id'), search: value });
    },
    [currentGroup, fetchGroupFields]
  );

  const { search } = useLocation();

  const onSubmit = useCallback(
    ({ groupFields = [], ...restOfValues }) => {
      const filters = queryString.parse(search);
      delete filters.group;
      if (!filters.orderBy) {
        filters.orderBy = DEFAULT_LEADS_ORDER_BY;
      }
      filters.orderBy = humps.decamelize(filters.orderBy as string);

      for (const filter in filters) {
        if (
          MULTIPLE_FILTER_KEYS.includes(filter) &&
          typeof filters[filter] === 'string'
        ) {
          // @ts-expect-error (2322) FIXME: Type '(string | null)[]' is not assignable to type 'string'
          filters[filter] = [filters[filter]];
        }
      }

      const leadDataKeys = [];
      for (const property in restOfValues) {
        const key = humps.decamelize(property);
        const val = restOfValues[property];
        if (val) {
          leadDataKeys.push(key);
        }
      }

      const leadFieldKeys = groupFields.map(({ label }: any) => label);

      const params = {
        leadsIds: allSelected ? 'all' : leadIds,
        leadsIncludeFields: [...leadDataKeys, ...leadFieldKeys],
        ...filters,
      };

      if (allSelected && leadIds.length > 0) {
        // @ts-expect-error ts-migrate(2456) FIXME
        params.excludedIds = leadIds;
      }

      createExport({
        groupId: currentGroup.get('id'),
        exportType: EXPORT_TYPE,
        formName: FORM,
        ...params,
      });
    },
    [allSelected, leadIds, createExport, currentGroup, search]
  );
  const currentVoteMethod = useMemo(
    () =>
      voteMethod.data.find(
        (vm: any) => vm.id === currentGroup.get('voteMethodId')
      ),
    [voteMethod.data, currentGroup]
  );

  const dataOptions = getExportDataOptions(isCouncilLeadPage);

  return (
    <Form
      id='exportLeadsForm'
      onSubmit={onSubmit}
      initialValues={{
        emailAddress: true,
        firstName: true,
        lastName: true,
        mainContactFullName: true,
        phoneNumber: true,
        status: true,
      }}
      render={({ handleSubmit, invalid, form: { restart } }) => {
        return (
          <DazzlingDialog
            id='exportLeadsModal'
            disabled={invalid}
            alertProps={{
              message: hasSubmitFailed
                ? 'Export Failed'
                : 'Exported Successfully',
            }}
            formName={FORM}
            handleClose={() => {
              restart();
              onClose();
            }}
            open={open}
            onAccept={handleSubmit}
            headerProps={{
              icon: GetApp,
              title: 'Export',
              subtitle: 'Fine tune the data that you need for each lead',
              highlightedSubtitle: allSelected
                ? 'All leads selected'
                : `${leadIds.length} ${pluralize(
                    'lead',
                    leadIds.length
                  )} selected`,
            }}
            resetForm={restart}
          >
            <Grid container spacing={2} id='dialogContent'>
              {dataOptions.map(
                ({ name, label }) =>
                  (name !== 'endorsements' ||
                    (currentVoteMethod as any)?.scoreAlgorithm !== 'sum') && (
                    <Grid item xs={6} key={name}>
                      <Field
                        name={name}
                        component={Checkbox}
                        label={label}
                        type='checkbox'
                      />
                    </Grid>
                  )
              )}

              <Grid item xs={8}>
                <Field
                  name='groupFields'
                  render={props => (
                    <MultiAutocomplete
                      label='Select Fields'
                      readOnly
                      options={data}
                      loading={loading}
                      onFetch={handleFetchGroupFields}
                      {...props}
                    />
                  )}
                  margin='none'
                  autoFocus
                  required
                />
              </Grid>
            </Grid>
          </DazzlingDialog>
        );
      }}
    />
  );
};

const useRedux = () => {
  const dispatch = useDispatch();
  const actions = useMemo(
    () => ({
      createExport: (payload: any) => dispatch(createExportAction(payload)),
      fetchGroupFields: (payload: any) =>
        dispatch(fetchGroupFieldsAction(payload)),
      clearFileExportById: (payload: any) =>
        dispatch(clearFileExportByIdAction(payload)),
    }),
    [dispatch]
  );
  const data = useSelector(
    state => ({
      currentGroup: getCurrentGroup(state),
      voteMethod: getSliceState('voteMethod')(state) as any,
      groupField: getSliceState('groupField')(state),
      fileExport: getSliceEntityById('fileExport', EXPORT_TYPE, Map())(state),
      form: getForm(FORM)(state),
    }),
    isEqual
  );
  return { actions, data };
};

ExportLeadsModal.defaultProps = {
  leadIds: [],
};

export default memo(ExportLeadsModal);
