export default function getTotalKey(type: any) {
  switch (type) {
    default:
    case 1:
      return 'total';
    case 2:
      return 'greekChapterTotal';
    case 3:
      return 'greekOrganizationTotal';
    case 4:
      return 'councilTotal';
  }
}
