import React from 'react';
import classNames from 'classnames';

// material-ui
import { makeStyles, Box } from '@material-ui/core';

type Props = {
  children: any;
  borderColorVariant?: 'default' | 'light' | 'dark' | 'white';
  borderRadiusVariant?: 4 | 8;
};

export default function OutlinedCard({
  children,
  borderColorVariant,
  borderRadiusVariant,
}: Props) {
  const classes = useStyles();

  const getBorderColor = () => {
    switch (borderColorVariant) {
      case 'default':
      default:
        return classes.default;
      case 'light':
        return classes.light;
      case 'dark':
        return classes.dark;
      case 'white':
        return classes.white;
    }
  };

  const getBorderRadius = () => {
    switch (borderRadiusVariant) {
      case 4:
      default:
        return classes.borderFour;
      case 8:
        return classes.borderTen;
    }
  };

  return (
    <Box
      className={classNames(
        classes.container,
        getBorderColor(),
        getBorderRadius()
      )}
    >
      {children}
    </Box>
  );
}
const useStyles = makeStyles(theme => {
  return {
    container: {
      padding: 16,
    },

    default: {
      border: `1px solid ${(theme.palette.background as any).outline}`,
    },
    light: {
      border: `1px solid ${(theme.palette.background as any).lightPaper}`,
    },
    dark: {
      border: `1px solid ${(theme.palette.background as any).darkPaper}`,
    },
    white: {
      border: `1px solid ${(theme.palette.common as any).white}`,
    },

    borderFour: {
      borderRadius: 4,
    },
    borderTen: {
      borderRadius: 8,
    },
  };
});
