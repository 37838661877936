import {
  THREAD_FETCH_REQUEST,
  THREAD_READ_REQUEST,
  THREAD_START_LISTEN_REQUEST,
  THREAD_STOP_LISTEN_REQUEST,
  THREAD_CLEAR,
} from 'store/constants/threadTypes';

export function fetchThreadsAction({
  groupId,
  page = 1,
  pnmRecipientId,
  search,
  metaId,
}: any) {
  return {
    type: THREAD_FETCH_REQUEST,
    payload: {
      groupId,
      page,
      pnmRecipientId,
      search,
      metaId,
    },
  };
}

export function clearThreadsAction() {
  return {
    type: THREAD_CLEAR,
  };
}

export function readThreadAction({ groupId, threadId }: any) {
  return {
    type: THREAD_READ_REQUEST,
    payload: { groupId, threadId },
  };
}

export function startListeningForThreadsAction({
  search,
}: {
  search: null | string;
}) {
  return {
    type: THREAD_START_LISTEN_REQUEST,
    payload: { search },
  };
}

export function stopListeningForThreadsAction() {
  return {
    type: THREAD_STOP_LISTEN_REQUEST,
  };
}
