import { getClient } from 'sources/api';

export const client = getClient({ decamelizeRequest: true });

export enum Resource {
  GroupKey = '/group_key',
  Notifications = '/notifications',
  CreateCheckoutSession = '/billing/create_checkout_session',
  BillingPayments = '/billing/payments',
  BillingSubscriptionPlan = '/billing/stripe_subscription',
  BillingSubscriptionPaymentMethod = '/billing/payment_method',
  BillingSubscriptionDefaultPaymentMethod = '/billing/default_payment_method',
  BillingStripeApiKey = '/billing/stripe_api_key',
  StripeProducts = '/products',
  File = '/files',
  LeadDuplicates = '/pnm_duplicates',
  LeadAttrs = '/pnm_attrs',
  LeadTags = '/pnm_tags',
  Leads = '/pnms',
  EventGuests = '/event_guests',
  EventTimeSlots = '/event_time_slots',
  Tasks = '/tasks',
  Groups = '/groups',
  Account = '/accounts',
  Campus = '/campuses',
  AuthIntegrations = '/auth_integrations',
  AuthIntegrationsFull = '/auth_integrations/full',
  AuthIntegrationsSessionId = '/auth_integrations/generate_unique_session_id',
}
