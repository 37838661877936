import {
  FORM_FEE_FETCH_REQUEST,
  FORM_ONE_FEE_FETCH_REQUEST,
  FORM_FEE_CREATE_REQUEST,
  FORM_FEE_DELETE_REQUEST,
  FORM_FEE_UPDATE_REQUEST,
} from 'store/constants/formFeeTypes';

export function fetchFormFeeAction({ groupId }: { groupId: number }) {
  return {
    type: FORM_FEE_FETCH_REQUEST,
    payload: { groupId },
  };
}

export function fetchOneFormFeeAction({
  groupId,
  formId,
  token,
}: {
  groupId: number;
  formId: number;
  token?: string | null;
}) {
  return {
    type: FORM_ONE_FEE_FETCH_REQUEST,
    payload: { groupId, formId, token },
  };
}

export function createFormFeeAction({
  formName,
  groupId,
  formId,
  title,
  amount,
}: {
  formName: string;
  groupId: number;
  formId: number;
  title: string;
  amount: number;
}) {
  return {
    type: FORM_FEE_CREATE_REQUEST,
    payload: { formName, groupId, formId, title, amount },
  };
}

export function deleteFormFeeAction({
  formName,
  groupId,
  formId,
  feeId,
}: {
  formName: string;
  groupId: number;
  formId: number;
  feeId: number;
}) {
  return {
    type: FORM_FEE_DELETE_REQUEST,
    payload: {
      formName,
      groupId,
      formId,
      feeId,
    },
  };
}

export function updateFormFeeAction({
  formName,
  groupId,
  formFees,
}: {
  formName: string;
  groupId: number;
  formFees: FormFee[];
}) {
  return {
    type: FORM_FEE_UPDATE_REQUEST,
    payload: { formName, groupId, formFees },
  };
}
