import { useEffect, useRef } from 'react';

/* super helpful guide to useRef as a way to get previous state/props: 
    https://blog.logrocket.com/how-to-get-previous-props-state-with-react-hooks/
*/
export function usePrevious(value: any) {
  // the `value` can be state or props
  const ref = useRef(value); // initializes ref to initial value

  // useEffect is invoked after the component renders, giving the ref a value
  useEffect(() => {
    ref.current = value;
  });

  // we return an undefined value the first time, then get current and previous values ever render onward
  return ref.current;
}
