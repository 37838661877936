export const MESSAGE_CREATE_REQUEST = 'MESSAGE_CREATE_REQUEST';
export const MESSAGE_CREATE = 'MESSAGE_CREATE';

export const MESSAGE_FETCH_REQUEST = 'MESSAGE_FETCH_REQUEST';
export const MESSAGE_FETCH = 'MESSAGE_FETCH';

export const MESSAGE_CLEAR = 'MESSAGE_CLEAR';

export const MESSAGE_START_LISTEN_REQUEST = 'MESSAGE_START_LISTEN_REQUEST';
export const MESSAGE_START_LISTEN = 'MESSAGE_START_LISTEN';

export const MESSAGE_STOP_LISTEN_REQUEST = 'MESSAGE_STOP_LISTEN_REQUEST';
export const MESSAGE_STOP_LISTEN = 'MESSAGE_STOP_LISTEN';
