import { Grid, Typography } from '@material-ui/core';
import DazzlingDialog from 'components/DazzlingDialog';

interface Props {
  onDelete: (data: AuthIntegration) => void;
  onClose?: () => void;
  open?: boolean;
  integration?: AuthIntegration | null;
}

export function DeleteIntegrationDialog({
  open,
  integration,
  onDelete,
  onClose,
}: Props) {
  return (
    <DazzlingDialog
      open={open ?? false}
      acceptLabel='Delete'
      onAccept={() => {
        if (!integration) {
          return;
        }
        onDelete(integration);
      }}
      handleClose={() => onClose?.()}
      headerProps={{
        title: 'Delete Integration',
      }}
    >
      <Grid container>
        <Typography variant='body1'>
          Are you sure that you want to delete the integration '
          {integration?.label}'?
        </Typography>
      </Grid>
    </DazzlingDialog>
  );
}
