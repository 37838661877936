import React, { useState, useCallback } from 'react';
import { Theme } from 'assets/theme';

// material-ui
import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@material-ui/core';
import TableCellMui, { TableCellProps } from '@material-ui/core/TableCell';
import { makeStyles } from '@material-ui/core/styles';

type SkippedLeadsTableProps = {
  leadsData: {
    firstName: string;
    lastName: string;
    emailAddress: string;
    phoneNumber: string;
    row: number;
  }[];
};

const ROWS_PER_PAGE = 5;

export default function SkippedLeadsTable({
  leadsData,
}: SkippedLeadsTableProps) {
  const classes = useStyles();
  const [page, setPage] = useState<number>(0);

  const handlePageChange = useCallback(
    (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
      event?.preventDefault();
      event?.stopPropagation();
      setPage(newPage);
    },
    []
  );

  return (
    <div className={classes.mainContainer}>
      <TableContainer component={Paper} className={classes.outlinedTable}>
        <Table aria-label='skipped leads table'>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Email Address</TableCell>
              <TableCell>Phone Number</TableCell>
              <TableCell align='center'>Row Number</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {leadsData
              .slice(page * ROWS_PER_PAGE, page * ROWS_PER_PAGE + ROWS_PER_PAGE)
              .map(leadData => (
                <TableRow key={leadData.row}>
                  <TableCell>
                    {leadData.firstName + ' ' + leadData.lastName}
                  </TableCell>
                  <TableCell>{leadData.emailAddress}</TableCell>
                  <TableCell>{leadData.phoneNumber}</TableCell>
                  <TableCell align='center'>{leadData.row}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[ROWS_PER_PAGE]}
        component='div'
        count={leadsData.length}
        rowsPerPage={ROWS_PER_PAGE}
        page={page}
        onPageChange={handlePageChange}
      />
    </div>
  );
}

const TableCell = (props: TableCellProps) => {
  const classes = useStyles();
  return <TableCellMui {...props} className={classes.borderTop} />;
};

const useStyles = makeStyles(theme => {
  const border = `1px solid ${(theme as Theme).palette.background.lightPaper}`;

  return {
    mainContainer: {
      width: '100%',
    },
    outlinedTable: {
      borderRadius: 8,
      border,
      borderTop: border,
    },
    borderTop: {
      borderBottom: border,
    },
  };
});
