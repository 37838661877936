import React from 'react';
// MUI components
import Grid from '@material-ui/core/Grid';
//components
import PaymentsCard from 'routes/PaymentAnalytics/PaymentsCard';

const PaymentAnalytics = function() {
  return (
    <Grid container spacing={2} alignContent='flex-start'>
      <Grid item xs={12}>
        <PaymentsCard />
      </Grid>
    </Grid>
  );
};

export default PaymentAnalytics;
