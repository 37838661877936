export const PHONE_NUMBER_CREATE_REQUEST = 'PHONE_NUMBER_CREATE_REQUEST';
export const PHONE_NUMBER_CREATE = 'PHONE_NUMBER_CREATE';
export const PHONE_NUMBER_CREATE_DONE =
  'PHONE_NUMBER_CREATE_DONE'; /* For refetching groupFeatureUsage slice with updated values */

export const PHONE_NUMBER_UPDATE_REQUEST = 'PHONE_NUMBER_UPDATE_REQUEST';
export const PHONE_NUMBER_UPDATE = 'PHONE_NUMBER_UPDATE';

export const PHONE_NUMBER_DELETE_REQUEST = 'PHONE_NUMBER_DELETE_REQUEST';
export const PHONE_NUMBER_DELETE = 'PHONE_NUMBER_DELETE';
export const PHONE_NUMBER_DELETE_DONE =
  'PHONE_NUMBER_DELETE_DONE'; /* For refetching groupFeatureUsage slice with updated values */

export const PHONE_NUMBER_TOGGLE_CALL_FORWARDING_REQUEST =
  'PHONE_NUMBER_TOGGLE_CALL_FORWARDING_REQUEST';
export const PHONE_NUMBER_TOGGLE_CALL_FORWARDING =
  'PHONE_NUMBER_TOGGLE_CALL_FORWARDING';

export const PHONE_NUMBER_FETCH_FOR_GROUP_REQUEST =
  'PHONE_NUMBER_FETCH_FOR_GROUP_REQUEST';
export const PHONE_NUMBER_FETCH_FOR_GROUP = 'PHONE_NUMBER_FETCH_FOR_GROUP';

export const PHONE_NUMBER_GET_AVAILABLE_TWILIO_REQUEST =
  'PHONE_NUMBER_GET_AVAILABLE_TWILIO_REQUEST';
export const PHONE_NUMBER_GET_AVAILABLE_TWILIO =
  'PHONE_NUMBER_GET_AVAILABLE_TWILIO';
