export function getTableColumns(
  pathname: any,
  isSystemAdmin: any,
  currentGroupId: any,
  isCurrentGroupOrganization: any,
  orderingDisabled: boolean
) {
  let columns: any = [];

  if (pathname.includes('chapters')) {
    if (isSystemAdmin && !currentGroupId) {
      columns = [
        {
          label: 'Designation',
          key: 'name',
        },
        { label: 'Organization', key: 'organization', disableSort: true },
        { label: 'Campus', key: 'campus' },
        { label: 'Council', key: 'council', disableSort: true },
        { label: 'Latest Activity', key: 'latestActivityOn' },
        { label: 'Leads', key: 'leadsCount' },
        { label: 'Joiners', key: 'joinersCount' },
        { label: 'Members', key: 'membersCount' },
      ];
    } else if (isCurrentGroupOrganization) {
      columns = [
        {
          label: 'Designation',
          key: 'name',
        },
        { label: 'Council', key: 'council', disableSort: true },
        { label: 'Labels', key: 'labels', disableSort: true },
        { label: 'Latest Activity', key: 'latestActivityOn' },
        { label: 'Leads', key: 'leadsCount' },
        { label: 'Joiners', key: 'joinersCount' },
        { label: 'Members', key: 'membersCount' },
      ];
    } else {
      columns = [
        {
          label: 'Designation',
          key: 'name',
        },
        { label: 'Organization', key: 'organization', disableSort: true },
        { label: 'Council', key: 'council', disableSort: true },
        { label: 'Latest Activity', key: 'latestActivityOn' },
        { label: 'Leads', key: 'leadsCount' },
        { label: 'Joiners', key: 'joinersCount' },
        { label: 'Members', key: 'membersCount' },
      ];
    }
  } else if (pathname.includes('organizations') && isSystemAdmin) {
    columns = [
      {
        label: 'Name',
        key: 'name',
      },
      {
        label: 'Chapters',
        key: 'childrenCount',
        tooltipTitle: 'All chapters, regardless if linked to dashboard',
        disableSort: true,
      },
      { label: 'HQ Admins', key: 'membersCount' },
    ];
  } else if (pathname.includes('councils') && isSystemAdmin) {
    columns = [
      {
        label: 'Name',
        key: 'name',
      },
      {
        label: 'Campus',
        key: 'campus',
      },
      {
        label: 'Chapters',
        key: 'childrenCount',
        disableSort: true,
        tooltipTitle: 'All chapters, regardless if linked to dashboard',
      },
      { label: 'Council Admins', key: 'membersCount' },
    ];
  }

  if (isSystemAdmin) {
    columns.unshift({ label: 'ID', key: 'id' });
  }
  if (orderingDisabled)
    columns.forEach((column: any) => {
      column.disableSort = true;
    });

  return columns;
}
