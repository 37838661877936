import { isArray } from './check';
import { toInt10 } from './transform';

const getLeadFilterParams = (
  currentValue?: number[] | false,
  value?: string
) => {
  const newCurrentValue =
    currentValue && isArray(currentValue) ? [...currentValue] : [];
  newCurrentValue.push(toInt10(value));
  return newCurrentValue;
};

export default getLeadFilterParams;
