import React, { useCallback, useMemo, useEffect } from 'react';
import { isEqual } from 'lodash';
import moment, { Moment } from 'moment';

// helpers
import { getFormattedDateTime } from 'helpers/getFormattedDateTime';
import { getComparator } from 'components/Table/helpers/sortFunctions';

// hooks
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

// MUI components
import { makeStyles } from '@material-ui/core/styles';
import Podium from './Podium';
import { Button, Grid, Card, CardContent, Typography } from '@material-ui/core';
import {
  Call,
  Email,
  GroupAdd,
  InsertChart,
  NoteAdd,
  Person,
  Schedule,
  Sms,
  SwapVert,
  UnfoldMore,
} from '@material-ui/icons';

// components
import AnalyticsActionBar from 'components/AnalyticsActionBar';
import Header from 'components/Header';
import Table from 'components/Table';

// action creators
import { createGroupLeaderboardReport } from 'store/actions/apiTaskActions';

// selectors
import getCurrentGroupId from 'store/selectors/getCurrentGroupId';
import getForm from 'store/selectors/getForm';
import getSliceEntityById from 'store/selectors/getSliceEntityById';

const FORM = 'groupLeaderboardReportForm';
const INITIAL_START_DATE = moment().subtract(7, 'days');
const INITIAL_END_DATE = moment();
LeaderboardTable.defaultProps = { rowsPerPage: 10 };

type OwnProps = {
  rowsPerPage?: number;
};

type LeaderboardMember = {
  accountId: number;
  accountName: string;
  callsCreated: number;
  emailSent: number;
  latestActivityDate: string;
  notesCreated: number;
  pnmStatusesUpdated: number;
  pnmsCreated: number;
  points: number;
  textsSent: number;
};

type Props = OwnProps & typeof LeaderboardTable.defaultProps;

export default function LeaderboardTable({ rowsPerPage }: Props) {
  const classes = useStyles();
  const history = useHistory();
  const {
    location: { pathname, search },
  } = history;

  const onAnalyticsPage = pathname.includes('analytics');
  const dispatch = useDispatch();
  const initialOrder = 'desc';
  const initialOrderBy = 'status';
  const urlAccountId = new URLSearchParams(search).get('account');

  const {
    currentGroupId,
    report: {
      data: { content: rawContent = [], meta: { columns = [] } = {} } = {},
    } = {},
    form: { isSubmitting },
  } = useSelector(
    state => ({
      currentGroupId: getCurrentGroupId(state),
      form: getForm(FORM)(state),
      report: getSliceEntityById(
        'report',
        'ReportTypes.GROUP_LEADERBOARD',
        {}
      )(state),
    }),
    isEqual
  );

  const content = useMemo(
    () =>
      rawContent.map((row: LeaderboardMember) => ({
        ...row,
        latestActivityDate: getFormattedDateTime(
          row.latestActivityDate,
          'MMM D, YYYY'
        ),
      })),
    [rawContent]
  );

  const navigateToAnalytics = useCallback(() => {
    history.push({ pathname: '/analytics/leaderboard', search });
  }, [history, search]);

  const tableData = useMemo(() => {
    if (!content) return [];

    if (isSubmitting || !content.length) return [];

    const tableRows = content.map(
      (
        { accountId, accountName, points, ...rest }: LeaderboardMember,
        index: number
      ) => ({
        key: accountId,
        rank: index + 1,
        accountName: (
          <>
            <Typography variant='body2'>{accountName}</Typography>
            <Typography variant='caption' color='secondary'>
              {points} points
            </Typography>
          </>
        ),
        latestActivityDateSort: moment(rest.latestActivityDate).unix(),
        ...rest,
      })
    );

    return tableRows;
  }, [content, isSubmitting]);

  const getOrderBy = (orderBy: string): string => {
    switch (orderBy) {
      case 'latestActivityDate':
        return 'latestActivityDateSort';
      default:
        return orderBy;
    }
  };

  const sortingComparator = (order: boolean | string, orderBy: string) =>
    getComparator(order, getOrderBy(orderBy));

  const formattedColumns = columns.map((column: any) => {
    return {
      ...column,
      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      label: icons[column.key],
      tooltipTitle: column.label,
    };
  });

  formattedColumns.unshift({ key: 'rank', label: '', disableSort: false });

  // Date Range calls onDateChange on initial render, so this will trigger our first fetch too
  const dispatchCreateReport = useCallback(
    ({
      startDate,
      endDate,
      forceRefresh,
    }: {
      startDate: Moment;
      endDate: Moment;
      forceRefresh: boolean;
    }) => {
      dispatch(
        createGroupLeaderboardReport({
          groupId: currentGroupId,
          startDate,
          endDate,
          forceRefresh,
          formName: FORM,
        })
      );
    },
    [currentGroupId, dispatch]
  );

  useEffect(() => {
    // Fetch the report on the home page, since we don't display the DateRange component there
    if (!onAnalyticsPage) {
      dispatchCreateReport({
        startDate: INITIAL_START_DATE,
        endDate: INITIAL_END_DATE,
        forceRefresh: false,
      });
    }
  }, []); // eslint-disable-line

  const setOverviewMember = useCallback(
    rowData => {
      const newSearch = new URLSearchParams(search);
      newSearch.set('account', rowData.key);
      history.push({ pathname, search: newSearch?.toString() });
    },
    [history, pathname, search]
  );

  return (
    <Card>
      <CardContent className={classes.cardContent}>
        <Grid container justifyContent='space-between'>
          {onAnalyticsPage ? (
            <Grid item xs={12} sm>
              <Grid container justifyContent='flex-end'>
                <Grid item xs={11} className={classes.dateRangeContainer}>
                  <AnalyticsActionBar
                    onCreateReport={dispatchCreateReport}
                    title='Leaderboard'
                    subtitle='Select a member to view activity in-depth'
                    icon={InsertChart}
                    loading={isSubmitting}
                  />
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <>
              <Grid item xs={12} sm>
                <Header
                  title='Leaderboard'
                  subtitle='Displaying past 14 days of activity'
                  icon={InsertChart}
                  compact
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={1}
                md={3}
                xl={2}
                className={classes.moreContainer}
              >
                <Button
                  id='moreButton'
                  startIcon={<UnfoldMore />}
                  variant='outlined'
                  onClick={navigateToAnalytics}
                >
                  More
                </Button>
              </Grid>
            </>
          )}

          <Grid item xs={12}>
            <Podium data={content} />
          </Grid>

          <Grid item xs={12}>
            <Table
              columns={formattedColumns}
              data={tableData}
              variant='outlined'
              numberOfSkeletonRows={isSubmitting ? 8 : 0}
              initialOrder={initialOrder}
              initialOrderBy={initialOrderBy}
              TableRowProps={{
                className: classes.tableRow,
                onRowClick: onAnalyticsPage ? setOverviewMember : null,
                // @ts-expect-error ts-migrate(2322) FIXME: Type '{}' is not assignable to type 'never'.
                selectedItems: { [urlAccountId]: true },
              }}
              TableCellProps={{
                className: classes.tableCell,
              }}
              HeaderTableCellProps={{
                className: classes.headerTableCell,
              }}
              TablePaginationProps={{
                count: content.length,
                rowsPerPage,
                rowsPerPageOptions: [10],
              }}
              sortingComparator={sortingComparator}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

const icons = {
  accountName: <Person />,
  notesCreated: <NoteAdd />,
  pnmStatusesUpdated: <SwapVert />,
  textsSent: <Sms />,
  emailsSent: <Email />,
  callsCreated: <Call />,
  pnmsCreated: <GroupAdd />,
  latestActivityDate: <Schedule />,
};

const useStyles = makeStyles(theme => ({
  cardContent: {
    paddingLeft: 24,
    paddingRight: 24,
  },
  dateItem: {
    marginRight: 3,
  },
  toItem: {
    marginRight: 12,
    marginLeft: 8,
  },

  tableRow: {
    cursor: 'pointer',
  },

  tableCell: {
    paddingTop: 10,
    paddingBottom: 10,
  },

  headerTableCell: {
    color: theme.palette.text.secondary,
  },

  noneFoundContainer: {
    border: `2px solid ${theme.palette.background.paper}`,
    borderRadius: 4,
    padding: 20,
  },
  dateRangeContainer: {
    [theme.breakpoints.only('xs')]: {
      paddingRight: '20%',
      marginBottom: 24,
    },
  },
  moreContainer: {
    textAlign: 'right',
    [theme.breakpoints.only('xs')]: {
      textAlign: 'center',
      marginBottom: 24,
    },
  },
}));
