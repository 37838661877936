import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import history from 'helpers/history';

// action creators
import { doneIndicator, error } from 'store/actions/httpActions';
import {
  destroyForm,
  startFormSubmit,
  setFormSubmitFailed,
  setFormSubmitSucceeded,
} from 'store/actions/formActions';

// api
import client from 'sources/api';

// constants
import {
  FORGOT_PASSWORD_UPDATE,
  FORGOT_PASSWORD_UPDATE_REQUEST,
} from 'store/constants/forgotPasswordTypes';

// lib
import httpSaga from 'store/sagas/httpSaga';

// helpers
import isNamesListSite from 'helpers/isNamesListSite';

const WATCH_TYPE = FORGOT_PASSWORD_UPDATE_REQUEST;
const TYPE = FORGOT_PASSWORD_UPDATE;
const FORM = 'forgotPasswordForm';

export function* updateForgotPassword(action: any) {
  yield put(startFormSubmit(FORM));

  const {
    payload: { password, token },
  } = action;

  try {
    // @ts-expect-error ts-migrate(2569) FIXME: Type 'Generator<any, any, unknown>' is not an arra... Remove this comment to see the full error message
    yield* httpSaga(
      TYPE,
      call(client.post, '/auth/reset_forgot_password', {
        new_password: password,
        token,
        is_nameslist: isNamesListSite(),
      }),
      {
        throwError: true,
      }
    );

    yield put(setFormSubmitSucceeded(FORM));
    yield put(destroyForm(FORM));
    yield history.push('/login');
  } catch (err) {
    yield all([
      yield put(error(TYPE, err)),
      // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
      yield put(setFormSubmitFailed(FORM)),
    ]);
  } finally {
    yield put(doneIndicator(TYPE));
  }
}

export function* watch() {
  yield takeEvery(WATCH_TYPE, updateForgotPassword);
}

export default function* root() {
  yield all([fork(watch)]);
}
