/*
 *  Handles merging the new and old states.
 *    Built for situations like referral form tokens, where the payload only has one attribute that we want
 */
export default function merge(map: any, action: any, options = {}) {
  const { payload } = action;
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'entity' does not exist on type '{}'.
  const { entity, result: resultKey = 'result' } = options;

  let newState = map;

  const newEntity = entity || payload.get('entity');
  const id = payload.getIn(['data', resultKey]);

  if (id) {
    const updatedItem = payload.getIn([
      'data',
      'entities',
      newEntity,
      id.toString(),
    ]);

    newState = newState.mergeIn(['data', 'byId', id.toString()], updatedItem);
  }
  return newState;
}
