import React from 'react';

// MUI components
import { Grid } from '@material-ui/core';
import LeadsOverviewCard from './LeadsOverviewCard';
import LeadsInfoCard from './LeadsInfoCard';
import LeadsMainContactCard from './LeadsMainContactCard';

const LeadsAnalytics = function() {
  return (
    <Grid container spacing={2} alignContent='flex-start'>
      <Grid item xs={12} sm={5} md={4} lg={3}>
        <LeadsOverviewCard />
      </Grid>
      <Grid item xs={12} sm={7} md={8} lg={9}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <LeadsInfoCard />
          </Grid>
          <Grid item xs={12}>
            <LeadsMainContactCard />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default LeadsAnalytics;
