import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import moment from 'moment';

// components
import BlockQuote from 'components/BlockQuote';
import Button from 'components/Button';

// material-ui
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
  Avatar,
  Badge,
  CircularProgress,
  Accordion as MuiAccordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  withStyles,
  makeStyles,
  Fade,
} from '@material-ui/core';

import RequestIcon from '@material-ui/icons/DoubleArrow';

// helpers
import { Theme } from 'assets/types';
import getQuotes from 'components/BlockQuote/helpers/getQuotes';
import getRandomNumberInRange from 'helpers/getRandomNumberInRange';

const Accordion = withStyles(theme => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    boxShadow: 'none',
  },
  // @ts-expect-error ts-migrate(2322) FIXME: Type '{ children?: ReactNode; } | { children?: Rea... Remove this comment to see the full error message
}))(props => <MuiAccordion {...props} />);

const WaitingRoomContent = function({
  fetchRequestsForUser,
  requests,
  requestLoading,
}: any) {
  const [expandRequests, setExpandRequests] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    fetchRequestsForUser();
  }, [fetchRequestsForUser]);

  const quotes = useMemo(() => getQuotes(), []);
  const quote = useMemo(
    () => quotes[getRandomNumberInRange(0, quotes.length)],
    [quotes]
  );

  return (
    <Grid item className={classes.contentContainer}>
      {requestLoading ? (
        <Grid container id='progress'>
          <Grid item>
            <CircularProgress classes={{ root: classes.progress }} />
          </Grid>
        </Grid>
      ) : (
        <Fade in={!requestLoading}>
          <Grid container direction='column'>
            {/* @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call. */}
            <Grid
              item
              id='panel'
              component={Accordion}
              className={classes.outlined}
              expanded={expandRequests}
              onChange={() => {
                setExpandRequests(!expandRequests);
              }}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Badge
                  badgeContent={requests.size}
                  classes={{ badge: classes.badge }}
                >
                  <Button className={classes.bold}>View your requests</Button>
                </Badge>
              </AccordionSummary>
              <AccordionDetails className={classes.topOutline}>
                <List>
                  {requests.map((request: any) => {
                    return (
                      <ListItem key={request.get('id')} id='listItem'>
                        <ListItemAvatar>
                          <Avatar className={classes.avatar}>
                            <RequestIcon />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText>
                          {`${request.getIn(['groupId', 'displayName'])} `}
                          {`submitted on ${moment(
                            request.get('createdOn')
                          ).format('MMMM Do, YYYY')}`}
                        </ListItemText>
                      </ListItem>
                    );
                  })}
                </List>
              </AccordionDetails>
            </Grid>

            {/* @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call. */}
            <Grid item component={Accordion} expanded={!expandRequests}>
              <AccordionSummary style={{ display: 'none' }} />
              <AccordionDetails classes={{ root: classes.blockquoteContainer }}>
                <BlockQuote
                  id='blockQuote'
                  content={quote.content}
                  author={quote.author}
                  subtitle={quote.subtitle}
                />
              </AccordionDetails>
            </Grid>
          </Grid>
        </Fade>
      )}
    </Grid>
  );
};

WaitingRoomContent.propTypes = {
  fetchRequestsForUser: PropTypes.func.isRequired,
  requestLoading: PropTypes.bool.isRequired,
  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'typeof List' is not assignable t... Remove this comment to see the full error message
  requests: PropTypes.instanceOf(Immutable.List).isRequired,
};

const useStyles = makeStyles<Theme>(theme => ({
  avatar: {
    backgroundColor: theme.palette.common.white,
    '& svg': {
      color: theme.palette.background.landing,
    },
  },
  bold: {
    fontWeight: 'bold',
    fontSize: 15,
  },
  badge: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
  },

  contentContainer: {
    minWidth: '100%',
  },

  blockquoteContainer: {
    padding: 12,
    backgroundColor: theme.palette.background.landing,
  },
  topOutline: {
    borderTopColor: theme.palette.common.white,
    borderTopWidth: 1,
    borderTopStyle: 'solid',
  },
  outlined: {
    padding: '5px 0 5px !important',
    borderRadius: 4,
    borderColor: theme.palette.common.white,
    borderWidth: 1,
    borderStyle: 'solid',
    backgroundColor: theme.palette.background.landing,
  },
  progress: {
    color: theme.palette.common.white,
  },
}));

export default WaitingRoomContent;
