export const EVENT_CREATE_REQUEST = 'EVENT_CREATE_REQUEST';
export const EVENT_CREATE = 'EVENT_CREATE';

export const EVENT_UPDATE_REQUEST = 'EVENT_UPDATE_REQUEST';
export const EVENT_UPDATE = 'EVENT_UPDATE';

export const EVENT_DELETE_REQUEST = 'EVENT_DELETE_REQUEST';
export const EVENT_DELETE = 'EVENT_DELETE';

export const EVENT_FETCH_FOR_GROUP_REQUEST = 'EVENT_FETCH_FOR_GROUP_REQUEST';
export const EVENT_FETCH_FOR_GROUP = 'EVENT_FETCH_FOR_GROUP';

export const EVENT_FETCH_BY_ID_REQUEST = 'EVENT_FETCH_BY_ID_REQUEST';
export const EVENT_FETCH_BY_ID = 'EVENT_FETCH_BY_ID';

export const CLEAR_EVENT = 'CLEAR_EVENT';
