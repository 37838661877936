import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

// action creators
import { doneIndicator } from 'store/actions/httpActions';

// api
import client from 'sources/api';

// constants
import {
  GROUP_VALUE_FETCH_REQUEST,
  GROUP_VALUE_FETCH,
} from 'store/constants/groupValueTypes';

// schemas
import { groupValueSchema } from 'store/schemas/groupValueSchema';

// helpers
import httpSaga from 'store/sagas/httpSaga';

const WATCH_TYPE = GROUP_VALUE_FETCH_REQUEST;
const TYPE = GROUP_VALUE_FETCH;

export function* fetchGroupValues(action: any) {
  const {
    payload: { groupId },
  } = action;

  // @ts-expect-error ts-migrate(2569) FIXME: Type 'Generator<any, any, unknown>' is not an arra... Remove this comment to see the full error message
  yield* httpSaga(
    TYPE,
    call(client.get, '/group_values', {
      params: { group_id: groupId },
    }),
    {
      schema: [groupValueSchema],
    }
  );

  yield put(doneIndicator(TYPE));
}

export function* watch() {
  yield takeEvery(WATCH_TYPE, fetchGroupValues);
}

export default function* root() {
  yield all([fork(watch)]);
}
