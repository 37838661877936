import React, { useEffect, useCallback, SetStateAction } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

// material-ui
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ArrowDownward from '@material-ui/icons/ArrowDownward';

// components
import Table from 'components/Table';
import Button from 'components/Button';

// action creators
import { fetchGroups, clearGroups } from 'store/actions/groupActions';
import { fetchTagsAction } from 'store/actions/tagActions';

// helpers
import { usePrevious } from 'helpers/hooks/usePrevious';
import useInfiniteScroll from 'helpers/hooks/useInfiniteScroll';
import useGroupFetchParams from 'helpers/hooks/useGroupFetchParams';
import getGroupPaginationKey from './helpers/getGroupPaginationKey';
import { getTableColumns } from './helpers/getTableColumns';
import getGroupTypeName from 'helpers/getGroupTypeName';

// selectors
import getCurrentGroupId from 'store/selectors/getCurrentGroupId';
import getIsCurrentGroupOrganization from 'store/selectors/getIsCurrentGroupOrganization';
import getIsCurrentGroupSystem from 'store/selectors/getIsCurrentGroupSystem';
import getIsSystemAdmin from 'store/selectors/getIsSystemAdmin';
import getIsHqAdmin from 'store/selectors/getIsHqAdmin';
import getLoadingFromState from 'store/selectors/getLoadingFromState';
import getNextPageFromState from 'store/selectors/getNextPageFromState';

// styles
import { makeStyles } from '@material-ui/styles';
import { Theme } from 'assets/types';

type Props = {
  allSelected?: boolean;
  loading?: boolean;
  data: Group[];
  selectedGroups: Group[];
  setSelectedGroups: (...args: SetStateAction<Group[]>[]) => void;
};

export default function GroupListTable({
  data,
  loading = false,
  allSelected,
  selectedGroups,
  setSelectedGroups,
}: Props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    location: { pathname, search },
  } = history;
  const paginationMetaId = getGroupPaginationKey(pathname);

  const {
    currentGroupId,
    isSystemAdmin,
    isHqAdmin,
    isCurrentGroupOrganization,
    isCurrentGroupSystem,
    groupNextPage,
    currentGroupLoading,
  } = useSelector(
    state => ({
      currentGroupId: getCurrentGroupId(state),
      isSystemAdmin: getIsSystemAdmin(state),
      isHqAdmin: getIsHqAdmin(state),
      isCurrentGroupOrganization: getIsCurrentGroupOrganization(state),
      isCurrentGroupSystem: getIsCurrentGroupSystem(state),
      groupNextPage: getNextPageFromState('group', paginationMetaId)(state),
      currentGroupLoading: getLoadingFromState(
        'currentGroup',
        null,
        false
      )(state),
    }),
    shallowEqual
  );

  const classes = useStyles();
  const [isBottom, scrollRef] = useInfiniteScroll(loading, 200);
  const previousIsBottom = usePrevious(isBottom);
  const groupType = getGroupTypeName(pathname);
  const hasNextPage = Boolean(groupNextPage);

  const urlParams = new URLSearchParams(search);

  const orderByParam = urlParams.get('orderBy');
  const searchParam = urlParams.get('search');
  const groupParam = urlParams.get('group');
  const latestActivityStart = urlParams.get('latestActivityStart');
  const latestActivityEnd = urlParams.get('latestActivityEnd');
  const labelsString = urlParams.get('labels');
  const labels = labelsString
    ?.split(',')
    ?.map(urlLabel => parseInt(urlLabel, 10));

  let initialOrder: 'asc' | 'desc' =
    orderByParam?.charAt(0) === '-' ? 'desc' : 'asc';
  let initialOrderBy = 'name';
  if (orderByParam) {
    initialOrderBy =
      orderByParam.charAt(0) === '-' ? orderByParam.substr(1) : orderByParam;
  }
  const orderingDisabled = Boolean(searchParam);

  const columns = getTableColumns(
    pathname,
    isSystemAdmin,
    currentGroupId,
    isCurrentGroupOrganization,
    orderingDisabled
  );

  const initialParams = {
    type: groupType,
    orderBy: orderByParam || initialOrderBy,
    search: searchParam,
    latestActivityStart,
    latestActivityEnd,
    labelIds: labels,
    fetchParents: groupType === 'GREEK_CHAPTER',
    useOrderWhenIds: true,
  };

  if (groupParam) {
    (initialParams as any).parentId = groupParam;
  }

  const params = useGroupFetchParams(initialParams);

  // Fetches data: initially, on filter changes, and on search changes
  useEffect(() => {
    if (!currentGroupLoading && params) {
      dispatch(clearGroups());
      dispatch(fetchGroups(params));
    }
    // Including params in the dependencies leads to infinite loop of fetching
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    groupParam,
    latestActivityStart,
    latestActivityEnd,
    labelsString,
    searchParam,
    currentGroupLoading,
    orderByParam,
  ]);

  useEffect(() => {
    if (currentGroupId && !isCurrentGroupSystem) {
      dispatch(fetchTagsAction({ groupId: currentGroupId }));
    }
  }, [dispatch, currentGroupId, isCurrentGroupSystem]);

  const destinationGroup = useCallback(
    ({ id }: { id?: number } = {}) => {
      if (pathname.includes('chapters')) {
        return `/home?group=${id}`;
      }
      if (pathname.includes('organizations')) {
        return `/groups/chapters?group=${id}`;
      }
      if (pathname.includes('councils')) {
        return `/chapters?group=${id}`;
      }
    },
    [pathname]
  );

  // Fetch initial chapters, orgs, and councils
  useEffect(() => {
    if (
      isSystemAdmin &&
      !isCurrentGroupOrganization &&
      !groupParam &&
      (!currentGroupId || isCurrentGroupSystem) &&
      !currentGroupLoading
    ) {
      if (groupType !== 'GREEK_CHAPTER') {
        dispatch(
          fetchGroups({
            search: '',
            type: 'GREEK_CHAPTER',
            orderBy: 'name',
          })
        );
      }
      dispatch(
        fetchGroups({
          search: groupType === 'GREEK_ORGANIZATION' ? searchParam : '',
          type: 'GREEK_ORGANIZATION',
          orderBy: 'name',
        })
      );
      dispatch(
        fetchGroups({
          type: 'COUNCIL',
          orderBy: 'name',
          search: groupType === 'COUNCIL' ? searchParam : '',
        })
      );
    }
  }, [
    currentGroupId,
    currentGroupLoading,
    dispatch,
    groupParam,
    groupType,
    isCurrentGroupOrganization,
    isCurrentGroupSystem,
    isSystemAdmin,
    searchParam,
  ]);

  const onFetchNextPage = useCallback(() => {
    if (!loading && groupNextPage) {
      if (groupType === 'GREEK_CHAPTER') {
        dispatch(
          fetchGroups({
            page: groupNextPage,
            appendResult: true,
            ...params,
          })
        );
      } else if (!loading && groupNextPage) {
        dispatch(
          fetchGroups({
            page: groupNextPage,
            appendResult: true,
            ...params,
          })
        );
      }
    }
  }, [loading, groupNextPage, groupType, dispatch, params]);

  useEffect(() => {
    if (!previousIsBottom && isBottom) {
      onFetchNextPage();
    }
  }, [onFetchNextPage, isBottom, previousIsBottom]);

  const fetchSortedData = (property: string, isNewOrderDesc: boolean) => {
    const newSearch = urlParams;
    let newOrderBy = isNewOrderDesc ? property : `-${property}`;
    urlParams.set('orderBy', newOrderBy);
    history.push({ pathname, search: newSearch.toString() });
  };

  return (
    // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
    <Grid container className={classes.listContainer} ref={scrollRef}>
      {!loading && !data.length ? (
        <Grid item xs={12}>
          <Grid container>
            <Grid
              item
              xs={12}
              sm={10}
              md={8}
              lg={6}
              className={classes.noneFoundContainer}
            >
              <Typography variant='body1'>No Groups Found.</Typography>
              <Typography variant='body2' color='secondary'>
                Add groups above
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Table
          columns={columns}
          data={data}
          numberOfSkeletonRows={loading ? 10 : 0}
          options={{
            enableCheckboxes: isSystemAdmin || isHqAdmin,
          }}
          fetchSortedData={fetchSortedData}
          initialOrder={initialOrder}
          initialOrderBy={orderingDisabled ? null : initialOrderBy}
          TableProps={{
            className: classes.table,
            stickyHeader: true,
          }}
          TableRowProps={{
            classes: {
              root: classes.root,
              selected: classes.selected,
              hover: classes.hover,
            },
            hover: true,
            hasLinkTo: destinationGroup,
            checkboxTooltipTitle: 'Select group',
          }}
          TableCellProps={{
            className: classes.tableCell,
          }}
          HeaderTableCellProps={{
            className: classes.headerTableCell,
          }}
          CheckboxProps={{
            allSelected,
            selectedItems: selectedGroups,
            setSelectedItems: setSelectedGroups,
          }}
          SkeletonProps={{
            height: 80,
          }}
        />
      )}

      {hasNextPage && Boolean(data.length) && (
        <Grid item xs={12} className={classes.loadMoreButton}>
          <Button
            variant='outlined'
            onClick={onFetchNextPage}
            startIcon={<ArrowDownward />}
          >
            Load More
          </Button>
        </Grid>
      )}
    </Grid>
  );
}

const useStyles = makeStyles(theme => {
  return {
    listContainer: {
      overflow: 'scroll',
      height: 'calc(100% - 60px)',
      paddingLeft: 24,
      paddingRight: 24,
    },
    table: {
      borderCollapse: 'separate',
      borderSpacing: '0 8px',
    },
    root: {
      cursor: 'pointer',
      transition: 'all 0.3s ease',
      height: 80,
      backgroundColor: (theme as Theme).palette.background.paper,
    },
    loadMoreButton: {
      textAlign: 'center',
    },
    hover: {
      '&$hover:hover': {
        transition: 'all 0.3s ease',
        backgroundColor: (theme as Theme).palette.background.lightPaper,
      },
    },
    selected: {
      '&$selected': {
        transition: 'all 0.3s ease',
        backgroundColor: (theme as Theme).palette.primary.light,
      },
      '&$selected:hover': {
        backgroundColor: (theme as Theme).palette.primary.light,
      },
    },
    tableCell: {
      borderBottom: 0,
      '&:first-child': {
        borderRadius: '4px 0 0 4px',
      },
      '&:last-child': {
        borderRadius: '0 4px 4px 0',
      },
    },
    headerTableCell: {
      borderBottom: 0,
      paddingTop: 0,
      paddingBottom: 0,
      color: (theme as Theme).palette.text.secondary,
    },
    noneFoundContainer: {
      border: `2px solid ${(theme as Theme).palette.background.paper}`,
      borderRadius: 4,
      padding: 20,
      marginTop: 24,
    },
  };
});
