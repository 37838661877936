import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

// hooks
import { usePrevious } from 'helpers/hooks/usePrevious';

// MUI components
import {
  InputAdornment,
  IconButton,
  CircularProgress,
} from '@material-ui/core';
import { Send } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import { Theme } from 'assets/types';

// components
import TextField from 'components/TextField';

// action creators
import { createCommentAction } from 'store/actions/commentActions';

// selectors
import getLoadingFromState from 'store/selectors/getLoadingFromState';
import getStatusFromState from 'store/selectors/getStatusFromState';

type Props = {
  activityId: number;
  onCreate: (...args: any[]) => any;
};

export default function CommentInput({ activityId, onCreate }: Props) {
  const classes = useStyles();
  const [body, setBody] = useState('');
  const dispatch = useDispatch();
  const metaId = `createComment-${activityId}`;
  const loading = useSelector(getLoadingFromState('comment', metaId, false));
  const prevLoading = usePrevious(loading);
  const status = useSelector(getStatusFromState('comment', metaId, 'success'));
  useEffect(() => {
    if (prevLoading && !loading && status === 'success') {
      setBody('');
      onCreate();
    }
  }, [status, prevLoading, loading, onCreate]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setBody(event.target.value);
  const createComment = () =>
    dispatch(createCommentAction({ fields: { activityId, body }, metaId }));
  const handleCtrlEnter = (event: any) => {
    if ((event.ctrlKey || event.metaKey) && event.key === 'Enter')
      createComment();
  };
  return (
    <TextField
      placeholder='Leave a comment...'
      input={{
        value: body,
      }}
      multiline
      fullWidth
      onChange={handleChange}
      onKeyDown={handleCtrlEnter}
      variant='standard'
      margin='none'
      maxLength={2048}
      InputProps={{
        disableUnderline: true,
        className: classNames(classes.background, classes.createInput),
        endAdornment: (
          <InputAdornment position='end'>
            <IconButton
              onClick={createComment}
              disabled={loading || !body}
              size='small'
            >
              {loading ? (
                <CircularProgress size={24} />
              ) : (
                <Send fontSize='small' />
              )}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
}
const useStyles = makeStyles((theme: Theme) => ({
  createInput: theme.typography.body2,
  background: {
    backgroundColor: theme.palette.background.lightPaper,
    borderRadius: '4px',
    padding: '10px 8px',
  },
}));
