export const AUTH_INTEGRATION_CREATE = 'AUTH_INTEGRATION_CREATE';
export const AUTH_INTEGRATION_CREATE_REQUEST =
  'AUTH_INTEGRATION_CREATE_REQUEST';

export const AUTH_INTEGRATION_UPDATE = 'AUTH_INTEGRATION_UPDATE';
export const AUTH_INTEGRATION_UPDATE_REQUEST =
  'AUTH_INTEGRATION_UPDATE_REQUEST';

export const AUTH_INTEGRATION_DELETE = 'AUTH_INTEGRATION_DELETE';
export const AUTH_INTEGRATION_DELETE_REQUEST =
  'AUTH_INTEGRATION_DELETE_REQUEST';
