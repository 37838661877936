import React from 'react';
import { useHistory } from 'react-router-dom';

import { isEqual } from 'lodash';
import { Form, Field } from 'react-final-form';

// material-ui
import Box from '@material-ui/core/Box';
import PersonAdd from '@material-ui/icons/PersonAdd';

// components
import DazzlingDialog from 'components/DazzlingDialog';
import LeadSelector from 'routes/Calls/LeadSelector';

interface AddCallLeadModalProps {
  open: boolean;
  onClose: () => void;
}

export default function AddCallLeadModal({
  open,
  onClose,
}: AddCallLeadModalProps) {
  const history = useHistory();
  const handleFormSubmit = ({ lead }: { lead: Lead }) => {
    if (lead.phoneNumber)
      history.push({
        pathname: '/calls',
        state: { newCallQueueLeadIds: [lead.id] },
      });
    else
      history.push({
        pathname: '/calls',
        state: { leadsWithoutPhoneNumber: [lead] },
      });
    onClose();
  };

  return (
    <Form
      initialValues={{ lead: null }}
      onSubmit={handleFormSubmit}
      render={({ handleSubmit, pristine, form: { reset } }) => (
        <DazzlingDialog
          disabled={pristine}
          handleClose={onClose}
          open={open}
          onAccept={handleSubmit}
          headerProps={{
            icon: PersonAdd,
            title: 'Add Call To Queue',
            subtitle: 'Search and select a lead to add to your call queue',
          }}
          acceptLabel='Add'
          submitOnEnter
          // @ts-expect-error ts-migrate(2322) FIXME: Type '{ children: Element; alertProps: { message: ... Remove this comment to see the full error message
          onExited={reset}
        >
          <Box width='70%' marginTop={1} marginBottom={3}>
            <Field
              allowNull
              isEqual={isEqual}
              name='lead'
              component={LeadSelector}
              fullWidth
              margin='normal'
            />
          </Box>
        </DazzlingDialog>
      )}
    />
  );
}
