import { all } from 'redux-saga/effects';

// sagas
import createVoteSaga from './createVoteSaga';
import fetchVotesSaga from './fetchVotesSaga';
import updateVoteSaga from './updateVoteSaga';
import deleteVotesSaga from './deleteVotesSaga';

const voteSaga = function* rootSaga() {
  yield all([
    createVoteSaga(),
    deleteVotesSaga(),
    fetchVotesSaga(),
    updateVoteSaga(),
  ]);
};

export default voteSaga;
