import React from 'react';

// MUI components
import { Grid } from '@material-ui/core';
import MilestoneUsageCard from './MilestoneUsageCard';
import MilestoneStatusCard from './MilestoneStatusCard';

const MilestonesAnalytics = function() {
  return (
    <Grid container spacing={2} alignContent='flex-start'>
      <Grid item xs={12}>
        <MilestoneUsageCard />
      </Grid>
      <Grid item xs={12}>
        <MilestoneStatusCard />
      </Grid>
    </Grid>
  );
};

export default MilestonesAnalytics;
