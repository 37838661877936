import React, { useCallback, useState } from 'react';

// MUI components
import { Grid, Typography } from '@material-ui/core';

// components
import DateButton from 'components/DateButton';

export default function DateRange({
  endLabel = 'End',
  startLabel = 'Start',
  disableFuture = true,
  disabled = false,
  initialEndDate,
  initialStartDate,
  justifyContent = 'flex-start',
  maxDays = 90,
  minStartDate,
  onDateChange,
  pickerType = 'date',
  variant = 'default',
  ...restOfProps
}: {
  endLabel?: string;
  startLabel?: string;
  disableFuture?: boolean;
  disabled?: boolean;
  initialStartDate?: any; // TODO: PropTypes.instanceOf(Object)
  initialEndDate?: any; // TODO: PropTypes.instanceOf(Object)
  justifyContent?:
    | 'flex-start'
    | 'space-around'
    | 'space-between'
    | 'space-evenly'
    | 'center'
    | 'flex-end';
  maxDays?: number;
  minStartDate?: any; // TODO: PropTypes.instanceOf(Object)
  onDateChange: (...args: any[]) => void;
  pickerType?: 'date' | 'datetime' | 'time';
  variant?: 'default' | 'input';
}) {
  const [startDate, setStartDate] = useState(initialStartDate);
  const [endDate, setEndDate] = useState(initialEndDate);

  // we default to only pulling data from up to a 90 day range:
  const checkDateRangeLimit = useCallback(
    (startDate, endDate) =>
      startDate && endDate ? endDate.diff(startDate, 'days') > maxDays : false,
    [maxDays]
  );

  const handleSetStartDate = useCallback(
    value => {
      setStartDate(value);

      onDateChange({
        startDate: value,
        endDate,
        isError: checkDateRangeLimit(value, endDate),
        ...restOfProps,
      });
    },
    [checkDateRangeLimit, endDate, onDateChange, restOfProps]
  );

  const handleSetEndDate = useCallback(
    value => {
      setEndDate(value);

      onDateChange({
        startDate,
        endDate: value,
        isError: checkDateRangeLimit(startDate, value),
        ...restOfProps,
      });
    },
    [checkDateRangeLimit, startDate, onDateChange, restOfProps]
  );

  const getStartPickerProps = useCallback(() => {
    switch (pickerType) {
      case 'date':
        return {
          minDate: minStartDate,
          maxDate: endDate,
          disableFuture,
        };
      case 'datetime':
        return {
          minDateTime: minStartDate,
          maxDateTime: endDate,
          disableFuture,
        };
      case 'time':
        return {
          minTime: minStartDate,
          maxTime: endDate,
          disableFuture,
        };
      default:
        return {};
    }
  }, [disableFuture, endDate, minStartDate, pickerType]);

  const getEndPickerProps = useCallback(() => {
    switch (pickerType) {
      case 'date':
        return {
          minDate: startDate,
          disableFuture,
        };
      case 'datetime':
        return {
          minDateTime: startDate,
          disableFuture,
        };
      case 'time':
        return {
          minTime: startDate,
          disableFuture,
        };
      default:
        return {};
    }
  }, [disableFuture, pickerType, startDate]);

  return (
    <Grid
      container
      alignItems='center'
      spacing={2}
      justifyContent={justifyContent}
      wrap='nowrap'
    >
      <Grid item xs>
        <DateButton
          id='startDate'
          type={pickerType}
          label={startLabel}
          value={startDate}
          onChange={handleSetStartDate}
          variant={variant}
          ButtonProps={{
            disabled: disabled,
            variant: 'outlined',
          }}
          DatePickerProps={getStartPickerProps()}
        />
      </Grid>

      <Grid item>to</Grid>

      <Grid item xs>
        <DateButton
          id='endDate'
          type={pickerType}
          label={endLabel}
          value={endDate}
          onChange={handleSetEndDate}
          variant={variant}
          ButtonProps={{
            disabled: disabled,
            variant: 'outlined',
          }}
          DatePickerProps={getEndPickerProps()}
        />
      </Grid>
      {checkDateRangeLimit(startDate, endDate) && (
        <Grid item xs={12} justifyContent='center'>
          <Typography variant='subtitle2' color={'error'}>
            {`Date range cannot exceed ${maxDays} days, please select a new range`}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
}
