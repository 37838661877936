export const PAYMENT_SETUP_FETCH_REQUEST = 'PAYMENT_SETUP_FETCH_REQUEST';
export const PAYMENT_SETUP_FETCH = 'PAYMENT_SETUP_FETCH';

export const PAYMENT_SETUP_GET_LINK_FETCH_REQUEST =
  'PAYMENT_SETUP_GET_LINK_FETCH_REQUEST';
export const PAYMENT_SETUP_GET_LINK_FETCH = 'PAYMENT_SETUP_GET_LINK_FETCH';

export const PAYMENT_SETUP_UPDATE_REQUEST = 'PAYMENT_SETUP_UPDATE_REQUEST';
export const PAYMENT_SETUP_UPDATE = 'PAYMENT_SETUP_UPDATE';

export const PAYMENT_DELETE_REQUEST = 'PAYMENT_DELETE_REQUEST';
export const PAYMENT_DELETE = 'PAYMENT_DELETE';

export const PAYMENT_CLEAR = 'PAYMENT_CLEAR';
