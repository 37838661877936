import {
  FORGOT_PASSWORD_CREATE_REQUEST,
  FORGOT_PASSWORD_UPDATE_REQUEST,
} from 'store/constants/forgotPasswordTypes';

export function createForgotPassword({ email }: any) {
  return {
    type: FORGOT_PASSWORD_CREATE_REQUEST,
    payload: { email },
  };
}

export function updateForgotPassword(password: any, token: any) {
  return {
    type: FORGOT_PASSWORD_UPDATE_REQUEST,
    payload: { token, password },
  };
}
