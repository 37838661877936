import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { List } from 'immutable';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { isEqual } from 'lodash';

// material-ui
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ArrowBack } from '@material-ui/icons';

// components
import SidebarItem from './components/SidebarItem';
import Button from 'components/Button';
import { clearStatuses } from 'store/actions/statusActions';
import { clearCurrentGroup } from 'store/actions/groupActions';
import { clearMembers } from 'store/actions/memberActions';

// selectors
import getIsSystemAdmin from 'store/selectors/getIsSystemAdmin';
import getCurrentGroupId from 'store/selectors/getCurrentGroupId';
import getCurrentUserGroups from 'store/selectors/getCurrentUserGroups';
import getCurrentParentOrganization from 'store/selectors/getCurrentParentOrganization';
import getCurrentParentCouncil from 'store/selectors/getCurrentParentCouncil';
import getIsHqAdmin from 'store/selectors/getIsHqAdmin';
import getIsCouncilAdmin from 'store/selectors/getIsCouncilAdmin';
import getIsCurrentGroupSystem from 'store/selectors/getIsCurrentGroupSystem';
import getIsCurrentGroupChapter from 'store/selectors/getIsCurrentGroupChapter';

const Sidebar = function({ pages }: any) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { location: { pathname, search = '' } = {} } = history;
  const {
    currentGroupId,
    currentParentCouncil,
    currentParentOrganization,
    currentUserGroups,
    hasMultipleGroups,
    isHqAdmin,
    isCouncilAdmin,
    isSystemAdmin,
    isCurrentGroupChapter,
    isCurrentGroupSystem,
  } = useSelector(
    state => ({
      currentGroupId: getCurrentGroupId(state),
      currentParentCouncil: getCurrentParentCouncil(state),
      currentParentOrganization: getCurrentParentOrganization(state),
      currentUserGroups: getCurrentUserGroups(state),
      hasMultipleGroups: getCurrentUserGroups(state).size > 1,
      isHqAdmin: getIsHqAdmin(state),
      isCouncilAdmin: getIsCouncilAdmin(state),
      isSystemAdmin: getIsSystemAdmin(state),
      isCurrentGroupChapter: getIsCurrentGroupChapter(state),
      isCurrentGroupSystem: getIsCurrentGroupSystem(state),
    }),
    isEqual
  );

  // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
  const notOnDashboard = !pathname.includes('groups');
  const isGroupSizeOne = currentUserGroups
    ? currentUserGroups.size === 1
    : false;
  const sidebarPages = pages.filter((page: any) => page.get('sidebar'));
  const displayDashboardButton =
    (!isSystemAdmin &&
      notOnDashboard &&
      isCurrentGroupChapter &&
      (hasMultipleGroups || isHqAdmin || isCouncilAdmin)) ||
    (isSystemAdmin && currentGroupId && !isCurrentGroupSystem) ||
    (isCouncilAdmin && currentGroupId && !isGroupSizeOne);
  const handleDashboardClick = useCallback(() => {
    dispatch(clearMembers());
    dispatch(clearCurrentGroup());
    dispatch(clearStatuses());
    const newSearch = new URLSearchParams(search);
    let searchKeys: string[] = [];
    newSearch.forEach((_, key: string) => {
      if (!key.includes('group')) searchKeys.push(key);
    });
    for (const key in searchKeys) {
      newSearch.delete(searchKeys[key]);
    }

    if (!isSystemAdmin && isHqAdmin) {
      if (currentParentOrganization)
        newSearch.set('group', currentParentOrganization.id.toString());
      history.push({
        pathname: '/groups/chapters',
        search: newSearch.toString(),
      });
    } else if (!isSystemAdmin && isCouncilAdmin) {
      newSearch.set('group', currentParentCouncil.id.toString());
      history.push({
        pathname: '/groups/chapters',
        search: newSearch.toString(),
      });
    } else {
      const navigatedFromGroupId = localStorage.getItem('navigatedFromGroupId');
      if (navigatedFromGroupId === currentParentCouncil?.id.toString()) {
        newSearch.set('group', currentParentCouncil.id.toString());
        history.push({
          pathname: '/chapters',
          search: newSearch.toString(),
        });
      } else if (
        navigatedFromGroupId === currentParentOrganization?.id.toString()
      ) {
        newSearch.set('group', currentParentOrganization.id.toString());
        history.push({
          pathname: '/groups/chapters',
          search: newSearch.toString(),
        });
      } else {
        newSearch.delete('group');
        history.push({
          pathname: '/groups/chapters',
          search: newSearch ? (newSearch as URLSearchParams).toString() : '',
        });
      }
    }
  }, [
    dispatch,
    history,
    currentParentOrganization,
    currentParentCouncil,
    isHqAdmin,
    isCouncilAdmin,
    isSystemAdmin,
    search,
  ]);

  return (
    <aside id='sidebar' className={classes.sidebar}>
      <Grid container justifyContent='center' spacing={2}>
        {displayDashboardButton && (
          <Button
            id='dashboardButton'
            className={classes.backButton}
            startIcon={<ArrowBack />}
            onClick={handleDashboardClick}
          >
            Dashboard
          </Button>
        )}

        {sidebarPages.map((page: any) => (
          <SidebarItem page={page} key={page.get('path')} />
        ))}
      </Grid>
    </aside>
  );
};
Sidebar.propTypes = {
  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'typeof List' is not assignable t... Remove this comment to see the full error message
  pages: PropTypes.instanceOf(List).isRequired,
};
const useStyles = makeStyles(theme => {
  return {
    sidebar: {
      position: 'relative',
      flex: '0 0 150px',
      maxWidth: 150,
      minWidth: 150,
      backgroundColor: (theme as any).palette.background.darkPaper,
      overflow: 'auto',
      width: 150,
      paddingBottom: 16,
      paddingTop: 16,
      paddingLeft: 8,
      paddingRight: 8,
      borderRight: `1px solid ${(theme as any).palette.background.paper}`,
      zIndex: 101, // above app header
    },
    backButton: {
      marginTop: 4,
    },
  };
});
export default Sidebar;
