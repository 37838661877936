import React, { useState, useCallback } from 'react';
import { List, Map } from 'immutable';
import { isEqual } from 'lodash';

// hooks
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

// MUI components
import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import useMediaQuery from '@material-ui/core/useMediaQuery';

// icons
import AccountIcon from '@material-ui/icons/SettingsApplications';
import LogoutIcon from '@material-ui/icons/ExitToApp';
import ArrowBack from '@material-ui/icons/ArrowBack';

// components
import SuperAvatar from 'components/SuperAvatar';
import MegaMenu from 'components/MegaMenu';

// action creators
import { destroySession } from 'store/actions/sessionActions';
import { clearCurrentGroup } from 'store/actions/groupActions';
import { clearMembers } from 'store/actions/memberActions';
import { clearStatuses } from 'store/actions/statusActions';

// selectors
import getCurrentUserGroups from 'store/selectors/getCurrentUserGroups';
import getIsSystemAdmin from 'store/selectors/getIsSystemAdmin';
import getCurrentUser from 'store/selectors/getCurrentUser';
import getIsHqAdmin from 'store/selectors/getIsHqAdmin';
import getIsCouncilAdmin from 'store/selectors/getIsCouncilAdmin';
import getIsCurrentGroupChapter from 'store/selectors/getIsCurrentGroupChapter';
import getIsCurrentGroupSystem from 'store/selectors/getIsCurrentGroupSystem';
import getCurrentParentOrganization from 'store/selectors/getCurrentParentOrganization';
import getCurrentParentCouncil from 'store/selectors/getCurrentParentCouncil';

const ProfileAvatar = function() {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const { location: { search = '' } = {} } = history;
  const dispatch = useDispatch();
  const isSmallOrDown = useMediaQuery(theme.breakpoints.down('sm'));
  const [anchorEl, setAnchorEl] = useState(null);
  const {
    currentGroup,
    currentUser,
    currentParentCouncil,
    currentParentOrganization,
    hasMultipleGroups,
    isHqAdmin,
    isCouncilAdmin,
    currentUserGroups,
    isSystemAdmin,
    isCurrentGroupChapter,
    isCurrentGroupSystem,
  } = useSelector(
    state => ({
      currentGroup: (state as any).getIn(['currentGroup', 'data'], Map()),
      currentUser: getCurrentUser(state),
      currentParentCouncil: getCurrentParentCouncil(state),
      currentParentOrganization: getCurrentParentOrganization(state),
      hasMultipleGroups: getCurrentUserGroups(state).size > 1,
      currentUserGroups: getCurrentUserGroups(state),
      isHqAdmin: getIsHqAdmin(state),
      isCouncilAdmin: getIsCouncilAdmin(state),
      isSystemAdmin: getIsSystemAdmin(state),
      isCurrentGroupChapter: getIsCurrentGroupChapter(state),
      isCurrentGroupSystem: getIsCurrentGroupSystem(state),
    }),
    isEqual
  );

  const handleDashboardClick = useCallback(() => {
    dispatch(clearMembers());
    dispatch(clearCurrentGroup());
    dispatch(clearStatuses());
    setAnchorEl(null);
    const newSearch = new URLSearchParams(search);
    let searchKeys: string[] = [];
    newSearch.forEach((_, key: string) => {
      if (!key.includes('group')) searchKeys.push(key);
    });
    for (const key in searchKeys) {
      newSearch.delete(searchKeys[key]);
    }

    if (!isSystemAdmin && (isHqAdmin || isCouncilAdmin)) {
      newSearch.set('group', currentUserGroups.first());
      history.push({
        pathname: '/groups/chapters',
        search: newSearch ? (newSearch as URLSearchParams).toString() : '',
      });
    } else {
      const navigatedFromGroupId = localStorage.getItem('navigatedFromGroupId');
      if (navigatedFromGroupId === currentParentCouncil?.id.toString()) {
        newSearch.set('group', currentParentCouncil.id.toString());
        history.push({
          pathname: '/chapters',
          search: newSearch.toString(),
        });
      } else if (
        navigatedFromGroupId === currentParentOrganization?.id.toString()
      ) {
        newSearch.set('group', currentParentOrganization.id.toString());
        history.push({
          pathname: '/groups/chapters',
          search: newSearch.toString(),
        });
      } else {
        newSearch.delete('group');
        history.push({
          pathname: '/groups/chapters',
          search: newSearch ? (newSearch as URLSearchParams).toString() : '',
        });
      }
    }
  }, [
    currentUserGroups,
    currentParentCouncil,
    currentParentOrganization,
    dispatch,
    history,
    isCouncilAdmin,
    isHqAdmin,
    isSystemAdmin,
    search,
  ]);

  const handleNavigateToAccount = useCallback(() => {
    const oldSearch = new URLSearchParams(search);
    const groupParam = oldSearch.get('group');
    const newSearch = new URLSearchParams();
    if (groupParam) newSearch.set('group', groupParam);

    history.push({
      pathname: '/settings/account',
      search: newSearch.toString(),
    });
  }, [history, search]);

  const getMenuSections = useCallback(() => {
    const {
      location: { pathname },
    } = history;

    const notOnDashboard = !pathname.includes('groups');

    const displayDashboardOption =
      (!isSystemAdmin &&
        notOnDashboard &&
        isCurrentGroupChapter &&
        (hasMultipleGroups || isHqAdmin || isCouncilAdmin)) ||
      (isSystemAdmin && !isCurrentGroupSystem && currentGroup.get('id'));

    let sectionHeader;

    if (isSystemAdmin && currentGroup.get('id')) {
      sectionHeader = `Viewing as ${currentGroup.get('displayName')}`;
    } else if (currentGroup.get('displayName') && !isCouncilAdmin) {
      sectionHeader = currentGroup.get('displayName');
    } else if (currentGroup.get('name') && isCouncilAdmin) {
      sectionHeader = currentGroup.get('name');
    }

    // This list is composed into immutable elements manually
    //  so that the icon components don't get converted into objects
    let menuSections = List([
      Map({
        id: 'profileActions',
        sectionHeader,
        items: List([
          Map({
            id: 'account',
            label: 'Account',
            icon: <AccountIcon />,
            onClick: handleNavigateToAccount,
          }),
          Map({
            id: 'logOut',
            label: 'Log Out',
            icon: <LogoutIcon />,
            onClick: () => dispatch(destroySession()),
          }),
        ]),
      }),
    ]);
    if (displayDashboardOption) {
      const menuSection = (menuSections as any).first().get('items');
      menuSections = menuSections.setIn(
        [0, 'items'],
        menuSection.unshift(
          Map({
            id: 'dashboard',
            label: 'Back To Dashboard',
            icon: <ArrowBack />,
            onClick: () => handleDashboardClick(),
          })
        )
      );
    }
    return menuSections;
  }, [
    currentGroup,
    dispatch,
    handleDashboardClick,
    handleNavigateToAccount,
    hasMultipleGroups,
    history,
    isCouncilAdmin,
    isCurrentGroupChapter,
    isHqAdmin,
    isSystemAdmin,
    isCurrentGroupSystem,
  ]);
  return (
    <Grid item id='avatarContainer'>
      <IconButton
        id='profileButton'
        aria-label='Profile'
        className={classes.profileButton}
        onClick={(e: any) => setAnchorEl(e.currentTarget)}
      >
        <SuperAvatar
          id='avatar'
          fullName={currentUser.toJS().fullName}
          imageId={currentUser.get('profilePhotoFileId')}
          size={isSmallOrDown ? 'small' : 'medium'}
          background='gradient'
          aria-haspopup='true'
          raised
        />
      </IconButton>

      <MegaMenu
        anchorEl={anchorEl}
        handleClose={() => setAnchorEl(null)}
        menuSections={getMenuSections()}
      />
    </Grid>
  );
};

const useStyles = makeStyles(theme => ({
  profileButton: {
    padding: 0,
  },
}));

export default ProfileAvatar;
