import { all, call, fork, put, takeEvery, select } from 'redux-saga/effects';

// action creators
import { doneIndicator } from 'store/actions/httpActions';

// api
import client from 'sources/api';

// constants
import { TAG_CREATE_REQUEST, TAG_CREATE } from 'store/constants/tagTypes';

// selectors
import getCurrentGroupId from 'store/selectors/getCurrentGroupId';

// schemas
import { tagSchema } from 'store/schemas/tagSchema';

// helpers
import httpSaga from 'store/sagas/httpSaga';

const WATCH_TYPE = TAG_CREATE_REQUEST;
const TYPE = TAG_CREATE;

export function* createTag(action: any) {
  const {
    payload: { formName, fields },
  } = action;

  const currentGroupId = yield select(getCurrentGroupId);

  // @ts-expect-error ts-migrate(2569) FIXME: Type 'Generator<any, any, unknown>' is not an arra... Remove this comment to see the full error message
  yield* httpSaga(
    TYPE,
    call(client.post, `/tags?group_id=${currentGroupId}`, {
      ...fields,
      type: 'label',
    }),
    {
      schema: tagSchema,
      formName,
    }
  );

  yield put(doneIndicator(TYPE));
}

export function* watch() {
  yield takeEvery(WATCH_TYPE, createTag);
}

export default function* root() {
  yield all([fork(watch)]);
}
