import React, { useMemo, useState, useEffect } from 'react';
import Immutable from 'immutable';

// hooks
import { useCampusQuery, useCreateCampusMutation } from 'api/campus';

// material-ui
import Grid from '@material-ui/core/Grid';

// components
import AutoSuggest from 'components/AutoSuggest';

export interface CampusDropdownProps {
  token?: string | null;
  multiSelect?: boolean;
  values?: any;
  input?: {
    name?: string;
    value?: string | null;
    onChange: ({ label, value }: { label: string; value: number }) => void;
  };
  hasAddButton?: boolean;
}

const CampusDropdown = ({
  token,
  multiSelect,
  input,
  values,
  hasAddButton,
  ...restOfProps
}: CampusDropdownProps) => {
  const [searchValue, setSearchValue] = useState<string>('');
  const { name } = input || {};

  // Get campuses query
  const {
    data: fetchedCampuses,
    isLoading: fetchCampusLoading,
  } = useCampusQuery(searchValue, token);

  // Create campus query
  const {
    data: createdCampus,
    isLoading: createCampusLoading,
    mutate: createCampus,
  } = useCreateCampusMutation();

  const addNewCampus = (displayName: string) => {
    createCampus({ name: displayName });
  };

  useEffect(() => {
    if (createdCampus?.id) {
      input?.onChange({ label: createdCampus.name, value: createdCampus.id });
    }
  }, [createdCampus]); // eslint-disable-line

  const suggestions = useMemo<{ label?: string; value?: number }[]>(
    () =>
      fetchedCampuses?.length
        ? fetchedCampuses.map((campus: Campus) => ({
            label: campus.name,
            value: campus.id,
          }))
        : [],
    [fetchedCampuses]
  );

  const onSearchChange = (search?: string | null) => {
    if (search || search === '') {
      setSearchValue(search);
    }
  };

  return (
    <Grid>
      <AutoSuggest
        // @ts-ignore
        suggestionsCardType='expand'
        autoSuggestType={hasAddButton ? 'fields' : ''}
        label={name || ''}
        loading={fetchCampusLoading || createCampusLoading}
        onSearchChange={onSearchChange}
        handleAddItemClick={addNewCampus}
        addItemLabel='campus'
        suggestions={suggestions}
        variant='outlined'
        input={input}
        multiSelect={multiSelect}
        callSearchChangeWhenNull
        {...(values ? { formValues: Immutable.fromJS(values) } : {})}
        {...restOfProps}
      />
    </Grid>
  );
};

export default CampusDropdown;
