// images
import firstLogo from 'assets/images/growMyGroup.png';
import secondLogo from 'assets/images/namesListLogo.png';
import imageContainerBackgroundImage from 'assets/images/landingBackgroundForNamesList.jpg';
import appHeaderLogo from 'assets/images/namesListLogoHeader.png';
import formHeaderDefaultLogo from 'assets/images/namesListLogo.png';

// NamesList
const getNL = (): SiteVisualData => ({
  pageTitle: 'NamesList',
  firstLogo,
  firstLogoAlt: 'GrowMyGroup',
  formHeaderDefaultLogo,
  secondLogo,
  secondLogoAlt: 'NamesList',
  landingRightSideText: '',
  imageContainerBackgroundImage,
  supportEmail: 'support@growmygroup.com',
  about: 'https://www.growmygroup.com/',
  demo: '', // TODO should be added later
  pricing: '', // TODO should be added later
  facebook: '', //TODO should be added later
  instagram: 'https://www.instagram.com/growmygroup/',
  twitter: '', // TODO should be added later
  deletePhoneNumberModalFirstMessage:
    'Are you sure you want to delete this phone number for your NamesList account?',
  preferencesCardFirstLabel: 'Receive Emails From NamesList',
  preferencesCardSecondLabel:
    'Receive Push Notifications From NamesList on Android and iOS',
  errorDialogSubtitle:
    'Report any unresolvable errors to support@grownmygroup.com',
  deleteAccountCardHighlightedSubtitle:
    'Are you sure you want to delete your personal NamesList account? Note this will not delete your location/group only your personal account. By selecting the delete button you will be permanently removing your account. You will no longer receive notifications and will no longer have access.',
  deleteAccountCardSubtitle:
    'Do you want to permanently delete your NamesList account?',
  callPanelMessage:
    "We couldn't find a NamesList phone number for your account.",
  createEventModalLabel: 'Invite all of your NamesList members to event',
  textMessageFormMessage:
    "We couldn't find a NamesList phone number for your account.",
  deletedLeadCardMessage:
    'This lead has been removed from your NamesList account.',
  deleteLeadModalSubtitle: 'Remove lead from NamesList',
  leadMessageTabMessage:
    "We couldn't find a NamesList phone number for your account.",
  inviteMembersCardSubtitle:
    'Email fellow group members an invitation to join your NamesList team',
  messagesContentMessage:
    "We couldn't find a NamesList phone number for your account.",
  paymentSetupCardAlertMessage:
    'By creating and collecting fees through NamesList, you acknowledge that both Stripe and Phired Up & GrowMyGroup will be collecting a percentage of your fees. To see full terms and conditions, please click on the link below.',
  unlinkChapterModalAdditionalSubtitle:
    'If you want to re-link this group, you will need to contact support@grownmygroup.com.',
  selectChapterFormError: 'Failed to create or join group',
  selectChapterFormCouncilLabel: 'Location',
  selectChapterFormCouncilPlaceholder: 'Search Location',
  selectChapterFormOrganizationLabel: 'Group',
  selectChapterFormOrganizationPlaceholder: 'Search Group',
  selectChapterHeaderTitle: 'Select your group',
  selectChapterHeaderSubTitle: 'Join an existing account or start a new one',
  selectChapterFormSchemaCouncilMessage: "^Location can't be blank",
  selectChapterFormSchemaOrganizationMessage: "^Group can't be blank",
  loginFormHeaderTitle: 'Welcome back!',
  loginFormMessage:
    'The NamesList System is experiencing issues. We are working to resolve this as quickly as we can. Please wait a few minutes and attempt to login again.',
  signupFormHeaderTitle: 'Welcome!',
  signupFormActionsText: "By signing up, you agree to GrowMyGroup's",
  appHeaderLogo,
  appHeaderLogoAlt: 'NamesList',
});

export default getNL;
