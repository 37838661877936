import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// material-ui
import { makeStyles } from '@material-ui/core/styles';
import MuiButton from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Hidden from '@material-ui/core/Hidden';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import Zoom from '@material-ui/core/Zoom';
import Fab from 'components/Fab';

// components
import IconButton from 'components/IconButton';

// constants
const TIMEOUT = 2000;
const ANIMATE_IN_DURATION = 250;
const ANIMATE_OUT_DURATION = 250;

const Button = function({
  CircularProgressProps,
  children,
  className: buttonClassName,
  disabled,
  fail,
  fabIcon,
  iconButtonIcon,
  loading,
  onClick,
  success,
  type,
  ...restOfProps
}: any) {
  const {
    size,
    className: circularProgressClassName,
    ...restOfCircularProgressProps
  } = CircularProgressProps;

  const classes = useStyles();

  const [displaySuccess, setDisplaySuccess] = useState(false);
  const [displayFail, setDisplayFail] = useState(false);
  const [beginAnimateOut, setBeginAnimateOut] = useState(false);

  let displaySuccessTimer = useRef(null);
  let displayFailTimer = useRef(null);
  let beginAnimateTimer1 = useRef(null);
  let beginAnimateTimer2 = useRef(null);

  useEffect(() => {
    if (success) {
      setDisplaySuccess(true);
      // @ts-expect-error ts-migrate(2322) FIXME: Type 'number' is not assignable to type 'null'.
      displaySuccessTimer.current = setTimeout(
        setDisplaySuccess,
        TIMEOUT,
        false
      );
      // @ts-expect-error ts-migrate(2322) FIXME: Type 'number' is not assignable to type 'null'.
      beginAnimateTimer1.current = setTimeout(
        setBeginAnimateOut,
        TIMEOUT - ANIMATE_OUT_DURATION,
        true
      );
      // @ts-expect-error ts-migrate(2322) FIXME: Type 'number' is not assignable to type 'null'.
      beginAnimateTimer2.current = setTimeout(
        setBeginAnimateOut,
        ANIMATE_OUT_DURATION,
        false
      );
    } else if (fail) {
      setDisplayFail(true);
      // @ts-expect-error ts-migrate(2322) FIXME: Type 'number' is not assignable to type 'null'.
      displayFailTimer.current = setTimeout(setDisplayFail, TIMEOUT, false);
      // @ts-expect-error ts-migrate(2322) FIXME: Type 'number' is not assignable to type 'null'.
      beginAnimateTimer1.current = setTimeout(
        setBeginAnimateOut,
        TIMEOUT - ANIMATE_OUT_DURATION,
        true
      );
      // @ts-expect-error ts-migrate(2322) FIXME: Type 'number' is not assignable to type 'null'.
      beginAnimateTimer2.current = setTimeout(
        setBeginAnimateOut,
        ANIMATE_OUT_DURATION,
        false
      );
    }
  }, [fail, success]);

  useEffect(() => {
    return () => {
      // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
      clearTimeout(displaySuccessTimer.current);
      // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
      clearTimeout(displayFailTimer.current);
      // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
      clearTimeout(beginAnimateTimer1.current);
      // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
      clearTimeout(beginAnimateTimer2.current);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getButtonLabel = () => {
    if (displaySuccess) {
      return (
        <Zoom
          in={beginAnimateOut ? false : displaySuccess}
          timeout={beginAnimateOut ? ANIMATE_OUT_DURATION : ANIMATE_IN_DURATION}
        >
          <CheckIcon />
        </Zoom>
      );
    } else if (displayFail) {
      return (
        <Zoom
          in={beginAnimateOut ? false : displayFail}
          timeout={beginAnimateOut ? ANIMATE_OUT_DURATION : ANIMATE_IN_DURATION}
        >
          <ClearIcon />
        </Zoom>
      );
    } else if (loading) {
      return (
        <CircularProgress
          size={size || 16}
          classes={{ colorPrimary: classes.buttonProgressColor }}
          className={circularProgressClassName}
          {...restOfCircularProgressProps}
        />
      );
    } else {
      return children;
    }
  };

  if (loading || success) {
    delete restOfProps.startIcon;
  }

  const renderButton = () => (
    <MuiButton
      className={classNames(
        classes.button,
        {
          [classes.buttonSuccess]: displaySuccess,
          [classes.buttonFail]: displayFail,
        },
        buttonClassName
      )}
      type={displaySuccess || displayFail ? 'button' : type}
      onClick={displaySuccess || displayFail ? null : onClick}
      disabled={displaySuccess || displayFail ? false : disabled}
      {...restOfProps}
    >
      {getButtonLabel()}
    </MuiButton>
  );

  // Renders Fab instead of Button on mobile
  const renderResponsiveWithFab = () => (
    <div id='fabReady'>
      <Hidden smUp implementation='css'>
        <Fab
          className={classNames(
            {
              [classes.buttonSuccess]: displaySuccess,
              [classes.buttonFail]: displayFail,
            },
            buttonClassName
          )}
          type={displaySuccess || displayFail ? 'button' : type}
          onClick={displaySuccess || displayFail ? null : onClick}
          disabled={displaySuccess || displayFail ? false : disabled}
          variant='circular'
        >
          {fabIcon}
        </Fab>
      </Hidden>

      <Hidden xsDown implementation='css'>
        {renderButton()}
      </Hidden>
    </div>
  );

  // Renders Fab instead of Button on mobile
  const renderResponsiveWithIconButton = () => (
    <div id='iconButtonReady'>
      <Hidden smUp implementation='css'>
        <IconButton
          className={classNames(
            {
              [classes.buttonSuccess]: displaySuccess,
              [classes.buttonFail]: displayFail,
            },
            buttonClassName
          )}
          type={displaySuccess || displayFail ? 'button' : type}
          onClick={displaySuccess || displayFail ? null : onClick}
          disabled={displaySuccess || displayFail ? false : disabled}
        >
          {iconButtonIcon}
        </IconButton>
      </Hidden>

      <Hidden xsDown implementation='css'>
        {renderButton()}
      </Hidden>
    </div>
  );

  if (fabIcon) {
    return renderResponsiveWithFab();
  } else if (iconButtonIcon) {
    return renderResponsiveWithIconButton();
  } else {
    return renderButton();
  }
};

// @ts-expect-error ts-migrate(7022) FIXME: 'propTypes' implicitly has type 'any' because it d... Remove this comment to see the full error message
Button.propTypes = {
  ...Button.propTypes,
  CircularProgressProps: PropTypes.instanceOf(Object),
  fabIcon: PropTypes.instanceOf(Object),
  iconButtonIcon: PropTypes.instanceOf(Object),
  loading: PropTypes.bool,
  success: PropTypes.bool,
};

// @ts-expect-error ts-migrate(7022) FIXME: 'defaultProps' implicitly has type 'any' because i... Remove this comment to see the full error message
Button.defaultProps = {
  ...Button.defaultProps,
  CircularProgressProps: {},
  fabIcon: null,
  iconButtonIcon: null,
  loading: false,
  success: false,
};

const useStyles = makeStyles(theme => ({
  button: {
    minHeight: 36,
    minWidth: 85,
  },

  buttonSuccess: {
    color: theme.palette.common.white,
    borderColor: green['700'],
    backgroundColor: green['700'],
    '&:hover': {
      backgroundColor: green['700'],
      cursor: 'default',

      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: green['700'],
      },
    },
  },

  buttonFail: {
    color: theme.palette.common.white,
    borderColor: red['500'],
    backgroundColor: red['500'],
    '&:hover': {
      backgroundColor: red['500'],
      cursor: 'default',

      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: red['500'],
      },
    },
  },

  rightIcon: {
    marginLeft: theme.spacing(1),
  },

  buttonProgressColor: {
    color: theme.palette.common.white,
  },
}));

export default Button;
