import { connect } from 'react-redux';
import { Map } from 'immutable';

// action creators
import { verifySession } from 'store/actions/sessionActions';

// components
import App from './App';

// selectors
import getCurrentUserGroups from 'store/selectors/getCurrentUserGroups';
import getCurrentGroup from 'store/selectors/getCurrentGroup';
import getCurrentUser from 'store/selectors/getCurrentUser';
import getLoadingFromState from 'store/selectors/getLoadingFromState';

const mapState = (state: any) => {
  return {
    sessionIsValid: state.getIn(['session', 'valid'], false),
    userHasGroup: getCurrentUserGroups(state).size > 0,
    isSystemAdmin: state.getIn(['currentUser', 'data', 'isSystemAdmin'], false),
    currentGroup: (getCurrentGroup(state) || Map()).toJS(),
    currentUser: (getCurrentUser(state) || Map()).toJS(),
    currentGroupLoading: getLoadingFromState('currentGroup', null, true)(state),
    currentUserLoading: getLoadingFromState('currentUser', null, true)(state),
  };
};

const mapDispatch = { verifySession };

export default connect(mapState, mapDispatch)(App as any);
