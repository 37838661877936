import React, {
  Dispatch,
  SetStateAction,
  useState,
  useCallback,
  useEffect,
} from 'react';
import { Field } from 'react-final-form';
import { Elements, ElementsConsumer } from '@stripe/react-stripe-js';
import { loadStripe, SetupIntent, Stripe } from '@stripe/stripe-js';
// hooks
import { useStripeApiKeyQuery } from 'api/billing';
// material-ui
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
// components
import StripeCard from 'components/ManageSubscription/components/StripeCard';
//helpers
import {
  StripeFieldErrorMessage,
  StripeConfirmPaymentIntent,
} from 'components/ManageSubscription/helpers/interfaces';
import { StripePaymentStatus } from 'components/ManageSubscription/helpers/types';

interface StripeFieldProps {
  mutators?: {
    setValue?: (field: string, value: string | boolean | null) => void;
  };
  stripePaymentStatus: StripePaymentStatus;
  callSubmitGlobalForm: () => void;
  onStripePaymentStatusChange: Dispatch<SetStateAction<StripePaymentStatus>>;
  onlyConfirmPaymentIntent?: boolean;
  stripeConfirmPaymentIntent?: StripeConfirmPaymentIntent;
  onSetupIntentSuccess?: (setupIntent?: SetupIntent) => void;
}

const StripeField = ({
  mutators,
  stripePaymentStatus,
  onStripePaymentStatusChange,
  callSubmitGlobalForm,
  onlyConfirmPaymentIntent,
  stripeConfirmPaymentIntent,
  onSetupIntentSuccess,
}: StripeFieldProps) => {
  const classes = useStyles();
  const { data: { stripeApiKey } = {} } = useStripeApiKeyQuery();
  const [stripePromise, setStripePromise] = useState<Stripe | null>(null);
  const [stripeErrorMessage, setStripeErrorMessage] = useState<
    StripeFieldErrorMessage[]
  >([]);

  const getElements = useCallback(
    () =>
      stripePromise ? (
        <Elements
          options={{
            fonts: [
              {
                cssSrc:
                  'https://fonts.googleapis.com/css?family=Poppins:400,500,600',
              },
            ],
          }}
          stripe={stripePromise}
        >
          <ElementsConsumer>
            {({ elements, stripe }) => (
              <StripeCard
                elements={elements}
                stripe={stripe}
                onStripeErrorMessageChange={setStripeErrorMessage}
                mutators={mutators}
                stripePaymentStatus={stripePaymentStatus}
                onStripePaymentStatusChange={onStripePaymentStatusChange}
                callSubmitGlobalForm={callSubmitGlobalForm}
                onlyConfirmPaymentIntent={onlyConfirmPaymentIntent}
                stripeConfirmPaymentIntent={stripeConfirmPaymentIntent}
                onSetupIntentSuccess={onSetupIntentSuccess}
              />
            )}
          </ElementsConsumer>
        </Elements>
      ) : (
        <>{!onlyConfirmPaymentIntent && <CircularProgress size={40} />}</>
      ),
    [
      stripePaymentStatus,
      stripeConfirmPaymentIntent,
      stripePromise,
      mutators,
      setStripeErrorMessage,
      onStripePaymentStatusChange,
      onSetupIntentSuccess,
      onlyConfirmPaymentIntent,
      callSubmitGlobalForm,
    ]
  );

  useEffect(() => {
    const getAndSetStripeData = async (apiKey: string) => {
      setStripePromise(await loadStripe(apiKey));
    };
    if (stripeApiKey) {
      getAndSetStripeData(stripeApiKey);
    }
  }, [stripeApiKey]);

  return onlyConfirmPaymentIntent ? (
    getElements()
  ) : (
    <Grid container className={classes.formContent}>
      <Grid item xs={12} className={classes.fieldStripeBox}>
        <Field name='cardNumberComplete'>{getElements}</Field>
        {stripeErrorMessage.length > 0 && (
          <div className={classes.stripeErrorMessage}>
            {stripeErrorMessage.map(
              ({ key, value }: StripeFieldErrorMessage) => (
                <div key={key || ''}>{value || ''}</div>
              )
            )}
          </div>
        )}
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles(theme => ({
  fieldStripeBox: {
    marginTop: 16,
    marginBottom: 12,
  },
  formContent: {},
  stripeErrorMessage: {
    fontSize: '0.75rem',
    paddingTop: 7,
    color: theme.palette.error.main,
  },
}));

export default StripeField;
