import { all } from 'redux-saga/effects';

// sagas
import fetchGroupTagsSaga from './fetchGroupTagsSaga';
import createGroupTagsSaga from './createGroupTagsSaga';
import deleteGroupTagSaga from './deleteGroupTagSaga';

const groupTagSaga = function* rootSaga() {
  yield all([
    fetchGroupTagsSaga(),
    deleteGroupTagSaga(),
    createGroupTagsSaga(),
  ]);
};

export default groupTagSaga;
