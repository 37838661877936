import React, { useMemo } from 'react';
import moment from 'moment';
import { useEventTimeSlotsQuery } from 'api/eventTimeSlots';

// material-ui
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

// hooks
import { useHistory } from 'react-router-dom';

// components
import Select from 'components/Select';
import { Field } from 'react-final-form';

const EventFields = function({ formData }: { formData: ReferralForm }) {
  const classes = useStyles();
  const history = useHistory();
  const { location: { pathname } = {} } = history;

  // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
  const editing = pathname.includes('edit');
  const { data: allTimeSlots = [] } = useEventTimeSlotsQuery(
    Number(formData.eventId),
    formData.groupId
  );
  const availableTimeSlots = useMemo(
    () => allTimeSlots.filter(ets => ets.isAvailable),
    [allTimeSlots]
  );

  const rsvpOptions = [
    { value: 'yes', label: 'Yes' },
    { value: 'no', label: 'No' },
    { value: 'maybe', label: 'Maybe' },
  ];

  const timeSlotOptions = availableTimeSlots.map((timeSlot: EventTimeSlot) => {
    const { id, startDate, endDate, description } = timeSlot;

    const startDateTimeString = moment.utc(startDate).local();
    const startDateLabel = startDateTimeString.format('dddd, MMM Do LT');

    const endDateTimeString = moment.utc(endDate).local();
    const sameDate =
      startDateTimeString.format('MMM D') === endDateTimeString.format('MMM D');

    const endDateLabel = sameDate
      ? endDateTimeString.format('LT')
      : endDateTimeString.format('dddd, MMM Do LT');

    let timeSlotLabel = `${startDateLabel} to ${endDateLabel}`;
    timeSlotLabel = description
      ? `${timeSlotLabel} - (${description})`
      : timeSlotLabel;

    return {
      value: id,
      label: timeSlotLabel,
    };
  });

  return (
    <Grid item xs={12} className={classes.eventFieldsContent}>
      <Grid container>
        {allTimeSlots.length > 1 ? (
          <>
            <Grid item xs={12}>
              <Field
                name='eventTimeSlotId'
                disabled={editing}
                variant='outlined'
                options={timeSlotOptions}
                component={Select}
                label='Select a meeting time'
                required
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                name='rsvp'
                initialValue='yes'
                component={'input'}
                hidden
              />
            </Grid>
          </>
        ) : (
          <>
            <Grid item xs={12}>
              <Field
                name='rsvp'
                disabled={editing}
                variant='outlined'
                options={rsvpOptions}
                component={Select}
                label='Will you be attending?'
                required
              />
              <Grid item xs={12}>
                <Field
                  name='eventTimeSlotId'
                  initialValue={availableTimeSlots[0]?.id}
                  component={'input'}
                  hidden
                />
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles(theme => {
  return {
    eventFieldsContent: {
      paddingTop: 15,
      paddingLeft: 50,
      paddingRight: 50,

      [theme.breakpoints.only('xs')]: {
        paddingLeft: 20,
        paddingRight: 20,
      },
    },
  };
});

export default EventFields;
