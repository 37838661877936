import React from 'react';

// components
import Landing from 'layout/Landing';
import LoginForm from './LoginForm';
import LoginFormHeader from './LoginFormHeader';
import LoginSSO from './LoginSSO';

// helpers
import isNamesListSite from 'helpers/isNamesListSite';

const Login = function() {
  return (
    <Landing>
      <LoginFormHeader />
      <LoginForm />

      {/* TODO: Remove this after this will be handled from backend*/}
      {!isNamesListSite() && <LoginSSO />}
    </Landing>
  );
};

export default Login;
