import React from 'react';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
// MUI components
import { Grid, Typography } from '@material-ui/core';
type OwnProps = {
  title?: string;
  titleColor?: string;
  description?: string;
  descriptionColor?: string;
  variant?: 'outlined' | 'default';
};
type Props = OwnProps & typeof EmptyMessage.defaultProps;
export default function EmptyMessage({
  title,
  titleColor,
  description,
  descriptionColor,
  variant,
}: Props) {
  const classes = useStyles();
  return (
    <Grid
      container
      id='emptyMessage'
      className={classNames(
        classes.container,
        variant === 'outlined' && classes.outlined
      )}
    >
      <Grid item xs={12}>
        {/* @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call. */}
        <Typography variant='body2' color={titleColor} className={classes.bold}>
          {title}
        </Typography>
      </Grid>
      {description && (
        <Grid item xs={12} id='description'>
          {/* @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call. */}
          <Typography variant='body2' color={descriptionColor}>
            {description}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
}
EmptyMessage.defaultProps = {
  title: 'None Found.',
  titleColor: 'initial',
  descriptionColor: 'textSecondary',
  variant: 'default',
};
const useStyles = makeStyles(theme => ({
  container: {
    padding: 20,
  },
  outlined: {
    border: `1px solid ${(theme.palette.background as any).lightPaper}`,
    borderRadius: 8,
  },
  bold: {
    fontWeight: 600,
  },
}));
