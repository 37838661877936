import { all } from 'redux-saga/effects';

// sagas
import fetchMessagesSaga from './fetchMessagesSaga';
import createMessageSaga from './createMessageSaga';
import listenForMessagesSaga from './listenForMessagesSaga';

const messageSaga = function* rootSaga() {
  yield all([
    fetchMessagesSaga(),
    createMessageSaga(),
    listenForMessagesSaga(),
  ]);
};

export default messageSaga;
