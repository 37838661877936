import { parsePhoneNumberFromString } from 'libphonenumber-js';

export default function formatDisplayedPhoneNumber(
  phoneNumber?: string | null
) {
  if (!phoneNumber) {
    return '';
  }

  let parsedNumber = parsePhoneNumberFromString(phoneNumber, 'US');
  let displayedPhoneNumber;

  if (parsedNumber?.countryCallingCode === '1') {
    displayedPhoneNumber = parsedNumber?.formatNational() || '';
  } else {
    displayedPhoneNumber = parsedNumber?.formatInternational() || '';
  }
  return displayedPhoneNumber;
}
