import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { List } from 'immutable';

// material-ui
import IconButton from '@material-ui/core/IconButton';
import Fade from '@material-ui/core/Fade';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

// icons
import LeftArrowIcon from '@material-ui/icons/KeyboardArrowLeft';
import RightArrowIcon from '@material-ui/icons/KeyboardArrowRight';

const ITEMS_PER_FETCH = 10; // items fetched per page via the API

const SimplePagination = function({
  disableNextPage,
  onPageChange,
  total,
  items,
  loading,
}: any) {
  const classes = useStyles();
  const [currentPage, setCurrentPage] = useState(1);

  const from = ITEMS_PER_FETCH * (currentPage - 1) + 1;
  const to =
    total && total < ITEMS_PER_FETCH * currentPage
      ? total
      : ITEMS_PER_FETCH * currentPage;

  const handlePreviousPage = () => {
    setCurrentPage(currentPage - 1);
    onPageChange(currentPage - 1);
  };

  const handleNextPage = () => {
    setCurrentPage(currentPage + 1);
    onPageChange(currentPage + 1);
  };

  return (
    <Grid
      container
      direction='column'
      alignItems='center'
      justifyContent='center'
    >
      <Fade in={!loading && items.size > 0}>
        <Grid item>
          <Typography className={classes.paginationLabel} align='center'>
            {from} - {to}
            {total ? ` of ${total}` : ''}
          </Typography>
        </Grid>
      </Fade>

      <Grid item>
        <IconButton
          id='previousButton'
          size='medium'
          onClick={handlePreviousPage}
          disabled={currentPage === 1}
        >
          <LeftArrowIcon />
        </IconButton>

        <IconButton
          id='nextButton'
          size='medium'
          onClick={handleNextPage}
          disabled={disableNextPage}
        >
          <RightArrowIcon />
        </IconButton>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles({
  paginationLabel: {
    fontSize: 12,
  },
});

SimplePagination.propTypes = {
  onPageChange: PropTypes.func.isRequired,
  disableNextPage: PropTypes.bool.isRequired,
  total: PropTypes.number,
  loading: PropTypes.bool,
  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'typeof List' is not assignable t... Remove this comment to see the full error message
  items: PropTypes.instanceOf(List),
};

SimplePagination.defaultProps = {
  items: List([1]),
};

export default SimplePagination;
