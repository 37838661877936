import { all, call, fork, put, takeEvery, select } from 'redux-saga/effects';

// action creators
import { doneIndicator } from 'store/actions/httpActions';

// selector
import getCurrentGroupId from 'store/selectors/getCurrentGroupId';

// api
import client from 'sources/api';

// constants
import { NOTE_CREATE_REQUEST, NOTE_CREATE } from 'store/constants/noteTypes';

// schemas
import { noteSchema } from 'store/schemas/noteSchema';

// helpers
import httpSaga from 'store/sagas/httpSaga';

const WATCH_TYPE = NOTE_CREATE_REQUEST;
const TYPE = NOTE_CREATE;

export function* createNote(action: any) {
  const {
    payload: {
      formName,
      fields: { body, leadId, tagIds: tag_ids, callSid: call_sid },
      callbackOnSuccess = () => {},
    },
  } = action;

  const currentGroupId = yield select(getCurrentGroupId);
  // @ts-expect-error ts-migrate(2569) FIXME: Type 'Generator<any, any, unknown>' is not an arra... Remove this comment to see the full error message
  const response = yield* httpSaga(
    TYPE,
    call(client.post, `/notes?group_id=${currentGroupId}`, {
      body,
      pnm_id: leadId,
      tag_ids,
      call_sid,
    }),
    {
      schema: noteSchema,
      formName,
    }
  );

  const { status } = response || {};
  if (status === 200 || status === 201) {
    callbackOnSuccess();
  }

  yield put(doneIndicator(TYPE));
}

export function* watch() {
  yield takeEvery(WATCH_TYPE, createNote);
}

export default function* root() {
  yield all([fork(watch)]);
}
