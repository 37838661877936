import { Map } from 'immutable';
// constants
import { CURRENT_GROUP_FETCH } from 'store/constants/groupTypes';
// helpers
import httpReducer from './helpers/httpReducer';

export default function reduceRole(state = Map(), action = {}) {
  switch ((action as any).type) {
    case CURRENT_GROUP_FETCH:
      return httpReducer(state, action, {
        entity: 'role',
        updateResult: false,
      });
    default:
      return state;
  }
}
