import React, { useState, useCallback, useMemo } from 'react';
import { useHistory } from 'react-router';
import { Map, List } from 'immutable';
import { memoize, isEqual } from 'lodash';
import pluralize from 'pluralize';
import getGroupTypeName from 'helpers/getGroupTypeName';
// hooks
import { useSelector } from 'react-redux';
// material-ui
import {
  Grid,
  Checkbox as MuiCheckbox,
  Tooltip,
  Fade,
  Typography,
  Hidden,
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';
// icons
import { Edit, Delete, ArrowDropDown, LocalOffer } from '@material-ui/icons';
// components
import Button from 'components/Button';
import MegaMenu from 'components/MegaMenu';
import UpdateGroupForm from './UpdateGroupForm';
import DeleteGroupForm from './DeleteGroupForm';
import AssignLabelsForm from './AssignLabelsForm';
// selectors
import getPermission from 'store/selectors/getPermission';
import getLoadingFromState from 'store/selectors/getLoadingFromState';
import getIsSystemAdmin from 'store/selectors/getIsSystemAdmin';
import getIsHqAdmin from 'store/selectors/getIsHqAdmin';
import getIsCurrentGroupOrganization from 'store/selectors/getIsCurrentGroupOrganization';
const BULK_ACTIONS = ['updateGroup', 'deleteGroup'];
type Props = {
  allSelected: boolean;
  resetSelected: (...args: any[]) => any;
  data: any; // TODO: PropTypes.instanceOf(Array)
  selectedGroups: any; // TODO: PropTypes.instanceOf(Object)
  setAllSelected: (...args: any[]) => any;
};
export default function GroupListActions({
  allSelected,
  data,
  resetSelected,
  selectedGroups,
  setAllSelected,
}: Props) {
  const selectedIds = useMemo(() => Object.keys(selectedGroups), [
    selectedGroups,
  ]);
  const classes = useStyles();
  const history = useHistory();
  const { location: { pathname } = {} } = history;
  const [actionsAnchorEl, setActionsAnchorEl] = useState(null);
  const [modalKey, setModalKey] = useState(null);
  const groupType = getGroupTypeName(pathname);
  const {
    canUpdateGroup,
    canDeleteGroup,
    numTotalGroups,
    groupsLoading,
    isSystemAdmin,
    isHqAdmin,
    isCurrentGroupOrganization,
  } = useSelector(
    state => ({
      canUpdateGroup: getPermission('group.updateOne')(state),
      canDeleteGroup: getPermission('group.deleteOne')(state),
      numTotalGroups: (state as any).getIn(
        ['group', 'meta', 'total'],
        data.length
      ),
      groupsLoading: getLoadingFromState('group', false, false)(state),
      isSystemAdmin: getIsSystemAdmin(state),
      isHqAdmin: getIsHqAdmin(state),
      isCurrentGroupOrganization: getIsCurrentGroupOrganization(state),
    }),
    isEqual
  );
  const handleModalKeyChange = useCallback(
    newKey =>
      memoize(() =>
        setModalKey(oldKey => {
          // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
          if (newKey === null && BULK_ACTIONS.includes(oldKey)) resetSelected();
          return newKey;
        })
      ),
    [resetSelected]
  );
  const areGroupsSelected = selectedIds.length > 0 || allSelected;
  const groupsLabel = selectedIds.length === 1 ? 'group' : 'groups';
  const headerCount = allSelected ? 'all' : `${selectedIds.length} selected`;
  let groupActionsMenu = List([
    Map({
      display: areGroupsSelected,
      sectionHeader: `Actions for ${headerCount} ${
        selectedIds.length === 1 ? 'group' : 'groups'
      }`,
      id: 'selectedActions',
      items: List([
        Map({
          id: 'updateGroup',
          display:
            canUpdateGroup &&
            (isHqAdmin || isSystemAdmin) &&
            selectedIds.length === 1,
          label: 'Edit Group',
          icon: <Edit />,
          onClick: handleModalKeyChange('updateGroup'),
        }),
        Map({
          id: 'assignLabels',
          display: isCurrentGroupOrganization && selectedIds.length === 1,
          label: 'Assign Labels',
          icon: <LocalOffer />,
          onClick: handleModalKeyChange('assignLabels'),
        }),
      ]),
    }),
    Map({
      display: canDeleteGroup && isSystemAdmin && selectedIds.length === 1,
      sectionHeader: `Delete Actions`,
      id: 'deleteActions',
      items: List([
        Map({
          id: 'deleteGroup',
          label: 'Delete Group',
          icon: <Delete />,
          color: 'primary',
          onClick: handleModalKeyChange('deleteGroup'),
        }),
      ]),
    }),
  ]);
  const menuSectionsToDisplay = groupActionsMenu.filter(item =>
    item.get('display')
  );
  const firstSelectedItem =
    data.find((item = {}) => selectedGroups[(item as any).id]) || {};
  const onCheckedToggle = useCallback(() => {
    if (selectedIds.length > 0) {
      setAllSelected(false);
      resetSelected();
    } else {
      setAllSelected(!allSelected);
    }
  }, [allSelected, resetSelected, selectedIds.length, setAllSelected]);
  return (
    <Grid
      container
      spacing={3}
      alignItems='center'
      className={classes.toolbarContainer}
    >
      <Grid item xs={6}>
        <Grid container justifyContent='flex-start' alignItems='center'>
          {isSystemAdmin && (
            <Hidden xsDown>
              <Tooltip
                id='headerCheckboxTooltip'
                title={areGroupsSelected ? 'Deselect All' : 'Select All'}
              >
                <MuiCheckbox
                  checked={areGroupsSelected}
                  color='primary'
                  className={(classes as any).selectAllCheckbox}
                  indeterminate={areGroupsSelected}
                  onChange={onCheckedToggle}
                />
              </Tooltip>
            </Hidden>
          )}

          <Hidden xsDown>
            {groupsLoading ? (
              <Skeleton width={160} height={40} />
            ) : (
              data.length > 0 && (
                <Typography color='textSecondary' variant='subtitle2'>
                  Displaying {numTotalGroups}{' '}
                  {pluralize('group', numTotalGroups)}
                </Typography>
              )
            )}
          </Hidden>
        </Grid>
      </Grid>

      <Grid item xs={6} id='groupListActions'>
        <Grid container justifyContent='flex-end' alignItems='center'>
          {areGroupsSelected && (
            <Hidden xsDown>
              <Fade in={areGroupsSelected}>
                <Typography
                  variant='body2'
                  id='selectedLabel'
                  className={classes.selectedLabelMargin}
                >
                  {allSelected
                    ? 'All groups selected'
                    : `${selectedIds.length} ${groupsLabel} selected`}
                </Typography>
              </Fade>
            </Hidden>
          )}

          <Button
            disabled={!menuSectionsToDisplay.size}
            variant={areGroupsSelected ? 'contained' : 'outlined'}
            color={areGroupsSelected ? 'primary' : 'default'}
            onClick={(e: any) => {
              setActionsAnchorEl(e.currentTarget);
            }}
          >
            Actions
            <ArrowDropDown />
          </Button>
        </Grid>
      </Grid>

      <MegaMenu
        // @ts-expect-error ts-migrate(2322) FIXME: Type '{ id: string; anchorEl: null; handleClose: (... Remove this comment to see the full error message
        id='groupActionsMenu'
        anchorEl={actionsAnchorEl}
        handleClose={() => {
          setActionsAnchorEl(null);
        }}
        menuSections={groupActionsMenu}
      />

      <UpdateGroupForm
        id='updateGroupForm'
        open={modalKey === 'updateGroup'}
        groupType={groupType}
        initialValues={firstSelectedItem}
        handleClose={handleModalKeyChange(null)}
      />

      <DeleteGroupForm
        // @ts-expect-error ts-migrate(2322) FIXME: Type '{ id: string; open: boolean; groupType: stri... Remove this comment to see the full error message
        id='deleteGroupForm'
        open={modalKey === 'deleteGroup'}
        groupType={groupType}
        initialValues={firstSelectedItem}
        handleClose={handleModalKeyChange(null)}
      />

      <AssignLabelsForm
        selectedGroupId={firstSelectedItem.id}
        open={modalKey === 'assignLabels'}
        onClose={handleModalKeyChange(null)}
      />
    </Grid>
  );
}
const useStyles = makeStyles(theme => {
  return {
    toolbarContainer: {
      paddingLeft: 24,
      paddingRight: 24,
      marginBottom: 2,
      borderBottom: `1px solid ${(theme as any).palette.background.paper}`,
      backgroundColor: (theme as any).palette.background.darkPaper,
      [theme.breakpoints.only('xs')]: {
        paddingLeft: 8,
        paddingRight: 8,
      },
    },
    leftActionsContainer: {
      paddingLeft: 4,
    },
    selectedLabelMargin: {
      marginRight: 15,
      marginLeft: 15,
    },
    banner: {
      backgroundColor: theme.palette.primary.main,
      marginTop: -16,
      marginBottom: 16,
    },
    bannerButton: {
      textAlign: 'right',
    },
  };
});
