export default function getGroupTypeName(pathname: any) {
  if (pathname.includes('chapters')) {
    return 'greekChapter';
  } else if (pathname.includes('organizations')) {
    return 'greekOrganization';
  } else if (pathname.includes('councils')) {
    return 'council';
  } else {
    return '';
  }
}
