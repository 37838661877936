import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { Map } from 'immutable';

// action creators
import { doneIndicator, success } from 'store/actions/httpActions';

// api
import client from 'sources/api';

// constants
import {
  GROUP_FIELD_DELETE_REQUEST,
  GROUP_FIELD_DELETE,
} from 'store/constants/groupFieldTypes';

// schemas
import { groupFieldSchema } from 'store/schemas/groupFieldSchema';

// helpers
import httpSaga from 'store/sagas/httpSaga';

const WATCH_TYPE = GROUP_FIELD_DELETE_REQUEST;
const TYPE = GROUP_FIELD_DELETE;

export function* deleteGroupField(action: any) {
  const {
    payload: { formName, groupId, id },
  } = action;

  // @ts-expect-error ts-migrate(2569) FIXME: Type 'Generator<any, any, unknown>' is not an arra... Remove this comment to see the full error message
  yield* httpSaga(
    TYPE,
    call(client.delete, `/group_fields/${id}`, {
      params: { group_id: groupId },
    }),
    {
      schema: groupFieldSchema,
      formName,
      dispatchSuccess: false,
    }
  );

  yield put(success(TYPE, Map({ id })));
  yield put(doneIndicator(TYPE));
}

export function* watch() {
  yield takeEvery(WATCH_TYPE, deleteGroupField);
}

export default function* root() {
  yield all([fork(watch)]);
}
