import { Grid, makeStyles, Typography } from '@material-ui/core';
import DazzlingDialog from 'components/DazzlingDialog';
import { Form, Field } from 'react-final-form';
import validate from 'validate.js';
import LockIcon from '@material-ui/icons/Lock';
import Select from 'components/Select';
import TextField from 'components/TextField';
import { useMemo } from 'react';

interface Props {
  onSubmit: (data: AuthIntegration) => void;
  onClose?: () => void;
  integration?: AuthIntegration | null;
  open?: boolean;
}

const schema = {
  type: {
    presence: true,
  },
  name: {
    presence: true,
  },
  label: {
    presence: true,
  },
  clientId: { presence: true },
  clientSecret: { presence: true },
  scope: { presence: true },
  discoveryUri: (value: string, attributes: any) => {
    if (
      !attributes.issuer &&
      !attributes.authorizationEndpoint &&
      !attributes.jwksUri &&
      !attributes.tokenEndpoint &&
      !attributes.introspectionEndpoint &&
      !attributes.revocationEndpoint &&
      !attributes.signoutEndpoint
    ) {
      return { presence: true, url: true };
    }

    return { presence: false, url: true };
  },
  issuer: (value: string, attributes: any) => {
    if (!attributes.discoveryUri) {
      return { presence: true, url: true };
    }

    return { presence: false, url: true };
  },
  authorizationEndpoint: (value: string, attributes: any) => {
    if (!attributes.discoveryUri) {
      return { presence: true, url: true };
    }

    return { presence: false, url: true };
  },
  tokenEndpoint: (value: string, attributes: any) => {
    if (!attributes.discoveryUri) {
      return { presence: true, url: true };
    }

    return { presence: false, url: true };
  },
  jwksUri: (value: string, attributes: any) => {
    if (!attributes.discoveryUri) {
      return { presence: true, url: true };
    }

    return { presence: false, url: true };
  },
  introspectionEndpoint: (value: string, attributes: any) => {
    if (!attributes.discoveryUri) {
      return { presence: true, url: true };
    }

    return { presence: false, url: true };
  },
  signoutEndpoint: (value: string, attributes: any) => {
    if (!attributes.discoveryUri) {
      return { presence: true, url: true };
    }

    return { presence: false, url: true };
  },
  revocationEndpoint: (value: string, attributes: any) => {
    if (!attributes.discoveryUri) {
      return { presence: true, url: true };
    }

    return { presence: false, url: true };
  },
  roleField: { presence: false },
  councilField: { presence: false },
  organizationField: { presence: false },
  firstNameField: { presence: false },
  lastNameField: { presence: false },
  emailField: { presence: false },
  pictureField: { presence: false },
};

export function EditIntegrationDialog({
  onSubmit,
  onClose,
  integration,
  open,
}: Props) {
  const classes = useStyles();
  const initialValues = useMemo(() => {
    if (!integration) {
      return {};
    }

    return { ...integration };
  }, [integration]);

  return (
    <Form<AuthIntegration>
      onSubmit={onSubmit}
      validate={values => validate(values, schema)}
      initialValues={initialValues}
      render={({ handleSubmit, invalid, form: { reset, getState } }) => (
        <DazzlingDialog
          open={open ?? false}
          acceptLabel='Save'
          disabled={invalid}
          formName='authIntegrationForm'
          onAccept={handleSubmit}
          handleClose={() => {
            reset();
            onClose?.();
          }}
          headerProps={{
            title: 'Edit SSO Integration', // TODO: populate "Create" or "Edit" dynamically
            icon: LockIcon,
          }}
        >
          <Grid container direction='row' spacing={1}>
            <Grid item xs={12}>
              <Field
                name='type'
                label='Type'
                render={fieldProps => (
                  <Select
                    options={[{ value: 'oidc1.0', label: 'OpenID Connect' }]}
                    {...fieldProps}
                  />
                )}
                require
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Field
                name='name'
                label='Name'
                render={fieldProps => <TextField {...fieldProps} />}
                require
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Field
                name='label'
                label='Label'
                render={fieldProps => <TextField {...fieldProps} />}
                require
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Field
                name='clientId'
                label='Client ID'
                render={fieldProps => <TextField {...fieldProps} />}
                require
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Field
                name='clientSecret'
                label='Client Secret'
                render={fieldProps => <TextField {...fieldProps} />}
                require
              />
            </Grid>

            <Grid item xs={12}>
              <Field
                name='scope'
                label='Scope'
                render={fieldProps => <TextField {...fieldProps} />}
                require
              />
            </Grid>

            {!getState().values?.issuer &&
              !getState().values?.authorizationEndpoint &&
              !getState().values?.tokenEndpoint &&
              !getState().values?.jwksUri &&
              !getState().values?.introspectionEndpoint &&
              !getState().values?.revocationEndpoint &&
              !getState().values?.signoutEndpoint && (
                <Grid item xs={12}>
                  <Field
                    name='discoveryUri'
                    label='Discovery Uri'
                    render={fieldProps => <TextField {...fieldProps} />}
                  />
                </Grid>
              )}

            {!getState().values?.discoveryUri && (
              <Grid item xs={12} sm={6}>
                <Field
                  name='issuer'
                  label='Issuer'
                  render={fieldProps => <TextField {...fieldProps} />}
                />
              </Grid>
            )}

            {!getState().values?.discoveryUri && (
              <Grid item xs={12} sm={6}>
                <Field
                  name='authorizationEndpoint'
                  label='Authorization Endpoint'
                  render={fieldProps => <TextField {...fieldProps} />}
                />
              </Grid>
            )}

            {!getState().values?.discoveryUri && (
              <Grid item xs={12} sm={6}>
                <Field
                  name='tokenEndpoint'
                  label='Token Endpoint'
                  render={fieldProps => <TextField {...fieldProps} />}
                />
              </Grid>
            )}

            {!getState().values?.discoveryUri && (
              <Grid item xs={12} sm={6}>
                <Field
                  name='jwksUri'
                  label='JWKS URI'
                  render={fieldProps => <TextField {...fieldProps} />}
                />
              </Grid>
            )}

            {!getState().values?.discoveryUri && (
              <Grid item xs={12} sm={6}>
                <Field
                  name='introspectionEndpoint'
                  label='Introspection Endpoint'
                  render={fieldProps => <TextField {...fieldProps} />}
                />
              </Grid>
            )}

            {!getState().values?.discoveryUri && (
              <Grid item xs={12} sm={6}>
                <Field
                  name='revocationEndpoint'
                  label='Revocation Endpoint'
                  render={fieldProps => <TextField {...fieldProps} />}
                />
              </Grid>
            )}

            {!getState().values?.discoveryUri && (
              <Grid item xs={12} sm={6}>
                <Field
                  name='signoutEndpoint'
                  label='Signout Endpoint'
                  render={fieldProps => <TextField {...fieldProps} />}
                />
              </Grid>
            )}

            <Grid item xs={12} className={classes.formFieldSection}>
              <Typography variant='h6'>Customize UserInfo Fields</Typography>
            </Grid>

            <Grid item xs={12}>
              <Field
                name='roleField'
                label='Role Field'
                render={fieldProps => <TextField {...fieldProps} />}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Field
                name='councilField'
                label='Council Field'
                render={fieldProps => <TextField {...fieldProps} />}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Field
                name='organizationField'
                label='Organization Field'
                render={fieldProps => <TextField {...fieldProps} />}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Field
                name='firstNameField'
                label='First Name Field'
                render={fieldProps => <TextField {...fieldProps} />}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Field
                name='lastNameField'
                label='Last Name Field'
                render={fieldProps => <TextField {...fieldProps} />}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Field
                name='emailField'
                label='Email Field'
                render={fieldProps => <TextField {...fieldProps} />}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Field
                name='pictureField'
                label='Picture Field'
                render={fieldProps => <TextField {...fieldProps} />}
              />
            </Grid>
          </Grid>
        </DazzlingDialog>
      )}
    />
  );
}

const useStyles = makeStyles({
  formFieldSection: {
    marginTop: '0.7em',
  },
});
