import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

// action creators
import { doneIndicator } from 'store/actions/httpActions';

// api
import client from 'sources/api';

// schemas
import { apiTaskSchema } from 'store/schemas/apiTaskSchema';

// constants
import {
  API_TASK_FETCH,
  API_TASK_FETCH_REQUEST,
} from 'store/constants/apiTaskTypes';

// helpers
import httpSaga from 'store/sagas/httpSaga';

const WATCH_TYPE = API_TASK_FETCH_REQUEST;
const TYPE = API_TASK_FETCH;

export function* fetchTaskSaga(action: any) {
  const {
    payload: { taskId, groupId: group_id },
  } = action;

  // @ts-expect-error ts-migrate(2569) FIXME: Type 'Generator<any, any, unknown>' is not an arra... Remove this comment to see the full error message
  yield* httpSaga(
    TYPE,
    call(client.get, `/tasks/${taskId}`, {
      params: { group_id },
    }),
    { schema: apiTaskSchema }
  );

  yield put(doneIndicator(TYPE));
}

export function* watch() {
  yield takeEvery(WATCH_TYPE, fetchTaskSaga);
}

export default function* root() {
  yield all([fork(watch)]);
}
