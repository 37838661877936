export const MEMBER_FETCH_FOR_GROUP_REQUEST = 'MEMBER_FETCH_FOR_GROUP_REQUEST';
export const MEMBER_FETCH_FOR_GROUP = 'MEMBER_FETCH_FOR_GROUP';

export const MEMBER_CLEAR = 'MEMBER_CLEAR';

export const MEMBER_UPDATE_REQUEST = 'MEMBER_UPDATE_REQUEST';
export const MEMBER_UPDATE = 'MEMBER_UPDATE';

export const MEMBER_DELETE_REQUEST = 'MEMBER_DELETE_REQUEST';
export const MEMBER_DELETE = 'MEMBER_DELETE';

export const MEMBER_DELETE_ALL_FOR_GROUP_REQUEST =
  'MEMBER_DELETE_ALL_FOR_GROUP_REQUEST';
export const MEMBER_DELETE_ALL_FOR_GROUP = 'MEMBER_DELETE_ALL_FOR_GROUP';
