import React, { useMemo, useEffect, useCallback } from 'react';
import { isEqual } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';

// MUI components
import {
  Grid,
  Card,
  CardContent,
  Hidden,
  WithStyles,
  withStyles,
} from '@material-ui/core';
import { LocalOffer } from '@material-ui/icons';

// components
import Header from 'components/Header';
import Table from 'components/Table';
import ExportButton from 'components/ExportButton';
import ReportRefreshButton from 'components/ReportRefreshButton';

// action creators
import { createTagUsageReport } from 'store/actions/apiTaskActions';

// selectors
import getCurrentGroupId from 'store/selectors/getCurrentGroupId';
import getForm from 'store/selectors/getForm';
import getSliceEntityById from 'store/selectors/getSliceEntityById';

// styles
import tagUsageCardStyles from 'routes/TagsAnalytics/TagUsageCard/tagUsageCard.style';

const REPORT_FORM = 'tagUsageReportForm';
const rowsPerPage = 10;

export interface TagUsageCardProps
  extends WithStyles<typeof tagUsageCardStyles> {
  isCouncilTagsAnalytics?: boolean;
}

const TagUsageCard = ({
  classes,
  isCouncilTagsAnalytics,
}: TagUsageCardProps) => {
  const dispatch = useDispatch();
  const initialOrder = 'asc';
  const initialOrderBy = 'tagTitle';
  const {
    currentGroupId,
    report: {
      data: {
        content: unformattedContent = [],
        meta: { columns = [] } = {},
      } = {},
      id: reportId,
    },
    form: { isSubmitting },
  } = useSelector(
    state => ({
      currentGroupId: getCurrentGroupId(state),
      form: getForm(REPORT_FORM)(state),
      report: getSliceEntityById('report', 'ReportTypes.TAG_USAGE', {})(state),
    }),
    isEqual
  );

  const newColumns = useMemo(() => {
    if (isCouncilTagsAnalytics && columns.length > 0) {
      return columns.map((item: { key: string; label: string }) =>
        item.key === 'tagCount' ? { ...item, label: 'Times Used' } : item
      );
    }

    return columns;
  }, [columns, isCouncilTagsAnalytics]);

  const content = useMemo(
    () =>
      unformattedContent.map(({ leadsTagged = [], ...rest }, index: any) => {
        let leadsTaggedString = leadsTagged.slice(0, 3).join(', ');
        let othersTagged;
        if (leadsTagged?.length > 3) {
          leadsTaggedString = `${leadsTaggedString}, +${leadsTagged.length -
            3} others`;
          // Assigns overflow names to tooltip
          othersTagged = `+ ${leadsTagged.slice(3).join(', ')}`;
        }
        return {
          ...rest,
          cellTooltipTitle: othersTagged,
          leadsTagged: leadsTaggedString,
        };
      }),
    [unformattedContent]
  );

  const dispatchCreateReport = useCallback(
    ({ forceRefresh }: { forceRefresh: boolean }) => {
      dispatch(createTagUsageReport({ groupId: currentGroupId, forceRefresh }));
    },
    [dispatch, currentGroupId]
  );

  useEffect(() => {
    dispatchCreateReport({ forceRefresh: false });
  }, [dispatchCreateReport]);

  return (
    <Card>
      <CardContent className={classes.cardContent}>
        <Grid container>
          <Grid item xs>
            <Header
              title='Lead Tags Usage'
              icon={LocalOffer}
              {...(isCouncilTagsAnalytics
                ? { subtitle: 'View a breakdown of your chapter’s tag usage' }
                : {})}
              compact
            />
          </Grid>

          <Hidden xsDown>
            <Grid item xs={2}>
              <Grid container justifyContent='flex-end'>
                <Grid item>
                  <ReportRefreshButton
                    loading={isSubmitting}
                    onRefresh={dispatchCreateReport}
                  />
                </Grid>
                <ExportButton params={{ reportId }} disabled={isSubmitting} />
              </Grid>
            </Grid>
          </Hidden>

          <Grid item xs={12}>
            <Table
              columns={newColumns}
              data={content}
              variant='outlined'
              numberOfSkeletonRows={isSubmitting ? 8 : 0}
              initialOrder={initialOrder}
              initialOrderBy={initialOrderBy}
              TablePaginationProps={{
                count: content.length,
                rowsPerPage,
                rowsPerPageOptions: [rowsPerPage],
              }}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default withStyles(tagUsageCardStyles)(TagUsageCard);
