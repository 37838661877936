import { all } from 'redux-saga/effects';

// sagas
import fetchLeadTags from './fetchLeadTagsSaga';

const leadTagSaga = function* rootSaga() {
  yield all([fetchLeadTags()]);
};

export default leadTagSaga;
