import { ManageSubscriptionStatus } from 'components/ManageSubscription/helpers/types';

const getNextAcceptLabel = (nextStep: ManageSubscriptionStatus): string => {
  switch (nextStep) {
    case ManageSubscriptionStatus.SelectYourPlanYearOrMonth:
      return 'Finalize your plan';
    case ManageSubscriptionStatus.EnterPaymentMethod:
      return 'Enter Payment Info';
    case ManageSubscriptionStatus.ConfirmOrder:
      return 'Review Order';
    case ManageSubscriptionStatus.DowngradePlan:
      return 'Confirm';
    default:
      return 'Submit';
  }
};

export default getNextAcceptLabel;
