import React, { useEffect, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { isEqual } from 'lodash';
import { Map } from 'immutable';
import queryString from 'query-string';
// @ts-ignore
// import crypto from 'crypto-browserify';
import CryptoJS from 'crypto-js';
import classNames from 'classnames';
// hooks
import { useSelector, useDispatch } from 'react-redux';
import { usePrevious } from 'helpers/hooks/usePrevious';
import useElevio from './helpers/useElevio';

// action creators
import { setCurrentGroup } from 'store/actions/groupActions';
import { stopListeningForThreadsAction } from 'store/actions/threadActions';
import { stopListeningForMessagesAction } from 'store/actions/messageActions';
import { fetchStripeConfig } from 'store/actions/billingActions';
import { fetchCurrentUser } from 'store/actions/userActions';

// react router
import { Route } from 'react-router-dom';

// MUI components
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Hidden from '@material-ui/core/Hidden';
import Toolbar from '@material-ui/core/Toolbar';
import Box from '@material-ui/core/Box';

// components
import AppHeader from 'layout/ApplicationLayout/components/AppHeader';
import MobileMenu from 'routes/MobileMenu';
import MobileNavigation from 'layout/MobileNavigation';
import Sidebar from 'layout/Sidebar';
import ErrorBoundary from 'layout/ErrorBoundary';

// helpers
import getPages from 'layout/ApplicationLayout/helpers/getPages';
import isNamesListSite from 'helpers/isNamesListSite';

// selectors
import getCurrentGroupId from 'store/selectors/getCurrentGroupId';
import getCurrentUserGroups from 'store/selectors/getCurrentUserGroups';
import getFeatures from 'store/selectors/getFeatures';
import getCurrentSubscription from 'store/selectors/getCurrentSubscription';

const createHmacString = (privateKey: string, currentUserEmail: string) => {
  const key = CryptoJS.enc.Utf8.parse(privateKey);
  const timestamp = CryptoJS.enc.Utf8.parse(currentUserEmail);
  const hmac = CryptoJS.enc.Hex.stringify(CryptoJS.HmacSHA256(timestamp, key));

  //  const hmac = CryptoJS.HmacSHA256(ts, privateKey).toString(CryptoJS.enc.Hex)
  return hmac;
};

const stripeProducts = {
  basic: 'prod_Ha6dvcmM9Q2ylL',
  lite: 'prod_HaC9nC5It9G6Pz',
  standard: 'prod_HaC9Ei8FivUzTS',
  pro: 'prod_HaC9UfnbUNgkAo',
};

const ApplicationLayout = function({
  history,
  isSystemAdmin,
  isHqAdmin,
  isCouncilAdmin,
  isCurrentGroupChapter,
  isCurrentGroupOrganization,
  isCurrentGroupCouncil,
  isCurrentGroupSystem,
}: any) {
  const classes = useStyles();
  const dispatch = useDispatch();
  // @ts-expect-error ts-migrate(2525) FIXME: Initializer provides no value for this binding ele... Remove this comment to see the full error message
  const { location: { pathname, search } = {} } = history;
  const previousPathname = usePrevious(pathname);
  const elevio = useElevio();
  const {
    currentGroupId,
    currentSubscription,
    currentUserEmail,
    features,
    groupIds,
    permissions,
    roleId,
  } = useSelector(
    state => ({
      currentGroupId: getCurrentGroupId(state),
      currentUserEmail:
        (state as any).getIn(
          ['currentUser', 'data', 'emails', 0, 'email'],
          ''
        ) || '',
      groupIds: getCurrentUserGroups(state),
      features: getFeatures(state),
      currentSubscription: getCurrentSubscription(state),
      permissions: (state as any).getIn(
        ['currentUser', 'data', 'permissions'],
        Map()
      ),
      roleId: (state as any).getIn(['currentUser', 'data', 'roleId']),
    }),
    isEqual
  );

  const pages = useMemo(
    () =>
      getPages({
        currentGroupId,
        features,
        groupIds,
        isCurrentGroupCouncil,
        isCurrentGroupChapter,
        isCurrentGroupOrganization,
        isCurrentGroupSystem,
        isCouncilAdmin,
        isHqAdmin,
        isSystemAdmin,
        permissions,
        roleId,
      }),
    [
      currentGroupId,
      features,
      groupIds,
      isCouncilAdmin,
      isCurrentGroupCouncil,
      isCurrentGroupChapter,
      isCurrentGroupOrganization,
      isCurrentGroupSystem,
      isHqAdmin,
      isSystemAdmin,
      permissions,
      roleId,
    ]
  );

  const shouldRenderPaddedContainer =
    pathname !== '/forms' &&
    pathname !== '/leads' &&
    pathname !== '/groups/chapters' &&
    pathname !== '/groups/organizations' &&
    pathname !== '/chapters' &&
    pathname !== '/groups/councils';

  useEffect(() => {
    if (
      currentGroupId &&
      !currentSubscription &&
      (isCurrentGroupChapter || isCurrentGroupCouncil)
    ) {
      dispatch(fetchStripeConfig());
    }
  }, [
    currentGroupId,
    currentSubscription,
    isCurrentGroupChapter,
    isCurrentGroupCouncil,
    dispatch,
  ]);

  useEffect(() => {
    if (currentGroupId) {
      dispatch(
        fetchCurrentUser({
          currentGroupId: currentGroupId,
          skipImageFetch: true,
        })
      );
    }
  }, [dispatch, currentGroupId]);

  const currentStripeProduct =
    currentSubscription?.getIn(['plan', 'product']) || stripeProducts.basic;

  const getElevioGroup = useCallback(() => {
    switch (currentStripeProduct) {
      default:
      case stripeProducts.basic:
        return 'basic';
      case stripeProducts.lite:
        return 'lite';
      case stripeProducts.standard:
        return 'standard';
      case stripeProducts.pro:
        return 'pro';
    }
  }, [currentStripeProduct]);

  const currentElevioGroup = getElevioGroup();

  useEffect(() => {
    if (elevio && currentUserEmail) {
      const CBElevioAccountSecret = '5ef4adab5c3cd';
      const NLElevioAccountSecret = '6463fb2f39c1a';
      // const hmac = crypto.createHmac('sha256', elevioAccountSecret);
      // hmac.update(currentUserEmail);
      // const userHash = hmac.digest('hex');
      const userHash = createHmacString(
        isNamesListSite() ? NLElevioAccountSecret : CBElevioAccountSecret,
        currentUserEmail
      );
      elevio.on('load', (_elev: any) => {
        _elev.setUser({
          email: currentUserEmail,
          user_hash: userHash,
          groups: [currentElevioGroup],
        });
      });
    }
  }, [currentGroupId, currentUserEmail, elevio, currentElevioGroup]);

  useEffect(() => {
    if (pathname === '/') {
      if (currentGroupId && isCurrentGroupChapter) {
        history.replace({ pathname: '/home', search });
      } else if (groupIds.size > 1 || isSystemAdmin || isHqAdmin) {
        history.replace({ pathname: '/groups/chapters', search });
      }
    }
  }, [currentGroupId, groupIds.size, history, isSystemAdmin, pathname]); // eslint-disable-line

  const queryParams = queryString.parse(history?.location?.search);
  const urlGroup = queryParams.group;
  useEffect(() => {
    // reinitializes the current group from any page, mainly for refreshed pages
    if (
      !currentGroupId ||
      (urlGroup && currentGroupId.toString() !== urlGroup.toString())
    ) {
      dispatch(
        setCurrentGroup({
          groupId: urlGroup,
          skipNavigate: true,
          ...(isSystemAdmin ? { getGroupByType: 5 } : {}),
        })
      );
    }
  }, [currentGroupId, dispatch, urlGroup, isSystemAdmin]);

  useEffect(() => {
    if (
      previousPathname &&
      previousPathname.includes('messages') &&
      !pathname.includes('messages')
    ) {
      dispatch(stopListeningForThreadsAction());
      dispatch(stopListeningForMessagesAction());
    }
  }, [dispatch, previousPathname, pathname]);

  return (
    <Box
      component='section'
      height='100%'
      id='appLayout'
      display='flex'
      flex='auto'
      flexDirection='column'
      minHeight='0'
      minWidth='0'
      boxSizing='border-box'
    >
      <Hidden smDown>
        <AppHeader
          isSystemAdmin={isSystemAdmin}
          pathname={pathname}
          isHqAdmin={isHqAdmin}
          isCurrentGroupOrganization={isCurrentGroupOrganization}
        />
      </Hidden>
      <Hidden mdUp>
        <AppBar position='sticky' classes={{ root: classes.appBar }}>
          <Toolbar classes={{ gutters: classes.gutters }}>
            <AppHeader
              isSystemAdmin={isSystemAdmin}
              pathname={pathname}
              isHqAdmin={isHqAdmin}
              isCurrentGroupOrganization={isCurrentGroupOrganization}
            />
          </Toolbar>
        </AppBar>

        <Route path='/mobile-menu' component={MobileMenu} key='/mobile-menu' />
      </Hidden>
      <Box
        component='section'
        display='flex'
        flex='auto'
        flexDirection='row'
        minHeight='0'
        boxSizing='border-box'
        id='appLayout'
      >
        <Hidden smDown>
          <Sidebar pages={pages} />
        </Hidden>
        <Box
          width='0'
          component='section'
          position='relative'
          flex='auto'
          minHeight='0'
          boxSizing='border-box'
          overflow='auto'
          className={classNames(
            shouldRenderPaddedContainer ? classes.paddedContainer : undefined
          )}
          id='contentContainer'
        >
          <ErrorBoundary>
            {pages.map((page: any) => (
              <Route
                exact={page.get('exact', false)}
                path={page.get('path')}
                component={page.get('component')}
                key={page.get('path')}
              />
            ))}
          </ErrorBoundary>
        </Box>
      </Box>

      <Hidden mdUp>
        <MobileNavigation />
      </Hidden>
    </Box>
  );
};
ApplicationLayout.propTypes = {
  isSystemAdmin: PropTypes.bool.isRequired,
  isCouncilAdmin: PropTypes.bool.isRequired,
  isHqAdmin: PropTypes.bool.isRequired,
  isCurrentGroupChapter: PropTypes.bool.isRequired,
  isCurrentGroupOrganization: PropTypes.bool.isRequired,
  isCurrentGroupSystem: PropTypes.bool.isRequired,
  history: PropTypes.instanceOf(Object).isRequired,
};
const useStyles = makeStyles(theme => ({
  appLayout: {
    height: '100%',
  },
  logo: {
    width: '95%',
    [theme.breakpoints.only('sm')]: {
      width: '55%',
    },
    [theme.breakpoints.only('xs')]: {
      width: '85%',
    },
  },
  searchIcon: {
    textAlign: 'right',
    paddingRight: 10,
  },
  appBar: {
    backgroundColor: (theme.palette.background as any).darkPaper,
    height: 55,
  },
  appBarContainer: {
    height: 'inherit',
  },
  gutters: {
    paddingRight: 16,
    paddingLeft: 16,
  },
  paddedContainer: {
    paddingLeft: 24,
    paddingRight: 24,
  },
}));
export default ApplicationLayout;
