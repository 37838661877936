import React, { useCallback, useState } from 'react';

// components
import SelectChapterForm from './components/SelectChapterForm';
import SelectChapterHeader from './components/SelectChapterHeader';
import Landing from 'layout/Landing';
import SuccessCreateChapter from 'routes/SelectChapter/components/SuccessCreateChapter';

const SelectChapter = function() {
  const [
    isSuccessCreatingSubscription,
    setIsSuccessCreatingSubscription,
  ] = useState(false);

  const handleSuccessCreatingSubscription = useCallback(
    (callBack?: () => void) => {
      setIsSuccessCreatingSubscription(true);
      if (callBack) {
        callBack();
      }
    },
    [setIsSuccessCreatingSubscription]
  );

  return (
    <Landing>
      {isSuccessCreatingSubscription ? (
        <SuccessCreateChapter />
      ) : (
        <>
          <SelectChapterHeader />
          <SelectChapterForm
            onSuccessCreatingSubscription={handleSuccessCreatingSubscription}
          />
        </>
      )}
    </Landing>
  );
};

export default SelectChapter;
