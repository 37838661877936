import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Field, Form } from 'react-final-form';

//material-ui
import {
  Box,
  Card,
  CardActions,
  CardContent,
  Divider,
  FormHelperText,
  Grid,
  WithStyles,
  withStyles,
} from '@material-ui/core';

//components
import Header from 'components/Header';
import AlertMessage from 'components/AlertMessage/AlertMessage';
import Switch from 'components/Switch';
import Button from 'components/Button';

//icons
import { NewReleases } from '@material-ui/icons';
import SvgIcon from '@material-ui/core/SvgIcon';
import { ReactComponent as ShapeIcon } from 'assets/images/shape.svg';

//selectors
import getForm from 'store/selectors/getForm';

//actions
import { updateLeadSharing } from 'store/actions/communitySettingsActions';

//helpers
import {
  isEnableManualLeadSharing,
  getPushLeadFromBool,
} from 'routes/CommunitySettings/helpers/pushLeadField';

//styles
import leadSharingFormStyle from 'routes/CommunitySettings/components/LeadSharingForm/leadSharingForm.style';

export interface FormValuesType {
  enableManualLeadSharing: boolean;
}

export interface LeadSharingFormProps
  extends WithStyles<typeof leadSharingFormStyle> {
  isFirstCard?: boolean;
  pushLead?: string;
  groupId?: number;
}

const FORM = 'updateLeadSharingForm';

const LeadSharingForm = ({
  classes,
  isFirstCard,
  groupId,
  pushLead,
}: LeadSharingFormProps) => {
  const dispatch = useDispatch();
  const {
    form: { isSubmitting, hasSubmitFailed, hasSubmitSucceeded, formError },
  } = useSelector(state => ({
    form: getForm(FORM)(state),
  }));

  const initialValues = {
    enableManualLeadSharing: isEnableManualLeadSharing(pushLead),
  };

  const handleFormSubmit = (values: FormValuesType) => {
    dispatch(
      updateLeadSharing({
        groupId,
        formName: FORM,
        pushLead: getPushLeadFromBool(values?.enableManualLeadSharing),
      })
    );
  };

  return (
    <Form initialValues={initialValues} onSubmit={handleFormSubmit}>
      {({ handleSubmit, invalid, pristine, form: { reset } }) => (
        <form onSubmit={handleSubmit}>
          <Card
            className={`${classes.leadSharingCard} ${
              isFirstCard ? 'isFirstCard' : ''
            }`}
          >
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Grid container>
                    <Grid item xs={12}>
                      <Header
                        icon={(props: any) => (
                          <SvgIcon
                            {...props}
                            className='svgIcon'
                            component={ShapeIcon}
                            viewBox='0 0 39 19'
                          />
                        )}
                        title='Lead Sharing Settings'
                        subtitle='Manage how your leads are shared with your linked groups'
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Box className={classes.leadSharingBox} p={1}>
                  <Grid container spacing={2} alignItems='center'>
                    <Grid item xs={12} md={6}>
                      <div className={classes.leadSharingForm}>
                        <div className={classes.leadSharingFormInner}>
                          <div className={classes.leadSharingFormTitle}>
                            Lead Sharing
                          </div>
                          <Field
                            name='enableManualLeadSharing'
                            label='Enable manual lead sharing'
                            type='checkbox'
                            component={Switch}
                          />
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <AlertMessage
                        icon={<NewReleases color='secondary' />}
                        title='If this is NOT enabled, leads will automatically be shared with linked chapters upon a form submission. '
                        size={{ titleSize: '14' }}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </CardContent>
            <Divider />
            <CardActions>
              <Button disabled={pristine} onClick={reset}>
                Cancel
              </Button>
              <Button
                type='submit'
                color='primary'
                variant='contained'
                disabled={pristine || invalid || isSubmitting}
                loading={isSubmitting}
                success={hasSubmitSucceeded}
                fail={hasSubmitFailed}
              >
                Save
              </Button>

              {formError && (
                <Grid item xs={12}>
                  <FormHelperText error id='errorMessage'>
                    {formError?.get('message')}
                  </FormHelperText>
                </Grid>
              )}
            </CardActions>
          </Card>
        </form>
      )}
    </Form>
  );
};

export default withStyles(leadSharingFormStyle, { withTheme: true })(
  LeadSharingForm
);
