import React, { memo, useCallback } from 'react';

// hooks
import { useDeleteLeadMutation, useDeleteLeadsMutation } from 'api/leads';

// components
import DazzlingDialog from 'components/DazzlingDialog';

// material-ui
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

// icons
import DeleteIcon from '@material-ui/icons/Delete';

type Props = {
  open: boolean;
  onClose: (...args: any[]) => any;
  ids: any; // TODO: PropTypes.instanceOf(Array)
  allSelected: boolean;
};

const DeleteLeadsModal = ({ open, onClose, ids, allSelected }: Props) => {
  const {
    isLoading: isLeadDeleting,
    isSuccess: isLeadDeleted,
    isError: isLeadDeletingFailed,
    mutate: deleteLead,
  } = useDeleteLeadMutation();
  const {
    isLoading: isLeadsDeleting,
    isSuccess: isLeadsDeleted,
    isError: isLeadsDeletingFailed,
    mutate: deleteLeads,
  } = useDeleteLeadsMutation();
  const oneLeadSelected = !allSelected && ids.length === 1;
  const leadLabel = oneLeadSelected ? 'lead' : 'leads';
  const onSubmit = useCallback(() => {
    if (oneLeadSelected) {
      deleteLead(ids[0]);
    } else {
      deleteLeads({ allSelected, ids });
    }
  }, [oneLeadSelected, allSelected, deleteLead, deleteLeads, ids]);

  return (
    <DazzlingDialog
      id='deleteLeadsModal'
      isSuccess={isLeadDeleted || isLeadsDeleted}
      isError={isLeadDeletingFailed || isLeadsDeletingFailed}
      isLoading={isLeadDeleting || isLeadsDeleting}
      alertProps={{
        message: oneLeadSelected
          ? 'Lead Deleted Successfully'
          : 'Leads deleting in background, you will receive a notification when completed',
      }}
      handleClose={() => {
        onClose();
      }}
      open={open}
      slice='lead'
      acceptLabel='Delete'
      onAccept={onSubmit}
      headerProps={{
        icon: DeleteIcon,
        title: `Delete ${leadLabel}`,
        highlightedSubtitle: `All data connected to the selected ${leadLabel} will be lost.`,
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid
            container
            justifyContent='center'
            alignItems='center'
            spacing={2}
          >
            <Grid item xs={12}>
              <Typography paragraph>
                Are you sure that you want to delete{' '}
                {allSelected ? 'all' : ids.length} {leadLabel}?{' '}
                <strong>This action cannot be undone!</strong>
              </Typography>
              {!oneLeadSelected && (
                <Typography paragraph>
                  This could take a while -- you will receive a notification
                  when completed and you will need to refresh the page to see
                  results.
                </Typography>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </DazzlingDialog>
  );
};

export default memo(DeleteLeadsModal);
