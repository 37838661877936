import {
  isSubmitting,
  hasSubmitSucceeded,
  hasSubmitFailed,
  getError,
  getResponseMessage,
} from 'store/selectors/formSelectors';

import createDeepEqualSelector from 'helpers/createDeepEqualSelector';

export default function getForm(formName: any) {
  return createDeepEqualSelector(
    [
      (state: any) => isSubmitting(formName)(state),
      (state: any) => hasSubmitSucceeded(formName)(state),
      (state: any) => hasSubmitFailed(formName)(state),
      (state: any) => getError(formName)(state),
      (state: any) => getResponseMessage(formName)(state),
    ],
    (
      isSubmitting: boolean,
      hasSubmitSucceeded: boolean,
      hasSubmitFailed: boolean,
      formError: any,
      successMessage: string
    ) => {
      return {
        isSubmitting,
        hasSubmitSucceeded,
        hasSubmitFailed,
        formError,
        successMessage,
      };
    }
  );
}
