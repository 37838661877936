import Immutable from 'immutable';
import { normalize } from 'normalizr';
import { all, call, fork, put, takeEvery, select } from 'redux-saga/effects';

// action creators
import { doneIndicator, success } from 'store/actions/httpActions';
import { fetchMembersForGroup } from 'store/actions/memberActions';

// selectors
import getCurrentGroupId from 'store/selectors/getCurrentGroupId';
import getCurrentUser from 'store/selectors/getCurrentUser';
import getSliceResult from 'store/selectors/getSliceResult';

// api
import client from 'sources/api';

// constants
import {
  ACTIVITY_FETCH_REQUEST,
  ACTIVITY_FETCH,
} from 'store/constants/activityTypes';

import { COMMENT_FETCH } from 'store/constants/commentTypes';

// schemas
import { activitySchema } from 'store/schemas/activitySchema';
import { commentSchema } from 'store/schemas/commentSchema';

// helpers
import httpSaga from 'store/sagas/httpSaga';

const WATCH_TYPE = ACTIVITY_FETCH_REQUEST;
const TYPE = ACTIVITY_FETCH;

export function* fetchActivities(action: any) {
  const {
    payload: {
      source,
      actions,
      actorId,
      createdOnFrom,
      createdOnTo,
      orderBy,
      page,
      targetId,
      targetType,
    },
  } = action;

  const currentGroupId = yield select(getCurrentGroupId);

  const searchParams = new URLSearchParams();
  searchParams.set(source.name + '_id', source.id);

  if (!searchParams.get('group_id')) {
    searchParams.set('group_id', currentGroupId);
  }

  if (actorId) searchParams.set('actor_id', actorId);
  if (createdOnFrom) searchParams.set('created_on_from', createdOnFrom);
  if (createdOnTo) searchParams.set('created_on_to', createdOnTo);
  if (orderBy) searchParams.set('order_by', orderBy);

  // These two parameters are both needed in order to filter by target_id
  if (targetType) searchParams.set('target_type', targetType);
  if (targetId) searchParams.set('target_id', targetId);

  for (const action of actions) searchParams.append('action', action);
  searchParams.set('page', page);

  // @ts-expect-error ts-migrate(2569) FIXME: Type 'Generator<any, any, unknown>' is not an arra... Remove this comment to see the full error message
  const response = yield* httpSaga(
    TYPE,
    call(client.get, '/activity', {
      params: searchParams,
    }),
    {
      schema: [activitySchema],
    }
  );

  if (response) {
    const commentsData = response.data.results
      .filter((item: any) => item.lastComment)
      .map((item: any) => item.lastComment);
    const payload = {
      data: normalize(commentsData, [commentSchema]),
      entity: commentSchema.key,
      normalized: true,
    };

    // Send the response data to the store for the comments
    yield put(
      success(
        COMMENT_FETCH,
        Immutable.fromJS({
          ...payload,
        })
      )
    );

    const currentUser = yield select(getCurrentUser);
    const accountIds = commentsData.map((comment: any) => comment.accountId);
    const membersAccountIdList = yield select(getSliceResult('member'));
    const availableAccountIds = [
      currentUser.get('id'),
      ...membersAccountIdList.toJS(),
    ];
    const accountIdsToFetch = accountIds.filter(
      (id: any) => !availableAccountIds.includes(id)
    );
    if (accountIdsToFetch.length)
      yield put(
        fetchMembersForGroup({
          groupId: currentGroupId,
          accountIds: accountIdsToFetch,
        })
      );
  }
  yield put(doneIndicator(TYPE));
}
export function* watch() {
  yield takeEvery(WATCH_TYPE, fetchActivities);
}

export default function* root() {
  yield all([fork(watch)]);
}
