import { all } from 'redux-saga/effects';

// sagas
import createEventSaga from './createEventSaga';
import fetchEventByIdSaga from './fetchEventByIdSaga';
import fetchEventsForGroupSaga from './fetchEventsForGroupSaga';
import deleteEventSaga from './deleteEventSaga';
import updateEventSaga from './updateEventSaga';

const eventSaga = function* rootSaga() {
  yield all([
    createEventSaga(),
    fetchEventByIdSaga(),
    fetchEventsForGroupSaga(),
    deleteEventSaga(),
    updateEventSaga(),
  ]);
};

export default eventSaga;
