import React from 'react';
import PropTypes from 'prop-types';
import { List } from 'immutable';

// MUI
import ListSubheader from '@material-ui/core/ListSubheader';
import Divider from '@material-ui/core/Divider';

import MegaMenuItem from '../MegaMenuItem';

const MegaMenuSection = function({
  display,
  handleClose,
  items,
  numSections,
  sectionHeader,
  sectionIndex,
  itemProps,
  autoFocus,
}: any) {
  return (
    display && (
      <>
        {sectionHeader && (
          <ListSubheader disableSticky={true} id='subheader'>
            {sectionHeader}
          </ListSubheader>
        )}

        {items.map((item: any, index: number) => (
          <MegaMenuItem
            color={item.get('color')}
            display={item.get('display')}
            handleClose={handleClose}
            icon={item.get('icon')}
            itemId={item.get('id')}
            key={item.get('id')}
            label={item.get('label')}
            subText={item.get('subText')}
            onClick={item.get('onClick')}
            autoFocus={autoFocus && index === 0}
            {...itemProps}
          />
        ))}

        {numSections > 1 && sectionIndex < numSections - 1 && (
          <Divider id='divider' />
        )}
      </>
    )
  );
};

MegaMenuSection.propTypes = {
  display: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'typeof List' is not assignable t... Remove this comment to see the full error message
  items: PropTypes.instanceOf(List).isRequired,
  itemProps: PropTypes.instanceOf(Object),
  numSections: PropTypes.number.isRequired,
  sectionHeader: PropTypes.string,
  sectionIndex: PropTypes.number.isRequired,
};

MegaMenuSection.defaultProps = {
  display: true,
  sectionHeader: null,
  itemProps: {},
};

export default MegaMenuSection;
