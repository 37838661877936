import { Map, List } from 'immutable';

/*
 *  This handles removing a single item from a slice when given a
 *  response.
 */
export default function remove(map: any, action: any, options = {}) {
  const { payload } = action;

  let newState = map;
  const entity = payload.get('entity');

  const oldTotal = newState.getIn(['meta', 'total'], 0);
  const newTotal = oldTotal - 1;

  newState = newState.setIn(['meta', 'total'], newTotal);

  // Handles payloads that don't have the id directly on the state
  // Ex) Using the remove callback on an endpoint that has results
  const nestedItem =
    payload.getIn(['data', 'entities', entity], Map()).first() || Map();

  const id = nestedItem.getIn(['account', 'id'], '');

  // @ts-expect-error ts-migrate(2339) FIXME: Property 'result' does not exist on type '{}'.
  const { result: resultKey = 'result', updateResult = true } = options;

  // Set the result on the state if `updateResult` is `true`
  const oldResult = map.getIn(['data', resultKey], List());

  let newResult = List();
  const indexToDelete = oldResult.findIndex((item: any) => {
    return item.toString() === id.toString();
  });

  // Remove item from results, if the item was found
  newResult =
    indexToDelete !== -1 ? oldResult.delete(indexToDelete) : oldResult;

  if (updateResult) {
    // Replace the result if `updateResult` is `true`
    newState = newState.setIn(['data', resultKey], newResult);
  }

  // Merge the new items with the states original `byId` map
  const itemsById = newState.getIn(['data', 'byId'], Map());

  newState = newState.setIn(['data', 'byId'], itemsById.delete(id.toString()));

  return newState;
}
