import { Map } from 'immutable';
// constants
import {
  REFERRAL_SOURCE_FETCH_ONE,
  REFERRAL_SOURCE_FETCH_MANY,
  REFERRAL_SOURCE_CREATE,
} from 'store/constants/referralSourceTypes';
// helpers
import httpReducer from './helpers/httpReducer';

export default function reduceReferralSource(state = Map(), action = {}) {
  switch ((action as any).type) {
    case REFERRAL_SOURCE_CREATE:
    case REFERRAL_SOURCE_FETCH_ONE:
    case REFERRAL_SOURCE_FETCH_MANY:
      return httpReducer(state, action);
    default:
      return state;
  }
}
