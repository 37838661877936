import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
// MUI
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import ButtonBase from '@material-ui/core/ButtonBase';
import Hidden from '@material-ui/core/Hidden';
import Typography from '@material-ui/core/Typography';
const JumboTabs = function({
  history,
  icon,
  navigateTo,
  selected,
  subtitle,
  title,
}: any) {
  const classes = useStyles();
  // @ts-expect-error ts-migrate(2525) FIXME: Initializer provides no value for this binding ele... Remove this comment to see the full error message
  const { location: { search } = {} } = history;
  return (
    <ButtonBase
      id='button'
      className={
        selected
          ? classes.tabContainer
          : classNames(classes.translucent, classes.tabContainer)
      }
      onClick={() => history.push({ pathname: navigateTo, search })}
    >
      <Grid container spacing={4}>
        <Grid item xs={4}>
          <div
            className={
              selected
                ? classes.iconContainer
                : classNames(classes.translucentIcon, classes.iconContainer)
            }
          >
            <div className={classes.icon}>{icon}</div>
          </div>
        </Grid>

        <Hidden smDown>
          <Grid item xs={8}>
            <Grid container className={classes.tabTextContainer}>
              <Grid item xs={12}>
                <Typography className={classes.bold}>{title}</Typography>
              </Grid>

              <Grid item xs={12}>
                <Typography
                  color={!selected ? 'initial' : 'secondary'}
                  variant='body2'
                >
                  {subtitle}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Hidden>
      </Grid>
    </ButtonBase>
  );
};
JumboTabs.propTypes = {
  history: PropTypes.instanceOf(Object).isRequired,
  icon: PropTypes.instanceOf(Object).isRequired,
  navigateTo: PropTypes.string,
  selected: PropTypes.bool,
  subtitle: PropTypes.string,
  title: PropTypes.string.isRequired,
};
JumboTabs.defaultProps = {
  navigateTo: '',
  subtitle: '',
};
const useStyles = makeStyles(theme => ({
  iconContainer: {
    height: 53,
    width: 53,
    boxSizing: 'border-box',
    borderRadius: 100,
    border: `3px solid ${theme.palette.common.white}`,
    color: theme.palette.common.white,
  },
  translucentIcon: {
    border: `3px solid rgba(255, 255, 255, 0.35)`,
    color: 'rgba(255, 255, 255, 0.35)',
  },
  icon: {
    position: 'relative',
    top: 10,
  },
  bold: {
    fontWeight: 600,
  },
  translucent: {
    color: 'rgba(255, 255, 255, 0.35)',
  },
  tabsContainer: {
    marginBottom: 0,
  },
  tabContainer: {
    padding: 12,
    borderRadius: 4,
    '&:hover': {
      transition: 'all .4s ease',
      backgroundColor: (theme.palette.background as any).lightPaper,
    },
  },
  tabTextContainer: {
    textAlign: 'left',
  },
}));
export default JumboTabs;
