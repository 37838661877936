import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { isEqual } from 'lodash';

// MUI components
import { Grid } from '@material-ui/core';

// components
import RecruitmentActivityOverviewCard from 'routes/Analytics/RecruitmentActivityOverviewCard';
import RecruitmentActivityMilestonesCard from 'routes/Analytics/RecruitmentActivityMilestonesCard';
import RecruitmentActivityTagsCard from 'routes/Analytics/RecruitmentActivityTagsCard';
import NonJoinerStoryCard from 'routes/NonJoinerAnalytics/NonJoinerStoryCard';

// action creators
import { clearReports } from 'store/actions/reportActions';
import { fetchStatusesAction } from 'store/actions/statusActions';

// selectors
import getSliceState from 'store/selectors/getSliceState';
import getCurrentGroupId from 'store/selectors/getCurrentGroupId';

//helpers
import { isArray, isEmpty } from 'helpers/check';

interface NonJoinerAnalyticsProps {
  isOrganizationNonJoinerAnalytics?: boolean;
}

const NonJoinerAnalytics = ({
  isOrganizationNonJoinerAnalytics,
}: NonJoinerAnalyticsProps) => {
  const dispatch = useDispatch();
  const [nonJoinerStatusPositions, setNonJoinerStatusPositions] = useState<
    number[]
  >(isOrganizationNonJoinerAnalytics ? [2, 3, 4, 5, 6, 7, 8] : []);
  const { status: { data: statuses = [] } = {}, currentGroupId } = useSelector(
    state => ({
      status: getSliceState('status')(state),
      currentGroupId: getCurrentGroupId(state),
    }),
    isEqual
  );

  useEffect(() => {
    dispatch(clearReports());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchStatusesAction({ groupId: currentGroupId }));
  }, [dispatch, currentGroupId]);

  useEffect(() => {
    const getNonJoinerStatusPositions: number[] =
      !isEmpty(statuses) && isArray(statuses)
        ? statuses
            .filter(
              (status: Status) => status?.position && status?.position !== 1
            )
            .map((status: Status) => status.position)
        : [];
    setNonJoinerStatusPositions(getNonJoinerStatusPositions);
  }, [statuses]);

  return (
    <Grid container spacing={2} alignContent='flex-start'>
      {isOrganizationNonJoinerAnalytics && (
        <Grid item xs={12}>
          <NonJoinerStoryCard statusPositions={nonJoinerStatusPositions} />
        </Grid>
      )}
      <Grid item xs={12}>
        <RecruitmentActivityOverviewCard
          statusPositions={nonJoinerStatusPositions}
          reportFormName='nonJoinerActivityReportForm'
          exportFormName='nonJoinerActivityExportForm'
          statLabel='Non-Joiners'
          isOrganizationNonJoinerAnalytics={isOrganizationNonJoinerAnalytics}
          HeaderProps={{
            title: 'Non-Joiner Activity Overview',
            subtitle: `Analyze the leads that have not achieved your group's top status and see what prevented them from joining`,
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <RecruitmentActivityTagsCard
          reportFormName='nonJoinerTagsReportForm'
          exportFormName='nonJoinerTagsExportForm'
          statusPositions={nonJoinerStatusPositions}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <RecruitmentActivityMilestonesCard
          reportFormName='nonJoinerMilestonesReportForm'
          exportFormName='nonJoinerMilestonesExportForm'
          statusPositions={nonJoinerStatusPositions}
        />
      </Grid>
    </Grid>
  );
};

export default NonJoinerAnalytics;
