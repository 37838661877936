import React from 'react';

// material-ui
import { Grid, Card, CardContent, Box, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';

// icons
import { Payment } from '@material-ui/icons';

// components
import Header from 'components/Header';
import BillingOverview from './BillingOverview';

const billingHeaderProps = {
  icon: Payment,
  title: 'Billing Overview',
  subtitle: 'Manage your plan and control the features you can access',
};

export default function BillingSettings() {
  const theme = useTheme();
  const isSmallDeviceAndDown = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Grid item xs>
      <Card
        id='tabContent'
        style={{
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
          marginBottom: 16,
        }}
      >
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Header {...billingHeaderProps} />
            </Grid>

            <Box clone marginLeft={isSmallDeviceAndDown ? 0 : 11}>
              <Grid item xs={12}>
                <BillingOverview />
              </Grid>
            </Box>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
}
