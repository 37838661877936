import React from 'react';
// material-ui
import { Theme } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
import TextField from 'components/TextField';

const LandingTextField = function({
  classes,
  InputLabelProps,
  InputProps,
  ...restOfProps
}: any) {
  return (
    <TextField
      InputProps={{
        classes: {
          root: classes.textField,
          notchedOutline: classes.notchedOutline,
          focused: classes.focused,
        },
        ...InputProps,
      }}
      InputLabelProps={{
        classes: {
          root: classes.inputLabel,
          focused: classes.focused,
        },
        ...InputLabelProps,
      }}
      {...restOfProps}
    />
  );
};

LandingTextField.propTypes = TextField.propTypes;

const styles = (theme: Theme) => {
  return {
    focused: {},
    notchedOutline: {},
    textField: {
      '&$focused $notchedOutline': {
        borderColor: theme.palette.common.white,
        borderWidth: '1px',
      },
    },

    inputLabel: {
      '&$focused': {
        color: theme.palette.common.white,
      },
    },
  };
};

export default withStyles(styles)(LandingTextField);
