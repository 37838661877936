import React, { useEffect } from 'react';
import { Map } from 'immutable';
import pluralize from 'pluralize';
// hooks
import {
  useFeatureLimitRemaining,
  useFeatureHasLimit,
} from 'helpers/hooks/featureHooks';
import { useSelector, useDispatch } from 'react-redux';
// material-ui
import { Grid, Card, CardContent, Box, Hidden } from '@material-ui/core';
// icons
import { LocalOffer } from '@material-ui/icons';
// components
import Header from 'components/Header';
import TagsForm from 'routes/TagsSettings/TagsForm';
//selectors
import getCurrentGroupId from 'store/selectors/getCurrentGroupId';
// actions
import { fetchTagsAction } from 'store/actions/tagActions';

export default function TagsSettings() {
  const dispatch = useDispatch();
  const { tagSize, currentGroupId } = useSelector(state => ({
    currentGroupId: getCurrentGroupId(state),
    tagSize: (state as any).getIn(['tag', 'data', 'byId'], Map()).size,
  }));
  const remainingTags = useFeatureLimitRemaining('custom_tags', tagSize);
  const tagsHaveLimit = useFeatureHasLimit('custom_tags');
  let highlightedSubtitle = '';
  if (tagsHaveLimit) {
    if (remainingTags > 0) {
      highlightedSubtitle = pluralize(
        `You may create ${remainingTags} more tag`,
        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'number | true' is not assignable... Remove this comment to see the full error message
        remainingTags
      );
    } else {
      highlightedSubtitle =
        'You are out of custom tags. Delete tags or upgrade for more.';
    }
  }
  const headerProps = {
    id: 'elevio_tags_overview',
    icon: LocalOffer,
    title: 'Manage Lead Tags',
    subtitle:
      'Customize the names, color, and visibility of the attribute tags available when writing a note',
    highlightedSubtitle,
  };

  useEffect(() => {
    if (currentGroupId) {
      dispatch(fetchTagsAction({ groupId: currentGroupId }));
    }
  }, [currentGroupId, dispatch]);

  return (
    <Grid item xs>
      <Card
        id='tabContent'
        style={{
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
        }}
      >
        <CardContent>
          <Grid container>
            <Grid item xs>
              <Header {...headerProps} />
            </Grid>

            <Hidden xsDown>
              <Box width={75} />
            </Hidden>

            <Grid item xs={12}>
              <TagsForm />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
}
