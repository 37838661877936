import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { isEqual } from 'lodash';
import { Moment } from 'moment';

// MUI components
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Card, CardContent } from '@material-ui/core';
import { Phone, Sms, Email, Group } from '@material-ui/icons';

// components
import Table from 'components/Table';
import AnalyticsActionBar from 'components/AnalyticsActionBar';

// action creators
import { createMemberCommunicationReport } from 'store/actions/apiTaskActions';

// selectors
import getCurrentGroupId from 'store/selectors/getCurrentGroupId';
import getForm from 'store/selectors/getForm';
import getSliceEntityById from 'store/selectors/getSliceEntityById';

const REPORT_FORM = 'memberCommunicationReportForm';
const rowsPerPage = 10;

const MemberCommunicationCard = function() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const initialOrder = 'asc';
  const initialOrderBy = 'memberFullName';

  const {
    currentGroupId,
    report: {
      data: { content = [], meta: { columns = [] } = {} } = {},
      id: reportId,
    },
    form: { isSubmitting },
  } = useSelector(
    state => ({
      currentGroupId: getCurrentGroupId(state),
      form: getForm(REPORT_FORM)(state),
      report: getSliceEntityById(
        'report',
        'ReportTypes.COMMUNICATION_MEMBERS',
        {}
      )(state),
    }),
    isEqual
  );
  const formattedColumns = columns.map(({ key, label }: any) => {
    return {
      key,
      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      label: icons[key] || label,
      tooltipTitle: label,
    };
  });

  const dispatchCreateReport = useCallback(
    ({
      startDate,
      endDate,
      forceRefresh = false,
    }: {
      startDate?: Moment;
      endDate?: Moment;
      forceRefresh?: boolean;
    }) => {
      dispatch(
        createMemberCommunicationReport({
          groupId: currentGroupId,
          formName: REPORT_FORM,
          startDate,
          endDate,
          forceRefresh,
        })
      );
    },
    [currentGroupId, dispatch]
  );
  return (
    <Card className={classes.container}>
      <CardContent className={classes.cardContent}>
        <Grid container spacing={1}>
          <AnalyticsActionBar
            onCreateReport={dispatchCreateReport}
            title='Member Communication'
            icon={Group}
            loading={isSubmitting}
            reportId={reportId}
          />

          <Grid item xs={12}>
            <Table
              columns={formattedColumns}
              data={content}
              variant='outlined'
              numberOfSkeletonRows={isSubmitting ? 8 : 0}
              initialOrder={initialOrder}
              initialOrderBy={initialOrderBy}
              TableRowProps={{
                classes: {
                  root: (classes as any).root,
                },
              }}
              TableCellProps={{
                className: (classes as any).tableCell,
              }}
              HeaderTableCellProps={{
                className: (classes as any).headerTableCell,
              }}
              TablePaginationProps={{
                count: content.length,
                rowsPerPage,
                rowsPerPageOptions: [rowsPerPage],
              }}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
const icons = {
  callsSent: <Phone />,
  textsSent: <Sms />,
  emailsSent: <Email />,
};
const useStyles = makeStyles(theme => ({
  container: {
    minHeight: 270,
    [theme.breakpoints.down('md')]: {
      minHeight: 325,
    },
  },
  cardContent: {
    paddingLeft: 24,
    paddingRight: 24,
    marginBottom: 10,
  },
}));
export default MemberCommunicationCard;
