import React, { useState, useCallback, useMemo } from 'react';

// material-ui
import { Grid, Stepper, Step, StepLabel } from '@material-ui/core';

// components
import SelectGuestsStep from './SelectGuestsStep';
import ConfirmGuestsStep from './ConfirmGuestsStep';
import SendInvitationsStep from './SendInvitationsStep';
import StepperButtons from './StepperButtons';
import { EventGuestFilters } from 'api/eventGuests';

type Guest = { id: number; fullName: string };

export default function InvitesTab({ eventId }: { eventId: number }) {
  // initialize component state
  const [activeStep, setActiveStep] = useState<number>(0);
  const [selectedLeads, setSelectedLeads] = useState<Guest[]>([]);
  const selectedLeadIds = useMemo(() => selectedLeads.map(lead => lead.id), [
    selectedLeads,
  ]);
  const [allLeadsSelected, setAllLeadsSelected] = useState<boolean>(false);
  const [validationErrors, setValidationErrors] = useState<any>();
  const [leadFilters, setLeadFilters] = useState<EventGuestFilters>({});
  const [memberFilters, setMemberFilters] = useState<EventGuestFilters>({});

  const handleResetSelectedLeads = useCallback(() => {
    setSelectedLeads([]);
    setAllLeadsSelected(false);
  }, []);

  const handleSelectLead = useCallback((eventGuest: EventGuest) => {
    if (eventGuest.pnmId) {
      const leadId = eventGuest.pnmId;
      setSelectedLeads(selectedLeads =>
        selectedLeads.find(lead => lead.id === leadId)
          ? selectedLeads.filter(selectedLead => selectedLead.id !== leadId)
          : [...selectedLeads, { id: leadId, fullName: eventGuest.fullName }]
      );
    }
  }, []);

  const handleDeselectLead = useCallback(
    (lead: Guest) =>
      setSelectedLeads(selectedLeads =>
        selectedLeads.filter(selectedLead => selectedLead.id !== lead.id)
      ),
    []
  );

  const [selectedAccounts, setSelectedAccounts] = useState<Guest[]>([]);
  const selectedAccountIds = useMemo(
    () => selectedAccounts.map(account => account.id),
    [selectedAccounts]
  );
  const [allMembersSelected, setAllMembersSelected] = useState<boolean>(false);

  const handleResetSelectedMembers = useCallback(() => {
    setSelectedAccounts([]);
    setAllMembersSelected(false);
  }, []);

  const handleSelectAccount = useCallback((eventGuest: EventGuest) => {
    if (eventGuest.accountId) {
      const accountId = eventGuest.accountId;
      setSelectedAccounts(selectedAccounts =>
        selectedAccounts.find(account => account.id === accountId)
          ? selectedAccounts.filter(
              selectedAccount => selectedAccount.id !== accountId
            )
          : [
              ...selectedAccounts,
              { id: accountId, fullName: eventGuest.fullName },
            ]
      );
    }
  }, []);

  const handleDeselectAccount = useCallback(
    (account: Guest) =>
      setSelectedAccounts(selectedAccounts =>
        selectedAccounts.filter(
          selectedAccount => selectedAccount.id !== account.id
        )
      ),
    []
  );

  const incrementStep = useCallback(() => {
    if (activeStep === stepsMap['sendInvitations']) {
    } else {
      setActiveStep(activeStep + 1);
    }
  }, [activeStep]);

  const decrementStep = useCallback(() => {
    setActiveStep(activeStep - 1);
  }, [activeStep]);

  const isNextDisabled = useMemo(() => {
    switch (activeStep) {
      case stepsMap['confirmGuests']:
        return (
          !selectedLeads.length &&
          !allLeadsSelected &&
          !Object.keys(selectedAccountIds).length &&
          !allMembersSelected
        );
      case stepsMap['sendInvitations']:
        return Boolean(validationErrors);
      default:
        return false;
    }
  }, [
    activeStep,
    allLeadsSelected,
    allMembersSelected,
    selectedLeads,
    selectedAccountIds,
    validationErrors,
  ]);

  const stepContent = useMemo(
    () => ({
      0: (
        <SelectGuestsStep
          {...{
            eventId,
            guestType: 'lead',
            handleSelectedGuestsChange: handleSelectLead,
            selectedGuestIds: selectedLeadIds,
            handleAllGuestsSelectedChange: setAllLeadsSelected,
            allGuestsSelected: allLeadsSelected,
            handleResetSelectedGuests: handleResetSelectedLeads,
            guestFilters: leadFilters,
            setGuestFilters: setLeadFilters,
          }}
        />
      ),
      1: (
        <SelectGuestsStep
          {...{
            eventId,
            guestType: 'member',
            handleSelectedGuestsChange: handleSelectAccount,
            selectedGuestIds: selectedAccountIds,
            handleAllGuestsSelectedChange: setAllMembersSelected,
            allGuestsSelected: allMembersSelected,
            handleResetSelectedGuests: handleResetSelectedMembers,
            guestFilters: memberFilters,
            setGuestFilters: setMemberFilters,
          }}
        />
      ),
      2: (
        <ConfirmGuestsStep
          LeadProps={{
            handleDeselectLead: handleDeselectLead,
            selectedLeads,
            allLeadsSelected,
            hasLeadFilters: Object.keys(leadFilters).length > 0,
          }}
          MemberProps={{
            handleDeselectAccount: handleDeselectAccount,
            selectedAccounts,
            allMembersSelected,
            hasMemberFilters: Object.keys(memberFilters).length > 0,
          }}
        />
      ),
      3: (
        <SendInvitationsStep
          selectedLeadIds={selectedLeadIds}
          selectedAccountIds={selectedAccountIds}
          allLeadsSelected={allLeadsSelected}
          allMembersSelected={allMembersSelected}
          leadFilters={leadFilters}
          memberFilters={memberFilters}
          handleSetValidationErrors={setValidationErrors}
          StepperButtonProps={{
            activeStep: stepsMap['sendInvitations'],
            decrementStep,
            isNextDisabled,
          }}
        />
      ),
    }),
    [
      eventId,
      allLeadsSelected,
      allMembersSelected,
      decrementStep,
      isNextDisabled,
      selectedLeadIds,
      selectedLeads,
      handleSelectLead,
      handleDeselectLead,
      handleResetSelectedLeads,
      selectedAccounts,
      selectedAccountIds,
      handleSelectAccount,
      handleDeselectAccount,
      handleResetSelectedMembers,
      setValidationErrors,
      leadFilters,
      memberFilters,
    ]
  );

  return (
    <Grid container justifyContent='center' spacing={2}>
      <Grid item xs={12}>
        <Stepper activeStep={activeStep} id='invitesStepper'>
          {stepLabels.map(label => {
            return (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
      </Grid>

      {activeStep !== stepsMap['sendInvitations'] && (
        <StepperButtons
          activeStep={activeStep}
          incrementStep={incrementStep}
          decrementStep={decrementStep}
          isNextDisabled={isNextDisabled}
        />
      )}

      <Grid item xs={12}>
        {(stepContent as any)[activeStep]}
      </Grid>
    </Grid>
  );
}

const stepLabels = [
  'Select leads',
  'Select members',
  'Confirm Invites',
  'Send invitations',
];
const stepsMap = {
  selectLeads: 0,
  selectMembers: 1,
  confirmGuests: 2,
  sendInvitations: 3,
};
