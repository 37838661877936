import React from 'react';
import PropTypes from 'prop-types';

// MUI components
import { Grid } from '@material-ui/core';

// local components
import Button from 'components/Button';

const Buttons = function({ onSelectAll, onClearAll, hasSelectedItems }: any) {
  return (
    <Grid container>
      <Grid item xs={4}>
        <Button variant='outlined' id='selectAll' onClick={onSelectAll}>
          Select All
        </Button>
      </Grid>
      {hasSelectedItems ? (
        <Grid item xs={7}>
          <Button
            variant='outlined'
            id='clearAll'
            color='secondary'
            onClick={onClearAll}
          >
            Clear All
          </Button>
        </Grid>
      ) : null}
    </Grid>
  );
};

Buttons.propTypes = {
  onSelectAll: PropTypes.func.isRequired,
  onClearAll: PropTypes.func.isRequired,
  hasSelectedItems: PropTypes.bool.isRequired,
};

export default Buttons;
