import React, {
  useContext,
  useMemo,
  useEffect,
  useState,
  useCallback,
} from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import validate from 'validate.js';
import Immutable, { List } from 'immutable';

// components
import { Form, Field } from 'react-final-form';
import LandingAutoSuggest from 'layout/Landing/components/LandingAutoSuggest';
import SelectChapterFormActions from '../SelectChapterFormActions';
import Select from 'components/Select';
import ManageSubscription from 'components/ManageSubscription';

// material-ui
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import Typography from '@material-ui/core/Typography';
import OrgIcon from '@material-ui/icons/LocationCity';
import CouncilIcon from '@material-ui/icons/School';
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline';

// helpers
import { SiteVisualDataContext } from 'components/SiteVisualData';
import { ManageSubscriptionStatus } from 'components/ManageSubscription/helpers/types';
import isNamesListSiteFunction from 'helpers/isNamesListSite';
import { toString } from 'helpers/transform';
import { createGroup } from 'store/actions/groupActions';

const WHAT_BEST_DESCRIBES_YOUR_GROUP_LIST = [
  { label: 'Church/Religious Group', value: 'churchReligiousGroup' },
  { label: 'Community Service', value: 'communityService' },
  { label: 'Membership Organization', value: 'membershipOrganization' },
  { label: 'Sales Team', value: 'salesTeam' },
  { label: 'Small Business', value: 'smallBusiness' },
];

const SelectChapterForm = function({
  councilLoading,
  councils,
  chapters,
  fetchGroups,
  fetchStripeConfig,
  setCurrentGroup,
  groupLoading,
  organizationLoading,
  organizations,
  selectGroupFormSubmitting,
  selectGroupFormSubmitFailed,
  selectGroupFormSubmitSucceeded,
  onSuccessCreatingSubscription,
}: any) {
  const {
    selectChapterFormError,
    selectChapterFormCouncilLabel,
    selectChapterFormCouncilPlaceholder,
    selectChapterFormOrganizationLabel,
    selectChapterFormOrganizationPlaceholder,
    selectChapterFormSchemaCouncilMessage,
    selectChapterFormSchemaOrganizationMessage,
  } = useContext(SiteVisualDataContext);
  const classes = useStyles();
  const [selectedOrganizationId, setSelectedOrgId] = useState(null);
  const [selectedCouncilId, setSelectedCouncilId] = useState(null);
  const [selectedChapterId, setSelectedChapterId] = useState(null);
  const [currentModalStatus, setCurrentModalStatus] = useState<
    ManageSubscriptionStatus
  >(ManageSubscriptionStatus.Closed);
  const [numberOfFormPage, setNumberOfFormPage] = useState<number>(1);

  const isNamesListSite = useMemo(isNamesListSiteFunction, []);
  const dispatch = useDispatch();

  const schema = {
    council: {
      presence: {
        allowEmpty: false,
        message: selectChapterFormSchemaCouncilMessage,
      },
    },
    organization: {
      presence: {
        allowEmpty: false,
        message: selectChapterFormSchemaOrganizationMessage,
      },
    },
    ...(isNamesListSite && numberOfFormPage === 2
      ? {
          whatBestDescribesYourGroup: {
            presence: {
              allowEmpty: false,
            },
          },
        }
      : {}),
  };

  const getGroupFormValues = useCallback(values => {
    const emptyValue = { label: '', value: '' };
    const getGroupFormValue = (item?: { label?: string; value?: string }) =>
      item?.label && item?.value
        ? { label: toString(item.label), value: toString(item.value) }
        : { ...emptyValue };
    const { council, organization, whatBestDescribesYourGroup } = values || {};

    return {
      council: getGroupFormValue(council),
      organization: getGroupFormValue(organization),
      whatBestDescribesYourGroup: getGroupFormValue(whatBestDescribesYourGroup),
    };
  }, []);

  useEffect(() => {
    setSelectedChapterId(null);
  }, [groupLoading]);

  const addNewOrganization = (displayName: string) => {
    dispatch(
      createGroup({
        name: displayName,
        displayName: displayName,
        type: 'GREEK_ORGANIZATION',
      })
    );
  };

  const addNewCouncil = (displayName: string) => {
    dispatch(
      createGroup({
        name: displayName,
        displayName: displayName,
        type: 'COUNCIL',
      })
    );
  };

  useEffect(() => {
    // Fetch chapter using the ids of the parent group suggestions, to see if chapter already exists
    if (selectedCouncilId && selectedOrganizationId) {
      fetchGroups({
        parentIds: [selectedCouncilId, selectedOrganizationId],
        type: 'GREEK_CHAPTER',
      });
    }
  }, [fetchGroups, selectedCouncilId, selectedOrganizationId]);

  useEffect(() => {
    const parentIds = [selectedCouncilId, selectedOrganizationId];

    const selectedChapter = chapters.find((chapter: any) => {
      // determines if selected council and organization are the combination for an existing chapter
      const firstParent = chapter.getIn(['parents', 0, 'id']);
      const lastParent = chapter.getIn(['parents', 1, 'id']);

      return parentIds.includes(firstParent) && parentIds.includes(lastParent);
    });

    const chapterParentsMatchSelected = Boolean(selectedChapter);

    // Makes create button disabled when a chapter already exists after groups fetched
    if (
      chapterParentsMatchSelected &&
      selectedOrganizationId &&
      selectedCouncilId
    ) {
      setSelectedChapterId(selectedChapter.get('id'));
    } else {
      setSelectedChapterId(null);
    }
  }, [chapters, fetchGroups, selectedCouncilId, selectedOrganizationId]);

  useEffect(() => {
    if (isNamesListSite) {
      fetchStripeConfig();
    }
  }, [isNamesListSite, fetchStripeConfig]);

  // Refetches suggestions
  const handleOnSearchChange = (value: any, type: any) => {
    if (value) {
      fetchGroups({
        type,
        search: value,
      });
    }
  };

  const handleCurrentModalStatusChange = useCallback(setCurrentModalStatus, [
    setCurrentModalStatus,
  ]);

  const handleSuccessCreatingSubscription = useCallback(
    (groupId?: number) => {
      const callBack = () => {
        if (groupId) {
          // I add time to show the message to the user and then do redirect
          setTimeout(() => {
            setCurrentGroup({ groupId });
          }, 2500);
        }
      };
      onSuccessCreatingSubscription(callBack);
    },
    [onSuccessCreatingSubscription, setCurrentGroup]
  );

  const onSubmit = () => {};

  const handleValidate = (values = {}) => {
    const validationErrors = validate(values, schema);
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'council' does not exist on type '{}'.
    const { council = {}, organization = {} } = values;
    const councilId = council?.value;
    const organizationId = organization?.value;

    if (!groupLoading) {
      // sets selected items so that we know whether or not to re-fetch chapters
      setSelectedOrgId(organizationId);
      setSelectedCouncilId(councilId);
    }

    return validationErrors;
  };

  const handleClickSubscription = useCallback(() => {
    setCurrentModalStatus(ManageSubscriptionStatus.SelectYourPlan);
  }, [setCurrentModalStatus]);

  const renderFormMessage = () => {
    if (!selectGroupFormSubmitting && selectGroupFormSubmitSucceeded) {
      return (
        <Typography
          id='successMessage'
          color='error'
          variant='body2'
          className={classes.errorMessage}
        >
          Group created successfully
        </Typography>
      );
    } else if (!selectGroupFormSubmitting && selectGroupFormSubmitFailed) {
      return (
        <Typography
          id='errorMessage'
          color='error'
          variant='body2'
          className={classes.errorMessage}
        >
          {selectChapterFormError}
        </Typography>
      );
    }
  };

  return (
    <Form
      onSubmit={onSubmit}
      validate={values => handleValidate(values)}
      render={({ handleSubmit, invalid, values }) => (
        <>
          <Grid
            item
            component='form'
            onSubmit={handleSubmit}
            className={classes.form}
          >
            <Grid container direction='column' spacing={6}>
              <Grid item>
                <Grid
                  container
                  direction={isNamesListSite ? 'column-reverse' : 'column'}
                >
                  <Grid item>
                    <Field
                      id='council'
                      name='council'
                      component={LandingAutoSuggest}
                      formValues={Immutable.fromJS(values)}
                      label={selectChapterFormCouncilLabel}
                      placeholder={selectChapterFormCouncilPlaceholder}
                      loading={councilLoading}
                      onSearchChange={(value: any) =>
                        handleOnSearchChange(value, 'COUNCIL')
                      }
                      suggestions={councils.toJS()}
                      {...(isNamesListSite
                        ? {
                            autoSuggestType: 'fields',
                            handleAddItemClick: addNewCouncil,
                          }
                        : {})}
                      variant='outlined'
                      InputProps={{
                        startAdornment: (
                          <InputAdornment
                            position='start'
                            className={classes.adornment}
                          >
                            {isNamesListSite ? (
                              <LocationOnOutlinedIcon />
                            ) : (
                              <CouncilIcon />
                            )}
                          </InputAdornment>
                        ),
                      }}
                      disabled={isNamesListSite && numberOfFormPage > 1}
                      required
                    />
                  </Grid>
                  <Grid item>
                    <Field
                      id='organization'
                      name='organization'
                      component={LandingAutoSuggest}
                      label={selectChapterFormOrganizationLabel}
                      placeholder={selectChapterFormOrganizationPlaceholder}
                      formValues={Immutable.fromJS(values)}
                      {...(isNamesListSite
                        ? {
                            autoSuggestType: 'fields',
                            handleAddItemClick: addNewOrganization,
                          }
                        : {})}
                      loading={organizationLoading}
                      onSearchChange={(value: any) =>
                        handleOnSearchChange(value, 'GREEK_ORGANIZATION')
                      }
                      suggestions={organizations.toJS()}
                      variant='outlined'
                      InputProps={{
                        startAdornment: (
                          <InputAdornment
                            position='start'
                            className={classes.adornment}
                          >
                            {isNamesListSite ? (
                              <PeopleOutlineIcon />
                            ) : (
                              <OrgIcon />
                            )}
                          </InputAdornment>
                        ),
                      }}
                      disabled={isNamesListSite && numberOfFormPage > 1}
                      required
                    />
                  </Grid>
                </Grid>

                {isNamesListSite && numberOfFormPage === 2 && (
                  <Grid
                    className={classes.secondPartContainer}
                    container
                    spacing={1}
                  >
                    <Grid className={classes.secondPartTitle} item xs={12}>
                      Tell us more about your group
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        name='whatBestDescribesYourGroup'
                        component={Select}
                        label='What best describes your group?'
                        options={WHAT_BEST_DESCRIBES_YOUR_GROUP_LIST}
                        variant='outlined'
                        className={classes.textField}
                        margin='none'
                        required
                      />
                    </Grid>
                  </Grid>
                )}

                <Grid item className={classes.actions}>
                  {renderFormMessage()}
                  <SelectChapterFormActions
                    // @ts-expect-error ts-migrate(2322) FIXME: Type '{ values: Record<string, any>; selectedGroup... Remove this comment to see the full error message
                    values={values}
                    selectedGroupId={selectedChapterId}
                    joinDisabled={
                      invalid ||
                      !Boolean(selectedChapterId) ||
                      selectGroupFormSubmitting ||
                      groupLoading
                    }
                    createDisabled={
                      invalid ||
                      Boolean(selectedChapterId) ||
                      selectGroupFormSubmitting ||
                      groupLoading
                    }
                    isNamesListSite={isNamesListSite}
                    numberOfFormPage={numberOfFormPage}
                    onNumberOfFormPageChange={setNumberOfFormPage}
                    onCreateGroup={handleClickSubscription}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <ManageSubscription
            currentModalStatus={currentModalStatus}
            onCurrentModalStatusChange={handleCurrentModalStatusChange}
            onSuccessCreatingSubscription={handleSuccessCreatingSubscription}
            groupFormValues={getGroupFormValues(values)}
            shouldGroupBeCreated
            willSubscriptionBeChanged
            isItNewSubscription
          />
        </>
      )}
    />
  );
};

SelectChapterForm.propTypes = {
  councilLoading: PropTypes.bool.isRequired,
  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'typeof List' is not assignable t... Remove this comment to see the full error message
  councils: PropTypes.instanceOf(List).isRequired,
  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'typeof List' is not assignable t... Remove this comment to see the full error message
  chapters: PropTypes.instanceOf(List).isRequired,
  fetchGroups: PropTypes.func.isRequired,
  fetchStripeConfig: PropTypes.func.isRequired,
  setCurrentGroup: PropTypes.func.isRequired,
  onSuccessCreatingSubscription: PropTypes.func.isRequired,
  groupLoading: PropTypes.bool.isRequired,
  organizationLoading: PropTypes.bool.isRequired,
  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'typeof List' is not assignable t... Remove this comment to see the full error message
  organizations: PropTypes.instanceOf(List).isRequired,
  selectGroupFormSubmitting: PropTypes.bool.isRequired,
  selectGroupFormSubmitFailed: PropTypes.bool.isRequired,
  selectGroupFormSubmitSucceeded: PropTypes.bool.isRequired,
};

const useStyles = makeStyles(theme => ({
  form: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
    [theme.breakpoints.up('sm')]: {
      width: '350px',
    },
    [theme.breakpoints.up('lg')]: {
      width: '450px',
    },
  },
  secondPartContainer: {
    marginTop: 40,
    marginBottom: 20,
  },
  secondPartTitle: {
    fontSize: '1rem',
    fontWeight: 700,
  },
  textField: {
    '& .MuiListItemText-root': {
      marginTop: 0,
      marginBottom: 0,
      '& > .MuiTypography-root': {
        lineHeight: 'inherit',
      },
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.common.white,
    },
    '&.subscription': {
      '& .MuiOutlinedInput-adornedEnd': {
        paddingRight: 9,
      },
    },
  },
  adornment: {
    marginRight: 14,
  },
  actions: { marginTop: 10 },

  errorMessage: {
    marginBottom: 15,
  },
}));

export default SelectChapterForm;
