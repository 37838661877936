import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

// action creators
import { doneIndicator } from 'store/actions/httpActions';
import { startFormSubmit } from 'store/actions/formActions';

// api
import client from 'sources/api';

// constants
import {
  USER_REGISTER,
  USER_REGISTER_REQUEST,
} from 'store/constants/userTypes';

// lib
import httpSaga from 'store/sagas/httpSaga';

// helpers
import isNamesListSite from 'helpers/isNamesListSite';

const WATCH_TYPE = USER_REGISTER_REQUEST;
const TYPE = USER_REGISTER;
const FORM = 'signupForm';

export function* registerUser(action: any) {
  yield put(startFormSubmit(FORM));

  const {
    payload: { email, password, firstName: first_name, lastName: last_name },
  } = action;

  // @ts-expect-error ts-migrate(2569) FIXME: Type 'Generator<any, any, unknown>' is not an arra... Remove this comment to see the full error message
  yield* httpSaga(
    TYPE,
    call(client.post, '/auth/register', {
      email,
      password,
      first_name,
      last_name,
      is_nameslist: isNamesListSite(),
    }),
    {
      formName: FORM,
      shouldDestroyForm: false,
    }
  );

  yield put(doneIndicator(TYPE));
}

export function* watch() {
  yield takeEvery(WATCH_TYPE, registerUser);
}

export default function* root() {
  yield all([fork(watch)]);
}
