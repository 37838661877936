import React from 'react';
import { Box, Grid, Paper, Typography } from '@material-ui/core';
import { WithStyles, withStyles } from '@material-ui/core';

import alertMessageStyle from 'components/AlertMessage/alertMessage.style';

export interface AlertMessageProps
  extends WithStyles<typeof alertMessageStyle> {
  icon?: React.ReactNode;
  title: string;
  message?: string;
  button?: React.ReactNode;
  linkTo?: string;
  linkText?: string;
  size?: {
    titleSize?: '14' | 'default';
  };
  fullWidth?: boolean;
}

const AlertMessage = ({
  classes,
  icon,
  title,
  message,
  button,
  size,
  fullWidth,
}: AlertMessageProps) => {
  const { titleSize = 'default' } = size || {};

  return (
    <Paper
      className={`${classes.paper}${fullWidth ? ' fullWidth' : ''}`}
      elevation={0}
      variant='outlined'
    >
      <Box p={2}>
        <Grid container spacing={1} wrap='nowrap' alignItems='center'>
          {icon && (
            <Grid item>
              <Grid container>{icon}</Grid>
            </Grid>
          )}
          <Grid item>
            <Typography
              variant='subtitle1'
              color='secondary'
              className={`${classes.title} s-${titleSize}`}
            >
              {title}
            </Typography>
          </Grid>
        </Grid>
        {message && (
          <Grid container>
            <Grid item>
              <Box mt={1}>
                <Typography
                  variant='subtitle2'
                  color='textSecondary'
                  className={classes.message}
                >
                  {message}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        )}
        {button && <>{button}</>}
      </Box>
    </Paper>
  );
};

export default withStyles(alertMessageStyle)(AlertMessage);
