import { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import { useSelector } from 'react-redux';
import { client, Resource } from 'api';

import getCurrentGroupId from 'store/selectors/getCurrentGroupId';

export enum TaskNames {
  BulkCreateNotes = 'bulk_create_notes',
  BulkCreateReferralSources = 'bulk_create_referral_sources',
}

export interface CreateNotesParams extends LeadFetchFilters {
  pnmIds: number[] | 'all';
  excludedPnmIds?: number[];
  noteData?: { body?: string; tagIds?: number[] };
}

export interface CreateReferralSourcesParams extends LeadFetchFilters {
  pnmIds: number[] | 'all';
  excludedPnmIds?: number[];
  referralSourceIds: number[];
}

export function useCreateBgTaskMutation<Params>(name: TaskNames) {
  const groupId = useSelector(getCurrentGroupId);
  return useMutation<null, AxiosError, Params>(params =>
    client.post(Resource.Tasks + '?group_id=' + groupId, {
      name,
      params,
    })
  );
}
