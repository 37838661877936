import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

// hooks
import { useIsFeatureEnabled } from 'helpers/hooks/featureHooks';

// components
import ActivityFeed from 'components/ActivityFeed';
import LeadInfo from 'components/LeadInfo';
import LeadTasksTab from './LeadTasksTab';
import LeadMessageTab from './LeadMessageTab';
import ValuesIndicator from 'components/ValuesIndicator';
import PaymentsTab from 'routes/LeadList/components/PaymentsTab';

// helpers
import { roles } from 'helpers/getRoles';

// MUI icons
import {
  DateRange,
  Description,
  DoneAll,
  ListAlt,
  Timeline,
  MonetizationOnOutlined,
  QuestionAnswer,
  ThumbsUpDown,
} from '@material-ui/icons';

// MUI components
import { Paper, Tabs, Tab, useMediaQuery } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/styles';

// selectors
import getCurrentSubscription from 'store/selectors/getCurrentSubscription';

const LEAD = 'pnm';
const EVENT_FILTERS = { actions: ['event_guest.invited'] };
const NOTE_FILTERS = { actions: ['note.created'] };
const ACTIVITY_FILTERS = {
  actions: [
    'email_message.sent',
    'form.submitted',
    'note.created',
    'pnm.created',
    'pnm.deleted',
    'pnm.reported',
    'pnm.updated',
    'pnm_status.updated',
    'pnms.deleted',
    'pnms.imported',
    'pnms.duplicates_merged',
    'text_message.received',
    'text_message.sent',
  ],
};

export default function LeadTabs({
  leadId,
  hideInfo,
  hideTasks,
  isCouncilLeadPage,
}: {
  leadId: number;
  hideInfo?: boolean;
  hideTasks?: boolean;
  isCouncilLeadPage?: boolean | null;
}) {
  const classes = useStyles();
  const theme = useTheme();
  const isLargeAndDown = useMediaQuery((theme as any).breakpoints.down('lg'));
  const { isSystemAdmin, isGroupAdmin, currentSubscription } = useSelector(
    state => ({
      isSystemAdmin: (state as any).getIn(
        ['currentUser', 'data', 'isSystemAdmin'],
        false
      ),
      isGroupAdmin:
        (state as any).getIn(
          ['currentUser', 'data', 'roleId'],
          roles.member
        ) === roles.admin,
      currentSubscription: getCurrentSubscription(state as any),
    })
  );

  const currentPlan = currentSubscription?.getIn(['plan', 'name']);
  const isProfessionalVersion = currentPlan === 'ChapterBuilder Professional';
  const isStandardVersion = currentPlan === 'ChapterBuilder Standard';

  const activitySource = useMemo<{
    name: 'group' | 'pnm';
    id: number;
  }>(() => ({ name: LEAD, id: leadId }), [leadId]);

  const [tabValue, setTabValue] = useState<string>('info');

  useEffect(() => {
    setTabValue(hideInfo ? 'notes' : 'info');
  }, [hideInfo]);

  const tasksEnabled = useIsFeatureEnabled('tasks');
  const eventsEnabled = useIsFeatureEnabled('events');
  const textsEnabled = useIsFeatureEnabled('texts');

  const showMessagesTab =
    (isProfessionalVersion || isStandardVersion) &&
    (isSystemAdmin || isGroupAdmin) &&
    !isCouncilLeadPage &&
    textsEnabled;

  const handleTabChange = useCallback(
    (_, newTabValue) => {
      setTabValue(newTabValue);
    },
    [setTabValue]
  );

  const tabContent: {
    [key: string]: JSX.Element;
  } = useMemo(
    () => ({
      info: <LeadInfo leadId={leadId} withoutDetails />,
      notes: (
        <ActivityFeed
          source={activitySource}
          initialFilters={NOTE_FILTERS}
          title='Notes'
          withoutFiltering
          reload
        />
      ),
      activity: (
        <ActivityFeed
          source={activitySource}
          initialFilters={ACTIVITY_FILTERS}
          reload
        />
      ),
      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ leadId: number; }' is not assignable to ty... Remove this comment to see the full error message
      values: <ValuesIndicator leadId={leadId} />,
      payments: <PaymentsTab leadId={leadId} />,
      messages: <LeadMessageTab leadId={leadId} />,
      events: (
        <ActivityFeed
          source={activitySource}
          initialFilters={EVENT_FILTERS}
          title='Events'
          withoutFiltering
          reload
        />
      ),
      tasks: <LeadTasksTab leadId={leadId} />,
    }),
    [activitySource, leadId]
  );

  return (
    <>
      <Paper elevation={0} className={classes.paper}>
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          indicatorColor='primary'
          textColor='primary'
          variant={isLargeAndDown ? 'scrollable' : 'fullWidth'}
          scrollButtons='auto'
        >
          {!hideInfo && (
            <Tab icon={<ListAlt />} label='Info' id='info' value='info' />
          )}
          <Tab icon={<Description />} label='Notes' id='notes' value='notes' />
          <Tab
            icon={<Timeline />}
            label='Activity Feed'
            id='activity'
            value='activity'
          />
          {!isCouncilLeadPage && (
            <Tab
              icon={<ThumbsUpDown />}
              label='Endorsements'
              id='values'
              value='values'
            />
          )}
          {isCouncilLeadPage && (
            <Tab
              icon={<MonetizationOnOutlined />}
              label='Payments'
              id='payments'
              value='payments'
            />
          )}
          {showMessagesTab && (
            <Tab
              icon={<QuestionAnswer />}
              label='Messages'
              id='messages'
              value='messages'
            />
          )}
          {!!eventsEnabled && (
            <Tab
              icon={<DateRange />}
              label='Events'
              id='events'
              value='events'
            />
          )}
          {!hideTasks && tasksEnabled && !isCouncilLeadPage && (
            <Tab icon={<DoneAll />} label='Tasks' id='tasks' value='tasks' />
          )}
        </Tabs>
      </Paper>
      <Paper
        className={classNames(
          classes.tabContentPaper,
          tabValue === 'values' && classes.noPadding
        )}
      >
        {tabContent[tabValue]}
      </Paper>
    </>
  );
}
const useStyles = makeStyles(theme => {
  return {
    paper: {
      position: 'relative',
      zIndex: 2,
      borderBottomRightRadius: 0,
      borderBottomLeftRadius: 0,
      backgroundColor: (theme as any).palette.background.paper,
      borderBottom: `2px solid ${
        ((theme as any).palette.background as any).darkPaper
      }`,
      [(theme as any).breakpoints.down('sm')]: {
        marginLeft: 10,
        marginRight: 10,
      },
    },
    tabContentPaper: {
      padding: 16,
      backgroundColor: (theme as any).palette.background.paper,
      borderTopRightRadius: 0,
      borderTopLeftRadius: 0,
      overflowY: 'scroll',
      [(theme as any).breakpoints.down('sm')]: {
        marginLeft: 10,
        marginRight: 10,
      },
    },
    noPadding: {
      padding: 0,
    },
  };
});
