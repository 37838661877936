import React, { useCallback, useEffect, useState } from 'react';
import { Map } from 'immutable';
import { isEqual } from 'lodash';
import moment from 'moment';
// hooks
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
// material-ui
import { WithStyles, withStyles } from '@material-ui/core/styles';
// components
import ChapterListTable from 'routes/ChapterList/ChapterListTable';
import ChapterListActions from 'routes/ChapterList/ChapterListActions';
import LabelsCell from 'routes/ChapterList/LabelsCell';
import ParentCell from 'routes/ChapterList/ParentCell';
// action creators
import { storeClear } from 'store/actions/storeActions';
import { fetchLabelsAction } from 'store/actions/labelActions';
// selectors
import getIsSystemAdmin from 'store/selectors/getIsSystemAdmin';
import getIsHqAdmin from 'store/selectors/getIsHqAdmin';
import getIsCouncilAdmin from 'store/selectors/getIsCouncilAdmin';
import getLoadingFromState from 'store/selectors/getLoadingFromState';
import getChaptersCumpus from 'store/selectors/getChaptersCumpus';
import getCurrentGroupId from 'store/selectors/getCurrentGroupId';
import getLabels from 'store/selectors/getLabels';
import getTotal from 'store/selectors/getTotal';
//helpers
import getGroupParent from 'helpers/getGroupParent';
import getInitialParams from 'routes/ChapterList/helpers/getInitialParams';
//Styles
import chapterListStyles from 'routes/ChapterList/chapterList.style';

export interface ChapterListProps
  extends WithStyles<typeof chapterListStyles> {}

const ChapterList = ({ classes }: ChapterListProps) => {
  const history = useHistory();
  const {
    location: { search },
  } = history;
  const {
    chapterLoading,
    chapters,
    councilLoading,
    currentGroupId,
    currentGroup,
    currentGroupLoading,
    currentUser,
    file,
    isCouncilAdmin,
    isHqAdmin,
    isSystemAdmin,
    organizationLoading,
    role,
    session,
    greekChapterTotal,
  } = useSelector(
    state => ({
      chapters: getChaptersCumpus(state).toJS() as any,
      chapterLoading: getLoadingFromState(
        'group',
        'greekChapter',
        false
      )(state),
      councilLoading: getLoadingFromState('group', 'council', false)(state),
      currentGroupId: getCurrentGroupId(state),
      currentGroup: (state as any).get('currentGroup'),
      currentGroupLoading: getLoadingFromState(
        'currentGroup',
        null,
        false
      )(state),
      currentUser: (state as any).get('currentUser'),
      file: (state as any).get('file'),
      isSystemAdmin: getIsSystemAdmin(state),
      isCouncilAdmin: getIsCouncilAdmin(state),
      isHqAdmin: getIsHqAdmin(state),
      organizationLoading: getLoadingFromState(
        'group',
        'greekOrganization',
        false
      )(state),
      role: (state as any).get('role'),
      session: (state as any).get('session'),
      labels: getLabels(state),
      greekChapterTotal: getTotal('group', 'greekChapterTotal')(state),
    }),
    isEqual
  );

  const loading =
    councilLoading ||
    organizationLoading ||
    chapterLoading ||
    currentGroupLoading;
  const dispatch = useDispatch();
  const [allSelected, setAllSelected] = useState(false);
  const [selectedGroups, setSelectedGroups] = useState<number[]>([]);
  const handleResetSelected = () => {
    setSelectedGroups([]);
    setAllSelected(false);
  };

  const handleSelectAll = useCallback(() => {
    setSelectedGroups([]);
    setAllSelected(true);
  }, []);

  const handleSelectAllFetched = useCallback(
    () =>
      setSelectedGroups(
        (chapters || [])
          .filter((chapter: { id: number }) => !!chapter?.id)
          .map((chapter: { id: number }) => chapter.id)
      ),
    [chapters]
  );

  const selectChapter = useCallback(
    chapterId =>
      setSelectedGroups(selectedGroupsIds =>
        selectedGroupsIds.includes(chapterId)
          ? selectedGroupsIds.filter(item => item !== chapterId)
          : [...selectedGroupsIds, chapterId]
      ),
    []
  );

  useEffect(() => {
    let newState = Map({
      currentUser,
      session,
      file,
    });
    if (!isSystemAdmin || !isHqAdmin || !isCouncilAdmin) {
      newState = newState.set('currentGroup', currentGroup);
      newState = newState.set('role', role);
    }
    dispatch(storeClear(newState));
  }, []); // eslint-disable-line

  useEffect(() => {
    dispatch(fetchLabelsAction({ groupId: currentGroupId }));
  }, [dispatch, currentGroupId]);

  const getData = () => {
    return chapters.map((group = {}) => {
      const organization = getGroupParent(group, 'GREEK_ORGANIZATION');
      const council = getGroupParent(group, 'COUNCIL');
      const {
        dashboardLinkedParents = [],
        latestActivityOn,
        id: groupId,
        labels = [],
      } = (group as any) || {};

      const isOrganizationLinked = isSystemAdmin
        ? !!dashboardLinkedParents.find(
            (p = {}) => (p as any).id === organization?.id
          )
        : false;
      const isCouncilLinked = isSystemAdmin
        ? !!dashboardLinkedParents.find(
            (p = {}) => (p as any).id === council?.id
          )
        : false;

      return {
        ...(group || {}),
        isSelected: allSelected || selectedGroups.includes(groupId),
        isDisabled: allSelected,
        organizationName: organization?.name || '',
        councilName: council?.name || '',
        ...(latestActivityOn
          ? {
              latestActivityOn: moment
                .utc(latestActivityOn)
                .local()
                .format('MMM D, YYYY'),
            }
          : {}),
        ...(labels
          ? {
              labelsClear: labels,
              labels: (
                <LabelsCell tags={labels.filter(Boolean)} groupId={groupId} />
              ),
            }
          : {}),
        ...(isSystemAdmin
          ? {
              organization: (
                <ParentCell
                  parentId={organization.id}
                  childId={groupId}
                  parentName={organization?.name}
                  isLinked={isOrganizationLinked}
                />
              ),
              council: (
                <ParentCell
                  parentId={council.id}
                  childId={groupId}
                  parentName={council?.name}
                  isLinked={isCouncilLinked}
                />
              ),
            }
          : {
              organization: organization?.name,
              council: council?.name,
            }),
      };
    });
  };
  const data = getData();

  const initialParams = getInitialParams({
    isCouncilAdmin,
    currentGroupId,
    search,
    initialOrderBy: 'name',
  });

  const { filters: initialFilters, search: initialSearch } =
    initialParams || {};

  return (
    <div className={classes.groupsListContainer}>
      {(isSystemAdmin || isHqAdmin || isCouncilAdmin) && (
        <ChapterListActions
          allSelected={allSelected}
          resetSelected={handleResetSelected}
          selectedGroups={selectedGroups}
          onSelectAll={handleSelectAll}
          data={data}
          filters={initialFilters}
          search={initialSearch}
          chapterTotal={greekChapterTotal}
          onSelectAllFetched={handleSelectAllFetched}
        />
      )}
      <ChapterListTable
        data={data}
        loading={loading}
        initialParams={initialParams}
        selectChapter={selectChapter}
      />
    </div>
  );
};

export default withStyles(chapterListStyles, { withTheme: true })(ChapterList);
