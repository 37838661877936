import { Theme as MUITheme, createTheme } from '@material-ui/core/styles';
import {
  Palette,
  TypeBackground,
  TypeText,
} from '@material-ui/core/styles/createPalette';

// colors
import yellow from '@material-ui/core/colors/yellow';

// helpers
import isNamesListSite from 'helpers/isNamesListSite';

// fonts
const defaultFont = 'Poppins, sans-serif';

export interface Theme extends MUITheme {
  palette: Palette & {
    background: TypeBackground & {
      outline: string;
      default: string;
      darkPaper: string;
      lightPaper: string;
      highlightedPaper: string;
      hoverPaper: string;
      gradient: string;
      landing: string;
    };
    text: TypeText & {
      light: string;
    };
    link: {
      landingNavigation: string;
    };
  };
}

const chapterBuilderTheme: Theme = {
  palette: {
    type: 'dark',
    // @ts-ignore
    primary: {
      light: 'rgba(240, 79, 69, 0.28)',
      main: '#F04e45',
    },
    // @ts-ignore
    secondary: {
      dark: '#f89833',
      main: '#D6903C',
      light: '#fab93a',
    },
    // @ts-ignore
    error: {
      main: yellow[400],
    },
    // @ts-ignore
    success: {
      light: '#45F0B4',
      main: '#4caf50',
    },
    action: {
      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ main: string; }' is not assignable to type... Remove this comment to see the full error message
      main: '#fff',
    },
    background: {
      outline: 'rgba(255,255,255, 0.23)',
      default: '#1E2E39',
      darkPaper: '#273946',
      paper: '#2E404D',
      lightPaper: '#4C5E6B',
      highlightedPaper: '#5f7382',
      hoverPaper: '#4c5e6b7d',
      gradient: 'linear-gradient(140.28deg, #F04e45 0%, #f9a13a 100%)',
      landing: '#F04e45',
      //`linear-gradient(315deg, #f9a13a 0%, rgba(240, 79, 69, 0.28) 100%)`,
    },
    // @ts-ignore
    text: {
      light: 'rgba(255,255,255, 0.5)',
      secondary: 'rgba(255,255,255, 0.5)',
    },
    link: {
      landingNavigation: '#fff',
    },
  },

  // Variant breakdown: https://material-ui.com/components/typography/#component
  typography: {
    // @ts-expect-error ts-migrate(2322) FIXME: Type '{ useNextVariants: true; fontFamily: "Poppin... Remove this comment to see the full error message
    useNextVariants: true,
    fontFamily: defaultFont,
    h4: {
      fontWeight: 'bold',
      lineHeight: '50px',
    },
    h6: {
      fontWeight: 600,
    },
    button: {
      textTransform: 'none',
    },
    buttonNext: {
      textTransform: 'none',
    },
  },

  overrides: {
    MuiPaper: {
      rounded: {
        borderRadius: 8,
      },
    },
  },
};

const namesListTheme: Theme = {
  palette: {
    type: 'dark',
    // @ts-ignore
    primary: {
      light: 'rgba(240, 79, 69, 0.28)',
      main: '#F04e45',
    },
    // @ts-ignore
    secondary: {
      dark: '#f89833',
      main: '#D6903C',
      light: '#fab93a',
    },
    // @ts-ignore
    error: {
      main: yellow[400],
    },
    // @ts-ignore
    success: {
      light: '#45F0B4',
      main: '#4caf50',
    },
    action: {
      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ main: string; }' is not assignable to type... Remove this comment to see the full error message
      main: '#fff',
    },
    background: {
      outline: 'rgba(255,255,255, 0.23)',
      default: '#1E2E39',
      darkPaper: '#273946',
      paper: '#2E404D',
      lightPaper: '#4C5E6B',
      highlightedPaper: '#5f7382',
      hoverPaper: '#4c5e6b7d',
      gradient: 'linear-gradient(140.28deg, #F04e45 0%, #f9a13a 100%)',
      landing: '#2E404D',
      //`linear-gradient(315deg, #f9a13a 0%, rgba(240, 79, 69, 0.28) 100%)`,
    },
    // @ts-ignore
    text: {
      light: 'rgba(255,255,255, 0.5)',
      secondary: 'rgba(255,255,255, 0.5)',
    },
    link: {
      landingNavigation: '#586B78',
    },
  },

  // Variant breakdown: https://material-ui.com/components/typography/#component
  typography: {
    // @ts-expect-error ts-migrate(2322) FIXME: Type '{ useNextVariants: true; fontFamily: "Poppin... Remove this comment to see the full error message
    useNextVariants: true,
    fontFamily: defaultFont,
    h4: {
      fontWeight: 'bold',
      lineHeight: '50px',
    },
    h6: {
      fontWeight: 600,
    },
    button: {
      textTransform: 'none',
    },
    buttonNext: {
      textTransform: 'none',
    },
  },

  overrides: {
    MuiPaper: {
      rounded: {
        borderRadius: 8,
      },
    },
  },
};

export default createTheme(
  isNamesListSite() ? namesListTheme : chapterBuilderTheme
);
