import { Map } from 'immutable';
// constants
import { PHONE_NUMBER_GET_AVAILABLE_TWILIO } from 'store/constants/phoneNumberTypes';
// helpers
import httpReducer from './helpers/httpReducer';

export default function reduceTwilioNumber(state = Map(), action = {}) {
  switch ((action as any).type) {
    case PHONE_NUMBER_GET_AVAILABLE_TWILIO:
      return httpReducer(state, action, {
        mergeItems: false,
      });
    default:
      return state;
  }
}
