import React, { useCallback } from 'react';
import validate from 'validate.js';
import { isEqual } from 'lodash';

// hooks
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useLeadQuery } from 'api/leads';

// components
import DazzlingDialog from 'components/DazzlingDialog';
import TextField from 'components/TextField';
import Endorse from 'components/Endorse';
import { Field, Form } from 'react-final-form';

// material-ui
import Grid from '@material-ui/core/Grid';

// icons
import { ThumbsUpDown } from '@material-ui/icons';

// actions
import { createVoteAction, updateVoteAction } from 'store/actions/voteActions';

// selectors
import getSliceState from 'store/selectors/getSliceState';
import getCurrentUser from 'store/selectors/getCurrentUser';

const SCHEMA = {
  value: {
    presence: true,
  },
  comment: {
    length: { maximum: 1024 },
  },
};

const FORM = 'endorseLead';

type Props = {
  onClose: (...args: any[]) => any;
  opened: boolean;
};

export default function EndorseModal({ onClose, opened }: Props) {
  const { leadId } = useParams<{ leadId: string }>();
  const { data: lead } = useLeadQuery(Number(leadId));
  const dispatch = useDispatch();
  const createVote = useCallback(
    payload => dispatch(createVoteAction(payload)),
    [dispatch]
  );
  const updateVote = useCallback(
    payload => dispatch(updateVoteAction(payload)),
    [dispatch]
  );
  const votes = useSelector(getSliceState('vote'));
  const currentUser = useSelector(getCurrentUser);
  const vote = (votes as any).data.find(
    (vote: any) =>
      vote.accountId === (currentUser as any).get('id') &&
      vote.leadId.toString() === leadId
  );
  const handleFormSubmit = useCallback(
    fields => {
      if (vote) updateVote({ id: vote.id, leadId, fields, formName: FORM });
      else createVote({ leadId, fields, formName: FORM });
    },
    [createVote, updateVote, leadId, vote]
  );
  const validateFormFields = useCallback(
    fields => validate(fields, SCHEMA),
    []
  );
  return (
    <Form
      onSubmit={handleFormSubmit}
      initialValues={vote}
      initialValuesEqual={isEqual}
      validate={validateFormFields}
      render={({ handleSubmit, invalid, pristine, form: { reset } }) => (
        <DazzlingDialog
          disabled={invalid || pristine}
          formName={FORM}
          metaId={lead?.id}
          handleClose={onClose}
          open={opened}
          onAccept={handleSubmit}
          headerProps={{
            icon: ThumbsUpDown,
            title: `Endorse ${lead?.firstName}`,
            subtitle: `Evaluate ${lead?.firstName}'s culture fit and elaborate on it`,
          }}
          acceptLabel='Submit'
          submitOnEnter
          onExited={() => reset()}
        >
          <Grid container spacing={2}>
            <Grid item>
              <Field name='value' component={Endorse} />
            </Grid>
            <Grid item xs={12}>
              <Field
                name='comment'
                label='Comment'
                multiline
                rows='4'
                placeholder='Type something'
                component={TextField}
                fullWidth
                margin='normal'
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
        </DazzlingDialog>
      )}
    />
  );
}
