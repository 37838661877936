import SelectChapterFormActions from './SelectChapterFormActions';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

// action creators
import { createGroup } from 'store/actions/groupActions';
import { createRequest } from 'store/actions/requestActions';
import { destroySession } from 'store/actions/sessionActions';

// selectors
import {
  hasSubmitFailed,
  isSubmitting,
  hasSubmitSucceeded,
} from 'store/selectors/formSelectors';

const mapState = (state: any) => {
  return {
    selectGroupFormSubmitting: isSubmitting('selectGroupForm')(state),
    selectGroupFormSubmitFailed: hasSubmitFailed('selectGroupForm')(state),
    selectGroupFormSubmitSucceeded: hasSubmitSucceeded('selectGroupForm')(
      state
    ),
  };
};

const mapDispatch = {
  createGroup,
  createRequest,
  destroySession,
};

export default withRouter(
  connect(mapState, mapDispatch)(SelectChapterFormActions as any)
);
