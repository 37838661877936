import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

// action creators
import { doneIndicator } from 'store/actions/httpActions';

// api
import client from 'sources/api';

// constants
import {
  REFERRAL_FORM_SEND_PREVIEW_EMAIL_REQUEST,
  REFERRAL_FORM_SEND_PREVIEW_EMAIL,
} from 'store/constants/referralFormTypes';

// schemas
import { referralFormSchema } from 'store/schemas/referralFormSchema';

// helpers
import httpSaga from 'store/sagas/httpSaga';

const WATCH_TYPE = REFERRAL_FORM_SEND_PREVIEW_EMAIL_REQUEST;
const TYPE = REFERRAL_FORM_SEND_PREVIEW_EMAIL;

export function* sendPreviewEmail(action: any) {
  const {
    payload: { formId, groupId: group_id },
  } = action;

  // @ts-expect-error ts-migrate(2569) FIXME: Type 'Generator<any, any, unknown>' is not an arra... Remove this comment to see the full error message
  yield* httpSaga(
    TYPE,
    call(client.post, `/forms/${formId}/send_preview_email`, {
      params: {
        group_id,
      },
    }),
    {
      metaId: `preview-${formId}`,
      schema: referralFormSchema,
    }
  );

  yield put(doneIndicator(TYPE));
}

export function* watch() {
  yield takeEvery(WATCH_TYPE, sendPreviewEmail);
}

export default function* root() {
  yield all([fork(watch)]);
}
