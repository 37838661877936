import {
  MESSAGE_FETCH_REQUEST,
  MESSAGE_CREATE_REQUEST,
  MESSAGE_START_LISTEN_REQUEST,
  MESSAGE_STOP_LISTEN_REQUEST,
  MESSAGE_CLEAR,
} from 'store/constants/messageTypes';

export function fetchMessagesAction({ groupId, threadId, since }: any) {
  return {
    type: MESSAGE_FETCH_REQUEST,
    payload: { groupId, threadId, since },
  };
}

export function createMessageAction({
  accountIds,
  body,
  formName,
  groupId,
  isNewMessage = false,
  allSelected = false,
  leadIds,
  shouldFetchMessages,
  subject,
  threadId,
  threadType = 'text',
  filters,
  fileIds,
}: any) {
  return {
    type: MESSAGE_CREATE_REQUEST,
    payload: {
      accountIds,
      body,
      formName,
      groupId,
      isNewMessage,
      allSelected,
      leadIds,
      shouldFetchMessages,
      subject,
      threadId,
      threadType,
      filters,
      fileIds,
    },
  };
}

export function clearMessagesAction() {
  return {
    type: MESSAGE_CLEAR,
  };
}

export function startListeningForMessagesAction({ threadId }: any) {
  return {
    type: MESSAGE_START_LISTEN_REQUEST,
    payload: { threadId },
  };
}

export function stopListeningForMessagesAction() {
  return {
    type: MESSAGE_STOP_LISTEN_REQUEST,
  };
}
