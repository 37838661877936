import { all } from 'redux-saga/effects';

// sagas
import fetchSuggestedFieldsSaga from './fetchSuggestedFieldsSaga';

const suggestedFieldSaga = function* rootSaga() {
  yield all([fetchSuggestedFieldsSaga()]);
};

export default suggestedFieldSaga;
