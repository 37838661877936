import {
  all,
  call,
  fork,
  put,
  takeEvery,
  take,
  select,
} from 'redux-saga/effects';

// action creators
import { doneIndicator, error } from 'store/actions/httpActions';
import { refreshSession } from 'store/actions/sessionActions';

// api
import client from 'sources/api';

// constants
import {
  CURRENT_USER_UPDATE_REQUEST,
  CURRENT_USER_UPDATE,
} from 'store/constants/userTypes';
import { SESSION_REFRESH } from 'store/constants/sessionTypes';

// lib
import httpSaga from 'store/sagas/httpSaga';

const WATCH_TYPE = CURRENT_USER_UPDATE_REQUEST;
const TYPE = CURRENT_USER_UPDATE;

export function* updateCurrentUser(action: any) {
  const {
    firstName: first_name,
    lastName: last_name,
    profilePhotoFileId: profile_photo_file_id,
    phoneNumber: phone_number,
    allowEmailNotifications: allow_email_notifications,
    allowPushNotifications: allow_push_notifications,
    formName,
  } = action.payload;

  const currentUserId = yield select(state =>
    state.getIn(['currentUser', 'data', 'id'], false)
  );

  try {
    if (!currentUserId) {
      yield put(error(TYPE, { msg: 'Cannot find current user id' }));
    } else {
      // @ts-expect-error ts-migrate(2569) FIXME: Type 'Generator<any, any, unknown>' is not an arra... Remove this comment to see the full error message
      yield* httpSaga(
        TYPE,
        call(client.put, `/accounts/${currentUserId}`, {
          first_name,
          last_name,
          profile_photo_file_id,
          phone_number,
          allow_email_notifications,
          allow_push_notifications,
        }),
        {
          formName,
          throwError: true,
        }
      );

      // After updating the current user account we need to refresh the session because we
      // get the current user object off of the access token. Without this, if the user
      // were to refresh the page they wouldn't see their updates because the access token
      // is cached.
      yield put(refreshSession());
      yield take(doneIndicator(SESSION_REFRESH).type);
    }
  } catch (err) {
    yield put(error(TYPE, err));
  } finally {
    yield put(doneIndicator(TYPE));
  }
}

export function* watch() {
  yield takeEvery(WATCH_TYPE, updateCurrentUser);
}

export default function* root() {
  yield all([fork(watch)]);
}
