import React from 'react';

// material-ui
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MuiCheckbox from '@material-ui/core/Checkbox/Checkbox';

// components
import MultiSelectLabel from 'routes/ReferralForm/FormView/components/MultiSelectLabel';

//helpers
import { toString } from 'helpers/transform';

export interface MultiSelectFormControlProps {
  label: string;
  noteClasses: string;
  handleChangeCheckBox: (itemVal: string | number) => () => void;
  value: string | number;
  values: (string | number | boolean | null)[];
  className?: string;
  isCustomFieldTypeOfOrganizationForUniversalForm?: boolean;
}

const MultiSelectFormControl = ({
  noteClasses,
  label,
  value,
  values,
  handleChangeCheckBox,
  isCustomFieldTypeOfOrganizationForUniversalForm,
  className,
}: MultiSelectFormControlProps) => {
  return (
    <FormControlLabel
      control={
        <MuiCheckbox
          color='primary'
          checked={!!values.find(key => toString(key) === toString(value))}
          onClick={handleChangeCheckBox(value)}
        />
      }
      label={
        <MultiSelectLabel
          label={label}
          isCustomFieldTypeOfOrganizationForUniversalForm={
            isCustomFieldTypeOfOrganizationForUniversalForm
          }
          noteClasses={noteClasses}
        />
      }
      {...(className ? { className } : {})}
    />
  );
};

export default MultiSelectFormControl;
