import React, { useCallback } from 'react';
// material-ui
import { makeStyles } from '@material-ui/core/styles';
import MuiCheckbox from '@material-ui/core/Checkbox';
import MuiTableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import getPadding from 'components/Table/helpers/getPadding';

export default function TableRow({
  columns,
  data,
  TableCellProps,
  onRowClick,
  enableCheckbox = false,
  selectedItems = {},
  setSelectedItems = () => {},
  onSelect,
  variant,
  hover,
  ...restOfProps
}: {
  TableCellProps: any; // TODO: PropTypes.instanceOf(Object)
  columns: any[]; // TODO: PropTypes.instanceOf(Array)
  data: any; // TODO: PropTypes.instanceOf(Object)
  enableCheckbox?: boolean;
  onRowClick?: (...args: any[]) => any;
  selectedItems?: any; // TODO: PropTypes.instanceOf(Object)
  setSelectedItems?: (...args: any[]) => any;
  onSelect?: (item: any) => void;
  variant?: 'default' | 'outlined';
  hover?: boolean;
}) {
  const handleRowClicked = useCallback(() => {
    if (typeof onRowClick === 'function') {
      onRowClick(data);
    }
  }, [data, onRowClick]);

  const classes = useStyles();
  const itemId = data?.id;
  const isSelected = !!(selectedItems[itemId] || data?.isSelected);
  const checkboxTooltipTitle = isSelected ? 'Deselect' : 'Select';

  const onCheckedToggle = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    event.preventDefault();
    if (onSelect) {
      onSelect(data);
    } else {
      let newSelectedItems = { ...selectedItems };
      if (isSelected) {
        // deselects item
        delete newSelectedItems[itemId];
      } else {
        newSelectedItems[itemId] = true;
      }
      setSelectedItems(newSelectedItems);
    }
  };

  return (
    <MuiTableRow
      {...restOfProps}
      hover={Boolean(onRowClick) || hover}
      onClick={handleRowClicked}
      selected={isSelected}
      classes={
        variant === 'outlined'
          ? {
              root: classes.row,
            }
          : (restOfProps as any).classes
      }
    >
      {enableCheckbox && (
        <TableCell
          id='checkboxCell'
          key='cellCheckbox'
          classes={{ root: (classes as any).cellBorder }}
          {...TableCellProps}
          size='small'
          padding='checkbox'
          onClick={!data?.isDisabled && onCheckedToggle}
        >
          <Tooltip title={checkboxTooltipTitle} placement='bottom-start' arrow>
            <MuiCheckbox
              id='checkbox'
              checked={isSelected}
              disabled={data?.isDisabled}
              color='primary'
              classes={{ colorPrimary: classes.checkboxColor }}
            />
          </Tooltip>
        </TableCell>
      )}
      {columns.map(
        ({ key, label, padding, tooltipTitle: rowTooltipTitle = '' }: any) => {
          let node = data[key];
          const cellTooltipTitle = data?.tooltipTitle;
          // If this is not a valid react component, it's probably a string
          // and should be wrapped in Typography
          if (!React.isValidElement(node)) {
            node = <Typography variant='body2'>{data[key]}</Typography>;
          }
          return (
            <TableCell
              variant='body'
              key={key}
              classes={{
                root: classes.cell,
              }}
              {...TableCellProps}
              padding={getPadding(label, padding)}
            >
              <Tooltip
                arrow
                placement='bottom-start'
                title={cellTooltipTitle || rowTooltipTitle}
                disableHoverListener={!cellTooltipTitle && !rowTooltipTitle}
              >
                {node}
              </Tooltip>
            </TableCell>
          );
        }
      )}
    </MuiTableRow>
  );
}
const useStyles = makeStyles(theme => ({
  checkboxColor: {
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
      transition: 'all 0.2s ease',
      borderRadius: 4,
    },
  },
  cell: {
    '&:first-child': {
      paddingLeft: 24,
    },
    border: 'none',
  },
  row: {
    borderBottom: `1px solid ${(theme.palette.background as any).lightPaper}`,
    '&:last-child': {
      border: 'none',
    },
  },
}));
