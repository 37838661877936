import { Styles } from 'assets/types';

const formSuccessPageStyles: Styles = theme => ({
  root: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    paddingTop: 20,
    paddingBottom: 50,
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
    },
  },
  left: {
    width: 47,
    marginRight: 25,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      textAlign: 'center',
      marginRight: 0,
      paddingBottom: 10,
    },
  },
  right: {
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
  leftSvgIcon: {
    width: 50,
    height: 48,
  },
  title: {
    fontSize: '30px',
    lineHeight: '45px',
    fontWeight: 600,
    color: '#26F241',
    paddingBottom: 15,
  },
  text: {
    fontSize: '18px',
    lineHeight: '27px',
    fontWeight: 400,
  },
});

export default formSuccessPageStyles;
