import {
  STATUS_FETCH_REQUEST,
  STATUS_CREATE_REQUEST,
  STATUS_DELETE_REQUEST,
  STATUS_UPDATE_REQUEST,
  STATUS_CLEAR,
} from 'store/constants/statusTypes';

export function fetchStatusesAction({ groupId }: any) {
  return {
    type: STATUS_FETCH_REQUEST,
    payload: { groupId },
  };
}

export function createStatusAction({
  formName,
  groupId,
  defaultStatusId,
  values,
}: any) {
  return {
    type: STATUS_CREATE_REQUEST,
    payload: { formName, groupId, defaultStatusId, values },
  };
}

export function deleteStatusesAction({ formName, statuses }: any) {
  return {
    type: STATUS_DELETE_REQUEST,
    payload: { formName, statuses },
  };
}

export function updateStatusesAction({ formName, statuses }: any) {
  return {
    type: STATUS_UPDATE_REQUEST,
    payload: { formName, statuses },
  };
}

export function clearStatuses() {
  return {
    type: STATUS_CLEAR,
  };
}
