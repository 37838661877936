import { connect } from 'react-redux';

// action creators
import {
  resendVerificationEmail,
  makeEmailPrimary,
  deleteEmail,
} from 'store/actions/emailActions';

// components
import EmailListItem from './EmailListItem';

// selectors
import {
  isSubmitting,
  hasSubmitSucceeded,
  hasSubmitFailed,
} from 'store/selectors/formSelectors';

const mapState = (state: any, { email }: any) => {
  const DELETE_FORM = `deleteEmailForm${email.get('id')}`;
  const MAKE_PRIMARY_FORM = `makeEmailPrimaryForm${email.get('id')}`;
  const RESEND_VERIFICATION_FORM = `resendVerificationEmailForm${email.get(
    'id'
  )}`;

  return {
    deleteEmailSubmitting: isSubmitting(DELETE_FORM)(state),
    deleteEmailSubmitSucceeded: hasSubmitSucceeded(DELETE_FORM)(state),
    deleteEmailSubmitFailed: hasSubmitFailed(DELETE_FORM)(state),
    makeEmailPrimarySubmitting: isSubmitting(MAKE_PRIMARY_FORM)(state),
    makeEmailPrimarySubmitSucceeded: hasSubmitSucceeded(MAKE_PRIMARY_FORM)(
      state
    ),
    makeEmailPrimarySubmitFailed: hasSubmitFailed(MAKE_PRIMARY_FORM)(state),
    resendVerificationSubmitting: isSubmitting(RESEND_VERIFICATION_FORM)(state),
    resendVerificationSubmitSucceeded: hasSubmitSucceeded(
      RESEND_VERIFICATION_FORM
    )(state),
    resendVerificationSubmitFailed: hasSubmitFailed(RESEND_VERIFICATION_FORM)(
      state
    ),
  };
};

const mapDispatch = { resendVerificationEmail, makeEmailPrimary, deleteEmail };

export default connect(mapState, mapDispatch)(EmailListItem);
