import React, { useCallback } from 'react';
import { Field } from 'react-final-form';

// material-ui
import { Grid, Box, Hidden, Divider, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';

// components
import IconButton from 'components/IconButton';
import TextField from 'components/TextField';
import ToggleChip from 'components/ToggleChip';
import { Tooltip } from '@material-ui/core';

// icons
import { Delete } from '@material-ui/icons';

type Props = {
  fieldPrefix?: string;
  title?: string;
  color?: string;
  onDelete?: (...args: any[]) => any;
  isImmutable: boolean;
};

export default function MilestoneFields({
  fieldPrefix,
  onDelete,
  title,
  isImmutable,
}: Props) {
  const theme = useTheme();
  const isExtraSmall = useMediaQuery(theme.breakpoints.only('xs'));
  const IMMUTABLE_MILESTONE_MESSAGE = `"${title}" milestone can't be updated or removed`;

  const handleClick = useCallback(
    // @ts-expect-error ts-migrate(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
    () => onDelete(Number(fieldPrefix.match(/\d+/))),
    [onDelete, fieldPrefix]
  );

  return (
    <Grid
      container
      alignItems='center'
      spacing={isExtraSmall ? 1 : 3}
      wrap={isExtraSmall ? 'wrap' : 'nowrap'}
    >
      <Hidden smUp>
        <Grid item xs={12}>
          <Divider style={{ marginTop: 10, marginBottom: 10 }} />
        </Grid>
      </Hidden>

      <Grid item xs={9} sm={2}>
        <Box marginRight={3}>
          {/* @ts-expect-error ts-migrate(2322) FIXME: Type '{ children: string | undefined; selected: tr... Remove this comment to see the full error message */}
          <ToggleChip selected ring hideSelectIcon disabled>
            {title}
          </ToggleChip>
        </Box>
      </Grid>

      <Hidden smUp>
        <Grid item xs={3}>
          <Tooltip title={isImmutable ? IMMUTABLE_MILESTONE_MESSAGE : ''}>
            <Box>
              <Box clone display='block' marginLeft='auto'>
                <IconButton onClick={handleClick} disabled={isImmutable}>
                  <Delete />
                </IconButton>
              </Box>
            </Box>
          </Tooltip>
        </Grid>
      </Hidden>

      <Grid item xs={12} sm={3}>
        <Tooltip title={isImmutable ? IMMUTABLE_MILESTONE_MESSAGE : ''}>
          <Box>
            <Field
              fullWidth
              name={`${fieldPrefix}.title`}
              label='Title'
              variant='outlined'
              component={TextField}
              disabled={isImmutable}
            />
          </Box>
        </Tooltip>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Tooltip title={isImmutable ? IMMUTABLE_MILESTONE_MESSAGE : ''}>
          <Box>
            <Field
              fullWidth
              name={`${fieldPrefix}.description`}
              label='Description'
              variant='outlined'
              component={TextField}
              disabled={isImmutable}
            />
          </Box>
        </Tooltip>
      </Grid>

      <Hidden xsDown>
        <Grid item xs={1}>
          <Tooltip title={isImmutable ? IMMUTABLE_MILESTONE_MESSAGE : ''}>
            <Box>
              <Box clone display='block' marginLeft='auto'>
                <IconButton onClick={handleClick} disabled={isImmutable}>
                  <Delete />
                </IconButton>
              </Box>
            </Box>
          </Tooltip>
        </Grid>
      </Hidden>
    </Grid>
  );
}
