import { AxiosError } from 'axios';
import { MutationKey, useMutation, useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import { client, Resource } from 'api';
import getCurrentGroupId from 'store/selectors/getCurrentGroupId';
import useFetchFilters from 'helpers/hooks/useFetchFilters';

export function useDeleteLeadTagMutation() {
  const queryClient = useQueryClient();
  const { tagIds: filterTagIds } = useFetchFilters() || {};
  const groupId = useSelector(getCurrentGroupId);
  return useMutation<
    null,
    AxiosError,
    { pnmId: number; tagId: number; leadsQueryKey?: MutationKey }
  >(
    ({ pnmId, tagId }) =>
      client
        .delete(Resource.LeadTags, {
          params: { group_id: groupId, pnm_id: pnmId, tag_id: tagId },
        })
        .then(({ data }) => data),
    {
      onSuccess: (data, options) => {
        const { pnmId, tagId, leadsQueryKey } = options || {};
        if (!leadsQueryKey || (filterTagIds && filterTagIds.length > 0)) {
          queryClient.cancelQueries([Resource.Leads]);
          queryClient.refetchQueries([Resource.Leads]);
          return;
        }

        const { isFetching } = queryClient.getQueryState(leadsQueryKey) || {};
        if (isFetching) {
          queryClient.cancelQueries([Resource.Leads]);
          queryClient.refetchQueries([Resource.Leads]);
          return;
        }

        const dataLeads: {
          pages?: { results: Lead[] };
        } = (queryClient.getQueryData(leadsQueryKey) as any) || {};

        const newDataLeads = ((dataLeads as any)?.pages || []).map(
          (page: { results: Lead[] }) => {
            const newResults = page?.results?.map(itemResult => {
              if (itemResult?.id === pnmId) {
                const newTags = (itemResult?.tags || [])?.filter(
                  itemTag => itemTag !== tagId
                );
                return {
                  ...itemResult,
                  tags: newTags,
                };
              }

              return itemResult;
            });

            return {
              ...page,
              results: newResults,
            };
          }
        );
        queryClient.setQueryData(leadsQueryKey, {
          ...dataLeads,
          pages: newDataLeads,
        });
      },
    }
  );
}
