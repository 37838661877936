export const MANUAL_LEAD_SHARING_KEY = 'manual';
export const AUTOMATIC_LEAD_SHARING_KEY = 'automatic';

export const PUSH_LEAD_TYPE = {
  [MANUAL_LEAD_SHARING_KEY]: 'PushType.MANUAL',
  [AUTOMATIC_LEAD_SHARING_KEY]: 'PushType.AUTOMATIC',
};

export const getPushLeadFe = (pushLeadBe?: string) => {
  if (!pushLeadBe) {
    return undefined;
  }

  for (const key of Object.keys(PUSH_LEAD_TYPE)) {
    if (PUSH_LEAD_TYPE[key as keyof typeof PUSH_LEAD_TYPE] === pushLeadBe) {
      return key;
    }
  }

  return undefined;
};

export const getPushLeadBe = (pushLeadFe: keyof typeof PUSH_LEAD_TYPE) =>
  PUSH_LEAD_TYPE?.[pushLeadFe];

export const isEnableManualLeadSharing = (pushLeadBe?: string) =>
  !!pushLeadBe && getPushLeadFe(pushLeadBe) === MANUAL_LEAD_SHARING_KEY;

export const getPushLeadFromBool = (isEnable: boolean) =>
  isEnable ? MANUAL_LEAD_SHARING_KEY : AUTOMATIC_LEAD_SHARING_KEY;
