import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

// action creators
import { doneIndicator } from 'store/actions/httpActions';

// api
import client from 'sources/api';

// constants
import {
  GROUP_FIELD_FETCH_REQUEST,
  GROUP_FIELD_FETCH,
} from 'store/constants/groupFieldTypes';

// schemas
import { groupFieldSchema } from 'store/schemas/groupFieldSchema';

// helpers
import httpSaga from 'store/sagas/httpSaga';

const WATCH_TYPE = GROUP_FIELD_FETCH_REQUEST;
const TYPE = GROUP_FIELD_FETCH;

export function* fetchGroupFields(action: any) {
  const {
    payload: {
      groupId,
      orderBy = 'position',
      search,
      resultKey = 'result',
      page = 1,
      metaId,
    },
  } = action;

  // @ts-expect-error ts-migrate(2569) FIXME: Type 'Generator<any, any, unknown>' is not an arra... Remove this comment to see the full error message
  yield* httpSaga(
    TYPE,
    call(client.get, '/group_fields', {
      params: { group_id: groupId, order_by: orderBy, search, page },
    }),
    {
      schema: [groupFieldSchema],
      resultKey,
      metaId,
    }
  );

  yield put(doneIndicator(TYPE));
}

export function* watch() {
  yield takeEvery(WATCH_TYPE, fetchGroupFields);
}

export default function* root() {
  yield all([fork(watch)]);
}
