import React, { useCallback, useEffect, useMemo, memo, useState } from 'react';
import validate from 'validate.js';
import { isEqual } from 'lodash';
import pluralize from 'pluralize';

// hooks
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router';
import { useLeadQuery, useLeadsInfiniteQuery } from 'api/leads';

// components
import DazzlingDialog from 'components/DazzlingDialog';
import WizzyWigField from 'components/WizzyWigField';
import TextField from 'components/TextField';
import Button from 'components/Button';
import IconButton from 'components/IconButton';
import Accordion from 'components/Accordion';
import { Field, Form } from 'react-final-form';
import FileInputField from 'components/FileInputField';
import FilesList from 'components/CreateEmailModal/components/FilesList';

// material-ui
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';

// icons
import { Email, AddCircle, Delete } from '@material-ui/icons';

// selectors
import getCurrentGroupId from 'store/selectors/getCurrentGroupId';
import getLoadingFromState from 'store/selectors/getLoadingFromState';

// action creators
import { createMessageAction } from 'store/actions/messageActions';
import { createAndUploadFile } from 'store/actions/fileActions';

//helpers
import { FileFields } from 'components/CreateEmailModal/helpers/interfaces';

// schemas
const schema = {
  subject: {
    presence: true,
    length: { maximum: 255 },
  },

  body: {
    presence: true,
  },
};

const EMAIL_FORM = 'createEmailForm';
const IMAGE_FORM = 'addFilesToEmailForm';

type CreateEmailModalProps = {
  allSelected?: boolean;
  onClose: (...args: any[]) => any;
  open: boolean;
  leadIds?: number[];
  totalIds?: number[];
  filters?: object;
  totalLeadsCount?: number;
  isCouncilLeadPage?: boolean | null;
};

const CreateEmailModal = ({
  open,
  onClose,
  leadIds = [],
  totalIds = [],
  allSelected,
  filters = {},
  totalLeadsCount = 0,
  isCouncilLeadPage,
}: CreateEmailModalProps) => {
  const dispatch = useDispatch();
  const [recipientIds, setRecipientIds] = useState(leadIds);
  const [fileList, setFileList] = useState<FileFields[]>([]);
  const [isFileConverting, setIsFileConverting] = useState(false);
  const [showEmailAllModal, setShowEmailAllModal] = useState(false);
  const { leadId } = useParams<{ leadId: string }>();
  const isOnLeadProfile = Boolean(leadId);
  const { data: lead } = useLeadQuery(Number(leadId));
  const { data } = useLeadsInfiniteQuery('', {
    refetchOnMount: false,
    retryOnMount: false,
  });
  const leads: Lead[] = useMemo(() => (data?.pages ? data?.pages.flat() : []), [
    data,
  ]);
  const { search } = useLocation();

  const { currentGroupId, fileLoading } = useSelector(
    state => ({
      currentGroupId: getCurrentGroupId(state),
      fileLoading: getLoadingFromState('file', false, false)(state),
    }),
    isEqual
  );

  useEffect(() => {
    if (open) {
      setRecipientIds(leadIds);
    }
  }, [open, leadIds.length]); // eslint-disable-line

  const INDEX_TO_INSERT = 0; // We always insert at the beginning, for now

  let highlightedSubtitle = '';
  let noneHaveEmails = false;

  if (allSelected) {
    highlightedSubtitle = !Object.keys(filters).length
      ? totalIds.length === 0
        ? 'Sending email to all leads'
        : `Sending email to ${totalLeadsCount - totalIds.length} ` +
          `${pluralize('lead', totalLeadsCount)}`
      : `Sending email to  ${totalLeadsCount - totalIds.length} filtered ` +
        `${pluralize('lead', totalLeadsCount)}`;
  } else {
    highlightedSubtitle = `Sending email to ${recipientIds.length} ${pluralize(
      'lead',
      recipientIds.length
    )}`;
    const numWithoutEmails = totalIds.length - leadIds.length;
    noneHaveEmails = totalIds.length === numWithoutEmails;

    if (totalIds.length > leadIds.length) {
      highlightedSubtitle = `${highlightedSubtitle} (${numWithoutEmails} did not have an email address)`;
    }

    if (noneHaveEmails) {
      highlightedSubtitle =
        'None of the selected leads have emails. Update their info and try again.';
    }
  }

  const dispatchSendEmail = useCallback(
    values => {
      const payload: any = {
        groupId: currentGroupId,
        threadType: 'email',
        leadIds: recipientIds,
        allSelected,
        subject: values.subject,
        body: values.body,
        formName: EMAIL_FORM,
        ...(Array.isArray(fileList) && isCouncilLeadPage && fileList.length > 0
          ? { fileIds: fileList.map(item => item.newFileId) }
          : {}),
      };

      setShowEmailAllModal(false);
      if (allSelected && filters) payload.filters = filters;

      dispatch(createMessageAction(payload));
    },
    [
      allSelected,
      isCouncilLeadPage,
      currentGroupId,
      dispatch,
      recipientIds,
      filters,
      fileList,
    ]
  );

  const getInsertOptions = () => {
    return [
      {
        label: 'Lead First Name',
        value: 'first_name',
      },
      {
        label: 'Lead Full Name',
        value: 'full_name',
      },
    ];
  };

  const handleDeleteRecipient = useCallback(
    idToDelete => {
      const indexToDelete = recipientIds.findIndex(
        (id: any) => id === idToDelete
      );

      // JS splice wasn't persisting the changes when setting the state,
      //  So we're using filter instead
      const newRecipientIds = recipientIds.filter(
        (id: any, index: any) => index !== indexToDelete
      );

      setRecipientIds(newRecipientIds);
    },
    [recipientIds]
  );

  const recipients = useMemo(
    () =>
      isOnLeadProfile && lead?.emailAddress
        ? [lead]
        : leads.filter(
            lead => recipientIds.includes(lead?.id) && lead.emailAddress
          ),
    [lead, isOnLeadProfile, leads, recipientIds]
  );

  const handleFileChange = useCallback(
    ({ filePreview, newFileId, fileName }: FileFields) => {
      if (
        newFileId &&
        filePreview &&
        !filePreview.match(/^data:/) &&
        !fileList.find(file => file?.newFileId === newFileId)
      ) {
        setFileList([
          ...fileList,
          {
            filePreview,
            newFileId,
            fileName,
          },
        ]);
      }
    },
    [fileList]
  );

  const handleCreateAndUploadFile = (file: any) => {
    dispatch(createAndUploadFile({ file, formName: IMAGE_FORM }));
  };

  const handleFileConverting = (newIsFileConverting: boolean) => {
    setIsFileConverting(newIsFileConverting);
  };

  const handleCloseForm = useCallback(
    (restart: () => void) => {
      setFileList([]);
      onClose();
      restart();
    },
    [onClose]
  );

  const handleRestartForm = useCallback((restart: () => void) => {
    setFileList([]);
    restart();
  }, []);

  const handleRemoveFile = (fileId: number) => {
    const newList = fileList.filter(
      ({ newFileId }: FileFields) => newFileId !== fileId
    );
    setFileList([...(newList || [])]);
  };

  const warningModalConditions =
    !Object.keys(filters).length &&
    !search.includes('search') &&
    ((allSelected && totalIds.length === 0) ||
      (totalIds.length !== 0 && totalIds.length === totalLeadsCount));

  const RecipientList = useMemo(() => {
    return (
      <Box
        clone
        maxHeight={200}
        overflow='auto'
        width={350}
        border='1px solid'
        borderColor='background.lightPaper'
        borderRadius={8}
        pl={5}
      >
        <List>
          {recipients.map(recipient => (
            <ListItem key={recipient.id}>
              <ListItemText primary={recipient.fullName} />
              <ListItemSecondaryAction>
                <IconButton
                  edge='end'
                  disabled={recipientIds.length === 1}
                  aria-label='delete'
                  onClick={() => handleDeleteRecipient(recipient.id)}
                >
                  <Delete />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </Box>
    );
  }, [handleDeleteRecipient, recipientIds, recipients]);

  return (
    <Form
      id={EMAIL_FORM}
      onSubmit={dispatchSendEmail}
      initialValuesEqual={isEqual}
      validate={values => validate(values, schema)}
      mutators={{
        insertTemplateText: (textToInsert, state, { changeValue }) => {
          const insertString =
            textToInsert[0] === 'full_name'
              ? '{{ first_name }} {{ last_name }}'
              : `{{ ${textToInsert} }}`;

          changeValue(state, 'body', value => {
            if (!value) return insertString;

            return `${value.slice(
              0,
              INDEX_TO_INSERT
            )}${insertString}${value.slice(INDEX_TO_INSERT)}`;
          });
        },
      }}
      render={({
        handleSubmit,
        values,
        invalid,
        pristine,
        form: { mutators, restart },
      }) => {
        return (
          <>
            <DazzlingDialog
              id='emailLeadModal'
              disabled={
                invalid ||
                pristine ||
                noneHaveEmails ||
                fileLoading ||
                isFileConverting
              }
              formName={EMAIL_FORM}
              successMessage='Sent to email queue successfully!'
              handleClose={() => {
                handleCloseForm(restart);
              }}
              open={open}
              resetForm={() => {
                handleRestartForm(restart);
              }}
              onAccept={
                warningModalConditions
                  ? () => setShowEmailAllModal(true)
                  : handleSubmit
              }
              errorListTitle='Emails sent successfully to all but the following leads'
              headerProps={{
                icon: Email,
                title: 'Email',
                subtitle:
                  'Compose a message with mass email features to send to leads',
                highlightedSubtitle,
              }}
              acceptLabel='Send'
              submitOnEnter
            >
              <Grid container spacing={2} alignItems='center'>
                {!allSelected && (
                  <Accordion
                    label='Review Recipients'
                    details={RecipientList}
                  />
                )}

                <Grid item xs={12}>
                  <Field
                    name='subject'
                    component={TextField}
                    label='Subject'
                    margin='none'
                    required
                  />
                </Grid>
                <Grid item xs={8} sm={4}>
                  <Field
                    name='textToInsert'
                    component={TextField}
                    label='Text To Insert'
                    margin='none'
                    helperText='Inserts at beginning of email'
                    options={getInsertOptions()}
                    select
                  />
                </Grid>
                <Grid item xs={4} sm={8} style={{ marginTop: -22 }}>
                  <Button
                    id='insertButton'
                    startIcon={<AddCircle />}
                    onClick={() =>
                      mutators.insertTemplateText(values.textToInsert)
                    }
                  >
                    Click To Insert
                  </Button>
                </Grid>

                <Grid item xs={12}>
                  <Field name='body' component={WizzyWigField} />
                </Grid>

                {isCouncilLeadPage ? (
                  <>
                    <FilesList
                      files={fileList}
                      removeFile={handleRemoveFile}
                      loading={fileLoading || isFileConverting}
                    />

                    <Grid item xs={12}>
                      <Field name='fileId'>
                        {props => (
                          <FileInputField
                            // @ts-expect-error ts-migrate(2322) FIXME: Type '{ input: FieldInputProps<any, HTMLElement>; ... Remove this comment to see the full error message
                            formName={IMAGE_FORM}
                            onChange={handleFileChange}
                            onSubmit={handleCreateAndUploadFile}
                            onFileConverting={handleFileConverting}
                            buttonLabel='Attach a file'
                            disabled={fileLoading || isFileConverting}
                            {...props}
                          />
                        )}
                      </Field>
                    </Grid>
                  </>
                ) : null}
              </Grid>
            </DazzlingDialog>
            <DazzlingDialog
              id='emailAllModal'
              handleClose={() => setShowEmailAllModal(false)}
              open={showEmailAllModal}
              acceptLabel='Send All'
              onAccept={handleSubmit}
              headerProps={{
                icon: Email,
                title: `Are you sure you want to email ALL leads?`,
              }}
            />
          </>
        );
      }}
    />
  );
};

export default memo(CreateEmailModal);
