import React from 'react';
import PropTypes from 'prop-types';

// components
import { Link as RouterLink } from 'react-router-dom';

// material-ui
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { withStyles } from '@material-ui/core/styles';

const ForgotPasswordHeader = function({ classes, stage }: any) {
  return (
    <div className={classes.headerContainer}>
      <Typography className={classes.bold} variant='body1'>
        <Link component={RouterLink} to='/login' color='textPrimary'>
          <ArrowBackIcon className={classes.backIcon} />
          Back to Login
        </Link>
      </Typography>

      <Typography variant='h4'>
        {stage === 'sendEmail' && 'Forgot your password?'}
        {stage === 'passwordReset' && 'Reset your password'}
      </Typography>
      <Typography variant='body1'>
        {stage === 'sendEmail' &&
          'Enter your email to send a link to reset your password'}

        {stage === 'passwordReset' && 'Create a new password for your account'}
      </Typography>
    </div>
  );
};

ForgotPasswordHeader.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  stage: PropTypes.oneOf(['passwordReset', 'sendEmail']).isRequired,
};

const styles = (theme: any) => {
  return {
    headerContainer: {
      paddingTop: 16,
      paddingBottom: 16,
      [theme.breakpoints.up('sm')]: {
        padding: 24,
      },
    },
    backIcon: {
      marginBottom: '-6px',
      marginRight: '8px',
    },

    bold: {
      fontWeight: 'bold',
    },
  };
};

// @ts-expect-error ts-migrate(2345) FIXME: Argument of type '(theme: any) => { headerContaine... Remove this comment to see the full error message
export default withStyles(styles)(ForgotPasswordHeader);
