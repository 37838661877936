import Immutable, { Map } from 'immutable';
import { denormalize } from 'normalizr';
import createDeepEqualSelector from 'helpers/createDeepEqualSelector';

// schemas
import { currentGroupSchema } from '../schemas/currentGroupSchema';
import getSliceItems from './getSliceItems';

const getCurrentGroupSlice = (state: any) =>
  state.getIn(['currentGroup', 'data'], Map());

export default createDeepEqualSelector(
  [getCurrentGroupSlice, getSliceItems('role'), getSliceItems('group')],
  (currentGroup: any, roles: any, groups: any) => {
    const entities = {
      role: roles.toJS(),
      group: groups.toJS(),
    };

    return (Immutable.fromJS(
      denormalize(currentGroup.toJS(), currentGroupSchema, entities)
    ) as unknown) as TypedMap<Group>;
  }
);
