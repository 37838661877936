import Immutable from 'immutable';
import { put } from 'redux-saga/effects';

// helpers
import getResponsePayload from 'helpers/getResponsePayload';

// action creators
import {
  error,
  errorIndicator,
  loading,
  success,
  successIndicator,
} from 'store/actions/httpActions';

import {
  destroyForm,
  startFormSubmit,
  setFormSubmitFailed,
  setFormSubmitSucceeded,
} from 'store/actions/formActions';

export default function* httpSaga(
  type: any,
  request: any,
  {
    schema,
    throwError = false,
    formName,
    dispatchSuccess = true,
    dispatchFormSuccess = true,
    dispatchLoading = true,
    shouldDestroyForm = true,
    resultKey = 'result',
    totalKey = 'total',
    paginationKey,
    reducerOptions = {},
    metaId,
    singleResponseAsArray = false,
  }: any = {
    throwError: false,
  }
) {
  // Start form submit if form name was given
  if (formName) {
    yield put(startFormSubmit(formName));
  }

  // Notify the store that this type is loading
  if (dispatchLoading) {
    yield put(loading(type, metaId));
  }

  try {
    // Yield to the request promise
    const response = yield request;

    const { hasNext, hasPrevious, next, previous, total, msg = '' } =
      response.data || {};

    // Notify the store that the HTTP call was successful for this type
    yield put(successIndicator(type));

    if (dispatchSuccess) {
      const payload = getResponsePayload(
        singleResponseAsArray && response?.data
          ? {
              ...response,
              data: { results: [response?.data] },
            }
          : response,
        schema
      );

      // Send the response data to the store for this type to be caught by a reducer
      yield put(
        success(
          type,
          Immutable.fromJS({
            hasNext,
            hasPrevious,
            next,
            previous,
            total,
            responseOptions: {
              resultKey,
              totalKey,
              paginationKey,
            },
            ...reducerOptions,
            ...payload,
          }),
          metaId
        )
      );
    }

    // Set form submit succeeded if formName was given
    if (formName && dispatchFormSuccess) {
      yield put(setFormSubmitSucceeded(formName, msg));
      if (shouldDestroyForm) {
        yield put(destroyForm(formName));
      }
    }

    // Return the original response back to the parent saga
    return response;
  } catch (err) {
    // Notify the store that this type had an error
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 2.
    yield put(errorIndicator(type, err));

    if (throwError) {
      // Throw the error to be handled by the parent saga
      throw err;
    } else {
      // Dispatch an error payload for this type to be caught by the reducer
      yield put(error(type, err, metaId));

      if (formName) {
        yield put(setFormSubmitFailed(formName, err));
      }
    }
  }
}
