import { Map } from 'immutable';

export function hasSubmitFailed(formName: any) {
  return (state: any) => {
    return state.getIn(['form', formName, 'submitFailed'], false);
  };
}

export function isSubmitting(formName: any) {
  return (state: any) => {
    return state.getIn(['form', formName, 'submitting'], false);
  };
}

export function hasSubmitSucceeded(formName: any) {
  return (state: any) => {
    return state.getIn(['form', formName, 'submitSucceeded'], false);
  };
}

export function getError(formName: any) {
  return (state: any) => {
    return state.getIn(['form', formName, 'error'], Map());
  };
}

export function getResponseMessage(formName: any) {
  return (state: any) => {
    return state.getIn(['form', formName, 'successMessage'], '');
  };
}
