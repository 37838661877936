import React, { useState, useMemo, useCallback, useRef } from 'react';
import Immutable from 'immutable';
import queryString from 'query-string';
import {
  useNotificationsQuery,
  useReadNotificationsMutation,
} from 'api/notifications';

// hooks
import { useHistory } from 'react-router-dom';

// components
import MegaMenu from 'components/MegaMenu';
import IconButton from 'components/IconButton';

// material-ui
import { makeStyles } from '@material-ui/core/styles';
import { Badge } from '@material-ui/core';

// selectors
import { useSelector } from 'react-redux';
import getCurrentUser from 'store/selectors/getCurrentUser';

// icons
import NotificationsIcon from '@material-ui/icons/Notifications';

// helpers
import formatNotification from './helpers/formatNotification';

const PLACEHOLDER_NOTIFICATION = Immutable.fromJS([
  {
    id: 1,
    items: [
      {
        id: 1,
        label: 'No Notifications Found',
        subText: '',
      },
    ],
  },
]);

const NotificationsButton = function() {
  const classes = useStyles();
  const history = useHistory();
  const { location: { search = '' } = {} } = history;
  const anchorRef = useRef(null);
  const currentUser = useSelector(getCurrentUser);

  const [menuOpen, setMenuOpen] = useState(false);

  const { data, hasNextPage, fetchNextPage } = useNotificationsQuery();
  const notifications = useMemo(() => data?.pages.flat() || [], [data]);
  const hasUnreadNotifications = data?.pages.flat().some(n => !n.isRead);
  const { mutate: readNotifications } = useReadNotificationsMutation();

  const navigateTo = useCallback(
    (path, activityId = null, otherSearch = null) => {
      const searchObj = queryString.parse(search);
      const newSearchObj = { group: searchObj.group, ...otherSearch };
      activityId && (newSearchObj.activityId = activityId);
      const newSearch = queryString.stringify(newSearchObj);
      history.push({ pathname: path, search: newSearch });
    },
    [history, search]
  );

  const handleButtonClick = () => {
    setMenuOpen(true);
    if (hasUnreadNotifications) {
      readNotifications();
    }
  };

  const menuSections = notifications.length
    ? notifications.map((notification, index) =>
        formatNotification(notification, navigateTo, index, currentUser.toJS())
      )
    : PLACEHOLDER_NOTIFICATION;

  const onPaginate = useCallback(() => {
    if (hasNextPage) {
      fetchNextPage();
    }
  }, [hasNextPage, fetchNextPage]);

  return (
    <div className={classes.container}>
      <IconButton
        id='notificationsButton'
        aria-label='Notifications'
        onClick={handleButtonClick}
        ref={anchorRef}
      >
        <Badge
          variant='dot'
          color='primary'
          invisible={!hasUnreadNotifications}
        >
          <NotificationsIcon />
        </Badge>
      </IconButton>

      <MegaMenu
        // @ts-expect-error ts-migrate(2322) FIXME: Type '{ className: string; anchorEl: false | null;... Remove this comment to see the full error message
        className={classes.menu}
        anchorEl={menuOpen && anchorRef.current}
        menuSections={menuSections}
        handlePagination={onPaginate}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        itemProps={{}}
        handleClose={() => setMenuOpen(false)}
      />
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  container: {
    marginRight: 15,
    marginLeft: 'auto',
  },
  buttonLabel: {
    fontWeight: 'bold',
  },
  menu: {
    marginTop: 5,
  },
}));

export default NotificationsButton;
