import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import Immutable from 'immutable';

// action creators
import { doneIndicator, success } from 'store/actions/httpActions';

// api
import client from 'sources/api';

// constants
import { EVENT_DELETE_REQUEST, EVENT_DELETE } from 'store/constants/eventTypes';

// schemas
import { eventSchema } from 'store/schemas/eventSchema';

// helpers
import httpSaga from 'store/sagas/httpSaga';

const WATCH_TYPE = EVENT_DELETE_REQUEST;
const TYPE = EVENT_DELETE;

export function* deleteEventSaga(action: any) {
  const {
    payload: { eventId, groupId: group_id },
  } = action;

  // @ts-expect-error ts-migrate(2569) FIXME: Type 'Generator<any, any, unknown>' is not an arra... Remove this comment to see the full error message
  yield* httpSaga(
    TYPE,
    call(client.delete, `/events/${eventId}?group_id=${group_id}`),
    {
      dispatchSuccess: false,
      metaId: `delete-${eventId}`,
      schema: eventSchema,
    }
  );

  yield put(
    success(
      TYPE,
      Immutable.fromJS({
        id: eventId,
      })
    )
  );

  yield put(doneIndicator(TYPE));
}

export function* watch() {
  yield takeEvery(WATCH_TYPE, deleteEventSaga);
}

export default function* root() {
  yield all([fork(watch)]);
}
