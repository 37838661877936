export default function getVisibilityOptions() {
  return [
    {
      value: 'all',
      label: 'All',
      description: 'Every chapter user',
    },
    {
      value: 'admin',
      label: 'Admins',
      description: 'Leadership team and admins only',
    },
  ];
}
