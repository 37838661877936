import { Map } from 'immutable';
import {
  LEAD_TAG_FETCH_REQUEST,
  LEAD_TAG_CLEAR,
} from 'store/constants/leadTagTypes';

export function fetchLeadTagsAction({ id }: { id: number }) {
  return {
    type: LEAD_TAG_FETCH_REQUEST,
    payload: { id },
  };
}

export function clearLeadTagsAction({ id }: { id: number }) {
  return {
    type: LEAD_TAG_CLEAR,
    payload: Map({ id }),
  };
}
