import React from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';

// components
import Button from 'components/Button';

// material-ui
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const EmailListItem = function({
  email,
  deleteEmail,
  resendVerificationEmail,
  makeEmailPrimary,
  deleteEmailSubmitting,
  deleteEmailSubmitSucceeded,
  deleteEmailSubmitFailed,
  makeEmailPrimarySubmitting,
  makeEmailPrimarySubmitSucceeded,
  makeEmailPrimarySubmitFailed,
  resendVerificationSubmitting,
  resendVerificationSubmitSucceeded,
  resendVerificationSubmitFailed,
}: any) {
  const classes = useStyles();

  return (
    <Grid item className={classes.listItem} xs={12}>
      <Grid container alignItems='center' spacing={2}>
        <Grid item xs={12} sm='auto'>
          <Typography variant='body1'>{email.get('email')}</Typography>
        </Grid>

        <Grid item xs={4} sm='auto'>
          <Button
            id='resendVerificationButton'
            variant='outlined'
            disabled={email.get('verified')}
            loading={resendVerificationSubmitting}
            success={resendVerificationSubmitSucceeded}
            fail={resendVerificationSubmitFailed}
            onClick={() => {
              resendVerificationEmail(email.get('id'));
            }}
          >
            {email.get('verified') ? 'Verified' : 'Resend Verification Email'}
          </Button>
        </Grid>

        <Grid item xs={4} sm='auto'>
          <Button
            id='makeEmailPrimaryButton'
            variant='outlined'
            color='secondary'
            disabled={email.get('primary') || !email.get('verified')}
            loading={makeEmailPrimarySubmitting}
            success={makeEmailPrimarySubmitSucceeded}
            fail={makeEmailPrimarySubmitFailed}
            onClick={() => {
              makeEmailPrimary(email.get('id'));
            }}
          >
            {email.get('primary') ? 'Primary' : 'Make Primary'}
          </Button>
        </Grid>

        <Grid item xs={4} sm='auto'>
          <Button
            id='deleteEmailButton'
            variant='outlined'
            color='primary'
            disabled={email.get('primary')}
            loading={deleteEmailSubmitting}
            success={deleteEmailSubmitSucceeded}
            fail={deleteEmailSubmitFailed}
            onClick={() => {
              deleteEmail(email.get('id'));
            }}
          >
            Delete
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

EmailListItem.propTypes = {
  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'typeof Map' is not assignable to... Remove this comment to see the full error message
  email: PropTypes.instanceOf(Map).isRequired,
  deleteEmail: PropTypes.func.isRequired,
  resendVerificationEmail: PropTypes.func.isRequired,
  makeEmailPrimary: PropTypes.func.isRequired,
  deleteEmailSubmitting: PropTypes.bool.isRequired,
  deleteEmailSubmitSucceeded: PropTypes.bool.isRequired,
  deleteEmailSubmitFailed: PropTypes.bool.isRequired,
  makeEmailPrimarySubmitting: PropTypes.bool.isRequired,
  makeEmailPrimarySubmitSucceeded: PropTypes.bool.isRequired,
  makeEmailPrimarySubmitFailed: PropTypes.bool.isRequired,
  resendVerificationSubmitting: PropTypes.bool.isRequired,
  resendVerificationSubmitSucceeded: PropTypes.bool.isRequired,
  resendVerificationSubmitFailed: PropTypes.bool.isRequired,
};

const useStyles = makeStyles({
  listItem: {
    padding: 10,
  },

  divider: {
    marginTop: 10,
  },
});

export default EmailListItem;
