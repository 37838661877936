import { all, call, fork, takeEvery, put, select } from 'redux-saga/effects';

// action creators
import { doneIndicator } from 'store/actions/httpActions';

// selectors
import getCurrentGroupId from 'store/selectors/getCurrentGroupId';

// api
import client from 'sources/api';

// constants
import {
  MILESTONE_UPDATE_REQUEST,
  MILESTONE_UPDATE,
} from 'store/constants/milestoneTypes';

// lib
import httpSaga from 'store/sagas/httpSaga';

// schemas
import { milestoneSchema } from 'store/schemas/milestoneSchema';

const WATCH_TYPE = MILESTONE_UPDATE_REQUEST;
const TYPE = MILESTONE_UPDATE;

export function* updateMilestone(action: any) {
  const {
    payload: {
      formName,
      id,
      fields: { title, description },
    },
  } = action;
  const currentGroupId = yield select(getCurrentGroupId);
  // @ts-expect-error ts-migrate(2569) FIXME: Type 'Generator<any, any, unknown>' is not an arra... Remove this comment to see the full error message
  yield* httpSaga(
    TYPE,
    call(client.put, `/tags/${id}?group_id=${currentGroupId}`, {
      title,
      description,
      type: 'event',
      visibility: [],
    }),
    {
      formName,
      shouldDestroyForm: false,
      schema: milestoneSchema,
    }
  );

  yield put(doneIndicator(TYPE));
}

export function* watch() {
  yield takeEvery(WATCH_TYPE, updateMilestone);
}

export default function* root() {
  yield all([fork(watch)]);
}
