import React, { useCallback, useMemo, useState } from 'react';
import validate from 'validate.js';

// hooks
import { useIsFeatureEnabled } from 'helpers/hooks/featureHooks';
import { useSelector } from 'react-redux';
import { useUpdateLeadMutation } from 'api/leads';

// material-ui
import { Box } from '@material-ui/core';
import { Email, Edit } from '@material-ui/icons';

// components
import CreateEmailModal from 'components/CreateEmailModal';
import CardActionItem from 'components/CardActionItem';
import Toast from 'components/Toast';

// selectors
import getPermission from 'store/selectors/getPermission';

const SCHEMA = {
  emailAddress: {
    email: { message: 'not valid' },
    length: { maximum: 255 },
  },
};

type EmailItemProps = {
  lead: Lead;
  displayActions?: boolean;
  isCouncilLeadPage?: boolean | null;
};

// Avoid memo-izing this component!
// Doing so will cause issues with CreateEmailModal
export default function EmailItem({
  lead,
  isCouncilLeadPage,
  displayActions = true,
}: EmailItemProps) {
  const {
    isLoading: emailUpdating,
    mutate: updateLead,
  } = useUpdateLeadMutation();
  const [emailModalOpen, setEmailModalOpen] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(true);
  const noEmailAddress = !Boolean(lead.emailAddress);

  const emailsEnabled = useIsFeatureEnabled('emails');
  const canCreateMessages = useSelector(getPermission('message.createMany'));

  const handleCloseEmailModal = useCallback(() => {
    setEmailModalOpen(false);
  }, []);

  const handleOpenEmailModal = useCallback(e => {
    e.preventDefault();
    e.stopPropagation();
    setEmailModalOpen(true);
  }, []);

  const actions = useMemo(() => {
    if (noEmailAddress || !canCreateMessages || !emailsEnabled) {
      return [
        {
          id: 'updateEmail',
          icon: <Edit />,
          tooltipTitle: 'Update Email',
          onClick: () => {},
        },
      ];
    }

    return [
      {
        id: 'email',
        icon: <Email />,
        tooltipTitle: 'Send Email',
        onClick: handleOpenEmailModal,
      },
    ];
  }, [canCreateMessages, handleOpenEmailModal, emailsEnabled, noEmailAddress]);

  const handleChange = useCallback(emailAddress => {
    const validationErrors = validate({ emailAddress }, SCHEMA);
    setIsEmailValid(!validationErrors || emailAddress === '');
    return emailAddress;
  }, []);

  const handleUpdate = useCallback(
    emailAddress => {
      const dirty = emailAddress !== lead.emailAddress;

      if (isEmailValid && dirty) {
        updateLead({
          id: lead.id,
          emailAddress,
        });
      }
    },
    [isEmailValid, lead, updateLead]
  );
  const leadIds = useMemo(() => [lead.id], [lead]);

  return (
    <Box>
      <CardActionItem
        label={lead.emailAddress || ''}
        displayActions={displayActions}
        emptyLabel='No email address'
        variant='contained'
        loading={emailUpdating}
        actions={actions}
        // @ts-expect-error ts-migrate(2322) FIXME: Type '{ label: any; displayActions: any; emptyLabe... Remove this comment to see the full error message
        button={noEmailAddress}
        hover
        editable
        onChange={handleChange}
        onBlur={handleUpdate}
        TextFieldProps={{
          id: 'emailField',
          type: 'email',
          label: isEmailValid ? 'Email Address' : 'Email Invalid',
          error: isEmailValid,
          fullWidth: true,
          autoFocus: true,
        }}
      />

      {!!emailModalOpen && (
        <CreateEmailModal
          open={emailModalOpen}
          onClose={handleCloseEmailModal}
          leadIds={leadIds}
          isCouncilLeadPage={isCouncilLeadPage}
        />
      )}

      <Toast
        successMessage='Updated successfully'
        failureMessage='Update failed'
        slice='lead'
      />
    </Box>
  );
}
