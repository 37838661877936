import { toUpperCase, toString } from 'helpers/transform';

export const ORGANIZATION_GENDER: { [key: string]: string } = {
  1: 'All',
  2: 'Women',
  3: 'Men',
};

export const getOrganizationGenderAsArray = (): string[] => {
  const values = [];
  for (const key in ORGANIZATION_GENDER) {
    values.push(ORGANIZATION_GENDER[key]);
  }

  return values;
};

export const getOrganizationGenderById = (
  id: string | number
): string | undefined => ORGANIZATION_GENDER?.[toString(id)];

export const getOrganizationGenderId = (gender: string): string | undefined => {
  const value = toUpperCase(gender);
  for (const key in ORGANIZATION_GENDER) {
    if (ORGANIZATION_GENDER[key].toUpperCase() === value) {
      return key;
    }
  }

  return;
};
