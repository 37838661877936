import React, { useCallback, useEffect, useMemo, useState } from 'react';
import moment, { Moment } from 'moment';

// redux-hooks
import { useSelector } from 'react-redux';

// components
import DateRange from './DateRange';
import ReportRefreshButton from 'components/ReportRefreshButton';
import ExportButton from 'components/ExportButton';
import ChapterDropdown from './ChapterDropdown';
import Header from 'components/Header';

// selectors
import getIsSystemAdmin from 'store/selectors/getIsSystemAdmin';

// MUI
import { Grid } from '@material-ui/core';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';
import { SvgIconTypeMap } from '@material-ui/core';

interface FormDateRangeProps {
  initialStartDate?: Moment;
  initialEndDate?: Moment;
  loading?: boolean;
  reportId?: string;
  title?: string;
  subtitle?: string;
  newMembersReport?: boolean;
  isHqReport?: boolean;
  icon?: OverridableComponent<SvgIconTypeMap>;
  currentGroupId?: number;
  isCouncilAdmin?: boolean;
  isHqAdmin?: boolean;
  isFetchingGroupsTurnedOff?: boolean;
  onCreateReport: (args: {
    startDate: Moment;
    endDate: Moment;
    forceRefresh: boolean;
    groupId?: number;
  }) => void;
}

const AnalyticsActionBar = ({
  loading,
  reportId,
  subtitle,
  title,
  currentGroupId,
  isCouncilAdmin,
  isHqAdmin,
  newMembersReport,
  isHqReport,
  isFetchingGroupsTurnedOff,
  initialStartDate = moment().subtract(
    newMembersReport && isCouncilAdmin ? 90 : 7,
    'days'
  ),
  initialEndDate = moment(),
  icon,
  onCreateReport,
}: FormDateRangeProps) => {
  const isSystemAdmin = useSelector(getIsSystemAdmin);

  const [dates, setDates] = useState({
    startDate: initialStartDate,
    endDate: initialEndDate,
    isError: false,
  });

  const [selectedGroupId, setSelectedGroupId] = useState(currentGroupId);

  const maxDays =
    isSystemAdmin ||
    (isHqAdmin && isHqReport) ||
    (newMembersReport && isCouncilAdmin)
      ? 366
      : 90;

  const filterByChapterMoreOptions = useMemo(
    () => [
      {
        value: currentGroupId,
        label: 'All Chapters',
      },
    ],
    [currentGroupId]
  );

  const createReport = useCallback(
    ({
      startDate = dates.startDate,
      endDate = dates.endDate,
      forceRefresh = false,
      groupId = selectedGroupId,
    }: {
      startDate?: Moment;
      endDate?: Moment;
      forceRefresh?: boolean;
      groupId?: number;
    }) => {
      onCreateReport({
        startDate,
        endDate,
        forceRefresh,
        groupId,
      });
    },
    [onCreateReport, dates, selectedGroupId]
  );

  const handleDateChange = useCallback(
    ({
      startDate,
      endDate,
      isError,
    }: {
      startDate: Moment;
      endDate: Moment;
      isError: boolean;
    }) => {
      setDates({ startDate, endDate, isError });
    },
    []
  );

  const handleChapterChange = (value?: number) => {
    setSelectedGroupId(value);
  };

  useEffect(() => {
    if (!dates.isError) {
      createReport({
        startDate: dates.startDate,
        endDate: dates.endDate,
        groupId: selectedGroupId,
      });
    }
  }, [dates, selectedGroupId, createReport]);

  return (
    <Grid
      container
      spacing={2}
      direction='row'
      justifyContent='space-between'
      alignItems='center'
    >
      <Grid item xs={3}>
        {title && (
          <Header
            title={title}
            subtitle={subtitle}
            icon={icon}
            compact
            alignItems='center'
          />
        )}
      </Grid>
      <Grid item xs>
        <Grid container spacing={2} justifyContent='flex-end'>
          {currentGroupId && (
            <Grid item xs>
              <ChapterDropdown
                currentGroupId={currentGroupId}
                input={{
                  name: 'filterByChapter',
                  value: selectedGroupId,
                  onChange: handleChapterChange,
                }}
                meta={{}}
                moreOptionsFirst={filterByChapterMoreOptions}
                label='Filter by Chapter'
                variant='outlined'
                selectStyle='analyticsList'
                isCouncilAdmin={isCouncilAdmin}
                isFetchingGroupsTurnedOff={isFetchingGroupsTurnedOff}
                disabled={dates.isError}
              />
            </Grid>
          )}
          <Grid item xs>
            <Grid container wrap='nowrap' justifyContent='flex-end'>
              <Grid item>
                <DateRange
                  onDateChange={handleDateChange}
                  initialStartDate={initialStartDate}
                  initialEndDate={initialEndDate}
                  maxDays={maxDays}
                  disabled={loading}
                />
              </Grid>
              <Grid item>
                <Grid container wrap='nowrap'>
                  <Grid item>
                    <ReportRefreshButton
                      loading={loading}
                      onRefresh={createReport}
                      disabled={dates.isError}
                    />
                  </Grid>
                  <Grid item>
                    {reportId && (
                      <ExportButton params={{ reportId }} disabled={loading} />
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AnalyticsActionBar;
