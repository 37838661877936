import { all, call, fork, put, takeEvery, take } from 'redux-saga/effects';
import Immutable from 'immutable';

// action creators
import { doneIndicator, success } from 'store/actions/httpActions';
import { fetchFiles } from 'store/actions/fileActions';

// api
import client from 'sources/api';

// constants
import {
  REFERRAL_FORM_FETCH_FOR_GROUP_REQUEST,
  REFERRAL_FORM_FETCH_FOR_GROUP,
} from 'store/constants/referralFormTypes';

// helpers
import httpSaga from 'store/sagas/httpSaga';
import getResponsePayload from 'helpers/getResponsePayload';

// schema
import { referralFormSchema } from 'store/schemas/referralFormSchema';

const WATCH_TYPE = REFERRAL_FORM_FETCH_FOR_GROUP_REQUEST;
const TYPE = REFERRAL_FORM_FETCH_FOR_GROUP;

export function* fetchReferralFormsForGroup(action: any) {
  const { groupId: group_id, page } = action.payload;

  try {
    // @ts-expect-error ts-migrate(2569) FIXME: Type 'Generator<any, any, unknown>' is not an arra... Remove this comment to see the full error message
    const response = yield* httpSaga(
      TYPE,
      call(client.get, '/forms', {
        params: {
          group_id,
          page,
        },
      }),
      {
        dispatchSuccess: false,
        schema: [referralFormSchema],
      }
    );

    let payload;
    if (response) {
      const { data: { results = [] } = {} } = response;

      const imageIds = yield results
        .filter((result: any) => result.headerPhotoFileId !== null)
        .map((result: any) => result.headerPhotoFileId);

      // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
      yield put(fetchFiles(imageIds));
      yield take('FILE_FETCH_DONE');

      payload = getResponsePayload(response, [referralFormSchema]);
      const { hasNext, hasPrevious, next, previous, total, msg = '' } =
        response.data || {};
      payload = {
        hasNext,
        hasPrevious,
        next,
        previous,
        total,
        msg,
        ...payload,
      };
    }

    yield put(success(TYPE, Immutable.fromJS(payload)));
  } finally {
    yield put(doneIndicator(TYPE));
  }
}

export function* watch() {
  yield takeEvery(WATCH_TYPE, fetchReferralFormsForGroup);
}

export default function* root() {
  yield all([fork(watch)]);
}
