import humps from 'humps';
import { useQuery } from 'react-query';
import { AxiosError } from 'axios';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { client, Resource } from 'api';
import getCurrentGroupId from 'store/selectors/getCurrentGroupId';

export type EventGuestQueryParams = {
  page: number;
  search: string;
  statusIds?: number[];
  invited?: '' | 'true' | 'false';
  rsvp?: '' | 'yes' | 'no' | 'maybe' | 'n/a';
  attended?: '' | 'true' | 'false';
  type?: 'pnm' | 'member';
  startDateStart?: string;
  startDateEnd?: string;
  orderBy?: string;
};

export type EventGuestFilters = {
  statusIds?: number[];
  invited?: '' | 'true' | 'false';
  rsvp?: '' | 'yes' | 'no' | 'maybe' | 'n/a';
  attended?: '' | 'true' | 'false';
  type?: 'pnm' | 'member';
  startDateStart?: string;
  startDateEnd?: string;
};

export const useEventGuestsQuery = ({
  page = 1,
  search = '',
  statusIds = [],
  invited = '',
  rsvp = '',
  attended = '',
  type = 'pnm',
  startDateStart = '',
  startDateEnd = '',
  orderBy = 'fullName',
}: EventGuestQueryParams) => {
  const groupId = useSelector(getCurrentGroupId);
  const { eventId } = useParams<{ eventId: string }>();
  const params = {
    groupId: groupId,
    eventId,
    type,
    statusId: statusIds,
    invited,
    rsvp,
    attended,
    startDateStart,
    startDateEnd,
    orderBy: humps.decamelize(orderBy),
    search,
    page,
  };
  return useQuery<
    {
      results: EventGuest[];
      next: string | null;
      previous: string | null;
      total: number;
    },
    AxiosError,
    {
      eventGuests: EventGuest[];
      total: number;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
    }
  >(
    [Resource.EventGuests, params],
    () => client.get(Resource.EventGuests, { params }).then(({ data }) => data),
    {
      select: data => ({
        eventGuests: data.results,
        hasNextPage: data.next !== null,
        hasPreviousPage: data.previous !== null,
        total: data.total,
      }),
      enabled: Boolean(params.groupId && params.eventId),
    }
  );
};
