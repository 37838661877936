import React, { useCallback, useMemo } from 'react';
import moment, { Moment } from 'moment';
import { isEqual } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';

// MUI components
import {
  Grid,
  Card,
  CardContent,
  WithStyles,
  withStyles,
} from '@material-ui/core';

// components
import Table from 'components/Table';
import AnalyticsActionBar from 'components/AnalyticsActionBar';

// action creators
import { createChapterLeaderboardReport } from 'store/actions/apiTaskActions';

// selectors
import getForm from 'store/selectors/getForm';
import getSliceEntityById from 'store/selectors/getSliceEntityById';
import getIsHqAdmin from 'store/selectors/getIsHqAdmin';
import getCurrentGroup from 'store/selectors/getCurrentGroup';

// helpers
import getTableColumns from 'routes/ChapterAnalytics/ChapterLeaderboard/helpers/getTableColumns';
import { getComparator } from 'components/Table/helpers/sortFunctions';
import { isEmpty, isArray } from 'helpers/check';
import getGroupType from 'helpers/getGroupType';

// icons
import AccountBalance from '@material-ui/icons/AccountBalance';

// styles
import chapterLeaderboardStyles from 'routes/ChapterAnalytics/ChapterLeaderboard/chapterLeaderboard.style';

const REPORT_FORM = 'chapterLeaderboardReportForm';
const ROWS_PER_PAGE = 10;

export interface ChapterLeaderboardContent {
  callsCreated: number;
  emailsSent: number;
  groupId: number;
  groupName?: string;
  groupOrganization?: string;
  groupCouncil?: string;
  groupNameSort?: string;
  latestActivityDate: string;
  latestActivityDateSort?: number;
  notesCreated: number;
  pnmStatusesUpdated: number;
  pnmsCreated: number;
  points?: number | null;
  textsSent: number;
}

export interface ChapterLeaderboardProps
  extends WithStyles<typeof chapterLeaderboardStyles> {}

const ChapterLeaderboard = ({ classes }: ChapterLeaderboardProps) => {
  const dispatch = useDispatch();

  const {
    currentGroup,
    isHqAdmin,
    report: { data: { content = [] } = {}, id: reportId },
    form: { isSubmitting },
  } = useSelector(
    state => ({
      currentGroup: getCurrentGroup(state),
      isHqAdmin: getIsHqAdmin(state),
      form: getForm(REPORT_FORM)(state),
      report: getSliceEntityById(
        'report',
        'ReportTypes.CHAPTER_LEADERBOARD',
        {}
      )(state),
    }),
    isEqual
  );

  const isOrganization =
    currentGroup.get('type') === getGroupType('GREEK_ORGANIZATION');

  const currentGroupId = currentGroup.get('id');

  const dispatchCreateReport = useCallback(
    ({
      startDate,
      endDate,
      forceRefresh = false,
    }: {
      startDate: Moment;
      endDate: Moment;
      forceRefresh: boolean;
    }) => {
      dispatch(
        createChapterLeaderboardReport({
          groupId: currentGroupId,
          formName: REPORT_FORM,
          startDate,
          endDate,
          forceRefresh,
        })
      );
    },
    [currentGroupId, dispatch]
  );

  const columns = getTableColumns(isOrganization);

  const newContent = useMemo<ChapterLeaderboardContent[]>(() => {
    if (!content || isEmpty(content) || !isArray(content)) {
      return [];
    }

    return content.map((item: ChapterLeaderboardContent) => {
      const {
        points,
        groupName,
        groupCouncil,
        groupOrganization,
        latestActivityDate,
      } = item || {};

      const parentGroup = groupCouncil || groupOrganization;

      const latestActivityDateMoment =
        latestActivityDate && moment(latestActivityDate).isValid()
          ? moment(latestActivityDate)
          : false;
      const newGroupName = points ? (
        <>
          {!isEmpty(groupName) && <div>{groupName}</div>}
          {!isEmpty(parentGroup) && <div>{parentGroup}</div>}
          <div className={classes.points}>{points} points</div>
        </>
      ) : (
        groupName
      );
      return {
        ...(item || {}),
        groupName: newGroupName,
        groupNameSort: groupName || parentGroup,
        latestActivityDate: latestActivityDateMoment
          ? latestActivityDateMoment.local().format('MMM DD, YYYY')
          : '',
        latestActivityDateSort: latestActivityDateMoment
          ? latestActivityDateMoment.unix()
          : 0,
      };
    });
  }, [content, classes.points]);

  const getOrderBy = (orderBy: string): string => {
    switch (orderBy) {
      case 'groupName':
        return 'groupNameSort';

      case 'latestActivityDate':
        return 'latestActivityDateSort';

      default:
        return orderBy;
    }
  };

  const sortingComparator = (order: boolean | string, orderBy: string) =>
    getComparator(order, getOrderBy(orderBy));

  return (
    <Card>
      <CardContent className={classes.cardContent}>
        <Grid container spacing={2}>
          <AnalyticsActionBar
            onCreateReport={dispatchCreateReport}
            title='Chapter Leaderboard'
            subtitle='View and filter the activity of your chapters'
            icon={AccountBalance}
            isHqAdmin={isHqAdmin}
            loading={isSubmitting}
            reportId={reportId}
            isHqReport
          />

          <Grid item xs={12} className={classes.tableGrid}>
            <Table
              columns={columns}
              data={newContent}
              variant='outlined'
              numberOfSkeletonRows={isSubmitting ? 8 : 0}
              initialOrder='asc'
              initialOrderBy='groupName'
              TablePaginationProps={{
                count: newContent.length,
                rowsPerPage: ROWS_PER_PAGE,
                rowsPerPageOptions: [ROWS_PER_PAGE],
              }}
              sortingComparator={sortingComparator}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default withStyles(chapterLeaderboardStyles, { withTheme: true })(
  ChapterLeaderboard
);
