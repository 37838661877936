export const TASK_FETCH_REQUEST = 'TASK_FETCH_REQUEST';
export const TASK_FETCH = 'TASK_FETCH';
export const TASK_CREATE_REQUEST = 'TASK_CREATE_REQUEST';
export const TASK_CREATE = 'TASK_CREATE';
export const TASK_COMPLETE_REQUEST = 'TASK_COMPLETE_REQUEST';
export const TASK_COMPLETE = 'TASK_COMPLETE';
export const TASK_UNCOMPLETE_REQUEST = 'TASK_UNCOMPLETE_REQUEST';
export const TASK_UNCOMPLETE = 'TASK_UNCOMPLETE';
export const TASK_DELETE_REQUEST = 'TASK_DELETE_REQUEST';
export const TASK_DELETE = 'TASK_DELETE';
export const TASK_POPULATE_REQUEST = 'TASK_POPULATE_REQUEST';
export const TASK_POPULATE = 'TASK_POPULATE';
export const TASK_CLEAR = 'TASK_CLEAR';
export const TASK_CLEAR_BY_ID = 'TASK_CLEAR_BY_ID';
