import { all, call, fork, put, takeEvery, take } from 'redux-saga/effects';
import Immutable from 'immutable';

// action creators
import { doneIndicator, success } from 'store/actions/httpActions';
import { fetchMembersForGroup } from 'store/actions/memberActions';

// api
import client from 'sources/api';

// constants
import {
  EVENT_FETCH_FOR_GROUP_REQUEST,
  EVENT_FETCH_FOR_GROUP,
} from 'store/constants/eventTypes';
import { MEMBER_FETCH_FOR_GROUP } from 'store/constants/memberTypes';

// helpers
import httpSaga from 'store/sagas/httpSaga';
import getResponsePayload from 'helpers/getResponsePayload';

// schema
import { eventSchema } from 'store/schemas/eventSchema';

const WATCH_TYPE = EVENT_FETCH_FOR_GROUP_REQUEST;
const TYPE = EVENT_FETCH_FOR_GROUP;

export function* fetchEventsForGroupSaga(action: any) {
  const { groupId: group_id } = action.payload;

  try {
    // @ts-expect-error ts-migrate(2569) FIXME: Type 'Generator<any, any, unknown>' is not an arra... Remove this comment to see the full error message
    const response = yield* httpSaga(
      TYPE,
      call(client.get, '/events', {
        params: {
          group_id,
        },
      }),
      {
        dispatchSuccess: false,
        schema: [eventSchema],
      }
    );

    let payload;

    if (response) {
      const { data: { results = [] } = {} } = response;

      const pointOfContactIds = yield results
        .filter((result: any) => result.pointOfContact !== null)
        .map((result: any) => result.pointOfContact);

      yield put(
        fetchMembersForGroup({
          groupId: group_id,
          accountIds: pointOfContactIds,
        })
      );
      yield take(MEMBER_FETCH_FOR_GROUP);

      payload = getResponsePayload(response, [eventSchema]);
    }
    yield put(success(TYPE, Immutable.fromJS(payload)));
  } finally {
    yield put(doneIndicator(TYPE));
  }
}

export function* watch() {
  yield takeEvery(WATCH_TYPE, fetchEventsForGroupSaga);
}

export default function* root() {
  yield all([fork(watch)]);
}
