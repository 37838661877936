import React, { useCallback, useMemo, memo, useEffect } from 'react';
import { Field, Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';

import humps from 'humps';
import { Map } from 'immutable';
import { isEqual } from 'lodash';
import pluralize from 'pluralize';

import { createExportAction } from 'store/actions/apiTaskActions';
import { clearFileExportById as clearFileExportByIdAction } from 'store/actions/fileExportActions';
import getCurrentGroupId from 'store/selectors/getCurrentGroupId';
import getSliceEntityById from 'store/selectors/getSliceEntityById';
import getForm from 'store/selectors/getForm';

import download from 'helpers/download';

import DazzlingDialog from 'components/DazzlingDialog';
import Checkbox from 'components/Checkbox';

import GetApp from '@material-ui/icons/GetApp';
import { Grid } from '@material-ui/core';

const FORM = 'exportChaptersFrom';
const EXPORT_TYPE = 'chapters';

type ExportChaptersModalProps = {
  open: boolean;
  onClose: (...args: any[]) => any;
  chaptersIds?: any; // TODO: PropTypes.instanceOf(Array)
  allSelected?: boolean;
};

const ExportChaptersModal = ({
  open,
  onClose,
  chaptersIds,
  allSelected,
}: ExportChaptersModalProps) => {
  const {
    actions: { createExport, clearFileExportById },
    data: {
      currentGroupId,
      fileExport: { downloadUrl } = {},
      form: { hasSubmitFailed } = {},
    },
  } = useRedux();

  useEffect(() => {
    if (downloadUrl) {
      download(downloadUrl);
      clearFileExportById({ exportId: 'chapters' });
    }
  }, [clearFileExportById, downloadUrl]);

  const onSubmit = useCallback(
    ({ groupFields = [], ...restOfValues }) => {
      const chapterDataKeys = [];
      for (const property in restOfValues) {
        const key = humps.decamelize(property);
        const val = restOfValues[property];
        if (val) {
          chapterDataKeys.push(key);
        }
      }

      const leadFieldKeys = groupFields.map(({ label }: any) => label);

      const params = {
        chaptersIds: allSelected ? 'all' : chaptersIds,
        chaptersIncludeFields: [...chapterDataKeys, ...leadFieldKeys],
      };

      createExport({
        groupId: currentGroupId,
        exportType: EXPORT_TYPE,
        formName: FORM,
        ...params,
      });
    },
    [allSelected, chaptersIds, createExport, currentGroupId]
  );

  return (
    <Form
      id='exportChaptersFrom'
      onSubmit={onSubmit}
      initialValues={{
        organization: true,
        council: true,
      }}
      render={({ handleSubmit, invalid, form: { reset } }) => {
        return (
          <DazzlingDialog
            id='exportLeadsModal'
            disabled={invalid}
            alertProps={{
              message: hasSubmitFailed
                ? 'Export Failed'
                : 'Exported Successfully',
            }}
            formName={FORM}
            handleClose={() => {
              reset();
              onClose();
            }}
            open={open}
            onAccept={handleSubmit}
            headerProps={{
              icon: GetApp,
              title: 'Export',
              subtitle: 'Fine tune the data that you need for each chapter',
              highlightedSubtitle: allSelected
                ? 'All chapters selected'
                : `${chaptersIds.length} ${pluralize(
                    'chapter',
                    chaptersIds.length
                  )} selected`,
            }}
            resetForm={reset}
          >
            <Grid container spacing={2} id='dialogContent'>
              {dataOptions.map(({ name, label }) => (
                <Grid key={name} item xs={6}>
                  <Field
                    name={name}
                    component={Checkbox}
                    label={label}
                    type='checkbox'
                  />
                </Grid>
              ))}
            </Grid>
          </DazzlingDialog>
        );
      }}
    />
  );
};

const dataOptions = [
  {
    name: 'name',
    label: 'Designation',
  },
  {
    name: 'organization',
    label: 'Organization',
  },
  {
    name: 'council',
    label: 'Council',
  },
  {
    name: 'latestActivityOn',
    label: 'Latest Activity',
  },
  {
    name: 'leadsCount',
    label: 'Leads',
  },
  {
    name: 'joinersCount',
    label: 'Joiners',
  },
  {
    name: 'membersCount',
    label: 'Members',
  },
  {
    name: 'labels',
    label: 'Labels',
  },
];

const useRedux = () => {
  const dispatch = useDispatch();
  const actions = useMemo(
    () => ({
      createExport: (payload: any) => dispatch(createExportAction(payload)),
      clearFileExportById: (payload: any) =>
        dispatch(clearFileExportByIdAction(payload)),
    }),
    [dispatch]
  );
  const data = useSelector(
    state => ({
      currentGroupId: getCurrentGroupId(state),
      fileExport: getSliceEntityById('fileExport', EXPORT_TYPE, Map())(state),
      form: getForm(FORM)(state),
    }),
    isEqual
  );
  return { actions, data };
};

export default memo(ExportChaptersModal);
