import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Map, List } from 'immutable';
import { useSelector } from 'react-redux';

// material-ui
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import Box from '@material-ui/core/Box';
import Chip from '@material-ui/core/Chip';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import TextInput from '@material-ui/core/TextField';
import TextField from 'components/TextField';
import Typography from '@material-ui/core/Typography';
import Hidden from '@material-ui/core/Hidden';
import { makeStyles } from '@material-ui/core/styles';

// icons
import DeleteIcon from '@material-ui/icons/Delete';
import DragIcon from '@material-ui/icons/DragIndicator';
import OrganizationIcon from '@material-ui/icons/LocationCity';

// components
import { Field } from 'react-final-form';
import Select from 'components/Select';
import Switch from 'components/Switch';
import Button from 'components/Button';
import IconButton from 'components/IconButton';

// helpers
import getVisibilityOptions from 'helpers/getVisibilityOptions';
import isFieldTypeWithOptions from 'routes/FieldsSettings/helpers/isFieldTypeWithOptions';

// selectors
import getLoadingFromState from 'store/selectors/getLoadingFromState';
import getFieldTypeOptions from 'helpers/getFieldTypeOptions';
import { isEmpty } from 'helpers/check';

const sourceLabelMap: { [key: string]: string } = {
  PNMAttr: 'Lead Field',
  FormField: 'Form Field',
  GroupValue: 'Value',
};

const FieldItem = function({
  field,
  index,
  items,
  mutators,
  label,
  labelToDelete,
  onDelete,
  subText,
  isCurrentGroupCouncil,
}: any) {
  const classes = useStyles();
  const fieldData = items.get(index, Map());

  const { deleteLoading } = useSelector(state => ({
    deleteLoading:
      labelToDelete === fieldData.get('label')
        ? getLoadingFromState('groupValue', `delete-${labelToDelete}`)(state)
        : false,
  }));

  const optionList = fieldData.get('options', List()).toJS();

  const [optionName, setOptionName] = useState('');

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOptionName(e.target.value);
  };

  const addOption = (optionName: string) => {
    if (
      optionName &&
      (isEmpty(optionList) ||
        !optionList.find((item: string) => item === optionName))
    ) {
      mutators.setValue(`${field}.options`, [...optionList, optionName]);
    }
  };

  const removeOption = (key: string) => {
    mutators.setValue(`${field}.options`, [
      ...optionList.filter((option: string) => option !== key),
    ]);
  };

  const handleSubmit = (e: Event) => {
    e.preventDefault();
    addOption(optionName);
    setOptionName('');
  };

  const isTypeOfFieldFormField = !!(
    fieldData?.get('sources', List()) || List()
  ).find((item: string) => item === 'FormField');

  const isFieldCustom = fieldData?.get('type') === 'custom';

  const renderFieldSourceLabel = () => {
    return fieldData
      .get('sources', List())
      .toSet()
      .sort()
      .map((source: string) => sourceLabelMap[source])
      .join(', ') as string;
  };

  const renderFieldLabel = () => {
    return (
      <Grid container>
        <Grid item xs={12}>
          <Typography
            variant='body2'
            color='textSecondary'
            className={classes.italic}
          >
            {label || renderFieldSourceLabel()}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography variant='body1' className={classes.bold}>
            {fieldData.get('label')}
          </Typography>
        </Grid>

        {subText && (
          <Grid item xs={12} id='subText'>
            <Typography
              variant='body2'
              className={classes.italic}
              color='secondary'
            >
              {subText}
            </Typography>
          </Grid>
        )}
      </Grid>
    );
  };

  const renderSourceLabel = () => {
    return (
      <Grid container>
        <Grid item xs={12}>
          <Typography
            variant='body2'
            color='textPrimary'
            className={classes.fieldSource}
          >
            {label || renderFieldSourceLabel()}
          </Typography>
        </Grid>

        {subText && (
          <Grid item xs={12} id='subText'>
            <Typography
              variant='body2'
              className={classes.fieldSource}
              color='secondary'
            >
              {subText}
            </Typography>
          </Grid>
        )}
      </Grid>
    );
  };

  const visibility = (
    <Field
      name={`${field}.visibility`}
      label='Visible To'
      variant='outlined'
      options={getVisibilityOptions()}
      component={Select}
    />
  );

  if (isFieldCustom || isCurrentGroupCouncil) {
    return (
      <Grid item xs={12} id='fieldItem'>
        <Card id='fieldItem' className={classes.card}>
          <Grid container spacing={2} alignItems='center' alignContent='center'>
            <Grid item xs={12} md={3}>
              <Tooltip title={`Click & drag to reorder`}>
                <DragIcon />
              </Tooltip>
            </Grid>

            <Grid item xs={8} md={isFieldCustom ? 3 : 6}>
              {renderSourceLabel()}
            </Grid>

            {!!isFieldCustom && (
              <Grid item xs={8} md={3}>
                {isCurrentGroupCouncil && (
                  <Field
                    name={`${field}.isPublished`}
                    type='checkbox'
                    label='Share with Linked Group'
                    component={Switch}
                  />
                )}
              </Grid>
            )}

            {onDelete && !fieldData.get('parent') ? (
              <Grid item xs={4} md={3} id='deleteIcon'>
                <Grid container justifyContent='flex-end' alignItems='center'>
                  <Grid item xs={5} md={2}>
                    <IconButton
                      onClick={() => onDelete(fieldData)}
                      loading={deleteLoading}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
            ) : (
              <Hidden smDown>
                <Grid item md={3} />
              </Hidden>
            )}

            <Grid item xs={12} md={isFieldCustom ? 3 : 6}>
              <Field
                name={`${field}.label`}
                label='Field Name'
                component={TextField}
                margin='none'
                disabled
              />
            </Grid>

            {isFieldCustom && (
              <Grid item xs={12} md={3}>
                <Field
                  name={`${field}.description`}
                  label='Description'
                  component={TextField}
                  margin='none'
                />
              </Grid>
            )}

            <Grid className='gridSelectBox' item xs={12} md={3}>
              {visibility}
            </Grid>

            {isTypeOfFieldFormField && isFieldCustom && (
              <Grid className='gridSelectBox' item xs={12} md={3}>
                <Field
                  name={`${field}.fieldType`}
                  label='Field Type'
                  variant='outlined'
                  options={getFieldTypeOptions()}
                  component={Select}
                  margin='none'
                />
              </Grid>
            )}

            {isFieldTypeWithOptions(fieldData.get('fieldType')) &&
              isTypeOfFieldFormField &&
              isFieldCustom && (
                <>
                  <Grid item xs={12} md={3}>
                    <Field name={`${field}.option`}>
                      {props => (
                        <TextInput
                          className={classes.textInput}
                          label='Option Name'
                          variant='outlined'
                          value={optionName}
                          name={props.input.name}
                          onChange={handleChange}
                        />
                      )}
                    </Field>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Button
                      style={{ paddingTop: 14, paddingBottom: 14 }}
                      variant='outlined'
                      size='large'
                      startIcon={<AddCircleIcon />}
                      iconButtonIcon={<AddCircleIcon />}
                      onClick={handleSubmit}
                    >
                      Add Option
                    </Button>
                  </Grid>
                  <Grid container item xs={12}>
                    {optionList.map((item: string) => (
                      <Box
                        key={item}
                        marginLeft={1}
                        marginTop={1}
                        marginBottom={1}
                        marginRight={1}
                      >
                        <Grid item xs={2} sm={4} md={4}>
                          <Chip
                            label={item}
                            variant='outlined'
                            onDelete={() => removeOption(item)}
                          />
                        </Grid>
                      </Box>
                    ))}
                  </Grid>
                </>
              )}
          </Grid>
        </Card>
      </Grid>
    );
  } else {
    return (
      <Grid item xs={12} id='fieldItem'>
        <Card id='fieldItem' className={classes.card}>
          <Grid container spacing={3} alignItems='center' alignContent='center'>
            <Grid item xs={1} className={classes.dragIconContainer}>
              <Tooltip title={`Click & drag to reorder`}>
                <DragIcon />
              </Tooltip>
            </Grid>

            <Grid item xs={4} sm={3}>
              {fieldData.get('description') ? (
                <Tooltip
                  id='descriptionTooltip'
                  title={fieldData.get('description')}
                  placement='bottom-start'
                >
                  {renderFieldLabel()}
                </Tooltip>
              ) : (
                renderFieldLabel()
              )}
            </Grid>

            <Grid item xs={4} sm={5} className={classes.selectContainer}>
              <Grid container>
                <Grid item xs={12} sm={6}>
                  {visibility}
                </Grid>
              </Grid>
            </Grid>

            {fieldData.get('parent') && (
              <Grid item xs={2} id='requiredIcon'>
                <Grid
                  container
                  direction='column'
                  justifyContent='center'
                  alignItems='center'
                >
                  <Grid item xs={12}>
                    <OrganizationIcon className={classes.requiredIcon} />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography>Set By HQ</Typography>
                  </Grid>
                </Grid>
              </Grid>
            )}

            {onDelete && !fieldData.get('parent') && (
              <Grid item xs={3} id='deleteIcon'>
                <Grid container justifyContent='flex-end' alignItems='center'>
                  <Grid item xs={6} md={2}>
                    <IconButton
                      onClick={() => onDelete(fieldData)}
                      loading={deleteLoading}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Card>
      </Grid>
    );
  }
};

const useStyles = makeStyles(theme => ({
  card: {
    backgroundColor: (theme.palette.background as any).lightPaper,
    padding: 16,
  },
  dragIconContainer: {
    textAlign: 'left',
  },
  italic: {
    fontStyle: 'italic',
  },
  fieldSource: {
    fontSize: '1rem',
    fontFamily: 'Poppins, sans-serif',
    fontWeight: 400,
    marginLeft: 5,
    marginTop: 5,
  },
  textInput: {
    width: '100%',
  },
  bold: {
    fontWeight: 600,
  },
  requiredIcon: {
    color: theme.palette.success.main,
  },
  selectContainer: {
    marginTop: 5,
    marginBottom: 0,
  },
}));

FieldItem.propTypes = {
  field: PropTypes.string,
  label: PropTypes.string,
  index: PropTypes.number,
  mutators: PropTypes.any,
  labelToDelete: PropTypes.string,
  isCurrentGroupCouncil: PropTypes.bool,
  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'typeof List' is not assignable t... Remove this comment to see the full error message
  items: PropTypes.instanceOf(List).isRequired,
  subText: PropTypes.string,
  onDelete: PropTypes.func,
};

FieldItem.defaultProps = {
  subText: '',
  field: '',
  index: 0,
  onDelete: null,
  labelToDelete: null,
};
export default FieldItem;
