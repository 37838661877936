import React, { useState, useEffect, useCallback } from 'react';
import { Map, List } from 'immutable';
import queryString from 'query-string';

// hooks
import { useHistory } from 'react-router-dom';
import { useLeadsInfiniteQuery } from 'api/leads';

// material-ui
import { makeStyles } from '@material-ui/core/styles';
import { Tooltip } from '@material-ui/core';

// icons
import { ArrowUpward, ArrowDropDown } from '@material-ui/icons';

// components
import Button from 'components/Button';
import IconButton from 'components/IconButton';
import MegaMenu from 'components/MegaMenu';

export enum LeadsSortByKeys {
  Status = 'status_custom-position',
  FirstName = 'firstName',
  LastName = 'lastName',
  Email = 'emailAddress',
  MainContact = 'mainContactFullName',
  DateAdded = 'createdOn',
  LastContacted = 'lastContactedOn',
}

export enum LeadsSortByDirections {
  Asc = 'asc',
  Desc = 'desc',
}

export default function LeadListSort() {
  const classes = useStyles();
  const history = useHistory();

  const queryParams = queryString.parse(history.location.search);
  const orderByParam = queryParams.orderBy as LeadsSortByKeys | null;
  let sortDirection: LeadsSortByDirections = LeadsSortByDirections.Asc;
  let sortBy: LeadsSortByKeys = LeadsSortByKeys.Status;
  if (orderByParam) {
    sortDirection =
      orderByParam.charAt(0) === '-'
        ? LeadsSortByDirections.Desc
        : LeadsSortByDirections.Asc;
    sortBy =
      orderByParam.charAt(0) === '-'
        ? (orderByParam.substring(1) as LeadsSortByKeys)
        : (orderByParam as LeadsSortByKeys);
  }
  const sortByKeyToLabel = (key: LeadsSortByKeys) => {
    switch (key) {
      default:
      case LeadsSortByKeys.Status:
        return 'Status';
      case LeadsSortByKeys.FirstName:
        return 'First Name';
      case LeadsSortByKeys.LastName:
        return 'Last Name';
      case LeadsSortByKeys.Email:
        return 'Email';
      case LeadsSortByKeys.MainContact:
        return 'Main Contact';
      case LeadsSortByKeys.DateAdded:
        return 'Date Added';
      case LeadsSortByKeys.LastContacted:
        return 'Last Contacted';
    }
  };

  const sortByLabel = sortByKeyToLabel(sortBy);
  const [sortByAnchorEl, setSortByAnchorEl] = useState(null);
  const { isLoading } = useLeadsInfiniteQuery();

  const updateOrderBy = useCallback(
    (newSortDirection: LeadsSortByDirections, newSortBy: LeadsSortByKeys) => {
      const newQueryParams = queryString.parse(history.location.search);
      newQueryParams.orderBy = `${
        newSortDirection === LeadsSortByDirections.Asc ? '' : '-'
      }${newSortBy}`;
      history.replace({
        pathname: history.location.pathname,
        search: queryString.stringify(newQueryParams),
      });
    },
    [history]
  );

  const setSortBy = (newSortBy: LeadsSortByKeys) =>
    updateOrderBy(sortDirection, newSortBy);
  const setSortDirection = (newSortDirection: LeadsSortByDirections) =>
    updateOrderBy(newSortDirection, sortBy);

  useEffect(() => {
    const queryParams = queryString.parse(history.location.search);
    if (!queryParams.orderBy) {
      updateOrderBy(LeadsSortByDirections.Asc, LeadsSortByKeys.Status);
    }
  }, [history, updateOrderBy]);

  const getSortByOptionsMenu = () => {
    const menuSections = List([
      Map({
        id: 'sortBy',
        sectionHeader: 'Sort By:',
        items: List([
          Map({
            id: 'firstName',
            label: 'First Name',
            onClick: () => {
              setSortBy(LeadsSortByKeys.FirstName);
            },
          }),
          Map({
            id: 'lastName',
            label: 'Last Name',
            onClick: () => {
              setSortBy(LeadsSortByKeys.LastName);
            },
          }),
          Map({
            id: 'status_custom-position',
            label: 'Status',
            onClick: () => {
              setSortBy(LeadsSortByKeys.Status);
            },
          }),
          Map({
            id: 'emailAddress',
            label: 'Email',
            onClick: () => {
              setSortBy(LeadsSortByKeys.Email);
            },
          }),
          Map({
            id: 'mainContactFullName',
            label: 'Main Contact',
            onClick: () => {
              setSortBy(LeadsSortByKeys.MainContact);
            },
          }),
          Map({
            id: 'createdOn',
            label: 'Date Added',
            onClick: () => {
              setSortBy(LeadsSortByKeys.DateAdded);
            },
          }),
          Map({
            id: 'lastContactedOn',
            label: 'Last Contacted',
            onClick: () => {
              setSortBy(LeadsSortByKeys.LastContacted);
            },
          }),
        ]),
      }),
    ]);

    return menuSections;
  };

  return (
    <div className={classes.sortContainer}>
      <Tooltip title='Sort By'>
        <span>
          <Button
            id='sortByButton'
            disabled={isLoading}
            endIcon={<ArrowDropDown />}
            onClick={(e: any) => {
              setSortByAnchorEl(e.currentTarget);
            }}
          >
            {sortByLabel}
          </Button>
        </span>
      </Tooltip>

      <Tooltip title='Sort Direction'>
        <span>
          <IconButton
            id='sortDirectionButton'
            disabled={isLoading}
            onClick={() =>
              setSortDirection(
                sortDirection === LeadsSortByDirections.Asc
                  ? LeadsSortByDirections.Desc
                  : LeadsSortByDirections.Asc
              )
            }
          >
            <ArrowUpward
              className={
                sortDirection === 'asc'
                  ? classes.arrowUpward
                  : classes.arrowDownward
              }
            />
          </IconButton>
        </span>
      </Tooltip>

      <MegaMenu
        // @ts-expect-error ts-migrate(2322) FIXME: Type '{ id: string; anchorEl: null; handleClose: (... Remove this comment to see the full error message
        id='sortByMenu'
        anchorEl={sortByAnchorEl}
        handleClose={() => {
          setSortByAnchorEl(null);
        }}
        menuSections={getSortByOptionsMenu()}
      />
    </div>
  );
}

const useStyles = makeStyles(theme => {
  return {
    sortContainer: {
      marginRight: 8,
    },
    arrowDownward: {
      transform: 'rotate(180deg)',
      transition: '0.25s ease',
    },
    arrowUpward: {
      transition: '0.25s ease',
    },
  };
});
