import { all } from 'redux-saga/effects';

// sagas
import createGroupValueSaga from './createGroupValueSaga';
import deleteGroupValueSaga from './deleteGroupValueSaga';
import fetchGroupValuesSaga from './fetchGroupValuesSaga';

const groupValueSaga = function* rootSaga() {
  yield all([
    createGroupValueSaga(),
    deleteGroupValueSaga(),
    fetchGroupValuesSaga(),
  ]);
};

export default groupValueSaga;
