import { Map } from 'immutable';

// constants
import {
  THREAD_FETCH,
  THREAD_START_LISTEN,
  THREAD_STOP_LISTEN,
  THREAD_CLEAR,
} from 'store/constants/threadTypes';

// helpers
import httpReducer from './helpers/httpReducer';

export default function reduceThread(state = Map(), action = {}) {
  switch ((action as any).type) {
    case THREAD_FETCH:
      return httpReducer(state, action, { appendResult: true });
    case THREAD_START_LISTEN:
    case THREAD_STOP_LISTEN:
      return httpReducer(state, action);
    case THREAD_CLEAR:
      return Map();
    default:
      return state;
  }
}
