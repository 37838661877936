import { memoize } from 'lodash';
import { Map, List } from 'immutable';
import { createSelector } from 'reselect';
import getLoadingFromState from 'store/selectors/getLoadingFromState';
import getNextPageFromState from 'store/selectors/getNextPageFromState';
import getTotal from 'store/selectors/getTotal';

const modify = memoize(
  (
    loading,
    page,
    total,
    dataMap
  ): {
    loading: boolean;
    page: number;
    total: number;
    data: any[];
  } => ({
    loading,
    page,
    total,
    data: Object.values(dataMap.toJS()) || [],
  }),
  (loading, page, total, dataMap) => [loading, dataMap].join('')
);

const getSelected = (
  state: any,
  slice: string,
  metaId: string | null,
  initLoading: boolean,
  resultKey: null | string
): {
  loading: boolean;
  page: number;
  total: number;
  dataMap: any;
} => {
  // gets items from state slice in the order that they're sorted in the result key
  const dataList = state.getIn([slice, 'data', resultKey]); //when result = undefined we will have error and ([slice, 'data', resultKey], List()) doesn't help
  const dataMap = (dataList || List()).map((id: number) =>
    state.getIn([slice, 'data', 'byId', id?.toString()], Map())
  );

  return {
    loading: getLoadingFromState(slice, metaId, initLoading)(state),
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
    page: getNextPageFromState(slice)(state),
    total: getTotal(slice)(state),
    dataMap: dataMap,
  };
};

export default function getSliceState(
  slice: string,
  metaId: null | string = null,
  initLoading = true,
  resultKey: null | string = 'result'
) {
  return createSelector(
    state => getSelected(state, slice, metaId, initLoading, resultKey),
    selected =>
      modify(selected.loading, selected.page, selected.total, selected.dataMap)
  );
}
