import React, { useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { isEqual } from 'lodash';
import { Moment } from 'moment';

// MUI components
import {
  Grid,
  Card,
  CardContent,
  WithStyles,
  withStyles,
} from '@material-ui/core';
import {
  Ballot,
  Call,
  Delete,
  Email,
  GroupAdd,
  NoteAdd,
  Sms,
  SwapVert,
  Timeline,
} from '@material-ui/icons';

// components
import ActivityOverview from 'components/ActivityOverview';
import AnalyticsActionBar from 'components/AnalyticsActionBar';

// action creators
import { createGroupActivityReport } from 'store/actions/apiTaskActions';

// selectors
import getCurrentGroupId from 'store/selectors/getCurrentGroupId';
import getForm from 'store/selectors/getForm';
import getSliceEntityById from 'store/selectors/getSliceEntityById';
import getPermission from 'store/selectors/getPermission';
import getIsCurrentGroupCouncil from 'store/selectors/getIsCurrentGroupCouncil';
import getIsCurrentGroupOrganization from 'store/selectors/getIsCurrentGroupOrganization';
import getIsHqAdmin from 'store/selectors/getIsHqAdmin';

// styles
import groupActivityCardStyle from 'routes/GroupAnalytics/components/GroupActivityCard/groupActivityCard.style';

const REPORT_FORM = 'groupActivityReportForm';

const icons = [
  <NoteAdd />,
  <SwapVert />,
  <Sms />,
  <Email />,
  <Call />,
  <GroupAdd />,
  <Delete />,
  <Ballot />,
];

export interface GroupActivityCardProps
  extends WithStyles<typeof groupActivityCardStyle> {}

const GroupActivityCard = ({ classes }: GroupActivityCardProps) => {
  const dispatch = useDispatch();

  const {
    currentGroupId,
    isHqAdmin,
    report: {
      data: { content: contentArray = [], meta: { columns = [] } = {} } = {},
      id: reportId,
    } = {},
    form: { isSubmitting },
    canGetGroupActivityReport,
    isCurrentGroupCouncil,
    isCurrentGroupOrganization,
  } = useSelector(
    state => ({
      currentGroupId: getCurrentGroupId(state),
      isHqAdmin: getIsHqAdmin(state),
      form: getForm(REPORT_FORM)(state),
      report: getSliceEntityById(
        'report',
        'ReportTypes.GROUP_ACTIVITY',
        {}
      )(state),
      canGetGroupActivityReport: getPermission('report.groupActivity.getOne')(
        state
      ),
      isCurrentGroupCouncil: getIsCurrentGroupCouncil(state),
      isCurrentGroupOrganization: getIsCurrentGroupOrganization(state),
    }),
    isEqual
  );

  const content = contentArray[0];

  const dispatchCreateReport = useCallback(
    ({
      startDate,
      endDate,
      forceRefresh = false,
    }: {
      startDate?: Moment;
      endDate?: Moment;
      forceRefresh?: boolean;
    }) => {
      dispatch(
        createGroupActivityReport({
          groupId: currentGroupId,
          formName: REPORT_FORM,
          startDate,
          endDate,
          forceRefresh,
        })
      );
    },
    [currentGroupId, dispatch]
  );

  const titleText = useMemo<string>(() => {
    const suffix = 'Activity';

    if (isCurrentGroupCouncil) {
      return `Community ${suffix}`;
    }

    if (isCurrentGroupOrganization) {
      return `Organization ${suffix}`;
    }

    return `Group ${suffix}`;
  }, [isCurrentGroupCouncil, isCurrentGroupOrganization]);

  const subTitleText = useMemo<string>(() => {
    if (isCurrentGroupCouncil) {
      return 'community';
    }

    if (isCurrentGroupOrganization) {
      return 'organization';
    }

    return 'member';
  }, [isCurrentGroupCouncil, isCurrentGroupOrganization]);

  if (!canGetGroupActivityReport) {
    return null;
  }

  const formattedColumns = icons.map((icon, index) => ({
    icon,
    ...columns[index],
  }));

  return (
    <Card className={classes.container}>
      <CardContent className={classes.cardContent}>
        <Grid container spacing={1}>
          <AnalyticsActionBar
            onCreateReport={dispatchCreateReport}
            title={titleText}
            subtitle={`View ${
              formattedColumns?.length ? formattedColumns?.length : ''
            } metrics of ${subTitleText} activity by date`}
            icon={Timeline}
            isHqAdmin={isHqAdmin}
            loading={isSubmitting}
            reportId={reportId}
            isHqReport
          />
          <Grid item xs={12} className={classes.overviewContainer}>
            <ActivityOverview
              data={content}
              columns={formattedColumns}
              loading={isSubmitting}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default withStyles(groupActivityCardStyle, { withTheme: true })(
  GroupActivityCard
);
