import React, { useEffect } from 'react';
import validate from 'helpers/validate';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
// action creators
import { registerUser } from 'store/actions/userActions';
import { destroyForm } from 'store/actions/formActions';
// components
import { Form, Field } from 'react-final-form';
import LandingTextField from 'layout/Landing/components/LandingTextField';
import SignupFormActions from './SignupFormActions';
// selectors
import { useReduxForm } from 'store/hooks/useReduxForm';
// material-ui
import {
  FormHelperText,
  Grid,
  InputAdornment,
  makeStyles,
  useMediaQuery,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import EmailIcon from '@material-ui/icons/Email';
import LockIcon from '@material-ui/icons/Lock';
// schemas
const schema = {
  firstName: {
    presence: true,
    length: { maximum: 255 },
  },
  lastName: {
    presence: true,
    length: { maximum: 255 },
  },
  email: {
    presence: true,
    email: { message: 'not valid' },
    length: { maximum: 255 },
  },
  password: {
    presence: true,
    containsUppercase: true,
    containsLowercase: true,
    containsNumber: true,
    length: {
      minimum: 8,
      maximum: 255,
    },
  },
  passwordConfirmation: {
    presence: true,
    equality: 'password',
    length: { maximum: 255 },
  },
};
const FORM = 'signupForm';
const SignupForm = function() {
  const theme = useTheme();
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const widthSmallAndUp = useMediaQuery(theme.breakpoints.up('sm'));
  const {
    hasSubmitFailed,
    hasSubmitSucceeded,
    isSubmitting,
    formError,
    successMessage,
  } = useReduxForm(FORM);
  const queryString = history.location.search;
  const urlParams = new URLSearchParams(queryString);
  const emailAddress = urlParams.get('emailAddress');
  const alreadyRegistered = 'Email already registered';
  useEffect(() => {
    if (formError.get('message') === alreadyRegistered) {
      const params = {
        pathname: '/login',
      };
      if (emailAddress) {
        (params as any).search = `?emailAddress=${emailAddress}`;
      }
      setTimeout(() => {
        dispatch(destroyForm(FORM)); // destroys form: otherwise user won't be able to return to sign up page
        history.push(params); // redirects to login
      }, 4000);
    }
  }, [dispatch, emailAddress, formError, history]);
  const onSubmit = (values: any) => {
    dispatch(registerUser(values));
  };
  const getInitialValues = () => {
    if (emailAddress) {
      return {
        // Adds in + for empty spaces so that email validation still works with alias emails
        email: emailAddress.split(' ').join('+'),
      };
    }
    return {};
  };
  const getFormMessage = () => {
    let message = formError.get('message') || successMessage;
    if (formError.get('message') === alreadyRegistered) {
      message = `${message} - redirecting to log in`;
    }
    return message;
  };
  return (
    <Form
      onSubmit={onSubmit}
      initialValues={getInitialValues()}
      validate={values => validate(values, schema)}
      render={({ handleSubmit, invalid }) => (
        <form onSubmit={handleSubmit} className={classes.form}>
          <Grid container direction='column' spacing={6}>
            <Grid item>
              <Grid id='nameFields' container spacing={widthSmallAndUp ? 3 : 0}>
                <Grid item xs={12} sm={6}>
                  <Field
                    name='firstName'
                    // @ts-expect-error ts-migrate(2322) FIXME: Type 'ComponentType<Pick<InferProps<any>, string |... Remove this comment to see the full error message
                    component={LandingTextField}
                    label='First Name'
                    autoFocus
                    required
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Field
                    name='lastName'
                    // @ts-expect-error ts-migrate(2322) FIXME: Type 'ComponentType<Pick<InferProps<any>, string |... Remove this comment to see the full error message
                    component={LandingTextField}
                    label='Last Name'
                    required
                  />
                </Grid>
              </Grid>

              <Field
                name='email'
                // @ts-expect-error ts-migrate(2322) FIXME: Type 'ComponentType<Pick<InferProps<any>, string |... Remove this comment to see the full error message
                component={LandingTextField}
                label='Email Address'
                disabled={Boolean(emailAddress)}
                helperText={
                  Boolean(emailAddress)
                    ? 'Already have an account? Log in and add this email address to your account settings in order to accept the invite.'
                    : ''
                }
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <EmailIcon />
                    </InputAdornment>
                  ),
                }}
                required
              />
              <Field
                name='password'
                // @ts-expect-error ts-migrate(2322) FIXME: Type 'ComponentType<Pick<InferProps<any>, string |... Remove this comment to see the full error message
                component={LandingTextField}
                label='Password'
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <LockIcon />
                    </InputAdornment>
                  ),
                }}
                type='password'
                required
              />
              <Field
                name='passwordConfirmation'
                // @ts-expect-error ts-migrate(2322) FIXME: Type 'ComponentType<Pick<InferProps<any>, string |... Remove this comment to see the full error message
                component={LandingTextField}
                label='Confirm Password'
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <LockIcon />
                    </InputAdornment>
                  ),
                }}
                type='password'
                required
              />

              {(formError || successMessage) && (
                <FormHelperText
                  id='formMessage'
                  error={Boolean(formError || successMessage)}
                >
                  {getFormMessage()}
                </FormHelperText>
              )}
            </Grid>

            <SignupFormActions
              isSubmitting={isSubmitting}
              hasSubmitFailed={hasSubmitFailed}
              hasSubmitSucceeded={hasSubmitSucceeded}
              invalid={invalid}
            />
          </Grid>
        </form>
      )}
    />
  );
};
const useStyles = makeStyles(theme => ({
  form: {
    maxWidth: '500px',
    [theme.breakpoints.up('sm')]: {
      padding: 24,
    },
  },
}));
export default SignupForm;
