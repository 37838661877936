import Immutable, { List, Map } from 'immutable';

/*
 * @param {Immutable.Map} state - The original application state
 * @param {object} action
 * @param {Immutable.Map} action.payload
 * @param {Immutable.Map} action.meta
 * @param {boolean} action.error - A flag used to show if an action was triggered by an error
 * @param {object} options - Some extra options to pass to the reducer
 * @param {string} options.entity - An optional entity name to use, the default entity is the one used by the Normalizr schema
 * @param {function} options.callback - A reducer that accepts (state, action)
 * @param {string} options.result - An optional custom result key to store the incoming result as
 * @param {boolean} options.updateResult - An optional boolean to tell the reducer to update the result or not
 * @param {boolean} options.appendResult - An optional boolean to tell the reducer to append or replace the result when updating
 * @param {boolean} options.updateLoading - An optional boolean to tell the reducer to not update the loading indicator
 */

function constructErrorPayload(payload: any) {
  const {
    // @ts-expect-error ts-migrate(2525) FIXME: Initializer provides no value for this binding ele... Remove this comment to see the full error message
    response: { status, statusText, data: { msg } } = { data: {} },
  } = payload;

  if (!msg) {
    return payload;
  } else {
    return {
      status,
      statusText,
      msg,
    };
  }
}

export default function httpReducer(state = Map(), action = {}, options = {}) {
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'payload' does not exist on type '{}'.
  const { payload, meta = Map(), error = false } = action;

  const {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'appendResult' does not exist on type '{}... Remove this comment to see the full error message
    appendResult = Map.isMap(payload) && payload.get('appendResult', false),
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'callback' does not exist on type '{}'.
    callback,
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'entity' does not exist on type '{}'.
    entity,
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'result' does not exist on type '{}'.
    result: resultKey = 'result',
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'total' does not exist on type '{}'.
    total: totalKey = 'total',
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'paginationKey' does not exist on type '{... Remove this comment to see the full error message
    paginationKey,
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'updateLoading' does not exist on type '{... Remove this comment to see the full error message
    updateLoading = true,
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'updateResult' does not exist on type '{}... Remove this comment to see the full error message
    updateResult = true,
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'updateItemsById' does not exist on type ... Remove this comment to see the full error message
    updateItemsById = true,
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'mergeItems' does not exist on type '{}'.
    mergeItems = true,
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'mergeItems' does not exist on type '{}'.
    disableMergeDeep = false,
  } = options;

  /*
   *  `metaId` is an identifier used to set extra values on
   *  `meta` that are more specific than the entire slice.
   *
   *  This can be used to track the meta data on a single resource object rather
   *  than the entire slice, like keeping track if a single user in a list is being updated.
   */
  const metaId = meta.get('metaId');
  const loading = meta.get('loading', false);
  const status = meta.get('status', false);

  return state.withMutations((map: any) => {
    // Set the loading indicators on the state if `updateLoading` was not set to `false`
    if (updateLoading) {
      map.setIn(['meta', 'loading'], loading);

      if (metaId) {
        map.setIn(['meta', `${metaId}-loading`], loading);
      }
    }

    // Set or remove `meta.status` from the state
    if (status) {
      map.setIn(['meta', 'status'], status);

      if (metaId) {
        map.setIn(['meta', `${metaId}-status`], status);
      }
    } else {
      map.deleteIn(['meta', 'status']);
      map.deleteIn(['meta', `${metaId}-status`]);
    }

    // Set or remove `meta.error` from the state
    if (error) {
      const errorPayload = constructErrorPayload(payload);

      // Actions with `error` set to true should have an error object set to the `payload`
      map.setIn(['meta', 'error'], errorPayload);

      if (metaId) {
        map.setIn(['meta', `${metaId}-error`], errorPayload);
      }
    } else {
      map.deleteIn(['meta', 'error']);
      map.deleteIn(['meta', `${metaId}-error`]);
    }

    // If the payload is defined, process it
    if (
      typeof payload !== 'undefined' &&
      !error &&
      (Map.isMap(payload) || List.isList(payload))
    ) {
      if (callback) {
        // Offload the rest of the reducer to a callback if one was provided
        callback(map, action, options);
        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
      } else if (payload.get('data') && payload.get('normalized')) {
        const oldResult = map.getIn(['data', resultKey], List());
        let newResult: any = payload.getIn(['data', 'result'], List());

        // Handle results from create actions that return a single value
        if (newResult && !Array.isArray(newResult) && !List.isList(newResult)) {
          newResult = oldResult.push(newResult);
        }

        // Set the result on the state if `updateResult` is `true`
        if (updateResult && !appendResult) {
          // Replace the result if `appendResult` is `false
          map.setIn(['data', resultKey], newResult);

          // We add any item to the standard `result` list regardless of the custom key
          if (resultKey !== 'result') {
            map.setIn(['data', 'result'], newResult);
          }
        } else if (updateResult && appendResult) {
          // Append to the result if `appendResult` is `true`
          const appendedResult = oldResult.concat(
            newResult.toOrderedSet().subtract(oldResult.toOrderedSet())
          );

          map.setIn(['data', resultKey], appendedResult);

          // We add any item to the standard `result` list regardless of the custom key
          if (resultKey !== 'result') {
            map.setIn(
              ['data', resultKey],
              oldResult
                .toOrderedSet()
                .union(newResult.toOrderedSet())
                .toList()
            );
          }
        }

        // Get the new items from the payload using the given entity, or defaulting to the
        // entity used by the schema
        if (updateItemsById) {
          const itemsById = payload.getIn(
            // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
            ['data', 'entities', entity || payload.get('entity')],
            Map()
          );

          if (mergeItems) {
            // Merge the new items with the states original `byId` map
            map.setIn(
              ['data', 'byId'],
              disableMergeDeep
                ? map.getIn(['data', 'byId'], Map()).merge(itemsById)
                : map.getIn(['data', 'byId'], Map()).mergeDeep(itemsById)
            );
          } else {
            // Merge the new items with the states original `byId` map
            map.setIn(['data', 'byId'], itemsById);
          }
        }
      } else {
        // Set `data` on the state to `payload.data` unless it is undefined, then use `payload`
        if (mergeItems) {
          map.mergeDeep(
            // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
            Map({ data: Immutable.fromJS(payload.get('data') || payload) })
          );
        } else {
          // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
          map.set('data', Immutable.fromJS(payload.get('data') || payload));
        }
      }

      // Set previous and next for pagination, only if they are on the payload
      //  This is because only `GET` method requests have pagination info on them currently
      const previousKey =
        paginationKey && paginationKey !== 'total'
          ? `${paginationKey}-previous`
          : 'previous';
      const nextKey =
        paginationKey && paginationKey !== 'total'
          ? `${paginationKey}-next`
          : 'next';

      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
      if (payload.has('previous') || payload.has('next')) {
        // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 2.
        map.setIn(['meta', previousKey], payload.get('previous', null));
        // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 2.
        map.setIn(['meta', nextKey], payload.get('next', null));
      }
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
      if (payload.has('hasNext') || payload.has('hasPrevious')) {
        // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 2.
        map.setIn(['meta', 'hasNext'], payload.get('hasNext', null));
        // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 2.
        map.setIn(['meta', 'hasPrevious'], payload.get('hasPrevious', null));
      }

      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
      if (payload.has('total')) {
        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
        map.setIn(['meta', totalKey], payload.get('total'));
      }
    }
  });
}
