import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {
  AUTH_INTEGRATION_UPDATE,
  AUTH_INTEGRATION_UPDATE_REQUEST,
} from 'store/constants/authIntegrationTypes';
import { authIntegrationSchema } from 'store/schemas/authIntegrationSchema';
import httpSaga from '../httpSaga';
import client from 'sources/api';
import { doneIndicator } from 'store/actions/httpActions';

const WATCH_TYPE = AUTH_INTEGRATION_UPDATE_REQUEST;
const TYPE = AUTH_INTEGRATION_UPDATE;

export function* updateAuthIntegration({ payload }: any) {
  // @ts-expect-error ts-migrate(2569) FIXME: Type 'Generator<any, any, unknown>' is not an arra... Remove this comment to see the full error message
  yield* httpSaga(
    TYPE,
    call(client.post, `/auth_integrations/${payload.id}`, { ...payload }),
    { schema: authIntegrationSchema }
  );

  yield put(doneIndicator(TYPE));
}

export function* watch() {
  yield takeEvery(WATCH_TYPE, updateAuthIntegration);
}

export default function* root() {
  yield all([fork(watch)]);
}
