import { Styles } from 'assets/types';
import fieldWithTypeStyles from 'routes/ReferralForm/FormView/components/FieldWithType/fieldWithType.style';

const fieldWithTypeMultiSelectStyles: Styles = theme => ({
  ...fieldWithTypeStyles(theme),
  headerBoxClasses: {
    fontWeight: 'bold',
    fontSize: '1.1em',
    paddingBottom: 15,
    '& button': {
      fontSize: 'inherit',
      marginLeft: 5,
      padding: 0,
    },
    '& svg': {
      fontSize: 'inherit',
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop: 25,
      '&.firstHeader': {
        paddingTop: 0,
      },
    },
  },
  dazzlingDialogTextClasses: {
    fontSize: '1.2em',
    paddingTop: 0,
    marginTop: 0,
  },
});

export default fieldWithTypeMultiSelectStyles;
