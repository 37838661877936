import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

// action creators
import { doneIndicator } from 'store/actions/httpActions';

// api
import client from 'sources/api';

// constants
import { REQUEST_FETCH_FOR_USER_REQUEST } from 'store/constants/requestTypes';
import { GROUP_FETCH } from 'store/constants/groupTypes';

// helpers
import httpSaga from 'store/sagas/httpSaga';
import getBaseType from '../helpers/getBaseType';
import isNamesListSite from 'helpers/isNamesListSite';
import { toString } from 'helpers/transform';

// schema
import { requestSchema } from 'store/schemas/requestSchema';
import { groupSchema } from 'store/schemas/groupSchema';

const WATCH_TYPE = REQUEST_FETCH_FOR_USER_REQUEST;

export function* fetchRequestsForUserSaga(action: any) {
  const TYPE = getBaseType(action.type);

  try {
    // @ts-expect-error ts-migrate(2569) FIXME: Type 'Generator<any, any, unknown>' is not an arra... Remove this comment to see the full error message
    const response = yield* httpSaga(TYPE, call(client.get, '/requests'), {
      schema: [requestSchema],
    });

    // Fetch the groups that the user's requests are for
    const groupIds = response.data.results.map((result: any) => result.groupId);

    // Format the params by appending multiple values for the same key
    //  In this case `?id=1&id=2&id=3` so that we can get multiple items
    let groupParams = new URLSearchParams();
    groupParams.set('is_nameslist', toString(isNamesListSite()));
    groupIds.forEach((id: any) => groupParams.append('id', id));

    // @ts-expect-error ts-migrate(2569) FIXME: Type 'Generator<any, any, unknown>' is not an arra... Remove this comment to see the full error message
    yield* httpSaga(
      GROUP_FETCH,
      call(client.get, '/groups', { params: groupParams }),
      {
        schema: [groupSchema],
      }
    );
  } finally {
    yield put(doneIndicator(TYPE));
  }
}

export function* watch() {
  yield takeEvery(WATCH_TYPE, fetchRequestsForUserSaga);
}

export default function* root() {
  yield all([fork(watch)]);
}
