import { all, call, fork, put, takeEvery, select } from 'redux-saga/effects';
import Immutable from 'immutable';

// action creators
import { doneIndicator, success } from 'store/actions/httpActions';

// selector
import getCurrentGroupId from 'store/selectors/getCurrentGroupId';

// api
import client from 'sources/api';

// constants
import {
  PHONE_NUMBER_DELETE_REQUEST,
  PHONE_NUMBER_DELETE,
} from 'store/constants/phoneNumberTypes';

// schemas
import { phoneNumberSchema } from 'store/schemas/phoneNumberSchema';

// helpers
import httpSaga from 'store/sagas/httpSaga';
import getResponsePayload from 'helpers/getResponsePayload';

const WATCH_TYPE = PHONE_NUMBER_DELETE_REQUEST;
const TYPE = PHONE_NUMBER_DELETE;

export function* deletePhoneNumberSaga(action: any) {
  const {
    payload: { phoneNumberId },
  } = action;

  const META_ID = `delete-${phoneNumberId}`;

  const currentGroupId = yield select(getCurrentGroupId);

  // @ts-expect-error ts-migrate(2569) FIXME: Type 'Generator<any, any, unknown>' is not an arra... Remove this comment to see the full error message
  const response = yield* httpSaga(
    TYPE,
    call(
      client.delete,
      `/phone_numbers/${phoneNumberId}?group_id=${currentGroupId}`
    ),
    {
      dispatchSuccess: false,
      schema: phoneNumberSchema,
      metaId: META_ID,
    }
  );
  if (response) {
    const successResponse = { data: { id: phoneNumberId } };
    const payload = getResponsePayload(successResponse, phoneNumberSchema);

    yield put(success(TYPE, Immutable.fromJS(payload), META_ID));
  }

  yield put(doneIndicator(TYPE));
}

export function* watch() {
  yield takeEvery(WATCH_TYPE, deletePhoneNumberSaga);
}

export default function* root() {
  yield all([fork(watch)]);
}
