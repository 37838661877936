import {
  FORM_DESTROY,
  FORM_START_SUBMIT,
  FORM_STOP_SUBMIT,
  FORM_SET_SUBMIT_FAILED,
  FORM_SET_SUBMIT_SUCCEEDED,
} from 'store/constants/formTypes';

export function destroyForm(formName: any) {
  return {
    type: FORM_DESTROY,
    payload: { formName },
  };
}

export function startFormSubmit(formName: any) {
  return {
    type: FORM_START_SUBMIT,
    payload: { formName },
  };
}

export function stopFormSubmit(formName: any) {
  return {
    type: FORM_STOP_SUBMIT,
    payload: { formName },
  };
}

export function setFormSubmitFailed(formName: any, err: any) {
  const errorResponse = (err || {}).response;

  return {
    type: FORM_SET_SUBMIT_FAILED,
    payload: { formName, error: errorResponse || err },
  };
}

export function setFormSubmitSucceeded(formName: any, msg = '') {
  return {
    type: FORM_SET_SUBMIT_SUCCEEDED,
    payload: { formName, msg },
  };
}
