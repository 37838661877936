import React, { useState, useEffect } from 'react';
import Immutable, { Map } from 'immutable';
import validate from 'validate.js';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { isEqual } from 'lodash';

// selectors and custom hooks
import { useReduxForm } from 'store/hooks/useReduxForm';
import getLoadingFromState from 'store/selectors/getLoadingFromState';
import getGroupFields from 'store/selectors/getGroupFields';
import getCurrentGroupId from 'store/selectors/getCurrentGroupId';

// material-ui
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import FormHelperText from '@material-ui/core/FormHelperText';
import Skeleton from '@material-ui/lab/Skeleton';
import Typography from '@material-ui/core/Typography';

// action creators
import {
  deleteGroupValue,
  fetchGroupValues,
} from 'store/actions/groupValueActions';
import {
  fetchGroupFields,
  updateGroupFields,
} from 'store/actions/groupFieldActions';
import { destroyForm } from 'store/actions/formActions';

// icons
import ValuesIcon from '@material-ui/icons/Star';
import AddCircleIcon from '@material-ui/icons/AddCircle';

// components
import { Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';

import ActionBar from 'components/ActionBar';
import Button from 'components/Button';
import DraggableList from 'components/DraggableList';
import FieldItem from 'components/FieldItem';
import Header from 'components/Header';
import CreateValueModal from './CreateValueModal';

// schemas
const schema = {
  visibility: {
    presence: true,
  },
};

const FORM = 'valuesForm';

const ValuesSettings = function() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [modalOpen, setModalOpen] = useState(false);
  const [labelToDelete, setLabelToDelete] = useState(null);
  const metaId = `delete-${labelToDelete}`;

  const {
    hasSubmitFailed,
    hasSubmitSucceeded,
    isSubmitting,
    formError,
  } = useReduxForm(FORM);

  const {
    deleteLoading,
    groupFieldsLoading,
    groupId,
    groupValueFields,
    groupValuesLoading,
  } = useSelector(
    state => ({
      groupFieldsLoading: getLoadingFromState(
        'groupField',
        false,
        false
      )(state),
      groupId: getCurrentGroupId(state),
      deleteLoading: getLoadingFromState('groupValue', metaId, false)(state),
      groupValueFields: getGroupFields(
        ['GroupValue'],
        'default',
        'valuesResult'
      )(state),
      groupValuesLoading: getLoadingFromState('groupValue')(state),
    }),
    shallowEqual
  );

  const loading = groupFieldsLoading || groupValuesLoading;

  useEffect(() => {
    dispatch(fetchGroupFields({ groupId, resultKey: 'valuesResult' }));
    dispatch(fetchGroupValues({ groupId }));
  }, [dispatch, groupId]);

  const onSubmit = (values: any) => {
    if (values.groupValueFields) {
      // prevents case where values is an event object
      dispatch(
        updateGroupFields({
          formName: FORM,
          groupFields: values.groupValueFields,
        })
      );
    }
  };

  const getInitialValues = () => {
    return { groupValueFields: groupValueFields.toJS() };
  };

  const validateArray = (items: any) => {
    return items.map((item: any) => validate(item, schema));
  };

  const handleDelete = (groupField: any) => {
    const groupValue: any = groupValueFields.find(
      (groupValue: any) => groupValue.get('label') === groupField.get('label'),
      {}, // context
      Map() // not set value
    );

    setLabelToDelete(groupValue.get('label'));

    dispatch(deleteGroupValue({ label: groupValue.get('label'), groupId }));
  };

  const handleActionBarClose = (event: any, reason: any, reset: any) => {
    // sets values back to pristine
    if (reason === 'timeout') {
      reset();
      dispatch(destroyForm(FORM));
    }
  };

  return (
    <Grid item xs={12}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Card id='tabContent' className={classes.topCard}>
            <CardContent className={classes.cardContent}>
              <Grid container spacing={4} alignItems='flex-start'>
                <Grid item>
                  <Header
                    id='header'
                    icon={ValuesIcon}
                    title='Values'
                    subtitle='Customize the order and visibility of the values that display as options on forms 
                      and the "Endorsements" tab of the lead profile'
                    highlightedSubtitle={`Click & drag items to rearrange display order`}
                  />
                </Grid>

                <Hidden smUp>
                  <Grid item xs={3} className={classes.buttonContainer}>
                    <Button
                      id='create-value'
                      color='primary'
                      className={classes.button}
                      fabIcon={<AddCircleIcon />}
                      variant='contained'
                      disabled={deleteLoading}
                      onClick={() => setModalOpen(true)}
                      startIcon={<AddCircleIcon />}
                    >
                      New Value
                    </Button>
                  </Grid>
                </Hidden>

                {formError.get('message') && (
                  <Grid item xs={12}>
                    <FormHelperText error id='errorMessage'>
                      {formError.get('message')}
                    </FormHelperText>
                  </Grid>
                )}
              </Grid>

              {loading && groupValueFields.size === 0 && (
                <Grid
                  container
                  id='skeletonContainer'
                  spacing={2}
                  justifyContent='center'
                  alignContent='center'
                >
                  <Grid item xs={12}>
                    <Skeleton variant='rect' height={50} />
                  </Grid>
                  <Grid item xs={12}>
                    <Skeleton variant='rect' height={50} />
                  </Grid>
                  <Grid item xs={12}>
                    <Skeleton variant='rect' height={50} />
                  </Grid>
                </Grid>
              )}

              {!loading && groupValueFields.size === 0 && (
                <Grid
                  container
                  id='noneFoundContainer'
                  className={classes.noneFoundContainer}
                >
                  <Grid item xs={12}>
                    <Typography variant='body1'>No values found</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant='body2' color='textSecondary'>
                      Create values by clicking the button in the top left of
                      your screen.
                    </Typography>
                  </Grid>
                </Grid>
              )}

              {groupValueFields.size > 0 && (
                <Form
                  onSubmit={onSubmit}
                  mutators={{ ...arrayMutators }}
                  validate={values => validateArray(values.groupValueFields)}
                  initialValues={getInitialValues()}
                  initialValuesEqual={isEqual}
                  keepDirtyOnReinitialize={isSubmitting} // prevents items from reinitializing
                  render={({
                    handleSubmit,
                    values = [],
                    invalid,
                    pristine,
                    form: { reset },
                  }) => (
                    <Grid
                      container
                      id='form'
                      component='form'
                      onSubmit={handleSubmit}
                      spacing={2}
                    >
                      <Grid item xs={12}>
                        <FieldArray name='groupValueFields'>
                          {({ fields }) => (
                            <DraggableList
                              initialItems={groupValueFields}
                              key='list'
                              fields={fields}
                              itemComponent={
                                <FieldItem
                                  items={Immutable.fromJS(
                                    values.groupValueFields
                                  )}
                                  onDelete={handleDelete}
                                  labelToDelete={labelToDelete}
                                  label='Value'
                                />
                              }
                            />
                          )}
                        </FieldArray>
                      </Grid>

                      <ActionBar
                        open={!pristine}
                        message='Save your changes?'
                        id='actionBar'
                        onClose={(event: any, reason: any) =>
                          handleActionBarClose(event, reason, reset)
                        }
                        // @ts-expect-error ts-migrate(2322) FIXME: Type 'number | null' is not assignable to type 'nu... Remove this comment to see the full error message
                        autoHideDuration={hasSubmitSucceeded ? 1500 : null}
                      >
                        <Button
                          disabled={pristine || isSubmitting}
                          onClick={reset}
                        >
                          Cancel
                        </Button>

                        <Button
                          variant='outlined'
                          color='primary'
                          type='submit'
                          onClick={onSubmit}
                          disabled={pristine || invalid || isSubmitting}
                          loading={isSubmitting}
                          success={hasSubmitSucceeded}
                          fail={hasSubmitFailed}
                        >
                          Save
                        </Button>
                      </ActionBar>
                    </Grid>
                  )}
                />
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <CreateValueModal open={modalOpen} onClose={() => setModalOpen(false)} />
    </Grid>
  );
};

const useStyles = makeStyles(theme => ({
  cardContent: {
    marginBottom: 55, // prevents save bar from covering last item on mobile
  },
  buttonContainer: {
    textAlign: 'right',
    marginTop: 10,
  },

  button: {
    marginRight: 8,
  },
  noneFoundContainer: {
    [theme.breakpoints.up('sm')]: {
      marginLeft: 90,
    },
  },
  topCard: {
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
  },
}));

export default ValuesSettings;
