import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import validate from 'validate.js';
import { isEqual } from 'lodash';

// hooks
import { useDispatch, useSelector } from 'react-redux';
import { useLeadsInfiniteQuery } from 'api/leads';
import useDebounce from 'helpers/hooks/useDebounce';
import useInfiniteScroll from 'helpers/hooks/useInfiniteScroll';

// material-ui
import { Grid, Hidden, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

// icons
import { DoneAll } from '@material-ui/icons';

//components
import DazzlingDialog from 'components/DazzlingDialog';
import TextField from 'components/TextField';
import DateSelector from 'components/DateSelector';
import MultiAutocomplete from 'components/MultiAutocomplete';
import { Form, Field } from 'react-final-form';

// actions
import { createTask } from 'store/actions/taskActions';
import { fetchMembersForGroup } from 'store/actions/memberActions';

// selectors
import getMembers from 'store/selectors/getMembers';
import getCurrentGroupId from 'store/selectors/getCurrentGroupId';
import getLoadingFromState from 'store/selectors/getLoadingFromState';
import getNextPageUrlFromState from 'store/selectors/getNextPageUrlFromState';

const FORM = 'createTask';
const SCHEMA = {
  title: {
    presence: true,
    length: { maximum: 100 },
  },
  description: {
    length: { maximum: 500 },
  },
  dueDate: {
    presence: true,
  },
  dueTime: {
    presence: true,
  },
  selectedMembers: {
    presence: true,
    length: { minimum: 1, message: 'Select at least one member' },
  },
};
const DIALOG_DATA = {
  icon: DoneAll,
  title: 'New Task',
  subtitle: 'Describe your task, assign it to members, and tag it to leads',
  highlightedSubtitle: 'Assigned members will be notified',
};

type Props = {
  open: boolean;
  onClose: () => void;
};

const CreateTaskModal = memo(({ open, onClose }: Props) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const [membersSearchValue, setMembersSearchValue] = useState('');
  const debouncedMembersSearchValue = useDebounce(membersSearchValue, 600);

  const [leadSearchValue, setLeadSearchValue] = useState('');
  const [leadSelectorOpen, setLeadSelectorOpen] = useState(false);
  const debouncedLeadSearchValue = useDebounce(leadSearchValue, 600);

  const { groupId, memberLoading, memberSuggestions, nextPage } = useSelector(
    state => ({
      memberSuggestions: getMembers('suggestions')(state),
      groupId: getCurrentGroupId(state),
      memberLoading: getLoadingFromState('member', false, false)(state),
      nextPage: getNextPageUrlFromState('member')(state),
    }),
    isEqual
  );
  const {
    data,
    isLoading: leadsLoading,
    hasNextPage: leadsHasNextPage,
    fetchNextPage,
  } = useLeadsInfiniteQuery(debouncedLeadSearchValue);

  const leads = useMemo(() => (data?.pages ? data?.pages.flat() : []), [data]);
  const leadOptions = leads.map(lead => ({
    label: lead.fullName,
    value: lead.id,
  }));

  const [scrolledToBottom, scrollRef] = useInfiniteScroll(leadsLoading, 6);

  useEffect(() => {
    if (leadsHasNextPage && scrolledToBottom) fetchNextPage();
  }, [leadsHasNextPage, scrolledToBottom, fetchNextPage]);

  const toggleOpened = useCallback(
    () => setLeadSelectorOpen(leadSelectorOpen => !leadSelectorOpen),
    []
  );

  useEffect(() => {
    dispatch(fetchMembersForGroup({ groupId, debouncedMembersSearchValue }));
  }, [dispatch, groupId, debouncedMembersSearchValue]);

  const urlParams = new URLSearchParams(nextPage);
  const pageNumber = Number(urlParams.get('page'));

  useEffect(() => {
    if (open && pageNumber) {
      // fetch initial suggestions
      dispatch(
        fetchMembersForGroup({
          groupId: groupId,
          appendResult: true,
          page: pageNumber,
        })
      );
    }
  }, [dispatch, groupId, open, pageNumber]);
  const validateTaskFields = useCallback(
    values => validate(values, SCHEMA),
    []
  );
  const handleFormSubmit = useCallback(
    ({
      title,
      description,
      dueDate,
      dueTime,
      selectedMembers = [],
      selectedLeads = [],
    }) => {
      const accountIds = selectedMembers.map((member: any) => member.value);
      const leadIds = selectedLeads.map((lead: any) => lead.value);
      const dueOn = dueDate.set({
        hour: dueTime.get('hour'),
        minute: dueTime.get('minute'),
      });
      dispatch(
        createTask({
          formName: FORM,
          title,
          description,
          dueOn,
          accountIds,
          leadIds,
        })
      );
    },
    [dispatch]
  );

  const onMemberSearchChange = useCallback(
    search => setMembersSearchValue(search),
    []
  );
  const onLeadSearchChange = useCallback(
    search => setLeadSearchValue(search),
    []
  );
  return (
    <Form
      validate={validateTaskFields}
      onSubmit={handleFormSubmit}
      initialValues={{ dueDate: null, dueTime: null }}
      render={({ handleSubmit, invalid, values = {}, form: { restart } }) => {
        return (
          <DazzlingDialog
            acceptLabel='Create'
            formName={FORM}
            alertProps={{ message: 'Task Created Successfully' }}
            handleClose={onClose}
            headerProps={DIALOG_DATA}
            onAccept={handleSubmit}
            disabled={invalid}
            open={open}
            resetForm={restart}
            onExited={restart}
            height='tall'
          >
            <Grid
              container
              spacing={2}
              alignItems='center'
              justifyContent='space-between'
            >
              <Grid item xs={12} sm={6}>
                <Field
                  fullWidth
                  name='title'
                  label='Title'
                  variant='outlined'
                  component={TextField}
                  required
                />
              </Grid>
              <Hidden xsDown>
                <Grid item sm={6} />
              </Hidden>

              <Grid item xs={12} sm={6}>
                <Field
                  name='dueDate'
                  label='Due Date'
                  id='dueDate'
                  disablePast
                  placeholder='mmm dd, yyyy'
                  component={DateSelector}
                  fullWidth
                  allowNull
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  name='dueTime'
                  label='Due Time'
                  id='dueTime'
                  pickerType='time'
                  placeholder='hh:mm (alp)m'
                  component={DateSelector}
                  fullWidth
                  allowNull
                  required
                />
              </Grid>

              <Grid item xs={12}>
                <Field
                  name='description'
                  label='Description'
                  variant='outlined'
                  multiline
                  component={TextField}
                />
              </Grid>

              <Grid item xs={12}>
                <Grid
                  container
                  className={classes.borderedContainer}
                  spacing={3}
                >
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    className={classes.membersContainer}
                  >
                    <Grid className={classes.paddedContainer}>
                      <Field
                        name='selectedMembers'
                        render={props => (
                          <MultiAutocomplete
                            id='members'
                            classes={{ listbox: classes.listBox }}
                            label='Search & Assign Members'
                            readOnly
                            options={memberSuggestions.toJS()}
                            loading={memberLoading}
                            onFetch={onMemberSearchChange}
                            {...props}
                          />
                        )}
                        margin='none'
                        autoFocus
                        required
                      />
                    </Grid>

                    {!values.selectedMembers?.length && (
                      <Typography
                        variant='body2'
                        color='secondary'
                        className={classes.paddedContainer}
                      >
                        No members selected. At least one member is required
                      </Typography>
                    )}
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Grid className={classes.paddedContainer}>
                      <Field
                        name='selectedLeads'
                        render={props => (
                          <MultiAutocomplete
                            id='leads'
                            open={leadSelectorOpen}
                            onOpen={toggleOpened}
                            onClose={toggleOpened}
                            classes={{ listbox: classes.listBox }}
                            label='Search & Assign Leads'
                            readOnly
                            options={leadOptions}
                            loading={leadsLoading}
                            ListboxProps={{
                              role: 'listbox',
                              ref: scrollRef,
                            }}
                            onFetch={onLeadSearchChange}
                            {...props}
                          />
                        )}
                        margin='none'
                        autoFocus
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </DazzlingDialog>
        );
      }}
    />
  );
});

const useStyles = makeStyles(theme => {
  const border = `1px solid ${(theme.palette.background as any).lightPaper}`;
  return {
    borderedContainer: {
      border,
      borderRadius: 4,
      marginTop: 15,
      marginBottom: 20,
    },
    paddedContainer: {
      margin: 8,
    },
    listBox: {
      maxHeight: '300px',
    },
    membersContainer: {
      [theme.breakpoints.up('sm')]: {
        borderRight: border,
      },
    },
  };
});
export default CreateTaskModal;
