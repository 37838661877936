import React, { useMemo, useEffect, memo } from 'react';
import PropTypes from 'prop-types';
import { List } from 'immutable';
import { isEqual } from 'lodash';
import useScrollBottom from 'helpers/hooks/useScrollBottom';

// MUI
import { makeStyles } from '@material-ui/core/styles';

// components
import MegaMenuSection from './components/MegaMenuSection';

// selectors

const MegaMenuList = function({
  handleClose,
  menuSections,
  itemProps,
  handlePagination,
}: any) {
  const classes = useStyles();
  const numSections = useMemo(
    () =>
      menuSections.filter((menuSection: any) => menuSection.get('display'))
        .size,
    [menuSections]
  );
  const [isBottom, scrollRef] = useScrollBottom(0);

  useEffect(() => {
    if (isBottom) {
      handlePagination();
    }
  }, [handlePagination, isBottom]);

  return (
    // @ts-expect-error ts-migrate(2322) FIXME: Type 'boolean | MutableRefObject<null>' is not ass... Remove this comment to see the full error message
    <div ref={scrollRef} className={classes.list}>
      {menuSections.map((menuSection: any, index: number) => (
        <MegaMenuSection
          display={menuSection.get('display')}
          handleClose={handleClose}
          // @ts-expect-error ts-migrate(2322) FIXME: Type '{ display: any; handleClose: any; id: any; i... Remove this comment to see the full error message
          id={menuSection.get('id')}
          items={menuSection.get('items', List())}
          key={menuSection.get('id')}
          numSections={numSections}
          sectionHeader={menuSection.get('sectionHeader')}
          sectionIndex={index}
          itemProps={itemProps}
          autoFocus={index === 0}
        />
      ))}
    </div>
  );
};

MegaMenuList.propTypes = {
  itemProps: PropTypes.instanceOf(Object),
  handleClose: PropTypes.func,
  handlePagination: PropTypes.func,
  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'typeof List' is not assignable t... Remove this comment to see the full error message
  menuSections: PropTypes.instanceOf(List).isRequired,
};

MegaMenuList.defaultProps = {
  anchorEl: null,
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left',
  },
  anchorPosition: { top: 10, left: 10 },
  anchorReference: 'anchorEl',
  handleClose: () => {},
  handlePagination: () => {},
};

const useStyles = makeStyles(theme => ({
  list: {
    overflow: 'auto',
    [theme.breakpoints.up('sm')]: {
      maxHeight: 500,
    },
  },
}));

export default memo(MegaMenuList, isEqual);
