import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

// action creators
import { doneIndicator, success } from 'store/actions/httpActions';

// api
import client from 'sources/api';

// constants
import { GROUP_DELETE_REQUEST } from 'store/constants/groupTypes';

// lib
import httpSaga from 'store/sagas/httpSaga';
import getBaseType from '../helpers/getBaseType';
import getResultKey from './helpers/getResultKey';

import Immutable from 'immutable';

// schema
import { groupSchema } from 'store/schemas/groupSchema';

const WATCH_TYPE = GROUP_DELETE_REQUEST;

export function* deleteGroup(action: any) {
  const { formName, id, type } = action.payload;
  const FORM = formName;
  const TYPE = getBaseType(action.type);

  try {
    // @ts-expect-error ts-migrate(2569) FIXME: Type 'Generator<any, any, unknown>' is not an arra... Remove this comment to see the full error message
    yield* httpSaga(TYPE, call(client.delete, `/groups/${id}`), {
      schema: groupSchema,
      formName: FORM,
      dispatchSuccess: false,
    });

    yield put(
      success(
        TYPE,
        Immutable.fromJS({
          id,
          responseOptions: {
            resultKey: getResultKey(type),
          },
        })
      )
    );
  } finally {
    yield put(doneIndicator(TYPE));
  }
}

export function* watch() {
  yield takeEvery(WATCH_TYPE, deleteGroup);
}

export default function* root() {
  yield all([fork(watch)]);
}
