import React, { memo } from 'react';

import TextField from 'components/TextField';

import {
  red,
  pink,
  purple,
  deepPurple,
  lightBlue,
  blue,
  teal,
  green,
  yellow,
  deepOrange,
} from '@material-ui/core/colors';

import { Grid, Box, MenuItem } from '@material-ui/core';

export const COLOR_OPTIONS = [
  { value: red['500'], label: 'Red' },
  { value: pink['A200'], label: 'Pink' },
  { value: purple['A200'], label: 'Purple' },
  { value: deepPurple['A200'], label: 'Deep Purple' },
  { value: blue['A200'], label: 'Blue' },
  { value: lightBlue['A200'], label: 'Light Blue' },
  { value: teal['A200'], label: 'Teal' },
  { value: green['A400'], label: 'Green' },
  { value: yellow['400'], label: 'Yellow' },
  { value: deepOrange['400'], label: 'Orange' },
];

export const defaultColor = deepPurple['A200'];

const ColorSelector = (props: any) => (
  <TextField {...props} select options={[]}>
    {COLOR_OPTIONS.map(option => (
      <MenuItem key={option.value} value={option.value}>
        <Grid
          container
          justifyContent='space-between'
          alignItems='center'
          spacing={3}
        >
          <Grid item>{option.label}</Grid>
          <Grid item>
            <Box
              bgcolor={option.value}
              borderRadius='50%'
              height='1.3em'
              width='1.3em'
            />
          </Grid>
        </Grid>
      </MenuItem>
    ))}
  </TextField>
);

export default memo(ColorSelector);
