import React, { useCallback, useState } from 'react';
// material-ui
import { withStyles, WithStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import SvgIcon from '@material-ui/core/SvgIcon';
// components
import DazzlingDialog from 'components/DazzlingDialog';
import Link from '@material-ui/core/Link';
//icons
import { ReactComponent as BookCheckIcon } from 'assets/images/book-check.svg';
//styles
import agreeToFeeDisclaimerStyle from 'routes/PaymentSettings/PaymentSetupCard/AgreeToFeeDisclaimer/agreeToFeeDisclaimer.style';
import Typography from '@material-ui/core/Typography';

export interface AgreeToFeeDisclaimerProps
  extends WithStyles<typeof agreeToFeeDisclaimerStyle> {}

const AgreeToFeeDisclaimer = ({ classes }: AgreeToFeeDisclaimerProps) => {
  const [isAgreementModalVisible, setIsAgreementModalVisible] = useState(false);

  const handleOpenAgreementModal = useCallback(() => {
    setIsAgreementModalVisible(true);
  }, []);

  const handleCloseAgreementModal = useCallback(() => {
    setIsAgreementModalVisible(false);
  }, []);

  const labelAgreeToTerms = (
    <Link
      component='button'
      variant='subtitle2'
      color='secondary'
      underline='always'
      onClick={handleOpenAgreementModal}
    >
      View full terms and conditions here.
    </Link>
  );

  const getDialogContent = () => {
    const stripeLink = (
      <Link
        variant='subtitle2'
        color='secondary'
        href='https://stripe.com/pricing'
        target='_blank'
        rel='noreferrer'
      >
        here
      </Link>
    );
    const mailTo = (
      <Link
        variant='subtitle2'
        color='secondary'
        href='mailto:info@phiredup.com'
      >
        info@phiredup.com
      </Link>
    );

    return (
      <Grid container>
        <Grid item xs={12} id='warningLabel'>
          <Typography paragraph>
            By accepting funds through our payment processor, Stripe, you agree
            and understand that both Phired Up and TechniPhi (company) and
            Stripe (processor) assess a service fee for each transaction. Phired
            Up and TechniPhi's fee is 2% of each transaction, and Stripe's fees
            are updated regularly {stripeLink}. Phired Up, TechniPhi, and Stripe
            are compliant with all appropriate laws and policies governing
            transaction fees and questions can be directed to {mailTo}.
          </Typography>
        </Grid>
      </Grid>
    );
  };

  return (
    <Grid container>
      <Grid item>
        <Box mt={2}>{labelAgreeToTerms}</Box>
      </Grid>
      <DazzlingDialog
        open={isAgreementModalVisible}
        headerProps={{
          icon: (props: any) => (
            <SvgIcon
              {...props}
              className='svgIcon'
              component={BookCheckIcon}
              viewBox='0 0 46 52'
            />
          ),
          title: 'Agreement',
          subtitle: 'Full terms and conditions',
        }}
        handleClose={handleCloseAgreementModal}
        displayActions={false}
        children={getDialogContent()}
      />
    </Grid>
  );
};

export default withStyles(agreeToFeeDisclaimerStyle, { withTheme: true })(
  AgreeToFeeDisclaimer
);
