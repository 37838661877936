import React, { useState, useEffect } from 'react';
import { Field } from 'react-final-form';
import { Elements, ElementsConsumer } from '@stripe/react-stripe-js';
import { loadStripe, Stripe } from '@stripe/stripe-js';
// material-ui
import { withStyles, WithStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import SvgIcon from '@material-ui/core/SvgIcon';
// components
import StripeForm from 'routes/ReferralForm/FormStripeView/components/StripeForm';
import TextField from 'components/TextField';
// selectors
//icons
import { ReactComponent as StripeLogoIcon } from 'assets/images/stripe-logo.svg';
//helpers
import {
  StripePaymentStatus,
  SetStripePaymentStatus,
} from 'routes/ReferralForm/FormView/helpers/interfaces';
//styles
import formStripeViewStyles from 'routes/ReferralForm/FormStripeView/formStripeView.style';

export interface FormStripeViewProps
  extends WithStyles<typeof formStripeViewStyles> {
  callSubmitGlobalForm: (...args: any[]) => any;
  setStripeCardElement: (...args: any[]) => any;
  mutators: any;
  isCouncilForm?: boolean | null;
  accountNumber?: string | null;
  paymentIntentClientSecret?: string | null;
  referralFormLoading?: boolean;
  stripePaymentStatus: StripePaymentStatus;
  setStripePaymentStatus: SetStripePaymentStatus;
  stripeApiKey?: string;
}

const FormStripeView = ({
  classes,
  isCouncilForm,
  callSubmitGlobalForm,
  setStripeCardElement,
  mutators,
  accountNumber,
  paymentIntentClientSecret,
  referralFormLoading,
  stripePaymentStatus,
  setStripePaymentStatus,
  stripeApiKey,
}: FormStripeViewProps) => {
  const [stripeErrorMessage, setStripeErrorMessage] = useState(null);
  const [
    stripePromise,
    setStripePromise,
  ] = useState<Promise<Stripe | null> | null>(null);

  useEffect(() => {
    if (stripeApiKey && accountNumber)
      setStripePromise(
        loadStripe(stripeApiKey, { stripeAccount: accountNumber })
      );
  }, [stripeApiKey, accountNumber]);

  if (!isCouncilForm) {
    return null;
  }

  return (
    <Grid container className={classes.formContent}>
      <Grid item xs={12}>
        <div className={classes.stripeHeader}>
          <SvgIcon
            className={classes.svgStripeLogoIcon}
            component={StripeLogoIcon}
            viewBox='0 0 65 27'
          />
          Pay with Card
        </div>
      </Grid>
      <Grid item xs={12}>
        <Field name='emailAddressStripe' component={TextField} label='Email' />
      </Grid>
      <Grid item xs={12} className={classes.fieldStripeBox}>
        <Field name='stripeFormComplete'>
          {props =>
            stripePromise && (
              <Elements
                options={{
                  fonts: [
                    {
                      cssSrc:
                        'https://fonts.googleapis.com/css?family=Poppins:300,300i,400,400i,500,500i,600,600i,700,700i',
                    },
                  ],
                }}
                stripe={stripePromise}
              >
                <ElementsConsumer>
                  {({ elements, stripe }) => (
                    <StripeForm
                      elements={elements}
                      stripe={stripe}
                      callSubmitGlobalForm={callSubmitGlobalForm}
                      setStripeCardElement={setStripeCardElement}
                      setStripeErrorMessage={setStripeErrorMessage}
                      stripeErrorMessage={stripeErrorMessage}
                      mutators={mutators}
                      paymentIntentClientSecret={paymentIntentClientSecret}
                      referralFormLoading={referralFormLoading}
                      stripePaymentStatus={stripePaymentStatus}
                      setStripePaymentStatus={setStripePaymentStatus}
                    />
                  )}
                </ElementsConsumer>
              </Elements>
            )
          }
        </Field>
        {stripeErrorMessage && (
          <div className={classes.stripeErrorMessage}>{stripeErrorMessage}</div>
        )}
        <Field name='paymentMethodId'>
          {props => (
            <input
              type='hidden'
              name={props.input.name}
              value={props.input.value}
            />
          )}
        </Field>
        <Field name='paymentIntentId'>
          {props => (
            <input
              type='hidden'
              name={props.input.name}
              value={props.input.value}
            />
          )}
        </Field>
      </Grid>
      <Grid item xs={12}>
        <Field name='nameOnCard' component={TextField} label='Name on card' />
      </Grid>
      <Grid item xs={12}>
        <div className={classes.fieldsAreRequired}>All fields are required</div>
      </Grid>
    </Grid>
  );
};

export default withStyles(formStripeViewStyles, { withTheme: true })(
  FormStripeView
);
