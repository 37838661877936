import React, { ReactNode } from 'react';

// material-ui
import { Grid, Box, Paper, Typography } from '@material-ui/core';

// components
import Button from 'components/Button';

type Props = {
  buttonLabel: string;
  header: string;
  subtitle: string;
  icon?: ReactNode;
  onButtonClick: () => void;
};

const Banner = ({
  buttonLabel,
  header,
  subtitle,
  icon,
  onButtonClick,
}: Props) => {
  return (
    <Box
      bgcolor='primary.main'
      paddingX={2}
      paddingY={1}
      width='fill-available'
      minHeight={72}
      height={'100%'}
      display='flex'
      clone
    >
      <Paper>
        <Grid
          container
          wrap='nowrap'
          alignItems='center'
          justify='space-between'
          spacing={2}
        >
          <Grid item>
            <Typography variant='h6'>{header}</Typography>
            {icon ? (
              <Grid container spacing={1} alignItems='center'>
                <Grid item>{icon}</Grid>
                <Grid item>
                  <Typography variant='subtitle2'>{subtitle}</Typography>
                </Grid>
              </Grid>
            ) : (
              <Typography variant='subtitle2'>{subtitle}</Typography>
            )}
          </Grid>
          <Grid item>
            <Button variant='outlined' onClick={onButtonClick}>
              {buttonLabel}
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
};

export default Banner;
