import React, { useMemo, useCallback, memo, useContext } from 'react';
import PropTypes from 'prop-types';
// components
import DazzlingDialog from 'components/DazzlingDialog';
import { Error, Warning } from '@material-ui/icons';
import {
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
// hooks
import { useHistory } from 'react-router-dom';
// helpers
import { SiteVisualDataContext } from 'components/SiteVisualData';

const ErrorDialog = function({ errors, open, onClose }: any) {
  const classes = useStyles();
  const { errorDialogSubtitle } = useContext(SiteVisualDataContext);
  const history = useHistory();
  const navigateToLead = useCallback(
    leadId => {
      if (leadId) {
        history.push({ pathname: `/leads/${leadId}` });
      }
    },
    [history]
  );

  const dialogHeaderData = {
    icon: Error,
    title: 'Error Log',
    subtitle: errorDialogSubtitle,
  };

  const ErrorItem = useCallback(
    ({ error = {} }) => {
      const { msg, pnmId: leadId } = error;
      return (
        <ListItem>
          <ListItemIcon>
            <Warning color='error' />
          </ListItemIcon>
          <ListItemText
            secondary={
              leadId && (
                <div>
                  <Typography color='textSecondary'>{`For lead ID ${leadId}`}</Typography>
                  <Button
                    onClick={() => navigateToLead(leadId)}
                    className={classes.viewButton}
                  >
                    View Profile
                  </Button>
                </div>
              )
            }
          >
            {msg}
          </ListItemText>
        </ListItem>
      );
    },
    [classes.viewButton, navigateToLead]
  );
  const dialogContent = useMemo(
    () => (
      <List className={classes.errorList}>
        {errors?.length &&
          errors.map((error: any, index: any) => (
            <React.Fragment key={`${error}_${index}`}>
              <ErrorItem error={error} index={error} />
              {index !== errors.length - 1 && <Divider />}
            </React.Fragment>
          ))}
      </List>
    ),
    [classes.errorList, errors, ErrorItem]
  );
  return (
    <DazzlingDialog
      id='errorListDialog'
      actions={[]}
      handleClose={onClose}
      headerProps={dialogHeaderData}
      height='auto'
      open={open}
    >
      {dialogContent}
    </DazzlingDialog>
  );
};

const useStyles = makeStyles(theme => {
  return {
    errorList: {
      border: `1px solid ${(theme.palette.background as any).outline}`,
      borderRadius: 8,
    },
    viewButton: {
      marginLeft: -8,
    },
  };
});
ErrorDialog.propTypes = {
  errors: PropTypes.instanceOf(Array).isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};
ErrorDialog.defaultProps = {
  onClose: () => {},
};
export default memo(ErrorDialog);
