import { List } from 'immutable';
import getCurrentGroupId from './getCurrentGroupId';
import getAvailableAddOnProdIds from './getAvailableAddOnProdIds';

export default function getCurrentSubscription(state: any) {
  const currentGroupId = getCurrentGroupId(state) || '';
  const currentSubscriptions = state.getIn(
    [
      'billing',
      'data',
      'byId',
      currentGroupId.toString(),
      'currentSubscriptions',
    ],
    List()
  );

  const addOnProdIds = getAvailableAddOnProdIds(state);

  for (var sub of currentSubscriptions) {
    if (!addOnProdIds.includes(sub.getIn(['plan', 'product']))) {
      return sub;
    }
  }
}
