import { Styles } from 'assets/types';
import formViewStyles from 'routes/ReferralForm/FormView/formView.style';

const formStripeViewStyles: Styles = theme => {
  const { formContent } = formViewStyles(theme);
  return {
    formContent,
    fieldStripeBox: {
      marginTop: 16,
      marginBottom: 12,
    },
    stripeHeader: {
      fontSize: '16px',
      fontWeight: 400,
      lineHeight: '20px',
      paddingTop: 5,
      paddingBottom: 25,
      textAlign: 'center',
    },
    svgStripeLogoIcon: {
      fontSize: '20px',
      width: 65,
      height: 27,
      marginRight: 10,
      marginBottom: 4,
      display: 'inline-block',
      verticalAlign: 'middle',
    },
    fieldsAreRequired: {
      paddingTop: 20,
      fontSize: '16px',
    },
    stripeErrorMessage: {
      fontSize: '0.75rem',
      paddingTop: 7,
      color: '#ffee58',
    },
  };
};

export default formStripeViewStyles;
