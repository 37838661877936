import { schema } from 'normalizr';
export const leadReferralSourceSchema = new schema.Entity(
  'leadReferralSource',
  {},
  {
    idAttribute: 'pnmId',
    processStrategy: value =>
      Array.isArray(value)
        ? value.reduce(
            (acc, item) => ({ ...acc, [item.referralSourceId]: item }),
            {}
          )
        : { [value.referralSourceId]: value },
  }
);
