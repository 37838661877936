import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useDeleteEventTimeSlotsMutation } from 'api/eventTimeSlots';
import { FieldArrayRenderProps } from 'react-final-form-arrays';

// material-ui
import { Grid, Typography, Tooltip, Box } from '@material-ui/core';
import { Delete, FileCopy, AddCircle } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';

// components
import OutlinedCard from 'components/OutlinedCard';
import Button from 'components/Button';
import IconButton from 'components/IconButton';
import Accordion from 'components/Accordion';
import TimeSlot from './TimeSlot';
import CreateEventDateTimeSlots from './CreateEventDateTimeSlotsModal';
import { FormEventTimeSlot } from './FormSettings';

interface EventDateTimeSlotsProps {
  fieldArrayRenderProps: FieldArrayRenderProps<FormEventTimeSlot, HTMLElement>;
  eventDate: string;
  isOnLastDateTimeSlot: boolean;
  updateDataOnPage?: () => void;
}

export default function EventDateTimeSlots({
  fieldArrayRenderProps: { fields },
  eventDate,
  isOnLastDateTimeSlot,
  updateDataOnPage = () => {},
}: EventDateTimeSlotsProps) {
  const classes = useStyles();
  const [opened, setOpened] = useState(false);
  const [isCopy, setIsCopy] = useState(false);
  const {
    mutate: deleteEventTimeSlots,
    isLoading,
    isSuccess,
  } = useDeleteEventTimeSlotsMutation();
  useEffect(() => {
    if (!opened) setTimeout(() => setIsCopy(false), 100);
  }, [opened]);

  useEffect(() => {
    if (isSuccess) {
      updateDataOnPage();
    }
  }, [isSuccess]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Grid item xs={12}>
        <OutlinedCard>
          <Grid container alignItems='center' spacing={2}>
            <Grid item xs={10}>
              <Typography>{eventDate}</Typography>
            </Grid>

            <Grid item xs={1}>
              <Tooltip title='Duplicate time slots for a new date'>
                <IconButton
                  aria-label='duplicate'
                  onClick={() => {
                    setOpened(true);
                    setIsCopy(true);
                  }}
                >
                  <FileCopy />
                </IconButton>
              </Tooltip>
            </Grid>

            <Grid item xs={1}>
              <Tooltip
                title={
                  isOnLastDateTimeSlot
                    ? "You can't remove all time slots for the last date"
                    : 'Delete all time slots for this date'
                }
              >
                <Box>
                  <IconButton
                    aria-label='delete'
                    loading={isLoading}
                    disabled={isOnLastDateTimeSlot}
                    onClick={() => {
                      deleteEventTimeSlots(fields.value.map(ets => ets.id));
                    }}
                  >
                    <Delete />
                  </IconButton>
                </Box>
              </Tooltip>
            </Grid>

            <Grid item xs={12} className={classes.accordionContainer}>
              <Accordion
                details={
                  <Grid container spacing={2}>
                    {fields.map((prefix, index) => (
                      <Grid item xs={12} key={prefix}>
                        <TimeSlot
                          eventTimeSlotId={fields.value[index].id}
                          fieldPrefix={prefix}
                          isOnLastTimeSlot={
                            isOnLastDateTimeSlot && fields.length === 1
                          }
                          updateDataOnPage={updateDataOnPage}
                        />
                      </Grid>
                    ))}
                    <Grid item xs={12}>
                      <Button
                        startIcon={<AddCircle />}
                        onClick={() => {
                          setOpened(true);
                        }}
                      >
                        New Time Slot
                      </Button>
                    </Grid>
                  </Grid>
                }
                label='Manage Time Slots'
                labelPosition='left'
              />
            </Grid>
          </Grid>
        </OutlinedCard>
      </Grid>
      <CreateEventDateTimeSlots
        opened={opened}
        isCopy={isCopy}
        date={!isCopy ? moment(eventDate) : null}
        onClose={() => setOpened(false)}
        timeSlots={fields.value}
        updateDataOnPage={updateDataOnPage}
      />
    </>
  );
}

const useStyles = makeStyles(theme => {
  return {
    accordionContainer: {
      borderTop: `1px solid ${(theme.palette.background as any).outline}`,
    },
  };
});
