import { client, Resource } from 'api';
import { AxiosError } from 'axios';
import { useQuery } from 'react-query';

export function useAuthIntegrationsQuery() {
  return useQuery<AuthIntegration[], AxiosError>(
    [Resource.AuthIntegrations],
    () =>
      client.get(Resource.AuthIntegrations).then(({ data }) => data.results),
    { refetchOnWindowFocus: false }
  );
}

export function useAuthIntegrationsFullQuery() {
  return useQuery<AuthIntegration[], AxiosError>(
    [Resource.AuthIntegrations],
    () =>
      client
        .get(Resource.AuthIntegrationsFull)
        .then(({ data }) => data.results),
    { refetchOnWindowFocus: false }
  );
}

export function useAuthSessionIdQuery() {
  return useQuery<{ session: string }, AxiosError, string>(
    [Resource.AuthIntegrationsSessionId],
    () =>
      client
        .get(Resource.AuthIntegrationsSessionId)
        .then(({ data }) => data.session),
    { refetchOnWindowFocus: false }
  );
}
