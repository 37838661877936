import { connect } from 'react-redux';

// action creators
import { destroySession } from 'store/actions/sessionActions';

// components
import WaitingRoomActions from './WaitingRoomActions';

const mapDispatch = { destroySession };

export default connect(null, mapDispatch)(WaitingRoomActions);
