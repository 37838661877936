import React from 'react';
import classNames from 'classnames';

// material-i
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

export default function BlockQuote({
  author,
  content,
  subtitle,
}: {
  id: any;
  author: string;
  content: string;
  subtitle: string;
}) {
  const classes = useStyles();

  return (
    <Grid
      container
      className={classes.blockQuote}
      direction='column'
      spacing={3}
    >
      <Typography className={classes.quote}>"</Typography>
      <Grid
        item
        className={classes.italic}
        component={Typography}
        variant='body1'
      >
        {content}
      </Grid>

      <Grid item>
        <Grid container direction='column'>
          <Grid
            item
            className={classes.bold}
            component={Typography}
            variant='body1'
          >
            {author}
          </Grid>
          <Grid
            item
            className={classNames(classes.subtitle, classes.italic)}
            component={Typography}
            variant='body1'
          >
            {subtitle}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles(theme => {
  return {
    blockQuote: {
      border: `1px solid #ffffff`,
      borderRadius: '4px',
      marginTop: '15px',
      padding: '30px 10px 10px 50px',
    },

    subtitle: {
      fontSize: '14px',
    },

    italic: {
      fontStyle: 'italic',
    },

    bold: {
      fontWeight: 'bold',
    },

    quote: {
      fontSize: '100px',
      fontStyle: 'italic',
      height: '30px',
      marginLeft: '-35px',
      marginTop: '-35px',
    },
  };
});
