import { all, call, fork, put, takeEvery, select } from 'redux-saga/effects';
import { Map } from 'immutable';

// action creators
import { doneIndicator, success } from 'store/actions/httpActions';

// api
import client from 'sources/api';

// constants
import {
  GROUP_REMOVE_DASHBOARD_LINK_REQUEST,
  GROUP_REMOVE_DASHBOARD_LINK,
} from 'store/constants/groupTypes';

// lib
import httpSaga from 'store/sagas/httpSaga';

// schema
import { groupSchema } from 'store/schemas/groupSchema';

const TYPE = GROUP_REMOVE_DASHBOARD_LINK;
const WATCH_TYPE = GROUP_REMOVE_DASHBOARD_LINK_REQUEST;

export function* removeDashboardLinkSaga(action: any) {
  const { parentId: parent_id, childId: child_id } = action.payload;

  const FORM = 'removeDashboardLinkForm';
  const childGroup = yield select(state =>
    state.getIn(['group', 'data', 'byId', child_id.toString()], Map())
  );

  // @ts-expect-error ts-migrate(2569) FIXME: Type 'Generator<any, any, unknown>' is not an arra... Remove this comment to see the full error message
  yield* httpSaga(
    TYPE,
    call(client.post, `/groups/${parent_id}/remove_dashboard_link`, {
      parent_id,
      child_id,
    }),
    { schema: groupSchema, formName: FORM, dispatchSuccess: false }
  );

  yield put(success(TYPE, Map({ parentId: parent_id, childGroup })));

  yield put(doneIndicator(TYPE));
}

export function* watch() {
  yield takeEvery(WATCH_TYPE, removeDashboardLinkSaga);
}

export default function* root() {
  yield all([fork(watch)]);
}
