import React from 'react';
import { AsYouType } from 'libphonenumber-js';
// material-ui
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import InputAdornment from '@material-ui/core/InputAdornment';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import CloseSharp from '@material-ui/icons/CloseSharp';
import { Grid } from '@material-ui/core';

const FinalFormTextField = function({
  label,
  input,
  options,
  select,
  meta: { visited, invalid, error, data },
  loading,
  clearable,
  InputProps,
  SelectProps,
  children,
  maxLength,
  maxLengthMessage,
  margin = 'normal',
  ...restOfProps
}: any) {
  const classes = useStyles();
  // The incoming meta.error prop can be an array of error messages.
  // When this happens, the MUI TextField tries to render all of the messages
  // in a not pretty way.
  //
  // If we have an array of errors, simply use just the first error for simplicity
  let errorMessage = error;

  if (Array.isArray(error)) {
    errorMessage = error[0];
  }

  const warning = (data || {}).warning;
  let warningMessage = warning;

  const handleClear = () => {
    if (SelectProps && SelectProps.multiple) {
      input.onChange([]);
    } else {
      input.onChange(null);
    }
  };
  if (Array.isArray(warning)) {
    warningMessage = warning[0];
  }
  let newInputProps = { ...InputProps };

  if (loading) {
    newInputProps.endAdornment = (
      <InputAdornment position='end'>
        <CircularProgress className={classes.loadingIndicator} size={20} />
      </InputAdornment>
    );
  } else if (
    clearable &&
    // Show clear button when there is non empty value
    ((!(SelectProps && SelectProps.multiple) && input.value) ||
      input.value?.length > 0)
  ) {
    newInputProps.endAdornment = (
      <InputAdornment position='end'>
        <Box marginRight={2}>
          <IconButton onClick={handleClear}>
            <CloseSharp />
          </IconButton>
        </Box>
      </InputAdornment>
    );
  }
  let phoneNumber = {};

  if (restOfProps.type === 'tel') {
    let formattedNumber = input.value?.toString();
    /* The formatter has issues with open and closed parentheses
          and backspacing through them.
    
          We strip the parentheses out so that the formatter can still work.
          More: https://github.com/catamphetamine/libphonenumber-js/issues/225
        */
    if (formattedNumber.includes('(') && !formattedNumber.includes(')')) {
      formattedNumber = formattedNumber.replace('(', '');
    } else {
      formattedNumber = new AsYouType('US').input(formattedNumber);
    }

    (phoneNumber as any).value = formattedNumber;
  }

  const helperText =
    (visited && errorMessage) ||
    warningMessage ||
    restOfProps.helperText ||
    (maxLength &&
      input.value.length > maxLength &&
      (maxLengthMessage
        ? maxLengthMessage
        : "You've reached the maximum character limit."));

  // Character counter extending helperText node
  const characterCounter = maxLength && (
    <Grid container justify='space-between'>
      <Grid item>{helperText}</Grid>
      <Grid item>
        <span
          className={
            input.value.length > 0 && input.value.length <= maxLength
              ? classes.characterCounter
              : ''
          }
        >
          {input.value.length}
        </span>{' '}
        / {maxLength}
      </Grid>
    </Grid>
  );

  return (
    <TextField
      label={label}
      margin={margin}
      error={
        (visited && invalid) || (maxLength && input.value.length > maxLength)
      }
      select={select}
      InputProps={newInputProps}
      SelectProps={SelectProps}
      {...input}
      {...restOfProps}
      {...phoneNumber}
      helperText={characterCounter || helperText}
    >
      {select
        ? options.map((option: any) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))
        : null}
      {children}
    </TextField>
  );
};

FinalFormTextField.propTypes = {
  ...(TextField as any).propTypes,
};
FinalFormTextField.defaultProps = {
  fullWidth: true,
  meta: {},
  variant: 'outlined',
  InputProps: {},
  loading: false,
};

const useStyles = makeStyles(theme => {
  return {
    loadingIndicator: {
      marginRight: -5,
    },
    characterCounter: {
      color: theme.palette.success.light,
    },
  };
});

export default FinalFormTextField;
