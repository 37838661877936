import { all } from 'redux-saga/effects';

// sagas
import sessionCreateSaga from './sessionCreateSaga';
import sessionDestroySaga from './sessionDestroySaga';
import sessionRefreshSaga from './sessionRefreshSaga';
import sessionVerifySaga from './sessionVerifySaga';

const sessionSaga = function* rootSaga() {
  yield all([
    sessionCreateSaga(),
    sessionDestroySaga(),
    sessionRefreshSaga(),
    sessionVerifySaga(),
  ]);
};

export default sessionSaga;
