import React, { useEffect, useState, useCallback } from 'react';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import { isEqual } from 'lodash';
import { roles } from 'helpers/getRoles';
import classNames from 'classnames';
// hooks
import { useDispatch, useSelector } from 'react-redux';
// material-ui
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
// components
import TabController from 'components/TabController';
import TaskList from 'components/TaskList';
import CreateTaskButton from 'components/CreateTaskButton';
// action creators
import { fetchTasks, clearTasks } from 'store/actions/taskActions';
// selectors
import getPermission from 'store/selectors/getPermission';
import getIsSystemAdmin from 'store/selectors/getIsSystemAdmin';
const LeadTasksTab = function({ leadId }: any) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const {
    canCreateTask,
    currentUserRole,
    nextTasksUrl,
    currentUserId,
    isSystemAdmin,
  } = useSelector(
    state => ({
      canCreateTask: getPermission('todo.createOne')(state),
      isSystemAdmin: getIsSystemAdmin(state),
      currentUserId: (state as any).getIn(['currentUser', 'data', 'id']),
      nextTasksUrl: (state as any).getIn(['task', 'meta', 'next']),
      currentUserRole: (state as any).getIn(
        ['currentUser', 'data', 'roleId'],
        roles.member
      ),
    }),
    isEqual
  );
  const isLeaderOrAdmin =
    [roles.admin, roles.leader].includes(currentUserRole) || isSystemAdmin;
  const initialTab = 'incomplete';
  const [currentTab, setCurrentTab] = useState(initialTab);
  const [fetchTaskParams, setFetchTaskParams] = useState({
    incomplete: true,
  });
  const fetchNextPage = useCallback(() => {
    if (nextTasksUrl) {
      const page = new URLSearchParams(nextTasksUrl).get('page');
      dispatch(fetchTasks({ ...fetchTaskParams, page }));
    }
  }, [dispatch, fetchTaskParams, nextTasksUrl]);
  useEffect(() => {
    handleChangeTab(initialTab);
  }, []); // eslint-disable-line
  const handleChangeTab = (newTab: any) => {
    setCurrentTab(newTab);
    let params;
    switch (newTab) {
      default:
      case 'incomplete':
        params = {
          // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ leadId: any; completed: boolea... Remove this comment to see the full error message
          leadId,
          completed: false,
        };
        break;
      case 'complete':
        params = {
          leadId,
          completed: true,
        };
        break;
    }
    if (currentUserRole === roles.member) {
      (params as any).accountId = currentUserId;
    }
    setFetchTaskParams(params);
    dispatch(clearTasks());
    dispatch(fetchTasks(params));
  };
  return (
    <>
      <Grid
        container
        className={classNames(
          classes.tabBarContainer,
          isLeaderOrAdmin && classes.borderedTabBar
        )}
      >
        <Grid item xs={11}>
          {isLeaderOrAdmin && (
            <Grid container justifyContent='center'>
              <Grid item xs={10} sm={7} md={6} className={classes.tabBar}>
                <TabController
                  // @ts-expect-error ts-migrate(2322) FIXME: Type '{ id: string; tabs: any; size: string; curre... Remove this comment to see the full error message
                  id='tabController'
                  tabs={tabs}
                  size='compact'
                  currentTab={currentTab}
                  onAnyTabClick={handleChangeTab}
                  containerColor='darkPaper'
                  tabHoverColor='default'
                />
              </Grid>
            </Grid>
          )}
        </Grid>

        {canCreateTask && (
          <Grid item xs={1}>
            <CreateTaskButton />
          </Grid>
        )}
      </Grid>

      <Grid
        container
        className={classes.taskListContainer}
        spacing={2}
        justifyContent='center'
      >
        <Grid item xs={12} sm={10} md={9} lg={6}>
          <TaskList
            onFetchNextPage={fetchNextPage}
            hasNextPage={Boolean(nextTasksUrl)}
          />
        </Grid>
      </Grid>
    </>
  );
};
LeadTasksTab.propTypes = {
  leadId: PropTypes.number.isRequired,
};
const tabs = Immutable.fromJS([
  {
    key: 'incomplete',
    label: 'Incomplete',
  },
  {
    key: 'complete',
    label: 'Complete',
  },
]);
const useStyles = makeStyles(theme => {
  const border = `1px solid ${(theme.palette.background as any).lightPaper}`;
  return {
    tasksTab: {
      marginBottom: 24,
      [theme.breakpoints.only('xs')]: {
        marginBottom: 45,
        height: '100%',
        overflow: 'scroll',
      },
    },
    header: {
      borderBottom: border,
      marginBottom: 7,
      paddingLeft: 20,
      paddingRight: 20,
      paddingTop: 15,
    },
    bold: {
      fontWeight: 800,
    },
    tabBarContainer: {
      paddingTop: 8,
      paddingLeft: 16,
      paddingRight: 16,
      paddingBottom: 7,
    },
    borderedTabBar: {
      borderBottom: border,
    },
    tabBar: {
      marginLeft: '8.333%',
    },
    taskListContainer: {
      marginTop: 15,
      height: '87%',
      overflowY: 'scroll',
      paddingLeft: 20,
      paddingRight: 20,
      [theme.breakpoints.down('sm')]: {
        paddingBottom: 20,
      },
    },
  };
});
export default LeadTasksTab;
