import React, { useState } from 'react';
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from 'react-beautiful-dnd';

// components
import {
  Grid,
  Typography,
  Box,
  IconButton,
  Menu,
  MenuItem,
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import SuperAvatar from 'components/SuperAvatar';

// icons
import { DragHandle, Clear, MoreVert } from '@material-ui/icons';

export default function CallQueue({
  leads,
  onChange,
  loading,
}: {
  leads: Lead[];
  onChange: (updatedLeads: Lead[]) => void;
  loading: boolean;
}) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [hoveredLeadIndex, setHoveredLeadIndex] = useState<number | null>(null);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClearAll = () => {
    onChange([]);
    setAnchorEl(null);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    const reorderedLeads = Array.from(leads);
    const [removed] = reorderedLeads.splice(result.source.index, 1);
    reorderedLeads.splice(result.destination.index, 0, removed);
    onChange(reorderedLeads);
  };
  return (
    <Grid container direction='column'>
      <Box paddingY='20px' paddingX={2} clone>
        <Grid item>
          <Grid container alignItems='center'>
            <Grid item xs>
              <Typography variant='h6'>Next In Queue</Typography>
            </Grid>
            <Grid item>
              <IconButton size='small' onClick={handleClick}>
                <MoreVert />
              </IconButton>
              <Menu
                id='long-menu'
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleMenuClose}
              >
                <MenuItem disabled>
                  <Typography variant='body2'>Queue Options</Typography>
                </MenuItem>
                <MenuItem onClick={handleClearAll}>
                  <Box clone color='primary.main'>
                    <Grid container alignItems='center' spacing={2}>
                      <Clear fontSize='small' />
                      <Grid item>
                        <Typography variant='body2'>Clear Queue</Typography>
                      </Grid>
                    </Grid>
                  </Box>
                </MenuItem>
              </Menu>
            </Grid>
          </Grid>

          <Box fontSize='0.8rem' clone>
            <Typography variant='subtitle2' color='textSecondary'>
              {loading ? <Skeleton width={80} /> : `${leads.length} queued`}
            </Typography>
          </Box>
        </Grid>
      </Box>
      <Grid item>
        <Box height='2px' width='100%' bgcolor='background.darkPaper' />
      </Grid>
      {loading ? (
        Array.from({ length: 6 }, (_, i) => (
          <>
            <Grid item key={i}>
              <Skeleton height={'56px'} />
            </Grid>

            <Grid item>
              <Box height='2px' width='100%' bgcolor='background.darkPaper' />
            </Grid>
          </>
        ))
      ) : (
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId='droppable'>
            {(provided, snapshot) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {leads.map((lead: Lead, index: number) => (
                  <Draggable
                    key={lead.id}
                    draggableId={lead.id.toString()}
                    index={index}
                  >
                    {provided => (
                      <Grid
                        item
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        onMouseOver={() => setHoveredLeadIndex(index)}
                        onMouseLeave={() => setHoveredLeadIndex(null)}
                      >
                        <Box padding={1}>
                          <Grid container alignItems='center' spacing={1}>
                            <Grid item>
                              <DragHandle color={'disabled'} />
                            </Grid>
                            <Grid item>
                              <SuperAvatar
                                fullName={lead.fullName}
                                imageUrl={lead.profilePhotoFileUrl}
                                size={'small'}
                              />
                            </Grid>
                            <Grid item xs zeroMinWidth>
                              <Typography
                                variant='body2'
                                noWrap
                                display='block'
                              >
                                {lead.fullName}
                              </Typography>
                            </Grid>
                            {index === hoveredLeadIndex && (
                              <Grid item>
                                <IconButton
                                  color='primary'
                                  size='small'
                                  onClick={() =>
                                    onChange([
                                      ...leads.slice(0, index),
                                      ...leads.slice(index + 1),
                                    ])
                                  }
                                >
                                  <Clear fontSize='small' />
                                </IconButton>
                              </Grid>
                            )}
                          </Grid>
                        </Box>
                        <Box
                          height='2px'
                          width='100%'
                          bgcolor='background.darkPaper'
                        />
                      </Grid>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      )}
    </Grid>
  );
}
