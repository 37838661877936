import React, { useCallback, useContext } from 'react';
import validate from 'validate.js';
import { isEqual } from 'lodash';
import moment from 'moment';

// hooks
import { useSelector, useDispatch } from 'react-redux';

// material-ui
import { Grid, Typography } from '@material-ui/core';

// icons
import EventIcon from '@material-ui/icons/DateRange';

//components
import DazzlingDialog from 'components/DazzlingDialog';
import TextField from 'components/TextField';
import { Form, Field } from 'react-final-form';
import DateRange from 'components/DateRange';
import Switch from 'components/Switch';
import MemberSelector from 'components/MemberSelector';
import OutlinedCard from 'components/OutlinedCard';
import StatusSelector from 'components/StatusSelector';
import TagSelector from './TagSelector';

// action creators
import { updateEvent } from 'store/actions/eventActions';

// selectors
import getCurrentGroupId from 'store/selectors/getCurrentGroupId';

// helpers
import { SiteVisualDataContext } from 'components/SiteVisualData';

const FORM = 'updateEventForm';

const UpdateEventModal = ({
  event,
  open,
  onClose,
}: {
  event: GroupEvent;
  onClose: (...args: any[]) => any;
  open: boolean;
}) => {
  const groupId = useSelector(getCurrentGroupId);
  const { createEventModalLabel } = useContext(SiteVisualDataContext);

  const dispatch = useDispatch();
  const dispatchUpdateEvent = useCallback(
    values => {
      dispatch(
        updateEvent({ groupId, eventId: event.id, formName: FORM, ...values })
      );
    },
    [dispatch, event.id, groupId]
  );

  const handleDateChange = useCallback(({ startDate, endDate, input }) => {
    input.onChange({ startDate, endDate });
  }, []);

  const validateForm = useCallback(values => {
    return validate(values, schema);
  }, []);

  return (
    <Form
      id='updateEventForm'
      validate={validateForm}
      onSubmit={dispatchUpdateEvent}
      initialValues={{
        title: event.title,
        location: event.location,
        pointOfContact: {
          value: event.pointOfContact?.account?.id,
          label: event.pointOfContact
            ? `${event.pointOfContact?.account?.firstName} ${event.pointOfContact?.account?.lastName}`
            : null,
        },
        inviteAllMembers: event.inviteAllMembers,
        linkToParents: event.linkToParents,
        description: event.description,
        eventDate: {
          startDate: moment.utc(event.startDate).local(),
          endDate: moment.utc(event.endDate).local(),
        },
        attendingStatusId: event.attendingStatusId,
        attendedStatusId: event.attendedStatusId,
        attendingTagIds: event.attendingTagIds || [],
        attendedTagIds: event.attendedTagIds || [],
      }}
      keepDirtyOnReinitialize
      render={({
        handleSubmit,
        invalid,
        initialValues,
        values,
        form: { restart },
      }) => {
        return (
          <DazzlingDialog
            acceptLabel='Update'
            alertProps={{ message: 'Event Updated Successfully' }}
            disabled={
              invalid || isEqual(initialValues, values)
              // This is a work-around as pristine doesn't work here (always has false value)
            }
            formName={FORM}
            handleClose={onClose}
            headerProps={dialogData}
            height='auto'
            id='updateEvent'
            onAccept={handleSubmit}
            open={open}
          >
            <Grid container spacing={1} direction='row'>
              <Grid item xs={12} sm={8}>
                <Field
                  name='title'
                  label='Title'
                  variant='outlined'
                  component={TextField}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  name='eventDate'
                  // @ts-expect-error ts-migrate(2322) FIXME: Type 'typeof DateRange' is not assignable to type ... Remove this comment to see the full error message
                  component={DateRange}
                  pickerType='datetime'
                  formName={FORM}
                  disableFuture={false}
                  endLabel='End Date & Time'
                  startLabel='Start Date & Time'
                  initialStartDate={initialValues.eventDate.startDate}
                  initialEndDate={initialValues.eventDate.endDate}
                  onDateChange={handleDateChange}
                  justifyContent='flex-start'
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  name='location'
                  label='Event Location'
                  variant='outlined'
                  component={TextField}
                  multiline
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  allowNull
                  isEqual={isEqual}
                  name='pointOfContact'
                  label='Point of Contact'
                  component={MemberSelector}
                  margin='normal'
                />
              </Grid>
              <Grid item xs={12} sm={8}>
                <Field
                  name='description'
                  label='Description'
                  variant='outlined'
                  component={TextField}
                  multiline
                />
              </Grid>

              <Grid item xs={12}>
                <OutlinedCard>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography variant='body2'>Auto Statuses</Typography>
                      <Typography color='textSecondary' variant='caption'>
                        Advance your leads' statuses when they RSVP or attend
                        this event
                      </Typography>
                    </Grid>

                    <Grid item xs={12} lg={6} md={9}>
                      <Field
                        id='attendingStatusId'
                        name='attendingStatusId'
                        component={StatusSelector}
                        label="RSVP'd as attending"
                        aria-label="RSVP'd as attending"
                        margin='none'
                        clearable
                      />
                    </Grid>
                    <Grid item xs={12} lg={6} md={9}>
                      <Field
                        id='attendedStatusId'
                        name='attendedStatusId'
                        component={StatusSelector}
                        label='Attended Event'
                        aria-label='Attended Event'
                        margin='none'
                        clearable
                      />
                    </Grid>
                  </Grid>
                </OutlinedCard>
              </Grid>

              <Grid item xs={12}>
                <OutlinedCard>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography variant='body2'>
                        Auto Milestones & Tags
                      </Typography>
                      <Typography color='textSecondary' variant='caption'>
                        Add milestones and tags to your leads when they RSVP or
                        attend this event
                      </Typography>
                    </Grid>
                    <Grid item xs={12} lg={6} md={9}>
                      <Field
                        id='attendingTagIds'
                        name='attendingTagIds'
                        label="RSVP'd as attending"
                        aria-label="RSVP'd as attending"
                        margin='none'
                        render={({ input, ...rest }) => (
                          <TagSelector {...input} {...rest} />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} lg={6} md={9}>
                      <Field
                        id='attendedTagIds'
                        name='attendedTagIds'
                        label='Attended Event'
                        aria-label='Attended Event'
                        margin='none'
                        render={({ input, ...rest }) => (
                          <TagSelector {...input} {...rest} />
                        )}
                      />
                    </Grid>
                  </Grid>
                </OutlinedCard>
              </Grid>

              <Grid item xs={12}>
                <OutlinedCard>
                  <Grid container spacing={0}>
                    <Grid item>
                      <Typography variant='body2'>
                        Additional Options
                      </Typography>
                    </Grid>

                    <Grid item xs={12}>
                      <Field
                        name='inviteAllMembers'
                        label={createEventModalLabel}
                        type='checkbox'
                        component={Switch}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        name='linkToParents'
                        label='Share event with your council'
                        type='checkbox'
                        component={Switch}
                      />
                    </Grid>
                  </Grid>
                </OutlinedCard>
              </Grid>
            </Grid>
          </DazzlingDialog>
        );
      }}
    />
  );
};

const dialogData = {
  icon: EventIcon,
  title: 'Update Event',
  subtitle: 'Edit any important details for your event',
};

// schemas
const schema = {
  title: {
    presence: true,
  },
  'eventDate.startDate': {
    presence: true,
  },
  'eventDate.endDate': {
    presence: true,
  },
  location: {
    presence: true,
  },
  pointOfContact: {
    presence: true,
  },
  description: {
    length: {
      maximum: 2048,
    },
  },
};

export default UpdateEventModal;
