import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { isEqual } from 'lodash';

// hooks
import { useSelector, useDispatch } from 'react-redux';

// components
import InvitesTab from './InvitesTab';
import ManageGuestsTable from './ManageGuestsTable';
import FormsTab from './FormsTab';

// MUI icons
import { EventAvailable, PersonAdd, PostAdd } from '@material-ui/icons';

// MUI components
import { Paper, Tabs, Tab, useMediaQuery, Box, Grid } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/styles';

// action creators
import { destroyForm } from 'store/actions/formActions';

// selectors
import getForm from 'store/selectors/getForm';

export default function EventTabs({ eventId }: { eventId: number }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const theme = useTheme();
  const isExtraSmall = useMediaQuery((theme as any).breakpoints.only('xs'));

  const [tabValue, setTabValue] = useState<string>('form');

  const handleTabChange = useCallback(
    (_, newTabValue) => {
      setTabValue(newTabValue);
    },
    [setTabValue]
  );

  const { createEventGuestForm } = useSelector(
    state => ({
      createEventGuestForm: getForm('createEventGuestsForm')(state),
    }),
    isEqual
  );

  useEffect(() => {
    if (createEventGuestForm.hasSubmitSucceeded) {
      setTimeout(() => {
        setTabValue('leads');
        dispatch(destroyForm('createEventGuestsForm'));
      }, 2500);
    }
  }, [eventId, createEventGuestForm.hasSubmitSucceeded, dispatch]);

  const tabContent: {
    [key: string]: JSX.Element;
  } = useMemo(
    () => ({
      leads: (
        <Grid
          container
          justifyContent='center'
          spacing={2}
          className={classes.guestContainer}
        >
          <Grid item xs={12}>
            <ManageGuestsTable id='selectLeadsTable' guestType='lead' />
          </Grid>
        </Grid>
      ),
      members: (
        <Grid
          container
          justifyContent='center'
          spacing={5}
          className={classes.guestContainer}
        >
          <Grid item xs={12}>
            <ManageGuestsTable id='manageMembersTable' guestType='member' />
          </Grid>
        </Grid>
      ),
      invites: <InvitesTab eventId={eventId} />,
      form: <FormsTab />,
    }),
    [classes.guestContainer, eventId]
  );

  return (
    <Box marginTop={2}>
      <Paper elevation={0} className={classes.paper}>
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          indicatorColor='primary'
          textColor='primary'
          variant={isExtraSmall ? 'scrollable' : 'fullWidth'}
          scrollButtons='auto'
        >
          <Tab
            icon={<EventAvailable />}
            label='Manage Lead Guests'
            id='leads'
            value='leads'
          />
          <Tab
            icon={<EventAvailable />}
            label='Manage Member Guests'
            id='members'
            value='members'
          />
          <Tab
            icon={<PersonAdd />}
            label='Send Invites'
            id='invites'
            value='invites'
          />
          <Tab icon={<PostAdd />} label='Sign-up Form' id='form' value='form' />
        </Tabs>
      </Paper>
      <Paper className={classes.tabContentPaper}>{tabContent[tabValue]}</Paper>
    </Box>
  );
}
const useStyles = makeStyles((theme: any) => {
  return {
    guestContainer: {
      paddingLeft: 32,
      paddingRight: 32,
      [theme.breakpoints.only('xs')]: {
        paddingLeft: 16,
        paddingRight: 16,
      },
    },
    paper: {
      position: 'relative',
      zIndex: 2,
      borderBottomRightRadius: 0,
      borderBottomLeftRadius: 0,
      backgroundColor: theme.palette.background.paper,
      borderBottom: `2px solid ${theme.palette.background.darkPaper}`,
      [theme.breakpoints.down('sm')]: {
        marginLeft: 10,
        marginRight: 10,
      },
    },
    tabContentPaper: {
      padding: 16,
      backgroundColor: theme.palette.background.paper,
      borderTopRightRadius: 0,
      borderTopLeftRadius: 0,
      [theme.breakpoints.down('sm')]: {
        marginLeft: 10,
        marginRight: 10,
      },
    },
    noPadding: {
      padding: 0,
    },
  };
});
