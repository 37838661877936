import { Map } from 'immutable';
import getCurrentGroupId from './getCurrentGroupId';

export default function getCustomer(state: any) {
  const currentGroupId = getCurrentGroupId(state) || '';

  return state.getIn(
    ['billing', 'data', 'byId', currentGroupId.toString(), 'customer'],
    Map()
  );
}
