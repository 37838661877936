import {
  MEMBER_FETCH_FOR_GROUP_REQUEST,
  MEMBER_CLEAR,
  MEMBER_UPDATE_REQUEST,
  MEMBER_DELETE_REQUEST,
  MEMBER_DELETE_ALL_FOR_GROUP_REQUEST,
} from 'store/constants/memberTypes';

export function fetchMembersForGroup({
  accountIds,
  appendResult,
  groupId,
  metaId,
  perPage,
  page,
  search,
}: any) {
  return {
    type: MEMBER_FETCH_FOR_GROUP_REQUEST,
    payload: {
      accountIds,
      appendResult,
      groupId,
      metaId,
      page,
      perPage,
      search,
    },
  };
}

export function clearMembers() {
  return {
    type: MEMBER_CLEAR,
  };
}

export function updateMember({ memberId, roleId }: any) {
  return {
    type: MEMBER_UPDATE_REQUEST,
    payload: { memberId, roleId },
  };
}

export function deleteMember({ memberId }: any) {
  return {
    type: MEMBER_DELETE_REQUEST,
    payload: { memberId },
  };
}

export function deleteAllMembersForGroup({ groupId }: any) {
  return {
    type: MEMBER_DELETE_ALL_FOR_GROUP_REQUEST,
    payload: { groupId },
  };
}
