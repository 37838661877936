import { all } from 'redux-saga/effects';

// sagas
import fetchFormFeeSaga from 'store/sagas/formFee/fetchFormFeeSaga';
import fetchOneFormFeeSaga from 'store/sagas/formFee/fetchOneFormFeeSaga';
import createFormFeeSaga from 'store/sagas/formFee/createFormFeeSaga';
import deleteFormFeeSaga from 'store/sagas/formFee/deleteFormFeeSaga';
import updateFormFeeSaga from 'store/sagas/formFee/updateFormFeeSaga';

const formFeeSaga = function* rootSaga() {
  yield all([
    fetchFormFeeSaga(),
    fetchOneFormFeeSaga(),
    createFormFeeSaga(),
    deleteFormFeeSaga(),
    updateFormFeeSaga(),
  ]);
};

export default formFeeSaga;
