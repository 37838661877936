import Immutable from 'immutable';
import { normalize } from 'normalizr';
import { all, call, fork, put, takeEvery, select } from 'redux-saga/effects';

// action creators
import { doneIndicator, success } from 'store/actions/httpActions';
import { fetchMembersForGroup } from 'store/actions/memberActions';

// selectors
import getCurrentGroupId from 'store/selectors/getCurrentGroupId';
import getCurrentUser from 'store/selectors/getCurrentUser';
import getSliceResult from 'store/selectors/getSliceResult';

// api
import client from 'sources/api';

// constants
import {
  ACTIVITY_FETCH_BY_ID_REQUEST,
  ACTIVITY_FETCH_BY_ID,
} from 'store/constants/activityTypes';
import { COMMENT_FETCH } from 'store/constants/commentTypes';

// schemas
import { activitySchema } from 'store/schemas/activitySchema';
import { commentSchema } from 'store/schemas/commentSchema';

// helpers
import httpSaga from 'store/sagas/httpSaga';

const WATCH_TYPE = ACTIVITY_FETCH_BY_ID_REQUEST;
const TYPE = ACTIVITY_FETCH_BY_ID;

export function* fetchActivity(action: any) {
  const {
    payload: { id },
  } = action;

  const currentGroupId = yield select(getCurrentGroupId);

  // @ts-expect-error ts-migrate(2569) FIXME: Type 'Generator<any, any, unknown>' is not an arra... Remove this comment to see the full error message
  const response = yield* httpSaga(
    TYPE,
    call(client.get, `/activity/${id}?group_id=${currentGroupId}`),
    {
      schema: activitySchema,
    }
  );

  if (response) {
    const lastComment = response.data.lastComment;

    if (lastComment) {
      const payload = {
        data: normalize(lastComment, commentSchema),
        entity: commentSchema.key,
        normalized: true,
      };

      // Send the response data to the store for the comments
      yield put(
        success(
          COMMENT_FETCH,
          Immutable.fromJS({
            ...payload,
          })
        )
      );

      const currentUser = yield select(getCurrentUser);
      const membersAccountIdList = yield select(getSliceResult('member'));
      if (
        currentUser.get('id') !== lastComment.accountId &&
        !membersAccountIdList.includes(lastComment.accountId)
      ) {
        const currentGroupId = yield select(getCurrentGroupId);
        yield put(
          fetchMembersForGroup({
            groupId: currentGroupId,
            accountIds: [lastComment.accountId],
          })
        );
      }
    }
  }
  yield put(doneIndicator(TYPE));
}

export function* watch() {
  yield takeEvery(WATCH_TYPE, fetchActivity);
}

export default function* root() {
  yield all([fork(watch)]);
}
