import React, { useCallback, useMemo } from 'react';
import { isEqual } from 'lodash';

// hooks
import { useDispatch } from 'react-redux';
import { useCreateBgTaskMutation } from 'api/tasks';

// components
import DazzlingDialog from 'components/DazzlingDialog';
import TagsSelector from 'components/ChipsSelector';
import { Field, Form } from 'react-final-form';

// material-ui
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import { Typography } from '@material-ui/core';

// icons
import { NoteAdd } from '@material-ui/icons';

// actions
import { createNoteAction } from 'store/actions/noteActions';
import { fetchLeadTagsAction } from 'store/actions/leadTagActions';

// helpers
import useFetchFilters from 'helpers/hooks/useFetchFilters';
import { LeadsQuery } from 'routes/LeadList/helpers/interfaces';

// constants
import { TaskNames, CreateNotesParams } from 'api/tasks';

const FORM = 'addTagLeadForm';

interface AddTagModalProps {
  onClose: () => void;
  open: boolean;
  leadsQuery: LeadsQuery;
  allSelected?: boolean;
  disabled?: boolean;
  leadIds?: number[];
  tags?: Tag[];
  leads?: Lead[];
}

const AddTagModal = ({
  allSelected = false,
  leadIds = [],
  onClose,
  open,
  disabled,
  tags,
  leads = [],
  leadsQuery,
}: AddTagModalProps) => {
  const dispatch = useDispatch();
  const filters = useFetchFilters();

  const singleLeadId = leadIds.length === 1 && leadIds[0];
  const isBulk = allSelected || leadIds.length > 1;

  const { refetchLeads } = leadsQuery || {};

  const {
    isLoading: isCreatingNotes,
    isSuccess: isCreatingNotesSucceed,
    isError: isCreatingNotesFailed,
    reset: resetCreateNotesMutation,
    mutate: createNotes,
  } = useCreateBgTaskMutation<CreateNotesParams>(TaskNames.BulkCreateNotes);

  const singleLeadCurrentTagIds = useMemo(() => {
    if (isBulk) return;
    const currentLead = leads.find(lead => lead.id === singleLeadId);

    return currentLead?.tags;
  }, [singleLeadId, isBulk, leads]);

  const isTagOptionChecked = useCallback(
    (id: number) =>
      !!(
        singleLeadCurrentTagIds &&
        singleLeadCurrentTagIds.find((tagId: number) => tagId === id)
      ),
    [singleLeadCurrentTagIds]
  );

  const tagOptions = useMemo(
    () =>
      (tags || []).map(({ id, title, color }: Tag) => ({
        value: id,
        label: title,
        color: '#' + color,
        selected: isTagOptionChecked(id),
        type: 'label',
      })),
    [tags, isTagOptionChecked]
  );

  const callbackOnSuccess = useCallback(() => {
    refetchLeads();
  }, [refetchLeads]);

  const handleExit = useCallback(() => {
    if (singleLeadId && !isBulk) {
      dispatch(fetchLeadTagsAction({ id: singleLeadId }));
    }
  }, [dispatch, singleLeadId, isBulk]);

  const handleFormSubmit = useCallback(
    ({ tags = [] }) => {
      if (isBulk) {
        const params: CreateNotesParams = {
          pnmIds: allSelected ? 'all' : leadIds,
          noteData: { tagIds: tags },
          ...filters,
        };
        if (allSelected) params.excludedPnmIds = leadIds;
        createNotes(params);
      } else if (singleLeadId) {
        dispatch(
          createNoteAction({
            formName: FORM,
            fields: {
              leadId: singleLeadId,
              tagIds: tags,
            },
            callbackOnSuccess,
          })
        );
      }
    },
    [
      dispatch,
      callbackOnSuccess,
      createNotes,
      leadIds,
      allSelected,
      filters,
      isBulk,
      singleLeadId,
    ]
  );

  const highlightedSubtitle = allSelected
    ? 'all leads'
    : leadIds.length === 1
    ? 'one lead'
    : `${leadIds.length} leads`;

  return (
    <Form
      onSubmit={handleFormSubmit}
      initialValues={{ tags: [] }}
      initialValuesEqual={isEqual}
      render={({ handleSubmit, invalid, pristine, form: { reset } }) => (
        <DazzlingDialog
          disabled={invalid || pristine || disabled}
          isLoading={isCreatingNotes}
          isSuccess={isCreatingNotesSucceed}
          isError={isCreatingNotesFailed}
          formName={!isBulk ? FORM : undefined}
          handleClose={onClose}
          open={open}
          onAccept={handleSubmit}
          headerProps={{
            icon: NoteAdd,
            title: 'Add Lead Tags',
            highlightedSubtitle: `Group your leads by apply custom tags to the ${highlightedSubtitle} selected`,
          }}
          acceptLabel='Save'
          TransitionProps={{
            onExit: handleExit,
            onExited: () => {
              !disabled && reset();
              resetCreateNotesMutation();
            },
          }}
          submitOnEnter
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {isBulk && (
                <Typography paragraph>
                  This could take a while -- you will receive a notification
                  when completed.
                </Typography>
              )}
            </Grid>
            <Grid item xs={12} id='actionsFilter'>
              <Tooltip
                arrow
                title='Tags give you simple ways to categorize and group your leads'
              >
                <div>
                  <Field
                    name='tags'
                    label='Select Tags'
                    InputLabelProps={{
                      shrink: true,
                    }}
                    options={tagOptions}
                    fullWidth
                  >
                    {props => <TagsSelector options={tagOptions} {...props} />}
                  </Field>
                </div>
              </Tooltip>
            </Grid>
          </Grid>
        </DazzlingDialog>
      )}
    />
  );
};

export default AddTagModal;
