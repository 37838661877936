import React, { useCallback, useMemo, useEffect } from 'react';
import validate from 'validate.js';
import { isEqual } from 'lodash';

// hooks
import { useSelector, useDispatch } from 'react-redux';

// components
import DazzlingDialog from 'components/DazzlingDialog';
import TextField from 'components/TextField';
import TagsSelector from 'components/ChipsSelector';
import { Field, Form } from 'react-final-form';

// material-ui
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';

// icons
import { NoteAdd } from '@material-ui/icons';

// actions
import { updateNoteAction } from 'store/actions/noteActions';
import {
  fetchLeadTagsAction,
  clearLeadTagsAction,
} from 'store/actions/leadTagActions';

// selectors
import getSliceState from 'store/selectors/getSliceState';
import getSliceEntityById from 'store/selectors/getSliceEntityById';

const SCHEMA = {
  body: {
    length: { maximum: 2048 },
  },
};

export default function UpdateNoteTagModal({
  disabled,
  noteId,
  leadId,
  onClose,
  open,
  shouldFetchTags,
}: {
  disabled?: boolean;
  noteId: number;
  leadId: number;
  onClose: (...args: any[]) => any;
  open: boolean;
  shouldFetchTags?: boolean;
}) {
  const {
    actions: { updateNote, fetchLeadTags, clearLeadTags },
    state: { tags = [], milestones = [], leadTags = {}, note = {} },
  } = useRedux(leadId, noteId);

  const { body, tagIds = [] } = note;

  const formName = `updateNote${noteId}`;

  useEffect(() => {
    if (shouldFetchTags && leadId && open) {
      fetchLeadTags();
    }
  }, [fetchLeadTags, open, shouldFetchTags, leadId]);

  const tag_options = useMemo(
    () =>
      tags.map(({ id, title, color }: any = {}) => ({
        value: id,
        label: title,
        color: '#' + color,
        checked: leadTags[id],
        type: 'label',
      })),
    [tags, leadTags]
  );

  const initialTagIds = tags.filter(t => tagIds?.includes(t.id)).map(t => t.id);
  const initialMilestoneIds = milestones
    .filter(m => tagIds?.includes(m.id))
    .map(m => m.id);

  const milestone_options = useMemo(
    () =>
      milestones.map(({ id, title }: any = {}) => ({
        value: id,
        label: title,
        ring: true,
        // Decrement the milestone count if this milestone is already assigned on the note
        count: initialMilestoneIds.includes(id)
          ? leadTags[id]?.count - 1 || 0
          : leadTags[id]?.count || 0,
        type: 'event',
      })),
    [milestones, initialMilestoneIds, leadTags]
  );

  const handleExit = useCallback(() => {
    if (leadId) {
      clearLeadTags();
      fetchLeadTags();
    }
  }, [leadId, clearLeadTags, fetchLeadTags]);

  const handleFormSubmit = useCallback(
    ({ body, milestones = {}, tags = {} }) => {
      updateNote({
        formName: formName,
        id: noteId,
        body,
        tagIds: [...milestones, ...tags],
      });
    },
    [updateNote, formName, noteId]
  );
  const validateFormFields = useCallback(
    fields => validate(fields, SCHEMA),
    []
  );

  return (
    <Form
      onSubmit={handleFormSubmit}
      initialValues={{
        body,
        tags: initialTagIds,
        milestones: initialMilestoneIds,
      }}
      initialValuesEqual={isEqual}
      validate={validateFormFields}
      render={({ handleSubmit, invalid, pristine, form: { reset } }) => (
        <DazzlingDialog
          disabled={invalid || pristine || disabled}
          formName={formName}
          handleClose={onClose}
          open={open}
          onAccept={handleSubmit}
          headerProps={{
            icon: NoteAdd,
            title: 'Update Note',
            highlightedSubtitle: `Record your relationship and interactions with this lead through notes, milestones, and tags`,
          }}
          acceptLabel='Update'
          submitOnEnter
          onExit={handleExit}
          onExited={() => !disabled && reset()}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Field
                name='body'
                label='Note'
                multiline
                rows='4'
                placeholder='Type something'
                maxLength={SCHEMA.body.length.maximum}
                component={TextField}
                fullWidth
                margin='normal'
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>

            <Grid item xs={12} id='actionsFilter'>
              <Tooltip
                arrow
                placement='top'
                title='Milestones help you log significant steps a lead has taken in
                the recruitment process'
              >
                <div>
                  <Field
                    name='milestones'
                    label='Select Milestones'
                    // @ts-expect-error ts-migrate(2322) FIXME: Type 'typeof ChipsSelector' is not assignable to t... Remove this comment to see the full error message
                    component={TagsSelector}
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    options={milestone_options}
                    isEqual={(a, b) => isEqual(new Set(a), new Set(b))}
                    chipsColor={null}
                  />
                </div>
              </Tooltip>
            </Grid>
            <Grid item xs={12} id='actionsFilter'>
              <Tooltip
                arrow
                title='Tags give you simple ways to categorize and group your leads'
              >
                <div>
                  <Field
                    name='tags'
                    label='Select Tags'
                    // @ts-expect-error ts-migrate(2322) FIXME: Type 'typeof ChipsSelector' is not assignable to t... Remove this comment to see the full error message
                    component={TagsSelector}
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    options={tag_options}
                    isEqual={(a, b) => isEqual(new Set(a), new Set(b))}
                  />
                </div>
              </Tooltip>
            </Grid>
          </Grid>
        </DazzlingDialog>
      )}
    />
  );
}

const useRedux = (leadId: number, noteId: number) => {
  const dispatch = useDispatch();
  const actions = useMemo(
    () => ({
      updateNote: (payload: any) => dispatch(updateNoteAction(payload)),
      fetchLeadTags: () => dispatch(fetchLeadTagsAction({ id: leadId })),
      clearLeadTags: () => dispatch(clearLeadTagsAction({ id: leadId })),
    }),
    [dispatch, leadId]
  );
  const state = useSelector(
    state => ({
      tags: getSliceState('tag')(state).data,
      milestones: getSliceState('milestone')(state).data,
      leadTags: getSliceEntityById('leadTag', leadId, [])(state),
      note: getSliceEntityById('note', noteId)(state),
    }),
    isEqual
  );
  return { actions, state };
};
