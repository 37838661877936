import { List, Map } from 'immutable';

export default function addLabels(state: any, action: any) {
  const { payload = Map() } = action;

  const groupTags = payload.getIn(['data', 'entities', 'groupTag'], Map());
  const groupId = (groupTags.first() || Map()).get('groupId');

  const oldData = state.get('data', Map());
  const oldLabels = oldData.getIn(
    ['byId', groupId?.toString(), 'labels'],
    List()
  );
  let newLabels = oldLabels;

  groupTags.forEach((groupTag = Map()) => {
    const tagId = groupTag.get('tagId');
    if (!newLabels.includes(tagId)) {
      newLabels = newLabels.push(tagId);
    }
  });

  let newData = oldData.setIn(
    ['byId', groupId?.toString(), 'labels'],
    newLabels
  );

  return state.set('data', oldData.merge(newData));
}
