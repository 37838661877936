import React, { useState, useMemo, useCallback, useEffect } from 'react';
import classNames from 'classnames';

// hooks
import { useHistory } from 'react-router-dom';
import { usePrevious } from 'helpers/hooks/usePrevious';

// MUI components
import { makeStyles } from '@material-ui/core/styles';
import {
  Fab,
  InputAdornment,
  Grid,
  Card,
  Typography,
  IconButton,
  Box,
} from '@material-ui/core';
import { Search, Create, Close } from '@material-ui/icons';

// components
import TextField from 'components/TextField';

const ThreadListHeader = function({
  setMessageView,
}: {
  setMessageView: (view: string) => void;
}) {
  const classes = useStyles();
  const history = useHistory();
  const {
    location: { pathname, search },
  } = history;
  const newSearch = useMemo(() => new URLSearchParams(search), [search]);
  const handleNewMessageClick = () => {
    const newSearch = new URLSearchParams(search);
    newSearch.delete('thread');
    history.replace({ pathname, search: newSearch.toString() });
    setMessageView('newMessage');
  };
  const [searchValue, setSearchValue] = useState(newSearch.get('search'));
  const previousSearchValue = usePrevious(searchValue);
  const [focused, setFocused] = useState(false);

  const [timerId, setTimerId] = useState<NodeJS.Timeout | null>(null);
  const handleClear = useCallback(() => {
    setSearchValue('');
  }, []);
  const handleSearchChange = useCallback(event => {
    let newSearchValue = event.currentTarget.value;
    setSearchValue(newSearchValue);
  }, []);

  useEffect(() => {
    if (searchValue !== previousSearchValue) {
      if (timerId) {
        clearTimeout(timerId);
      }
      setTimerId(
        setTimeout(
          ({ searchValue }) => {
            if (searchValue) {
              newSearch.set('search', searchValue);
            } else {
              newSearch.delete('search');
            }
            newSearch.delete('thread');
            history.replace({ pathname, search: newSearch.toString() });
          },
          500,
          { searchValue }
        )
      );
    }
  }, [searchValue, previousSearchValue, newSearch, pathname, history, timerId]);

  return (
    <Grid
      container
      alignItems='center'
      justifyContent='space-between'
      className={classes.messagesListHeaderContainer}
    >
      <Grid item>
        <Typography variant='h6' id='elevio_education_what_to_say'>
          Messages
        </Typography>
      </Grid>
      <Grid item>
        <Box width={24} />
      </Grid>
      <Grid item xs>
        <Card
          className={classNames(classes.searchBar, focused && classes.focused)}
        >
          <div className={classes.textField}>
            <TextField
              placeholder='Search'
              value={searchValue}
              onChange={handleSearchChange}
              onFocus={() => setFocused(true)}
              onBlur={() => setFocused(false)}
              variant='standard'
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <Search />
                  </InputAdornment>
                ),
                endAdornment: searchValue && (
                  <InputAdornment position='end'>
                    <IconButton onClick={handleClear} size='small'>
                      <Close />
                    </IconButton>
                  </InputAdornment>
                ),
                disableUnderline: true,
              }}
            />
          </div>
        </Card>
      </Grid>
      <Grid item>
        <Box width={24} />
      </Grid>
      <Grid item className={classes.newMessageButtonContainer}>
        <Fab
          color='primary'
          size='small'
          onClick={handleNewMessageClick}
          id='newMessageFab'
        >
          <Create />
        </Fab>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles(theme => {
  const border = `2px solid ${(theme.palette.background as any).darkPaper}`;
  return {
    messagesListHeaderContainer: {
      padding: 26,
      borderBottom: border,
    },
    searchBar: {
      paddingLeft: 15,
      borderRadius: 8,
      paddingRight: 24,
      backgroundColor: (theme.palette.background as any).lightPaper,
      transition: 'all 0.25s ease',
    },
    focused: {
      transition: 'all 0.25s ease',
      backgroundColor: (theme.palette.background as any).highlightedPaper,
    },
    textField: {
      marginTop: -8,
    },
    iconContainer: {
      textAlign: 'center',
    },
    newMessageButtonContainer: {
      textAlign: 'right',
    },
  };
});

export default ThreadListHeader;
