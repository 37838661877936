import React, { useState, useCallback, useMemo, useRef } from 'react';
import { List, Map } from 'immutable';
import { roles } from 'helpers/getRoles';
import PropTypes from 'prop-types';

// hooks
import { useSelector, shallowEqual } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  useFeatureLimitRemaining,
  useIsFeatureEnabled,
  useFeatureHasLimit,
} from 'helpers/hooks/featureHooks';

// components
import MegaMenu from 'components/MegaMenu';
import AddCallLeadModal from 'routes/Calls/AddCallLeadModal';
import CreateGroupForm from 'layout/ApplicationLayout/components/CreateGroupForm';
import CreateLeadModal from 'routes/LeadList/LeadListView/LeadListActions/CreateLeadModal';
import CreateFormModal from 'routes/Forms/CreateFormModal';
import CreateValueModal from 'routes/ValuesSettings/CreateValueModal';
import CreateStatusModal from 'routes/StatusSettings/CreateStatusModal';
import CreateMilestoneModal from 'routes/MilestonesSettings/CreateMilestoneModal';
import CreateTagModal from 'routes/TagsSettings/CreateTagModal';
import CreateLabelModal from 'routes/LabelsSettings/CreateLabelModal';
import CreateTaskModal from 'components/CreateTaskModal';
import CreateEventModal from 'routes/EventList/CreateEventModal';
import CreateCampusModal from './CreateCampusModal';

// material-ui
import { makeStyles } from '@material-ui/core/styles';
import {
  ButtonGroup,
  Button,
  Typography,
  Fab,
  Tooltip,
} from '@material-ui/core';

// icons
import AddCircleOutlined from '@material-ui/icons/AddCircleOutlined';
import Link from '@material-ui/icons/Link';
import Add from '@material-ui/icons/Add';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import Phone from '@material-ui/icons/Phone';
import Council from '@material-ui/icons/School';
import Org from '@material-ui/icons/LocationCity';
import Chapter from '@material-ui/icons/AccountBalance';
import Campus from '@material-ui/icons/LocationOn';
import PersonAdd from '@material-ui/icons/PersonAdd';
import GroupAdd from '@material-ui/icons/GroupAdd';
import DoneAll from '@material-ui/icons/DoneAll';
import Sms from '@material-ui/icons/Sms';

// selectors
import getIsSystemAdmin from 'store/selectors/getIsSystemAdmin';
import getPermission from 'store/selectors/getPermission';
import getCurrentGroupId from 'store/selectors/getCurrentGroupId';
import getIsCurrentGroupCouncil from 'store/selectors/getIsCurrentGroupCouncil';
import getIsCurrentGroupOrganization from 'store/selectors/getIsCurrentGroupOrganization';
import getIsHqAdmin from 'store/selectors/getIsHqAdmin';
import getIsCurrentGroupSystem from 'store/selectors/getIsCurrentGroupSystem';

const GROUP_TYPES = ['GREEK_CHAPTER', 'COUNCIL', 'GREEK_ORGANIZATION'];
const DEFAULT_BUTTON_LABEL = 'New Lead';

const NewButton = function({ variant }: any) {
  const classes = useStyles();
  const history = useHistory();
  const {
    location: { pathname, search = '', state = {} },
  } = history;
  const anchorRef = useRef(null);
  const [modalToOpen, setModalToOpen] = useState<string | null>(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const handleMenuClose = useCallback(() => {
    setMenuOpen(false);
  }, []);
  const handleModalClose = useCallback(() => {
    setModalToOpen(null);
  }, []);
  const {
    canCreateForm,
    canCreateInvite,
    canCreateManyLeads,
    canCreateMessages,
    canCreateOneLead,
    canCreateTask,
    currentGroupId,
    formsSize,
    isSystemAdmin,
    isAdmin,
    isHqAdmin,
    milestonesSize,
    statusesSize,
    tagsSize,
    isCurrentGroupCouncil,
    isCurrentGroupOrganization,
    isCurrentGroupSystem,
  } = useSelector(
    state => ({
      canCreateForm: getPermission('form.createOne')(state),
      canCreateInvite: getPermission('groupAccountInvite.createOne')(state),
      canCreateManyLeads: getPermission('task.bulkImportPnms')(state),
      canCreateMessages: getPermission('message.createMany')(state),
      canCreateOneLead: getPermission('pnm.createOne')(state),
      canCreateTask: getPermission('todo.createOne')(state),
      currentGroupId: getCurrentGroupId(state),
      isCurrentGroupCouncil: getIsCurrentGroupCouncil(state),
      isCurrentGroupOrganization: getIsCurrentGroupOrganization(state),
      isCurrentGroupSystem: getIsCurrentGroupSystem(state),
      isAdmin:
        (state as any).getIn(
          ['currentUser', 'data', 'roleId'],
          roles.member
        ) === roles.admin,
      isHqAdmin: getIsHqAdmin(state),
      formsSize: (state as any).getIn(['referralForm', 'data', 'byId'], Map())
        .size,
      isSystemAdmin: getIsSystemAdmin(state),
      milestonesSize: (state as any).getIn(['milestone', 'data', 'byId'], Map())
        .size,
      statusesSize: (state as any).getIn(['status', 'data', 'byId'], Map())
        .size,
      tagsSize: (state as any).getIn(['tag', 'data', 'byId'], Map()).size,
    }),
    shallowEqual
  );
  const isHqAdminInGroupOrganization = isCurrentGroupOrganization && isHqAdmin;
  const callsEnabled = useIsFeatureEnabled('calls');
  const shouldAddCall = pathname.includes('/calls') && callsEnabled && isAdmin;
  const formsEnabled =
    useIsFeatureEnabled('forms') || (!currentGroupId && isSystemAdmin);
  const formsHaveLimit = useFeatureHasLimit('forms');
  const textsEnabled = useIsFeatureEnabled('texts');
  const hasRemainingForms = useFeatureLimitRemaining('forms', formsSize) > 0;
  const shouldCreateForm =
    pathname.includes('/forms') &&
    canCreateForm &&
    formsEnabled &&
    (!formsHaveLimit || hasRemainingForms);
  const milestonesEnabled = useIsFeatureEnabled('custom_milestones');
  const milestonesHaveLimit = useFeatureHasLimit('custom_milestones');
  const hasRemainingMilestones =
    useFeatureLimitRemaining('custom_milestones', milestonesSize) > 0;
  const shouldCreateMilestone =
    pathname.includes('settings/milestones') &&
    milestonesEnabled &&
    (!milestonesHaveLimit || hasRemainingMilestones);
  const tagsEnabled = useIsFeatureEnabled('custom_tags');
  const tagsHaveLimit = useFeatureHasLimit('custom_tags');
  const hasRemainingTags =
    useFeatureLimitRemaining('custom_tags', tagsSize) > 0;
  const shouldCreateTag =
    pathname.includes('settings/tags') &&
    tagsEnabled &&
    (!tagsHaveLimit || hasRemainingTags);
  const shouldCreateLabel = pathname.includes('settings/labels');
  const statusesEnabled = useIsFeatureEnabled('custom_statuses');
  const statusesHaveLimit = useFeatureHasLimit('custom_statuses');
  const hasRemainingStatuses =
    useFeatureLimitRemaining('custom_statuses', statusesSize) > 0;
  const shouldCreateStatus =
    pathname.includes('settings/statuses') &&
    statusesEnabled &&
    (!statusesHaveLimit || hasRemainingStatuses);
  const valuesAreEnabled = useIsFeatureEnabled('values');
  const shouldCreateValue =
    pathname.includes('settings/values') && valuesAreEnabled;

  const eventsEnabled = useIsFeatureEnabled('events') || true;
  const shouldCreateEvent = pathname.includes('events') && eventsEnabled;

  const handleButtonClick = useCallback(() => {
    if (pathname.includes('groups') && isSystemAdmin) {
      if (pathname.includes('chapters')) setModalToOpen('GREEK_CHAPTER');
      if (pathname.includes('organizations'))
        setModalToOpen('GREEK_ORGANIZATION');
      if (pathname.includes('councils')) setModalToOpen('COUNCIL');
    } else if (shouldCreateForm) {
      setModalToOpen('createForm');
    } else if (shouldCreateValue) {
      setModalToOpen('createValue');
    } else if (shouldCreateStatus) {
      setModalToOpen('createStatus');
    } else if (shouldCreateMilestone) {
      setModalToOpen('createMilestone');
    } else if (shouldCreateTag) {
      setModalToOpen('createTag');
    } else if (shouldCreateLabel) {
      setModalToOpen('createLabel');
    } else if (shouldCreateEvent) {
      setModalToOpen('createEvent');
    } else if (shouldAddCall) {
      setModalToOpen('addCall');
    } else {
      setModalToOpen('createLead');
    }
  }, [
    isSystemAdmin,
    pathname,
    shouldCreateEvent,
    shouldAddCall,
    shouldCreateForm,
    shouldCreateLabel,
    shouldCreateMilestone,
    shouldCreateStatus,
    shouldCreateTag,
    shouldCreateValue,
  ]);
  const handleDropdownClick = useCallback(() => {
    setMenuOpen(menuOpen => !menuOpen);
  }, []);
  const getButtonLabel = useCallback(() => {
    if (pathname.includes('groups') && isSystemAdmin) {
      if (pathname.includes('organizations')) return 'New Organization';
      if (pathname.includes('councils')) return 'New Council';
      return 'New Chapter';
    } else if (shouldAddCall) {
      return 'Add call';
    } else if (shouldCreateForm) {
      return 'New Form';
    } else if (shouldCreateValue) {
      return 'New Value';
    } else if (shouldCreateStatus) {
      return 'New Status';
    } else if (shouldCreateMilestone) {
      return 'New Milestone';
    } else if (shouldCreateTag) {
      return 'New Tag';
    } else if (shouldCreateLabel) {
      return 'New Label';
    } else if (shouldCreateEvent) {
      return 'New Event';
    }
    return DEFAULT_BUTTON_LABEL;
  }, [
    isSystemAdmin,
    pathname,
    shouldCreateEvent,
    shouldAddCall,
    shouldCreateForm,
    shouldCreateLabel,
    shouldCreateMilestone,
    shouldCreateTag,
    shouldCreateValue,
    shouldCreateStatus,
  ]);
  const getMenuSections = useCallback(() => {
    let menuSections = List();
    // This list is composed into immutable elements manually
    //  so that the icon components don't get converted into objects
    if (pathname.includes('groups') && isSystemAdmin) {
      menuSections = List([
        Map({
          id: 'addActions',
          sectionHeader: 'Add Actions',
          items: List([
            Map({
              id: 'newChapter',
              label: 'New Chapter',
              icon: <Chapter />,
              onClick: () => setModalToOpen('GREEK_CHAPTER'),
            }),
            Map({
              id: 'newCouncil',
              label: 'New Council',
              icon: <Council />,
              onClick: () => setModalToOpen('COUNCIL'),
            }),
            Map({
              id: 'newOrganization',
              label: 'New Organization',
              icon: <Org />,
              onClick: () => setModalToOpen('GREEK_ORGANIZATION'),
            }),
            Map({
              id: 'newCampus',
              label: 'New Campus',
              icon: <Campus />,
              onClick: () => setModalToOpen('CAMPUS'),
            }),
            Map({
              id: 'addDashboardLink',
              label: 'Link Chapter',
              icon: <Link />,
              display: currentGroupId && !isCurrentGroupSystem,
              onClick: () => setModalToOpen('addDashboardLink'),
            }),
          ]),
        }),
      ]);
    } else {
      menuSections = List([
        Map({
          id: 'leadActions',
          items: List([
            Map({
              id: 'addLead',
              display:
                !isCurrentGroupOrganization &&
                canCreateOneLead &&
                !isHqAdminInGroupOrganization,
              label: 'New Lead',
              icon: <PersonAdd />,
              onClick: () => {
                setModalToOpen('createLead');
              },
            }),
            Map({
              id: 'importLeads',
              display:
                !isCurrentGroupOrganization &&
                canCreateManyLeads &&
                !isHqAdminInGroupOrganization,
              label: 'Import Leads',
              icon: <GroupAdd />,
              onClick: () => {
                history.push({
                  pathname: '/import/leads',
                  search,
                });
              },
            }),
          ]),
        }),
        Map({
          id: 'generalActions',
          items: List([
            Map({
              id: 'addTask',
              display:
                canCreateTask &&
                !isHqAdminInGroupOrganization &&
                !isCurrentGroupCouncil &&
                !isCurrentGroupOrganization &&
                !isCurrentGroupSystem,
              label: 'New Task',
              icon: <DoneAll />,
              onClick: () => {
                setModalToOpen('createTask');
              },
            }),
            Map({
              id: 'newMessage',
              display:
                canCreateMessages && textsEnabled && !isCurrentGroupSystem,
              label: 'New Message',
              icon: <Sms />,
              onClick: () => {
                history.push({ pathname: '/messages', search, state });
              },
            }),
            Map({
              id: 'newCall',
              display:
                isAdmin &&
                callsEnabled &&
                !isCurrentGroupCouncil &&
                !isCurrentGroupSystem,
              label: 'New Call',
              icon: <Phone />,
              onClick: () => setModalToOpen('addCall'),
            }),
          ]),
        }),
        Map({
          id: 'memberActions',
          display: canCreateInvite,
          items: List([
            Map({
              id: 'inviteMember',
              display: canCreateInvite && !isHqAdminInGroupOrganization,
              label: 'Invite Member',
              icon: <PersonAdd />,
              onClick: () => {
                history.push({
                  pathname: '/settings/members/single',
                  search,
                });
              },
            }),
            Map({
              id: 'importMembers',
              display: canCreateInvite && !isHqAdminInGroupOrganization,
              label: 'Import Members',
              icon: <GroupAdd />,
              onClick: () => {
                history.push({
                  pathname: '/settings/members/bulk',
                });
              },
            }),
          ]),
        }),
      ]);
    }
    return menuSections;
  }, [
    callsEnabled,
    canCreateInvite,
    canCreateManyLeads,
    canCreateMessages,
    canCreateOneLead,
    canCreateTask,
    currentGroupId,
    history,
    isAdmin,
    isSystemAdmin,
    isCurrentGroupSystem,
    isCurrentGroupCouncil,
    textsEnabled,
    pathname,
    search,
    state,
    isHqAdminInGroupOrganization,
    isCurrentGroupOrganization,
  ]);

  const menuSections = getMenuSections();

  const areMenuSectionsVisible = useMemo<boolean>(() => {
    if (List.isList(menuSections)) {
      return !!menuSections.find((menuSection: any) => {
        if (Map.isMap(menuSection)) {
          const items = menuSection?.get('items', List()) || List();
          if (List.isList(items)) {
            return !!items.find(
              (item: any) => !!(Map.isMap(item) && item.get('display', false))
            );
          }
        }

        return false;
      });
    }

    return false;
  }, [menuSections]);

  const buttonLabel = getButtonLabel();

  // Don't display the New Lead dropdown button for
  // HQ Admin dashboard, Settings or Analytics pages.
  if (
    isCurrentGroupOrganization &&
    ['settings', 'analytics'].includes(pathname)
  ) {
    return null;
  }

  return (
    <div
      className={
        variant === 'fab' ? classes.fabContainer : classes.buttonContainer
      }
    >
      {variant === 'fab' ? (
        // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
        <Fab
          id='dropdownButton'
          color='primary'
          ref={anchorRef}
          className={classes.fabButton}
          aria-owns={anchorRef.current ? 'addMenu' : null}
          aria-haspopup='true'
          onClick={handleDropdownClick}
        >
          <Add className={classes.fabButtonIcon} />
        </Fab>
      ) : (
        (!isHqAdminInGroupOrganization ||
          areMenuSectionsVisible ||
          buttonLabel !== DEFAULT_BUTTON_LABEL) && (
          // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
          <ButtonGroup
            color='primary'
            variant='contained'
            ref={anchorRef}
            aria-owns={anchorRef.current ? 'addMenu' : null}
            aria-haspopup='true'
          >
            <Button
              variant='contained'
              color='primary'
              size='large'
              startIcon={<AddCircleOutlined />}
              id='newButton'
              onClick={handleButtonClick}
            >
              <Typography variant='subtitle2' className={classes.buttonLabel}>
                {buttonLabel}
              </Typography>
            </Button>

            {(!isHqAdminInGroupOrganization || areMenuSectionsVisible) && (
              <Tooltip title='More actions'>
                <Button
                  size='small'
                  onClick={handleDropdownClick}
                  id='dropdownButton'
                  aria-label='More actions'
                  aria-haspopup='true'
                >
                  <ArrowDropDown />
                </Button>
              </Tooltip>
            )}
          </ButtonGroup>
        )
      )}

      <MegaMenu
        // @ts-expect-error ts-migrate(2322) FIXME: Type '{ className: string; anchorEl: false | null;... Remove this comment to see the full error message
        className={classes.menu}
        anchorEl={menuOpen && anchorRef.current}
        menuSections={menuSections}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        handleClose={handleMenuClose}
      />

      {modalToOpen && (
        <>
          <CreateGroupForm
            open={GROUP_TYPES.includes(modalToOpen)}
            groupType={modalToOpen}
            onClose={handleModalClose}
          />
          <CreateCampusModal
            open={modalToOpen === 'CAMPUS'}
            onClose={handleModalClose}
          />
          <CreateLeadModal
            open={modalToOpen === 'createLead'}
            onClose={handleModalClose}
          />
          <CreateFormModal
            open={modalToOpen === 'createForm'}
            onClose={handleModalClose}
          />
          <CreateValueModal
            open={modalToOpen === 'createValue'}
            onClose={handleModalClose}
          />
          <CreateStatusModal
            open={modalToOpen === 'createStatus'}
            onClose={handleModalClose}
          />
          <CreateMilestoneModal
            open={modalToOpen === 'createMilestone'}
            onClose={handleModalClose}
          />
          <CreateTagModal
            open={modalToOpen === 'createTag'}
            onClose={handleModalClose}
          />
          <CreateLabelModal
            open={modalToOpen === 'createLabel'}
            onClose={handleModalClose}
            isCurrentGroupCouncil={isCurrentGroupCouncil}
          />
          <CreateTaskModal
            open={modalToOpen === 'createTask'}
            onClose={handleModalClose}
          />
          <CreateEventModal
            open={modalToOpen === 'createEvent'}
            onClose={handleModalClose}
          />
          <AddCallLeadModal
            open={modalToOpen === 'addCall'}
            onClose={handleModalClose}
          />
        </>
      )}
    </div>
  );
};
NewButton.propTypes = {
  variant: PropTypes.oneOf(['default', 'fab']),
};
NewButton.defaultProps = {
  variant: 'default',
};
const useStyles = makeStyles(theme => ({
  fabContainer: {
    marginRight: 15,
  },
  buttonContainer: {
    marginRight: 20,
  },
  buttonLabel: {
    fontWeight: 'bold',
  },
  menu: {
    marginTop: 5,
  },
  fabButton: {
    [theme.breakpoints.up('md')]: {
      height: 60,
      width: 60,
      position: 'absolute',
      zIndex: 3,
      borderRadius: 15,
      transform: 'rotate(45deg)',
      bottom: 24,
      right: 24,
      boxShadow: '10px 10px 11px 0 #1E2E39',
    },
    [theme.breakpoints.down('md')]: {
      height: 48,
      width: 48,
      zIndex: 3,
      borderRadius: 8,
      transform: 'rotate(45deg)',
      boxShadow: '10px 10px 11px 0 #1E2E39',
    },
  },
  fabButtonIcon: {
    transform: 'rotate(45deg)',
    fontSize: 26,
  },
}));
export default NewButton;
