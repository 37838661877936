import React from 'react';

// hooks
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useDateTime } from 'helpers/hooks/useDateTime';
import { useLeadQuery } from 'api/leads';

// action creators
import { readThreadAction } from 'store/actions/threadActions';

// MUI components
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, ListItem } from '@material-ui/core';
import { ContactMail } from '@material-ui/icons';

// components
import SuperAvatar from 'components/SuperAvatar';

// selectors
import getCurrentGroupId from 'store/selectors/getCurrentGroupId';

interface ThreadListItemProps {
  thread: Thread;
  setMessageView: (view: string) => void;
}

const ThreadListItem = function({
  thread,
  setMessageView,
}: ThreadListItemProps) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    location: { pathname, search },
  } = history;
  const currentGroupId = useSelector(getCurrentGroupId);
  const { data: lead } = useLeadQuery(thread.recipients[0]?.pnmId || NaN);
  const threadId = new URLSearchParams(search).get('thread');
  const itemSelected = threadId === thread.id.toString();
  const hasUnreadMessages = thread.hasUnreadMessages || false;
  const textColor = hasUnreadMessages ? 'inherit' : 'textSecondary';
  const updatedOn = useDateTime(thread.updatedOn);
  const messageExcerpt = thread.latestMessageExcerpt || '';
  const handleListItemClick = () => {
    const newSearch = new URLSearchParams(search);
    newSearch.set('thread', thread.id.toString());
    history.replace({ pathname, search: newSearch.toString() });
    // Mark thread as read
    dispatch(
      readThreadAction({ groupId: currentGroupId, threadId: thread.id })
    );
    setMessageView('thread');
  };
  return (
    <ListItem
      button
      className={classes.messagesListItemContainer}
      selected={itemSelected}
      onClick={handleListItemClick}
    >
      <Grid container alignItems='center'>
        <Grid item xs={2}>
          <SuperAvatar
            fullName={lead?.fullName}
            size='default'
            imageUrl={lead?.profilePhotoFileUrl}
          >
            {!lead?.id && <ContactMail fontSize='inherit' />}
          </SuperAvatar>
        </Grid>
        <Grid item xs={10}>
          <Grid container alignItems='center'>
            <Grid item xs={10}>
              <Typography
                variant='body1'
                className={classes.listItemTitle}
                color={textColor}
              >
                {lead?.fullName || thread.recipients[0]?.phoneNumber}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography variant='caption' color={textColor}>
                {updatedOn.date}
              </Typography>
            </Grid>

            <Grid item xs={11} className={classes.latestMessageContainer}>
              <Typography variant='caption' color={textColor}>
                {messageExcerpt}
              </Typography>
            </Grid>

            {hasUnreadMessages && (
              <Grid item xs={1} id='newIndicator'>
                <div className={classes.newIndicator} />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </ListItem>
  );
};

const useStyles = makeStyles(theme => {
  const border = `2px solid ${(theme.palette.background as any).darkPaper}`;
  return {
    messagesListItemContainer: {
      paddingLeft: 16,
      borderBottom: border,
      height: 77,
    },
    listItemTitle: {
      fontWeight: 600,
    },
    newIndicator: {
      width: 12,
      height: 12,
      backgroundColor: theme.palette.primary.main,
      borderRadius: 200,
    },
    latestMessageContainer: {
      minWidth: 0,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  };
});

export default ThreadListItem;
