import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useContext,
} from 'react';
import PropTypes from 'prop-types';
import { isEqual, toInteger } from 'lodash';

// hooks
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import useScrollTop from 'helpers/hooks/useScrollTop';

// MUI components
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import { ArrowUpward } from '@material-ui/icons';

// components
import MessageInput from './MessageInput';
import NewMessageHeader from './NewMessageHeader';
import ThreadHeader from './ThreadHeader';
import Thread from './Thread';
import Button from 'components/Button';

// actions
import {
  fetchMessagesAction,
  startListeningForMessagesAction,
} from 'store/actions/messageActions';
import { readThreadAction } from 'store/actions/threadActions';

// selectors
import getCurrentGroupId from 'store/selectors/getCurrentGroupId';
import getThreadById from 'store/selectors/getThreadById';
import getLoadingFromState from 'store/selectors/getLoadingFromState';
import getSliceState from 'store/selectors/getSliceState';
import getStatusFromState from 'store/selectors/getStatusFromState';

// helpers
import { SiteVisualDataContext } from 'components/SiteVisualData';

const MessagesContent = function({ setMessageView }: any) {
  const { messagesContentMessage } = useContext(SiteVisualDataContext);
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    location: { search },
  } = history;
  const [isTop, scrollRef] = useScrollTop(0);
  const [recipients, setRecipients] = useState<number[]>([]);
  const [excludedRecipients, setExcludedRecipients] = useState<number[]>([]);
  const [filters, setFilters] = useState<LeadFetchFilters>({});
  const [templateText, setTemplateText] = useState('');
  const threadId = new URLSearchParams(search).get('thread');
  const [totalLeads, setTotalLeads] = useState(0);
  const {
    currentGroupId,
    thread,
    hasNextMessages,
    nextMessagesUrl,
    messagesLoading,
    currentUserId,
    phoneNumberState,
    messageCreating,
    messageStatus,
  } = useSelector(
    state => ({
      currentGroupId: getCurrentGroupId(state),
      currentUserId: (state as any).getIn(['currentUser', 'data', 'id']),
      thread: getThreadById(threadId)(state) as any,
      hasNextMessages: (state as any).getIn(['message', 'meta', 'hasNext']),
      nextMessagesUrl: (state as any).getIn(['message', 'meta', 'next']),
      messagesLoading: getLoadingFromState('message', null, false)(state),
      phoneNumberState: getSliceState('phoneNumber', 'fetch', false)(state),
      messageCreating: getLoadingFromState('message', 'create', false)(state),
      messageStatus: getStatusFromState('message', 'create')(state),
    }),
    isEqual
  );
  const displayLoadMore = useMemo(
    () =>
      hasNextMessages &&
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'current' does not exist on type 'boolean... Remove this comment to see the full error message
      scrollRef.current &&
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'current' does not exist on type 'boolean... Remove this comment to see the full error message
      scrollRef.current.scrollTop === 0 &&
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'current' does not exist on type 'boolean... Remove this comment to see the full error message
      scrollRef.current.scrollHeight === scrollRef.current.clientHeight,
    [hasNextMessages, scrollRef]
  );
  const contentType = threadId ? 'thread' : 'newMessage';
  const hasUnreadMessages = thread.get('hasUnreadMessages', false);
  const phoneNumbers = phoneNumberState.data;
  const phoneNumbersLoading = phoneNumberState.loading;
  const currentUserHasPhoneNumber = phoneNumbers.find(
    (phoneNumber: any) => phoneNumber.account === currentUserId
  );

  useEffect(() => {
    if (hasUnreadMessages && threadId) {
      // Mark thread as read
      dispatch(
        readThreadAction({
          groupId: currentGroupId,
          threadId,
        })
      );
    }
  }, [currentGroupId, hasUnreadMessages, threadId]); // eslint-disable-line

  useEffect(() => {
    // Automatically scrolls to bottom of thread
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'current' does not exist on type 'boolean... Remove this comment to see the full error message
    if (scrollRef.current && !messagesLoading) {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'current' does not exist on type 'boolean... Remove this comment to see the full error message
      scrollRef.current.scrollTop = 1000;
    }
  }, [scrollRef, threadId]); // eslint-disable-line

  useEffect(() => {
    if (threadId) {
      dispatch(startListeningForMessagesAction({ threadId }));
    }
  }, [threadId]); // eslint-disable-line

  const fetchEarlierMessages = useCallback(() => {
    dispatch(
      fetchMessagesAction({
        groupId: currentGroupId,
        threadId,
        since: nextMessagesUrl,
      })
    );
  }, [currentGroupId, dispatch, nextMessagesUrl, threadId]);

  useEffect(() => {
    if (isTop && hasNextMessages) {
      fetchEarlierMessages();
    }
  }, [hasNextMessages, isTop]); // eslint-disable-line

  const handleSetRecipients = useCallback(
    (values = {}) => {
      if (Array.isArray(values.recipients)) {
        let newFilters = {};
        let newExcludedRecipients: number[] = [];
        const newRecipients: number[] = values.recipients.map((lead: any) => {
          // If we're using all recipients and a filter was given, set
          // newFilters variable to the incoming filter object. Otherwise
          // the filters will be cleared if recipients change
          if (lead.value === 'all' && lead.filters) {
            newFilters = lead.filters || {};
            newExcludedRecipients = lead.excludedLeadIds;
          }
          return lead.value === 'all' ? lead.value : toInteger(lead.value);
        });

        if (recipients.length !== newRecipients.length) {
          setExcludedRecipients(newExcludedRecipients);
          setFilters(newFilters);
          setRecipients(newRecipients);
        }
      }
    },
    [recipients.length]
  );
  const handleSetTemplateText = useCallback((values = {}) => {
    setTemplateText(values.textToInsert);
  }, []);

  return (
    <Grid
      container
      direction='column'
      className={classes.messagesContentContainer}
    >
      {currentUserHasPhoneNumber || phoneNumbersLoading ? (
        <>
          {(contentType === 'thread' || phoneNumbersLoading) && (
            <Grid item xs className={classes.threadHeaderContainer}>
              <ThreadHeader thread={thread} setMessageView={setMessageView} />
            </Grid>
          )}

          {contentType === 'newMessage' && currentUserHasPhoneNumber && (
            <Grid item xs>
              <NewMessageHeader
                handleSetRecipients={handleSetRecipients}
                handleSetTemplateText={handleSetTemplateText}
                recipients={recipients}
                setMessageView={setMessageView}
                messageCreating={messageCreating}
                messageStatus={messageStatus}
                setLeadsCount={setTotalLeads}
              />
            </Grid>
          )}

          {contentType === 'thread' && (
            // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
            <Grid item xs className={classes.threadContainer} ref={scrollRef}>
              {displayLoadMore && (
                <Grid item xs className={classes.loadMoreButtonContainer}>
                  <Button
                    startIcon={<ArrowUpward />}
                    onClick={fetchEarlierMessages}
                  >
                    Load More
                  </Button>
                </Grid>
              )}
              <Thread />
            </Grid>
          )}

          {currentUserHasPhoneNumber && (
            <Grid item xs className={classes.messageInputContainer}>
              <MessageInput
                recipients={recipients}
                excludedRecipients={excludedRecipients}
                filters={filters}
                thread={thread}
                templateText={templateText}
                totalLeads={totalLeads}
                contentType={contentType}
              />
            </Grid>
          )}
        </>
      ) : (
        <Grid item xs className={classes.setupMessage}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant='subtitle2'>
                {messagesContentMessage}
              </Typography>
              <Typography color='textSecondary' variant='body2'>
                Create and assign a phone number to get started with messaging!
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Button
                variant='outlined'
                onClick={() =>
                  history.push({ pathname: '/settings/phone', search })
                }
              >
                Set Up Number
              </Button>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};
MessagesContent.propTypes = {
  setMessageView: PropTypes.func.isRequired,
};
const useStyles = makeStyles(theme => {
  const border = `2px solid ${(theme.palette.background as any).darkPaper}`;
  return {
    messagesContentContainer: {
      height: '100%',
    },
    threadHeaderContainer: {
      borderBottom: border,
      padding: 20,
      maxHeight: 102,
    },
    setupMessage: {
      borderBottom: border,
      padding: 20,
      maxHeight: 130,
    },
    threadContainer: {
      padding: 20,
      overflow: 'scroll',
      flexWrap: 'inherit',
    },
    messageInputContainer: {
      maxHeight: 140,
      borderTop: border,
      [theme.breakpoints.only('xs')]: {
        marginBottom: 12,
      },
    },
    loadMoreButtonContainer: {
      textAlign: 'center',
      marginBottom: 5,
    },
  };
});
export default MessagesContent;
