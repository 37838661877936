import React, { useState } from 'react';

// material-ui
import { Grid, Paper } from '@material-ui/core';

// components
import TaskItemDetails from './TaskItemDetails';
import TaskItemHeader from './TaskItemHeader';

const TaskItem = function({ task, isAssignedToYou }: any) {
  const [hover, setHover] = useState(false);

  return (
    <Grid item xs={12} xl={10} id='taskItem'>
      <Grid
        container
        component={Paper}
        variant='outlined'
        justifyContent='center'
        onMouseOver={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <TaskItemHeader
          hover={hover}
          isAssignedToYou={isAssignedToYou}
          {...task}
        />

        <TaskItemDetails task={task} {...task} />
      </Grid>
    </Grid>
  );
};

export default TaskItem;
