import React, {
  memo,
  useState,
  useCallback,
  useRef,
  useMemo,
  useEffect,
  MouseEvent,
} from 'react';
import { useSelector } from 'react-redux';
import { useUpdateLeadMutation } from 'api/leads';
import { isEqual } from 'lodash';

// mui-components
import { Box, Avatar, Typography, Menu, MenuItem } from '@material-ui/core';
import { Edit, SwapVert } from '@material-ui/icons';

// styles
import { withStyles, makeStyles } from '@material-ui/core/styles';

// components
import IconButton from 'components/IconButton';
import Button from 'components/Button';
import CardActionItem from 'components/CardActionItem';
import UpdateStatusModal from './UpdateStatusModal';

// selectors
import getLeadStatuses from 'store/selectors/getLeadStatuses';
import getDefaultStatusId from 'store/selectors/getDefaultStatusId';
import getPermission from 'store/selectors/getPermission';
import getJStatusId from 'store/selectors/getJStatusId';
import getIsCurrentGroupCouncil from 'store/selectors/getIsCurrentGroupCouncil';
import getCurrentGroupId from 'store/selectors/getCurrentGroupId';

// helpers
import { toInt10 } from 'helpers/transform';

type LeadStatusProps = {
  lead: Lead;
  shouldDisplayLabelIcon?: boolean;
  size?: 'small' | 'large';
  variant?: 'fab' | 'button' | 'icon' | 'label';
  onLoadingChange?: (loading: boolean) => void;
  openStatusModal?: (leadId: number) => void;
};

const LeadStatus = ({
  lead,
  size = 'small',
  onLoadingChange = () => {},
  variant = 'fab',
  shouldDisplayLabelIcon = true,
  openStatusModal,
}: LeadStatusProps) => {
  const {
    isLoading: leadLoading,
    mutate: updateLead,
  } = useUpdateLeadMutation();
  const classes = useStyles();
  const buttonRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [openJStatusUpdateModal, setOpenJStatusUpdateModal] = useState(false);

  // selector data
  const {
    statuses = [],
    defaultStatusId,
    canPatchLead,
    currentGroupId,
    jStatusId,
    isCurrentGroupCouncil,
  } = useSelector(
    state => ({
      statuses: getLeadStatuses(state),
      defaultStatusId: getDefaultStatusId(state),
      canPatchLead: getPermission('pnm.patchOne')(state),
      jStatusId: getJStatusId(state),
      currentGroupId: getCurrentGroupId(state),
      isCurrentGroupCouncil: getIsCurrentGroupCouncil(state),
    }),
    isEqual
  );

  const leadStatusId = lead.statusId || parseInt(defaultStatusId, 10);
  const selectedStatus = useMemo(
    () => statuses.find((status: any) => status.id === leadStatusId, {}),
    [statuses, leadStatusId]
  );
  const { id, abbreviation = '', title = '' } = selectedStatus || {};
  const [avatarSize, textVariant] = useMemo(
    () => (size === 'small' ? [36, 'button'] : [50, 'h6']),
    [size]
  );

  const toggleMenu = useCallback(
    (
      event: MouseEvent<
        HTMLAnchorElement | HTMLButtonElement | HTMLLIElement,
        globalThis.MouseEvent
      >
    ) => {
      event.stopPropagation();
      event.preventDefault();
      canPatchLead && setOpen(isOpen => !isOpen);
    },
    [canPatchLead]
  );
  const toggleJStatusModal = useCallback(() => {
    setOpenJStatusUpdateModal(prevOpen => !prevOpen);
  }, []);

  const handleSelect = useCallback(
    (event: MouseEvent<HTMLLIElement, globalThis.MouseEvent>) => {
      const intJStatusId = toInt10(jStatusId);
      const newSelectedStatusId = event.currentTarget.value;
      if (newSelectedStatusId !== id && newSelectedStatusId !== intJStatusId)
        updateLead({
          id: lead.id,
          statusId: newSelectedStatusId,
        });
      else if (newSelectedStatusId === intJStatusId && isCurrentGroupCouncil) {
        if (openStatusModal) {
          openStatusModal(lead.id);
        } else {
          toggleJStatusModal();
        }
      }
      toggleMenu(event);
    },
    [
      updateLead,
      lead,
      id,
      toggleMenu,
      toggleJStatusModal,
      openStatusModal,
      jStatusId,
      isCurrentGroupCouncil,
    ]
  );
  useEffect(() => {
    onLoadingChange(leadLoading);
  }, [leadLoading, onLoadingChange]);

  const actions = useMemo(() => {
    return [
      {
        id: 'status',
        icon: <SwapVert />,
        tooltipTitle: 'Update Status',
        ref: buttonRef,
        onClick: toggleMenu,
      },
    ];
  }, [toggleMenu]);

  return (
    <>
      {variant === 'fab' && (
        <IconButton
          ref={buttonRef}
          onClick={toggleMenu}
          disabled={!canPatchLead}
          loading={leadLoading}
        >
          <Box
            clone
            bgcolor='common.white'
            color='primary.main'
            width={avatarSize}
            height={avatarSize}
          >
            <Avatar className={classes.fabContainer}>
              {/* @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call. */}
              <Typography variant={textVariant}>{abbreviation}</Typography>
            </Avatar>
          </Box>
        </IconButton>
      )}

      {variant === 'button' && (
        <span ref={buttonRef}>
          <Button
            variant='outlined'
            onClick={toggleMenu}
            disabled={!canPatchLead}
            loading={leadLoading}
          >
            Update Status
          </Button>
        </span>
      )}

      {variant === 'icon' && (
        <Box clone ml='auto' width={32} height={32}>
          <IconButton
            ref={buttonRef}
            onClick={toggleMenu}
            disabled={!canPatchLead}
            loading={leadLoading}
          >
            <Edit />
          </IconButton>
        </Box>
      )}

      {variant === 'label' && (
        <Box>
          <CardActionItem
            label={title}
            startElement={<Typography variant='h6'>{abbreviation}</Typography>}
            displayActions={canPatchLead && shouldDisplayLabelIcon}
            onClick={toggleMenu}
            actions={actions}
            typographyVariant='body2'
            bold
            hover
          />
        </Box>
      )}

      <LightMenu
        anchorEl={buttonRef.current}
        keepMounted
        open={open}
        onClose={toggleMenu}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {statuses.map(status => (
          <MenuItem
            key={status.id}
            value={status.id}
            selected={Boolean(id) && status.id === id}
            onClick={handleSelect}
          >
            {[status.abbreviation, status.title].join(' - ')}
          </MenuItem>
        ))}
      </LightMenu>

      <UpdateStatusModal
        open={openJStatusUpdateModal}
        onClose={toggleJStatusModal}
        leadIds={[lead.id]}
        groupId={currentGroupId}
        isCouncilLeadPage={isCurrentGroupCouncil}
      />
    </>
  );
};

const LightMenu = withStyles(theme => ({
  paper: { backgroundColor: (theme.palette.background as any).lightPaper },
}))(Menu);

const useStyles = makeStyles(theme => {
  const itemSize = 40;
  return {
    fabContainer: {
      boxShadow: '0px 10px 25px 0px rgba(0,0,0,0.5)',
    },
    compactContainer: {
      [theme.breakpoints.only('xs')]: {
        marginRight: 0,
      },
      [theme.breakpoints.only('sm')]: {
        marginRight: '35%',
      },
      [theme.breakpoints.only('md')]: {
        marginRight: 0,
      },
      [theme.breakpoints.only('lg')]: {
        marginRight: '10%',
      },
      [theme.breakpoints.only('xl')]: {
        marginRight: '3%',
      },
    },
    itemContainer: {
      display: 'flex',
      alignItems: 'center',
      border: '1px solid',
      borderRadius: 30,
      borderColor: 'rgba(255,255,255,0.23)',
      minHeight: itemSize,
      marginBottom: 10,
      paddingRight: 15,
      minWidth: 0,
      transition: 'all ease 0.3s',
      '&:hover': {
        backgroundColor: (theme.palette.background as any).lightPaper,
        transition: 'all ease 0.3s',
      },
    },
    itemIcon: {
      alignItems: 'center',
      border: '1px solid',
      borderRadius: '100%',
      borderColor: 'rgba(255,255,255,0.23)',
      display: 'flex',
      height: itemSize,
      width: itemSize,
      minHeight: itemSize,
      minWidth: itemSize,
      justifyContent: 'center',
      marginLeft: -2,
      marginRight: 10,
    },
  };
});

export default memo(LeadStatus, isEqual);
