import React, { useMemo, useEffect, useState, useCallback } from 'react';
import { isEqual } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';

// MUI components
import {
  Grid,
  Card,
  CardContent,
  Hidden,
  WithStyles,
  withStyles,
  useMediaQuery,
} from '@material-ui/core';
import Timeline from '@material-ui/icons/Timeline';
import Group from '@material-ui/icons/Group';
import PieChart from '@material-ui/icons/PieChart';
import Header from 'components/Header';
import Table from 'components/Table';
import ExportButton from 'components/ExportButton';
import StatItem from 'components/StatItem';
import ChapterDropdown from 'components/ChapterDropdown';
import ReportRefreshButton from 'components/ReportRefreshButton';

// action creators
import { createRecruitmentActivityReport } from 'store/actions/apiTaskActions';

// selectors
import getCurrentGroupId from 'store/selectors/getCurrentGroupId';
import getForm from 'store/selectors/getForm';
import getSliceEntityById from 'store/selectors/getSliceEntityById';
import getIsCouncilAdmin from 'store/selectors/getIsCouncilAdmin';
// helpers
import { isEmpty, isArray } from 'helpers/check';
import { toFloat } from 'helpers/transform';
import { HeaderProps } from 'components/Header';
import getTableColumns, {
  TableColumns,
} from 'routes/Analytics/RecruitmentActivityOverviewCard/helpers/getTableColumns';

// styles
import recruitmentActivityOverviewCardStyles from 'routes/Analytics/RecruitmentActivityOverviewCard/recruitmentActivityOverviewCard.style';

export interface RecruitmentActivityOverviewCardProps
  extends WithStyles<typeof recruitmentActivityOverviewCardStyles> {
  statusPositions?: number[];
  reportFormName: string;
  exportFormName: string;
  HeaderProps?: HeaderProps;
  statLabel: string;
  isOrganizationNonJoinerAnalytics?: boolean;
}

export interface FieldsType {
  filterByChapter?: number;
}

const ROWS_PER_PAGE = 5;

const RecruitmentActivityOverviewCard = ({
  classes,
  statusPositions,
  reportFormName,
  exportFormName,
  HeaderProps = {
    title: 'Activity',
    subtitle: 'Explore the joining process story for your leads',
  },
  statLabel,
  isOrganizationNonJoinerAnalytics,
}: RecruitmentActivityOverviewCardProps) => {
  const dispatch = useDispatch();
  const initialOrder = 'asc';
  const initialOrderBy = 'leadFullName';
  const isMaxWidth720 = useMediaQuery('(max-width:720px)');
  const isMaxWidth500 = useMediaQuery('(max-width:500px)');
  const tableColumns: TableColumns[] = getTableColumns();

  const {
    currentGroupId,
    isCouncilAdmin,
    report: { data: { content = [] } = {}, id: reportId },
    form: { isSubmitting, hasSubmitFailed },
  } = useSelector(
    state => ({
      currentGroupId: getCurrentGroupId(state),
      form: getForm(reportFormName)(state),
      isCouncilAdmin: getIsCouncilAdmin(state),
      report: getSliceEntityById(
        'report',
        'ReportTypes.RECRUITMENT_ACTIVITY',
        {}
      )(state),
    }),
    isEqual
  );

  const [fields, setFields] = useState<FieldsType>({
    filterByChapter: currentGroupId,
  });
  const [averagesData, setAveragesData] = useState({});

  const dispatchCreateReport = useCallback(
    ({ forceRefresh }: { forceRefresh: boolean }) => {
      const { filterByChapter } = fields || { filterByChapter: currentGroupId };
      dispatch(
        createRecruitmentActivityReport({
          groupId: filterByChapter,
          statusPositions,
          formName: reportFormName,
          forceRefresh,
        })
      );
    },
    [dispatch, currentGroupId, fields, statusPositions, reportFormName]
  );

  useEffect(() => {
    if (!isEmpty(statusPositions)) {
      dispatchCreateReport({ forceRefresh: false });
    }
  }, [statusPositions, dispatchCreateReport]);

  useEffect(() => {
    if (isArray(content) && !isEmpty(content)) {
      const getAveragesData = () => {
        const data: { [key: string]: string | number | null } = {
          leadFullName: 'Averages',
        };
        tableColumns.forEach(({ key }: TableColumns) => {
          if (key !== 'leadFullName') {
            const total = content.reduce(
              (
                sum: number,
                current: { [key: string]: string | number | null }
              ) => (current?.[key] ? sum + toFloat(current?.[key]) : sum),
              0
            );
            data[key] = total
              ? Math.round((total / content.length) * 100) / 100
              : 0;
          }
        });

        return data;
      };

      setAveragesData(getAveragesData());
    }
  }, [content]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleChangeChapter = (name: string) => (value?: string | number) => {
    setFields({ ...fields, [name]: value });
  };

  const filterByChapterMoreOptions = useMemo(
    () => [
      {
        value: currentGroupId,
        label: 'All Chapters',
      },
    ],
    [currentGroupId]
  );

  const {
    filteredLeadsCount = 0,
    totalLeadsCount = 0,
    filteredLeadsPercentage = 0,
  } = content[0] || {};

  return (
    <Card>
      <CardContent className={classes.cardContent}>
        <Grid container spacing={2}>
          <Grid item xs={12} lg>
            <Header icon={Timeline} compact {...HeaderProps} />
          </Grid>

          {isOrganizationNonJoinerAnalytics ? (
            <Grid
              item
              xs={12}
              {...(isMaxWidth720 ? { sm: 12 } : { sm: true })}
              md
              lg={3}
            >
              <ChapterDropdown
                currentGroupId={currentGroupId}
                input={{
                  name: 'filterByChapter',
                  value: fields?.filterByChapter || currentGroupId,
                  onChange: handleChangeChapter('filterByChapter'),
                }}
                meta={{}}
                moreOptionsFirst={filterByChapterMoreOptions}
                label='Filter by Chapter'
                variant='outlined'
                selectStyle='analyticsList'
                isCouncilAdmin={isCouncilAdmin}
              />
            </Grid>
          ) : null}

          <Hidden xsDown>
            <Grid className={classes.exportButtonGrid} xs={3} item>
              <Grid container justifyContent='flex-end'>
                <Grid item>
                  <ReportRefreshButton
                    loading={isSubmitting}
                    onRefresh={dispatchCreateReport}
                  />
                </Grid>
                <ExportButton
                  params={{ reportId }}
                  disabled={!statusPositions?.length || isSubmitting}
                />
              </Grid>
            </Grid>
            <Grid className={classes.gridFullLine} item xs={12} />
          </Hidden>

          <Grid item xs={isMaxWidth500 ? 12 : 6} sm={5} lg={3} xl={2}>
            <StatItem
              title={
                totalLeadsCount
                  ? `${filteredLeadsCount} / ${totalLeadsCount}`
                  : 'No leads found.'
              }
              subtitle={`${statLabel} / Total Leads`}
              icon={<Group />}
              loading={isSubmitting}
              gutterBottom
            />
          </Grid>
          <Grid item xs={isMaxWidth500 ? 12 : 6} sm={5} lg={3} xl={2}>
            <StatItem
              title={`${filteredLeadsPercentage}%`}
              subtitle={`Percent ${statLabel}`}
              icon={<PieChart />}
              loading={isSubmitting}
              gutterBottom
            />
          </Grid>

          <Grid item xs={12}>
            <Table
              columns={tableColumns}
              data={content}
              variant='outlined'
              emptyMessage={
                hasSubmitFailed
                  ? 'Error: Report Failed To Load'
                  : 'No data found.'
              }
              emptyMessageColor={hasSubmitFailed ? 'error' : 'textSecondary'}
              numberOfSkeletonRows={isSubmitting ? ROWS_PER_PAGE : 0}
              initialOrder={initialOrder}
              initialOrderBy={initialOrderBy}
              TablePaginationProps={{
                count: content.length,
                rowsPerPage: ROWS_PER_PAGE,
                rowsPerPageOptions: [ROWS_PER_PAGE],
              }}
              TableFooterRowProps={{
                ...(!isEmpty(averagesData) ? { data: averagesData } : {}),
              }}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default withStyles(recruitmentActivityOverviewCardStyles, {
  withTheme: true,
})(RecruitmentActivityOverviewCard);
