import React, { useCallback, useEffect } from 'react';
import { Map } from 'immutable';
import PropTypes from 'prop-types';
import { isEqual } from 'lodash';

// hooks
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import useInfiniteScroll from 'helpers/hooks/useInfiniteScroll';

// MUI components
import { makeStyles } from '@material-ui/core/styles';
import { Grid, CircularProgress, Fade } from '@material-ui/core';

// components
import ThreadListHeader from './ThreadListHeader';
import ThreadListItem from './ThreadListItem';
import EmptyMessage from 'components/EmptyMessage';

// action creators
import {
  fetchThreadsAction,
  clearThreadsAction,
} from 'store/actions/threadActions';

// selectors
import getCurrentGroupId from 'store/selectors/getCurrentGroupId';
import getLoadingFromState from 'store/selectors/getLoadingFromState';
import getSliceState from 'store/selectors/getSliceState';

const ThreadList = function({ setMessageView }: any) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { search } = useLocation();
  const newSearch = new URLSearchParams(search);
  const searchParam = newSearch.get('search');
  const {
    currentGroupId,
    hasLeads,
    leadsLoading,
    threads,
    threadsFetching,
    threadsPaginating,
  } = useSelector(
    state => ({
      currentGroupId: getCurrentGroupId(state),
      threads: getSliceState('thread')(state),
      threadsFetching: getLoadingFromState('thread', 'fetch', false)(state),
      threadsPaginating: getLoadingFromState(
        'thread',
        'paginate',
        false
      )(state),
      leadsLoading: getLoadingFromState('lead', null, false)(state),
      hasLeads: (state as any).getIn(['lead', 'data', 'byId'], Map()).size > 0,
    }),
    isEqual
  );
  const [isBottom, scrollRef] = useInfiniteScroll(threadsFetching, 16);

  const renderThreads = () => {
    if (threads.data.length) {
      return threads.data
        .sort((a, b) =>
          a.updatedOn > b.updatedOn ? -1 : a.updatedOn > b.updatedOn ? 1 : 0
        )
        .map((thread = Map()) => {
          return (
            <Fade in={!threadsFetching} key={thread.id}>
              <ThreadListItem thread={thread} setMessageView={setMessageView} />
            </Fade>
          );
        });
    } else {
      return (
        <EmptyMessage
          title='No text messages found'
          description='Send your first message by clicking the write button above'
        />
      );
    }
  };

  const fetchNextPage = useCallback(() => {
    const params = {
      search: searchParam,
      page: threads.page,
      metaId: 'paginate',
    };
    if (currentGroupId) (params as any).groupId = currentGroupId;
    dispatch(fetchThreadsAction(params));
  }, [currentGroupId, searchParam, threads.page, dispatch]);

  useEffect(() => {
    const params = { search: searchParam };
    if (searchParam) dispatch(clearThreadsAction());
    if (currentGroupId) (params as any).groupId = currentGroupId;
    dispatch(fetchThreadsAction(params));
  }, [searchParam]); // eslint-disable-line

  useEffect(() => {
    if (isBottom && threads.page) {
      fetchNextPage();
    }
  }, [isBottom, threads.page, fetchNextPage]); // eslint-disable-line

  return (
    <Grid
      container
      direction='column'
      className={classes.messagesListContainer}
    >
      <Grid item>
        <ThreadListHeader setMessageView={setMessageView} />
      </Grid>

      {threadsFetching || (leadsLoading && !hasLeads) ? (
        <Grid item xs className={classes.progress}>
          <CircularProgress />
        </Grid>
      ) : (
        // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
        <Grid item xs className={classes.threadsContainer} ref={scrollRef}>
          {renderThreads()}
          {threadsPaginating && (
            <Grid item xs className={classes.progress}>
              <CircularProgress />
            </Grid>
          )}
        </Grid>
      )}
    </Grid>
  );
};

ThreadList.propTypes = {
  setMessageView: PropTypes.func.isRequired,
};

const useStyles = makeStyles(theme => {
  return {
    messagesListContainer: {
      height: '100%',
    },
    threadsContainer: {
      overflow: 'scroll',
      [theme.breakpoints.only('xs')]: {
        paddingBottom: 40,
      },
    },
    progress: {
      textAlign: 'center',
      marginTop: 24,
    },
  };
});

export default ThreadList;
