import React, { useCallback } from 'react';
import { useFileQuery } from 'api/files';
import classNames from 'classnames';

// MUI components
import { makeStyles } from '@material-ui/core/styles';
import Avatar, { AvatarProps } from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';

// icon
import PersonIcon from '@material-ui/icons/Person';

interface OwnProps extends Omit<AvatarProps, 'variant'> {
  background?: 'default' | 'gradient' | 'primary' | 'dark';
  children?: React.ReactNode;
  fullName?: string;
  imageId?: number | null;
  imageUrl?: string | null;
  token?: string | null;
  loading?: boolean;
  raised?: boolean;
  size?:
    | 'xsmall'
    | 'small'
    | 'default'
    | 'medium'
    | 'large'
    | 'xlarge'
    | '2xlarge'
    | '3xlarge'
    | 'huge';
  tooltipTitle?: string;
  variant?: 'circle' | 'roundedSquare';
}

export default function SuperAvatar({
  background = 'default',
  fullName,
  children,
  imageId,
  imageUrl,
  token,
  loading = false,
  raised = false,
  size = 'default',
  tooltipTitle = '',
  variant = 'circle',
  ...restOfProps
}: OwnProps) {
  const classes = useStyles();
  let initials;
  if (fullName) {
    const nameArray = fullName.split(' ');
    const firstInitial = nameArray[0][0];
    const lastInitial = nameArray[nameArray.length - 1][0];

    initials = firstInitial + ' ' + lastInitial;
  }
  const { data: image, isLoading: imageIsLoading } = useFileQuery(
    imageId,
    token
  );
  const getSizeClass = useCallback(() => {
    switch (size) {
      case 'xsmall':
        return classes.xsmall;
      case 'small':
        return classes.small;
      case 'medium':
        return classes.medium;
      case 'large':
        return classes.large;
      case 'xlarge':
        return classes.xlarge;
      case '2xlarge':
        return classes['2xlarge'];
      case '3xlarge':
        return classes['3xlarge'];
      case 'huge':
        return classes.huge;
      case 'default':
      default:
        return {};
    }
  }, [classes, size]);
  const getVariantClass = useCallback(() => {
    switch (variant) {
      case 'roundedSquare':
        return classes.roundedSquare;
      default:
      case 'circle':
        return {};
    }
  }, [classes.roundedSquare, variant]);
  const getTypographyVariant = useCallback(() => {
    switch (size) {
      case '2xlarge':
        return 'h3';
      case '3xlarge':
        return 'h2';
      case 'huge':
        return 'h2';
      case 'xsmall':
        return 'subtitle2';
      default:
        return 'h6';
    }
  }, [size]);
  const getBackgroundClass = useCallback(() => {
    switch (background) {
      case 'gradient':
        return classes.gradientTextAvatar;
      case 'primary':
        return classes.primaryTextAvatar;
      case 'dark':
        return classes.darkTextAvatar;
      case 'default':
      default:
        return classes.textAvatar;
    }
  }, [
    background,
    classes.darkTextAvatar,
    classes.gradientTextAvatar,
    classes.primaryTextAvatar,
    classes.textAvatar,
  ]);
  return loading || imageIsLoading ? (
    <Avatar
      id='loadingAvatar'
      className={classNames(
        { [classes.cursorPointer]: Boolean(restOfProps.onClick) },
        getSizeClass(),
        getVariantClass(),
        raised && classes.raised,
        getBackgroundClass()
      )}
    >
      <Grid container justifyContent='center'>
        <Grid item>
          <CircularProgress />
        </Grid>
      </Grid>
    </Avatar>
  ) : (
    <Tooltip title={tooltipTitle}>
      <Avatar
        id='mainAvatar'
        alt={initials}
        src={imageUrl ?? image?.url}
        className={classNames(
          {
            [classes.cursorPointer]: Boolean(restOfProps.onClick),
          },
          getSizeClass(),
          getVariantClass(),
          raised && classes.raised,
          getBackgroundClass()
        )}
        {...restOfProps}
      >
        {children ? (
          children
        ) : (
          <Typography
            className={classes.textAvatarFont}
            variant={getTypographyVariant()}
          >
            {initials || <PersonIcon fontSize='inherit' />}
          </Typography>
        )}
      </Avatar>
    </Tooltip>
  );
}
const useStyles = makeStyles(theme => ({
  cursorPointer: {
    cursor: 'pointer',
  },
  // Size classes
  xsmall: {
    width: 24,
    height: 24,
  },
  small: {
    width: 40,
    height: 40,
  },
  medium: {
    width: 50,
    height: 50,
  },
  large: {
    width: 60,
    height: 60,
  },
  xlarge: {
    width: 75,
    height: 75,
  },
  '2xlarge': {
    width: 125,
    height: 125,
  },
  '3xlarge': {
    width: 170,
    height: 170,
  },
  huge: {
    width: 205,
    height: 205,
  },
  // Variant classes
  roundedSquare: {
    borderRadius: 8,
  },
  // Style classes
  raised: {
    boxShadow: '0 2px 10px 0 rgba(0,0,0,0.5)',
  },
  gradientTextAvatar: {
    background: `linear-gradient(140.28deg, ${theme.palette.primary.main} 0%, ${theme.palette.secondary.main} 100%)`,
    color: theme.palette.common.white,
  },
  primaryTextAvatar: {
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  darkTextAvatar: {
    background: (theme.palette.background as any).darkPaper,
    color: theme.palette.common.white,
  },
  textAvatar: {
    background: theme.palette.common.white,
    color: theme.palette.primary.main,
  },
  textAvatarFont: {
    letterSpacing: -2,
    fontWeight: 600,
  },
}));
