import React from 'react';

// components
import ConfirmGuestsTable from './ConfirmGuestsTable';

// material-ui
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

type Guest = { id: number; fullName: string };

export default function ConfirmGuestsStep({
  LeadProps,
  MemberProps,
}: {
  LeadProps: {
    allLeadsSelected: boolean;
    handleDeselectLead: (guest: Guest) => void;
    selectedLeads: Guest[];
    hasLeadFilters: boolean;
  };
  MemberProps: {
    allMembersSelected: boolean;
    handleDeselectAccount: (guest: Guest) => void;
    selectedAccounts: Guest[];
    hasMemberFilters: boolean;
  };
}) {
  const {
    allLeadsSelected,
    handleDeselectLead,
    selectedLeads,
    hasLeadFilters,
  } = LeadProps;
  const {
    allMembersSelected,
    handleDeselectAccount,
    selectedAccounts,
    hasMemberFilters,
  } = MemberProps;
  const classes = useStyles();
  return (
    <Grid
      container
      justifyContent='flex-start'
      className={classes.container}
      spacing={2}
    >
      <Grid item xs={12}>
        <Typography variant='h6'>Confirm Invites</Typography>
        <Typography variant='body2' color='textSecondary'>
          Review your invites, remove, or go back to add more.
        </Typography>
      </Grid>

      <Grid item xs={12} md={6}>
        <ConfirmGuestsTable
          allSelected={allLeadsSelected}
          hasFilters={hasLeadFilters}
          content={selectedLeads}
          title='Leads'
          onDeselect={handleDeselectLead}
          allSelectedMessage={
            allLeadsSelected
              ? `When all ${
                  hasLeadFilters ? 'filtered' : ''
                } leads selected, no data is displayed here.`
              : '0 leads selected'
          }
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <ConfirmGuestsTable
          allSelected={allMembersSelected}
          hasFilters={hasMemberFilters}
          content={selectedAccounts}
          title='Members'
          onDeselect={handleDeselectAccount}
          allSelectedMessage={
            allMembersSelected
              ? `When all ${
                  hasMemberFilters ? 'filtered' : ''
                } members selected, no data is displayed here.`
              : '0 members selected'
          }
        />
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles(theme => {
  return {
    container: {
      paddingLeft: 32,
      paddingRight: 32,
      [theme.breakpoints.only('xs')]: {
        paddingLeft: 16,
        paddingRight: 16,
      },
    },
  };
});
