import { Map, List } from 'immutable';

export default function removeByLabel(state: any, action: any, options = {}) {
  // the payload contains a label string
  const { payload = Map() } = action;

  // @ts-expect-error ts-migrate(2339) FIXME: Property 'result' does not exist on type '{}'.
  const { result = 'result' } = options;

  let newState = state;

  const groupFieldIdToDelete = state
    .getIn(['data', 'byId'], Map())
    .find(
      (groupField: any) => groupField.get('label') === payload.get('label'),
      {}, // context
      Map() // not set value
    )
    .get('id', '')
    .toString();

  if (groupFieldIdToDelete) {
    const oldResult = state.getIn(['data', result], List());
    let newResult = List();

    // Remove field from results
    newResult = oldResult.delete(
      oldResult.findIndex(
        (item: any) => item.toString() === groupFieldIdToDelete
      )
    );
    newState = newState.setIn(['data', result], newResult);

    // Merge the new items with the state's original `byId` map
    const itemsById = newState.getIn(['data', 'byId'], Map());

    newState = newState.setIn(
      ['data', 'byId'],
      itemsById.delete(groupFieldIdToDelete)
    );
  }

  return newState;
}
