import {
  GROUP_TAG_FETCH_REQUEST,
  GROUP_TAG_CREATE_REQUEST,
  GROUP_TAG_DELETE_REQUEST,
  GROUP_TAG_CLEAR,
} from 'store/constants/groupTagTypes';

export function fetchGroupTagsAction({ groupId, parentId }: any) {
  return {
    type: GROUP_TAG_FETCH_REQUEST,
    payload: { groupId, parentId },
  };
}

export function createGroupTagsAction({
  groupId,
  parentId,
  tagIds,
  formName,
}: any) {
  return {
    type: GROUP_TAG_CREATE_REQUEST,
    payload: { groupId, parentId, tagIds, formName },
  };
}

export function deleteGroupTagAction({ groupId, tagId, formName }: any) {
  return {
    type: GROUP_TAG_DELETE_REQUEST,
    payload: { groupId, tagId, formName },
  };
}

export function clearGroupTagsAction() {
  return {
    type: GROUP_TAG_CLEAR,
  };
}
