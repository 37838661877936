import getCurrentParentCouncil from './getCurrentParentCouncil';

export default function getCurrentParentCouncilName(state: any) {
  const parent = getCurrentParentCouncil(state);
  if (parent != null && parent?.displayName) {
    return parent.displayName;
  } else {
    return '';
  }
}
