export const USER_REGISTER_REQUEST = 'USER_REGISTER_REQUEST';
export const USER_REGISTER = 'USER_REGISTER';

export const USER_VERIFY_EMAIL_REQUEST = 'USER_VERIFY_EMAIL_REQUEST';
export const USER_VERIFY_EMAIL = 'USER_VERIFY_EMAIL';

export const CURRENT_USER_ADD = 'CURRENT_USER_ADD';

export const CURRENT_USER_FETCH_REQUEST = 'CURRENT_USER_FETCH_REQUEST';
export const CURRENT_USER_FETCH = 'CURRENT_USER_FETCH';

export const USER_FETCH_BY_IDS_REQUEST = 'USER_FETCH_BY_IDS_REQUEST';
export const USER_FETCH_BY_IDS = 'USER_FETCH_BY_IDS';

export const SYSTEM_ADMIN_FETCH_REQUEST = 'SYSTEM_ADMIN_FETCH_REQUEST';
export const SYSTEM_ADMIN_FETCH = 'SYSTEM_ADMIN_FETCH';

export const CURRENT_USER_UPDATE_REQUEST = 'CURRENT_USER_UPDATE_REQUEST';
export const CURRENT_USER_UPDATE = 'CURRENT_USER_UPDATE';

export const USER_CLEAR = 'USER_CLEAR';

export const CURRENT_USER_UPDATE_PASSWORD_REQUEST =
  'CURRENT_USER_UPDATE_PASSWORD_REQUEST';
export const CURRENT_USER_UPDATE_PASSWORD = 'CURRENT_USER_UPDATE_PASSWORD';
