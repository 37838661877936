import React from 'react';
// redux-hooks
import { useSelector } from 'react-redux';
// material-ui
import { makeStyles } from '@material-ui/core/styles';
// components
import { Grid, Typography, Fab } from '@material-ui/core';
// icons
import { ThumbDown, ThumbUp } from '@material-ui/icons';
// selectors
import getCurrentGroup from 'store/selectors/getCurrentGroup';
import getSliceEntityById from 'store/selectors/getSliceEntityById';
type Props = {
  input?: {
    name?: string;
    onChange: (...args: any[]) => any;
    value?: number;
  };
};
// @ts-expect-error ts-migrate(2339) FIXME: Property 'value' does not exist on type '{ name?: ... Remove this comment to see the full error message
export default function Endorse({ input: { value, onChange } }: Props) {
  const classes = useStyles();
  const currentGroup = useSelector(getCurrentGroup);
  const currentVoteMethod = useSelector(
    getSliceEntityById('voteMethod', (currentGroup as any).get('voteMethodId'))
  );
  const handleClick = (newValue: any) => () => onChange(newValue);

  return (
    <Grid container spacing={3}>
      {(currentVoteMethod as any).options
        .sort((a: any, b: any) =>
          (currentVoteMethod as any).scoreAlgorithm === 'sum'
            ? b.value - a.value
            : a.value - b.value
        )
        .map((option: any) => (
          <Grid item key={option.value}>
            <Fab
              color='inherit'
              disabled={option.value === value}
              classes={classes}
              onClick={handleClick(option.value)}
            >
              {(currentVoteMethod as any).scoreAlgorithm === 'sum' ? (
                option.value === 1 ? (
                  <ThumbUp />
                ) : (
                  <ThumbDown />
                )
              ) : (
                <Typography variant='h6'>{option.value}</Typography>
              )}
            </Fab>
          </Grid>
        ))}
    </Grid>
  );
}
const useStyles = makeStyles(theme => ({
  root: {
    background: (theme.palette.background as any).lightPaper,
    '&$disabled': {
      background: (theme.palette.background as any).gradient,
      color: theme.palette.text.primary,
    },
  },
  disabled: {},
}));
