import {
  COMMENT_FETCH_REQUEST,
  COMMENT_CREATE_REQUEST,
  COMMENT_UPDATE_REQUEST,
  COMMENT_DELETE_REQUEST,
  COMMENT_CLEAR,
} from 'store/constants/commentTypes';

export function fetchCommentsAction({ activityId, metaId, page }: any) {
  return {
    type: COMMENT_FETCH_REQUEST,
    payload: { activityId, page, metaId },
  };
}

export function createCommentAction({ fields, metaId }: any) {
  return {
    type: COMMENT_CREATE_REQUEST,
    payload: { fields, metaId },
  };
}

export function updateCommentAction({ id, fields }: any) {
  return {
    type: COMMENT_UPDATE_REQUEST,
    payload: { id, fields },
  };
}

export function deleteCommentAction({ id }: any) {
  return {
    type: COMMENT_DELETE_REQUEST,
    payload: { id },
  };
}

export function clearCommentsAction() {
  return {
    type: COMMENT_CLEAR,
  };
}
