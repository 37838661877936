import { all } from 'redux-saga/effects';

// sagas
import createFormFieldSaga from './createFormFieldSaga';
import deleteFormFieldSaga from './deleteFormFieldSaga';
import fetchFormFieldsSaga from './fetchFormFieldsSaga';
import updateFormFieldsSaga from './updateFormFieldsSaga';

const formFieldSaga = function* rootSaga() {
  yield all([
    createFormFieldSaga(),
    deleteFormFieldSaga(),
    fetchFormFieldsSaga(),
    updateFormFieldsSaga(),
  ]);
};

export default formFieldSaga;
