import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { useHistory } from 'react-router';
import { useLeadDuplicatesQuery } from 'api/leadDuplicates';

// components
import { Grid, Box, Paper, Typography, IconButton } from '@material-ui/core';
import { KeyboardArrowRight, KeyboardArrowLeft } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import DuplicateLeads from './DuplicateLeads';
import queryString from 'query-string';

export default function LeadDuplicatesView() {
  const history = useHistory();
  const searchObject = useMemo(
    () => queryString.parse(history.location.search),
    [history.location.search]
  );
  const [isRefetchingAfterDelete, setIsRefetchingAfterDelete] = useState(false);
  const [index, setIndex] = useState(
    searchObject.index ? Number(searchObject.index) : 1
  );
  const {
    data,
    isLoading: isGettingDuplicates,
    refetch,
    isFetching,
  } = useLeadDuplicatesQuery(index);
  const isLoading = isGettingDuplicates || isRefetchingAfterDelete;
  const goToPrevPage = useCallback(() => setIndex(index => index - 1), []);
  const goToNextPage = useCallback(() => setIndex(index => index + 1), []);
  const handleDelete = useCallback(() => {
    if (data?.total === 1) {
      history.push('/leads');
    } else if (index === data?.total) {
      setIndex(index - 1);
    } else {
      setIsRefetchingAfterDelete(true);
      refetch();
    }
  }, [history, data, index, refetch]);

  useEffect(() => {
    searchObject.index = String(index);
    history.replace({
      pathname: history.location.pathname,
      search: queryString.stringify(searchObject),
    });
  }, [history, index, searchObject]);

  useEffect(() => {
    if (searchObject.index && data?.total) {
      if (Number(searchObject.index) > data.total) setIndex(data.total);
    }
  }, [searchObject, data]);

  useEffect(() => {
    if (!isFetching) setIsRefetchingAfterDelete(false);
  }, [isFetching]);
  return (
    <Box
      marginY={3}
      padding={3}
      width='fill-available'
      height='max-content'
      minHeight='calc(100% - 48px)'
      flex='0 0 100%'
      flexDirection='column'
      display='flex'
      clone
    >
      <Paper>
        <Box clone display='flex' height='100%' flexGrow='1'>
          <Grid container direction='column' spacing={1} wrap='nowrap'>
            <Grid item xs>
              <DuplicateLeads
                leads={data?.results || []}
                isLeadsLoading={isLoading}
                onDelete={handleDelete}
              />
            </Grid>
            <Grid item>
              <Box clone height='100%'>
                <Grid
                  container
                  direction='column'
                  justifyContent='flex-end'
                  alignItems='flex-end'
                >
                  <Grid item>
                    <Grid container direction='column' alignItems='flex-end'>
                      <Grid item>
                        {isLoading ? (
                          <Skeleton width={230} />
                        ) : (
                          <Typography variant='body2' color='textSecondary'>
                            View more leads with duplicates
                          </Typography>
                        )}
                      </Grid>
                      <Grid item>
                        {isLoading ? (
                          <Skeleton width={140} height={48} />
                        ) : (
                          <Grid container alignItems='center' spacing={1}>
                            <Grid item>
                              <Typography variant='body2'>
                                {index} - {data?.total}
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Grid container>
                                <Grid item>
                                  <IconButton
                                    disabled={index === 1}
                                    onClick={goToPrevPage}
                                  >
                                    <KeyboardArrowLeft />
                                  </IconButton>
                                </Grid>
                                <Grid item>
                                  <IconButton
                                    disabled={index === data?.total}
                                    onClick={goToNextPage}
                                  >
                                    <KeyboardArrowRight />
                                  </IconButton>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Box>
  );
}
