import React, { memo, useCallback, useMemo } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import PropTypes from 'prop-types';
import validate from 'validate.js';
// material-ui
import Grid from '@material-ui/core/Grid';
// icons
import { SwapVert } from '@material-ui/icons';
//components
import DazzlingDialog from 'components/DazzlingDialog';
import TextField from 'components/TextField';
import Switch from 'components/Switch';
import { Form, Field } from 'react-final-form';
// actions
import { createStatusAction } from 'store/actions/statusActions';
// selectors
import getCurrentGroupId from 'store/selectors/getCurrentGroupId';
import getDefaultStatusId from 'store/selectors/getDefaultStatusId';
const FORM = 'createStatusForm';
const SCHEMA = {
  abbreviation: {
    presence: true,
    length: { maximum: 4 },
  },
  title: {
    presence: true,
    length: { maximum: 128 },
  },
  description: {
    length: { maximum: 1024 },
  },
};
const DIALOG_DATA = {
  icon: SwapVert,
  title: 'New Status',
  subtitle: `Create a status that represents your lead's statuses`,
};

type Props = {
  open: boolean;
  onClose: () => void;
};

const CreateStatusModal = memo(({ open, onClose }: Props) => {
  const {
    actions: { createStatus },
    data: { currentGroupId, defaultStatusId },
  } = useRedux();

  const validateStatusFields = useCallback(
    values => validate(values, SCHEMA),
    []
  );

  const handleFormSubmit = useCallback(
    values => {
      createStatus({
        formName: FORM,
        groupId: currentGroupId,
        defaultStatusId,
        values,
      });
    },
    [createStatus, currentGroupId, defaultStatusId]
  );

  return (
    <Form
      validate={validateStatusFields}
      onSubmit={handleFormSubmit}
      render={({ handleSubmit, invalid, form: { reset } }) => (
        <DazzlingDialog
          acceptLabel='Create'
          formName={FORM}
          handleClose={onClose}
          headerProps={DIALOG_DATA}
          onAccept={handleSubmit}
          disabled={invalid}
          onExited={reset}
          open={Boolean(open)}
        >
          <Grid
            container
            spacing={2}
            alignItems='center'
            justifyContent='space-between'
          >
            <Grid item>
              <Field
                name='abbreviation'
                label='Abbreviation'
                variant='outlined'
                component={TextField}
              />
            </Grid>
            <Grid item>
              <Field
                name='isDefault'
                label='Default'
                type='checkbox'
                component={Switch}
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                name='title'
                label='Title'
                variant='outlined'
                component={TextField}
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                name='description'
                label='Description'
                variant='outlined'
                component={TextField}
              />
            </Grid>
          </Grid>
        </DazzlingDialog>
      )}
    />
  );
});
(CreateStatusModal as any).propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};
const useRedux = () => {
  const dispatch = useDispatch();
  const actions = useMemo(
    () => ({
      createStatus: (payload: any) => dispatch(createStatusAction(payload)),
    }),
    [dispatch]
  );
  const data = useSelector(
    state => ({
      currentGroupId: getCurrentGroupId(state),
      defaultStatusId: getDefaultStatusId(state),
    }),
    shallowEqual
  );
  return { actions, data };
};
export default CreateStatusModal;
