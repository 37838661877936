import React from 'react';
import PropTypes from 'prop-types';

// components
import ForgotPasswordForm from './components/ForgotPasswordForm';
import ForgotPasswordHeader from './components/ForgotPasswordHeader';
import Landing from 'layout/Landing';

const ForgotPassword = function({
  match: {
    params: { token },
  },
}: any) {
  const stage = token ? 'passwordReset' : 'sendEmail';

  return (
    <Landing>
      <ForgotPasswordHeader stage={stage} />
      <ForgotPasswordForm stage={stage} token={token} />
    </Landing>
  );
};

ForgotPassword.propTypes = {
  match: PropTypes.instanceOf(Object).isRequired,
};

ForgotPassword.defaultProps = {
  match: { params: {} },
};

export default ForgotPassword;
