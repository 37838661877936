import { all, call, fork, put, takeEvery, select } from 'redux-saga/effects';

// action creators
import { doneIndicator } from 'store/actions/httpActions';

// api
import client from 'sources/api';

// constants
import {
  TASK_UNCOMPLETE_REQUEST,
  TASK_UNCOMPLETE,
} from 'store/constants/taskTypes';

// selectors
import getCurrentGroupId from 'store/selectors/getCurrentGroupId';

// schemas
import { taskSchema } from 'store/schemas/taskSchema';

// helpers
import httpSaga from 'store/sagas/httpSaga';

const WATCH_TYPE = TASK_UNCOMPLETE_REQUEST;
const TYPE = TASK_UNCOMPLETE;

export function* uncompleteTaskSaga(action: any) {
  const {
    payload: { taskId },
  } = action;

  const currentGroupId = yield select(getCurrentGroupId);

  // @ts-expect-error ts-migrate(2569) FIXME: Type 'Generator<any, any, unknown>' is not an arra... Remove this comment to see the full error message
  yield* httpSaga(
    TYPE,
    call(client.post, `/todos/${taskId}/uncomplete?group_id=${currentGroupId}`),
    {
      metaId: `uncomplete-${taskId}`,
      schema: taskSchema,
    }
  );

  yield put(doneIndicator(TYPE));
}

export function* watch() {
  yield takeEvery(WATCH_TYPE, uncompleteTaskSaga);
}

export default function* root() {
  yield all([fork(watch)]);
}
