import { call, all, fork, put, takeEvery } from 'redux-saga/effects';
import Immutable from 'immutable';
// action creators
import {
  error,
  errorIndicator,
  success,
  successIndicator,
  doneIndicator,
} from 'store/actions/httpActions';

// api
import client from 'sources/api';

// constants
import {
  CURRENT_USER_FETCH_REQUEST,
  CURRENT_USER_FETCH,
} from 'store/constants/userTypes';

// sagas
import httpSaga from 'store/sagas/httpSaga';

const TYPE = CURRENT_USER_FETCH;
const WATCH_TYPE = CURRENT_USER_FETCH_REQUEST;

export function* fetchCurrentUser(action: any) {
  const {
    payload: { currentGroupId = '' },
  } = action;

  try {
    const requestConfig = {};

    if (currentGroupId) {
      Object.assign(requestConfig, { params: { group_id: currentGroupId } });
    }

    const {
      data: {
        account: currentUserAccount,
        groupIds: currentUserGroupIds,
        ...rest
      },
      // @ts-expect-error ts-migrate(2569) FIXME: Type 'Generator<any, any, unknown>' is not an arra... Remove this comment to see the full error message
    } = yield* httpSaga(TYPE, call(client.get, '/auth/me', requestConfig), {
      dispatchSuccess: false,
      throwError: true,
    });

    const {
      data: { results: currentUserEmails },
    } = yield call(client.get, '/emails');

    const currentUser = {
      ...currentUserAccount,
      groupIds: currentUserGroupIds,
      emails: currentUserEmails,
      ...rest,
    };

    yield put(successIndicator(TYPE));
    yield put(success(TYPE, Immutable.fromJS(currentUser)));
  } catch (err) {
    yield put(errorIndicator(TYPE));
    yield put(error(TYPE, err));
  } finally {
    yield put(doneIndicator(TYPE));
  }
}

export function* watch() {
  yield takeEvery(WATCH_TYPE, fetchCurrentUser);
}

export default function* root() {
  yield all([fork(watch)]);
}
