import Immutable from 'immutable';
import { denormalize } from 'normalizr';

import { messageSchema } from '../schemas/messageSchema';
import createDeepEqualSelector from 'helpers/createDeepEqualSelector';
import getSliceItems from './getSliceItems';
import getSliceResult from './getSliceResult';

export default function getMessagesForThread(threadId: any) {
  return createDeepEqualSelector(
    [getSliceResult('message'), getSliceItems('message')],
    (result: any, messages: any) => {
      const entities = {
        message: messages.toJS(),
      };

      let items = denormalize(result.toJS(), [messageSchema], entities);

      items = items.filter(
        (item: any) => item.threadId.toString() === threadId.toString()
      );

      return Immutable.fromJS(items).sortBy((item: any) =>
        item.get('createdOn')
      );
    }
  );
}
