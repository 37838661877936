import { all } from 'redux-saga/effects';

// sagas
import importLeadsSaga from 'store/sagas/lead/importLeadsSaga';
import updateManuallyPushLeadsSaga from 'store/sagas/lead/updateManuallyPushLeadsSaga';

const sessionSaga = function* rootSaga() {
  yield all([importLeadsSaga(), updateManuallyPushLeadsSaga()]);
};

export default sessionSaga;
