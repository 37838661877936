import { Styles, Theme } from 'assets/types';

const referralFormStyles: Styles = theme => ({
  backgroundMain: {
    height: 'max-content',
    background: `linear-gradient(315deg, ${theme.palette.secondary.main} 0%, ${theme.palette.primary.main} 100%)`,
  },
  backgroundTriangle: {
    position: 'fixed',
    width: 0,
    height: 0,
    left: '-970vw',
    top: '15vh',
    borderLeft: '1070vw solid transparent',
    borderBottom: `178vh solid ${
      (theme as Theme).palette.background.darkPaper
    }`,
  },
  backgroundTriangleBottom: {
    position: 'absolute',
    width: 0,
    height: 0,
    left: 0,
    top: '75%',
    borderRight: '170vw solid transparent',
    borderTop: `25vh solid ${(theme as Theme).palette.background.darkPaper}`,
  },
  toggleFormButton: {
    position: 'fixed',
    bottom: 20,
    right: 20,
    zIndex: 10,
    '&.viewBtn': {
      [theme.breakpoints.only('sm')]: {
        display: 'none',
      },
      '&.minHeightBtn': {
        [theme.breakpoints.up('md')]: {
          display: 'none',
        },
      },
    },
  },
  backButton: {
    position: 'fixed',
    left: 23,
    top: 26,
  },
});

export default referralFormStyles;
