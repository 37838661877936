import React from 'react';

// material-ui
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

// components
import FormSettings from './FormSettings';
import Header from 'components/Header';

export default function FormsTab() {
  const classes = useStyles();
  return (
    <Grid
      container
      justifyContent='flex-start'
      spacing={2}
      className={classes.container}
    >
      <Grid item xs={12}>
        <Header
          title='Sign-up Form'
          subtitle='Forms with one time slot will prompt guests to RSVP, multiple time slots will prompt guests to select a time'
          compact
        />
      </Grid>

      <Grid item xs={12}>
        <FormSettings />
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles(theme => {
  return {
    container: {
      paddingLeft: 32,
      paddingRight: 32,
      [theme.breakpoints.only('xs')]: {
        paddingLeft: 16,
        paddingRight: 16,
      },
    },
  };
});
