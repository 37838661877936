import { Map } from 'immutable';
import { all, call, fork, put, takeEvery, select } from 'redux-saga/effects';

// action creators
import { doneIndicator, success } from 'store/actions/httpActions';

// selector
import getCurrentGroupId from 'store/selectors/getCurrentGroupId';

// api
import client from 'sources/api';

// constants
import { NOTE_DELETE_REQUEST, NOTE_DELETE } from 'store/constants/noteTypes';

// schemas
import { noteSchema } from 'store/schemas/noteSchema';

// helpers
import httpSaga from 'store/sagas/httpSaga';

const WATCH_TYPE = NOTE_DELETE_REQUEST;
const TYPE = NOTE_DELETE;

export function* deleteNote(action: any) {
  const {
    payload: { id },
  } = action;
  const currentGroupId = yield select(getCurrentGroupId);
  const metaId = `delete-${id}`;

  // @ts-expect-error ts-migrate(2569) FIXME: Type 'Generator<any, any, unknown>' is not an arra... Remove this comment to see the full error message
  yield* httpSaga(
    TYPE,
    call(client.delete, `/notes/${id}?group_id=${currentGroupId}`),
    {
      dispatchSuccess: false,
      schema: noteSchema,
      metaId,
    }
  );

  yield put(success(TYPE, Map({ id }), metaId));
  yield put(doneIndicator(TYPE));
}

export function* watch() {
  yield takeEvery(WATCH_TYPE, deleteNote);
}

export default function* root() {
  yield all([fork(watch)]);
}
