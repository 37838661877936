import { Map } from 'immutable';
// constants
import {
  EVENT_CREATE,
  EVENT_DELETE,
  EVENT_UPDATE,
  EVENT_FETCH_FOR_GROUP,
  EVENT_FETCH_BY_ID,
  CLEAR_EVENT,
} from 'store/constants/eventTypes';
// helpers
import httpReducer from './helpers/httpReducer';
import remove from './callbacks/remove';
import update from './callbacks/update';
export default function reduceEvent(state = Map(), action = {}) {
  switch ((action as any).type) {
    case EVENT_DELETE:
      return httpReducer(state, action, {
        entity: 'event',
        callback: remove,
      });
    case EVENT_CREATE:
      return httpReducer(state, action, {
        entity: 'event',
      });
    case EVENT_UPDATE:
      return httpReducer(state, action, {
        entity: 'event',
        updateResult: false,
        callback: update,
      });
    case EVENT_FETCH_FOR_GROUP:
    case EVENT_FETCH_BY_ID:
      return httpReducer(state, action, {
        entity: 'event',
      });
    case CLEAR_EVENT:
      return Map();
    default:
      return state;
  }
}
