import React, { useCallback } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import validate from 'validate.js';

// material-ui
import Grid from '@material-ui/core/Grid';

// icons
import DeleteIcon from '@material-ui/icons/Delete';

//components
import DazzlingDialog from 'components/DazzlingDialog';
import TextField from 'components/TextField';
import { Form, Field } from 'react-final-form';

// action creators
import { deleteAllMembersForGroup } from 'store/actions/memberActions';
import getCurrentGroupId from 'store/selectors/getCurrentGroupId';

const FORM = 'deleteAllMembersForm';

type Props = {
  onClose: (...args: any[]) => any;
  open: boolean;
};

const DeleteAllMembersModal = ({ open, onClose }: Props) => {
  const { groupId } = useSelector(
    state => ({
      groupId: getCurrentGroupId(state),
    }),
    shallowEqual
  );

  const dispatch = useDispatch();
  const dispatchDeleteAllMembersForm = useCallback(
    values => {
      dispatch(deleteAllMembersForGroup({ groupId }));
    },
    [dispatch, groupId]
  );

  const dialogContent = (values: any) => {
    return (
      <Grid container spacing={2} direction='column'>
        <Grid item xs={12} sm={6}>
          <Field
            name='deleteConfirmation'
            label='Type DELETE'
            variant='outlined'
            component={TextField}
          />
        </Grid>
      </Grid>
    );
  };

  return (
    <Form
      id='deleteAllMembersForm'
      validate={values => validate(values, schema)}
      onSubmit={values => dispatchDeleteAllMembersForm(values)}
      render={({ handleSubmit, invalid, values }) => (
        <DazzlingDialog
          id='deleteAllMembersForm'
          acceptLabel='Delete'
          formName={FORM}
          handleClose={onClose}
          headerProps={dialogData}
          height='short'
          onAccept={handleSubmit}
          disabled={invalid}
          submitOnEnter={false}
          open={open}
        >
          {dialogContent(values)}
        </DazzlingDialog>
      )}
    />
  );
};

const dialogData = {
  icon: DeleteIcon,
  title: 'Delete All Members',
  subtitle:
    'Are you sure you want to remove all current members from your chapter?',
  highlightedSubtitle: 'Type "DELETE" into the field below to confirm.',
};

const schema = {
  deleteConfirmation: {
    presence: true,
    inclusion: {
      within: { DELETE: 'deleteConfirmation' },
      message: 'is %{value}, DELETE must be entered',
    },
  },
};

export default DeleteAllMembersModal;
