import React, { useEffect } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { useLeadsQuery } from 'api/leads';
import { useGroupsQuery } from 'api/groups';

// MUI components
import {
  Grid,
  Card,
  CardContent,
  WithStyles,
  withStyles,
} from '@material-ui/core';
import {
  AccountBalance,
  Group,
  GroupOutlined,
  InsertChart,
} from '@material-ui/icons';

// components
import Header from 'components/Header';
import StatItem from 'components/StatItem';

// action creators
import { fetchMembersForGroup } from 'store/actions/memberActions';

// selectors
import getCurrentGroupId from 'store/selectors/getCurrentGroupId';
import getTotal from 'store/selectors/getTotal';
import getIsCurrentGroupCouncil from 'store/selectors/getIsCurrentGroupCouncil';
import getIsCurrentGroupOrganization from 'store/selectors/getIsCurrentGroupOrganization';

// styles
import groupOverviewCardStyle from 'routes/GroupAnalytics/components/GroupOverviewCard/groupOverviewCard.style';

export interface GroupOverviewCardProps
  extends WithStyles<typeof groupOverviewCardStyle> {}

const GroupOverviewCard = ({ classes }: GroupOverviewCardProps) => {
  const dispatch = useDispatch();

  const {
    currentGroupId,
    totalMembers,
    isCurrentGroupCouncil,
    isCurrentGroupOrganization,
  } = useSelector(
    state => ({
      currentGroupId: getCurrentGroupId(state),
      totalMembers: getTotal('member')(state)?.toString(),
      isCurrentGroupCouncil: getIsCurrentGroupCouncil(state),
      isCurrentGroupOrganization: getIsCurrentGroupOrganization(state),
    }),
    shallowEqual
  );

  const { data: leads } = useLeadsQuery();
  const { data: chapters } = useGroupsQuery();

  useEffect(() => {
    dispatch(fetchMembersForGroup({ groupId: currentGroupId }));
  }, [currentGroupId, dispatch]);

  const isCouncilOrOrganization =
    isCurrentGroupCouncil || isCurrentGroupOrganization;

  return (
    <Card className={classes.container}>
      <CardContent className={classes.cardContent}>
        <Grid container>
          <Grid item xs={12}>
            <Header
              title={isCouncilOrOrganization ? 'Overview' : 'Group Overview'}
              icon={isCouncilOrOrganization ? InsertChart : AccountBalance}
              compact
            />
          </Grid>
          {isCouncilOrOrganization && (
            <StatItem
              title={chapters?.total ? String(chapters.total) : '0'}
              subtitle='Chapters'
              icon={<AccountBalance />}
              gutterBottom
            />
          )}
          <StatItem
            title={leads?.total ? String(leads.total) : '0'}
            subtitle='Leads'
            icon={<Group />}
            gutterBottom
          />
          <StatItem
            title={totalMembers}
            subtitle={isCouncilOrOrganization ? 'Users' : 'Members'}
            icon={<GroupOutlined />}
          />
        </Grid>
      </CardContent>
    </Card>
  );
};

export default withStyles(groupOverviewCardStyle, { withTheme: true })(
  GroupOverviewCard
);
