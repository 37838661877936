import { Map } from 'immutable';

export default function removeAttributeByLabel(state: any, action: any) {
  // the payload contains a label string
  const { payload = Map() } = action;

  let newState = state;
  const entity = payload.get('entity', '').toString();
  const idToUpdate = payload.getIn(['data', 'result'], '').toString();

  const labelToDelete = payload.getIn(
    ['data', 'entities', entity, idToUpdate, 'label'],
    ''
  );

  if (idToUpdate) {
    // Merge the new items with the state's original `byId` map
    const item = newState.getIn(['data', 'byId', idToUpdate], Map());

    newState = newState.setIn(
      ['data', 'byId', idToUpdate],
      item.delete(labelToDelete)
    );
  }

  return newState;
}
