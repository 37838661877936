import { all } from 'redux-saga/effects';

// sagas
import fetchStatusesSaga from './fetchStatusesSaga';
import createStatusSaga from './createStatusSaga';
import deleteStatusesSaga from './deleteStatusesSaga';
import updateStatusesSaga from './updateStatusesSaga';

const statusSaga = function* rootSaga() {
  yield all([
    fetchStatusesSaga(),
    createStatusSaga(),
    deleteStatusesSaga(),
    updateStatusesSaga(),
  ]);
};

export default statusSaga;
