import {
  REFERRAL_FORM_CREATE_REQUEST,
  REFERRAL_FORM_UPDATE_REQUEST,
  REFERRAL_FORM_FETCH_FOR_GROUP_REQUEST,
  REFERRAL_FORM_FETCH_BY_ID_REQUEST,
  REFERRAL_FORM_SUBMIT_REQUEST,
  REFERRAL_FORM_DELETE_REQUEST,
  REFERRAL_FORM_SEND_PREVIEW_EMAIL_REQUEST,
  REFERRAL_FORM_CREATE_TOKEN_REQUEST,
} from 'store/constants/referralFormTypes';

export function createReferralForm({ groupId, formName, ...values }: any) {
  return {
    type: REFERRAL_FORM_CREATE_REQUEST,
    payload: { groupId, formName, ...values },
  };
}

export function deleteReferralForm({ formId }: any) {
  return {
    type: REFERRAL_FORM_DELETE_REQUEST,
    payload: { formId },
  };
}

export function updateReferralForm({
  groupId,
  formId,
  formName,
  noFormFields,
  isCouncilForm,
  isOrganizationForm,
  isUniversalForm,
  ...values
}: any) {
  return {
    type: REFERRAL_FORM_UPDATE_REQUEST,
    payload: {
      groupId,
      formId,
      formName,
      noFormFields,
      isCouncilForm,
      isOrganizationForm,
      isUniversalForm,
      ...values,
    },
  };
}

export function fetchReferralFormsForGroup({
  groupId,
  page = 1,
}: {
  groupId: number;
  page?: number;
}) {
  return {
    type: REFERRAL_FORM_FETCH_FOR_GROUP_REQUEST,
    payload: { groupId, page },
  };
}

export function fetchReferralFormById({ groupId, formId, token }: any) {
  return {
    type: REFERRAL_FORM_FETCH_BY_ID_REQUEST,
    payload: { groupId, formId, token },
  };
}

export function submitReferralForm({
  formId,
  formName,
  token,
  ...values
}: any) {
  return {
    type: REFERRAL_FORM_SUBMIT_REQUEST,
    payload: { formId, formName, token, ...values },
  };
}

export function sendPreviewEmail({ formId, groupId }: any) {
  return {
    type: REFERRAL_FORM_SEND_PREVIEW_EMAIL_REQUEST,
    payload: { formId, groupId },
  };
}

export function createReferralFormToken({ formId, groupId }: any) {
  return {
    type: REFERRAL_FORM_CREATE_TOKEN_REQUEST,
    payload: { formId, groupId },
  };
}
