import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Immutable, { Map } from 'immutable';
import validate from 'validate.js';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { isEqual } from 'lodash';
import { useReduxForm } from 'store/hooks/useReduxForm';
import arrayMutators from 'final-form-arrays';

// material-ui
import { WithStyles, withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import FormHelperText from '@material-ui/core/FormHelperText';
import Typography from '@material-ui/core/Typography';
import Skeleton from '@material-ui/lab/Skeleton';

// components
import { Form } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import ActionBar from 'components/ActionBar';
import Button from 'components/Button';
import DraggableList from 'components/DraggableList';
import FieldItem from 'components/FieldItem';
import Header from 'components/Header';
import DeleteFieldModal from './DeleteFieldModal';

// selectors
import getCurrentGroupId from 'store/selectors/getCurrentGroupId';
import getLoadingFromState from 'store/selectors/getLoadingFromState';
import getGroupFields from 'store/selectors/getGroupFields';

// action creators
import {
  fetchGroupFields,
  updateGroupFields,
} from 'store/actions/groupFieldActions';
import { destroyForm } from 'store/actions/formActions';
import getIsCurrentGroupCouncil from 'store/selectors/getIsCurrentGroupCouncil';

// icons
import FieldsIcon from '@material-ui/icons/List';

// styles
import fieldsSettingsStyles from 'routes/FieldsSettings/fieldsSettings.style';

// helpers
import isFieldTypeWithOptions from 'routes/FieldsSettings/helpers/isFieldTypeWithOptions';
import { isEmpty } from 'helpers/check';

export interface FieldsSettingsProps
  extends WithStyles<typeof fieldsSettingsStyles> {}

export const FORM = 'fieldsForm';

const FieldsSettings = ({ classes }: FieldsSettingsProps) => {
  const dispatch = useDispatch();
  const [currentFieldId, setCurrentFieldId] = useState<number | null>(null);

  const schema = {
    visibility: {
      presence: true,
    },
  };

  const {
    hasSubmitFailed,
    hasSubmitSucceeded,
    isSubmitting,
    formError,
  } = useReduxForm(FORM);

  const {
    groupFieldsLoading,
    groupFields,
    groupId,
    isCurrentGroupCouncil,
  } = useSelector(
    state => ({
      groupFieldsLoading: getLoadingFromState('groupField')(state),
      groupFields: getGroupFields(['PNMAttr', 'FormField'])(state),
      groupId: getCurrentGroupId(state),
      isCurrentGroupCouncil: getIsCurrentGroupCouncil(state),
    }),
    shallowEqual
  );

  const initialValues = useMemo(() => ({ groupFields: groupFields?.toJS() }), [
    groupFields,
  ]);

  useEffect(() => {
    dispatch(fetchGroupFields({ groupId }));
  }, [dispatch, groupId]);

  const onSubmit = (values: any) => {
    if (Array.isArray(values?.groupFields)) {
      // prevents case where values is an event object
      const groupFields = values.groupFields.map(
        (groupField: {
          fieldType?: string | null;
          options?: string[] | null;
        }) => {
          const { fieldType, options } = groupField || {};
          return {
            ...(groupField || {}),
            ...(!isEmpty(options) && !isFieldTypeWithOptions(fieldType)
              ? { options: [] }
              : {}),
          };
        }
      );
      dispatch(updateGroupFields({ formName: FORM, groupFields }));
    }
  };

  const validateArray = (items: any) => {
    return items.map((item: any) => validate(item, schema));
  };

  const handleActionBarClose = (event: any, reason: any, reset: any) => {
    // sets values back to pristine
    if (reason === 'timeout') {
      reset();
      dispatch(destroyForm(FORM));
    }
  };

  const handleFieldDelete = useCallback((field: Map<string, any>) => {
    setCurrentFieldId(field.get('id'));
  }, []);

  const handleCloseModal = useCallback(() => {
    setCurrentFieldId(null);
  }, []);

  return (
    <Grid item xs={12}>
      <DeleteFieldModal
        open={!!currentFieldId}
        onClose={handleCloseModal}
        fieldId={currentFieldId}
        groupId={groupId}
      />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Card id='tabContent' className={classes.topCard}>
            <CardContent className={classes.cardContent}>
              <Grid container spacing={8} alignItems='center'>
                <Grid item xs={12}>
                  <Header
                    id='header'
                    icon={FieldsIcon}
                    title='Fields'
                    subtitle={`Customize the order and visibility of the fields that display as options on forms 
                  and the "Info" tab of a lead's Profile. New fields are created when added to a lead's profile or while creating a form.`}
                    highlightedSubtitle={`Click & drag items to rearrange display order`}
                  />
                </Grid>

                {formError.get('message') && (
                  <Grid item xs={12} id='errorMessage'>
                    <FormHelperText error>
                      {formError.get('message')}
                    </FormHelperText>
                  </Grid>
                )}
              </Grid>

              {groupFieldsLoading && groupFields.size === 0 && (
                <Grid
                  container
                  spacing={2}
                  justifyContent='center'
                  alignContent='center'
                  id='skeletonContainer'
                >
                  <Grid item xs={12}>
                    <Skeleton variant='rect' height={50} />
                  </Grid>
                  <Grid item xs={12}>
                    <Skeleton variant='rect' height={50} />
                  </Grid>
                  <Grid item xs={12}>
                    <Skeleton variant='rect' height={50} />
                  </Grid>
                </Grid>
              )}

              {!groupFieldsLoading && groupFields.size === 0 && (
                <Grid
                  container
                  className={classes.noFieldsMessage}
                  id='noneFoundContainer'
                >
                  <Grid item xs={12}>
                    <Typography variant='body1'>No fields found</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant='body2' color='textSecondary'>
                      Create fields on the fly when editing a lead's profile or
                      building a form.
                    </Typography>
                  </Grid>
                </Grid>
              )}

              {groupFields.size > 0 && (
                <Form
                  onSubmit={onSubmit}
                  validate={values => validateArray(values.groupFields)}
                  initialValues={initialValues}
                  initialValuesEqual={isEqual}
                  mutators={{
                    ...arrayMutators,
                    setValue: ([field, value], state, { changeValue }) => {
                      changeValue(state, field, () => value);
                    },
                  }}
                  keepDirtyOnReinitialize={isSubmitting}
                  render={({
                    handleSubmit,
                    values = [],
                    invalid,
                    pristine,
                    form: { reset, mutators },
                  }) => (
                    <Grid
                      container
                      id='form'
                      component='form'
                      onSubmit={handleSubmit}
                      spacing={2}
                    >
                      <Grid item xs={12}>
                        <FieldArray name='groupFields'>
                          {({ fields }) => (
                            <DraggableList
                              initialItems={groupFields}
                              key='list'
                              fields={fields}
                              itemComponent={
                                <FieldItem
                                  items={Immutable.fromJS(values.groupFields)}
                                  mutators={mutators}
                                  isCurrentGroupCouncil={isCurrentGroupCouncil}
                                  onDelete={handleFieldDelete}
                                />
                              }
                            />
                          )}
                        </FieldArray>
                      </Grid>
                      <ActionBar
                        open={!pristine}
                        message='Save your changes?'
                        onClose={(event: any, reason: any) =>
                          handleActionBarClose(event, reason, reset)
                        }
                        // @ts-expect-error ts-migrate(2322) FIXME: Type 'number | null' is not assignable to type 'nu... Remove this comment to see the full error message
                        autoHideDuration={hasSubmitSucceeded ? 1500 : null}
                      >
                        <Button disabled={pristine} onClick={reset}>
                          Cancel
                        </Button>

                        <Button
                          variant='outlined'
                          color='primary'
                          type='submit'
                          onClick={onSubmit}
                          disabled={pristine || invalid || isSubmitting}
                          loading={isSubmitting}
                          success={hasSubmitSucceeded}
                          fail={hasSubmitFailed}
                        >
                          Save
                        </Button>
                      </ActionBar>
                    </Grid>
                  )}
                />
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default withStyles(fieldsSettingsStyles, { withTheme: true })(
  FieldsSettings
);
