import { all, call, fork, put, takeEvery, take } from 'redux-saga/effects';
// action creators
import { doneIndicator, error } from 'store/actions/httpActions';
import { setCurrentGroup } from 'store/actions/groupActions';
// api
import client from 'sources/api';
// constants
import { GROUP_CREATE_REQUEST } from 'store/constants/groupTypes';
// lib
import httpSaga from 'store/sagas/httpSaga';
import getBaseType from '../helpers/getBaseType';
import getResultKey from './helpers/getResultKey';
// schema
import { groupSchema } from 'store/schemas/groupSchema';
// helpers
import isNamesListSite from 'helpers/isNamesListSite';

const WATCH_TYPE = GROUP_CREATE_REQUEST;

export function* createGroupSaga(action: any) {
  const {
    formName,
    displayName,
    type,
    redirectToGroup,
    ...values
  } = action.payload;
  const FORM = formName;
  const TYPE = getBaseType(action.type);
  let response = { data: {} };
  try {
    // @ts-expect-error ts-migrate(2569) FIXME: Type 'Generator<any, any, unknown>' is not an arra... Remove this comment to see the full error message
    response = yield* httpSaga(
      TYPE,
      call(client.post, '/groups', {
        display_name: displayName,
        type,
        is_nameslist: isNamesListSite(),
        ...values,
      }),
      { schema: groupSchema, formName: FORM, resultKey: getResultKey(type) }
    );
  } catch (err) {
    yield put(error(TYPE, err));
  } finally {
    yield put(doneIndicator(TYPE));
    if (redirectToGroup) {
      const groupId = (response.data as any).id;
      yield put(setCurrentGroup({ groupId }));
      yield take('CURRENT_GROUP_SET_DONE');
    }
  }
}
export function* watch() {
  yield takeEvery(WATCH_TYPE, createGroupSaga);
}
export default function* root() {
  yield all([fork(watch)]);
}
