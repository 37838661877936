import { EventGuestFilters } from 'api/eventGuests';
import { Moment } from 'moment';
import {
  API_TASK_CREATE_EXPORT_REQUEST,
  API_TASK_CREATE_EVENT_GUEST_MESSAGE_REQUEST,
  API_TASK_FETCH_REQUEST,
  API_TASK_REPORT_NEW_MEMBERS_REQUEST,
  API_TASK_REPORT_COMMUNICATION_OVERVIEW_REQUEST,
  API_TASK_REPORT_GROUP_ACTIVITY_REQUEST,
  API_TASK_REPORT_GROUP_STATUS_REQUEST,
  API_TASK_REPORT_GROUP_LEADERBOARD_REQUEST,
  API_TASK_REPORT_LEAD_COMMUNICATION_REQUEST,
  API_TASK_REPORT_LEADS_INFO_REQUEST,
  API_TASK_REPORT_LEADS_MAIN_CONTACT_REQUEST,
  API_TASK_REPORT_LEADS_OVERVIEW_REQUEST,
  API_TASK_REPORT_MEMBER_COMMUNICATION_REQUEST,
  API_TASK_REPORT_MEMBER_LEADERBOARD_REQUEST,
  API_TASK_REPORT_TAG_USAGE_REQUEST,
  API_TASK_REPORT_MILESTONE_USAGE_REQUEST,
  API_TASK_REPORT_TAG_STATUS_REQUEST,
  API_TASK_REPORT_MILESTONE_STATUS_REQUEST,
  API_TASK_REPORT_RECRUITMENT_ACTIVITY_MILESTONES_REQUEST,
  API_TASK_REPORT_RECRUITMENT_ACTIVITY_TAGS_REQUEST,
  API_TASK_REPORT_RECRUITMENT_ACTIVITY_REQUEST,
  API_CREATE_TASK_REPORT_REQUEST,
  API_CREATE_TASK_REPORT_BY_GROUP_ID_REQUEST,
} from 'store/constants/apiTaskTypes';

export function fetchTask({ groupId, taskId }: any) {
  return {
    type: API_TASK_FETCH_REQUEST,
    payload: {
      groupId,
      taskId,
    },
  };
}

export function reportNewMembers({
  leadIds,
  joinDate,
  formName = '',
  isCob,
}: {
  leadIds: number[];
  joinDate: string;
  formName: string;
  isCob?: boolean;
}) {
  return {
    type: API_TASK_REPORT_NEW_MEMBERS_REQUEST,
    payload: {
      leadIds,
      joinDate,
      formName,
      isCob,
    },
  };
}

export function createGroupActivityReport({
  groupId,
  startDate,
  endDate,
  forceRefresh,
}: any) {
  return {
    type: API_TASK_REPORT_GROUP_ACTIVITY_REQUEST,
    payload: {
      groupId,
      startDate,
      endDate,
      forceRefresh,
    },
  };
}

export function createGroupStatusReport({
  groupId,
  startDate,
  endDate,
  forceRefresh,
}: any) {
  return {
    type: API_TASK_REPORT_GROUP_STATUS_REQUEST,
    payload: {
      groupId,
      startDate,
      endDate,
      forceRefresh,
    },
  };
}

export function createLabelAveragesReport({
  groupId,
  startDate,
  endDate,
  formName,
  forceRefresh,
}: {
  groupId: number;
  formName: string;
  startDate: Moment;
  endDate: Moment;
  forceRefresh?: boolean;
}) {
  return {
    type: API_CREATE_TASK_REPORT_REQUEST,
    payload: {
      name: 'generate_labels_averages_report',
      groupId,
      formName,
      startDate,
      endDate,
      forceRefresh,
    },
  };
}

export function createLabelTotalsReport({
  groupId,
  formName,
  startDate,
  endDate,
  forceRefresh,
}: {
  groupId: number;
  formName: string;
  startDate: Moment;
  endDate: Moment;
  forceRefresh?: boolean;
}) {
  return {
    type: API_CREATE_TASK_REPORT_REQUEST,
    payload: {
      name: 'generate_labels_totals_report',
      groupId,
      formName,
      startDate,
      endDate,
      forceRefresh,
    },
  };
}

export function createExportAction({
  groupId,
  exportType,
  formName,
  ...exportParams
}: any) {
  return {
    type: API_TASK_CREATE_EXPORT_REQUEST,
    payload: {
      groupId,
      exportType,
      formName,
      ...exportParams,
    },
  };
}

export function createEventGuestsMessagesAction({
  formName,
  groupId,
  eventId,
  leadIds,
  accountIds,
  leadFilters,
  memberFilters,
  body,
  subject,
  threadType,
}: {
  formName: string;
  groupId: number;
  eventId: number;
  leadIds?: number[] | 'all';
  accountIds?: number[] | 'all';
  leadFilters?: EventGuestFilters;
  memberFilters?: EventGuestFilters;
  body: string;
  subject: string;
  threadType: 'text' | 'email';
}) {
  return {
    type: API_TASK_CREATE_EVENT_GUEST_MESSAGE_REQUEST,
    payload: {
      formName,
      groupId,
      eventId,
      leadIds,
      accountIds,
      leadFilters,
      memberFilters,
      body,
      subject,
      threadType,
    },
  };
}

export function createGroupLeaderboardReport({
  groupId,
  startDate,
  endDate,
  forceRefresh,
}: any) {
  return {
    type: API_TASK_REPORT_GROUP_LEADERBOARD_REQUEST,
    payload: {
      groupId,
      startDate,
      endDate,
      forceRefresh,
    },
  };
}

export function createMemberLeaderboardReport({
  actorId,
  groupId,
  startDate,
  endDate,
  forceRefresh,
}: any) {
  return {
    type: API_TASK_REPORT_MEMBER_LEADERBOARD_REQUEST,
    payload: {
      actorId,
      groupId,
      startDate,
      endDate,
      forceRefresh,
    },
  };
}

export function createLeadsOverviewReport({ groupId, forceRefresh }: any) {
  return {
    type: API_TASK_REPORT_LEADS_OVERVIEW_REQUEST,
    payload: {
      groupId,
      forceRefresh,
    },
  };
}

export function createLeadsInfoReport({ groupId, forceRefresh }: any) {
  return {
    type: API_TASK_REPORT_LEADS_INFO_REQUEST,
    payload: {
      groupId,
      forceRefresh,
    },
  };
}

export function createLeadsMainContactReport({ groupId, forceRefresh }: any) {
  return {
    type: API_TASK_REPORT_LEADS_MAIN_CONTACT_REQUEST,
    payload: {
      groupId,
      forceRefresh,
    },
  };
}

export function createChapterLeaderboardReport({
  groupId,
  formName,
  startDate,
  endDate,
  forceRefresh,
}: {
  groupId: number;
  formName: string;
  startDate: Moment;
  endDate: Moment;
  forceRefresh?: boolean;
}) {
  return {
    type: API_CREATE_TASK_REPORT_REQUEST,
    payload: {
      name: 'generate_chapter_leaderboard_report',
      groupId,
      formName,
      startDate,
      endDate,
      forceRefresh,
    },
  };
}

export function createPaymentsReport({
  groupId,
  formName,
  startDate,
  endDate,
  forceRefresh,
}: {
  groupId: number;
  formName: string;
  startDate: Moment;
  endDate: Moment;
  forceRefresh?: boolean;
}) {
  return {
    type: API_CREATE_TASK_REPORT_REQUEST,
    payload: {
      name: 'generate_payments_report',
      groupId,
      formName,
      startDate,
      endDate,
      forceRefresh,
    },
  };
}

export function createTaskNewMembersListReport({
  groupId,
  formName,
  startDate,
  endDate,
  isForChildren,
  forceRefresh,
}: {
  formName: string;
  groupId: number;
  startDate: Moment;
  endDate: Moment;
  isForChildren?: boolean;
  forceRefresh?: boolean;
}) {
  return {
    type: API_CREATE_TASK_REPORT_REQUEST,
    payload: {
      name: 'generate_new_members_list_report',
      groupId,
      formName,
      startDate,
      endDate,
      isForChildren,
      forceRefresh,
    },
  };
}

export function createTaskCouncilNewMembersListReport({
  groupId,
  formName,
  startDate,
  endDate,
  childId,
  forceRefresh,
}: {
  formName: string;
  groupId: number;
  startDate: Moment;
  endDate: Moment;
  childId?: number;
  forceRefresh?: boolean;
}) {
  return {
    type: API_CREATE_TASK_REPORT_REQUEST,
    payload: {
      name: 'generate_new_members_council_list_report',
      groupId,
      formName,
      startDate,
      endDate,
      childId,
      forceRefresh,
    },
  };
}

export function createNewMemberCountReport({
  groupId,
  formName,
  startDate,
  endDate,
  forceRefresh,
}: {
  formName: string;
  groupId: number;
  startDate: Moment;
  endDate: Moment;
  forceRefresh?: boolean;
}) {
  return {
    type: API_CREATE_TASK_REPORT_REQUEST,
    payload: {
      name: 'generate_new_members_reported_count_report',
      groupId,
      formName,
      startDate,
      endDate,
      forceRefresh,
    },
  };
}

export function createTaskRecruitmentStoryReport({
  groupId,
  formName,
  forceRefresh,
}: {
  formName: string;
  groupId: number;
  forceRefresh?: boolean;
}) {
  return {
    type: API_CREATE_TASK_REPORT_REQUEST,
    payload: {
      name: 'generate_recruitment_story_report',
      groupId,
      formName,
      forceRefresh,
    },
  };
}

export function createTagUsageReport({ groupId, forceRefresh }: any) {
  return {
    type: API_TASK_REPORT_TAG_USAGE_REQUEST,
    payload: {
      groupId,
      forceRefresh,
    },
  };
}

export function createMilestoneUsageReport({ groupId, forceRefresh }: any) {
  return {
    type: API_TASK_REPORT_MILESTONE_USAGE_REQUEST,
    payload: {
      groupId,
      forceRefresh,
    },
  };
}

export function createTagStatusReport({ groupId, forceRefresh }: any) {
  return {
    type: API_TASK_REPORT_TAG_STATUS_REQUEST,
    payload: {
      groupId,
      forceRefresh,
    },
  };
}

export function createMilestoneStatusReport({ groupId, forceRefresh }: any) {
  return {
    type: API_TASK_REPORT_MILESTONE_STATUS_REQUEST,
    payload: {
      groupId,
      forceRefresh,
    },
  };
}

export function createRecruitmentActivityMilestonesReport({
  groupId,
  statusPositions,
  formName,
  forceRefresh,
}: any) {
  return {
    type: API_TASK_REPORT_RECRUITMENT_ACTIVITY_MILESTONES_REQUEST,
    payload: {
      groupId,
      statusPositions,
      formName,
      forceRefresh,
    },
  };
}

export function createRecruitmentActivityTagsReport({
  groupId,
  statusPositions,
  formName,
  forceRefresh,
}: any) {
  return {
    type: API_TASK_REPORT_RECRUITMENT_ACTIVITY_TAGS_REQUEST,
    payload: {
      groupId,
      statusPositions,
      formName,
      forceRefresh,
    },
  };
}

export function createRecruitmentActivityReport({
  groupId,
  statusPositions,
  formName,
  forceRefresh,
}: any) {
  return {
    type: API_TASK_REPORT_RECRUITMENT_ACTIVITY_REQUEST,
    payload: {
      groupId,
      statusPositions,
      formName,
      forceRefresh,
    },
  };
}

export function createReferralSourceStatusReport({
  groupId,
  formName,
  forceRefresh,
}: {
  groupId: number;
  formName: string;
  forceRefresh?: boolean;
}) {
  return {
    type: API_CREATE_TASK_REPORT_REQUEST,
    payload: {
      name: 'generate_referral_source_report',
      groupId,
      formName,
      forceRefresh,
    },
  };
}

export function createNewMembersTagsReport({
  groupId,
  formName,
  forceRefresh,
}: {
  groupId: number;
  formName: string;
  forceRefresh?: boolean;
}) {
  return {
    type: API_CREATE_TASK_REPORT_REQUEST,
    payload: {
      name: 'generate_new_members_tags_report',
      groupId,
      formName,
      forceRefresh,
    },
  };
}

export function createNewMembersMilestonesReport({
  groupId,
  formName,
  forceRefresh,
}: {
  groupId: number;
  formName: string;
  forceRefresh?: boolean;
}) {
  return {
    type: API_CREATE_TASK_REPORT_REQUEST,
    payload: {
      name: 'generate_new_members_milestones_report',
      groupId,
      formName,
      forceRefresh,
    },
  };
}

export function createReferralSourceFormDataReport({
  groupId,
  formName,
  forceRefresh,
}: {
  groupId: number;
  formName: string;
  forceRefresh?: boolean;
}) {
  return {
    type: API_CREATE_TASK_REPORT_REQUEST,
    payload: {
      name: 'generate_form_data_report',
      groupId,
      formName,
      forceRefresh,
    },
  };
}

export function createChapterUserDataReport({
  groupId,
  formName,
  forceRefresh,
}: {
  groupId: number;
  formName: string;
  forceRefresh?: boolean;
}) {
  return {
    type: API_CREATE_TASK_REPORT_REQUEST,
    payload: {
      name: 'generate_user_data_report',
      groupId,
      formName,
      forceRefresh,
    },
  };
}

export function createCommunicationOverviewReport({
  groupId,
  startDate,
  endDate,
  forceRefresh,
}: any) {
  return {
    type: API_TASK_REPORT_COMMUNICATION_OVERVIEW_REQUEST,
    payload: {
      groupId,
      startDate,
      endDate,
      forceRefresh,
    },
  };
}

export function createPhoneLineDataReport({
  groupId,
  formName,
  forceRefresh,
}: any) {
  return {
    type: API_CREATE_TASK_REPORT_REQUEST,
    payload: {
      name: 'generate_phone_line_data_report',
      groupId,
      formName,
      forceRefresh,
    },
  };
}

export function createLeadCommunicationReport({
  groupId,
  startDate,
  endDate,
  forceRefresh,
}: any) {
  return {
    type: API_TASK_REPORT_LEAD_COMMUNICATION_REQUEST,
    payload: {
      groupId,
      startDate,
      endDate,
      forceRefresh,
    },
  };
}

export function createMemberCommunicationReport({
  groupId,
  startDate,
  endDate,
  forceRefresh,
}: any) {
  return {
    type: API_TASK_REPORT_MEMBER_COMMUNICATION_REQUEST,
    payload: {
      groupId,
      startDate,
      endDate,
      forceRefresh,
    },
  };
}

export function createGroupStatusReportByChapter({
  groupId,
  formName,
  startDate,
  endDate,
  forceRefresh,
}: {
  formName: string;
  groupId: number;
  startDate: Moment;
  endDate: Moment;
  forceRefresh?: boolean;
}) {
  return {
    type: API_CREATE_TASK_REPORT_BY_GROUP_ID_REQUEST,
    payload: {
      name: 'generate_group_status_report',
      groupId,
      formName,
      startDate,
      endDate,
      forceRefresh,
    },
  };
}

export function createParentGroupsStatusReportAll({
  groupId,
  formName,
  startDate,
  endDate,
  forceRefresh,
}: {
  formName: string;
  groupId: number;
  startDate: Moment;
  endDate: Moment;
  forceRefresh?: boolean;
}) {
  return {
    type: API_CREATE_TASK_REPORT_BY_GROUP_ID_REQUEST,
    payload: {
      name: 'generate_parent_groups_status_report',
      groupId,
      formName,
      startDate,
      endDate,
      forceRefresh,
    },
  };
}

export function createNonJoinersStoryDataReport({
  groupId,
  statusPositions,
  forceRefresh,
}: {
  groupId: number;
  statusPositions: (number | string)[];
  forceRefresh?: boolean;
}) {
  return {
    type: API_CREATE_TASK_REPORT_REQUEST,
    payload: {
      name: 'generate_recruitment_story_report',
      groupId,
      statusPositions,
      forceRefresh,
    },
  };
}
