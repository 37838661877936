import React, { useEffect, useCallback } from 'react';
import validate from 'validate.js';
import { isEqual } from 'lodash';
import humps from 'humps';

// hooks
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useDateTime } from 'helpers/hooks/useDateTime';

// components
import { Form } from 'react-final-form';
import EmailForm from './EmailForm';
import TextMessageForm from './TextMessageForm';
import StepperButtons from './StepperButtons';
import Toast from 'components/Toast';

// material-ui
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

// selectors
import getCurrentGroupId from 'store/selectors/getCurrentGroupId';
import getEventById from 'store/selectors/getEventById';
import getCurrentGroup from 'store/selectors/getCurrentGroup';
import getForm from 'store/selectors/getForm';
import getReferralForms from 'store/selectors/getReferralForms';

// action creators
import { createEventGuests } from 'store/actions/eventGuestActions';
import { fetchPhoneNumbersForGroup } from 'store/actions/phoneNumberActions';
import {
  fetchReferralFormById,
  createReferralFormToken,
} from 'store/actions/referralFormActions';

// types
import { EventGuestFilters } from 'api/eventGuests';

const INDEX_TO_INSERT = 0; // We always insert at the beginning, for now

interface FormSchema {
  subject: {
    presence?: boolean;
    length: {
      maximum: number;
    };
  };
  emailBody: {
    presence?: boolean;
  };
  textMessageBody: {
    presence?: boolean;
  };
}

const FORM = 'createEventGuestsForm';

export default function SendInvitationsStep({
  allLeadsSelected,
  selectedLeadIds,
  allMembersSelected,
  leadFilters,
  memberFilters,
  handleSetValidationErrors,
  selectedAccountIds,
  StepperButtonProps,
}: {
  allLeadsSelected: boolean;
  selectedLeadIds: number[];
  allMembersSelected: boolean;
  leadFilters: EventGuestFilters;
  memberFilters: EventGuestFilters;
  handleSetValidationErrors: (errors: any) => void;
  selectedAccountIds: number[];
  StepperButtonProps: {
    activeStep: number;
    decrementStep: () => void;
    isNextDisabled: boolean;
  };
}) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const params: { eventId?: string } = useParams();
  const { eventId = '' } = params;

  const {
    currentGroup,
    currentGroupId,
    event,
    form: {
      isSubmitting,
      hasSubmitSucceeded,
      hasSubmitFailed,
      formError,
      successMessage,
    },
    referralForms,
  } = useSelector(
    state => ({
      currentGroup: getCurrentGroup(state)?.toJS() as any,
      currentGroupId: getCurrentGroupId(state),
      event: getEventById(eventId)(state),
      form: getForm(FORM)(state),
      referralForms: getReferralForms(state)?.toJS(),
    }),
    isEqual
  );

  // Build out the initial message values
  const { date: startDate, time: startTime } = useDateTime(event.startDate);
  const { date: endDate, time: endTime } = useDateTime(event.endDate);
  const hasDateDiff = startDate !== endDate;
  const dateLabel = `${startDate}, ${startTime} to ${
    hasDateDiff ? `${endDate}, ${endTime}` : endTime
  }`;

  const referralForm =
    referralForms.find((form: any) => form.id === event.formId) || {};

  const formLink = `${window.location.origin}/forms/${referralForm.id}/view/?token=${referralForm.token}`;

  const initialEmail = `<p>You're invited to ${currentGroup.name}'s ${event.title} event!</p>
    <p>When: ${dateLabel}</p>
    <p>Where: ${event.location}</p>
    <p>Details: ${event.description}</p>
    <p>Event Form Link: <a href="${formLink}">Click Here</a></p>
    <p>We hope to see you there!</p>`;

  const initialTextMessage = `You're invited to ${currentGroup.name}'s ${event.title} event!
    \nWhen: ${dateLabel}
    \nWhere: ${event.location}
    \nDetails: ${event.description}`;

  useEffect(() => {
    dispatch(fetchPhoneNumbersForGroup());
  }, [dispatch]);
  useEffect(() => {
    if (!referralForm.id) {
      dispatch(
        fetchReferralFormById({ formId: event.formId, groupId: currentGroupId })
      );
    }
  }, [dispatch, currentGroupId, referralForm.id, event.formId]);

  useEffect(() => {
    if (referralForm.isPublished && !referralForm.token) {
      dispatch(
        createReferralFormToken({
          formId: event.formId,
          groupId: currentGroupId,
        })
      );
    }
  }, [
    dispatch,
    referralForm.isPublished,
    currentGroupId,
    referralForm.id,
    event.formId,
    referralForm.token,
  ]);

  // Dynamically shape the form schema as switches are toggled
  const validateForm = useCallback(
    values => {
      const schema: FormSchema = {
        subject: {
          length: { maximum: 255 },
        },
        emailBody: {},
        textMessageBody: {},
      };

      if (values?.emailSelected) {
        schema.subject = { presence: true, ...schema.subject };
        schema.emailBody = { presence: true };
      }

      if (values?.textMessageSelected) {
        schema.textMessageBody = { presence: true };
      }

      // Prevents user from sending invites without an invitation method
      if (!values?.emailSelected && !values?.textMessageSelected) {
        const validationErrors = {
          emailSelected: [
            'Please select either an email or text message invitation',
          ],
          textMessageSelected: [
            'Please select either an email or text message invitation',
          ],
        };
        handleSetValidationErrors(validationErrors);
        return validationErrors;
      }

      const validationErrors = validate(values, schema);
      handleSetValidationErrors(validationErrors);
      return validationErrors;
    },
    [handleSetValidationErrors]
  );

  const handleFormSubmit = (values: any) => {
    // Dispatch with filters if all selected
    // Lead filters
    const pnmFilters: any = {};
    if (allLeadsSelected) {
      Object.keys(leadFilters).forEach(key => {
        if (key === 'statusIds') {
          pnmFilters[
            `pnm_status_id`
          ] = (leadFilters as EventGuestFilters).statusIds;
        } else {
          pnmFilters[
            `pnm_${humps.decamelize(key)}`
          ] = (leadFilters as EventGuestFilters)[
            key as keyof EventGuestFilters
          ];
        }
      });
    }
    // Member filters
    const accountFilters: any = {};
    if (allMembersSelected) {
      Object.keys(memberFilters).forEach(key => {
        accountFilters[
          `account_${humps.decamelize(key)}`
        ] = (memberFilters as EventGuestFilters)[
          key as keyof EventGuestFilters
        ];
      });
    }

    // Create event guest records, send emails, and send texts in one swoop
    dispatch(
      createEventGuests({
        groupId: currentGroupId,
        eventId: parseInt(eventId, 10),
        invited: true,
        leadIds: allLeadsSelected ? 'all' : selectedLeadIds,
        accountIds: allMembersSelected ? 'all' : selectedAccountIds,
        leadFilters: pnmFilters,
        memberFilters: accountFilters,
        emailParams: {
          subject: values?.subject,
          emailBody: values?.emailSelected ? values?.emailBody : undefined,
        },
        textMessageParams: {
          textMessageBody: values?.textMessageSelected
            ? values?.textMessageBody
            : undefined,
        },
        formName: FORM,
      })
    );
  };

  return (
    <>
      <Form
        validate={validateForm}
        onSubmit={handleFormSubmit}
        initialValues={{
          subject: `Invitation: ${event.title} @ ${dateLabel}`,
          emailBody: initialEmail,
          textMessageBody: initialTextMessage,
        }}
        initialValuesEqual={isEqual}
        mutators={{
          insertEmailTemplateText: insertTemplateText('emailBody'),
          insertTextMessageTemplateText: insertTemplateText('textMessageBody'),
        }}
        render={({ handleSubmit, values, form: { mutators } }) => (
          <>
            <Grid container>
              <StepperButtons
                incrementStep={handleSubmit}
                NextButtonProps={{
                  id: 'sendButton',
                  loading: isSubmitting,
                  success: hasSubmitSucceeded,
                  fail: hasSubmitFailed,
                }}
                {...StepperButtonProps}
              />
            </Grid>

            <Grid
              container
              justifyContent='flex-start'
              className={classes.container}
              spacing={2}
            >
              {Boolean(formError) && (
                <Grid item xs={12}>
                  <Typography color='error'>
                    {formError.get('message')}
                  </Typography>
                </Grid>
              )}

              <Grid item xs={12}>
                <Typography variant='h6'>Send Invitations</Typography>
                <Typography variant='body2' color='textSecondary'>
                  Select your invite methods, draft invite messages, and spread
                  the word of your event!
                </Typography>
                <Typography variant='body2' color='secondary'>
                  All selected leads and members will receive an invitation,
                  even those previously invited
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <EmailForm
                  emailSelected={values?.emailSelected}
                  textToInsert={values?.emailTextToInsert}
                  mutators={mutators}
                />
              </Grid>

              <Grid item xs={12}>
                <TextMessageForm
                  textMessageSelected={values?.textMessageSelected}
                  textToInsert={values?.textMessageTextToInsert}
                  mutators={mutators}
                />
              </Grid>
            </Grid>
          </>
        )}
      />

      <Toast
        alertProps={{ message: 'Invitations Sent Successfully' }}
        hideErrorDialog={true}
        hideSnackbar={false}
        formName={FORM}
        id='sendInvitationsToast'
        successMessage={successMessage}
      />
    </>
  );
}

const insertTemplateText = (fieldName: string) => (
  textToInsert: string,
  state: any,
  { changeValue }: any
) => {
  const insertString =
    textToInsert[0] === 'full_name'
      ? '{{ first_name }} {{ last_name }}'
      : `{{ ${textToInsert} }}`;

  changeValue(state, fieldName, (value: any) => {
    if (!value) return insertString;

    return `${value.slice(0, INDEX_TO_INSERT)}${insertString}${value.slice(
      INDEX_TO_INSERT
    )}`;
  });
};

const useStyles = makeStyles(theme => {
  return {
    container: {
      paddingLeft: 32,
      paddingRight: 32,
      [theme.breakpoints.only('xs')]: {
        paddingLeft: 16,
        paddingRight: 16,
      },
    },
  };
});
