import { all } from 'redux-saga/effects';

// sagas
import fetchPaymentSettingsSaga from 'store/sagas/paymentSettings/fetchPaymentSettingsSaga';
import fetchPaymentStripeLinkSaga from 'store/sagas/paymentSettings/fetchPaymentStripeLinkSaga';
import updatePaymentSettingsSaga from 'store/sagas/paymentSettings/updatePaymentSettingsSaga';
import deletePaymentSettingsSaga from 'store/sagas/paymentSettings/deletePaymentSettingsSaga';

const paymentSettingsSaga = function* rootSaga() {
  yield all([
    fetchPaymentSettingsSaga(),
    fetchPaymentStripeLinkSaga(),
    updatePaymentSettingsSaga(),
    deletePaymentSettingsSaga(),
  ]);
};

export default paymentSettingsSaga;
