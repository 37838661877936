import React from 'react';
import PropTypes from 'prop-types';

// material-ui
import Snackbar from '@material-ui/core/Snackbar';
import { makeStyles } from '@material-ui/core/styles';

const ActionBar = function({ children, message, onClose, open, ...rest }: any) {
  const classes = useStyles();

  return (
    <Snackbar
      className={classes.snackbarContainer}
      open={open}
      message={message}
      onClose={onClose}
      ContentProps={{ classes: { root: classes.root } }}
      action={<React.Fragment>{children}</React.Fragment>}
      {...rest}
    />
  );
};

const useStyles = makeStyles(theme => ({
  snackbarContainer: {
    position: 'fixed',
    bottom: 0,
    [theme.breakpoints.only('xs')]: {
      bottom: 65,
    },
  },
  root: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.background.default,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
}));

ActionBar.propTypes = {
  children: PropTypes.instanceOf(Object),
  message: PropTypes.string,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
};

ActionBar.defaultProps = {
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'center',
  },
  autoHideDuration: null,
  children: {},
  message: '',
  onClose: () => {},
};

export default ActionBar;
