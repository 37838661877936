import React, { useState, useCallback } from 'react';
import { Map, List } from 'immutable';
import { useSelector } from 'react-redux';

// icons
import { Delete as DeleteIcon, Edit as EditIcon } from '@material-ui/icons';

// components
import MegaMenu from 'components/MegaMenu';
import DeleteEventModal from './DeleteEventModal';
import UpdateEventModal from './UpdateEventModal';

// selectors
import getPermission from 'store/selectors/getPermission';

export default function EventOptionsMenu({
  anchorEl,
  onClose,
  event,
}: {
  anchorEl: null | HTMLElement;
  onClose: (...args: any[]) => void;
  event: GroupEvent;
}) {
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);

  const openDeleteModal = useCallback(() => setDeleteModalOpen(true), []);
  const closeDeleteModal = useCallback(() => setDeleteModalOpen(false), []);

  const openEditModal = useCallback(() => setEditModalOpen(true), []);
  const closeEditModal = useCallback(() => setEditModalOpen(false), []);

  const { canUpdateEvent, canDeleteEvent } = useSelector(state => ({
    canUpdateEvent: getPermission('event.updateOne')(state),
    canDeleteEvent: getPermission('event.deleteOne')(state),
  }));

  const getMenuSections = () => {
    // This list is composed into immutable elements manually
    //  so that the icon components don't get converted into objects
    let menuSections = List([
      Map({
        sectionHeader: 'Event Options',
        id: 'eventOptions',
        items: List([
          Map({
            id: 'edit',
            label: 'Edit Event',
            icon: <EditIcon />,
            display: canUpdateEvent,
            onClick: openEditModal,
          }),
        ]),
      }),
      Map({
        id: 'eventDelete',
        display: canDeleteEvent,
        items: List([
          Map({
            id: 'delete',
            label: 'Delete Event',
            icon: <DeleteIcon />,
            display: canDeleteEvent,
            onClick: openDeleteModal,
          }),
        ]),
      }),
    ]);

    return menuSections;
  };

  return (
    <div>
      <MegaMenu
        // @ts-expect-error ts-migrate(2322) FIXME: Type '{ id: string; anchorEl: any; handleClose: an... Remove this comment to see the full error message
        id='eventOptionsMenu'
        anchorEl={anchorEl}
        handleClose={onClose}
        menuSections={getMenuSections()}
      />
      <DeleteEventModal
        eventId={event.id}
        open={deleteModalOpen}
        onClose={closeDeleteModal}
      />
      <UpdateEventModal
        event={event}
        open={editModalOpen}
        onClose={closeEditModal}
      />
    </div>
  );
}
