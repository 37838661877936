import Immutable, { Map } from 'immutable';
// constants
import { REPORT_CREATE, REPORT_CLEAR } from 'store/constants/reportTypes';

export default function reduceReport(state = Map(), action = {}) {
  switch ((action as any).type) {
    case REPORT_CREATE:
      const payload = Immutable.fromJS((action as any).payload);
      return state.setIn(['data', 'byId', payload.get('type')], payload);
    case REPORT_CLEAR:
      return Map();
    default:
      return state;
  }
}
