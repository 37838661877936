import { all, call, fork, put, takeEvery, select } from 'redux-saga/effects';

// action creators
import { doneIndicator } from 'store/actions/httpActions';

// selector
import getCurrentGroupId from 'store/selectors/getCurrentGroupId';

// api
import client from 'sources/api';

// constants
import {
  PHONE_NUMBER_TOGGLE_CALL_FORWARDING_REQUEST,
  PHONE_NUMBER_TOGGLE_CALL_FORWARDING,
} from 'store/constants/phoneNumberTypes';

// schemas
import { phoneNumberSchema } from 'store/schemas/phoneNumberSchema';

// helpers
import httpSaga from 'store/sagas/httpSaga';

const WATCH_TYPE = PHONE_NUMBER_TOGGLE_CALL_FORWARDING_REQUEST;
const TYPE = PHONE_NUMBER_TOGGLE_CALL_FORWARDING;

export function* toggleCallForwardingSaga(action: any) {
  const {
    payload: { phoneNumberId },
  } = action;

  const currentGroupId = yield select(getCurrentGroupId);

  // @ts-expect-error ts-migrate(2569) FIXME: Type 'Generator<any, any, unknown>' is not an arra... Remove this comment to see the full error message
  yield* httpSaga(
    TYPE,
    call(
      client.post,
      `/phone_numbers/${phoneNumberId}/toggle_call_forwarding?group_id=${currentGroupId}`
    ),
    {
      schema: phoneNumberSchema,
      metaId: `toggle-${phoneNumberId}`,
    }
  );
  yield put(doneIndicator(TYPE));
}

export function* watch() {
  yield takeEvery(WATCH_TYPE, toggleCallForwardingSaga);
}

export default function* root() {
  yield all([fork(watch)]);
}
