import { Styles, Theme } from 'assets/types';

const chapterSearchBarStyles: Styles = theme => ({
  searchBar: {
    paddingLeft: 15,
    borderRadius: 8,
    paddingRight: 24,
    backgroundColor: (theme as Theme).palette.background.lightPaper,
    transition: 'all 0.25s ease',
    [theme.breakpoints.between('md', 'lg')]: {
      width: '48.75%',
    },
    [theme.breakpoints.up('xl')]: {
      width: '32%',
    },
  },
  focused: {
    transition: 'all 0.25s ease',
    backgroundColor: (theme as Theme).palette.background.lightPaper,
  },
  textField: {
    marginTop: -9,
  },
  barIcon: {
    textAlign: 'right',
  },
  loadingIndicator: {
    height: 24,
  },
  labelSelector: {
    marginBottom: 24,
  },
});

export default chapterSearchBarStyles;
