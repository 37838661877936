import SelectChapterForm from './SelectChapterForm';
import { connect } from 'react-redux';

// action creators
import { fetchGroups } from 'store/actions/groupActions';
import { fetchStripeConfig } from 'store/actions/billingActions';
import getLoadingFromState from 'store/selectors/getLoadingFromState';
import getOrganizations from 'store/selectors/getOrganizations';
import getCouncils from 'store/selectors/getCouncils';
import getChapters from 'store/selectors/getChapters';
import { setCurrentGroup } from 'store/actions/groupActions';

// selectors
import {
  hasSubmitFailed,
  hasSubmitSucceeded,
  isSubmitting,
} from 'store/selectors/formSelectors';

const mapState = (state: any) => {
  return {
    councilLoading: getLoadingFromState('group', 'council', false)(state),
    councils: getCouncils(state, 'suggestions'),
    chapters: getChapters(state),
    groupLoading: getLoadingFromState('group', false, false)(state),
    organizationLoading: getLoadingFromState(
      'group',
      'greekOrganization',
      false
    )(state),
    organizations: getOrganizations(state, 'suggestions'),
    selectGroupFormSubmitting: isSubmitting('selectGroupForm')(state),
    selectGroupFormSubmitFailed: hasSubmitFailed('selectGroupForm')(state),
    selectGroupFormSubmitSucceeded: hasSubmitSucceeded('selectGroupForm')(
      state
    ),
  };
};

const mapDispatch = {
  fetchGroups,
  fetchStripeConfig,
  setCurrentGroup,
};

export default connect(mapState, mapDispatch)(SelectChapterForm);
