import axios, { AxiosRequestTransformer } from 'axios';
import humps from 'humps';
import qs from 'query-string';

// helpers
import handleTokenRefreshAndRetryRequest from './handleTokenRefreshAndRetryRequest';
import { getAccessToken } from 'helpers/tokenUtils';

const ignoredRefreshEndpoints = [
  '/auth/login',
  '/auth/logout',
  '/auth/refresh',
];

export function getClient({
  camelizeResponse = true,
  decamelizeRequest = false,
}) {
  const BASE_URL = process.env.REACT_APP_BASE_API_URL;

  const client = axios.create({
    baseURL: BASE_URL,
    timeout: 30000,
    headers: {
      accept: 'application/json',
      'Content-Type': 'application/json',
    },
    responseType: 'json',
    transformResponse: data =>
      camelizeResponse
        ? humps.camelizeKeys(JSON.parse(data || null))
        : JSON.parse(data || null),
    transformRequest: [
      data => (decamelizeRequest ? humps.decamelizeKeys(data) : data),
      ...((axios.defaults?.transformRequest ||
        []) as AxiosRequestTransformer[]),
    ],

    paramsSerializer: params => {
      let objParams = params;
      if (params instanceof URLSearchParams)
        objParams = qs.parse(params.toString());
      for (const key in objParams) if (!objParams[key]) delete objParams[key];
      if (decamelizeRequest) {
        objParams = humps.decamelizeKeys(objParams);
      }
      return qs.stringify(objParams);
    },
  });

  client?.interceptors.request.use(
    request => {
      const accessToken = getAccessToken();

      if (
        accessToken &&
        request &&
        request.headers &&
        !request.headers.Authorization
      ) {
        request.headers.Authorization = `Bearer ${accessToken}`;
      }

      return request;
    },
    err => Promise.reject(err)
  );

  client?.interceptors.response.use(
    response => response,
    (err = {}) => {
      const {
        response: {
          status: statusCode,
          config: { url },
        },
      } = err;

      const shouldRefresh = ignoredRefreshEndpoints.every(
        ignoredEndpoint => !url.includes(ignoredEndpoint)
      );

      if (statusCode === 401 && shouldRefresh) {
        return handleTokenRefreshAndRetryRequest(err);
      }

      return Promise.reject(err);
    }
  );

  return client;
}

const client = getClient({});

export default client;
