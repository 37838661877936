import { normalize } from 'normalizr';
export default function getResponsePayload(response = {}, schema: any) {
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'data' does not exist on type '{}'.
  const { data: { results } = {} } = response;
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'data' does not exist on type '{}'.
  const { data = {} } = response;
  let payload;
  const newData = Array.isArray(results) ? results : data;
  // Normalize the response data if a schema was provided
  if ((response as any).data && schema) {
    payload = {
      data: normalize(newData, schema),
      entity: (schema[0] || schema).key,
      normalized: true,
    };
  } else {
    // Set the default payload to the response data
    payload = {
      data: newData,
    };
  }
  return payload;
}
