import {
  SupervisorAccount,
  FiberNew,
  Sms,
  DoneAll,
  Message,
  Delete,
  PersonAdd,
} from '@material-ui/icons';
import React from 'react';
import moment from 'moment';
import { Map, List } from 'immutable';
import formatStartDate from 'helpers/formatStartDate';
import formatEndDate from 'helpers/formatEndDate';

export default function formatNotification(
  notification: any,
  navigateTo: any,
  index: any,
  currentUser: any
) {
  const dateFormat = 'MMMM Do, h:mma';

  let formatted = Map({
    id: notification.activityId,
    sectionHeader: index === 0 ? 'Notifications' : null,
    items: List([
      Map({
        id: notification.activityId,
        label: notification.title,
        color: notification.isRead ? 'inherit' : 'secondary',
        subText: moment
          .utc(notification.createdOn)
          .local()
          .format(dateFormat),
      }),
    ]),
  });

  switch (notification.action) {
    case 'ActivityActions.PNMS_DELETED':
      formatted = formatted.setIn(['items', 0, 'icon'], <Delete />);
      formatted = formatted.setIn(['items', 0, 'onClick'], () =>
        navigateTo('/home', notification.activityId)
      );
      break;
    case 'ActivityActions.PNM_CREATED':
    case 'ActivityActions.PNM_MANUAL_PUSHED':
      formatted = formatted.setIn(['items', 0, 'icon'], <PersonAdd />);
      formatted = formatted.setIn(['items', 0, 'onClick'], () =>
        navigateTo('/leads', null, {
          createdOnStart: formatStartDate(currentUser.latestActivityOn),
          createdOnEnd: formatEndDate(new Date()),
          newLeads: true,
        })
      );
      break;
    case 'ActivityActions.COMMENT_CREATED':
      formatted = formatted.setIn(['items', 0, 'icon'], <Message />);
      formatted = formatted.setIn(['items', 0, 'onClick'], () =>
        navigateTo('/home', notification.activityId)
      );
      break;
    case 'ActivityActions.GROUP_REQUEST_CREATED':
      formatted = formatted.setIn(['items', 0, 'icon'], <SupervisorAccount />);
      formatted = formatted.setIn(['items', 0, 'onClick'], () =>
        navigateTo('/settings/members')
      );
      break;
    case 'ActivityActions.TODO_CREATED':
      formatted = formatted.setIn(['items', 0, 'icon'], <DoneAll />);
      formatted = formatted.setIn(['items', 0, 'onClick'], () =>
        navigateTo('/home')
      );
      break;
    case 'ActivityActions.TEXT_MESSAGE_RECEIVED':
      formatted = formatted.setIn(['items', 0, 'icon'], <Sms />);
      formatted = formatted.setIn(['items', 0, 'onClick'], () =>
        navigateTo('/messages')
      );
      break;
    default:
      formatted = formatted.setIn(['items', 0, 'icon'], <FiberNew />);
      formatted = formatted.setIn(['items', 0, 'onClick'], () => {});
      break;
  }
  return formatted;
}
