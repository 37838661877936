import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import Immutable from 'immutable';

// action creators
import {
  doneIndicator,
  error,
  errorIndicator,
  loading,
  success,
  successIndicator,
} from 'store/actions/httpActions';

// api
import client from 'sources/api';

// constants
import { GROUP_FETCH_BY_ID_REQUEST } from 'store/constants/groupTypes';

// helpers
import getResponsePayload from 'helpers/getResponsePayload';
import getBaseType from '../helpers/getBaseType';
import populateParents from './helpers/populateParents';
import { toString } from 'helpers/transform';
import isNamesListSite from 'helpers/isNamesListSite';

// schema
import { groupSchema } from 'store/schemas/groupSchema';

const WATCH_TYPE = [GROUP_FETCH_BY_ID_REQUEST];

/*
 *  Handle fetching a group by id and then fetching the parents for that group
 */
export function* fetchGroupById(action: any) {
  const TYPE = getBaseType(action.type);

  let schema = [groupSchema];
  const { id, fetchParents } = action.payload || {};

  let groupParams = {
    id,
    is_nameslist: toString(isNamesListSite()),
  };

  try {
    // Notify the store that this type is loading
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
    yield put(loading(TYPE));

    // Yield to the request promises
    const response = yield call(client.get, '/groups', { params: groupParams });

    // format and normalize the response
    const payload = getResponsePayload(response, schema);

    // Populates parents for groups
    if (fetchParents) {
      // Get ids off each item of the result
      let allParentIds: any = [];
      const { results = [] } = response.data || {};

      results.forEach((item: any) => {
        allParentIds = allParentIds.concat(item.parents);
      });

      yield populateParents(allParentIds);
    }

    // Notify the store that the HTTP call was successful for this type
    yield put(successIndicator(TYPE));

    // Send the response data to the store for this type to be caught by a reducer
    yield put(
      success(
        TYPE,
        Immutable.fromJS({
          ...payload,
        })
      )
    );
  } catch (err) {
    // Notify the store that this type had an error
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 2.
    yield put(errorIndicator(TYPE, err));

    // Dispatch an error payload for this type to be caught by the reducer
    yield put(error(TYPE, err));
  } finally {
    yield put(doneIndicator(TYPE));
  }
}

export function* watch() {
  yield takeEvery(WATCH_TYPE, fetchGroupById);
}

export default function* root() {
  yield all([fork(watch)]);
}
