import Immutable, { List, Map } from 'immutable';
import { denormalize } from 'normalizr';

import { groupValueSchema } from '../schemas/groupValueSchema';

export default function getGroupValues(state: any) {
  const groupValue = state.get('groupValue', Map());
  const result = groupValue.getIn(['data', 'result'], List());

  const entities = {
    groupValue: state.getIn(['groupValue', 'data', 'byId'], Map()).toJS(),
  };

  let items = denormalize(result.toJS(), [groupValueSchema], entities);
  return Immutable.fromJS(items);
}
