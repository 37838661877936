import { all, put, fork, takeEvery, take, select } from 'redux-saga/effects';

// action creators
import {
  doneIndicator,
  errorIndicator,
  successIndicator,
  error,
} from 'store/actions/httpActions';
import { destroySession, validateSession } from 'store/actions/sessionActions';
import { fetchCurrentUser } from 'store/actions/userActions';
import { setCurrentGroup } from 'store/actions/groupActions';

// api
import client from 'sources/api';

// constants
import {
  SESSION_REFRESH_REQUEST,
  SESSION_REFRESH,
} from 'store/constants/sessionTypes';
import { CURRENT_USER_FETCH } from 'store/constants/userTypes';
import { CURRENT_GROUP_FETCH } from 'store/constants/groupTypes';

// helpers
import {
  getRefreshToken,
  isTokenValid,
  setAccessToken,
} from 'helpers/tokenUtils';
import history from 'helpers/history';

// selectors
import getCurrentUserGroups from 'store/selectors/getCurrentUserGroups';
import getIsSystemAdmin from 'store/selectors/getIsSystemAdmin';
import getIsHqAdmin from 'store/selectors/getIsHqAdmin';

const TYPE = SESSION_REFRESH;
const WATCH_TYPE = SESSION_REFRESH_REQUEST;

/*
 * The session refresh saga is responsible for refreshing the access token.
 * If the access token was successfully refreshed, the current user is fetched
 * and session is marked as valid. If any of this fails, the session is destroyed
 * and the user is logged out.
 */
export function* refreshSession() {
  // Get the refresh token from local storage
  const refreshToken = yield getRefreshToken();

  // Check to see if the refresh token is valid
  const refreshTokenValid = yield isTokenValid(refreshToken);

  try {
    if (refreshTokenValid) {
      const response = yield client({
        method: 'post',
        url: '/auth/refresh',
        headers: { Authorization: `Bearer ${refreshToken}` },
      });

      // Set the new auth token in local storage
      const newAccessToken = response.data.accessToken;
      yield setAccessToken(newAccessToken);

      // Get the current group off the search params and from local storage
      const urlParams = new URLSearchParams(history.location.search);
      const urlGroupId = urlParams.get('group');

      const localStorageGroupId = localStorage.getItem('group');

      // Use the current group from the search params over the local storage
      let currentGroupId = urlGroupId || localStorageGroupId;

      // Fetch the current user
      yield put(fetchCurrentUser({ currentGroupId }));
      // Wait for the current user to be fetched
      yield take(doneIndicator(CURRENT_USER_FETCH).type);

      const currentUserGroups = yield select(state =>
        getCurrentUserGroups(state)
      );

      const isCurrentUserSystemAdmin = yield select(state =>
        getIsSystemAdmin(state)
      );
      const isCurrentUserHqAdmin = yield select(state => getIsHqAdmin(state));

      if (
        !currentGroupId &&
        currentUserGroups.size === 1 &&
        !isCurrentUserHqAdmin
      ) {
        currentGroupId = currentUserGroups.first();

        yield put(fetchCurrentUser({ currentGroupId, skipImageFetch: true }));
        // Wait for the current user to be fetched
        yield take(doneIndicator(CURRENT_USER_FETCH).type);
      }

      // If the current group is set, fetch it if the user has access
      if (currentGroupId) {
        if (
          true ||
          // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string | null' is not assignable... Remove this comment to see the full error message
          currentUserGroups.includes(parseInt(currentGroupId, 10)) ||
          isCurrentUserSystemAdmin ||
          isCurrentUserHqAdmin
        ) {
          yield put(
            setCurrentGroup({
              groupId: currentGroupId,
              skipNavigate: true,
            })
          );

          yield take(doneIndicator(CURRENT_GROUP_FETCH).type);
        } else {
          urlParams.delete('group');
          localStorage.removeItem('group');

          const newUrlParams = urlParams.toString() || '';
          yield history.replace({
            pathname: history.location.pathname,
            search: newUrlParams,
          });
        }
      }

      // Notify the store that session refresh was successful
      yield put(successIndicator(TYPE));

      // Validate the session
      yield put(validateSession());
    } else {
      // If the refresh token is invalid, dispatch an error and destroy the session
      yield put(errorIndicator(TYPE));
      yield put(error(TYPE, { msg: 'Refresh token invalid' }));
      yield put(destroySession());
    }
  } catch (err) {
    yield put(errorIndicator(TYPE));
    yield put(error(TYPE, err));

    // Destroy the session on error
    yield put(destroySession());
  } finally {
    // Notify the store that session refresh is done
    yield put(doneIndicator(TYPE));
  }
}

export function* watch() {
  yield takeEvery(WATCH_TYPE, refreshSession);
}

export default function* root() {
  yield all([fork(watch)]);
}
