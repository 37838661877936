import React, { useCallback, useMemo } from 'react';
import moment, { Moment } from 'moment';
import { isEqual } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';

// MUI components
import {
  Grid,
  Card,
  CardContent,
  WithStyles,
  withStyles,
} from '@material-ui/core';

// components
import Table from 'components/Table';
import AnalyticsActionBar from 'components/AnalyticsActionBar';

// actions
import { createNewMemberCountReport } from 'store/actions/apiTaskActions';

// selectors
import getCurrentGroupId from 'store/selectors/getCurrentGroupId';
import getForm from 'store/selectors/getForm';
import getSliceEntityById from 'store/selectors/getSliceEntityById';
import getIsHqAdmin from 'store/selectors/getIsHqAdmin';

// helpers
import { isEmpty } from 'helpers/check';
import { getComparator } from 'components/Table/helpers/sortFunctions';

// icons
import BookIcon from '@material-ui/icons/Book';

// styles
import newMemberCountStyles from 'routes/NewMemberAnalytics/NewMemberCount/newMemberCount.style';

const REPORT_FORM = 'NewMemberCountReportForm';
const ROWS_PER_PAGE = 10;

interface ContentType {
  groupName: string | null;
  totalLeadsCount: number;
  newMembersCount: number;
  reportedMembersCount: number;
  lastedReported: string | null;
  lastedReportedSort?: number;
}

export interface NewMemberCountProps
  extends WithStyles<typeof newMemberCountStyles> {}

const NewMemberCount = ({ classes }: NewMemberCountProps) => {
  const dispatch = useDispatch();

  const {
    currentGroupId,
    isHqAdmin,
    report: {
      data: {
        content: unformattedContent = [],
        meta: { columns = [] } = {},
      } = {},
      id: reportId,
    },
    form: { isSubmitting },
  } = useSelector(
    state => ({
      currentGroupId: getCurrentGroupId(state),
      isHqAdmin: getIsHqAdmin(state),
      form: getForm(REPORT_FORM)(state),
      report: getSliceEntityById(
        'report',
        'ReportTypes.NEW_MEMBERS_REPORTED_COUNT',
        {}
      )(state),
    }),
    isEqual
  );

  const dispatchCreateReport = useCallback(
    ({
      startDate,
      endDate,
      forceRefresh,
    }: {
      startDate: Moment;
      endDate: Moment;
      forceRefresh: boolean;
    }) => {
      dispatch(
        createNewMemberCountReport({
          groupId: currentGroupId,
          formName: REPORT_FORM,
          startDate,
          endDate,
          forceRefresh,
        })
      );
    },
    [currentGroupId, dispatch]
  );

  const content = useMemo<ContentType[]>(() => {
    if (!unformattedContent || isEmpty(unformattedContent)) {
      return [];
    }

    return unformattedContent.map((item: ContentType) => {
      const { lastedReported } = item || {};
      const lastedReportedMoment =
        lastedReported && moment(lastedReported).isValid()
          ? moment(lastedReported)
          : false;

      return {
        ...(item || {}),
        lastedReported: lastedReportedMoment
          ? lastedReportedMoment.local().format('MMM DD, YYYY')
          : '',
        lastedReportedSort: lastedReportedMoment
          ? lastedReportedMoment.unix()
          : 0,
      };
    });
  }, [unformattedContent]);

  const sortingComparator = (order: boolean | string, orderBy: string) => {
    const newOrderBy =
      orderBy === 'lastedReported' ? 'lastedReportedSort' : orderBy;

    return getComparator(order, newOrderBy);
  };

  return (
    <Card>
      <CardContent className={classes.cardContent}>
        <Grid container spacing={2}>
          <AnalyticsActionBar
            onCreateReport={dispatchCreateReport}
            title='Reported New Member Count'
            subtitle='See database reporting numbers for linked groups'
            icon={BookIcon}
            isHqAdmin={isHqAdmin}
            reportId={reportId}
            loading={isSubmitting}
            isHqReport
          />

          <Grid item xs={12} className={classes.tableGrid}>
            <Table
              columns={columns}
              data={content}
              variant='outlined'
              numberOfSkeletonRows={isSubmitting ? 8 : 0}
              initialOrder='asc'
              initialOrderBy='groupName'
              TablePaginationProps={{
                count: content.length,
                rowsPerPage: ROWS_PER_PAGE,
                rowsPerPageOptions: [ROWS_PER_PAGE],
              }}
              sortingComparator={sortingComparator}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default withStyles(newMemberCountStyles, { withTheme: true })(
  NewMemberCount
);
