import React, { useMemo } from 'react';
import classNames from 'classnames';
// material-ui
import FormHelperText from '@material-ui/core/FormHelperText';
import { makeStyles } from '@material-ui/core/styles';
// icons
import CheckIcon from '@material-ui/icons/Check';

interface RadioFieldAsButtonProps {
  meta: {
    active?: boolean;
    dirty?: boolean;
    touched?: boolean;
    error?: string[];
  };
  input: {
    checked?: boolean;
  };
  label?: string;
  isErrorRed?: boolean;
}

const RadioFieldAsButton = ({
  label,
  input,
  isErrorRed,
  meta: { active, dirty, error, touched },
  ...rest
}: RadioFieldAsButtonProps) => {
  const classes = useStyles();
  const errorMessage = useMemo(
    () =>
      error &&
      !active &&
      (dirty || touched) && (
        <FormHelperText
          className={classNames(classes.error, { isErrorRed })}
          error
        >
          {error[0]}
        </FormHelperText>
      ),
    [active, dirty, error, touched, isErrorRed, classes.error]
  );
  const isActive = !!input?.checked;

  return (
    <>
      <label className={classNames(classes.labelButton, { isActive })}>
        {isActive && <CheckIcon className={classes.icon} />}
        {label || ''}
        <input {...input} {...rest} className={classes.input} />
      </label>
      {errorMessage}
    </>
  );
};

const useStyles = makeStyles(theme => ({
  labelButton: {
    display: 'inline-block',
    cursor: 'pointer',
    minWidth: 165,
    minHeight: 30,
    padding: '5px 15px',
    height: 'auto',
    fontSize: '1rem',
    fontWeight: 600,
    lineHeight: '30px',
    color: '#000000',
    backgroundColor: '#A5AEB4',
    border: '1px solid #A5AEB4',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.218495)',
    borderRadius: 4,
    textAlign: 'center',
    '&.isActive': {
      backgroundColor: '#33D970',
    },
  },
  icon: {
    fontSize: '1.2em',
    verticalAlign: 'middle',
    marginRight: 5,
    marginBottom: 2,
  },
  input: {
    display: 'none',
  },
  error: {
    '&.isErrorRed': {
      color: 'red',
    },
  },
}));

export default RadioFieldAsButton;
