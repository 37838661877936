import React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';

// material-ui
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';

const WaitingRoomActions = function({ destroySession }: any) {
  const classes = useStyles();

  return (
    <Grid className={classes.actionsContainer} item>
      <Grid container justifyContent='space-between'>
        <Grid item>
          <Link
            className={classes.bold}
            variant='subtitle2'
            component={RouterLink}
            to='/select-chapter'
            color='textPrimary'
          >
            Join or Create Another Group
          </Link>
        </Grid>

        <Grid item>
          {/* @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call. */}
          <Link
            id='logoutLink'
            className={classes.bold}
            variant='subtitle2'
            component='button'
            color='textPrimary'
            to='/login'
            onClick={() => {
              destroySession();
            }}
          >
            Log Out
          </Link>
        </Grid>
      </Grid>
    </Grid>
  );
};

WaitingRoomActions.propTypes = {
  destroySession: PropTypes.func.isRequired,
};

const useStyles = makeStyles(theme => ({
  bold: {
    fontWeight: 'bold',
  },

  actionsContainer: {
    width: '100%',
  },
}));

export default WaitingRoomActions;
