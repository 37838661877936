import { Map } from 'immutable';
// constants
import { GROUP_FEATURE_USAGE_FETCH } from 'store/constants/groupFeatureUsageTypes';
// helpers
import httpReducer from './helpers/httpReducer';

export default function reduceGroupFeatureUsage(state = Map(), action = {}) {
  switch ((action as any).type) {
    case GROUP_FEATURE_USAGE_FETCH:
      return httpReducer(state, action, {
        mergeItems: false,
        entity: 'groupFeatureUsage',
      });
    default:
      return state;
  }
}
