import Immutable from 'immutable';

export function successIndicator(type: any) {
  return {
    type: `${type}_SUCCESS`,
  };
}

export function errorIndicator(type: any) {
  return {
    type: `${type}_ERROR`,
  };
}

export function doneIndicator(type: any) {
  return {
    type: `${type}_DONE`,
  };
}

export function loading(type: any, metaId: any) {
  /*
   *  `metaId` is an identifier used to set extra values on
   *  `meta` that are more specific than the entire slice.
   */
  return {
    type,
    meta: Immutable.fromJS({
      loading: true,
      metaId,
    }),
  };
}

export function error(type: any, err: any, metaId?: any) {
  /*
   *  `metaId` is an identifier used to set extra values on
   *  `meta` that are more specific than the entire slice.
   */
  const errorResponse = (err || {}).response;

  return {
    type,
    payload: Immutable.fromJS(errorResponse || err),
    error: true,
    meta: Immutable.fromJS({
      status: 'error',
      loading: false,
      metaId,
    }),
  };
}

export function success(type: any, payload: any, metaId?: any) {
  /*
   *  `metaId` is an identifier used to set extra values on
   *  `meta` that are more specific than the entire slice.
   */
  return {
    type,
    payload,
    meta: Immutable.fromJS({
      status: 'success',
      loading: false,
      metaId,
    }),
  };
}
