import React, { useEffect, useState } from 'react';
import { Map } from 'immutable';
import classNames from 'classnames';
// hooks
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
// MUI components
import { Grid, Typography, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
// components
import LeadAttributeWithFieldType from 'components/LeadInfo/LeadAttributeWithFieldType';
import LeadAttributeItemValue from 'components/LeadInfo/LeadAttributeItemValue';
// icons
import IconButton from 'components/IconButton';
import Delete from '@material-ui/icons/Delete';
// selectors
import getLoadingFromState from 'store/selectors/getLoadingFromState';
import getCurrentGroupId from 'store/selectors/getCurrentGroupId';
import getPermission from 'store/selectors/getPermission';
// action creators
import {
  deleteLeadAttribute,
  updateOrCreateLeadAttribute,
} from 'store/actions/leadAttributeActions';

type Props = {
  attribute: Map<string, string | number>;
  leadId: number;
  index: number;
  isLastItem: boolean;
  isFetching: boolean;
  onChange: () => void;
};

export default function LeadAttributeItem({
  leadId,
  attribute,
  index,
  isLastItem,
  isFetching,
  onChange,
}: Props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [awaitNewEditing, setAwaitNewEditing] = useState<boolean>(false);
  const [hoverIndex, setHoverIndex] = useState(null);
  const [editingIndex, setEditingIndex] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState(null);
  const [isAwaitingRefetch, setIsAwaitingRefetch] = useState<boolean>(false);
  const {
    updateAttributeLoading,
    deleteAttributeLoading,
    groupId,
    canDeleteLeadAttribute,
    canUpdateLeadAttribute,
  } = useSelector(
    state => ({
      groupId: getCurrentGroupId(state),
      updateAttributeLoading: getLoadingFromState(
        'leadAttribute',
        `updateOrCreate-${attribute.get('label')}`,
        false
      )(state),
      deleteAttributeLoading: getLoadingFromState(
        'leadAttribute',
        `delete-${attribute.get('label')}`,
        false
      )(state),
      canUpdateLeadAttribute: getPermission('pnmAttr.updateOne')(state),
      canDeleteLeadAttribute: getPermission('pnmAttr.deleteOne')(state),
    }),
    shallowEqual
  );

  const currentValue: any = attribute.get('value', null);

  const handleDelete = (event: any, attribute: any) => {
    event.stopPropagation();
    setInputValue(null);
    dispatch(
      deleteLeadAttribute({
        leadId,
        groupId,
        label: attribute.get('label'),
      })
    );
  };
  const handleEdit = () => {
    if (canUpdateLeadAttribute && !awaitNewEditing && !updateAttributeLoading) {
      setEditingIndex(true);
    }
  };

  const handleUpdate = (newValue?: any) => {
    setEditingIndex(false);
    setAwaitNewEditing(true);
    const value = typeof newValue !== 'undefined' ? newValue : inputValue;
    if (value !== currentValue) {
      dispatch(
        updateOrCreateLeadAttribute({
          leadId,
          groupId,
          label: attribute.get('label'),
          value,
          isNewAttribute: currentValue === undefined,
        })
      );
    }
  };

  const handleKeyDown = (event: any) => {
    if (event.key === 'Escape' && editingIndex) {
      setEditingIndex(false);
      setInputValue(null);
    }
  };
  const handleKeypress = (event: any) => {
    if (event.key === 'Enter' && editingIndex) {
      handleUpdate();
    }
  };

  useEffect(() => {
    if (
      isAwaitingRefetch &&
      !isFetching &&
      !updateAttributeLoading &&
      !deleteAttributeLoading
    ) {
      onChange();
      setIsAwaitingRefetch(false);
    }
  }, [
    isFetching,
    isAwaitingRefetch,
    updateAttributeLoading,
    deleteAttributeLoading,
    onChange,
    setIsAwaitingRefetch,
  ]);

  useEffect(() => {
    if (updateAttributeLoading || deleteAttributeLoading) {
      setIsAwaitingRefetch(true);
    }
  }, [updateAttributeLoading, deleteAttributeLoading, setIsAwaitingRefetch]);

  useEffect(() => {
    if (awaitNewEditing) {
      setTimeout(() => {
        setAwaitNewEditing(false);
      }, 800);
    }
  }, [awaitNewEditing]);

  return (
    <Grid
      item
      xs={12}
      id='attribute'
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'number' is not assignable to par... Remove this comment to see the full error message
      onMouseOver={() => setHoverIndex(index)}
      onMouseLeave={() => setHoverIndex(null)}
      onKeyPress={handleKeypress}
      onKeyDown={handleKeyDown}
      className={classNames(
        classes.attributeItem,
        canUpdateLeadAttribute && classes.attributeItemHover,
        // Don't render bottom border for the last attribute
        !isLastItem && classes.borderedItem
      )}
    >
      <Grid
        id='editableArea'
        container
        alignItems='center'
        className={classNames(classes.textCursor, classes.editArea)}
        onClick={handleEdit}
      >
        <Grid item xs>
          <Typography
            color='textSecondary'
            className={classes.attributeText}
            variant='body2'
          >
            {attribute.get('label')}
          </Typography>
        </Grid>

        <Grid item xs={2}>
          {(currentValue || currentValue === false) && canDeleteLeadAttribute && (
            <IconButton
              id='deleteButton'
              loading={deleteAttributeLoading}
              onClick={(e: any) => handleDelete(e, attribute)}
              className={
                index === hoverIndex ? classes.visible : classes.hidden
              }
            >
              <Delete />
            </IconButton>
          )}
        </Grid>

        {editingIndex ? (
          <Grid item xs={10} key='textField'>
            <LeadAttributeWithFieldType
              value={inputValue}
              attribute={attribute}
              saveValue={handleUpdate}
              setInputValue={setInputValue}
            />
          </Grid>
        ) : (
          <Grid item xs={10} className={classes.textCursor} id='label'>
            {currentValue ||
            currentValue === false ||
            !canUpdateLeadAttribute ? (
              <Typography className={classes.attributeText} variant='body2'>
                <LeadAttributeItemValue attribute={attribute} />
              </Typography>
            ) : (
              <Typography
                className={classes.emptyAttributeText}
                variant='body2'
                color='textSecondary'
              >
                Click to enter value
              </Typography>
            )}
          </Grid>
        )}

        <Grid item xs={2} id='circularProgress'>
          {updateAttributeLoading && (
            <CircularProgress size={20} disableShrink />
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}
const useStyles = makeStyles(theme => {
  const border = `1px solid rgba(255,255,255,0.25)`;
  const fontWeight = 600;
  return {
    attributeText: {
      fontWeight,
      wordWrap: 'break-word',
    },
    emptyAttributeText: {
      wordWrap: 'break-word',
    },
    attributeItem: {
      paddingLeft: 20,
      paddingRight: 20,
      paddingTop: 5,
      paddingBottom: 15,
    },
    attributeItemHover: {
      '&:hover': {
        backgroundColor: (theme.palette.background as any).lightPaper,
        transition: '0.2 ease',
      },
    },
    borderedItem: {
      borderBottom: border,
    },
    visible: {
      transition: '0.3s ease',
      opacity: 1,
    },
    hidden: {
      transition: '0.3s ease',
      opacity: 0,
    },
    textCursor: {
      cursor: 'text',
    },
    editArea: {
      padding: 5,
      minHeight: 78,
    },
    editAreaHover: {
      backgroundColor: (theme.palette.background as any).lightPaper,
      borderRadius: 3,
    },
  };
});
