import React from 'react';

// MUI components
import Grid from '@material-ui/core/Grid';

//components
import ReferralSourceStatusCard from 'routes/ReferralSourceAnalytics/ReferralSourceStatusCard';
import ReferralSourceFormDataCard from 'routes/ReferralSourceAnalytics/ReferralSourceFormDataCard';

export interface ReferralSourceAnalyticsProps {
  isCouncilReferralSourceAnalytics?: boolean;
  isOrganizationReferralSourceAnalytics?: boolean;
}

const ReferralSourceAnalytics = function({
  isCouncilReferralSourceAnalytics,
  isOrganizationReferralSourceAnalytics,
}: ReferralSourceAnalyticsProps) {
  return (
    <Grid container spacing={2} alignContent='flex-start'>
      <Grid item xs={12}>
        <ReferralSourceStatusCard
          isCouncilReferralSourceAnalytics={isCouncilReferralSourceAnalytics}
          isOrganizationReferralSourceAnalytics={
            isOrganizationReferralSourceAnalytics
          }
        />
      </Grid>
      {isOrganizationReferralSourceAnalytics && (
        <Grid item xs={12}>
          <ReferralSourceFormDataCard />
        </Grid>
      )}
    </Grid>
  );
};

export default ReferralSourceAnalytics;
