import { PaymentMethodStatus } from 'assets/types';
import { isString } from 'helpers/check';

const getPaymentStatus = (state: any): PaymentMethodStatus | undefined => {
  const status = state.getIn(['paymentSettings', 'data', 'status']);
  if (status && isString(status)) {
    return status;
  }
  return;
};

export default getPaymentStatus;
