import { List, Map } from 'immutable';
import { denormalize } from 'normalizr';

import { eventSchema } from '../schemas/eventSchema';

export default function getEvents(state: any) {
  const event = state.get('event', Map());

  const result = event.getIn(['data', 'result'], List());

  const entities = {
    event: state.getIn(['event', 'data', 'byId'], Map()).toJS(),
    member: state.getIn(['member', 'data', 'byId'], Map()).toJS(),
  };

  let items: GroupEvent[] = denormalize(result.toJS(), [eventSchema], entities);

  return items.sort((a, b) => +new Date(b.startDate) - +new Date(a.startDate));
}
