import React, { useEffect, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';

// hooks
import { useHistory } from 'react-router-dom';
import useInfiniteScroll from 'helpers/hooks/useInfiniteScroll';
import { usePrevious } from 'helpers/hooks/usePrevious';

// material-ui
import {
  Box,
  Grid,
  Typography,
  WithStyles,
  withStyles,
  Tooltip,
} from '@material-ui/core';

// components
import Table from 'components/Table';
import SuperAvatar from 'components/SuperAvatar';
import Button from 'components/Button';
import LeadStatus from 'components/LeadStatus';
import TagsCell from 'routes/LeadList/components/TagsCell';
import UpdateStatusModal from 'components/UpdateStatusModal';
import AddTagModal from 'routes/LeadList/components/AddTagModal';
import getTableColumns from 'routes/LeadList/LeadListView/LeadListTableView/helpers/getTableColumns';

// selectors
import getCurrentGroupId from 'store/selectors/getCurrentGroupId';

// helpers
import formatDisplayedPhoneNumber from 'helpers/formatDisplayedPhoneNumber';
import { LeadsQuery } from 'routes/LeadList/helpers/interfaces';

// icons
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import ShareIcon from '@material-ui/icons/Share';

// styles
import leadListTableViewStyles from 'routes/LeadList/LeadListView/LeadListTableView/leadListTableView.style';

const STATUS = 'status_custom-position';

export interface LeadListTableViewProps
  extends WithStyles<typeof leadListTableViewStyles> {
  allSelected: boolean;
  hasNextPage?: boolean;
  loading: boolean;
  onFetchNextPage: any;
  leads: Lead[];
  actionLeadIds: number[];
  withMarginTop: boolean;
  selectLead: (id: number) => void;
  leadsQuery: LeadsQuery;
  isCouncilLeadPage?: boolean | null;
  tags?: Tag[];
}

const LeadListTableView = ({
  classes,
  allSelected,
  hasNextPage,
  loading,
  onFetchNextPage,
  leads,
  actionLeadIds,
  withMarginTop = true,
  selectLead,
  isCouncilLeadPage,
  tags = [],
  leadsQuery,
}: LeadListTableViewProps) => {
  const history = useHistory();

  const {
    location: { pathname, search },
  } = history;

  const urlParams = new URLSearchParams(search);

  const handleLeadSelect = useCallback((lead: Lead) => selectLead(lead.id), [
    selectLead,
  ]);

  const destinationLeadLink = useCallback(({ id }: any = {}) => {
    return `/leads/${id}`;
  }, []);

  const [statusModalLeadId, setStatusModalLeadId] = useState<number | null>(
    null
  );
  const [tagModalLeadId, setTagModalLeadId] = useState<number | null>(null);

  const [isBottom, scrollRef] = useInfiniteScroll(loading, 200);
  const previousIsBottom = usePrevious(isBottom);

  const openStatusModal = useCallback(leadId => {
    setStatusModalLeadId(leadId);
  }, []);

  const closeStatusModal = useCallback(() => {
    setStatusModalLeadId(null);
  }, []);

  const openTagModal = useCallback(
    (leadId: number) => setTagModalLeadId(leadId),
    []
  );
  const closeTagModal = useCallback(() => setTagModalLeadId(null), []);

  useEffect(() => {
    if (!previousIsBottom && isBottom) {
      onFetchNextPage();
    }
  }, [isBottom, onFetchNextPage, previousIsBottom]);

  const groupId = useSelector(getCurrentGroupId);

  // list ordering logic
  const orderByParam = urlParams.get('orderBy');
  const searchParam = urlParams.get('search');

  let initialOrder: 'asc' | 'desc' =
    orderByParam?.charAt(0) === '-' ? 'desc' : 'asc';

  if (orderByParam && orderByParam.includes(STATUS)) {
    initialOrder = orderByParam?.charAt(0) === '-' ? 'asc' : 'desc';
  }
  let initialOrderBy = 'fullName';

  if (orderByParam) {
    initialOrderBy =
      orderByParam.charAt(0) === '-' ? orderByParam.substr(1) : orderByParam;
  }

  const fetchSortedData = (property: any, isNewOrderDesc: any) => {
    const newSearch = urlParams;
    const newOrderBy = isNewOrderDesc ? property : `-${property}`;

    urlParams.set('orderBy', newOrderBy);

    if (searchParam) urlParams.delete('orderBy');

    history.push({ pathname, search: newSearch.toString() });
  };

  const columns = getTableColumns(
    STATUS,
    !!isCouncilLeadPage,
    searchParam || ''
  );

  const formattedTableData = leads.map(lead => {
    const {
      id,
      createdOn = null,
      lastContactedOn = null,
      phoneNumber,
      tags: leadTags,
      isShared,
    } = lead || {};
    const displayedPhoneNumber = formatDisplayedPhoneNumber(phoneNumber);

    return {
      ...lead,
      isSelected: allSelected
        ? !actionLeadIds.includes(lead.id)
        : actionLeadIds.includes(lead.id),
      phoneNumber: displayedPhoneNumber,
      lastContactedOn: lastContactedOn
        ? moment
            .utc(lastContactedOn)
            .local()
            .format('MMM DD, YYYY')
        : 'Not contacted',
      createdOn: createdOn
        ? moment
            .utc(createdOn)
            .local()
            .format('MMM DD, YYYY')
        : '-',
      avatar: isCouncilLeadPage ? (
        <div className={classes.avatarCellBox}>
          <SuperAvatar
            fullName={lead.fullName}
            imageUrl={lead.profilePhotoFileUrl}
            size='medium'
            background='dark'
          />
          {isShared && (
            <Tooltip title={'Shared with Chapters'}>
              <div className={classes.sharedLead}>
                <ShareIcon />
              </div>
            </Tooltip>
          )}
        </div>
      ) : (
        <SuperAvatar
          fullName={lead.fullName}
          imageUrl={lead.profilePhotoFileUrl}
          size='medium'
          background='dark'
        />
      ),
      [STATUS]: <LeadStatus lead={lead} openStatusModal={openStatusModal} />,
      tagsLabel: (
        <TagsCell
          currentLeadId={id}
          tags={tags}
          currentTags={leadTags || []}
          leadsQuery={leadsQuery}
          onOpenTagModal={openTagModal}
        />
      ),
    };
  });

  return (
    <Box clone marginTop={withMarginTop ? 3 : 0}>
      {/* @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call. */}
      <Grid
        container
        className={classes.listContainer}
        ref={scrollRef}
        direction='column'
        wrap='nowrap'
      >
        {!loading && !leads.length ? (
          <Grid item>
            <Grid container>
              <Grid
                item
                xs={12}
                sm={10}
                md={8}
                lg={6}
                className={classes.noneFoundContainer}
              >
                <Typography variant='h6'>No leads Found.</Typography>
                <Typography variant='body1' color='secondary'>
                  Add leads from Actions, Forms, or Events
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <Grid item>
            <Table
              id='table'
              columns={columns}
              data={formattedTableData}
              numberOfSkeletonRows={loading ? 11 : 0}
              options={{
                enableCheckboxes: true,
                enableHeaderCheckbox: false,
              }}
              fetchSortedData={fetchSortedData}
              initialOrder={initialOrder}
              initialOrderBy={Boolean(searchParam) ? null : initialOrderBy}
              TableProps={{
                className: classes.table,
                stickyHeader: true,
              }}
              TableRowProps={{
                classes: {
                  root: classes.root,
                  selected: classes.selected,
                  hover: classes.hover,
                },
                checkboxTooltipTitle: 'Select lead',
                hasLinkTo: destinationLeadLink,
              }}
              TableCellProps={{
                className: classes.tableCell,
              }}
              HeaderTableCellProps={{
                className: classes.headerTableCell,
              }}
              CheckboxProps={{
                onSelect: handleLeadSelect,
              }}
              SkeletonProps={{
                height: 70,
              }}
            />
          </Grid>
        )}

        {hasNextPage && leads.length && (
          <Grid item className={classes.loadMoreButton}>
            <Button
              variant='outlined'
              onClick={() => onFetchNextPage()}
              startIcon={<ArrowDownward />}
            >
              Load More
            </Button>
          </Grid>
        )}

        <UpdateStatusModal
          open={!!statusModalLeadId}
          onClose={closeStatusModal}
          leadIds={statusModalLeadId ? [statusModalLeadId] : []}
          groupId={groupId}
          isCouncilLeadPage={!!isCouncilLeadPage}
        />

        <AddTagModal
          open={!!tagModalLeadId}
          onClose={closeTagModal}
          leadIds={tagModalLeadId ? [tagModalLeadId] : []}
          tags={tags}
          leads={leads}
          leadsQuery={leadsQuery}
        />
      </Grid>
    </Box>
  );
};

export default withStyles(leadListTableViewStyles, { withTheme: true })(
  LeadListTableView
);
