import { StripeFieldErrorMessage } from 'components/ManageSubscription/helpers/interfaces';

const updateStripeErrorMessage = (key: string, value: string) => (
  errors: StripeFieldErrorMessage[]
): StripeFieldErrorMessage[] => {
  const errorsList = [...errors];
  const indexByKey = errorsList.findIndex(
    ({ key: errorKey }: StripeFieldErrorMessage) => errorKey === key
  );
  const newError: StripeFieldErrorMessage = {
    key,
    value,
  };
  if (indexByKey !== -1) {
    if (!value && indexByKey) {
      return [
        ...errorsList.slice(0, indexByKey),
        ...errorsList.slice(indexByKey + 1),
      ];
    }
    errorsList[indexByKey] = newError;
    return errorsList;
  }

  errorsList.push(newError);
  return errorsList;
};

export default updateStripeErrorMessage;
