import { all } from 'redux-saga/effects';

// sagas
import fetchStripeConfigSaga from './fetchStripeConfigSaga';
import createBillingPortalSaga from './createBillingPortalSaga';

const billingSaga = function* rootSaga() {
  yield all([fetchStripeConfigSaga(), createBillingPortalSaga()]);
};

export default billingSaga;
