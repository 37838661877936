import {
  USER_REGISTER_REQUEST,
  USER_VERIFY_EMAIL_REQUEST,
  CURRENT_USER_UPDATE_REQUEST,
  CURRENT_USER_ADD,
  CURRENT_USER_FETCH_REQUEST,
  CURRENT_USER_UPDATE_PASSWORD_REQUEST,
  SYSTEM_ADMIN_FETCH_REQUEST,
  USER_CLEAR,
  USER_FETCH_BY_IDS_REQUEST,
} from 'store/constants/userTypes';

export function registerUser({ firstName, lastName, email, password }: any) {
  return {
    type: USER_REGISTER_REQUEST,
    payload: { firstName, lastName, email, password },
  };
}

export function verifyUserEmail(token: any, history: any) {
  return {
    type: USER_VERIFY_EMAIL_REQUEST,
    payload: { token, history },
  };
}

export function addCurrentUser(payload: any) {
  return {
    type: CURRENT_USER_ADD,
    payload,
  };
}

export function updateCurrentUser({
  formName,
  firstName,
  lastName,
  profilePhotoFileId,
  phoneNumber,
  allowEmailNotifications,
  allowPushNotifications,
}: any) {
  return {
    type: CURRENT_USER_UPDATE_REQUEST,
    payload: {
      formName,
      firstName,
      lastName,
      profilePhotoFileId,
      phoneNumber,
      allowEmailNotifications,
      allowPushNotifications,
    },
  };
}

export function fetchCurrentUser({ currentGroupId, skipImageFetch }: any) {
  return {
    type: CURRENT_USER_FETCH_REQUEST,
    payload: {
      currentGroupId,
      skipImageFetch,
    },
  };
}

export function fetchUserByIds({ ids }: any) {
  return {
    type: USER_FETCH_BY_IDS_REQUEST,
    payload: { ids },
  };
}

export function fetchSystemAdmins({ page }: any) {
  return {
    type: SYSTEM_ADMIN_FETCH_REQUEST,
    payload: { page },
  };
}

export function updateCurrentUserPassword({
  currentPassword,
  newPassword,
}: any) {
  return {
    type: CURRENT_USER_UPDATE_PASSWORD_REQUEST,
    payload: { currentPassword, newPassword },
  };
}

export function clearUsers() {
  return {
    type: USER_CLEAR,
  };
}
