import React, { useContext, useEffect } from 'react';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import Bugsnag from '@bugsnag/js';

// material-ui
import { makeStyles } from '@material-ui/core/styles';

// react router
import { Switch, Route, Redirect } from 'react-router-dom';

// components
import Login from 'routes/Login';
import Signup from 'routes/Signup';
import ForgotPassword from 'routes/ForgotPassword';
import WaitingRoom from 'routes/WaitingRoom';
import EmailVerification from 'routes/EmailVerification';
import SelectChapter from 'routes/SelectChapter';
import ReferralForm from 'routes/ReferralForm';
import ApplicationLayout from 'layout/ApplicationLayout';
import LoadingScreen from './components/LoadingScreen';
// helpers
import { SiteVisualDataContext } from 'components/SiteVisualData';
import OpenIDConnect from 'routes/Oidc';

type Props = {
  currentGroup: Group;
  currentGroupLoading: boolean;
  currentUser: CurrentUser;
  currentUserLoading: boolean;
  isSystemAdmin: boolean;
  sessionIsValid: boolean | 'unknown';
  userHasGroup: boolean;
  verifySession: (...args: any[]) => any;
};

const App = ({
  currentGroup,
  currentGroupLoading,
  currentUser,
  currentUserLoading,
  isSystemAdmin,
  sessionIsValid,
  userHasGroup,
  verifySession,
}: Props) => {
  const classes = useStyles();
  const { pageTitle = '' } = useContext(SiteVisualDataContext);

  useEffect(() => {
    if (sessionIsValid === 'unknown') {
      verifySession();
    }
  }, [sessionIsValid, verifySession]);

  useEffect(() => {
    document.title = pageTitle;
  }, [pageTitle]);

  const canUserAccessApp = () => {
    if (sessionIsValid && sessionIsValid !== 'unknown') {
      if (isSystemAdmin) {
        return true;
      } else if (userHasGroup) {
        return true;
      }
    }
    return false;
  };

  if (
    !currentUserLoading &&
    !currentGroupLoading &&
    process.env.BUGSNAG_API_KEY
  ) {
    // Initate the bugsnag instance
    Bugsnag.start({
      apiKey: process.env.BUGSNAG_API_KEY,
      plugins: [new BugsnagPluginReact()],
      onError: event => {
        // Set extra data for any errors that we report
        if (currentUser) {
          event.setUser(
            currentUser.id?.toString(),
            currentUser.primaryEmail,
            `${currentUser.firstName} ${currentUser.lastName}`
          );
        }
        if (currentGroup) {
          event.addMetadata('group', {
            id: currentGroup.id?.toString(),
            name: currentGroup.name,
            displayName: currentGroup.displayName,
          });
        }
      },
    });
  }

  return (
    <div className={classes.app} id='appContainer'>
      <Switch>
        <Route path='/login' component={Login} />
        <Route path='/signup' component={Signup} />
        <Route path='/oidc/redirect' component={OpenIDConnect} />
        <Route path='/forgot-password/:token' component={ForgotPassword} />
        <Route path='/forgot-password' component={ForgotPassword} />
        <Route path='/auth/confirm/:token' component={EmailVerification} />
        <Route path='/emails/confirm/:token' component={EmailVerification} />
        <Route
          key='view'
          path={`/forms/:formId/view`}
          component={ReferralForm}
        />

        {sessionIsValid && [
          <Route
            path='/select-chapter'
            component={SelectChapter}
            key='/select-chapter'
          />,
          <Route
            path='/requests/pending'
            component={WaitingRoom}
            key='/requests/pending'
          />,
        ]}

        {sessionIsValid === 'unknown' ? (
          <LoadingScreen />
        ) : canUserAccessApp() ? (
          [
            <Route
              key='edit'
              path={`/forms/:formId/edit`}
              component={ReferralForm}
            />,
            <Route key='app' path='/' component={ApplicationLayout} />,
          ]
        ) : (
          <Redirect to='/login' />
        )}
      </Switch>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  app: {
    height: '100vh',
    display: 'flex',
    margin: 0,
    padding: 0,

    // Hides scrollbar for entire site
    '& *': {
      '&::-webkit-scrollbar': {
        display: 'none',
      },
    },
  },
}));

export default App;
