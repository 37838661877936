import {
  ACTIVITY_FETCH_REQUEST,
  ACTIVITY_FETCH_BY_ID_REQUEST,
  ACTIVITY_CLEAR,
} from 'store/constants/activityTypes';

export function fetchActivity({ id }: any) {
  return {
    type: ACTIVITY_FETCH_BY_ID_REQUEST,
    payload: { id },
  };
}

export function fetchActivities({
  source,
  actions,
  actorId,
  createdOnFrom,
  createdOnTo,
  orderBy,
  page = 1,
  targetId,
  targetType,
}: any) {
  return {
    type: ACTIVITY_FETCH_REQUEST,
    payload: {
      source,
      actions,
      actorId,
      createdOnFrom,
      createdOnTo,
      orderBy,
      page,
      targetId,
      targetType,
    },
  };
}

export function clearActivities() {
  return {
    type: ACTIVITY_CLEAR,
  };
}
