import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import 'react-quill/dist/quill.snow.css'; // ES6
import ReactQuill from 'react-quill';

// material-ui
import { makeStyles } from '@material-ui/core/styles';

const WizzyWigField = function({
  input,
  setSelectionStartIndex,
  clipboard = null,
  ...restOfProps
}: any) {
  const classes = useStyles();
  const inputRef = useRef(null);

  let selectionStartIndex = 0;

  if (inputRef.current && setSelectionStartIndex) {
    // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
    const editor = inputRef.current.getEditor();
    const selection = editor.getSelection();
    if (selection) {
      selectionStartIndex = selection.index;
    }
  }

  useEffect(() => {
    if (setSelectionStartIndex) {
      setSelectionStartIndex(selectionStartIndex);
    }
  }, [selectionStartIndex, setSelectionStartIndex]);

  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, false] }],
      ['bold', 'italic', 'underline'],
      [{ color: [] }, { background: [] }],
      ['link'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      ['clean'],
    ],
    ...(clipboard ? { clipboard } : {}),
  };

  return (
    <div className={classes.editor}>
      <ReactQuill
        theme='snow'
        value={input.value}
        modules={modules}
        onChange={input.onChange}
        ref={inputRef}
        {...restOfProps}
      />
    </div>
  );
};

WizzyWigField.propTypes = {
  input: PropTypes.instanceOf(Object).isRequired,
  editorRef: PropTypes.instanceOf(Object),
  setSelectionStartIndex: PropTypes.func,
};

const useStyles = makeStyles(theme => {
  return {
    editor: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.black,
    },
    // Bring back this style for a child div of ReactQuill if this bug has been fixed:
    //  https://github.com/zenoamaro/react-quill/issues/309
    editingArea: {
      minHeight: 163,
    },
  };
});

export default WizzyWigField;
