import { useMutation, useQueryClient, useInfiniteQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { client, Resource } from 'api';
import getCurrentGroupId from 'store/selectors/getCurrentGroupId';
import queryString from 'query-string';

export function useNotificationsQuery() {
  const params = { groupId: useSelector(getCurrentGroupId) };
  return useInfiniteQuery(
    [Resource.Notifications, params],
    ({ pageParam = 1 }) =>
      client
        .get(Resource.Notifications, { params: { ...params, page: pageParam } })
        .then(({ data }) => data),
    {
      select: data => ({
        ...data,
        pages: data.pages.map(page => page.results),
      }),
      getNextPageParam: lastPage => queryString.parse(lastPage.next).page,
      enabled: Boolean(params.groupId),
      refetchInterval: 15000,
    }
  );
}

export function useReadNotificationsMutation() {
  const queryClient = useQueryClient();

  const params = { groupId: useSelector(getCurrentGroupId) };

  return useMutation(
    () =>
      client.post(Resource.Notifications + '/mark_all_read', null, { params }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([Resource.Notifications, params]);
      },
    }
  );
}
