export default function getFieldTypeOptions() {
  return [
    {
      value: 'text',
      label: 'Text',
    },
    {
      value: 'select',
      label: 'Select',
    },
    {
      value: 'multiselect',
      label: 'Multiselect',
    },
    {
      value: 'boolean',
      label: 'Checkbox',
    },
  ];
}
