import { Map } from 'immutable';
// constants
import {
  API_TASK_CREATE_EXPORT,
  API_TASK_FETCH,
  API_TASK_REPORT_NEW_MEMBERS,
  API_TASK_REPORT_COMMUNICATION_OVERVIEW,
  API_TASK_REPORT_GROUP_ACTIVITY,
  API_TASK_REPORT_GROUP_LEADERBOARD,
  API_TASK_REPORT_GROUP_STATUS,
  API_TASK_REPORT_LEADS_INFO,
  API_TASK_REPORT_LEADS_MAIN_CONTACT,
  API_TASK_REPORT_LEADS_OVERVIEW,
  API_TASK_REPORT_LEAD_COMMUNICATION,
  API_TASK_REPORT_MEMBER_COMMUNICATION,
  API_TASK_REPORT_MEMBER_LEADERBOARD,
  API_TASK_REPORT_MILESTONE_STATUS,
  API_TASK_REPORT_RECRUITMENT_ACTIVITY_MILESTONES,
  API_TASK_REPORT_RECRUITMENT_ACTIVITY_TAGS,
  API_TASK_REPORT_RECRUITMENT_ACTIVITY,
  API_TASK_REPORT_MILESTONE_USAGE,
  API_TASK_REPORT_TAG_STATUS,
  API_TASK_REPORT_TAG_USAGE,
  API_CREATE_TASK_REPORT,
} from 'store/constants/apiTaskTypes';
// helpers
import httpReducer from './helpers/httpReducer';

export default function reduceApiTask(state = Map(), action = {}) {
  switch ((action as any).type) {
    case API_TASK_FETCH:
      return httpReducer(state, action, {
        entity: 'apiTask',
        updateItemsById: true,
        mergeItems: false,
      });
    case API_TASK_CREATE_EXPORT:
    case API_TASK_REPORT_COMMUNICATION_OVERVIEW:
    case API_TASK_REPORT_GROUP_ACTIVITY:
    case API_TASK_REPORT_GROUP_LEADERBOARD:
    case API_TASK_REPORT_GROUP_STATUS:
    case API_TASK_REPORT_LEAD_COMMUNICATION:
    case API_TASK_REPORT_LEADS_INFO:
    case API_TASK_REPORT_LEADS_MAIN_CONTACT:
    case API_TASK_REPORT_LEADS_OVERVIEW:
    case API_TASK_REPORT_MEMBER_COMMUNICATION:
    case API_TASK_REPORT_MEMBER_LEADERBOARD:
    case API_TASK_REPORT_MILESTONE_STATUS:
    case API_TASK_REPORT_MILESTONE_USAGE:
    case API_TASK_REPORT_RECRUITMENT_ACTIVITY_MILESTONES:
    case API_TASK_REPORT_RECRUITMENT_ACTIVITY_TAGS:
    case API_TASK_REPORT_RECRUITMENT_ACTIVITY:
    case API_TASK_REPORT_TAG_STATUS:
    case API_TASK_REPORT_TAG_USAGE:
    case API_CREATE_TASK_REPORT:
      return httpReducer(state, action);
    case API_TASK_REPORT_NEW_MEMBERS:
      return httpReducer(state, action, {
        entity: 'apiTask',
        updateItemsById: false,
        updateResult: false,
      });
    default:
      return state;
  }
}
