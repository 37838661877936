import { schema } from 'normalizr';

// Uses an item from the member state, but sets the id to that of the account id
export const memberSchema = new schema.Entity(
  'member',
  {},
  {
    idAttribute: value => value.account.id,
  }
);
