import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
// mui
import {
  IconButton,
  InputBase,
  InputAdornment,
  CircularProgress,
  useTheme,
} from '@material-ui/core';
import { CheckCircle, Cancel } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
// actions
import { updateCommentAction } from 'store/actions/commentActions';
// selectors
import getLoadingFromState from 'store/selectors/getLoadingFromState';
import { usePrevious } from 'helpers/hooks/usePrevious';
import getStatusFromState from 'store/selectors/getStatusFromState';
type Props = {
  id: number;
  body: string;
  onExit: (...args: any[]) => any;
};
export default function UpdateCommentInput({ id, body, onExit }: Props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const loading = useSelector(getLoadingFromState('comment', id, false));
  const status = useSelector(getStatusFromState('comment', id, 'success'));
  const previousLoading = usePrevious(loading);
  const [updatedBody, setUpdatedBody] = useState();
  const inputRef = useRef(null);
  const theme = useTheme();
  const updateComment = () =>
    dispatch(updateCommentAction({ id, fields: { body: updatedBody } }));
  const handleChange = (event: any) => setUpdatedBody(event.target.value);
  const handleKeyDown = (event: any) => {
    if (event.key === 'Escape') onExit();
    else if ((event.ctrlKey || event.metaKey) && event.key === 'Enter')
      updateComment();
  };
  useEffect(() => {
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
    setUpdatedBody(body);
    setTimeout(() => {
      if (inputRef.current) {
        // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
        inputRef.current.focus();
      }
    }, theme.transitions.duration.leavingScreen);
  }, [body, theme]);
  useEffect(() => {
    if (previousLoading && !loading && status === 'success') onExit();
  }, [previousLoading, loading, status, onExit]);
  return (
    <InputBase
      inputRef={inputRef}
      className={classes.updateInput}
      value={updatedBody}
      multiline
      fullWidth
      onChange={handleChange}
      onKeyDown={handleKeyDown}
      endAdornment={
        <InputAdornment position='end'>
          {loading ? (
            <CircularProgress size={20} />
          ) : (
            <>
              <IconButton size='small' onClick={onExit}>
                <Cancel fontSize='small' />
              </IconButton>
              {body !== (updatedBody as any)?.trim() && (
                <IconButton size='small' onClick={updateComment}>
                  <CheckCircle fontSize='small' />
                </IconButton>
              )}
            </>
          )}
        </InputAdornment>
      }
    />
  );
}
const useStyles = makeStyles(theme => ({
  updateInput: (theme as any).typography.body2,
}));
