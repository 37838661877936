import React, { useCallback, useEffect, useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { List } from 'immutable';

// components
import Select from 'components/Select';
import { Field } from 'react-final-form';
import { OptionsProps } from 'components/ChapterDropdown/ChapterDropdown';

// material-ui
import { Grid } from '@material-ui/core';

// selectors
import getChapters from 'store/selectors/getChapters';
import getNextPageFromState from 'store/selectors/getNextPageFromState';
import getLoadingFromState from 'store/selectors/getLoadingFromState';

// actions
import { fetchGroups } from 'store/actions/groupActions';

// types
import { GroupFormatType } from 'store/selectors/getChapters';

interface ChapterSelectionFieldProps {
  className?: string;
  groupId: number | string;
  multiple: boolean;
  subtext?: string;
  label?: string;
  suggestions?: GroupFormatType;
}

const ChapterSelectionField = ({
  className,
  groupId,
  multiple,
  subtext,
  label = 'All Chapters',
  suggestions = 'suggestionsByOrgName',
}: ChapterSelectionFieldProps) => {
  const dispatch = useDispatch();

  const { location: { pathname, search } = {} } = useHistory();
  const editing = pathname?.includes('edit');
  const urlParams = new URLSearchParams(search);
  const token = urlParams.get('token');

  const { chapters, chapterLoading, groupNextPage } = useSelector(
    state => ({
      chapters: getChapters(state, suggestions),
      chapterLoading: getLoadingFromState(
        'group',
        'greekChapter',
        false
      )(state),
      groupNextPage: getNextPageFromState('group', 'greekChapter')(state),
    }),
    shallowEqual
  );

  const getChildren = useCallback(
    (page: number | null) => {
      dispatch(
        fetchGroups({
          parentId: groupId,
          type: 'GREEK_CHAPTER',
          appendResult: true,
          page,
          token,
        })
      );
    },
    [dispatch, token, groupId]
  );

  useEffect(() => {
    if (!chapterLoading) {
      getChildren(1);
    }
  }, []); // eslint-disable-line

  useEffect(() => {
    if (groupNextPage) getChildren(groupNextPage);
  }, [getChildren, groupNextPage]);

  const chapterOptions = useMemo<OptionsProps[]>(
    () => (List.isList(chapters) ? (chapters.toJS() as OptionsProps[]) : []),
    [chapters]
  );

  return (
    <Grid item xs={12} className={className}>
      <Field
        disabled={editing}
        name='childIds'
        margin='normal'
        variant='outlined'
        helperText={subtext}
        render={props => (
          <Select
            label={label}
            options={chapterOptions}
            multiple={multiple}
            {...props}
          />
        )}
      />
    </Grid>
  );
};

export default ChapterSelectionField;
