import React, { useCallback, useContext } from 'react';

// redux hooks
import { useDispatch } from 'react-redux';

// actions
import { deletePhoneNumber } from 'store/actions/phoneNumberActions';

// components
import DazzlingDialog from 'components/DazzlingDialog';
import { Grid, Typography } from '@material-ui/core';
import { DeleteOutline } from '@material-ui/icons';

// helpers
import { SiteVisualDataContext } from 'components/SiteVisualData';

type Props = {
  open: boolean;
  onClose: (...args: any[]) => any;
  phoneNumberId: any;
  isCurrentGroupCouncil?: boolean;
};

export default function DeletePhoneNumberModal({
  open,
  onClose,
  phoneNumberId,
  isCurrentGroupCouncil,
}: Props) {
  const dispatch = useDispatch();
  const { deletePhoneNumberModalFirstMessage } = useContext(
    SiteVisualDataContext
  );
  const onSubmit = useCallback(() => {
    dispatch(
      deletePhoneNumber({
        phoneNumberId: phoneNumberId,
      })
    );
  }, [dispatch, phoneNumberId]);

  return (
    <DazzlingDialog
      id='deletePhoneNumberModal'
      successMessage='Phone Number Deleted Successfully'
      failureMessage='Failed to Delete Phone Number'
      handleClose={onClose}
      open={open}
      metaId={`delete-${phoneNumberId}`}
      slice='phoneNumber'
      acceptLabel='Delete'
      onAccept={onSubmit}
      headerProps={{
        icon: DeleteOutline,
        title: `Delete Phone Number`,
        highlightedSubtitle: isCurrentGroupCouncil
          ? ''
          : 'After deleting it you will no longer be able to send messages or make calls from it. It will also delete all message threads in your Message tab, but they will still be visible on the activity feeds.',
        ...(isCurrentGroupCouncil ? { alignItems: 'center' } : {}),
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid
            container
            justifyContent='center'
            alignItems='center'
            spacing={2}
          >
            <Grid item xs={12}>
              <Typography variant='h6'>
                {isCurrentGroupCouncil ? (
                  <>
                    Are you sure you want to delete your phone line? By deleting
                    your phone line, you will lose access to this number and
                    will be unable to get it back. Additionally, all message
                    threads sent by this number will be removed. Are you sure
                    you want to delete this number?
                  </>
                ) : (
                  deletePhoneNumberModalFirstMessage
                )}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </DazzlingDialog>
  );
}
