import React, { useEffect, useState } from 'react';
import { List, Map } from 'immutable';
import { isEqual } from 'lodash';
import getGroupParent from 'helpers/getGroupParent';
import moment from 'moment';
// hooks
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
// material-ui
import { makeStyles } from '@material-ui/core/styles';
// components
import GroupListTable from './GroupListTable';
import GroupListActions from './GroupListActions';
import JumboTabs from 'components/JumboTabs';
import LabelsCell from './LabelsCell';
import ParentCell from './ParentCell';
// icons
import ChapterIcon from '@material-ui/icons/AccountBalance';
import CouncilIcon from '@material-ui/icons/School';
import OrgIcon from '@material-ui/icons/LocationCity';
// action creators
import { storeClear } from 'store/actions/storeActions';
// selectors
import getOrganizations from 'store/selectors/getOrganizations';
import getCouncils from 'store/selectors/getCouncils';
import getIsSystemAdmin from 'store/selectors/getIsSystemAdmin';
import getTotal from 'store/selectors/getTotal';
import getCurrentGroupId from 'store/selectors/getCurrentGroupId';
import getIsHqAdmin from 'store/selectors/getIsHqAdmin';
import getIsCouncilAdmin from 'store/selectors/getIsCouncilAdmin';
import getLoadingFromState from 'store/selectors/getLoadingFromState';
import getChapters from 'store/selectors/getChapters';
import getIsCurrentGroupOrganization from 'store/selectors/getIsCurrentGroupOrganization';
import getIsCurrentGroupSystem from 'store/selectors/getIsCurrentGroupSystem';
import getIsCurrentGroupCouncil from 'store/selectors/getIsCurrentGroupCouncil';

export default function GroupList() {
  const {
    chapterLoading,
    chapters,
    councilLoading,
    councilTotal,
    councils,
    currentGroup,
    currentGroupId,
    currentGroupLoading,
    currentUser,
    file,
    greekChapterTotal,
    greekOrganizationTotal,
    isCurrentGroupOrganization,
    isCurrentGroupCouncil,
    isCurrentGroupSystem,
    isCouncilAdmin,
    isHqAdmin,
    isSystemAdmin,
    organizationLoading,
    organizations,
    role,
    session,
  } = useSelector(
    state => ({
      chapters: getChapters(state).toJS() as any,
      chapterLoading: getLoadingFromState(
        'group',
        'greekChapter',
        false
      )(state),
      councilTotal: getTotal('group', 'councilTotal')(state),
      // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
      councils: getCouncils(state).toJS(),
      councilLoading: getLoadingFromState('group', 'council', false)(state),
      currentGroup: (state as any).get('currentGroup'),
      currentGroupId: getCurrentGroupId(state),
      currentGroupLoading: getLoadingFromState(
        'currentGroup',
        null,
        false
      )(state),
      currentUser: (state as any).get('currentUser'),
      file: (state as any).get('file'),
      greekChapterTotal: getTotal('group', 'greekChapterTotal')(state),
      greekOrganizationTotal: getTotal(
        'group',
        'greekOrganizationTotal'
      )(state),
      isCurrentGroupOrganization: getIsCurrentGroupOrganization(state),
      isCurrentGroupCouncil: getIsCurrentGroupCouncil(state),
      isCurrentGroupSystem: getIsCurrentGroupSystem(state),
      isSystemAdmin: getIsSystemAdmin(state),
      isCouncilAdmin: getIsCouncilAdmin(state),
      isHqAdmin: getIsHqAdmin(state),
      // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
      organizations: getOrganizations(state).toJS(),
      organizationLoading: getLoadingFromState(
        'group',
        'greekOrganization',
        false
      )(state),
      role: (state as any).get('role'),
      session: (state as any).get('session'),
    }),
    isEqual
  );
  const loading =
    councilLoading ||
    organizationLoading ||
    chapterLoading ||
    currentGroupLoading;
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    location: { pathname, search },
  } = history;
  const [allSelected, setAllSelected] = useState(false);
  const [selectedGroups, setSelectedGroups] = useState<Group[]>([]);
  const handleResetSelected = () => setSelectedGroups([]);
  useEffect(() => {
    dispatch(
      storeClear(
        Map({
          currentUser,
          currentGroup,
          session,
          file,
          role,
        })
      )
    );
  }, []); // eslint-disable-line
  useEffect(() => {
    if (pathname === '/groups') {
      history.replace({ pathname: '/groups/chapters', search });
    }
  });
  let tabs = List([
    Map({
      title: 'Chapters',
      icon: <ChapterIcon />,
      subtitle:
        greekChapterTotal > 0 ? `${greekChapterTotal} results` : 'None found',
      navigateTo: '/groups/chapters',
    }),
  ]);
  if (isSystemAdmin && (!currentGroupId || isCurrentGroupSystem)) {
    tabs = tabs.push(
      Map({
        title: 'Organizations',
        icon: <OrgIcon />,
        subtitle:
          greekOrganizationTotal > 0
            ? `${greekOrganizationTotal} results`
            : 'None found',
        navigateTo: '/groups/organizations',
      })
    );
    tabs = tabs.push(
      Map({
        title: 'Councils',
        icon: <CouncilIcon />,
        subtitle: councilTotal > 0 ? `${councilTotal} results` : 'None found',
        navigateTo: '/groups/councils',
      })
    );
  }
  const getData = () => {
    if (pathname.includes('chapters')) {
      return chapters.map((group = {}) => {
        const organization = getGroupParent(group, 'GREEK_ORGANIZATION');
        const council = getGroupParent(group, 'COUNCIL');
        const {
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'dashboardLinkedParents' does not exist o... Remove this comment to see the full error message
          dashboardLinkedParents = [],
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'latestActivity' does not exist on type '... Remove this comment to see the full error message
          latestActivityOn,
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'id' does not exist on type '{}'.
          id: groupId,
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'labels' does not exist on type '{}'.
          labels = [],
        } = group || [];
        if (latestActivityOn) {
          (group as any).latestActivityOn = moment
            .utc(latestActivityOn)
            .local()
            .format('MMM D, YYYY');
        }
        if (isCurrentGroupOrganization) {
          (group as any).labels = (
            <LabelsCell tags={labels.filter(Boolean)} groupId={groupId} />
          );
        }
        if (isSystemAdmin) {
          const isOrganizationLinked = Boolean(
            dashboardLinkedParents.find(
              (p = {}) => (p as any).id === organization?.id
            )
          );
          const isCouncilLinked = Boolean(
            dashboardLinkedParents.find(
              (p = {}) => (p as any).id === council?.id
            )
          );
          (group as any).organization = (
            <ParentCell
              parentId={organization.id}
              childId={groupId}
              parentName={organization?.name}
              isLinked={isOrganizationLinked}
            />
          );
          (group as any).council = (
            <ParentCell
              parentId={council.id}
              childId={groupId}
              parentName={council?.name}
              isLinked={isCouncilLinked}
            />
          );
        } else {
          (group as any).organization = organization?.name;
          (group as any).council = council?.name;
        }
        return group;
      });
    } else if (pathname.includes('organizations') && isSystemAdmin) {
      return organizations;
    } else if (pathname.includes('councils') && isSystemAdmin) {
      return councils;
    } else {
      return [];
    }
  };
  let data = getData();
  return (
    <div className={classes.groupsListContainer}>
      {isSystemAdmin && (
        <div className={classes.tabsContainer}>
          <JumboTabs tabs={tabs} tabSpacing='compact' />
        </div>
      )}
      {(isSystemAdmin ||
        isHqAdmin ||
        (isCouncilAdmin && currentGroupId && isCurrentGroupCouncil)) && (
        <GroupListActions
          allSelected={allSelected}
          resetSelected={handleResetSelected}
          selectedGroups={selectedGroups}
          setAllSelected={setAllSelected}
          data={data}
        />
      )}

      <GroupListTable
        data={data}
        loading={loading}
        selectedGroups={selectedGroups}
        setSelectedGroups={setSelectedGroups}
        allSelected={allSelected}
      />
    </div>
  );
}
const useStyles = makeStyles(theme => {
  return {
    groupsListContainer: {
      height: '100%',
      overflow: 'hidden',
      margin: 0,
    },
    tabsContainer: {
      backgroundColor: (theme as any).palette.background.darkPaper,
      padding: 8,
      paddingBottom: 0,
    },
  };
});
