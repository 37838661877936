export const isEmpty = (value: any): boolean => {
  try {
    return (
      value === undefined ||
      value === '' ||
      value === null ||
      JSON.stringify(value) === JSON.stringify({}) ||
      value.length === 0
    );
  } catch (e) {
    return false;
  }
};

export const isNumber = (value: any) => {
  const result =
    !Number.isNaN(parseFloat(value)) && Number.isFinite(parseFloat(value));
  if (result) {
    return String(parseFloat(value)).length === String(value).length;
  }

  return false;
};

export const isObject = (value: any): boolean =>
  typeof value === 'object' && value !== null;

export const isString = (value: any) =>
  typeof value === 'string' || value instanceof String;

export const isArray = (value: any): boolean => Array.isArray(value);

export const isFunc = (value: any) =>
  value && {}.toString.call(value) === '[object Function]';
