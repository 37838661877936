import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

// action creators
import { doneIndicator } from 'store/actions/httpActions';

// api
import client from 'sources/api';

// constants
import {
  INVITE_RESEND_REQUEST,
  INVITE_RESEND,
} from 'store/constants/inviteTypes';

// schemas
import { inviteSchema } from 'store/schemas/inviteSchema';

// helpers
import httpSaga from 'store/sagas/httpSaga';

const WATCH_TYPE = INVITE_RESEND_REQUEST;
const TYPE = INVITE_RESEND;

export function* resendInviteSaga(action: any) {
  const {
    payload: { groupId, email },
  } = action;

  // @ts-expect-error ts-migrate(2569) FIXME: Type 'Generator<any, any, unknown>' is not an arra... Remove this comment to see the full error message
  yield* httpSaga(
    TYPE,
    call(client.post, '/invites/resend', {
      group_id: groupId,
      email,
    }),
    {
      schema: inviteSchema,
      metaId: `resend-${email}`,
    }
  );

  yield put(doneIndicator(TYPE));
}

export function* watch() {
  yield takeEvery(WATCH_TYPE, resendInviteSaga);
}

export default function* root() {
  yield all([fork(watch)]);
}
