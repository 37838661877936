import React from 'react';

// hooks
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

// MUI components
import { makeStyles } from '@material-ui/core/styles';
import { Grid, CircularProgress } from '@material-ui/core';

// components
import Message from './Message';

// selectors
import getMessagesForThread from 'store/selectors/getMessagesForThread';
import getLoadingFromState from 'store/selectors/getLoadingFromState';

function Thread({ id }: { id?: number }) {
  const classes = useStyles();
  const {
    location: { search },
  } = useHistory();
  const threadId = id || new URLSearchParams(search).get('thread');
  const { messages, messagesLoading } = useSelector(state => ({
    messages: getMessagesForThread(threadId)(state) as any,
    messagesLoading: getLoadingFromState('message', null, false)(state),
  }));

  return (
    <Grid container direction='column' spacing={1}>
      {messagesLoading && !messages.size ? (
        <Grid item xs className={classes.loadingIndicator}>
          <CircularProgress size={30} />
        </Grid>
      ) : (
        messages.map((message: any) => (
          <Grid
            item
            xs={10}
            sm={8}
            md={6}
            key={message.get('id')}
            className={
              message.get('type') === 'outgoing'
                ? classes.alignRight
                : classes.alignLeft
            }
          >
            <Message message={message} />
          </Grid>
        ))
      )}
    </Grid>
  );
}

const useStyles = makeStyles(theme => {
  return {
    alignRight: {
      alignSelf: 'flex-end',
    },
    alignLeft: {
      alignSelf: 'flex-start',
    },
    loadingIndicator: {
      alignSelf: 'center',
    },
  };
});

export default Thread;
