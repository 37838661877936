import { all } from 'redux-saga/effects';

// sagas
import createPhoneNumberSaga from './createPhoneNumberSaga';
import getAvailableFromTwilioSaga from './getAvailableFromTwilioSaga';
import fetchPhoneNumbersForGroupSaga from './fetchPhoneNumbersForGroupSaga';
import updatePhoneNumberSaga from './updatePhoneNumberSaga';
import deletePhoneNumberSaga from './deletePhoneNumberSaga';
import toggleCallForwardingSaga from './toggleCallForwardingSaga';

const phoneNumberSaga = function* rootSaga() {
  yield all([
    createPhoneNumberSaga(),
    getAvailableFromTwilioSaga(),
    fetchPhoneNumbersForGroupSaga(),
    updatePhoneNumberSaga(),
    toggleCallForwardingSaga(),
    deletePhoneNumberSaga(),
  ]);
};

export default phoneNumberSaga;
