import React, { Dispatch, SetStateAction, useCallback } from 'react';
// material-ui
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
// icons
import WarningIcon from '@material-ui/icons/Warning';
//components
import DazzlingDialog from 'components/DazzlingDialog';
import Button from 'components/Button';
// helpers
import { ManageSubscriptionStatus } from 'components/ManageSubscription/helpers/types';

interface BillingErrorModalProps {
  open: boolean;
  onCurrentModalStatusChange: Dispatch<
    SetStateAction<ManageSubscriptionStatus>
  >;
  onClose?: () => void;
}

const BillingErrorModal = ({
  open,
  onCurrentModalStatusChange,
  onClose = () => {},
}: BillingErrorModalProps) => {
  const classes = useStyles();

  const handleClose = useCallback(() => {
    onCurrentModalStatusChange(ManageSubscriptionStatus.Closed);
    onClose();
  }, [onCurrentModalStatusChange, onClose]);

  return (
    <DazzlingDialog
      handleClose={handleClose}
      headerProps={{
        icon: WarningIcon,
        title: 'Billing Error',
        alignItems: 'center',
      }}
      open={open}
      actions={[
        <Button color='primary' variant='contained' onClick={handleClose}>
          Close
        </Button>,
      ]}
      isBackgroundWhite
      isButtonOnTheRightCorner
    >
      <Grid container spacing={1}>
        <Grid className={classes.text} item xs={12}>
          There are some issues in your group. Please, update this page and try
          to do the same action again. If you have these issues again, please
          contact the administrator.
        </Grid>
      </Grid>
    </DazzlingDialog>
  );
};

const useStyles = makeStyles(theme => ({
  text: {
    fontSize: '0.95rem',
    fontWeight: 400,
    lineHeight: '1.5em',
    color: theme.palette.background.paper,
  },
}));

export default BillingErrorModal;
