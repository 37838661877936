import React from 'react';
import PropTypes from 'prop-types';
// material-ui
import MuiSnackbar from '@material-ui/core/Snackbar';
import { Alert, AlertTitle } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';
const Snackbar = function({
  alertProps: { message, title, ...restOfAlertProps },
  snackbarProps,
}: any) {
  const classes = useStyles();
  // set error filled to app's primary color
  return (
    <MuiSnackbar {...snackbarProps}>
      <Alert
        {...restOfAlertProps}
        classes={{ filledError: classes.filledError }}
      >
        {title && <AlertTitle>{title}</AlertTitle>}
        {message}
      </Alert>
    </MuiSnackbar>
  );
};
Snackbar.propTypes = {
  snackbarProps: PropTypes.instanceOf(Object),
  alertProps: PropTypes.instanceOf(Object),
  title: PropTypes.string,
  message: PropTypes.string.isRequired,
};
Snackbar.defaultProps = {
  snackbarProps: (MuiSnackbar as any).defaultProps,
  alertProps: (Alert as any).defaultProps,
  message: '',
};
const useStyles = makeStyles(theme => {
  return {
    filledError: {
      backgroundColor: theme.palette.primary.main,
    },
  };
});
export default Snackbar;
