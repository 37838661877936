import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Map, List } from 'immutable';
import { useSelector, useDispatch } from 'react-redux';

// material-ui
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

// icons
import DeleteIcon from '@material-ui/icons/Delete';

// components
import TextField from 'components/TextField';
import IconButton from 'components/IconButton';

// action creators
import { updateMember, deleteMember } from 'store/actions/memberActions';

// selectors
import getLoadingFromState from 'store/selectors/getLoadingFromState';
import getIsHqAdmin from 'store/selectors/getIsHqAdmin';
import getIsCouncilAdmin from 'store/selectors/getIsCouncilAdmin';
import getIsSystemAdmin from 'store/selectors/getIsSystemAdmin';
import getIsCurrentGroupOrganization from 'store/selectors/getIsCurrentGroupOrganization';
import getIsCurrentGroupCouncil from 'store/selectors/getIsCurrentGroupCouncil';

const CurrentMemberItem = function({ currentGroup, member }: any) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [currentRoleId, setCurrentRoleId] = useState(member.get('roleId'));

  const updateMetaId = `update-${member.get('id')}`;
  const deleteMetaId = `delete-${member.get('id')}`;

  const {
    isCouncilAdmin,
    isCurrentGroupCouncil,
    isCurrentGroupOrganization,
    isHqAdmin,
    isSystemAdmin,
    memberDeleting,
    memberUpdating,
  } = useSelector(state => ({
    isCouncilAdmin: getIsCouncilAdmin(state),
    isCurrentGroupCouncil: getIsCurrentGroupCouncil(state),
    isCurrentGroupOrganization: getIsCurrentGroupOrganization(state),
    isHqAdmin: getIsHqAdmin(state),
    isSystemAdmin: getIsSystemAdmin(state),
    memberDeleting: getLoadingFromState('member', deleteMetaId, false)(state),
    memberUpdating: getLoadingFromState('member', updateMetaId, false)(state),
  }));

  useEffect(() => {
    // If memberUpdating (loading) goes from `true` => `false` that means the
    // request is finished and we can set the roleId back to whatever the
    // store has. If there was an error, the roleId value will not have changed.
    if (!memberUpdating) {
      setCurrentRoleId(member.get('roleId'));
    }
  }, [member, memberUpdating]);

  const roleOptions = currentGroup
    .get('roles', List())
    .map((role: any) => {
      return {
        value: role.get('id'),
        label: role.get('displayName'),
      };
    })
    .toJS();

  const handleRoleChange = (e: any) => {
    const {
      target: { value },
    } = e;

    setCurrentRoleId(value);
    dispatch(updateMember({ memberId: member.get('id'), roleId: value }));
  };

  const handleDelete = () => {
    dispatch(deleteMember({ memberId: member.get('id') }));
  };

  return (
    <Grid container alignItems='center'>
      <Grid item xs={12} sm={7}>
        <Grid container direction='column'>
          <Typography className={classes.bold}>
            {`${member.getIn(['account', 'firstName'])} ${member.getIn([
              'account',
              'lastName',
            ])}`}
          </Typography>
          <Typography className={classes.semibold} color='textSecondary'>
            {member.get('email')}
          </Typography>
        </Grid>
      </Grid>

      <Grid item xs={10} sm={3}>
        <TextField
          name='role'
          label='Role'
          disabled={
            (isCurrentGroupOrganization && (isHqAdmin || isSystemAdmin)) ||
            (isCurrentGroupCouncil && (isCouncilAdmin || isSystemAdmin))
          }
          value={currentRoleId}
          select
          options={roleOptions}
          onChange={handleRoleChange}
          loading={memberUpdating}
        />
      </Grid>

      <Grid item xs={2} className={classes.deleteButton}>
        <IconButton
          id='deleteMemberButton'
          aria-label='delete'
          onClick={handleDelete}
          loading={memberDeleting}
        >
          <DeleteIcon />
        </IconButton>
      </Grid>
    </Grid>
  );
};

CurrentMemberItem.propTypes = {
  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'typeof Map' is not assignable to... Remove this comment to see the full error message
  currentGroup: PropTypes.instanceOf(Map).isRequired,
  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'typeof Map' is not assignable to... Remove this comment to see the full error message
  member: PropTypes.instanceOf(Map).isRequired,
};

const useStyles = makeStyles({
  bold: {
    fontWeight: 800,
  },
  semibold: {
    fontWeight: 600,
  },
  deleteButton: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
});

export default CurrentMemberItem;
