import { all } from 'redux-saga/effects';

// sagas
import createEmailSaga from './createEmailSaga';
import deleteEmailSaga from './deleteEmailSaga';
import makeEmailPrimarySaga from './makeEmailPrimarySaga';
import resendVerificationEmailSaga from './resendVerificationEmailSaga';

const sessionSaga = function* rootSaga() {
  yield all([
    createEmailSaga(),
    deleteEmailSaga(),
    makeEmailPrimarySaga(),
    resendVerificationEmailSaga(),
  ]);
};

export default sessionSaga;
