import React, { useMemo } from 'react';
import { useSelector, shallowEqual } from 'react-redux';

// material-ui
import { Tooltip, Chip, Box } from '@material-ui/core';

// components
import IconButton from 'components/IconButton';

// action creators
import getLoadingFromState from 'store/selectors/getLoadingFromState';

export default function EditGuestButton({
  disabled = false,
  guest,
  type,
  icon,
  label,
  handleEditButtonClick,
  selectedGuest,
  selectedCellType,
  multipleEts = false,
}: {
  disabled?: boolean;
  guest: GuestTableItem;
  type: EventStatusTypes;
  icon?: React.ReactElement;
  label?: string;
  handleEditButtonClick: (
    event: Event,
    guest: GuestTableItem | null,
    type: EventStatusTypes,
    guestNotComingToETS?: boolean
  ) => void;
  selectedGuest: GuestTableItem | null;
  selectedCellType?: EventStatusTypes;
  multipleEts?: boolean;
}) {
  // get data from redux state
  const { guestUpdating } = useSelector(
    state => ({
      guestUpdating: selectedGuest
        ? getLoadingFromState(
            'eventGuest',
            `update-${selectedGuest.id}`,
            false
          )(state)
        : false,
    }),
    shallowEqual
  );

  const loading = useMemo(
    () =>
      selectedGuest?.id?.toString() === guest?.id?.toString() &&
      type === selectedCellType &&
      guestUpdating,
    [selectedGuest, guest, guestUpdating, selectedCellType, type]
  );
  if (label) {
    return (
      <Tooltip title={getTooltipTitle(disabled, guest, type, multipleEts)}>
        <Chip
          label={label}
          variant='outlined'
          onClick={
            !disabled
              ? (e: any) => handleEditButtonClick(e, guest, type)
              : undefined
          }
          onDelete={(e: any) => {
            handleEditButtonClick(e, guest, type, true);
          }}
        />
      </Tooltip>
    );
  }
  return (
    <Tooltip title={getTooltipTitle(disabled, guest, type, multipleEts)}>
      <Box>
        <IconButton
          disabled={disabled}
          name='eventGuestAvailability'
          loading={loading}
          onClick={(e: any) => handleEditButtonClick(e, guest, type)}
        >
          {icon}
        </IconButton>
      </Box>
    </Tooltip>
  );
}

const getTooltipTitle = (
  disabled: boolean,
  guest: GuestTableItem | null,
  type: EventStatusTypes,
  multipleEts: boolean
) => {
  if (disabled) return 'There are no time slots available';
  let title;

  if (type === 'invited') {
    title = guest?.invited ? 'Invited' : 'Not Invited';
  } else if (type === 'rsvp') {
    if (multipleEts) {
      if (guest?.rsvp === 'yes') {
        title = 'Attending';
      } else {
        title = `No RSVP Yet`;
      }
    } else {
      if (guest?.rsvp === 'yes') {
        title = 'Attending';
      } else if (guest?.rsvp === 'maybe') {
        title = 'Maybe Attending';
      } else if (guest?.rsvp === 'no') {
        title = 'Not Attending';
      } else {
        title = `No RSVP Yet`;
      }
    }
  } else {
    title = guest?.attended ? 'Attended' : 'Did Not Attend';
  }

  title = `${title} | Click to edit`;

  return title;
};
