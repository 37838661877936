import { useMemo } from 'react';
import humps from 'humps';
import { AxiosError } from 'axios';
import qs from 'query-string';
import {
  useInfiniteQuery,
  useQuery,
  useQueries,
  UseQueryOptions,
  useMutation,
  useQueryClient,
  UseInfiniteQueryOptions,
} from 'react-query';
import { useLocation, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { client, Resource } from 'api';
import getCurrentGroupId from 'store/selectors/getCurrentGroupId';
import getLeadStatuses from 'store/selectors/getLeadStatuses';
import getReportingPlatformName from 'store/selectors/getReportingPlatformName';
import {
  createLeadReferralSourceAction,
  deleteLeadReferralSourceAction,
} from 'store/actions/leadReferralSourceActions';
import getIsSystemAdmin from 'store/selectors/getIsSystemAdmin';
import { roles } from 'helpers/getRoles';
import { isEqual } from 'lodash';

export const DEFAULT_LEADS_ORDER_BY = 'status_custom-position';

function useLeadsQueryParams(queryString: string) {
  const currentGroupId = useSelector(getCurrentGroupId);
  return useMemo(() => {
    const params = qs.parse(queryString);

    const tagMilestone = {
      tagId: 'milestoneId',
      excludedTagId: 'excludedMilestoneId',
    };

    for (const [tag, milestone] of Object.entries(tagMilestone)) {
      if (params[milestone]) {
        if (!Array.isArray(params[milestone])) {
          params[milestone] = [params[milestone] as string];
        }
        if (params[tag]) {
          if (!Array.isArray(params[tag])) {
            params[tag] = [params[tag] as string];
          }
          (params[tag] as string[]).push(...(params[milestone] as string[]));
        } else {
          params[tag] = params[milestone];
        }
        delete params[milestone];
      }
    }
    delete params.group;

    if (!params.orderBy) {
      params.orderBy = DEFAULT_LEADS_ORDER_BY;
    }
    params.orderBy = humps.decamelize(params.orderBy as string);
    params.groupId = currentGroupId;
    return params;
  }, [queryString, currentGroupId]);
}

export function useLeadsInfiniteQuery(
  searchLeads: string = '',
  options: UseInfiniteQueryOptions<
    { results: Lead[]; next: string | null; total: number },
    AxiosError,
    Lead[]
  > = {}
) {
  const { search, pathname } = useLocation();
  const params = useLeadsQueryParams(search);
  if (searchLeads.length) {
    params.search = searchLeads;
  } else if (!pathname.includes('leads')) {
    params.search = null;
  }
  const queryKey = [Resource.Leads, params];
  return useInfiniteQuery<
    { results: Lead[]; next: string | null; total: number },
    AxiosError,
    Lead[]
  >(
    queryKey,
    ({ pageParam = 1 }) =>
      client
        .get(Resource.Leads, { params: { ...params, page: pageParam } })
        .then(({ data }) => data),
    {
      select: data => ({
        ...data,
        pages: data.pages.map(page => page.results),
        pageParams: data.pages.map(page => ({
          total: page.total,
        })),
        queryKey,
      }),
      getNextPageParam: lastPage => qs.parse(lastPage.next || '')?.page,
      enabled: Boolean(params.groupId) || pathname === '/leads',
      ...options,
    }
  );
}

export function useLeadsQuery({
  page = 1,
  search = '',
  orderBy = 'first_name',
  perPage = 10,
} = {}) {
  const params = {
    groupId: useSelector(getCurrentGroupId),
    orderBy,
    search,
    page,
    perPage,
  };
  return useQuery<
    {
      results: Lead[];
      next: string | null;
      previous: string | null;
      total: number;
    },
    AxiosError,
    {
      leads: Lead[];
      total: number;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
    }
  >(
    [Resource.Leads, params],
    () => client.get(Resource.Leads, { params }).then(({ data }) => data),
    {
      keepPreviousData: true,
      select: data => {
        return {
          leads: data.results,
          hasNextPage: data.next !== null,
          hasPreviousPage: data.previous !== null,
          total: data.total,
        };
      },
    }
  );
}

export function useNewMemberLeadsQuery({ page = 1 }) {
  const { isAdmin, isSystemAdmin, reportingPlatformName } = useSelector(
    state => ({
      isSystemAdmin: getIsSystemAdmin(state),
      isAdmin:
        (state as any).getIn(
          ['currentUser', 'data', 'roleId'],
          roles.member
        ) === roles.admin,
      reportingPlatformName: getReportingPlatformName(state),
    }),
    isEqual
  );

  const enabled = reportingPlatformName && (isAdmin || isSystemAdmin);
  const leadStatuses = useSelector(getLeadStatuses);
  const params = {
    groupId: useSelector(getCurrentGroupId),
    page,
    orderBy: 'first_name',
    isReported: 'false',
    perPage: 100,
    statusId: leadStatuses[0]?.id,
  };
  return useInfiniteQuery<
    { results: Lead[]; next: string | null },
    AxiosError,
    Lead[]
  >(
    [Resource.Leads, params],
    ({ pageParam = 1 }) =>
      client
        .get(Resource.Leads, { params: { ...params, page: pageParam } })
        .then(({ data }) => data),
    {
      select: data => ({
        ...data,
        pages: data.pages.map(page => page.results),
      }),
      getNextPageParam: lastPage => qs.parse(lastPage.next || '')?.page,
      enabled: enabled && Boolean(leadStatuses[0]?.id),
    }
  );
}

export function useLeadQuery(id: number) {
  const groupId = useSelector(getCurrentGroupId);
  return useQuery<Lead, AxiosError>(
    [Resource.Leads, groupId, id],
    () =>
      client
        .get(Resource.Leads + '/' + id, { params: { groupId } })
        .then(({ data }) => data),
    {
      enabled: Boolean(groupId && id),
    }
  );
}

export function useLeadsQueries(ids: number[]) {
  const groupId = useSelector(getCurrentGroupId);
  return useQueries<UseQueryOptions<Lead, AxiosError>[]>(
    ids.map(id => ({
      queryKey: [Resource.Leads, groupId, id],
      queryFn: () =>
        client
          .get(Resource.Leads + '/' + id, { params: { groupId } })
          .then(({ data }) => data),
      enabled: Boolean(groupId && id),
      retry: false,
    }))
  );
}

type CreateLeadFields = {
  firstName: string;
  lastName: string;
  emailAddress?: string;
  phoneNumber?: string;
  statusId: number;
  profilePhotoFileId?: number;
  referralSourceIds?: number | number[];
};

export function useCreateLeadMutation() {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const groupId = useSelector(getCurrentGroupId);
  return useMutation<Lead, AxiosError, CreateLeadFields>(
    ({ referralSourceIds, ...fields }) =>
      client
        .post(Resource.Leads, { groupId, ...fields })
        .then(({ data }) => data),
    {
      onSuccess: (lead, { referralSourceIds }) => {
        queryClient.refetchQueries([Resource.Leads], { active: true });
        const formattedReferralSourceIds = Array.isArray(referralSourceIds)
          ? referralSourceIds
          : [referralSourceIds];
        for (const referralSourceId of formattedReferralSourceIds)
          dispatch(
            createLeadReferralSourceAction({
              leadId: lead.id,
              referralSourceId,
            })
          );
      },
    }
  );
}

export function useDeleteLeadMutation() {
  const queryClient = useQueryClient();
  const { leadId: isOnLeadProfile } = useParams<{ leadId?: string }>();
  const groupId = useSelector(getCurrentGroupId);
  return useMutation<null, AxiosError, number>(
    id =>
      client
        .delete(Resource.Leads + '/' + id, { params: { groupId } })
        .then(({ data }) => data),
    {
      onSuccess: () => {
        if (!isOnLeadProfile) {
          queryClient.refetchQueries([Resource.Leads], { active: true });
        }
      },
    }
  );
}

export function useDeleteLeadsMutation() {
  const queryClient = useQueryClient();
  const { search } = useLocation();
  const groupId = useSelector(getCurrentGroupId);
  return useMutation<null, AxiosError, { allSelected: boolean; ids: number[] }>(
    ({ allSelected, ids }) => {
      let params = null;
      if (allSelected) {
        params = qs.parse(search);
        params.excludedId = ids.map(String);
        delete params.orderBy;
      }
      return client
        .delete(Resource.Leads, {
          params: { groupId, id: allSelected ? 'all' : ids, ...params },
        })
        .then(({ data }) => data);
    },
    {
      onSuccess: () => {
        queryClient.refetchQueries([Resource.Leads], { active: true });
      },
    }
  );
}

type UpdateLeadFields = {
  id: number;
  firstName?: string;
  lastName?: string;
  emailAddress?: string;
  phoneNumber?: string;
  statusId?: number;
  mainContactId?: number;
  profilePhotoFileId?: number;
  addedReferralSourceIds?: number[];
  removedReferralSourceIds?: number[];
};

export function useUpdateLeadMutation() {
  const queryClient = useQueryClient();
  const groupId = useSelector(getCurrentGroupId);
  const dispatch = useDispatch();
  return useMutation<Lead, AxiosError<{ msg: string }>, UpdateLeadFields>(
    ({ id, ...fields }) =>
      client
        .patch(Resource.Leads + '/' + id, fields, { params: { groupId } })
        .then(({ data }) => data),
    {
      onSuccess: (
        _,
        { id, addedReferralSourceIds = [], removedReferralSourceIds = [] }
      ) => {
        queryClient.refetchQueries([Resource.Leads], { active: true });
        for (const referralSourceId of addedReferralSourceIds)
          dispatch(
            createLeadReferralSourceAction({ leadId: id, referralSourceId })
          );
        for (const referralSourceId of removedReferralSourceIds)
          dispatch(
            deleteLeadReferralSourceAction({ leadId: id, referralSourceId })
          );
      },
    }
  );
}

type UpdateLeadsFields = {
  allSelected: boolean;
  ids: number[];
  statusId?: number;
  mainContactId?: number;
};

export function useUpdateLeadsMutation() {
  const queryClient = useQueryClient();
  const { search } = useLocation();
  const groupId = useSelector(getCurrentGroupId);
  return useMutation<Lead, AxiosError, UpdateLeadsFields>(
    ({ allSelected, ids, ...fields }) => {
      let params = null;
      if (allSelected) {
        params = qs.parse(search);
        params.excludedId = ids.map(String);
        delete params.orderBy;
      }
      return client
        .patch(Resource.Leads, fields, {
          params: { groupId, id: allSelected ? 'all' : ids, ...params },
        })
        .then(({ data }) => data);
    },
    {
      onSuccess: () => {
        queryClient.refetchQueries([Resource.Leads], { active: true });
      },
    }
  );
}
