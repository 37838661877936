import React, { useCallback, memo, useState, useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import classNames from 'classnames';
import { isEqual } from 'lodash';
import { useHistory } from 'react-router-dom';

// selectors
import getPermission from 'store/selectors/getPermission';
import getLeadStatuses from 'store/selectors/getLeadStatuses';

// material-ui
import {
  Card,
  CardContent,
  Fade,
  Grid,
  Hidden,
  useMediaQuery,
  Box,
  Tooltip,
  IconButton,
  Theme,
  Typography,
  Checkbox,
  CheckboxProps,
  Icon,
} from '@material-ui/core';
import {
  ErrorOutline,
  NewReleases,
  Star,
  StarBorder,
} from '@material-ui/icons';
import Skeleton from '@material-ui/lab/Skeleton';
import { makeStyles, useTheme, withStyles } from '@material-ui/styles';
import { yellow } from '@material-ui/core/colors';

// components
import SuperAvatar from 'components/SuperAvatar';
import LeadStatus from 'components/LeadStatus';
import CardButtonGroup from 'components/CardButtonGroup';
import EmailItem from 'components/EmailItem';
import PhoneItem from 'components/PhoneItem';
import MixedLink from 'components/MixedLink';

// types
import { Location } from 'history';

type LeadCardProps = {
  allSelected?: boolean;
  isSelected: boolean;
  isStarred?: boolean;
  withBorder?: boolean;
  lead: Lead;
  skeleton?: boolean;
  starDisabled?: boolean;
  onSelect: (lead: Lead) => void;
  onStar?: (lead: Lead) => void;
};

type LocationState = {
  fromPathname?: string;
};

const LeadCard = function({
  allSelected = false,
  isSelected,
  isStarred,
  lead,
  onSelect,
  onStar,
  skeleton = false,
  withBorder = false,
  starDisabled = false,
}: LeadCardProps) {
  const classes = useStyles();
  const history = useHistory<LocationState>();
  const isOnDuplicatesPage = history.location.pathname.startsWith(
    '/leads/duplicates'
  );
  const navigateToDuplicates = useCallback(
    () => history.push(`/leads/duplicates/${lead.pnmDuplicateId}`),
    [history, lead.pnmDuplicateId]
  );
  const theme = useTheme<Theme>();
  const isExtraSmall = useMediaQuery(theme.breakpoints.only('xs'));
  const [shouldDisplayActionButtons, setShouldDisplayActionButtons] = useState(
    false
  );
  const { statuses, canMergeDuplicates } = useSelector(
    state => ({
      statuses: getLeadStatuses(state),
      canMergeDuplicates: getPermission('pnmDuplicate.getMany')(state),
    }),
    shallowEqual
  );

  const displayActionButtons = useCallback(e => {
    setShouldDisplayActionButtons(true);
  }, []);

  const hideActionButtons = useCallback(e => {
    setShouldDisplayActionButtons(false);
  }, []);
  const handleBoxChange = useCallback(() => onSelect(lead), [lead, onSelect]);
  const handleStarChange = useCallback(() => onStar && onStar(lead), [
    lead,
    onStar,
  ]);

  const displayMayHaveJoinedWarning =
    lead.mayHaveJoined &&
    statuses.find(status => status.id === lead.statusId)?.position !== 1;

  const navigateToProfileParams = useMemo<Location<LocationState>>(
    () => ({
      pathname: `/leads/${lead.id}`,
      state: {
        ...history.location.state,
        fromPathname: history.location.pathname,
      },
      search: '',
      hash: '',
    }),
    [history, lead]
  );

  const avatarSize = isExtraSmall ? 'xlarge' : '3xlarge';

  return (
    <Box clone border={withBorder ? 1 : 0} borderColor='background.outline'>
      <Card
        className={classNames(
          classes.card,
          !skeleton && isSelected && classes.selectedCard,
          !skeleton && isStarred && classes.starredCard
        )}
      >
        {skeleton ? (
          <div>
            <Grid
              container
              wrap='nowrap'
              spacing={3}
              className={classes.cardContent}
            >
              <Grid item>
                <Skeleton
                  className={classes.avatarSkeleton}
                  variant='rect'
                  height={isExtraSmall ? 75 : 170}
                  width={isExtraSmall ? 75 : 170}
                />
              </Grid>

              <Grid item xs={12}>
                <Skeleton height={38} width='65%' />
                <Hidden xsDown>
                  <Skeleton
                    height={128}
                    width='85%'
                    variant='rect'
                    className={classes.cardInfoGroupSkeleton}
                  />
                </Hidden>
              </Grid>
            </Grid>

            <Hidden smUp>
              <Skeleton
                height={128}
                width='100%'
                variant='rect'
                className={classes.mobileCardInfoGroupSkeleton}
              />
            </Hidden>
          </div>
        ) : (
          <Fade in={!skeleton}>
            <div
              onMouseEnter={
                isOnDuplicatesPage ? undefined : displayActionButtons
              }
              onMouseLeave={hideActionButtons}
            >
              <Grid
                container
                wrap='nowrap'
                spacing={3}
                className={classes.cardContent}
              >
                <Grid item>
                  <MixedLink
                    to={{
                      pathname: `/leads/${lead.id}`,
                      state: {
                        ...history.location.state,
                        fromPathname: history.location.pathname,
                      },
                    }}
                  >
                    <SuperAvatar
                      fullName={lead.fullName}
                      variant='roundedSquare'
                      raised={Boolean(lead.profilePhotoFileUrl)}
                      imageUrl={lead.profilePhotoFileUrl}
                      size={avatarSize}
                      background='dark'
                    />
                  </MixedLink>
                </Grid>

                <Grid item xs={10} zeroMinWidth>
                  <Grid container spacing={1}>
                    {canMergeDuplicates &&
                      lead.pnmDuplicateId !== null &&
                      !isOnDuplicatesPage && (
                        <Grid item>
                          <Tooltip title='Lead may be a duplicate'>
                            <IconButton
                              size='small'
                              onClick={navigateToDuplicates}
                            >
                              <NewReleases color='primary' />
                            </IconButton>
                          </Tooltip>
                        </Grid>
                      )}
                    {displayMayHaveJoinedWarning && (
                      <Grid item>
                        <Tooltip title='It looks like this lead may have joined another chapter. Might not be a good fit for your organization'>
                          <Icon fontSize='medium'>
                            <ErrorOutline color='secondary' />
                          </Icon>
                        </Tooltip>
                      </Grid>
                    )}
                    <Grid item xs>
                      <Grid
                        container
                        className={classes.leadHeaderContainer}
                        spacing={1}
                        alignItems='flex-start'
                        wrap='nowrap'
                      >
                        <Grid item xs={12} zeroMinWidth>
                          <Typography className={classes.leadListName} noWrap>
                            {lead.fullName}
                          </Typography>
                        </Grid>
                        {onStar && (
                          <Grid item xs className={classes.checkboxContainer}>
                            <CheckStar
                              disabled={
                                !(allSelected || isSelected) || starDisabled
                              }
                              checked={isStarred}
                              onChange={handleStarChange}
                            />
                          </Grid>
                        )}
                        <Grid
                          item
                          xs
                          className={classes.checkboxContainer}
                          id='checkboxContainer'
                        >
                          <Tooltip
                            id='cardCheckboxTooltip'
                            title={
                              allSelected
                                ? 'All Selected'
                                : isSelected
                                ? 'Deselect lead'
                                : 'Select lead'
                            }
                          >
                            <Checkbox
                              id='checkbox'
                              checked={isSelected}
                              color='primary'
                              classes={{
                                colorPrimary: classes.checkboxColor,
                              }}
                              onClick={e => e.stopPropagation()}
                              onChange={handleBoxChange}
                            />
                          </Tooltip>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Hidden xsDown>
                    <CardInfoGroup
                      {...{
                        classes,
                        isOnDuplicatesPage,
                        isExtraSmall,
                        shouldDisplayActionButtons,
                        lead,
                      }}
                      key={lead.id}
                    />
                  </Hidden>
                </Grid>
              </Grid>
            </div>
          </Fade>
        )}

        {!skeleton && (
          <Hidden smUp>
            <CardContent className={classes.mobileCardInfoGroup}>
              <CardInfoGroup
                {...{
                  classes,
                  isOnDuplicatesPage,
                  isExtraSmall,
                  shouldDisplayActionButtons,
                  lead,
                }}
                key={lead.id}
              />
            </CardContent>
          </Hidden>
        )}

        <Hidden xsDown>
          <CardButtonGroup
            lead={lead}
            loading={skeleton}
            navigateToProfileParams={navigateToProfileParams}
          />
        </Hidden>
      </Card>
    </Box>
  );
};

const CheckStar = withStyles({
  root: {
    color: yellow[400],
    '&$checked': {
      color: yellow[600],
    },
  },
  checked: {},
})((props: CheckboxProps) => (
  <Checkbox
    color='default'
    icon={<StarBorder />}
    checkedIcon={<Star />}
    {...props}
  />
));

const useStyles = makeStyles((theme: any) => {
  return {
    card: {
      transition: 'all 0.3s ease',
    },
    selectedCard: {
      transition: 'all .25s ease',
      backgroundColor: theme.palette.primary.light,
      '&:hover': {
        backgroundColor: theme.palette.primary.light,
      },
    },
    starredCard: {
      transition: 'all .25s ease',
      backgroundColor: theme.palette.secondary.main,
      '&:hover': {
        backgroundColor: theme.palette.secondary.main,
      },
    },
    outlinedContainer: {
      border: `1px solid ${theme.palette.background.outline}`,
      borderRadius: 4,
      [theme.breakpoints.only('xs')]: {
        marginRight: 0,
      },
      [theme.breakpoints.only('sm')]: {
        marginRight: '30%',
      },
      [theme.breakpoints.only('md')]: {
        marginRight: 0,
      },
      [theme.breakpoints.only('lg')]: {
        marginRight: '10%',
      },
      [theme.breakpoints.only('xl')]: {
        marginRight: '3%',
      },
    },
    leadHeaderContainer: {
      height: 50,
    },

    leadListName: {
      fontSize: 20,
      [theme.breakpoints.only('xs')]: {
        fontSize: 16,
      },
      fontWeight: 500,
    },

    checkboxContainer: { marginTop: -7, textAlign: 'right' },

    checkboxColor: {
      '&:hover': {
        backgroundColor: theme.palette.primary.light,
        transition: 'all 0.2s ease',
      },
    },
    cardContent: {
      paddingTop: 16,
      paddingLeft: 16,
      paddingRight: 16,
      paddingBottom: 8,
    },
    mobileCardInfoGroup: {
      paddingTop: 8,
    },
    mobileCardInfoGroupSkeleton: {
      marginBottom: 10,
      marginTop: 10,
      borderRadius: 4,
    },
    cardInfoGroupSkeleton: { marginBottom: 10, borderRadius: 4 },
    avatarSkeleton: {
      borderRadius: 8,
    },
    accordionSkeleton: {
      height: 48,
    },
  };
});

const CardInfoGroup = ({
  classes,
  isOnDuplicatesPage,
  isExtraSmall,
  shouldDisplayActionButtons,
  lead,
}: any) => (
  <Tooltip
    title={
      isOnDuplicatesPage &&
      'These fields are immutable when managing duplicates'
    }
  >
    <Box>
      <div
        className={classes.outlinedContainer}
        style={{ pointerEvents: isOnDuplicatesPage ? 'none' : 'auto' }}
      >
        <LeadStatus
          lead={lead}
          variant='label'
          size={isExtraSmall ? 'small' : 'large'}
          onLoadingChange={() => {}}
          shouldDisplayLabelIcon={shouldDisplayActionButtons}
        />

        <EmailItem lead={lead} displayActions={shouldDisplayActionButtons} />
        <PhoneItem lead={lead} displayActions={shouldDisplayActionButtons} />
      </div>
    </Box>
  </Tooltip>
);

export default memo(LeadCard, isEqual);
