import { Map } from 'immutable';
// constants
import { LEAD_TAG_FETCH, LEAD_TAG_CLEAR } from 'store/constants/leadTagTypes';
// helpers
import httpReducer from './helpers/httpReducer';
import remove from './callbacks/remove';

export default function reduceLeadTag(state = Map(), action = {}) {
  switch ((action as any).type) {
    case LEAD_TAG_FETCH:
      return httpReducer(state, action, { appendResult: true });
    case LEAD_TAG_CLEAR:
      return remove(state, action);
    default:
      return state;
  }
}
