import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
// material-ui
import MuiAccordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';
// icons
import { ExpandMore, CheckCircle } from '@material-ui/icons';
Accordion.defaultProps = {
  label: '',
  labelPosition: 'left',
  defaultExpanded: false,
};
type OwnProps = {
  defaultExpanded?: boolean;
  details: any; // TODO: PropTypes.instanceOf(Object)
  highlighted?: boolean;
  label?: string;
  labelPosition?: 'left' | 'right';
};
type Props = OwnProps & typeof Accordion.defaultProps;
export default function Accordion({
  defaultExpanded,
  details,
  highlighted,
  label,
  labelPosition,
}: Props) {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);
  useEffect(() => {
    setExpanded(defaultExpanded);
  }, [defaultExpanded]);
  return (
    <MuiAccordion
      elevation={0}
      onChange={() => setExpanded(!expanded)}
      className={classNames(
        classes.accordion,
        !expanded && classes.closedAccordion
      )}
      onClick={e => e.stopPropagation()}
      expanded={expanded}
      TransitionProps={{ mountOnEnter: true, unmountOnExit: true }}
    >
      <AccordionSummary
        expandIcon={<ExpandMore />}
        className={classNames(
          classes.accordionSummary,
          !expanded && classes.hover
        )}
      >
        <Grid
          container
          justifyContent={labelPosition === 'left' ? 'flex-start' : 'flex-end'}
          spacing={2}
        >
          <Grid item>
            <Typography
              variant='subtitle2'
              color={highlighted ? 'primary' : 'textSecondary'}
            >
              {label}
            </Typography>
          </Grid>

          {highlighted && (
            <Grid item xs={1}>
              <CheckCircle color='primary' />
            </Grid>
          )}
        </Grid>
      </AccordionSummary>

      <AccordionDetails className={classes.accordionDetails}>
        {details}
      </AccordionDetails>
    </MuiAccordion>
  );
}
const useStyles = makeStyles(theme => ({
  accordion: {
    '&:before': {
      background: 'none',
    },
    background: 'none',
  },
  closedAccordion: {
    marginTop: 7,
  },
  accordionSummary: {
    transition: 'all .25s ease',
  },
  hover: {
    '&:hover': {
      transition: 'all .25s ease',
      background: (theme as any).palette.background.hoverPaper,
    },
  },
  accordionDetails: {
    justifyContent: 'flex-start',
    minHeight: 'auto',
    padding: 16,
    paddingTop: 0,
  },
}));
