import React, { useEffect, useState, useRef } from 'react';

// hooks
import { useSelector } from 'react-redux';

// material-ui
import { withStyles, WithStyles } from '@material-ui/core/styles';
import { Card, Grid, Hidden, Tooltip, Typography } from '@material-ui/core';

// icons
import CopyIcon from '@material-ui/icons/FileCopy';
import CodeIcon from '@material-ui/icons/Code';

// selectors
import getLoadingFromState from 'store/selectors/getLoadingFromState';
import getStatusFromState from 'store/selectors/getStatusFromState';

// components
import Switch from 'components/Switch';
import Button from 'components/Button';
import { Field } from 'react-final-form';

//styles
import formEditorOptionsStyles from 'routes/ReferralForm/FormEditorOptions/formEditorOptions.style';

export interface FormEditorOptionsProps
  extends WithStyles<typeof formEditorOptionsStyles> {
  isPublished: boolean | null;
  hasActivePaymentMethod: boolean;
  emailContent?: string;
  formId?: number;
  eventId?: number;
  token?: string;
  isCouncilForm?: boolean | null;
  isOrganizationForm?: boolean | null;
  isUniversalForm?: boolean;
  valuesCollectPayment?: boolean | null;
  chapterSelectionEnabled?: boolean;
  genderSelectionEnabled?: boolean;
}

const FormEditorOptions = function({
  classes,
  isPublished,
  emailContent,
  formId,
  eventId,
  token,
  isCouncilForm,
  isOrganizationForm,
  isUniversalForm,
  hasActivePaymentMethod,
  valuesCollectPayment,
  chapterSelectionEnabled,
  genderSelectionEnabled,
}: FormEditorOptionsProps) {
  const emailContentWithoutHtml = emailContent
    ? (emailContent || '')
        .replace('<p>', '')
        .replace('</p>', '')
        .replace('<br>', '')
    : false;
  const hasEmailContent = Boolean(emailContentWithoutHtml);
  const [linkCopied, setLinkCopied] = useState(false);
  const [embedCopied, setEmbedCopied] = useState(false);
  const formLinkRef = useRef(null);
  const formEmbedRef = useRef(null);
  const url = window.location.origin;
  const formLink = `${url}/forms/${formId}/view/?token=${token}`;
  const formEmbed = `<iframe src='${formLink}' height='600' width='95%' style='border: 2px solid #dfdfdf; border-radius: 4px; display: block; margin: auto;'></iframe>`;
  const linkSharingLabel = isPublished ? 'Link Sharing On' : 'Link Sharing Off';
  const { tokenLoading, tokenStatus } = useSelector(state => ({
    tokenLoading: getLoadingFromState('referralForm', 'token', false)(state),
    tokenStatus: getStatusFromState('referralForm', 'token')(state),
  }));

  useEffect(() => {
    setTimeout(() => setLinkCopied(false), 1500);
  }, [linkCopied]);

  useEffect(() => {
    setTimeout(() => setEmbedCopied(false), 1500);
  }, [embedCopied]);

  const handleCopyLink = () => {
    navigator.clipboard.writeText(formLink);
    setLinkCopied(true);
  };

  const handleCopyEmbed = () => {
    navigator.clipboard.writeText(formEmbed);
    setEmbedCopied(true);
  };

  return (
    <Hidden smDown>
      <Card className={classes.optionsCard}>
        <Grid container>
          <Grid item xs={12} className={classes.optionsCardContent}>
            <Typography color='textSecondary' variant='subtitle2'>
              Form Sharing
            </Typography>
          </Grid>

          <Grid item xs={12} className={classes.optionsCardContent}>
            <Field
              id='linkSharingField'
              type='checkbox'
              name='isPublished'
              label={linkSharingLabel}
              disabled={eventId}
              component={Switch}
            />
            <input
              className={classes.formLinkInput}
              type='text'
              ref={formLinkRef}
              value={formLink}
            />
            <input
              className={classes.formLinkInput}
              type='text'
              ref={formEmbedRef}
              value={formEmbed}
            />
          </Grid>

          {isPublished && token !== undefined && (
            <>
              <Grid
                item
                xs={12}
                className={classes.optionsCardContent}
                id='copyFormLink'
              >
                <Tooltip arrow open={linkCopied} title='Copied to clipboard'>
                  <Button
                    variant='outlined'
                    startIcon={<CopyIcon />}
                    loading={tokenLoading}
                    onClick={handleCopyLink}
                    disabled={tokenLoading || tokenStatus === 'error'}
                  >
                    Copy Form Link
                  </Button>
                </Tooltip>
              </Grid>

              <Grid
                item
                xs={12}
                className={classes.optionsCardContent}
                id='copyEmbedCode'
              >
                <Tooltip arrow open={embedCopied} title='Copied to clipboard'>
                  <Button
                    variant='outlined'
                    startIcon={<CodeIcon />}
                    loading={tokenLoading}
                    onClick={handleCopyEmbed}
                    disabled={tokenLoading || tokenStatus === 'error'}
                  >
                    Copy Embed Code
                  </Button>
                </Tooltip>
              </Grid>
            </>
          )}

          <Grid item xs={12} className={classes.sectionDivider} />

          <Grid item xs={12} className={classes.optionsCardContent}>
            <Typography color='textSecondary' variant='subtitle2'>
              Form Options
            </Typography>
          </Grid>

          <Grid item xs={12} className={classes.optionsCardContent}>
            <Field
              type='checkbox'
              name='enableImageUpload'
              label='Enable Image Uploads'
              component={Switch}
            />
          </Grid>

          <Grid item xs={12} className={classes.optionsCardContent}>
            <Field
              type='checkbox'
              name='requestReferrersInfo'
              label='Request Referrers Info'
              component={Switch}
            />
          </Grid>

          <Grid item xs={12} className={classes.optionsCardContent}>
            <Field
              type='checkbox'
              name='enableConfirmationEmail'
              label={
                hasEmailContent
                  ? 'Enable Confirmation Emails'
                  : 'Craft Your Confirmation Email To Enable'
              }
              disabled={!hasEmailContent}
              component={Switch}
            />
          </Grid>
          <Grid item xs={12} className={classes.optionsCardContent}>
            <Field
              type='checkbox'
              name='emailRequired'
              label='Require Email'
              component={Switch}
            />
          </Grid>
          <Grid item xs={12} className={classes.optionsCardContent}>
            <Field
              type='checkbox'
              name='phoneNumberRequired'
              label='Require Phone Number'
              component={Switch}
            />
          </Grid>

          {isCouncilForm && (
            <Grid item xs={12} className={classes.optionsCardContent}>
              <Field
                type='checkbox'
                name='chapterSelectionEnabled'
                label='Chapter Selection'
                component={Switch}
                disabled={genderSelectionEnabled}
              />
            </Grid>
          )}

          {isCouncilForm && (
            <Grid item xs={12} className={classes.optionsCardContent}>
              <Field
                type='checkbox'
                name='genderSelectionEnabled'
                label='Organization Gender Selection'
                component={Switch}
                disabled={chapterSelectionEnabled}
              />
            </Grid>
          )}

          {isCouncilForm && (
            <Grid item xs={12} className={classes.optionsCardContent}>
              <Field
                type='checkbox'
                name='collectPayment'
                label='Collect Payment'
                component={Switch}
                disabled={!hasActivePaymentMethod && !valuesCollectPayment}
              />
              {!hasActivePaymentMethod && valuesCollectPayment && (
                <div className={classes.warningPaymentText}>
                  There is some issue with the Payment Method or Form Fee. You
                  should disable the "Collect Payment" option for this form.
                </div>
              )}
            </Grid>
          )}
          {(isOrganizationForm || isCouncilForm || isUniversalForm) && (
            <>
              <Grid item xs={12} className={classes.optionsCardContent}>
                <Field
                  type='checkbox'
                  name='enableCustomAgreement1'
                  label='Custom Agreement'
                  component={Switch}
                />
              </Grid>
              <Grid item xs={12} className={classes.optionsCardContent}>
                <Field
                  type='checkbox'
                  name='enableCustomAgreement2'
                  label='Custom Agreement 2'
                  component={Switch}
                />
              </Grid>
            </>
          )}
        </Grid>
      </Card>
    </Hidden>
  );
};

export default withStyles(formEditorOptionsStyles, { withTheme: true })(
  FormEditorOptions
);
