import React from 'react';

// MUI
import { useTheme, makeStyles } from '@material-ui/core/styles';
import { Grid, Box, useMediaQuery, Typography } from '@material-ui/core';

// components
import GuestsTable from './GuestsTable';

export default function EventGuestsCreatedDetails({
  guests = [],
}: {
  guests?: EventGuest[];
}) {
  const theme = useTheme();
  const classes = useStyles();
  const isExtraSmall = useMediaQuery(theme.breakpoints.only('xs'));

  const accounts = guests?.filter(guest => guest?.accountId);
  const leads = guests?.filter(guest => guest?.pnmId);

  return (
    <Box marginLeft={!isExtraSmall && 8} width='fill-available'>
      <Grid container spacing={2} className={classes.container}>
        {!accounts?.length && !leads?.length && (
          <Typography>No guests found.</Typography>
        )}

        {accounts?.length > 0 && (
          <Grid item xs={12}>
            <GuestsTable guests={accounts} guestType={'member'} />
          </Grid>
        )}
        {leads?.length > 0 && (
          <Grid item xs={12}>
            <GuestsTable guests={leads} guestType={'lead'} />
          </Grid>
        )}
      </Grid>
    </Box>
  );
}

const useStyles = makeStyles(theme => {
  return {
    container: {
      paddingBottom: 12,
    },
  };
});
