import {
  all,
  call,
  fork,
  put,
  takeEvery,
  select,
  delay,
  take,
} from 'redux-saga/effects';

import { Map, List } from 'immutable';

// action creators
import {
  doneIndicator,
  success,
  errorIndicator,
  error,
} from 'store/actions/httpActions';
import { fetchTask } from 'store/actions/apiTaskActions';

// selectors
import getTaskById from 'store/selectors/getTaskById';

// api
import client from 'sources/api';

// constants
import { LEAD_IMPORT_REQUEST, LEAD_IMPORT } from 'store/constants/leadTypes';

// helpers
import httpSaga from 'store/sagas/httpSaga';

const WATCH_TYPE = LEAD_IMPORT_REQUEST;
const TYPE = LEAD_IMPORT;

export function* importLeadsSaga(action: any): any {
  const {
    payload: { fileId, groupId, shouldSkipDuplicates },
  } = action;

  // 1) Post file ID to tasks endpoint to be processed and initiate the worker/task to bulk lead members
  // @ts-expect-error ts-migrate(2569) FIXME: Type 'Generator<any, any, unknown>' is not an arra... Remove this comment to see the full error message
  const taskPostResponse = yield* httpSaga(
    TYPE,
    call(client.post, `/tasks/?group_id=${groupId}`, {
      name: 'bulk_import_pnms',
      params: {
        group_id: groupId,
        file_id: fileId,
        should_skip_duplicates: shouldSkipDuplicates,
      },
    }),
    {
      dispatchSuccess: false,
    }
  );

  const { taskId } = taskPostResponse.data;

  // 2) Use the worker/task ID to hit an endpoint periodically to check on the status
  let taskStatus = 'PROCESSING';
  let task = Map();

  while (taskStatus === 'PROCESSING' || !taskStatus) {
    yield delay(2000);

    yield put(fetchTask({ taskId, groupId }));
    yield take('API_TASK_FETCH_DONE');
    task = yield select(state => getTaskById(taskId)(state));
    // @ts-expect-error ts-migrate(2322) FIXME: Type 'unknown' is not assignable to type 'string'.
    taskStatus = task.get('status');
  }

  if (taskStatus === 'SUCCESS') {
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 3 arguments, but got 1.
    yield put(success(LEAD_IMPORT));
  } else if (taskStatus === 'FAILURE') {
    // Notify the store that this type had an error
    let errors = task.getIn(['result', 'errors'], List());

    const message = Map({
      data: Map({ errors }),
    });
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 2.
    yield put(errorIndicator(TYPE, message));

    // Dispatch an error payload for this type to be caught by the reducer
    yield put(error(TYPE, message));
  }

  yield put(doneIndicator(TYPE));
}

export function* watch() {
  yield takeEvery(WATCH_TYPE, importLeadsSaga);
}

export default function* root() {
  yield all([fork(watch)]);
}
