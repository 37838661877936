import getGroupType from 'helpers/getGroupType';
/*
 * @param {Immutable.Map} group
 * @param {string} type
 */
export default function getGroupParent(group = {}, type = 'STANDARD') {
  const parents = (group as any).parents || [];
  const groupType = getGroupType(type);
  const groupParent =
    parents.find((parent = {}) => (parent as any).type === groupType) || {};
  return groupParent;
}
