import React, { useState, useEffect, useCallback, useMemo } from 'react';
import validate from 'helpers/validate';
import { isEqual } from 'lodash';
// hooks
import { useSelector, useDispatch } from 'react-redux';
import { useCreateLeadMutation } from 'api/leads';

// components
import Button from 'components/Button';
import DazzlingDialog from 'components/DazzlingDialog';
import FileInputField from 'components/FileInputField';
import SuperAvatar from 'components/SuperAvatar';
import TextField from 'components/TextField';
import ReferralSourceSelector from 'components/ReferralSourceSelector';
import StatusSelector from 'components/StatusSelector';
import { Field, Form } from 'react-final-form';

// material-ui
import Grid from '@material-ui/core/Grid';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles, useTheme } from '@material-ui/core/styles';

// icons
import PersonAddIcon from '@material-ui/icons/PersonAdd';

// action creators
import { createAndUploadFile as createAndUploadFileAction } from 'store/actions/fileActions';
import { fetchStatusesAction } from 'store/actions/statusActions';

// selectors
import getDefaultStatusId from 'store/selectors/getDefaultStatusId';
import getLoadingFromState from 'store/selectors/getLoadingFromState';
import getCurrentGroupId from 'store/selectors/getCurrentGroupId';

// schemas
const schema = {
  emailAddress: {
    email: { message: 'not valid' },
    length: { maximum: 255 },
  },
  firstName: {
    presence: true,
    length: { maximum: 255 },
  },
  lastName: {
    presence: true,
    length: { maximum: 255 },
  },
  phoneNumber: {
    validPhoneNumber: true,
  },
};

const CREATE_FORM = 'createLeadForm';
const IMAGE_FORM = 'leadImageForm';
type CreateLeadModalProps = {
  open: boolean;
  onClose: (...args: any[]) => any;
};
export default function CreateLeadModal({
  open,
  onClose,
}: CreateLeadModalProps) {
  const classes = useStyles();
  const theme = useTheme();
  const isExtraSmall = useMediaQuery(theme.breakpoints.only('xs'));
  const {
    mutate: createLead,
    error,
    isError,
    isSuccess,
    isLoading,
    reset: resetCreateLead,
  } = useCreateLeadMutation();

  const {
    actions: { createAndUploadFile, fetchStatuses },
    data: { defaultStatusId, fileLoading, currentGroupId },
  } = useRedux();

  const onSubmit = useCallback(
    fields => {
      createLead(fields);
    },
    [createLead]
  );

  // initalize component state
  const [imageId, setImageId] = useState<number | null>(null);
  const [submitSource, setSubmitSource] = useState(null);
  const [isFileConverting, setIsFileConverting] = useState(false);

  useEffect(() => {
    if (isSuccess) {
      setImageId(null);
      resetCreateLead();
      if (submitSource === 'primary') {
        // Delays close so that check indicator on button can appear
        setTimeout(onClose, 750);
      }
    }
  }, [onClose, isSuccess, submitSource, resetCreateLead]);

  useEffect(() => {
    if (open) {
      fetchStatuses(currentGroupId);
    }
  }, [currentGroupId, open, fetchStatuses]);

  const handleFileChange = useCallback(({ filePreview, newFileId }) => {
    setImageId(newFileId);
  }, []);

  const setSourceToPrimary = useCallback(() => {
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '"primary"' is not assignable to ... Remove this comment to see the full error message
    setSubmitSource('primary');
  }, []);

  const handleFileConverting = (newIsFileConverting: boolean) => {
    setIsFileConverting(newIsFileConverting);
  };

  // These are the custom actions that are being passed to the dialog
  const getFormActions = ({ invalid, submit, restart }: any) => [
    <Button
      id='rejectButton'
      onClick={() => {
        restart();
        resetCreateLead();
        onClose();
        setImageId(null);
      }}
    >
      Cancel
    </Button>,
    <Button
      id='submitAndAddAnotherButton'
      color='primary'
      variant='outlined'
      disabled={invalid || isLoading || fileLoading}
      loading={isLoading && submitSource === 'secondary'}
      success={isSuccess && submitSource === 'secondary'}
      fail={isError && submitSource === 'secondary'}
      onClick={() => {
        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '"secondary"' is not assignable t... Remove this comment to see the full error message
        setSubmitSource('secondary');
        submit();
      }}
    >
      Submit and Add Another
    </Button>,
    <Button
      id='submitButton'
      type='submit'
      color='primary'
      variant='contained'
      disabled={invalid || isLoading || fileLoading}
      loading={isLoading && submitSource === 'primary'}
      success={isSuccess && submitSource === 'primary'}
      fail={isError && submitSource === 'primary'}
      onClick={setSourceToPrimary}
    >
      Submit
    </Button>,
  ];
  const renderFileInput = () => {
    return (
      <Field
        name='profilePhotoFileId'
        render={props => (
          <FileInputField
            // @ts-expect-error ts-migrate(2322) FIXME: Type '{ input: FieldInputProps<any, HTMLElement>; ... Remove this comment to see the full error message
            fileType='image'
            formName={IMAGE_FORM}
            onChange={handleFileChange}
            onSubmit={createAndUploadFile}
            onFileConverting={handleFileConverting}
            buttonLabel='Upload Lead Picture'
            {...props}
          />
        )}
      />
    );
  };
  const handleValidate = useCallback(
    fields => validate(fields, schema) || error?.response?.data,
    [error]
  );
  return (
    <Form
      id='createLeadForm'
      initialValues={{ statusId: defaultStatusId, referralSourceIds: [] }}
      initialValuesEqual={isEqual}
      onSubmit={onSubmit}
      validate={handleValidate}
      render={({ handleSubmit, invalid, form: { submit, restart } }) => (
        <DazzlingDialog
          id='createLeadModal'
          disabled={invalid}
          alertProps={{ message: 'Lead Created Successfully' }}
          formName={CREATE_FORM}
          handleClose={() => {
            resetCreateLead();
            restart();
            onClose();
          }}
          open={open}
          onAccept={handleSubmit}
          headerProps={{
            icon: PersonAddIcon,
            title: 'Add A New Lead',
            highlightedSubtitle: 'Fill in this form to create a lead',
          }}
          actions={getFormActions({ invalid, submit, restart })}
          resetForm={restart}
          isError={isError}
          isLoading={isLoading}
          isSuccess={isSuccess}
        >
          <Grid container spacing={2} id='dialogContent'>
            <Grid item xs={12} className={classes.imageContainer}>
              <Grid
                container
                justifyContent='center'
                alignItems='center'
                spacing={2}
              >
                <Grid item xs={3}>
                  <SuperAvatar
                    size={isExtraSmall ? 'xlarge' : '2xlarge'}
                    background='dark'
                    loading={fileLoading || isFileConverting}
                    imageId={imageId}
                  />
                </Grid>

                <Grid item xs={9}>
                  {renderFileInput()}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Field
                id='firstName'
                name='firstName'
                component={TextField}
                label='First Name'
                aria-label='First Name'
                margin='none'
                autoFocus
                required
              />
            </Grid>
            <Grid item xs={6}>
              <Field
                id='lastName'
                name='lastName'
                component={TextField}
                label='Last Name'
                aria-label='Last Name'
                margin='none'
                required
              />
            </Grid>
            <Grid item xs={12} sm={8}>
              <Field
                id='emailAddress'
                name='emailAddress'
                component={TextField}
                label='Email'
                aria-label='Email'
                type='email'
                margin='none'
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Field
                id='phoneNumber'
                name='phoneNumber'
                render={props => (
                  <TextField
                    label='Phone Number'
                    aria-label='Phone Number'
                    type='tel'
                    margin='none'
                    {...props}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                name='referralSourceIds'
                component={ReferralSourceSelector}
                label='Referral Sources'
                margin='none'
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                id='statusId'
                name='statusId'
                component={StatusSelector}
                label='Status'
                aria-label='Status'
                margin='none'
              />
            </Grid>
          </Grid>
        </DazzlingDialog>
      )}
    />
  );
}

const useRedux = () => {
  const dispatch = useDispatch();
  const actions = useMemo(
    () => ({
      createAndUploadFile: (file: any) => {
        dispatch(createAndUploadFileAction({ file, formName: IMAGE_FORM }));
      },
      fetchStatuses: (groupId: number) => {
        dispatch(fetchStatusesAction({ groupId }));
      },
    }),
    [dispatch]
  );
  const data = useSelector(
    state => ({
      defaultStatusId: getDefaultStatusId(state),
      fileLoading: getLoadingFromState('file', false, false)(state),
      currentGroupId: getCurrentGroupId(state),
    }),
    isEqual
  );
  return { actions, data };
};
const useStyles = makeStyles(theme => ({
  imageContainer: {
    marginBottom: 20,
  },
}));
