import { all, call, fork, put, takeEvery, select } from 'redux-saga/effects';

// action creators
import { doneIndicator, error } from 'store/actions/httpActions';
import { fetchCurrentGroup } from 'store/actions/groupActions';

// api
import client from 'sources/api';

// constants
import {
  UPDATE_LEAD_SHARING_REQUEST,
  UPDATE_LEAD_SHARING,
} from 'store/constants/communitySettingsTypes';

// lib
import httpSaga from 'store/sagas/httpSaga';

//selectors
import getForm from 'store/selectors/getForm';

const WATCH_TYPE = UPDATE_LEAD_SHARING_REQUEST;
const TYPE = UPDATE_LEAD_SHARING;

export function* updateLeadSharingSaga(action: any) {
  const { pushLead, formName, groupId } = action.payload || {};

  if (!groupId) {
    yield put(error(TYPE, { msg: 'Cannot find current group id' }));
  } else {
    // @ts-expect-error ts-migrate(2569) FIXME: Type 'Generator<any, any, unknown>' is not an arra... Remove this comment to see the full error message
    yield* httpSaga(
      TYPE,
      call(client.patch, `/groups/${groupId}`, {
        push_lead: pushLead,
      }),
      {
        formName,
      }
    );

    const {
      form: { hasSubmitFailed },
    } = yield select(state => ({
      form: getForm(formName)(state),
    })) || { form: { hasSubmitFailed: true } };

    if (!hasSubmitFailed) {
      yield put(fetchCurrentGroup({ id: groupId }));
    }
    yield put(doneIndicator(TYPE));
  }
}

export function* watch() {
  yield takeEvery(WATCH_TYPE, updateLeadSharingSaga);
}

export default function* root() {
  yield all([fork(watch)]);
}
