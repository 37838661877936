import React, { useRef, useEffect } from 'react';
import { isEqual } from 'lodash';

// hooks
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

// material-ui
import { Grid, useMediaQuery } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { ArrowDownward } from '@material-ui/icons';
import Skeleton from '@material-ui/lab/Skeleton';
import useScrollBottom from 'helpers/hooks/useScrollBottom';

// components
import TaskItem from './TaskItem';
import EmptyMessage from 'components/EmptyMessage';
import Button from 'components/Button';

// selectors
import getTasks from 'store/selectors/getTasks';
import getLoadingFromState from 'store/selectors/getLoadingFromState';

const HEADER_MENU_HEIGHT = 100;

const TaskList = function({
  onFetchNextPage,
  hasNextPage,
  isAssignedToYou,
}: any) {
  const classes = useStyles();
  const containerRef = useRef();
  const { pathname } = useLocation();
  const [isBottom, scrollRef] = useScrollBottom(65);

  const theme = useTheme();
  const isSmallAndUp = useMediaQuery(theme.breakpoints.up('sm'));

  // @ts-expect-error ts-migrate(2339) FIXME: Property 'offsetTop' does not exist on type '{}'.
  const { current: { offsetTop } = {} } = containerRef;
  const heightToSubtract = offsetTop + HEADER_MENU_HEIGHT;

  const { tasksLoading, tasks } = useSelector(
    state => ({
      tasks: getTasks(state) as any,
      tasksLoading: getLoadingFromState('task', 'fetch', false)(state),
    }),
    isEqual
  );

  useEffect(() => {
    if (isBottom && hasNextPage) {
      onFetchNextPage();
    }
  }, [hasNextPage, isBottom, onFetchNextPage]);

  const listStyle =
    tasks.size > 3 && isSmallAndUp && pathname.includes('home')
      ? { height: `calc(100vh - ${heightToSubtract}px)` }
      : {};

  const renderSkeletons = () => {
    return (
      Array(5)
        // @ts-expect-error ts-migrate(2554) FIXME: Expected 1-3 arguments, but got 0.
        .fill()
        .map((item, index) => (
          <Grid item xs={12} xl={10} id='skeleton' key={index}>
            <Skeleton variant='rect' height={112} />
          </Grid>
        ))
    );
  };

  const renderTasks = () => {
    if (tasks.size) {
      return tasks.map((task: any) => (
        <TaskItem
          key={task.get('id')}
          isAssignedToYou={isAssignedToYou}
          task={task.toJS()}
        />
      ));
    }
    return (
      <Grid item xs={12} xl={10} id='empty'>
        <div className={classes.emptyMessage}>
          <EmptyMessage
            title='No tasks found.'
            description='Create, assign, and complete tasks to view them'
            variant='outlined'
          />
        </div>
      </Grid>
    );
  };

  return (
    // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
    <Grid ref={containerRef}>
      {/* @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call. */}
      <Grid
        container
        ref={scrollRef}
        className={classes.taskList}
        style={listStyle}
        justifyContent='center'
        spacing={2}
      >
        {tasksLoading && !tasks.size ? renderSkeletons() : renderTasks()}

        {hasNextPage && (
          <Grid item xs={12} className={classes.loadMoreButton}>
            <Button
              variant='outlined'
              onClick={onFetchNextPage}
              loading={tasksLoading}
              startIcon={<ArrowDownward />}
            >
              Load More
            </Button>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles(() => {
  return {
    taskList: {
      overflowX: 'hidden',
      paddingBottom: 24,
    },
    loadMoreButton: {
      textAlign: 'center',
    },
    emptyMessage: {
      paddingTop: 16,
    },
  };
});

export default TaskList;
