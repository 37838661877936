import moment, { Moment } from 'moment';

export default function formatStartDate(date: any) {
  let formattedDate: Moment = date;

  if (!moment.isMoment(date)) {
    formattedDate = moment(date);
  }

  return formattedDate
    .clone()
    .startOf('day')
    .utc()
    .toISOString();
}
