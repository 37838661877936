import React, { useMemo } from 'react';
// material-ui
import { ToggleButton } from '@material-ui/lab';
import { Box, Badge, Tooltip, Grid } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import {
  emphasize,
  getLuminance,
} from '@material-ui/core/styles/colorManipulator';
import { Add, Remove, Check } from '@material-ui/icons';
type Props = {
  children?: any; // TODO: PropTypes.instanceOf(Object)
  disabled?: boolean;
  selected?: boolean;
  tooltipDisabled?: boolean;
  count?: number;
  checked?: boolean;
  hideSelectIcon?: boolean;
  disableBadge?: boolean;
  type: 'label' | 'event';
};
const ToggleChip = ({
  count = 0,
  checked,
  tooltipDisabled,
  children,
  disabled,
  selected,
  hideSelectIcon,
  disableBadge = true,
  type,
  ...restProps
}: Props) => {
  const badgeCount = useMemo(
    () => (Number.isInteger(count) ? count + Number(selected) : 0),
    [count, selected]
  );

  return (
    <Tooltip
      title={children}
      disableHoverListener={tooltipDisabled}
      disableFocusListener={tooltipDisabled}
      disableTouchListener={tooltipDisabled}
    >
      <span>
        <StyledToggleButton {...{ disabled, selected }} {...restProps}>
          <Box clone width='fill-available' position='inherit'>
            <Badge
              badgeContent={disableBadge ? null : badgeCount}
              color={'primary'}
            >
              <Grid
                container
                justifyContent='space-around'
                alignItems='center'
                spacing={1}
              >
                {!hideSelectIcon &&
                  (selected ? (
                    <Grid item>
                      <Grid container>
                        <Remove />
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid item>
                      <Grid container>
                        <Add />
                      </Grid>
                    </Grid>
                  ))}
                <Grid item xs zeroMinWidth>
                  <Box
                    whiteSpace='nowrap'
                    overflow='hidden'
                    textOverflow='ellipsis'
                    width='fill-available'
                  >
                    {children}
                  </Box>
                </Grid>
                {!hideSelectIcon && type === 'label' && checked && <Check />}
              </Grid>
            </Badge>
          </Box>
        </StyledToggleButton>
      </span>
    </Tooltip>
  );
};
// @ts-expect-error ts-migrate(2339) FIXME: Property 'color' does not exist on type '{ theme: ... Remove this comment to see the full error message
const StyledToggleButton = styled(ToggleButton)(({ theme, color, ring }) => ({
  display: 'inline-flex',
  height: 32,
  paddingLeft: 11,
  paddingRight: 11,
  paddingTop: 0,
  paddingBottom: 0,
  borderRadius: 100,
  width: 'fill-available',
  minWidth: 'calc(100% + 5px)',
  color: ring ? theme.palette.text.primary : theme.palette.text.secondary,
  borderColor: ring
    ? theme.palette.background.default
    : (theme.palette.background as any).lightPaper,
  '&.Mui-selected': {
    borderColor: ring
      ? theme.palette.secondary.main
      : (theme.palette.background as any).lightPaper,
    color: color && getLuminance(color) > 0.5 ? 'black' : 'white',
    backgroundColor: color || 'transparent',
    '&:hover, &:focus': {
      backgroundColor: color
        ? emphasize(color, 0.5)
        : 'rgba(255, 255, 255, 0.25)',
    },
    '&:active': {
      backgroundColor: color
        ? emphasize(color, 0.6)
        : 'rgba(255, 255, 255, 0.15)',
    },
  },
}));
export default ToggleChip;
