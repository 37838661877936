import { Map } from 'immutable';
// constants
import {
  LEAD_ATTRIBUTE_CREATE,
  LEAD_ATTRIBUTE_DELETE,
  LEAD_ATTRIBUTE_UPDATE_OR_CREATE,
} from 'store/constants/leadAttributeTypes';
// helpers
import httpReducer from './helpers/httpReducer';
import removeAttributeByLabel from './callbacks/removeAttributeByLabel';

export default function reduceLeadAttribute(state = Map(), action = {}) {
  switch ((action as any).type) {
    case LEAD_ATTRIBUTE_UPDATE_OR_CREATE:
      return httpReducer(state, action);
    case LEAD_ATTRIBUTE_CREATE:
      return httpReducer(state, action, { appendResult: true });
    case LEAD_ATTRIBUTE_DELETE:
      return httpReducer(state, action, {
        callback: removeAttributeByLabel,
      });
    default:
      return state;
  }
}
