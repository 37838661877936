import React, { useContext } from 'react';
import PropTypes from 'prop-types';

// components
import { Link as RouterLink } from 'react-router-dom';

// material-ui
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import Hidden from '@material-ui/core/Hidden';
// helpers
import { SiteVisualDataContext } from 'components/SiteVisualData';
import isNamesListSite from 'helpers/isNamesListSite';

const SignupFormHeader = function({ classes }: any) {
  const { signupFormHeaderTitle } = useContext(SiteVisualDataContext);
  return (
    <div className={classes.headerContainer}>
      <Typography variant='h4' display='inline'>
        {signupFormHeaderTitle}
      </Typography>
      {!isNamesListSite() ? (
        <Typography
          className={classes.darkAccentColor}
          variant='h4'
          display='inline'
        >
          {' '}
          ChapterBuilder
        </Typography>
      ) : (
        <span className={classes.emptyBox} />
      )}

      <Grid container justifyContent='space-between'>
        <Hidden smDown>
          <Grid item>
            <Typography variant='body1'>
              Please sign up to get started.
            </Typography>
          </Grid>
        </Hidden>

        <Grid item>
          <Typography variant='body1' display='inline'>
            Already a user?{' '}
          </Typography>

          <Typography className={classes.bold} variant='body1' display='inline'>
            <Link component={RouterLink} to='/login' color='textPrimary'>
              Log In
            </Link>
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};

SignupFormHeader.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
};

const styles = (theme: any) => {
  return {
    emptyBox: {
      display: 'inline-block',
      width: 0,
      [theme.breakpoints.up('sm')]: {
        width: 245,
      },
    },
    headerContainer: {
      paddingTop: 16,
      paddingBottom: 16,
      [theme.breakpoints.up('sm')]: {
        padding: 24,
      },
    },
    darkAccentColor: {
      color: theme.palette.background.paper,
    },
    bold: {
      fontWeight: 'bold',
    },
  };
};

// @ts-expect-error ts-migrate(2345) FIXME: Argument of type '(theme: any) => { headerContaine... Remove this comment to see the full error message
export default withStyles(styles)(SignupFormHeader);
