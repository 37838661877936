import Immutable, { List, Map } from 'immutable';
import { denormalize } from 'normalizr';

// schemas
import { groupCouncilSchema } from 'store/schemas/groupSchema';

export default function getChaptersCumpus(state: any) {
  const group = state.get('group', Map());

  const result = group.getIn(['data', 'greekChapterResult'], List());

  const entities = {
    group: state.getIn(['group', 'data', 'byId'], Map()).toJS(),
    label: state.getIn(['label', 'data', 'byId'], Map()).toJS(),
  };

  let items = denormalize(result.toJS(), [groupCouncilSchema], entities);

  return Immutable.fromJS(items.filter((item: any) => item !== undefined));
}
