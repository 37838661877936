import React, { useMemo } from 'react';

// components
import Table from 'components/Table';

// material-ui
import { Grid, Typography, IconButton } from '@material-ui/core';
import { Clear } from '@material-ui/icons';

const rowsPerPage = 5;
type Content = {
  id: 'all' | number;
  fullName: string;
};

export default function ConfirmGuestsTable({
  allSelected,
  hasFilters,
  content,
  onDeselect,
  title,
  allSelectedMessage,
}: {
  allSelected: boolean;
  hasFilters: boolean;
  content: Content[];
  onDeselect: (guest: { id: number; fullName: string }) => void;
  title: string;
  allSelectedMessage: string;
}) {
  const formattedContent = useMemo(
    () =>
      allSelected
        ? content
        : content.map(c => ({
            deselect: (
              <IconButton
                onClick={() =>
                  onDeselect(c as { id: number; fullName: string })
                }
              >
                <Clear />
              </IconButton>
            ),
            ...c,
          })),
    [content, allSelected, onDeselect]
  );

  return (
    <Grid container justifyContent='flex-start' spacing={2}>
      <Grid item xs={12}>
        <Typography variant='subtitle2' color='secondary'>
          {title}
        </Typography>
        <Typography variant='body2' color='textSecondary'>
          {allSelected ? `All ${hasFilters ? 'filtered' : ''}` : content.length}{' '}
          selected
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Table
          columns={columns}
          data={formattedContent}
          variant='outlined'
          SkeletonProps={{
            height: 28,
          }}
          TablePaginationProps={{
            count: content.length,
            rowsPerPage,
            rowsPerPageOptions: [rowsPerPage],
          }}
          emptyMessage={allSelectedMessage}
        />
      </Grid>
    </Grid>
  );
}

const columns = [
  { label: 'Name', key: 'fullName', disableSort: true },
  {
    label: 'Deselect',
    key: 'deselect',
    disableSort: true,
    tooltipTitle: 'Deselect guest',
  },
];
