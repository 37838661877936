import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import Immutable from 'immutable';

// action creators
import {
  doneIndicator,
  error,
  errorIndicator,
  loading,
  success,
  successIndicator,
} from 'store/actions/httpActions';

// api
import client from 'sources/api';

// constants
import { GROUP_FETCH_REQUEST } from 'store/constants/groupTypes';

// helpers
import getResponsePayload from 'helpers/getResponsePayload';
import getGroupType from 'helpers/getGroupType';
import getResultKey from './helpers/getResultKey';
import getPaginationKey from './helpers/getPaginationKey';
import getLoadingKey from './helpers/getLoadingKey';
import getBaseType from '../helpers/getBaseType';
import getTotalKey from './helpers/getTotalKey';
import populateParents from './helpers/populateParents';
import isNamesListSite from 'helpers/isNamesListSite';
import { toString } from 'helpers/transform';

// schema
import { groupSchema } from 'store/schemas/groupSchema';

const WATCH_TYPE = [GROUP_FETCH_REQUEST];

export function* fetchGroups(action: any) {
  const TYPE = getBaseType(action.type);
  let schema = [groupSchema];
  const {
    ids = [],
    fetchParents,
    orderBy = 'name',
    page = 1,
    search = '',
    type = getGroupType('STANDARD'),
    appendResult = false,
    parentId: parent_id, // fetches all groups that are a child of the given id
    parentIds = [],
    labelIds = [],
    latestActivityEnd,
    latestActivityStart,
    mergeItems,
    useOrderWhenIds = false,
    token,
  } = action.payload || {};

  let groupParams = new URLSearchParams();
  groupParams.set('is_nameslist', toString(isNamesListSite()));

  if (ids.length) {
    // Format the params by appending multiple values for the same key
    //  In this case `?id=1&id=2` so that we can get individual groups
    ids.forEach((id: any) => groupParams.append('id', id));
    if (useOrderWhenIds) {
      groupParams.set('order_by', camelToSnakeCase(orderBy));
      groupParams.set('page', page);
    }
  } else if (parentIds.length) {
    // Format the params by appending multiple values for the same key
    //  In this case `?parent_id=1&parent_id=2` so that we can get groups by their parents
    parentIds.forEach((id: any) => groupParams.append('parent_id', id));
  } else {
    // For fetching paginated, searched groups
    groupParams.set('page', page);
    groupParams.set('order_by', camelToSnakeCase(orderBy));

    if (search) groupParams.set('search', search);
    if (parent_id) groupParams.set('linked_parent_id', parent_id);
    if (type) groupParams.set('type', type);
  }

  if (latestActivityStart && latestActivityEnd) {
    groupParams.set('latest_activity_on_start', latestActivityStart);
    groupParams.set('latest_activity_on_end', latestActivityEnd);
  }

  if (labelIds) {
    labelIds.forEach((labelId: any) => groupParams.append('label_id', labelId));
  }

  try {
    // Notify the store that this type is loading
    const loadingKey = getLoadingKey(type);
    yield put(loading(TYPE, loadingKey));

    // Yield to the request promises
    const response = yield call(client.get, '/groups', {
      params: groupParams,
      ...(token ? { headers: { Authorization: `Bearer ${token}` } } : {}),
    });

    const { next, previous, total } = response.data || {};

    // format and normalize the response
    const payload = getResponsePayload(response, schema);

    // Populates parents for groups
    if (fetchParents) {
      const { results = [] } = response.data || {};

      let allParentIds: any = [];
      for (const group of results) {
        for (const parentId of group.parents) {
          if (!allParentIds.includes(parentId)) {
            allParentIds.push(parentId);
          }
        }
      }
      yield populateParents(allParentIds);
    }

    // Notify the store that the HTTP call was successful for this type
    yield put(successIndicator(TYPE));

    // Send the response data to the store for this type to be caught by a reducer
    yield put(
      success(
        TYPE,
        Immutable.fromJS({
          next,
          previous,
          total,
          appendResult,
          mergeItems,
          responseOptions: {
            resultKey: getResultKey(type),
            totalKey: getTotalKey(type),
            paginationKey: getPaginationKey(type),
          },
          ...payload,
        }),
        loadingKey
      )
    );
  } catch (err) {
    // Notify the store that this type had an error
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 2.
    yield put(errorIndicator(TYPE, err));

    // Dispatch an error payload for this type to be caught by the reducer
    yield put(error(TYPE, err));
  } finally {
    yield put(doneIndicator(TYPE));
  }
}

export function* watch() {
  yield takeEvery(WATCH_TYPE, fetchGroups);
}

export default function* root() {
  yield all([fork(watch)]);
}

const camelToSnakeCase = (str = '') =>
  str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);
