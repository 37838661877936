import { Styles, Theme } from 'assets/types';

const tableFirstStyles: Styles = theme => ({
  listContainer: {
    overflow: 'scroll',
    height: 'calc(100% - 60px)',
    paddingLeft: 24,
    paddingRight: 24,
  },
  table: {
    borderCollapse: 'separate',
    borderSpacing: '0 8px',
  },
  root: {
    cursor: 'pointer',
    transition: 'all 0.3s ease',
    height: 80,
    backgroundColor: theme.palette.background.paper,
  },
  loadMoreButton: {
    textAlign: 'center',
  },
  hover: {
    '&$hover:hover': {
      transition: 'all 0.3s ease',
      backgroundColor: (theme as Theme).palette.background.lightPaper,
    },
  },
  selected: {
    '&$selected': {
      transition: 'all 0.3s ease',
      backgroundColor: theme.palette.primary.light,
    },
    '&$selected:hover': {
      backgroundColor: theme.palette.primary.light,
    },
  },
  tableCell: {
    borderBottom: 0,
    '&:first-child': {
      borderRadius: '4px 0 0 4px',
    },
    '&:last-child': {
      borderRadius: '0 4px 4px 0',
    },
  },
  headerTableCell: {
    borderBottom: 0,
    paddingTop: 0,
    paddingBottom: 0,
    color: theme.palette.text.secondary,
  },
  noneFoundContainer: {
    border: `2px solid ${theme.palette.background.paper}`,
    borderRadius: 4,
    padding: 20,
    marginTop: 24,
  },
});

export default tableFirstStyles;
