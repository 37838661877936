import React, { useEffect } from 'react';
import classNames from 'classnames';
import Immutable from 'immutable';
import { isEqual } from 'lodash';

// hooks
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { useLeadAttrsQuery } from 'api/leadAttrs';

// MUI components
import { Grid } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';

// components
import EmptyMessage from 'components/EmptyMessage';
import LeadAttributesHeader from './LeadAttributesHeader';
import LeadAttributeItem from './LeadAttributeItem';

// actions
import { fetchGroupFields } from 'store/actions/groupFieldActions';

// selectors
import getLoadingFromState from 'store/selectors/getLoadingFromState';
import getPermission from 'store/selectors/getPermission';
import getAttributes from 'store/selectors/getAttributes';
import getCurrentGroupId from 'store/selectors/getCurrentGroupId';

export default function LeadAttributes({
  leadId,
  shouldFetchData = true,
}: {
  leadId: number;
  shouldFetchData?: boolean;
}) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const {
    leadLoading,
    groupFieldsLoading,
    canCreateLeadAttribute,
  } = useSelector(
    state => ({
      leadLoading: getLoadingFromState('lead')(state),
      groupFieldsLoading: getLoadingFromState(
        'groupField',
        false,
        false
      )(state),
      canCreateLeadAttribute: getPermission('pnmAttr.createOne')(state),
    }),
    shallowEqual
  );

  const {
    data: leadAttrs = {},
    isLoading: leadAttributeLoading,
    isFetching,
    refetch,
  } = useLeadAttrsQuery(leadId, shouldFetchData);

  // Uses lead from the first useSelector, so we do a second useSelector
  const { currentGroupId, attributes } = useSelector(
    state => ({
      currentGroupId: getCurrentGroupId(state),
      attributes: getAttributes(
        Immutable.fromJS({ attributes: leadAttrs }) as any
      )(state) as any,
    }),
    isEqual
  );

  useEffect(() => {
    if (shouldFetchData) {
      dispatch(
        fetchGroupFields({ groupId: currentGroupId, orderBy: 'position' })
      );
    }
  }, [currentGroupId, dispatch, leadId, shouldFetchData]);

  const renderSkeleton = () => {
    if (leadAttributeLoading) {
      // @ts-expect-error ts-migrate(2554) FIXME: Expected 1-3 arguments, but got 0.
      const skeletons = Array(3).fill();

      return skeletons.map((_, index) => (
        <Grid
          item
          xs={12}
          key={index}
          id='skeletons'
          className={classNames(
            classes.infoItem,
            index + 1 !== skeletons.length && classes.borderedItem
          )}
        >
          <Skeleton height={22} variant='text' width={150} />
          <Skeleton height={22} />
        </Grid>
      ));
    }
  };

  const renderEmptyMessage = () => {
    if (attributes && !attributes.size && !leadLoading && !groupFieldsLoading) {
      return (
        <EmptyMessage
          title='No attributes found'
          description='Build out this profile by adding and editing info'
          descriptionColor='secondary'
        />
      );
    }
  };

  const renderAttributes = () => {
    if (!leadAttributeLoading) {
      return attributes.map((attribute: any, index: any) => (
        <LeadAttributeItem
          key={attribute.get('label')}
          attribute={attribute}
          index={index}
          leadId={leadId}
          isLastItem={index + 1 === attributes.size}
          isFetching={isFetching}
          onChange={refetch}
        />
      ));
    }
  };

  return (
    <Grid container className={classes.outlinedContainer}>
      <LeadAttributesHeader
        leadId={leadId}
        canCreateLeadAttribute={canCreateLeadAttribute}
      />

      {renderSkeleton()}
      {renderEmptyMessage()}

      {renderAttributes()}
    </Grid>
  );
}

const useStyles = makeStyles(theme => {
  const border = `1px solid rgba(255,255,255,0.25)`;

  return {
    outlinedContainer: {
      marginBottom: 20,
      border,
      borderRadius: 4,
    },
    infoItem: {
      paddingLeft: 20,
      paddingRight: 20,
      paddingTop: 5,
      paddingBottom: 15,
    },
    borderedItem: {
      borderBottom: border,
    },
  };
});
