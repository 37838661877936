import React from 'react';
import { Form } from 'react-final-form';
import { useDispatch } from 'react-redux';

// MUI
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

// components
import DazzlingDialog from 'components/DazzlingDialog';

// action creators
import { deleteGroup } from 'store/actions/groupActions';

// icons
import CouncilIcon from '@material-ui/icons/School';
import OrgIcon from '@material-ui/icons/LocationCity';
import ChapterIcon from '@material-ui/icons/AccountBalance';

type Props = {
  groupType: string;
  handleClose: (...args: any[]) => any;
  initialValues: any; // TODO: PropTypes.instanceOf(Object)
  open: boolean;
};

const DeleteChapterForm = ({
  groupType,
  handleClose,
  initialValues,
  open,
}: Props) => {
  const dispatch = useDispatch();
  const { id, name } = initialValues || {};

  const onSubmit = () => {
    dispatch(
      deleteGroup({
        id,
        formName: 'deleteGroupForm',
        type: groupType,
      })
    );
  };

  const getDialogContent = () => {
    return (
      <Grid container>
        <Grid item xs={12} id='warningLabel'>
          <Typography variant='subtitle2' color='secondary'>
            Are you sure you want to delete {name}?
          </Typography>
        </Grid>
      </Grid>
    );
  };

  const getHeaderProps = () => {
    switch (groupType) {
      case 'GREEK_CHAPTER':
        return {
          icon: ChapterIcon,
          title: 'Delete Chapter',
          subtitle: 'Remove chapter from ChapterBuilder',
        };
      case 'GREEK_ORGANIZATION':
        return {
          icon: OrgIcon,
          title: 'Delete Organization',
          subtitle: 'Remove organization from ChapterBuilder',
        };
      case 'COUNCIL':
        return {
          icon: CouncilIcon,
          title: 'Delete Council',
          subtitle: 'Remove council from ChapterBuilder',
        };
      default:
        return {
          title: 'Delete',
        };
    }
  };

  const headerProps = getHeaderProps();

  return (
    <Form
      id='deleteForm'
      onSubmit={onSubmit}
      initialValues={initialValues}
      render={({ handleSubmit }) => (
        <DazzlingDialog
          id='deleteDialog'
          acceptLabel='Delete'
          children={getDialogContent()}
          formName='deleteGroupForm'
          handleClose={handleClose}
          headerProps={headerProps}
          onAccept={handleSubmit}
          open={open}
        />
      )}
    />
  );
};

export default DeleteChapterForm;
