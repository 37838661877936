type ColorRGB = {
  r: number;
  g: number;
  b: number;
};

function hexToRgb(hex: string) {
  var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  hex = hex.replace(shorthandRegex, function(m, r, g, b) {
    return r + r + g + g + b + b;
  });

  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
}

function distance(a: ColorRGB, b: ColorRGB) {
  return Math.sqrt(
    Math.pow(a.r - b.r, 2) + Math.pow(a.g - b.g, 2) + Math.pow(a.b - b.b, 2)
  );
}

// return nearest color from array
export function nearestColor(colorHex: string, colorOptions: string[]) {
  var lowest = Number.POSITIVE_INFINITY;
  var tmp;
  let index = 0;
  colorOptions.forEach((el, i) => {
    const left = hexToRgb(colorHex);
    const right = hexToRgb(el);
    if (left && right) {
      tmp = distance(left, right);
      if (tmp < lowest) {
        lowest = tmp;
        index = i;
      }
    }
  });
  return colorOptions[index];
}
