import React, {
  Dispatch,
  SetStateAction,
  useState,
  useCallback,
  useMemo,
} from 'react';
import { Map } from 'immutable';
import { useSelector } from 'react-redux';
// hooks
import { useStripeProductsQuery } from 'api/stripeProducts';
// components
import SelectYourPlanModal from 'components/ManageSubscription/components/SelectYourPlanModal';
import SelectYourPlanYearMonthModal from 'components/ManageSubscription/components/SelectYourPlanYearMonthModal';
import PaymentMethodModal from 'components/ManageSubscription/components/PaymentMethodModal';
import ConfirmOrderModal from 'components/ManageSubscription/components/ConfirmOrderModal';
import DowngradeModal from 'components/ManageSubscription/components/DowngradeModal';
import BillingErrorModal from 'components/ManageSubscription/components/BillingErrorModal';
// selectors
import getCurrentSubscription from 'store/selectors/getCurrentSubscription';
//helpers
import {
  SubscriptionInfo,
  GroupFormValues,
} from 'components/ManageSubscription/helpers/interfaces';
import { ManageSubscriptionStatus } from 'components/ManageSubscription/helpers/types';
import { toString } from 'helpers/transform';

const INIT_SUBSCRIPTION_INFO: SubscriptionInfo = {
  newSubscription: null,
  newSubscriptionPlanInterval: null,
  confirmDowngrade: false,
  paymentMethodId: null,
  paymentMethodCardBrand: null,
  paymentMethodCardLast4: null,
  emailAddress: null,
};

interface ManageSubscriptionProps {
  currentModalStatus: ManageSubscriptionStatus;
  onCurrentModalStatusChange: Dispatch<
    SetStateAction<ManageSubscriptionStatus>
  >;
  isItNewSubscription?: boolean;
  isSubscriptionBlocked?: boolean;
  willSubscriptionBeChanged?: boolean;
  shouldGroupBeCreated?: boolean;
  onSuccessCreatingSubscription?: (groupId?: number) => void;
  groupFormValues?: GroupFormValues;
}

const ManageSubscription = ({
  isItNewSubscription,
  isSubscriptionBlocked,
  willSubscriptionBeChanged,
  shouldGroupBeCreated,
  currentModalStatus,
  onCurrentModalStatusChange,
  onSuccessCreatingSubscription,
  groupFormValues,
}: ManageSubscriptionProps) => {
  const {
    data: stripeProducts,
    isLoading: stripeProductLoading,
  } = useStripeProductsQuery();
  const [subscriptionInfo, setSubscriptionInfo] = useState<SubscriptionInfo>({
    ...INIT_SUBSCRIPTION_INFO,
  });

  const { currentSubscription } = useSelector(state => ({
    currentSubscription: getCurrentSubscription(state),
  }));

  const {
    currentSubscriptionPlanProductId,
    currentSubscriptionPlanId,
    currentSubscriptionPlanInterval,
  } = useMemo(
    (): {
      currentSubscriptionPlanProductId?: string;
      currentSubscriptionPlanId?: string;
      currentSubscriptionPlanInterval?: string;
    } =>
      !currentSubscription || !Map.isMap(currentSubscription)
        ? {}
        : {
            currentSubscriptionPlanProductId: toString(
              currentSubscription.getIn(['plan', 'product'])
            ),
            currentSubscriptionPlanId: toString(
              currentSubscription.getIn(['plan', 'id'])
            ),
            currentSubscriptionPlanInterval: toString(
              currentSubscription.getIn(['plan', 'interval'])
            ),
          },
    [currentSubscription]
  );

  const handleClose = useCallback(
    (status?: ManageSubscriptionStatus) => {
      const {
        newSubscription,
        newSubscriptionPlanInterval,
        confirmDowngrade,
        emailAddress,
      } = subscriptionInfo;
      switch (status) {
        case ManageSubscriptionStatus.SelectYourPlan:
          setSubscriptionInfo({
            ...INIT_SUBSCRIPTION_INFO,
            ...(newSubscription
              ? { newSubscription: { ...newSubscription } }
              : {}),
          });
          break;
        case ManageSubscriptionStatus.EnterPaymentMethod:
          setSubscriptionInfo({
            ...INIT_SUBSCRIPTION_INFO,
            ...(newSubscription
              ? { newSubscription: { ...newSubscription } }
              : {}),
            newSubscriptionPlanInterval,
            confirmDowngrade,
            emailAddress,
          });
          break;
        default:
          setSubscriptionInfo({ ...INIT_SUBSCRIPTION_INFO });
          break;
      }
    },
    [subscriptionInfo]
  );

  return (
    <>
      {currentModalStatus === ManageSubscriptionStatus.BillingError && (
        <BillingErrorModal
          onCurrentModalStatusChange={onCurrentModalStatusChange}
          onClose={handleClose}
          open
        />
      )}
      {currentModalStatus === ManageSubscriptionStatus.SelectYourPlan && (
        <SelectYourPlanModal
          currentModalStatus={currentModalStatus}
          isItNewSubscription={!!isItNewSubscription}
          isSubscriptionBlocked={!!isSubscriptionBlocked}
          stripeProductLoading={stripeProductLoading}
          stripeProducts={stripeProducts}
          newSubscriptionProductId={subscriptionInfo?.newSubscription?.id}
          currentSubscriptionPlanProductId={currentSubscriptionPlanProductId}
          currentSubscriptionPlanId={currentSubscriptionPlanId}
          onCurrentModalStatusChange={onCurrentModalStatusChange}
          onSubscriptionInfoChange={setSubscriptionInfo}
          onClose={handleClose}
          open
        />
      )}
      {currentModalStatus ===
        ManageSubscriptionStatus.SelectYourPlanYearOrMonth && (
        <SelectYourPlanYearMonthModal
          currentModalStatus={currentModalStatus}
          isItNewSubscription={!!isItNewSubscription}
          newSubscription={subscriptionInfo?.newSubscription}
          stripeProducts={stripeProducts}
          currentSubscriptionPlanInterval={currentSubscriptionPlanInterval}
          currentSubscriptionPlanProductId={currentSubscriptionPlanProductId}
          currentSubscriptionPlanId={currentSubscriptionPlanId}
          onCurrentModalStatusChange={onCurrentModalStatusChange}
          onSubscriptionInfoChange={setSubscriptionInfo}
          onClose={handleClose}
          open
        />
      )}
      {currentModalStatus === ManageSubscriptionStatus.DowngradePlan && (
        <DowngradeModal
          isItNewSubscription={!!isItNewSubscription}
          onCurrentModalStatusChange={onCurrentModalStatusChange}
          onSubscriptionInfoChange={setSubscriptionInfo}
          onClose={handleClose}
          open
        />
      )}
      {currentModalStatus === ManageSubscriptionStatus.EnterPaymentMethod && (
        <PaymentMethodModal
          isItNewSubscription={!!isItNewSubscription}
          subscriptionInfo={subscriptionInfo}
          onCurrentModalStatusChange={onCurrentModalStatusChange}
          onSubscriptionInfoChange={setSubscriptionInfo}
          onClose={handleClose}
          open
        />
      )}
      {[
        ManageSubscriptionStatus.ConfirmOrder,
        ManageSubscriptionStatus.OrderComplete,
        ManageSubscriptionStatus.OrderCompleteClose,
      ].includes(currentModalStatus) && (
        <ConfirmOrderModal
          open={
            currentModalStatus === ManageSubscriptionStatus.ConfirmOrder ||
            currentModalStatus === ManageSubscriptionStatus.OrderComplete
          }
          orderComplete={
            currentModalStatus === ManageSubscriptionStatus.OrderComplete ||
            currentModalStatus === ManageSubscriptionStatus.OrderCompleteClose
          }
          isItNewSubscription={!!isItNewSubscription}
          willSubscriptionBeChanged={willSubscriptionBeChanged}
          subscriptionInfo={subscriptionInfo}
          stripeProducts={stripeProducts}
          currentSubscriptionPlanInterval={currentSubscriptionPlanInterval}
          currentSubscriptionPlanProductId={currentSubscriptionPlanProductId}
          currentSubscriptionPlanId={currentSubscriptionPlanId}
          onCurrentModalStatusChange={onCurrentModalStatusChange}
          onClose={handleClose}
          shouldGroupBeCreated={shouldGroupBeCreated}
          groupFormValues={groupFormValues}
          onSuccessCreatingSubscription={onSuccessCreatingSubscription}
        />
      )}
    </>
  );
};

export default ManageSubscription;
