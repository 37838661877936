import { all, call, fork, takeEvery, put } from 'redux-saga/effects';

// action creators
import { doneIndicator } from 'store/actions/httpActions';

// api
import client from 'sources/api';

// constants
import {
  STATUS_UPDATE_REQUEST,
  STATUS_UPDATE,
} from 'store/constants/statusTypes';

// lib
import httpSaga from 'store/sagas/httpSaga';

// schemas
import { statusSchema } from 'store/schemas/statusSchema';

import { setFormSubmitSucceeded } from 'store/actions/formActions';

const WATCH_TYPE = STATUS_UPDATE_REQUEST;
const TYPE = STATUS_UPDATE;

export function* updateStatuses(action: any) {
  const {
    payload: { formName, statuses },
  } = action;

  yield all(
    statuses.map((statuses: any) => {
      const {
        groupId,
        id,
        abbreviation,
        title,
        description,
        isDefault: is_default,
        position,
      } = statuses;

      return httpSaga(
        TYPE,
        call(client.put, `/statuses/${id}?group_id=${groupId}`, {
          abbreviation,
          title,
          description,
          is_default,
          position,
        }),
        {
          formName,
          metaId: `update-lead-${id}-status`,
          dispatchFormSuccess: false,
          shouldDestroyForm: false,
          schema: statusSchema,
        }
      );
    })
  );

  // Manually setting form submit success necessary for forms that loop through API calls:
  //  Otherwise `submitting` may be set to false while calls are still occuring
  yield put(setFormSubmitSucceeded(formName, ''));

  yield put(doneIndicator(TYPE));
}

export function* watch() {
  yield takeEvery(WATCH_TYPE, updateStatuses);
}

export default function* root() {
  yield all([fork(watch)]);
}
