import React from 'react';

// material-ui
import { Grid, Typography } from '@material-ui/core';

const WaitingRoomHeader = function() {
  return (
    <Grid item>
      <Typography variant='h4'>Request Sent</Typography>
      <Typography variant='body1'>
        You'll be notified by email as soon as you're approved to join!
      </Typography>
    </Grid>
  );
};

export default WaitingRoomHeader;
