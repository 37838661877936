import React, { useEffect, useCallback, memo } from 'react';
import { isEqual } from 'lodash';

// material-ui
import { Grid, Box } from '@material-ui/core';

// components
import LeadCard from './LeadCard';
import EmptyMessage from 'components/EmptyMessage';
import Button from 'components/Button';
import { ArrowDownward } from '@material-ui/icons';

// hooks
import useInfiniteScroll from 'helpers/hooks/useInfiniteScroll';
import { usePrevious } from 'helpers/hooks/usePrevious';

const MAX_CLIENT_HEIGHT = 1240;

type LeadListCardViewProps = {
  allSelected: boolean;
  hasNextPage?: boolean;
  leads: Lead[];
  loading: boolean;
  nextPage?: number | null;
  onFetchNextPage: (...args: any) => void;
  actionLeadIds: number[];
  selectLead: (id: number) => void;
  withPaddingTop?: boolean;
};

const LeadListCardView = function({
  allSelected,
  hasNextPage,
  leads,
  loading,
  nextPage,
  onFetchNextPage,
  actionLeadIds,
  selectLead,
  withPaddingTop = true,
}: LeadListCardViewProps) {
  // Scroll init
  const [isBottom, scrollRef] = useInfiniteScroll(loading, 275);
  const previousIsBottom = usePrevious(isBottom);
  const handleLeadSelect = useCallback((lead: Lead) => selectLead(lead.id), [
    selectLead,
  ]);

  // For screens with a tall height, the user can't scroll to page 2
  const hasTallScreenHeight =
    scrollRef.current &&
    scrollRef.current?.clientHeight > MAX_CLIENT_HEIGHT &&
    nextPage === 2;

  useEffect(() => {
    if (!previousIsBottom && isBottom) {
      onFetchNextPage();
    }
  }, [isBottom, onFetchNextPage, previousIsBottom]);
  return (
    <Box
      paddingTop={withPaddingTop ? 3 : 0}
      overflow='auto'
      height='100%'
      paddingLeft={3}
      paddingRight={3}
      // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
      ref={scrollRef}
    >
      <Grid container spacing={3} key='leadCardView'>
        {leads.map(lead => (
          <Grid item key={lead.id} xs={12} md={6} zeroMinWidth>
            <LeadCard
              lead={lead}
              isSelected={
                allSelected
                  ? !actionLeadIds.includes(lead.id)
                  : actionLeadIds.includes(lead.id)
              }
              onSelect={handleLeadSelect}
              allSelected={allSelected}
            />
          </Grid>
        ))}

        {loading &&
          new Array(10).fill(null).map((_, index) => {
            return (
              <Grid item zeroMinWidth key={index} xs={12} md={6}>
                <LeadCard
                  key={index}
                  lead={{} as Lead}
                  isSelected={false}
                  allSelected={false}
                  onSelect={() => {}}
                  skeleton
                />
              </Grid>
            );
          })}

        {!loading && !leads.length && (
          <Grid item xs={12} id='noneFound'>
            <EmptyMessage
              title='No Leads Found'
              description='Add leads from Actions, Forms, or Events'
              descriptionColor='secondary'
              variant='outlined'
            />
          </Grid>
        )}

        {hasNextPage &&
          leads.length &&
          !loading &&
          scrollRef.current &&
          (scrollRef.current.scrollHeight === scrollRef.current.clientHeight ||
            hasTallScreenHeight) && (
            <Box textAlign='center' clone>
              <Grid item zeroMinWidth xs={12}>
                <Button
                  variant='outlined'
                  onClick={() => onFetchNextPage()}
                  startIcon={<ArrowDownward />}
                >
                  Load More
                </Button>
              </Grid>
            </Box>
          )}
      </Grid>
    </Box>
  );
};

export default memo(LeadListCardView, isEqual);
