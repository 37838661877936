import Immutable, { List, Map } from 'immutable';
import { denormalize } from 'normalizr';

import { inviteSchema } from '../schemas/inviteSchema';

export default function getInvites(state: any) {
  const invite = state.get('invite', Map());
  const result = invite.getIn(['data', 'result'], List());

  const entities = {
    invite: state.getIn(['invite', 'data', 'byId'], Map()).toJS(),
    group: state.getIn(['group', 'data', 'byId'], Map()).toJS(),
  };

  let items = denormalize(result.toJS(), [inviteSchema], entities);

  return Immutable.fromJS(items);
}
