import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
} from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import ReactAutoSuggest from 'react-autosuggest';
import { usePrevious } from 'helpers/hooks/usePrevious';
// MUI components
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Zoom from '@material-ui/core/Zoom';
// icons
import Search from '@material-ui/icons/Search';
import Clear from '@material-ui/icons/Clear';
import AddIcon from '@material-ui/icons/AddCircle';
// components
import TextField from 'components/TextField';
import AutoSuggestFieldItem from './AutoSuggestFieldItem';
const AutoSuggestInput = function({
  autoSuggestType,
  circularProgressProps,
  defaultSuggestion = {},
  displayAllSuggestionsOnFocus,
  handleClearSelected: handleClearSelectedFromProps,
  handleSelectAll,
  handleAddItemClick,
  addItemLabel,
  helperText,
  htmlInputProps,
  input: { onChange, onBlur, onFocus, value, ...restOfInput },
  label,
  loading,
  multiSelect,
  onSuggestionsClearRequested,
  onSuggestionsFetchRequested,
  placeholder,
  shouldReset,
  suggestions,
  suggestionsCardType,
  variant,
  ...restOfProps
}: any) {
  const classes = useStyles();
  const [displayValue, setDisplayValue] = useState('');
  const [formValue, setFormValue] = useState<{
    value?: number;
    label: string;
  }>();
  const [addItemSelected, setAddItemSelected] = useState(false);
  const previousValue = usePrevious(value);
  useEffect(() => {
    const { label, value } = defaultSuggestion;
    if (label && value) {
      setDisplayValue(label);
      setFormValue(defaultSuggestion);
      onChange(defaultSuggestion);
    }
  }, [defaultSuggestion, onChange]);
  const handleClearSelected = useCallback(() => {
    setDisplayValue('');
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
    setFormValue('');
    onChange('');
  }, [onChange]);
  useEffect(() => {
    if (shouldReset) {
      handleClearSelected();
    }
  }, [handleClearSelected, shouldReset]);
  useEffect(() => {
    if (previousValue && !value && fieldRef.current !== null) {
      fieldRef.current.focus();
    }
  }, [previousValue, value]);

  const getSuggestionValue = () => label;
  const handleChange = useCallback((event, { newValue, method }) => {
    if (method === 'type') {
      setDisplayValue(newValue);
      setFormValue(newValue);
    }
  }, []);
  const handleBlur = useCallback(
    event => {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'label' does not exist on type 'Map<unkno... Remove this comment to see the full error message
      const { label } = formValue;
      // added this condition because the event target was coming across null at first
      if (event.target) {
        if (event.target.getAttribute('type') === 'text' && label) {
          const newEvent = event;
          newEvent.target.value = formValue;
          setDisplayValue(label);
          setFormValue(formValue);
        }
      }
    },
    [formValue]
  );
  const handleFocus = useCallback(
    (...params) => {
      setAddItemSelected(false);
      onFocus(params);
    },
    [onFocus]
  );
  const shouldRenderSuggestions = useCallback(() => {
    if (addItemSelected) {
      return false;
    } else if (!displayValue && displayAllSuggestionsOnFocus) {
      // displays all suggestions on initial focus
      return true;
    } else if (displayValue.length > 0) {
      // displays suggestions once character has been typed
      return true;
    }
    return false;
  }, [addItemSelected, displayAllSuggestionsOnFocus, displayValue]);
  const onAddItem = useCallback(() => {
    setAddItemSelected(true);
    const fieldId = handleAddItemClick(displayValue);
    const newSuggestion = { label: displayValue, value: fieldId };
    // Set the form's value to that of the newly created field
    setFormValue(newSuggestion);
    onChange(newSuggestion);
  }, [displayValue, handleAddItemClick, onChange]);
  const handleSuggestionSelected = useCallback(
    (event, { suggestion }) => {
      // Don't allow error message results to be selected
      if (suggestion.value === 'noneFound') {
        return;
      }
      if (suggestion.value === 'addItem') {
        onAddItem(); // create the field and set the form value
      } else if (multiSelect) {
        setDisplayValue('');
        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
        setFormValue('');
        onChange(suggestion);
      } else {
        setDisplayValue(suggestion.label);
        setFormValue(suggestion);
        onChange(suggestion);
      }
    },
    [multiSelect, onAddItem, onChange]
  );
  const renderSuggestion = useCallback(
    ({ label, value }, { isHighlighted }) => {
      let element;
      if (autoSuggestType === 'fields') {
        if (value === 'addItem') {
          element = (
            <MenuItem
              id='add'
              component='div'
              className={classes.addItem}
              selected={isHighlighted}
            >
              <ListItemIcon>
                <AddIcon />
              </ListItemIcon>
              <Typography>{label}</Typography>
            </MenuItem>
          );
        } else {
          element = (
            <AutoSuggestFieldItem
              label={label}
              value={value}
              // @ts-expect-error ts-migrate(2322) FIXME: Type '{ label: any; value: any; items: any; search... Remove this comment to see the full error message
              items={Immutable.fromJS(suggestions)}
              searchValue={displayValue}
              selected={isHighlighted}
            />
          );
        }
      } else {
        element = (
          <MenuItem id='menuItem' selected={isHighlighted} component='div'>
            <Typography>{label}</Typography>
          </MenuItem>
        );
      }
      return element;
    },
    [autoSuggestType, classes.addItem, displayValue, suggestions]
  );
  const renderLoadingIndicator = useCallback(() => {
    return (
      <CircularProgress
        className={classes.loadingIndicator}
        size={20}
        {...circularProgressProps}
      />
    );
  }, [circularProgressProps, classes.loadingIndicator]);
  const fieldRef = useRef<HTMLInputElement | null>(null);
  const renderInput = useCallback(
    inputProps => {
      const { label, helperText, variant, ...restOfInput } = inputProps;
      restOfProps.InputProps = {
        startAdornment: (
          <InputAdornment position='start'>
            <Search />
          </InputAdornment>
        ),
        endAdornment: loading ? (
          renderLoadingIndicator()
        ) : (
          <InputAdornment position='end'>
            <Zoom in={Boolean(value) && Boolean(displayValue)}>
              <IconButton
                onClick={handleClearSelected}
                disabled={restOfProps?.disabled}
              >
                <Clear />
              </IconButton>
            </Zoom>
          </InputAdornment>
        ),
        ...restOfProps.InputProps,
      };
      return (
        <FormControl className={classes.formControl}>
          <TextField
            placeholder={placeholder}
            label={label}
            margin='normal'
            variant={variant}
            helperText={helperText}
            inputRef={fieldRef}
            InputProps={restOfProps.InputProps}
            inputProps={htmlInputProps}
            {...restOfInput}
            {...restOfProps}
          />
        </FormControl>
      );
    },
    [
      fieldRef,
      classes.formControl,
      displayValue,
      handleClearSelected,
      htmlInputProps,
      loading,
      placeholder,
      renderLoadingIndicator,
      restOfProps,
      value,
    ]
  );
  const renderSuggestionsContainer = useCallback(
    ({ containerProps, children }) => (
      <Paper
        classes={{ root: children ? '' : classes.suggestionsCardClosed }}
        {...containerProps}
      >
        {children}
      </Paper>
    ),
    [classes.suggestionsCardClosed]
  );
  const theme = useMemo(
    () => ({
      container: classes.container,
      suggestionsContainer:
        suggestionsCardType === 'popup'
          ? classes.suggestionsCardPopup
          : classes.suggestionsCardExpand,
      suggestionsList: classes.suggestionsList,
      suggestion: classes.suggestion,
    }),
    [
      classes.container,
      classes.suggestion,
      classes.suggestionsCardExpand,
      classes.suggestionsCardPopup,
      classes.suggestionsList,
      suggestionsCardType,
    ]
  );
  const inputProps = {
    helperText,
    label,
    onBlur: handleBlur,
    onChange: handleChange,
    onFocus: handleFocus,
    value: displayValue,
    variant,
    ...restOfInput,
  };
  const newSuggestions = suggestions.length
    ? suggestions
    : [{ label: 'No results found.', value: 'noneFound' }];
  // Handles appending the add item value to the array of suggestions
  const itemExists = suggestions.find(
    (suggestion = {}) =>
      ((suggestion as any).label || '').toLowerCase() ===
      (displayValue || '').toLowerCase()
  );
  if (autoSuggestType === 'fields' && !itemExists && displayValue) {
    if (newSuggestions[0].value === 'noneFound') {
      newSuggestions.shift();
    }
    const addItemInArray = newSuggestions.find(
      (suggestion: any) => suggestion.value === 'addItem'
    );
    if (!addItemInArray) {
      newSuggestions.push({
        label: `Add ${addItemLabel} "${displayValue}"`,
        value: 'addItem',
      });
    }
  }
  useEffect(() => {
    if (
      addItemSelected &&
      formValue &&
      !formValue.value &&
      suggestions.length > 0
    ) {
      const newValue = suggestions.find(
        (suggestion: { label: string; value: number }) =>
          suggestion.label === formValue.label
      );
      if (newValue) {
        setFormValue(newValue);
        onChange(newValue);
      }
    }
  }, [addItemSelected, formValue, suggestions, onChange]);

  return (
    <div className={classes.autoSuggestContainer}>
      <ReactAutoSuggest
        id='autosuggest'
        focusInputOnSuggestionClick={false}
        getSuggestionValue={getSuggestionValue}
        highlightFirstSuggestion={true}
        shouldRenderSuggestions={shouldRenderSuggestions}
        inputProps={inputProps}
        onSuggestionSelected={handleSuggestionSelected}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        renderInputComponent={renderInput}
        renderSuggestion={renderSuggestion}
        renderSuggestionsContainer={renderSuggestionsContainer}
        suggestions={newSuggestions}
        theme={theme}
      />
    </div>
  );
};
AutoSuggestInput.propTypes = {
  autoSuggestType: PropTypes.oneOf(['fields', 'default']),
  circularProgressProps: PropTypes.instanceOf(Object),
  children: PropTypes.instanceOf(Array),
  containerProps: PropTypes.instanceOf(Object),
  defaultSuggestion: PropTypes.instanceOf(Object),
  displayAllSuggestionsOnFocus: PropTypes.bool,
  handleClearSelected: PropTypes.func,
  handleSelectAll: PropTypes.func,
  handleAddItemClick: PropTypes.func,
  addItemLabel: PropTypes.string,
  helperText: PropTypes.string,
  htmlInputProps: PropTypes.instanceOf(Object),
  input: PropTypes.instanceOf(Object).isRequired,
  label: PropTypes.string,
  loading: PropTypes.bool,
  multiSelect: PropTypes.bool,
  onSuggestionsClearRequested: PropTypes.func.isRequired,
  onSuggestionsFetchRequested: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  shouldReset: PropTypes.bool,
  suggestions: PropTypes.instanceOf(Array).isRequired,
  suggestionsCardType: PropTypes.oneOf(['expand', 'popup']),
  variant: PropTypes.string,
};
AutoSuggestInput.defaultProps = {
  autoSuggestType: 'default',
  circularProgressProps: {},
  defaultSuggestion: null,
  displayAllSuggestionsOnFocus: true,
  handleAddItemClick: () => {},
  addItemLabel: 'field',
  helperText: '',
  htmlInputProps: {},
  label: '',
  loading: false,
  placeholder: '',
  suggestionsCardType: 'popup',
  variant: 'standard',
};
const useStyles = makeStyles(theme => ({
  autoSuggestContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  addItem: {
    borderTop: `1px solid ${(theme.palette.background as any).lightPaper}`,
  },
  loadingIndicator: {
    position: 'absolute',
    right: 24,
  },
  container: {
    flexGrow: 1,
    position: 'relative',
  },
  formControl: {
    width: '100%',
  },
  suggestionsCardPopup: {
    position: 'absolute',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3),
    minHeight: 175,
    maxHeight: 175,
    left: 0,
    right: 0,
    zIndex: 100,
    overflowY: 'scroll',
    transition: 'all 0.4s ease',
    backgroundColor: theme.palette.background.default,
  },
  suggestionsCardExpand: {
    position: 'relative',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3),
    left: 0,
    right: 0,
    zIndex: 100,
    transition: 'all 0.4s ease',
    backgroundColor: theme.palette.background.default,
  },
  suggestionsCardClosed: {
    // transition: 'all 0.4s ease',
    margin: 0,
    minHeight: 0,
    backgroundColor: theme.palette.background.default,
  },
  suggestion: {
    display: 'block',
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none',
  },
}));
export default AutoSuggestInput;
