import { Map } from 'immutable';
// constants
import {
  GROUP_FIELD_FETCH,
  GROUP_FIELD_UPDATE,
  GROUP_FIELD_DELETE,
} from 'store/constants/groupFieldTypes';
// helpers
import httpReducer from './helpers/httpReducer';
import remove from './callbacks/remove';
import removeByLabel from './helpers/removeByLabel';
import { GROUP_VALUE_DELETE } from 'store/constants/groupValueTypes';

export default function reduceGroupField(state = Map(), action = {}) {
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'payload' does not exist on type '{}'.
  const { payload = Map() } = action;
  switch ((action as any).type) {
    case GROUP_FIELD_FETCH:
      return httpReducer(state, action, {
        entity: 'groupField',
        updateResult: true,
        mergeItems: true,
        disableMergeDeep: true,
        appendResult: true,
        result: payload.getIn(['responseOptions', 'resultKey'], 'result'),
      });
    case GROUP_FIELD_UPDATE:
      return httpReducer(state, action, {
        updateResult: true,
        mergeItems: true,
        disableMergeDeep: true,
        appendResult: true,
      });
    case GROUP_FIELD_DELETE:
      return httpReducer(state, action, { callback: remove });
    case GROUP_VALUE_DELETE:
      return removeByLabel(state, action, { result: 'valuesResult' });
    default:
      return state;
  }
}
