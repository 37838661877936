import React from 'react';

// components
import { Grid, Box } from '@material-ui/core';
import CultureFitEndorsements from 'components/CultureFitEndorsements';

export default function ValuesIndicator() {
  return (
    <Grid container spacing={2}>
      <Grid item xs>
        <Box margin={2}>
          <CultureFitEndorsements />
        </Box>
      </Grid>
    </Grid>
  );
}
