import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import validate from 'validate.js';

// material-ui
import Grid from '@material-ui/core/Grid';

// icons
import Star from '@material-ui/icons/Star';

//components
import DazzlingDialog from 'components/DazzlingDialog';
import FieldSelector from 'components/FieldSelector';
import TextField from 'components/TextField';
import { Form, Field } from 'react-final-form';

// action creators
import { createGroupValue } from 'store/actions/groupValueActions';
import getCurrentGroupId from 'store/selectors/getCurrentGroupId';

const FORM = 'createValueForm';

type Props = {
  onClose: (...args: any[]) => any;
  open: boolean;
};

const CreateValueModal = ({ open, onClose }: Props) => {
  const { groupId } = useSelector(state => ({
    groupId: getCurrentGroupId(state),
  }));

  const dispatch = useDispatch();
  const dispatchCreateValue = useCallback(
    values => {
      dispatch(createGroupValue({ groupId, formName: FORM, ...values }));
    },
    [dispatch, groupId]
  );

  const dialogContent = (values: any) => {
    return (
      <Grid container spacing={2} direction='column'>
        <Grid item xs={12} sm={6}>
          <Field
            name='selectedField'
            values={values}
            component={FieldSelector}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <Field
            name='description'
            label='Description'
            variant='outlined'
            helperText='Describe how a member may represent this value'
            component={TextField}
          />
        </Grid>
      </Grid>
    );
  };

  return (
    <Form
      id='createValueForm'
      validate={values => validate(values, schema)}
      onSubmit={values => dispatchCreateValue(values)}
      render={({ handleSubmit, invalid, values, form: { restart } }) => (
        <DazzlingDialog
          acceptLabel='Create'
          disabled={invalid}
          formName={FORM}
          handleClose={onClose}
          headerProps={dialogData}
          height='short'
          id='createValue'
          onAccept={handleSubmit}
          open={open}
          resetForm={restart}
          submitOnEnter={false}
        >
          {dialogContent(values)}
        </DazzlingDialog>
      )}
    />
  );
};

const dialogData = {
  icon: Star,
  title: 'New Value',
  subtitle: `Create a field that represents your chapter's values`,
};

// schemas
const schema = {
  selectedField: {
    presence: true,
  },
};

export default CreateValueModal;
