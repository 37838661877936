import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// material-ui
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
//components
import PaymentSetupCard from 'routes/PaymentSettings/PaymentSetupCard';
import FormFeesCard from 'routes/PaymentSettings/FormFeesCard';
//actions
import { fetchPaymentSettingsAction } from 'store/actions/paymentSettingsActions';
import { fetchCurrentGroup } from 'store/actions/groupActions';
//selectors
import getCurrentGroupId from 'store/selectors/getCurrentGroupId';
import getPaymentAccountNumber from 'store/selectors/getPaymentAccountNumber';
import getPaymentContactPersonId from 'store/selectors/getPaymentContactPersonId';
import getPaymentStatus from 'store/selectors/getPaymentStatus';
import getCurrentPaymentStatus from 'store/selectors/getCurrentPaymentStatus';
import getLoadingFromState from 'store/selectors/getLoadingFromState';
//helpers
import { isEmpty } from 'helpers/check';
import {
  isPaymentMethodEnabled,
  isPaymentMethodDisabled,
  isPaymentMethodWaiting,
  isPaymentMethodFailed,
} from 'helpers/checkPaymentMethod';

const PaymentSettings = () => {
  const dispatch = useDispatch();
  const [areYouGoingToGoToLink, setAreYouGoingToGoToLink] = useState<boolean>(
    false
  );
  const [areWaiting, setAreWaiting] = useState<NodeJS.Timer | null>(null);
  const [waitingCount, setWaitingCount] = useState<number>(0);
  const [isFailedAfterSetup, setIsFailedAfterSetup] = useState<boolean>(false);

  const {
    groupId,
    accountNumber,
    paymentStatus,
    contactPersonId,
    currentPaymentStatus,
    isAccountNumberLoading,
    isCurrentGroupLoading,
  } = useSelector(state => ({
    groupId: getCurrentGroupId(state),
    accountNumber: getPaymentAccountNumber(state),
    contactPersonId: getPaymentContactPersonId(state),
    paymentStatus: getPaymentStatus(state),
    currentPaymentStatus: getCurrentPaymentStatus(state),
    isAccountNumberLoading: getLoadingFromState(
      'paymentSettings',
      undefined,
      false
    )(state),
    isCurrentGroupLoading: getLoadingFromState(
      'currentGroup',
      undefined,
      false
    )(state),
  }));

  const isPaymentActive = paymentStatus
    ? isPaymentMethodEnabled(paymentStatus)
    : false;

  useEffect(() => {
    return () => {
      if (groupId && !isCurrentGroupLoading) {
        dispatch(fetchCurrentGroup({ id: groupId }));
      }
    };
  }, []); // eslint-disable-line

  useEffect(() => {
    if (!isPaymentMethodDisabled(currentPaymentStatus) && groupId) {
      dispatch(fetchPaymentSettingsAction({ groupId }));
    }
  }, [dispatch, groupId, currentPaymentStatus]);

  useEffect(() => {
    if (
      !areWaiting &&
      !isAccountNumberLoading &&
      (isPaymentMethodWaiting(paymentStatus) ||
        (isPaymentMethodFailed(paymentStatus) && isFailedAfterSetup))
    ) {
      setAreWaiting(currentAreWaiting => {
        if (!currentAreWaiting) {
          return setTimeout(() => {
            dispatch(fetchPaymentSettingsAction({ groupId }));
            setAreWaiting(null);
          }, 20000);
        }

        return currentAreWaiting;
      });
      if (waitingCount < 20) {
        setWaitingCount(currentWaitingCount => currentWaitingCount + 1);
      }
    }
    // eslint-disable-next-line
  }, [paymentStatus, areWaiting, isAccountNumberLoading, groupId, dispatch]);

  if (
    isPaymentMethodDisabled(currentPaymentStatus) &&
    isAccountNumberLoading &&
    !paymentStatus &&
    areYouGoingToGoToLink
  ) {
    return (
      <Box m={3}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <PaymentSetupCard
        accountNumber={accountNumber}
        contactPersonId={contactPersonId}
        isPaymentActive={isPaymentActive}
        paymentStatus={paymentStatus || 'disabled'}
        groupId={groupId}
        areYouGoingToGoToLink={areYouGoingToGoToLink}
        setAreYouGoingToGoToLink={setAreYouGoingToGoToLink}
        isAccountNumberLoading={isAccountNumberLoading}
        setIsFailedAfterSetup={setIsFailedAfterSetup}
        isFailedAfterSetup={isFailedAfterSetup}
        waitingCount={waitingCount}
      />
      {accountNumber && !isEmpty(accountNumber) && isPaymentActive && (
        <FormFeesCard isPaymentActive={isPaymentActive} />
      )}
    </>
  );
};

export default PaymentSettings;
