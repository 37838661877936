import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { List, Map } from 'immutable';
import pluralize from 'pluralize';

// hooks
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import {
  useFeatureLimitRemaining,
  useFeatureHasLimit,
} from 'helpers/hooks/featureHooks';

// material-ui
import Grid from '@material-ui/core/Grid';

// components
import ListCard from 'components/ListCard';
import CurrentMemberItem from './CurrentMemberItem';
import DeleteAllMembersModal from './DeleteAllMembersModal';

// icons
import PeopleIcon from '@material-ui/icons/People';
import DeleteIcon from '@material-ui/icons/Delete';

// action creators
import { fetchMembersForGroup } from 'store/actions/memberActions';

// selectors
import getMembers from 'store/selectors/getMembers';
import getLoadingFromState from 'store/selectors/getLoadingFromState';
import getNextPageUrlFromState from 'store/selectors/getNextPageUrlFromState';
import getTotal from 'store/selectors/getTotal';

const CurrentMembersCard = function({ currentGroup }: any) {
  const currentGroupId = currentGroup.get('id');

  const dispatch = useDispatch();
  const [searchValue, setSearchValue] = useState('');
  const [timerId, setTimerId] = useState(null);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const { members, loading, hasNextPage, totalMembers } = useSelector(
    state => ({
      members: getMembers()(state) as any,
      loading: getLoadingFromState('member', false, false)(state),
      hasNextPage: Boolean(getNextPageUrlFromState('member')(state)),
      totalMembers: getTotal('member')(state),
    }),
    shallowEqual
  );

  let usersRemainingMessage = '';
  const usersRemaining = useFeatureLimitRemaining('users', members.size);
  const usersHasLimit = useFeatureHasLimit('users');

  if (usersHasLimit && !loading) {
    if (usersRemaining) {
      usersRemainingMessage = pluralize(
        `You may add ${usersRemaining} more members`,
        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'number | true' is not assignable... Remove this comment to see the full error message
        usersRemaining
      );
    } else {
      usersRemainingMessage =
        'Your team is full. Remove a member or upgrade for more.';
    }
  }

  useEffect(() => {
    if (currentGroupId) {
      dispatch(fetchMembersForGroup({ groupId: currentGroupId, page: 1 }));
    }
  }, [currentGroupId, dispatch]);

  const renderItems = () => {
    return members.map((member: any) => (
      <CurrentMemberItem member={member} currentGroup={currentGroup} />
    ));
  };

  const fetchItems = (page: any) => {
    dispatch(
      fetchMembersForGroup({
        groupId: currentGroupId,
        page,
        search: searchValue,
      })
    );
  };

  const handleSearchChange = ({ target: { value } }: any) => {
    if (currentGroupId && (value || searchValue)) {
      if (timerId) {
        // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
        clearTimeout(timerId);
      }

      setTimerId(
        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'number' is not assignable to par... Remove this comment to see the full error message
        setTimeout(
          () =>
            dispatch(
              fetchMembersForGroup({
                groupId: currentGroupId,
                page: 1,
                search: value,
              })
            ),
          1000
        )
      );
    }

    setSearchValue(value);
  };

  const getMenuSections = () => {
    // This list is composed into immutable elements manually
    //  so that the icon components don't get converted into objects
    let menuSections = List([
      Map({
        sectionHeader: 'Member Actions',
        id: 'menuActions',
        items: List([
          Map({
            id: 'deleteMembers',
            label: 'Delete All Members',
            icon: <DeleteIcon />,
            onClick: () => setDeleteModalOpen(true),
          }),
        ]),
      }),
    ]);

    return menuSections;
  };

  return (
    <Grid item sm={12} md={6}>
      <ListCard
        title='Current Members'
        subtitle='Manage current members of your team'
        highlightedSubtitle={usersRemainingMessage}
        searchLabel='Search Members'
        searchPlaceholder='Enter name or email'
        headerIcon={PeopleIcon}
        items={renderItems()}
        menuSections={getMenuSections()}
        onSearchChange={handleSearchChange}
        paginationProps={{
          disableNextPage: !hasNextPage,
          onPageChange: fetchItems,
          total: totalMembers,
          loading,
          items: members,
        }}
        loading={loading}
      />

      <DeleteAllMembersModal
        open={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
      />
    </Grid>
  );
};

CurrentMembersCard.propTypes = {
  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'typeof Map' is not assignable to... Remove this comment to see the full error message
  currentGroup: PropTypes.instanceOf(Map).isRequired,
};

export default CurrentMembersCard;
