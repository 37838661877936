import { Styles, Theme } from 'assets/types';

const formItemStyles: Styles = theme => {
  const border = `1px solid ${(theme as Theme).palette.background.lightPaper}`;

  return {
    italic: {
      fontStyle: 'italic',
    },
    bold: {
      fontWeight: 600,
    },
    headerContainer: {
      padding: 24,
      paddingRight: 12,
      borderBottom: border,
    },
    responsesContainer: {
      paddingTop: 5,
    },
    responsesLabel: {
      marginRight: 15,
    },
    actionsContainer: {
      textAlign: 'right',
    },
    expansionDetails: {
      display: 'block',
    },
    exportIcon: {
      float: 'right',
      marginTop: 22,
    },
  };
};

export default formItemStyles;
