import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import validate from 'validate.js';
import moment from 'moment';
import pluralize from 'pluralize';

// hooks
import { useDispatch, useSelector } from 'react-redux';
import { useNewMemberLeadsQuery } from 'api/leads';

// material-ui
import {
  CardActions,
  Grid,
  MenuItem,
  Switch,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

// components
import { Form, Field } from 'react-final-form';
import Button from 'components/Button';
import DateSelector from 'components/DateSelector';

// action creators
import { destroyForm } from 'store/actions/formActions';
import { reportNewMembers } from 'store/actions/apiTaskActions';

// selectors
import getForm from 'store/selectors/getForm';
import getCurrentParentOrganizationName from 'store/selectors/getCurrentParentOrganizationName';
import getReportingPlatformName from 'store/selectors/getReportingPlatformName';

// constants
const UPDATE_FORM = 'updateForm';
const REPORT_FORM = 'reportForm';
const REPORT_MEMBERS_SCHEMA = {
  joinDate: {
    presence: true,
  },
};

const ReportMembersForm = function({
  updateLeadsFormDirty,
  updateLeadsFormInvalid,
  selectedIds,
}: any) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [isCob, setIsCob] = useState<boolean>(false);

  const {
    parentOrgName,
    reportingPlatformName,
    reportMembersForm,
    updateLeadsForm,
  } = useSelector(state => ({
    parentOrgName: getCurrentParentOrganizationName(state),
    reportingPlatformName: getReportingPlatformName(state),
    reportMembersForm: getForm(REPORT_FORM)(state),
    updateLeadsForm: getForm(UPDATE_FORM)(state),
  }));

  const { refetch } = useNewMemberLeadsQuery({ page: 1 });

  useEffect(() => {
    if (reportMembersForm.hasSubmitSucceeded) {
      setTimeout(() => dispatch(destroyForm(REPORT_FORM)), 5000);
      refetch();
    }
  }, [refetch, dispatch, reportMembersForm.hasSubmitSucceeded]);

  const dispatchReportMembers = (values: any) => {
    const joinDate = moment(values.joinDate).format('YYYY-MM-DD');

    if (reportingPlatformName) {
      dispatch(
        reportNewMembers({
          leadIds: selectedIds,
          joinDate,
          formName: REPORT_FORM,
          ...(reportingPlatformName === 'IMIS' && { isCob }),
        })
      );
    }
  };

  const renderFormMessage = useCallback(
    (dirty, invalid) => {
      if (invalid) {
        return (
          <Typography color='secondary' id='updateInvalid' variant='caption'>
            Please fill in all emails to report new members. Emails MUST NOT use
            a '.edu' domain.
          </Typography>
        );
      } else if (dirty) {
        return (
          <Typography color='secondary' id='updateDirty' variant='caption'>
            Please save your changes to report new members
          </Typography>
        );
      } else if (reportMembersForm.formError.size) {
        return (
          <Typography color='error' id='reportError' variant='caption'>
            {reportMembersForm.formError.getIn(
              ['message', 'general'],
              reportMembersForm.formError.get('message', '')
            )}
          </Typography>
        );
      } else if (updateLeadsForm.formError.size) {
        return (
          <Typography color='error' variant='caption' id='updateError'>
            {updateLeadsForm.formError.get('message', '')}
          </Typography>
        );
      } else if (reportMembersForm.hasSubmitSucceeded) {
        return (
          <Typography color='primary' variant='caption' id='success'>
            {reportMembersForm.successMessage}
          </Typography>
        );
      } else if (!selectedIds.length) {
        return (
          <Typography color='secondary' variant='caption' id='noneSelected'>
            No members selected
          </Typography>
        );
      }
      return;
    },
    [
      reportMembersForm.formError,
      reportMembersForm.successMessage,
      reportMembersForm.hasSubmitSucceeded,
      selectedIds.length,
      updateLeadsForm.formError,
    ]
  );

  const handleToggleRecruitmentType = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setIsCob(event.target.checked);
    },
    []
  );

  const reportingDateLabel = getReportingDateLabel(parentOrgName);

  return (
    <Form
      id='reportMembersForm'
      onSubmit={dispatchReportMembers}
      initialValues={{ joinDate: moment().format('YYYY-MM-DD') }}
      validate={values => validate(values, REPORT_MEMBERS_SCHEMA)}
      render={({ values, invalid, handleSubmit, form: { reset } }) => (
        <form onSubmit={handleSubmit}>
          <CardActions className={classes.cardActions}>
            <Grid
              container
              justifyContent='center'
              alignItems='center'
              direction='column'
              spacing={2}
            >
              {reportingPlatformName === 'IMIS' && (
                <Grid item>
                  <Typography variant='body1'>
                    Please select recruitment type
                  </Typography>
                  <MenuItem>
                    <Grid
                      container
                      spacing={2}
                      alignItems='center'
                      justifyContent='center'
                    >
                      <Grid item>
                        <Typography variant='subtitle2'>Primary</Typography>
                      </Grid>
                      <Grid item>
                        <Switch
                          checked={isCob}
                          color='primary'
                          onChange={handleToggleRecruitmentType}
                        />
                      </Grid>
                      <Grid item>
                        <Typography variant='subtitle2'>COB</Typography>
                      </Grid>
                    </Grid>
                  </MenuItem>
                </Grid>
              )}
              <Grid item>
                <Field
                  id='joinDate'
                  name='joinDate'
                  label={reportingDateLabel}
                  component={DateSelector}
                  disableFuture
                  fullWidth
                  allowNull
                  required
                />
              </Grid>
            </Grid>
          </CardActions>

          <CardActions className={classes.cardActions}>
            <Grid
              container
              direction='column'
              justifyContent='center'
              alignItems='center'
              spacing={1}
            >
              <Grid item xs={12}>
                <Button
                  id='reportMembersButton'
                  type='submit'
                  color='primary'
                  variant='contained'
                  disabled={
                    !selectedIds.length ||
                    updateLeadsFormDirty ||
                    updateLeadsFormInvalid ||
                    invalid ||
                    reportMembersForm.isSubmitting ||
                    updateLeadsForm.isSubmitting
                  }
                  loading={reportMembersForm.isSubmitting}
                  success={reportMembersForm.hasSubmitSucceeded}
                  fail={reportMembersForm.hasSubmitFailed}
                >
                  Report {selectedIds.length ? selectedIds.length : ''} New
                  {pluralize(' Member', selectedIds.length)}
                </Button>
              </Grid>

              <Grid item>
                {renderFormMessage(
                  updateLeadsFormDirty,
                  updateLeadsFormInvalid
                )}
              </Grid>
            </Grid>
          </CardActions>
        </form>
      )}
    />
  );
};

ReportMembersForm.propTypes = {
  updateLeadsFormDirty: PropTypes.bool.isRequired,
  updateLeadsFormInvalid: PropTypes.bool.isRequired,
  selectedIds: PropTypes.instanceOf(Array).isRequired,
};

const useStyles = makeStyles(theme => {
  return {
    skeleton: {
      height: 56,
      borderRadius: 4,
    },
    cardActions: { margin: 16 },
  };
});

const getReportingDateLabel = (parentOrgName: string): string => {
  switch (parentOrgName) {
    case 'Alpha Tau Omega':
      return 'Pledge Date';
    case 'Delta Chi':
      return 'Association Date';
    default:
      return 'Date Joined';
  }
};

export default ReportMembersForm;
