import React from 'react';
import { List, Map } from 'immutable';
//helpers
import { arrayToStringWithoutEmpty } from 'helpers/transform';

interface LeadAttributeItemValueProps {
  attribute: Map<string, string | number>;
}

const LeadAttributeItemValue = ({ attribute }: LeadAttributeItemValueProps) => {
  const isCustomType = attribute.get('type') === 'custom';
  const fieldType = attribute.get('fieldType');
  const value = attribute.get('value', null);

  const defaultTextValue = value || '';

  const boolValToText = (value: any) => {
    if (!!value) {
      return 'Yes';
    }
    if (value === false) {
      return 'No';
    }

    return '';
  };

  const multiSelectToText = (value: any) => {
    const separator = ', ';
    if (Array.isArray(value)) {
      return arrayToStringWithoutEmpty(value, separator);
    }

    if (List.isList(value)) {
      return arrayToStringWithoutEmpty(value?.toJS() || [], separator);
    }

    return '';
  };

  const getCouncilField = () => {
    switch (fieldType) {
      case 'multiselect':
        return multiSelectToText(value);

      case 'boolean':
        return boolValToText(value);

      default:
        return defaultTextValue;
    }
  };

  return <>{isCustomType ? getCouncilField() : defaultTextValue}</>;
};

export default LeadAttributeItemValue;
