import getGroupType from '../../helpers/getGroupType';

export default function getCurrentParentOrganization(state: any): Group | null {
  const parents = (state as any)
    .getIn(['currentGroup', 'data', 'parents'])
    ?.toJS();
  let parent1 = null;
  let parent2 = null;

  if (parents && parents[0] != null) {
    parent1 = (state as any)
      .getIn(['group', 'data', 'byId', parents[0].toString()])
      ?.toJS();
  }

  if (parents && parents[1] != null) {
    parent2 = (state as any)
      .getIn(['group', 'data', 'byId', parents[1].toString()])
      ?.toJS();
  }

  if (parent1 && parent1.type === getGroupType('GREEK_ORGANIZATION')) {
    return parent1;
  } else if (parent2 && parent2.type === getGroupType('GREEK_ORGANIZATION')) {
    return parent2;
  } else {
    return null;
  }
}
