import React from 'react';

// react router
import { Route, Switch } from 'react-router-dom';

// components
import LeadProfileView from 'routes/LeadList/LeadProfileView';
import LeadListView from 'routes/LeadList/LeadListView';
import LeadDuplicatesView from 'routes/LeadList/LeadDuplicatesView';
import LeadDuplicateView from 'routes/LeadList/LeadDuplicateView';
import ErrorBoundary from 'layout/ErrorBoundary';

type LeadListProps = {
  isCouncilLeadPage?: boolean;
};

const LeadList = ({ isCouncilLeadPage = false }: LeadListProps) => {
  return (
    <ErrorBoundary>
      <Switch>
        <Route
          path={`/leads/duplicates/:leadDuplicateId`}
          component={LeadDuplicateView}
        />
        <Route path={`/leads/duplicates`} component={LeadDuplicatesView} />
        <Route path='/leads/:leadId'>
          <LeadProfileView isCouncilLeadPage={isCouncilLeadPage} />
        </Route>
        <Route path='/leads'>
          <LeadListView isCouncilLeadPage={isCouncilLeadPage} />
        </Route>
      </Switch>
    </ErrorBoundary>
  );
};

export default LeadList;
