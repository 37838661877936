import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import validate from 'validate.js';
import { Map } from 'immutable';

// hooks
import { useDispatch, useSelector } from 'react-redux';
import { useReduxForm } from 'store/hooks/useReduxForm';

// material-ui
import { makeStyles } from '@material-ui/core/styles';
import { FormHelperText, Grid, InputAdornment } from '@material-ui/core';

// icons
import EmailIcon from '@material-ui/icons/Email';
import SendIcon from '@material-ui/icons/Send';

// components
import { Form, Field } from 'react-final-form';
import TextField from 'components/TextField';
import Button from 'components/Button';
import ChapterSelectionField from 'components/ChapterSelectionField';

// action creators
import { createInvite } from 'store/actions/inviteActions';

// selectors
import getIsHqAdmin from 'store/selectors/getIsHqAdmin';
import getIsSystemAdmin from 'store/selectors/getIsSystemAdmin';
import getIsCouncilAdmin from 'store/selectors/getIsCouncilAdmin';
import getIsCurrentGroupOrganization from 'store/selectors/getIsCurrentGroupOrganization';
import getIsCurrentGroupCouncil from 'store/selectors/getIsCurrentGroupCouncil';

// schemas
const schema = {
  email: {
    presence: true,
    email: { message: 'not valid' },
    length: { maximum: 255 },
  },

  role: {
    presence: true,
  },
};

const CHAPTER_SUB_TEXT = `Select group to add admins to, else they will be added as HQ admins`;

const SingleInviteForm = function({ currentGroup, roleOptions }: any) {
  const {
    hasSubmitFailed,
    hasSubmitSucceeded,
    isSubmitting,
    formError,
  } = useReduxForm('singleInviteForm');

  const classes = useStyles();
  const dispatch = useDispatch();

  const {
    isCouncilAdmin,
    isCurrentGroupCouncil,
    isCurrentGroupOrganization,
    isHqAdmin,
    isSystemAdmin,
  } = useSelector(state => ({
    isCouncilAdmin: getIsCouncilAdmin(state),
    isCurrentGroupCouncil: getIsCurrentGroupCouncil(state),
    isCurrentGroupOrganization: getIsCurrentGroupOrganization(state),
    isHqAdmin: getIsHqAdmin(state),
    isSystemAdmin: getIsSystemAdmin(state),
  }));

  let resetForm: any;
  let resetFormFieldState: any;

  useEffect(() => {
    if (hasSubmitSucceeded) {
      resetForm();

      // The `touched` state of a field does not reset on submit,
      //  so we must do this manually for any field that uses `presence` for validation
      resetFormFieldState('email');
      resetFormFieldState('role');
    }
  }, [hasSubmitSucceeded]); // eslint-disable-line

  const onSubmit = (values: any) => {
    dispatch(
      createInvite({
        groupId: currentGroup.get('id'),
        ...values,
        childGroupId: values.childIds,
      })
    );
  };

  const getInitialValues = useCallback(() => {
    if (
      (isCurrentGroupOrganization && (isHqAdmin || isSystemAdmin)) ||
      (isCurrentGroupCouncil && (isCouncilAdmin || isSystemAdmin))
    ) {
      return {
        role: 1,
      };
    }
    return {};
  }, [
    isCouncilAdmin,
    isCurrentGroupCouncil,
    isCurrentGroupOrganization,
    isHqAdmin,
    isSystemAdmin,
  ]);

  return (
    <div>
      <Form
        onSubmit={onSubmit}
        validate={values => validate(values, schema)}
        initialValues={getInitialValues()}
        render={({
          handleSubmit,
          invalid,
          pristine,
          form: { reset, resetFieldState },
        }) => {
          resetForm = reset;
          resetFormFieldState = resetFieldState;

          return (
            <Grid
              container
              component='form'
              onSubmit={handleSubmit}
              spacing={2}
              alignItems='flex-start'
            >
              <Grid item xs={12} sm={3}>
                <Field
                  name='email'
                  render={fieldProps => <TextField {...fieldProps} />}
                  label='Email Address'
                  placeholder='Enter email address'
                  type='email'
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <EmailIcon />
                      </InputAdornment>
                    ),
                  }}
                  required
                />
              </Grid>

              <Grid item xs={12} sm={3}>
                <Field
                  id='roleSelect'
                  name='role'
                  label='Role'
                  disabled={
                    (isCurrentGroupOrganization &&
                      (isHqAdmin || isSystemAdmin)) ||
                    (isCurrentGroupCouncil && (isCouncilAdmin || isSystemAdmin))
                  }
                  render={fieldProps => <TextField {...fieldProps} />}
                  options={roleOptions}
                  select
                />
              </Grid>

              {(isSystemAdmin || isHqAdmin) && isCurrentGroupOrganization && (
                <Grid item xs={10} sm={3}>
                  <ChapterSelectionField
                    groupId={currentGroup.get('id')}
                    multiple={false}
                    subtext={CHAPTER_SUB_TEXT}
                    label='HQ Group'
                    suggestions='suggestionsByName'
                  />
                </Grid>
              )}

              <Grid item xs={2} sm='auto' className={classes.sendButton}>
                <Button
                  id='sendInviteButton'
                  type='submit'
                  fabIcon={<SendIcon />}
                  color='primary'
                  variant='outlined'
                  disabled={pristine || invalid}
                  loading={isSubmitting}
                  fail={hasSubmitFailed}
                  success={hasSubmitSucceeded}
                >
                  <SendIcon className={classes.submitButtonIcon} />
                  Send Invite
                </Button>
              </Grid>
            </Grid>
          );
        }}
      />

      {formError && (
        <Grid item>
          <FormHelperText error>{formError.get('message')}</FormHelperText>
        </Grid>
      )}
    </div>
  );
};

SingleInviteForm.propTypes = {
  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'typeof Map' is not assignable to... Remove this comment to see the full error message
  currentGroup: PropTypes.instanceOf(Map).isRequired,
  roleOptions: PropTypes.instanceOf(Array).isRequired,
};

const useStyles = makeStyles({
  submitButtonIcon: {
    marginRight: 10,
  },
  sendButton: {
    marginTop: 25,
  },
});

export default SingleInviteForm;
