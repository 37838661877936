import React, { useCallback, useContext, useState } from 'react';

// hooks
import { useDispatch } from 'react-redux';

// material-ui
import {
  Card,
  CardContent,
  Grid,
  Typography,
  makeStyles,
  Box,
} from '@material-ui/core';

// icons
import { DeleteOutline } from '@material-ui/icons';

// components
import Header from 'components/Header';
import Button from 'components/Button';
import DazzlingDialog from 'components/DazzlingDialog';

// react query
import { useDeleteSelfAccountMutation } from 'api/account';

// action creators
import { storeClear } from 'store/actions/storeActions';

// helpers
import { removeAccessToken, removeRefreshToken } from 'helpers/tokenUtils';
import { SiteVisualDataContext } from 'components/SiteVisualData';

const DeleteAccountCard = () => {
  const classes = useStyles();
  const {
    deleteAccountCardHighlightedSubtitle,
    deleteAccountCardSubtitle,
  } = useContext(SiteVisualDataContext);
  const dispatch = useDispatch();
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const {
    mutate: deleteAccount,
    isLoading: isDeleting,
    isError,
    isSuccess: isDeleted,
    reset: resetDeletion,
  } = useDeleteSelfAccountMutation();

  const deleteHandler = useCallback(() => {
    deleteAccount();
  }, [deleteAccount]);

  const logout = useCallback(() => {
    if (isDeleted) {
      removeAccessToken();
      removeRefreshToken();
      dispatch(
        storeClear({
          session: {
            valid: false,
          },
        })
      );
    }
    resetDeletion();
  }, [dispatch, isDeleted, resetDeletion]);

  return (
    <Grid item sm={12} md={6}>
      <DazzlingDialog
        id='deleteAccountModal'
        successMessage='Account Deleted Successfully'
        failureMessage='Failed to Delete Account'
        isLoading={isDeleting}
        isSuccess={isDeleted}
        isError={isError}
        onExited={logout}
        handleClose={() => setDeleteModalOpen(false)}
        open={deleteModalOpen}
        acceptLabel='Delete'
        onAccept={deleteHandler}
        headerProps={{
          icon: DeleteOutline,
          title: `Are you sure you want to Delete your account?`,
          highlightedSubtitle: deleteAccountCardHighlightedSubtitle,
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid
              container
              justifyContent='center'
              alignItems='center'
              spacing={2}
            >
              <Grid item xs={12}>
                <Typography variant='h6'>
                  THIS ACTION CANNOT BE UNDONE!
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DazzlingDialog>
      <Card id='deleteAccountContent'>
        <CardContent>
          <Header
            id='header'
            icon={DeleteOutline}
            title='Delete Account'
            subtitle={deleteAccountCardSubtitle}
          />

          <Box display='flex' justifyContent='center'>
            <Button
              color='primary'
              variant='contained'
              size='large'
              className={classes.button}
              onClick={() => {
                setDeleteModalOpen(true);
              }}
            >
              Delete Account
            </Button>
          </Box>
        </CardContent>
      </Card>
    </Grid>
  );
};
const useStyles = makeStyles(theme => {
  return {
    button: {
      width: '70%',
    },
  };
});
export default DeleteAccountCard;
