import React, { useState } from 'react';

// MUI
import {
  Card,
  Grid,
  Box,
  IconButton,
  Button,
  Tooltip,
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import {
  PersonAdd,
  Add,
  Delete,
  VpnKeyOutlined,
  FileCopyOutlined,
} from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';

// components
import Header from 'components/Header';

// api
import {
  useGroupKeyQuery,
  useCreateGroupKeyMutation,
  useDeleteGroupKeyMutation,
} from 'api/groupKey';
import AuthIntegrations from './AuthIntegrations/index';

// helpers
import isNamesListSite from 'helpers/isNamesListSite';

export default function IntegrationsSettings() {
  const classes = useStyles();
  const [tooltipTitle, setTooltipTitle] = useState('Copy API Key');
  const {
    mutate: createGroupKey,
    isLoading: isCreating,
  } = useCreateGroupKeyMutation();
  const {
    mutate: deleteGroupKey,
    isLoading: isDeleting,
  } = useDeleteGroupKeyMutation();
  const { data: groupKey, isLoading: isQuerying } = useGroupKeyQuery();

  return (
    <Grid item xs={12}>
      <Card className={classes.topCard}>
        <Box minHeight={280}>
          <Grid container>
            <Grid item xs={12}>
              <Header
                icon={PersonAdd}
                title='Leads Integration'
                subtitle={
                  'Manage API access key for the open leads integration'
                }
                highlightedSubtitle={
                  !groupKey?.apiKey
                    ? 'This group does not have an API key yet'
                    : undefined
                }
              />
            </Grid>
            <Grid item>
              <Box width={91} />
            </Grid>
            <Grid item xs={7}>
              {isQuerying || isCreating || isDeleting ? (
                <Skeleton height={100} />
              ) : groupKey?.apiKey ? (
                <Box
                  clone
                  height={84}
                  padding={2}
                  border={1}
                  borderRadius={4}
                  borderColor='background.outline'
                >
                  <Grid container alignItems='center'>
                    <Grid
                      item
                      xs={2}
                      container
                      direction='column'
                      alignItems='center'
                    >
                      <Grid item>
                        <VpnKeyOutlined />
                      </Grid>
                      <Grid item>API Key</Grid>
                    </Grid>
                    <Grid item xs>
                      <Tooltip
                        arrow
                        onClose={() =>
                          setTimeout(() => setTooltipTitle('Copy API Key'), 100)
                        }
                        title={tooltipTitle}
                        leaveDelay={1500}
                        placement='top'
                      >
                        <Button
                          startIcon={<FileCopyOutlined />}
                          onClick={() => {
                            if (groupKey && groupKey.apiKey) {
                              navigator.clipboard.writeText(groupKey.apiKey);
                              setTooltipTitle('Copied to clipboard');
                            }
                          }}
                        >
                          {groupKey?.apiKey}
                        </Button>
                      </Tooltip>
                    </Grid>
                    <Grid item>
                      <IconButton onClick={() => deleteGroupKey()}>
                        <Delete />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Box>
              ) : (
                <Button
                  variant='contained'
                  color='primary'
                  startIcon={<Add />}
                  onClick={() => createGroupKey()}
                >
                  Create API Key
                </Button>
              )}
            </Grid>
          </Grid>
        </Box>

        {/* TODO: Remove this after this will be handled from backend*/}
        {!isNamesListSite() && <AuthIntegrations />}
      </Card>
    </Grid>
  );
}

const useStyles = makeStyles({
  topCard: {
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    padding: 16,
    width: '100%',
  },
  cardActions: { margin: 16 },
});
