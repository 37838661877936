import { all, call, fork, takeEvery, put, select } from 'redux-saga/effects';

// action creators
import { doneIndicator } from 'store/actions/httpActions';

// api
import client from 'sources/api';

// selectors
import getCurrentGroupId from 'store/selectors/getCurrentGroupId';

// constants
import {
  COMMENT_UPDATE_REQUEST,
  COMMENT_UPDATE,
} from 'store/constants/commentTypes';

// lib
import httpSaga from 'store/sagas/httpSaga';

// schemas
import { commentSchema } from 'store/schemas/commentSchema';

const WATCH_TYPE = COMMENT_UPDATE_REQUEST;
const TYPE = COMMENT_UPDATE;

export function* updateComment(action: any) {
  const {
    payload: {
      id,
      fields: { body },
    },
  } = action;

  const currentGroupId = yield select(getCurrentGroupId);

  // @ts-expect-error ts-migrate(2569) FIXME: Type 'Generator<any, any, unknown>' is not an arra... Remove this comment to see the full error message
  yield* httpSaga(
    TYPE,
    call(client.patch, `/comments/${id}?group_id=${currentGroupId}`, { body }),
    {
      schema: commentSchema,
      metaId: id,
    }
  );

  yield put(doneIndicator(TYPE));
}

export function* watch() {
  yield takeEvery(WATCH_TYPE, updateComment);
}

export default function* root() {
  yield all([fork(watch)]);
}
