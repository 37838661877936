import ApplicationLayout from './ApplicationLayout';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

// selectors
import getIsSystemAdmin from 'store/selectors/getIsSystemAdmin';
import getIsHqAdmin from 'store/selectors/getIsHqAdmin';
import getIsCouncilAdmin from 'store/selectors/getIsCouncilAdmin';
import getIsCurrentGroupChapter from 'store/selectors/getIsCurrentGroupChapter';
import getIsCurrentGroupOrganization from 'store/selectors/getIsCurrentGroupOrganization';
import getIsCurrentGroupCouncil from 'store/selectors/getIsCurrentGroupCouncil';
import getIsCurrentGroupSystem from 'store/selectors/getIsCurrentGroupSystem';

const mapState = (state: any) => {
  return {
    isSystemAdmin: getIsSystemAdmin(state),
    isHqAdmin: getIsHqAdmin(state),
    isCouncilAdmin: getIsCouncilAdmin(state),
    isCurrentGroupChapter: getIsCurrentGroupChapter(state),
    isCurrentGroupOrganization: getIsCurrentGroupOrganization(state),
    isCurrentGroupCouncil: getIsCurrentGroupCouncil(state),
    isCurrentGroupSystem: getIsCurrentGroupSystem(state),
  };
};

export default withRouter(connect(mapState)(ApplicationLayout));
