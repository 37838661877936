import React from 'react';
import { List, Map } from 'immutable';
import { useHistory } from 'react-router-dom';

// MUI
import Grid from '@material-ui/core/Grid';

// components
import JumboTab from './components/JumboTab';

JumboTabs.defaultProps = {
  tabSpacing: 'normal',
};

type OwnProps = {
  tabs: List<Map<string, any>>;
  tabSpacing?: 'compact' | 'normal';
};

type Props = OwnProps & typeof JumboTabs.defaultProps;

export default function JumboTabs({ tabs, tabSpacing }: Props) {
  const history = useHistory();
  const { location: { pathname } = {} } = history;

  return (
    <Grid container>
      {tabs.map((tab: any) => (
        <Grid
          item
          xs={3}
          sm={3}
          lg={tabSpacing === 'compact' ? 2 : 3}
          key={tab.get('title')}
        >
          <JumboTab
            history={history}
            icon={tab.get('icon')}
            navigateTo={tab.get('navigateTo')}
            // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
            selected={pathname.includes(tab.get('navigateTo'))}
            subtitle={tab.get('subtitle')}
            title={tab.get('title')}
          />
        </Grid>
      ))}
    </Grid>
  );
}
