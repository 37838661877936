import { all, call, fork, put, takeEvery, select } from 'redux-saga/effects';

// action creators
import { doneIndicator } from 'store/actions/httpActions';

// api
import client from 'sources/api';

// constants
import { LABEL_CREATE_REQUEST, LABEL_CREATE } from 'store/constants/labelTypes';

// selectors
import getCurrentGroupId from 'store/selectors/getCurrentGroupId';

// schemas
import { labelSchema } from 'store/schemas/labelSchema';

// helpers
import httpSaga from 'store/sagas/httpSaga';

const WATCH_TYPE = LABEL_CREATE_REQUEST;
const TYPE = LABEL_CREATE;

export function* createLabel(action: any) {
  const {
    payload: { formName, fields },
  } = action || {};

  const currentGroupId = yield select(getCurrentGroupId);

  // @ts-expect-error ts-migrate(2569) FIXME: Type 'Generator<any, any, unknown>' is not an arra... Remove this comment to see the full error message
  yield* httpSaga(
    TYPE,
    call(client.post, `/tags?group_id=${currentGroupId}`, {
      ...fields,
      type: 'group_label',
    }),
    {
      schema: labelSchema,
      formName,
    }
  );

  yield put(doneIndicator(TYPE));
}

export function* watch() {
  yield takeEvery(WATCH_TYPE, createLabel);
}

export default function* root() {
  yield all([fork(watch)]);
}
