import PropTypes from 'prop-types';
import Immutable, { List, Map } from 'immutable';
import React, { useState, useEffect } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
const draggableGrid = 8;
const getItemStyle = (isDragging: any, draggableStyle: any) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  margin: `0 0 ${draggableGrid * 1.25}px 0`,
  // styles we need to apply on draggables
  ...draggableStyle,
});
const getListStyle = () => ({
  padding: draggableGrid,
  width: '100%',
  borderRadius: 5,
});
const DraggableList = function({ fields, initialItems, itemComponent }: any) {
  const [items, setItems] = useState(List());
  useEffect(() => {
    if (initialItems) {
      setItems(initialItems);
    }
  }, [initialItems]);
  const onDragEnd = (result: any) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    // Some list items are fixed to the top of the list and not draggable
    //   We want to make sure no item is placed above them
    const previousItemDragDisabled = getDragDisabled(result.destination.index);
    if (previousItemDragDisabled) {
      return;
    }
    fields.move(result.source.index, result.destination.index);
  };
  const getDragDisabled = (index: any) => {
    const item = items.get(index, Map()) as any;
    if (!item.get('draggable') && item.has('draggable')) {
      return true;
    }
    return false;
  };
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId='droppable'>
        {(providedDrop, snapshotDrop) => (
          <div
            ref={providedDrop.innerRef}
            // @ts-expect-error ts-migrate(2554) FIXME: Expected 0 arguments, but got 1.
            style={getListStyle(snapshotDrop.isDraggingOver)}
          >
            {fields.map((field: any, index: any) => (
              <Draggable
                key={field}
                draggableId={field}
                isDragDisabled={getDragDisabled(index)}
                index={index}
              >
                {(providedDrag, snapshotDrag) => (
                  <div>
                    <div
                      ref={providedDrag.innerRef}
                      {...providedDrag.draggableProps}
                      {...providedDrag.dragHandleProps}
                      style={getItemStyle(
                        snapshotDrag.isDragging,
                        providedDrag.draggableProps.style
                      )}
                    >
                      {React.cloneElement(itemComponent, { field, index })}
                    </div>
                    {(providedDrag as any).placeholder}
                  </div>
                )}
              </Draggable>
            ))}
            {providedDrop.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};
DraggableList.propTypes = {
  fields: PropTypes.instanceOf(Object).isRequired,
  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'typeof List' is not assignable t... Remove this comment to see the full error message
  initialItems: PropTypes.instanceOf(Immutable.List),
  itemComponent: PropTypes.instanceOf(Object),
};
DraggableList.defaultProps = {
  initialItems: List(),
  itemComponent: <div></div>,
};
export default DraggableList;
