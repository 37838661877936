import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';

// MUI components
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Card, Grid, useMediaQuery } from '@material-ui/core';

// components
import ThreadList from './ThreadList';
import MessagesContent from './MessagesContent';

// action creators
import {
  startListeningForThreadsAction,
  stopListeningForThreadsAction,
  clearThreadsAction,
} from 'store/actions/threadActions';
import { fetchPhoneNumbersForGroup as fetchPhoneNumbersForGroupAction } from 'store/actions/phoneNumberActions';

const Messages = function() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const searchParam = queryParams.get('search');
  const theme = useTheme();
  const isExtraSmall = useMediaQuery(theme.breakpoints.only('xs'));
  const [messageView, setMessageView] = useState('list');
  useEffect(() => {
    dispatch(stopListeningForThreadsAction());
    dispatch(startListeningForThreadsAction({ search: searchParam }));
    dispatch(fetchPhoneNumbersForGroupAction());
  }, [searchParam]); // eslint-disable-line

  useEffect(() => {
    dispatch(clearThreadsAction());
  }, [dispatch]);

  return (
    <div className={classes.container}>
      <Card className={classes.messagesCard}>
        <Grid container className={classes.messagesContainer}>
          {(!isExtraSmall || (isExtraSmall && messageView === 'list')) && (
            <Grid
              item
              xs={12}
              sm={5}
              lg={4}
              xl={3}
              className={classes.messagesListContainer}
            >
              <ThreadList setMessageView={setMessageView} />
            </Grid>
          )}

          {(!isExtraSmall || (isExtraSmall && messageView !== 'list')) && (
            <Grid item xs={12} sm={7} lg={8} xl={9} id='messagesContent'>
              <MessagesContent setMessageView={setMessageView} />
            </Grid>
          )}
        </Grid>
      </Card>
    </div>
  );
};
const useStyles = makeStyles(theme => {
  const border = `2px solid ${(theme.palette.background as any).darkPaper}`;
  return {
    container: {
      height: '100%',
      overflowY: 'scroll',
      overflowX: 'hidden',
      paddingTop: 24,
      paddingBottom: 24,
    },
    messagesCard: {
      height: '100%',
    },
    messagesContainer: {
      height: '100%',
    },
    messagesListContainer: {
      borderRight: border,
    },
  };
});
export default Messages;
