import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import Immutable, { List, Map } from 'immutable';

// MUI components
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';

// components
import AutoSuggestButtons from './AutoSuggestButtons';
import AutoSuggestInput from './AutoSuggestInput';
import AutoSuggestChips from './AutoSuggestChips';

const AutoSuggest = function({
  allowSelectAll,
  chipsLabel,
  defaultSuggestion,
  formValues,
  getAllSuggestions,
  helperText,
  input,
  label,
  loading,
  multiSelect,
  multiSelectLabel,
  onSearchChange,
  placeholder,
  suggestions,
  variant,
  callSearchChangeWhenNull = false,
  ...restOfProps
}: any) {
  const classes = useStyles();

  const { value = [], name } = input;

  const initialSelectedItems = Immutable.fromJS(value || []) as any;

  const [timerId, setTimerId] = useState(null);
  const [selectedItems, setSelectedItems] = useState(initialSelectedItems);

  useEffect(() => {
    if (multiSelect) {
      let selectedItem = Map();

      if (Map.isMap(formValues)) {
        const itemValue = formValues.get(name, {}) as any;

        if (typeof itemValue === 'object') {
          selectedItem = Map(itemValue);
        } else {
          selectedItem = Map({
            ...itemValue,
          });
        }
      }

      const alreadySelected = selectedItems.includes(selectedItem);

      if (selectedItem.get('value') && !alreadySelected) {
        setSelectedItems(selectedItems.push(selectedItem));
      }
    }
  }, [formValues, selectedItems, name, input, multiSelect]);

  useEffect(() => {
    if (input.onChange && multiSelect && selectedItems) {
      input.onChange(selectedItems.toJS());
    }
  }, [selectedItems]); //eslint-disable-line

  const handleUpdateSuggestions = useCallback(
    ({ value }) => {
      if (value.length > 1 || callSearchChangeWhenNull) {
        if (timerId) {
          clearTimeout(timerId as any);
        }

        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'number' is not assignable to par... Remove this comment to see the full error message
        setTimerId(setTimeout(onSearchChange, 500, value));
      }
    },
    [onSearchChange, callSearchChangeWhenNull, timerId]
  );

  const handleClearSuggestions = useCallback(() => {
    onSearchChange(null);
  }, [onSearchChange]);

  const handleSelectAll = useCallback(() => {
    setSelectedItems(getAllSuggestions());
  }, [getAllSuggestions]);

  const handleClearAll = useCallback(() => {
    input.onChange();

    setSelectedItems(List());
  }, [input]);

  const handleDeleteChip = useCallback(
    index => {
      input.onChange({});
      const newSelected = selectedItems.splice(index, 1);

      setSelectedItems(newSelected);
    },
    [input, selectedItems]
  );

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} className={classes.autoSuggestContainer}>
        <AutoSuggestInput
          defaultSuggestion={defaultSuggestion}
          helperText={helperText}
          input={input}
          loading={loading}
          multiSelect={multiSelect}
          onSuggestionsClearRequested={handleClearSuggestions}
          onSuggestionsFetchRequested={handleUpdateSuggestions}
          placeholder={placeholder}
          suggestions={suggestions}
          variant={variant}
          label={label}
          {...restOfProps}
        />
      </Grid>

      {multiSelect && multiSelectLabel && (
        <Grid item xs={12}>
          <Typography variant='subtitle2'>{multiSelectLabel}</Typography>
        </Grid>
      )}

      {multiSelect && (
        <Grid item xs={12}>
          <AutoSuggestChips
            selected={selectedItems}
            chipsLabel={chipsLabel}
            deleteChip={handleDeleteChip}
          />
        </Grid>
      )}
      {multiSelect && allowSelectAll && (
        <Grid item xs={12}>
          <AutoSuggestButtons
            onSelectAll={handleSelectAll}
            onClearAll={handleClearAll}
            hasSelectedItems={selectedItems.size > 0}
          />
        </Grid>
      )}
    </Grid>
  );
};

AutoSuggest.propTypes = {
  allowSelectAll: PropTypes.bool,
  autoSuggestType: PropTypes.string,
  chipsLabel: PropTypes.string,
  defaultSuggestion: PropTypes.instanceOf(Object),
  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'typeof Map' is not assignable to... Remove this comment to see the full error message
  formValues: PropTypes.instanceOf(Map).isRequired,
  getAllSuggestions: PropTypes.func,
  helperText: PropTypes.string,
  input: PropTypes.instanceOf(Object),
  label: PropTypes.string,
  loading: PropTypes.bool,
  multiSelect: PropTypes.bool,
  multiSelectLabel: PropTypes.string,
  onSearchChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  suggestions: PropTypes.instanceOf(Array).isRequired,
  variant: PropTypes.string,
};

AutoSuggest.defaultProps = {
  allowSelectAll: false,
  defaultSuggestion: {},
  formValues: Map(),
  getAllSuggestions: () => {},
  handleClearSelected: null,
  handleSelectAll: null,
  helperText: '',
  input: {},
  label: '',
  loading: false,
  multiSelect: false,
  multiSelectLabel: '',
  placeholder: '',
  variant: 'outlined',
};

const useStyles = makeStyles(theme => ({
  autoSuggestContainer: {
    alignItems: 'center',
    paddingBottom: 0,
  },
}));

export default AutoSuggest;
