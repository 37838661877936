import React, { useCallback, useEffect, useMemo } from 'react';
import { Route, Switch, Redirect, useHistory } from 'react-router-dom';
import { getAnalyticsTabs } from './getAnalyticsTabs';
import { useSelector } from 'react-redux';
import { isEqual } from 'lodash';

// MUI components
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Tab, Tabs, Box } from '@material-ui/core';

// selectors
import getFeatures from 'store/selectors/getFeatures';
import getIsSystemAdmin from 'store/selectors/getIsSystemAdmin';
import getIsHqAdmin from 'store/selectors/getIsHqAdmin';
import getIsCouncilAdmin from 'store/selectors/getIsCouncilAdmin';
import getIsCurrentGroupCouncil from 'store/selectors/getIsCurrentGroupCouncil';
import getIsCurrentGroupOrganization from 'store/selectors/getIsCurrentGroupOrganization';

const Analytics = function() {
  const classes = useStyles();
  const history = useHistory();
  const {
    location: { pathname = '', search },
  } = history;
  const formattedPathname = pathname.replace('*', 'group');
  const {
    features,
    roleId,
    isSystemAdmin,
    isHqAdmin,
    isCurrentGroupCouncil,
    isCurrentGroupOrganization,
    isCouncilAdmin,
  } = useSelector(
    state => ({
      features: getFeatures(state),
      roleId: (state as any).getIn(['currentUser', 'data', 'roleId']),
      isSystemAdmin: getIsSystemAdmin(state),
      isHqAdmin: getIsHqAdmin(state),
      isCurrentGroupCouncil: getIsCurrentGroupCouncil(state),
      isCurrentGroupOrganization: getIsCurrentGroupOrganization(state),
      isCouncilAdmin: getIsCouncilAdmin(state),
    }),
    isEqual
  );
  const tabs = useMemo(() => {
    const allTabs = getAnalyticsTabs({
      features,
      roleId,
      isSystemAdmin,
      isHqAdmin,
      isCurrentGroupCouncil,
      isCouncilAdmin,
      isCurrentGroupOrganization,
    });
    return allTabs.filter((t: any) => t.enabled);
  }, [
    features,
    isHqAdmin,
    isSystemAdmin,
    isCurrentGroupCouncil,
    isCouncilAdmin,
    isCurrentGroupOrganization,
    roleId,
  ]);
  useEffect(() => {
    if (pathname === '/analytics') {
      history.replace({ pathname: '/analytics/group', search });
    }
  }, [history, pathname, search]);
  const handleChangeTab = useCallback(
    (_, value) => {
      history.push({ pathname: value, search });
    },
    [history, search]
  );
  return (
    <Box position='relative'>
      <Box position='absolute' width='100%'>
        <Grid container className={classes.container}>
          <Grid item xs={12}>
            <Tabs
              className={classes.tabBar}
              id='tabBar'
              classes={{ indicator: classes.indicator }}
              value={formattedPathname}
              onChange={handleChangeTab}
              variant='scrollable'
              scrollButtons='auto'
              indicatorColor='primary'
              textColor='primary'
              aria-label='settings tabs'
            >
              {tabs.map(({ route, label, icon, id }: any) => (
                <Tab id={id} key={id} label={label} icon={icon} value={route} />
              ))}
            </Tabs>

            <Grid container className={classes.tabContentContainer}>
              {tabs && (
                <Switch>
                  {tabs.map(({ route, component }: any) => (
                    <Route key={route} path={route} component={component} />
                  ))}

                  <Redirect
                    to={{
                      pathname: tabs[0].route,
                      search,
                    }}
                  />
                </Switch>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};
const useStyles = makeStyles(theme => ({
  container: {
    paddingTop: 24,
    paddingBottom: 24,
  },
  tabBar: {
    borderRadius: 8,
    backgroundColor: theme.palette.background.paper,
    borderBottom: `2px solid ${(theme.palette.background as any).darkPaper}`,
    zIndex: 100,
    [theme.breakpoints.down('xs')]: {
      backgroundColor: theme.palette.background.paper,
    },
  },
  indicator: {
    display: 'none',
  },
  tabContentContainer: {
    marginTop: 16,
  },
}));
export default Analytics;
