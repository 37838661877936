import { Styles } from 'assets/types';

const iconSize = 28;

const labelsCellStyles: Styles = theme => ({
  labelContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: iconSize,
    height: iconSize,
    borderRadius: 100,
    border: '2px solid white',
    transition: '0.2s ease',
    '&.disabled': {
      opacity: 0.8,
      cursor: 'auto',
      '& svg': {
        display: 'none',
      },
    },
  },
  deleteIcon: {
    height: 20,
    width: 20,
    opacity: 0,
    '&:hover': {
      transition: '0.2s ease',
      opacity: 1,
    },
  },
  addButton: {
    height: iconSize,
    width: iconSize,
  },
  gridBox: {
    paddingTop: 5,
    paddingBottom: 5,
    justifyContent: 'flex-start',
  },
  gridIn: {
    padding: '2px 1px',
    flexGrow: 'unset',
  },
});

export default labelsCellStyles;
