import React, { useCallback, useState, useEffect, useMemo } from 'react';
import validate from 'helpers/validate';
import { Map } from 'immutable';
import { isEqual } from 'lodash';

//helpers
import formatDisplayedPhoneNumber from 'helpers/formatDisplayedPhoneNumber';
import { isEmpty } from 'helpers/check';

// hooks
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useLeadQuery, useUpdateLeadMutation } from 'api/leads';

// components
import DazzlingDialog from 'components/DazzlingDialog';
import TextField from 'components/TextField';
import StatusSelector from 'components/StatusSelector';
import FileInputField from 'components/FileInputField';
import ReferralSourceSelector from 'components/ReferralSourceSelector';
import SuperAvatar from 'components/SuperAvatar';
import { Field, Form } from 'react-final-form';

// material-ui
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import useMediaQuery from '@material-ui/core/useMediaQuery';

// icons
import { Edit } from '@material-ui/icons';

// action creators
import { createAndUploadFile } from 'store/actions/fileActions';

// selectors
import getLoadingFromState from 'store/selectors/getLoadingFromState';
import getSliceEntityById from 'store/selectors/getSliceEntityById';

// schemas
const SCHEMA = {
  emailAddress: {
    email: { message: 'not valid' },
    length: { maximum: 255 },
  },
  firstName: {
    presence: true,
    length: { maximum: 255 },
  },
  lastName: {
    presence: true,
    length: { maximum: 255 },
  },
  phoneNumber: {
    validPhoneNumber: true,
  },
};

const IMAGE_FORM = 'leadImageForm';

type UpdateLeadInfoModalProps = {
  open: boolean;
  onClose: (...args: any[]) => any;
};

type UpdateLeadFormFields = {
  firstName: string;
  lastName: string;
  emailAddress?: string;
  phoneNumber?: string;
  statusId?: number;
  profilePhotoFileId?: number;
  leadReferralSourceIds: number | number[];
};

export default function UpdateLeadInfoModal({
  open,
  onClose,
}: UpdateLeadInfoModalProps) {
  const { leadId } = useParams<{ leadId: string }>();
  const classes = useStyles();
  const theme = useTheme();
  const isExtraSmall = useMediaQuery(theme.breakpoints.only('xs'));
  const dispatch = useDispatch();
  const {
    isLoading,
    isSuccess,
    isError,
    error,
    mutate: updateLead,
    reset: resetUpdateLeadMutation,
  } = useUpdateLeadMutation();
  const { data: lead } = useLeadQuery(Number(leadId));

  // grab data from state
  const { fileLoading, leadReferralSources = Map() } = useSelector(
    state => ({
      leadReferralSources: getSliceEntityById(
        'leadReferralSource',
        leadId
      )(state),
      fileLoading: getLoadingFromState('file', false, false)(state),
    }),
    isEqual
  );

  // initalize component state
  const [imageId, setImageId] = useState(lead?.profilePhotoFileId);
  const [isFileConverting, setIsFileConverting] = useState(false);

  useEffect(() => {
    setImageId(lead?.profilePhotoFileId);
  }, [lead?.profilePhotoFileId]);

  const dispatchCreateAndUploadFile = (file: any) => {
    dispatch(createAndUploadFile({ file, formName: IMAGE_FORM }));
  };

  const leadReferralSourceIds = useMemo<number[]>(
    () =>
      Object.values(leadReferralSources).map(
        (item = {}) => (item as any).referralSourceId as number
      ),
    [leadReferralSources]
  );

  const handleSubmit = useCallback(
    ({
      leadReferralSourceIds: newReferralSources,
      ...newLeadValues
    }: UpdateLeadFormFields) => {
      const newLeadReferralSourceIds = Array.isArray(newReferralSources)
        ? newReferralSources
        : [newReferralSources];
      const removedReferralSourceIds = leadReferralSourceIds.filter(
        id => !newLeadReferralSourceIds.includes(id)
      );
      const addedReferralSourceIds = newLeadReferralSourceIds.filter(
        id => !leadReferralSourceIds.includes(id)
      );
      const updatedFields = {};
      for (const key in lead) {
        //make sure we don't drop any keys that may have been deleted
        if (!(key in newLeadValues)) {
          (updatedFields as any)[key] = null;
          //make sure the newLeadValues hasn't changed from the initialvalues.
        } else if ((newLeadValues as any)[key] !== (lead as any)[key]) {
          // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          updatedFields[key] = newLeadValues[key];
        }
      }
      updateLead({
        id: Number(leadId),
        removedReferralSourceIds,
        addedReferralSourceIds,
        ...updatedFields,
      });
    },
    [leadId, leadReferralSourceIds, lead, updateLead]
  );

  const handleFileChange = ({ filePreview, newFileId }: any) => {
    setImageId(newFileId);
  };

  const handleFileConverting = (newIsFileConverting: boolean) => {
    setIsFileConverting(newIsFileConverting);
  };

  const validateLeadInfo = useCallback(
    values => validate(values, SCHEMA) || error?.response?.data,
    [error]
  );
  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={{
        ...lead,
        phoneNumber: formatDisplayedPhoneNumber(lead?.phoneNumber),
        emailAddress: !isEmpty(lead?.emailAddress) ? lead?.emailAddress : null,
        leadReferralSourceIds,
      }}
      keepDirtyOnReinitialize
      initialValuesEqual={isEqual}
      validate={validateLeadInfo}
      render={({ handleSubmit, invalid, pristine }) => (
        <DazzlingDialog
          id='updateLeadModal'
          isLoading={isLoading}
          isSuccess={isSuccess}
          isError={isError}
          alertProps={{ message: 'Lead Updated Successfully' }}
          disabled={invalid || pristine || fileLoading}
          handleClose={onClose}
          open={open}
          onAccept={handleSubmit}
          headerProps={{
            icon: Edit,
            title: 'Update Info',
            highlightedSubtitle: 'Edit basic information for a lead',
          }}
          TransitionProps={{
            onExited: () => {
              resetUpdateLeadMutation();
            },
          }}
          acceptLabel='Update'
          submitOnEnter
        >
          <Grid container spacing={2}>
            <Grid item xs={12} className={classes.imageContainer}>
              <Grid
                container
                justifyContent='center'
                alignItems='center'
                spacing={2}
              >
                <Grid item xs={3}>
                  <SuperAvatar
                    size={isExtraSmall ? 'xlarge' : '2xlarge'}
                    background='dark'
                    loading={fileLoading || isFileConverting}
                    fullName={lead?.fullName}
                    imageId={imageId}
                  />
                </Grid>

                <Grid item xs={9}>
                  <Field
                    name='profilePhotoFileId'
                    render={props => (
                      <FileInputField
                        // @ts-expect-error ts-migrate(2322) FIXME: Type '{ input: FieldInputProps<any, HTMLElement>; ... Remove this comment to see the full error message
                        fileType='image'
                        formName={IMAGE_FORM}
                        onChange={handleFileChange}
                        onSubmit={dispatchCreateAndUploadFile}
                        onFileConverting={handleFileConverting}
                        buttonLabel='Upload Lead Picture'
                        {...props}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Field<string>
                name='firstName'
                component={TextField}
                label='First Name'
                margin='none'
                required
              />
            </Grid>
            <Grid item xs={6}>
              <Field<string>
                name='lastName'
                component={TextField}
                label='Last Name'
                margin='none'
                required
              />
            </Grid>
            <Grid item xs={12} sm={8}>
              <Field<string>
                name='emailAddress'
                component={TextField}
                label='Email'
                type='email'
                margin='none'
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Field<string>
                name='phoneNumber'
                render={props => (
                  <TextField
                    label='Phone Number'
                    type='tel'
                    margin='none'
                    {...props}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Field<number[]>
                name='leadReferralSourceIds'
                isEqual={isEqual}
                component={ReferralSourceSelector}
                label='Referral Sources'
                margin='none'
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                name='statusId'
                component={StatusSelector}
                label='Status'
                margin='none'
              />
            </Grid>
          </Grid>
        </DazzlingDialog>
      )}
    />
  );
}

const useStyles = makeStyles(theme => ({
  imageContainer: {
    marginBottom: 20,
  },
}));
