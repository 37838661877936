import { all, call, fork, put, takeEvery, select } from 'redux-saga/effects';
import Immutable from 'immutable';

// action creators
import { doneIndicator, success } from 'store/actions/httpActions';

// selectors
import getCurrentGroupId from 'store/selectors/getCurrentGroupId';

// api
import client from 'sources/api';

// constants
import {
  LEAD_TAG_FETCH_REQUEST,
  LEAD_TAG_FETCH,
} from 'store/constants/leadTagTypes';

// schemas
import { leadTagSchema } from 'store/schemas/leadTagSchema';
import getResponsePayload from 'helpers/getResponsePayload';

// helpers
import httpSaga from 'store/sagas/httpSaga';

const WATCH_TYPE = LEAD_TAG_FETCH_REQUEST;
const TYPE = LEAD_TAG_FETCH;

export function* fetchLeadTags(action: any) {
  const {
    payload: { id },
  } = action;

  const currentGroupId = yield select(getCurrentGroupId);

  // @ts-expect-error ts-migrate(2569) FIXME: Type 'Generator<any, any, unknown>' is not an arra... Remove this comment to see the full error message
  const response = yield* httpSaga(
    TYPE,
    call(client.get, `/pnm_tags?group_id=${currentGroupId}&pnm_id=${id}`),
    {
      dispatchSuccess: false,
      schema: leadTagSchema,
      metaId: id,
    }
  );

  const payload = getResponsePayload(response, leadTagSchema);
  // @ts-expect-error ts-migrate(2538) FIXME: Type 'undefined' cannot be used as an index type.
  payload.data.entities.leadTag[id] = payload.data.entities.leadTag[undefined];
  // @ts-expect-error ts-migrate(2538) FIXME: Type 'undefined' cannot be used as an index type.
  delete payload.data.entities.leadTag[undefined];
  payload.data.result = [id];

  yield put(success(TYPE, Immutable.fromJS(payload), id));
  yield put(doneIndicator(TYPE));
}

export function* watch() {
  yield takeEvery(WATCH_TYPE, fetchLeadTags);
}

export default function* root() {
  yield all([fork(watch)]);
}
