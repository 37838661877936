export default function getStatusFromState(
  slice: any,
  metaId?: any,
  defaultValue?: any
) {
  const key = metaId ? `${metaId}-status` : 'status';

  return (state: any) => {
    return state.getIn([slice, 'meta', key]) || defaultValue;
  };
}
