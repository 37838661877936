import { Map } from 'immutable';
import { memoize } from 'lodash';
import { createSelector } from 'reselect';

const modify = memoize(
  (selected, defaultValue) => selected?.toJS() || defaultValue
);

const getSelected = (state: any, slice: any, id: any) => {
  let entity = state.getIn([slice, 'data', 'byId', id?.toString()], Map());
  const profilePhotoFileId = entity.get('profilePhotoFileId', null);
  const url = state.getIn(
    ['file', 'data', 'byId', profilePhotoFileId?.toString(), 'url'],
    null
  );
  if (url) {
    entity = entity.set('profilePhotoFileId', Map({ url }));
  }
  return entity;
};

export default function getSliceEntityById(
  slice: any,
  id: any,
  defaultValue = {}
) {
  return createSelector(
    state => getSelected(state, slice, id),
    selected => modify(selected, defaultValue)
  );
}
