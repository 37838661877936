import { Map } from 'immutable';
// constants
import {
  REQUEST_FETCH_FOR_USER,
  REQUEST_FETCH_FOR_GROUP,
  REQUEST_CREATE,
  REQUEST_UPDATE,
  REQUEST_DELETE,
} from 'store/constants/requestTypes';
// helpers
import httpReducer from './helpers/httpReducer';
import remove from './callbacks/remove';

export default function reduceRequest(state = Map(), action = {}) {
  switch ((action as any).type) {
    case REQUEST_FETCH_FOR_USER:
    case REQUEST_FETCH_FOR_GROUP:
    case REQUEST_CREATE:
      return httpReducer(state, action, {
        entity: 'request',
      });
    case REQUEST_UPDATE:
    case REQUEST_DELETE:
      return httpReducer(state, action, {
        entity: 'request',
        callback: remove,
      });
    default:
      return state;
  }
}
