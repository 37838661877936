export default function getResultKey(type: any) {
  switch (type) {
    default:
    case 1:
      return 'result';
    case 2:
      return 'greekChapterResult';
    case 3:
      return 'greekOrganizationResult';
    case 4:
      return 'councilResult';
  }
}
