import { toUpperCase, toString } from 'helpers/transform';

export const ORGANIZATION_FOCUS: { [key: string]: string } = {
  1: 'Asian-Interest',
  2: 'Hispanic-Interest',
  3: 'Religious-Interest',
  4: 'LGBTQ+',
  5: 'Multicultural',
  6: 'Service-Specific',
  7: 'Historically Black',
  11: 'Business',
  12: 'Music',
  13: 'Law',
  14: 'Engineering',
  15: 'Agriculture',
  16: 'Medicine',
  17: 'Military',
  18: 'Performing Arts',
  19: 'Social',
};

export const ORGANIZATION_FOCUS_CULTURALLY_BASED = [1, 2, 3, 4, 5, 6, 7];

export const getOrganizationFocusAsArray = (): string[] => {
  const values = [];
  for (const key in ORGANIZATION_FOCUS) {
    values.push(ORGANIZATION_FOCUS[key]);
  }

  return values;
};

export const getOrganizationFocusById = (
  id: string | number
): string | undefined => ORGANIZATION_FOCUS?.[toString(id)];

export const getOrganizationFocusId = (focus: string): string | undefined => {
  const value = toUpperCase(focus);
  for (const key in ORGANIZATION_FOCUS) {
    if (ORGANIZATION_FOCUS[key].toUpperCase() === value) {
      return key;
    }
  }

  return;
};

export const isOrganizationFocusCulturallyBased = (
  id?: number | string,
  value?: string
): boolean => {
  const key = id
    ? getOrganizationFocusById(id)
    : value
    ? getOrganizationFocusId(value)
    : null;

  return key
    ? !!ORGANIZATION_FOCUS_CULTURALLY_BASED.find(item => toString(item) === key)
    : false;
};
