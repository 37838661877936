import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Field } from 'react-final-form';
import { Map } from 'immutable';
import TextField from 'components/TextField';
import formFeesItemStyle from 'routes/PaymentSettings/FormFeesCard/FormFeesItem/formFeesItemStyle.style';
import getReferralForms from 'store/selectors/getReferralForms';
import { Grid, WithStyles, withStyles } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';

export interface FormFeesItemProps
  extends WithStyles<typeof formFeesItemStyle> {
  prefix?: string;
  formFees?: FormFee[];
  values?: FormFee | null;
  isFormIdDisabled?: boolean;
}

const FormFeesItem = ({
  prefix,
  formFees,
  values,
  isFormIdDisabled,
}: FormFeesItemProps) => {
  const { forms } = useSelector(state => ({
    forms: getReferralForms(state) || Map(),
  }));

  const { formId: valuesFormId } = values || {};

  const formFeesId = useMemo(() => {
    if (!formFees) {
      return [];
    }
    const formFeesWithoutCurrent = valuesFormId
      ? formFees.filter(({ formId }) => formId !== valuesFormId)
      : formFees;

    return formFeesWithoutCurrent.map(({ formId }) => formId);
  }, [formFees, valuesFormId]);

  const options = forms
    .filter(
      (form: Map<'id', number>) =>
        formFeesId.indexOf(form?.get('id') || 0) === -1
    )
    .map((form: Map<'title' | 'id', any>) => ({
      label: form.get('title'),
      value: form.get('id'),
    }));

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={4}>
        <Field
          name={prefix ? `${prefix}.title` : 'title'}
          label='Fee Name'
          render={props => <TextField {...props} />}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <Field
          name={prefix ? `${prefix}.amount` : 'amount'}
          label='Fee Amount'
          render={props => (
            <TextField
              {...props}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>$</InputAdornment>
                ),
              }}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={5}>
        <Field
          name={prefix ? `${prefix}.formId` : 'formId'}
          label='Form Fee is Connected To'
          render={props => <TextField {...props} select options={options} />}
          {...(isFormIdDisabled ? { disabled: true } : {})}
        />
      </Grid>
    </Grid>
  );
};

export default withStyles(formFeesItemStyle)(FormFeesItem);
