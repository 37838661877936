import { all, call, fork, takeEvery, put, select } from 'redux-saga/effects';

// action creators
import { doneIndicator } from 'store/actions/httpActions';

// api
import client from 'sources/api';

// selectors
import getCurrentGroupId from 'store/selectors/getCurrentGroupId';

// constants
import { VOTE_UPDATE_REQUEST, VOTE_UPDATE } from 'store/constants/voteTypes';

// lib
import httpSaga from 'store/sagas/httpSaga';

// schemas
import { voteSchema } from 'store/schemas/voteSchema';

const WATCH_TYPE = VOTE_UPDATE_REQUEST;
const TYPE = VOTE_UPDATE;

export function* updateVote(action: any) {
  const {
    payload: {
      id,
      leadId: lead_id,
      fields: { value, comment },
      formName,
    },
  } = action;

  const currentGroupId = yield select(getCurrentGroupId);

  // @ts-expect-error ts-migrate(2569) FIXME: Type 'Generator<any, any, unknown>' is not an arra... Remove this comment to see the full error message
  yield* httpSaga(
    TYPE,
    call(
      client.put,
      `/votes/${id}?group_id=${currentGroupId}&lead_id=${lead_id}`,
      {
        value,
        comment,
      }
    ),
    {
      schema: voteSchema,
      formName,
    }
  );

  yield put(doneIndicator(TYPE));
}

export function* watch() {
  yield takeEvery(WATCH_TYPE, updateVote);
}

export default function* root() {
  yield all([fork(watch)]);
}
