import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

// action creators
import { doneIndicator } from 'store/actions/httpActions';

// api
import client from 'sources/api';

// constants
import {
  CURRENT_USER_UPDATE_PASSWORD,
  CURRENT_USER_UPDATE_PASSWORD_REQUEST,
} from 'store/constants/userTypes';

// lib
import httpSaga from 'store/sagas/httpSaga';

// helpers
import isNamesListSite from 'helpers/isNamesListSite';

const WATCH_TYPE = CURRENT_USER_UPDATE_PASSWORD_REQUEST;
const TYPE = CURRENT_USER_UPDATE_PASSWORD;
const FORM = 'updatePasswordForm';

export function* updateCurrentUserPassword(action: any) {
  const {
    payload: { currentPassword: current_password, newPassword: new_password },
  } = action;

  // @ts-expect-error ts-migrate(2569) FIXME: Type 'Generator<any, any, unknown>' is not an arra... Remove this comment to see the full error message
  yield* httpSaga(
    TYPE,
    call(client.post, '/auth/reset_password', {
      current_password,
      new_password,
      is_nameslist: isNamesListSite(),
    }),
    {
      formName: FORM,
    }
  );

  yield put(doneIndicator(TYPE));
}

export function* watch() {
  yield takeEvery(WATCH_TYPE, updateCurrentUserPassword);
}

export default function* root() {
  yield all([fork(watch)]);
}
