import React, { useEffect, useState, useCallback, useMemo, memo } from 'react';
import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form';
import Immutable from 'immutable';
import { isEqual } from 'lodash';

// hooks
import { usePrevious } from 'helpers/hooks/usePrevious';
import { useHistory } from 'react-router-dom';
import { useLeadsQuery } from 'api/leads';
import useDebounce from 'helpers/hooks/useDebounce';

// MUI components
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Hidden, IconButton } from '@material-ui/core';
import { ArrowBack, AddCircle } from '@material-ui/icons';

// components
import AutoSuggest from 'components/AutoSuggest';
import TextField from 'components/TextField';
import Button from 'components/Button';

const NewMessageHeader = function({
  handleSetRecipients,
  handleSetTemplateText,
  recipients,
  setMessageView,
  messageCreating,
  messageStatus,
  setLeadsCount,
}: any) {
  const classes = useStyles();
  let resetForm: any;
  const [initialRecipients, setInitialRecipients] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [maxTotalLeadSize, setMaxTotalLeadSize] = useState<number>(50);
  const debouncedSearch = useDebounce(searchValue, 600);
  const {
    data: { leads = [], total: totalLeads = 0 } = {},
    isLoading: searchingLeads,
  } = useLeadsQuery({ search: debouncedSearch, perPage: maxTotalLeadSize });

  const leadSuggestions = useMemo(() => {
    const leadsWithPhoneNumber = leads.filter(lead => lead.phoneNumber);
    let leadSuggestions = leadsWithPhoneNumber.map(lead => ({
      label: lead.fullName,
      value: lead.id.toString(),
    }));
    leadSuggestions.unshift({
      value: 'all',
      label: 'All leads',
    });
    return leadSuggestions;
  }, [leads]);

  const previousMessageCreating = usePrevious(messageCreating);
  const allSelected = useMemo(() => recipients && recipients.includes('all'), [
    recipients,
  ]);

  const history = useHistory();
  const {
    location: { pathname, search, state = {} },
  } = history;

  // @ts-expect-error ts-migrate(2339) FIXME: Property 'recipients' does not exist on type 'unkn... Remove this comment to see the full error message
  const { recipients: routerRecipients = [] } = state;

  useEffect(() => {
    if (
      previousMessageCreating &&
      !messageCreating &&
      messageStatus === 'success'
    ) {
      resetForm();
    }
  }, [messageCreating, messageStatus, previousMessageCreating, resetForm]);

  useEffect(() => {
    if (routerRecipients.length) {
      setInitialRecipients(routerRecipients);
      handleSetRecipients({ recipients: routerRecipients });
    }
  }, []); // eslint-disable-line

  useEffect(() => {
    if (!searchingLeads && totalLeads && totalLeads > maxTotalLeadSize) {
      setMaxTotalLeadSize(totalLeads);
    }
  }, [totalLeads, maxTotalLeadSize, searchingLeads]);

  useEffect(() => {
    // Clear out the memory history's initial recipients once we've received them and initialized the form
    if (initialRecipients.length) {
      history.replace({
        pathname,
        search,
        // @ts-expect-error ts-migrate(2698) FIXME: Spread types may only be created from object types... Remove this comment to see the full error message
        state: { ...state, recipients: [] },
      });
    }
  }, [initialRecipients]); // eslint-disable-line

  const onLeadSearchChange = useCallback(search => setSearchValue(search), []);

  const insertOptions = useMemo(() => {
    return [
      {
        label: 'Lead First Name',
        value: 'first_name',
      },
      {
        label: 'Lead Full Name',
        value: 'full_name',
      },
    ];
  }, []);

  return (
    <Form
      onSubmit={() => {}}
      initialValues={{ recipients: routerRecipients }}
      keepDirtyOnReinitialize
      initialValuesEqual={isEqual}
      render={({ values = {}, form: { reset } }) => {
        resetForm = reset;
        setLeadsCount(totalLeads);
        handleSetRecipients(values);
        return (
          <Grid
            container
            alignItems='center'
            className={classes.newMessageHeaderContainer}
          >
            <Hidden smUp>
              <Grid item xs={2}>
                <IconButton onClick={() => setMessageView('list')}>
                  <ArrowBack />
                </IconButton>
              </Grid>
            </Hidden>

            <Grid item xs={10} sm={12}>
              <Typography>New Message</Typography>
            </Grid>

            <Grid item xs={12} sm={8} lg={6}>
              <Field
                circularProgressProps={{ disableShrink: true }}
                component={AutoSuggest}
                displayAllSuggestionsOnFocus
                disabled={allSelected}
                formValues={Immutable.fromJS(values)}
                id='leads'
                label='To'
                autocomplete='off'
                loading={searchingLeads}
                multiSelect
                multiSelectLabel='Recipients'
                name='recipients'
                onSearchChange={onLeadSearchChange}
                suggestions={leadSuggestions}
                variant='outlined'
              />
            </Grid>

            <Grid item xs={12}>
              <Grid
                container
                alignItems='center'
                spacing={2}
                className={classes.optionsContainer}
              >
                <Grid item xs={8} sm={4}>
                  <Field
                    name='textToInsert'
                    component={TextField}
                    label='Text To Insert'
                    margin='none'
                    helperText='Inserts at beginning of message'
                    options={insertOptions}
                    select
                  />
                </Grid>
                <Grid item xs={4} sm={8} style={{ marginTop: -22 }}>
                  <Button
                    id='insertButton'
                    startIcon={<AddCircle />}
                    onClick={() => handleSetTemplateText(values)}
                  >
                    Click To Insert
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        );
      }}
    />
  );
};
NewMessageHeader.propTypes = {
  handleSetRecipients: PropTypes.func.isRequired,
  handleSetTemplateText: PropTypes.func.isRequired,
  recipients: PropTypes.instanceOf(Array).isRequired,
  setMessageView: PropTypes.func.isRequired,
  messageCreating: PropTypes.bool,
  messageStatus: PropTypes.string,
  setLeadsCount: PropTypes.func,
};
NewMessageHeader.defaultProps = {
  type: 'thread',
};
const useStyles = makeStyles(theme => {
  const border = `2px solid ${(theme.palette.background as any).darkPaper}`;
  return {
    newMessageHeaderContainer: {
      borderBottom: border,
      padding: 20,
    },
    optionsContainer: {
      marginTop: 20,
    },
  };
});
export default memo(NewMessageHeader);
