import React, { useState, useCallback, useEffect, ReactNode } from 'react';
import classNames from 'classnames';

// material-ui
import MuiTable from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/styles';

// components
import TableToolbar from './components/TableToolbar';
import TableHeader from './components/TableHeader';
import TableRow from './components/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import TableFooterRow from './TableFooterRow';
import MixedLink from 'components/MixedLink';
import SkeletonRow from 'components/Table/SkeletonRow';

// helpers
import {
  getComparator as getDefaultComparator,
  stableSort,
} from 'components/Table/helpers/sortFunctions';

export default function Table({
  CheckboxProps = {},
  HeaderTableCellProps = {},
  SkeletonProps = {},
  TableCellProps = {},
  TableFooterRowProps = {},
  TablePaginationProps = {
    rowsPerPage: 10,
    count: 0,
  },
  TableProps = {},
  TableRowProps = {},
  columns,
  data = [],
  emptyMessage = 'No data found.',
  emptyMessageColor = 'textSecondary',
  fetchSortedData,
  initialOrder = 'asc',
  initialOrderBy = null,
  numberOfSkeletonRows = 0,
  onPageChange,
  options: { enableCheckboxes, enableHeaderCheckbox = true } = {},
  title,
  variant = 'default',
  sortDisabled = false,
  sortingComparator,
}: {
  CheckboxProps?: any; // TODO: PropTypes.instanceOf(Object)
  HeaderTableCellProps?: any; // TODO: PropTypes.instanceOf(Object)
  SkeletonProps?: any; // TODO: PropTypes.instanceOf(Object)
  TableCellProps?: any; // TODO: PropTypes.instanceOf(Object)
  TablePaginationProps?: any; // TODO: PropTypes.instanceOf(Object)
  TableProps?: any; // TODO: PropTypes.instanceOf(Object)
  TableRowProps?: any; // TODO: PropTypes.instanceOf(Object)
  TableFooterRowProps?: any; // TODO: PropTypes.instanceOf(Object)
  columns: any; // TODO: PropTypes.instanceOf(Array)
  data: any; // TODO: PropTypes.instanceOf(Array)
  emptyMessage?: string;
  emptyMessageColor?: any;
  fetchSortedData?: (...args: any[]) => any;
  id?: string;
  initialOrder?: 'asc' | 'desc';
  initialOrderBy?: string | null;
  numberOfSkeletonRows?: number;
  onPageChange?: (...args: any[]) => any;
  options?: {
    enableCheckboxes?: boolean;
    enableHeaderCheckbox?: boolean;
  };
  title?: any;
  variant?: 'default' | 'outlined';
  sortDisabled?: boolean;
  sortingComparator?: (
    order: boolean | string,
    orderBy: string
  ) => (a: any, b: any) => number;
}) {
  const classes = useStyles();
  const [order, setOrder] = useState(initialOrder);
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState(data);
  const [orderBy, setOrderBy] = useState(
    initialOrderBy ? initialOrderBy : (columns[0] as any)?.id
  );

  const getComparator = !!sortingComparator
    ? sortingComparator
    : getDefaultComparator;

  useEffect(() => {
    setRows(data);
  }, [data]); // eslint-disable-line

  const {
    rowsPerPage,
    count,
    paginationType = 'local',
  }: {
    rowsPerPage: number;
    count: number;
    paginationType?: 'local' | 'api';
  } = TablePaginationProps;

  const paginate = (items: any[]) => {
    return items.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  };

  useEffect(() => {
    if (Boolean(count)) {
      let paginatedRows = data;

      // Divide rows into pages data locally
      if (paginationType === 'local') {
        const sortedRows = stableSort(data, getComparator(order, orderBy));
        paginatedRows = paginate(sortedRows);
        // Handle sort with local data
      }
      setRows(paginatedRows);
    }
  }, [count, order, orderBy, page, data]); // eslint-disable-line

  // Paginate locally through report data
  const handlePageChange = useCallback((event, newPage) => {
    setPage(newPage);
  }, []);

  useEffect(() => {
    // update parent with new rows
    if (onPageChange) {
      const sortedRows = stableSort(data, getComparator(order, orderBy));
      let paginatedRows = sortedRows;

      // Divide rows into pages data locally
      if (paginationType === 'local') {
        paginatedRows = paginate(sortedRows);
      }

      // handles populating row data in the parent or fetching next page of data
      onPageChange(paginatedRows, page);
    }
  }, [page]); // eslint-disable-line

  const handleRequestSort = useCallback(
    (event, property) => {
      const isDesc =
        orderBy !== property || (orderBy === property && order === 'desc');
      const newOrder = isDesc ? 'asc' : 'desc';
      setOrder(newOrder);
      setOrderBy(property);
      if (typeof fetchSortedData === 'function') {
        // Handle sort via API / parent
        fetchSortedData(property, isDesc);
      }
    },
    [fetchSortedData, order, orderBy]
  );
  const { tableClassName, ...restOfTableProps } = TableProps;
  const { hasLinkTo } = TableRowProps;
  const linkComponent: {
    component: ReactNode;
    to: string | Function;
  } = {
    component: 'tr',
    to: hasLinkTo,
  };
  hasLinkTo && (linkComponent.component = MixedLink);

  return (
    <div
      className={classNames(
        classes.root,
        variant === 'outlined' ? classes.outlinedTable : {}
      )}
    >
      {title && <TableToolbar title={title} />}

      <div
        className={
          !restOfTableProps.stickyHeader ? classes.tableWrapper : undefined
        }
      >
        <MuiTable
          className={classNames((classes as any).table, tableClassName)}
          aria-labelledby={'tableTitle'}
          aria-label='table'
          {...restOfTableProps}
        >
          <TableHeader
            columns={columns}
            order={order}
            orderBy={!sortDisabled && orderBy}
            enableCheckboxes={enableCheckboxes}
            enableHeaderCheckbox={enableHeaderCheckbox}
            onRequestSort={handleRequestSort}
            TableCellProps={HeaderTableCellProps}
            CheckboxProps={CheckboxProps}
            // @ts-expect-error ts-migrate(2322) FIXME: Type '{ columns: never; order: never; orderBy: any... Remove this comment to see the full error message
            SkeletonProps={SkeletonProps}
            variant={variant}
          />

          <TableBody>
            {(rows as any).length || numberOfSkeletonRows ? (
              (count === 0 || numberOfSkeletonRows === 0) &&
              (rows as any).map((row = {}, index: any) => {
                if (typeof hasLinkTo === 'function')
                  linkComponent.to = hasLinkTo(row);
                return (
                  <TableRow
                    key={index}
                    columns={columns}
                    data={row}
                    variant={variant}
                    enableCheckbox={enableCheckboxes}
                    TableCellProps={TableCellProps}
                    {...linkComponent}
                    {...TableRowProps}
                    {...CheckboxProps}
                  />
                );
              })
            ) : (
              <tr>
                <td>
                  <Typography
                    variant='subtitle2'
                    color={emptyMessageColor}
                    className={classes.emptyRowContainer}
                  >
                    {emptyMessage}
                  </Typography>
                </td>
              </tr>
            )}

            {(TableFooterRowProps as any).data &&
              Boolean((rows as any).length) && (
                <TableFooterRow
                  columns={columns}
                  data={(TableFooterRowProps as any).data}
                  variant={variant}
                  enableCheckbox={enableCheckboxes}
                />
              )}
          </TableBody>
        </MuiTable>

        {numberOfSkeletonRows > 0 && (
          <Grid container spacing={1} className={classes.skeletonContainer}>
            {/* @ts-expect-error ts-migrate(2554) FIXME: Expected 1-3 arguments, but got 0. */}
            {new Array(numberOfSkeletonRows).fill().map((item, index) => {
              return (
                <SkeletonRow
                  key={index}
                  variant={variant}
                  SkeletonProps={SkeletonProps}
                />
              );
            })}
          </Grid>
        )}

        {count !== null && count !== undefined && variant !== 'default' && (
          // Renders pagination controls for tables that either:
          // a) have all data loaded in locally (has a defined count)
          // b) do not paginate via infinite scroll (variant is default)
          <TablePagination
            component='div'
            onChangePage={handlePageChange}
            page={page}
            className={classes.tablePagination}
            {...TablePaginationProps}
          />
        )}
      </div>
    </div>
  );
}
const useStyles = makeStyles(theme => {
  const border = `1px solid ${
    ((theme as any).palette.background as any).lightPaper
  }`;

  return {
    root: {
      width: '100%',
    },
    outlinedTable: {
      borderRadius: 8,
      border,
    },
    tablePagination: {
      borderTop: border,
    },
    tableWrapper: {
      overflowX: 'auto',
    },
    skeletonContainer: {
      marginTop: 0,
    },
    emptyRowContainer: {
      paddingLeft: 22,
      paddingTop: 16,
      paddingBottom: 16,
    },
  };
});
