import React, { useState } from 'react';

// material-ui
import { withStyles, WithStyles } from '@material-ui/core/styles';
import MuiCheckbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import SvgIcon from '@material-ui/core/SvgIcon';

// components
import { Field } from 'react-final-form';
import DazzlingDialog from 'components/DazzlingDialog';
import Button from 'components/Button';

// icons
import { ReactComponent as BookCheckIcon } from 'assets/images/book-check.svg';

// styles
import agreeToTermsFieldStyles from 'routes/ReferralForm/AgreeToTermsField/agreeToTermsField.style';

export interface AgreeToTermsFieldProps
  extends WithStyles<typeof agreeToTermsFieldStyles> {
  disabled?: boolean;
  customAgreementContent?: React.ReactNode | null;
  enableCustomAgreement?: boolean | null;
  customAgreementName: string;
}

const AgreeToTermsField = ({
  classes,
  disabled,
  customAgreementContent,
  enableCustomAgreement,
  customAgreementName,
}: AgreeToTermsFieldProps) => {
  const [isAgreementModalVisible, setIsAgreementModalVisible] = useState(false);

  const handleOpenAgreementModal = (event: Event) => {
    event.preventDefault();
    setIsAgreementModalVisible(true);
  };

  const handleCloseAgreementModal = () => {
    setIsAgreementModalVisible(false);
  };

  const labelAgreeToTerms = (
    <>
      I agree to the terms and conditions set in this agreement.
      <Button
        className={classes.buttonViewAgreement}
        variant='text'
        component='a'
        onClick={handleOpenAgreementModal}
      >
        View Agreement
      </Button>
    </>
  );

  if (!enableCustomAgreement) {
    return null;
  }

  return (
    <Grid item xs={12} className={classes.formContent}>
      <Grid container>
        <Grid item xs={12}>
          <Field name={customAgreementName} type='checkbox'>
            {(props: any) => (
              <FormControlLabel
                control={
                  <MuiCheckbox
                    {...props.input}
                    color='primary'
                    disabled={disabled}
                  />
                }
                label={labelAgreeToTerms}
              />
            )}
          </Field>
        </Grid>
      </Grid>
      {customAgreementContent && (
        <DazzlingDialog
          open={isAgreementModalVisible}
          headerProps={{
            icon: (props: any) => (
              <SvgIcon
                {...props}
                className='svgIcon'
                component={BookCheckIcon}
                viewBox='0 0 46 52'
              />
            ),
            title: 'Agreement',
            subtitleNode: (
              <div className={classes.subtitleNodeInModal}>
                By completing this form I am agreeing to the following terms and
                consenting to any requests made by the custom agreement below:
              </div>
            ),
          }}
          handleClose={handleCloseAgreementModal}
          displayActions={false}
        >
          <div className={classes.agreementTextInModal}>
            {customAgreementContent}
          </div>
        </DazzlingDialog>
      )}
    </Grid>
  );
};

export default withStyles(agreeToTermsFieldStyles, { withTheme: true })(
  AgreeToTermsField
);
