import {
  all,
  call,
  fork,
  put,
  takeEvery,
  select,
  take,
} from 'redux-saga/effects';
import Immutable from 'immutable';
// action creators
import { doneIndicator, success } from 'store/actions/httpActions';
import { fetchMembersForGroup } from 'store/actions/memberActions';
// selector
import getCurrentGroupId from 'store/selectors/getCurrentGroupId';
// api
import client from 'sources/api';
// constants
import {
  PHONE_NUMBER_FETCH_FOR_GROUP_REQUEST,
  PHONE_NUMBER_FETCH_FOR_GROUP,
} from 'store/constants/phoneNumberTypes';
// schemas
import { phoneNumberSchema } from 'store/schemas/phoneNumberSchema';
// helpers
import httpSaga from 'store/sagas/httpSaga';
import getResponsePayload from 'helpers/getResponsePayload';
const WATCH_TYPE = PHONE_NUMBER_FETCH_FOR_GROUP_REQUEST;
const TYPE = PHONE_NUMBER_FETCH_FOR_GROUP;
export function* fetchPhoneNumbersForGroupSaga() {
  const currentGroupId = yield select(getCurrentGroupId);
  const metaId = 'fetch';
  // @ts-expect-error ts-migrate(2569) FIXME: Type 'Generator<any, any, unknown>' is not an arra... Remove this comment to see the full error message
  const response = yield* httpSaga(
    TYPE,
    call(client.get, '/phone_numbers', {
      params: {
        group_id: currentGroupId,
      },
    }),
    {
      schema: [phoneNumberSchema],
      metaId,
      dispatchSuccess: false,
    }
  );
  let payload;
  if (response) {
    // @ts-expect-error ts-migrate(2525) FIXME: Initializer provides no value for this binding ele... Remove this comment to see the full error message
    const { data: { next, previous, total, results = [] } = {} } = response;
    const accountIds = yield results
      .filter((res: any) => res.account !== null)
      .map((res = {}) => (res as any).account);
    yield put(
      fetchMembersForGroup({
        groupId: currentGroupId,
        accountIds,
        appendResult: true,
        mergeItems: true,
      })
    );
    yield take('MEMBER_FETCH_FOR_GROUP_DONE');
    const rest = yield getResponsePayload(response, [phoneNumberSchema]);
    payload = {
      next,
      previous,
      total,
      ...rest,
    };
  }
  // Send the response data to the store for this type to be caught by a reducer
  yield put(success(TYPE, Immutable.fromJS(payload), metaId));
  yield put(doneIndicator(TYPE));
}
export function* watch() {
  yield takeEvery(WATCH_TYPE, fetchPhoneNumbersForGroupSaga);
}
export default function* root() {
  yield all([fork(watch)]);
}
