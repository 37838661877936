export const INVITE_FETCH_FOR_GROUP_REQUEST = 'INVITE_FETCH_FOR_GROUP_REQUEST';
export const INVITE_FETCH_FOR_GROUP = 'INVITE_FETCH_FOR_GROUP';

export const INVITE_CREATE_REQUEST = 'INVITE_CREATE_REQUEST';
export const INVITE_CREATE = 'INVITE_CREATE';

export const INVITE_RESEND_REQUEST = 'INVITE_RESEND_REQUEST';
export const INVITE_RESEND = 'INVITE_RESEND';

export const INVITE_DELETE_REQUEST = 'INVITE_DELETE_REQUEST';
export const INVITE_DELETE = 'INVITE_DELETE';

export const INVITE_BULK_REQUEST = 'INVITE_BULK_REQUEST';
export const INVITE_BULK = 'INVITE_BULK';
