import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import Immutable from 'immutable';
// action creators
import { doneIndicator, success } from 'store/actions/httpActions';
// api
import client from 'sources/api';
// constants
import { FILE_FETCH_REQUEST, FILE_FETCH } from 'store/constants/fileTypes';
// schemas
import { fileSchema } from 'store/schemas/fileSchema';
// lib
import httpSaga from 'store/sagas/httpSaga';
import getResponsePayload from 'helpers/getResponsePayload';
const WATCH_TYPE = FILE_FETCH_REQUEST;
const TYPE = FILE_FETCH;
export function* fetchFilesSaga(action: any) {
  const {
    payload: { fileIds, token },
  } = action;
  const ids = Array.isArray(fileIds) ? fileIds : [fileIds];
  const options = {};
  if (token) {
    (options as any).headers = {
      Authorization: `Bearer ${token}`,
    };
  }
  let payload;
  const responseArray = yield all(
    ids.map(fileId =>
      httpSaga(TYPE, call(client.get, `/files/${fileId}`, options), {
        dispatchSuccess: false,
        schema: fileSchema,
      })
    )
  );
  let response;
  response = responseArray[0];
  if (response) {
    response.data.results = yield responseArray.map(
      (responseItem: any, index: any) => ({
        url: responseItem.data.url,
        id: ids[index],
      })
    );
    delete response.data.url;
    payload = getResponsePayload(response, [fileSchema]);
  }
  // Send the response data to the store for this type to be caught by a reducer
  yield put(success(TYPE, Immutable.fromJS(payload)));
  yield put(doneIndicator(TYPE));
}
export function* watch() {
  yield takeEvery(WATCH_TYPE, fetchFilesSaga);
}
export default function* root() {
  yield all([fork(watch)]);
}
