import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

// action creators
import { doneIndicator } from 'store/actions/httpActions';

// api
import client from 'sources/api';

// constants
import {
  USER_FETCH_BY_IDS_REQUEST,
  USER_FETCH_BY_IDS,
} from 'store/constants/userTypes';

// schemas
import { userSchema } from 'store/schemas/userSchema';

// helpers
import httpSaga from 'store/sagas/httpSaga';

const WATCH_TYPE = USER_FETCH_BY_IDS_REQUEST;
const TYPE = USER_FETCH_BY_IDS;

export function* fetchUserByIdsSaga(action: any) {
  const {
    payload: { ids },
  } = action;

  const idParams = new URLSearchParams();

  ids.forEach((id: any) => idParams.append('id', id));

  // @ts-expect-error ts-migrate(2569) FIXME: Type 'Generator<any, any, unknown>' is not an arra... Remove this comment to see the full error message
  yield* httpSaga(TYPE, call(client.get, '/accounts', { params: idParams }), {
    dispatchSuccess: true,
    schema: [userSchema],
  });

  yield put(doneIndicator(TYPE));
}

export function* watch() {
  yield takeEvery(WATCH_TYPE, fetchUserByIdsSaga);
}

export default function* root() {
  yield all([fork(watch)]);
}
