import { all } from 'redux-saga/effects';

// sagas
import createNoteSaga from './createNoteSaga';
import fetchNoteSaga from './fetchNoteSaga';
import updateNoteSaga from './updateNoteSaga';
import deleteNoteSaga from './deleteNoteSaga';

const noteSaga = function* rootSaga() {
  yield all([
    createNoteSaga(),
    fetchNoteSaga(),
    deleteNoteSaga(),
    updateNoteSaga(),
  ]);
};

export default noteSaga;
