export const SCHEMA = {
  title: {
    presence: true,
  },
  amount: {
    presence: true,
    numericality: {
      onlyInteger: true,
      lessThanOrEqualTo: 999999999,
    },
  },
  formId: {
    presence: true,
  },
};
