export function getStateToken() {
  return localStorage.getItem('oidcStateToken');
}

export function setStateToken(state: string) {
  return localStorage.setItem('oidcStateToken', state);
}

export function removeStateToken() {
  return localStorage.removeItem('oidcStateToken');
}

export function getIntegrationId() {
  return localStorage.getItem('oidcIntegrationId');
}

export function setIntegrationId(id: string) {
  return localStorage.setItem('oidcIntegrationId', id);
}

export function removeIntegrationId() {
  return localStorage.removeItem('oidcIntegrationId');
}
