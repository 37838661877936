import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { client, Resource } from 'api';
import { AxiosError } from 'axios';
// selectors
import getCurrentGroupId from 'store/selectors/getCurrentGroupId';
// helpers
import isNamesListSite from 'helpers/isNamesListSite';

export function useStripeProductsQuery() {
  const groupId = useSelector(getCurrentGroupId);
  const params = {
    ...(groupId ? { groupId } : { isNameslist: isNamesListSite() }),
  };
  return useQuery<StripeProduct[], AxiosError, StripeProduct[]>(
    [Resource.StripeProducts],
    () =>
      client.get(Resource.StripeProducts, { params }).then(({ data }) => data),
    { refetchOnWindowFocus: false }
  );
}
