import React from 'react';
import ReactHtmlParser from 'html-react-parser';

// MUI
import { useTheme, makeStyles } from '@material-ui/core/styles';
import { Grid, Box, Typography, useMediaQuery } from '@material-ui/core';

// components
import RecipientsTable from './RecipientsTable';

type Props = {
  body?: string; // old structure of activity message record for backwards compat
  subject?: string; // old structure of activity message record for backwards compat
  threadType?: 'text' | 'email'; // old structure of activity message record for backwards compat
  message: {
    body?: string;
    subject?: string;
    threadType?: 'text' | 'email';
    accountIds?: number[];
    pnmIds?: number[];
  };
  messages: {
    body?: string;
    subject?: string;
    threadType?: 'text' | 'email';
    accountIds?: number[];
    pnmIds?: number[];
  };
  recipients: ActivityMessageRecipient[];
};

const MessageDetails = ({
  body: bodyLegacy = '',
  subject: subjectLegacy,
  threadType: threadTypeLegacy,
  message,
  messages,
  recipients = [],
}: Props) => {
  const theme = useTheme();
  const classes = useStyles();
  const isExtraSmall = useMediaQuery(theme.breakpoints.only('xs'));

  const messageData = messages || message;
  const { body, subject, threadType, accountIds = [] } = messageData || {};

  return (
    <Box marginLeft={!isExtraSmall && 8} width='fill-available'>
      {(threadType || threadTypeLegacy) === 'email' && (
        <Box marginBottom={2}>
          <Typography
            variant='subtitle2'
            color='textSecondary'
            display='inline'
          >
            Subject:{' '}
          </Typography>
          <Typography
            variant='subtitle2'
            display='inline'
            style={{ fontStyle: 'italic' }}
          >
            {subject || subjectLegacy}
          </Typography>
        </Box>
      )}

      <Grid
        container
        direction='column'
        spacing={2}
        className={classes.container}
      >
        <Grid container spacing={3}>
          <Grid item>
            <Box
              width={4}
              height='100%'
              borderRadius={4}
              bgcolor='background.default'
            />
          </Grid>
          <Grid item>
            <Typography variant='body2' className={classes.anchor}>
              {ReactHtmlParser(body || bodyLegacy)}
            </Typography>
          </Grid>

          {recipients?.length > 0 && (
            <Grid item xs={12}>
              <RecipientsTable
                recipients={recipients}
                recipientType={accountIds?.length > 0 ? 'member' : 'lead'}
                contactAttribute={
                  threadType === 'text' ? 'phoneNumber' : 'emailAddress'
                }
              />
            </Grid>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

const useStyles = makeStyles(theme => {
  return {
    container: {
      paddingBottom: 12,
    },
    anchor: {
      '& a': {
        color: theme.palette.primary.main,
      },
    },
  };
});

export default MessageDetails;
