import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';

// components
import DazzlingDialog from 'components/DazzlingDialog';
import { Grid, Typography } from '@material-ui/core';
import { DeleteOutline } from '@material-ui/icons';

// actions
import { deleteGroupField } from 'store/actions/groupFieldActions';

// constant
import { FORM } from './FieldsSettings';

type Props = {
  open: boolean;
  onClose: () => void;
  fieldId: number | null;
  groupId: number;
};

export default function DeleteFieldModal({
  open,
  onClose,
  fieldId,
  groupId,
}: Props) {
  const dispatch = useDispatch();
  const deleteHandler = useCallback(() => {
    fieldId &&
      dispatch(
        deleteGroupField({ formName: FORM + fieldId, id: fieldId, groupId })
      );
  }, [dispatch, fieldId, groupId]);

  return (
    <DazzlingDialog
      id='deleteFieldModal'
      successMessage='Field Deleted Successfully'
      failureMessage='Failed to Delete Field'
      handleClose={onClose}
      open={open}
      formName={FORM + fieldId}
      acceptLabel='Delete'
      onAccept={deleteHandler}
      headerProps={{
        icon: DeleteOutline,
        title: `Are you sure you want to delete this field?`,
        highlightedSubtitle: `By deleting this field it will also remove the Form Fields and will be removed from Leads which had attributes with this name.`,
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid
            container
            justifyContent='center'
            alignItems='center'
            spacing={2}
          >
            <Grid item xs={12}>
              <Typography variant='h6'>
                This action cannot be undone.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </DazzlingDialog>
  );
}
