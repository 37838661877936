import React, { useState, useCallback } from 'react';

// hooks
import { useDispatch } from 'react-redux';

// material-ui
import { Grid, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

// components
import IconButton from 'components/IconButton';
import AssignLabelsForm from './AssignLabelsForm';

// icons
import Delete from '@material-ui/icons/Delete';
import Add from '@material-ui/icons/Add';

// action creators
import { deleteGroupTagAction } from 'store/actions/groupTagActions';

LabelsCell.defaultProps = { tags: [] };

type OwnProps = {
  tags?: any; // TODO: PropTypes.instanceOf(Array)
  groupId: number;
};

type Props = OwnProps & typeof LabelsCell.defaultProps;

export default function LabelsCell({ tags, groupId }: Props) {
  const dispatch = useDispatch();
  const classes = useStyles();

  const [modalOpen, setModalOpen] = useState(false);

  const handleLabelClick = (groupId: any, tagId: any) => {
    dispatch(deleteGroupTagAction({ groupId, tagId }));
  };

  const handleOpenModal = useCallback((e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    setModalOpen(true);
  }, []);
  const handleCloseModal = useCallback(() => setModalOpen(false), []);

  return (
    <Grid
      container
      alignItems='center'
      onClick={(event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
      }}
    >
      {tags.map(({ id, title, color }: any = {}) => {
        return (
          <Grid
            item
            xs
            key={id}
            onClick={(e: React.MouseEvent<HTMLElement>) => {
              e.preventDefault();
              handleLabelClick(groupId, id);
            }}
          >
            <Tooltip title={title}>
              <div
                style={{
                  backgroundColor: `#${color}`,
                }}
                className={classes.labelContainer}
              >
                <Delete className={classes.deleteIcon} />
              </div>
            </Tooltip>
          </Grid>
        );
      })}

      <Grid item xs>
        <Tooltip title='Assign Label'>
          <IconButton onClick={handleOpenModal} className={classes.addButton}>
            <Add />
          </IconButton>
        </Tooltip>

        <AssignLabelsForm
          selectedGroupId={groupId}
          open={modalOpen}
          onClose={handleCloseModal}
        />
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles(() => {
  const iconSize = 28;

  return {
    labelContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: iconSize,
      height: iconSize,
      borderRadius: 100,
      border: '2px solid white',
      transition: '0.2s ease',
    },
    deleteIcon: {
      height: 20,
      width: 20,
      opacity: 0,
      '&:hover': {
        transition: '0.2s ease',
        opacity: 1,
      },
    },
    addButton: {
      height: iconSize,
      width: iconSize,
    },
  };
});
