import { all } from 'redux-saga/effects';

// sagas
import fetchVoteMethodsSaga from './fetchVoteMethodsSaga';

const threadSaga = function* rootSaga() {
  yield all([fetchVoteMethodsSaga()]);
};

export default threadSaga;
