import { Map } from 'immutable';
// constants
import {
  VOTE_CREATE,
  VOTE_UPDATE,
  VOTE_DELETE,
  VOTE_FETCH,
} from 'store/constants/voteTypes';
// helpers
import httpReducer from './helpers/httpReducer';
import update from './callbacks/update';
import removeMany from './callbacks/removeMany';

export default function reduceVote(state = Map(), action = {}) {
  switch ((action as any).type) {
    case VOTE_FETCH:
    case VOTE_CREATE:
      return httpReducer(state, action, { appendResult: true });
    case VOTE_UPDATE:
      return httpReducer(state, action, {
        callback: update,
        updateResult: false,
      });
    case VOTE_DELETE:
      return httpReducer(state, action, {
        byAttribute: 'leadId',
        callback: removeMany,
      });
    default:
      return state;
  }
}
