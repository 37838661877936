import PropTypes from 'prop-types';
import React from 'react';
import FileInput from 'components/FileInput';

const FileInputField = function({ input, onChange, ...restOfProps }: any) {
  const handleFileChange = ({ filePreview, newFileId, fileName }: any) => {
    input.onChange(newFileId);
    onChange({ filePreview, newFileId, fileName });
  };

  return <FileInput {...restOfProps} onChange={handleFileChange} />;
};

FileInputField.propTypes = {
  onChange: PropTypes.func.isRequired,
  input: PropTypes.instanceOf(Object).isRequired,
};

export default FileInputField;
