import React, { useCallback } from 'react';
// material-ui
import { TextField } from '@material-ui/core';
import { ToggleButtonGroup } from '@material-ui/lab';
import { styled } from '@material-ui/core/styles';
// components
import ToggleChip from 'components/ToggleChip';
type OwnProps = {
  input?: {
    name: string;
    onChange: (...args: any[]) => any;
    value: any[];
  };
  options: any[];
  atLeastOne?: any; // TODO: ({ input: { value }, atLeastOne }) => atLeastOne && !value.length && new Error('value should be provided if `atLeastOne` props is provided')
  chipsColor?: string;
};
type Props = OwnProps & typeof ChipsSelector.defaultProps;
export default function ChipsSelector({
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'name' does not exist on type '{ name: st... Remove this comment to see the full error message
  input: { name, onChange: changeSelected, value: selected },
  options,
  atLeastOne,
  chipsColor,
  ...restProps
}: Props) {
  const handleChange = useCallback(
    (_, newSelected) => changeSelected(newSelected),
    [changeSelected]
  );

  const handleFocus = useCallback(event => {
    event.stopPropagation();
    event.target.blur();
  }, []);

  return (
    <TextField
      name={name}
      select
      SelectProps={{
        multiple: true,
        displayEmpty: true,
        open: false,
        IconComponent: () => null,
        inputProps: {
          disabled: true,
        },
        renderValue: selected => (
          <ToggleChipsGroup value={selected} onChange={handleChange}>
            {options.map(
              ({
                value,
                label,
                count,
                color,
                ring,
                checked,
                type,
                selected: alreadySelected,
                disabled,
              }) => (
                <ToggleChip
                  key={value}
                  tooltipDisabled
                  // @ts-expect-error ts-migrate(2322) FIXME: Type '{ children: any; key: any; tooltipDisabled: ... Remove this comment to see the full error message
                  value={value}
                  disabled={
                    disabled ||
                    (atLeastOne &&
                      (selected as any).length === 1 &&
                      (selected as any).includes(value))
                  }
                  onFocus={handleFocus}
                  color={color || chipsColor}
                  ring={ring}
                  disableBadge={type === 'label'}
                  type={type}
                  checked={checked}
                  count={count}
                  size='small'
                  {...(alreadySelected ? { selected: alreadySelected } : {})}
                >
                  {label}
                </ToggleChip>
              )
            )}
          </ToggleChipsGroup>
        ),
      }}
      value={selected}
      variant='outlined'
      {...restProps}
      children={[]}
    />
  );
}
ChipsSelector.defaultProps = {
  atLeastOne: false,
  chipsColor: '#fff',
};
const ToggleChipsGroup = styled(ToggleButtonGroup)({
  margin: -10,
  flexWrap: 'wrap',
  '& > *': {
    margin: 10,
  },
});
