import React, { useEffect, useCallback, useMemo } from 'react';
import { List } from 'immutable';
import { isEqual } from 'lodash';
import queryString from 'query-string';
import moment from 'moment';
import formatStartDate from 'helpers/formatStartDate';
import formatEndDate from 'helpers/formatEndDate';
import validate from 'validate.js';

// hooks
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

// material-ui
import {
  Grid,
  Radio,
  withStyles,
  WithStyles,
  RadioGroup,
  FormControlLabel,
} from '@material-ui/core';

// components
import Select from 'components/Select';
import Switch from 'components/Switch';
import { Field } from 'react-final-form';
import FilterDialog from 'components/FilterDialog';
import DateRange from 'components/DateRange';
import MemberSelector from 'components/MemberSelector';
import ReferralSourceSelector from 'components/ReferralSourceSelector';

// action creators
import { fetchMembersForGroup } from 'store/actions/memberActions';
import { fetchReferralSourceAction as fetchReferralSource } from 'store/actions/referralSourceActions';

// selectors
import getCurrentGroupId from 'store/selectors/getCurrentGroupId';
import getLeadStatuses from 'store/selectors/getLeadStatuses';
import getMembers from 'store/selectors/getMembers';
import getSliceState from 'store/selectors/getSliceState';
import getIsCurrentGroupCouncil from 'store/selectors/getIsCurrentGroupCouncil';
import getIsCurrentGroupChapter from 'store/selectors/getIsCurrentGroupChapter';

// styles
import leadListFilterStyles from 'components/LeadListFilter/leadListFilter.style';

const FORM = 'filterLeadsForm';

const SHARED_OPTIONS = [
  { label: 'ALL', value: 'all' },
  { label: 'Shared Leads', value: 'true' },
  { label: 'Not Shared Leads', value: 'false' },
];

type FormSchema = {
  tags?: [boolean, number[]];
  milestones?: [boolean, number[]];
  statuses?: number[];
  uncontacted?: boolean;
  isShared?: string;
  mainContact?: {
    value: number | 'none';
    label: string;
  };
  referralSource?: number;
  createdOn: {
    startDate?: moment.Moment | string;
    endDate?: moment.Moment | string;
  };
  updatedOn: {
    startDate?: moment.Moment | string;
    endDate?: moment.Moment | string;
  };
  lastContactedOn: {
    startDate?: moment.Moment | string;
    endDate?: moment.Moment | string;
  };
};

export interface LeadListFilterProps
  extends WithStyles<typeof leadListFilterStyles> {}

const LeadListFilter = ({ classes }: LeadListFilterProps) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const queryParams = queryString.parse(history.location.search);

  // pulls in data from the app state
  const {
    currentGroupId,
    statuses,
    tag: { data: tags = [] },
    milestone: { data: milestones = [] },
    members,
    isCurrentGroupCouncil,
    isCurrentGroupChapter,
  } = useSelector(
    state => ({
      currentGroupId: getCurrentGroupId(state),
      statuses: getLeadStatuses(state),
      tag: getSliceState('tag')(state),
      milestone: getSliceState('milestone')(state),
      members: (getMembers('suggestions')(state) || List()).toJS() as any,
      isCurrentGroupCouncil: getIsCurrentGroupCouncil(state),
      isCurrentGroupChapter: getIsCurrentGroupChapter(state),
    }),
    isEqual
  );

  // populate filters so that chips have display values
  useEffect(() => {
    // populate main contact's name
    if (queryParams?.mainContactId) {
      dispatch(
        fetchMembersForGroup({
          groupId: currentGroupId,
          accountIds: [queryParams.mainContactId],
        })
      );
    }
    // populate referral source's name
    if (queryParams?.referralSourceId) {
      dispatch(fetchReferralSource({ id: queryParams.referralSourceId }));
    }
  }, [currentGroupId, dispatch]); // eslint-disable-line

  const handleSetFilters = ({
    mainContact,
    referralSource,
    tags,
    milestones,
    uncontacted,
    isShared,
    statuses,
    createdOn = {},
    updatedOn = {},
    lastContactedOn = {},
  }: FormSchema) => {
    const newSearch = new URLSearchParams(history.location.search);

    if (mainContact?.value) {
      newSearch.set('mainContactId', mainContact.value.toString());
    } else newSearch.delete('mainContactId');

    if (referralSource) {
      newSearch.set('referralSourceId', referralSource.toString());
    } else newSearch.delete('referralSourceId');

    newSearch.delete('tagId');
    newSearch.delete('excludedTagId');
    if (tags) {
      let [excluded, tagIds] = tags;
      if (excluded) {
        for (const tagId of tagIds)
          newSearch.append('excludedTagId', String(tagId));
      } else {
        for (const tagId of tagIds) newSearch.append('tagId', String(tagId));
      }
    }
    newSearch.delete('milestoneId');
    newSearch.delete('excludedMilestoneId');
    if (milestones) {
      let [excluded, milestoneIds] = milestones;
      if (excluded) {
        for (const milestoneId of milestoneIds)
          newSearch.append('excludedMilestoneId', String(milestoneId));
      } else {
        for (const milestoneId of milestoneIds)
          newSearch.append('milestoneId', String(milestoneId));
      }
    }
    newSearch.delete('statusId');
    if (statuses) {
      for (const statusId of statuses)
        newSearch.append('statusId', String(statusId));
    }
    if (uncontacted) {
      newSearch.set('uncontacted', 'true');
    } else {
      newSearch.delete('uncontacted');
    }
    if (isShared && isShared.toString() !== 'all') {
      newSearch.set('isShared', isShared.toString());
    } else {
      newSearch.delete('isShared');
    }
    if (createdOn?.startDate && createdOn?.endDate) {
      newSearch.set('createdOnStart', formatStartDate(createdOn.startDate));
      newSearch.set('createdOnEnd', formatEndDate(createdOn.endDate));
    } else {
      newSearch.delete('createdOnStart');
      newSearch.delete('createdOnEnd');
    }
    if (updatedOn?.startDate && updatedOn?.endDate) {
      newSearch.set('updatedOnStart', formatStartDate(updatedOn.startDate));
      newSearch.set('updatedOnEnd', formatEndDate(updatedOn.endDate));
    } else {
      newSearch.delete('updatedOnStart');
      newSearch.delete('updatedOnEnd');
    }
    if (lastContactedOn?.startDate && lastContactedOn?.endDate) {
      newSearch.set(
        'lastContactedOnStart',
        formatStartDate(lastContactedOn.startDate)
      );
      newSearch.set(
        'lastContactedOnEnd',
        formatEndDate(lastContactedOn.endDate)
      );
    } else {
      newSearch.delete('lastContactedOnStart');
      newSearch.delete('lastContactedOnEnd');
    }

    history.replace({
      pathname: history.location.pathname,
      search: newSearch.toString(),
    });
  };

  const validateFields = useCallback(values => {
    validate(values, getSchema(values));
  }, []);

  const handleDateChange = useCallback(({ startDate, endDate, input }) => {
    input.onChange({ startDate, endDate });
  }, []);

  // Filter options
  const tagOptions = tags.map(({ title, id }: any = {}) => ({
    label: title,
    value: id,
  }));
  const milestoneOptions = milestones.map(({ title, id }: any = {}) => ({
    label: title,
    value: id,
  }));

  const statusOptions = useMemo(
    () =>
      statuses.map((status: Status) => ({
        label: status.abbreviation,
        value: status.id,
      })),
    [statuses]
  );

  // Build out the available filters
  const filterItems = [
    {
      label: 'Date Created',
      field: (
        <Grid item xs>
          <Field
            name='createdOn'
            // @ts-expect-error ts-migrate(2322) FIXME: Type 'typeof DateRange' is not assignable to type ... Remove this comment to see the full error message
            component={DateRange}
            onDateChange={handleDateChange}
            justifyContent='flex-start'
            initialStartDate={
              queryParams.createdOnStart
                ? moment(queryParams.createdOnStart as string)
                : ''
            }
            initialEndDate={
              queryParams.createdOnEnd
                ? moment(queryParams.createdOnEnd as string)
                : ''
            }
            required
          />
        </Grid>
      ),
      selected: Boolean(queryParams.createdOnStart),
    },
    {
      label: 'Date Updated',
      field: (
        <Grid item xs>
          <Field
            name='updatedOn'
            // @ts-expect-error ts-migrate(2322) FIXME: Type 'typeof DateRange' is not assignable to type ... Remove this comment to see the full error message
            component={DateRange}
            formName={FORM}
            onDateChange={handleDateChange}
            justifyContent='flex-start'
            initialStartDate={
              queryParams.updatedOnStart
                ? moment(queryParams.updatedOnStart as string)
                : ''
            }
            initialEndDate={
              queryParams.updatedOnEnd
                ? moment(queryParams.updatedOnEnd as string)
                : ''
            }
            required
          />
        </Grid>
      ),
      selected: Boolean(queryParams.updatedOnStart),
    },
    {
      label: 'Last Contacted Date',
      field: (
        <Grid item xs>
          <Field
            name='lastContactedOn'
            // @ts-expect-error ts-migrate(2322) FIXME: Type 'typeof DateRange' is not assignable to type ... Remove this comment to see the full error message
            component={DateRange}
            formName={FORM}
            onDateChange={handleDateChange}
            justifyContent='flex-start'
            initialStartDate={
              queryParams.lastContactedOnStart
                ? moment(queryParams.lastContactedOnStart as string)
                : ''
            }
            initialEndDate={
              queryParams.lastContactedOnEnd
                ? moment(queryParams.lastContactedOnEnd as string)
                : ''
            }
            required
          />
        </Grid>
      ),
      selected: Boolean(queryParams.lastContactedOnStart),
    },
    ...(isCurrentGroupChapter
      ? [
          {
            label: 'Not Contacted',
            field: (
              <Grid item xs>
                <Field
                  name='uncontacted'
                  type='checkbox'
                  label='Toggle On to filter and display leads that have not been contacted'
                  component={Switch}
                />
              </Grid>
            ),
            selected: Boolean(queryParams.uncontacted),
          },
        ]
      : []),
    {
      label: 'Main Contact',
      field: (
        <Grid item xs className={classes.selector}>
          <Field
            allowNull
            isEqual={isEqual}
            name='mainContact'
            component={MemberSelector}
            isForFiltering
            fullWidth
            margin='normal'
          />
        </Grid>
      ),
      selected: Boolean(queryParams.mainContactId),
    },
    {
      label: 'Referral Source',
      field: (
        <Grid item xs className={classes.selector}>
          <Field
            name='referralSource'
            component={ReferralSourceSelector}
            placeholder='Search'
            multiple={false}
            readOnly
            label='Referral Source'
          />
        </Grid>
      ),
      selected: Boolean(queryParams.referralSourceId),
    },
    ...(isCurrentGroupCouncil
      ? [
          {
            label: 'Shared',
            field: (
              <Grid item xs className={classes.selector}>
                <Field
                  name='isShared'
                  type='radio'
                  render={props => (
                    <RadioGroup
                      aria-labelledby='isShared'
                      value={props.input.value}
                      name='isShared'
                      onChange={props.input.onChange}
                      {...props}
                    >
                      {SHARED_OPTIONS.map(({ label, value }) => (
                        <FormControlLabel
                          value={value}
                          control={<Radio />}
                          label={label}
                        />
                      ))}
                    </RadioGroup>
                  )}
                />
              </Grid>
            ),
            selected: Boolean(queryParams.isShared),
          },
        ]
      : []),
    {
      label: 'Status',
      field: (
        <Grid item xs className={classes.selector}>
          <Field
            name='statuses'
            render={props => (
              <Select
                label='Select Status'
                // @ts-expect-error ts-migrate(2322) FIXME: Type '{ input: FieldInputProps<any, HTMLElement>; ... Remove this comment to see the full error message
                variant='outlined'
                options={statusOptions}
                multiple={true}
                {...props}
              />
            )}
          />
        </Grid>
      ),
      selected: queryParams.statusId
        ? Boolean(queryParams.statusId)
        : undefined,
    },
    {
      label: `Lead ${!!queryParams.excludedTagId ? "doesn't have " : ''}Tag`,
      field: (
        <Grid item xs className={classes.selector}>
          <Field
            name='tags'
            render={props => (
              <Select
                label='Select Tag'
                // @ts-expect-error ts-migrate(2322) FIXME: Type '{ input: FieldInputProps<any, HTMLElement>; ... Remove this comment to see the full error message
                variant='outlined'
                options={tagOptions}
                multiple={true}
                toggleLabel={`Toggle on for "Doesn't have this tag(s)".`}
                {...props}
              />
            )}
          />
        </Grid>
      ),
      selected: queryParams.tagId
        ? Boolean(queryParams.tagId)
        : queryParams.excludedTagId
        ? Boolean(queryParams.excludedTagId)
        : undefined,
    },
    {
      label: `Lead ${
        !!queryParams.excludedMilestoneId ? "doesn't have " : ''
      }Milestone`,
      field: (
        <Grid item xs className={classes.selector}>
          <Field
            name='milestones'
            render={props => (
              <Select
                label='Select Milestone'
                // @ts-expect-error ts-migrate(2322) FIXME: Type '{ input: FieldInputProps<any, HTMLElement>; ... Remove this comment to see the full error message
                variant='outlined'
                options={milestoneOptions}
                multiple={true}
                toggleLabel={`Toggle on for "Doesn't have this milestone(s)".`}
                {...props}
              />
            )}
          />
        </Grid>
      ),
      selected: queryParams.milestoneId
        ? Boolean(queryParams.milestoneId)
        : queryParams.excludedMilestoneId
        ? Boolean(queryParams.excludedMilestoneId)
        : undefined,
    },
  ];
  const initialStatuses = useMemo(() => {
    if (queryParams.statusId) {
      if (Array.isArray(queryParams.statusId)) {
        return queryParams.statusId.map(Number);
      }
      return [Number(queryParams.statusId)];
    }
    return [];
  }, [queryParams.statusId]);

  const findTags = useCallback(
    (
      queryTags: string | (string | null)[],
      searchTagOptions: { label: string; value: number }[]
    ) => {
      const tagOptionValues = searchTagOptions.map(tagItem => tagItem.value);
      if (Array.isArray(queryTags)) {
        const numberTags = queryTags.map(Number);
        return numberTags.filter((queryTag: number) => {
          return tagOptionValues.find(tagItem => queryTag === tagItem);
        });
      }
      if (tagOptionValues.find(tagItem => Number(queryTags) === tagItem)) {
        return [Number(queryTags)];
      }
      return [];
    },
    []
  );

  const initialTags = useMemo(() => {
    if (queryParams.tagId) {
      return [false, findTags(queryParams.tagId, tagOptions)];
    } else if (queryParams.excludedTagId) {
      return [true, findTags(queryParams.excludedTagId, tagOptions)];
    }
    return [false, []];
  }, [queryParams.tagId, queryParams.excludedTagId, findTags, tagOptions]);

  const initialMilestones = useMemo(() => {
    if (queryParams.milestoneId) {
      return [false, findTags(queryParams.milestoneId, milestoneOptions)];
    } else if (queryParams.excludedMilestoneId) {
      return [
        true,
        findTags(queryParams.excludedMilestoneId, milestoneOptions),
      ];
    }
    return [false, []];
  }, [
    queryParams.milestoneId,
    queryParams.excludedMilestoneId,
    findTags,
    milestoneOptions,
  ]);

  const initialShared = useMemo(() => {
    if (queryParams.isShared) {
      return queryParams.isShared.toString();
    }

    return 'all';
  }, [queryParams.isShared]);

  const initialFilters = useMemo(() => {
    return {
      createdOn: {
        startDate: queryParams.createdOnStart,
        endDate: queryParams.createdOnEnd,
      },
      updatedOn: {
        startDate: queryParams.updatedOnStart,
        endDate: queryParams.updatedOnEnd,
      },
      lastContactedOn: {
        startDate: queryParams.lastContactedOnStart,
        endDate: queryParams.lastContactedOnEnd,
      },
      uncontacted: queryParams.uncontacted,
      tags: initialTags,
      milestones: initialMilestones,
      statuses: initialStatuses,
      isShared: initialShared,
      referralSource: queryParams.referralSourceId,
      mainContact: {
        value: queryParams.mainContactId,
        label: members.find(
          (member: any) =>
            member?.value?.toString() === queryParams?.mainContactId?.toString()
        )?.label,
      },
    };
  }, [
    queryParams,
    initialStatuses,
    initialTags,
    initialMilestones,
    members,
    initialShared,
  ]);

  return (
    <div id='elevio_filter_leads'>
      <FilterDialog
        defaultExpanded={false}
        filterItems={filterItems}
        onFilterChange={handleSetFilters}
        validateFields={validateFields}
        initialFilters={initialFilters}
        closeFormAfterReset
        turnOnHardResetForm
      />
    </div>
  );
};

const getSchema = (values = {}) => {
  const schema = {};
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'dateCreated' does not exist on type '... Remove this comment to see the full error message
  const { dateCreated = {} } = values;
  if (dateCreated?.startDate && !dateCreated?.endDate) {
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    schema['dateCreated.endDate'] = {
      presence: { message: 'Select an end date' },
    };
  } else if (!dateCreated?.startDate && dateCreated?.endDate) {
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    schema['dateCreated.startDate'] = {
      presence: { message: 'Select a start date' },
    };
  }
  return schema;
};

export default withStyles(leadListFilterStyles)(LeadListFilter);
