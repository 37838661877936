import { useQuery } from 'react-query';
import { client, Resource } from 'api';

export const useFileQuery = (fileId?: number | null, token?: string | null) => {
  return useQuery<{ url: string }, Error>(
    [Resource.File, fileId],
    () =>
      client
        .get(Resource.File + '/' + fileId, {
          ...(token ? { headers: { Authorization: `Bearer ${token}` } } : {}),
        })
        .then(({ data }) => data),
    { enabled: Boolean(fileId), staleTime: Infinity }
  );
};
