import { Styles, Theme } from 'assets/types';

const paymentItemStyles: Styles = theme => ({
  payBox: {
    marginBottom: 20,
  },
  formTitle: {
    fontSize: '0.9rem',
    fontWeight: 600,
    color: '#ffffff',
    paddingBottom: 10,
  },
  amountBox: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: 10,
  },
  status: {
    paddingRight: 5,
    color: '#26F241',
    lineHeight: '1em',
  },
  amount: {
    fontSize: '0.9rem',
    fontWeight: 500,
    color: '#ffffff',
  },
  textBox: {
    color: '#ffffff',
    fontSize: '0.8rem',
    fontWeight: 500,
    paddingBottom: 10,
    '& .leftText': {
      display: 'inline-block',
      marginRight: '0.3em',
      color: '#A5AEB4',
    },
  },
  timeBox: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: 10,
    color: 'rgba(255, 255, 255, 0.5)',
    fontSize: '0.8rem',
    '& .leftIcon': {
      display: 'inline-block',
      marginRight: '0.3em',
      lineHeight: '1em',
      '& svg': {
        width: 16,
        height: 16,
      },
    },
  },
  topLine: {
    position: 'absolute',
    right: 24,
    top: -36,
    width: 4,
    height: 64,
    backgroundColor: (theme as Theme).palette.background.lightPaper,
  },
  bottomLine: {
    position: 'absolute',
    right: 24,
    top: 40,
    width: 4,
    height: '100%',
    backgroundColor: (theme as Theme).palette.background.lightPaper,
  },
  dot: {
    top: 28,
    right: 18,
    position: 'absolute',
    padding: 9,
    borderRadius: '50%',
    backgroundColor: (theme as Theme).palette.background.lightPaper,
    [theme.breakpoints.down('xs')]: {
      zIndex: 0,
    },
  },
  nbsp: {
    display: 'inline-block',
    width: '0.3em',
  },
});

export default paymentItemStyles;
