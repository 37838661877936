import React, { useEffect, useState, useMemo, useCallback, memo } from 'react';
import { isEqual } from 'lodash';
import Immutable, { Map, List } from 'immutable';

// components
import Snackbar from 'components/Snackbar';
import ErrorDialog from 'components/ErrorDialog';
import { Button } from '@material-ui/core';

// hooks
import { useSelector, useDispatch } from 'react-redux';
import { usePrevious } from 'helpers/hooks/usePrevious';

// selectors
import getErrorMessage from 'store/selectors/getErrorMessage';
import getLoadingFromState from 'store/selectors/getLoadingFromState';
import getStatusFromState from 'store/selectors/getStatusFromState';
import getForm from 'store/selectors/getForm';

// action creators
import { destroyForm } from 'store/actions/formActions';

const Toast = ({
  alertProps = {},
  failureMessage = 'Action failed',
  formName = '',
  handleResetForm = () => {},
  hideErrorDialog = false,
  hideSuccessMessage = false,
  hideSnackbar = false,
  isSuccess = false,
  isError = false,
  id = 'toast',
  metaId = null,
  shouldDestroyFormOnClose = false,
  slice = '',
  snackbarProps = {},
  successMessage = 'Completed successfully',
  forceErrorDialog = false,
}: {
  alertProps?: any;
  failureMessage?: string;
  formName?: string;
  handleResetForm?: () => void;
  hideErrorDialog?: boolean;
  hideSuccessMessage?: boolean | null;
  hideSnackbar?: boolean;
  isSuccess?: boolean;
  isError?: boolean;
  id?: string;
  metaId?: string | number | null;
  shouldDestroyFormOnClose?: boolean;
  slice?: string;
  snackbarProps?: any;
  successMessage?: String;
  forceErrorDialog?: boolean;
}) => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const [severity, setSeverity] = useState<string | null>(null);
  const [errorDialogOpen, setErrorDialogOpen] = useState(false);

  const dispatch = useDispatch();
  const { stateError = '', stateLoading, status, formData = {} } = useSelector(
    state => ({
      stateError: getErrorMessage(slice)(state),
      stateLoading: getLoadingFromState(slice, metaId, false)(state),
      status: getStatusFromState(slice, metaId)(state),
      formData: getForm(formName)(state),
    }),
    isEqual
  );

  // @ts-expect-error ts-migrate(2339) FIXME: Property 'formError' does not exist on type '{}'.
  const { formError = Map() } = formData;

  let formErrorMessage = formError ? formError.get('message') : '';

  let errorList = formName
    ? formError.getIn(['validationErrors', 'errors'], List()) || List()
    : stateError || List();

  if (typeof errorList === 'string') {
    errorList = Immutable.fromJS([
      {
        msg: errorList,
        id: 1,
      },
    ]);
  } else if (List.isList(errorList) && typeof errorList.first() === 'string') {
    errorList = errorList.map((errorString, key) =>
      Map({ msg: errorString, id: key })
    );
  }

  if (errorList.size === 1) {
    formErrorMessage = `${(errorList.first() || Map()).get(
      'msg'
    )}. ${formErrorMessage}`;
  }

  const errorMessage = useMemo(() => {
    let errorMessage = '';

    if (errorList.size > 1) {
      errorMessage = `${failureMessage}: There were ${errorList.size} errors`;
    } else if (failureMessage) {
      errorMessage = formName
        ? `${failureMessage}${formErrorMessage ? ':' : ''} ${formErrorMessage ||
            ''}`
        : `${failureMessage}${stateError ? ':' : ''} ${stateError || ''}`;
    } else {
      errorMessage = formName ? formErrorMessage : stateError;
    }

    return errorMessage;
  }, [errorList.size, failureMessage, formErrorMessage, formName, stateError]);

  // @ts-expect-error ts-migrate(2339) FIXME: Property 'isSubmitting' does not exist on type '{}... Remove this comment to see the full error message
  const loading = formName ? formData.isSubmitting : stateLoading;
  const previousLoading = usePrevious(loading);

  const hasSubmitFailed = formName
    ? // @ts-expect-error ts-migrate(2339) FIXME: Property 'hasSubmitFailed' does not exist on type ... Remove this comment to see the full error message
      formData.hasSubmitFailed
    : status === 'error';
  const hasSubmitSucceeded = formName
    ? // @ts-expect-error ts-migrate(2339) FIXME: Property 'hasSubmitSucceeded' does not exist on ty... Remove this comment to see the full error message
      formData.hasSubmitSucceeded
    : previousLoading && !loading && status === 'success';

  const previousHasSubmitSucceeded = usePrevious(hasSubmitSucceeded);

  const previousHasSubmitFailed = usePrevious(hasSubmitFailed);

  const previousSnackbarOpen = usePrevious(snackbarOpen);

  const openErrorDialog = useCallback(() => {
    setErrorDialogOpen(true);
  }, []);

  const closeErrorDialog = useCallback(() => {
    setErrorDialogOpen(false);
  }, []);

  const closeSnackbar = useCallback(() => {
    setSnackbarOpen(false);
  }, []);

  const snackbarPropsObject = useMemo(
    () => ({
      autoHideDuration: 4000,
      open: snackbarOpen,
      onClose: closeSnackbar,
      ...snackbarProps,
    }),
    [snackbarOpen, closeSnackbar, snackbarProps]
  );

  useEffect(() => {
    if (
      ((!previousHasSubmitSucceeded && hasSubmitSucceeded) || isSuccess) &&
      !snackbarOpen &&
      !hideSuccessMessage
    ) {
      setSnackbarOpen(true);
      setSeverity('success');
    }
  }, [
    hasSubmitSucceeded,
    previousHasSubmitSucceeded,
    snackbarOpen,
    isSuccess,
    hideSuccessMessage,
  ]);

  useEffect(
    () => () => {
      if (!previousHasSubmitSucceeded && hasSubmitSucceeded) {
        handleResetForm();
      }
    },
    [handleResetForm, hasSubmitSucceeded, previousHasSubmitSucceeded]
  );

  useEffect(() => {
    if (
      previousSnackbarOpen &&
      !snackbarOpen &&
      formName &&
      shouldDestroyFormOnClose &&
      !hasSubmitFailed
    ) {
      dispatch(destroyForm(formName));
    }
  }, [
    previousSnackbarOpen,
    snackbarOpen,
    dispatch,
    formName,
    hasSubmitFailed,
    shouldDestroyFormOnClose,
  ]);

  useEffect(() => {
    if (
      ((!previousHasSubmitFailed && hasSubmitFailed) || isError) &&
      !forceErrorDialog
    ) {
      if (!snackbarOpen) {
        setSnackbarOpen(true);
        setSeverity('error');
      }
    } else if (
      ((!previousHasSubmitFailed && hasSubmitFailed) || isError) &&
      forceErrorDialog
    ) {
      if (!errorDialogOpen) {
        setErrorDialogOpen(true);
        setSeverity('error');
      }
    }
  }, [
    hasSubmitFailed,
    previousHasSubmitFailed,
    snackbarOpen,
    isError,
    forceErrorDialog,
    errorDialogOpen,
  ]);

  return (
    <>
      {!hideSnackbar && (
        <>
          <Snackbar
            // @ts-expect-error ts-migrate(2322) FIXME: Type '{ id: any; snackbarProps: any; alertProps: a... Remove this comment to see the full error message
            id={id}
            snackbarProps={snackbarPropsObject}
            alertProps={{
              message:
                severity && severity === 'success'
                  ? successMessage
                  : errorMessage,
              severity,
              variant: 'filled',
              onClose: closeSnackbar,
              action: errorList.size > 1 && (
                <Button id='viewErrorButton' onClick={openErrorDialog}>
                  View
                </Button>
              ),
              ...alertProps,
            }}
          />
          {!hideErrorDialog && (
            <ErrorDialog
              errors={errorList ? errorList.toJS() : []}
              open={Boolean(errorDialogOpen)}
              onClose={closeErrorDialog}
            />
          )}
        </>
      )}
    </>
  );
};

export default memo(Toast, isEqual);
