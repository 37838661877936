import remove from './remove';
import { List, Map } from 'immutable';

/*
 *  This handles removing multiples items from a slice when given a
 *  response.
 */
export default function removeMany(map: any, action: any, options = {}) {
  const { payload } = action;

  // @ts-expect-error ts-migrate(2339) FIXME: Property 'result' does not exist on type '{}'.
  const { result: resultKey = 'result' } = options;

  let newState = map;

  if (payload.first() === 'all') {
    // Delete all
    newState = newState.setIn(['data', 'byId'], Map());
    newState = newState.setIn(['data', resultKey], List());
  } else {
    payload.forEach((item: any) => {
      newState = remove(newState, { payload: item }, options);
    });
  }

  return newState;
}
