import { Styles } from 'assets/types';

const leadSharingFormStyle: Styles = theme => ({
  leadSharingCard: {
    marginTop: theme.spacing(3),
    '&.isFirstCard': {
      marginTop: 0,
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
    },
  },
  leadSharingBox: {
    width: '100%',
  },
  leadSharingForm: {
    width: '100%',
    boxSizing: 'border-box',
    paddingRight: 40,
    [theme.breakpoints.down('md')]: {
      paddingRight: 20,
    },
    [theme.breakpoints.down('sm')]: {
      paddingRight: 0,
    },
  },
  leadSharingFormInner: {
    border: '1px solid rgba(255, 255, 255, 0.12)',
    borderRadius: 8,
    padding: theme.spacing(2),
  },
  leadSharingFormTitle: {
    fontSize: '1rem',
    fontWeight: 700,
    width: '100%',
  },
});

export default leadSharingFormStyle;
