import { OIDC_LOGIN_REQUEST } from 'store/constants/oidcTypes';

export function login({
  code,
  redirectUri,
  id,
}: {
  code: string;
  redirectUri: string;
  id: number;
}) {
  return {
    type: OIDC_LOGIN_REQUEST,
    payload: { code, redirect_uri: redirectUri, id },
  };
}
