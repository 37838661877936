import React, { useMemo, useCallback, useState } from 'react';
import validate from 'validate.js';
import { useDispatch, useSelector } from 'react-redux';
import { isEqual } from 'lodash';

// final form
import { Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';

// selectors
import getSliceState from 'store/selectors/getSliceState';
import getCombinedForm from 'store/selectors/getCombinedForm';

// actions
import { updateTagAction } from 'store/actions/tagActions';
import { destroyForm as destroyFormAction } from 'store/actions/formActions';

// material-ui
import { Grid } from '@material-ui/core';

// components
import TagFields from './TagFields';
import ActionBar from 'components/ActionBar';
import Button from 'components/Button';
import DeleteTagModal from './DeleteTagModal';

const SCHEMA = {
  title: {
    presence: true,
    length: { maximum: 128 },
  },
  description: {
    length: { maximum: 1024 },
  },
};

export const FORM = 'manageTag';

export default function TagsForm() {
  const [submittedIds, setSubmittedIds] = useState([]);
  const [currentTagId, setCurrentTagId] = useState<number | null>(null);
  const {
    actions: { updateTag, destroyForm },
    state: {
      tagsForm: { isSubmitting, hasSubmitFailed, hasSubmitSucceeded },
      tag: { data: tags },
    },
  } = useRedux(submittedIds);
  const initialTags = useMemo(
    () =>
      tags.map((tag: any) => ({
        ...tag,
        color: '#' + tag.color,
        visibility: null,
      })),
    [tags]
  );
  const validateForm = useCallback(
    values => ({
      tags: values.tags.map((status: any) => validate(status, SCHEMA)),
    }),
    []
  );
  const handleTagDelete = useCallback((id: number) => {
    setCurrentTagId(id);
  }, []);

  const handleCloseModal = useCallback(() => {
    setCurrentTagId(null);
  }, []);

  const handleFormSubmit = useCallback(
    values => {
      for (const tag of initialTags) {
        const valuesTag = values.tags.find(({ id }: any) => id === tag.id);
        if (valuesTag && !isEqual(valuesTag, tag)) {
          // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '(ids: never[]) => any[]' is not ... Remove this comment to see the full error message
          setSubmittedIds(ids => [...ids, valuesTag.id]);
          updateTag({
            formName: FORM + valuesTag.id,
            id: valuesTag.id,
            fields: { ...valuesTag, color: valuesTag.color.slice(1) },
          });
        }
      }
    },
    [initialTags, updateTag]
  );

  const handleClose = useCallback(() => {
    for (const id of submittedIds) destroyForm(FORM + id);
    setSubmittedIds([]);
  }, [submittedIds, destroyForm]);

  return (
    <Form
      onSubmit={handleFormSubmit}
      mutators={{ ...arrayMutators }}
      keepDirtyOnReinitialize={isSubmitting}
      initialValues={{ tags: initialTags }}
      initialValuesEqual={isEqual}
      validate={validateForm}
      render={({ handleSubmit, invalid, pristine, form: { reset } }) => (
        <form onSubmit={handleSubmit}>
          <FieldArray name='tags' isEqual={isEqual}>
            {({ fields }) => (
              <Grid container justifyContent={'flex-end'} spacing={2}>
                {fields.map((prefix, index) => (
                  <Grid item xs={12} key={prefix}>
                    <TagFields
                      fieldPrefix={prefix}
                      {...fields.value[index]}
                      onDelete={handleTagDelete}
                    />
                  </Grid>
                ))}
                <DeleteTagModal
                  open={!!currentTagId}
                  onClose={handleCloseModal}
                  tagId={currentTagId}
                />
              </Grid>
            )}
          </FieldArray>
          <ActionBar
            open={!pristine || isSubmitting || hasSubmitSucceeded}
            message='Save your changes?'
            onClose={handleClose}
            // @ts-expect-error ts-migrate(2322) FIXME: Type 'number | null' is not assignable to type 'nu... Remove this comment to see the full error message
            autoHideDuration={hasSubmitSucceeded ? 1500 : null}
          >
            <Button disabled={pristine} onClick={reset}>
              Cancel
            </Button>
            <Button
              variant='outlined'
              color='primary'
              type='submit'
              disabled={pristine || invalid || isSubmitting}
              loading={isSubmitting}
              success={hasSubmitSucceeded}
              fail={hasSubmitFailed}
            >
              Save
            </Button>
          </ActionBar>
        </form>
      )}
    />
  );
}

const useRedux = (submittedIds: any) => {
  const dispatch = useDispatch();
  const actions = useMemo(
    () => ({
      updateTag: (payload: any) => dispatch(updateTagAction(payload)),
      destroyForm: (payload: any) => dispatch(destroyFormAction(payload)),
    }),
    [dispatch]
  );
  const state = useSelector(
    state => ({
      tagsForm: getCombinedForm(submittedIds.map((id: any) => FORM + id))(
        state
      ),
      tag: getSliceState('tag')(state),
    }),
    isEqual
  );
  return { actions, state };
};
