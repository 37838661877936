import { useRef, useEffect, useState, useCallback } from 'react';

const useScrollBottom = (bottomOffset = 0) => {
  const [isBottom, setIsBottom] = useState(false);
  const scrollRef = useRef(null);

  const onScroll = useCallback(() => {
    if (scrollRef.current) {
      setIsBottom(
        // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
        scrollRef.current.scrollTop >=
          // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
          scrollRef.current.scrollHeight -
            // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
            scrollRef.current.clientHeight -
            bottomOffset
      );
    }
  }, [bottomOffset]);

  useEffect(() => {
    const current = scrollRef.current;

    // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
    current.addEventListener('scroll', onScroll);

    return () => {
      if (scrollRef && current) {
        // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
        current.removeEventListener('scroll', onScroll);
      }
    };
  }, [onScroll]);

  return [isBottom, scrollRef];
};

export default useScrollBottom;
