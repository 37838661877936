import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import { client, Resource } from 'api';
import getCurrentGroupId from 'store/selectors/getCurrentGroupId';

export const useGroupKeyQuery = () => {
  const params = { groupId: useSelector(getCurrentGroupId) };
  return useQuery<{ apiKey: string | null }, Error>(
    [Resource.GroupKey, params],
    () => client.get(Resource.GroupKey, { params }).then(({ data }) => data)
  );
};

export const useCreateGroupKeyMutation = () => {
  const queryClient = useQueryClient();
  const params = { groupId: useSelector(getCurrentGroupId) };
  return useMutation<{ apiKey: string | null }, Error>(
    () =>
      client.post(Resource.GroupKey, null, { params }).then(({ data }) => data),
    {
      onSuccess: result => {
        queryClient.setQueryData([Resource.GroupKey, params], result);
      },
    }
  );
};

export const useDeleteGroupKeyMutation = () => {
  const queryClient = useQueryClient();
  const params = { groupId: useSelector(getCurrentGroupId) };
  return useMutation<{ apiKey: string | null }, Error>(
    () => client.delete(Resource.GroupKey, { params }).then(({ data }) => data),
    {
      onSuccess: () => {
        queryClient.setQueryData([Resource.GroupKey, params], { apiKey: null });
      },
    }
  );
};
