import React from 'react';
// material-ui
import { Grid, Tooltip, WithStyles, withStyles } from '@material-ui/core';
//Styles
import tagsAnalyticCellStyles from 'routes/NewMemberAnalytics/components/TagsAnalyticCell/tagsAnalyticCell.style';

export interface TagsAnalyticCellProps
  extends WithStyles<typeof tagsAnalyticCellStyles> {
  currentTags: (string | number)[][] | null;
}

const TagsAnalyticCell = ({ classes, currentTags }: TagsAnalyticCellProps) => {
  if (!currentTags || !Array.isArray(currentTags)) {
    return null;
  }

  return (
    <Grid container alignItems='center' className={classes.gridBox}>
      {currentTags.map(([id, title, color]) => {
        return (
          id && (
            <Grid item xs key={id} className={classes.gridIn}>
              <Tooltip title={title || ''}>
                <div
                  style={{
                    backgroundColor: `#${color || '7C4DFF'}`,
                  }}
                  className={classes.labelContainer}
                />
              </Tooltip>
            </Grid>
          )
        );
      })}
    </Grid>
  );
};

export default withStyles(tagsAnalyticCellStyles, { withTheme: true })(
  TagsAnalyticCell
);
