import React from 'react';

// material-ui
import { WithStyles, withStyles, IconButton } from '@material-ui/core';

//icons
import DeleteIcon from '@material-ui/icons/Delete';

//helpers
import { FileFields } from 'components/CreateEmailModal/helpers/interfaces';

//Styles
import fileItemStyles from 'components/CreateEmailModal/components/FileItem/fileItem.style';

export interface FileItemProps extends WithStyles<typeof fileItemStyles> {
  file: FileFields;
  loading: boolean;
  removeFile: (id: number) => void;
}

const FileItem = ({ classes, loading, removeFile, file }: FileItemProps) => {
  const handleDeleteFile = () => {
    if (!file?.newFileId) {
      return;
    }
    removeFile(file.newFileId);
  };

  return !!file?.newFileId ? (
    <div className={classes.fileBox}>
      <div className={classes.fileName}>{file?.fileName || 'Unknown'}</div>
      <IconButton
        size='small'
        className={classes.deleteFileButton}
        onClick={handleDeleteFile}
        disabled={loading}
      >
        <DeleteIcon />
      </IconButton>
    </div>
  ) : null;
};

export default withStyles(fileItemStyles)(FileItem);
