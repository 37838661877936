import React from 'react';
import { Form, Field } from 'react-final-form';
import { useSelector } from 'react-redux';
import { useCreateCheckoutSessionMutation } from 'api/billing';
import { useStripe } from '@stripe/react-stripe-js';

// MUI
import { Grid, Typography, Divider } from '@material-ui/core';
import { Phone } from '@material-ui/icons';

// components
import DazzlingDialog from 'components/DazzlingDialog';
import TextField from 'components/TextField';
import getCurrentGroupId from 'store/selectors/getCurrentGroupId';
import getSliceEntityById from 'store/selectors/getSliceEntityById';

interface AddOnProps {
  opened: boolean;
  handleClose: () => void;
}

export default function AddOnModalTextsOrMinutes({
  opened,
  handleClose,
}: AddOnProps) {
  const stripe = useStripe();
  const {
    data,
    mutate: createCheckoutSession,
    isError,
    isLoading,
    isSuccess,
    reset: resetMutation,
  } = useCreateCheckoutSessionMutation('payment');
  const currentGroupId = useSelector(getCurrentGroupId);
  const {
    availableAddOnProducts: [
      callMinutes12000,
      callMinutes2400,
      // eslint-disable-next-line
      __,
      textMessages25000,
      textMessages5000,
    ] = [],
  } = useSelector(getSliceEntityById('billing', currentGroupId));

  const minuteAddOns =
    callMinutes2400 && callMinutes12000
      ? [callMinutes2400, callMinutes12000]
      : [];
  const textAddOns =
    textMessages5000 && textMessages25000
      ? [textMessages5000, textMessages25000]
      : [];
  return (
    <Form
      onSubmit={({ minutesPriceId, textsPriceId }) => {
        const stripeLineItems = [];
        if (minutesPriceId)
          stripeLineItems.push({
            price: minutesPriceId,
            quantity: 1,
          });
        if (textsPriceId)
          stripeLineItems.push({
            price: textsPriceId,
            quantity: 1,
          });
        createCheckoutSession(stripeLineItems);
      }}
      render={({ handleSubmit, pristine, values, form: { reset } }) => (
        <DazzlingDialog
          isError={isError}
          isLoading={isLoading}
          isSuccess={isSuccess}
          acceptLabel={'Continue'}
          open={opened}
          headerProps={{
            icon: Phone,
            title: 'Purchase Additional Texts and Minutes',
            subtitle: 'Select your options and proceed to secure checkout',
          }}
          onAccept={handleSubmit}
          disabled={pristine}
          handleClose={handleClose}
          submitOnEnter
          onExited={() => {
            if (isSuccess)
              stripe?.redirectToCheckout({ sessionId: data.checkoutSessionId });
            resetMutation();
            reset();
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={7}>
              <Field
                name='minutesPriceId'
                component={TextField}
                select
                variant='outlined'
                label='Minutes'
                fullWidth
                options={
                  callMinutes2400 && callMinutes12000
                    ? [
                        {
                          value: callMinutes2400.priceId,
                          label: `2,400 Minutes ($${callMinutes2400.priceAmount /
                            100})`,
                        },
                        {
                          value: callMinutes12000.priceId,
                          label: `12,000 Minutes ($${callMinutes12000.priceAmount /
                            100})`,
                        },
                      ]
                    : []
                }
              />
            </Grid>
            <Grid item xs={7}>
              <Field
                name='textsPriceId'
                component={TextField}
                select
                variant='outlined'
                label='Texts'
                fullWidth
                options={
                  textMessages5000 && textMessages25000
                    ? [
                        {
                          value: textMessages5000.priceId,
                          label: `5,000 Texts ($${textMessages5000.priceAmount /
                            100})`,
                        },
                        {
                          value: textMessages25000.priceId,
                          label: `25,000 Texts ($${textMessages25000.priceAmount /
                            100})`,
                        },
                      ]
                    : []
                }
              />
            </Grid>
            <Grid
              item
              container
              direction='column'
              alignContent='flex-start'
              spacing={1}
            >
              <Grid item xs={3} container justify='space-between'>
                <Grid item>
                  <Typography variant='subtitle2' color='textSecondary'>
                    Minutes
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant='subtitle2' color='textSecondary'>
                    {`$${getAddOnPrice(
                      'minutesPriceId',
                      values,
                      minuteAddOns
                    )}`}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={3} container justify='space-between'>
                <Grid item>
                  <Typography variant='subtitle2' color='textSecondary'>
                    Texts
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant='subtitle2' color='textSecondary'>
                    {`$${getAddOnPrice('textsPriceId', values, textAddOns)}`}
                  </Typography>
                </Grid>
              </Grid>
              <Divider />
              <Grid item xs={3} container justify='space-between'>
                <Grid item>
                  <Typography variant='subtitle2' color='textSecondary'>
                    Total:
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography>{`$${getAddOnPrice(
                    'minutesPriceId',
                    values,
                    minuteAddOns
                  ) +
                    getAddOnPrice(
                      'textsPriceId',
                      values,
                      textAddOns
                    )}`}</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DazzlingDialog>
      )}
    />
  );
}

interface AddOn {
  priceId: number;
  priceAmount: number;
}

const getAddOnPrice = (
  key: 'minutesPriceId' | 'textsPriceId',
  values: { minutesPriceId?: number; textsPriceId?: number },
  addOns: AddOn[] = []
): number => {
  if (values[key]) {
    const addOn = addOns.find(addOn => addOn.priceId === values[key]);
    if (addOn) return addOn.priceAmount / 100;
  }
  return 0;
};
