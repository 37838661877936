import React, { useEffect, useCallback, Dispatch, SetStateAction } from 'react';
import { Stripe, SetupIntent } from '@stripe/stripe-js';
//components
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from '@stripe/react-stripe-js';
import { useTheme } from '@material-ui/core';
import CardField from 'components/CardField';
//helpers
import {
  StripeConfirmPaymentIntent,
  StripeFieldErrorMessage,
} from 'components/ManageSubscription/helpers/interfaces';
import { StripePaymentStatus } from 'components/ManageSubscription/helpers/types';
import updateStripeErrorMessage from 'components/ManageSubscription/helpers/updateStripeErrorMessage';
import isItSetupIntent from 'components/ManageSubscription/helpers/isItSetupIntent';

export interface StripeCardProps {
  elements: any;
  stripe: Stripe | null;
  onStripeErrorMessageChange: Dispatch<
    SetStateAction<StripeFieldErrorMessage[]>
  >;
  onStripePaymentStatusChange: Dispatch<SetStateAction<StripePaymentStatus>>;
  mutators?: {
    setValue?: (field: string, value: string | boolean | null) => void;
  };
  stripePaymentStatus: StripePaymentStatus;
  callSubmitGlobalForm: () => void;
  onSetupIntentSuccess?: (setupIntent?: SetupIntent) => void;
  onlyConfirmPaymentIntent?: boolean;
  stripeConfirmPaymentIntent?: StripeConfirmPaymentIntent;
}

const StripeCard = ({
  elements,
  stripe,
  onStripeErrorMessageChange,
  mutators,
  stripePaymentStatus,
  onStripePaymentStatusChange,
  onlyConfirmPaymentIntent,
  callSubmitGlobalForm,
  stripeConfirmPaymentIntent,
  onSetupIntentSuccess = () => {},
}: StripeCardProps) => {
  const theme = useTheme();
  const clearFields = useCallback(() => {
    if (mutators?.setValue) {
      mutators.setValue('paymentMethodId', null);
      mutators.setValue('paymentMethodCardBrand', null);
      mutators.setValue('paymentMethodCardLast4', null);
    }
  }, [mutators]);

  const handleSubmit = async (event?: any) => {
    if (event) {
      event.preventDefault();
    }

    if (!mutators?.setValue) {
      onStripeErrorMessageChange(
        updateStripeErrorMessage('stripe', "Can't mutate values")
      );
      return;
    }

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      onStripeErrorMessageChange(
        updateStripeErrorMessage('stripe', 'Stripe has not loaded yet')
      );
      onStripePaymentStatusChange(StripePaymentStatus.PmError);
      return;
    }

    const cardElement = elements.getElement(
      CardNumberElement,
      CardExpiryElement,
      CardCvcElement
    );

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    });

    if (error || !paymentMethod) {
      //Error
      clearFields();
      onStripeErrorMessageChange(
        updateStripeErrorMessage(
          'stripe',
          'Payment information was not accepted.'
        )
      );
      onStripePaymentStatusChange(StripePaymentStatus.PmError);
      return;
    }

    const { id, card } = paymentMethod || {};
    mutators.setValue('paymentMethodId', id);
    mutators.setValue('paymentMethodCardBrand', card?.brand || '');
    mutators.setValue('paymentMethodCardLast4', card?.last4 || '');
    onStripeErrorMessageChange([]);
    onStripePaymentStatusChange(StripePaymentStatus.PmSuccess);
    callSubmitGlobalForm();
  };

  const handleChangeInput = useCallback(
    (event: any) => {
      const { complete = false, elementType = '', error } = event || {};
      if (elementType && mutators?.setValue) {
        mutators.setValue(`${elementType}Complete`, complete);
        onStripeErrorMessageChange(
          updateStripeErrorMessage(elementType, error?.message || '')
        );
      }
    },
    [mutators, onStripeErrorMessageChange]
  );

  useEffect(() => {
    if (stripePaymentStatus === StripePaymentStatus.SendRequestToGetPm) {
      onStripePaymentStatusChange(StripePaymentStatus.WaitingPm);
      handleSubmit();
    }
  }, [stripePaymentStatus]); // eslint-disable-line

  useEffect(() => {
    if (
      stripePaymentStatus === StripePaymentStatus.SendRequestToConfirmPIS &&
      stripeConfirmPaymentIntent &&
      stripe
    ) {
      onStripePaymentStatusChange(StripePaymentStatus.WaitingConfirmPIS);
      if (isItSetupIntent(stripeConfirmPaymentIntent.clientSecret)) {
        stripe
          .confirmCardSetup(stripeConfirmPaymentIntent.clientSecret, {
            payment_method: stripeConfirmPaymentIntent.paymentMethod,
          })
          .then(async result => {
            if (
              result?.error &&
              result?.error?.setup_intent?.status !== 'succeeded'
            ) {
              onStripePaymentStatusChange(StripePaymentStatus.ConfirmPISError);
              return;
            }
            onSetupIntentSuccess(
              result.setupIntent || result?.error?.setup_intent
            );
            onStripePaymentStatusChange(StripePaymentStatus.ConfirmPISSuccess);
          });
        return;
      }
      stripe
        .confirmCardPayment(stripeConfirmPaymentIntent.clientSecret, {
          payment_method: stripeConfirmPaymentIntent.paymentMethod,
        })
        .then(async result => {
          if (
            result?.error &&
            result?.error?.payment_intent?.status !== 'succeeded'
          ) {
            onStripePaymentStatusChange(StripePaymentStatus.ConfirmPISError);
            return;
          }
          onStripePaymentStatusChange(StripePaymentStatus.ConfirmPISSuccess);
        });
    }
  }, [stripePaymentStatus]); // eslint-disable-line

  return !onlyConfirmPaymentIntent ? (
    <div id='cardStripeForm' onSubmit={handleSubmit}>
      <CardField
        input={{
          onChange: handleChangeInput,
        }}
        borderSchemeColor='grey'
        baseTextColor={theme.palette.grey[900]}
        basePlaceholderColor={theme.palette.grey[500]}
        areFieldsSeparately
      />
    </div>
  ) : null;
};

export default StripeCard;
