import React from 'react';
// MUI components
import Grid from '@material-ui/core/Grid';
//components
import ChapterLeaderboard from 'routes/ChapterAnalytics/ChapterLeaderboard';
import ChapterUserData from 'routes/ChapterAnalytics/ChapterUserData';

export interface ChapterAnalyticsProps {
  isOrganizationChapterAnalytics?: boolean;
}

const ChapterAnalytics = ({
  isOrganizationChapterAnalytics,
}: ChapterAnalyticsProps) => {
  return (
    <Grid container spacing={2} alignContent='flex-start'>
      <Grid item xs={12}>
        <ChapterLeaderboard />
      </Grid>
      {isOrganizationChapterAnalytics && (
        <Grid item xs={12}>
          <ChapterUserData />
        </Grid>
      )}
    </Grid>
  );
};

export default ChapterAnalytics;
