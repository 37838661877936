import React from 'react';

// material-ui
import {
  Grid,
  InputAdornment,
  MenuItem,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { Search } from '@material-ui/icons';

// components
import Table from 'components/Table';
import TextField from 'components/TextField';
import MaterialTextField from '@material-ui/core/TextField';
import { debounce } from 'lodash';
import { EventGuestQueryParams } from 'api/eventGuests';
import DateSelector from 'components/DateSelector';
import moment from 'moment';

const rowsPerPage = 10;

export default function SelectGuestsTable({
  allSelected,
  hasFilters,
  columns,
  guestType = 'guest',
  guests,
  statuses,
  eventTimeSlots,
  queryParams,
  isSearching,
  handleAllGuestsSelectedChange,
  handleSelectedGuestsChange,
  handleResetSelectedGuests,
  onPageChange,
  onSearchChange,
  onSortChange,
  handleStatusChange,
  handleInvitedChange,
  handleRsvpChange,
  handleEtsDateChange,
  handleAttendedChange,
  searchLoading,
  selectedGuestIds,
  tableLoading,
  TablePaginationProps,
}: {
  id?: string;
  allSelected: boolean;
  hasFilters: boolean;
  columns: any[];
  guestType: 'lead' | 'member' | 'guest';
  guests: any[];
  statuses: Status[];
  eventTimeSlots: EventTimeSlot[];
  queryParams: EventGuestQueryParams;
  isSearching: boolean;
  handleAllGuestsSelectedChange: (ids: number[]) => void;
  handleSelectedGuestsChange: (eventGuest: EventGuest) => void;
  handleResetSelectedGuests: (eventGuest: EventGuest) => void;
  onSearchChange: (...args: any) => void;
  onPageChange: (...args: any) => void;
  onSortChange: (...args: any) => void;
  handleStatusChange?: (...args: any) => void;
  handleInvitedChange?: (...args: any) => void;
  handleRsvpChange?: (...args: any) => void;
  handleEtsDateChange: (...args: any) => void;
  handleAttendedChange?: (...args: any) => void;
  searchLoading: boolean;
  selectedGuestIds: any;
  tableLoading: boolean;
  TablePaginationProps?: any;
}) {
  const initialOrder = 'asc';
  const initialOrderBy = 'firstName';

  return (
    <Grid container justifyContent='flex-start' spacing={2} alignItems='center'>
      <Grid item xs={12}>
        <Typography variant='h6' id='guestsTitle'>
          Invite {guestType.charAt(0).toUpperCase()}
          {guestType.slice(1)}s
        </Typography>

        <Typography
          variant='body2'
          color='textSecondary'
          id='selectedGuestsLabel'
        >
          {allSelected
            ? `All ${hasFilters ? 'filtered' : ''}`
            : Object.keys(selectedGuestIds).length}{' '}
          selected
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Grid
          container
          justifyContent='flex-start'
          spacing={2}
          alignItems='center'
        >
          <Grid item xs={6}>
            <TextField
              id='inviteSearchInput'
              label={`Search ${guestType}s`}
              placeholder='Type name'
              loading={searchLoading}
              margin='none'
              onChange={debounce(onSearchChange, 600)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <Search />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <Tooltip
              title={isSearching ? 'Filters are disabled while searching' : ''}
            >
              <Grid
                container
                justifyContent='flex-start'
                spacing={2}
                wrap='nowrap'
                alignItems='center'
              >
                {guestType === 'lead' && (
                  <Grid item xs={3}>
                    <MaterialTextField
                      disabled={isSearching}
                      value={queryParams.statusIds}
                      fullWidth
                      variant='outlined'
                      label='Statuses'
                      onChange={handleStatusChange}
                      select
                      InputLabelProps={{
                        shrink: true,
                      }}
                      SelectProps={{
                        multiple: true,
                        displayEmpty: true,
                        renderValue: (selected: any) =>
                          selected.length === 0 ||
                          selected.length === statuses.length
                            ? 'All'
                            : statuses
                                .filter(status => selected.includes(status.id))
                                .map(status => status.abbreviation)
                                .join(', '),
                      }}
                    >
                      {statuses.map(status => (
                        <MenuItem key={status.id} value={status.id}>
                          {status.abbreviation}
                        </MenuItem>
                      ))}
                    </MaterialTextField>
                  </Grid>
                )}
                <Grid item xs={2}>
                  <MaterialTextField
                    disabled={isSearching}
                    value={queryParams.invited}
                    fullWidth
                    variant='outlined'
                    label='Invited'
                    onChange={handleInvitedChange}
                    select
                    InputLabelProps={{
                      shrink: true,
                    }}
                    SelectProps={{
                      displayEmpty: true,
                      renderValue: (selected: any) =>
                        !selected ? 'All' : selected === 'true' ? 'Yes' : 'No',
                    }}
                  >
                    {[
                      { value: null, label: 'All' },
                      { value: 'true', label: 'Yes' },
                      { value: 'false', label: 'No' },
                    ].map(option => (
                      <MenuItem key={option.value} value={option.value || ''}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </MaterialTextField>
                </Grid>
                {eventTimeSlots.length > 1 ? (
                  <Grid item xs={5}>
                    <DateSelector
                      meta={{}}
                      fullWidth
                      label='RSVP Date'
                      disabled={isSearching}
                      input={{
                        value: queryParams.startDateStart
                          ? moment(queryParams.startDateStart)
                          : null,
                        onChange: handleEtsDateChange,
                        name: 'ets',
                        onBlur: () => {},
                        onFocus: () => {},
                      }}
                    />
                  </Grid>
                ) : (
                  <Grid item xs={3}>
                    <MaterialTextField
                      disabled={isSearching}
                      value={queryParams.rsvp}
                      fullWidth
                      variant='outlined'
                      label='RSVP'
                      onChange={handleRsvpChange}
                      select
                      InputLabelProps={{
                        shrink: true,
                      }}
                      SelectProps={{
                        displayEmpty: true,
                        renderValue: (selected: any) =>
                          [
                            { value: '', label: 'All' },
                            { value: 'yes', label: 'Yes' },
                            { value: 'no', label: 'No' },
                            { value: 'maybe', label: 'Maybe' },
                            { value: 'n/a', label: 'N/A' },
                          ].find(item => item.value === selected)?.label,
                      }}
                    >
                      {[
                        { value: '', label: 'All' },
                        { value: 'yes', label: 'Yes' },
                        { value: 'no', label: 'No' },
                        { value: 'maybe', label: 'Maybe' },
                        { value: 'n/a', label: 'N/A' },
                      ].map(option => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </MaterialTextField>
                  </Grid>
                )}
                <Grid item xs={2}>
                  <MaterialTextField
                    disabled={isSearching}
                    value={queryParams.attended}
                    fullWidth
                    variant='outlined'
                    label='Attended'
                    onChange={handleAttendedChange}
                    select
                    InputLabelProps={{
                      shrink: true,
                    }}
                    SelectProps={{
                      displayEmpty: true,
                      renderValue: (selected: any) =>
                        !selected ? 'All' : selected === 'true' ? 'Yes' : 'No',
                    }}
                  >
                    {[
                      { value: null, label: 'All' },
                      { value: 'true', label: 'Yes' },
                      { value: 'false', label: 'No' },
                    ].map(option => (
                      <MenuItem key={option.value} value={option.value || ''}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </MaterialTextField>
                </Grid>
              </Grid>
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Table
          columns={columns}
          data={guests}
          variant='outlined'
          numberOfSkeletonRows={tableLoading ? rowsPerPage : 0}
          initialOrder={initialOrder}
          initialOrderBy={initialOrderBy}
          options={{ enableCheckboxes: true }}
          fetchSortedData={onSortChange}
          onPageChange={onPageChange}
          TablePaginationProps={{
            count: guests.length,
            rowsPerPage,
            rowsPerPageOptions: [rowsPerPage],
            paginationType: 'api',
            ...TablePaginationProps,
          }}
          CheckboxProps={{
            allSelected,
            selectedItems: selectedGuestIds,
            onSelect: handleSelectedGuestsChange,
            setAllSelected: handleAllGuestsSelectedChange,
            resetSelected: handleResetSelectedGuests,
          }}
          sortDisabled={isSearching}
        />
      </Grid>
    </Grid>
  );
}
