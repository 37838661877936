import React, { useCallback, memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useSelector, shallowEqual } from 'react-redux';
import { useHistory } from 'react-router-dom';
// material-ui
import { Grid, Typography } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { makeStyles } from '@material-ui/core/styles';
// components
import SuperAvatar from 'components/SuperAvatar';
// selectors
import getLoadingFromState from 'store/selectors/getLoadingFromState';
const TaskItemSection = function({
  label,
  items,
  taskId,
  divider,
  navigateTo,
}: any) {
  const classes = useStyles();
  const history = useHistory();
  const {
    location: { search },
  } = history;
  const metaId = `populating-${taskId}`;
  const { taskPopulating } = useSelector(
    state => ({
      taskPopulating: getLoadingFromState('task', metaId, false)(state),
    }),
    shallowEqual
  );
  const getFullName = ({ firstName = '', lastName = '' } = {}) => {
    return `${firstName} ${lastName}`;
  };
  let itemsToDisplay = items.slice(0, 2); // We'll display the first two items
  let itemsForTooltip = items.slice(2); // And add another to serve as the placeholder "+3 others" item
  // That is, if we need the placeholder item at all
  if (items.length > 2) {
    itemsToDisplay.push({
      id: 'placeholder',
      firstName: '+',
      lastName: itemsForTooltip.length.toString(),
    });
  }
  const otherNames = itemsForTooltip
    .map((item: any) => getFullName(item))
    .join(', ');
  const getTooltipTitle = useCallback(
    (item, index) => {
      return index < 2 ? getFullName(item) : otherNames;
    },
    [otherNames]
  );
  const handleItemClick = useCallback(
    id => {
      history.push({ pathname: `${navigateTo}${id}`, search });
    },
    [history, navigateTo, search]
  );
  const renderSkeletons = () => {
    return (
      Array(2)
        // @ts-expect-error ts-migrate(2554) FIXME: Expected 1-3 arguments, but got 0.
        .fill()
        .map((_, index) => (
          <Grid item xs={3} sm={2} lg={3} key={index}>
            <Skeleton
              id='skeleton'
              variant='circle'
              className={classes.skeleton}
            />
          </Grid>
        ))
    );
  };
  const renderItems = () => {
    if (items.length) {
      return itemsToDisplay.map((item: any = {}, index: any) => {
        // let item = Map.isMap(i) ? i : Map(); // TODO: get rid of this once members populate
        const inputProps = {};
        if (navigateTo && (item as any).id !== 'placeholder') {
          (inputProps as any).onClick = () => handleItemClick((item as any).id);
        }
        return (
          <Grid
            item
            xs={3}
            sm={2}
            lg={3}
            id='taskSectionItem'
            key={(item as any).id || index}
          >
            <SuperAvatar
              size='xsmall'
              fullName={item.fullName}
              tooltipTitle={getTooltipTitle(item, index)}
              raised
              {...inputProps}
            />
          </Grid>
        );
      });
    }
    return (
      <Grid item xs id='noneFound'>
        <Typography variant='caption' color='secondary'>
          None found.
        </Typography>
      </Grid>
    );
  };
  return (
    <Grid
      item
      xs={6}
      className={classNames(classes.section, divider && classes.divider)}
    >
      <Grid container spacing={1}>
        <Grid item xs={4} sm={5}>
          <Typography
            variant={'caption'}
            component={'span'}
            color={'textSecondary'}
          >
            {label}
          </Typography>
        </Grid>

        <Grid item xs={7}>
          <Grid container spacing={4}>
            {taskPopulating ? renderSkeletons() : renderItems()}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
TaskItemSection.propTypes = {
  taskId: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  items: PropTypes.instanceOf(Array).isRequired,
  divider: PropTypes.bool,
  navigateTo: PropTypes.string,
};
TaskItemSection.defaultProps = {
  items: [],
  navigateTo: null,
};
const useStyles = makeStyles(theme => {
  const border = `1px solid ${(theme.palette.background as any).lightPaper}`;
  return {
    section: {
      padding: 10,
      paddingLeft: 24,
    },
    divider: {
      borderRight: border,
    },
    skeleton: {
      height: 24,
      width: 24,
    },
  };
});
export default memo(TaskItemSection);
