import React from 'react';
import PropTypes from 'prop-types';
import { List, Map } from 'immutable';

// MUI components
import { makeStyles } from '@material-ui/core/styles';
import { Chip, Tooltip, Typography } from '@material-ui/core';

const AutoSuggestChips = function({
  deleteChip,
  selected,
  chipsToDisplay,
  chipsLabel,
  maxItemsForTooltip,
  maxLabelLength,
}: any) {
  const classes = useStyles();

  const chips = [];
  let othersCount = 0;
  const others: any = [];

  if (!selected.size) {
    return (
      <Typography id='noneSelectedLabel' variant='body2' color='secondary'>
        {chipsLabel}
      </Typography>
    );
  }

  selected.forEach((item: any, index: any) => {
    if (Map.isMap(item)) {
      let label = (item.get('label', '') as any).substring(0, maxLabelLength);

      if ((item.get('label', '') as any).length > maxLabelLength) {
        label = label.concat('...');
      }

      const maxItemsToDisplay = chipsToDisplay + maxItemsForTooltip;

      if (index >= chipsToDisplay) {
        if (index < maxItemsToDisplay) {
          others.push(label);
        } else if (index === maxItemsToDisplay) {
          // Trail off with ellipses Tooltip text if max items reached
          others.push('...');
        }
        othersCount += 1;
      } else if (index <= chipsToDisplay) {
        chips.push(
          <Chip
            key={item.get('value') as string}
            label={label}
            onDelete={() => deleteChip(index)}
            className={classes.chip}
          />
        );
      }
    }
  });

  if (othersCount > 0) {
    const othersString = others.join(', ');

    chips.push(
      <Tooltip title={othersString} key='others'>
        <Chip
          id='othersChip'
          label={`+ ${othersCount} others`}
          className={classes.chip}
        />
      </Tooltip>
    );
  }

  return <div className={classes.chips}>{chips}</div>;
};

AutoSuggestChips.propTypes = {
  deleteChip: PropTypes.func.isRequired,
  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'typeof List' is not assignable t... Remove this comment to see the full error message
  selected: PropTypes.instanceOf(List).isRequired,
  chipsToDisplay: PropTypes.number,
  maxItemsForTooltip: PropTypes.number,
  maxLabelLength: PropTypes.number,
  chipsLabel: PropTypes.string,
};

AutoSuggestChips.defaultProps = {
  chipsToDisplay: 5,
  maxItemsForTooltip: 1,
  maxLabelLength: 60,
  chipsLabel: 'No items selected',
};

const useStyles = makeStyles(theme => ({
  chip: {
    margin: theme.spacing(0.25),
  },

  chips: {
    margin: 5,
    display: 'flex',
    flexWrap: 'wrap',
  },
}));

export default AutoSuggestChips;
