import React, { memo, useState, useCallback } from 'react';

// icons
import { AddCircleOutline } from '@material-ui/icons';

//components
import IconButton from 'components/IconButton';
import CreateTaskModal from './CreateTaskModal';

const CreateTaskButton = memo(() => {
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleOpenDialog = useCallback(() => {
    setDialogOpen(true);
  }, []);
  const handleCloseDialog = useCallback(() => {
    setDialogOpen(false);
  }, []);

  return (
    <>
      <IconButton id='create-task' onClick={handleOpenDialog}>
        <AddCircleOutline />
      </IconButton>

      <CreateTaskModal open={dialogOpen} onClose={handleCloseDialog} />
    </>
  );
});

export default CreateTaskButton;
