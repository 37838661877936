import React from 'react';

// MUI components
import { Grid } from '@material-ui/core';

// components
import CommunicationOverview from 'routes/CommunicationAnalytics/CommunicationOverviewCard';
import LeadCommunicationCard from 'routes/CommunicationAnalytics/LeadCommunicationCard';
import MemberCommunicationCard from 'routes/CommunicationAnalytics/MemberCommunicationCard';
import PhoneLineCommunicationCard from 'routes/CommunicationAnalytics/PhoneLineCommunicationCard';

interface CommunicationAnalyticsProps {
  isOrganizationCommunicationAnalytics?: boolean;
}

const CommunicationAnalytics = ({
  isOrganizationCommunicationAnalytics,
}: CommunicationAnalyticsProps) => {
  return (
    <Grid container spacing={2} alignContent='flex-start'>
      <Grid item xs={12}>
        <CommunicationOverview
          isOrganizationCommunicationAnalytics={
            isOrganizationCommunicationAnalytics
          }
        />
      </Grid>
      {isOrganizationCommunicationAnalytics ? (
        <Grid item xs={12}>
          <PhoneLineCommunicationCard />
        </Grid>
      ) : (
        <>
          <Grid item xs={12} sm={6}>
            <LeadCommunicationCard />
          </Grid>
          <Grid item xs={12} sm={6}>
            <MemberCommunicationCard />
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default CommunicationAnalytics;
