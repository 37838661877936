import React, { useEffect, useCallback } from 'react';
// material-ui
import { withStyles, WithStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { ArrowDownward } from '@material-ui/icons';
// components
import Table from 'components/Table';
import Button from 'components/Button';
// action creators
import { fetchGroups, clearGroups } from 'store/actions/groupActions';
// selectors
import getCurrentGroupId from 'store/selectors/getCurrentGroupId';
// hooks
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import useInfiniteScroll from 'helpers/hooks/useInfiniteScroll';
import { usePrevious } from 'helpers/hooks/usePrevious';
import useChapterFetchParams from 'helpers/hooks/useChapterFetchParams';
// selectors
import getIsSystemAdmin from 'store/selectors/getIsSystemAdmin';
import getIsHqAdmin from 'store/selectors/getIsHqAdmin';
import getIsCurrentGroupOrganization from 'store/selectors/getIsCurrentGroupOrganization';
import getNextPageFromState from 'store/selectors/getNextPageFromState';
import getLoadingFromState from 'store/selectors/getLoadingFromState';
import getIsCouncilAdmin from 'store/selectors/getIsCouncilAdmin';
//Helpers
import getTableColumns from 'routes/ChapterList/ChapterListTable/helpers/getTableColumns';
import { InitialParams } from 'routes/ChapterList/helpers/getInitialParams';
//Styles
import chapterListTableStyles from 'routes/ChapterList/ChapterListTable/chapterListTable.style';

export interface ChapterListTableProps
  extends WithStyles<typeof chapterListTableStyles> {
  loading?: boolean;
  data: any; // TODO: PropTypes.instanceOf(Array)
  initialParams: InitialParams;
  selectChapter: (chapterId: number) => void;
}

const ChapterListTable = ({
  classes,
  data,
  loading,
  initialParams,
  selectChapter,
}: ChapterListTableProps) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    location: { pathname, search },
  } = history;
  const paginationMetaId = 'greekChapter';

  const {
    currentGroupId,
    isSystemAdmin,
    isHqAdmin,
    isCouncilAdmin,
    isCurrentGroupOrganization,
    groupNextPage,
    currentGroupLoading,
  } = useSelector(
    state => ({
      currentGroupId: getCurrentGroupId(state),
      isSystemAdmin: getIsSystemAdmin(state),
      isHqAdmin: getIsHqAdmin(state),
      isCouncilAdmin: getIsCouncilAdmin(state),
      isCurrentGroupOrganization: getIsCurrentGroupOrganization(state),
      groupNextPage: getNextPageFromState('group', paginationMetaId)(state),
      currentGroupLoading: getLoadingFromState(
        'currentGroup',
        null,
        false
      )(state),
    }),
    shallowEqual
  );

  const [isBottom, scrollRef] = useInfiniteScroll(Boolean(loading), 200);
  const previousIsBottom = usePrevious(isBottom);

  const hasNextPage = !!groupNextPage;
  const urlParams = new URLSearchParams(search);

  const {
    initialOrder,
    initialOrderBy,
    type: groupType,
    search: searchParam,
    parentId: groupParam,
    orderBy,
    labelsString,
    filters,
  } = initialParams || {};

  const { latestActivityEnd, latestActivityStart } = filters || {};

  const orderingDisabled = !!searchParam;
  const params = useChapterFetchParams({
    ...(initialParams || {}),
    ...(filters || {}),
  });

  const columns = getTableColumns(isSystemAdmin, orderingDisabled);

  // Fetches data: initially, on filter changes, and on search changes
  useEffect(() => {
    if (!currentGroupLoading && params) {
      dispatch(clearGroups());
      dispatch(fetchGroups(params));
    }
    // Including params in the dependencies leads to infinite loop of fetching
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    groupParam,
    latestActivityStart,
    latestActivityEnd,
    labelsString,
    searchParam,
    currentGroupLoading,
    orderBy,
  ]);

  // Fetch initial chapters, orgs, and councils
  useEffect(() => {
    if (
      isSystemAdmin &&
      !isCurrentGroupOrganization &&
      !groupParam &&
      !currentGroupId &&
      !currentGroupLoading
    ) {
      if (groupType !== 'GREEK_CHAPTER') {
        dispatch(
          fetchGroups({
            search: groupType === 'GREEK_CHAPTER' ? searchParam : '',
            type: 'GREEK_CHAPTER',
            orderBy: 'name',
          })
        );
      }
      dispatch(
        fetchGroups({
          search: groupType === 'GREEK_ORGANIZATION' ? searchParam : '',
          type: 'GREEK_ORGANIZATION',
          orderBy: 'name',
        })
      );
      dispatch(
        fetchGroups({
          type: 'COUNCIL',
          orderBy: 'name',
          search: groupType === 'COUNCIL' ? searchParam : '',
        })
      );
    }
  }, [
    currentGroupId,
    currentGroupLoading,
    dispatch,
    groupParam,
    groupType,
    isCurrentGroupOrganization,
    isSystemAdmin,
    searchParam,
  ]);

  const onFetchNextPage = useCallback(() => {
    if (!loading && groupNextPage) {
      if (groupType === 'GREEK_CHAPTER') {
        dispatch(
          fetchGroups({
            page: groupNextPage,
            appendResult: true,
            ...params,
          })
        );
      } else if (!loading && groupNextPage) {
        dispatch(
          fetchGroups({
            page: groupNextPage,
            appendResult: true,
            ...params,
          })
        );
      }
    }
  }, [loading, groupNextPage, groupType, dispatch, params]);

  const handleChapterSelect = useCallback(
    (chapter: { id: number }) => {
      if (chapter?.id) {
        selectChapter(chapter.id);
      }
    },
    [selectChapter]
  );

  useEffect(() => {
    if (!previousIsBottom && isBottom) {
      onFetchNextPage();
    }
  }, [isBottom, previousIsBottom]); // eslint-disable-line

  const fetchSortedData = (property: any, isNewOrderDesc: any) => {
    const newSearch = urlParams;
    const newOrderBy = isNewOrderDesc ? property : `-${property}`;
    urlParams.set('orderBy', newOrderBy);
    history.push({ pathname, search: newSearch.toString() });
  };

  const destinationGroup = ({ id }: any = {}) => {
    return `/home?group=${id}`;
  };

  return (
    // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
    <Grid className={classes.listContainer} ref={scrollRef} container>
      {!loading && !data.length ? (
        <Grid item xs={12}>
          <Grid container>
            <Grid
              item
              xs={12}
              sm={10}
              md={8}
              lg={6}
              className={classes.noneFoundContainer}
            >
              <Typography variant='body1'>No Groups Found.</Typography>
              <Typography variant='body2' color='secondary'>
                Add groups above
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Table
          columns={columns}
          data={data}
          numberOfSkeletonRows={loading ? 10 : 0}
          options={{
            enableCheckboxes: isSystemAdmin || isHqAdmin || isCouncilAdmin,
            enableHeaderCheckbox: false,
          }}
          fetchSortedData={fetchSortedData}
          initialOrder={initialOrder}
          initialOrderBy={orderingDisabled ? null : initialOrderBy}
          TableProps={{
            className: classes.table,
            stickyHeader: true,
          }}
          TableRowProps={{
            classes: {
              root: classes.root,
              selected: classes.selected,
              hover: classes.hover,
            },
            hover: true,
            hasLinkTo: !isCouncilAdmin && destinationGroup,
            checkboxTooltipTitle: 'Select group',
          }}
          TableCellProps={{
            className: classes.tableCell,
          }}
          HeaderTableCellProps={{
            className: classes.headerTableCell,
          }}
          CheckboxProps={{
            onSelect: handleChapterSelect,
          }}
          SkeletonProps={{
            height: 80,
          }}
        />
      )}

      {hasNextPage && Boolean(data.length) && (
        <Grid item xs={12} className={classes.loadMoreButton}>
          <Button
            variant='outlined'
            onClick={onFetchNextPage}
            startIcon={<ArrowDownward />}
          >
            Load More
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

export default withStyles(chapterListTableStyles, { withTheme: true })(
  ChapterListTable
);
