import { Map } from 'immutable';
import { all, call, fork, put, takeEvery, select } from 'redux-saga/effects';

// action creators
import { doneIndicator, success } from 'store/actions/httpActions';

// selectors
import getCurrentGroupId from 'store/selectors/getCurrentGroupId';

// api
import client from 'sources/api';

// constants
import {
  MILESTONE_DELETE_REQUEST,
  MILESTONE_DELETE,
} from 'store/constants/milestoneTypes';

// schemas
import { milestoneSchema } from 'store/schemas/milestoneSchema';

// helpers
import httpSaga from 'store/sagas/httpSaga';

const WATCH_TYPE = MILESTONE_DELETE_REQUEST;
const TYPE = MILESTONE_DELETE;

export function* deleteMilestone(action: any) {
  const {
    payload: { formName, id },
  } = action;

  const currentGroupId = yield select(getCurrentGroupId);

  // @ts-expect-error ts-migrate(2569) FIXME: Type 'Generator<any, any, unknown>' is not an arra... Remove this comment to see the full error message
  yield* httpSaga(
    TYPE,
    call(client.delete, `/tags/${id}?group_id=${currentGroupId}`),
    {
      formName,
      dispatchSuccess: false,
      shouldDestroyForm: false,
      schema: milestoneSchema,
    }
  );
  yield put(success(TYPE, Map({ id })));
  yield put(doneIndicator(TYPE));
}

export function* watch() {
  yield takeEvery(WATCH_TYPE, deleteMilestone);
}

export default function* root() {
  yield all([fork(watch)]);
}
