import React, { useCallback, useEffect } from 'react';
import { isEqual } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';

// MUI components
import { Grid, Card, CardContent, Hidden } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { LinearScale } from '@material-ui/icons';

// components
import Header from 'components/Header';
import Table from 'components/Table';
import ExportButton from 'components/ExportButton';
import ReportRefreshButton from 'components/ReportRefreshButton';

// action creators
import {
  createRecruitmentActivityMilestonesReport,
  createNewMembersMilestonesReport,
} from 'store/actions/apiTaskActions';

// selectors
import getCurrentGroupId from 'store/selectors/getCurrentGroupId';
import getForm from 'store/selectors/getForm';
import getSliceEntityById from 'store/selectors/getSliceEntityById';

// helpers
import { isEmpty } from 'helpers/check';

type Props = {
  reportFormName: string;
  exportFormName: string;
  statusPositions?: number[];
  isOrganizationMemberAnalytics?: boolean;
};

export default function RecruitmentActivityMilestonesCard({
  reportFormName,
  exportFormName,
  statusPositions,
  isOrganizationMemberAnalytics,
}: Props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const initialOrder = 'asc';
  const initialOrderBy = 'milestoneTitle';
  const rowsPerPage = 10;
  const {
    currentGroupId,
    report: {
      data: { content = [], meta: { columns = [] } = {} } = {},
      id: reportId,
    },
    form: { isSubmitting, hasSubmitFailed },
  } = useSelector(
    state => ({
      currentGroupId: getCurrentGroupId(state),
      form: getForm(reportFormName)(state),
      report: getSliceEntityById(
        'report',
        isOrganizationMemberAnalytics
          ? 'ReportTypes.NEW_MEMBERS_MILESTONES'
          : 'ReportTypes.RECRUITMENT_ACTIVITY_MILESTONES',
        {}
      )(state),
    }),
    isEqual
  );

  const createReportRecruitmentActivityMilestones = useCallback(
    ({ forceRefresh = false }: { forceRefresh: boolean }) => {
      dispatch(
        createRecruitmentActivityMilestonesReport({
          groupId: currentGroupId,
          statusPositions,
          formName: reportFormName,
          forceRefresh,
        })
      );
    },
    [dispatch, currentGroupId, statusPositions, reportFormName]
  );

  useEffect(() => {
    if (!isEmpty(statusPositions) && !isOrganizationMemberAnalytics) {
      createReportRecruitmentActivityMilestones({ forceRefresh: false });
    }
  }, [
    createReportRecruitmentActivityMilestones,
    statusPositions,
    isOrganizationMemberAnalytics,
  ]);

  const createReportNewMembersMilestones = useCallback(
    ({ forceRefresh = false }: { forceRefresh: boolean }) => {
      dispatch(
        createNewMembersMilestonesReport({
          groupId: currentGroupId,
          formName: reportFormName,
          forceRefresh,
        })
      );
    },
    [dispatch, currentGroupId, reportFormName]
  );

  useEffect(() => {
    if (isOrganizationMemberAnalytics) {
      createReportNewMembersMilestones({ forceRefresh: false });
    }
  }, [isOrganizationMemberAnalytics, createReportNewMembersMilestones]);

  return (
    <Card>
      <CardContent className={classes.cardContent}>
        <Grid container>
          <Grid item xs>
            <Header
              title='Milestone Usage'
              subtitle={
                isOrganizationMemberAnalytics
                  ? 'Examine the relationship building process for new members through the lens of milestones'
                  : 'Examine the joining process through the lens of milestones'
              }
              icon={LinearScale}
              compact
            />
          </Grid>

          <Hidden xsDown>
            <Grid item xs={2} md={3}>
              <Grid container justifyContent='flex-end'>
                <Grid item>
                  <ReportRefreshButton
                    loading={isSubmitting}
                    onRefresh={
                      isOrganizationMemberAnalytics
                        ? createReportNewMembersMilestones
                        : createReportRecruitmentActivityMilestones
                    }
                  />
                </Grid>
                <ExportButton
                  params={{ reportId }}
                  disabled={
                    (!statusPositions?.length &&
                      !isOrganizationMemberAnalytics) ||
                    isSubmitting
                  }
                />
              </Grid>
            </Grid>
          </Hidden>

          <Grid item xs={12}>
            <Table
              columns={
                isOrganizationMemberAnalytics ? columns : columns.slice(1)
              }
              data={content}
              variant='outlined'
              emptyMessage={
                hasSubmitFailed
                  ? 'Error: Report Failed To Load'
                  : 'No data found.'
              }
              emptyMessageColor={hasSubmitFailed ? 'error' : 'textSecondary'}
              numberOfSkeletonRows={isSubmitting ? 8 : 0}
              initialOrder={initialOrder}
              initialOrderBy={initialOrderBy}
              TablePaginationProps={{
                count: content.length,
                rowsPerPage,
                rowsPerPageOptions: [rowsPerPage],
              }}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
const useStyles = makeStyles(theme => ({
  cardContent: {
    paddingLeft: 24,
    paddingRight: 24,
  },
  exportButton: {
    textAlign: 'right',
  },
}));
