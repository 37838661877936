import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

// action creators
import { doneIndicator } from 'store/actions/httpActions';

// api
import client from 'sources/api';

// constants
import { THREAD_READ_REQUEST, THREAD_READ } from 'store/constants/threadTypes';

// schemas
import { threadSchema } from 'store/schemas/threadSchema';

// helpers
import httpSaga from 'store/sagas/httpSaga';

const WATCH_TYPE = THREAD_READ_REQUEST;
const TYPE = THREAD_READ;

export function* readThreadSaga(action: any) {
  const {
    payload: { groupId, threadId },
  } = action;

  // @ts-expect-error ts-migrate(2569) FIXME: Type 'Generator<any, any, unknown>' is not an arra... Remove this comment to see the full error message
  yield* httpSaga(
    TYPE,
    call(client.post, `/threads/${threadId}/mark_as_read?group_id=${groupId}`),
    {
      schema: threadSchema,
    }
  );

  yield put(doneIndicator(TYPE));
}

export function* watch() {
  yield takeEvery(WATCH_TYPE, readThreadSaga);
}

export default function* root() {
  yield all([fork(watch)]);
}
