import React, { useCallback } from 'react';
import { Field } from 'react-final-form';

// material-ui
import { Grid, Box, useMediaQuery, Hidden, Divider } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';

// components
import IconButton from 'components/IconButton';
import TextField from 'components/TextField';
import ColorSelector, { defaultColor } from 'components/ColorSelector';
import ToggleChip from 'components/ToggleChip';

// icons
import { Delete } from '@material-ui/icons';

type Props = {
  fieldPrefix?: string;
  title?: string;
  color?: string;
  onDelete?: (...args: any[]) => any;
};

export default function LabelFields({
  fieldPrefix,
  title,
  color,
  onDelete,
}: Props) {
  const theme = useTheme();
  const isExtraSmall = useMediaQuery(theme.breakpoints.only('xs'));
  const handleClick = useCallback(
    // @ts-expect-error ts-migrate(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
    () => onDelete(Number(fieldPrefix.match(/\d+/))),
    [onDelete, fieldPrefix]
  );

  return (
    <Grid
      container
      alignItems='center'
      spacing={isExtraSmall ? 1 : 3}
      wrap={isExtraSmall ? 'wrap' : 'nowrap'}
    >
      <Hidden smUp>
        <Grid item xs={12}>
          <Divider style={{ marginTop: 10, marginBottom: 10 }} />
        </Grid>
      </Hidden>

      <Grid item xs={9} sm={2}>
        <Box marginRight={3}>
          {/* @ts-expect-error ts-migrate(2322) FIXME: Type '{ children: string | undefined; color: strin... Remove this comment to see the full error message */}
          <ToggleChip color={color} selected hideSelectIcon disabled>
            {title}
          </ToggleChip>
        </Box>
      </Grid>

      <Hidden smUp>
        <Grid item xs={3}>
          <Box clone display='block' marginLeft='auto'>
            <IconButton onClick={handleClick}>
              <Delete />
            </IconButton>
          </Box>
        </Grid>
      </Hidden>

      <Grid item xs={12} sm={3}>
        <Field
          fullWidth
          name={`${fieldPrefix}.title`}
          label='Title'
          variant='outlined'
          component={TextField}
        />
      </Grid>

      <Grid item xs={12} sm={3}>
        <Field
          name={`${fieldPrefix}.color`}
          label='Color'
          variant='outlined'
          component={ColorSelector}
          fullWidth
          {...(!color ? { initialValue: defaultColor } : {})}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <Field
          fullWidth
          name={`${fieldPrefix}.description`}
          label='Description'
          variant='outlined'
          component={TextField}
        />
      </Grid>
      <Hidden xsDown>
        <Grid item xs={1}>
          <Box clone display='block' marginLeft='auto'>
            <IconButton onClick={handleClick}>
              <Delete />
            </IconButton>
          </Box>
        </Grid>
      </Hidden>
    </Grid>
  );
}
