import React, { useState, useMemo, useCallback } from 'react';
import { Map, List } from 'immutable';
import { roles } from 'helpers/getRoles';
// hooks
import { useParams, useHistory } from 'react-router-dom';
import { useSelector, shallowEqual } from 'react-redux';
import { useIsFeatureEnabled } from 'helpers/hooks/featureHooks';
// material-ui
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useMediaQuery } from '@material-ui/core';
// icons
import {
  Edit,
  NoteAdd,
  DeleteOutline,
  ArrowDropDown,
  ContactMail,
  Email,
  Sms,
  Phone,
} from '@material-ui/icons';
// components
import Button from 'components/Button';
import MegaMenu from 'components/MegaMenu';
import DeleteLeadModal from './DeleteLeadModal';
import UpdateLeadInfoModal from './UpdateLeadInfoModal';
import CreateNoteTagModal from 'components/CreateNoteTagModal';
import AssignMainContactModal from 'components/AssignMainContactModal';
import CreateEmailModal from 'components/CreateEmailModal';
import ClearVotesModal from 'components/ClearVotesModal';
// selectors
import getPermission from 'store/selectors/getPermission';
import getIsSystemAdmin from 'store/selectors/getIsSystemAdmin';

type OwnProps = {
  loading?: boolean;
  lead: any; // TODO: PropTypes.instanceOf(Map)
  isCouncilLeadPage?: boolean | null;
};
// @ts-expect-error ts-migrate(2456) FIXME: Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof LeadActionsButton.defaultProps;
// @ts-expect-error ts-migrate(7022) FIXME: 'LeadActionsButton' implicitly has type 'any' beca... Remove this comment to see the full error message
const LeadActionsButton = ({ loading, lead, isCouncilLeadPage }: Props) => {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const { location: { search, state } = {} } = history;
  const isExtraSmall = useMediaQuery(theme.breakpoints.only('xs'));
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'leadId' does not exist on type '{}'.
  const { leadId = '' } = useParams();
  const {
    isSystemAdmin,
    canAssignMainContact,
    canCreateCall,
    canCreateMessages,
    canCreateNote,
    canDeleteLead,
    canPatchLead,
    canClearVotes,
  } = useSelector(
    state => ({
      isSystemAdmin: getIsSystemAdmin(state),
      canDeleteLead: getPermission('pnm.deleteOne')(state),
      canPatchLead: getPermission('pnm.updateOne')(state),
      canCreateNote: getPermission('note.createMany')(state),
      canCreateMessages: getPermission('message.createMany')(state),
      canCreateCall: getPermission('call.createOne')(state),
      canAssignMainContact: ![roles.coach, roles.member].includes(
        (state as any).getIn(['currentUser', 'data', 'roleId'], roles.member)
      ),
      canClearVotes:
        (state as any).getIn(
          ['currentUser', 'data', 'roleId'],
          roles.member
        ) === roles.admin,
    }),
    shallowEqual
  );
  const [actionsAnchorEl, setActionsAnchorEl] = useState(null);
  const [modalKey, setModalKey] = useState(null);
  const leadEmail = lead?.emailAddress;
  const leadPhoneNumber = lead?.phoneNumber;
  const textsEnabled = useIsFeatureEnabled('texts');
  const emailsEnabled = useIsFeatureEnabled('emails');
  const callsEnabled = useIsFeatureEnabled('calls');
  const handleClose = useCallback(() => setModalKey(null), []);
  const toggleModalKey = useCallback(newKey => setModalKey(newKey), []);
  const setAnchorToNull = useCallback(() => {
    setActionsAnchorEl(null);
  }, []);
  const setAnchorToTarget = useCallback(e => {
    setActionsAnchorEl(e.currentTarget);
  }, []);
  const navigateToMessages = useCallback(() => {
    history.push({
      pathname: '/messages',
      search,
      state: {
        recipients: [{ value: lead.id, label: lead.fullName }],
        // @ts-expect-error ts-migrate(2698) FIXME: Spread types may only be created from object types... Remove this comment to see the full error message
        ...state,
      },
    });
  }, [history, lead, search, state]);
  const navigateToCalls = useCallback(() => {
    history.push({
      pathname: '/calls',
      state: {
        newCallQueueLeadIds: [Number(lead.id)],
        // @ts-expect-error ts-migrate(2698) FIXME: Spread types may only be created from object types... Remove this comment to see the full error message
        ...state,
      },
    });
  }, [history, lead, state]);
  const leadActionsMenu = useMemo(
    () =>
      List([
        Map({
          id: 'leadActions',
          items: List([
            Map({
              id: 'createNoteTag',
              display: canCreateNote,
              label: 'Add Note',
              icon: <NoteAdd />,
              onClick: () => toggleModalKey('createNoteTag'),
            }),
            Map({
              display: isSystemAdmin || canAssignMainContact,
              id: 'assignMainContact',
              label: 'Assign Main Contact',
              icon: <ContactMail />,
              onClick: () => toggleModalKey('assignMainContact'),
            }),
            Map({
              display:
                canCreateCall &&
                callsEnabled &&
                leadPhoneNumber &&
                !isCouncilLeadPage,
              id: 'call',
              label: 'Call',
              icon: <Phone />,
              onClick: navigateToCalls,
            }),
            Map({
              display: leadEmail && canCreateMessages && emailsEnabled,
              id: 'email',
              label: 'Email',
              icon: <Email />,
              onClick: () => toggleModalKey('email'),
            }),
            Map({
              display: canCreateMessages && textsEnabled && leadPhoneNumber,
              id: 'text',
              label: 'Text',
              icon: <Sms />,
              onClick: navigateToMessages,
            }),
            Map({
              display: canPatchLead,
              id: 'updateLead',
              label: 'Update Info',
              icon: <Edit />,
              onClick: () => toggleModalKey('updateLead'),
            }),
          ]),
        }),
        Map({
          display: canDeleteLead,
          id: 'leadDeleteAction',
          items: List([
            Map({
              id: 'clearVotes',
              display: (isSystemAdmin || canClearVotes) && !isCouncilLeadPage,
              label: 'Clear Endorsements',
              icon: <DeleteOutline />,
              color: 'primary',
              onClick: () => toggleModalKey('clearVotes'),
            }),
            Map({
              id: 'deleteLead',
              label: 'Delete Lead',
              icon: <DeleteOutline color='primary' />,
              color: 'primary',
              onClick: () => toggleModalKey('deleteLead'),
            }),
          ]),
        }),
      ]),
    [
      canCreateNote,
      canAssignMainContact,
      canCreateCall,
      callsEnabled,
      leadPhoneNumber,
      navigateToCalls,
      leadEmail,
      canCreateMessages,
      emailsEnabled,
      textsEnabled,
      navigateToMessages,
      canPatchLead,
      canDeleteLead,
      canClearVotes,
      toggleModalKey,
      isCouncilLeadPage,
      isSystemAdmin,
    ]
  );
  return (
    <div className={classes.leadActionsContainer} id='leadProfileActions'>
      <Button
        id='leadProfileActionsButton'
        disabled={loading}
        color='primary'
        variant='contained'
        endIcon={<ArrowDropDown />}
        onClick={setAnchorToTarget}
      >
        Actions
      </Button>
      <MegaMenu
        paperId={'leadProfileActionsMenu'}
        anchorEl={actionsAnchorEl}
        handleClose={setAnchorToNull}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: isExtraSmall ? 'center' : 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: isExtraSmall ? 'center' : 'right',
        }}
        menuSections={leadActionsMenu}
      />
      {!!modalKey && (
        <>
          <CreateNoteTagModal
            open={modalKey === 'createNoteTag'}
            onClose={handleClose}
            leadIds={[leadId.toString()]}
          />
          <UpdateLeadInfoModal
            open={modalKey === 'updateLead'}
            onClose={handleClose}
          />
          <AssignMainContactModal
            open={modalKey === 'assignMainContact'}
            onClose={handleClose}
          />
          <CreateEmailModal
            open={modalKey === 'email'}
            onClose={handleClose}
            leadIds={[leadId.toString()]}
            isCouncilLeadPage={isCouncilLeadPage}
          />
          <ClearVotesModal
            open={modalKey === 'clearVotes'}
            onClose={handleClose}
            leadIds={[leadId]}
          />
          <DeleteLeadModal
            open={modalKey === 'deleteLead'}
            onClose={handleClose}
          />
        </>
      )}
    </div>
  );
};
LeadActionsButton.defaultProps = {
  loading: false,
};
const useStyles = makeStyles(theme => {
  return {
    leadActionsContainer: {
      alignItems: 'center',
      display: 'flex',
      height: 60,
      marginLeft: 'auto',
      margin: 8,
      marginRight: 20,
    },
  };
});
export default LeadActionsButton;
