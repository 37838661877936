import React, { useState, useCallback, useMemo, memo, useEffect } from 'react';
import { useHistory } from 'react-router';
import { Map, List } from 'immutable';
import { memoize, isEqual } from 'lodash';
import { roles } from 'helpers/getRoles';
import pluralize from 'pluralize';

// hooks
import { useSelector } from 'react-redux';
import { useIsFeatureEnabled } from 'helpers/hooks/featureHooks';
import useFetchFilters from 'helpers/hooks/useFetchFilters';

// material-ui
import {
  Grid,
  Checkbox as MuiCheckbox,
  Tooltip,
  Fade,
  Typography,
  Hidden,
  Box,
  WithStyles,
  withStyles,
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

// icons
import {
  AddCircle,
  Sms,
  SwapVert as UpdateStatusIcon,
  DeleteOutline,
  Phone,
  Share as ShareIcon,
  Ballot as ReferralSourceIcon,
} from '@material-ui/icons';
import DeleteIcon from '@material-ui/icons/Delete';
import Email from '@material-ui/icons/Email';
import AssignContactIcon from '@material-ui/icons/ContactMail';
import CreateNoteTagIcon from '@material-ui/icons/NoteAdd';
import GetApp from '@material-ui/icons/GetApp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ViewListIcon from '@material-ui/icons/ViewList';
import ViewModuleIcon from '@material-ui/icons/ViewModule';

// components
import Button from 'components/Button';
import IconButton from 'components/IconButton';
import MegaMenu from 'components/MegaMenu';
import CreateLeadModal from './CreateLeadModal';
import UpdateStatusModal from 'components/UpdateStatusModal';
import DeleteLeadsModal from './DeleteLeadsModal';
import LeadListSort from './LeadListSort';
import AssignMainContactModal from 'components/AssignMainContactModal';
import AssignReferralSourceModal from 'components/AssignReferralSourceModal';
import CreateEmailModal from 'components/CreateEmailModal';
import ExportLeadsModal from './ExportLeadsModal';
import ClearVotesModal from 'components/ClearVotesModal';
import AddTagModal from 'routes/LeadList/components/AddTagModal';
import ManuallyPushLeadsModal from 'routes/LeadList/LeadListView/LeadListActions/ManuallyPushLeadsModal';
import CreateNoteTagModal from 'components/CreateNoteTagModal';

// selectors
import getPermission from 'store/selectors/getPermission';
import getLoadingFromState from 'store/selectors/getLoadingFromState';
import getIsSystemAdmin from 'store/selectors/getIsSystemAdmin';

// helpers
import { LeadsQuery } from 'routes/LeadList/helpers/interfaces';
import { isEnableManualLeadSharing } from 'routes/CommunitySettings/helpers/pushLeadField';

// styles
import leadListActionsStyles from 'routes/LeadList/LeadListView/LeadListActions/leadListActions.style';

const BULK_ACTIONS = [
  'createTag',
  'createNoteTag',
  'selectedActions',
  'assignMainContact',
  'assignReferralSource',
  'deleteLeads',
  'updateStatus',
  'email',
  'exportLeads',
  'manuallyPushLeads',
];

export interface LeadListActionsProps
  extends WithStyles<typeof leadListActionsStyles> {
  allSelected: boolean;
  listType: 'card' | 'table';
  totalLeadsCount: number;
  actionLeadIds: number[];
  leads: Lead[];
  tags?: Tag[];
  leadsQuery: LeadsQuery;
  onListTypeToggle: (...args: any[]) => any;
  onSelectAllFetched: () => void;
  onResetSelected: () => void;
  onSelectAll: () => void;
  groupId: number;
  isCouncilLeadPage?: boolean | null;
  pushLead?: string;
}
const LeadListActions = ({
  classes,
  allSelected,
  listType,
  onListTypeToggle,
  onSelectAllFetched,
  onResetSelected,
  totalLeadsCount,
  actionLeadIds,
  onSelectAll,
  leads,
  isCouncilLeadPage,
  tags,
  leadsQuery,
  pushLead,
  groupId,
}: LeadListActionsProps) => {
  // permission checks
  const textsEnabled = useIsFeatureEnabled('texts');
  const emailsEnabled = useIsFeatureEnabled('emails');
  const rollingCallsEnabled = useIsFeatureEnabled('rolling_calls');

  // routing init
  const history = useHistory();
  const { location: { search } = {} } = history;
  // @ts-ignore
  const urlParams = new URLSearchParams(search);
  const searchParam = urlParams.get('search');

  // component state init
  const [actionsAnchorEl, setActionsAnchorEl] = useState(null);
  const [modalKey, setModalKey] = useState(null);

  // filters
  const filters = useFetchFilters();

  const {
    isSystemAdmin,
    canUpdateLead,
    canDeleteLead,
    canExportLeads,
    canAssignMainContact,
    canAssignReferralSource,
    canCreateMessages,
    canCreateNotes,
    canClearVotes,
    leadsLoading,
    canMakeRollingCalls,
  } = useSelector(
    state => ({
      isSystemAdmin: getIsSystemAdmin(state),
      canUpdateLead: getPermission('pnm.updateMany')(state),
      canDeleteLead: getPermission('pnm.deleteOne')(state),
      canCreateMessages: getPermission('message.createMany')(state),
      canCreateNotes: getPermission('note.createMany')(state),
      leadsLoading: getLoadingFromState('lead', null, false)(state),
      canExportLeads: ![roles.coach, roles.member].includes(
        (state as any).getIn(['currentUser', 'data', 'roleId'], roles.member)
      ),
      canAssignMainContact: ![roles.coach, roles.member].includes(
        (state as any).getIn(['currentUser', 'data', 'roleId'], roles.member)
      ),
      canAssignReferralSource: ![roles.coach, roles.member].includes(
        (state as any).getIn(['currentUser', 'data', 'roleId'], roles.member)
      ),
      canClearVotes:
        (state as any).getIn(
          ['currentUser', 'data', 'roleId'],
          roles.member
        ) === roles.admin,
      canMakeRollingCalls:
        (state as any).getIn(
          ['currentUser', 'data', 'roleId'],
          roles.member
        ) === roles.admin && rollingCallsEnabled,
    }),
    isEqual
  );

  const { refetchLeads } = leadsQuery || {};

  const idsWithEmails = useMemo(() => {
    if (modalKey === 'email') {
      return actionLeadIds.filter(leadId => {
        const leadData = leads.find(lead => lead.id === leadId);
        return leadData?.emailAddress;
      });
    }
    return actionLeadIds;
  }, [modalKey, leads, actionLeadIds]);

  const handleModalKeyChange = useCallback(
    newKey =>
      memoize(() =>
        setModalKey(oldKey => {
          // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
          if (newKey === null && BULK_ACTIONS.includes(oldKey))
            onResetSelected();
          return newKey;
        })
      ),
    [onResetSelected]
  );

  const redirectToMessages = useCallback(
    () =>
      history.push({
        pathname: '/messages',
        state: {
          recipients: allSelected
            ? [
                {
                  label: !Object.keys(filters).length
                    ? actionLeadIds.length === 0
                      ? 'All leads'
                      : `${totalLeadsCount - actionLeadIds.length} ` +
                        `${pluralize('lead', totalLeadsCount)}`
                    : `${totalLeadsCount - actionLeadIds.length} filtered ` +
                      `${pluralize('lead', totalLeadsCount)}`,
                  value: 'all',
                  filters,
                  excludedLeadIds: actionLeadIds,
                },
              ]
            : leads.reduce(
                (acc: any, { id, fullName }: any) =>
                  actionLeadIds.includes(id)
                    ? [...acc, { value: id, label: fullName }]
                    : acc,
                []
              ),
        },
      }),
    [history, leads, actionLeadIds, allSelected, filters, totalLeadsCount]
  );

  const addToCallQueue = useCallback(() => {
    const leadsToBeAdded: Lead[] = leads.filter((lead: Lead) =>
      actionLeadIds.includes(lead.id)
    );

    const leadsWithoutPhoneNumber: Lead[] = [];
    const newCallQueueLeadIds: number[] = [];

    for (const lead of leadsToBeAdded)
      if (lead.phoneNumber) newCallQueueLeadIds.push(lead.id);
      else leadsWithoutPhoneNumber.push(lead);

    history.push({
      pathname: '/calls',
      state: { leadsWithoutPhoneNumber, newCallQueueLeadIds },
    });
  }, [history, leads, actionLeadIds]);

  const selectedLeadsCount = allSelected
    ? totalLeadsCount - actionLeadIds.length
    : actionLeadIds.length;

  const areLeadsSelected = selectedLeadsCount > 0;
  const headerCount = allSelected ? 'all' : `${actionLeadIds.length} selected`;

  let leadActionsMenu = List([
    Map({
      sectionHeader: 'Overall Actions',
      id: 'overallActions',
      items: List([
        Map({
          id: 'newLead',
          label: 'New Lead',
          icon: <AddCircle />,
          onClick: handleModalKeyChange('newLead'),
        }),
      ]),
    }),
    Map({
      display: areLeadsSelected,
      sectionHeader: `Actions for ${headerCount} ${pluralize(
        'lead',
        selectedLeadsCount
      )}`,
      id: 'selectedActions',
      items: List([
        Map({
          id: 'addToCallQueue',
          display: canMakeRollingCalls && !allSelected,
          label: 'Add To Call Queue',
          icon: <Phone />,
          onClick: addToCallQueue,
        }),
        Map({
          id: 'assignMainContact',
          display: isSystemAdmin || canAssignMainContact,
          label: 'Assign Main Contact',
          icon: <AssignContactIcon />,
          onClick: handleModalKeyChange('assignMainContact'),
        }),
        Map({
          id: 'assignReferralSource',
          display: isSystemAdmin || canAssignReferralSource,
          label: 'Assign Referral Source',
          icon: <ReferralSourceIcon />,
          onClick: handleModalKeyChange('assignReferralSource'),
        }),
        Map({
          id: 'email',
          display: canCreateMessages && emailsEnabled,
          label: 'Email',
          icon: <Email />,
          onClick: handleModalKeyChange('email'),
        }),
        Map({
          id: 'sms',
          display: canCreateMessages && textsEnabled,
          label: 'Text',
          icon: <Sms />,
          onClick: redirectToMessages,
        }),
        Map({
          id: 'updateStatus',
          display: canUpdateLead,
          label: 'Update Status',
          icon: <UpdateStatusIcon />,
          onClick: handleModalKeyChange('updateStatus'),
        }),
        ...(isCouncilLeadPage
          ? [
              Map({
                id: 'createTag',
                display: canCreateNotes,
                label: 'Tag Leads',
                icon: <CreateNoteTagIcon />,
                onClick: handleModalKeyChange('createTag'),
              }),
              Map({
                id: 'manuallyPushLeads',
                display: isEnableManualLeadSharing(pushLead),
                label:
                  actionLeadIds.length === 1
                    ? 'Manually Push Lead'
                    : 'Manually Push Leads',
                icon: <ShareIcon />,
                onClick: handleModalKeyChange('manuallyPushLeads'),
              }),
            ]
          : [
              Map({
                id: 'createNoteTag',
                display: canCreateNotes,
                label: 'Create Note & Tag',
                icon: <CreateNoteTagIcon />,
                onClick: handleModalKeyChange('createNoteTag'),
              }),
            ]),
        Map({
          id: 'exportLeads',
          display: canExportLeads || isSystemAdmin,
          label: 'Export Leads',
          icon: <GetApp />,
          onClick: handleModalKeyChange('exportLeads'),
        }),
      ]),
    }),
    Map({
      display: canDeleteLead && areLeadsSelected,
      sectionHeader: `Delete Actions`,
      id: 'deleteActions',
      items: List([
        Map({
          id: 'clearVotes',
          display: (isSystemAdmin || canClearVotes) && !isCouncilLeadPage,
          label: 'Clear Endorsements',
          icon: <DeleteOutline />,
          color: 'primary',
          onClick: handleModalKeyChange('clearVotes'),
        }),
        Map({
          id: 'deleteLeads',
          label: actionLeadIds.length === 1 ? 'Delete lead' : 'Delete leads',
          icon: <DeleteIcon />,
          color: 'primary',
          onClick: handleModalKeyChange('deleteLeads'),
        }),
      ]),
    }),
  ]);

  const onCheckedToggle = useCallback(() => {
    if (selectedLeadsCount > 0) {
      onResetSelected();
    } else {
      onSelectAllFetched();
    }
  }, [onSelectAllFetched, onResetSelected, selectedLeadsCount]);

  useEffect(() => {
    onResetSelected();
  }, [search, onResetSelected]);

  return (
    <Grid container alignItems='center' className={classes.toolbarContainer}>
      <Grid item>
        <Grid container justifyContent='flex-start' alignItems='center'>
          <Hidden xsDown>
            <Grid item>
              <Tooltip
                id='headerCheckboxTooltip'
                title={
                  areLeadsSelected ? 'Deselect All' : 'Select All Displayed'
                }
              >
                <Box>
                  <MuiCheckbox
                    checked={areLeadsSelected}
                    color='primary'
                    indeterminate={
                      areLeadsSelected && selectedLeadsCount !== totalLeadsCount
                    }
                    onChange={onCheckedToggle}
                  />
                </Box>
              </Tooltip>
            </Grid>
          </Hidden>

          {listType === 'card' && !searchParam && <LeadListSort />}

          <Hidden xsDown>
            <Grid item>
              <Grid container spacing={2} alignItems='center' wrap='nowrap'>
                <Grid item>
                  {leadsLoading ? (
                    <Skeleton width={160} height={40} />
                  ) : (
                    leads.length > 0 && (
                      <Typography color='textSecondary' variant='subtitle2'>
                        Displaying {leads.length} out of {totalLeadsCount}{' '}
                        {pluralize('lead', leads.length)}
                      </Typography>
                    )
                  )}
                </Grid>
                {leads.length > 0 &&
                  actionLeadIds.length === leads.length &&
                  leads.length !== totalLeadsCount &&
                  !allSelected && (
                    <Grid item>
                      <Tooltip
                        title={
                          Boolean(searchParam)
                            ? 'Select all disabled when searching'
                            : ''
                        }
                      >
                        <Box>
                          <Button
                            onClick={onSelectAll}
                            color={'primary'}
                            variant={'contained'}
                            disabled={Boolean(searchParam)}
                          >
                            {!Object.keys(filters).length
                              ? 'Select all leads'
                              : `Select ${totalLeadsCount} filtered ` +
                                `${pluralize('lead', totalLeadsCount)}`}
                          </Button>
                        </Box>
                      </Tooltip>
                    </Grid>
                  )}
              </Grid>
            </Grid>
          </Hidden>
        </Grid>
      </Grid>

      <Grid item xs id='leadListActions'>
        <Grid container justifyContent='flex-end' alignItems='center'>
          {areLeadsSelected && (
            <Hidden xsDown>
              <Fade in={areLeadsSelected}>
                <Typography id='selectedLabel' variant='body2'>
                  {(selectedLeadsCount === totalLeadsCount
                    ? 'All'
                    : selectedLeadsCount) +
                    (Object.keys(filters).length ? ' filtered' : '') +
                    pluralize(' lead', selectedLeadsCount) +
                    ' selected'}
                </Typography>
              </Fade>
            </Hidden>
          )}

          {isCouncilLeadPage ? (
            <span className={classes.spacing} />
          ) : (
            <Hidden xsDown>
              <IconButton
                className={classes.iconButtonMargin}
                id='listTypeToggleButton'
                onClick={onListTypeToggle}
                aria-label={listType === 'card' ? 'table view' : 'card view'}
              >
                {listType === 'card' && (
                  <Tooltip title='Toggle table view'>
                    <ViewListIcon />
                  </Tooltip>
                )}
                {listType === 'table' && (
                  <Tooltip title='Toggle card view'>
                    <ViewModuleIcon />
                  </Tooltip>
                )}
              </IconButton>
            </Hidden>
          )}

          <Button
            id='elevio_bulk_actions'
            variant={areLeadsSelected ? 'contained' : 'outlined'}
            color={areLeadsSelected ? 'primary' : 'default'}
            onClick={(e: any) => {
              setActionsAnchorEl(e.currentTarget);
            }}
          >
            Actions
            <ArrowDropDownIcon />
          </Button>
        </Grid>
      </Grid>

      <MegaMenu
        // @ts-expect-error ts-migrate(2322) FIXME: Type '{ id: string; anchorEl: null; handleClose: (... Remove this comment to see the full error message
        id='leadActionsMenu'
        anchorEl={actionsAnchorEl}
        handleClose={() => {
          setActionsAnchorEl(null);
        }}
        menuSections={leadActionsMenu}
      />

      <CreateLeadModal
        open={modalKey === 'newLead'}
        onClose={handleModalKeyChange(null)}
      />
      <UpdateStatusModal
        open={modalKey === 'updateStatus'}
        onClose={handleModalKeyChange(null)}
        allSelected={allSelected}
        leadIds={actionLeadIds}
        groupId={groupId}
        isCouncilLeadPage={!!isCouncilLeadPage}
      />
      <AssignMainContactModal
        open={modalKey === 'assignMainContact'}
        onClose={handleModalKeyChange(null)}
        allSelected={allSelected}
        leadIds={actionLeadIds}
      />
      <AssignReferralSourceModal
        open={modalKey === 'assignReferralSource'}
        onClose={handleModalKeyChange(null)}
        allSelected={allSelected}
        leadIds={actionLeadIds}
        filters={filters}
      />
      <DeleteLeadsModal
        open={modalKey === 'deleteLeads'}
        onClose={handleModalKeyChange(null)}
        allSelected={allSelected}
        ids={actionLeadIds}
      />
      <ManuallyPushLeadsModal
        open={modalKey === 'manuallyPushLeads'}
        onClose={handleModalKeyChange(null)}
        allSelected={allSelected}
        ids={actionLeadIds}
        leads={leads}
        groupId={groupId}
        refetchLeads={refetchLeads}
        filters={filters}
      />
      <ClearVotesModal
        open={modalKey === 'clearVotes'}
        onClose={handleModalKeyChange(null)}
        allSelected={allSelected}
        leadIds={actionLeadIds}
      />
      <CreateEmailModal
        open={modalKey === 'email'}
        onClose={handleModalKeyChange(null)}
        totalIds={actionLeadIds}
        leadIds={idsWithEmails}
        allSelected={allSelected}
        filters={filters}
        totalLeadsCount={totalLeadsCount}
        isCouncilLeadPage={isCouncilLeadPage}
      />
      <CreateNoteTagModal
        open={modalKey === 'createNoteTag'}
        onClose={handleModalKeyChange(null)}
        leadIds={actionLeadIds}
        allSelected={allSelected}
        shouldFetchTags
      />
      <AddTagModal
        open={modalKey === 'createTag'}
        onClose={handleModalKeyChange(null)}
        leadIds={actionLeadIds}
        allSelected={allSelected}
        tags={tags}
        leads={leads || []}
        leadsQuery={leadsQuery}
      />
      <ExportLeadsModal
        open={modalKey === 'exportLeads'}
        onClose={handleModalKeyChange(null)}
        leadIds={actionLeadIds}
        allSelected={allSelected}
        isCouncilLeadPage={isCouncilLeadPage}
      />
    </Grid>
  );
};

export default withStyles(leadListActionsStyles, { withTheme: true })(
  memo(LeadListActions, isEqual)
);
