import React, { useMemo } from 'react';

// hooks
import { useSelector } from 'react-redux';

// material-ui
import {
  Box,
  Chip,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { CloseSharp } from '@material-ui/icons';

// selectors
import getSliceState from 'store/selectors/getSliceState';

export default function TagSelector({ label, value, onChange, ...rest }: any) {
  const classes = useStyles();

  const { milestones = [], tags = [] } = useSelector(state => ({
    milestones: getSliceState('milestone')(state).data,
    tags: getSliceState('tag')(state).data,
  }));

  const milestoneOptions = useMemo(
    () =>
      milestones.map(({ id, title }: any = {}) => ({
        value: id,
        label: title,
      })),
    [milestones]
  );

  const tagOptions = useMemo(
    () =>
      tags.map(({ id, title }: any = {}) => ({
        value: id,
        label: title,
      })),
    [tags]
  );
  const options = milestoneOptions.concat(tagOptions);

  const handleClear = () => {
    onChange([]);
  };

  const handleChange = (event: any) => {
    // In Material UI v4 ListSubheaders are being taken as MenuItems with the value of 'undefined',
    // that's why here we need handleChange. With MUI v5 we can simply pass onChange to Select component.
    event.preventDefault();
    if (!event.target.value.includes(undefined)) {
      onChange(event.target.value);
    }
  };

  return (
    <FormControl variant='outlined' fullWidth>
      <InputLabel className={classes.inputLabel}>{label}</InputLabel>
      <Select
        {...rest}
        multiple
        onChange={handleChange}
        value={value}
        renderValue={selected => (
          <Grid container spacing={1}>
            {(selected as any).map((value: any) => (
              <Grid item xs>
                <Chip
                  key={value}
                  label={
                    (
                      options.find(
                        // @ts-expect-error ts-migrate(2739) FIXME: Type '{}' is missing the following properties from... Remove this comment to see the full error message
                        (tag = {}) => value === (tag as any).value
                      ) || {}
                    ).label
                  }
                  className={classes.chip}
                />
              </Grid>
            ))}
          </Grid>
        )}
        endAdornment={
          Boolean(value.length) && (
            <InputAdornment position='end'>
              <Box marginRight={2}>
                <IconButton onClick={handleClear}>
                  <CloseSharp />
                </IconButton>
              </Box>
            </InputAdornment>
          )
        }
      >
        <ListSubheader>Milestones</ListSubheader>
        {milestoneOptions.map((option: any) => (
          <MenuItem
            key={option.value}
            value={option.value}
            className={classes.menuItem}
          >
            {option.label}
          </MenuItem>
        ))}
        <ListSubheader>Tags</ListSubheader>
        {tagOptions.map((option: any) => (
          <MenuItem
            key={option.value}
            value={option.value}
            className={classes.menuItem}
          >
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
const useStyles = makeStyles(theme => ({
  chip: {
    backgroundColor: (theme.palette.background as any).lightPaper,
  },
  inputLabel: {
    backgroundColor: theme.palette.background.paper,
  },
  menuItem: {
    marginLeft: 10,
  },
}));
