import { all, call, put, fork, takeEvery } from 'redux-saga/effects';

// action creators
import { doneIndicator } from 'store/actions/httpActions';
import { storeClear } from 'store/actions/storeActions';

// api
import client from 'sources/api';

// constants
import {
  SESSION_DESTROY,
  SESSION_DESTROY_REQUEST,
} from 'store/constants/sessionTypes';

// helpers
import {
  removeAccessToken,
  removeRefreshToken,
  getAccessToken,
} from 'helpers/tokenUtils';
// import { removeStateToken, removeIntegrationId } from 'helpers/oidcUtils';

// sagas
import httpSaga from 'store/sagas/httpSaga';

const WATCH_TYPE = SESSION_DESTROY_REQUEST;
const TYPE = SESSION_DESTROY;

export function* sessionDestroy() {
  const accessToken = yield getAccessToken();

  if (accessToken) {
    // @ts-expect-error ts-migrate(2569) FIXME: Type 'Generator<any, any, unknown>' is not an arra... Remove this comment to see the full error message
    yield* httpSaga(TYPE, call(client.delete, '/auth/logout'));
  }

  yield all([
    yield removeAccessToken(),
    yield removeRefreshToken(),
    // yield removeStateToken(),    // TODO: Troubleshoot these being fired during SSO login flow...
    // yield removeIntegrationId(),
    yield put(
      storeClear({
        session: {
          valid: false,
        },
      })
    ),
  ]);

  yield put(doneIndicator(TYPE));
}

export function* watch() {
  yield takeEvery(WATCH_TYPE, sessionDestroy);
}

export default function* root() {
  yield all([fork(watch)]);
}
