import React, { useEffect, useCallback } from 'react';
import { isEqual } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';

// MUI components
import {
  Grid,
  Card,
  CardContent,
  Hidden,
  WithStyles,
  withStyles,
} from '@material-ui/core';

// components
import Header from 'components/Header';
import Table from 'components/Table';
import ExportButton from 'components/ExportButton';
import ReportRefreshButton from 'components/ReportRefreshButton';

// action creators
import { createReferralSourceStatusReport } from 'store/actions/apiTaskActions';

// selectors
import getCurrentGroupId from 'store/selectors/getCurrentGroupId';
import getForm from 'store/selectors/getForm';
import getSliceEntityById from 'store/selectors/getSliceEntityById';

// icons
import SwapVert from '@material-ui/icons/SwapVert';
import Ballot from '@material-ui/icons/Ballot';

// styles
import referralSourceStatusCardStyles from 'routes/ReferralSourceAnalytics/ReferralSourceStatusCard/referralSourceStatusCard.style';

const REPORT_FORM = 'referralSourceStatusReportForm';
const ROWS_PER_PAGE = 10;
const INITIAL_ORDER = 'asc';
const INITIAL_ORDER_BY = 'refSrcName';

export interface ReferralSourceStatusCardProps
  extends WithStyles<typeof referralSourceStatusCardStyles> {
  isCouncilReferralSourceAnalytics?: boolean;
  isOrganizationReferralSourceAnalytics?: boolean;
}

const ReferralSourceStatusCard = ({
  classes,
  isCouncilReferralSourceAnalytics,
  isOrganizationReferralSourceAnalytics,
}: ReferralSourceStatusCardProps) => {
  const dispatch = useDispatch();

  const {
    currentGroupId,
    report: {
      data: { content = [], meta: { columns = [] } = {} } = {},
      id: reportId,
    },
    form: { isSubmitting },
  } = useSelector(
    state => ({
      currentGroupId: getCurrentGroupId(state),
      form: getForm(REPORT_FORM)(state),
      report: getSliceEntityById(
        'report',
        'ReportTypes.REFERRAL_SOURCE',
        {}
      )(state),
    }),
    isEqual
  );

  const dispatchCreateReport = useCallback(
    ({ forceRefresh }: { forceRefresh: boolean }) => {
      dispatch(
        createReferralSourceStatusReport({
          groupId: currentGroupId,
          formName: REPORT_FORM,
          forceRefresh,
        })
      );
    },
    [dispatch, currentGroupId]
  );

  useEffect(() => {
    dispatchCreateReport({ forceRefresh: false });
  }, [dispatchCreateReport]);

  const getHeaderTitle = useCallback<() => string>(() => {
    if (isCouncilReferralSourceAnalytics) {
      return 'Referral Source Report';
    }
    if (isOrganizationReferralSourceAnalytics) {
      return 'Organization Referral Source Report';
    }

    return 'Referral Sources & Status';
  }, [isCouncilReferralSourceAnalytics, isOrganizationReferralSourceAnalytics]);

  return (
    <Card>
      <CardContent className={classes.cardContent}>
        <Grid container>
          <Grid item xs>
            <Header
              title={getHeaderTitle()}
              subtitle={
                isCouncilReferralSourceAnalytics ||
                isOrganizationReferralSourceAnalytics
                  ? 'See where your leads are coming from and who is progressing'
                  : 'See where your most successful leads are coming from'
              }
              icon={
                isCouncilReferralSourceAnalytics ||
                isOrganizationReferralSourceAnalytics
                  ? Ballot
                  : SwapVert
              }
              compact
            />
          </Grid>

          <Hidden xsDown>
            <Grid item xs={2}>
              <Grid container justifyContent='flex-end'>
                <Grid item>
                  <ReportRefreshButton
                    loading={isSubmitting}
                    onRefresh={dispatchCreateReport}
                  />
                </Grid>
                <ExportButton params={{ reportId }} disabled={isSubmitting} />
              </Grid>
            </Grid>
          </Hidden>

          <Grid item xs={12}>
            <Table
              columns={columns}
              data={content}
              variant='outlined'
              numberOfSkeletonRows={isSubmitting ? 8 : 0}
              initialOrder={INITIAL_ORDER}
              initialOrderBy={INITIAL_ORDER_BY}
              TablePaginationProps={{
                count: content.length,
                rowsPerPage: ROWS_PER_PAGE,
                rowsPerPageOptions: [ROWS_PER_PAGE],
              }}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default withStyles(referralSourceStatusCardStyles, { withTheme: true })(
  ReferralSourceStatusCard
);
