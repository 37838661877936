import React, {
  useState,
  useRef,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import { FieldRenderProps } from 'react-final-form';
import { debounce } from 'lodash';

// hooks
import useInfiniteScroll from 'helpers/hooks/useInfiniteScroll';
import { useLeadsInfiniteQuery } from 'api/leads';

// material-ui
import {
  TextField,
  InputAdornment,
  Box,
  IconButton,
  CircularProgress,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

// icons
import Search from '@material-ui/icons/Search';
import CloseSharp from '@material-ui/icons/CloseSharp';

// styles
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from 'assets/types';

const LeadSelector = ({
  input: { name, onChange, value },
  ...rest
}: FieldRenderProps<Lead>) => {
  const classes = useStyles();
  const inputRef = useRef<HTMLInputElement>(null);
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState('');
  const [options, setOptions] = useState<Lead[]>([]);

  const {
    data,
    isLoading,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useLeadsInfiniteQuery(search, { enabled: open });
  const leads: Lead[] = useMemo(() => (data?.pages ? data?.pages.flat() : []), [
    data,
  ]);

  const loading = isLoading || isFetchingNextPage;
  const [scrolledToBottom, scrollRef] = useInfiniteScroll(loading, 8);

  useEffect(() => {
    if (hasNextPage && scrolledToBottom) fetchNextPage();
  }, [hasNextPage, scrolledToBottom, fetchNextPage]);

  const setFocus = useCallback(
    (focusState: boolean) => () =>
      inputRef.current &&
      (focusState ? inputRef.current.focus() : inputRef.current.blur()),
    []
  );

  const handleSearchChange = useCallback((_, value) => setSearch(value), []);

  const toggleOpen = useCallback(() => setOpen(open => !open), []);

  useEffect(() => {
    setOptions(() => (open ? leads : []));
  }, [open, leads]);

  const handleValueChange = useCallback(
    (_: React.ChangeEvent<{}>, value: Lead | null) => {
      onChange(value);
    },
    [onChange]
  );
  const clear = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      event.stopPropagation();
      if (inputRef.current) {
        inputRef.current.blur();
        onChange(null);
        setSearch('');
      }
    },
    [onChange]
  );

  return (
    <Autocomplete
      open={open}
      forcePopupIcon={false}
      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ open: boolean; forcePopupIcon: false; disa... Remove this comment to see the full error message
      disableOpenOnFocus
      blurOnSelect
      getOptionLabel={option => option && option.fullName}
      options={options}
      filterOptions={() => leads}
      loading={loading}
      onChange={handleValueChange}
      value={value}
      onInputChange={debounce(handleSearchChange, 600)}
      classes={{ paper: classes.paper, listbox: classes.listBox }}
      ListboxProps={{
        role: 'listbox',
        ref: scrollRef,
      }}
      renderInput={params => (
        <TextField
          {...params}
          {...rest}
          onClick={toggleOpen}
          onBlur={toggleOpen}
          inputRef={inputRef}
          name={name}
          label={'Lead'}
          placeholder={'Select lead'}
          variant={'outlined'}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position='start'>
                <Box marginRight={1}>
                  {isLoading ? <CircularProgress size={20} /> : <Search />}
                </Box>
              </InputAdornment>
            ),
            endAdornment: value && (
              <InputAdornment position='end'>
                <IconButton
                  id='clear-member'
                  onMouseEnter={setFocus(true)}
                  onMouseLeave={setFocus(false)}
                  onClick={clear}
                >
                  <CloseSharp />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      )}
    />
  );
};

const useStyles = makeStyles(theme => {
  return {
    paper: {
      backgroundColor: (theme as Theme).palette.background.lightPaper,
    },
    listBox: {
      maxHeight: '324px',
    },
  };
});

export default LeadSelector;
