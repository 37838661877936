import {
  all,
  call,
  fork,
  put,
  takeEvery,
  take,
  select,
} from 'redux-saga/effects';
import history from 'helpers/history';
import { Map } from 'immutable';

// action creators
import { doneIndicator } from 'store/actions/httpActions';
import { fetchCurrentUser } from 'store/actions/userActions';
import { fetchRequestsForUser } from 'store/actions/requestActions';
import { setCurrentGroup } from 'store/actions/groupActions';

import {
  validateSession,
  invalidateSession,
} from 'store/actions/sessionActions';

// api
import client from 'sources/api';

// constants
import {
  SESSION_CREATE,
  SESSION_CREATE_REQUEST,
} from 'store/constants/sessionTypes';
import { CURRENT_USER_FETCH } from 'store/constants/userTypes';
import { REQUEST_FETCH_FOR_USER } from 'store/constants/requestTypes';

// helpers
import { setAccessToken, setRefreshToken } from 'helpers/tokenUtils';

// selectors
import getCurrentUserGroups from 'store/selectors/getCurrentUserGroups';
import getRequests from 'store/selectors/getRequests';

// sagas
import httpSaga from 'store/sagas/httpSaga';

// helpers
import isNamesListSite from 'helpers/isNamesListSite';

const WATCH_TYPE = SESSION_CREATE_REQUEST;
const TYPE = SESSION_CREATE;

export function* sessionCreate({ payload }: any) {
  // Make the auth request
  // @ts-expect-error ts-migrate(2569) FIXME: Type 'Generator<any, any, unknown>' is not an arra... Remove this comment to see the full error message
  const response = yield* httpSaga(
    TYPE,
    call(client.post, '/auth/login', {
      ...payload,
      is_nameslist: isNamesListSite(),
    }),
    {
      formName: 'loginForm',
    }
  );

  // Pull the access and refresh tokens off the response
  // @ts-expect-error ts-migrate(2525) FIXME: Initializer provides no value for this binding ele... Remove this comment to see the full error message
  const { data: { accessToken, refreshToken } = {} } = response || {};

  if (accessToken && refreshToken) {
    // Set the tokens in local storage
    yield all([
      yield setAccessToken(accessToken),
      yield setRefreshToken(refreshToken),
    ]);

    // Dispatch the action to fetch the current user data
    yield put(fetchCurrentUser({}));

    // Wait for the current user fetch to finish
    yield take(CURRENT_USER_FETCH + '_DONE');

    const currentUserGroups = yield select(state =>
      getCurrentUserGroups(state)
    );

    const groupId = currentUserGroups.first();

    if (groupId && currentUserGroups.size === 1) {
      // re-fetch the current user in order to get current group permissions
      yield put(
        fetchCurrentUser({
          currentGroupId: groupId,
          skipImageFetch: true,
        })
      );

      // Wait for the current user fetch to finish
      yield take(CURRENT_USER_FETCH + '_DONE');
    }

    // Select the current user object from the app state
    const currentUser = yield select(state =>
      state.getIn(['currentUser', 'data'], Map())
    );

    // Mark the session as being valid
    yield put(validateSession());

    /*
     * Handle navigating into the app
     */

    if (currentUser.get('isSystemAdmin')) {
      // If the user is a system admin, navigate to the group list
      yield history.push('/groups/chapters');
    } else if (
      currentUser.get('isHqAdmin') ||
      currentUser.get('isCouncilAdmin')
    ) {
      // navigate to the group list for their HQ group
      yield put(setCurrentGroup({ groupId, skipNavigate: false }));
    } else if (currentUserGroups.size) {
      // If the user already belongs to groups, navigate them to their specific group or group list
      if (currentUserGroups.size > 1) {
        yield history.push('/groups/chapters');
      } else if (currentUserGroups.size === 1) {
        // navigate to the home page for a single group's site
        yield put(setCurrentGroup({ groupId }));
        yield history.push({
          pathname: '/home',
          search: `?group=${groupId}`,
        });
      }
    } else {
      //  Query requests to see if any exist for the user
      // Dispatch the action to fetch the user's requests data
      yield put(fetchRequestsForUser());

      // Wait for the current user fetch to finish
      yield take(REQUEST_FETCH_FOR_USER + '_DONE');

      // Select the current user object from the app state
      const requests = yield select(state => getRequests(state));
      const userHasRequests = requests.size > 0;

      if (userHasRequests) {
        yield history.push('/requests/pending');
      } else {
        yield history.push('/select-chapter');
      }
    }
  } else {
    // Mark the session as being invalid
    yield put(invalidateSession());
  }

  // Notify the store that this saga is done
  yield put(doneIndicator(TYPE));
}

export function* watch() {
  yield takeEvery(WATCH_TYPE, sessionCreate);
}

export default function* root() {
  yield all([fork(watch)]);
}
