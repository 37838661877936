import {
  all,
  call,
  fork,
  put,
  takeEvery,
  take,
  select,
  delay,
} from 'redux-saga/effects';

// action creators
import {
  doneIndicator,
  success,
  errorIndicator,
  error,
} from 'store/actions/httpActions';
import { fetchTask } from 'store/actions/apiTaskActions';
import { Map } from 'immutable';

// selectors
import getTaskStatusById from 'store/selectors/getTaskStatusById';

// api
import client from 'sources/api';

// constants
import { INVITE_BULK_REQUEST, INVITE_BULK } from 'store/constants/inviteTypes';

// helpers
import httpSaga from 'store/sagas/httpSaga';

const WATCH_TYPE = INVITE_BULK_REQUEST;
const TYPE = INVITE_BULK;

export function* bulkInvite(action: any) {
  const {
    payload: { fileId, roleId, groupId, childGroupId },
  } = action;

  // 1) Post file ID to tasks endpoint to be processed and initiate the worker/task to bulk invite members
  // @ts-expect-error ts-migrate(2569) FIXME: Type 'Generator<any, any, unknown>' is not an arra... Remove this comment to see the full error message
  const taskPostResponse = yield* httpSaga(
    TYPE,
    call(client.post, `/tasks/?group_id=${groupId}`, {
      name: 'bulk_invite_members',
      params: {
        group_id: groupId,
        file_id: fileId,
        role_id: roleId,
        child_group_id: childGroupId,
      },
    }),
    {
      dispatchSuccess: false,
    }
  );

  const { taskId } = taskPostResponse.data;

  // 2) Use the worker/task ID to hit an endpoint periodically to check on the status
  let taskStatus = 'PROCESSING';

  while (taskStatus === 'PROCESSING') {
    yield delay(2000);

    yield put(fetchTask({ taskId, groupId }));
    yield take('API_TASK_FETCH_DONE');
    taskStatus = yield select(state => getTaskStatusById(taskId)(state));
  }

  if (taskStatus === 'FAILURE') {
    // Notify the store that this type had an error
    const message = Map({ data: Map({ msg: 'Import failed.' }) });

    // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 2.
    yield put(errorIndicator(TYPE, message));

    // Dispatch an error payload for this type to be caught by the reducer
    yield put(error(TYPE, message));
  } else {
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 3 arguments, but got 1.
    yield put(success(TYPE));
  }

  yield put(doneIndicator(TYPE));
}

export function* watch() {
  yield takeEvery(WATCH_TYPE, bulkInvite);
}

export default function* root() {
  yield all([fork(watch)]);
}
