export const API_TASK_FETCH_REQUEST = 'API_TASK_FETCH_REQUEST';
export const API_TASK_FETCH = 'API_TASK_FETCH';

export const API_TASK_REPORT_GROUP_ACTIVITY_REQUEST =
  'API_TASK_REPORT_GROUP_ACTIVITY_REQUEST';
export const API_TASK_REPORT_GROUP_ACTIVITY = 'API_TASK_REPORT_GROUP_ACTIVITY';

export const API_TASK_REPORT_GROUP_STATUS_REQUEST =
  'API_TASK_REPORT_GROUP_STATUS_REQUEST';
export const API_TASK_REPORT_GROUP_STATUS = 'API_TASK_REPORT_GROUP_STATUS';

export const API_TASK_REPORT_GROUP_LEADERBOARD_REQUEST =
  'API_TASK_REPORT_GROUP_LEADERBOARD_REQUEST';
export const API_TASK_REPORT_GROUP_LEADERBOARD =
  'API_TASK_REPORT_GROUP_LEADERBOARD';

export const API_TASK_REPORT_MEMBER_LEADERBOARD_REQUEST =
  'API_TASK_REPORT_MEMBER_LEADERBOARD_REQUEST';
export const API_TASK_REPORT_MEMBER_LEADERBOARD =
  'API_TASK_REPORT_MEMBER_LEADERBOARD';

export const API_TASK_REPORT_LEADS_OVERVIEW_REQUEST =
  'API_TASK_REPORT_LEADS_OVERVIEW_REQUEST';
export const API_TASK_REPORT_LEADS_OVERVIEW = 'API_TASK_REPORT_LEADS_OVERVIEW';

export const API_TASK_REPORT_LEADS_INFO_REQUEST =
  'API_TASK_REPORT_LEADS_INFO_REQUEST';
export const API_TASK_REPORT_LEADS_INFO = 'API_TASK_REPORT_LEADS_INFO';

export const API_TASK_REPORT_LEADS_MAIN_CONTACT_REQUEST =
  'API_TASK_REPORT_LEADS_MAIN_CONTACT_REQUEST';
export const API_TASK_REPORT_LEADS_MAIN_CONTACT =
  'API_TASK_REPORT_LEADS_MAIN_CONTACT';

export const API_TASK_REPORT_TAG_USAGE_REQUEST =
  'API_TASK_REPORT_TAG_USAGE_REQUEST';
export const API_TASK_REPORT_TAG_USAGE = 'API_TASK_REPORT_TAG_USAGE';

export const API_TASK_REPORT_MILESTONE_USAGE_REQUEST =
  'API_TASK_REPORT_MILESTONE_USAGE_REQUEST';
export const API_TASK_REPORT_MILESTONE_USAGE =
  'API_TASK_REPORT_MILESTONE_USAGE';

export const API_TASK_REPORT_TAG_STATUS_REQUEST =
  'API_TASK_REPORT_TAG_STATUS_REQUEST';
export const API_TASK_REPORT_TAG_STATUS = 'API_TASK_REPORT_TAG_STATUS';

export const API_TASK_REPORT_MILESTONE_STATUS_REQUEST =
  'API_TASK_REPORT_MILESTONE_STATUS_REQUEST';
export const API_TASK_REPORT_MILESTONE_STATUS =
  'API_TASK_REPORT_MILESTONE_STATUS';

export const API_TASK_REPORT_RECRUITMENT_ACTIVITY_MILESTONES_REQUEST =
  'API_TASK_REPORT_RECRUITMENT_ACTIVITY_MILESTONES_REQUEST';
export const API_TASK_REPORT_RECRUITMENT_ACTIVITY_MILESTONES =
  'API_TASK_REPORT_RECRUITMENT_ACTIVITY_MILESTONES';

export const API_TASK_REPORT_RECRUITMENT_ACTIVITY_TAGS_REQUEST =
  'API_TASK_REPORT_RECRUITMENT_ACTIVITY_TAGS_REQUEST';
export const API_TASK_REPORT_RECRUITMENT_ACTIVITY_TAGS =
  'API_TASK_REPORT_RECRUITMENT_ACTIVITY_TAGS';

export const API_TASK_REPORT_RECRUITMENT_ACTIVITY_REQUEST =
  'API_TASK_REPORT_RECRUITMENT_ACTIVITY_REQUEST';
export const API_TASK_REPORT_RECRUITMENT_ACTIVITY =
  'API_TASK_REPORT_RECRUITMENT_ACTIVITY';

export const API_TASK_REPORT_COMMUNICATION_OVERVIEW_REQUEST =
  'API_TASK_REPORT_COMMUNICATION_OVERVIEW_REQUEST';
export const API_TASK_REPORT_COMMUNICATION_OVERVIEW =
  'API_TASK_REPORT_COMMUNICATION_OVERVIEW';

export const API_TASK_REPORT_LEAD_COMMUNICATION_REQUEST =
  'API_TASK_REPORT_LEAD_COMMUNICATION_REQUEST';
export const API_TASK_REPORT_LEAD_COMMUNICATION =
  'API_TASK_REPORT_LEAD_COMMUNICATION';

export const API_TASK_REPORT_MEMBER_COMMUNICATION_REQUEST =
  'API_TASK_REPORT_MEMBER_COMMUNICATION_REQUEST';
export const API_TASK_REPORT_MEMBER_COMMUNICATION =
  'API_TASK_REPORT_MEMBER_COMMUNICATION';

export const API_TASK_REPORT_NEW_MEMBERS_REQUEST =
  'API_TASK_REPORT_NEW_MEMBERS_REQUEST';
export const API_TASK_REPORT_NEW_MEMBERS = 'API_TASK_REPORT_NEW_MEMBERS';

export const API_TASK_CREATE_EXPORT_REQUEST = 'API_TASK_CREATE_EXPORT_REQUEST';
export const API_TASK_CREATE_EXPORT = 'API_TASK_CREATE_EXPORT';

export const API_CREATE_TASK_REPORT_REQUEST = 'API_CREATE_TASK_REPORT_REQUEST';
export const API_CREATE_TASK_REPORT = 'API_CREATE_TASK_REPORT';

export const API_TASK_CREATE_EVENT_GUEST_MESSAGE_REQUEST =
  'API_TASK_CREATE_EVENT_GUEST_MESSAGE_REQUEST';
export const API_TASK_CREATE_EVENT_GUEST_MESSAGE =
  'API_TASK_CREATE_EVENT_GUEST_MESSAGE';

export const API_CREATE_TASK_REPORT_BY_GROUP_ID_REQUEST =
  'API_CREATE_TASK_REPORT_BY_GROUP_ID_REQUEST';
export const API_CREATE_TASK_REPORT_BY_GROUP_ID =
  'API_CREATE_TASK_REPORT_BY_GROUP_ID';
