import React, { useCallback } from 'react';
import { Moment } from 'moment';
import { isEqual } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';

// MUI components
import {
  Grid,
  Card,
  CardContent,
  WithStyles,
  withStyles,
} from '@material-ui/core';

// components
import Table from 'components/Table';
import AnalyticsActionBar from 'components/AnalyticsActionBar';

// action creators
import { createPaymentsReport } from 'store/actions/apiTaskActions';

// selectors
import getCurrentGroupId from 'store/selectors/getCurrentGroupId';
import getForm from 'store/selectors/getForm';
import getSliceEntityById from 'store/selectors/getSliceEntityById';

// icons
import MonetizationOnOutlined from '@material-ui/icons/MonetizationOnOutlined';

// styles
import paymentsCardStyles from 'routes/PaymentAnalytics/PaymentsCard/paymentsCard.style';

const REPORT_FORM = 'councilPaymentsCardReportForm';
const ROWS_PER_PAGE = 10;

export interface PaymentsCardProps
  extends WithStyles<typeof paymentsCardStyles> {}

const PaymentsCard = ({ classes }: PaymentsCardProps) => {
  const dispatch = useDispatch();

  const {
    currentGroupId,
    report: {
      data: { content = [], meta: { columns = [] } = {} } = {},
      id: reportId,
    },
    form: { isSubmitting },
  } = useSelector(
    state => ({
      currentGroupId: getCurrentGroupId(state),
      form: getForm(REPORT_FORM)(state),
      report: getSliceEntityById('report', 'ReportTypes.PAYMENTS', {})(state),
    }),
    isEqual
  );

  const dispatchCreateReport = useCallback(
    ({
      startDate,
      endDate,
      forceRefresh,
    }: {
      startDate: Moment;
      endDate: Moment;
      forceRefresh: boolean;
    }) => {
      dispatch(
        createPaymentsReport({
          groupId: currentGroupId,
          formName: REPORT_FORM,
          startDate,
          endDate,
          forceRefresh,
        })
      );
    },

    [currentGroupId, dispatch]
  );

  return (
    <Card>
      <CardContent className={classes.cardContent}>
        <Grid container spacing={2}>
          <AnalyticsActionBar
            onCreateReport={dispatchCreateReport}
            loading={isSubmitting}
            reportId={reportId}
            title='Payments'
            subtitle='Pull a list of all the fees collected through your forms'
            icon={MonetizationOnOutlined}
          />

          <Grid item xs={12} className={classes.tableGrid}>
            <Table
              columns={columns}
              data={content}
              variant='outlined'
              numberOfSkeletonRows={isSubmitting ? 8 : 0}
              initialOrder='asc'
              initialOrderBy='lead'
              TablePaginationProps={{
                count: content.length,
                rowsPerPage: ROWS_PER_PAGE,
                rowsPerPageOptions: [ROWS_PER_PAGE],
              }}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default withStyles(paymentsCardStyles, { withTheme: true })(
  PaymentsCard
);
