import { connect } from 'react-redux';

// components
import UpdatePasswordCard from './UpdatePasswordCard';

// action creators
import { updateCurrentUserPassword } from 'store/actions/userActions';

// selectors
import {
  hasSubmitFailed,
  isSubmitting,
  hasSubmitSucceeded,
  getError,
} from 'store/selectors/formSelectors';

const mapState = (state: any) => {
  return {
    updatePasswordFormSubmitting: isSubmitting('updatePasswordForm')(state),
    updatePasswordFormSubmitFailed: hasSubmitFailed('updatePasswordForm')(
      state
    ),
    updatePasswordFormSubmitSucceeded: hasSubmitSucceeded('updatePasswordForm')(
      state
    ),
    updatePasswordFormError: getError('updatePasswordForm')(state),
  };
};

const mapDispatch = { updateCurrentUserPassword };

export default connect(mapState, mapDispatch)(UpdatePasswordCard);
