import React from 'react';

// components
import Button from 'components/Button';

// material-ui
import { alpha } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';

// helpers
import isNamesListSite from 'helpers/isNamesListSite';

const LandingButton = function({ classes, ...restOfProps }: any) {
  return (
    <Button
      className={classes.landingButton}
      variant='contained'
      CircularProgressProps={{
        color: 'inherit',
      }}
      {...restOfProps}
    />
  );
};

const styles = (theme: any) => {
  return {
    landingButton: {
      marginRight: '30px',
      width: '80%',
      color: theme.palette.common.white,
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[2],
      '&.landingButton': {
        backgroundColor: isNamesListSite()
          ? theme.palette.primary.main
          : theme.palette.background.paper,
        '&.Mui-disabled': {
          backgroundColor: theme.palette.action.disabledBackground,
        },
      },
      '&:active': {
        boxShadow: theme.shadows[8],
      },
      '&:hover': {
        backgroundColor: alpha(theme.palette.background.paper, 0.8),
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          backgroundColor: theme.palette.background.paper,
        },
      },
      [theme.breakpoints.only('xs')]: {
        width: '85%',
      },
    },
  };
};

export default withStyles(styles)(LandingButton);
