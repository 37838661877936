import { combineReducers } from 'redux-immutable';

// reducers
import activity from './activity';
import apiTask from './apiTask';
import billing from './billing';
import comment from './comment';
import currentGroup from './currentGroup';
import currentUser from './currentUser';
import email from './email';
import event from './event';
import eventGuest from './eventGuest';
import file from './file';
import fileExport from './fileExport';
import forgotPassword from './forgotPassword';
import form from './form';
import formField from './formField';
import group from './group';
import groupFeatureUsage from './groupFeatureUsage';
import groupField from './groupField';
import groupTag from './groupTag';
import groupValue from './groupValue';
import invite from './invite';
import lead from './lead';
import leadAttribute from './leadAttribute';
import leadReferralSource from './leadReferralSource';
import leadTag from './leadTag';
import member from './member';
import message from './message';
import milestone from './milestone';
import note from './note';
import phoneNumber from './phoneNumber';
import referralForm from './referralForm';
import referralSource from './referralSource';
import report from './report';
import request from './request';
import role from './role';
import rootReducer from './root';
import session from './session';
import status from './status';
import suggestedField from './suggestedField';
import tag from './tag';
import label from 'store/reducers/label';
import task from './task';
import thread from './thread';
import twilioNumber from './twilioNumber';
import user from './user';
import vote from './vote';
import voteMethod from './voteMethod';
import formFee from 'store/reducers/formFee';
import paymentSettings from 'store/reducers/paymentSettings';

const reducers = {
  activity,
  apiTask,
  billing,
  comment,
  currentGroup,
  currentUser,
  email,
  event,
  eventGuest,
  file,
  fileExport,
  forgotPassword,
  form,
  formField,
  group,
  groupFeatureUsage,
  groupField,
  groupTag,
  groupValue,
  invite,
  lead,
  leadAttribute,
  leadReferralSource,
  leadTag,
  member,
  message,
  milestone,
  note,
  phoneNumber,
  referralForm,
  referralSource,
  report,
  request,
  role,
  session,
  status,
  suggestedField,
  tag,
  label,
  task,
  thread,
  twilioNumber,
  user,
  vote,
  voteMethod,
  formFee,
  paymentSettings,
};

// Combine all of the reducers
const combinedReducers = combineReducers({ ...reducers });

export default rootReducer(combinedReducers);
