import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

// action creators
import { doneIndicator } from 'store/actions/httpActions';

// api
import client from 'sources/api';

// constants
import { REQUEST_UPDATE_REQUEST } from 'store/constants/requestTypes';
import { MEMBER_FETCH_FOR_GROUP } from 'store/constants/memberTypes';

// lib
import httpSaga from 'store/sagas/httpSaga';
import getBaseType from '../helpers/getBaseType';

// schema
import { requestSchema } from 'store/schemas/requestSchema';
import { memberSchema } from 'store/schemas/memberSchema';

const WATCH_TYPES = [REQUEST_UPDATE_REQUEST];

export function* updateRequest(action: any) {
  const { formName, id, status, groupId, roleId: role_id } = action.payload;

  const FORM = formName;
  const TYPE = getBaseType(action.type);

  try {
    // @ts-expect-error ts-migrate(2569) FIXME: Type 'Generator<any, any, unknown>' is not an arra... Remove this comment to see the full error message
    yield* httpSaga(
      TYPE,
      call(client.patch, `/requests/${id}`, {
        status,
        role_id,
      }),
      {
        schema: requestSchema,
        formName: FORM,
        metaId: `update-${id}`,
      }
    );

    // Refetches members
    // @ts-expect-error ts-migrate(2569) FIXME: Type 'Generator<any, any, unknown>' is not an arra... Remove this comment to see the full error message
    yield* httpSaga(
      MEMBER_FETCH_FOR_GROUP,
      call(client.get, '/members', {
        params: { group_id: groupId },
      }),
      {
        schema: [memberSchema],
      }
    );
  } finally {
    yield put(doneIndicator(TYPE));
  }
}

export function* watch() {
  yield takeEvery(WATCH_TYPES, updateRequest);
}

export default function* root() {
  yield all([fork(watch)]);
}
