import React from 'react';
import { Field } from 'react-final-form';

// material-ui
import { withStyles, WithStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MuiCheckbox from '@material-ui/core/Checkbox/Checkbox';
import Grid from '@material-ui/core/Grid';

// components
import TextField from 'components/TextField';
import FieldWithTypeSelect from 'routes/ReferralForm/FormView/components/FieldWithTypeSelect';
import FieldWithTypeMultiSelect from 'routes/ReferralForm/FormView/components/FieldWithTypeMultiSelect';

//helpers
import { isEmpty } from 'helpers/check';

//styles
import fieldWithTypeStyles from 'routes/ReferralForm/FormView/components/FieldWithType/fieldWithType.style';

export interface FieldWithTypeProps
  extends WithStyles<typeof fieldWithTypeStyles> {
  id: number;
  name: string;
  label: string;
  mutators: any;
  groupId: number;
  required?: boolean;
  fieldType?: FormFieldType | null;
  description?: string | null;
  options?: string[];
  optionsWithLabel?: { label: string; value: string | number }[];
  isCouncilForm?: boolean | null;
  isOrganizationForm?: boolean | null;
  isUniversalForm?: boolean;
  value?: boolean | null | (string | number)[] | string;
  isImmutable?: boolean;
  multiSelectModalButtons?: React.ReactNode | null;
  open?: boolean;
  setOpen?: (open: boolean) => void;
  token?: string | null;
}

const FieldWithType = function({
  classes,
  mutators,
  groupId,
  isCouncilForm,
  isOrganizationForm,
  isUniversalForm,
  name,
  required,
  fieldType,
  options,
  optionsWithLabel,
  id,
  label,
  value,
  isImmutable,
  description,
  open,
  setOpen = () => {},
  multiSelectModalButtons,
  token,
}: FieldWithTypeProps) {
  const defaultTextField = (
    <Field
      name={name}
      component={TextField}
      label={label}
      required={required}
      {...(!isEmpty(description) ? { helperText: description } : {})}
    />
  );

  const getOptionsField = (options: string[]) =>
    options.map((item: string) => ({
      value: item,
      label: item,
    }));

  const optionsField =
    optionsWithLabel || (options ? getOptionsField(options) : []);

  const getCouncilField = () => {
    switch (fieldType) {
      case 'multiselect':
        return (
          <FieldWithTypeMultiSelect
            name={name}
            required={required}
            label={label}
            value={value}
            description={description}
            multiSelectModalButtons={multiSelectModalButtons}
            options={optionsField}
            isUniversalForm={isUniversalForm}
            open={open}
            setOpen={setOpen}
            mutators={mutators}
          />
        );

      case 'select':
        return (
          <FieldWithTypeSelect
            name={name}
            required={required}
            groupId={groupId}
            label={label}
            description={description}
            options={optionsField}
            isUniversalForm={isUniversalForm}
            isOrganizationForm={!!isOrganizationForm}
            isImmutable={isImmutable}
            token={token}
          />
        );

      case 'boolean':
        return (
          <Field name={name} type='checkbox' required={required}>
            {(props: any) => (
              <FormControlLabel
                {...(!isEmpty(description)
                  ? { className: classes.controlLabelDesc }
                  : {})}
                control={<MuiCheckbox {...props.input} color='primary' />}
                label={
                  isEmpty(description) ? (
                    label
                  ) : (
                    <>
                      {label}
                      <div className={classes.checkboxDescription}>
                        {description}
                      </div>
                    </>
                  )
                }
              />
            )}
          </Field>
        );
    }

    return defaultTextField;
  };

  return (
    <Grid item xs={12} id={`formField${id}`}>
      {getCouncilField()}
    </Grid>
  );
};

export default withStyles(fieldWithTypeStyles)(FieldWithType);
