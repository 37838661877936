import React, { memo, useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import validate from 'validate.js';
import PropTypes from 'prop-types';
// material-ui
import { Grid } from '@material-ui/core';
// icons
import { LocalOffer } from '@material-ui/icons';
//components
import DazzlingDialog from 'components/DazzlingDialog';
import TextField from 'components/TextField';
import ColorSelector, { defaultColor } from 'components/ColorSelector';
import { Form, Field } from 'react-final-form';
// actions
import { createTagAction } from 'store/actions/tagActions';
import { createLabelAction } from 'store/actions/labelActions';

const FORM = 'createTag';
const SCHEMA = {
  color: {
    presence: true,
  },
  title: {
    presence: true,
    length: { maximum: 128 },
  },
};
const DIALOG_DATA = {
  icon: LocalOffer,
  title: 'New Label',
  subtitle: 'Create a label to categorize, organize, and filter your groups',
};

type Props = {
  open: boolean;
  onClose: () => void;
  isCurrentGroupCouncil?: boolean;
};

const CreateLabelModal = memo(
  ({ open, onClose, isCurrentGroupCouncil }: Props) => {
    const {
      actions: { createTag, createLabel },
    } = useRedux();
    const validateLabelFields = useCallback(
      values => validate(values, SCHEMA),
      []
    );
    const handleFormSubmit = useCallback(
      ({ title, color, description }) => {
        if (isCurrentGroupCouncil) {
          createLabel({
            formName: FORM,
            fields: { title, description, color: color.slice(1) },
          });
          return;
        }
        createTag({
          formName: FORM,
          fields: { title, description, color: color.slice(1) },
        });
      },
      [createTag, createLabel, isCurrentGroupCouncil]
    );
    return (
      <Form
        validate={validateLabelFields}
        onSubmit={handleFormSubmit}
        render={({ handleSubmit, invalid, form: { reset } }) => (
          <DazzlingDialog
            id='create-label-dialog'
            acceptLabel='Create'
            formName={FORM}
            handleClose={onClose}
            headerProps={DIALOG_DATA}
            onAccept={handleSubmit}
            disabled={invalid}
            open={Boolean(open)}
            onExited={reset}
          >
            <Grid container spacing={2} alignItems='center'>
              <Grid item xs={6}>
                <Field
                  fullwidth
                  name='title'
                  label='Title'
                  variant='outlined'
                  component={TextField}
                />
              </Grid>
              <Grid item xs={6}>
                <Field
                  initialValue={defaultColor}
                  fullwidth
                  name='color'
                  label='Color'
                  variant='outlined'
                  component={ColorSelector}
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  name='description'
                  label='Description'
                  variant='outlined'
                  component={TextField}
                />
              </Grid>
            </Grid>
          </DazzlingDialog>
        )}
      />
    );
  }
);
(CreateLabelModal as any).propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
const useRedux = () => {
  const dispatch = useDispatch();
  const actions = useMemo(
    () => ({
      createTag: (payload: any) => dispatch(createTagAction(payload)),
      createLabel: (payload: {
        formName: string;
        fields: { title: string; description: string; color: string };
      }) => dispatch(createLabelAction(payload)),
    }),
    [dispatch]
  );
  return { actions };
};
export default CreateLabelModal;
