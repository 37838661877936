import React from 'react';
// material-ui
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import TableCell from '@material-ui/core/TableCell';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { makeStyles } from '@material-ui/styles';
import getPadding from 'components/Table/helpers/getPadding';

export default function TableHeader({
  columns,
  enableCheckboxes = false,
  enableHeaderCheckbox = true,
  loading,
  onRequestSort,
  order,
  orderBy,
  TableCellProps,
  CheckboxProps,
}: {
  columns: any; // TODO: PropTypes.instanceOf(Array)
  loading?: boolean;
  onRequestSort: (...args: any[]) => any;
  order: 'asc' | 'desc';
  orderBy: string;
  enableCheckboxes?: boolean;
  enableHeaderCheckbox?: boolean;
  TableCellProps: any; // TODO: PropTypes.instanceOf(Object)
  CheckboxProps: any; // TODO: PropTypes.instanceOf(Object)
}) {
  const classes = useStyles();
  const createSortHandler = (disableSort: any, key: any) => (event: any) => {
    if (!disableSort) {
      onRequestSort(event, key);
    }
  };

  const {
    allSelected,
    selectedItems = {},
    setAllSelected = () => {},
    setSelectedItems = () => {},
    resetSelected = () => {},
  }: {
    allSelected?: boolean;
    selectedItems?: any; // TODO: PropTypes.instanceOf(Object)
    setSelectedItems?: (...args: any[]) => any;
    setAllSelected?: (...args: any[]) => any;
    resetSelected?: (...args: any[]) => any;
  } = CheckboxProps;

  const areItemsSelected = Object.keys(selectedItems).length > 0;

  const handleCheckboxToggle = () => {
    if (areItemsSelected) {
      resetSelected();
      setSelectedItems({});
      setAllSelected(false);
    } else {
      setAllSelected(!allSelected);
    }
  };

  const renderCheckboxCell = () => {
    let element;

    if (enableCheckboxes) {
      element = (
        <TableCell
          key='checkboxHeader'
          size='small'
          padding='checkbox'
          {...TableCellProps}
        >
          {enableHeaderCheckbox && (
            <Tooltip
              id='headerCheckboxTooltip'
              title={
                areItemsSelected || allSelected ? 'Deselect All' : 'Select All'
              }
            >
              <Checkbox
                checked={allSelected || areItemsSelected}
                color='primary'
                indeterminate={areItemsSelected}
                onChange={handleCheckboxToggle}
              />
            </Tooltip>
          )}
        </TableCell>
      );
    }
    return element;
  };

  return (
    <TableHead>
      <TableRow>
        {renderCheckboxCell()}
        {columns.map(
          ({
            key,
            label,
            padding,
            disableSort,
            tooltipTitle = '',
            isTooltipAllowed = false,
          }: any) => {
            return (
              <TableCell
                key={key}
                padding={getPadding(label, padding)}
                sortDirection={orderBy === key ? order : false}
                classes={{ root: classes.headerCell }}
                {...TableCellProps}
              >
                <Tooltip
                  arrow
                  title={tooltipTitle}
                  disableHoverListener={!tooltipTitle}
                >
                  <div>
                    {label && (
                      <TableSortLabel
                        disabled={disableSort && !isTooltipAllowed}
                        hideSortIcon={disableSort}
                        active={orderBy === key}
                        direction={order}
                        onClick={createSortHandler(disableSort, key)}
                      >
                        {label}
                        {orderBy === key ? (
                          <span className={classes.visuallyHidden}>
                            {order === 'desc'
                              ? 'sorted descending'
                              : 'sorted ascending'}
                          </span>
                        ) : null}
                      </TableSortLabel>
                    )}
                  </div>
                </Tooltip>
              </TableCell>
            );
          }
        )}
      </TableRow>
    </TableHead>
  );
}
const useStyles = makeStyles(theme => {
  return {
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
    headerCell: {
      '&:first-child': {
        paddingLeft: 24,
      },
      borderBottomColor: (theme as any).palette.background.lightPaper,
      paddingTop: 14,
      paddingBottom: 14,
    },
  };
});
