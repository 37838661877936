import { all, call, fork, put, takeEvery, select } from 'redux-saga/effects';

// action creators
import { doneIndicator } from 'store/actions/httpActions';

// api
import client from 'sources/api';

// constants
import { TASK_FETCH_REQUEST, TASK_FETCH } from 'store/constants/taskTypes';

// schemas
import { taskSchema } from 'store/schemas/taskSchema';

// helpers
import httpSaga from 'store/sagas/httpSaga';

// selectors
import getCurrentGroupId from 'store/selectors/getCurrentGroupId';

const WATCH_TYPE = TASK_FETCH_REQUEST;
const TYPE = TASK_FETCH;

export function* fetchTasksSaga(action: any) {
  const {
    payload: {
      completed,
      leadId: lead_id,
      page,
      accountId: account_id,
      dueOnFrom: due_on_from,
      dueOnTo: due_on_to,
    },
  } = action;

  const currentGroupId = yield select(getCurrentGroupId);

  // @ts-expect-error ts-migrate(2569) FIXME: Type 'Generator<any, any, unknown>' is not an arra... Remove this comment to see the full error message
  yield* httpSaga(
    TYPE,
    call(client.get, '/todos', {
      params: {
        group_id: currentGroupId,
        completed,
        pnm_id: lead_id,
        page,
        account_id,
        due_on_from,
        due_on_to,
      },
    }),
    {
      metaId: 'fetch',
      schema: [taskSchema],
    }
  );

  yield put(doneIndicator(TYPE));
}

export function* watch() {
  yield takeEvery(WATCH_TYPE, fetchTasksSaga);
}

export default function* root() {
  yield all([fork(watch)]);
}
