import React, { useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { isEqual } from 'lodash';
import { useHistory } from 'react-router-dom';
import { useLeadsQueries } from 'api/leads';

// MUI
import { makeStyles } from '@material-ui/core/styles';

// components
import Table from 'components/Table';

// actions
import { fetchMembersForGroup } from 'store/actions/memberActions';

// selectors
import getCurrentGroupId from 'store/selectors/getCurrentGroupId';
import getLoadingFromState from 'store/selectors/getLoadingFromState';
import getSliceState from 'store/selectors/getSliceState';

const ROWS_PER_PAGE = 5;

export default function GuestsTable({
  guestType,
  guests,
}: {
  guestType: 'member' | 'lead';
  guests: EventGuest[];
}) {
  const history = useHistory();
  const {
    location: { search },
  } = history;
  const dispatch = useDispatch();
  const classes = useStyles();
  const [guestsOnPage, setGuestsOnPage] = useState<any[]>([]);
  const [leadGuestIdsOnPage, setLeadGuestIdsOnPage] = useState<number[]>([]);
  const results = useLeadsQueries(leadGuestIdsOnPage);
  const leads = useMemo(
    () =>
      results
        .filter(result => result.isSuccess)
        .map(result => result.data as Lead),
    [results]
  );
  const leadsLoading = useMemo(
    () => results.map(result => result.isLoading).some(Boolean),
    [results]
  );

  const {
    currentGroupId,
    memberLoading,
    member: { data: members = [] },
  } = useSelector(
    state => ({
      currentGroupId: getCurrentGroupId(state),
      memberLoading: getLoadingFromState('member', null, false)(state),
      member: getSliceState('member')(state),
    }),
    isEqual
  );

  const guestNames: any[] = useMemo(() => {
    const names: any = {};

    if (guestType === 'lead') {
      guestsOnPage.forEach(guest => {
        const leadGuest = leads.find(lead => lead.id === guest.id);

        names[guest.id] = leadGuest?.fullName;
      });
    } else {
      guestsOnPage.forEach(guest => {
        const memberGuest =
          members.find((member: any) => {
            const { account } = member;
            return account.id?.toString() === guest.id?.toString();
          }) || {};

        const { account } = memberGuest;
        names[guest.id] = `${account?.firstName} ${account?.lastName}`;
      });
    }

    return names;
  }, [guestsOnPage, leads, members, guestType]);

  const handlePageChange = (tableRows: any[]) => {
    const idsToFetch = tableRows.map((tableRow: any) => tableRow.id);
    setGuestsOnPage(tableRows);

    if (guestType === 'lead' && !leadsLoading) {
      setLeadGuestIdsOnPage(idsToFetch);
    } else if (guestType === 'member' && !memberLoading) {
      dispatch(
        fetchMembersForGroup({
          accountIds: idsToFetch,
          groupId: currentGroupId,
        })
      );
    }
  };

  const navigateToGuest = ({ id }: { id: number }) => {
    if (guestType === 'lead') {
      history.push({ pathname: `/leads/${id}`, search });
    }
  };

  const columns = [
    {
      label: `${guestType.charAt(0).toUpperCase()}${guestType.slice(1)}`,
      key: 'guestName',
      disableSort: true,
    },
  ];

  const content = guests.map((guest: EventGuest) => {
    return {
      id: guestType === 'member' ? guest.accountId : guest.pnmId,
      guestName:
        guestNames[
          guestType === 'member' ? guest?.accountId || 0 : guest?.pnmId || 0
        ],
    };
  });

  const loading = guestType === 'member' ? memberLoading : leadsLoading;
  const maxSkeletons = guests.length > 5 ? 5 : guests.length;

  return (
    <div className={classes.container}>
      <Table
        columns={columns}
        data={loading ? [] : content}
        variant='outlined'
        onPageChange={handlePageChange}
        numberOfSkeletonRows={loading ? maxSkeletons : 0}
        SkeletonProps={{
          height: 28,
        }}
        TableRowProps={{
          onRowClick: navigateToGuest,
        }}
        TablePaginationProps={{
          count: content.length,
          rowsPerPage: ROWS_PER_PAGE,
          rowsPerPageOptions: [ROWS_PER_PAGE],
        }}
      />
    </div>
  );
}

const useStyles = makeStyles(theme => {
  return {
    container: {
      margin: 8,
      marginRight: 12,
      marginBottom: 4,
    },
  };
});
