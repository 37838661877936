import {
  INVITE_CREATE_REQUEST,
  INVITE_DELETE_REQUEST,
  INVITE_FETCH_FOR_GROUP_REQUEST,
  INVITE_RESEND_REQUEST,
  INVITE_BULK_REQUEST,
} from 'store/constants/inviteTypes';

export function fetchInvitesForGroup({ groupId, page, search }: any) {
  return {
    type: INVITE_FETCH_FOR_GROUP_REQUEST,
    payload: {
      groupId,
      page,
      search,
    },
  };
}

export function createInvite({ groupId, email, role, childGroupId }: any) {
  return {
    type: INVITE_CREATE_REQUEST,
    payload: { groupId, email, role, childGroupId },
  };
}

export function bulkInvite({ groupId, fileId, roleId, childGroupId }: any) {
  return {
    type: INVITE_BULK_REQUEST,
    payload: { groupId, fileId, roleId, childGroupId },
  };
}

export function resendInvite({ groupId, email }: any) {
  return {
    type: INVITE_RESEND_REQUEST,
    payload: { groupId, email },
  };
}

export function deleteInvite({ id }: any) {
  return {
    type: INVITE_DELETE_REQUEST,
    payload: { id },
  };
}
