import { Map } from 'immutable';

const getJStatusId = (state: any) =>
  state
    .getIn(['status', 'data', 'byId'], Map())
    .findKey(
      (status: any) =>
        status.get('abbreviation') === 'J' && status.get('isImmutable')
    );

export default getJStatusId;
