import React, { useContext } from 'react';
import moment from 'moment';
import { Device } from 'twilio-client';
// hooks
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
// api
import client from 'sources/api';
// components
import Button from 'components/Button';
import CreateNoteTagModal from 'components/CreateNoteTagModal';
import TemplatedMessageButton from 'components/TemplatedMessageButton';
import { Grid, IconButton, Typography, Box, Snackbar } from '@material-ui/core';
import { Alert, Skeleton } from '@material-ui/lab';
import {
  Call,
  CallEnd,
  SettingsPhone,
  PhoneInTalk,
  Mic,
  MicOff,
  NoteAdd,
} from '@material-ui/icons';
// selectors
import getCurrentGroupId from 'store/selectors/getCurrentGroupId';
import getCurrentUser from 'store/selectors/getCurrentUser';
import getSliceState from 'store/selectors/getSliceState';
// actions
import { fetchPhoneNumbersForGroup as fetchPhoneNumbersForGroupAction } from 'store/actions/phoneNumberActions';
// helpers
import { SiteVisualDataContext } from 'components/SiteVisualData';

type Props = {
  id: number;
  phoneNumber: string;
  leadsLoading: boolean;
  lastCall: boolean;
  onNextCall: (...args: any[]) => any;
  onCallEnd: () => void;
};

export default function CallPanel({
  id,
  phoneNumber,
  leadsLoading,
  lastCall,
  onNextCall,
  onCallEnd,
}: Props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const { callPanelMessage } = useContext(SiteVisualDataContext);
  const currentGroupId = useSelector(getCurrentGroupId);
  const currentUser = useSelector(getCurrentUser);
  const phoneNumbers = useSelector(getSliceState('phoneNumber', null, true));
  const currentUserId = (currentUser as any).get('id');
  const [duration, setDuration] = useState(null);
  const [callSid, setCallSid] = useState<null | string>(null);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [call, setCall] = useState(null);
  const [noteOpened, setNoteOpened] = useState(false);
  const connection = (Device as any).activeConnection();

  useEffect(() => {
    dispatch(fetchPhoneNumbersForGroupAction());
  }, [dispatch]);

  useEffect(() => {
    if (!(phoneNumbers as any).loading) {
      client
        .get(`/calls/token?group_id=${currentGroupId}`)
        .then((response: any) => {
          (Device as any).setup(response.data.token);
        })
        .catch((err: any) => {
          setError(true);
        });
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
      Device.on('ready', () => setLoading(false));
      return () => {
        (Device as any).destroy();
      };
    }
  }, [currentGroupId, phoneNumbers]);

  useEffect(() => {
    if (connection && call === 'started') {
      connection.on('accept', () => {
        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'Moment' is not assignable to par... Remove this comment to see the full error message
        setDuration(moment().startOf('day'));
      });
      connection.on('disconnect', () => {
        setCallSid(connection.parameters.CallSid);
        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '"ended"' is not assignable to pa... Remove this comment to see the full error message
        setCall('ended');
        onCallEnd();
      });
    }
  }, [connection, call, onNextCall, onCallEnd]);

  useEffect(() => {
    if (duration) {
      const timer = setInterval(() => {
        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '(duration: null) => Moment' is n... Remove this comment to see the full error message
        setDuration(duration => moment(duration.add(1, 'second')));
      }, 1000);
      return () => {
        clearInterval(timer);
      };
    }
  }, [duration]);

  const currentUserHasPhoneNumber = (phoneNumbers as any).data.find(
    (phoneNumber: any) => phoneNumber.account === currentUserId
  );
  const startCall = () => {
    (Device as any).connect({
      type: 'outgoing',
      group_id: currentGroupId,
      account_id: currentUserId,
      pnm_id: id,
    });
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '"started"' is not assignable to ... Remove this comment to see the full error message
    setCall('started');
  };
  const endCall = () => (Device as any).disconnectAll();

  const toggleMuted = () => {
    if (connection) connection.mute(!connection.isMuted());
  };

  const handleClickNext = () => {
    setDuration(null);
    setCall(null);
    onNextCall();
  };
  const handleNoteClose = () => {
    setCallSid(null);
    setNoteOpened(false);
  };
  const handleNoteOpen = () => setNoteOpened(true);
  const handleClick = () => history.push({ pathname: '/settings/phone' });

  return (phoneNumbers as any).loading || currentUserHasPhoneNumber ? (
    <>
      <Box padding={2} clone>
        <Grid
          container
          spacing={2}
          direction='column'
          alignItems='center'
          justifyContent='center'
        >
          <Grid item>
            <Box
              bgcolor='background.darkPaper'
              borderRadius='50%'
              display='inline-flex'
              padding={5}
            >
              <Box clone fontSize={60}>
                {connection ? (
                  duration ? (
                    <PhoneInTalk color='disabled' />
                  ) : (
                    <SettingsPhone color='disabled' />
                  )
                ) : call === 'ended' ? (
                  <CallEnd color='disabled' />
                ) : (
                  <Call color='disabled' />
                )}
              </Box>
            </Box>
          </Grid>
          <Grid item>
            <Typography>
              {leadsLoading ? (
                <Skeleton width={160} height={36} />
              ) : (
                phoneNumber
              )}
            </Typography>
          </Grid>
          <Grid item>
            {call === 'ended'
              ? 'Call Ended'
              : // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
                duration && duration.format('mm:ss')}
          </Grid>
          <Grid item></Grid>
          <Grid item>
            {connection && (
              <Button
                id='note'
                style={{ marginTop: 18 }}
                fullWidth
                variant='outlined'
                startIcon={<NoteAdd />}
                onClick={handleNoteOpen}
              >
                Write Note
              </Button>
            )}
          </Grid>
          <Grid item>
            {connection ? (
              <Grid container>
                <Grid item>
                  <IconButton onClick={toggleMuted} id='muteToggle'>
                    {connection && connection.isMuted() ? <MicOff /> : <Mic />}
                  </IconButton>
                </Grid>
                <Grid item>
                  <Box
                    sx={{
                      // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
                      backgroundColor: 'rgb(255, 0, 0)',
                      '&:hover': {
                        backgroundColor: 'rgb(255, 0, 0, 0.7)',
                      },
                    }}
                    clone
                  >
                    <IconButton onClick={endCall} id='endCall'>
                      <CallEnd />
                    </IconButton>
                  </Box>
                </Grid>
              </Grid>
            ) : (
              (call === null && (
                <Box minWidth={120}>
                  <Button
                    id='startCall'
                    variant='contained'
                    color='primary'
                    fullWidth
                    onClick={startCall}
                    disabled={error}
                    loading={
                      loading || (phoneNumbers as any).loading || leadsLoading
                    }
                  >
                    Call
                  </Button>
                </Box>
              )) ||
              (call === 'ended' && (
                <Grid container direction='column' spacing={2}>
                  <Grid item>
                    <TemplatedMessageButton leadId={id} />
                  </Grid>
                  <Grid item>
                    <Button
                      id='startCall'
                      variant='contained'
                      color='primary'
                      fullWidth
                      onClick={handleClickNext}
                    >
                      {lastCall ? 'Done' : 'Next'}
                    </Button>
                  </Grid>
                </Grid>
              ))
            )}
          </Grid>
        </Grid>
      </Box>
      <Snackbar open={error} autoHideDuration={6000}>
        <Alert severity='error'>Something went wrong...</Alert>
      </Snackbar>
      <CreateNoteTagModal
        leadIds={[id]}
        open={noteOpened || Boolean(callSid)}
        disabled={Boolean(connection)}
        callSid={callSid}
        onClose={handleNoteClose}
      />
    </>
  ) : (
    <Box padding={4}>
      <Grid
        container
        spacing={2}
        alignItems='center'
        justifyContent='center'
        direction='column'
      >
        <Grid item>
          <Typography variant='subtitle2'>{callPanelMessage}</Typography>
          <Typography color='textSecondary' variant='body2'>
            Create and assign a phone number to start making calls!
          </Typography>
        </Grid>
        <Grid item>
          <Button variant='outlined' onClick={handleClick}>
            Set Up Number
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}
