import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';
import { useNotificationsQuery } from 'api/notifications';

// MUI components
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Badge, Button } from '@material-ui/core';

const SidebarItem = function({ page }: any) {
  const classes = useStyles();
  const history = useHistory();
  const {
    location: { pathname, search },
  } = history;

  const handleNavigate = () => {
    const oldSearch = new URLSearchParams(search);
    const groupParam = oldSearch.get('group');
    const newSearch = new URLSearchParams();

    if (groupParam) newSearch.set('group', groupParam);

    history.push({ pathname: page.get('path'), search: newSearch.toString() });
  };
  const { data } = useNotificationsQuery();

  const hasNewMessages = data?.pages
    .flat()
    .some(
      n =>
        n.action === 'ActivityActions.TEXT_MESSAGE_RECEIVED' &&
        n.isRead === false
    );

  const formattedPath = page.get('path', '').replace('*', '');
  const basePathname = `/${pathname.split('/')[1]}/`;

  const selected = basePathname.includes(formattedPath);
  const Icon = page.get('icon');

  return (
    <Button
      className={classes.sidebarItem}
      onClick={handleNavigate}
      disableFocusRipple={true}
    >
      <Grid item xs={10} key={page.get('path')}>
        <Grid
          container
          direction='column'
          justifyContent='center'
          alignItems='center'
          spacing={1}
          className={
            selected
              ? classNames(classes.container, classes.selected)
              : classes.container
          }
        >
          <Grid item xs={12}>
            <Badge
              variant='dot'
              color='primary'
              classes={{
                colorPrimary: selected
                  ? classes.selectedBadge
                  : classes.unselectedBadge,
              }}
              invisible={page.get('id') !== 'messages' || !hasNewMessages}
            >
              <Icon color='action' fontSize='large' />
            </Badge>
          </Grid>

          <Grid item xs={12}>
            <Typography variant='subtitle2'>{page.get('label')}</Typography>
          </Grid>
        </Grid>
      </Grid>
    </Button>
  );
};

SidebarItem.propTypes = {
  page: PropTypes.instanceOf(Object),
};

SidebarItem.defaultProps = {
  page: 'home',
  mobile: false,
};

const useStyles = makeStyles(theme => ({
  sidebarItem: {
    width: '88%',
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 8,
    paddingBottom: 8,

    '&:focus': {
      transition: 'all .4s ease',
      backgroundColor: theme.palette.background.default,
      borderRadius: 8,
    },
  },

  container: {
    paddingTop: 8,
    paddingBottom: 8,
  },

  selected: {
    backgroundColor: `${theme.palette.primary.main} !important`,
    boxShadow: '0 2px 15px 2px rgba(0,0,0,0.5)',
    borderRadius: 8,
  },

  selectedBadge: {
    background: theme.palette.common.white,
  },
  unselectedBadge: {
    background: theme.palette.primary.main,
  },
}));

export default SidebarItem;
