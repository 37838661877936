import React, { useState } from 'react';
import classNames from 'classnames';

// MUI components
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

// components
import IconButton from 'components/IconButton';
import CreateLeadAttributeModal from './CreateLeadAttributeModal';

// icons
import AddCircleOutlined from '@material-ui/icons/AddCircleOutlined';

type Props = {
  leadId: number;
  canCreateLeadAttribute: boolean;
};

export default function LeadAttributesHeader({
  leadId,
  canCreateLeadAttribute,
}: Props) {
  const classes = useStyles();
  const [createModalOpen, setCreateModalOpen] = useState(false);

  return (
    <Grid
      item
      xs={12}
      key='header'
      className={classNames(classes.headerItem, classes.borderedItem)}
    >
      <Grid container alignItems='center'>
        <Grid item xs>
          <Typography className={classes.attributeText} variant='subtitle2'>
            Info
          </Typography>
        </Grid>

        {canCreateLeadAttribute && (
          <Grid item xs={2}>
            <IconButton
              id='createAttributeButton'
              onClick={() => setCreateModalOpen(true)}
            >
              <AddCircleOutlined />
            </IconButton>
          </Grid>
        )}
      </Grid>

      {canCreateLeadAttribute && (
        <CreateLeadAttributeModal
          leadId={leadId}
          open={createModalOpen}
          onClose={() => setCreateModalOpen(false)}
        />
      )}
    </Grid>
  );
}

const useStyles = makeStyles(theme => {
  const border = `1px solid rgba(255,255,255,0.25)`;

  return {
    attributeText: {
      fontWeight: 600,
      wordWrap: 'break-word',
    },
    headerItem: {
      paddingLeft: 20,
      paddingRight: 20,
      paddingTop: 10,
      paddingBottom: 10,
    },
    borderedItem: {
      borderBottom: border,
    },
  };
});
