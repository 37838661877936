import { useRef, useEffect, useState, useCallback } from 'react';

export default function useInfiniteScroll(
  loading: boolean,
  bottomOffset = 0
): [boolean, React.MutableRefObject<HTMLElement | null>] {
  const [scrolledToBottom, setScrolledToBottom] = useState(false);
  const scrollRef = useRef<HTMLElement | null>(null);

  const onScroll = useCallback(() => {
    if (scrollRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = scrollRef.current;
      const scrollBottom = scrollHeight - clientHeight - scrollTop;
      setScrolledToBottom(scrollBottom <= bottomOffset);
    }
  }, [bottomOffset]);

  useEffect(() => {
    const current = scrollRef.current;
    if (current) current.addEventListener('scroll', onScroll);

    return () => {
      if (scrollRef && current) {
        current.removeEventListener('scroll', onScroll);
      }
    };
  }, [scrollRef.current, onScroll]); // eslint-disable-line

  useEffect(() => {
    if (loading) setScrolledToBottom(false);
  }, [loading]);

  return [scrolledToBottom, scrollRef];
}
