import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// material-ui
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

// icons
import EmailIcon from '@material-ui/icons/Email';

//components
import DazzlingDialog from 'components/DazzlingDialog';

// action creators
import { sendPreviewEmail } from 'store/actions/referralFormActions';

// selectors
import getPrimaryEmail from 'store/selectors/getPrimaryEmail';

type Props = {
  onClose: (...args: any[]) => any;
  open: boolean;
  formId: number;
};

const PreviewEmailModal = ({ open, onClose, formId }: Props) => {
  const dispatch = useDispatch();
  const dispatchSendPreviewEmail = useCallback(
    values => {
      dispatch(sendPreviewEmail({ formId }));
    },
    [dispatch, formId]
  );

  const { email } = useSelector(state => ({
    email: getPrimaryEmail(state),
  }));

  const dialogContent = (values: any) => {
    return (
      <Grid container spacing={2} direction='column'>
        <Grid item xs={12} sm={6}>
          <Typography>Email will be sent to {email}</Typography>
        </Grid>
      </Grid>
    );
  };

  return (
    <DazzlingDialog
      id='sendPreviewDialog'
      acceptLabel='Send'
      slice='referralForm'
      alertProps={{ message: 'Preview Email Sent' }}
      metaId={`preview-${formId}`}
      handleClose={onClose}
      headerProps={dialogData}
      height='auto'
      // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 0.
      onAccept={() => dispatchSendPreviewEmail()}
      submitOnEnter={false}
      open={open}
    >
      {/* @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 0. */}
      {dialogContent()}
    </DazzlingDialog>
  );
};

const dialogData = {
  icon: EmailIcon,
  title: 'Send Preview Email',
  subtitle:
    'Send a preview of the form confirmation email to your primary email address.',
};

export default PreviewEmailModal;
