import React, { useEffect } from 'react';
//components
import { CardElement } from '@stripe/react-stripe-js';
import CardField from 'components/CardField';
import { withStyles, WithStyles } from '@material-ui/core/styles';
//helpers
import { isEmpty, isString } from 'helpers/check';
import {
  SetStripePaymentStatus,
  StripePaymentStatus,
} from 'routes/ReferralForm/FormView/helpers/interfaces';
//style
import stripeFormStyles from 'routes/ReferralForm/FormStripeView/components/StripeForm/stripeForm.style';

export interface StripeFormProps extends WithStyles<typeof stripeFormStyles> {
  elements: any;
  stripe: any;
  callSubmitGlobalForm: (...args: any[]) => any;
  setStripeCardElement: (...args: any[]) => any;
  setStripeErrorMessage: (...args: any[]) => any;
  mutators: any;
  stripeErrorMessage?: string | null;
  paymentIntentClientSecret?: string | null;
  referralFormLoading?: boolean;
  stripePaymentStatus: StripePaymentStatus;
  setStripePaymentStatus: SetStripePaymentStatus;
}

const StripeForm = ({
  elements,
  stripe,
  callSubmitGlobalForm,
  setStripeCardElement,
  setStripeErrorMessage,
  mutators,
  stripeErrorMessage,
  paymentIntentClientSecret,
  referralFormLoading,
  stripePaymentStatus,
  setStripePaymentStatus,
}: StripeFormProps) => {
  const handleSubmit = async (event: any) => {
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      setStripeErrorMessage('Stripe has not loaded yet');
      return;
    }

    const cardElement = elements.getElement(CardElement);

    if (cardElement) {
      setStripeCardElement(cardElement);
    }

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    });

    if (error) {
      //Error
      mutators.setValue('paymentMethodId', null);
      mutators.setValue('paymentIntentId', null);
      setStripeErrorMessage('Payment information was not accepted.');
      return;
    }

    if (paymentMethod) {
      const { id } = paymentMethod || {};
      mutators.setValue('paymentMethodId', id);
      mutators.setValue('paymentIntentId', null);
      setStripeErrorMessage(null);
      setStripePaymentStatus('SET_PM');
      callSubmitGlobalForm(event);
      return;
    }

    mutators.setValue('paymentMethodId', null);
    mutators.setValue('paymentIntentId', null);
    setStripeErrorMessage('Payment information was not accepted.');
  };

  useEffect(() => {
    if (
      !referralFormLoading &&
      !isEmpty(paymentIntentClientSecret) &&
      stripePaymentStatus === 'GET_PICS' &&
      stripe
    ) {
      const acceptPayment = async () => {
        setStripePaymentStatus('WAITING_PICS');
        try {
          const stripeHandleCard = await stripe.handleCardAction(
            paymentIntentClientSecret
          );
          if (stripeHandleCard?.error) {
            setStripePaymentStatus('SET_PICS_ERROR');
            setStripeErrorMessage(
              isString(stripeHandleCard.error)
                ? stripeHandleCard.error
                : 'Payment information was not accepted.'
            );
            mutators.setValue('paymentMethodId', null);
            return;
          }
          const { paymentIntent: { id: paymentIntentId } = { id: false } } =
            stripeHandleCard || {};
          if (paymentIntentId) {
            mutators.setValue('paymentIntentId', paymentIntentId);
            setStripeErrorMessage(null);
            setStripePaymentStatus('SET_PICS');
            callSubmitGlobalForm();
            return;
          }
          setStripeErrorMessage('Payment information was not accepted.');
          mutators.setValue('paymentMethodId', null);
          setStripePaymentStatus('SET_PICS_ERROR');
        } catch (e) {
          mutators.setValue('paymentMethodId', null);
          setStripePaymentStatus('SET_PICS_ERROR');
          setStripeErrorMessage('Payment information was not accepted.');
        }
      };
      acceptPayment().then();
    }
    // eslint-disable-next-line
  }, [
    paymentIntentClientSecret,
    referralFormLoading,
    stripePaymentStatus,
    stripe,
  ]);

  return (
    <form
      id='cardStripeForm'
      onSubmit={handleSubmit}
      className='cardStripeForm'
    >
      <CardField
        input={{
          onChange: (event: any) => {
            const { complete = false } = event || {};
            mutators.setValue('stripeFormComplete', complete);
            if (stripeErrorMessage) {
              setStripeErrorMessage(null);
            }
          },
        }}
      />
    </form>
  );
};

export default withStyles(stripeFormStyles)(StripeForm);
