export const EMAIL_RESEND_VERIFICATION_REQUEST =
  'EMAIL_RESEND_VERIFICATION_REQUEST';
export const EMAIL_RESEND_VERIFICATION = 'EMAIL_RESEND_VERIFICATION';

export const EMAIL_MAKE_PRIMARY_REQUEST = 'EMAIL_MAKE_PRIMARY_REQUEST';
export const EMAIL_MAKE_PRIMARY = 'EMAIL_MAKE_PRIMARY';

export const EMAIL_DELETE_REQUEST = 'EMAIL_DELETE_REQUEST';
export const EMAIL_DELETE = 'EMAIL_DELETE';

export const EMAIL_CREATE_REQUEST = 'EMAIL_CREATE_REQUEST';
export const EMAIL_CREATE = 'EMAIL_CREATE';

export const EMAIL_FETCH_REQUEST = 'EMAIL_FETCH_REQUEST';
export const EMAIL_FETCH = 'EMAIL_FETCH';
