import React, { useContext } from 'react';

// material-ui
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

// helpers
import { SiteVisualDataContext } from 'components/SiteVisualData';

const SelectChapterHeader = function() {
  const { selectChapterHeaderTitle, selectChapterHeaderSubTitle } = useContext(
    SiteVisualDataContext
  );

  return (
    <Grid item>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant='h4'>{selectChapterHeaderTitle}</Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography variant='body1'>{selectChapterHeaderSubTitle}</Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SelectChapterHeader;
