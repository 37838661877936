import { REPORT_CREATE, REPORT_CLEAR } from 'store/constants/reportTypes';
import humps from 'humps';

export function createReport(report: any) {
  // camelize column keys
  const { data: { meta: { columns = [] } = {} } = {} } = report || {};
  const formattedColumns = columns.map(({ key, label }: any = {}) => ({
    key: humps.camelize(key),
    label,
  }));

  report.data.meta = { columns: formattedColumns };

  return {
    type: REPORT_CREATE,
    payload: report,
  };
}

export function clearReports() {
  return {
    type: REPORT_CLEAR,
  };
}
