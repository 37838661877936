import React, {
  useState,
  useCallback,
  useMemo,
  useEffect,
  MutableRefObject,
} from 'react';
import { useHistory } from 'react-router-dom';
import { useLeadsInfiniteQuery } from 'api/leads';
import { debounce } from 'lodash';

// material-ui
import Autocomplete from '@material-ui/lab/Autocomplete';
import Search from '@material-ui/icons/Search';
import InputAdornment from '@material-ui/core/InputAdornment';
import Grid from '@material-ui/core/Grid';

// components
import LeadSearchCard from './LeadSearchCard';
import TextField from 'components/TextField';

//styles
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from 'assets/types';

// hooks
import useInfiniteScroll from 'helpers/hooks/useInfiniteScroll';

const LeadSearch = () => {
  const classes = useStyles();
  const history = useHistory();
  const [searchValue, setSearchValue] = useState<string>('');
  const [opened, setOpened] = useState<boolean>(false);
  const [options, setOptions] = useState<Lead[]>([]);

  const {
    data,
    isLoading,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useLeadsInfiniteQuery(searchValue, { enabled: opened });
  const leads: Lead[] = useMemo(() => (data?.pages ? data?.pages.flat() : []), [
    data,
  ]);

  const loading = isLoading || isFetchingNextPage;
  const [scrolledToBottom, scrollRef] = useInfiniteScroll(loading, 2);

  useEffect(() => {
    if (hasNextPage && scrolledToBottom) fetchNextPage();
  }, [hasNextPage, scrolledToBottom, fetchNextPage]);

  useEffect(() => {
    setOptions(() => (opened ? leads : []));
  }, [opened, leads]);

  const toggleOpened = useCallback(() => setOpened(opened => !opened), []);

  const handleSearchChange = useCallback(
    (_, value) => setSearchValue(value),
    []
  );

  const navigateToProfile = useCallback(
    (lead: Lead) => {
      history.push({ pathname: `/leads/${lead?.id}` });
      return lead.fullName;
    },
    [history]
  );

  return (
    <Grid container spacing={2}>
      <Grid item xl={6} lg={7} md={8} sm={10}>
        <Autocomplete
          freeSolo
          open={opened}
          onOpen={toggleOpened}
          onClose={toggleOpened}
          clearText=''
          className={classes.searchBar}
          classes={{ paper: classes.paper, clearIndicator: classes.clear }}
          loading={loading}
          options={options}
          getOptionLabel={option => option && navigateToProfile(option)}
          filterOptions={() => leads}
          onInputChange={debounce(handleSearchChange, 600)}
          renderOption={option => <LeadSearchCard lead={option} />}
          ListboxProps={{
            role: 'listbox',
            ref: scrollRef as MutableRefObject<HTMLElement>,
          }}
          renderInput={params => (
            <TextField
              {...params}
              placeholder='Search Leads'
              variant='standard'
              InputProps={{
                ...params.InputProps,
                className: classes.textField,
                disableUnderline: true,
                startAdornment: (
                  <InputAdornment position='start'>
                    <Search />
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles(theme => {
  return {
    searchBar: {
      marginLeft: 20,
      marginRight: 20,
      borderRadius: 8,
      backgroundColor: (theme as Theme).palette.background.lightPaper,
      transition: 'all 0.25s ease',
      textAlign: 'center',
    },
    textField: {
      paddingLeft: 16,
      marginTop: -8,
    },
    barIcon: {
      textAlign: 'right',
    },
    paper: {
      backgroundColor: (theme as Theme).palette.background.lightPaper,
      boxShadow: '0px 2px 4px 8px #1E2E39',
    },
    clear: {
      marginRight: 16,
    },
  };
});

export default LeadSearch;
