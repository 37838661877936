import React from 'react';
import { useSelector } from 'react-redux';

// selectors
import getCurrentGroup from 'store/selectors/getCurrentGroup';

// components
import InviteMembersCard from './InviteMembersCard';
import RequestsCard from './RequestsCard';
import CurrentMembersCard from './CurrentMembersCard';
import PendingInvitesCard from './PendingInvitesCard';

const MemberSettings = function() {
  const { currentGroup } = useSelector(state => ({
    currentGroup: getCurrentGroup(state),
  }));

  return [
    <InviteMembersCard key='inviteMembersCard' currentGroup={currentGroup} />,
    <RequestsCard key='requestsCard' currentGroup={currentGroup} />,
    <PendingInvitesCard key='pendingInvitesCard' currentGroup={currentGroup} />,
    <CurrentMembersCard key='currentMembersCard' currentGroup={currentGroup} />,
  ];
};

export default MemberSettings;
