import Immutable, { List, Map } from 'immutable';
import { denormalize } from 'normalizr';

import { formFieldSchema } from '../schemas/formFieldSchema';

export default function getFormFields(state: any) {
  const formField = state.get('formField', Map());
  const result = formField.getIn(['data', 'result'], List());

  const entities = {
    formField: state.getIn(['formField', 'data', 'byId'], Map()).toJS(),
  };

  let items = denormalize(result.toJS(), [formFieldSchema], entities);
  items = Immutable.fromJS(items);
  return items.sortBy((item: any) => item.get('position'));
}
