import { all, call, fork, put, takeEvery, take } from 'redux-saga/effects';
import Immutable from 'immutable';
// action creators
import { doneIndicator, success } from 'store/actions/httpActions';
import { fetchFiles } from 'store/actions/fileActions';
import { setFormSubmitSucceeded, destroyForm } from 'store/actions/formActions';
// api
import client from 'sources/api';
import axios from 'axios';
// constants
import {
  FILE_CREATE_AND_UPLOAD_REQUEST,
  FILE_CREATE_AND_UPLOAD,
  FILE_UPLOAD,
} from 'store/constants/fileTypes';
// schemas
import { fileSchema } from 'store/schemas/fileSchema';
// lib
import httpSaga from 'store/sagas/httpSaga';
import getResponsePayload from 'helpers/getResponsePayload';
const WATCH_TYPE = FILE_CREATE_AND_UPLOAD_REQUEST;
export function* createAndUploadFileSaga(action: any) {
  const {
    payload: { file = {}, formName, token },
  } = action;
  const { type, name } = file;
  const postOptions = {};
  if (token) {
    (postOptions as any).headers = {
      Authorization: `Bearer ${token}`,
    };
  }
  /* -- File upload flow -- */
  // 1) POST /files to create file entry in db, returning s3 upload url and file id
  // @ts-expect-error ts-migrate(2569) FIXME: Type 'Generator<any, any, unknown>' is not an arra... Remove this comment to see the full error message
  const createResponse = yield* httpSaga(
    FILE_CREATE_AND_UPLOAD,
    call(
      client.post,
      '/files',
      {
        file_name: name,
        content_type: type,
      },
      postOptions
    ),
    {
      formName,
      dispatchFormSuccess: false,
      dispatchSuccess: false,
      schema: fileSchema,
    }
  );
  if (createResponse) {
    // @ts-expect-error ts-migrate(2525) FIXME: Initializer provides no value for this binding ele... Remove this comment to see the full error message
    const { data: { id, uploadUrl } = {} } = createResponse;
    // 2) Upload file to presigned s3 URL
    const options = {
      headers: {
        'Content-Type': type,
      },
    };
    // @ts-expect-error ts-migrate(2569) FIXME: Type 'Generator<any, any, unknown>' is not an arra... Remove this comment to see the full error message
    yield* httpSaga(FILE_UPLOAD, call(axios.put, uploadUrl, file, options), {
      dispatchSuccess: false,
    });
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 3 arguments, but got 1.
    yield put(success(FILE_UPLOAD));
    // Wait for the file upload to complete
    yield put(doneIndicator(FILE_UPLOAD));
    // 3) GET /files/id. Returns temporary link to image on s3.
    yield put(fetchFiles(id, token));
    // Wait for the new file to be fetched
    yield take('FILE_FETCH_DONE');
  }
  const payload = getResponsePayload(createResponse, fileSchema);
  yield put(success(FILE_CREATE_AND_UPLOAD, Immutable.fromJS(payload)));
  // Set form submit succeeded if formName was given
  yield put(setFormSubmitSucceeded(formName, 'Uploaded successfully'));
  yield put(destroyForm(formName));
  yield put(doneIndicator(FILE_CREATE_AND_UPLOAD));
}
export function* watch() {
  yield takeEvery(WATCH_TYPE, createAndUploadFileSaga);
}
export default function* root() {
  yield all([fork(watch)]);
}
