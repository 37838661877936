import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { doneIndicator } from 'store/actions/httpActions';

//action
import { destroyForm, setFormSubmitSucceeded } from 'store/actions/formActions';

// api
import client from 'sources/api';

// constants
import {
  FORM_FEE_UPDATE,
  FORM_FEE_UPDATE_REQUEST,
} from 'store/constants/formFeeTypes';

// schemas
import { feeSchema } from 'store/schemas/feeSchema';

// helpers
import httpSaga from 'store/sagas/httpSaga';

const WATCH_TYPE = FORM_FEE_UPDATE_REQUEST;
const TYPE = FORM_FEE_UPDATE;

export function* updateFormFeeSaga(action: any) {
  const {
    payload: { formName, groupId, formFees },
  } = action;

  yield all(
    formFees.map((value: FormFee) => {
      const { formId, title, amount } = value;

      return httpSaga(
        TYPE,
        call(client.put, `/form_fees?group_id=${groupId}&form_id=${formId}`, {
          form_fee_name: title,
          fee_amount: amount,
        }),
        {
          shouldDestroyForm: false,
          dispatchFormSuccess: false,
          schema: feeSchema,
          formName,
        }
      );
    })
  );

  yield put(setFormSubmitSucceeded(formName, ''));
  yield put(destroyForm(formName));

  yield put(doneIndicator(TYPE));
}

export function* watch() {
  yield takeEvery(WATCH_TYPE, updateFormFeeSaga);
}

export default function* root() {
  yield all([fork(watch)]);
}
