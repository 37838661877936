import React from 'react';

// redux hooks
import { useDispatch } from 'react-redux';

// actions
import { deleteVotesAction } from 'store/actions/voteActions';
import { DeleteOutline } from '@material-ui/icons';

// components
import DazzlingDialog from 'components/DazzlingDialog';
import { Grid, Typography } from '@material-ui/core';
import useFetchFilters from 'helpers/hooks/useFetchFilters';

const META_ID = 'deleteVotes';

type Props = {
  open: boolean;
  onClose: (...args: any[]) => any;
  allSelected?: boolean;
  leadIds: number[];
};

export default function ClearVotesModal({
  open,
  onClose,
  allSelected = false,
  leadIds,
}: Props) {
  const dispatch = useDispatch();
  const filters = useFetchFilters();
  const leadLabel = leadIds.length === 1 ? 'lead' : 'leads';
  const onSubmit = () => {
    dispatch(
      deleteVotesAction({
        allSelected,
        leadIds,
        filters,
        metaId: META_ID,
      })
    );
  };

  return (
    <DazzlingDialog
      id='clearVotesModal'
      alertProps={{
        message: 'Endorsements Cleared Successfully',
      }}
      handleClose={onClose}
      open={open}
      slice='vote'
      acceptLabel='Clear'
      metaId={META_ID}
      onAccept={onSubmit}
      headerProps={{
        icon: DeleteOutline,
        title: `Clear Endorsements`,
        highlightedSubtitle: `All endorsements for the selected ${leadLabel} will be lost.`,
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid
            container
            justifyContent='center'
            alignItems='center'
            spacing={2}
          >
            <Grid item xs={12}>
              <Typography variant='h6'>
                {'Are you sure that you want to clear these endorsements?'}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </DazzlingDialog>
  );
}
