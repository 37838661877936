import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AddFormFeesModal from 'routes/PaymentSettings/FormFeesCard/AddFormFeesModal/AddFormFeesModal';
import FormFeesForm from 'routes/PaymentSettings/FormFeesCard/FormFeesForm/FormFeesForm';
import Header from 'components/Header';
import AlertMessage from 'components/AlertMessage/AlertMessage';
import Button from 'components/Button';
import { Card, CardContent, Grid } from '@material-ui/core';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import { MonetizationOnOutlined, NewReleases } from '@material-ui/icons';
import AddCircleRoundedIcon from '@material-ui/icons/AddCircleRounded';
//selectors
import getCurrentGroupId from 'store/selectors/getCurrentGroupId';
import getFormFees from 'store/selectors/getFormFees';
//actions
import { fetchReferralFormsForGroup } from 'store/actions/referralFormActions';
import { fetchFormFeeAction } from 'store/actions/formFeeActions';
//styles
import formFeesCardStyle from 'routes/PaymentSettings/FormFeesCard/formFeesCard.style';

export interface FormFeesCardProps
  extends WithStyles<typeof formFeesCardStyle> {
  isPaymentActive?: boolean;
}

const FormFeesCard = ({ classes, isPaymentActive }: FormFeesCardProps) => {
  const dispatch = useDispatch();
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);

  const { groupId, formFees } = useSelector(state => ({
    groupId: getCurrentGroupId(state),
    formFees: getFormFees(state),
  }));

  const handleAddModalOpen = useCallback(() => setIsAddModalOpen(true), [
    setIsAddModalOpen,
  ]);

  useEffect(() => {
    dispatch(fetchReferralFormsForGroup({ groupId }));
    dispatch(fetchFormFeeAction({ groupId }));
  }, [dispatch, groupId]);

  return (
    <Grid item xs={12}>
      <Card id='formFeesCard'>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={12} sm={6}>
                  <Header
                    icon={MonetizationOnOutlined}
                    title='Form Fees'
                    subtitle='Create the fees you want to collect through your forms'
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <AlertMessage
                    icon={<NewReleases color='secondary' />}
                    title='You must set up your payment setting in order to create new form fees.'
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Button
                id='addEmailButton'
                onClick={handleAddModalOpen}
                startIcon={<AddCircleRoundedIcon />}
                className={classes.buttonText}
                disabled={!isPaymentActive}
              >
                New Form Fee
              </Button>
              <AddFormFeesModal
                isModalOpen={isAddModalOpen}
                setIsModalOpen={setIsAddModalOpen}
                formFees={formFees}
                groupId={groupId}
              />
            </Grid>
            <FormFeesForm groupId={groupId} formFees={formFees} />
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default withStyles(formFeesCardStyle)(FormFeesCard);
