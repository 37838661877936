import React, { ReactNode, useMemo, useCallback } from 'react';
import classNames from 'classnames';
// material-ui
import { makeStyles } from '@material-ui/core/styles';
import { Chip, Tooltip } from '@material-ui/core';
// helpers
import FormHelperText from '@material-ui/core/FormHelperText';

interface ManageSubscriptionProps {
  meta: {
    active?: boolean;
    dirty?: boolean;
    touched?: boolean;
    error?: string[];
  };
  input: {
    checked?: boolean;
  };
  title?: string;
  features?: string[];
  price?: ReactNode;
  subTitle?: string;
  disabled?: boolean;
  onLabelClick?: () => void;
}

const SubscriptionPlanItem = ({
  title,
  subTitle,
  features,
  price,
  input,
  disabled,
  onLabelClick,
  meta: { active, dirty, error, touched },
  ...rest
}: ManageSubscriptionProps) => {
  const classes = useStyles();
  const isActive = !!input?.checked && !disabled;
  const errorMessage = useMemo(
    () =>
      error &&
      !active &&
      (dirty || touched) && (
        <FormHelperText className={classes.error} error>
          {error[0]}
        </FormHelperText>
      ),
    [active, dirty, error, touched, classes.error]
  );
  const { firstFeatures, secondFeatures } = useMemo((): {
    firstFeatures?: string[];
    secondFeatures?: string[];
  } => {
    const maxLength = 8;
    const maxLengthPlusOne = maxLength + 1;

    return Array.isArray(features)
      ? {
          firstFeatures: features.slice(
            0,
            features.length > maxLengthPlusOne ? maxLengthPlusOne : maxLength
          ),
          secondFeatures:
            features.length > maxLengthPlusOne
              ? features.slice(maxLength)
              : undefined,
        }
      : {};
  }, [features]);

  const handleLabelClick = useCallback(() => {
    if (onLabelClick && !disabled) {
      onLabelClick();
    }
  }, [onLabelClick, disabled]);

  return (
    <>
      <label
        className={classNames(classes.label, { isActive, disabled })}
        onClick={handleLabelClick}
      >
        <input
          {...input}
          {...rest}
          className={classes.input}
          disabled={disabled}
        />
        <div className={classes.firstPart}>
          <div className={classes.title}>
            {title}
            {subTitle && <div className={classes.subTitle}>{subTitle}</div>}
          </div>
          <div className={classes.text}>
            <ul>
              {(firstFeatures || []).map(item => (
                <li key={item}>{item}</li>
              ))}
            </ul>
            {!!secondFeatures ? (
              <Tooltip
                title={secondFeatures.map(feature => (
                  <div key={feature} className={classes.secondFeatureItem}>
                    {feature}
                  </div>
                ))}
              >
                <Chip
                  className={classes.secondFeatureChip}
                  label={`+ ${secondFeatures.length} others`}
                />
              </Tooltip>
            ) : null}
          </div>
        </div>
        {price && (
          <div className={classes.secondPart}>
            <div className={classes.price}>{price}</div>
          </div>
        )}
        {errorMessage}
      </label>
    </>
  );
};

const useStyles = makeStyles(theme => ({
  label: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    padding: '12px 15px',
    boxSizing: 'border-box',
    border: '1px solid rgba(60, 66, 87, 0)',
    borderRadius: 8,
    overflow: 'hidden',
    '&:hover': {
      borderColor: 'rgba(60, 66, 87, 0.1)',
    },
    '&.disabled:hover': {
      borderColor: 'rgba(60, 66, 87, 0)',
    },
    '&.isActive': {
      borderColor: '#000000 !important',
      backgroundColor: 'rgba(76, 94, 107, 0.2)',
    },
    '&.disabled *': {
      color: theme.palette.grey[600],
    },
  },
  input: {
    display: 'none',
  },
  firstPart: {
    height: '100%',
  },
  secondPart: {
    height: 'auto',
  },
  title: {
    paddingBottom: 10,
    fontSize: '1.5rem',
    lineHeight: '2rem',
    fontWeight: 700,
    color: theme.palette.primary.main,
    [theme.breakpoints.up('sm')]: {
      minHeight: 55,
    },
  },
  subTitle: {
    fontSize: '0.8rem',
    lineHeight: '0.95rem',
    fontWeight: 600,
  },
  text: {
    fontSize: '0.9rem',
    lineHeight: '1.3rem',
    fontWeight: 700,
    paddingBottom: 15,
    '& ul': {
      listStylePosition: 'outside',
      paddingLeft: 20,
      margin: 0,
      '& li': {
        marginBottom: 3,
      },
    },
  },
  price: {
    display: 'flex',
    padding: '10px 20px',
    minHeight: 100,
    fontSize: '1.05rem',
    lineHeight: '1.3rem',
    fontWeight: 600,
    color: theme.palette.common.black,
    alignContent: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    flexWrap: 'wrap',
    borderRadius: 8,
    marginTop: 10,
    marginBottom: 10,
    cursor: 'pointer',
    overflow: 'hidden',
    backgroundColor: theme.palette.common.white,
    boxShadow:
      '0px 6px 10px rgba(0, 0, 0, 0.12), 0px 8px 12px rgba(0, 0, 0, 0.12), 0px 1px 12px rgba(0, 0, 0, 0.12)',
  },
  error: {
    color: `${theme.palette.error.main} !important`,
    textAlign: 'center',
  },
  secondFeatureChip: {
    marginTop: 5,
    width: '100%',
  },
  secondFeatureItem: {
    fontSize: '0.8rem',
    lineHeight: '1.4em',
  },
}));

export default SubscriptionPlanItem;
