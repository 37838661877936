import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import Immutable from 'immutable';

// action creators
import { doneIndicator, success } from 'store/actions/httpActions';

// api
import client from 'sources/api';

// constants
import {
  GROUP_TAG_FETCH_REQUEST,
  GROUP_TAG_FETCH,
} from 'store/constants/groupTagTypes';

// schemas
import { groupTagSchema } from 'store/schemas/groupTagSchema';
import getResponsePayload from 'helpers/getResponsePayload';

// helpers
import httpSaga from 'store/sagas/httpSaga';

const WATCH_TYPE = GROUP_TAG_FETCH_REQUEST;
const TYPE = GROUP_TAG_FETCH;

export function* fetchGroupTagsSaga(action: any) {
  const {
    payload: { groupId, parentId },
  } = action;

  // @ts-expect-error ts-migrate(2569) FIXME: Type 'Generator<any, any, unknown>' is not an arra... Remove this comment to see the full error message
  const response = yield* httpSaga(
    TYPE,
    call(client.get, `/group_tags?group_id=${groupId}&parent_id=${parentId}`),
    {
      dispatchSuccess: false,
      schema: groupTagSchema,
      metaId: groupId,
    }
  );

  const payload = getResponsePayload(response, groupTagSchema);
  payload.data.entities.groupTag[groupId] =
    // @ts-expect-error ts-migrate(2538) FIXME: Type 'undefined' cannot be used as an index type.
    payload.data.entities.groupTag[undefined];
  // @ts-expect-error ts-migrate(2538) FIXME: Type 'undefined' cannot be used as an index type.
  delete payload.data.entities.groupTag[undefined];
  payload.data.result = [groupId];

  yield put(success(TYPE, Immutable.fromJS(payload), groupId));
  yield put(doneIndicator(TYPE));
}

export function* watch() {
  yield takeEvery(WATCH_TYPE, fetchGroupTagsSaga);
}

export default function* root() {
  yield all([fork(watch)]);
}
