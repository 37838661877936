import {
  select,
  all,
  call,
  fork,
  put,
  takeEvery,
  take,
} from 'redux-saga/effects';
import Immutable from 'immutable';

// action creators
import {
  doneIndicator,
  error,
  errorIndicator,
  success,
} from 'store/actions/httpActions';
import { updateManuallyPushLeads } from 'store/actions/leadActions';

// api
import { getClient } from 'sources/api';

// constants
import {
  LEAD_MANUAL_PUSH_REQUEST,
  LEAD_MANUAL_PUSH,
} from 'store/constants/leadTypes';

// helpers
import httpSaga from 'store/sagas/httpSaga';
import delayTwoSeconds from 'helpers/delayTwoSeconds';
import { fetchTask } from 'store/actions/apiTaskActions';
import getSliceEntityById from 'store/selectors/getSliceEntityById';
import {
  setFormSubmitFailed,
  setFormSubmitSucceeded,
} from 'store/actions/formActions';

const WATCH_TYPE = LEAD_MANUAL_PUSH_REQUEST;
const TYPE = LEAD_MANUAL_PUSH;
const META_ID = 'update';

const client = getClient({
  camelizeResponse: true,
  decamelizeRequest: true,
});

export function* updateManuallyPushLeadsSaga(
  action: ReturnType<typeof updateManuallyPushLeads>
): any {
  const {
    payload: {
      pnmIds,
      leadsUpdate = () => {},
      formName,
      groupId,
      name = 'manual_push_leads',
      filters,
    },
  } = action;

  // @ts-expect-error ts-migrate(2569) FIXME: Type 'Generator<any, any, unknown>' is not an arra... Remove this comment to see the full error message
  const taskResponse: any = yield* httpSaga(
    TYPE,
    call(
      client.post,
      '/tasks',
      {
        name,
        params: { lead_ids: pnmIds, ...filters },
      },
      {
        params: { group_id: groupId },
      }
    ),
    {
      metaId: META_ID,
      dispatchSuccess: false,
      dispatchFormSuccess: false,
      formName,
    }
  );

  // Get the task ID from the response payload
  const { taskId } = taskResponse?.data || {};

  // If there is no taskId then just dispatch the done indicator
  // and stop the saga -- there was probably an error that happened
  // with the task creation (permissions/limits error)
  if (!taskId) {
    yield put(doneIndicator(TYPE));
    return;
  }

  let resolvedTask;

  // Wait for the task to resolve
  while (!resolvedTask) {
    yield delayTwoSeconds();

    yield put(fetchTask({ taskId, groupId }));
    yield take('API_TASK_FETCH_DONE');

    const task = yield select(state =>
      getSliceEntityById('apiTask', taskId.toString())(state)
    );

    const { status: statusTask } = task || {};

    if (statusTask && statusTask !== 'PROCESSING') {
      resolvedTask = task;
    }
  }

  const taskData = yield select(state =>
    getSliceEntityById('apiTask', taskId.toString())(state)
  );

  if (resolvedTask?.status === 'FAILURE') {
    const errorPayload = {
      data: {
        msg: taskData?.result?.excMessage || '',
        errors: taskData?.result?.errors || [],
      },
    };

    yield put(errorIndicator(TYPE));
    yield put(setFormSubmitFailed(formName, errorPayload));
    yield put(error(TYPE, errorPayload, META_ID));
  } else {
    yield put(success(TYPE, Immutable.fromJS({}), META_ID));
    yield put(setFormSubmitSucceeded(formName, 'Messages sent successfully'));

    // Fetch messages after success if needed
    leadsUpdate();
  }

  // Finally done
  yield put(doneIndicator(TYPE));
}
export function* watch() {
  // @ts-ignore
  yield takeEvery(WATCH_TYPE, updateManuallyPushLeadsSaga);
}
export default function* root() {
  yield all([fork(watch)]);
}
