import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
// action creators
import { doneIndicator } from 'store/actions/httpActions';
// api
import client from 'sources/api';
// constants
import {
  EVENT_FETCH_BY_ID_REQUEST,
  EVENT_FETCH_BY_ID,
} from 'store/constants/eventTypes';
// helpers
import httpSaga from 'store/sagas/httpSaga';
// schema
import { eventSchema } from 'store/schemas/eventSchema';

const WATCH_TYPE = EVENT_FETCH_BY_ID_REQUEST;
const TYPE = EVENT_FETCH_BY_ID;

export function* fetchEventByIdSaga(action: any) {
  const { groupId: group_id, eventId } = action.payload;
  const options = {};

  let response;
  try {
    // @ts-expect-error ts-migrate(2569) FIXME: Type 'Generator<any, any, unknown>' is not an arra... Remove this comment to see the full error message
    response = yield* httpSaga(
      TYPE,
      call(client.get, `/events/${eventId}`, {
        params: {
          group_id,
        },
        ...options,
      }),
      {
        schema: eventSchema,
      }
    );
    yield put(doneIndicator(TYPE));
  } finally {
    if (response) {
      yield put(doneIndicator(TYPE));
    }
  }
}
export function* watch() {
  yield takeEvery(WATCH_TYPE, fetchEventByIdSaga);
}
export default function* root() {
  yield all([fork(watch)]);
}
