import { Map } from 'immutable';
// constants
import {
  SESSION_VALIDATE,
  SESSION_INVALIDATE,
  SESSION_VERIFY,
  SESSION_VERIFY_DONE,
} from 'store/constants/sessionTypes';

export default function reduceSession(
  state = Map({ valid: 'unknown', verifying: false }),
  action = {}
) {
  switch ((action as any).type) {
    case SESSION_VALIDATE:
      return state.set('valid', true);
    case SESSION_INVALIDATE:
      return state.set('valid', false);
    case SESSION_VERIFY:
      return state.set('verifying', true);
    case SESSION_VERIFY_DONE:
      return state.set('verifying', false);
    default:
      return state;
  }
}
