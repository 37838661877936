import React, { useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { isEqual } from 'lodash';
import { Moment } from 'moment';

// MUI components
import {
  Grid,
  Card,
  CardContent,
  WithStyles,
  withStyles,
} from '@material-ui/core';
import LocalOffer from '@material-ui/icons/LocalOffer';

// components
import Table from 'components/Table';
import AnalyticsActionBar from 'components/AnalyticsActionBar';

// action creators
import { createLabelTotalsReport } from 'store/actions/apiTaskActions';

// selectors
import getCurrentGroupId from 'store/selectors/getCurrentGroupId';
import getForm from 'store/selectors/getForm';
import getSliceEntityById from 'store/selectors/getSliceEntityById';
import getIsHqAdmin from 'store/selectors/getIsHqAdmin';

// helpers
import getTableColumnIcons from 'routes/LabelAnalytics/helpers/getTableColumnIcons';

// Styles
import labelAnalyticsStyle from 'routes/LabelAnalytics/labelAnalytics.style';

const REPORT_FORM = 'labelsTotalsReportForm';

export interface LabelAnalyticsProps
  extends WithStyles<typeof labelAnalyticsStyle> {}

const LabelTotalsAnalyticsCard = ({ classes }: LabelAnalyticsProps) => {
  const dispatch = useDispatch();

  const initialOrder = 'desc';
  const initialOrderBy = 'labels';

  const {
    currentGroupId,
    isHqAdmin,
    report: {
      data: {
        content: unformattedContent = [],
        meta: { columns: unformattedColumns = [] } = {},
      } = [],
      id: reportId,
    } = {},
    form: { isSubmitting },
  } = useSelector(
    state => ({
      currentGroupId: getCurrentGroupId(state),
      isHqAdmin: getIsHqAdmin(state),
      form: getForm(REPORT_FORM)(state),
      report: getSliceEntityById(
        'report',
        'ReportTypes.LABELS_TOTALS',
        {}
      )(state),
    }),
    isEqual
  );

  const icons: any = getTableColumnIcons({ percentClassName: classes.percent });

  const columns = unformattedColumns.map((column: any) => {
    return {
      ...column,
      label: icons?.[column?.key || ''] || '',
      tooltipTitle: column?.label || '',
      disableSort: true,
    };
  });

  const content = useMemo(() => {
    if (!unformattedContent) return [];

    if (isSubmitting || !unformattedContent.length) return [];

    return unformattedContent.map(({ statusId, ...rest }: any) => {
      return {
        key: statusId,
        ...rest,
      };
    });
  }, [isSubmitting, unformattedContent]);

  const dispatchCreateReport = useCallback(
    ({
      startDate,
      endDate,
      forceRefresh,
    }: {
      startDate: Moment;
      endDate: Moment;
      forceRefresh: boolean;
    }) => {
      dispatch(
        createLabelTotalsReport({
          groupId: currentGroupId,
          formName: REPORT_FORM,
          startDate,
          endDate,
          forceRefresh,
        })
      );
    },
    [currentGroupId, dispatch]
  );

  return (
    <Card>
      <CardContent className={classes.cardContent}>
        <Grid container spacing={2}>
          <AnalyticsActionBar
            onCreateReport={dispatchCreateReport}
            title='Chapter Labels - Totals'
            subtitle='View chapter statistics by label categories'
            icon={LocalOffer}
            isHqAdmin={isHqAdmin}
            reportId={reportId}
            loading={isSubmitting}
            isHqReport
          />
        </Grid>

        <Grid item xs={12} className={classes.tableGrid}>
          <Table
            columns={columns}
            data={content}
            variant='outlined'
            numberOfSkeletonRows={isSubmitting ? 8 : 0}
            fetchSortedData={() => {}}
            initialOrder={initialOrder}
            initialOrderBy={initialOrderBy}
            TableRowProps={{
              classes: {
                root: classes.root,
              },
            }}
            TableCellProps={{
              className: classes.tableCell,
            }}
            HeaderTableCellProps={{
              className: classes.headerTableCell,
            }}
          />
        </Grid>
      </CardContent>
    </Card>
  );
};

export default withStyles(labelAnalyticsStyle, { withTheme: true })(
  LabelTotalsAnalyticsCard
);
