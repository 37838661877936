import React, { useMemo } from 'react';
import { isEqual } from 'lodash';
import { useSelector } from 'react-redux';

import TextField from 'components/TextField';
import getLeadStatuses from 'store/selectors/getLeadStatuses';

export default function StatusSelector(props: any) {
  const { statuses = [] } = useSelector(
    state => ({ statuses: getLeadStatuses(state) }),
    isEqual
  );

  const options = useMemo(
    () =>
      statuses.map(({ id, abbreviation, title }: any) => ({
        value: id,
        label: [abbreviation, title].join(' - '),
      })),
    [statuses]
  );
  return <TextField select {...{ options, ...props }} />;
}
