import { Styles } from 'assets/types';

const groupActivityCardStyle: Styles = theme => ({
  container: {
    height: '100%',
  },
  cardContent: {
    paddingLeft: 24,
    paddingRight: 24,
  },
  dateRangeContainer: {
    [theme.breakpoints.down('lg')]: {
      order: 1,
    },
  },
});

export default groupActivityCardStyle;
