import React, { useState, useEffect, useCallback, useMemo } from 'react';
import pluralize from 'pluralize';
import { isEqual } from 'lodash';

// hooks
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useLeadsInfiniteQuery } from 'api/leads';

// material-ui
import { Box, Grid } from '@material-ui/core';
import { NewReleases } from '@material-ui/icons';

// components
import Banner from 'components/Banner';
import LeadListActions from 'routes/LeadList/LeadListView/LeadListActions';
import LeadListCardView from 'routes/LeadList/LeadListView/LeadListCardView';
import LeadListTableView from 'routes/LeadList/LeadListView/LeadListTableView';

// selectors
import getCurrentGroupId from 'store/selectors/getCurrentGroupId';
import getNextPageFromState from 'store/selectors/getNextPageFromState';
import getLoadingFromState from 'store/selectors/getLoadingFromState';
import getCurrentGroup from 'store/selectors/getCurrentGroup';
import getSliceState from 'store/selectors/getSliceState';
import getPermission from 'store/selectors/getPermission';
import getReportingPlatformName from 'store/selectors/getReportingPlatformName';

// action creators
import { fetchTagsAction } from 'store/actions/tagActions';
import { fetchStatusesAction } from 'store/actions/statusActions';

// helpers
import { LeadsQueryData } from 'routes/LeadList/helpers/interfaces';
import getPushLead from 'store/selectors/getPushLead';

interface LeadListViewProps {
  isCouncilLeadPage?: boolean | null;
}

export default function LeadListView({ isCouncilLeadPage }: LeadListViewProps) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { location: { pathname, search, state = {} } = {} } = history;
  const {
    data,
    isLoading,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    refetch: refetchLeads,
  } = useLeadsInfiniteQuery();
  const leads: Lead[] = useMemo(() => (data?.pages ? data?.pages.flat() : []), [
    data,
  ]);
  const totalLeadsCount = useMemo(
    () =>
      data?.pageParams
        ? (data?.pageParams as { total: number }[])[data?.pageParams.length - 1]
            ?.total
        : 0,
    [data]
  );

  const {
    currentGroupId,
    currentGroup,
    nextPage,
    tags = [],
    tagsLoading,
    pushLead,
    reportingPlatformName,
  } = useSelector(
    state => ({
      currentGroupId: getCurrentGroupId(state),
      currentGroup: getCurrentGroup(state),
      nextPage: getNextPageFromState('lead')(state),
      tagsLoading: getLoadingFromState('tag', null, false)(state),
      tags: (getSliceState('tag')(state) || {}).data,
      pushLead: getPushLead(state),
      reportingPlatformName: getReportingPlatformName(state),
    }),
    isEqual
  );

  const loading = useMemo(
    () =>
      isLoading || isFetchingNextPage || !!(isCouncilLeadPage && tagsLoading),
    [isLoading, isFetchingNextPage, isCouncilLeadPage, tagsLoading]
  );

  const newMembersBannerText = useMemo(
    () =>
      `You have ${currentGroup.get('newMemberPnmsCount', 0)} new 
    ${pluralize('member', currentGroup.get('newMemberPnmsCount', 0))} 
    to report to ${reportingPlatformName}.`,
    [currentGroup, reportingPlatformName]
  );

  const initialListType =
    (state as any).listType || (isCouncilLeadPage ? 'table' : 'card');
  const [listType, setListType] = useState(initialListType);
  const [allSelected, setAllSelected] = useState(false);
  const [actionLeadIds, setActionLeadIds] = useState<number[]>([]);
  const handleResetSelected = useCallback(() => {
    setActionLeadIds([]);
    setAllSelected(false);
  }, []);

  const handleSelectAll = useCallback(() => {
    setActionLeadIds([]);
    setAllSelected(true);
  }, []);
  const handleSelectAllFetched = useCallback(
    () => setActionLeadIds(leads.map(lead => lead.id)),
    [leads]
  );
  const selectLead = useCallback(
    leadId =>
      setActionLeadIds(actionLeadIds =>
        actionLeadIds.includes(leadId)
          ? actionLeadIds.filter(actionLeadId => actionLeadId !== leadId)
          : [...actionLeadIds, leadId]
      ),
    []
  );

  const navigateToReportingPage = useCallback(() => {
    history.push({
      pathname: '/review-members',
      search,
    });
  }, [history, search]);

  const navigateToDuplicatesPage = useCallback(() => {
    history.push({
      pathname: '/leads/duplicates',
    });
  }, [history]);

  const canMergeDuplicates = useSelector(
    getPermission('pnmDuplicate.deleteMany')
  );

  const handleListTypeToggle = () => {
    history.push({
      pathname,
      search,
      // @ts-expect-error ts-migrate(2698) FIXME: Spread types may only be created from object types... Remove this comment to see the full error message
      state: { ...state, listType: listType === 'card' ? 'table' : 'card' },
    });
    setListType(listType === 'card' ? 'table' : 'card');
  };
  const handleFetchNextPage = useCallback(() => {
    if (!loading) fetchNextPage();
  }, [loading, fetchNextPage]);

  useEffect(() => {
    if (isCouncilLeadPage && listType === 'table') {
      dispatch(fetchStatusesAction({ groupId: currentGroupId }));
      dispatch(fetchTagsAction({ groupId: currentGroupId }));
    }
  }, []); // eslint-disable-line

  return (
    <Box height='100%' id='leadListContainer' clone>
      <Grid container direction='column' wrap='nowrap'>
        <Grid item>
          <LeadListActions
            onListTypeToggle={handleListTypeToggle}
            actionLeadIds={actionLeadIds}
            onSelectAll={handleSelectAll}
            leads={leads}
            totalLeadsCount={totalLeadsCount}
            allSelected={allSelected}
            onSelectAllFetched={handleSelectAllFetched}
            onResetSelected={handleResetSelected}
            listType={listType}
            isCouncilLeadPage={isCouncilLeadPage}
            tags={tags}
            leadsQuery={{
              queryKey: (data as LeadsQueryData)?.queryKey,
              refetchLeads,
            }}
            pushLead={pushLead}
            groupId={currentGroupId}
          />
        </Grid>
        <Box paddingY={2} paddingX={3}>
          <Grid container spacing={3}>
            {reportingPlatformName &&
              Boolean(currentGroup.get('newMemberPnmsCount', 0)) && (
                <Grid item xs={12} md={6}>
                  <Banner
                    buttonLabel='Review Members'
                    header='Congratulations!'
                    subtitle={newMembersBannerText}
                    onButtonClick={navigateToReportingPage}
                  />
                </Grid>
              )}
            {canMergeDuplicates && currentGroup.get('hasDuplicatePnms', false) && (
              <Grid item xs={12} md={6}>
                <Banner
                  buttonLabel='Manage Duplicates'
                  header='Let’s clean those up!'
                  subtitle='We’ve found duplicate leads on your list.'
                  icon={<NewReleases />}
                  onButtonClick={navigateToDuplicatesPage}
                />
              </Grid>
            )}
          </Grid>
        </Box>
        <Box clone overflow='hidden'>
          <Grid item xs>
            {listType === 'card' && (
              <LeadListCardView
                allSelected={allSelected}
                hasNextPage={hasNextPage}
                leads={leads}
                loading={loading}
                nextPage={nextPage}
                onFetchNextPage={handleFetchNextPage}
                actionLeadIds={actionLeadIds}
                selectLead={selectLead}
                withPaddingTop={
                  !Boolean(
                    currentGroup.get('hasDuplicatePnms', false) ||
                      currentGroup.get('newMemberPnmsCount', 0)
                  )
                }
              />
            )}
            {listType === 'table' && (
              <LeadListTableView
                leads={leads}
                onFetchNextPage={handleFetchNextPage}
                hasNextPage={hasNextPage}
                allSelected={allSelected}
                actionLeadIds={actionLeadIds}
                selectLead={selectLead}
                loading={loading}
                withMarginTop={Boolean(
                  currentGroup.get('hasDuplicatePnms', false) ||
                    currentGroup.get('newMemberPnmsCount', 0)
                )}
                isCouncilLeadPage={isCouncilLeadPage}
                tags={tags}
                leadsQuery={{
                  queryKey: (data as LeadsQueryData)?.queryKey,
                  refetchLeads,
                }}
              />
            )}
          </Grid>
        </Box>
      </Grid>
    </Box>
  );
}
