import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import ApartmentOutlinedIcon from '@material-ui/icons/ApartmentOutlined';
import AssignmentTurnedInOutlinedIcon from '@material-ui/icons/AssignmentTurnedInOutlined';
import PhoneIcon from '@material-ui/icons/Phone';
import SmsIcon from '@material-ui/icons/Sms';
import EmailIcon from '@material-ui/icons/Email';
import PeopleOutline from '@material-ui/icons/PeopleOutline';
import DateRange from '@material-ui/icons/DateRange';
// import PeopleIcon from '@material-ui/icons/People';
// import HowToRegIcon from '@material-ui/icons/HowToReg';
// import BallotIcon from '@material-ui/icons/Ballot';

const getTableColumns = () => [
  {
    key: 'chapterName',
    label: (
      <Tooltip title='Chapter'>
        <ApartmentOutlinedIcon />
      </Tooltip>
    ),
    disableSort: true,
    isTooltipAllowed: true,
  },
  // {
  //   key: 'totalLeadsCount',
  //   label: (
  //     <Tooltip title='Total Leads'>
  //       <PeopleIcon />
  //     </Tooltip>
  //   ),
  //   disableSort: true,
  //   isTooltipAllowed: true,
  // },
  // {
  //   key: 'filteredLeadsCount',
  //   label: (
  //     <Tooltip title='Total Leads with Selected Statuses'>
  //       <BallotIcon />
  //     </Tooltip>
  //   ),
  //   disableSort: true,
  //   isTooltipAllowed: true,
  // },
  // {
  //   key: 'filteredLeadsPercentage',
  //   label: (
  //     <Tooltip title='Percentage of Leads with Selected Statuses'>
  //       <HowToRegIcon />
  //     </Tooltip>
  //   ),
  //   disableSort: true,
  //   isTooltipAllowed: true,
  // },
  {
    key: 'filteredStatuses',
    label: (
      <Tooltip title='Selected Statuses'>
        <AssignmentTurnedInOutlinedIcon />
      </Tooltip>
    ),
    disableSort: true,
    isTooltipAllowed: true,
  },
  {
    key: 'callsReceived',
    label: (
      <Tooltip title='Calls Received'>
        <PhoneIcon />
      </Tooltip>
    ),
    disableSort: true,
    isTooltipAllowed: true,
  },
  {
    key: 'textsReceived',
    label: (
      <Tooltip title='Texts Received'>
        <SmsIcon />
      </Tooltip>
    ),
    disableSort: true,
    isTooltipAllowed: true,
  },
  {
    key: 'emailsReceived',
    label: (
      <Tooltip title='Emails Received'>
        <EmailIcon />
      </Tooltip>
    ),
    disableSort: true,
    isTooltipAllowed: true,
  },
  {
    key: 'eventsAttended',
    label: (
      <Tooltip title='Events Attended - determined by "Attended Meeting or Activity" milestone'>
        <DateRange />
      </Tooltip>
    ),
    disableSort: true,
    isTooltipAllowed: true,
  },
  {
    key: 'membersInvolved',
    label: (
      <Tooltip title='Members Involved'>
        <PeopleOutline />
      </Tooltip>
    ),
    disableSort: true,
    isTooltipAllowed: true,
  },
];

export default getTableColumns;
