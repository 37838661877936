import { AxiosError } from 'axios';
import { getClient } from 'sources/api';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { Resource } from 'api';
import getCurrentGroupId from 'store/selectors/getCurrentGroupId';

const client = getClient({
  camelizeResponse: false,
  decamelizeRequest: false,
});

export function useLeadAttrsQuery(leadId: number, isEnabled: boolean = true) {
  const groupId = useSelector(getCurrentGroupId);
  return useQuery<any, AxiosError>(
    [Resource.LeadAttrs, groupId, leadId],
    () =>
      client
        .get(Resource.LeadAttrs, {
          params: { group_id: groupId, pnm_id: leadId },
        })
        .then(({ data }) => data),
    {
      enabled: Boolean(isEnabled && groupId && !Number.isNaN(leadId)),
    }
  );
}
