import React, { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { Map } from 'immutable';
import { isEqual } from 'lodash';
// material-ui
import { withStyles, useTheme, WithStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
// icons
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ViewIcon from '@material-ui/icons/RemoveRedEye';
import EditIcon from '@material-ui/icons/Edit';
// components
import FormView from './FormView';
import FormEditor from './FormEditor';
import Button from 'components/Button';
// selectors
import getFormFields from 'store/selectors/getFormFields';
import getReferralForms from 'store/selectors/getReferralForms';
import getPermission from 'store/selectors/getPermission';
import getCurrentGroupId from 'store/selectors/getCurrentGroupId';
import getLoadingFromState from 'store/selectors/getLoadingFromState';
import getIsCurrentGroupCouncil from 'store/selectors/getIsCurrentGroupCouncil';
import getCurrentPaymentStatus from 'store/selectors/getCurrentPaymentStatus';
import getFormFees from 'store/selectors/getFormFees';
import getIsCurrentGroupOrganization from 'store/selectors/getIsCurrentGroupOrganization';
import getPaymentAccountNumber from 'store/selectors/getPaymentAccountNumber';
import getSliceItem from 'store/selectors/getSliceItem';
import getIsSystemAdmin from 'store/selectors/getIsSystemAdmin';
import getIsCurrentGroupSystem from 'store/selectors/getIsCurrentGroupSystem';
// action creators
import { fetchReferralFormById } from 'store/actions/referralFormActions';
import { fetchOneFormFeeAction } from 'store/actions/formFeeActions';
import { fetchPaymentSettingsAction } from 'store/actions/paymentSettingsActions';
import {
  fetchFormFields,
  clearFormFields,
} from 'store/actions/formFieldActions';
import getPaymentStatus from 'store/selectors/getPaymentStatus';
//helpers
import { isArray, isEmpty } from 'helpers/check';
import { isPaymentMethodEnabled } from 'helpers/checkPaymentMethod';
import { toInt10 } from 'helpers/transform';
//styles
import referralFormStyles from 'routes/ReferralForm/referralForm.style';

export interface ReferralFormProps
  extends WithStyles<typeof referralFormStyles> {}

const ReferralForm = function({ classes }: ReferralFormProps) {
  const dispatch = useDispatch();
  const theme = useTheme();
  const history = useHistory();
  const params = useParams();
  const { formId } = (params as { formId?: number | string | null }) || {};
  const { location: { pathname, search } = {} } = history;
  const urlParams = new URLSearchParams(search);
  const token = urlParams.get('token');
  const isExtraSmall = useMediaQuery(theme.breakpoints.only('xs'));
  const isMaxHeight810 = useMediaQuery('(max-height:810px)');
  const toggleFormButtonVariant = isExtraSmall ? 'contained' : 'outlined';
  const [wasPaymentSettingsFetched, setWasPaymentSettingsFetched] = useState<
    boolean
  >(false);

  // Get state data
  const {
    currentGroupId,
    currentUserId,
    formFields,
    forms,
    canUpdateForm,
    referralFormsLoading,
    isCurrentGroupCouncil,
    isCurrentGroupOrganization,
    isCurrentGroupSystem,
    hasPaymentMethod,
    paymentStatus,
    formFees,
    accountNumber,
    stripeApiKey,
    isSystemAdmin,
  } = useSelector(
    state => ({
      canUpdateForm: getPermission('form.updateOne')(state),
      currentGroupId: getCurrentGroupId(state),
      currentUserId: (state as any).getIn(['currentUser', 'data', 'id']),
      formFields: getFormFields(state),
      referralFormsLoading: getLoadingFromState(
        'referralForm',
        null,
        true
      )(state),
      forms: getReferralForms(state),
      isCurrentGroupCouncil: getIsCurrentGroupCouncil(state),
      isCurrentGroupOrganization: getIsCurrentGroupOrganization(state),
      isCurrentGroupSystem: getIsCurrentGroupSystem(state),
      hasPaymentMethod: isPaymentMethodEnabled(getCurrentPaymentStatus(state)),
      paymentStatus: getPaymentStatus(state),
      stripeApiKey: getSliceItem('paymentSettings', 'stripeApiKey')(state),
      formFees: getFormFees(state),
      accountNumber: getPaymentAccountNumber(state),
      isSystemAdmin: getIsSystemAdmin(state),
    }),
    isEqual
  );

  const formFeeByFormId = useMemo<FormFee | undefined>(() => {
    if (!formFees || !isArray(formFees) || !formId) {
      return undefined;
    }
    const formIdInt = toInt10(formId);
    return formFees.find(
      ({ formId: itemFormId }: FormFee) => itemFormId === formIdInt
    );
  }, [formFees, formId]);

  const formData = forms.find(
    (form: any) => form.get('id').toString() === formId,
    {},
    Map()
  );

  const groupId = formData.get('groupId') || currentGroupId;
  const formBelongsToCurrentGroup = currentGroupId === formData.get('groupId');

  const {
    isOrganizationFormGroupType,
    isCouncilFormGroupType,
    isSystemFormGroupType,
  } = useMemo<{
    isOrganizationFormGroupType: boolean;
    isCouncilFormGroupType: boolean;
    isSystemFormGroupType: boolean;
  }>(() => {
    const formType = formData ? formData?.get('groupType') : false;
    return {
      isOrganizationFormGroupType: !!(formType === 3),
      isCouncilFormGroupType: !!(formType === 4),
      isSystemFormGroupType: !!(formType === 5),
    };
  }, [formData]);

  const isFormCollectPaymentTurnOn = formData
    ? formData?.get('collectPayment')
    : false;

  const canViewForm =
    (currentUserId && formBelongsToCurrentGroup) ||
    (formData.get('isPublished') && token);

  const isUniversalForm = useMemo<boolean>(
    () => isCurrentGroupSystem && isSystemAdmin,
    [isSystemAdmin, isCurrentGroupSystem]
  );

  useEffect(() => {
    // Fetch form fields
    dispatch(clearFormFields());
    if (groupId) {
      dispatch(fetchFormFields({ formId, groupId, token }));
    }
  }, [dispatch, formId, groupId, token]);

  useEffect(() => {
    // Checks if form data exists, refetches form if not
    if (!formData.size && formId && (token || groupId)) {
      dispatch(fetchReferralFormById({ formId, groupId, token }));
    }
  }, [groupId]); // eslint-disable-line

  useEffect(() => {
    if (hasPaymentMethod && groupId && isCurrentGroupCouncil && formId) {
      dispatch(fetchOneFormFeeAction({ groupId, formId: toInt10(formId) }));
    }
  }, [dispatch, groupId, hasPaymentMethod, formId, isCurrentGroupCouncil]);

  useEffect(() => {
    if (hasPaymentMethod && groupId) {
      dispatch(fetchPaymentSettingsAction({ groupId }));
    }
  }, [dispatch, groupId, hasPaymentMethod]);

  //get data when view form by token
  useEffect(() => {
    if (
      !wasPaymentSettingsFetched &&
      !isEmpty(token) &&
      isFormCollectPaymentTurnOn &&
      groupId &&
      formId
    ) {
      setWasPaymentSettingsFetched(true);
      dispatch(fetchPaymentSettingsAction({ groupId, token }));
      dispatch(
        fetchOneFormFeeAction({ groupId, formId: toInt10(formId), token })
      );
    }
  }, [
    dispatch,
    groupId,
    formId,
    isFormCollectPaymentTurnOn,
    token,
    wasPaymentSettingsFetched,
  ]);

  const navigateBack = () => {
    history.goBack();
  };
  // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
  const isViewing = pathname.includes('view');

  const hasActivePaymentMethod =
    (hasPaymentMethod || isPaymentMethodEnabled(paymentStatus)) &&
    !isEmpty(formFeeByFormId);

  const renderToggleFormButton = () => {
    if (isViewing) {
      return (
        <Button
          id='editButton'
          variant={toggleFormButtonVariant}
          startIcon={<EditIcon />}
          fabIcon={<EditIcon />}
          className={classes.toggleFormButton}
          onClick={() => history.push(`/forms/${formId}/edit`)}
        >
          Edit Form
        </Button>
      );
    } else {
      return (
        <Button
          id='viewButton'
          variant={toggleFormButtonVariant}
          startIcon={<ViewIcon />}
          fabIcon={<ViewIcon />}
          className={`${classes.toggleFormButton}${
            isMaxHeight810 && !isExtraSmall ? ' minHeightBtn' : ''
          } viewBtn`}
          onClick={() => history.push(`/forms/${formId}/view`)}
        >
          View Form
        </Button>
      );
    }
  };

  return (
    <Grid
      container
      className={classes.backgroundMain}
      justifyContent={isViewing ? 'center' : 'flex-start'}
    >
      <div className={classes.backgroundTriangle} />
      {currentUserId && (
        <Hidden smDown>
          <IconButton
            id='backButton'
            className={classes.backButton}
            onClick={navigateBack}
          >
            <ArrowBackIcon />
          </IconButton>
        </Hidden>
      )}

      {isViewing ? (
        <FormView
          formData={formData}
          groupId={groupId}
          formFields={formFields}
          token={token}
          canViewForm={!!canViewForm}
          isCouncilForm={isCurrentGroupCouncil || isCouncilFormGroupType}
          isOrganizationForm={
            isCurrentGroupOrganization || isOrganizationFormGroupType
          }
          isUniversalForm={isUniversalForm || isSystemFormGroupType}
          hasActivePaymentMethod={hasActivePaymentMethod}
          accountNumber={accountNumber}
          stripeApiKey={stripeApiKey}
          {...(!isEmpty(formFeeByFormId)
            ? { formFeeValue: formFeeByFormId }
            : {})}
        />
      ) : (
        <FormEditor
          formData={formData}
          formFields={formFields}
          isCouncilForm={isCurrentGroupCouncil}
          isOrganizationForm={isCurrentGroupOrganization}
          isUniversalForm={isUniversalForm}
          hasActivePaymentMethod={hasActivePaymentMethod}
        />
      )}

      {currentUserId &&
        canUpdateForm &&
        formBelongsToCurrentGroup &&
        !referralFormsLoading &&
        renderToggleFormButton()}
    </Grid>
  );
};

export default withStyles(referralFormStyles, { withTheme: true })(
  ReferralForm
);
