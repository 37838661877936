import React from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import classNames from 'classnames';
// hooks
import { useDateTime } from 'helpers/hooks/useDateTime';
// MUI components
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Tooltip } from '@material-ui/core';
const Message = function({ message }: any) {
  const classes = useStyles();
  const createdOn = useDateTime(message.get('createdOn'));
  const timestampLabel = `${createdOn.date} at ${createdOn.time}`;
  return (
    <Tooltip
      title={timestampLabel}
      placement={message.get('type') === 'outgoing' ? 'left' : 'right'}
    >
      <div
        className={classNames(
          classes.threadItemContainer,
          message.get('type') === 'outgoing'
            ? classes.outgoingThreadItem
            : classes.incomingThreadItem
        )}
      >
        <Typography variant='body2' className={classes.messageBodyText}>
          {message.get('body')}
        </Typography>
      </div>
    </Tooltip>
  );
};
Message.propTypes = {
  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'typeof Map' is not assignable to... Remove this comment to see the full error message
  message: PropTypes.instanceOf(Map).isRequired,
};
Message.defaultProps = {
  message: Map(),
};
const useStyles = makeStyles(theme => {
  return {
    threadItemContainer: {
      borderRadius: 20,
      paddingLeft: 15,
      paddingRight: 15,
      paddingTop: 8,
      paddingBottom: 8,
    },
    outgoingThreadItem: {
      backgroundColor: theme.palette.primary.main,
    },
    incomingThreadItem: {
      backgroundColor: (theme.palette.background as any).lightPaper,
    },
    messageBodyText: {
      whiteSpace: 'pre-line',
    },
  };
});
export default Message;
