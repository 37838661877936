import React, { useState, useEffect } from 'react';
import { isEqual } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';

// mui
import {
  Box,
  Grid,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  CircularProgress,
  Fade,
} from '@material-ui/core';
import { Edit, Delete, MoreHoriz } from '@material-ui/icons';

// components
import SuperAvatar from 'components/SuperAvatar';
import UpdateCommentInput from './UpdateCommentInput';

// actions
import { deleteCommentAction } from 'store/actions/commentActions';

// selectors
import getSliceEntityById from 'store/selectors/getSliceEntityById';
import getCurrentUser from 'store/selectors/getCurrentUser';
import getLoadingFromState from 'store/selectors/getLoadingFromState';

type Props = {
  id: number;
  body: string;
  accountId: number;
  onDelete: (...args: any[]) => any;
};

export default function Comment({ id, body, accountId, onDelete }: Props) {
  const dispatch = useDispatch();
  const { member, currentUser, memberLoading, loading } = useSelector(
    state => ({
      member: getSliceEntityById('member', accountId)(state),
      memberLoading: getLoadingFromState('member', null, false)(state),
      loading: getLoadingFromState('comment', id, false)(state),
      currentUser: getCurrentUser(state),
    }),
    isEqual
  );
  const [editing, setEditing] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [hovered, setHovered] = useState(false);
  const isCommenterCurrentUser = currentUser.get('id') === accountId;
  const commenter: Account =
    (isCommenterCurrentUser ? currentUser.toJS() : member?.account) || {};
  const showMoreButton =
    (currentUser.get('isSystemAdmin') || isCommenterCurrentUser) &&
    hovered &&
    !editing;

  const finishEditing = () => setEditing(false);
  const closeMenu = () => setAnchorEl(null);
  const deleteComment = () => {
    dispatch(deleteCommentAction({ id }));
    setAnchorEl(null);
  };
  const openMenu = (event: any) => setAnchorEl(event.currentTarget);
  const handleMouseEnter = () => setHovered(true);
  const handleMouseLeave = () => setHovered(false);
  const startEditing = () => {
    setEditing(true);
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 0 arguments, but got 1.
    closeMenu(null);
  };

  useEffect(
    () => () => {
      onDelete();
    },
    [onDelete]
  );
  return (
    <>
      <Grid
        container
        spacing={2}
        alignItems='center'
        wrap='nowrap'
        direction={isCommenterCurrentUser ? 'row-reverse' : 'row'}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <Grid item>
          <SuperAvatar
            fullName={commenter.fullName}
            loading={commenter && memberLoading}
            tooltipTitle={commenter?.fullName}
            imageId={commenter.profilePhotoFileId}
          />
        </Grid>
        <Grid item xs={editing} style={{ marginTop: -20 }}>
          <Box clone color='text.secondary' marginLeft={1}>
            <Typography variant='caption'>{commenter.fullName}</Typography>
          </Box>
          <Box height={3} />

          <Box
            paddingX={2}
            paddingY={editing ? 0 : 1}
            border={1}
            borderColor='background.lightPaper'
            borderRadius={20}
            minWidth={32}
          >
            {editing ? (
              <UpdateCommentInput id={id} body={body} onExit={finishEditing} />
            ) : (
              <Grid container spacing={1} alignItems='center'>
                <Grid item>
                  <Box clone whiteSpace='pre-line'>
                    <Typography variant='body2'>{body}</Typography>
                  </Box>
                </Grid>
                {loading && (
                  <Grid item>
                    <CircularProgress size={16} />
                  </Grid>
                )}
              </Grid>
            )}
          </Box>
        </Grid>
        <Fade in={showMoreButton}>
          <Grid item>
            <Box
              clone
              border={1}
              borderRadius='50%'
              borderColor='background.lightPaper'
            >
              {isCommenterCurrentUser ? (
                <IconButton size='small' onClick={openMenu} name='actions'>
                  <MoreHoriz />
                </IconButton>
              ) : (
                <IconButton size='small' onClick={deleteComment}>
                  <Delete />
                </IconButton>
              )}
            </Box>
          </Grid>
        </Fade>
      </Grid>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        keepMounted
        onClose={closeMenu}
      >
        <MenuItem onClick={startEditing} dense>
          <Grid container spacing={1} alignItems='center'>
            <Edit fontSize='small' />
            <Grid item>Edit</Grid>
          </Grid>
        </MenuItem>
        <MenuItem onClick={deleteComment} dense>
          <Grid container spacing={1} alignItems='center'>
            <Delete fontSize='small' />
            <Grid item>Delete</Grid>
          </Grid>
        </MenuItem>
      </Menu>
    </>
  );
}
