import React, { useMemo } from 'react';
import moment from 'moment';
import { Map } from 'immutable';

// material-ui
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import { CallMade } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

// hooks
import { useSelector } from 'react-redux';

// selectors
import getCurrentSubscription from 'store/selectors/getCurrentSubscription';
import getCustomer from 'store/selectors/getCustomer';
import getLoadingFromState from 'store/selectors/getLoadingFromState';
import getErrorMessage from 'store/selectors/getErrorMessage';

// components
import BillingOverviewItem from 'routes/BillingSettings/components/BillingOverviewItem';
import ManageSubscriptionButtons from 'routes/BillingSettings/components/ManageSubscriptionButtons';
import AccountLocked from 'routes/BillingSettings/components/AccountLocked';

// helpers
import { toString, toStringWithoutNullUndefined } from 'helpers/transform';
import { isEmpty } from 'helpers/check';

const DATE_FORMAT = 'MMMM Do, YYYY';

const BillingSettings = () => {
  const classes = useStyles();
  const {
    currentSubscription,
    customer,
    billingLoading,
    billingErrorMessage,
  } = useSelector(state => ({
    currentSubscription: getCurrentSubscription(state),
    customer: getCustomer(state),
    billingLoading: getLoadingFromState('billing', false, false)(state),
    billingErrorMessage: getErrorMessage('billing')(state),
  }));
  const { subscriptionStatus, subscriptionPlanName = '' } = useMemo(
    (): {
      subscriptionStatus?: string;
      subscriptionPlanName?: string;
    } =>
      !currentSubscription || !Map.isMap(currentSubscription)
        ? {}
        : {
            subscriptionStatus: toString(currentSubscription.get('status')),
            subscriptionPlanName: toString(
              currentSubscription.getIn(['plan', 'name'])
            ),
          },
    [currentSubscription]
  );

  const isBillingError = useMemo<boolean>(() => {
    if (billingLoading) {
      return false;
    }

    const isEmptyCustomer = Map.isMap(customer)
      ? customer.isEmpty()
      : isEmpty(customer);

    return isEmptyCustomer || !!billingErrorMessage;
  }, [customer, billingErrorMessage, billingLoading]);

  const isEmptyCurrentSubscription = useMemo<boolean>(
    () =>
      Map.isMap(currentSubscription)
        ? currentSubscription.isEmpty()
        : isEmpty(currentSubscription),
    [currentSubscription]
  );

  const hasDefaultPaymentMethod = useMemo<boolean>(() => {
    if (isBillingError) {
      return false;
    }

    const subscriptionDefaultPaymentMethod = Map.isMap(currentSubscription)
      ? currentSubscription.get('defaultPaymentMethod')
      : null;
    const customerDefaultPaymentMethod = Map.isMap(customer)
      ? customer.getIn(['invoiceSettings', 'defaultPaymentMethod'])
      : null;

    return !!(subscriptionDefaultPaymentMethod || customerDefaultPaymentMethod);
  }, [isBillingError, currentSubscription, customer]);

  const { currentCardBrand, currentCardLast4 } = useMemo(
    (): {
      currentCardBrand?: string;
      currentCardLast4?: string;
    } =>
      !customer || !Map.isMap(customer) || customer.isEmpty()
        ? {}
        : {
            currentCardBrand: toStringWithoutNullUndefined(
              customer.getIn(['paymentCard', 'brand'])
            ),
            currentCardLast4: toStringWithoutNullUndefined(
              customer.getIn(['paymentCard', 'last4'])
            ),
          },
    [customer]
  );

  const isSubscriptionBlocked = subscriptionStatus !== 'active';

  const accountCreatedDate = moment
    .unix(customer.get('created'))
    .format(DATE_FORMAT);
  const currentPeriodStart = moment
    .unix(currentSubscription?.get('currentPeriodStart'))
    .format(DATE_FORMAT);
  const currentPeriodEnd = moment
    .unix(currentSubscription?.get('currentPeriodEnd'))
    .format(DATE_FORMAT);

  return (
    <Grid container>
      <Grid
        item
        xs={12}
        {...(!isSubscriptionBlocked
          ? { md: 8, lg: 6, xl: 5 }
          : { md: 11, lg: 8 })}
      >
        <Grid container spacing={2} alignItems='center'>
          {!isSubscriptionBlocked ? (
            <>
              <BillingOverviewItem
                billingLoading={billingLoading}
                title='Current Plan'
                description={subscriptionPlanName}
              />
              <BillingOverviewItem
                billingLoading={billingLoading}
                title='Account Created On'
                description={accountCreatedDate}
              />
              <BillingOverviewItem
                billingLoading={billingLoading}
                title='Last Renewed On'
                description={currentPeriodStart}
                icon={<CallMade />}
              />
              <BillingOverviewItem
                billingLoading={billingLoading}
                title='Next Renewal Date'
                description={currentPeriodEnd}
              />
              {currentCardBrand && currentCardLast4 && (
                <Grid className={classes.currentCardGrid} item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item>
                      <Grid container spacing={1}>
                        <Grid item>
                          <Typography
                            color='textSecondary'
                            variant='subtitle2'
                            component='span'
                          >
                            Payment method:
                          </Typography>
                        </Grid>
                        <Grid item>
                          {currentCardBrand.charAt(0).toUpperCase() +
                            currentCardBrand.slice(1)}
                          <span className={classes.specialBox}>****</span>
                          {currentCardLast4}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </>
          ) : billingLoading ? (
            <div className={classes.loadingIndicator}>
              <CircularProgress size={20} />
            </div>
          ) : (
            <AccountLocked
              isBillingError={isBillingError}
              isEmptyCurrentSubscription={isEmptyCurrentSubscription}
              hasDefaultPaymentMethod={hasDefaultPaymentMethod}
            />
          )}
          <ManageSubscriptionButtons
            isSubscriptionBlocked={
              (isSubscriptionBlocked && !isEmptyCurrentSubscription) ||
              !hasDefaultPaymentMethod
            }
            hasDefaultPaymentMethod={hasDefaultPaymentMethod}
            isBillingError={isBillingError}
            isEmptyCurrentSubscription={isEmptyCurrentSubscription}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles(theme => ({
  loadingIndicator: {
    width: '100%',
    textAlign: 'center',
    marginBottom: 20,
  },
  currentCardGrid: {
    marginTop: 8,
  },
  specialBox: {
    display: 'inline-block',
    fontSize: '1.2rem',
    lineHeight: '1rem',
    padding: '7px 7px 0px 7px',
    verticalAlign: 'middle',
  },
}));

export default BillingSettings;
