import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { Moment } from 'moment';

// hooks
import { useSelector } from 'react-redux';

// api
import { useEventTimeSlotsQuery } from 'api/eventTimeSlots';
import {
  EventGuestQueryParams,
  EventGuestFilters,
  useEventGuestsQuery,
} from 'api/eventGuests';

// material-ui
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Cancel, CheckCircle, HelpOutline } from '@material-ui/icons';

// components
import SelectGuestsTable from './SelectGuestsTable';

// selectors
import getLeadStatuses from 'store/selectors/getLeadStatuses';

// helpers
import { roleNames } from 'helpers/getRoles';
import { getTimeSlotLabel } from 'helpers/getTimeSlotLabel';
import formatStartDate from 'helpers/formatStartDate';
import formatEndDate from 'helpers/formatEndDate';

const INITIAL_QUERY_PARAMS: EventGuestQueryParams = {
  page: 1,
  search: '',
  statusIds: [],
  invited: '',
  rsvp: '',
  attended: '',
  startDateStart: '',
  startDateEnd: '',
  orderBy: 'fullName',
};

export default function SelectGuestsStep({
  eventId,
  guestType,
  allGuestsSelected,
  handleAllGuestsSelectedChange,
  handleSelectedGuestsChange,
  selectedGuestIds,
  handleResetSelectedGuests,
  guestFilters,
  setGuestFilters,
}: {
  eventId: number;
  guestType: 'lead' | 'member';
  allGuestsSelected: boolean;
  handleAllGuestsSelectedChange: (...args: any) => void;
  handleSelectedGuestsChange: (...args: any) => void;
  selectedGuestIds: any;
  handleResetSelectedGuests: (...args: any) => void;
  guestFilters: EventGuestFilters;
  setGuestFilters: React.Dispatch<React.SetStateAction<EventGuestFilters>>;
}) {
  const classes = useStyles();
  const statuses: Status[] = useSelector(getLeadStatuses);
  const initialQueryParams = useMemo<EventGuestQueryParams>(
    () => ({
      ...INITIAL_QUERY_PARAMS,
      type: guestType === 'lead' ? 'pnm' : 'member',
    }),
    [guestType]
  );
  const [queryParams, setQueryParams] = useState<EventGuestQueryParams>({
    ...initialQueryParams,
    ...guestFilters,
  });
  const isSearching = Boolean(queryParams.search);

  const {
    data: { eventGuests = [], total, hasNextPage, hasPreviousPage } = {},
    isLoading: eventGuestsLoading,
  } = useEventGuestsQuery(queryParams);

  // Clear query params when searching
  useEffect(() => {
    setQueryParams({
      ...initialQueryParams,
      search: queryParams.search,
    });
  }, [queryParams.search]); // eslint-disable-line react-hooks/exhaustive-deps

  // When the step button is pressed, it should update between pnm/member
  useEffect(() => {
    setQueryParams({
      ...initialQueryParams,
      ...guestFilters,
    });
  }, [initialQueryParams]); // eslint-disable-line react-hooks/exhaustive-deps

  // If all guests with filters selected, send set filters for params
  useEffect(() => {
    if (allGuestsSelected) {
      const newFilters: any = {};
      if (queryParams.statusIds?.length) {
        newFilters.statusIds = queryParams.statusIds;
      }
      for (const key in queryParams) {
        if (
          [
            'invited',
            'rsvp',
            'attended',
            'startDateStart',
            'startDateEnd',
          ].includes(key)
        ) {
          if (queryParams[key as keyof EventGuestQueryParams]) {
            newFilters[key] = queryParams[key as keyof EventGuestQueryParams];
          }
        }
      }
      setGuestFilters(newFilters);
    }
  }, [allGuestsSelected, queryParams, setGuestFilters]);

  const {
    data: eventTimeSlots = [],
    isLoading: etsLoading,
  } = useEventTimeSlotsQuery(Number(eventId));

  const handlePageChange = useCallback((rows: any[], newPage: number) => {
    // MUI Table pages are zero indexed, so we pass `page + 1` to the API
    setQueryParams(queryParams => ({ ...queryParams, page: newPage + 1 }));
  }, []);

  const handleSearchChange = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
      setQueryParams(queryParams => ({
        ...queryParams,
        search: event.target.value,
      })),
    []
  );

  const getRsvpForGuest = useCallback(
    (eventGuest: EventGuest) => {
      if (eventTimeSlots.length > 1) {
        let ets = eventTimeSlots.find(
          ets => ets.id === eventGuest.eventTimeSlotId
        );
        if (ets) {
          return getTimeSlotLabel(ets);
        }
        return;
      }
      switch (eventGuest.rsvp) {
        case 'yes':
          return <CheckCircle className={classes.greenIcon} />;
        case 'maybe':
          return <HelpOutline className={classes.orangeIcon} />;
        case 'no':
          return <Cancel className={classes.redIcon} />;
      }
      return eventGuest.rsvp;
    },
    [eventTimeSlots, classes.greenIcon, classes.orangeIcon, classes.redIcon]
  );
  const handleSortChange = useCallback(
    (column: string, isDesc: boolean) =>
      setQueryParams(queryParams => ({
        ...queryParams,
        orderBy:
          (isDesc ? '-' : '') +
          (column === 'status'
            ? 'statusPosition'
            : column === 'rsvp' && eventTimeSlots.length > 1
            ? 'startDate'
            : column),
      })),
    [eventTimeSlots]
  );
  const handleStatusChange = useCallback(
    (event: React.ChangeEvent<{ value: unknown }>) =>
      setQueryParams(queryParams => ({
        ...queryParams,
        statusIds: event.target.value as number[],
      })),
    []
  );
  const handleInvitedChange = useCallback(
    (event: React.ChangeEvent<{ value: unknown }>) =>
      setQueryParams(queryParams => ({
        ...queryParams,
        invited: event.target.value as '' | 'true' | 'false',
      })),
    []
  );
  const handleRsvpChange = useCallback(
    (event: React.ChangeEvent<{ value: unknown }>) =>
      setQueryParams(queryParams => ({
        ...queryParams,
        rsvp: event.target.value as '' | 'yes' | 'no' | 'maybe' | 'n/a',
      })),
    []
  );
  const handleEtsDateChange = useCallback(
    (etsDate: null | Moment) =>
      setQueryParams(queryParams => ({
        ...queryParams,
        startDateStart: formatStartDate(etsDate),
        startDateEnd: formatEndDate(etsDate),
      })),
    []
  );
  const handleAttendedChange = useCallback(
    (event: React.ChangeEvent<{ value: unknown }>) =>
      setQueryParams(queryParams => ({
        ...queryParams,
        attended: event.target.value as '' | 'true' | 'false',
      })),
    []
  );

  const guests = useMemo<(EventGuest & { status?: string; role?: string })[]>(
    () =>
      eventGuests.map(eg =>
        guestType === 'lead'
          ? {
              ...eg,
              status: statuses.find(s => s.id === eg.statusId)?.abbreviation,
            }
          : {
              ...eg,
              role: (roleNames as any)[eg.roleId as number],
            }
      ),
    [eventGuests, statuses, guestType]
  );

  const content = useMemo(
    () =>
      guests.map(guest => ({
        ...guest,
        invited: guest.invited ? (
          <CheckCircle className={classes.greenIcon} />
        ) : null,
        rsvp: getRsvpForGuest(guest),
        attended: guest.attended ? (
          <CheckCircle className={classes.greenIcon} />
        ) : null,
        isSelected:
          selectedGuestIds.includes(
            guestType === 'lead' ? guest.pnmId : guest.accountId
          ) || allGuestsSelected,
        isDisabled: allGuestsSelected,
      })),
    [
      guests,
      guestType,
      getRsvpForGuest,
      selectedGuestIds,
      allGuestsSelected,
      classes.greenIcon,
    ]
  );

  const columns = useMemo(() => {
    const columns: {
      label: string;
      key: string;
      disableSort: boolean;
      padding?: string;
    }[] = [];
    if (guestType === 'lead')
      columns.push(
        ...[
          { label: 'Lead', key: 'fullName', disableSort: isSearching },
          {
            label: 'Status',
            key: 'status',
            disableSort: isSearching,
            padding: 'none',
          },
          { label: 'Email', key: 'emailAddress', disableSort: true },
          { label: 'Phone', key: 'phoneNumber', disableSort: true },
        ]
      );
    else
      columns.push(
        ...[
          { label: 'Name', key: 'fullName', disableSort: isSearching },
          { label: 'Email', key: 'emailAddress', disableSort: true },
          { label: 'Role', key: 'role', disableSort: true },
        ]
      );
    columns.push(
      ...[
        {
          label: 'Invited',
          key: 'invited',
          disableSort: isSearching,
          padding: 'none',
        },
        {
          label: 'RSVP',
          key: 'rsvp',
          disableSort: isSearching,
          padding: 'none',
        },
        {
          label: 'Attended',
          key: 'attended',
          disableSort: isSearching,
          padding: 'none',
        },
      ]
    );
    return columns;
  }, [guestType, isSearching]);

  return (
    <Grid
      container
      justifyContent='center'
      spacing={5}
      className={classes.container}
    >
      <Grid item xs={12}>
        <SelectGuestsTable
          id={guestType === 'lead' ? 'selectLeadsTable' : 'selectMembersTable'}
          allSelected={allGuestsSelected}
          hasFilters={Object.keys(guestFilters).length > 0}
          columns={columns}
          guestType={guestType}
          guests={content}
          statuses={statuses}
          eventTimeSlots={eventTimeSlots}
          queryParams={queryParams}
          isSearching={isSearching}
          tableLoading={eventGuestsLoading || etsLoading}
          searchLoading={eventGuestsLoading || etsLoading}
          handleAllGuestsSelectedChange={handleAllGuestsSelectedChange}
          handleSelectedGuestsChange={handleSelectedGuestsChange}
          handleResetSelectedGuests={handleResetSelectedGuests}
          onPageChange={handlePageChange}
          onSearchChange={handleSearchChange}
          onSortChange={handleSortChange}
          handleStatusChange={handleStatusChange}
          handleInvitedChange={handleInvitedChange}
          handleRsvpChange={handleRsvpChange}
          handleEtsDateChange={handleEtsDateChange}
          handleAttendedChange={handleAttendedChange}
          selectedGuestIds={selectedGuestIds}
          TablePaginationProps={{
            backIconButtonProps: {
              disabled: !hasPreviousPage,
            },
            nextIconButtonProps: {
              disabled: !hasNextPage,
            },
            count: total,
          }}
        />
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles(theme => {
  return {
    greenIcon: {
      color: (theme as any).palette.success.light,
    },
    orangeIcon: {
      color: (theme as any).palette.secondary.main,
    },
    redIcon: {
      color: (theme as any).palette.primary.main,
    },
    container: {
      paddingLeft: 32,
      paddingRight: 32,
      [theme.breakpoints.only('xs')]: {
        paddingLeft: 16,
        paddingRight: 16,
      },
    },
  };
});
