import {
  all,
  fork,
  takeEvery,
  call,
  put,
  take,
  select,
} from 'redux-saga/effects';
import { Map } from 'immutable';
import httpSaga from 'store/sagas/httpSaga';
import client from 'sources/api';
import { OIDC_LOGIN, OIDC_LOGIN_REQUEST } from 'store/constants/oidcTypes';
import { setAccessToken, setRefreshToken } from 'helpers/tokenUtils';
import { fetchCurrentUser } from 'store/actions/userActions';
import { CURRENT_USER_FETCH } from 'store/constants/userTypes';
import getCurrentUserGroups from 'store/selectors/getCurrentUserGroups';
import {
  invalidateSession,
  validateSession,
} from 'store/actions/sessionActions';
import history from 'helpers/history';
import { setCurrentGroup } from 'store/actions/groupActions';
import { fetchRequestsForUser } from 'store/actions/requestActions';
import { REQUEST_FETCH_FOR_USER } from 'store/constants/requestTypes';
import getRequests from 'store/selectors/getRequests';
import { doneIndicator } from 'store/actions/httpActions';

const WATCH_TYPE = OIDC_LOGIN_REQUEST;
const TYPE = OIDC_LOGIN;

export function* login({ payload }: any) {
  // @ts-expect-error ts-migrate(2569) FIXME: Type 'Generator<any, any, unknown>' is not an arra... Remove this comment to see the full error message
  const response = yield* httpSaga(
    TYPE,
    call(client.post, '/auth_integrations/login', { ...payload })
  );
  // @ts-expect-error ts-migrate(2525) FIXME: Initializer provides no value for this binding ele... Remove this comment to see the full error message
  const { data: { accessToken, refreshToken } = {} } = response || {};
  if (accessToken && refreshToken) {
    yield all([
      yield setAccessToken(accessToken),
      yield setRefreshToken(refreshToken),
    ]);

    yield put(fetchCurrentUser({}));

    yield take(CURRENT_USER_FETCH + '_DONE');

    const currentUserGroups = yield select(state =>
      getCurrentUserGroups(state)
    );
    const groupId = currentUserGroups.first();
    if (groupId && currentUserGroups.size === 1) {
      yield put(
        fetchCurrentUser({ currentGroupId: groupId, skipImageFetch: true })
      );

      yield take(CURRENT_USER_FETCH + '_DONE');
    }

    const currentUser = yield select(state =>
      state.getIn(['currentUser', 'data'], Map())
    );

    yield put(validateSession());

    if (currentUser.get('isSystemAdmin')) {
      yield history.push('/groups/chapters');
    } else if (
      currentUser.get('isHqAdmin') ||
      currentUser.get('isCouncilAdmin')
    ) {
      yield put(setCurrentGroup({ groupId, skipNavigate: false }));
    } else if (currentUserGroups.size) {
      if (currentUserGroups.size > 1) {
        yield history.push('/groups/chapters');
      } else if (currentUserGroups.size === 1) {
        yield put(setCurrentGroup({ groupId }));
        yield history.push({ pathname: '/home', search: `?group=${groupId}` });
      }
    } else {
      yield put(fetchRequestsForUser());
      yield take(REQUEST_FETCH_FOR_USER + '_DONE');

      const requests = yield select(state => getRequests(state));
      const userHasRequests = requests.size > 0;

      if (userHasRequests) {
        yield history.push('requests/pending');
      } else {
        yield history.push('/select-chapter');
      }
    }
  } else {
    yield put(invalidateSession());
  }

  yield put(doneIndicator(TYPE));
}

export function* watch() {
  yield takeEvery(WATCH_TYPE, login);
}

export default function* root() {
  yield all([fork(watch)]);
}
