import React, { useState, useEffect, useMemo } from 'react';
import { List, Map } from 'immutable';

// material-ui
import { withStyles, WithStyles } from '@material-ui/core/styles';
import SvgIcon from '@material-ui/core/SvgIcon';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MuiCheckbox from '@material-ui/core/Checkbox/Checkbox';
import Grid from '@material-ui/core/Grid';

// components
import TextField from 'components/TextField';
import DazzlingDialog from 'components/DazzlingDialog';

//helpers
import { arrayToStringWithoutEmpty, toArray } from 'helpers/transform';

//icons
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { ReactComponent as BookCheckIcon } from 'assets/images/book-check.svg';

//styles
import leadAttributeWithFieldTypeStyles from 'components/LeadInfo/LeadAttributeWithFieldType/leadAttributeWithFieldType.style';

export interface FieldWithTypeProps
  extends WithStyles<typeof leadAttributeWithFieldTypeStyles> {
  attribute: Map<string, any>;
  saveValue: (newValue?: any) => void;
  setInputValue: any;
  options?: string[];
  value?: string | number | null | (string | number)[] | List<string | number>;
}

const LeadAttributeWithFieldType = ({
  classes,
  attribute,
  saveValue,
  setInputValue,
  value,
}: FieldWithTypeProps) => {
  const isCustomType = attribute.get('type') === 'custom';
  const fieldType = attribute.get('fieldType');
  const options = attribute?.get('options', List())?.toJS() || [];

  const [openDropdown, setOpenDropdown] = useState<boolean>(true);

  const newValue = useMemo<(string | number)[]>(() => {
    if (Array.isArray(value)) {
      return [...value];
    }
    if (List.isList(value)) {
      return toArray(value?.toJS() || []);
    }

    return [];
  }, [value]);

  const getOptionsField = (options: string[]) =>
    options.map((item: string) => ({
      value: item,
      label: item,
    }));

  const optionsField = options ? getOptionsField(options) : [];

  const mdOptions = optionsField.length > 4 ? 4 : 12;

  const handleClearClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const multiSelectOnClick = (event: React.MouseEvent<HTMLElement>) => {
    handleClearClick(event);
  };

  const handleBlur = () => {
    saveValue();
  };

  const handleCloseDropdown = () => {
    setOpenDropdown(false);
    saveValue();
  };

  const handleOpenDropdown = () => {
    setOpenDropdown(true);
  };

  const handleCloseMultiSelectModal = () => {
    saveValue();
  };

  const handleChangeCheckBox = (
    event: React.MouseEvent<HTMLElement>,
    itemVal: string | number
  ) => {
    handleClearClick(event);
    const newValueToSet = !newValue.find(
      (item: string | number) => item === itemVal
    )
      ? [...newValue, itemVal]
      : [...newValue.filter((item: string | number) => item !== itemVal)];
    setInputValue(newValueToSet.length > 0 ? newValueToSet : null);
  };

  const handleChangeField = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value: targetValue, name: targetName } = event?.target || {};
    setInputValue(targetValue);

    if (targetName && targetName === 'selectBox') {
      saveValue(targetValue);
    }
  };

  const handleClickCheckbox = (event: React.MouseEvent<HTMLElement>) => {
    handleClearClick(event);
    setInputValue(!value);
    saveValue(!value);
  };

  const defaultTextField = (
    <TextField
      InputProps={{ classes: { root: classes.textInput } }}
      onBlur={handleBlur}
      onChange={handleChangeField}
      value={value}
      autoFocus
      fullWidth
    />
  );

  const getCouncilField = () => {
    switch (fieldType) {
      case 'multiselect':
        return (
          <>
            <TextField
              value={arrayToStringWithoutEmpty(newValue)}
              onClick={multiSelectOnClick}
              onChange={multiSelectOnClick}
              InputProps={{
                endAdornment: <ArrowDropDownIcon />,
                classes: { root: classes.textInput },
              }}
              autoFocus
              fullWidth
            />
            <DazzlingDialog
              headerProps={{
                icon: (props: any) => (
                  <SvgIcon
                    {...props}
                    className='svgIcon'
                    component={BookCheckIcon}
                    viewBox='0 0 46 52'
                  />
                ),
                title: attribute.get('label') || '',
              }}
              handleClose={handleCloseMultiSelectModal}
              displayActions={false}
              open
            >
              <Grid container className={classes.gridDialogBox}>
                {!!options &&
                  options.map((item: string | number) => (
                    <Grid
                      item
                      xs={12}
                      md={mdOptions}
                      className={classes.gridDialogItem}
                    >
                      <FormControlLabel
                        control={
                          <MuiCheckbox
                            color='primary'
                            checked={!!newValue.find(key => key === item)}
                            onClick={(event: React.MouseEvent<HTMLElement>) => {
                              handleChangeCheckBox(event, item);
                            }}
                          />
                        }
                        label={item}
                      />
                    </Grid>
                  ))}
              </Grid>
            </DazzlingDialog>
          </>
        );

      case 'select':
        return (
          <TextField
            InputProps={{ classes: { root: classes.textInput } }}
            onBlur={handleBlur}
            onChange={handleChangeField}
            onClick={handleClearClick}
            value={value}
            options={optionsField}
            name='selectBox'
            SelectProps={{
              open: openDropdown,
              onOpen: handleOpenDropdown,
              onClose: handleCloseDropdown,
              onClick: handleClearClick,
            }}
            select
            autoFocus
            fullWidth
          />
        );

      case 'boolean':
        return (
          <MuiCheckbox
            onBlur={handleBlur}
            onClick={handleClickCheckbox}
            color='primary'
            autoFocus
            checked={!!value}
          />
        );
    }

    return defaultTextField;
  };

  useEffect(() => {
    setInputValue(attribute.get('value'));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return isCustomType ? getCouncilField() : defaultTextField;
};

export default withStyles(leadAttributeWithFieldTypeStyles)(
  LeadAttributeWithFieldType
);
