import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';

// hooks
import { useSelector } from 'react-redux';
import { useLeadQuery } from 'api/leads';
import { useHistory } from 'react-router-dom';

// MUI components
import {
  Grid,
  Typography,
  IconButton,
  Hidden,
  ButtonBase,
  useMediaQuery,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { Skeleton } from '@material-ui/lab';
import { ArrowBack, ContactMail } from '@material-ui/icons';

// components
import SuperAvatar from 'components/SuperAvatar';

// selectors
import getLoadingFromState from 'store/selectors/getLoadingFromState';

const LEAD_ID_KEY = 'pnmId';

const ThreadHeader = function({ thread, setMessageView }: any) {
  const leadId = thread.getIn(['recipients', 0, LEAD_ID_KEY], NaN);
  const { data: lead, isLoading: leadsLoading } = useLeadQuery(leadId);
  const phoneNumber = thread.getIn(['recipients', 0, 'phoneNumber']);
  const theme = useTheme();
  const history = useHistory();
  const isExtraSmall = useMediaQuery(theme.breakpoints.only('xs'));
  const navigateToProfile = useCallback(() => {
    history.push({ pathname: `/leads/${lead?.id}` });
  }, [history, lead]);
  const { threadsFetching, phoneNumbersLoading } = useSelector(state => ({
    threadsFetching: getLoadingFromState('thread', 'fetch', false)(state),
    phoneNumbersLoading: getLoadingFromState('phoneNumber', null, false)(state),
  }));
  const title = lead ? lead.fullName : phoneNumber;
  return (
    <Grid container spacing={4}>
      {leadsLoading || threadsFetching || phoneNumbersLoading ? (
        <>
          <Grid item xs={2} lg={1}>
            <Skeleton variant='circle' width={60} height={60} />
          </Grid>
          <Grid item xs>
            <Skeleton width={300} />
            <Skeleton width={150} />
          </Grid>
        </>
      ) : (
        <>
          <Hidden smUp>
            <IconButton onClick={() => setMessageView('list')}>
              <ArrowBack />
            </IconButton>
          </Hidden>
          <Grid item xs={2} lg={1}>
            <SuperAvatar
              onClick={navigateToProfile}
              fullName={lead?.fullName}
              size={isExtraSmall ? 'medium' : 'large'}
              imageUrl={lead?.profilePhotoFileUrl}
            >
              {!lead && <ContactMail />}
            </SuperAvatar>
          </Grid>
          <Grid item xs>
            <ButtonBase onClick={navigateToProfile}>
              <Typography variant='h6'>{title}</Typography>
            </ButtonBase>
            {Boolean(title) && (
              <Typography color='textSecondary' variant='body2'>
                You and {title}
              </Typography>
            )}
          </Grid>
        </>
      )}
    </Grid>
  );
};
ThreadHeader.propTypes = {
  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'typeof Map' is not assignable to... Remove this comment to see the full error message
  thread: PropTypes.instanceOf(Map).isRequired,
  setMessageView: PropTypes.func.isRequired,
};
ThreadHeader.defaultProps = {
  thread: Map(),
};
export default ThreadHeader;
