import React, { useState, useCallback } from 'react';
import moment from 'moment';

import { roles } from 'helpers/getRoles';
// material-ui
import {
  Box,
  ButtonGroup,
  Grid,
  Hidden,
  Typography,
  Tooltip,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from 'assets/types';
// icons
import {
  MoreVert as MoreVertIcon,
  Settings,
  Today as DateIcon,
  LocationOn as LocationIcon,
  AccountCircle as ContactIcon,
} from '@material-ui/icons';
// components
import Button from 'components/Button';
import IconButton from 'components/IconButton';
import DateIndicator from 'components/DateIndicator';
import OutlinedCard from 'components/OutlinedCard';
import EventOptionsMenu from './EventOptionsMenu';
// hooks
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useDateTime } from 'helpers/hooks/useDateTime';

export default function EventItem({
  displayTopLine,
  event,
  isLastChild,
  showDate,
}: {
  displayTopLine: boolean;
  event: GroupEvent;
  isLastChild: boolean;
  showDate: boolean;
}) {
  const classes = useStyles();
  const history = useHistory();

  const [optionsAnchorEl, setOptionsAnchorEl] = useState(null);
  const { date: startDate, time: startTime } = useDateTime(event.startDate);
  const { date: endDate, time: endTime } = useDateTime(event.endDate);

  const { canAccessEventOptions } = useSelector(state => ({
    canAccessEventOptions:
      [roles.admin, roles.leader].includes(
        (state as any).getIn(['currentUser', 'data', 'roleId'], roles.member)
      ) || (state as any).getIn(['currentUser', 'data', 'isSystemAdmin']),
  }));

  const navigateToManageEvent = useCallback(() => {
    const path = `/events/${event.id}`;
    history.push(path);
  }, [event.id, history]);

  const hasDateDiff = startDate !== endDate;
  const dateLabel = `${startDate}, ${startTime} to ${
    hasDateDiff ? `${endDate}, ${endTime}` : endTime
  }`;
  const contactLabel = `${event.pointOfContact?.account.firstName} ${event.pointOfContact?.account.lastName}`;
  const currentDate = moment();
  const isEventFinished =
    event.endDate < currentDate.format('YYYY-MM-DTHH:mm:ss');

  return (
    <Grid item xs={12} sm={10} lg={8} xl={7}>
      <Grid container>
        <Hidden xsDown>
          <Grid item>
            <Box position='relative' width={100} height='100%'>
              <Box position='absolute' top={28}>
                <DateIndicator {...{ date: startDate, showDate }} />
              </Box>
              <Box
                className={isEventFinished ? classes.dot : classes.activeDot}
              />
              <>
                {displayTopLine && (
                  <Box id='topLine' className={classes.topLine} />
                )}
                {!isLastChild && (
                  <Box id='bottomLine' className={classes.bottomLine} />
                )}
              </>
            </Box>
          </Grid>
        </Hidden>

        <Grid item xs>
          <OutlinedCard
            borderColorVariant={isEventFinished ? 'light' : 'white'}
            borderRadiusVariant={8}
          >
            <Grid container>
              <Grid item xs={12} sm={8} lg={9}>
                <Typography
                  variant='body1'
                  className={isEventFinished ? classes.grayText : classes.bold}
                >
                  {event.title}
                </Typography>

                <DetailItem
                  label={dateLabel}
                  icon={<DateIcon className={classes.grayIcon} />}
                  tooltipTitle='Event Date'
                />
                <DetailItem
                  label={event.location}
                  icon={<LocationIcon className={classes.grayIcon} />}
                  tooltipTitle='Event Location'
                />
                {Boolean(event.pointOfContact) && (
                  <DetailItem
                    label={contactLabel}
                    icon={<ContactIcon className={classes.grayIcon} />}
                    tooltipTitle='Point of contact'
                  />
                )}
              </Grid>

              <Grid item xs={12} sm={4} lg={3}>
                <Grid
                  container
                  alignItems='center'
                  className={classes.actionsContainer}
                >
                  <Grid item xs>
                    <ButtonGroup>
                      <Button
                        id='manageButton'
                        endIcon={<Settings />}
                        onClick={navigateToManageEvent}
                      >
                        Manage
                      </Button>
                    </ButtonGroup>
                  </Grid>

                  {canAccessEventOptions && (
                    <Grid item xs>
                      <IconButton
                        id='optionsButton'
                        onClick={(e: any) =>
                          setOptionsAnchorEl(e.currentTarget)
                        }
                      >
                        <MoreVertIcon />
                      </IconButton>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </OutlinedCard>
        </Grid>

        {canAccessEventOptions && (
          <EventOptionsMenu
            event={event}
            anchorEl={optionsAnchorEl}
            onClose={() => setOptionsAnchorEl(null)}
          />
        )}
      </Grid>
    </Grid>
  );
}

const DetailItem = ({
  label,
  icon,
  tooltipTitle,
}: {
  label: string;
  icon: any;
  tooltipTitle: string;
}) => (
  <Tooltip title={tooltipTitle} placement='bottom-start' arrow>
    <Grid container spacing={1}>
      <Grid item>{icon}</Grid>
      <Grid item>
        <Typography variant='body2' color='textSecondary'>
          {label}
        </Typography>
      </Grid>
    </Grid>
  </Tooltip>
);

const useStyles = makeStyles((theme: Theme) => {
  return {
    italic: {
      fontStyle: 'italic',
    },
    bold: {
      fontWeight: 600,
      marginBottom: 6,
    },
    actionsContainer: {
      textAlign: 'right',
    },
    grayIcon: {
      color: theme.palette.text.secondary,
    },
    grayText: {
      color: theme.palette.text.secondary,
      marginBottom: 6,
    },
    topLine: {
      position: 'absolute',
      right: 24,
      top: -36,
      width: 4,
      height: 64,
      backgroundColor: theme.palette.background.lightPaper,
    },
    bottomLine: {
      position: 'absolute',
      right: 24,
      top: 40,
      width: 4,
      height: '100%',
      backgroundColor: theme.palette.background.lightPaper,
    },
    dot: {
      top: 28,
      right: 17,
      position: 'absolute',
      padding: 9,
      borderRadius: '50%',
      backgroundColor: theme.palette.background.lightPaper,
      [theme.breakpoints.down('xs')]: {
        zIndex: 0,
      },
    },
    activeDot: {
      top: 28,
      right: 17,
      position: 'absolute',
      padding: 9,
      opacity: 0.9,
      boxShadow: `0px 0px 12px 0.5px ${theme.palette.common.white}`,
      borderRadius: '50%',
      backgroundColor: theme.palette.background.lightPaper,
    },
  };
});
