import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { isEqual } from 'lodash';
import pluralize from 'pluralize';
import { List } from 'immutable';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe, Stripe } from '@stripe/stripe-js';

// material-ui
import {
  CircularProgress,
  Grid,
  Card,
  CardContent,
  Box,
  Typography,
  Hidden,
  Button,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PhoneIcon from '@material-ui/icons/PermPhoneMsg';
import Add from '@material-ui/icons/Add';

// components
import Header from 'components/Header';
import PhoneNumberItem from './PhoneNumberItem';
import CreatePhoneNumberForm from './CreatePhoneNumberForm';
import AddOnModalTextsOrMinutes from './AddOnModalTextsOrMinutes';
import AddOnModalPhoneLines from './AddOnModalPhoneLines';
import DeletePhoneNumberModal from 'components/DeletePhoneNumberModal';
// import AddOnModalPhoneLines from './AddOnModalPhoneLines';

// selectors
import getForm from 'store/selectors/getForm';
import getCurrentGroupId from 'store/selectors/getCurrentGroupId';
import getSliceState from 'store/selectors/getSliceState';

// actions
import {
  createPhoneNumber as createPhoneNumberAction,
  fetchPhoneNumbersForGroup as fetchPhoneNumbersForGroupAction,
} from 'store/actions/phoneNumberActions';
import { fetchMembersForGroup as fetchMembersForGroupAction } from 'store/actions/memberActions';
import { fetchGroupFeatureUsage as fetchGroupFeatureUsageAction } from 'store/actions/groupFeatureActions';

// hooks
import { useDispatch, useSelector } from 'react-redux';

// selectors
import getCurrentUser from 'store/selectors/getCurrentUser';
import getSliceEntityById from 'store/selectors/getSliceEntityById';
import getIsCurrentGroupCouncil from 'store/selectors/getIsCurrentGroupCouncil';

const CREATE_FORM = 'createPhoneNumberForm';

export default function PhoneSettings() {
  const {
    actions: {
      fetchPhoneNumbersForGroup,
      fetchMembersForGroup,
      fetchGroupFeatureUsage,
    },
    state: {
      currentGroupId,
      phoneNumberState,
      groupFeatureUsageFeatures,
      isCurrentGroupCouncil,
    },
  } = useRedux();

  const classes = useStyles();
  const [opened, setOpened] = useState(false);
  const [
    stripePromise,
    setStripePromise,
  ] = useState<Promise<Stripe | null> | null>(null);
  const { stripeApiKey } = useSelector(
    getSliceEntityById('billing', currentGroupId)
  );

  const [handleTextOrMessages, setHandleTextOrMessages] = useState(false);
  const [handlePhoneLines, setHandlePhoneLines] = useState(false);
  const [currentNumberId, setCurrentNumberId] = useState<number | null>(null);

  const textsUsage = groupFeatureUsageFeatures?.find(
    (feature: any) => feature.name === 'texts'
  );

  const textsRemaining = textsUsage?.totalUnitsRemaining || 0;
  const textsLimit = textsUsage?.planLimit || 0;

  const minutesUsage = groupFeatureUsageFeatures?.find(
    (feature: any) => feature.name === 'voice_minutes'
  );

  const minutesRemaining = minutesUsage?.totalUnitsRemaining || 0;
  const minutesLimit = minutesUsage?.planLimit || 0;

  const phonesUsage = groupFeatureUsageFeatures?.find(
    (feature: any) => feature.name === 'phone_numbers'
  );

  const phoneNumbersRemaining = phonesUsage?.totalUnitsRemaining || 0;
  const phoneNumbers = phoneNumberState.data;
  const title = 'Manage Phone Lines';
  const subtitle = !phoneNumbersRemaining
    ? 'Manage phone lines for mass texting and calling'
    : 'Create and manage phone lines for mass texting and calling';

  const highlightedSubtitle = !phoneNumbersRemaining
    ? `You've reached your phone number limit, but can still manage your existing numbers`
    : `${phoneNumbersRemaining} new phone ${pluralize(
        'number',
        phoneNumbersRemaining
      )} remaining`;

  const handleClose = useCallback(() => setCurrentNumberId(null), []);

  useEffect(() => {
    fetchPhoneNumbersForGroup();
    fetchMembersForGroup({
      groupId: currentGroupId,
      appendResult: true,
    });
    fetchGroupFeatureUsage();
  }, []); // eslint-disable-line
  useEffect(() => {
    if (stripeApiKey) setStripePromise(loadStripe(stripeApiKey));
  }, [stripeApiKey]);

  const chooseAdd = (fn: any) => {
    setHandleTextOrMessages(false);
    setHandlePhoneLines(false);
    fn(true);
    setOpened(true);
  };

  const handleDelete = useCallback((phoneNumberId: number) => {
    setCurrentNumberId(phoneNumberId);
  }, []);

  return (
    <>
      <Grid item xs>
        <Card className={classes.topCard}>
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Header icon={PhoneIcon} title={title} subtitle={subtitle} />
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Hidden xsDown>
                <Grid item>
                  <Box width={75}></Box>
                </Grid>
              </Hidden>

              <Grid item xs>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} lg={!isCurrentGroupCouncil ? 4 : 6}>
                    <AddOn
                      title='Phone Lines Remaining'
                      usage={`${phoneNumbersRemaining} phone ${pluralize(
                        'line',
                        phoneNumbersRemaining
                      )}`}
                      buttonLabel='Add Phone Lines'
                      onAdd={() => chooseAdd(setHandlePhoneLines)}
                    />
                  </Grid>
                  {!isCurrentGroupCouncil && (
                    <Grid item xs={12} sm={6} lg={4}>
                      <AddOn
                        title='Minutes Remaining'
                        usage={`${minutesRemaining} of ${minutesLimit} minutes`}
                        buttonLabel='Add Minutes'
                        onAdd={() => chooseAdd(setHandleTextOrMessages)}
                      />
                    </Grid>
                  )}
                  <Grid item xs={12} sm={6} lg={!isCurrentGroupCouncil ? 4 : 6}>
                    <AddOn
                      title='Text Messages Remaining'
                      usage={`${textsRemaining} of ${textsLimit} text messages`}
                      buttonLabel='Add Texts'
                      onAdd={() => chooseAdd(setHandleTextOrMessages)}
                    />
                  </Grid>
                  <Grid
                    className={classes.phoneNumbersHeaderContainer}
                    item
                    xs={12}
                  >
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Box marginBottom={'-15px'}>
                          <Header
                            title='Phone Numbers'
                            highlightedSubtitle={highlightedSubtitle}
                          />
                        </Box>
                      </Grid>

                      {/* Add back when call forwarding is done */}
                      {/* <UserPhoneNumberForm /> */}

                      {Boolean(phoneNumbersRemaining) && (
                        <CreatePhoneNumberForm />
                      )}

                      {phoneNumberState.loading ? (
                        <Grid item xs={12} className={classes.loadingIndicator}>
                          <CircularProgress />
                        </Grid>
                      ) : (
                        phoneNumbers?.map((phoneNumber: any) => (
                          <PhoneNumberItem
                            key={phoneNumber.id}
                            onDelete={handleDelete}
                            {...phoneNumber}
                          />
                        ))
                      )}
                      <DeletePhoneNumberModal
                        open={currentNumberId !== null}
                        onClose={handleClose}
                        phoneNumberId={currentNumberId}
                        isCurrentGroupCouncil={isCurrentGroupCouncil}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      {handleTextOrMessages ? (
        <Elements stripe={stripePromise}>
          <AddOnModalTextsOrMinutes
            opened={opened}
            handleClose={() => setOpened(false)}
          />
        </Elements>
      ) : null}
      {handlePhoneLines ? (
        <Elements stripe={stripePromise}>
          <AddOnModalPhoneLines
            opened={opened}
            handleClose={() => setOpened(false)}
          />
        </Elements>
      ) : null}
    </>
  );
}

interface AddOnProps {
  title: string;
  usage: string;
  buttonLabel: string;
  onAdd: () => void;
}

const AddOn = ({ title, usage, buttonLabel, onAdd }: AddOnProps) => (
  <Box>
    <Box
      border={1}
      borderRadius={6}
      borderColor='text.secondary'
      clone
      padding={2}
    >
      <Grid item container alignItems='center' justifyContent='space-between'>
        <Grid item>
          <Typography color='textSecondary'>{title}</Typography>
          <Typography color='textPrimary'>{usage}</Typography>
        </Grid>
        <Grid item>
          <Button startIcon={<Add />} variant='outlined' onClick={onAdd}>
            {buttonLabel}
          </Button>
        </Grid>
      </Grid>
    </Box>
  </Box>
);

const useStyles = makeStyles({
  topCard: {
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    minHeight: 525,
    height: 'auto',
    paddingBottom: 175,
  },
  loadingIndicator: {
    textAlign: 'center',
  },
  phoneNumbersHeaderContainer: {
    marginTop: 32,
  },
});

const useRedux = () => {
  const dispatch = useDispatch();
  const actions = useMemo(
    () => ({
      createPhoneNumber: (payload: any) =>
        dispatch(createPhoneNumberAction(payload)),
      fetchPhoneNumbersForGroup: () =>
        dispatch(fetchPhoneNumbersForGroupAction()),
      fetchMembersForGroup: (payload: any) =>
        dispatch(fetchMembersForGroupAction(payload)),
      fetchGroupFeatureUsage: () => dispatch(fetchGroupFeatureUsageAction()),
    }),
    [dispatch]
  );
  const state = useSelector(
    state => ({
      form: getForm(CREATE_FORM)(state),
      currentGroupId: getCurrentGroupId(state),
      phoneNumberState: getSliceState('phoneNumber', 'fetch', false)(state),
      // @ts-expect-error ts-migrate(2554) FIXME: Expected 2-3 arguments, but got 1.
      // prettier-ignore
      groupFeatureUsageFeatures: state.getIn(['groupFeatureUsage', 'data', 'features'], List()).toJS(),
      isCurrentGroupCouncil: getIsCurrentGroupCouncil(state),
      currentUser: getCurrentUser(state),
    }),
    isEqual
  );
  return { actions, state };
};
