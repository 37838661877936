import React, {
  useCallback,
  useState,
  useMemo,
  useEffect,
  useContext,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { List, Map } from 'immutable';
import { isEqual } from 'lodash';

// api
import { useEventTimeSlotsQuery } from 'api/eventTimeSlots';

// material-ui
import { withStyles, WithStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import FormHelperText from '@material-ui/core/FormHelperText';
import Skeleton from '@material-ui/lab/Skeleton';
import Typography from '@material-ui/core/Typography';
import Fade from '@material-ui/core/Fade';

// icons
import Lock from '@material-ui/icons/Lock';
import ArrowBack from '@material-ui/icons/ArrowBack';

// components
import { Form } from 'react-final-form';
import Button from 'components/Button';
import Toast from 'components/Toast';
import ReferrerFields from 'routes/ReferralForm/ReferrerFields';
import BasicFields from 'routes/ReferralForm/BasicFields';
import EventFields from 'routes/ReferralForm/EventFields';
import AgreeToTermsField from 'routes/ReferralForm/AgreeToTermsField';
import FormStripeView from 'routes/ReferralForm/FormStripeView';
import FormPage from 'routes/ReferralForm/FormView/components/FormPage';
import FormSuccessPage from 'routes/ReferralForm/FormView/components/FormSuccessPage';
import FieldWithType from 'routes/ReferralForm/FormView/components/FieldWithType';
import ChapterSelectionField from 'components/ChapterSelectionField';
import GenderSelectionField from 'components/GenderSelectionField';

// selectors
import getLoadingFromState from 'store/selectors/getLoadingFromState';
import getForm from 'store/selectors/getForm';
import getSliceItem from 'store/selectors/getSliceItem';

// action creators
import { submitReferralForm } from 'store/actions/referralFormActions';

// helpers
import validate from 'helpers/validate';
import { isEmpty } from 'helpers/check';
import { numberToCurrency, toInt10 } from 'helpers/transform';
import { StripePaymentStatus } from 'routes/ReferralForm/FormView/helpers/interfaces';
import { SiteVisualDataContext } from 'components/SiteVisualData';

// styles
import formViewStyles from 'routes/ReferralForm/FormView/formView.style';

export interface FormViewProps extends WithStyles<typeof formViewStyles> {
  groupId: number | string;
  formData: any;
  formFields: List<TypedMap<MappedFormField>>;
  canViewForm: boolean;
  hasActivePaymentMethod: boolean;
  token?: string | null;
  isCouncilForm?: boolean | null;
  isOrganizationForm?: boolean | null;
  isUniversalForm?: boolean;
  formFeeValue?: FormFee;
  accountNumber?: string | null;
  stripeApiKey?: string;
}

const SUBMIT_FORM = 'viewReferralForm';
const FILE_FORM = 'createLeadFileForm';

const CHAPTER_SUB_TEXT = `Please select the chapters you are interested in and want your
  information shared with. If you do not select any groups, your
  information will be shared with all chapters.`;

const FormView = function({
  classes,
  groupId,
  formData,
  formFields,
  token,
  canViewForm,
  isCouncilForm,
  isOrganizationForm,
  isUniversalForm,
  hasActivePaymentMethod,
  formFeeValue,
  accountNumber,
  stripeApiKey,
}: FormViewProps) {
  const dispatch = useDispatch();
  const params = useParams();

  const [formPageNumber, setFormPageNumber] = useState(0);
  const [stripeCardElement, setStripeCardElement] = useState({});
  const [stripePaymentStatus, setStripePaymentStatus] = useState<
    StripePaymentStatus
  >('INIT');
  const [wasFormSentSuccess, setWasFormSentSuccess] = useState(false);

  const customAgreement1Required = formData.get(
    'customAgreement1Required',
    false
  );
  const customAgreement2Required = formData.get(
    'customAgreement2Required',
    false
  );
  const enableCustomAgreement1 = formData.get('enableCustomAgreement1', false);
  const enableCustomAgreement2 = formData.get('enableCustomAgreement2', false);
  const customAgreement1Content = formData.get('customAgreement1Content', '');
  const customAgreement2Content = formData.get('customAgreement2Content', '');
  const collectPayment = formData.get('collectPayment', false);
  const emailRequired = formData.get('emailRequired', false);
  const chapterSelectionEnabled = formData.get(
    'chapterSelectionEnabled',
    false
  );
  const genderSelectionEnabled = formData.get('genderSelectionEnabled', false);
  const phoneNumberRequired = formData.get('phoneNumberRequired', false);
  const { amount: collectPaymentAmount } = formFeeValue || {};
  const { formHeaderDefaultLogo } = useContext(SiteVisualDataContext);

  // schemas
  const schema = useMemo(
    () => ({
      emailAddress: {
        email: { message: 'not valid' },
        length: { maximum: 255 },
        presence: emailRequired,
      },
      firstName: {
        presence: true,
        length: { maximum: 255 },
      },
      lastName: {
        presence: true,
        length: { maximum: 255 },
      },
      phoneNumber: {
        validPhoneNumber: true,
        presence: phoneNumberRequired,
      },
      referrerFirstName: { presence: false },
      referrerLastName: { presence: false },
      referrerEmailAddress: { presence: false },
      ...((isCouncilForm || isOrganizationForm || isUniversalForm) &&
      customAgreement1Required &&
      enableCustomAgreement1
        ? {
            customAgreement1: {
              presence: true,
              type: 'boolean',
              inclusion: {
                within: [true],
              },
            },
          }
        : {}),
      ...((isCouncilForm || isOrganizationForm || isUniversalForm) &&
      customAgreement2Required &&
      enableCustomAgreement2
        ? {
            customAgreement2: {
              presence: true,
              type: 'boolean',
              inclusion: {
                within: [true],
              },
            },
          }
        : {}),
      ...(isCouncilForm && collectPayment && formPageNumber === 1
        ? {
            stripeFormComplete: {
              presence: true,
              type: 'boolean',
              inclusion: {
                within: [true],
              },
            },
            emailAddressStripe: {
              presence: true,
            },
            nameOnCard: {
              presence: true,
            },
          }
        : {}),
    }),
    [
      isCouncilForm,
      isOrganizationForm,
      isUniversalForm,
      collectPayment,
      formPageNumber,
      customAgreement1Required,
      enableCustomAgreement1,
      customAgreement2Required,
      enableCustomAgreement2,
      emailRequired,
      phoneNumberRequired,
    ]
  );

  // @ts-expect-error ts-migrate(2339) FIXME: Property 'formId' does not exist on type '{}'.
  const { formId } = params;
  let resetForm: any;
  let resetFormFieldState: any;
  let getFormRegisteredFields: any;
  let currentValues: any;
  let formMutators: any;

  const isEventForm = Boolean(formData.get('eventId', null));

  const [referringSomeoneChecked, setReferringSomeoneChecked] = useState(false);
  const {
    data: availableTimeSlots = [],
    refetch,
    isFetched: isETSFetched,
  } = useEventTimeSlotsQuery(
    formData.get('eventId', ''),
    formData.get('groupId'),
    true
  );

  const {
    fileLoading,
    formFieldLoading,
    referralFormLoading,
    currentUserLoading,
    submitForm = {},
    fileForm = {},
    paymentIntentClientSecret,
    paymentRequiresAction,
  }: any = useSelector(state => {
    return {
      fileLoading: getLoadingFromState('file', false, false)(state),
      currentUserLoading: getLoadingFromState(
        'currentUser',
        false,
        false
      )(state),
      formFieldLoading: getLoadingFromState('formField')(state),
      referralFormLoading: getLoadingFromState('referralForm')(state),
      submitForm: getForm(SUBMIT_FORM)(state),
      fileForm: getForm(FILE_FORM)(state),
      paymentIntentClientSecret: getSliceItem(
        'referralForm',
        'paymentIntentClientSecret'
      )(state),
      paymentRequiresAction: getSliceItem(
        'referralForm',
        'requiresSourceAction'
      )(state),
    };
  }, isEqual);

  useEffect(() => {
    if (submitForm?.hasSubmitSucceeded && isEventForm) refetch();
  }, [submitForm, refetch, isEventForm]);

  const headerLoading =
    (referralFormLoading || fileLoading) && !fileForm.isSubmitting;
  const headerPhotoFileData = formData.get('headerPhotoFileId', Map());
  const loading = useMemo(
    () =>
      referralFormLoading ||
      fileLoading ||
      formFieldLoading ||
      currentUserLoading,
    [currentUserLoading, fileLoading, formFieldLoading, referralFormLoading]
  );

  const image = headerPhotoFileData
    ? headerPhotoFileData.get('url')
    : formHeaderDefaultLogo;

  const handleResetForm = () => {
    if (
      isCouncilForm &&
      collectPayment &&
      stripePaymentStatus === 'SET_PM' &&
      paymentRequiresAction &&
      paymentIntentClientSecret
    ) {
      setStripePaymentStatus('GET_PICS');
      return;
    }

    resetForm();
    const currentValues = getFormRegisteredFields();
    // The `touched` state of a field does not reset on submit,
    //  so we must do this manually for any field that uses `presence` for validation
    (currentValues || []).forEach((item: string) => {
      resetFormFieldState(item);
    });

    if (collectPayment && (stripeCardElement as any)?.clear) {
      (stripeCardElement as any)?.clear();
    }

    if (
      !wasFormSentSuccess &&
      (isCouncilForm || isOrganizationForm || isUniversalForm)
    ) {
      setWasFormSentSuccess(true);
    }
  };

  const replaceCampusValue = (values: any): { Campus?: number } => {
    const { Campus: campusVal } = values || {};

    return isUniversalForm && campusVal
      ? { Campus: toInt10(campusVal?.value || campusVal) }
      : {};
  };

  const replaceChapterValue = (
    values: any
  ): { 'Refer To Chapter'?: number } => {
    const referToChapter = values?.['Refer To Chapter'];

    return isOrganizationForm && referToChapter
      ? {
          'Refer To Chapter': toInt10(referToChapter?.value || referToChapter),
        }
      : {};
  };

  const dispatchSubmitForm = (values: any) => {
    const valuesWithDots: { [key: string]: string } = {};
    for (const key in values) {
      valuesWithDots[key.replaceAll('_', '.')] = values[key];
    }
    dispatch(
      submitReferralForm({
        formId,
        formName: SUBMIT_FORM,
        token,
        ...(valuesWithDots || {}),
        ...replaceCampusValue(valuesWithDots),
        ...replaceChapterValue(valuesWithDots),
      })
    );
  };

  const handleCheckboxChange = useCallback(() => {
    setReferringSomeoneChecked(!referringSomeoneChecked);
  }, [referringSomeoneChecked]);

  const handleSchemaChanges = (values: any) => {
    if (referringSomeoneChecked) {
      schema.referrerFirstName = { presence: true };
      schema.referrerLastName = { presence: true };
      schema.referrerEmailAddress = { presence: true };
    } else {
      schema.referrerFirstName = { presence: false };
      schema.referrerLastName = { presence: false };
      schema.referrerEmailAddress = { presence: false };
    }
  };

  const handleClickBack = () => {
    if (collectPayment && formMutators) {
      const { stripeFormComplete } = currentValues || {};
      if (stripeFormComplete) {
        formMutators.setValue('stripeFormComplete', false);
      }
    }
    setFormPageNumber(formPageNumber ? formPageNumber - 1 : 0);
  };

  const formatError = () => {
    const message = submitForm.formError.get('message', '');
    if (!isEmpty(message) && message.indexOf(`['`) !== -1) {
      const startIndex = message.indexOf(`['`) + 2;
      const endIndex = message.indexOf(`']`);
      return message.slice(startIndex, endIndex);
    }
    return message;
  };

  const renderFormFields = useCallback(
    (values: any) => {
      if (!formFieldLoading) {
        return formFields.map(formField => {
          const required = formField.get('required');
          // Get label and replace dots with underscore for
          // Field `name` to avoid nested input values
          const label = formField.get('label');
          const name = label.replaceAll('.', '_');

          const id = formField.get('id');
          const fieldType = formField.get('fieldType');
          const description = formField.get('description');
          const options = formField.get('options');
          const isImmutable = formField.get('isImmutable');

          if (required) {
            (schema as any)[name] = {
              presence: true,
              ...(fieldType === 'boolean'
                ? {
                    type: 'boolean',
                    inclusion: {
                      within: [true],
                    },
                  }
                : {}),
            };
          }

          return (
            <FieldWithType
              key={id}
              id={id}
              name={name}
              required={required}
              groupId={toInt10(groupId)}
              fieldType={fieldType}
              label={label}
              isCouncilForm={isCouncilForm}
              isOrganizationForm={isOrganizationForm}
              isUniversalForm={isUniversalForm}
              mutators={formMutators}
              value={values[name]}
              description={description}
              isImmutable={isImmutable}
              token={token}
              {...(options ? { options: options?.toJS() || [] } : {})}
            />
          );
        });
      } else {
        return new Array(2).fill(true).map((_, index) => (
          <Grid item xs={12} key={`skeleton_${index}`}>
            <Skeleton id='formFieldSkeleton' variant='rect' height={56} />
          </Grid>
        ));
      }
    },
    [
      formFieldLoading,
      formFields,
      schema,
      formMutators,
      isCouncilForm,
      isOrganizationForm,
      isUniversalForm,
      groupId,
      token,
    ]
  );

  const handleValidate = (values: any) => {
    // adds and removes referrer fields from schema
    handleSchemaChanges(values);
    return validate(values, schema);
  };

  const handleClickNext = () => {
    if (collectPayment && formMutators) {
      const { emailAddress = '', emailAddressStripe = '' } =
        currentValues || {};
      if (!isEmpty(emailAddress) && isEmpty(emailAddressStripe)) {
        formMutators.setValue('emailAddressStripe', emailAddress);
      }
    }
    setFormPageNumber(formPageNumber + 1);
  };

  const submitStripeFormButton = (
    handleSubmit: (...args: any[]) => any,
    pristine: any,
    invalid: any,
    title: string = 'Submit'
  ) => (
    <Button
      type='primary'
      form='cardStripeForm'
      variant='contained'
      color='primary'
      // onClick={handleSubmit}
      disabled={pristine || invalid || submitForm.isSubmitting || fileLoading}
      loading={submitForm.isSubmitting}
      success={submitForm.hasSubmitSucceeded}
      fail={submitForm.hasSubmitFailed}
    >
      {title}
    </Button>
  );

  const submitButton = (
    handleSubmit: (...args: any[]) => any,
    pristine: any,
    invalid: any,
    title: string = 'Submit'
  ) => (
    <Button
      type='submit'
      variant='contained'
      color='primary'
      onClick={handleSubmit}
      disabled={pristine || invalid || submitForm.isSubmitting || fileLoading}
      loading={submitForm.isSubmitting}
      success={submitForm.hasSubmitSucceeded}
      fail={submitForm.hasSubmitFailed}
    >
      {title}
    </Button>
  );

  const nextButton = (pristine: any, invalid: any) => (
    <Button
      variant='contained'
      color='primary'
      onClick={handleClickNext}
      disabled={pristine || invalid || submitForm.isSubmitting || fileLoading}
      loading={submitForm.isSubmitting}
      isNextButton
    >
      Next
    </Button>
  );

  const getSubmitButton = (
    handleSubmit: (...args: any[]) => any,
    pristine: any,
    invalid: any
  ) => {
    if (isCouncilForm && collectPayment && collectPaymentAmount) {
      if (formPageNumber === 1) {
        return submitStripeFormButton(
          handleSubmit,
          pristine,
          invalid,
          `Pay $${numberToCurrency(collectPaymentAmount)}`
        );
      }

      return nextButton(pristine, invalid);
    }

    return submitButton(handleSubmit, pristine, invalid);
  };

  const getButtonBack = () =>
    formPageNumber > 0 ? (
      <Button
        className={classes.buttonBack}
        variant='contained'
        onClick={handleClickBack}
      >
        <ArrowBack className='arrowBack' />
        Back
      </Button>
    ) : null;

  const collectPaymentError =
    isCouncilForm &&
    collectPayment &&
    (!collectPaymentAmount || !hasActivePaymentMethod);

  return (
    <Grid
      item
      xs={12}
      sm={10}
      md={8}
      lg={7}
      xl={6}
      className={classes.formCard}
    >
      {(canViewForm || loading) && !collectPaymentError ? (
        isEventForm && isETSFetched && !availableTimeSlots.length ? (
          <Card id='notViewable' className={classes.notViewableCard}>
            <Grid container>
              <Grid item xs={1}>
                <Icon>
                  <Lock />
                </Icon>
              </Grid>
              <Grid item xs={11}>
                <Typography>
                  There are no time slots available for this event
                </Typography>

                <Typography color='textSecondary' variant='subtitle2'>
                  For more information contact the event point of contact.
                </Typography>
              </Grid>
            </Grid>
          </Card>
        ) : (
          <Card id='formCard'>
            <Grid container justifyContent='center'>
              <Grid item xs={12} className={classes.headerImageContainer}>
                {headerLoading ? (
                  <Skeleton
                    id='headerImageSkeleton'
                    variant='rect'
                    height={110}
                    width={330}
                    className={classes.headerImageSkeleton}
                  />
                ) : (
                  <Fade in={!headerLoading}>
                    <img
                      id='headerImage'
                      src={image}
                      className={classes.headerImage}
                      alt='Form Header'
                    />
                  </Fade>
                )}
              </Grid>

              <Grid item xs={12} className={classes.title}>
                {referralFormLoading ? (
                  <Skeleton
                    id='titleSkeleton'
                    variant='text'
                    height={38}
                    width={300}
                  />
                ) : (
                  <Grid>
                    <Typography
                      variant='h6'
                      id='title'
                      gutterBottom={formData.get('description')}
                    >
                      {formData.get('title')}
                    </Typography>

                    {formData.get('description') && (
                      <Typography
                        variant='subtitle2'
                        color='textSecondary'
                        id='description'
                      >
                        {formData.get('description')}
                      </Typography>
                    )}
                  </Grid>
                )}
              </Grid>

              {wasFormSentSuccess &&
              (isCouncilForm || isOrganizationForm || isUniversalForm) ? (
                <Grid item xs={12} className={classes.formFieldsContent}>
                  <FormSuccessPage
                    isOrganizationForm={isOrganizationForm}
                    isUniversalForm={isUniversalForm}
                  />
                </Grid>
              ) : (
                <Form
                  validate={handleValidate}
                  onSubmit={dispatchSubmitForm}
                  keepDirtyOnReinitialize={submitForm.isSubmitting}
                  mutators={{
                    setValue: ([field, value], state, { changeValue }) => {
                      changeValue(state, field, () => value);
                    },
                  }}
                  render={({
                    handleSubmit,
                    invalid,
                    pristine,
                    values,
                    form: {
                      reset,
                      mutators,
                      resetFieldState,
                      getRegisteredFields,
                    },
                  }) => {
                    resetForm = reset;
                    resetFormFieldState = resetFieldState;
                    getFormRegisteredFields = getRegisteredFields;
                    currentValues = values;
                    formMutators = mutators;
                    return (
                      <>
                        <FormPage isVisible={!formPageNumber || !isCouncilForm}>
                          <BasicFields
                            enableImageUpload={formData.get(
                              'enableImageUpload',
                              false
                            )}
                            emailRequired={emailRequired}
                            phoneNumberRequired={phoneNumberRequired}
                          />

                          {isEventForm && (
                            <EventFields formData={formData.toJS()} />
                          )}

                          <Grid
                            item
                            xs={12}
                            className={classes.formFieldsContent}
                          >
                            <Grid container spacing={1}>
                              {renderFormFields(values)}
                            </Grid>
                          </Grid>

                          {isCouncilForm && chapterSelectionEnabled && (
                            <ChapterSelectionField
                              groupId={groupId}
                              className={classes.formFieldsContent}
                              subtext={CHAPTER_SUB_TEXT}
                              multiple
                            />
                          )}

                          {isCouncilForm && genderSelectionEnabled && (
                            <GenderSelectionField />
                          )}

                          {formData.get('requestReferrersInfo') && (
                            <ReferrerFields
                              referringSomeoneChecked={referringSomeoneChecked}
                              onCheckboxChange={handleCheckboxChange}
                            />
                          )}

                          {(isCouncilForm ||
                            isOrganizationForm ||
                            isUniversalForm) && (
                            <AgreeToTermsField
                              customAgreementContent={
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: customAgreement1Content,
                                  }}
                                />
                              }
                              enableCustomAgreement={enableCustomAgreement1}
                              customAgreementName='customAgreement1'
                            />
                          )}

                          {(isCouncilForm ||
                            isOrganizationForm ||
                            isUniversalForm) && (
                            <AgreeToTermsField
                              customAgreementContent={
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: customAgreement2Content,
                                  }}
                                />
                              }
                              enableCustomAgreement={enableCustomAgreement2}
                              customAgreementName='customAgreement2'
                            />
                          )}
                        </FormPage>

                        {isCouncilForm && collectPayment && (
                          <FormPage isVisible={formPageNumber === 1}>
                            <FormStripeView
                              isCouncilForm={isCouncilForm}
                              callSubmitGlobalForm={handleSubmit}
                              setStripeCardElement={setStripeCardElement}
                              mutators={mutators}
                              accountNumber={accountNumber}
                              paymentIntentClientSecret={
                                paymentIntentClientSecret
                              }
                              referralFormLoading={!!referralFormLoading}
                              stripePaymentStatus={stripePaymentStatus}
                              setStripePaymentStatus={setStripePaymentStatus}
                              stripeApiKey={stripeApiKey}
                            />
                          </FormPage>
                        )}

                        <Grid item xs={12} className={classes.footerContainer}>
                          {submitForm.formError && (
                            <FormHelperText
                              error
                              id='errorMessage'
                              className={classes.errorMessage}
                            >
                              {formatError()}
                            </FormHelperText>
                          )}

                          {getSubmitButton(handleSubmit, pristine, invalid)}

                          {formPageNumber > 0 && (
                            <Grid container>
                              <Grid className={classes.gridBack} item xs={12}>
                                {getButtonBack()}
                              </Grid>
                            </Grid>
                          )}
                        </Grid>

                        <Toast
                          snackbarProps={{
                            autoHideDuration: 2000,
                          }}
                          successMessage='Submitted Successfully'
                          failureMessage='Failed To Submit. Try Again!'
                          hideErrorDialog={true}
                          formName={SUBMIT_FORM}
                          shouldDestroyFormOnClose
                          handleResetForm={handleResetForm}
                          hideSuccessMessage={
                            isCouncilForm ||
                            isOrganizationForm ||
                            isUniversalForm
                          }
                        />
                      </>
                    );
                  }}
                />
              )}
            </Grid>
          </Card>
        )
      ) : (
        <Card id='notViewable' className={classes.notViewableCard}>
          <Grid container>
            <Grid item xs={1}>
              <Icon>
                <Lock />
              </Icon>
            </Grid>
            <Grid item xs={11}>
              {collectPaymentError ? (
                <Typography>
                  There is some issue with the payment method.
                </Typography>
              ) : (
                <>
                  <Typography>
                    You do not have permission to view this form.
                  </Typography>

                  <Typography color='textSecondary' variant='subtitle2'>
                    Please ask for a shareable link
                  </Typography>
                </>
              )}
            </Grid>
          </Grid>
        </Card>
      )}
    </Grid>
  );
};

export default withStyles(formViewStyles, { withTheme: true })(FormView);
