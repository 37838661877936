import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

// action creators
import { doneIndicator } from 'store/actions/httpActions';

// api
import client from 'sources/api';

// constants
import { REQUEST_FETCH_FOR_GROUP_REQUEST } from 'store/constants/requestTypes';

// helpers
import httpSaga from 'store/sagas/httpSaga';
import getBaseType from '../helpers/getBaseType';

// schema
import { requestSchema } from 'store/schemas/requestSchema';

const WATCH_TYPE = REQUEST_FETCH_FOR_GROUP_REQUEST;

export function* fetchRequestsForGroup(action: any) {
  const TYPE = getBaseType(action.type);
  const { groupId } = action.payload;

  try {
    // @ts-expect-error ts-migrate(2569) FIXME: Type 'Generator<any, any, unknown>' is not an arra... Remove this comment to see the full error message
    yield* httpSaga(
      TYPE,
      call(client.get, '/requests', {
        params: {
          group_id: groupId,
        },
      }),
      {
        schema: [requestSchema],
      }
    );
  } finally {
    yield put(doneIndicator(TYPE));
  }
}

export function* watch() {
  yield takeEvery(WATCH_TYPE, fetchRequestsForGroup);
}

export default function* root() {
  yield all([fork(watch)]);
}
