export default function getQuotes() {
  return [
    {
      content:
        'When I was ten, I wrote an essay on what I would be when I grew up and said I would be a professional soccer player and a comedian in the off season.',
      author: 'Will  Ferrell',
      subtitle: 'Delta Tau Delta, University of Southern California',
    },
    {
      content:
        'If I have chocolate around, I will eat it. I love it, I love it, I love it. I like a piece every day.',
      author: 'Julia Louis-Dreyfus',
      subtitle: 'Delta Gamma, Northwestern University',
    },
    {
      content: `Snakes. Why did it have to be snakes?`,
      author: 'Harrison Ford',
      subtitle: 'Sigma Nu, Ripon College',
    },
    {
      content: `People don't realize that I'm really funny and I'm an excellent bridge player.`,
      author: 'Sheryl Crow',
      subtitle: 'Kappa Alpha Theta, University of Missouri',
    },
    {
      content: `I was not a girl who grew up buying $100 candles. I was the girl who ran out of gas on her way to an audition.`,
      author: 'Meghan Markle',
      subtitle: 'Kappa Kappa Gamma, Northwestern University, Duchess of Sussex',
    },
    {
      content: `Throw caution to the wind and just do it.`,
      author: 'Carrie Underwood',
      subtitle: 'Sigma Sigma Sigma, Northeastern State University',
    },
    {
      content: `I am not afraid of much. I kill all the spiders in my house, and I'm planning to go skydiving. I am into girl power, and I'm very self-sufficient.`,
      author: 'Elizabeth Banks',
      subtitle: 'Delta Delta Delta, University of Pennsylvania',
    },
    {
      content: `So often in life, things that you regard as an impediment turn out to be great, good fortune.`,
      author: 'Ruth Bader-Ginsburg',
      subtitle: 'Alpha Epsilon Phi, Cornell University',
    },
    {
      content: `SAVE BANDIT!`,
      author: 'Angela Kinsley',
      subtitle: 'Chi Omega, Baylor University',
    },
    {
      content: `Alright, alright, alright`,
      author: 'Matthew McConaughey',
      subtitle: 'Delta Tau Delta, University of Texas at Austin',
    },
    {
      content: `It’s illegal in nine countries… Yep, it’s made with bits of real panther, so you know it’s good. They’ve done studies, you know. 60% of the time, it works every time.`,
      author: 'Paul Rudd',
      subtitle: 'Sigma Nu, University of Kansas',
    },
    {
      content: `My mother would look at me and she’d say, 'Kamala, you may be the first to do many things, but make sure you are not the last,'`,
      author: 'Kamala Harris',
      subtitle: 'Alpha Kappa Alpha, Howard University',
    },
    {
      content: `If you feel like there's something out there that you're supposed to be doing, if you have a passion for it, then stop wishing and just do it.`,
      author: 'Wanda Sykes',
      subtitle: 'Alpha Kappa Alpha, Hampton University',
    },
    {
      content: `You can take lessons to become almost anything: flying lessons, piano lessons, skydiving lessons, acting lessons, race car driving lessons, singing lessons. But there's no class for comedy. You have to be born with it`,
      author: 'Steve Harvey',
      subtitle: 'Omega Psi Phi, West Virginia University',
    },
    {
      content: `Talent wins games, but teamwork and intelligence wins championships.`,
      author: 'Michael Jordan',
      subtitle: 'Omega Psi Phi, UNC at Chapel Hill',
    },
    {
      content: `The ultimate measure of a man is not where he stands in moments of comfort and convenience, but where he stands at times of challenge and controversy.`,
      author: 'Martin Luther King Jr.',
      subtitle: 'Alpha Phi Alpha, Morehouse College',
    },
  ];
}
