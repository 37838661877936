import { Map } from 'immutable';
// constants
import {
  CURRENT_GROUP_FETCH,
  CURRENT_GROUP_CLEAR,
  CURRENT_GROUP_UPDATE,
} from 'store/constants/groupTypes';
// helpers
import mapSingleEntityToData from 'store/reducers/callbacks/mapSingleEntityToData';
import httpReducer from './helpers/httpReducer';

export default function reduceCurrentGroup(state = Map(), action = {}) {
  switch ((action as any).type) {
    case CURRENT_GROUP_UPDATE:
    case CURRENT_GROUP_FETCH:
      return httpReducer(state, action, {
        entity: 'currentGroup',
        callback: mapSingleEntityToData(),
      });
    case CURRENT_GROUP_CLEAR:
      return Map();
    default:
      return state;
  }
}
