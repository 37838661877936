import React, { useContext } from 'react';

import { isEqual } from 'lodash';

// hooks
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

// components
import { Field } from 'react-final-form';
import Switch from 'components/Switch';
import TextField from 'components/TextField';
import Button from 'components/Button';
import OutlinedCard from 'components/OutlinedCard';

// material-ui
import { Grid, Typography } from '@material-ui/core';
import { AddCircle } from '@material-ui/icons';

// selectors
import getSliceState from 'store/selectors/getSliceState';

// helpers
import { SiteVisualDataContext } from 'components/SiteVisualData';

export default function TextMessageForm({
  mutators,
  textMessageSelected,
  textToInsert,
}: {
  textMessageSelected: boolean;
  mutators: any;
  textToInsert?: string;
}) {
  const history = useHistory();
  const {
    location: { search },
  } = history;

  const { textMessageFormMessage } = useContext(SiteVisualDataContext);

  const {
    currentUserId,
    phoneNumber: { data: phoneNumbers },
  }: {
    currentUserId: number;
    phoneNumber: { data: any[] };
  } = useSelector(
    state => ({
      currentUserId: (state as any).getIn(['currentUser', 'data', 'id']),
      phoneNumber: getSliceState('phoneNumber', 'fetch', false)(state),
    }),
    isEqual
  );

  const currentUserHasPhoneNumber = phoneNumbers.find(
    (phoneNumber: any) => phoneNumber.account === currentUserId
  );

  const getInsertOptions = () => {
    return [
      {
        label: 'Lead First Name',
        value: 'first_name',
      },
      {
        label: 'Lead Full Name',
        value: 'full_name',
      },
    ];
  };

  return (
    <OutlinedCard borderRadiusVariant={8}>
      <Grid container justifyContent='flex-start' spacing={2}>
        <Grid item xs={12}>
          <Field
            name='textMessageSelected'
            label='Send an invite via text message'
            type='checkbox'
            disabled={!currentUserHasPhoneNumber}
            component={Switch}
          />
        </Grid>

        {textMessageSelected && (
          <>
            <Grid item xs={8} sm={4}>
              <Field
                name='textMessageTextToInsert'
                component={TextField}
                label='Text To Insert'
                margin='none'
                helperText='Inserts at beginning of message'
                options={getInsertOptions()}
                select
              />
            </Grid>
            <Grid item xs={4} sm={8} style={{ marginTop: 10 }}>
              <Button
                id='insertButton'
                startIcon={<AddCircle />}
                onClick={() =>
                  mutators.insertTextMessageTemplateText(textToInsert)
                }
              >
                Click To Insert
              </Button>
            </Grid>

            <Grid item xs={12}>
              <Field
                name='textMessageBody'
                component={TextField}
                label='Message'
                margin='none'
                multiline
                required
              />
            </Grid>
          </>
        )}

        {!currentUserHasPhoneNumber && (
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant='subtitle2'>
                  {textMessageFormMessage}
                </Typography>
                <Typography color='textSecondary' variant='body2'>
                  Create and assign a phone number to get started with
                  messaging!
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Button
                  variant='outlined'
                  onClick={() =>
                    history.push({ pathname: '/settings/phone', search })
                  }
                >
                  Set Up Number
                </Button>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </OutlinedCard>
  );
}
