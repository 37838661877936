import React from 'react';

// react router
import { Link, useLocation } from 'react-router-dom';

// material-ui
import ArrowBack from '@material-ui/icons/ArrowBack';
import Button from '@material-ui/core/Button';

const EventBackButton = () => {
  const { search, state } = useLocation();

  return (
    <Button
      size='large'
      style={{ marginRight: 10, marginLeft: 10 }}
      component={Link}
      to={{ pathname: '/events', search, state }}
      startIcon={<ArrowBack />}
    >
      Back To List
    </Button>
  );
};

export default EventBackButton;
