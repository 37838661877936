export const REFERRAL_FORM_CREATE_REQUEST = 'REFERRAL_FORM_CREATE_REQUEST';
export const REFERRAL_FORM_CREATE = 'REFERRAL_FORM_CREATE';

export const REFERRAL_FORM_SUBMIT_REQUEST = 'REFERRAL_FORM_SUBMIT_REQUEST';
export const REFERRAL_FORM_SUBMIT = 'REFERRAL_FORM_SUBMIT';

export const REFERRAL_FORM_UPDATE_REQUEST = 'REFERRAL_FORM_UPDATE_REQUEST';
export const REFERRAL_FORM_UPDATE = 'REFERRAL_FORM_UPDATE';

export const REFERRAL_FORM_DELETE_REQUEST = 'REFERRAL_FORM_DELETE_REQUEST';
export const REFERRAL_FORM_DELETE = 'REFERRAL_FORM_DELETE';

export const REFERRAL_FORM_FETCH_FOR_GROUP_REQUEST =
  'REFERRAL_FORM_FETCH_FOR_GROUP_REQUEST';
export const REFERRAL_FORM_FETCH_FOR_GROUP = 'REFERRAL_FORM_FETCH_FOR_GROUP';

export const REFERRAL_FORM_FETCH_BY_ID_REQUEST =
  'REFERRAL_FORM_FETCH_BY_ID_REQUEST';
export const REFERRAL_FORM_FETCH_BY_ID = 'REFERRAL_FORM_FETCH_BY_ID';

export const REFERRAL_FORM_SEND_PREVIEW_EMAIL_REQUEST =
  'REFERRAL_FORM_SEND_PREVIEW_EMAIL_REQUEST';
export const REFERRAL_FORM_SEND_PREVIEW_EMAIL =
  'REFERRAL_FORM_SEND_PREVIEW_EMAIL';

export const REFERRAL_FORM_CREATE_TOKEN_REQUEST =
  'REFERRAL_FORM_CREATE_TOKEN_REQUEST';
export const REFERRAL_FORM_CREATE_TOKEN = 'REFERRAL_FORM_CREATE_TOKEN';
