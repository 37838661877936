import { Map } from 'immutable';
// constants
import {
  COMMENT_FETCH,
  COMMENT_CREATE,
  COMMENT_UPDATE,
  COMMENT_DELETE,
  COMMENT_CLEAR,
} from 'store/constants/commentTypes';
import update from './callbacks/update';
import remove from './callbacks/remove';
// helpers
import httpReducer from './helpers/httpReducer';

export default function reduceComment(state = Map(), action = {}) {
  switch ((action as any).type) {
    case COMMENT_FETCH:
      return httpReducer(state, action, { appendResult: true });
    case COMMENT_CREATE:
      return httpReducer(state, action, { appendResult: true });
    case COMMENT_UPDATE:
      return httpReducer(state, action, { callback: update });
    case COMMENT_DELETE:
      return httpReducer(state, action, { callback: remove });
    case COMMENT_CLEAR:
      return Map();
    default:
      return state;
  }
}
