import { all } from 'redux-saga/effects';

import createSaga from './createSaga';
import updateSaga from './updateSaga';
import deleteSaga from './deleteSaga';

const authIntegrationSaga = function* rootSaga() {
  yield all([createSaga(), updateSaga(), deleteSaga()]);
};

export default authIntegrationSaga;
