import React, { useContext } from 'react';
// material-ui
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

// helpers
import { SiteVisualDataContext } from 'components/SiteVisualData';

const SuccessCreateChapter = () => {
  const classes = useStyles();
  const { supportEmail, pageTitle } = useContext(SiteVisualDataContext);

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant='h4'>Success!</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant='body1'>
          Give us a second while we setup your new {pageTitle} account!
        </Typography>
      </Grid>
      <Grid item xs={12} className={classes.textBox}>
        <Typography variant='body1'>
          You should also have received an order confirmation and receipt in
          your inbox. If you have issues, please let our support team know at
          <span className={classes.space} />
          <a href={`mailto:${supportEmail}`}>{supportEmail}</a>.
        </Typography>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles(theme => ({
  textBox: {
    marginTop: 50,
    '& a': {
      color: 'inherit',
      fontWeight: 500,
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  },
  space: {
    display: 'inline-block',
    width: '0.3em',
  },
}));

export default SuccessCreateChapter;
