import { Styles } from 'assets/types';

const manuallyPushLeadsModalStyles: Styles = () => ({
  spacing: {
    display: 'inline-block',
    width: '0.35em',
  },
});

export default manuallyPushLeadsModalStyles;
