import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { List, Map } from 'immutable';

// hooks
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useFeatureLimitRemaining } from 'helpers/hooks/featureHooks';

// material-ui
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Skeleton from '@material-ui/lab/Skeleton';

// icons
import RequestsIcon from '@material-ui/icons/SupervisorAccount';

// components
import Header from 'components/Header';
import RequestItem from './RequestItem';

//selectors
import getRequests from 'store/selectors/getRequests';
import getMembers from 'store/selectors/getMembers';
import getLoadingFromState from 'store/selectors/getLoadingFromState';

// action creators
import { fetchRequestsForGroup } from 'store/actions/requestActions';

const RequestsCard = function({ currentGroup }: any) {
  const currentGroupId = currentGroup.get('id');

  const { requests = List(), loading, members = List() } = useSelector(
    state => ({
      requests: getRequests(state) as any,
      members: getMembers()(state) as any,
      loading: getLoadingFromState('request', false, false)(state),
    }),
    shallowEqual
  );

  const dispatch = useDispatch();
  const usersRemaining = useFeatureLimitRemaining('users', members.size); // TO-DO: Refactor to use non-paginated member count

  const usersRemainingMessage =
    usersRemaining > 0
      ? ''
      : 'Your team is full. Remove a member or upgrade for more.';

  useEffect(() => {
    if (currentGroupId) {
      dispatch(fetchRequestsForGroup({ groupId: currentGroupId }));
    }
  }, [currentGroupId, dispatch]);

  const classes = useStyles();

  const renderRequests = () => {
    if (requests.size) {
      return (
        <Grid container spacing={2}>
          {requests.map((request: any) => (
            <RequestItem
              request={request}
              key={request.get('id')}
              disableAccept={usersRemaining === 0}
              // @ts-expect-error ts-migrate(2322) FIXME: Type '{ request: any; key: any; disableAccept: boo... Remove this comment to see the full error message
              currentGroupId={currentGroupId}
              id='item'
            />
          ))}
        </Grid>
      );
    }

    if (!loading) {
      return (
        <Typography id='noRequestsLabel' variant='subtitle1'>
          No Requests Found.
        </Typography>
      );
    }
  };

  return (
    <Grid item xs={12}>
      <Card id='tabContent'>
        <CardContent>
          <Header
            id='header'
            icon={RequestsIcon}
            title='Requests To Join'
            subtitle='Accept or reject requests for members to join your team'
            highlightedSubtitle={usersRemainingMessage}
          />

          <div className={classes.requestsContainer}>
            {loading && requests.size === 0 && (
              <Grid container spacing={2} direction='column'>
                <Grid item xs={5}>
                  <Skeleton variant='rect' height={40} />
                </Grid>
                <Grid item xs={5}>
                  <Skeleton variant='rect' height={40} />
                </Grid>
                <Grid item xs={5}>
                  <Skeleton variant='rect' height={40} />
                </Grid>
              </Grid>
            )}
            {renderRequests()}
          </div>
        </CardContent>
      </Card>
    </Grid>
  );
};

RequestsCard.propTypes = {
  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'typeof Map' is not assignable to... Remove this comment to see the full error message
  currentGroup: PropTypes.instanceOf(Map),
};

RequestsCard.defaultProps = {
  currentGroup: Map(),
};

const useStyles = makeStyles(theme => ({
  requestsContainer: {
    paddingLeft: 95,
    [theme.breakpoints.only('xs')]: {
      paddingLeft: 0,
    },
  },
}));

export default RequestsCard;
