import { Styles, Theme } from 'assets/types';

const chapterListActionsStyles: Styles = theme => ({
  toolbarContainer: {
    paddingLeft: 24,
    paddingRight: 24,
    marginBottom: 2,
    borderBottom: `1px solid ${theme.palette.background.paper}`,
    backgroundColor: (theme as Theme).palette.background.darkPaper,
    [theme.breakpoints.only('xs')]: {
      paddingLeft: 8,
      paddingRight: 8,
    },
  },
  leftActionsContainer: {
    paddingLeft: 4,
  },
  selectedLabelMargin: {
    marginRight: 15,
    marginLeft: 15,
  },
  banner: {
    backgroundColor: theme.palette.primary.main,
    marginTop: -16,
    marginBottom: 16,
  },
  bannerButton: {
    textAlign: 'right',
  },
});

export default chapterListActionsStyles;
