import { all, call, fork, put, takeEvery, take } from 'redux-saga/effects';
// action creators
import { doneIndicator } from 'store/actions/httpActions';
import { fetchFiles } from 'store/actions/fileActions';
// api
import client from 'sources/api';
// constants
import {
  REFERRAL_FORM_FETCH_BY_ID_REQUEST,
  REFERRAL_FORM_FETCH_BY_ID,
} from 'store/constants/referralFormTypes';
// helpers
import httpSaga from 'store/sagas/httpSaga';
// schema
import { referralFormSchema } from 'store/schemas/referralFormSchema';
const WATCH_TYPE = REFERRAL_FORM_FETCH_BY_ID_REQUEST;
const TYPE = REFERRAL_FORM_FETCH_BY_ID;
export function* fetchReferralFormById(action: any) {
  const { groupId: group_id, formId, token } = action.payload;
  const options = {};
  if (token) {
    (options as any).headers = {
      Authorization: `Bearer ${token}`,
    };
  }
  let response;
  try {
    // @ts-expect-error ts-migrate(2569) FIXME: Type 'Generator<any, any, unknown>' is not an arra... Remove this comment to see the full error message
    response = yield* httpSaga(
      TYPE,
      call(client.get, `/forms/${formId}`, {
        params: {
          group_id,
        },
        ...options,
      }),
      {
        schema: referralFormSchema,
      }
    );
    yield put(doneIndicator(TYPE));
  } finally {
    if (response) {
      const { data = {} } = response;
      const imageId = data.headerPhotoFileId;
      if (imageId) {
        yield put(fetchFiles(imageId, token));
        yield take('FILE_FETCH_DONE');
      }
      yield put(doneIndicator(TYPE));
    }
  }
}
export function* watch() {
  yield takeEvery(WATCH_TYPE, fetchReferralFormById);
}
export default function* root() {
  yield all([fork(watch)]);
}
