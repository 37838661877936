import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import moment from 'moment-timezone';
// action creators
import { doneIndicator } from 'store/actions/httpActions';
// api
import client from 'sources/api';
// constants
import {
  REFERRAL_FORM_SUBMIT_REQUEST,
  REFERRAL_FORM_SUBMIT,
} from 'store/constants/referralFormTypes';
// helpers
import httpSaga from 'store/sagas/httpSaga';
import { isEmpty } from 'helpers/check';

const WATCH_TYPE = REFERRAL_FORM_SUBMIT_REQUEST;
const TYPE = REFERRAL_FORM_SUBMIT;
export function* submitReferralForm(action: any) {
  const {
    payload: {
      token,
      formName,
      formId,
      emailAddress: email_address,
      firstName: first_name,
      lastName: last_name,
      phoneNumber: phone_number,
      profilePhotoFileId: profile_photo_file_id,
      referrerFirstName: person_first_name,
      referrerLastName: person_last_name,
      referrerEmailAddress: person_email_address,
      eventTimeSlotId: event_time_slot_id,
      paymentMethodId,
      paymentIntentId,
      emailAddressStripe,
      nameOnCard,
      stripeFormComplete,
      rsvp,
      timezone_name,
      childIds,
      orgGender,
      ...formFields
    },
  } = action;
  const options = {};
  if (token) {
    (options as any).headers = {
      Authorization: `Bearer ${token}`,
    };
  }
  // @ts-expect-error ts-migrate(2569) FIXME: Type 'Generator<any, any, unknown>' is not an arra... Remove this comment to see the full error message
  yield* httpSaga(
    TYPE,
    call(
      client.post,
      `/forms/${formId}/submit`,
      {
        pnm: {
          email_address,
          first_name,
          last_name,
          phone_number,
          profile_photo_file_id,
        },
        child_ids: childIds,
        org_gender: orgGender,
        pnm_attrs: {
          ...(!isEmpty(paymentMethodId) || !isEmpty(paymentIntentId)
            ? {
                ...(!isEmpty(paymentIntentId)
                  ? { payment_intent_id: paymentIntentId }
                  : { payment_method_id: paymentMethodId }),
                email_address: emailAddressStripe,
                card_name: nameOnCard,
              }
            : {}),
          ...formFields,
        },
        referral: {
          person_first_name,
          person_last_name,
          person_email_address,
        },
        event_guest: {
          event_time_slot_id,
          rsvp: event_time_slot_id ? 'yes' : rsvp,
          timezone_name: moment.tz.guess(),
        },
      },
      options
    ),
    {
      formName,
    }
  );
  yield put(doneIndicator(TYPE));
}
export function* watch() {
  yield takeEvery(WATCH_TYPE, submitReferralForm);
}
export default function* root() {
  yield all([fork(watch)]);
}
