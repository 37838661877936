import { applyMiddleware, compose, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import rootReducer from 'store/reducers';
import sagas from 'store/sagas';
// Used to enable redux dev tools
const composeEnhancers =
  (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const sagaMiddleware = createSagaMiddleware();
const middleware = [sagaMiddleware];
// Compose the redux middleware
const composedEnhancers = composeEnhancers(applyMiddleware(...middleware));
// Create the redux store
const store = createStore(rootReducer, composedEnhancers);
// This must be run after `applyMiddleware` has been called and thus `createStore`
sagaMiddleware.run(sagas);
export default store;
