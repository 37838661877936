const getExportDataOptions = (isCouncilLeadPage?: boolean | null) => [
  {
    name: 'firstName',
    label: 'First Name',
  },
  {
    name: 'emailAddress',
    label: 'Email Address',
  },
  {
    name: 'lastName',
    label: 'Last Name',
  },
  {
    name: 'phoneNumber',
    label: 'Phone Number',
  },
  {
    name: 'status',
    label: 'Status',
  },
  {
    name: 'mainContactFullName',
    label: 'Main Contact',
  },

  {
    name: 'tags',
    label: 'Tags',
  },
  {
    name: 'referral_source',
    label: 'Referral Source',
  },
  ...(!isCouncilLeadPage
    ? [
        {
          name: 'endorsements',
          label: 'Endorsements',
        },
      ]
    : []),
];

export default getExportDataOptions;
