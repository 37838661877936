import React, { useCallback, useEffect, useMemo } from 'react';
import { isEqual } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';

// MUI components
import {
  Grid,
  Card,
  CardContent,
  Hidden,
  WithStyles,
  withStyles,
  useMediaQuery,
} from '@material-ui/core';

// components
import Header from 'components/Header';
import Table from 'components/Table';
import ExportButton from 'components/ExportButton';
import StatItem from 'components/StatItem';
import ReportRefreshButton from 'components/ReportRefreshButton';

// actions
import { createTaskRecruitmentStoryReport } from 'store/actions/apiTaskActions';

// selectors
import getCurrentGroupId from 'store/selectors/getCurrentGroupId';
import getForm from 'store/selectors/getForm';
import getSliceEntityById from 'store/selectors/getSliceEntityById';

// helpers
import getTableColumns from 'routes/NewMemberAnalytics/RecruitmentStory/helpers/getTableColumns';
import { isEmpty } from 'helpers/check';

// icons
import BookIcon from '@material-ui/icons/Book';
import Group from '@material-ui/icons/Group';
import PieChart from '@material-ui/icons/PieChart';

// styles
import recruitmentStoryStyles from 'routes/NewMemberAnalytics/RecruitmentStory/recruitmentStory.style';

const REPORT_FORM = 'councilRecruitmentStoryReportForm';
const ROWS_PER_PAGE = 10;

export interface RecruitmentStoryProps
  extends WithStyles<typeof recruitmentStoryStyles> {
  isOrganizationMemberAnalytics?: boolean;
}

const RecruitmentStory = ({
  classes,
  isOrganizationMemberAnalytics,
}: RecruitmentStoryProps) => {
  const dispatch = useDispatch();
  const isMaxWidth500 = useMediaQuery('(max-width:500px)');

  const {
    currentGroupId,
    report: { data: { content = [] } = {}, id: reportId },
    form: { isSubmitting },
  } = useSelector(
    state => ({
      currentGroupId: getCurrentGroupId(state),
      form: getForm(REPORT_FORM)(state),
      report: getSliceEntityById(
        'report',
        'ReportTypes.RECRUITMENT_STORY',
        {}
      )(state),
    }),
    isEqual
  );

  const [formattedContent, averagesData] = useMemo(() => {
    if (isEmpty(content)) {
      return [[], {}];
    }
    const newContent =
      content?.map((item: {}, index: number) => ({
        ...(item || {}),
        systemId: index || content.length + 1,
      })) || [];
    const newAveragesData = newContent.shift();

    return [newContent, { ...(newAveragesData || {}) }];
  }, [content]);

  const [
    totalLeadsCount,
    filteredLeadsCount,
    filteredLeadsPercentage,
  ] = useMemo(() => {
    if (!content || isEmpty(content) || !content?.[0]) {
      return [0, 0, 0];
    }

    const {
      filteredChapterCount = 0,
      filteredChapterPercentage = 0,
      totalChapterCount = 0,
    } = content[0] || {};

    return [
      totalChapterCount,
      filteredChapterCount,
      filteredChapterPercentage.toString(10).slice(0, 5),
    ];
  }, [content]);

  const newColumns = getTableColumns();

  const dispatchCreateReport = useCallback(
    ({ forceRefresh }: { forceRefresh: boolean }) => {
      dispatch(
        createTaskRecruitmentStoryReport({
          groupId: currentGroupId,
          formName: REPORT_FORM,
          forceRefresh,
        })
      );
    },
    [dispatch, currentGroupId]
  );

  useEffect(() => {
    dispatchCreateReport({ forceRefresh: false });
  }, [dispatchCreateReport]);

  return (
    <Card>
      <CardContent className={classes.cardContent}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm>
            <Header
              title={
                isOrganizationMemberAnalytics
                  ? 'Organization Joiner Story'
                  : 'Recruitment Story'
              }
              subtitle={
                isOrganizationMemberAnalytics
                  ? 'Discover what it took to secure a new member by analyzing their joiner story'
                  : 'Discover what it took to recruit a new member by analyzing community averages'
              }
              icon={BookIcon}
              compact
            />
          </Grid>

          <Hidden xsDown>
            <Grid className={classes.exportButtonGrid} xs={3} item>
              <Grid container justifyContent='flex-end'>
                <Grid item>
                  <ReportRefreshButton
                    loading={isSubmitting}
                    onRefresh={dispatchCreateReport}
                  />
                </Grid>
                <ExportButton params={{ reportId }} disabled={isSubmitting} />
              </Grid>
            </Grid>
            <Grid className={classes.gridFullLine} item xs={12} />
          </Hidden>

          <Grid item xs={isMaxWidth500 ? 12 : 6} sm={5} lg={3} xl={2}>
            <StatItem
              title={
                totalLeadsCount
                  ? `${filteredLeadsCount} / ${totalLeadsCount}`
                  : 'No leads found.'
              }
              subtitle='Joined / Total Leads'
              icon={<Group />}
              loading={isSubmitting}
              gutterBottom
            />
          </Grid>
          <Grid item xs={isMaxWidth500 ? 12 : 6} sm={5} lg={3} xl={2}>
            <StatItem
              title={`${filteredLeadsPercentage}%`}
              subtitle='Percent Joined'
              icon={<PieChart />}
              loading={isSubmitting}
              gutterBottom
            />
          </Grid>

          <Grid item xs={12} className={classes.tableGrid}>
            <Table
              columns={newColumns}
              data={formattedContent}
              variant='outlined'
              numberOfSkeletonRows={isSubmitting ? 8 : 0}
              initialOrder='asc'
              initialOrderBy='systemId'
              TablePaginationProps={{
                count: formattedContent.length,
                rowsPerPage: ROWS_PER_PAGE,
                rowsPerPageOptions: [ROWS_PER_PAGE],
              }}
              TableFooterRowProps={{
                data: averagesData,
              }}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default withStyles(recruitmentStoryStyles, { withTheme: true })(
  RecruitmentStory
);
