import { all, call, fork, put, takeEvery, select } from 'redux-saga/effects';

// action creators
import { doneIndicator } from 'store/actions/httpActions';

// api
import client from 'sources/api';

// constants
import { VOTE_FETCH_REQUEST, VOTE_FETCH } from 'store/constants/voteTypes';

// schemas
import { voteSchema } from 'store/schemas/voteSchema';

// helpers
import httpSaga from 'store/sagas/httpSaga';

// selectors
import getCurrentGroupId from 'store/selectors/getCurrentGroupId';

const WATCH_TYPE = VOTE_FETCH_REQUEST;
const TYPE = VOTE_FETCH;

export function* fetchVotes(action: any) {
  const {
    payload: { leadId: lead_id, page },
  } = action;

  const currentGroupId = yield select(getCurrentGroupId);

  // @ts-expect-error ts-migrate(2569) FIXME: Type 'Generator<any, any, unknown>' is not an arra... Remove this comment to see the full error message
  yield* httpSaga(
    TYPE,
    call(client.get, '/votes', {
      params: { group_id: currentGroupId, lead_id, page },
    }),
    {
      schema: [voteSchema],
      metaId: lead_id,
    }
  );

  yield put(doneIndicator(TYPE));
}

export function* watch() {
  yield takeEvery(WATCH_TYPE, fetchVotes);
}

export default function* root() {
  yield all([fork(watch)]);
}
