import {
  select,
  all,
  call,
  fork,
  put,
  takeEvery,
  take,
} from 'redux-saga/effects';
import Immutable from 'immutable';

// action creators
import {
  doneIndicator,
  error,
  errorIndicator,
  success,
} from 'store/actions/httpActions';
import { fetchMessagesAction } from 'store/actions/messageActions';
import { fetchThreadsAction } from 'store/actions/threadActions';

// api
import client from 'sources/api';

// constants
import {
  MESSAGE_CREATE_REQUEST,
  MESSAGE_CREATE,
} from 'store/constants/messageTypes';

// helpers
import httpSaga from 'store/sagas/httpSaga';
import delayTwoSeconds from 'helpers/delayTwoSeconds';
import { fetchTask } from 'store/actions/apiTaskActions';
import getSliceEntityById from 'store/selectors/getSliceEntityById';
import {
  setFormSubmitFailed,
  setFormSubmitSucceeded,
} from 'store/actions/formActions';

const WATCH_TYPE = MESSAGE_CREATE_REQUEST;
const TYPE = MESSAGE_CREATE;
const META_ID = 'create';

const FILTERS_MAP = {
  isReported: 'is_reported',
  statusIds: 'status_id',
  tagIds: 'tag_id',
  createdOnStart: 'created_on_start',
  createdOnEnd: 'created_on_end',
  updatedOnStart: 'updated_on_start',
  updatedOnEnd: 'updated_on_end',
  lastContactedOnStart: 'last_contacted_on_start',
  lastContactedOnEnd: 'last_contacted_on_end',
  mainContactId: 'main_contact_id',
  referralSourceId: 'referral_source_id',
};

export function* createMessageSaga(action: any): any {
  const {
    payload: {
      accountIds = [],
      body,
      filters,
      formName = '',
      groupId,
      isNewMessage,
      allSelected = false,
      leadIds = [],
      shouldFetchMessages = true,
      subject,
      threadId,
      threadType: thread_type,
      fileIds,
    },
  } = action;

  const leadFilters = {};
  for (const filter in filters) {
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    leadFilters[FILTERS_MAP[filter]] = filters[filter];
  }
  if (allSelected && leadIds.length > 0) {
    // @ts-expect-error ts-migrate(7053) FIXME
    leadFilters.excluded_pnm_id = leadIds;
  }

  // Build out the create params
  const params = {
    thread_type,
    body,
    pnm_ids: allSelected ? 'all' : leadIds,
    account_ids:
      accountIds !== 'all'
        ? accountIds.map((accountId: string) => parseInt(accountId, 10))
        : 'all',
    ...(fileIds ? { file_ids: fileIds } : {}),
  };

  if (subject) (params as any).subject = subject;

  // Make POST request to create messages, spawns a background task
  // @ts-expect-error ts-migrate(2569) FIXME: Type 'Generator<any, any, unknown>' is not an arra... Remove this comment to see the full error message
  const taskResponse: any = yield* httpSaga(
    TYPE,
    call(client.post, `/messages?group_id=${groupId}`, params, {
      params: { ...leadFilters },
    }),
    {
      metaId: META_ID,
      dispatchSuccess: false, // Do not automatically dispatch success because we need to do some post processing and wait for the task to resolve
      dispatchFormSuccess: false, // Do not automatically dispatch form success because we need to do some post processing and wait for the task to resolve
      formName,
    }
  );

  // Get the task ID from the response payload
  const taskId = taskResponse?.data?.taskId;

  // If there is no taskId then just dispatch the done indicator
  // and stop the saga -- there was probably an error that happened
  // with the task creation (permissions/limits error)
  if (!taskId) {
    yield put(doneIndicator(TYPE));
    return;
  }

  let resolvedTask;

  // Wait for the task to resolve
  while (!resolvedTask) {
    yield delayTwoSeconds();

    yield put(fetchTask({ taskId, groupId }));
    yield take('API_TASK_FETCH_DONE');

    const task = yield select(state =>
      getSliceEntityById('apiTask', taskId.toString())(state)
    );

    if (task?.status && task?.status !== 'PROCESSING') {
      resolvedTask = task;
    }
  }

  const taskData = yield select(state =>
    getSliceEntityById('apiTask', taskId.toString())(state)
  );

  // If the task failed, get the error payload from the task payload
  // and put it on the store
  if (resolvedTask?.status === 'FAILURE') {
    const errorPayload = {
      data: {
        msg: taskData?.result?.excMessage || '',
        errors: taskData?.result?.errors || [],
      },
    };

    yield put(errorIndicator(TYPE));
    yield put(setFormSubmitFailed(formName, errorPayload));
    yield put(error(TYPE, errorPayload, META_ID));
  } else {
    // If the task was successful, put the success on the store.
    // Note: the messaging endpoint never returned a payload, only just 201 with
    // {} as the payload.
    yield put(success(TYPE, Immutable.fromJS({}), META_ID));
    yield put(setFormSubmitSucceeded(formName, 'Messages sent successfully'));

    // Fetch messages after success if needed
    if (shouldFetchMessages) {
      if (threadId) {
        yield put(fetchMessagesAction({ groupId, threadId }));
        yield take('MESSAGE_FETCH_DONE');
      } else if (isNewMessage) {
        yield put(fetchThreadsAction({ groupId, displayLoading: false }));
        yield take('THREAD_FETCH_DONE');
      }
    }
  }

  // Finally done
  yield put(doneIndicator(TYPE));
}
export function* watch() {
  yield takeEvery(WATCH_TYPE, createMessageSaga);
}
export default function* root() {
  yield all([fork(watch)]);
}
