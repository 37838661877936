import React, { useCallback, memo } from 'react';
import PropTypes from 'prop-types';
// components
import { Warning } from '@material-ui/icons';
import {
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
// hooks
import { useHistory } from 'react-router-dom';
const ErrorList = function({ errors, title }: any) {
  const classes = useStyles();
  const history = useHistory();
  const navigate = useCallback(
    id => {
      history.push({ pathname: `/leads/${id}` });
    },
    [history]
  );
  const ErrorItem = useCallback(
    ({ error = {} }) => {
      const { msg, leadId } = error;
      return (
        <ListItem>
          <ListItemIcon>
            <Warning />
          </ListItemIcon>
          <ListItemText
            disableTypography
            secondary={
              leadId && (
                <div>
                  <Typography color='textSecondary'>{`For lead ID ${leadId}`}</Typography>
                  <Button
                    onClick={() => navigate(leadId)}
                    className={classes.viewButton}
                  >
                    View Profile
                  </Button>
                </div>
              )
            }
          >
            <Typography>{msg}</Typography>
          </ListItemText>
        </ListItem>
      );
    },
    [classes.viewButton, navigate]
  );
  return (
    <List className={classes.errorList} id='errorList'>
      {title && errors.length > 1 && (
        <ListItem>
          <ListItemText>{title}</ListItemText>
        </ListItem>
      )}

      <ListItem>
        <ListItemText className={classes.listHeader}>
          {errors.length === 1
            ? `There was ${errors.length} error`
            : `There were ${errors.length} errors`}
        </ListItemText>
      </ListItem>

      {errors &&
        errors.map((error: any, index: any) => (
          <React.Fragment key={`${error}_${index}`}>
            <ErrorItem error={error} />
            {index !== errors.length - 1 && <Divider />}
          </React.Fragment>
        ))}
    </List>
  );
};
const useStyles = makeStyles(theme => {
  return {
    errorList: {
      marginTop: 20,
      border: `1px solid ${(theme.palette.background as any).outline}`,
      borderRadius: 8,
    },
    viewButton: {
      marginLeft: -8,
    },
    listHeader: {
      color: theme.palette.primary.main,
    },
  };
});
ErrorList.propTypes = {
  errors: PropTypes.instanceOf(Array).isRequired,
  title: PropTypes.string,
};
export default memo(ErrorList);
