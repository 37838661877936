import { Map } from 'immutable';
// constants
import {
  FILE_FETCH,
  FILE_CREATE_AND_UPLOAD,
  FILE_CREATE_AND_INVITE,
  FILE_CREATE_AND_IMPORT_LEADS,
  FILE_UPLOAD,
  FILE_CLEAR,
} from 'store/constants/fileTypes';
// helpers
import httpReducer from './helpers/httpReducer';

export default function reduceFile(state = Map(), action = {}) {
  switch ((action as any).type) {
    case FILE_FETCH:
    case FILE_CREATE_AND_INVITE:
    case FILE_CREATE_AND_IMPORT_LEADS:
    case FILE_CREATE_AND_UPLOAD:
      return httpReducer(state, action, { entity: 'file', appendResult: true });
    case FILE_UPLOAD:
      return httpReducer(state, action, {
        entity: 'file',
      });
    case FILE_CLEAR:
      return Map();
    default:
      return state;
  }
}
