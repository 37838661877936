import {
  EVENT_GUEST_CREATE_REQUEST,
  EVENT_GUEST_UPDATE_REQUEST,
} from 'store/constants/eventGuestTypes';
import { EventGuestFilters } from 'api/eventGuests';

export function createEventGuests({
  groupId,
  eventId,
  leadIds,
  accountIds,
  leadFilters,
  memberFilters,
  emailParams,
  textMessageParams,
  formName,
  invited,
  rsvp,
  eventTimeSlotId,
  attended,
}: {
  groupId: number;
  eventId: number;
  leadIds?: number[] | 'all';
  accountIds?: number[] | 'all';
  leadFilters?: EventGuestFilters;
  memberFilters?: EventGuestFilters;
  formName: string;
  invited?: boolean;
  rsvp?: RsvpOptions;
  eventTimeSlotId?: number | null;
  attended?: boolean;
  emailParams?: {
    subject?: string;
    emailBody?: string;
  };
  textMessageParams?: {
    textMessageBody?: string;
  };
}) {
  return {
    type: EVENT_GUEST_CREATE_REQUEST,
    payload: {
      groupId,
      eventId,
      leadIds,
      accountIds,
      leadFilters,
      memberFilters,
      emailParams,
      textMessageParams,
      formName,
      invited,
      rsvp,
      eventTimeSlotId,
      attended,
    },
  };
}

export function updateEventGuest({
  id, // event guest id
  groupId,
  data,
}: {
  id: number;
  groupId: number;
  data: {
    invited?: boolean;
    rsvp?: RsvpOptions;
    attended?: boolean;
    eventTimeSlotId?: number | null;
  };
}) {
  return {
    type: EVENT_GUEST_UPDATE_REQUEST,
    payload: {
      id,
      groupId,
      data,
    },
  };
}
