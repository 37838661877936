import {
  NOTE_CREATE_REQUEST,
  NOTE_FETCH_REQUEST,
  NOTE_DELETE_REQUEST,
  NOTE_UPDATE_REQUEST,
  NOTE_CLEAR,
} from 'store/constants/noteTypes';
import { Map } from 'immutable';

export function createNoteAction({
  formName,
  fields,
  filters,
  callbackOnSuccess,
}: {
  formName: string;
  fields: {
    leadId: number;
    body?: string;
    tagIds?: number[];
    callSid?: string;
  };
  filters?: LeadFetchFilters;
  callbackOnSuccess?: () => void;
}) {
  return {
    type: NOTE_CREATE_REQUEST,
    payload: { formName, fields, filters, callbackOnSuccess },
  };
}

export function fetchNoteAction({ id }: { id: number }) {
  return {
    type: NOTE_FETCH_REQUEST,
    payload: { id },
  };
}

export function updateNoteAction({
  id,
  body,
  tagIds,
  formName,
}: {
  id: number;
  body: string;
  tagIds: number[];
  formName: string;
}) {
  return {
    type: NOTE_UPDATE_REQUEST,
    payload: { id, body, tagIds, formName },
  };
}

export function clearNoteAction({ id }: { id: number }) {
  return {
    type: NOTE_CLEAR,
    payload: Map({ id }),
  };
}

export function deleteNoteAction({ id }: { id: number }) {
  return {
    type: NOTE_DELETE_REQUEST,
    payload: { id },
  };
}
