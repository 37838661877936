import WaitingRoomContent from './WaitingRoomContent';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

// action creators
import { fetchRequestsForUser } from 'store/actions/requestActions';

// selectors
import getRequests from 'store/selectors/getRequests';
import getLoadingFromState from 'store/selectors/getLoadingFromState';

const mapState = (state: any) => {
  return {
    requests: getRequests(state),
    requestLoading: getLoadingFromState('request')(state),
  };
};

const mapDispatch = {
  fetchRequestsForUser,
};

export default withRouter(connect(mapState, mapDispatch)(WaitingRoomContent));
