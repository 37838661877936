export const LEAD_REFERRAL_SOURCE_FETCH_REQUEST =
  'LEAD_REFERRAL_SOURCE_FETCH_REQUEST';
export const LEAD_REFERRAL_SOURCE_FETCH = 'LEAD_REFERRAL_SOURCE_FETCH';

export const LEAD_REFERRAL_SOURCE_CREATE_REQUEST =
  'LEAD_REFERRAL_SOURCE_CREATE_REQUEST';
export const LEAD_REFERRAL_SOURCE_CREATE = 'LEAD_REFERRAL_SOURCE_CREATE';

export const LEAD_REFERRAL_SOURCE_DELETE_REQUEST =
  'LEAD_REFERRAL_SOURCE_DELETE_REQUEST';
export const LEAD_REFERRAL_SOURCE_DELETE = 'LEAD_REFERRAL_SOURCE_DELETE';
