import { Map } from 'immutable';
import remove from 'store/reducers/callbacks/remove';

const removeFirstFieldByAttribute = (
  state: Map<any, any>,
  action: { payload: Map<any, any> } = { payload: Map() },
  options: { byAttribute: any } = { byAttribute: false }
) => {
  const { payload } = action || {};

  const { byAttribute } = options;

  const id = byAttribute ? payload.get(byAttribute, '') : payload.get('id', '');

  if (byAttribute) {
    const itemsById = state.getIn(['data', 'byId'], Map());
    const firstItemsById = (itemsById as any).find(
      (item: Map<string, string | number | null>) =>
        item.get(byAttribute)?.toString() === (id?.toString() || id),
      {},
      Map()
    );

    const firstId = firstItemsById.get('id', false);

    if (firstId) {
      const newPayload = payload.set('id', firstId);

      return remove(
        state,
        { ...action, payload: newPayload },
        {
          ...options,
          byAttribute: false,
        }
      );
    }
  }

  return remove(state, action, options);
};

export default removeFirstFieldByAttribute;
