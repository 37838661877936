import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

// action creators
import { doneIndicator, success } from 'store/actions/httpActions';

// api
import client from 'sources/api';

// constants
import {
  PAYMENT_SETUP_FETCH_REQUEST,
  PAYMENT_SETUP_FETCH,
} from 'store/constants/paymentSettingsTypes';

// helpers
import getResponsePayload from 'helpers/getResponsePayload';
import Immutable from 'immutable';
import httpSaga from 'store/sagas/httpSaga';

// schema
import { paymentSetupSchema } from 'store/schemas/paymentSetupSchema';

const WATCH_TYPE = PAYMENT_SETUP_FETCH_REQUEST;
const TYPE = PAYMENT_SETUP_FETCH;

export function* fetchPaymentSettingsSaga(action: any) {
  const {
    payload: { groupId, token },
  } = action || {};

  // @ts-expect-error ts-migrate(2569) FIXME: Type 'Generator<any, any, unknown>' is not an arra... Remove this comment to see the full error message
  const response = yield* httpSaga(
    TYPE,
    call(client.get, '/payment_setup', {
      params: { group_id: groupId },
      ...(token ? { headers: { Authorization: `Bearer ${token}` } } : {}),
    })
  );

  const payload = getResponsePayload(response, [paymentSetupSchema]);

  yield put(success(TYPE, Immutable.fromJS(payload)));

  yield put(doneIndicator(TYPE));
}

export function* watch() {
  yield takeEvery(WATCH_TYPE, fetchPaymentSettingsSaga);
}

export default function* root() {
  yield all([fork(watch)]);
}
