import React from 'react';

// components
import Landing from 'layout/Landing';
import WaitingRoomHeader from './components/WaitingRoomHeader';
import WaitingRoomActions from './components/WaitingRoomActions';
import WaitingRoomContent from './components/WaitingRoomContent';

const WaitingRoom = function() {
  return (
    <Landing>
      <WaitingRoomHeader />
      <WaitingRoomContent />
      <WaitingRoomActions />
    </Landing>
  );
};

export default WaitingRoom;
