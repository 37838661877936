import React, { useState, useEffect } from 'react';

// hooks
import { useSelector, useDispatch } from 'react-redux';
import {
  useFeatureLimitRemaining,
  useFeatureHasLimit,
} from 'helpers/hooks/featureHooks';

import useInfiniteScroll from 'helpers/hooks/useInfiniteScroll';

// material-ui
import { withStyles, WithStyles } from '@material-ui/core/styles';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Skeleton from '@material-ui/lab/Skeleton';
import Typography from '@material-ui/core/Typography';
import pluralize from 'pluralize';

// icons
import FormsIcon from '@material-ui/icons/Ballot';

// components
import Header from 'components/Header';
import Button from 'components/Button';
import FormItem from 'routes/Forms//FormItem';
import CreateFormModal from 'routes/Forms/CreateFormModal';

// selectors
import getLoadingFromState from 'store/selectors/getLoadingFromState';
import getCurrentGroupId from 'store/selectors/getCurrentGroupId';
import getReferralForms from 'store/selectors/getReferralForms';
import getNextPageFromState from 'store/selectors/getNextPageFromState';

// action creators
import { fetchReferralFormsForGroup } from 'store/actions/referralFormActions';

//styles
import formsStyles from 'routes/Forms/forms.style';

export interface FormsProps extends WithStyles<typeof formsStyles> {
  isCouncilForm?: boolean;
  isOrganizationForm?: boolean;
  isUniversalForm?: boolean;
}

const Forms = ({
  classes,
  isCouncilForm,
  isOrganizationForm,
  isUniversalForm,
}: FormsProps) => {
  const dispatch = useDispatch();
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [expandedId, setExpandedId] = useState(null);

  const { formsLoading, forms, groupId, nextPage } = useSelector(state => ({
    formsLoading: getLoadingFromState('referralForm')(state),
    forms: getReferralForms(state),
    nextPage: getNextPageFromState('referralForm')(state),
    groupId: getCurrentGroupId(state),
  }));

  const [scrolledToBottom, scrollRef] = useInfiniteScroll(formsLoading);

  let formsRemainingMessage = '';
  const formsRemaining = useFeatureLimitRemaining('forms', forms.size);
  const formsHasLimit = useFeatureHasLimit('forms');

  if (formsHasLimit && !formsLoading) {
    if (formsRemaining) {
      formsRemainingMessage = pluralize(
        `You may create ${formsRemaining} more form`,
        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'number | true' is not assignable... Remove this comment to see the full error message
        formsRemaining
      );
    } else {
      formsRemainingMessage =
        'You are out of available forms. Delete a form or upgrade for more.';
    }
  }

  useEffect(() => {
    dispatch(fetchReferralFormsForGroup({ groupId }));
  }, [dispatch, groupId]);

  useEffect(() => {
    if (scrolledToBottom && nextPage) {
      dispatch(fetchReferralFormsForGroup({ groupId, page: nextPage }));
    }
  }, [dispatch, groupId, scrolledToBottom, nextPage]);

  return (
    <Grid
      container
      className={classes.formsContainer}
      justifyContent='center'
      innerRef={scrollRef}
    >
      <Grid item xs={12}>
        <Card>
          <CardContent className={classes.cardContent}>
            <Grid container spacing={4} alignItems='flex-start'>
              <Grid item>
                <Header
                  id='elevio_education_mind_joggers'
                  icon={FormsIcon}
                  title={isUniversalForm ? 'Universal Forms' : 'Forms'}
                  subtitle='View and manage referral forms to grow your names list'
                  highlightedSubtitle={formsRemainingMessage}
                />
              </Grid>

              <Hidden smUp>
                <Grid item xs={3} className={classes.buttonContainer}>
                  <Button
                    color='primary'
                    className={classes.button}
                    fabIcon={<AddCircleIcon />}
                    variant='contained'
                    onClick={() => setCreateModalOpen(true)}
                    startIcon={<AddCircleIcon />}
                  >
                    New Form
                  </Button>
                </Grid>
              </Hidden>
            </Grid>

            {!formsLoading && forms.size === 0 && (
              <Grid
                container
                id='noneFoundContainer'
                className={classes.listItemContainer}
              >
                <Grid item xs={12} sm={10} md={8} lg={7}>
                  <Typography variant='body1'>No forms found</Typography>
                </Grid>
                <Grid item xs={12} sm={10} md={8} lg={7}>
                  <Typography variant='body2' color='textSecondary'>
                    Create your first referral form by clicking the button in
                    the top left of your screen.
                  </Typography>
                </Grid>
              </Grid>
            )}

            {forms.size > 0 && (
              <Grid
                container
                spacing={3}
                className={classes.paddedListItemContainer}
              >
                {forms.map((form: any) => (
                  <FormItem
                    key={form.get('id')}
                    {...form.toJS()}
                    expandedId={expandedId}
                    setExpandedId={setExpandedId}
                    isCouncilForm={isCouncilForm}
                    isOrganizationForm={isOrganizationForm}
                  />
                ))}
              </Grid>
            )}
            {formsLoading && (
              <Grid
                container
                id='skeletonContainer'
                spacing={3}
                justifyContent='flex-start'
                alignContent='center'
                className={classes.listItemContainer}
              >
                {Array(3)
                  .fill(1)
                  .map(() => (
                    <Grid item xs={12} sm={10} md={8} lg={7}>
                      <Skeleton variant='rect' height={148} />
                    </Grid>
                  ))}
              </Grid>
            )}
          </CardContent>
        </Card>
      </Grid>

      <CreateFormModal
        open={createModalOpen}
        onClose={() => setCreateModalOpen(false)}
      />
    </Grid>
  );
};

export default withStyles(formsStyles, { withTheme: true })(Forms);
