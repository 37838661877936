import { all, call, fork, put, takeEvery, delay } from 'redux-saga/effects';

// action creators
import { doneIndicator } from 'store/actions/httpActions';
import { error } from 'store/actions/httpActions';

// api
import client from 'sources/api';

// constants
import {
  USER_VERIFY_EMAIL,
  USER_VERIFY_EMAIL_REQUEST,
} from 'store/constants/userTypes';

// lib
import httpSaga from 'store/sagas/httpSaga';

const WATCH_TYPE = USER_VERIFY_EMAIL_REQUEST;
const TYPE = USER_VERIFY_EMAIL;

export function* verifyUserEmail(action: any) {
  const {
    payload: { token, history },
  } = action;

  const { history: { location: { pathname = '' } = {} } = {} } = action.payload;

  let endpoint = '/auth/verify';

  if (pathname.includes('emails')) {
    endpoint = '/emails/verify';
  }

  try {
    // @ts-expect-error ts-migrate(2569) FIXME: Type 'Generator<any, any, unknown>' is not an arra... Remove this comment to see the full error message
    yield* httpSaga(
      TYPE,
      call(client.post, endpoint, {
        code: token,
      }),
      {
        throwError: true,
      }
    );

    yield delay(5000);
    yield history.push('/login');
  } catch (err) {
    yield put(error(TYPE, err));
  } finally {
    yield put(doneIndicator(TYPE));
  }
}

export function* watch() {
  yield takeEvery(WATCH_TYPE, verifyUserEmail);
}

export default function* root() {
  yield all([fork(watch)]);
}
