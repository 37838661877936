import {
  REQUEST_FETCH_FOR_GROUP_REQUEST,
  REQUEST_FETCH_FOR_USER_REQUEST,
  REQUEST_CREATE_REQUEST,
  REQUEST_UPDATE_REQUEST,
  REQUEST_DELETE_REQUEST,
} from 'store/constants/requestTypes';

export function fetchRequestsForUser() {
  return {
    type: REQUEST_FETCH_FOR_USER_REQUEST,
    payload: {},
  };
}

export function fetchRequestsForGroup({ groupId }: any) {
  return {
    type: REQUEST_FETCH_FOR_GROUP_REQUEST,
    payload: {
      groupId,
    },
  };
}

export function createRequest({ groupId }: any) {
  return {
    type: REQUEST_CREATE_REQUEST,
    payload: {
      groupId,
    },
  };
}

export function updateRequest({
  id,
  status,
  formName,
  groupId,
  roleId = 4,
}: any) {
  return {
    type: REQUEST_UPDATE_REQUEST,
    payload: {
      id,
      status,
      formName,
      groupId,
      roleId,
    },
  };
}

export function deleteRequest({ id, formName }: any) {
  return {
    type: REQUEST_DELETE_REQUEST,
    payload: { id, formName },
  };
}
