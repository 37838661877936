import { schema } from 'normalizr';
export const groupTagSchema = new schema.Entity(
  'groupTag',
  {},
  {
    processStrategy: value =>
      value.reduce(
        (acc: any, item: any) => ({ ...acc, [item.tagId]: item }),
        {}
      ),
  }
);
