import { all } from 'redux-saga/effects';

// sagas
import fetchCurrentUserSaga from './fetchCurrentUserSaga';
import registerUserSaga from './registerUserSaga';
import updateCurrentUserPasswordSaga from './updateCurrentUserPasswordSaga';
import updateCurrentUserSaga from './updateCurrentUserSaga';
import verifyUserEmailSaga from './verifyUserEmailSaga';
import fetchSystemAdminsSaga from './fetchSystemAdminsSaga';
import fetchUserByIdsSaga from './fetchUserByIdsSaga';

const sessionSaga = function* rootSaga() {
  yield all([
    fetchCurrentUserSaga(),
    registerUserSaga(),
    updateCurrentUserSaga(),
    updateCurrentUserPasswordSaga(),
    verifyUserEmailSaga(),
    fetchSystemAdminsSaga(),
    fetchUserByIdsSaga(),
  ]);
};

export default sessionSaga;
