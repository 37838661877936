import { all } from 'redux-saga/effects';

// sagas
import fetchMembersForGroupSaga from './fetchMembersForGroupSaga';
import updateMemberSaga from './updateMemberSaga';
import deleteMemberSaga from './deleteMemberSaga';
import deleteAllMembersForGroupSaga from './deleteAllMembersForGroupSaga';

const memberSaga = function* rootSaga() {
  yield all([
    fetchMembersForGroupSaga(),
    updateMemberSaga(),
    deleteMemberSaga(),
    deleteAllMembersForGroupSaga(),
  ]);
};

export default memberSaga;
