import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useContext,
} from 'react';
import { isEqual } from 'lodash';
import { List } from 'immutable';

// hooks
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

// material-ui
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress, Grid, Typography } from '@material-ui/core';
import { ArrowUpward } from '@material-ui/icons';

// components
import Button from 'components/Button';
import Thread from 'routes/Messages/Thread';
import TextField from 'components/TextField';

// actions
import {
  fetchMessagesAction,
  clearMessagesAction,
} from 'store/actions/messageActions';
import {
  fetchThreadsAction,
  clearThreadsAction,
} from 'store/actions/threadActions';
import { fetchPhoneNumbersForGroup } from 'store/actions/phoneNumberActions';

// selectors
import getCurrentGroupId from 'store/selectors/getCurrentGroupId';
import getSliceState from 'store/selectors/getSliceState';
import getMembers from 'store/selectors/getMembers';

// helpers
import formatDisplayedPhoneNumber from 'helpers/formatDisplayedPhoneNumber';
import { SiteVisualDataContext } from 'components/SiteVisualData';

const LeadMessageTab = function({ leadId }: { leadId: number }) {
  const { leadMessageTabMessage } = useContext(SiteVisualDataContext);
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const [selectedPhoneNumber, setSelectedPhoneNumber] = useState<string>('');

  const {
    currentGroupId,
    hasNextMessages,
    nextMessagesUrl,
    phoneNumberState,
    threads,
    members,
  } = useSelector(
    state => ({
      currentGroupId: getCurrentGroupId(state),
      hasNextMessages: (state as any).getIn(['message', 'meta', 'hasNext']),
      nextMessagesUrl: (state as any).getIn(['message', 'meta', 'next']),
      phoneNumberState: getSliceState('phoneNumber', 'fetch', false)(state),
      threads: getSliceState('thread')(state),
      members: (getMembers('suggestions')(state) || List()).toJS() as {
        label: string;
        value: number;
      }[],
    }),
    isEqual
  );

  const phoneNumbers = phoneNumberState.data;
  const phoneNumbersLoading = phoneNumberState.loading;
  const phoneNumberOptions = useMemo(
    () =>
      phoneNumbers.map(
        ({
          account,
          twilioNumber,
        }: {
          account: number;
          twilioNumber: string;
        }) => ({
          value: twilioNumber,
          label: `${formatDisplayedPhoneNumber(twilioNumber)} - ${members.find(
            (member: { label: string; value: number }) =>
              member?.value === account
          )?.label || 'Unassigned'}`,
        })
      ),
    [members, phoneNumbers]
  );

  const selectedThread: Thread = threads.data.find(
    (thread: Thread) => thread.twilioPhoneNumber === selectedPhoneNumber
  );

  useEffect(() => {
    dispatch(fetchPhoneNumbersForGroup());
  }, [dispatch]);

  useEffect(() => {
    if (selectedThread) {
      dispatch(clearMessagesAction());
      dispatch(
        fetchMessagesAction({
          groupId: currentGroupId,
          threadId: selectedThread?.id,
        })
      );
    }
  }, [dispatch, selectedThread, currentGroupId]);

  useEffect(() => {
    const params = {
      pnmRecipientId: leadId,
    };
    if (currentGroupId) (params as any).groupId = currentGroupId;
    if (phoneNumbers.length) {
      dispatch(clearThreadsAction());
      dispatch(fetchThreadsAction(params));
    }
  }, [dispatch, currentGroupId, leadId, phoneNumbers.length]);

  const fetchEarlierMessages = useCallback(() => {
    dispatch(
      fetchMessagesAction({
        groupId: currentGroupId,
        threadId: selectedThread?.id,
        since: nextMessagesUrl,
      })
    );
  }, [dispatch, currentGroupId, selectedThread, nextMessagesUrl]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    setSelectedPhoneNumber(event.target.value);
  };

  return (
    <Grid container spacing={2}>
      {phoneNumbers.length ? (
        <>
          <Grid item xs={12}>
            <Grid
              container
              spacing={2}
              className={classes.phoneNumberSelectorContainer}
            >
              <Grid item xl={4} lg={5} md={6} xs={8}>
                <TextField
                  select
                  id='phoneNumberSelector'
                  name='phoneNumberSelector'
                  label='Select phone number'
                  aria-label='Select phone number'
                  variant='outlined'
                  loading={phoneNumbersLoading}
                  options={phoneNumberOptions}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
          </Grid>

          {selectedThread ? (
            <Grid item xs={12}>
              <Grid
                container
                spacing={2}
                direction='column'
                alignItems='center'
              >
                {hasNextMessages && (
                  <Grid item>
                    <Button
                      startIcon={<ArrowUpward />}
                      onClick={fetchEarlierMessages}
                    >
                      Load More
                    </Button>
                  </Grid>
                )}
                <Grid item className={classes.threadItem}>
                  <Thread id={selectedThread && selectedThread.id} />
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <Grid item xs>
              <Grid
                container
                spacing={2}
                className={classes.emptyMessageContainer}
              >
                <Grid item xs>
                  {selectedPhoneNumber ? (
                    <Typography variant='subtitle1' align='center'>
                      There is no message thread to display for this phone
                      number and lead.
                    </Typography>
                  ) : (
                    <Typography variant='subtitle1' align='center'>
                      Please select a phone number from the dropdown above to
                      view the conversation between your team and this lead.
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </Grid>
          )}
        </>
      ) : !phoneNumbersLoading ? (
        <Grid item xs className={classes.setupMessage}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant='subtitle2'>
                {leadMessageTabMessage}
              </Typography>
              <Typography color='textSecondary' variant='body2'>
                Navigate to the Phone tab under settings to create a new phone
                number.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Button
                variant='outlined'
                onClick={() => history.push({ pathname: '/settings/phone' })}
              >
                Create Number
              </Button>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Grid item xs className={classes.loadingIndicatorContainer}>
          <CircularProgress />
        </Grid>
      )}
    </Grid>
  );
};

const useStyles = makeStyles(theme => {
  return {
    emptyMessageContainer: {
      marginTop: 60,
      marginBottom: 60,
      padding: 20,
      textAlign: 'center',
    },
    phoneNumberSelectorContainer: {
      borderBottom: `2px solid ${(theme.palette.background as any).darkPaper}`,
      alignContent: 'center',
      maxHeight: 80,
    },
    setupMessage: {
      borderBottom: `2px solid ${(theme.palette.background as any).darkPaper}`,
      padding: 20,
      maxHeight: 130,
    },
    threadItem: {
      width: '100%',
      padding: 20,
    },
    loadingIndicatorContainer: {
      minHeight: 120,
      marginTop: 40,
      alignSelf: 'center',
      textAlign: 'center',
    },
  };
});
export default LeadMessageTab;
