import React, { useContext } from 'react';
import validate from 'validate.js';
import { useDispatch } from 'react-redux';
import { useLocation, Link as RouterLink } from 'react-router-dom';

// components
import { Form, Field } from 'react-final-form';
import LandingButton from 'layout/Landing/components/LandingButton';
import LandingTextField from 'layout/Landing/components/LandingTextField';

// material-ui
import EmailIcon from '@material-ui/icons/Email';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import Link from '@material-ui/core/Link';
import LockIcon from '@material-ui/icons/Lock';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

// action creators
import { createSession } from 'store/actions/sessionActions';

// selectors
import { useReduxForm } from 'store/hooks/useReduxForm';

// helpers
import { SiteVisualDataContext } from 'components/SiteVisualData';

// schemas
const schema = {
  email: {
    presence: true,
    email: { message: 'not valid' },
    length: { maximum: 255 },
  },

  password: {
    presence: true,
    length: { maximum: 255 },
  },
};

const LoginForm = function() {
  const dispatch = useDispatch();
  const { loginFormMessage } = useContext(SiteVisualDataContext);
  const classes = useStyles();
  const location = useLocation();
  const {
    isSubmitting,
    hasSubmitFailed,
    hasSubmitSucceeded,
    formError,
  } = useReduxForm('loginForm');

  const { search } = location;
  const urlParams = new URLSearchParams(search);
  const emailAddress = urlParams.get('emailAddress');

  const onSubmit = (values: any) => {
    dispatch(createSession(values));
  };

  const getInitialValues = () => {
    if (emailAddress) {
      return {
        email: emailAddress,
      };
    }
    return {};
  };

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={getInitialValues()}
      validate={values => validate(values, schema)}
      render={({ handleSubmit, invalid }) => (
        <form onSubmit={handleSubmit} className={classes.formContainer}>
          <Grid container direction='column' spacing={6}>
            <Grid item xs={12} sm={10}>
              <Field
                id='email'
                name='email'
                // @ts-expect-error ts-migrate(2322) FIXME: Type 'ComponentType<Pick<InferProps<any>, string |... Remove this comment to see the full error message
                component={LandingTextField}
                label='Email Address'
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <EmailIcon />
                    </InputAdornment>
                  ),
                }}
                FormHelperTextProps={{ role: 'alert' }}
                className={classes.inputFields}
                autoFocus
                required
              />

              <Field
                id='password'
                name='password'
                // @ts-expect-error ts-migrate(2322) FIXME: Type 'ComponentType<Pick<InferProps<any>, string |... Remove this comment to see the full error message
                component={LandingTextField}
                label='Password'
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <LockIcon />
                    </InputAdornment>
                  ),
                }}
                FormHelperTextProps={{ role: 'alert' }}
                className={classes.inputFields}
                type='password'
                required
              />

              {hasSubmitFailed &&
                formError?.get('message') === 'Bad email or password' && (
                  <FormHelperText id='errorMessage' error role='alert'>
                    Incorrect email or password, please try again
                  </FormHelperText>
                )}
              {hasSubmitFailed &&
                formError?.get('message') ===
                  'Please fix validation errors and try again' && (
                  <FormHelperText id='errorMessage' error role='alert'>
                    Please check your internet connection and try again
                  </FormHelperText>
                )}
              {hasSubmitFailed &&
                formError?.get('message') !== 'Bad email or password' &&
                formError?.get('message') !==
                  'Please fix validation errors and try again' && (
                  <FormHelperText id='errorMessage' error role='alert'>
                    {loginFormMessage}
                  </FormHelperText>
                )}
            </Grid>

            <Grid item>
              <Grid container spacing={1} alignItems='center'>
                <Grid item xs={6} sm={5}>
                  <LandingButton
                    id='loginButton'
                    type='submit'
                    disabled={invalid || isSubmitting}
                    loading={isSubmitting}
                    success={hasSubmitSucceeded}
                    fail={hasSubmitFailed}
                  >
                    Log In
                  </LandingButton>
                </Grid>
                <Grid item xs={6} sm={7}>
                  <Typography variant='subtitle2' display='inline'>
                    <Link
                      component={RouterLink}
                      to='/forgot-password'
                      color='textPrimary'
                    >
                      Forgot Password
                    </Link>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      )}
    />
  );
};

const useStyles = makeStyles(theme => ({
  formContainer: {
    [theme.breakpoints.up('sm')]: {
      padding: 24,
    },
  },
  inputFields: {
    width: 300,
  },
}));

export default LoginForm;
