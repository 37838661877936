import React, { useCallback, memo } from 'react';
import { isEqual } from 'lodash';
import { Form, Field } from 'react-final-form';
import { useSelector } from 'react-redux';
import { useUpdateLeadMutation, useUpdateLeadsMutation } from 'api/leads';
import { useParams } from 'react-router';

// material-ui
import { Box } from '@material-ui/core';
import { SwapVert as StatusIcon } from '@material-ui/icons';

// components
import DazzlingDialog from 'components/DazzlingDialog';
import StatusSelector from 'components/StatusSelector';
import ChapterDropdown from 'components/ChapterDropdown';

//selectors
import getJStatusId from 'store/selectors/getJStatusId';

// actions
import getDefaultStatusId from 'store/selectors/getDefaultStatusId';

//helpers
import { toInt10 } from 'helpers/transform';

type UpdateStatusModalProps = {
  open: boolean;
  onClose: (...args: any[]) => any;
  groupId: number;
  allSelected?: boolean;
  leadIds?: number[];
  isCouncilLeadPage?: boolean;
};

const UpdateStatusModal = ({
  open,
  onClose,
  allSelected = false,
  leadIds = [],
  isCouncilLeadPage,
  groupId,
}: UpdateStatusModalProps) => {
  const { leadId } = useParams<{ leadId: string }>();
  const {
    isLoading: isLeadUpdating,
    isSuccess: isLeadUpdated,
    isError: isLeadUpdatingFailed,
    mutate: updateLead,
    reset: resetUpdateLeadMutation,
  } = useUpdateLeadMutation();
  const {
    isLoading: isLeadsUpdating,
    isSuccess: isLeadsUpdated,
    isError: isLeadsUpdatingFailed,
    mutate: updateLeads,
    reset: resetUpdateLeadsMutation,
  } = useUpdateLeadsMutation();

  const { defaultStatusId, jStatusId } = useSelector(
    state => ({
      defaultStatusId: getDefaultStatusId(state),
      jStatusId: getJStatusId(state),
    }),
    isEqual
  );

  const singleLeadId =
    Number(leadId) || (!allSelected && leadIds.length === 1 && leadIds[0]);

  const handleFormSubmit = useCallback(
    ({ statusId, joinedGroupId }) => {
      const getJoinedGroupId =
        isCouncilLeadPage &&
        joinedGroupId &&
        jStatusId &&
        toInt10(statusId || 0) === toInt10(jStatusId)
          ? { joinedGroupId }
          : {};
      if (singleLeadId)
        updateLead({ id: singleLeadId, statusId, ...getJoinedGroupId });
      else
        updateLeads({
          allSelected,
          ids: leadIds,
          statusId,
          ...getJoinedGroupId,
        });
    },
    [
      updateLead,
      updateLeads,
      allSelected,
      leadIds,
      singleLeadId,
      isCouncilLeadPage,
      jStatusId,
    ]
  );

  const handleFunction = useCallback(
    func => () => {
      func();

      if (singleLeadId) resetUpdateLeadMutation();
      else resetUpdateLeadsMutation();
    },
    [singleLeadId, resetUpdateLeadMutation, resetUpdateLeadsMutation]
  );
  return (
    <Form
      initialValues={{ statusId: Number(defaultStatusId) }}
      onSubmit={handleFormSubmit}
      render={({ handleSubmit, values, form: { reset } }) => (
        <DazzlingDialog
          id='test'
          alertProps={{ message: 'Status Updated Successfully' }}
          handleClose={onClose}
          open={open}
          onAccept={handleSubmit}
          headerProps={{
            icon: StatusIcon,
            title: 'Update Status',
            subtitle: 'Change statuses to move leads through the recruitment',
            highlightedSubtitle: `${leadIds.length || 'All'} lead${
              !leadIds.length || leadIds.length > 1 ? 's' : ''
            } selected`,
          }}
          acceptLabel='Submit'
          submitOnEnter
          onExited={handleFunction(reset)}
          isLoading={isLeadUpdating || isLeadsUpdating}
          isSuccess={isLeadUpdated || isLeadsUpdated}
          isError={isLeadUpdatingFailed || isLeadsUpdatingFailed}
        >
          <Box width='70%' marginTop={1} marginBottom={3}>
            <Field
              isEqual={isEqual}
              name='statusId'
              component={StatusSelector}
              fullWidth
              margin='normal'
            />
          </Box>
          <>
            {isCouncilLeadPage &&
              jStatusId &&
              toInt10(values?.statusId || 0) === toInt10(jStatusId) && (
                <Box width='70%' marginTop={1} marginBottom={3}>
                  <Field
                    name='joinedGroupId'
                    fullWidth
                    margin='normal'
                    label='Chapter'
                  >
                    {props => (
                      <ChapterDropdown
                        currentGroupId={groupId}
                        variant='outlined'
                        isCouncilAdmin={isCouncilLeadPage}
                        labelFormat='suggestionsByOrgName'
                        {...props}
                      />
                    )}
                  </Field>
                </Box>
              )}
          </>
        </DazzlingDialog>
      )}
    />
  );
};

export default memo(UpdateStatusModal);
