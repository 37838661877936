import { Map } from 'immutable';

export default function removeAttributeById(state: any, action: any) {
  // the payload contains a label string
  const { payload = Map() } = action;

  let newState = state;
  const id = payload.get('id', '');
  const subId = payload.get('subId', '');

  const item = newState.getIn(['data', 'byId', id.toString()], Map());
  const newItem = item.delete(subId.toString());
  newState = newState.setIn(['data', 'byId', id.toString()], newItem);
  return newState;
}
