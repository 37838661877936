import { Map } from 'immutable';
// constants
import {
  EVENT_GUEST_CREATE,
  EVENT_GUEST_UPDATE,
} from 'store/constants/eventGuestTypes';
// helpers
import httpReducer from './helpers/httpReducer';

export default function reduceEventGuest(state = Map(), action = {}) {
  switch ((action as any).type) {
    case EVENT_GUEST_CREATE:
      return httpReducer(state, action, {
        entity: 'eventGuest',
        appendResult: true,
      });
    case EVENT_GUEST_UPDATE:
      return httpReducer(state, action, {
        entity: 'eventGuest',
        updateResult: false,
      });
    default:
      return state;
  }
}
