import React from 'react';
// material-ui
import { makeStyles } from '@material-ui/core/styles';
import MuiTableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import getPadding from 'components/Table/helpers/getPadding';
TableFooterRow.defaultProps = {
  TableCellProps: {},
};
type OwnProps = {
  columns: any; // TODO: PropTypes.instanceOf(Array)
  data: any; // TODO: PropTypes.instanceOf(Object)
  selectedItems?: any; // TODO: PropTypes.instanceOf(Object)
  TableCellProps?: any; // TODO: PropTypes.instanceOf(Object)
  onRowClick?: (...args: any[]) => any;
  setSelectedItems?: (...args: any[]) => any;
  enableCheckbox?: boolean;
  allSelected?: boolean;
  checkboxTooltipTitle?: string;
  variant?: 'default' | 'outlined';
};
type Props = OwnProps & typeof TableFooterRow.defaultProps;
export default function TableFooterRow({
  columns,
  data,
  TableCellProps,
  variant,
  ...restOfProps
}: Props) {
  const classes = useStyles();
  return (
    <MuiTableRow {...restOfProps} classes={{ root: classes.root }}>
      {columns.map(
        ({ key, label, padding, tooltipTitle: rowTooltipTitle = '' }: any) => {
          let node = data[key];
          const cellTooltipTitle = data?.tooltipTitle;
          // If this is not a valid react component, it's probably a string
          // and should be wrapped in Typography
          if (!React.isValidElement(node)) {
            node = (
              <Typography variant='body2' className={classes.semibold}>
                {data[key]}
              </Typography>
            );
          }
          return (
            <TableCell
              variant='body'
              key={key}
              classes={{
                root: classes.cell,
              }}
              {...TableCellProps}
              padding={getPadding(label, padding)}
            >
              <Tooltip
                arrow
                placement='bottom-start'
                title={cellTooltipTitle || rowTooltipTitle}
                disableHoverListener={!cellTooltipTitle && !rowTooltipTitle}
              >
                {node}
              </Tooltip>
            </TableCell>
          );
        }
      )}
    </MuiTableRow>
  );
}
const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.primary.main,
  },
  cell: {
    '&:first-child': {
      paddingLeft: 24,
    },
    border: 'none',
  },
  row: {
    borderBottom: `1px solid ${(theme.palette.background as any).lightPaper}`,
    '&:last-child': {
      border: 'none',
    },
  },
  semibold: {
    fontWeight: 600,
  },
}));
