import React, { useState, useCallback, useEffect, useMemo } from 'react';
import humps from 'humps';
import { List, Map } from 'immutable';
import { Moment } from 'moment';
import moment from 'moment-timezone';
import { roleNames } from 'helpers/getRoles';
import { debounce } from 'lodash';

// helpers
import formatStartDate from 'helpers/formatStartDate';
import formatEndDate from 'helpers/formatEndDate';
import { getTimeSlotLabel } from 'helpers/getTimeSlotLabel';

// hooks
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { useParams } from 'react-router-dom';
import { usePrevious } from 'helpers/hooks/usePrevious';
import { useEventTimeSlotsQuery } from 'api/eventTimeSlots';
import { EventGuestQueryParams, useEventGuestsQuery } from 'api/eventGuests';

// material-ui
import {
  Grid,
  InputAdornment,
  Typography,
  MenuItem,
  Box,
  Tooltip,
  TextField,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import {
  Add,
  Search,
  CheckCircle,
  Cancel,
  HelpOutline,
} from '@material-ui/icons';

// components
import Table from 'components/Table';
import MegaMenu from 'components/MegaMenu';
import EditGuestButton from './EditGuestButton';
import DateSelector from 'components/DateSelector';
import ExportButton from 'components/ExportButton';

// selectors
import getCurrentGroupId from 'store/selectors/getCurrentGroupId';
import getLeadStatuses from 'store/selectors/getLeadStatuses';
import getLoadingFromState from 'store/selectors/getLoadingFromState';
import getStatusFromState from 'store/selectors/getStatusFromState';

// action creators
import {
  createEventGuests,
  updateEventGuest,
} from 'store/actions/eventGuestActions';

const ROWS_PER_PAGE = 10;

const INITIAL_QUERY_PARAMS: EventGuestQueryParams = {
  page: 1,
  search: '',
  statusIds: [],
  invited: '',
  rsvp: '',
  attended: '',
  startDateStart: '',
  startDateEnd: '',
  orderBy: 'fullName',
};

export default function ManageGuestsTable({
  guestType,
}: {
  id?: string;
  guestType: 'lead' | 'member';
}) {
  // table order init
  const initialOrder = 'desc';
  const initialOrderBy = INITIAL_QUERY_PARAMS.orderBy;

  // component state init
  const [anchorEl, setAnchorEl] = useState<EventTarget | null>(null);
  const [selectedCellType, setSelectedCellType] = useState<EventStatusTypes>(
    null
  );
  const [selectedGuest, setSelectedGuest] = useState<GuestTableItem | null>(
    null
  );

  // get data from redux state
  const {
    currentGroupId,
    guestUpdating,
    guestUpdatedStatus,
    guestsLoading,
    statuses,
  } = useSelector(
    state => ({
      currentGroupId: getCurrentGroupId(state),
      guestUpdating: selectedGuest
        ? getLoadingFromState(
            'eventGuest',
            `update-${selectedGuest.id}`,
            false
          )(state)
        : false,
      guestUpdatedStatus: selectedGuest
        ? getStatusFromState('eventGuest', `update-${selectedGuest.id}`)(state)
        : 'success',
      guestsLoading: getLoadingFromState('eventGuest')(state),
      statuses: getLeadStatuses(state),
    }),
    shallowEqual
  );

  // hook initialization
  const classes = useStyles();
  const dispatch = useDispatch();
  const initialQueryParams = useMemo<EventGuestQueryParams>(
    () => ({
      ...INITIAL_QUERY_PARAMS,
      type: guestType === 'lead' ? 'pnm' : 'member',
    }),
    [guestType]
  );
  const [queryParams, setQueryParams] = useState<EventGuestQueryParams>(
    initialQueryParams
  );
  const isSearching = Boolean(queryParams.search);

  // This function gets called when the table renders, so no need for a useEffect data initialization
  const handlePageChange = useCallback((rows: any[], newPage: number) => {
    // MUI Table pages are zero indexed, so we pass `page + 1` to the API
    setQueryParams(queryParams => ({ ...queryParams, page: newPage + 1 }));
  }, []);

  const handleSearchChange = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
      setQueryParams(queryParams => ({
        ...queryParams,
        search: event.target.value,
      })),
    []
  );

  const handleDebouncedSearchChange = useMemo(
    () => debounce(handleSearchChange, 600),
    [handleSearchChange]
  );

  const handleStatusChange = useCallback(
    (event: React.ChangeEvent<{ value: unknown }>) =>
      setQueryParams(queryParams => ({
        ...queryParams,
        statusIds: event.target.value as number[],
      })),
    []
  );

  const handleInvitedChange = useCallback(
    (event: React.ChangeEvent<{ value: unknown }>) =>
      setQueryParams(queryParams => ({
        ...queryParams,
        invited: event.target.value as '' | 'true' | 'false',
      })),
    []
  );

  const handleAttendedChange = useCallback(
    (event: React.ChangeEvent<{ value: unknown }>) =>
      setQueryParams(queryParams => ({
        ...queryParams,
        attended: event.target.value as '' | 'true' | 'false',
      })),
    []
  );

  const handleRsvpChange = useCallback(
    (event: React.ChangeEvent<{ value: unknown }>) =>
      setQueryParams(queryParams => ({
        ...queryParams,
        rsvp: event.target.value as '' | 'yes' | 'no' | 'maybe' | 'n/a',
      })),
    []
  );

  const handleEtsDateChange = useCallback(
    (etsDate: null | Moment) =>
      setQueryParams(queryParams => ({
        ...queryParams,
        startDateStart: formatStartDate(etsDate),
        startDateEnd: formatEndDate(etsDate),
      })),
    []
  );

  const {
    data: { eventGuests = [], total, hasNextPage, hasPreviousPage } = {},
    isLoading: eventGuestsLoading,
    refetch,
  } = useEventGuestsQuery(queryParams);

  const guests = useMemo<(EventGuest & { status?: string; role?: string })[]>(
    () =>
      eventGuests.map(eg =>
        guestType === 'lead'
          ? {
              ...eg,
              status: statuses.find(s => s.id === eg.statusId)?.abbreviation,
            }
          : {
              ...eg,
              role: (roleNames as any)[eg.roleId as number],
            }
      ),
    [eventGuests, statuses, guestType]
  );

  useEffect(() => {
    setQueryParams({ ...initialQueryParams, search: queryParams.search });
  }, [queryParams.search, initialQueryParams]);

  const params: { eventId: string } = useParams();
  const { eventId } = params;

  const exportParams = useMemo(() => {
    const params: any = {
      eventId: Number(eventId),
      guestType: queryParams.type,
      ...queryParams,
    };
    if (params.orderBy) {
      params.orderBy = humps.decamelize(params.orderBy);
    }
    delete params.page;
    delete params.search;
    delete params.type;
    for (const field in params) {
      if (!params[field]) delete params[field];
    }
    params.timezone_name = moment.tz.guess();
    return params;
  }, [queryParams, eventId]);

  const {
    data: eventTimeSlots = [],
    refetch: refetchETS,
  } = useEventTimeSlotsQuery(Number(eventId));
  const availableETS = useMemo(
    () => eventTimeSlots.filter(ets => ets.isAvailable),
    [eventTimeSlots]
  );
  const previousGuestUpdatedStatus = usePrevious(guestUpdatedStatus);

  useEffect(
    () => () => {
      if (guestsLoading) {
        refetch();
      }
    },
    [guestsLoading, refetch]
  );

  useEffect(() => {
    if (
      !guestUpdating &&
      previousGuestUpdatedStatus !== 'success' &&
      guestUpdatedStatus === 'success'
    ) {
      setSelectedGuest(null);
      refetchETS();
    }
  }, [
    previousGuestUpdatedStatus,
    guestUpdatedStatus,
    guestUpdating,
    refetchETS,
  ]);

  const handleSortChange = useCallback(
    (column: string, isDesc: boolean) =>
      setQueryParams(queryParams => ({
        ...queryParams,
        orderBy:
          (isDesc ? '-' : '') +
          (column === 'status'
            ? 'statusPosition'
            : column === 'rsvp' && eventTimeSlots.length > 1
            ? 'startDate'
            : column),
      })),
    [eventTimeSlots]
  );

  // Create or update an event guest's record
  const handleUpdateGuest = useCallback(
    ({
      data,
    }: {
      data: {
        invited?: boolean;
        attended?: boolean;
        rsvp?: RsvpOptions;
        eventTimeSlotId?: number | null;
        guestId?: number;
      };
    }) => {
      // update event guest record
      const eventGuestId = selectedGuest?.id || data.guestId;
      if (eventGuestId) {
        dispatch(
          updateEventGuest({
            id: eventGuestId,
            groupId: currentGroupId,
            data,
          })
        );
      } else if (eventId) {
        // create a new event guest record
        dispatch(
          createEventGuests({
            groupId: currentGroupId,
            eventId: parseInt(eventId, 10),
            ...data,
            leadIds:
              guestType === 'lead' && selectedGuest?.pnmId
                ? [selectedGuest.pnmId]
                : undefined,
            accountIds:
              guestType === 'member' && selectedGuest?.accountId
                ? [selectedGuest.accountId]
                : undefined,
            formName: 'createEventGuest',
          })
        );
      }
    },
    [currentGroupId, dispatch, eventId, guestType, selectedGuest]
  );

  const handleEditButtonClick = useCallback(
    (
      event: Event,
      guest: GuestTableItem | null,
      type: EventStatusTypes,
      guestNotComingToETS = false
    ) => {
      if (guest && guestNotComingToETS) {
        handleUpdateGuest({
          data: { guestId: guest.id, rsvp: 'no', eventTimeSlotId: null },
        });
      } else {
        setAnchorEl(event.currentTarget);
      }
      setSelectedCellType(type);
      setSelectedGuest(guest);
    },
    [handleUpdateGuest]
  );

  // Menu options functions
  const getInvitedOptions = () =>
    List([
      Map({
        id: 'invited',
        sectionHeader: 'Set invited to:',
        items: List([
          Map({
            id: 'yes',
            label: 'Yes',
            onClick: () => {
              handleUpdateGuest({ data: { invited: true } });
            },
          }),
          Map({
            id: 'no',
            label: 'No',
            onClick: () => {
              handleUpdateGuest({ data: { invited: false } });
            },
          }),
        ]),
      }),
    ]);
  const getRsvpOptions = () =>
    List([
      Map({
        id: 'rsvp',
        sectionHeader:
          eventTimeSlots.length > 1 ? 'Select a meeting time' : 'RSVP as:',
        items:
          eventTimeSlots.length > 1
            ? List(
                availableETS.map(ets =>
                  Map({
                    id: ets.id,
                    label: getTimeSlotLabel(ets),
                    onClick: () => {
                      handleUpdateGuest({
                        data: { rsvp: 'yes', eventTimeSlotId: ets.id },
                      });
                    },
                  })
                )
              )
            : List([
                Map({
                  id: 'rsvp-yes',
                  label: 'Attending',
                  onClick: () => {
                    handleUpdateGuest({
                      data: {
                        rsvp: 'yes',
                        eventTimeSlotId: availableETS[0].id,
                      },
                    });
                  },
                }),
                Map({
                  id: 'rsvp-maybe',
                  label: 'Maybe Attending',
                  onClick: () => {
                    handleUpdateGuest({ data: { rsvp: 'maybe' } });
                  },
                }),
                Map({
                  id: 'rsvp-no',
                  label: 'Not Attending',
                  onClick: () => {
                    handleUpdateGuest({ data: { rsvp: 'no' } });
                  },
                }),
              ]),
      }),
    ]);

  const getAttendedOptions = () =>
    List([
      Map({
        id: 'attended',
        sectionHeader: 'Set attended to:',
        items: List([
          Map({
            id: 'yes',
            label: 'Yes',
            onClick: () => {
              handleUpdateGuest({ data: { attended: true } });
            },
          }),
          Map({
            id: 'no',
            label: 'No',
            onClick: () => {
              handleUpdateGuest({ data: { attended: false } });
            },
          }),
        ]),
      }),
    ]);

  // Menu rendering functions
  const renderInviteMenu = () => {
    return (
      <MegaMenu
        // @ts-expect-error
        id='invitedMenu'
        anchorEl={anchorEl}
        handleClose={() => {
          setAnchorEl(null);
          setSelectedCellType(null);
        }}
        menuSections={getInvitedOptions()}
      />
    );
  };

  const renderRsvpMenu = () => {
    return (
      <MegaMenu
        // @ts-expect-error
        id='rsvpMenu'
        anchorEl={anchorEl}
        handleClose={() => {
          setAnchorEl(null);
          setSelectedCellType(null);
        }}
        menuSections={getRsvpOptions()}
      />
    );
  };
  const renderAttendedMenu = () => {
    return (
      <MegaMenu
        // @ts-expect-error
        id='attendedMenu'
        anchorEl={anchorEl}
        handleClose={() => {
          setAnchorEl(null);
          setSelectedCellType(null);
        }}
        menuSections={getAttendedOptions()}
      />
    );
  };

  // Table cell button rendering functions
  const getInvitedButton = useCallback(
    (guest: GuestTableItem) => {
      return guest.invited ? (
        <EditGuestButton
          guest={guest}
          handleEditButtonClick={handleEditButtonClick}
          icon={<CheckCircle className={classes.greenIcon} />}
          selectedCellType={selectedCellType}
          selectedGuest={selectedGuest}
          type='invited'
        />
      ) : (
        <EditGuestButton
          guest={guest}
          handleEditButtonClick={handleEditButtonClick}
          icon={<Cancel className={classes.redIcon} />}
          selectedCellType={selectedCellType}
          selectedGuest={selectedGuest}
          type='invited'
        />
      );
    },
    [
      selectedGuest,
      selectedCellType,
      classes.redIcon,
      classes.greenIcon,
      handleEditButtonClick,
    ]
  );

  const getRsvpButton = useCallback(
    (guest: GuestTableItem) => {
      let multipleEts = eventTimeSlots.length > 1;
      if (multipleEts) {
        let eventTimeSlot = eventTimeSlots.find(
          ets => ets.id === guest.eventTimeSlotId
        );
        if (eventTimeSlot) {
          return (
            <EditGuestButton
              disabled={!Boolean(availableETS.length)}
              guest={guest}
              handleEditButtonClick={handleEditButtonClick}
              label={getTimeSlotLabel(eventTimeSlot)}
              selectedGuest={selectedGuest}
              type='rsvp'
              selectedCellType={selectedCellType}
              multipleEts
            />
          );
        }
        return (
          <EditGuestButton
            disabled={!Boolean(availableETS.length)}
            guest={guest}
            handleEditButtonClick={handleEditButtonClick}
            icon={<Add />}
            selectedGuest={selectedGuest}
            type='rsvp'
            selectedCellType={selectedCellType}
            multipleEts={multipleEts}
          />
        );
      }
      switch (guest.rsvp) {
        case 'yes':
          return (
            <EditGuestButton
              guest={guest}
              handleEditButtonClick={handleEditButtonClick}
              icon={<CheckCircle className={classes.greenIcon} />}
              selectedGuest={selectedGuest}
              type='rsvp'
              selectedCellType={selectedCellType}
              multipleEts={multipleEts}
            />
          );
        case 'maybe':
          return (
            <EditGuestButton
              disabled={!Boolean(availableETS.length)}
              guest={guest}
              handleEditButtonClick={handleEditButtonClick}
              icon={<HelpOutline className={classes.orangeIcon} />}
              selectedGuest={selectedGuest}
              type='rsvp'
              selectedCellType={selectedCellType}
              multipleEts={multipleEts}
            />
          );
        case 'no':
          return (
            <EditGuestButton
              disabled={!Boolean(availableETS.length)}
              guest={guest}
              handleEditButtonClick={handleEditButtonClick}
              icon={<Cancel className={classes.redIcon} />}
              selectedGuest={selectedGuest}
              type='rsvp'
              selectedCellType={selectedCellType}
            />
          );
        default:
          return (
            <EditGuestButton
              disabled={!Boolean(availableETS.length)}
              guest={guest}
              handleEditButtonClick={handleEditButtonClick}
              icon={<Add />}
              selectedGuest={selectedGuest}
              type='rsvp'
              selectedCellType={selectedCellType}
              multipleEts={multipleEts}
            />
          );
      }
    },
    [
      selectedGuest,
      selectedCellType,
      eventTimeSlots,
      availableETS,
      classes.redIcon,
      classes.greenIcon,
      classes.orangeIcon,
      handleEditButtonClick,
    ]
  );

  const getAttendedButton = useCallback(
    (guest: GuestTableItem) => {
      return guest.attended ? (
        <EditGuestButton
          guest={guest}
          handleEditButtonClick={handleEditButtonClick}
          icon={<CheckCircle className={classes.greenIcon} />}
          selectedCellType={selectedCellType}
          selectedGuest={selectedGuest}
          type='attended'
        />
      ) : (
        <EditGuestButton
          guest={guest}
          handleEditButtonClick={handleEditButtonClick}
          icon={<Cancel className={classes.redIcon} />}
          selectedCellType={selectedCellType}
          selectedGuest={selectedGuest}
          type='attended'
        />
      );
    },
    [
      selectedGuest,
      selectedCellType,
      classes.redIcon,
      classes.greenIcon,
      handleEditButtonClick,
    ]
  );

  // Gathers and formats columns and cell data for table
  const content = useMemo(
    () =>
      guests.map(guest => ({
        ...guest,
        invited: getInvitedButton(guest),
        rsvp: getRsvpButton(guest),
        attended: getAttendedButton(guest),
      })),
    [guests, getInvitedButton, getRsvpButton, getAttendedButton]
  );

  const columns = useMemo(() => {
    const columns: {
      label: string;
      key: string;
      disableSort: boolean;
      padding?: string;
    }[] = [];
    if (guestType === 'lead')
      columns.push(
        ...[
          { label: 'Lead', key: 'fullName', disableSort: isSearching },
          { label: 'Status', key: 'status', disableSort: isSearching },
          { label: 'Email', key: 'emailAddress', disableSort: true },
          { label: 'Phone', key: 'phoneNumber', disableSort: true },
        ]
      );
    else
      columns.push(
        ...[
          { label: 'Name', key: 'fullName', disableSort: isSearching },
          { label: 'Email', key: 'emailAddress', disableSort: true },
          { label: 'Role', key: 'role', disableSort: true },
        ]
      );
    columns.push(
      ...[
        {
          label: 'Invited',
          key: 'invited',
          disableSort: isSearching,
          padding: 'none',
        },
        {
          label: 'RSVP',
          key: 'rsvp',
          disableSort: isSearching,
          padding: 'none',
        },
        {
          label: 'Attended',
          key: 'attended',
          disableSort: isSearching,
          padding: 'none',
        },
      ]
    );
    return columns;
  }, [guestType, isSearching]);

  return (
    <Grid container justifyContent='flex-start' spacing={2} alignItems='center'>
      <Grid item xs={12}>
        <Typography variant='h6' id='guestsTitle'>
          Manage {guestType.charAt(0).toUpperCase()}
          {guestType.slice(1)}s
        </Typography>
        <Typography color='secondary' variant='body2'>
          Select Invited, Attending, and Attended cells to edit guests and check
          them in to your event
        </Typography>
      </Grid>
      <Grid item xs={12} xl={10}>
        <Grid container wrap='nowrap'>
          <Grid item xs>
            <Grid
              container
              justifyContent='flex-start'
              spacing={2}
              alignItems='center'
            >
              <Grid item xs={6}>
                <TextField
                  id='inviteSearchInput'
                  variant='outlined'
                  fullWidth
                  label={`Search ${guestType}s`}
                  placeholder='Type name'
                  onChange={handleDebouncedSearchChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <Search />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <Tooltip
                  title={
                    isSearching ? 'Filters are disabled while searching' : ''
                  }
                >
                  <Box>
                    <Grid
                      container
                      justifyContent='flex-start'
                      spacing={2}
                      wrap='nowrap'
                      alignItems='center'
                    >
                      {guestType === 'lead' && (
                        <Grid item xs={3}>
                          <TextField
                            disabled={isSearching}
                            value={queryParams.statusIds}
                            fullWidth
                            variant='outlined'
                            label='Statuses'
                            onChange={handleStatusChange}
                            select
                            InputLabelProps={{
                              shrink: true,
                            }}
                            SelectProps={{
                              multiple: true,
                              displayEmpty: true,
                              renderValue: (selected: any) =>
                                selected.length === 0 ||
                                selected.length === statuses.length
                                  ? 'All'
                                  : statuses
                                      .filter(status =>
                                        selected.includes(status.id)
                                      )
                                      .map(status => status.abbreviation)
                                      .join(', '),
                            }}
                          >
                            {statuses.map(status => (
                              <MenuItem key={status.id} value={status.id}>
                                {status.abbreviation}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>
                      )}
                      <Grid item xs={2}>
                        <TextField
                          disabled={isSearching}
                          value={queryParams.invited}
                          fullWidth
                          variant='outlined'
                          label='Invited'
                          onChange={handleInvitedChange}
                          select
                          InputLabelProps={{
                            shrink: true,
                          }}
                          SelectProps={{
                            displayEmpty: true,
                            renderValue: (selected: any) =>
                              !selected
                                ? 'All'
                                : selected === 'true'
                                ? 'Yes'
                                : 'No',
                          }}
                        >
                          {[
                            { value: null, label: 'All' },
                            { value: 'true', label: 'Yes' },
                            { value: 'false', label: 'No' },
                          ].map(option => (
                            <MenuItem
                              key={option.value}
                              value={option.value || ''}
                            >
                              {option.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                      {eventTimeSlots.length > 1 ? (
                        <Grid item xs={5}>
                          <DateSelector
                            meta={{}}
                            fullWidth
                            disabled={isSearching}
                            label='RSVP Date'
                            input={{
                              value: queryParams.startDateStart
                                ? moment(queryParams.startDateStart)
                                : null,
                              onChange: handleEtsDateChange,
                              name: 'ets',
                              onBlur: () => {},
                              onFocus: () => {},
                            }}
                          />
                        </Grid>
                      ) : (
                        <Grid item xs={3}>
                          <TextField
                            disabled={isSearching}
                            value={queryParams.rsvp}
                            fullWidth
                            variant='outlined'
                            label='RSVP'
                            onChange={handleRsvpChange}
                            select
                            InputLabelProps={{
                              shrink: true,
                            }}
                            SelectProps={{
                              displayEmpty: true,
                              renderValue: (selected: any) =>
                                [
                                  { value: '', label: 'All' },
                                  { value: 'yes', label: 'Yes' },
                                  { value: 'no', label: 'No' },
                                  { value: 'maybe', label: 'Maybe' },
                                  { value: 'n/a', label: 'N/A' },
                                ].find(item => item.value === selected)?.label,
                            }}
                          >
                            {[
                              { value: '', label: 'All' },
                              { value: 'yes', label: 'Yes' },
                              { value: 'no', label: 'No' },
                              { value: 'maybe', label: 'Maybe' },
                              { value: 'n/a', label: 'N/A' },
                            ].map(option => (
                              <MenuItem key={option.value} value={option.value}>
                                {option.label}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>
                      )}
                      <Grid item xs={2}>
                        <TextField
                          disabled={isSearching}
                          value={queryParams.attended}
                          fullWidth
                          variant='outlined'
                          label='Attended'
                          onChange={handleAttendedChange}
                          select
                          InputLabelProps={{
                            shrink: true,
                          }}
                          SelectProps={{
                            displayEmpty: true,
                            renderValue: (selected: any) =>
                              !selected
                                ? 'All'
                                : selected === 'true'
                                ? 'Yes'
                                : 'No',
                          }}
                        >
                          {[
                            { value: null, label: 'All' },
                            { value: 'true', label: 'Yes' },
                            { value: 'false', label: 'No' },
                          ].map(option => (
                            <MenuItem
                              key={option.value}
                              value={option.value || ''}
                            >
                              {option.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                    </Grid>
                  </Box>
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <ExportButton
              exportType='event_guests'
              params={exportParams}
              disabled={eventGuestsLoading || isSearching}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} xl={10}>
        <Table
          columns={columns}
          data={content}
          variant='outlined'
          numberOfSkeletonRows={eventGuestsLoading ? ROWS_PER_PAGE : 0}
          initialOrder={initialOrder}
          initialOrderBy={initialOrderBy}
          fetchSortedData={handleSortChange}
          onPageChange={handlePageChange}
          HeaderTableCellProps={{
            classes: {
              head: classes.tableCell,
            },
          }}
          TablePaginationProps={{
            rowsPerPage: ROWS_PER_PAGE,
            rowsPerPageOptions: [ROWS_PER_PAGE],
            paginationType: 'api',
            backIconButtonProps: {
              disabled: !hasPreviousPage,
            },
            nextIconButtonProps: {
              disabled: !hasNextPage,
            },
            count: total,
          }}
          sortDisabled={isSearching}
        />
      </Grid>

      {selectedCellType === 'invited' && renderInviteMenu()}
      {selectedCellType === 'rsvp' && renderRsvpMenu()}
      {selectedCellType === 'attended' && renderAttendedMenu()}
    </Grid>
  );
}

const useStyles = makeStyles(theme => {
  return {
    tableCell: {
      paddingRight: 24,
      borderBottomColor: (theme as any).palette.background.lightPaper,

      '&:last-child': {
        paddingRight: 24,
      },
    },
    greenIcon: {
      color: (theme as any).palette.success.light,
    },
    orangeIcon: {
      color: (theme as any).palette.secondary.main,
    },
    redIcon: {
      color: (theme as any).palette.primary.main,
    },
  };
});
