import { Map, List } from 'immutable';
export default function getErrorMessage(slice: any) {
  return (state: any) => {
    const data = state.getIn([slice, 'meta', 'error', 'data'], Map()) || Map();

    if (data.get('errors')) {
      if (data.get('errors').size === 0) {
        const msg = data.getIn(['errors', 0, 'msg']);
        const id = data.getIn(['errors', 0, 'pnmId']);
        return id ? `${msg} (Lead ID ${id})` : msg;
      } else if (
        data.get('errors').size === 1 &&
        data.getIn(['errors', 0, 'pnmId'])
      ) {
        const errorList = data.get('errors', List()).map((error: any) => {
          const msg = error.get('msg');
          const id = error.get('pnmId');
          return id ? `${msg} (Lead ID ${id})` : msg;
        });
        return errorList.join(', ');
      } else {
        return data.get('errors');
      }
    } else {
      return data.get('msg');
    }
  };
}
