import React from 'react';

// components
import { Link as RouterLink } from 'react-router-dom';
import MaterialLink from '@material-ui/core/Link';

// types
import { LinkProps as RouterLinkProps } from 'react-router-dom';
import { LinkProps as MaterialLinkProps } from '@material-ui/core/Link';

type MixedLinkProps = RouterLinkProps & MaterialLinkProps;

export default function MixedLink({
  underline = 'none',
  to,
  children,
  ...restOfProps
}: MixedLinkProps) {
  return (
    <MaterialLink
      component={RouterLink}
      to={to}
      underline={underline}
      {...restOfProps}
    >
      {children}
    </MaterialLink>
  );
}
