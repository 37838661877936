import { Styles } from 'assets/types';

const fieldWithTypeStyles: Styles = () => ({
  gridDialogBox: {},
  gridDialogButtons: {
    paddingTop: 20,
  },
  gridDialogItem: {},
  checkboxDescription: {
    color: 'rgba(255,255,255, 0.5)',
    fontSize: '0.75rem',
    fontWeight: 400,
    lineHeight: 1.66,
  },
  controlLabelDesc: {
    alignItems: 'flex-start',
    '& > .MuiFormControlLabel-label': {
      paddingTop: 10,
    },
  },
});

export default fieldWithTypeStyles;
