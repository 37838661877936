import { Styles, Theme } from 'assets/types';

const formEditorStyles: Styles = theme => {
  const border = `2px solid ${(theme as Theme).palette.background.darkPaper}`;
  return {
    header: {
      borderTop: border,
      borderBottom: border,
      padding: 15,
      paddingRight: 50,
      paddingLeft: 50,
      '& .MuiInputBase-root': {
        minHeight: '47px',
      },
      [theme.breakpoints.only('xs')]: {
        paddingRight: 20,
        paddingLeft: 20,
      },
    },
    headerImage: {
      width: '35%',
      [theme.breakpoints.only('xs')]: {
        width: '50%',
      },
    },
    headerImageContainer: {
      padding: 24,
      textAlign: 'center',
      [theme.breakpoints.only('xs')]: {
        padding: 12,
      },
    },
    headerImageSkeleton: {
      margin: 'auto',
    },
    titleField: {
      marginTop: 0,
    },
    formCard: {
      zIndex: 1,
      marginTop: 38,
      marginBottom: 38,
      marginLeft: '10%',
      [theme.breakpoints.only('xl')]: {
        marginLeft: '20%',
      },
      [theme.breakpoints.only('xs')]: {
        margin: 10,
      },
    },
    formContent: {
      paddingTop: 24,
      paddingLeft: 50,
      paddingRight: 50,
      [theme.breakpoints.only('xs')]: {
        paddingLeft: 20,
        paddingRight: 20,
      },
    },
    formContentHeader: {
      paddingBottom: 15,
    },
    editorContent: {
      borderTop: border,
      paddingTop: 24,
      paddingLeft: 50,
      paddingRight: 50,
      paddingBottom: 40,
      [theme.breakpoints.only('xs')]: {
        paddingLeft: 20,
        paddingRight: 20,
      },
    },
    errorMessage: {
      marginBottom: 15,
      textAlign: 'center',
    },
    footerContainer: {
      textAlign: 'center',
      margin: 10,
    },
    bottomField: {
      paddingTop: 14,
      paddingBottom: 4,
    },
    customAgreementRequired: {
      textAlign: 'right',
      '& .MuiFormControl-root': {
        paddingRight: 0,
        marginRight: 0,
      },
      '& .MuiFormControlLabel-root': {
        paddingRight: 0,
        marginRight: 0,
        justifyContent: 'flex-end',
      },
    },
  };
};

export default formEditorStyles;
